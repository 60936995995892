import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {PortTemplateDto} from "../../../../../generated/api/base";
import {THREEUtils} from "../../../../../utils/THREEUtils";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark-large.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {PortContainerStyled, PortsV5} from "./PortsV5";
import {PortSelectAreaV5} from "./PortSelectAreaV5";

interface IPortSelectorProps {
	spaceViewRenderer: SpaceViewRenderer;
	item: Xyicon;
	worldX: number;
	worldY: number;
	ports: PortTemplateDto[];
	type: "from" | "to";
	appState?: AppState;
}

const firstRowSize: number = 77; // px
const portRowSize: number = 54.5; // px

@inject("appState")
export class PortSelectorV5 extends React.Component<IPortSelectorProps> {
	private _ref = React.createRef<HTMLDivElement>();

	private onPortSelected = (portId: string) => {
		const {linkManager} = this.props.spaceViewRenderer.toolManager;

		if (this.props.type === "from") {
			linkManager.enableLinkMode(portId);
		} else {
			linkManager.createLinks(portId);
			linkManager.disableLinkMode();
		}
	};

	// private getSelectables()
	// {
	// 	const selectables = [
	// 		<div
	// 			key={0}
	// 			className="selectable"
	// 			onClick={() => this.onPortSelected(null)}
	// 		>
	// 			Link to Xyicon (no port)
	// 		</div>
	// 	];

	// 	selectables.push(...this.props.portEndPoints.map((portData: PortTemplateDto, index: number) =>
	// 	{
	// 		return (
	// 			<div
	// 				key={index + 1}
	// 				className="selectable"
	// 				onClick={() => this.onPortSelected(portData.id)}
	// 			>
	// 				Link to {portData.label}
	// 			</div>
	// 		);
	// 	}));

	// 	return selectables;
	// }

	private onCameraMove = () => {
		this.forceUpdate();
	};

	private portDataToSelectArea = (portData: PortTemplateDto) => {
		return (
			<React.Fragment key={portData.id}>
				<PortSelectAreaV5
					onClick={this.onPortSelected}
					portId={portData.id}
					leaf={portData.children.length === 0}
				/>
				{portData.children.map(this.portDataToSelectArea)}
			</React.Fragment>
		);
	};

	private getNumberOfRowsForPortData(portData: PortTemplateDto) {
		let numberOfRows: number = 1;

		for (const child of portData.children) {
			numberOfRows += this.getNumberOfRowsForPortData(child);
		}

		return numberOfRows;
	}

	private getNumberOfRowsForPortDataArray(portDataArray: PortTemplateDto[]) {
		let numberOfRows: number = 0;

		for (const portData of portDataArray) {
			numberOfRows += this.getNumberOfRowsForPortData(portData);
		}

		return numberOfRows;
	}

	public override componentWillUnmount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.onCameraMove);
	}

	public override componentDidMount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.onCameraMove);
		this.onCameraMove();
		this.forceUpdate();
	}

	public override render() {
		const {worldX, worldY, spaceViewRenderer, ports, item} = this.props;
		const worldZ = spaceViewRenderer.spaceOffset.z;
		const portSelectAreaHeight = portRowSize * (1 + this.getNumberOfRowsForPortDataArray(ports));

		return (
			<PortSelectorWrapper
				ref={this._ref}
				className="PortSelector floatingDropdown vbox"
				style={THREEUtils.getStyleForFloatingUIElement(worldX, worldY, worldZ, spaceViewRenderer, true, "top", this._ref.current)}
			>
				<div className="hbox alignCenter header">
					Create a link...
					<IconButtonV5
						IconComponent={CloseIcon}
						onClick={spaceViewRenderer.toolManager.linkManager.disableLinkMode}
					/>
				</div>
				<div className="wrapper">
					<PortContainerStyled className="PortContainer">
						<div
							className="vbox"
							style={{position: "relative", top: "7.5px", height: `${firstRowSize}px`}}
						>
							<div
								className="darkSilverText"
								style={{marginBottom: "12.5px"}}
							>
								Hover over the xyicon or a port to start creating a link.
							</div>
							<div
								className="firstRow hbox alignCenter"
								style={{marginBottom: "7.5px"}}
							>
								<div
									className="thumbnail"
									style={{backgroundImage: `url('${item.thumbnail}')`, transform: item.backgroundTransform}}
								></div>
								<div className="bubble flexCenter">Link to Xyicon</div>
							</div>
						</div>
						<PortsV5 item={item} />
					</PortContainerStyled>
					<div
						className="portSelectAreaContainer"
						style={{height: `${portSelectAreaHeight}px`, marginTop: `-${portSelectAreaHeight + 7}px`}}
					>
						<PortSelectAreaV5
							onClick={this.onPortSelected}
							portId={null}
							leaf={true}
						/>
						{ports.map(this.portDataToSelectArea)}
					</div>
				</div>
			</PortSelectorWrapper>
		);
	}
}

const PortSelectorWrapper = styled.div`
	position: absolute;
	width: fit-content;
	min-width: 100px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	left: 0;
	top: 0;
	max-width: initial;
	background: ${colorPalette.white};
	z-index: 1;
	.thumbnail {
		width: 38px;
		height: 38px;
	}

	.wrapper {
		position: relative;
		margin: 5px 0;
		padding: 0 15px;
		min-width: 200px;
		max-width: 475px;
		max-height: 670px;
		overflow: auto;
	}

	.header {
		display: flex;
		justify-content: space-between;
		padding: 0 8px;
		height: 48px;
		background-color: ${colorPalette.gray.c100};
		.button {
			margin-left: auto;
			margin-right: 8px;
		}
	}

	.PortContainer {
		.bubble {
			height: 40px;
			margin-left: 25px;
			background-color: ${colorPalette.primary.c500Primary};
			padding: 10px;
			color: white;
			animation: pulsate 1s ease-out 5;
		}

		.orphanedLinks {
			display: none;
		}
	}

	.PortContainer {
		margin: 0;
		.PortComponent {
			&.leaf {
				.textContainer {
					&.locked {
						opacity: 1;
					}
					&::after {
						filter: brightness(0) invert(1); // make the "lock" item white
					}
					.TextInput {
						background: none;
						color: white;
					}
					background-color: ${colorPalette.primary.c500Primary};
					animation: pulsate 1s ease-out 5;
				}
			}
		}
	}

	.portSelectAreaContainer {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		z-index: 1;

		.PortSelectArea {
			flex: 1;
			width: 100%;
			&:hover {
				&.leaf {
					cursor: pointer;
					background-color: rgba(52, 149, 240, 0.1);
				}
			}
		}
	}
`;

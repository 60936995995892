import type {IViewSort} from "../../../data/models/ViewUtils";
import {SortDirection} from "../../../data/models/ViewUtils";
import type {IFieldPointer} from "../../../data/models/field/Field";
import {fieldEquals} from "../../../data/models/field/FieldUtils";

export const compareTableValues = (a: any, b: any) => {
	if (!a && typeof a !== "number") {
		a = "";
	}
	if (a.toLowerCase) {
		a = a.toLowerCase();
	}

	if (!b && typeof b !== "number") {
		b = "";
	}
	if (b.toLowerCase) {
		b = b.toLowerCase();
	}

	const numberA = Number(a);
	const numberB = Number(b);

	// if both of them are numbers, no need to hack with the regex
	if (!isNaN(numberA) && !isNaN(numberB)) {
		return numberA - numberB;
	}

	a = a.toString();
	b = b.toString();

	const regex = /^[0-9]+.*/gm;

	// if a field value starts with digits, this will sort them as numbers and then every other thing is sorted as string (if there's any)
	if (a.match(regex) && b.match(regex)) {
		let aFirstChar = a.search(/\D/);
		let bFirstChar = b.search(/\D/);

		aFirstChar = aFirstChar === -1 ? a.length : aFirstChar;
		bFirstChar = bFirstChar === -1 ? b.length : bFirstChar;

		const aDigits = Number(a.slice(0, aFirstChar));
		const aChars = a.slice(aFirstChar);
		const bDigits = Number(b.slice(0, bFirstChar));
		const bChars = b.slice(bFirstChar);

		return aDigits > bDigits ? 1 : aDigits < bDigits ? -1 : aChars > bChars ? 1 : aChars < bChars ? -1 : 0;
	}

	return a > b ? 1 : a < b ? -1 : 0;
};

export const getSortCicleNextElement = (sortOrder: SortDirection) => {
	if (sortOrder === SortDirection.ASC) {
		return SortDirection.DESC;
	} else if (sortOrder === SortDirection.DESC) {
		return null;
	} else {
		return SortDirection.ASC;
	}
};

export const toggleTableSort = (sort: IViewSort, field: IFieldPointer) => {
	// probably no need for '?' because sort should be an existing object in every case
	let newDirection = sort?.direction;
	let newColumn = sort?.column;

	if (sort?.column && fieldEquals(sort.column, field)) {
		newDirection = getSortCicleNextElement(sort.direction);
	} else {
		newColumn = field;
		newDirection = SortDirection.ASC;
	}

	return {
		column: newColumn,
		direction: newDirection,
	};
};

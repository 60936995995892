import {computed, observable, makeObservable} from "mobx";
import type {IModel} from "../Model";
import {ObjectType, Permission} from "../../../generated/api/base";
import type {XyiconFeature, PortfolioPermissionSetsDto} from "../../../generated/api/base";

export class PermissionSet implements IModel {
	@observable
	private _data: PortfolioPermissionSetsDto;

	constructor(data: PortfolioPermissionSetsDto) {
		makeObservable(this);
		this._data = data;
	}

	// Used for type or object
	public getObjectPermission(typeId: string, feature: XyiconFeature): Permission {
		const featurePermissions = this._data.featurePermissions?.find((featurePermission) => featurePermission.feature === feature);
		const object = featurePermissions?.objectPermissions.find((o) => o.objectID === typeId);

		return object?.permission ?? Permission.None;
	}

	public getFieldPermission(fieldRefId: string, feature: XyiconFeature): Permission {
		const featurePermissions = this._data.featurePermissions?.find((featurePermission) => featurePermission.feature === feature);
		const fieldPermission = featurePermissions?.fieldPermissions.find((f) => f.fieldRefID === fieldRefId);

		return fieldPermission?.permission ?? Permission.None;
	}

	public getPortfolioFieldPermission(fieldRefId: string): Permission {
		const portfolioFieldPermission = this._data.portfolioFieldPermissions.find((f) => f.fieldRefID === fieldRefId);

		return portfolioFieldPermission?.permission ?? Permission.None;
	}

	public setPortfolioFieldPermission(fieldRefId: string, value: Permission) {
		const portfolioFieldPermission = this._data.portfolioFieldPermissions.find((perm) => perm.fieldRefID === fieldRefId);

		if (portfolioFieldPermission) {
			portfolioFieldPermission.permission = value;
		} else {
			this._data.portfolioFieldPermissions.push({
				fieldRefID: fieldRefId,
				permission: value,
			});
		}
	}

	public setFieldPermission(feature: XyiconFeature, fieldRefId: string, value: Permission) {
		const featurePermissions = this.getFeaturePermissions(feature);

		const fieldPermission = featurePermissions.fieldPermissions.find((o) => o.fieldRefID === fieldRefId);

		if (fieldPermission) {
			fieldPermission.permission = value;
		} else {
			featurePermissions.fieldPermissions.push({
				permission: value,
				fieldRefID: fieldRefId,
			});
		}
	}

	public setObjectPermission(feature: XyiconFeature, typeId: string, value: Permission) {
		const featurePermissions = this.getFeaturePermissions(feature);

		const object = featurePermissions.objectPermissions.find((o) => o.objectID === typeId);

		if (object) {
			object.permission = value;
		} else {
			featurePermissions.objectPermissions.push({
				permission: value,
				objectID: typeId,
				objectType: ObjectType.ObjectType,
			});
		}
	}

	public enableAccess(feature: XyiconFeature, value: boolean) {
		this.getFeaturePermissions(feature).canAccess = value;
	}

	public isAccessEnabled(feature: XyiconFeature) {
		return !!this._data.featurePermissions?.find((featurePermission) => featurePermission.feature === feature)?.canAccess;
	}

	private getFeaturePermissions(feature: XyiconFeature) {
		if (!this._data.featurePermissions) {
			this._data.featurePermissions = [];
		}

		let featurePermission = this._data.featurePermissions.find((featurePermission) => featurePermission.feature === feature);

		if (!featurePermission) {
			featurePermission = {
				feature,
				fieldPermissions: [],
				objectPermissions: [],
				canAccess: true,
			};
			this._data.featurePermissions.push(featurePermission);
		}
		return this._data.featurePermissions.find((featurePermission) => featurePermission.feature === feature);
	}

	public getData() {
		return this._data;
	}

	@computed
	public get id() {
		return this._data.portfolioPermissionSetID;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	public set name(value: string) {
		this._data.name = value;
	}

	public set portfolioPermission(value: Permission) {
		this._data.portfolioPermission = value;
	}

	@computed
	public get portfolioPermission() {
		return this._data.portfolioPermission;
	}

	@computed
	public get portfolioFieldPermissions() {
		return this._data.portfolioFieldPermissions;
	}

	@computed
	public get featurePermissions() {
		return this._data.featurePermissions;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import {IconButton} from "../../../../../widgets/button/IconButton";
import {ReactUtils} from "../../../../../utils/ReactUtils";

type LayerType = "hide" | "lock";

interface IHideAndLockProps {
	type: LayerType;
	active: boolean;
	onChange: (active: boolean) => void;
	isItForModifyAll?: boolean;
}

export function HideAndLock({type, active, isItForModifyAll, onChange}: IHideAndLockProps) {
	const title = type === "hide" ? (active ? "Show" : "Hide") : active ? "Unlock Position" : "Lock Position";

	return (
		<div
			className={ReactUtils.cls("HideAndLock", {modifyAll: isItForModifyAll})}
			onClick={() => onChange(!active)}
		>
			<IconButton
				icon={`${type}${active ? "-active" : ""}`}
				title={title}
			/>
		</div>
	);
}

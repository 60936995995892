import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {Report} from "../../../../../../../data/models/Report";
import {ReportScope} from "../../../../../../../generated/api/reports";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import type {AppState} from "../../../../../../../data/state/AppState";
import type {Portfolio} from "../../../../../../../data/models/Portfolio";
import {MultiSelectInput} from "../../../../../../widgets/input/multiselect/MultiSelectInput";
import {VerticalAlignment} from "../../../../../../../utils/dom/DomUtils";

interface IReportScopeWidgetProps {
	report: Report;
	onChange?: () => void;
	disabled?: boolean;
	appState?: AppState;
}

interface IScopeOption {
	id: ReportScope;
	label: string;
}

@inject("appState")
@observer
export class ReportScopeWidget extends React.Component<IReportScopeWidgetProps> {
	private _scopeOptions: IScopeOption[] = [
		{
			id: ReportScope.CurrentPortfolio,
			label: "Current Portfolio",
		},
		{
			id: ReportScope.PortfolioIDList,
			label: "Portfolio list",
		},
		{
			id: ReportScope.Organization,
			label: "Organization",
		},
	];

	private onChangeScope = (option: IScopeOption) => {
		this.props.report.scope = option.id;
		this.props.onChange?.();
	};

	private onChangePortfolioIDList = (value: Portfolio[]) => {
		this.props.report.portfolioIDList = value.map((p) => p.id);
		this.props.onChange?.();
	};

	private getSelectedOptions = () => {
		return this.props.appState.actions
			.getList(XyiconFeature.Portfolio)
			.filter((option) => this.props.report.portfolioIDList.includes(option.id)) as Portfolio[];
	};

	public override render() {
		let {disabled} = this.props;
		const {report, appState} = this.props;
		const selectedScope = this._scopeOptions.find((option) => option.id === report.scope);

		disabled = disabled || report.reportFeature === XyiconFeature.XyiconCatalog;

		return (
			<>
				<Field label="Scope">
					{disabled ? (
						selectedScope.label
					) : (
						<SelectInput
							sort={false}
							options={this._scopeOptions}
							render={(option) => option.label}
							selected={selectedScope}
							onChange={this.onChangeScope}
							disabled={disabled}
							verticalAlignment={VerticalAlignment.bottomOuter}
						/>
					)}
				</Field>
				{report.scope === ReportScope.CurrentPortfolio && (
					<Field
						label="Portfolio"
						className="text"
					>
						{appState.actions.getCurrentPortfolioName()}
					</Field>
				)}
				{report.scope === ReportScope.Organization && (
					<Field
						label="Organization"
						className="text"
					>
						{appState.actions.getCurrentOrganizationName()}
					</Field>
				)}
				{report.scope === ReportScope.PortfolioIDList && (
					<Field label="List">
						<MultiSelectInput
							options={appState.actions.getList(XyiconFeature.Portfolio) as Portfolio[]}
							render={(option) => option.name}
							selected={this.getSelectedOptions()}
							onChange={this.onChangePortfolioIDList}
							disabled={disabled}
							errorMessage={this.getSelectedOptions().length === 0 && "At least one portfolio should be selected"}
						/>
					</Field>
				)}
			</>
		);
	}
}

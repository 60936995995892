import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {SetScaleLine} from "../../../elements3d/markups/SetScaleLine";
import {SpaceEditorMode} from "../../../renderers/SpaceViewRendererUtils";
import type {DistanceUnitName, ExtendedDistanceUnitName} from "../../../Constants";
import type {Pointer} from "../../../../../../../../utils/interaction/Pointer";
import {SetScaleWindow} from "../../../../../../abstract/popups/SetScaleWindow";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {KeyboardListener} from "../../../../../../../../utils/interaction/key/KeyboardListener";
import {SetScaleWindowV5} from "../../../../../../../5.0/popup/SetScaleWindowV5";
import {ABTool} from "./ABTool";

export class SetScaleTool extends ABTool {
	private _isPopupOpen: boolean = false;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "set-scale");
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				if (!this._isPopupOpen) {
					this._markup?.destroy();
					this._spaceViewRenderer.inheritedMethods.setActiveTool(this._spaceViewRenderer.mode === SpaceEditorMode.NORMAL ? "selection" : "pan");
				}
				break;
		}
	};

	public override activate() {
		const hasChanged = super.activate();

		if (hasChanged) {
			KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
		}

		return hasChanged;
	}

	public override deactivate() {
		const hasChanged = super.deactivate();

		if (hasChanged) {
			KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
		}

		return hasChanged;
	}

	private reset() {
		const isNormalMode = this._spaceViewRenderer.mode === SpaceEditorMode.NORMAL;

		this._markup?.destroy();
		this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		if (!isNormalMode) {
			this._spaceViewRenderer.inheritedMethods.setScale(null, false);
		}
	}

	private getFinalUnitOfMeasure(unitOfMeasureForSpaceType: ExtendedDistanceUnitName): DistanceUnitName {
		const imperialUnits: ExtendedDistanceUnitName[] = ["foot", "foot&inch", "inch"];

		if (unitOfMeasureForSpaceType == null || imperialUnits.includes(unitOfMeasureForSpaceType)) {
			return "inch";
		} else if (unitOfMeasureForSpaceType === "meter") {
			return "centimeter";
		}

		return "millimeter";
	}

	protected override onPointerUpCallback = async (pointer: Pointer, worldX: number, worldY: number) => {
		const isNormalMode = this._spaceViewRenderer.mode === SpaceEditorMode.NORMAL;

		const ABWorldDistance = THREEUtils.calculateDistance(this._markup.data.geometryData || []);

		if (ABWorldDistance > 0) {
			this._isPopupOpen = true;
			const {unitOfMeasure} = this._spaceViewRenderer.space.type.settings;
			const finalUnitOfMeasure: DistanceUnitName = this.getFinalUnitOfMeasure(unitOfMeasure);
			const ABDistance =
				this._spaceViewRenderer.transport.appState.currentUIVersion === "5.0"
					? await SetScaleWindowV5.open(!isNormalMode, "", "Distance between A and B?", undefined, finalUnitOfMeasure, {
							parentElement: this._spaceViewRenderer.domElement.parentElement.parentElement,
						})
					: await SetScaleWindow.open(!isNormalMode, "", "Distance between A and B?", undefined, finalUnitOfMeasure, {
							parentElement: this._spaceViewRenderer.domElement.parentElement.parentElement,
						});

			this._isPopupOpen = false;

			if (ABDistance) {
				(this._markup as SetScaleLine).showDistance(ABDistance.value, ABDistance.unit);

				const valueInMeter = ABDistance.value * ABDistance.unit.multiplicator;
				const spaceUnitsPerMeter = ABWorldDistance / valueInMeter;

				console.log(`SpaceUnitsPerMeter: ${spaceUnitsPerMeter}`);

				this._spaceViewRenderer.inheritedMethods.setScale(spaceUnitsPerMeter, ABDistance.applyToAllSpaces);

				this._markupManager.add([this._markup], false);
				this._markup = null;
			} else {
				this.reset();
			}
		} else {
			this.reset();
		}
	};
}

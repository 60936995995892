import * as React from "react";
import {Constants} from "../../../space/spaceeditor/logic3d/Constants";
import type {ISliderProps} from "./Slider";
import {Slider} from "./Slider";

interface ILogarithmicSliderState {
	handlePos: number;
}

/**
 * General purpose slider with exponential (or logarithmic) scaling (geometric progression)
 */

export class LogarithmicSlider extends React.Component<ISliderProps, ILogarithmicSliderState> {
	private _timeoutId: number = -1;

	constructor(props: ISliderProps) {
		super(props);
		this.state = {
			handlePos: null,
		};
	}

	private setHandlePos = (newValue: number) => {
		const min = this.props.min;
		const max = this.props.max;

		const newZoomLevel = Math.pow(max / min, newValue) * min;

		this.props.onValueChange(newZoomLevel);

		clearTimeout(this._timeoutId);

		this.setState({
			handlePos: newValue,
		});

		this._timeoutId = window.setTimeout(() => {
			this.setState({handlePos: null});
		}, Constants.DURATIONS.DEFAULT_ANIMATION);
	};

	private getDragPos() {
		/**
		 * Putting the zoom level itself into a "linearly progressing" spectrum gives strange results. Details below:
		 *
		 * Eg.: We can go from the minimum zoom level to the maximum in 26 mousewheels.
		 * At the 13th mousewheel we would expect the draghandle to be in the center, but it's a lot closer to the minimum,
		 * because `zoom` is not a linear variable, because of the nature of its progression (multiplication instead addition), eg.: zoom *= 1.1;
		 *
		 * See https://en.wikipedia.org/wiki/Geometric_progression
		 * an = a1 * q^(n-1)
		 *
		 * We'll use `n` instead `(n-1)`, because we index from 0
		 *
		 * n = ?
		 *
		 * an = zoom.max or zoom.current,
		 * a1 = zoom.min, we actually use a0, because we index from 0
		 * q = zoomSpeed
		 * n = stepsNeededToReachCurrentFromMin = ?
		 */

		//const q = this._pointerControls.zoomSpeed;
		const stepsNeededToReachCurrentFromMin = Math.log(this.props.value / this.props.min); // / Math.log(q) + 1 not needed, because it doesn't affect the ratio and we index from 0
		const stepsNeededToReachMaxFromMin = Math.log(this.props.max / this.props.min); // / Math.log(q) + 1 not needed, because it doesn't affect the ratio and we index from 0

		const ratio = stepsNeededToReachCurrentFromMin / stepsNeededToReachMaxFromMin;

		return ratio;
	}

	public override render() {
		const dragHandlePos = this.state.handlePos === null ? this.getDragPos() : this.state.handlePos;

		return (
			<Slider
				width={this.props.width}
				value={dragHandlePos}
				onValueChange={this.setHandlePos}
				onPointerUp={this.props.onPointerUp}
				largeStepValue={this.props.largeStepValue}
				dataLabel={this.props.dataLabel}
				title={this.props.title}
				classNames={this.props.classNames}
				arrows={this.props.arrows}
			/>
		);
	}
}

import styled from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {FlexCenterStyle, fontSize, fontWeight, radius} from "../styles/styles";

export const UserStaticPageWrapperStyled = styled.div`
	${FlexCenterStyle}
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;

	.preLoadingPage {
		text-align: center;
	}

	.UserStaticPage {
		width: 500px;
		margin: auto;
		box-sizing: content-box;
		background: white;
		border-radius: 24px;
		box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);

		&.invalidLink {
			width: 709px;
		}

		.Notification {
			top: 25px;
			position: fixed;
		}

		.shadow {
			position: fixed;
			background: rgba(0, 0, 0, 0.5);
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
		}

		.desc {
			font-size: ${fontSize.md};
			color: ${colorPalette.gray.c950};
			margin: 0 0 16px 0;
		}

		.fieldContainer {
			position: relative;
			opacity: 1;
			transition: ease-in-out opacity 0.4s;
			svg {
				width: 16px;
				height: 16px;
			}

			.confirm {
				width: 100%;
				height: 32px;
				font-size: 16px;
				background-color: #1e88e5;
				color: white;
				border: none;
				border-radius: ${radius.sm};
				margin: 32px 0 8px 0;

				&.disabled {
					background: ${colorPalette.gray.c300};
					color: white;
				}
			}

			.textLink {
				color: #3495f0;
			}

			&.loading {
				opacity: 0;
				transition: ease-in-out opacity 0.4s;
			}

			.Button {
				margin-top: 8px;
			}
		}
	}

	.ForgottenPasswordReset {
		&.expiredForgottenPasswordReset {
			width: 816px;
		}
		.fieldContainer {
			.btns {
				align-self: center;

				.Button {
					margin-left: 10px;
					margin-top: 24px;

					&.disabled {
						background: ${colorPalette.gray.c300};
						color: ${colorPalette.white};
					}
				}
			}
		}
	}
`;

export const PasswordValidatorStyled = styled.div`
	margin: 8px 0;
	width: auto;

	.pwddRequirementsMessage {
		font-size: ${fontSize.sm};
		color: ${colorPalette.gray.c700Dark};
	}
`;

export const LoginWindowStyled = styled.div`
	width: 100vw;
	height: 100vh;
	${FlexCenterStyle};
	align-items: center;
	justify-content: center;
	background: ${colorPalette.gray.c100};
`;

export const FormContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	background: ${colorPalette.white};
	border-radius: 24px;
	box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
	padding: 48px;

	&.login {
		width: 396px;
		height: 464px;
	}

	&.newuserreg {
		padding: 32px;
	}

	.title {
		font-size: ${fontSize.xl};
		font-weight: ${fontWeight.bold};
		padding-bottom: 8px;

		&.loginTitel {
			font-size: 32px;
			font-weight: ${fontWeight.normal};
		}
	}

	.labelName {
		font-size: ${fontSize.md};
		padding-bottom: 4px;
		color: ${colorPalette.gray.c950};

		&.help {
			a {
				color: ${colorPalette.primary.c500Primary};
				font-size: 14px;
				font-weight: 400;
				text-decoration: none;
				line-height: 16px;
				height: 16px;
				justify-content: flex-end;
				display: flex;
				gap: 4px;

				p {
					transform: translate(0px, 1px);
				}
			}

			svg {
				width: 16px;
				height: 16px;

				path {
					stroke: ${colorPalette.primary.c500Primary};
				}
			}

			&:hover {
				p {
					color: ${colorPalette.primary.c700Dark};
				}

				svg path {
					stroke: ${colorPalette.primary.c700Dark};
				}
			}
		}
	}

	.welcomeMsg {
		margin-bottom: 8px;
	}

	.xyicon-logo {
		width: 150px;
		height: 50px;
		margin-bottom: 8px;
	}

	.description {
		font-size: ${fontSize.md};
		text-align: center;
		color: #727272;

		.support {
			color: black;
		}
	}

	.description-forgotpswd {
		font-size: ${fontSize.md};
		color: #727272;
	}

	.Field {
		${FlexCenterStyle}

		label {
			min-width: 160px;
		}

		.check-icon {
			color: ${colorPalette.positive.c500Primary};
			position: absolute;
			right: 95px;
			top: 25px;
		}
	}

	.inputGroup {
		position: relative;
		padding-top: 16px;

		input::placeholder {
			color: ${colorPalette.gray.c300};
			line-height: 16px;
			font-size: 14px;
		}

		input {
			border-radius: ${radius.sm};
			border: 1px solid #eaeaea;
			background-color: ${colorPalette.white};
			height: 32px;
			width: 100%;
			font-size: ${fontSize.md};
			line-height: 16px;
			color: ${colorPalette.gray.c950};
			resize: none;
			outline: none;

			&.email {
				background-color: ${colorPalette.gray.c100};
			}
		}

		input:focus {
			border-color: ${colorPalette.primary.c500Primary};
		}

		.forgotPassword {
			cursor: pointer;
			padding-top: 8px;
			color: ${colorPalette.primary.c500Primary};
		}

		.forgotPassword:hover {
			color: ${colorPalette.primary.c700Dark};
		}

		&.login {
			svg {
				position: absolute;
				top: 45px;
				left: 8px;
			}
		}

		&.rememeberme {
			left: 6px;
			color: ${colorPalette.gray.c950};
			font-size: ${fontSize.md};

			label {
				width: 100%;
				margin: 0;
				justify-content: left;
			}

			input[type="checkbox"] + label::before {
				margin: 0 8px 0 -6px;
				position: relative;
			}
		}

		&.password {
			.check-icon {
				color: ${colorPalette.positive.c500Primary};
				position: absolute;
				right: 40px;
				top: 42px;
			}
		}
	}

	.helpbutton {
		align-self: end;
		padding: 0;
		color: ${colorPalette.primary.c500Primary};

		.IconButton {
			height: 12px;

			&:hover {
				background-color: transparent;
			}
		}

		.label {
			margin: 0;
		}
	}

	.username {
		&.errorInfo {
			right: 35px;
		}

		.InfoButtonV5 {
			position: absolute;
			margin: 0;
			top: 15px;
			left: 135px;
			color: ${colorPalette.primary.c500Primary};
		}
	}

	.forgotPassword {
		cursor: pointer;
		padding-top: 8px;
	}

	form {
		width: 100%;
		position: relative;

		button.Button {
			width: 100%;
			height: 32px;
			font-size: ${fontSize.lg};
			background-color: ${colorPalette.primary.c500Primary};
			color: ${colorPalette.white};
			border: none;
			border-radius: ${radius.sm};
			margin: 32px 0;
			cursor: pointer;
		}

		input {
			padding: 8px;
		}

		button.Button .loading {
			margin-left: 110px;
		}

		button.Button .loading div .loader .dots div {
			background: ${colorPalette.white};
		}
	}

	.errorMessage {
		text-align: center;
		position: absolute;
		width: 100%;
		top: 320px;
		font-size: ${fontSize.md};
	}
`;

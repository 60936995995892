import {Signal} from "../signal/Signal";
import type {ISignalP1} from "../signal/ISignalP1";
import {DomUtils} from "../dom/DomUtils";

/**
 *
 * Detects a resize event for any kind of element.
 * (Except for HTMLCanvasElement unfortunately)
 * This will dispatch an event if the element is resized by code?
 *
 * http://www.backalleycoder.com/2013/03/18/cross-browser-event-based-element-resize-detection/
 *
 * TODO add support for ResizeObserver:
 * https://developers.google.com/web/updates/2016/10/resizeobserver
 *
 */
export class ResizeDetector {
	public static addedToDom = Signal.create();

	public resize: ISignalP1<ResizeDetector> = new Signal();

	private _element: HTMLElement;

	private _obj: HTMLObjectElement;
	private _previousPosition: string;

	constructor(element: HTMLElement) {
		this._element = element;

		this.init();
	}

	private init() {
		// If the position is static -> make it relative so a full sized object
		// can be added inside it.

		// Note: computed position might be falsy when _element is not yet added to the DOM
		const computedPosition = getComputedStyle(this._element).position;

		if (computedPosition === "static" || !computedPosition) {
			this._previousPosition = computedPosition;
			this._element.style.position = "relative";
		}

		// we use an object that has a write event
		this._obj = document.createElement("object");

		this._obj.onload = this.onObjectLoad;

		this._obj.setAttribute(
			"style",
			"display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;",
		);

		this._obj.type = "text/html";
		this._obj.data = "about:blank";

		this._element.appendChild(this._obj);

		// resize doesnt trigger when we enter fullscreen
		document.addEventListener("fullscreenchange", (event) => {
			if (document.fullscreenElement !== null) {
				setTimeout(this.onResize, 40);
			}
		});
	}

	private onObjectLoad = () => {
		this._obj.contentDocument.defaultView.addEventListener("resize", this.onResize);
	};

	private onResize = () => {
		// TODO throttle

		this.resize.dispatch(this);
	};

	public dispose() {
		DomUtils.detach(this._obj);
		this._obj.contentDocument?.defaultView.removeEventListener("resize", this.onResize);

		if (this._previousPosition !== undefined) {
			this._element.style.position = this._previousPosition;
		}
	}
}

import type {TransportLayer} from "../TransportLayer";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import type {XyiconFeature} from "../../generated/api/base";
import type {ILayoutDefinition, ISectionField} from "../../ui/modules/settings/modules/layout/LayoutSettings";

export class LayoutService {
	private _transport: TransportLayer;

	constructor(transportLayer: TransportLayer) {
		this._transport = transportLayer;
	}

	public async refreshLayouts() {
		const {result: layouts, error} = await this._transport.requestForOrganization<{feature: XyiconFeature; layoutDefinition: ILayoutDefinition}[]>({
			url: "fieldlayouts/all",
			method: XHRLoader.METHOD_GET,
		});

		if (layouts) {
			for (const layout of layouts) {
				this._transport.appState.layouts[layout.feature] = layout.layoutDefinition || {sections: []};

				// Fix for corrupt data coming from backend
				for (const section of this._transport.appState.layouts[layout.feature].sections) {
					const validFields: ISectionField[] = section.fields.filter((field) => this._actions.getFieldByRefId(field.id));

					if (validFields.length !== section.fields.length) {
						section.fields = validFields;
						console.warn("Corrupt data from fieldlayouts/all api");
					}
				}
			}
		}
	}

	private get _actions() {
		return this._transport.appState.actions;
	}

	public async updateLayout(layoutDefinition: ILayoutDefinition, feature: XyiconFeature) {
		const params = {
			feature: feature,
			layoutDefinition: layoutDefinition,
		};

		const {result, error} = await this._transport.requestForOrganization({
			url: "fieldlayouts/update",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		try {
			const layout = result.layoutDefinition as ILayoutDefinition;

			if (layout) {
				this._transport.appState.layouts[feature] = layout;
			}
		} catch (e) {
			console.warn(e);
		}
	}
}

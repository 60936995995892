import {useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import type {Boundary} from "../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import type {Catalog} from "../../../data/models/Catalog";
import type {Xyicon} from "../../../data/models/Xyicon";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {Functions} from "../../../utils/function/Functions";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {CardLayoutToolTip} from "../../modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {ReactUtils} from "../../utils/ReactUtils";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {useAppStore} from "../../../StateManager";
import {FlexCenterStyle} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import DogearFavIcon from "../../5.0/icons/dogear-fav.svg?react";
import {InfoBubbleV5} from "../button/InfoBubbleV5";

interface ISpaceItemV5Props {
	item: Catalog | Xyicon | Boundary | BoundarySpaceMap;
	className?: string;
	queryString: string;
	onPointerDown: (pointer: Pointer) => void;
	onPointerMove: (pointer: Pointer) => void;
	onPointerUp: (pointer: Pointer) => void;
}

SpaceItemV5.defaultProps = {
	className: "",
	onPointerDown: Functions.emptyFunction,
	onPointerMove: Functions.emptyFunction,
	onPointerUp: Functions.emptyFunction,
};

let _timeOutId: number = null;

export function SpaceItemV5(props: ISpaceItemV5Props) {
	const store = useAppStore();
	const {appState} = store;
	const {item, queryString} = props;
	const _parent = useRef<HTMLDivElement>();
	const _floating = useRef<HTMLDivElement>();

	const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
	const [toolTipTransform, setToolTipTransform] = useState<TransformObj | null>(null);

	function getEmbeddedCounter() {
		if (item.ownFeature === XyiconFeature.Xyicon) {
			const embeddedNumber = item.embeddedXyicons.length;

			if (embeddedNumber > 0) {
				return <div className="embeddedCounter">{embeddedNumber}</div>;
			}
		}

		return null;
	}

	function getFavoriteIcon() {
		if (item.ownFeature === XyiconFeature.XyiconCatalog && item.isFavorite) {
			return <DogearFavIcon style={{width: 24, position: "absolute", top: -4, left: -1}} />;
		}

		return null;
	}

	useEffect(() => {
		if (_parent.current && _floating.current) {
			setToolTipTransform(
				DomUtils.getFixedFloatingElementPosition(_parent.current, _floating.current, VerticalAlignment.topOuter, HorizontalAlignment.center),
			);
		}
	}, [isToolTipOpen]);

	const openTooltip = () => {
		clearTimeout(_timeOutId);
		_timeOutId = window.setTimeout(() => setIsToolTipOpen(true), 1000);
	};

	const closeTooltip = () => {
		clearTimeout(_timeOutId);
		setIsToolTipOpen(false);
	};

	function renderCustomRow(data: {key: string; value: string}) {
		let html = "";

		if (data.value !== "") {
			html = data.key + (data.key !== "" ? ": " : "") + StringUtils.regexHighlight(data.value, props.queryString);
		}

		return (
			<div
				className="typeName"
				dangerouslySetInnerHTML={{__html: html}}
			/>
		);
	}

	function userXyiconPermission() {
		return appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);
	}

	function hasSpaceItemV5Permission() {
		return item.ownFeature === XyiconFeature.Boundary || userXyiconPermission() > Permission.View;
	}

	const rows = appState.actions.getRowsForCardLayout(queryString, item);
	const inlineStyle: React.CSSProperties = {
		position: "absolute",
		left: "0",
		top: "0",
		transform: toolTipTransform?.translate,
		zIndex: 999,
		visibility: toolTipTransform?.translate ? "visible" : "hidden",
	};

	return (
		<PointerDetectorReact
			onDown={hasSpaceItemV5Permission && props.onPointerDown}
			onMove={hasSpaceItemV5Permission && props.onPointerMove}
			onUp={hasSpaceItemV5Permission && props.onPointerUp}
		>
			<SpaceItemStyled
				$grabbable={props.onPointerMove !== Functions.emptyFunction && hasSpaceItemV5Permission()}
				$noPermission={!hasSpaceItemV5Permission()}
				data-id={item.id}
				ref={_parent}
				onMouseOver={openTooltip}
				onMouseLeave={closeTooltip}
			>
				<ThumbnailContainerStyled>
					<ThumbnailStyled
						$backgroundImage={`url('${item.thumbnail}')`}
						$transform={(item as Xyicon).backgroundTransform || ""}
					/>
					{getEmbeddedCounter()}
					{getFavoriteIcon()}
				</ThumbnailContainerStyled>
				<div className="description vbox">
					<div className="typeName">{renderCustomRow(rows[0])}</div>
					{renderCustomRow(rows[1])}
					{renderCustomRow(rows[2])}
				</div>
				{isToolTipOpen && (
					<DomPortal destination={appState.app.modalContainer}>
						{hasSpaceItemV5Permission() ? (
							<CardLayoutToolTip
								item={item}
								divRef={_floating}
								style={inlineStyle}
								className={ReactUtils.cls({left: toolTipTransform?.horizontal === HorizontalAlignment.left})}
								queryString={queryString}
							/>
						) : (
							<InfoBubbleV5
								divRef={_floating}
								content={
									<>
										<SVGIcon icon="locked" />
										<div>You do not have permission to use this catalog item.</div>
									</>
								}
								style={inlineStyle}
								className="SpaceItemV5"
							/>
						)}
					</DomPortal>
				)}
			</SpaceItemStyled>
		</PointerDetectorReact>
	);
}

const SpaceItemStyled = styled.div<{$grabbable: boolean; $noPermission: boolean}>`
	${FlexCenterStyle};
	gap: 8px;
	flex: 1;
	.description {
		max-width: calc(100% - 48px);

		> div,
		.typeName {
			width: 100%;
			overflow: hidden;
			color: ${colorPalette.gray.c500Primary};
			font-size: 12px;
			text-overflow: ellipsis;
			white-space: nowrap;

			&:nth-child(1) {
				color: var(--icon);
				font-weight: initial;
			}

			&:first-child {
				font-size: 14px;
				color: ${colorPalette.gray.c900};
			}
		}
	}
`;

const ThumbnailContainerStyled = styled.div`
	width: 40px;
	height: 40px;
	position: relative;

	.dogear {
		position: absolute;
		top: -2px;
		fill: #f2c010;
	}

	.embeddedCounter {
		position: absolute;
		top: -4px;
		right: -5px;
		background-color: #37474f;
		color: white;
		border: 1px solid white;
		padding: 3px;
		border-radius: 100%;
		min-width: 20px;
		height: 20px;
		font-size: x-small;
		text-align: center;
	}
`;

const ThumbnailStyled = styled.div<{$backgroundImage: string; $transform: string}>`
	width: 100%;
	height: 100%;
	${(props) => {
		return css`
			background-image: ${props.$backgroundImage};
			transform: ${props.$transform};
			background-size: contain;
		`;
	}};
	background-repeat: no-repeat;
`;

import * as React from "react";

export interface IWrapperProps<TChild = HTMLElement> {
	children: React.ReactElement<TChild> & {ref?: React.RefObject<TChild>};
}

export abstract class Wrapper<TProps extends IWrapperProps, TChild extends HTMLElement> extends React.Component<TProps> {
	protected _child: React.RefObject<TChild>;

	constructor(props: TProps) {
		super(props);

		if (React.Children.count(this.props.children) !== 1) {
			throw new Error("PointerDetector should receive exactly 1 child!");
		}

		this._child = (this.props.children.ref as React.RefObject<TChild>) || React.createRef<TChild>();
	}

	public override render() {
		if (this.props.children.ref) {
			return this.props.children;
		}

		return {...this.props.children, ref: this._child};
	}

	public override componentDidMount() {
		const child = this._child.current;

		if (child) {
			this.onChildMounted(child);
		}
	}

	protected abstract onChildMounted(child: TChild): void;
}

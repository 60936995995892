import {inject, observer} from "mobx-react";
import * as React from "react";
import {computed, makeObservable} from "mobx";
import {PromptWindow} from "../../popups/PromptWindow";
import type {XyiconFeature} from "../../../../../generated/api/base";
import {Permission} from "../../../../../generated/api/base";
import type {IViewSort, ViewChangeType} from "../../../../../data/models/ViewUtils";
import {View} from "../../../../../data/models/View";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {MultiActionButton} from "../../../../widgets/button/multiaction/MultiActionButton";
import {notify} from "../../../../../utils/Notify";
import {NotificationType} from "../../../../notification/Notification";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";

interface ISaveToViewButtonProps {
	appState?: AppState;
	transport?: TransportLayer;
	feature: XyiconFeature;
	viewChangeType: ViewChangeType;
	manageColumns?: boolean;
	noIcon?: boolean;
	onSaveClicked?: () => void;
}

interface ISaveToViewButtonState {
	disabled: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class SaveToViewButton extends React.Component<ISaveToViewButtonProps, ISaveToViewButtonState> {
	private _isMounted: boolean = false;

	constructor(props: ISaveToViewButtonProps) {
		super(props);
		makeObservable(this);
		this.state = {
			disabled: false,
		};
	}

	private onSaveAsNewViewClick = async () => {
		const view = this.selectedView;
		const name = await PromptWindow.open("Please enter the name for the new View", "New View", `${view.name} - Copy`, `${view.name} - Copy`);

		if (name) {
			await this.saveNewView(name);
			this.props.onSaveClicked?.();
		}
	};

	private onSaveToViewClick = async () => {
		await this.saveView();
		this.props.onSaveClicked?.();
	};

	@computed
	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	@computed
	private get newData() {
		return this.selectedView.getNewDataForType(this.props.viewChangeType);
	}

	private saveNewDataToView(viewToSave: View) {
		const newData = this.newData as any;

		switch (this.props.viewChangeType) {
			case "captions":
				viewToSave.spaceEditorViewSettings.captions = newData;
				break;
			// Save columns and column sorts together
			case "columns":
			case "column sorts":
				viewToSave.setSerializedColumns(JSON.stringify(this.selectedView.getNewDataForType("columns")));
				viewToSave.setSorts(this.selectedView.getNewDataForType("column sorts") as IViewSort[]);
				break;
			case "conditional formatting":
				viewToSave.spaceEditorViewSettings.formattingRules = newData;
				break;
			case "filters":
				viewToSave.setFilters(newData);
				break;
			case "layers":
				viewToSave.spaceEditorViewSettings.layers = newData;
				break;
		}
	}

	// Overwrites selectedView's data with viewToResetTo's data
	private resetDataForSelectedView(viewToResetTo: View) {
		const selectedView = this.selectedView;

		selectedView.setFilters(viewToResetTo.filters);
		selectedView.setSerializedColumns(viewToResetTo.getSerializedColumns());

		// Only SpaceEditor Views have this property
		if (selectedView.spaceEditorViewSettings) {
			selectedView.spaceEditorViewSettings.captions = viewToResetTo.spaceEditorViewSettings.captions;
			selectedView.spaceEditorViewSettings.formattingRules = viewToResetTo.spaceEditorViewSettings.formattingRules;
			selectedView.spaceEditorViewSettings.layers = viewToResetTo.spaceEditorViewSettings.layers;
		}
	}

	private async saveNewView(newViewName: string) {
		this.setState({
			disabled: true,
		});

		const {appState, transport, feature} = this.props;
		const selectedView = this.selectedView;

		const currentData = selectedView.getData(); //get all of selectedView's data
		const savedView = new View(currentData, appState);

		// Save only the data that is associated with the viewDataType,
		// But after creating the new view, reset the state of selected view before selecting the newly created view
		this.saveNewDataToView(savedView);

		const dataToSave = ObjectUtils.deepClone(savedView.getData());

		dataToSave.viewSharingSettings.length = 0;

		dataToSave.name = newViewName;
		dataToSave.ownerUserID = appState.user?.id || "";

		const {result: viewData, error} = await transport.services.view.create(dataToSave, feature);

		if (viewData?.viewID) {
			// Reset the data of the selected view
			const savedView = new View(currentData, appState);

			this.resetDataForSelectedView(savedView);

			// Select the newly created view
			appState.actions.selectViewById(viewData.viewID);
		} else if (error) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Error,
				title: "Error",
				description: error.ErrorMessage || "Unknown error",
			});
		}

		if (this._isMounted) {
			this.setState({
				disabled: false,
			});
		}
	}

	private async saveView() {
		this.setState({
			disabled: true,
		});

		const {appState, transport} = this.props;
		const selectedView = this.selectedView;
		const currentData = selectedView.getData();
		const savedData = selectedView.getSavedData();
		const savedView = new View(savedData, appState);

		// Save only the data that is associated with the viewDataType,
		// But after the update-on-the-backend, update the view with the rest of the (unsaved) modifications
		this.saveNewDataToView(savedView);
		this.saveNewDataToView(selectedView);

		const dataToSave = savedView.getData();

		await transport.services.view.update(dataToSave);

		// Update the view with the rest of the (unsaved) modifications as well, but don't save them
		const currentView = new View(currentData, appState);

		this.resetDataForSelectedView(currentView);

		if (this._isMounted) {
			this.setState({
				disabled: false,
			});
		}
	}

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override componentWillUnmount() {
		this._isMounted = false;
	}

	public override render() {
		const view = this.selectedView;

		// in case of manageColumns we don't want to detect column width change in that case
		if (view.hasUnsavedChanges(this.props.viewChangeType, this.props.manageColumns)) {
			const viewPermission = view.getPermission();
			const canEdit = !view.isSystem && view.id && viewPermission >= Permission.Update;

			return (
				<MultiActionButton
					className="secondary"
					icon={!this.props.noIcon && "save"}
					disabled={this.state.disabled}
					options={[
						...(canEdit
							? [
									{
										id: "save",
										label: "Save to View",
										onClick: this.onSaveToViewClick,
									},
								]
							: []),
						{
							id: "saveAsNew",
							label: "Save as New",
							onClick: this.onSaveAsNewViewClick,
						},
					]}
				/>
			);
		}

		return null;
	}
}

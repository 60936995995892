import type {MouseEvent, ReactElement} from "react";
import {cloneElement, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {useAppStore} from "../../../StateManager";
import {useClickOutside} from "../utils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {colorPalette} from "../styles/colorPalette";
import {radius, zIndex} from "../styles/styles";
import {ReactUtils} from "../../utils/ReactUtils";
import {DropdownOptionsV5} from "./DropdownOptionsV5";
import type {IDropdownOption, ISubOptionWrapper} from "./DropdownOptionsV5";
import {IconButtonStyled} from "./IconButtonV5";

interface IDropdownButtonProps {
	readonly button: ReactElement;
	readonly options: (IDropdownOption | ISubOptionWrapper)[];
	readonly className?: string;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly optionsZIndex?: number;
	readonly width?: string;
	readonly changeAlignmentOnPositionCorrection?: boolean;
	readonly style?: React.CSSProperties;
	readonly parentStyle?: React.CSSProperties;
	readonly showSearch?: boolean;
	readonly closeOnMouseLeave?: boolean;
	readonly onClick?: () => void;
}

export const DropdownButtonV5 = ({
	button,
	options,
	className = "",
	horizontalAlignment = HorizontalAlignment.center,
	verticalAlignment = VerticalAlignment.bottomOuter,
	offsetX = 0,
	offsetY = 8,
	width = null,
	optionsZIndex = zIndex.dropdowns,
	changeAlignmentOnPositionCorrection = true,
	showSearch = false,
	style,
	parentStyle = {},
	closeOnMouseLeave = false,
	onClick,
}: IDropdownButtonProps) => {
	const appState = useAppStore((state) => state.appState);
	const modalContainer = appState.app.modalContainer;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [optionsTransform, setOptionsTransform] = useState<TransformObj | null>(null);
	const _container = useRef<HTMLDivElement>();
	const _floatingElement = useRef<HTMLDivElement>();

	const onToggle = (event: MouseEvent) => {
		event.stopPropagation();
		onClick?.();

		const newValue = !isOpen;

		setIsOpen(newValue);

		if (!newValue) {
			setOptionsTransform(null);
		}
	};

	const onClose = () => {
		setIsOpen(false);
		setOptionsTransform(null);
	};

	useClickOutside([_container, _floatingElement], onClose);

	useEffect(() => {
		if (isOpen && _container.current && _floatingElement.current) {
			setOptionsTransform(
				DomUtils.getFixedFloatingElementPosition(
					_container.current,
					_floatingElement.current,
					verticalAlignment,
					horizontalAlignment,
					offsetY,
					offsetX,
					changeAlignmentOnPositionCorrection,
				),
			);
		}
	}, [isOpen, horizontalAlignment, verticalAlignment, offsetX, offsetY, changeAlignmentOnPositionCorrection]);

	const buttonClone = cloneElement(button);

	const inlineStyle: React.CSSProperties = {
		transform: optionsTransform?.translate,
		visibility: optionsTransform ? "visible" : "hidden",
		width: width ? width : "inherit",
		...style,
	};

	return (
		<DropdownButtonStyled
			ref={_container}
			className={ReactUtils.cls(className || "", {isOpen})}
			onClick={onToggle}
			style={parentStyle}
		>
			{buttonClone}
			{isOpen && (
				<DomPortal destination={modalContainer}>
					<DropdownOptionsV5
						className={`${className}_options`}
						divRef={_floatingElement}
						style={inlineStyle}
						options={options}
						onClose={onClose}
						optionsZIndex={optionsZIndex}
						showSearch={showSearch}
						closeOnMouseLeave={closeOnMouseLeave}
					/>
				</DomPortal>
			)}
		</DropdownButtonStyled>
	);
};

export const DropdownButtonStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 32px;
	border-radius: ${radius.sm};
	&:hover,
	&.isOpen {
		background-color: ${colorPalette.gray.c200Light};
		${IconButtonStyled} {
			background-color: initial;
		}
	}

	&.borderButton {
		color: ${colorPalette.primary.c500Primary};
		border: 1px solid ${colorPalette.primary.c500Primary};
	}
`;

import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Button} from "../../widgets/button/Button";
import {SVGIcon} from "../../widgets/button/SVGIcon";

export interface ISettingOptionProps {
	label: string;
	children?: React.ReactElement<Button> | React.ReactElement<Button>[];
	open: boolean;
	selected: boolean;
	onClick: () => void;
	group?: boolean;
	isParentOpened?: boolean;
}

export class SettingOption extends React.PureComponent<ISettingOptionProps> {
	private onClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.props.onClick();
	};

	public override render() {
		const {selected, group, label, isParentOpened} = this.props;
		const children = React.Children.toArray(this.props.children) || [];

		return (
			<div
				onClick={this.onClick}
				className={ReactUtils.cls("SettingOption", {
					selected,
					group,
				})}
				data-cy={`SettingOption.${label}`}
			>
				<span className="label">
					{label}
					{group && (
						<>
							{isParentOpened ? (
								<span className="icon down" />
							) : (
								<SVGIcon
									icon="right"
									classNames="icon right"
								/>
							)}
						</>
					)}
				</span>
				{children}
			</div>
		);
	}
}

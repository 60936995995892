import * as React from "react";
import {TextInput} from "../../../../../widgets/input/text/TextInput";

export interface IStringParamProps {
	param: string;
	onChange(value: string): void;
	onFocus?: (value: boolean) => void;
}

export class StringParam extends React.Component<IStringParamProps> {
	private onFocus = () => {
		this.props.onFocus?.(true);
	};

	private onBlur = () => {
		this.props.onFocus?.(false);
	};

	public override render() {
		const {param, onChange} = this.props;

		return (
			<TextInput
				value={param || ""}
				onInput={(value) => onChange(value)}
				onClick={this.onFocus}
				onBlur={this.onBlur}
			/>
		);
	}
}

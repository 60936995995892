import type {Lambda} from "mobx";
import * as React from "react";
import styled, {css} from "styled-components";
import {observer} from "mobx-react";
import {observe, reaction} from "mobx";
import type {SpaceViewRenderer} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {SelectionToolType, SpaceTool} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {IIconConfig} from "../../../modules/catalog/create/CatalogTypes";
import type {Catalog} from "../../../../data/models/Catalog";
import type {IModel, ISpaceItemModel} from "../../../../data/models/Model";
import type {RescaleSpaceRequest, SpaceFileDto, SpaceFileInsertionInfo, UpdateSpaceFileRequest} from "../../../../generated/api/base";
import type {DockableTitle} from "../../../modules/space/spaceeditor/ui/toolbar/Dockable";
import type {Boundary} from "../../../../data/models/Boundary";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {IFilterState} from "../../../../data/models/filter/Filter";
import {arrayOfSelectionToolTypes} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {CatalogIconType, XyiconFeature} from "../../../../generated/api/base";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {Functions} from "../../../../utils/function/Functions";
import {SpaceEditorMode} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {FlexCenter, VerticalFlexStyle} from "../../styles/styles";
import {SplitterV5} from "../../../5.0/layout/SplitterV5";
import {FilterEditorV5} from "../../filter/FilterEditorV5";
import {CatalogItemPanelV5} from "../../abstract/view/catalog/CatalogItemPanelV5";
import {DetailsTabV5} from "../../details/DetailsTabV5";
import {Panel} from "../../abstract/Panel";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import FilterIcon from "../../../5.0/icons/filter-icon.svg?react";
import LabelsIcon from "../../../5.0/icons/label-icon.svg?react";
import FormattingIcon from "../../../5.0/icons/formatting-icon.svg?react";
import LayersIcon from "../../../5.0/icons/layer-icon.svg?react";
import CloseIcon from "../../../5.0/icons/xmark.svg?react";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import {SideGrid} from "../../abstract/SideGrid";
import {CaptionPanelV5} from "./viewbar/CaptionPanelV5";
import {ConditionalFormattingPanelV5} from "./viewbar/ConditionalFormattingPanelV5";
import {LayerPanelV5} from "./viewbar/LayersPanelV5";
import {DockableV5} from "./dockable/DockableV5";
import {SpaceEditorV5} from "./SpaceEditorV5";
import type {GridItem, GridSide} from "./spaceselector/GridTypes";

interface IBasicPropsFor3DTweaking {
	spaceViewRenderer: SpaceViewRenderer;
	param1?: string;
}

interface ISpaceViewState {
	selectedItems: ISpaceItemModel[];
	isCatalogOpen: boolean;
	isCatalogDocked: boolean;
	isBoundaryTypesWindowOpen: boolean;
	isBoundaryTypesWindowDocked: boolean;
	isUnplottedXyiconsOpen: boolean;
	isUnplottedXyiconsDocked: boolean;
	activeToolId: SpaceTool;
	selectionToolType: SelectionToolType;
	isLayerPanelOpen: boolean;
	isFilterPanelOpen: boolean;
	isConditionalFormattingPanelOpen: boolean;
	isCaptionPanelOpen: boolean;
	isCreatePanelOpen: boolean;
	editedCatalog: {
		config: IIconConfig;
		catalog: Catalog;
	};
	catalogMode: "create" | "edit";
	managingColumns: boolean;
	sharingViewId: string;
	isSpaceToPDFExportPanelOpen: boolean;
	isDetailsPanelOpen: boolean;
	openedSpaceEditorPanelType: TSpaceEditorPanelTypes | null;
}

export type TSpaceEditorPanelTypes = "formatting" | "layers" | "labels" | "filter";

@observer
export class SpaceEditorViewV5 extends React.Component<IBasicPropsFor3DTweaking, ISpaceViewState> {
	private readonly _feature = XyiconFeature.SpaceEditor;
	private _requestAnimationFrameId: number = null;
	private _conditionalFormattingPanel = React.createRef<ConditionalFormattingPanelV5>();
	private _captionPanel = React.createRef<CaptionPanelV5>();
	private _filterPanel = React.createRef<FilterEditorV5>();
	private _filteredItems: ISpaceItemModel[] = [];
	private _splitter: SplitterV5;
	private _disposerForViewChangeListener: Lambda;
	private _disposerForFilterListener: Lambda;
	private _lastViewId: string = "";

	constructor(props: IBasicPropsFor3DTweaking) {
		super(props);

		this.state = {
			selectedItems: [],
			isCatalogOpen: false,
			isCatalogDocked: false,
			isBoundaryTypesWindowOpen: false,
			isBoundaryTypesWindowDocked: false,
			isUnplottedXyiconsOpen: false,
			isUnplottedXyiconsDocked: false,
			activeToolId: "selection",
			selectionToolType: "selection",
			isLayerPanelOpen: false,
			isFilterPanelOpen: false,
			isConditionalFormattingPanelOpen: false,
			isCaptionPanelOpen: false,
			isCreatePanelOpen: false,
			editedCatalog: null,
			catalogMode: "create",
			managingColumns: false,
			sharingViewId: null,
			isSpaceToPDFExportPanelOpen: false,
			isDetailsPanelOpen: false,
			openedSpaceEditorPanelType: null,
		};

		this.resetFilters();
	}

	private resetFilters() {
		const selectedView = this.selectedView;

		if (selectedView.id !== this._lastViewId) {
			// Selected view changed, or opening view the first time
			// -> copy filters from view
			this._lastViewId = selectedView.id;

			const savedFilters = selectedView.getSavedFilters();

			this.updateSpaceEditorFilterState(savedFilters);
			this.listenFilters();

			selectedView.setFilters(ObjectUtils.deepClone(savedFilters));
		}
	}

	private onOpenSpaceToPDFExportPanel = () => {
		this.setState({
			isSpaceToPDFExportPanelOpen: true,
		});
	};

	private setDockableDocked = (value: boolean, title: DockableTitle) => {
		if (title === "Catalog") {
			this.setState({
				isCatalogDocked: value,
			});

			if (value === this.state.isBoundaryTypesWindowDocked) {
				this.setState({
					isBoundaryTypesWindowOpen: false,
				});
			} else if (value === this.state.isUnplottedXyiconsDocked) {
				this.setState({
					isUnplottedXyiconsOpen: false,
				});
			}
		} else if (title === "Boundary") {
			this.setState({
				isBoundaryTypesWindowDocked: value,
			});

			if (value === this.state.isCatalogDocked) {
				this.setState({
					isCatalogOpen: false,
				});
			} else if (value === this.state.isUnplottedXyiconsDocked) {
				this.setState({
					isUnplottedXyiconsOpen: false,
				});
			}
		} else if (title === "Unplotted Xyicons") {
			this.setState({
				isUnplottedXyiconsDocked: value,
			});

			if (value === this.state.isCatalogDocked) {
				this.setState({
					isCatalogOpen: false,
				});
			}
			if (value === this.state.isBoundaryTypesWindowDocked) {
				this.setState({
					isBoundaryTypesWindowOpen: false,
				});
			}
		}
	};

	private isDocked(title: DockableTitle) {
		if (title === "Catalog") {
			return this.state.isCatalogDocked;
		} else if (title === "Boundary") {
			return this.state.isBoundaryTypesWindowDocked;
		} else if (title === "Unplotted Xyicons") {
			return this.state.isUnplottedXyiconsDocked;
		}
	}

	private setDockableOpen = (value: boolean, title: DockableTitle, onlyIfNotDocked: boolean = false) => {
		const isDocked = this.isDocked(title);

		if ((onlyIfNotDocked && !isDocked) || !onlyIfNotDocked) {
			if (title === "Catalog") {
				this.setState({
					isCatalogOpen: value,
				});

				if (value && this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked === this.state.isCatalogDocked) {
					this.setState({
						isBoundaryTypesWindowOpen: false,
					});
				}
				if (value && this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked === this.state.isCatalogDocked) {
					this.setState({
						isUnplottedXyiconsOpen: false,
					});
				}
			} else if (title === "Boundary") {
				this.setState({
					isBoundaryTypesWindowOpen: value,
				});

				if (value && this.state.isCatalogOpen && this.state.isCatalogDocked === this.state.isBoundaryTypesWindowDocked) {
					this.setState({
						isCatalogOpen: false,
					});
				}
				if (value && this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked === this.state.isBoundaryTypesWindowDocked) {
					this.setState({
						isUnplottedXyiconsOpen: false,
					});
				}
			} else if (title === "Unplotted Xyicons") {
				this.setState({
					isUnplottedXyiconsOpen: value,
				});

				if (value && this.state.isCatalogOpen && this.state.isCatalogDocked === this.state.isUnplottedXyiconsDocked) {
					this.setState({
						isCatalogOpen: false,
					});
				}
				if (value && this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked === this.state.isUnplottedXyiconsDocked) {
					this.setState({
						isBoundaryTypesWindowOpen: false,
					});
				}
			}
		}
	};

	private selectItems = (spaceItems: ISpaceItemModel[], selectDetailsTab?: boolean, forceUpdate: boolean = false, doubleClick?: boolean) => {
		if (forceUpdate || !ObjectUtils.compare(this.state.selectedItems, spaceItems)) {
			this.setState({selectedItems: spaceItems});
		} else if (ObjectUtils.compare(this.state.selectedItems, spaceItems) && !!doubleClick) {
			this.setState({isDetailsPanelOpen: true});
		}
	};

	private setScale = async (spaceUnitsPerMeter: number) => {
		const {transport} = this.props.spaceViewRenderer;
		const {appState} = transport;
		const {space} = appState;
		const params: RescaleSpaceRequest = {
			spaceID: space.id,
			unitsPerMeter: spaceUnitsPerMeter,
			portfolioID: appState.portfolioId,
		};

		await transport.requestForOrganization<SpaceFileDto>({
			url: "spaces/rescale",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		space.setSpaceUnitsPerMeter(spaceUnitsPerMeter);

		await this.props.spaceViewRenderer.refreshSpace(true);
	};

	private confirmAlignment = async (insertionInfo: SpaceFileInsertionInfo) => {
		const activePDFFile = this.props.spaceViewRenderer.space.selectedSpaceFile;

		activePDFFile.setInsertionInfo(insertionInfo);

		const params: UpdateSpaceFileRequest = {
			spaceFileList: [
				{
					spaceFileID: activePDFFile.id,
					spaceVersionID: activePDFFile.spaceVersionId,
					spaceID: activePDFFile.parent.id,
					settings: {
						insertionInfo: activePDFFile.insertionInfo,
					},
				},
			],
			portfolioID: activePDFFile.parent.portfolioId,
		};

		await this.props.spaceViewRenderer.transport.updateSpaceFiles(params);
		this.props.spaceViewRenderer.setInsertionInfo(activePDFFile.insertionInfo);

		this.forceUpdate();
	};

	private setActiveTool = (id: SpaceTool) => {
		if (arrayOfSelectionToolTypes.includes(id as SelectionToolType)) {
			this.setSelectionToolType(id as SelectionToolType);
		}

		if (this.state.activeToolId !== id) {
			this.setState({
				activeToolId: id,
			});
		}

		if (id !== "selection") {
			this.props.spaceViewRenderer.spaceItemController.closeLinks();
			this.props.spaceViewRenderer.spaceItemController.deselectAll();
		}
	};

	private setSelectionToolType = (type: SelectionToolType) => {
		if (type !== this.state.selectionToolType) {
			this.setState({
				selectionToolType: type,
			});
		}
	};

	private getDockedTitle(): DockableTitle {
		if (this.state.isCatalogOpen && this.state.isCatalogDocked) {
			return "Catalog";
		} else if (this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked) {
			return "Boundary";
		} else if (this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked) {
			return "Unplotted Xyicons";
		} else {
			return null;
		}
	}

	private getFilteredItems() {
		return this.props.spaceViewRenderer.spaceItemController.getFilteredItems();
	}

	private onClickOnItem = (item: Boundary | Xyicon) => {
		if (this.props.spaceViewRenderer.isMounted) {
			this.props.spaceViewRenderer.toolManager.cameraControls.focusOn(item, false);
		}
	};

	private onAddCatalogClick = () => {
		this.setState({
			isCreatePanelOpen: true,
			editedCatalog: null,
			catalogMode: "create",
		});
	};

	private onDuplicateCatalogClick = async (catalog: Catalog) => {
		const iconConfig = await this.props.spaceViewRenderer.transport.getIconConfigOfCatalog(catalog);

		this.setState({
			isCreatePanelOpen: true,
			editedCatalog: {
				catalog: catalog,
				config: iconConfig,
			},
			catalogMode: "create",
		});
	};

	private closeCreatePanel = () => {
		this.setState({
			isCreatePanelOpen: false,
			editedCatalog: null,
		});
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private spaceViewShareClick = (viewId: string) => {
		this.toggleSharingPanel(viewId);
	};

	private getOverlayPanel() {
		this._filteredItems = this.getFilteredItems();

		switch (this.state.openedSpaceEditorPanelType) {
			case "filter":
				return (
					<FilterEditorV5
						ref={this._filterPanel}
						features={[XyiconFeature.Xyicon, XyiconFeature.Boundary]}
						feature={XyiconFeature.SpaceEditor}
						items={this._filteredItems}
						onSelect={Functions.emptyFunction}
						onClose={() => this.setState({openedSpaceEditorPanelType: null})}
					/>
				);
			case "layers":
				return (
					<LayerPanelV5
						spaceViewRenderer={this.props.spaceViewRenderer}
						showNumbers={true}
						view={this.selectedView}
					/>
				);
			case "labels":
				return (
					<CaptionPanelV5
						ref={this._captionPanel}
						spaceViewRenderer={this.props.spaceViewRenderer}
						view={this.selectedView}
					/>
				);
			case "formatting":
				return (
					<ConditionalFormattingPanelV5
						ref={this._conditionalFormattingPanel}
						spaceViewRenderer={this.props.spaceViewRenderer}
						view={this.selectedView}
					/>
				);
			default:
				return null;
		}
	}

	private get selectedView() {
		return this.props.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private toggleSharingPanel = (viewId?: string) => {
		this.setState({sharingViewId: viewId});
	};

	private stringifyFilters = () => {
		return JSON.stringify(this.selectedView.filters);
	};

	private onKeyboardPress = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this.setState({
				isDetailsPanelOpen: false,
				openedSpaceEditorPanelType: null,
			});
		}
	};

	private startListening() {
		this._disposerForViewChangeListener = observe(
			this.props.spaceViewRenderer.transport.appState.selectedViewId,
			(this._feature as any).toString(),
			(a) => {
				this.resetFilters();
			},
		);

		this.listenFilters();

		KeyboardListener.getInstance().signals.down.add(this.onKeyboardPress);
	}

	private listenFilters() {
		this._disposerForFilterListener?.();
		this._disposerForFilterListener = reaction(this.stringifyFilters, () => {
			this.updateSpaceEditorFilterState(this.selectedView.filters);
		});
	}

	private stopListening() {
		this._disposerForViewChangeListener?.();
		this._disposerForViewChangeListener = null;

		this._disposerForFilterListener?.();
		this._disposerForFilterListener = null;

		KeyboardListener.getInstance().signals.down.remove(this.onKeyboardPress);
	}

	private onSplitterDidMount = (splitter: SplitterV5) => {
		if (splitter) {
			this._splitter = splitter;
			const ratios = this.props.spaceViewRenderer.transport.services.localStorage.getSplitterRatios();

			if (ratios && ratios.length >= 2) {
				splitter.setRatios(ratios);
				this.props.spaceViewRenderer.onResize();
			}
		}
	};

	private onSplitterChange = (ratios: number[]) => {
		const {transport} = this.props.spaceViewRenderer;

		transport.services.localStorage.setSplitterRatios(ratios);
	};

	private saveStateToLocalStorage() {
		const organizationId = this.props.spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const stateToSave = {
				isBoundaryTypesWindowDocked: this.state.isBoundaryTypesWindowDocked,
				isBoundaryTypesWindowOpen: this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked,
				isCatalogDocked: this.state.isCatalogDocked,
				isCatalogOpen: this.state.isCatalogOpen && this.state.isCatalogDocked,
				isUnplottedXyiconsDocked: this.state.isUnplottedXyiconsDocked,
				isUnplottedXyiconsOpen: this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked,
			};

			this.props.spaceViewRenderer.transport.services.localStorage.set(this.getLocalStorageKey(organizationId), stateToSave);
		}
	}

	private getLocalStorageKey(organizationId: string) {
		return `srv4-org-${organizationId}-spaceeditor-spaceview-dockables-open-state`;
	}

	private loadStateFromLocalStorage() {
		const organizationId = this.props.spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const savedDockedState = this.props.spaceViewRenderer.transport.services.localStorage.get(this.getLocalStorageKey(organizationId));

			if (savedDockedState) {
				this.setState({
					...savedDockedState,
				});
			}
		}
	}

	private updateSpaceEditorFilterState = (filters: IFilterState = this.selectedView.filters) => {
		cancelAnimationFrame(this._requestAnimationFrameId);
		this._requestAnimationFrameId = window.requestAnimationFrame(() => {
			this.props.spaceViewRenderer.actions.updateSpaceEditorFilterState(filters);
		});
	};

	private onSpaceEditorDidMount = () => {
		this.updateSpaceEditorFilterState();
		this.forceUpdate();
	};

	private getPanelIcon = () => {
		switch (this.state.openedSpaceEditorPanelType) {
			case "filter":
				return <FilterIcon />;
			case "layers":
				return <LayersIcon />;
			case "formatting":
				return <FormattingIcon />;
			case "labels":
				return <LabelsIcon />;
			default:
				return null;
		}
	};

	public override async componentDidMount() {
		this.startListening();

		this.loadStateFromLocalStorage();

		const {actions} = this.props.spaceViewRenderer.transport.appState;

		const features = actions.getLoadingDependencies(XyiconFeature.SpaceEditor);

		for (const feature of features) {
			await this.props.spaceViewRenderer.transport.services.feature.refreshList(feature);
		}
		const {xyiconManager, boundaryManager, markupManager} = this.props.spaceViewRenderer;

		xyiconManager.signals.itemsAdd.add(this.forceUpdateArrow);
		boundaryManager.signals.itemsAdd.add(this.forceUpdateArrow);
		markupManager.signals.itemsAdd.add(this.forceUpdateArrow);

		xyiconManager.signals.itemsRemove.add(this.forceUpdateArrow);
		boundaryManager.signals.itemsRemove.add(this.forceUpdateArrow);
		markupManager.signals.itemsRemove.add(this.forceUpdateArrow);

		// Add potential new types
		actions.getSelectedView(XyiconFeature.SpaceEditor).updateSpaceEditorViewSettings();

		// There's a chance we get to this line when the spaceeditor is already fully loaded,
		// So it can't hurt to call this function again, just to be sure
		this.onSpaceEditorDidMount();
		this.props.spaceViewRenderer.signals.spaceLoadReady.add(this.onSpaceEditorDidMount);
	}

	public override componentDidUpdate() {
		this.saveStateToLocalStorage();
	}

	public override componentWillUnmount() {
		this.stopListening();

		const {xyiconManager, boundaryManager, markupManager} = this.props.spaceViewRenderer;

		xyiconManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		boundaryManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		markupManager.signals.itemsAdd.remove(this.forceUpdateArrow);

		xyiconManager.signals.itemsRemove.remove(this.forceUpdateArrow);
		boundaryManager.signals.itemsRemove.remove(this.forceUpdateArrow);
		markupManager.signals.itemsRemove.remove(this.forceUpdateArrow);

		this.props.spaceViewRenderer.signals.spaceLoadReady.remove(this.onSpaceEditorDidMount);
	}

	public static getDerivedStateFromProps(props: IBasicPropsFor3DTweaking, state: ISpaceViewState) {
		if (state.selectedItems.length === 0) {
			return {isDetailsPanelOpen: false};
		}

		return null;
	}

	private gridItemSide = (filterBySide: GridSide) => {
		const {gridItemSide} = this.props.spaceViewRenderer.transport.appState.app.graphicalTools;

		if (!gridItemSide) {
			return gridItemSide;
		}

		const splitted = gridItemSide.split("-");

		const side: GridSide = splitted[0] as GridSide;

		if (side !== filterBySide) {
			return "";
		}

		const item: GridItem = splitted[1] as GridItem;

		return (
			<SideGrid
				viewFeature={XyiconFeature.SpaceEditor}
				itemFeature={item === "xyicon" ? XyiconFeature.Xyicon : XyiconFeature.Boundary}
			/>
		);
	};

	private toggleDetailsPanel = () => {
		this.setState((prevState) => ({isDetailsPanelOpen: !prevState.isDetailsPanelOpen}));
	};

	private onCloseOverlayedDetailsPanel = () => {
		this.setState({isDetailsPanelOpen: false});
	};

	private onSelectItems = (items: IModel[]) => {
		this.selectItems(items as ISpaceItemModel[]);
	};

	private resetOpenedSpaceEditorPanelState = () => {
		this.setState({openedSpaceEditorPanelType: null});
	};

	public override render() {
		const space = this.props.spaceViewRenderer.transport.appState.space;
		const dockedTitle = this.getDockedTitle();
		const isDockedAndOpen = !!dockedTitle;

		if (space) {
			return (
				<SpaceEditorViewStyled>
					<SplitterV5
						ref={this.onSplitterDidMount}
						className={ReactUtils.cls({blurred: this.state.isCreatePanelOpen || this.state.sharingViewId})}
						onChange={this.onSplitterChange}
					>
						{isDockedAndOpen && (
							<DockableV5
								spaceViewRenderer={this.props.spaceViewRenderer}
								setDocked={this.setDockableDocked}
								setOpen={this.setDockableOpen}
								isDocked={isDockedAndOpen}
								title={dockedTitle}
								setActiveTool={this.setActiveTool}
								onAddCatalogClick={this.onAddCatalogClick}
								onDuplicateCatalogClick={this.onDuplicateCatalogClick}
								onCreateUnplottedXyicons={Functions.emptyFunction}
							/>
						)}
						{this.gridItemSide("left")}
						<SpaceEditorV5
							spaceViewRenderer={this.props.spaceViewRenderer}
							setDockableDocked={this.setDockableDocked}
							setDockableOpen={this.setDockableOpen}
							isCatalogOpen={this.state.isCatalogOpen}
							isBoundaryTypesWindowOpen={this.state.isBoundaryTypesWindowOpen}
							isUnplottedXyiconsOpen={this.state.isUnplottedXyiconsOpen}
							isCatalogDocked={this.state.isCatalogDocked}
							isBoundaryTypesWindowDocked={this.state.isBoundaryTypesWindowDocked}
							isUnplottedXyiconsDocked={this.state.isUnplottedXyiconsDocked}
							setScale={this.setScale}
							confirmAlignment={this.confirmAlignment}
							thumbnail={space.thumbnailFileURL}
							mode={SpaceEditorMode.NORMAL}
							activeToolId={this.state.activeToolId}
							setActiveTool={this.setActiveTool}
							selectionToolType={this.state.selectionToolType}
							selectItems={this.selectItems}
							onAddCatalogClick={this.onAddCatalogClick}
							onDuplicateCatalogClick={this.onDuplicateCatalogClick}
							spaceViewShareClick={this.spaceViewShareClick}
							onOpenSpaceToPDFExportPanel={this.onOpenSpaceToPDFExportPanel}
							toggleDetailsPanel={this.toggleDetailsPanel}
							onOpenPanelClick={(type) => this.setState({openedSpaceEditorPanelType: type})}
							activePanel={this.state.openedSpaceEditorPanelType}
							isDetailsPanelOpen={this.state.isDetailsPanelOpen}
						/>
						{this.gridItemSide("right")}
					</SplitterV5>
					{this.state.isCreatePanelOpen && (
						<CatalogItemPanelV5
							onClose={this.closeCreatePanel}
							iconConfig={this.state.editedCatalog?.config}
							catalog={this.state.editedCatalog?.catalog}
							mode={this.state.catalogMode}
							shape={this.state.editedCatalog?.catalog.iconCategory === CatalogIconType.ModelParameter ? "Custom Shape" : "Default Shape"}
							model={this.state.editedCatalog?.catalog.model}
							type={this.state.editedCatalog?.catalog.type}
						/>
					)}
					<Panel open={this.state.isDetailsPanelOpen && this.state.selectedItems.length > 0}>
						<DetailsTabV5
							items={this.state.selectedItems}
							feature={this.state.selectedItems[0]?.ownFeature}
							features={[XyiconFeature.Space, XyiconFeature.Boundary]}
							isPortTemplateEditorOpen={false}
							setPortTemplateEditorOpen={Functions.emptyFunction}
							onCloseOverlayedDetaislPanel={this.onCloseOverlayedDetailsPanel}
							onSelect={this.onSelectItems}
						/>
					</Panel>
					<SpaceEditorPanelContainerStyled $open={!!this.state.openedSpaceEditorPanelType}>
						{this.state.openedSpaceEditorPanelType !== "filter" && (
							<FlexCenter style={{justifyContent: "space-between", color: colorPalette.primary.c500Primary}}>
								<FlexCenter $gap="4px">
									{this.getPanelIcon()}
									<h4>{this.state.openedSpaceEditorPanelType?.toUpperCase()}</h4>
								</FlexCenter>
								<IconButtonV5
									IconComponent={CloseIcon}
									onClick={this.resetOpenedSpaceEditorPanelState}
								/>
							</FlexCenter>
						)}
						{this.getOverlayPanel()}
					</SpaceEditorPanelContainerStyled>
				</SpaceEditorViewStyled>
			);
		}

		// TODO loading?
		return null;
	}
}

const SpaceEditorPanelContainerStyled = styled.div<{$open: boolean}>`
	${VerticalFlexStyle};
	min-height: 0;
	width: 360px;
	position: absolute;
	right: 16px;
	bottom: 16px;
	height: calc(100vh - 163px);
	border-radius: 16px;
	transform: translateX(calc(100% + 16px));
	transition: ease-in-out transform 0.2s;
	background-color: white;
	z-index: 9;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	padding: 8px;
	gap: 16px;

	${(props) =>
		props.$open &&
		css`
			transform: translateX(0);
		`}
`;

const SpaceEditorViewStyled = styled.div`
	${VerticalFlexStyle}
	flex: 1;
	min-height: 0;
`;

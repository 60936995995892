import * as React from "react";
import {observer} from "mobx-react";
import type {IModulePanelProps} from "../../abstract/ModuleView";
import {IconButton} from "../../../widgets/button/IconButton";
import type {Report} from "../../../../data/models/Report";
import {ReportWizard} from "./wizard/ReportWizard";

interface IReportPanelProps extends IModulePanelProps {
	onRunReport: (report: Report) => void;
	report: Report;
}

@observer
export class ReportPanel extends React.Component<IReportPanelProps> {
	private getTitle() {
		const {report} = this.props;

		if (report.id) {
			return `Edit report - ${report.name}`;
		} else {
			if (report.isDuplicate) {
				return "Duplicate Report";
			} else {
				return "Create Report";
			}
		}
	}

	public override render() {
		const {report, onClose, onRunReport} = this.props;

		return (
			<div className="ReportPanel SidePanel">
				<div className="heading hbox createBox">
					<h4 className="detailsTitle">{this.getTitle()}</h4>
					<IconButton
						icon="close"
						title="Close Panel"
						className="close"
						onClick={(event) => onClose()}
					/>
				</div>

				<ReportWizard
					report={report}
					onClose={onClose}
					onRunReport={onRunReport}
				/>
			</div>
		);
	}
}

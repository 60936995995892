import * as React from "react";
import {inject, observer} from "mobx-react";
import {SVGIcon} from "../widgets/button/SVGIcon";
import type {Navigation} from "../../Navigation";
import {ReactUtils} from "../utils/ReactUtils";
import type {XyiconFeature} from "../../generated/api/base";
import type {AppState} from "../../data/state/AppState";
import type {View} from "../../data/models/View";
import {StringUtils} from "../../utils/data/string/StringUtils";
import type {Portfolio} from "../../data/models/Portfolio";
import type {TransportLayer} from "../../data/TransportLayer";
import type {NavigationEnum} from "../../Enums";

interface ISideBarNavItemProps {
	className?: string;
	feature: XyiconFeature;
	menuNav: NavigationEnum;
	menuIcon: string;
	menuLabel: string;
	activeNav: string;
	onToggleNavigation: () => void;
	portfolio?: Portfolio;
	appState?: AppState;
	transport?: TransportLayer;
	navigation?: Navigation;
	isOpenByDefault?: boolean;
}

interface ISideBarNavItemStates {
	open?: boolean;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class SideBarNavItem extends React.Component<ISideBarNavItemProps, ISideBarNavItemStates> {
	constructor(props: ISideBarNavItemProps) {
		super(props);
		this.state = {
			open: this.props.isOpenByDefault || false,
		};
	}

	private toggleDropDown = () => {
		this.setState((prevState) => ({open: !prevState.open}));
	};

	private onSelectView = (view: View) => {
		const {onToggleNavigation, feature, appState, navigation, portfolio, transport} = this.props;

		const menu = navigation.menus.find((menu) => menu.feature === feature);

		if (menu) {
			if (portfolio) {
				appState.portfolioId = portfolio.id;
				transport.services.localStorage.set(appState.actions.getKeyForLocalStorageSelectedPortfolio(), portfolio.id);
			}
			appState.actions.selectView(view);
			navigation.goApp(menu.nav);
			onToggleNavigation();
		}
	};

	private onClick = async (link: NavigationEnum) => {
		const {portfolio, onToggleNavigation, navigation, transport} = this.props;

		if (portfolio) {
			await transport.services.auth.switchPortfolio(portfolio.id);
		}
		onToggleNavigation();
		navigation.goApp(link);
	};

	public override render() {
		const {menuNav, activeNav, menuIcon, menuLabel, className, feature, appState} = this.props;
		const {open} = this.state;
		const views = appState.views[feature].filter((view) => view.showOnNavigation);

		return (
			<li
				key={menuNav}
				className={ReactUtils.cls(className || "", {navItem: true, open: open})}
			>
				<div className="hbox linkContainer">
					<span
						className={ReactUtils.cls("navLink", {active: menuNav === activeNav})}
						onClick={() => {
							this.onClick(menuNav);
						}}
					>
						<div className="iconContainer">
							<SVGIcon
								icon={menuIcon}
								classNames={`navIcon ${menuIcon}`}
							/>
						</div>
						{menuLabel}
					</span>
					{views.length !== 0 && (
						<div
							className="toggleContainer"
							onClick={this.toggleDropDown}
						>
							<div className={ReactUtils.cls("dropDownToggle", {open: open})} />
						</div>
					)}
				</div>
				{views.length !== 0 && (
					<ul className={ReactUtils.cls("views", {open: open})}>
						{views
							.sort((viewA, viewB) => StringUtils.sortIgnoreCase(viewA.name, viewB.name))
							.map((view) => (
								<li
									key={view.id}
									className="view"
									onClick={(event) => this.onSelectView(view)}
								>
									<span className="viewItem">{view.name}</span>
								</li>
							))}
					</ul>
				)}
			</li>
		);
	}
}

import * as React from "react";
import {IconButton} from "../../button/IconButton";
import {Functions} from "../../../../utils/function/Functions";
import type {ITextInputProps} from "../text/TextInput";
import {TextInput} from "../text/TextInput";
import {SVGIcon} from "../../button/SVGIcon";

export interface ISearchFieldProps extends Partial<ITextInputProps> {
	divRef?: React.RefObject<HTMLDivElement>;
	realParentId?: string;
}

export class SearchField extends React.Component<ISearchFieldProps> {
	public static defaultProps: ISearchFieldProps = {
		value: "",
		onChange: Functions.emptyFunction,
	};

	private deleteSearch = () => {
		this.props.onInput("");
	};

	public override render() {
		const {className, divRef, value, placeholder} = this.props;

		return (
			<div
				className={`SearchField FindInList ${className || ""}`}
				ref={divRef}
				data-realparentid={this.props?.realParentId || ""}
			>
				<SVGIcon
					classNames="search"
					icon="search"
				/>
				<TextInput
					{...this.props}
					placeholder={placeholder ?? "Find..."}
				/>
				{value?.length > 0 && (
					<IconButton
						className="cancel"
						icon="cancel"
						onClick={this.deleteSearch}
					/>
				)}
			</div>
		);
	}
}

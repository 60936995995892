import type {Mesh} from "three";
import type {AppState} from "../state/AppState";
import type {LibraryModelDto} from "../../generated/api/base";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {THREEUtils} from "../../utils/THREEUtils";
import type {IModel} from "./Model";

export enum LibraryModelType {
	STANDARD = 1,
	USER_UPLOADED_GLB = 2,
	SYSTEM_PROVIDED_GLB = 3,
	CODE_GENERATED_MODEL = 4,
}

export class LibraryModel implements IModel, LibraryModelDto {
	private _data: LibraryModelDto;
	private _appState: AppState;

	constructor(data: LibraryModelDto, appState: AppState) {
		this._data = data;
		this._appState = appState;
	}

	public get id() {
		return this._data.libraryModelID;
	}

	// The original file name. We don't really use it for anything
	public get fileName() {
		return this._data.fileName;
	}

	public get url(): string {
		switch (this.geometryType) {
			case LibraryModelType.USER_UPLOADED_GLB:
				return this._appState.app.transport.getFullPathFromServer(`librarymodels/${this.id}.glb`);
			case LibraryModelType.STANDARD:
				return Constants.URLForStandardXyiconGLB;
			default:
				return "";
		}
	}

	public get thumbnail() {
		return this._data.thumbnail;
	}

	public get keywords() {
		return this._data.keywords;
	}

	public get geometryType() {
		return this._data.type;
	}

	public async getSizeInMeters() {
		try {
			const mesh = await this.getPreviewMesh();
			const size = THREEUtils.getDimensionsOfObject(mesh);

			THREEUtils.disposeAndClearContainer(mesh);

			return size;
		} catch (error) {
			console.error("error ", error);
		}
	}

	public async getPreviewMesh(): Promise<Mesh> {
		const glTF = await THREEUtils.loadGLTF(this.url);
		const mesh = THREEUtils.getMeshFromGltf(glTF);

		return mesh;
	}

	public get data() {
		return this._data;
	}
}

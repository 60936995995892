import * as React from "react";
import {SelectInput} from "../../../../../../../../widgets/input/select/SelectInput";
import type {IInputProps} from "../../../../../../../../widgets/input/clicktoedit/FieldInput";

interface IBoolOption {
	label: string;
	value: boolean;
}

export class MassFieldBooleanInput extends React.Component<IInputProps> {
	public override render() {
		const {field, onInput, value} = this.props;
		const boolOptions: IBoolOption[] = [
			{
				label: field.dataTypeSettings.displayLabelForFalse,
				value: false,
			},
			{
				label: field.dataTypeSettings.displayLabelForTrue,
				value: true,
			},
		];

		return (
			<SelectInput
				options={boolOptions}
				render={(option) => option.label}
				selected={boolOptions.find((option) => option.value === value)}
				onChange={(option) => onInput(option.value)}
				onFocusLossForceBlur={true}
			/>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {runInAction} from "mobx";
import type {Sortable} from "react-sortablejs";
import {ReactSortable} from "react-sortablejs";
import type {IViewFolder, ViewFolderStructure} from "../../../data/models/ViewUtils";
import {rootFolderId} from "../../../data/models/ViewUtils";
import type {AppState} from "../../../data/state/AppState";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {ViewPreferenceCategory} from "../../../generated/api/base";
import {ViewFolder} from "./ViewFolder";
import {ViewItem} from "./ViewItem";

interface IViewFolderStructureProps {
	appState?: AppState;
	level: number;
	selectedViewId: string;
	viewFolder: IViewFolder;
	onShareClick: (viewId: string) => void;
	startFocusListen: () => void;
	stopFocusListen: () => void;
	onElementAddedToList: () => void;
	lastElementInList: React.RefObject<ViewFolder | ViewItem>;
	saveViewFolderStructureToDatabase: () => void;
	searchString: string;
	closeViewSelect: () => void;
	onSelect: (view: string) => Promise<void>;
}

const indentationSize = 15;
const dragTolerance = 4;

@inject("appState")
@observer
export class ViewFolderStructureReact extends React.Component<IViewFolderStructureProps> {
	// TODO: Probably when this returns with true, sortable.js calls event.preventDefault,
	// So it's not possible right now to change the text cursor (caret), or select the text
	// within the input element with draggable parents...
	private filterDragElement(this: Sortable, event: Event | TouchEvent, target: HTMLElement, sortable: Sortable): boolean {
		const element = event.target as HTMLElement;
		const elementName = element.nodeName.toLowerCase();

		return elementName === "input" || elementName === "textarea";
	}

	private setViewFolderStructure = (newViewFolderStructure: ViewFolderStructure) => {
		runInAction(() => {
			if (!ObjectUtils.compare(this.props.viewFolder.children, newViewFolderStructure)) {
				this.props.viewFolder.children.length = 0;
				this.props.viewFolder.children.push(...newViewFolderStructure);

				return this.props.saveViewFolderStructureToDatabase();
			}
		});
	};

	public override render() {
		const {
			viewFolder,
			startFocusListen,
			stopFocusListen,
			saveViewFolderStructureToDatabase,
			level,
			lastElementInList,
			searchString,
			selectedViewId,
			onShareClick,
			onElementAddedToList,
			closeViewSelect,
			onSelect,
		} = this.props;

		return (
			<div>
				{viewFolder.id !== rootFolderId && (
					<ViewFolder
						data={viewFolder}
						startFocusListen={startFocusListen}
						stopFocusListen={stopFocusListen}
						saveViewFolderStructureToDatabase={saveViewFolderStructureToDatabase}
						ref={level === 1 ? (lastElementInList as React.RefObject<ViewFolder>) : undefined}
						searchString={searchString}
					/>
				)}
				<ReactSortable
					list={viewFolder.children}
					setList={this.setViewFolderStructure}
					group="nested"
					animation={150}
					fallbackOnBody={true}
					forceFallback={true}
					swapThreshold={0.45}
					fallbackTolerance={dragTolerance}
					touchStartThreshold={dragTolerance}
					preventOnFilter={false}
					delay={500}
					delayOnTouchOnly={true}
					style={{
						marginLeft: `${indentationSize * Math.sign(level)}px`,
						marginBottom: "10px", // to be able to drop an item below the folder (and not inside)
					}}
					dragClass="dragged"
					filter={this.filterDragElement}
				>
					{(viewFolder.isOpen || searchString) &&
						viewFolder.children.map((v) => {
							if (v.category === ViewPreferenceCategory.View) {
								return (
									<ViewItem
										key={v.id}
										data={v}
										selectedViewId={selectedViewId}
										onShareClick={onShareClick}
										startFocusListen={startFocusListen}
										stopFocusListen={stopFocusListen}
										onElementAddedToList={onElementAddedToList}
										ref={level === 0 ? (lastElementInList as React.RefObject<ViewItem>) : undefined}
										searchString={searchString}
										closeViewSelect={closeViewSelect}
										onSelect={onSelect}
									/>
								);
							} else {
								return (
									<ViewFolderStructureReact
										key={v.id}
										viewFolder={v}
										selectedViewId={selectedViewId}
										level={level + 1}
										onShareClick={onShareClick}
										startFocusListen={startFocusListen}
										stopFocusListen={stopFocusListen}
										onElementAddedToList={onElementAddedToList}
										lastElementInList={lastElementInList}
										saveViewFolderStructureToDatabase={saveViewFolderStructureToDatabase}
										searchString={searchString}
										closeViewSelect={closeViewSelect}
										onSelect={onSelect}
									/>
								);
							}
						})}
				</ReactSortable>
			</div>
		);
	}
}

import type {BufferAttribute, InstancedBufferGeometry} from "three";
import {CircleGeometry} from "three/src/geometries/CircleGeometry.js";
import type {Xyicon3D} from "../../../elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import type {Xyicon} from "../../../../../../../../data/models/Xyicon";
import {InstancedIconManager} from "./InstancedIconManager";

export class LinkIconManager extends InstancedIconManager {
	private _xyiconsWithLinks: Xyicon3D[] = [];
	protected override _defaultBackgroundColor: [number, number, number] = [55 / 255, 71 / 255, 79 / 255];
	protected override _iconColor: [number, number, number] = [1, 1, 1];
	private _backgroundColorWithCrossPortfolioLinks: [number, number, number] = [128 / 255, 128 / 255, 128 / 255];
	private _hoveredBackgroundColor: [number, number, number] = [79 / 255, 55 / 255, 71 / 255];
	private _selectedBackgroundColor: [number, number, number] = [71 / 255, 79 / 255, 55 / 255];
	private _selectedLinkInstanceIds: Set<number> = new Set<number>();

	private readonly _indicesWithCrossPortfolioLinks: Set<number> = new Set<number>();

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "link");
		this._geometry = new CircleGeometry(0.5, 16);
	}

	public updateTransformations() {
		if (this._icons) {
			this.updateTransformationsFromDummyObjects((this._icons.userData.selectedItems as Xyicon3D[]).map((xyicon3D) => xyicon3D.getLinkIconObject));
		}
	}

	protected override prepareInstancedMesh() {
		this._xyiconsWithLinks = this.getXyiconsWithLinks();

		return {
			material: this._spaceViewRenderer.textureManager.miscTextures.link,
			objects: this._xyiconsWithLinks,
		};
	}

	protected onInstancedMeshCreated() {
		this._indicesWithCrossPortfolioLinks.clear();
		this._icons.userData.selectedItems = this._xyiconsWithLinks;

		for (let i = 0; i < this._xyiconsWithLinks.length; ++i) {
			const xyicon3D = this._xyiconsWithLinks[i];

			xyicon3D.linkInstanceId = i;

			const crossPortfolioLinks = this._spaceViewRenderer.actions.getCrossPortfolioLinksXyiconXyicon(xyicon3D.modelData.id);

			if (crossPortfolioLinks.length > 0) {
				this._indicesWithCrossPortfolioLinks.add(i);
				this.changeColorOfIconBG(i, ...this._backgroundColorWithCrossPortfolioLinks);
			}
		}

		this._spaceViewRenderer.spaceItemController.updateLinkBreakers();
	}

	private changeColorOfIconBG(linkInstanceId: number, r: number, g: number, b: number) {
		if (linkInstanceId > -1 && this._icons) {
			const geometry = this._icons.geometry as InstancedBufferGeometry;
			const backgroundColorAttribute = geometry.attributes.backgroundColor as BufferAttribute;

			THREEUtils.setAttributeXYZ(backgroundColorAttribute, linkInstanceId, r, g, b);

			this._spaceViewRenderer.needsRender = true;
		}
	}

	private darkenIconBGColor(linkInstanceId: number) {
		this.changeColorOfIconBG(linkInstanceId, this._selectedBackgroundColor[0], this._selectedBackgroundColor[1], this._selectedBackgroundColor[2]);
	}

	private semiDarkenIconBGColor(linkInstanceId: number) {
		this.changeColorOfIconBG(linkInstanceId, this._hoveredBackgroundColor[0], this._hoveredBackgroundColor[1], this._hoveredBackgroundColor[2]);
	}

	private resetIconBGColor(linkInstanceId: number) {
		const bgColor = this._indicesWithCrossPortfolioLinks.has(linkInstanceId)
			? this._backgroundColorWithCrossPortfolioLinks
			: this._defaultBackgroundColor;

		this.changeColorOfIconBG(linkInstanceId, ...bgColor);
	}

	public mouseOver(linkInstanceId: number) {
		if (!this._selectedLinkInstanceIds.has(linkInstanceId)) {
			this.semiDarkenIconBGColor(linkInstanceId);
		}
	}

	public mouseOut(linkInstanceId: number) {
		if (!this._selectedLinkInstanceIds.has(linkInstanceId)) {
			this.resetIconBGColor(linkInstanceId);
		}
	}

	public selectIcon(linkInstanceId: number) {
		this._selectedLinkInstanceIds.add(linkInstanceId);
		this.darkenIconBGColor(linkInstanceId);
	}

	public deselectIcon(linkInstanceId: number) {
		this._selectedLinkInstanceIds.delete(linkInstanceId);
		this.resetIconBGColor(linkInstanceId);
	}

	public override removeIcons() {
		super.removeIcons();
		for (const xyicon of this._xyiconsWithLinks) {
			xyicon.linkInstanceId = -1;
		}
		this._xyiconsWithLinks.length = 0;

		this._spaceViewRenderer.spaceItemController.updateLinkBreakers();
	}

	private getXyiconsWithLinks() {
		const {actions} = this._spaceViewRenderer.transport.appState;

		return this._spaceViewRenderer.xyiconManager.items.array.filter((xyicon: Xyicon3D) =>
			actions.doesXyiconHaveLinkIcon(xyicon.modelData as Xyicon),
		) as Xyicon3D[];
	}

	public get selectedLinkInstanceIds() {
		return this._selectedLinkInstanceIds;
	}
}

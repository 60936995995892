import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import {Tool} from "./Tool";
import type {ToolType} from "./Tools";

/** Basically camera movement control with mouse left button */
export class PanTool extends Tool {
	protected override _toolType: ToolType = "pan";

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, false, "grab");
	}

	protected onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._spaceViewRenderer.toolManager.cameraControls.pointerDown(pointer, true);
	};

	protected onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._spaceViewRenderer.toolManager.cameraControls.pointerMove(pointer);
	};

	protected onPointerUpCallback = () => {
		this.reset();
	};

	private reset = () => {
		// Don't do anything
	};
}

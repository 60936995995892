// Based on Fyrestar's algorithm at https://mevedia.com (https://github.com/Fyrestar/THREE.BufferGeometry-toIndexed)

import {Box3, BufferAttribute, BufferGeometry, Sphere} from "three";
import type {TypedArray} from "three";

export class IndexBufferGeometryUtils {
	private static readonly _fullIndex: boolean = true;
	private static readonly _precision: number = 6;
	private static readonly _prec: number = Math.pow(10, IndexBufferGeometryUtils._precision);

	private static floor(array: TypedArray, offset: number) {
		if (array instanceof Float32Array) {
			return Math.floor(array[offset] * this._prec);
		} else {
			return array[offset];
		}
	}

	private static createAttribute(srcAttribute: BufferAttribute, list: number[]) {
		const srcArray = srcAttribute.array;
		const dstAttribute = new BufferAttribute(new (srcArray as any).constructor(length * srcAttribute.itemSize), srcAttribute.itemSize);

		const dstArray = dstAttribute.array;

		switch (srcAttribute.itemSize) {
			case 1:
				for (let i = 0, l = list.length; i < l; i++) {
					dstArray[i] = srcArray[list[i]];
				}
				break;
			case 2:
				for (let i = 0, l = list.length; i < l; i++) {
					const index = list[i] * 2;
					const offset = i * 2;

					dstArray[offset] = srcArray[index];
					dstArray[offset + 1] = srcArray[index + 1];
				}
				break;
			case 3:
				for (let i = 0, l = list.length; i < l; i++) {
					const index = list[i] * 3;
					const offset = i * 3;

					dstArray[offset] = srcArray[index];
					dstArray[offset + 1] = srcArray[index + 1];
					dstArray[offset + 2] = srcArray[index + 2];
				}
				break;
			case 4:
				for (let i = 0, l = list.length; i < l; i++) {
					const index = list[i] * 4;
					const offset = i * 4;

					dstArray[offset] = srcArray[index];
					dstArray[offset + 1] = srcArray[index + 1];
					dstArray[offset + 2] = srcArray[index + 2];
					dstArray[offset + 3] = srcArray[index + 3];
				}
				break;
		}

		return dstAttribute;
	}

	private static hashAttribute(attribute: BufferAttribute, offset: number) {
		const array = attribute.array;

		switch (attribute.itemSize) {
			case 1:
				return this.floor(array, offset);
			case 2:
				return `${this.floor(array, offset)}_${this.floor(array, offset + 1)}`;
			case 3:
				return `${this.floor(array, offset)}_${this.floor(array, offset + 1)}_${this.floor(array, offset + 2)}`;
			case 4:
				return `${this.floor(array, offset)}_${this.floor(array, offset + 1)}_${this.floor(array, offset + 2)}_${this.floor(array, offset + 3)}`;
		}
	}

	public static toIndexBufferGeometry(src: BufferGeometry) {
		// return src, if it's already indexed
		if (src.index) {
			return src;
		}

		const fullIndex = this._fullIndex;
		const prec = this._prec;

		const vertices: {[key: string]: number} = {};
		const list: number[] = [];

		const store = (index: number, n: number) => {
			let id = "";

			for (let i = 0, l = attributesKeys.length; i < l; i++) {
				const key = attributesKeys[i];
				const attribute = src.attributes[key] as BufferAttribute;

				const offset = attribute.itemSize * index * 3 + n * attribute.itemSize;

				id += `${this.hashAttribute(attribute, offset)}_`;
			}

			// for (let i = 0, l = morphKeys.length; i < l; i++)
			// {
			// 	const key = morphKeys[i];
			// 	const attribute = _src.morphAttributes[key];

			// 	const offset = attribute.itemSize * index * 3 + n * attribute.itemSize;

			// 	id += this.hashAttribute(attribute, offset) + '_';
			// }

			if (vertices[id] === undefined) {
				vertices[id] = list.length;
				list.push(index * 3 + n);
			}

			return vertices[id];
		};

		const storeFast = (x: number, y: number, z: number, v: number) => {
			const id = `${Math.floor(x * prec)}_${Math.floor(y * prec)}_${Math.floor(z * prec)}`;

			if (vertices[id] === undefined) {
				vertices[id] = list.length;
				list.push(v);
			}

			return vertices[id];
		};

		const dst = new BufferGeometry();

		const attributesKeys = Object.keys(src.attributes);
		//const morphKeys = Object.keys(src.morphAttributes);

		const position = (src.attributes.position as BufferAttribute).array;
		const faceCount = position.length / 3 / 3;

		const typedArray = faceCount * 3 > 65536 ? Uint32Array : Uint16Array;
		const indexArray = new typedArray(faceCount * 3);

		// Full index only connects vertices where all attributes are equal

		if (fullIndex) {
			for (let i = 0, l = faceCount; i < l; i++) {
				indexArray[i * 3] = store(i, 0);
				indexArray[i * 3 + 1] = store(i, 1);
				indexArray[i * 3 + 2] = store(i, 2);
			}
		} else {
			for (let i = 0, l = faceCount; i < l; i++) {
				const offset = i * 9;

				indexArray[i * 3] = storeFast(position[offset], position[offset + 1], position[offset + 2], i * 3);
				indexArray[i * 3 + 1] = storeFast(position[offset + 3], position[offset + 4], position[offset + 5], i * 3 + 1);
				indexArray[i * 3 + 2] = storeFast(position[offset + 6], position[offset + 7], position[offset + 8], i * 3 + 2);
			}
		}

		// Index

		dst.index = new BufferAttribute(indexArray, 1);

		length = list.length;

		// Attributes

		for (let i = 0, l = attributesKeys.length; i < l; i++) {
			const key = attributesKeys[i];

			dst.attributes[key] = this.createAttribute(src.attributes[key] as BufferAttribute, list);
		}

		// Morph Attributes

		// for (let i = 0, l = morphKeys.length; i < l; i++)
		// {
		// 	const key = morphKeys[i];
		// 	dst.morphAttributes[key] = this.createAttribute(src.morphAttributes[key] as BufferAttribute);
		// }

		if (src.boundingSphere) {
			dst.boundingSphere = src.boundingSphere.clone();
		} else {
			dst.boundingSphere = new Sphere();
			dst.computeBoundingSphere();
		}

		if (src.boundingBox) {
			dst.boundingBox = src.boundingBox.clone();
		} else {
			dst.boundingBox = new Box3();
			dst.computeBoundingBox();
		}

		// Groups

		const groups = src.groups;

		for (let i = 0, l = groups.length; i < l; i++) {
			const group = groups[i];

			dst.addGroup(group.start, group.count, group.materialIndex);
		}

		return dst;
	}
}

import * as ReactDOM from "react-dom/client";
import {useEffect, useReducer} from "react";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {MathUtils} from "../../../utils/math/MathUtils";
import type {IPopupWindowConfig} from "../../modules/abstract/popups/PopupWindow";
import type {DistanceUnitName, IDistanceUnit} from "../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import {SelectInputDropdownClass, SelectInputV5} from "../input/select/SelectInputV5";
import "./PopupWindowV5.scss";
import {zIndex} from "../styles/styles";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {PopupWindowV5} from "./PopupWindowV5";

interface IScaleValue {
	value: number;
	unit: IDistanceUnit;
	applyToAllSpaces: boolean;
}

export class SetScaleWindowV5 extends PopupWindowV5<IScaleValue | null> {
	protected static override readonly _defaultConfig: IPopupWindowConfig = {
		ok: "Confirm",
		cancel: "",
		backdrop: false,
	};
	private _selectedUnit: IDistanceUnit;
	private _units: IDistanceUnit[] = [];
	private _checkbox: HTMLInputElement = document.createElement("input");
	private _currentValue: number;

	protected _okValue: IScaleValue;
	protected _cancelValue: null = null;

	constructor(
		applyToAllSpacesEnabled: boolean,
		message: string,
		title: string,
		placeholder: string,
		selectedUnit: DistanceUnitName,
		config: IPopupWindowConfig,
	) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(SetScaleWindowV5._defaultConfig, config),
		});

		this._selectedUnit = Constants.DISTANCE_UNITS[selectedUnit];
		this._additionalElements = document.createElement("div");
		this._additionalElements.className = "vbox";

		const measurementsContainer = document.createElement("div");

		measurementsContainer.className = "hbox measurementsContainer";
		const input = document.createElement("input");

		input.type = "number";
		input.defaultValue = placeholder;
		input.min = "0";
		input.max = "1000000000";
		input.onkeyup = this.onInputFieldChange;
		input.onchange = this.onInputFieldChange;
		measurementsContainer.appendChild(input);

		requestAnimationFrame(() => {
			if (input?.parentElement) {
				input.focus();
			}
		});

		for (const key in Constants.DISTANCE_UNITS) {
			const unit = Constants.DISTANCE_UNITS[key as keyof typeof Constants.DISTANCE_UNITS];

			if (unit.name !== "foot&inch") {
				this._units.push(unit);
			}
		}

		const SelectComponent = () => {
			const [, forceUpdate] = useReducer((x) => x + 1, 0);

			useEffect(() => {
				const onKeyDown = (event: KeyboardEvent) => {
					if (event.key === KeyboardListener.KEY_ESCAPE) {
						const optionsElementMaybe = document.querySelector(`.${SelectInputDropdownClass}`);

						if (optionsElementMaybe) {
							// Don't remove from DOM, hide instead
							// because if we remove it, then react will throw some errors, because it tries to remove it as well
							// when focus is being lost, but it can't, because it's already removed
							optionsElementMaybe.classList.add("hidden");
						}
					}
				};

				KeyboardListener.getInstance().signals.down.add(onKeyDown);
				() => {
					KeyboardListener.getInstance().signals.down.remove(onKeyDown);
				};
			}, []);

			return (
				<SelectInputV5
					options={this._units}
					selected={this._selectedUnit}
					render={(item: IDistanceUnit) => StringUtils.capitalize(item.plural)}
					onChange={(unit: IDistanceUnit) => {
						this.onUnitChangeReact(unit);
						forceUpdate();
					}}
					optionsZIndex={zIndex.popup + 1}
					isSameWidth={true}
				/>
			);
		};

		const selectContainer = document.createElement("div");

		const ReactRoot = ReactDOM.createRoot(selectContainer);

		ReactRoot.render(<SelectComponent />);

		measurementsContainer.appendChild(selectContainer);

		this._additionalElements.appendChild(measurementsContainer);

		if (applyToAllSpacesEnabled) {
			const field = document.createElement("div");

			field.className = "ScaleField CheckboxInput";

			const label = document.createElement("label");

			label.className = "label";
			label.textContent = "Apply scale to all pages";

			field.appendChild(label);

			this._checkbox.type = "checkbox";
			this._checkbox.checked = false;
			this._checkbox.id = "applyAllSpacesCheckBox";

			this._checkbox.addEventListener("change", () => {
				this.updateOkValue();
			});

			field.appendChild(this._checkbox);

			const nextSiblingLabel = document.createElement("label");

			nextSiblingLabel.setAttribute("for", this._checkbox.id);
			field.appendChild(nextSiblingLabel);

			this._additionalElements.appendChild(field);
		}

		this.updateOkValue();
	}

	private onUnitChangeReact = (unit: IDistanceUnit) => {
		this._selectedUnit = unit;
	};

	private onInputFieldChange = (event: Event) => {
		this._currentValue = parseFloat(((event.currentTarget ?? event.target) as HTMLInputElement).value);
		this.updateOkValue();
	};

	private updateOkValue() {
		this._okValue = {
			value: this._currentValue,
			unit: this._selectedUnit,
			applyToAllSpaces: this._checkbox.checked,
		};

		if (MathUtils.isValidNumber(this._okValue.value) && this._okValue.value > 0) {
			super.enableOkButton();
		} else {
			super.disableOkButton();
		}
	}

	public static open(
		applyToAllSpacesEnabled: boolean,
		message: string,
		title: string = "Submit",
		placeholder: string = "",
		selectedUnit: DistanceUnitName,
		config: IPopupWindowConfig = {},
	) {
		return new SetScaleWindowV5(applyToAllSpacesEnabled, message, title, placeholder, selectedUnit, config).open() as Promise<IScaleValue>;
	}
}

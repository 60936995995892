import styled from "styled-components";
import {colorPalette} from "../../styles/colorPalette";
import {radius} from "../../styles/styles";
import {IconButtonStyled} from "../../interaction/IconButtonV5";

export const PropagatedFieldInDetailsTab = styled.div<{$onlyFieldValue: boolean}>`
	position: relative;
	margin-top: ${(props) => (props.$onlyFieldValue ? 0 : "2px")};
	display: flex;
	width: 100%;

	> div > * {
		border: none;
		width: 100%;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};
	}

	.buttonContainer {
		position: absolute;
		height: 100%;
		right: 0;
		background-color: ${colorPalette.gray.c200Light};
		display: flex;
		align-items: center;
		justify-content: flex-end;

		${IconButtonStyled} {
			&:hover {
				border-radius: ${radius.sm};
				border: 1px solid ${colorPalette.gray.c950};
			}

			svg {
				color: ${colorPalette.gray.c950};
			}
		}
	}
`;

import * as React from "react";
import {inject} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {PortTemplateDto} from "../../../../../../generated/api/base";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {AppState} from "../../../../../../data/state/AppState";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {Ports} from "../../../../abstract/sidepanel/tabs/details/Ports";
import {PortSelectArea} from "./PortSelectArea";

interface IPortSelectorProps {
	spaceViewRenderer: SpaceViewRenderer;
	item: Xyicon;
	worldX: number;
	worldY: number;
	ports: PortTemplateDto[];
	type: "from" | "to";
	appState?: AppState;
}

const firstRowSize: number = 77; // px
const portRowSize: number = 56; // px

@inject("appState")
export class PortSelector extends React.Component<IPortSelectorProps> {
	private _ref = React.createRef<HTMLDivElement>();

	private onPortSelected = (portId: string) => {
		const {linkManager} = this.props.spaceViewRenderer.toolManager;

		if (this.props.type === "from") {
			linkManager.enableLinkMode(portId);
		} else {
			linkManager.createLinks(portId);
			linkManager.disableLinkMode();
		}
	};

	// private getSelectables()
	// {
	// 	const selectables = [
	// 		<div
	// 			key={0}
	// 			className="selectable"
	// 			onClick={() => this.onPortSelected(null)}
	// 		>
	// 			Link to Xyicon (no port)
	// 		</div>
	// 	];

	// 	selectables.push(...this.props.portEndPoints.map((portData: PortTemplateDto, index: number) =>
	// 	{
	// 		return (
	// 			<div
	// 				key={index + 1}
	// 				className="selectable"
	// 				onClick={() => this.onPortSelected(portData.id)}
	// 			>
	// 				Link to {portData.label}
	// 			</div>
	// 		);
	// 	}));

	// 	return selectables;
	// }

	private onCameraMove = () => {
		this.forceUpdate();
	};

	private portDataToSelectArea = (portData: PortTemplateDto) => {
		return (
			<React.Fragment key={portData.id}>
				<PortSelectArea
					onClick={this.onPortSelected}
					portId={portData.id}
					leaf={portData.children.length === 0}
				/>
				{portData.children.map(this.portDataToSelectArea)}
			</React.Fragment>
		);
	};

	private getNumberOfRowsForPortData(portData: PortTemplateDto) {
		let numberOfRows: number = 1;

		for (const child of portData.children) {
			numberOfRows += this.getNumberOfRowsForPortData(child);
		}

		return numberOfRows;
	}

	private getNumberOfRowsForPortDataArray(portDataArray: PortTemplateDto[]) {
		let numberOfRows: number = 0;

		for (const portData of portDataArray) {
			numberOfRows += this.getNumberOfRowsForPortData(portData);
		}

		return numberOfRows;
	}

	public override componentWillUnmount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.onCameraMove);
	}

	public override componentDidMount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.onCameraMove);
		this.onCameraMove();
		this.forceUpdate();
	}

	public override render() {
		const {worldX, worldY, spaceViewRenderer, ports, item} = this.props;
		const worldZ = spaceViewRenderer.spaceOffset.z;
		const portSelectAreaHeight = portRowSize * (1 + this.getNumberOfRowsForPortDataArray(ports));

		return (
			<div
				ref={this._ref}
				className="PortSelector floatingDropdown vbox"
				style={THREEUtils.getStyleForFloatingUIElement(worldX, worldY, worldZ, spaceViewRenderer, true, "top", this._ref.current)}
			>
				<div className="hbox alignCenter header">
					Create a link...
					<IconButton
						icon="close"
						onClick={spaceViewRenderer.toolManager.linkManager.disableLinkMode}
					/>
				</div>
				<div className="wrapper">
					<div className="PortContainer">
						<div
							className="vbox"
							style={{position: "relative", top: "7.5px", height: `${firstRowSize}px`}}
						>
							<div
								className="darkSilverText"
								style={{marginBottom: "12.5px"}}
							>
								Hover over the xyicon or a port to start creating a link.
							</div>
							<div
								className="firstRow hbox alignCenter"
								style={{marginBottom: "7.5px"}}
							>
								<div
									className="thumbnail"
									style={{backgroundImage: `url('${item.thumbnail}')`, transform: item.backgroundTransform}}
								></div>
								<div className="bubble flexCenter">Link to Xyicon</div>
							</div>
						</div>
						<Ports item={item} />
					</div>
					<div
						className="portSelectAreaContainer"
						style={{height: `${portSelectAreaHeight}px`, marginTop: `-${portSelectAreaHeight + 8}px`}}
					>
						<PortSelectArea
							onClick={this.onPortSelected}
							portId={null}
							leaf={true}
						/>
						{ports.map(this.portDataToSelectArea)}
					</div>
				</div>
			</div>
		);
	}
}

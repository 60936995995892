import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {IModel} from "../../../data/models/Model";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import LocationPinIcon from "../icons/location-pin.svg?react";
import PenFieldIcon from "../icons/pen-field.svg?react";
import DeleteIcon from "../icons/delete.svg?react";

interface BoundaryViewProps {
	param1?: string;
	param2?: string;
}

export const BoundaryViewV5 = (props: BoundaryViewProps) => {
	const appState = useAppStore((state) => state.appState);

	const canDelete = (selectedItems: IModel[]) => {
		return selectedItems.every((item) => appState.actions.getModuleTypePermission(item.typeId, item.ownFeature) > Permission.Update);
	};

	{
		return (
			<ModuleViewV5
				feature={XyiconFeature.Boundary}
				actionBar={[
					{
						id: "locate",
						title: "Locate Boundary",
						label: "Locate",
						IconComponent: LocationPinIcon,
						visible: (selectedItems) => selectedItems.length === 1,
					},
					{
						id: "fieldUpdate",
						title: "Update Field",
						label: "Update Field",
						IconComponent: PenFieldIcon,
						visible: () => true,
					},
					{
						id: "delete",
						title: "Delete Boundary",
						label: "Delete",
						IconComponent: DeleteIcon,
						visible: (selectedItems) => canDelete(selectedItems),
					},
				]}
			/>
		);
	}
};

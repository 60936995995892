import * as React from "react";
import {inject, observer} from "mobx-react";
import type {DocumentModel} from "../../../../../../../../data/models/DocumentModel";
import {DateFormatter} from "../../../../../../../../utils/format/DateFormatter";
import {OptionsButton} from "../../../../../../../widgets/button/options/OptionsButton";
import type {IContextOption} from "../../../../../../../widgets/context/ContextOptions";
import type {TransportLayer} from "../../../../../../../../data/TransportLayer";
import {IconButton} from "../../../../../../../widgets/button/IconButton";
import {getFileTypeLabel} from "../../../../../../../../data/models/FileType";
import type {App} from "../../../../../../../../App";
import type {DocumentSectionItem} from "../DocumentSection";

interface IDocumentCardItemProps {
	documentItem: DocumentSectionItem;
	getOptions: (document: DocumentSectionItem) => IContextOption[];
	onPreviewClick: (document: DocumentModel) => void;
	app?: App;
	transport?: TransportLayer;
}

@inject("app")
@inject("transport")
@observer
export class DocumentCardItem extends React.Component<IDocumentCardItemProps> {
	public override render() {
		const {documentItem, transport, getOptions, onPreviewClick} = this.props;
		const doc = documentItem.document;

		const fileTypeLabel = getFileTypeLabel(doc.fileType);
		const thumbnailPath = transport.services.document.getThumbnailPath(doc);

		return (
			<div
				key={doc.id}
				className="DocumentCardItem"
				onClick={getOptions(documentItem)[0].onSelect}
			>
				<div
					className="thumb"
					style={{backgroundImage: `url(${thumbnailPath})`}}
				>
					{!thumbnailPath && fileTypeLabel}
				</div>
				<div className="top">
					<IconButton
						icon="start"
						onClick={() => onPreviewClick(doc)}
					/>
					<OptionsButton
						className="options flexCenter"
						options={getOptions(documentItem)}
					/>
				</div>
				<div className="bottom">
					<div className="description">{doc.fileName}</div>
					<div className="type">{fileTypeLabel}</div>
					<div className="date">{DateFormatter.format(doc.lastModifiedAt)}</div>
				</div>
			</div>
		);
	}
}

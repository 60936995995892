import {Observer} from "mobx-react";
import {useAppStore} from "../../../StateManager";
import type {User} from "../../../data/models/User";
import type {UserGroup} from "../../../data/models/UserGroup";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import type {BaseSharedPreference, GlobalBase, Permission} from "../../../generated/api/base";
import {XyiconFeature} from "../../../generated/api/base";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import {Functions} from "../../../utils/function/Functions";
import type {IGlobalViewFolderSharingSettingsData} from "./SharingPopupV5";
import {SharingPopupV5} from "./SharingPopupV5";
import {getLabelFromViewReportOrViewFolder} from "./SharingUtils";

const convertSharedToSharingSettings = (shared: BaseSharedPreference): IGlobalViewFolderSharingSettingsData[] => {
	const sharingSettings: IGlobalViewFolderSharingSettingsData[] = [];

	const userGroupIds = shared.userGroupIDs || [];
	const userIds = shared.userIDs || [];

	for (const userGroupId of userGroupIds) {
		sharingSettings.push({
			userGroupID: userGroupId,
		});
	}

	for (const userId of userIds) {
		sharingSettings.push({
			userID: userId,
		});
	}

	return sharingSettings;
};

interface IViewFolderSharingPopup {
	viewFolder: IViewFolder;
	onClose: () => void;
}

export const ViewFolderSharingPopup = (props: IViewFolderSharingPopup) => {
	const appState = useAppStore((state) => state.appState);
	const {viewFolder, onClose} = props;

	return (
		<Observer>
			{() => {
				const shared = (viewFolder as GlobalBase).shared;

				if (shared) {
					if (!shared.userIDs) {
						shared.userIDs = [];
					}
					if (!shared.userGroupIDs) {
						shared.userGroupIDs = [];
					}

					const userIds = shared.userIDs;
					const userGroupIds = shared.userGroupIDs;

					const administratorUserGroup = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup).find((ug) => ug.name === "Administrators");

					if (administratorUserGroup && !userGroupIds.includes(administratorUserGroup.id)) {
						userGroupIds.push(administratorUserGroup.id);
					}

					const sharingSettings = convertSharedToSharingSettings(shared);

					const onSaveChanges = () => {
						return appState.organization.saveGlobalViewsToDb();
					};

					const onAddClick = (id: string, permission: Permission.View | Permission.Update, feature: XyiconFeature.User | XyiconFeature.UserGroup) => {
						if (feature === XyiconFeature.User) {
							userIds.push(id);
						} else {
							userGroupIds.push(id);
						}

						return onSaveChanges();
					};

					const onDeleteUserGroup = (userGroup: UserGroup) => {
						ArrayUtils.removeMutable(userGroupIds, userGroup.id);

						return onSaveChanges();
					};

					const onDeleteUser = (user: User) => {
						ArrayUtils.removeMutable(userIds, user.id);

						return onSaveChanges();
					};

					return (
						<SharingPopupV5
							onAddClick={onAddClick}
							onDeleteUserGroup={onDeleteUserGroup}
							onDeleteUser={onDeleteUser}
							setUserGroupPermission={Functions.emptyFunction}
							onConfirmOwnershipChange={Functions.emptyFunction}
							onSaveChanges={onSaveChanges}
							sharingSettings={sharingSettings}
							ownedByUserId=""
							itemLabel={getLabelFromViewReportOrViewFolder(viewFolder)}
							itemName={viewFolder.name}
							onClose={onClose}
							showShareOption={false}
						/>
					);
				} else {
					console.warn(`You're trying to share a non-global view?`);

					return <></>;
				}
			}}
		</Observer>
	);
};

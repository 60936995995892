import * as React from "react";
import type {FillOpacity} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";

interface IFillOptionsProps {
	onChange: (value: FillOpacity) => void;
}

export class FillOptions extends React.Component<IFillOptionsProps> {
	private onFillClick = () => {
		this.props.onChange(1);
	};

	private onSemiFillClick = () => {
		this.props.onChange(0.1);
	};

	private onNoFillClick = () => {
		this.props.onChange(0);
	};

	public override render() {
		return (
			<div className="FillOptions vbox">
				<div
					className="fillOption fill"
					title="Fill"
					onClick={this.onFillClick}
				/>
				<div
					className="fillOption semiFill"
					title="Semi Fill"
					onClick={this.onSemiFillClick}
				/>
				<div
					className="fillOption noFill"
					title="No Fill"
					onClick={this.onNoFillClick}
				/>
			</div>
		);
	}
}

import type {Object3D, Mesh, Line} from "three";
import {GrabbableCorner} from "../../elements3d/GrabbableCorner";
import type {EditableSpaceItem} from "../../elements3d/EditableSpaceItem";
import {Constants} from "../../Constants";
import type {Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import type {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import type {Markup} from "../../../../../../../data/models/Markup";
import type {IEditableItemModel} from "../../../../../../../data/models/Model";
import {MarkupType, XyiconFeature} from "../../../../../../../generated/api/base";
import {ItemManager} from "./ItemManager";

export abstract class EditableItemManager extends ItemManager {
	protected _currentlyEditedItem: EditableSpaceItem;
	private _intersectables: Object3D[] = [];

	public switchEditMode(enabled: boolean) {
		if (enabled) {
			this._currentlyEditedItem = this._selectedItems[0] as EditableSpaceItem;
			this._currentlyEditedItem.switchEditMode(true, false);
		} else {
			this._currentlyEditedItem?.switchEditMode(false, true);
			this._currentlyEditedItem = null;
		}
	}

	public onGrabbableCornerPointerDown(grabbableCorner: GrabbableCorner) {
		if (this._currentlyEditedItem) {
			this._currentlyEditedItem.onGrabbableCornerPointerDown(grabbableCorner);
		}
	}

	public onGrabbableCornerPointerMove(deltaX: number, deltaY: number) {
		if (this._currentlyEditedItem) {
			this._currentlyEditedItem.onGrabbableCornerPointerMove(deltaX, deltaY);
		}
	}

	public onGrabbableCornerPointerUp() {
		if (this._currentlyEditedItem) {
			this._currentlyEditedItem.onGrabbableCornerPointerUp();
			this.updateSelectionBox();
		}
	}

	public getIntersectables() {
		const filteredItems = this._items.array.filter((item: EditableSpaceItem) => !!item.modelData);
		const intersectablesObjects = filteredItems.map((item: EditableSpaceItem) => item.intersectables);

		this._intersectables.length = 0;

		const selectedItems = this._spaceViewRenderer.spaceItemController.selectedItems;

		const currentlyEditedItem = this._spaceViewRenderer.spaceItemController.currentlyEditedItem;

		for (const intersectableObject of intersectablesObjects) {
			const spaceItem = intersectableObject.userData.spaceItem as SpaceItem;

			if (spaceItem) {
				intersectableObject.traverseVisible((node: Object3D) => {
					if ((node as Mesh).isMesh || (node as Line).isLine) {
						if (currentlyEditedItem) {
							const isPartOfEditedItem = currentlyEditedItem.isObjectPartOfThis(node);

							if (isPartOfEditedItem) {
								this._intersectables.push(node);
							}
						} else {
							if (node.name === "textArea" && spaceItem.spaceItemType === "markup") {
								const isItTheOnlySelectedOne = selectedItems.length === 1 && selectedItems[0] === spaceItem;

								if (MarkupType.Text_Box === (spaceItem as Markup3D).type || isItTheOnlySelectedOne) {
									this._intersectables.push(node);
								}
							} else {
								this._intersectables.push(node);
							}
						}
					}
				});
			}
		}

		return this._intersectables;
	}

	public cancelEditMode() {
		if (this._currentlyEditedItem) {
			const {modelData} = this._currentlyEditedItem;

			if (modelData.ownFeature === XyiconFeature.Markup) {
				// We need this, because the markupcallout-target is saved in the settings,
				// and we need the ability to cancel any modifications to it here
				(modelData as Markup).resetSettingsToSaved();
			}

			this._currentlyEditedItem.updateByModel(this._currentlyEditedItem.modelData as IEditableItemModel, false);

			if (modelData.ownFeature === XyiconFeature.Boundary) {
				this._spaceViewRenderer.boundaryManager.captionManager.updateCaptions([this._currentlyEditedItem as BoundarySpaceMap3D]);
			}
		}
		this._currentlyEditedItem = null;
	}

	public get isInEditMode() {
		return !!this._currentlyEditedItem;
	}

	public get currentlyEditedItem() {
		return this._currentlyEditedItem;
	}

	public get snapThreshold() {
		const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;

		return (this._spaceViewRenderer.correctionMultiplier.current * GrabbableCorner.RADIUS * Constants.SIZE.GRABBABLE_ITEM_PX) / cameraZoomLevel;
	}
}

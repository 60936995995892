import * as React from "react";

export interface ISingleLineLabelProps {
	value: string;
	dataTypeSettings?: any;
}

export class SingleLineLabel extends React.PureComponent<ISingleLineLabelProps> {
	public override render() {
		const {value} = this.props;

		return (
			<div
				className="SingleLineLabel"
				dangerouslySetInnerHTML={{__html: value || ""}}
			/>
		);
	}
}

import * as React from "react";
import type {Mesh, MeshPhongMaterial} from "three";
import {SceneManager} from "../../space/spaceeditor/logic3d/features/SceneManager";
import type {LibraryModel} from "../../../../data/models/LibraryModel";
import {THREEUtils} from "../../../../utils/THREEUtils";
import type {Color, Dimensions} from "../../../../generated/api/base";

interface IModelPreviewerProps {
	libraryModel: LibraryModel;
	color?: Color;
	dimensions?: Dimensions;
	children?: React.ReactNode;
}

export class ModelPreviewer extends React.Component<IModelPreviewerProps> {
	private _canvasRef = React.createRef<HTMLCanvasElement>();
	private _mesh: Mesh;
	private _sceneManager: SceneManager;

	private get material() {
		return this._mesh?.material as MeshPhongMaterial;
	}

	public override async UNSAFE_componentWillReceiveProps(nextProps: IModelPreviewerProps) {
		if (nextProps.libraryModel !== this.props.libraryModel) {
			this._mesh = await nextProps.libraryModel.getPreviewMesh();
			this._sceneManager?.replaceMesh(this._mesh);
		}

		if (nextProps.color !== this.props.color) {
			this.updateColor(nextProps.color);
		}

		const newDimensions = nextProps.dimensions;

		if (newDimensions) {
			const dimensionsChanged =
				newDimensions?.x !== this.props.dimensions?.x ||
				newDimensions?.y !== this.props.dimensions?.y ||
				newDimensions?.z !== this.props.dimensions?.z;

			if (dimensionsChanged) {
				this.updateDimensions(newDimensions);
			}
		}
	}

	private updateDimensions(dimensions: Dimensions) {
		if (dimensions?.x && dimensions?.y && dimensions?.z) {
			const previousDimensions = THREEUtils.getDimensionsOfObject(this._mesh);

			const ratios = {
				x: dimensions.x / previousDimensions.x,
				y: dimensions.y / previousDimensions.y,
				z: dimensions.z / previousDimensions.z,
			};

			this._mesh.scale.x *= ratios.x;
			this._mesh.scale.y *= ratios.y;
			this._mesh.scale.z *= ratios.z;

			this._mesh.updateMatrixWorld(true);
			this._sceneManager.updateCameraProps();

			this._sceneManager.needsRender = true;
		}
	}

	private updateColor(color: Color) {
		const material = this.material;

		if (color && material) {
			material.color.set(`#${color.hex}`);
			material.opacity = 1 - color.transparency;

			const newTransparentValue = material.opacity < 1;

			if (newTransparentValue !== material.transparent) {
				material.transparent = newTransparentValue;
				material.needsUpdate = true;
			}

			this._sceneManager.needsRender = true;
		}
	}

	public get mesh() {
		return this._mesh;
	}

	public override async componentDidMount() {
		this._mesh = await this.props.libraryModel.getPreviewMesh();
		this._sceneManager = new SceneManager(this._canvasRef.current, this._mesh);

		this.updateColor(this.props.color);
		this.updateDimensions(this.props.dimensions);
	}

	public override componentWillUnmount() {
		this._sceneManager?.dispose();
	}

	public override render() {
		return (
			<div className="ModelPreviewer">
				<canvas ref={this._canvasRef}></canvas>
				{this.props.children}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {observable, makeObservable} from "mobx";
import {TabView} from "../../../widgets/tab/TabView";
import {TabChild} from "../../../widgets/tab/TabChild";
import {FilterEditor} from "../filter/FilterEditor";
import type {IModel} from "../../../../data/models/Model";
import {XyiconFeature} from "../../../../generated/api/base";
import {filterModels} from "../../../../data/models/filter/Filter";
import type {Catalog} from "../../../../data/models/Catalog";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {FeatureService} from "../../../../data/services/FeatureService";
import {Formatter} from "../../../../utils/format/Formatter";
import type {AppState} from "../../../../data/state/AppState";
import {GridTab} from "./tabs/grid/GridTab";
import {DetailsTab} from "./tabs/details/DetailsTab";

interface ISidePanelProps<T extends IModel> {
	features: XyiconFeature[];
	feature: XyiconFeature;
	items: T[];
	selected: T[];
	showGridForFeatures: XyiconFeature[];
	isPortTemplateEditorOpen: boolean;
	onSelect: (item: T[], disableTabChange?: boolean) => void;
	loading?: boolean;
	setPortTemplateEditorOpen: (value: boolean) => void;
	onManageColumns: () => void;
	onClick: (item: T) => void;
	onCatalogIconEditClick?: (catalog: Catalog) => void;
	transport?: TransportLayer;
	appState?: AppState;
}

@inject("appState")
@inject("transport")
@observer
export class SidePanel<T extends IModel> extends React.Component<ISidePanelProps<T>> {
	private _tabView = React.createRef<TabView>();

	@observable
	private _filterEditor = React.createRef<FilterEditor>();

	constructor(props: ISidePanelProps<T>) {
		super(props);
		makeObservable(this);
	}

	public getSelectedGridTabFeature(): XyiconFeature {
		const tabId = this._tabView.current?.state.selectedTabId;

		if (tabId) {
			if (tabId.includes("grid-")) {
				const feature = Number(tabId.split("-")[1]);

				if (feature) {
					return feature;
				}
			}
		}
		return XyiconFeature.Xyicon;
	}

	public selectTab = (id: string) => {
		this._tabView.current?.selectTab(id);
	};

	public get filterEditor() {
		return this._filterEditor.current;
	}

	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	private getFilteredItems(feature: XyiconFeature): T[] {
		let items = this.props.items.filter((item: IModel) => item.ownFeature === feature);

		const appState = this.props.transport.appState;

		// Apply local filters
		items = filterModels(items, this.selectedView.filters, appState, this.props.feature);

		return items as T[];
	}

	private getTabChildren() {
		const {items, features, feature, selected, showGridForFeatures, loading, onClick, onSelect, onManageColumns} = this.props;

		const selectedFeature = selected[0]?.ownFeature || features[0] || XyiconFeature.Portfolio;

		const children: React.JSX.Element[] = [];

		const detailsTabProps = {
			items: selected,
			feature: selectedFeature,
			features: features,
			isPortTemplateEditorOpen: this.props.isPortTemplateEditorOpen,
			setPortTemplateEditorOpen: this.props.setPortTemplateEditorOpen,
			onCatalogIconEditClick: this.props.onCatalogIconEditClick,
			onSelect: this.props.onSelect,
			noInitials: this.props.feature === XyiconFeature.Report,
		};

		for (const showGridForFeature of showGridForFeatures) {
			const selectedView = this.actions.getSelectedView(feature);
			const icon = showGridForFeatures.length === 1 ? "grid" : FeatureService.getApiNameForFeature(showGridForFeature);
			const title = feature === XyiconFeature.Space ? "Spaces" : Formatter.capitalize(icon);

			children.push(
				<TabChild
					id={`grid-${showGridForFeature}`}
					icon={icon}
					title={title}
					key="grid"
					label={title}
				>
					<GridTab
						items={this.getFilteredItems(showGridForFeature)}
						selected={selected}
						view={selectedView}
						feature={showGridForFeature}
						onClick={onClick}
						onManageColumns={onManageColumns}
						onSelect={(item: T[]) => onSelect(item, true)}
					/>
				</TabChild>,
			);
		}

		if (features[0] !== XyiconFeature.Report) {
			children.push(
				<TabChild
					id="filter"
					icon="filter"
					title="Filter"
					key="filter"
					label="Filter"
				>
					<FilterEditor
						ref={this._filterEditor}
						features={features}
						feature={feature}
						items={items}
						onSelect={onSelect}
						loader={loading}
					/>
				</TabChild>,
			);
		}

		children.push(
			<TabChild
				id="details"
				icon="details"
				title="Details"
				key="details"
				label="Details"
			>
				{<DetailsTab {...detailsTabProps} />}
			</TabChild>,
		);

		return children;
	}

	private get actions() {
		return this.props.transport.appState.actions;
	}

	public override render() {
		return (
			<TabView
				ref={this._tabView}
				className="SidePanel"
				selectedTabId="filter"
				feature={this.props.feature}
			>
				{this.getTabChildren()}
			</TabView>
		);
	}
}

import * as React from "react";
import {useAppStore} from "../../StateManager";
import type {IModel} from "../../data/models/Model";
import type {AppState} from "../../data/state/AppState";
import type {AppNotifications} from "../notification/AppNotifications";
import {ModalStyled, RootStyled} from "./Root.styled";
import {Flex, VerticalFlex} from "./styles/styles";
import {TopBarV5} from "./topbar/TopBarV5";
import {Main} from "./Main";
import {Navigation} from "./navigation/Navigation";
import {DetailsContainerV5} from "./details/DetailsContainerV5";
import {AppNotificationsV5} from "./popup/AppNotificationsV5";

interface IRootProps {
	readonly appState: AppState;
	readonly notificationContainer: React.RefObject<AppNotifications>;
	readonly modalContainer: React.RefObject<HTMLDivElement>;
	readonly view: React.JSX.Element;
	readonly items: IModel[];
	readonly closeDetailsContainer: (closeAll?: boolean) => void;
	readonly closeWideSearchPanel: () => void;
}

export function Root(props: IRootProps) {
	const appState = useAppStore((state) => state.appState);
	const setAppState = useAppStore((state) => state.setAppState);
	const isRootFrozen = useAppStore((state) => state.isRootFrozen);

	if (appState !== props.appState) {
		setAppState(props.appState);
	}

	return (
		<>
			<AppNotificationsV5 ref={props.notificationContainer as unknown as React.RefObject<AppNotificationsV5>} />
			<ModalStyled
				id="modalContainer"
				ref={props.modalContainer}
			/>
			<RootStyled $isFrozen={isRootFrozen}>
				<Flex
					$flex="1"
					style={{minWidth: 0}}
				>
					<Navigation />
					<VerticalFlex
						$flex="1"
						style={{minWidth: 0}}
					>
						<TopBarV5 />
						<Main view={props.view} />
						<DetailsContainerV5
							items={props.items}
							onClose={props.closeDetailsContainer}
							closeWideSearchPanel={props.closeWideSearchPanel}
						/>
					</VerticalFlex>
				</Flex>
			</RootStyled>
		</>
	);
}

import type {PointDouble, XyiconFeature, MarkupType} from "../../generated/api/base";
import type {Type} from "./Type";

export interface ISpaceItemModel extends IModel {
	applyData: (data: any) => void;
	thumbnail?: string;
	refId?: string;
	type: Type | MarkupType;
	spaceId: string;
	setSpaceId?: (spaceId: string) => void; // boundaries can belong to multiple spaces, eg.: boundary has 2 boundaryspacemaps, and they each belong to a different space
}

// Note: we could use an abstract base class instead of an interface but
// mobx had some problems in that case..inherited
export interface IModel extends IModelData {
	id: string;
	data?: Record<string, any>;
	refId?: string;
	name?: string;
	fieldData?: {
		[fieldRefId: string]: any;
	};
	typeId?: string;
	typeName?: string;
	thumbnail?: string;
	thumbnailFileURL?: string; // TODO remove this?
	applyData?: (data: any) => void;
	applyUpdate?: (data: any) => void;
	applyFieldValues?: (data: any) => void;

	// inherited connections
	portfolioId?: string;
	catalogId?: string;
	spaceId?: string;

	ownFeature?: XyiconFeature; // for example View.ownFeature = XyiconFeature.View
	itemFeature?: XyiconFeature; // for example View.itemFeature can be XyiconFeature.Boundary
}

export const getModelId = (model: IModel) => model.id;

export interface IEditableItemModel extends IModel {
	guid?: string; // for cut-paste
	geometryData: PointDouble[];
	orientation?: number;
	color?: string;
}

export interface IModelData {
	lastModifiedBy?: string;
	lastModifiedAt?: string;
}

import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {App} from "./App";
import {appHolder} from "./utils/AppUtils";

const root = ReactDOM.createRoot(appHolder());

root.render(<App />);

if (!(location.host.includes("localhost") || location.host.includes("127.0.0.1"))) {
	Sentry.init({
		dsn: "https://e2f3622c524e4127985ca792aab52f2f@o4504220242608128.ingest.sentry.io/4504220248440832",
		integrations: [new BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

import * as React from "react";

interface ViewStackProps {
	active: string;
	children?: IChild[];
}

type IChild = React.ReactElement<{id: string}>;

export class ViewStack extends React.Component<ViewStackProps> {
	public override render() {
		const {active} = this.props;

		const children = React.Children.toArray(this.props.children).filter((child) => !!child) as IChild[];
		const activeChild = children.filter((child) => child.props.id === active) || children[0];

		return activeChild;
	}
}

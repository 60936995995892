import * as React from "react";
import type {INumericFieldSettingsDefinition} from "../../../../../../data/models/field/datatypes/Numeric";
import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../../../generated/api/base";

interface INumericLabelProps {
	value: number;
	dataTypeSettings: INumericFieldSettingsDefinition;
}

export class NumericLabel extends React.PureComponent<INumericLabelProps> {
	public override render() {
		let {value, dataTypeSettings} = this.props;

		if (value === undefined || value === null) {
			return "";
		}

		if (typeof value === "string") {
			console.warn(
				"Numeric field is stored as a string in the database. We're converting it to a number to prevent crashes on the frontend, but ideally, it needs to be converted in the database at some point.",
			);
			value = Number(value);
		}

		value = value || 0;

		return FieldDataTypes.map[FieldDataType.Numeric].formatter?.(value, dataTypeSettings) ?? value.toFixed(dataTypeSettings.decimals);
	}
}

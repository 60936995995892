import * as React from "react";
import {SpaceEditorSettings} from "../spacecontrolbar/SpaceEditorSettings";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {PointerDetector} from "../../../../../../utils/interaction/PointerDetector";

interface ISpaceContextMenuProps {
	spaceViewRenderer: SpaceViewRenderer;
	worldX: number;
	worldY: number;
	onClose: () => void;
	onCopyClick: () => void;
	onCopyToStampClick: () => void;
	onCutClick: () => void;
	onDeleteClick: () => void;
	onPasteClick: () => void;
	onRealignSpaceClick: () => void;
}

export class SpaceContextMenu extends React.Component<ISpaceContextMenuProps> {
	private _ref = React.createRef<HTMLDivElement>();

	private onCopyToClipboardClick = () => {
		this.props.onClose();
		this.props.onCopyClick();
	};

	private onCopyToStampClick = () => {
		this.props.onClose();
		this.props.onCopyToStampClick();
	};

	private onCutClick = () => {
		this.props.onClose();
		this.props.onCutClick();
	};

	private onPasteClick = () => {
		this.props.onClose();
		this.props.onPasteClick();
	};

	private onDeleteClick = () => {
		this.props.onClose();
		this.props.onDeleteClick();
	};

	public override componentDidMount() {
		const {signals} = this.props.spaceViewRenderer.toolManager.cameraControls;

		signals.cameraGrabbed.add(this.props.onClose);
		signals.cameraPropsChange.add(this.props.onClose);

		// ref.current is null at the first render, so we have to recalculate the position immediately
		this.forceUpdate();
	}

	public override componentWillUnmount() {
		const {signals} = this.props.spaceViewRenderer.toolManager.cameraControls;

		signals.cameraGrabbed.remove(this.props.onClose);
		signals.cameraPropsChange.remove(this.props.onClose);
	}

	public override render() {
		const {worldX, worldY, spaceViewRenderer} = this.props;
		const worldZ = spaceViewRenderer.spaceOffset.z;
		const selectedItems = spaceViewRenderer.spaceItemController.selectedItems;

		const isCopyEnabled = selectedItems.length > 0 && !spaceViewRenderer.ghostModeManager.isActive;

		const selectableClasses = "selectable hbox";
		const hotkeyClasses = "marginLeftAuto display_flex alignCenter";

		return (
			<div
				ref={this._ref}
				className="SpaceContextMenu floatingDropdown vbox"
				style={THREEUtils.getStyleForFloatingUIElement(worldX, worldY, worldZ, spaceViewRenderer, true, "bottom", this._ref.current)}
				onContextMenu={PointerDetector.onContextMenu}
			>
				<div
					className={ReactUtils.cls(selectableClasses, {disabled: !isCopyEnabled})}
					onClick={this.onCopyToClipboardClick}
				>
					<span>Copy</span>
					<span className={hotkeyClasses}>Ctrl + C</span>
				</div>
				<div
					className={ReactUtils.cls(selectableClasses, {disabled: !isCopyEnabled})}
					onClick={this.onCutClick}
				>
					<span>Cut</span>
					<span className={hotkeyClasses}>Ctrl + X</span>
				</div>
				<div
					className={ReactUtils.cls(selectableClasses, {disabled: !isCopyEnabled})}
					onClick={this.onCopyToStampClick}
				>
					<span>Copy to Stamp</span>
					<span className={hotkeyClasses}>Ctrl + S</span>
				</div>
				<div
					className={ReactUtils.cls(selectableClasses, {disabled: isCopyEnabled})}
					onClick={this.onPasteClick}
				>
					<span>Paste</span>
					<span className={hotkeyClasses}>Ctrl + V</span>
				</div>
				<div
					className={ReactUtils.cls(selectableClasses, {disabled: !isCopyEnabled})}
					onClick={this.onDeleteClick}
				>
					<span>Delete</span>
					<span className={hotkeyClasses}>Del</span>
				</div>
				<div className={`${selectableClasses} spaceEditorSettings`}>
					<span>Settings</span>
					<span className={hotkeyClasses}>▶</span>
					<SpaceEditorSettings
						spaceViewRenderer={spaceViewRenderer}
						onRealignSpaceClick={this.props.onRealignSpaceClick}
						onClose={this.props.onClose}
						closeOnBlur={false}
					/>
				</div>
			</div>
		);
	}
}

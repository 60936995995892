import {computed, observable, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {typesFeatures} from "../state/AppStateConstants";
import {XyiconFeature} from "../../generated/api/base";
import type {Color, FeatureTypeDto} from "../../generated/api/base";
import type {IRealSize} from "../../ui/modules/settings/modules/type/form/RealSizeInput";
import type {ICardLayout} from "../../ui/modules/settings/modules/type/form/CardLayoutEditor";
import {getDefaultCardLayout} from "../../ui/modules/settings/modules/type/form/CardLayoutEditor";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import type {ExtendedDistanceUnitName} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {IModel} from "./Model";

interface ITypeSettings {
	color?: Color;
	unitOfMeasure?: ExtendedDistanceUnitName;
	xyiconSize?: IRealSize;
	boundaryHeight?: IRealSize;
	cardLayout?: {
		[key in XyiconFeature]?: ICardLayout;
	};
	xyiconLookupLinkFieldList?: string[];
}

export interface ITypeData extends FeatureTypeDto {
	feature: XyiconFeature;
	featureTypeID: string; //"f25f4914-6115-45fb-a001-aaaaaaab0001"
	isSystem: boolean;
	lastModifiedAt: string; //"2019-07-14T14:56:49.6667211+00:00"
	lastModifiedBy: string; //"f25f4914-6115-45fb-a000-aaaaaaaa0014"
	name: string; //"Hospital"
	organizationID: string; //"f25f4914-6115-45fb-a001-aaaaaaaaaaaa"
	settings: ITypeSettings;
}

export class Type implements IModel {
	public static readonly defaultColor: Color = {
		hex: "2296f3",
		transparency: 0,
	};

	public static readonly defaultXyiconSize: IRealSize = {
		value: 12,
		unit: "inch",
	};

	public static readonly defaultBoundaryHeight: IRealSize = {
		value: 9,
		unit: "foot",
	};

	private _appState: AppState;

	@observable
	private _data: ITypeData;

	@observable
	private _savedData: ITypeData;

	constructor(data: Partial<ITypeData>, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data as ITypeData);
	}

	public applyData(data: ITypeData) {
		this._data = data;

		if (!this._data.settings || typeof this._data.settings !== "object") {
			this._data.settings = {};
		}

		if (!this._data.settings.color) {
			this._data.settings.color = {...Type.defaultColor};
		}

		if (this._data.feature === XyiconFeature.Space) {
			if (!this._data.settings.xyiconSize) {
				this._data.settings.xyiconSize = {...Type.defaultXyiconSize};
			}
		} else if (this._data.feature === XyiconFeature.Boundary) {
			if (!this._data.settings.boundaryHeight) {
				this._data.settings.boundaryHeight = {...Type.defaultBoundaryHeight};
			}
		} else if (this._data.feature === XyiconFeature.Xyicon) {
			if (!this._data.settings.xyiconLookupLinkFieldList) {
				this._data.settings.xyiconLookupLinkFieldList = [];
			}
		}

		if (!this._data.settings.cardLayout) {
			this._data.settings.cardLayout = {};
		}
		if (!this._data.settings.cardLayout[this.feature]) {
			this._data.settings.cardLayout[this.feature] = getDefaultCardLayout(this.feature);
		}

		for (const key in typesFeatures) {
			const keyAsFeature = parseInt(key as any) as XyiconFeature;

			if (typesFeatures[keyAsFeature] === this.feature) {
				if (!this._data.settings.cardLayout[keyAsFeature]) {
					this._data.settings.cardLayout[keyAsFeature] = getDefaultCardLayout(keyAsFeature);
				}
			} else if (keyAsFeature === this.feature) {
				if (!this._data.settings.cardLayout[typesFeatures[keyAsFeature]]) {
					this._data.settings.cardLayout[typesFeatures[keyAsFeature]] = getDefaultCardLayout(keyAsFeature);
				}
			}
		}

		this._savedData = ObjectUtils.deepClone(this._data);
	}

	public applyUpdate(data: Partial<ITypeData>) {
		this._data.lastModifiedAt = data?.lastModifiedAt || this._data.lastModifiedAt;
		this._data.lastModifiedBy = data?.lastModifiedBy || this._data.lastModifiedBy;
	}

	@computed
	public get id() {
		return this._data.featureTypeID;
	}

	@computed
	public get savedData() {
		return this._savedData;
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	@computed
	public get isSystem() {
		return this._data.isSystem;
	}

	@computed
	public get feature() {
		return this._data.feature;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get settings() {
		//
		// Replace deleted references with the default ones
		//
		const cardLayout = this._data.settings?.cardLayout?.[this.feature];

		if (cardLayout) {
			const defaultCardLayout = getDefaultCardLayout(this.feature);

			for (const key in cardLayout) {
				const savedValue = cardLayout[key as keyof ICardLayout];
				const isSavedValueAFieldRefId = /f[0-9]+$/g.test(savedValue);

				if (isSavedValueAFieldRefId) {
					const field = this._appState.actions.getFieldByRefId(savedValue);

					if (!field) {
						// If the field doesn't exist anymore
						cardLayout[key as keyof ICardLayout] = defaultCardLayout[key as keyof ICardLayout];
					}
				}
			}
		}

		return this._data.settings;
	}

	@computed
	public get data() {
		return this._data;
	}
}

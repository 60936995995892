import {useState} from "react";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {PortfolioWideSearch} from "../../search/PortfolioWideSearch";
import {appHolder} from "../../../utils/AppUtils";

export const MainSearchV5 = () => {
	const [queryString, setQueryString] = useState<string>("");
	const [isSearchInputOpen, setIsSearchInputOpen] = useState<boolean>(false);

	const onChangeSearch = (value: string) => {
		setQueryString(value);

		if (value) {
			setIsSearchInputOpen(true);
		}
	};

	const closeSearchInput = () => {
		setQueryString("");
		setIsSearchInputOpen(false);
	};

	return (
		<div>
			<SearchFieldV5
				value={queryString}
				onChange={onChangeSearch}
			/>
			{isSearchInputOpen && (
				<DomPortal destination={appHolder()}>
					{/*  temporarily using V4 style */}
					<div className="V4">
						<PortfolioWideSearch
							query={queryString}
							loading={false}
							closeSearchInput={closeSearchInput}
						/>
					</div>
				</DomPortal>
			)}
		</div>
	);
};

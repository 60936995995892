import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import type {Xyicon3D} from "../../elements3d/Xyicon3D";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import {getActiveCaptionFields, getFeatureBySpaceItemType} from "../../renderers/SpaceViewRendererUtils";
import type {IFieldAdapter} from "../../../../../../../data/models/field/Field";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import {DebugInformation} from "../../../../../../../utils/DebugInformation";
import type {Collection} from "../../../../../../../data/models/abstract/Collection";
import {textPartsToTextContent} from "./TextUtils";
import {TextGroupManager} from "./TextGroupManager";

export type CaptionedItem = Xyicon3D | BoundarySpaceMap3D;

export class CaptionManager extends TextGroupManager {
	private _type: "boundary" | "xyicon";
	protected _instancedMeshName: string = "captionTexts";

	constructor(spaceViewRenderer: SpaceViewRenderer, type: "boundary" | "xyicon") {
		super(spaceViewRenderer, false);
		this._type = type;
		this._instancedMeshName += ` - ${this._type}`;
	}

	private get feature() {
		return getFeatureBySpaceItemType(this._type);
	}

	private get itemManager() {
		return this._spaceViewRenderer.getItemManager(this.feature);
	}

	public getActiveCaptionFields() {
		const {actions} = this._spaceViewRenderer;

		return getActiveCaptionFields(this.feature, actions);
	}

	public async updateCaptions(items: CaptionedItem[] = this._items.array) {
		if (items.length > 0) {
			const logId = `Updating ${this._type} captions`;

			DebugInformation.start(logId);
			const captionFields: IFieldAdapter[] = this.getActiveCaptionFields();

			for (const item of items) {
				item.addCaption(captionFields);
			}

			this.recreateGeometry();
			await this.updateCaptionPositions(items);
			DebugInformation.end(logId);
		}
	}

	public updateCaptionPositions(items: CaptionedItem[] = this._items.array): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			// Workaround to avoid "flickering"
			this._spaceViewRenderer.needsRender = false;

			const update = () => {
				const collisionSolver = this._spaceViewRenderer.spaceItemController.captionCollisionSolver;
				const sortFunction = (a: SpaceItem, b: SpaceItem) => (a.modelData?.id < b.modelData?.id ? 1 : -1);

				if (ObjectUtils.compare(this._items.array.sort(sortFunction), items.sort(sortFunction))) {
					const captionedItems = items.filter(CaptionManager.filterVisibleCaptionedItems) as CaptionedItem[];

					collisionSolver.updateCaptions(captionedItems);
				} else {
					const itemsWithValidPositions = items.filter((item) => item.position);
					const bbox = THREEUtils.calculateBox(itemsWithValidPositions.map((item) => item.position));
					const scale = THREEUtils.getSizeOfBoundingBox2(bbox);
					const center = THREEUtils.getCenterOfBoundingBox(bbox);

					const maxRadius = Math.max(scale.x, scale.y) + this._spaceViewRenderer.xyiconManager.xyiconSize * 10;

					collisionSolver.updateCaptionsInARadius(center.x, center.y, maxRadius);
				}

				this._spaceViewRenderer.needsRender = true;

				resolve();
			};

			// // Workaround for bug when unembedding a xyicon, or changing layer visibility
			// // and the xyicon size value is still 0 because of the Convergence animation
			const hasItemsJustBecomeVisible = items.some(
				(item: CaptionedItem) => item.spaceItemType === "xyicon" && (item as Xyicon3D).meshSize.value === 0 && (item as Xyicon3D).meshSize.end > 0,
			);

			if (hasItemsJustBecomeVisible) {
				requestAnimationFrame(update);
			} else {
				update();
			}
		});
	}

	public static readonly filterVisibleCaptionedItems = (item: CaptionedItem) => {
		return item.isVisible && !!textPartsToTextContent(item.caption?.text || []);
	};

	protected get _objectsWithTexts() {
		return this._items.array.filter(CaptionManager.filterVisibleCaptionedItems).map((item: CaptionedItem) => item.caption);
	}

	private get _items(): Collection<CaptionedItem> {
		return this.itemManager.items as Collection<CaptionedItem>;
	}

	private get captionSettings() {
		return this._spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor).spaceEditorViewSettings.captions[this._type];
	}

	public get captionFontSize() {
		return this.captionSettings.fontSize;
	}

	public get captionBackgroundColor() {
		return this.captionSettings.backgroundColor;
	}

	public get captionFontColor() {
		return this.captionSettings.fontColor;
	}

	public get captionFontFamily() {
		return this.captionSettings.fontFamily;
	}

	public get captionIsBold() {
		return this.captionSettings.isBold;
	}

	public get captionIsItalic() {
		return this.captionSettings.isItalic;
	}

	public get captionIsUnderlined() {
		return this.captionSettings.isUnderlined;
	}

	public get captionFields() {
		return this.captionSettings.checkList;
	}
}

import {useEffect, useState} from "react";
import type {View} from "../../../../../data/models/View";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {getMarkupColorForColorFiltering} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {IconButton} from "../../../../widgets/button/IconButton";
import {MarkupColorFilterPanel} from "./MarkupColorFilterPanel.styled";

interface IColorFilterPanelProps {
	spaceViewRenderer: SpaceViewRenderer;
	view: View;
}

const sortColors = (hexA: string, hexB: string) => {
	const hslA = ColorUtils.hex2hsl(hexA);
	const hslB = ColorUtils.hex2hsl(hexB);

	if (hslA.h !== hslB.h) {
		return hslA.h - hslB.h;
	} else if (hslA.l !== hslB.l) {
		return hslA.l - hslB.l;
	} else {
		return hslA.s - hslB.s;
	}
};

const getEyeIconColor = (hex: string): "white" | "black" => {
	const rgb = ColorUtils.hex2Array(hex);
	const [r, g, b] = rgb;

	const threshold = 180 / 255;

	if (r > threshold && g > threshold && b > threshold) {
		return "black";
	}

	return "white";
};

const toggleColor = (view: View, hex: string, uniqueColors: string[], spaceViewRenderer: SpaceViewRenderer) => {
	const uniqueColorsSet = new Set<string>(uniqueColors);
	const hiddenMarkupColorsSet = new Set<string>(view.spaceEditorViewSettings.layers.hiddenMarkupColors.filter((hex) => uniqueColorsSet.has(hex)));

	if (hiddenMarkupColorsSet.has(hex)) {
		hiddenMarkupColorsSet.delete(hex);
	} else {
		hiddenMarkupColorsSet.add(hex);
	}

	view.spaceEditorViewSettings.layers.hiddenMarkupColors = [...hiddenMarkupColorsSet];

	spaceViewRenderer.markupManager.onLayerSettingsModified();
};

const maxItemNumber = 14;

const createListFromColorArray = (
	hexArray: string[],
	view: View,
	hiddenColors: Set<string>,
	uniqueColors: string[],
	spaceViewRenderer: SpaceViewRenderer,
) => {
	return hexArray.map((hex: string) => {
		const isHidden = hiddenColors.has(hex);

		return (
			<div
				className={ReactUtils.cls("colorButton flexCenter", {bordered: !isHidden})}
				key={hex}
				title={`#${hex}`}
				onClick={() => toggleColor(view, hex, uniqueColors, spaceViewRenderer)}
			>
				<div
					className="colorFill flexCenter"
					style={{backgroundColor: `#${hex}`}}
				></div>
			</div>
		);
	});
};

export function MarkupColorFilterPanelV5(props: IColorFilterPanelProps) {
	const [updateCount, setUpdateCount] = useState<number>(0);
	const [isMoreColorsOpen, setIsMoreColorsOpen] = useState<boolean>(false);
	const spaceViewRenderer = props.spaceViewRenderer;
	const markupsOnSpace = spaceViewRenderer.markupManager.items.array as Markup3D[];
	const uniqueColors = ArrayUtils.removeDuplicates(markupsOnSpace.map((m) => getMarkupColorForColorFiltering(m)).sort(sortColors));
	const hiddenColors = new Set<string>(props.view.spaceEditorViewSettings.layers.hiddenMarkupColors);
	const shortList = uniqueColors.slice(0, maxItemNumber);
	const remainingItems = uniqueColors.slice(maxItemNumber);

	useEffect(() => {
		const {signals} = spaceViewRenderer.markupManager;
		const increaseUpdateCount = () => {
			setUpdateCount((u) => u + 1);
			if (remainingItems.length === 0 && isMoreColorsOpen) {
				setIsMoreColorsOpen(false);
			}
		};

		signals.itemsAdd.add(increaseUpdateCount);
		signals.itemsUpdate.add(increaseUpdateCount);
		signals.itemsRemove.add(increaseUpdateCount);
		signals.onClear.add(increaseUpdateCount);
		spaceViewRenderer.signals.spaceLoadStarted.add(increaseUpdateCount);
		spaceViewRenderer.signals.spaceLoadReady.add(increaseUpdateCount);

		return () => {
			signals.itemsAdd.remove(increaseUpdateCount);
			signals.itemsUpdate.remove(increaseUpdateCount);
			signals.itemsRemove.remove(increaseUpdateCount);
			signals.onClear.remove(increaseUpdateCount);
			spaceViewRenderer.signals.spaceLoadStarted.remove(increaseUpdateCount);
			spaceViewRenderer.signals.spaceLoadReady.remove(increaseUpdateCount);
		};
	}, [spaceViewRenderer, remainingItems.length, isMoreColorsOpen]);

	return (
		<MarkupColorFilterPanel className="MarkupColorFilterPanel hbox">
			<div className="label">By color:</div>
			<div className="colorContainer">
				{createListFromColorArray(shortList, props.view, hiddenColors, uniqueColors, props.spaceViewRenderer)}
				{remainingItems.length > 0 && (
					<div
						className="more flexCenter"
						onClick={() => setIsMoreColorsOpen((isOpen) => !isOpen)}
					>
						{remainingItems.length} more...
					</div>
				)}
				{isMoreColorsOpen && remainingItems.length > 0 && (
					<div className="moreColors window vbox">
						<div className="header">
							<div className="title"></div>
							<IconButton
								className="closeBtn"
								icon="close"
								onClick={() => setIsMoreColorsOpen(false)}
							/>
						</div>
						<div className="colorContainer">
							{createListFromColorArray(remainingItems, props.view, hiddenColors, uniqueColors, props.spaceViewRenderer)}
						</div>
					</div>
				)}
			</div>
		</MarkupColorFilterPanel>
	);
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IFieldColumn} from "../../../../../../../data/models/Report";
import {Report} from "../../../../../../../data/models/Report";
import type {OutputDataSortField} from "../../../../../../../generated/api/reports";
import {ReportType} from "../../../../../../../generated/api/reports";
import {ColumnEditor} from "../../../../../../widgets/column/ColumnEditor";
import {ToggleSwitchField} from "../../../../../../widgets/button/switch/ToggleSwitchField";
import {InfoButton} from "../../../../../abstract/common/infobutton/InfoButton";
import type {AppState} from "../../../../../../../data/state/AppState";
import {LINKED_FIELD_STRING, REPORT_COUNT_FIELD} from "../../../../../../../data/state/AppFields";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import type {IFilter} from "../../../../../../../data/models/filter/Filter";

interface IReportColumnsProps {
	report: Report;
	appState?: AppState;
}

interface IReportColumnsState {
	lastReportType: ReportType;
}

@inject("appState")
@observer
export class ReportColumns extends React.Component<IReportColumnsProps, IReportColumnsState> {
	constructor(props: IReportColumnsProps) {
		super(props);
		this.state = {
			lastReportType: null,
		};
	}

	public static isValid(report: Report) {
		return report.displayedFields.length > 0;
	}

	private addColumns = (fieldColumns: IFieldColumn[], index?: number, linkedFields?: boolean) => {
		const {report, appState} = this.props;

		// Don't allow adding unique columns in summarize mode
		// Without this, possible bug:
		// - select id, turn on summarize, add columns
		let fieldRefIds = fieldColumns.map((f) => f.field.refId);
		let linkedFieldRefIds = fieldColumns.map((lf) => lf.field.refId);

		if (report.summarizeResults) {
			if (report.type !== ReportType.UserDefinedLinkedXyiconReport) {
				fieldRefIds = fieldRefIds.filter((refId) => !appState.actions.getFieldByRefId(refId)?.unique);
			}
			linkedFieldRefIds = fieldRefIds.filter((refId) => !appState.actions.getFieldByRefId(refId)?.unique);
		}
		report.addDisplayedFields(fieldRefIds, index, linkedFields);
	};

	private removeColumns = (fieldColumns: IFieldColumn[], linkedFields?: boolean) => {
		const {report} = this.props;

		// Don't allow removing count
		const cols = fieldColumns.filter((f) => f.field.refId !== REPORT_COUNT_FIELD);

		report.removeDisplayedFields(cols, linkedFields);

		// Delete related sort fields as well if necessary
		let sortFields: OutputDataSortField[] = report.sortFields;

		if (linkedFields) {
			sortFields = report.sortLinkedFields;
		}

		const sortFieldsToRemove = sortFields.filter((sf) => fieldColumns.map((fc) => fc.field.refId).includes(sf.name));

		for (const sortFieldToRemove of sortFieldsToRemove) {
			report.removeSortField(sortFieldToRemove, linkedFields);
		}
	};

	private reorderColumn = (fromIndex: number | number[], toIndex: number, linkedFields?: boolean) => {
		this.props.report.reorderDisplayedFields(fromIndex, toIndex, linkedFields);
	};

	private getColumnRefIds = () => {
		return this.props.report.displayedFields.map((f) => f.field);
	};

	private getLinkedFieldsColumnRefIds = () => {
		return this.props.report.displayedLinkedFields.map((f) => f.field.replace(LINKED_FIELD_STRING, ""));
	};

	private onChangeSummarizeResults = (value: boolean) => {
		const {report} = this.props;

		if (value) {
			report.sortFields.forEach((sort) => {
				if (sort.name.includes("refId")) {
					report.removeSortField(sort, (sort as any).linkedField);
				}
			});

			const filters = report.filters;
			let index = filters.findIndex((filter) => (filter.value as IFilter).field?.includes("refId"));

			while (index > -1) {
				if (index >= 0) {
					filters.splice(index, 1);
				}

				// remove next separator if any
				const nextSeparator = filters[index];

				if (nextSeparator?.type === "separator") {
					filters.splice(index, 1);
				}

				// remove any last separators (normally this shouldn't run)
				while (filters[filters.length - 1]?.type === "separator") {
					filters.pop();
				}

				index = filters.findIndex((filter) => (filter.value as IFilter).field?.includes("refId"));
			}
		}

		report.summarizeResults = value;
	};

	private getFieldClassNames = (refId: string) => {
		const {report, appState} = this.props;
		const id = refId.replace("-linkedFields", refId);

		if (!report.summarizeResults) {
			return null;
		}

		return {
			disabled: appState.actions.getFieldByRefId(id)?.unique,
			count: id === REPORT_COUNT_FIELD,
		};
	};

	private filterField = (refId: string) => {
		const {report, appState} = this.props;

		return !!Report.serializeFieldName(refId, report, appState);
	};

	private onIncludeFieldsFromLinkedXyicons = (value: boolean) => {
		const {report} = this.props;

		if (value) {
			report.type = ReportType.UserDefinedLinkedXyiconReport;
			/* report.summarizeResults = false; */
		} else {
			report.type = this.state.lastReportType || ReportType.UserDefinedReport;

			this.setState({lastReportType: null});

			if (report.summarizeResults) {
				report.addDisplayedFields([REPORT_COUNT_FIELD]);
			}
		}
	};

	public override render() {
		const {report} = this.props;

		return (
			<div className="ReportColumns">
				<div className="toggles vbox">
					<div className="toggle hbox alignCenter">
						<ToggleSwitchField
							label="Summarize results for this report"
							value={report.summarizeResults}
							onChange={this.onChangeSummarizeResults}
						/>
						<InfoButton
							bubbleText={
								<>
									Enabling this will
									<br />
									1. Remove duplicates for all columns
									<br />
									2. Create a “Count” column and calculate total unique values for each row`
								</>
							}
						/>
					</div>
					{report.reportFeature === XyiconFeature.Xyicon && (
						<div className="toggle hbox alignCenter">
							<ToggleSwitchField
								label="Include fields from Linked Xyicons"
								value={report.type === ReportType.UserDefinedLinkedXyiconReport}
								onChange={this.onIncludeFieldsFromLinkedXyicons}
								// noBooleanLabel={true}
							/>
							<InfoButton
								bubbleText={
									<>
										Turn on this toggle if you want the report to include fields that belong to linked xyicons. You can sort and filter the report
										based on the selected fields in the next steps.
									</>
								}
							/>
						</div>
					)}
				</div>
				<ColumnEditor
					report={report}
					leftHeader="Available fields"
					rightHeader="Selected fields for columns"
					feature={report.reportFeature}
					addColumns={this.addColumns}
					removeColumns={this.removeColumns}
					reorderColumn={this.reorderColumn}
					getColumnRefIds={this.getColumnRefIds}
					getLinkedFieldsColumnRefIds={this.getLinkedFieldsColumnRefIds}
					getFieldClassNames={this.getFieldClassNames}
					filterColumn={this.filterField}
					defaultOpenFeature={report.reportFeature}
					linkedXyiconFieldsOn={report.type === ReportType.UserDefinedLinkedXyiconReport}
				/>
			</div>
		);
	}
}

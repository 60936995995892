import {ReactUtils} from "../../../../utils/ReactUtils";
import type {IInputPropsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import {TextInputV5} from "../TextInputV5";

export function MultiLineInputV5(props: IInputPropsV5) {
	const {value, onInput, onBlur, onChange, scrollHeight, inline, expand, caretPosition} = props;
	const inlineStyle: React.CSSProperties = {};

	if (scrollHeight) {
		inlineStyle.height = scrollHeight + (inline ? 0 : scrollHeight > 90 ? 40 : 16);
	}

	if (inline && !expand) {
		inlineStyle.maxHeight = 88;
	}

	if (!inline && !expand) {
		inlineStyle.maxHeight = 90;
	}

	return (
		<div className={ReactUtils.cls("vbox multiline", {expand, inline})}>
			<TextInputV5
				value={value}
				onInput={onInput}
				onBlur={onBlur}
				onChange={onChange}
				isTextArea={true}
				autoFocus={true}
				style={inlineStyle}
				caretPosition={caretPosition}
			/>
		</div>
	);
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {InfoButton} from "../abstract/common/infobutton/InfoButton";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {Button} from "../../widgets/button/Button";
import {ReactUtils} from "../../utils/ReactUtils";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import {PassSecurityLevel, PasswordUtils} from "../../widgets/password/PasswordUtils";
import {PasswordValidator} from "../../widgets/password/PasswordValidator";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {TextInput} from "../../widgets/input/text/TextInput";
import {PasswordInput} from "../../widgets/input/pssword/PasswordInput";
import {DomPortal} from "../abstract/portal/DomPortal";
import {InfoBubble} from "../abstract/common/infobutton/InfoBubble";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";

interface INewUserRegistrationProps {
	transport?: TransportLayer;
	navigation?: Navigation;
	appState?: AppState;
}

interface INewUserRegistrationStates {
	tokenID: string;
	token: string;
	firstname: string;
	lastname: string;
	username: string;
	password: string;
	passwordConfirm: string;
	email: string;
	errorMessage: string;
	userID: string;
	loading: boolean;
	registerInProgress: boolean;
	verificationInProgress: boolean;
	isUserTokenInvalid: boolean;
	usernameToolTipTransform: TransformObj;
	passwordToolTipTransform: TransformObj;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class NewUserRegistration extends React.Component<INewUserRegistrationProps, INewUserRegistrationStates> {
	private _usernameParent = React.createRef<HTMLInputElement>();
	private _passwordParent = React.createRef<HTMLInputElement>();
	private _username = React.createRef<HTMLDivElement>();
	private _password = React.createRef<HTMLDivElement>();

	constructor(props: INewUserRegistrationProps) {
		super(props);
		this.state = {
			tokenID: "",
			token: "",
			firstname: "",
			lastname: "",
			username: "",
			password: "",
			passwordConfirm: "",
			email: "",
			errorMessage: "",
			userID: "",
			loading: false,
			registerInProgress: false,
			verificationInProgress: false,
			isUserTokenInvalid: false,
			usernameToolTipTransform: null,
			passwordToolTipTransform: null,
		};
	}

	private get passwordConfirmError() {
		return this.state.passwordConfirm && this.state.passwordConfirm !== this.state.password ? "Password do not match." : "";
	}

	private handleFirstnameInput = (value: string) => {
		this.setState({
			firstname: value,
		});
	};

	private handleLastnameInput = (value: string) => {
		this.setState({
			lastname: value,
		});
	};

	private handleUsernameInput = (value: string) => {
		this.setState({
			username: value,
			errorMessage: "",
		});
	};

	private handleNewPasswordInput = (value: string) => {
		this.setState({
			password: value,
		});
	};

	private handlePasswordConfirm = (value: string) => {
		this.setState({
			passwordConfirm: value,
		});
	};

	private onGoToLoginClick = () => {
		this.props.navigation.goToLogin();
	};

	private verifyNewUserToken = async (tokenID: string, token: string) => {
		this.setState({verificationInProgress: true});

		const {result, error} = await this.props.transport.request({
			url: "auth/verifynewusertoken",
			method: XHRLoader.METHOD_POST,
			params: {tokenID, token},
		});

		if (error) {
			this.setState({isUserTokenInvalid: true});
		} else {
			this.setState({
				email: result.email,
				userID: result.userID,
			});
		}

		window.setTimeout(
			() => {
				this.setState({verificationInProgress: false});
			},
			Math.random() * 2000 + 2000,
		);
	};

	private setupUserProfile = async () => {
		const {tokenID, token, firstname, lastname, password, email} = this.state;

		this.setState({loading: true});

		let username = this.state.username === "" ? email : this.state.username;

		const {result, error} = await this.props.transport.request({
			url: "auth/setupuserprofile",
			method: XHRLoader.METHOD_POST,
			params: {
				firstname,
				lastname,
				username,
				password,
				countryCode: "",
				phoneNumber: "",
				tokenID: tokenID,
				token: token,
				userGeneralSetting: {
					timeZone: "GMT+2",
					theme: this.props.appState.theme,
					language: "en",
				},
			},
		});

		this.setState({loading: false});

		if (error) {
			this.setState({errorMessage: error.ErrorMessage});
		} else {
			this.setState({registerInProgress: true});

			window.setTimeout(
				() => {
					this.setState({registerInProgress: false});
					this.props.navigation.goToLogin();
				},
				Math.random() * 3000 + 3000,
			);
		}
	};

	private shouldRegisterBtnEnabled = () => {
		const {token, tokenID, firstname, lastname, errorMessage, password, passwordConfirm, registerInProgress} = this.state;

		if (
			token &&
			tokenID &&
			password === passwordConfirm &&
			PasswordUtils.getPasswordSecurityLevel(password) > PassSecurityLevel.RequirementsNotMet &&
			!registerInProgress &&
			password.length <= 64 &&
			firstname &&
			lastname &&
			!errorMessage
		) {
			return true;
		} else {
			return false;
		}
	};

	public override componentDidUpdate = (prevProps: INewUserRegistrationProps, prevState: INewUserRegistrationStates) => {
		if (this.state.errorMessage && !prevState.errorMessage) {
			this.setState({
				usernameToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._usernameParent.current,
					this._username.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.right,
				),
			});
		}

		if (this.passwordConfirmError && !prevState.passwordConfirm) {
			this.setState({
				passwordToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._passwordParent.current,
					this._password.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.right,
				),
			});
		}
	};

	public override componentDidMount() {
		const href = location.href;
		const tokenID = ReactUtils.searchForParamInUrl("tokenid", href);
		const token = ReactUtils.searchForParamInUrl("token", href);

		if (tokenID && token) {
			this.setState({token, tokenID});
			this.verifyNewUserToken(tokenID, token);
		}
	}

	public override componentWillUnmount() {
		this.props.transport.services.auth.logout();
	}

	private renderContent() {
		const {
			username,
			password,
			passwordConfirm,
			email,
			errorMessage,
			loading,
			registerInProgress,
			verificationInProgress,
			isUserTokenInvalid,
			usernameToolTipTransform,
			passwordToolTipTransform,
		} = this.state;

		const usernameInlineStyle: React.CSSProperties = {
			top: "5px",
			left: "-25px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
			transform: usernameToolTipTransform?.translate,
		};

		const passwordInlineStyle: React.CSSProperties = {
			top: "5px",
			left: "-5px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
			transform: passwordToolTipTransform?.translate,
		};

		if (!isUserTokenInvalid) {
			if (registerInProgress) {
				return (
					<>
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<div className="title">Registration was successful!</div>
						<div className="fieldContainer vbox description">
							<p>
								Redirecting to Xyicon Login...
								<a
									className="textLink"
									onClick={this.onGoToLoginClick}
								>
									Click here
								</a>{" "}
								if not redirected.
							</p>
						</div>
					</>
				);
			} else {
				return (
					<>
						<p className="welcomeMsg">Welcome to</p>
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<div className={ReactUtils.cls("fieldContainer vbox", {loading: verificationInProgress})}>
							<div className="inputGroup">
								<TextInput
									onInput={this.handleFirstnameInput}
									placeholder="First Name"
								/>
							</div>
							<div className="inputGroup">
								<TextInput
									onInput={this.handleLastnameInput}
									placeholder="Last Name"
								/>
							</div>
							<div className="inputGroup">
								<TextInput
									value={email}
									disabled={true}
								/>
							</div>
							<div className="inputGroup">
								<span className="username">
									<TextInput
										onInput={this.handleUsernameInput}
										placeholder="Username (Optional)"
										value={username}
										divRef={this._usernameParent}
									/>
									<div className="usernameinfo">
										<InfoButton
											icon="question-mark"
											bubbleText={"Enter a username as an additional login ID to your email."}
											className="onPopup tootltip"
										/>
									</div>
									{errorMessage && (
										<div className="usernameerror">
											<DomPortal destination={this.props.appState.app.modalContainer}>
												<div
													ref={this._username}
													className={ReactUtils.cls("infoIcon editing errorInfo", {left: false})}
													style={usernameInlineStyle}
												>
													{<SVGIcon icon="info" />}
													<InfoBubble
														content={"Username exists. Please select a new one."}
														isErrorMessage={true}
														style={{width: "280px"}}
													/>
												</div>
											</DomPortal>
										</div>
									)}
								</span>
							</div>
							<div className="inputGroup">
								<PasswordInput
									placeholder="Password"
									value={password}
									onInput={this.handleNewPasswordInput}
								/>
							</div>
							<div className="inputGroup password">
								<PasswordInput
									placeholder="Confirm Password"
									value={passwordConfirm}
									onInput={this.handlePasswordConfirm}
									divRef={this._passwordParent}
								/>
								{this.passwordConfirmError && (
									<div className="passworderror">
										<DomPortal destination={this.props.appState.app.modalContainer}>
											<div
												ref={this._password}
												className={ReactUtils.cls("infoIcon editing errorInfo", {left: false})}
												style={passwordInlineStyle}
											>
												{<SVGIcon icon="info" />}
												<InfoBubble
													content={"Password do not match."}
													isErrorMessage={true}
													style={{width: "200px"}}
												/>
											</div>
										</DomPortal>
									</div>
								)}
							</div>
							<div className="inputGroup">
								<PasswordValidator password={password} />
							</div>

							<Button
								className="primary"
								label="Register"
								disabled={!this.shouldRegisterBtnEnabled()}
								onClick={this.setupUserProfile}
								loading={loading}
							/>

							<Button
								onClick={() => this.props.navigation.openInNewTab("https://support.xyicon.com/docs")}
								className="naked small helpbutton"
								icon="help"
								label="Help"
							/>
						</div>
					</>
				);
			}
		} else {
			return (
				<>
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<div className="title">Error</div>
					<div className="fieldContainer vbox description">
						<p>
							Registration link has expired. Please reach out to your administrator to request a new invite.<br></br>
							If you've already registered, simply proceed to the login page to access your account.<br></br>
							For any assistance, feel free to contact our support team at &nbsp;
							<a
								className="support"
								href="https://support.xyicon.com/docs"
							>
								<b>support@xyicon.com</b>
							</a>{" "}
							Thank you!
						</p>
						<a
							className="textLink"
							onClick={this.onGoToLoginClick}
						>
							Go to login page
						</a>
					</div>
				</>
			);
		}
	}

	public override render() {
		const {verificationInProgress, isUserTokenInvalid} = this.state;

		if (verificationInProgress) {
			return (
				<div className="UserStaticPageWrapper">
					<div className="preLoadingPage">
						<LoaderIcon />
						<p>Verification in progress ...</p>
					</div>
				</div>
			);
		} else {
			return (
				<div className="UserStaticPageWrapper">
					<div className={ReactUtils.cls("UserStaticPage", {invalidLink: isUserTokenInvalid})}>
						<div className="formContainer hbox alignCenter">{this.renderContent()}</div>
					</div>
				</div>
			);
		}
	}
}

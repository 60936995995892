import {FieldDataType} from "../../../generated/api/base";

// 1 -> "SingleLineText"
export const findFieldDataTypeKey = (type: FieldDataType) => {
	for (const key in FieldDataType) {
		const value = Number(key);

		if (isNaN(value)) {
			if ((FieldDataType[key] as unknown as number) === type) {
				return key;
			}
		}
	}
	return null;
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import type {AppState} from "../../../../../../data/state/AppState";
import type {Type} from "../../../../../../data/models/Type";
import {ClickToEditInput} from "../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {FieldDataType, XyiconFeature} from "../../../../../../generated/api/base";
import {DateFormatter} from "../../../../../../utils/format/DateFormatter";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {Initials} from "../../../../../widgets/Initials";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import type {Color} from "../../../../../../generated/api/base";
import {Functions} from "../../../../../../utils/function/Functions";
import {HorizontalAlignment} from "../../../../../../utils/dom/DomUtils";
import type {ExtendedDistanceUnitName} from "../../../../space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../../space/spaceeditor/logic3d/Constants";
import {UnitSelector} from "../../../../../widgets/input/clicktoedit/UnitSelector";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {RealSizeInput} from "./RealSizeInput";
import type {IRealSize} from "./RealSizeInput";

interface ITypeFormHeaderProps {
	type: Type;
	appState?: AppState;
	transport?: TransportLayer;
	avatar?: boolean;
	createPanelNameInput?: (name: string) => void;
}

@inject("appState")
@inject("transport")
@observer
export class TypeFormHeader extends React.Component<ITypeFormHeaderProps> {
	private _timeoutId: number = null;

	private isNameValid = (value: string) => {
		const {type, appState} = this.props;

		return appState.actions.isTypeNameValid(value, type);
	};

	private onChangeColor = (color: Color) => {
		this.props.type.settings.color = color;
		this.updateType();
	};

	private onChangeXyiconSize = (size: IRealSize) => {
		const xyiconSize = this.props.type.settings.xyiconSize;

		if (size.unit !== xyiconSize.unit) {
			size.value = (size.value * Constants.DISTANCE_UNITS[xyiconSize.unit].multiplicator) / Constants.DISTANCE_UNITS[size.unit].multiplicator;
		}

		if (size.unit === "foot&inch") {
			size.unit = "inch";
			size.value = MathUtils.convertFeetAndInchesToInches(1, 0);
		}

		this.props.type.settings.xyiconSize = {...size};
		this.updateType();
	};

	private onChangeUnitOfMeasurement = (unit: ExtendedDistanceUnitName) => {
		this.props.type.settings.unitOfMeasure = unit;
		this.updateType();
	};

	private onChangeBoundaryHeight = (size: IRealSize) => {
		const boundarySize = this.props.type.settings.boundaryHeight;

		if (size.unit !== boundarySize.unit) {
			size.value = (size.value * Constants.DISTANCE_UNITS[boundarySize.unit].multiplicator) / Constants.DISTANCE_UNITS[size.unit].multiplicator;
		}

		this.props.type.settings.boundaryHeight = {...size};
		this.updateType();
	};

	private updateType() {
		clearTimeout(this._timeoutId);
		this._timeoutId = window.setTimeout(this.saveChanges, 500);
	}

	private saveChanges = () => {
		const {type, transport} = this.props;

		if (type.id) {
			transport.services.typefield.updateType(type);
		}
	};

	private getColorInput() {
		const {type} = this.props;
		const settings = type.settings;
		const color = settings.color || {hex: "2296f3", transparency: 0};

		return (
			<Field label="Color">
				<ColorSelector
					title="Color"
					color={color}
					onColorChange={this.onChangeColor}
					isTransparencyEnabled={false}
					horizontalAlignment={HorizontalAlignment.outerRight}
				/>
			</Field>
		);
	}

	private onChange = (name: string) => {
		this.props.type.name = name;
		this.saveChanges();
	};

	private renderForm() {
		const {type} = this.props;

		if ([XyiconFeature.Portfolio, XyiconFeature.Xyicon].includes(type.feature)) {
			return this.getColorInput();
		} else if (type.feature === XyiconFeature.Space) {
			const xyiconSize = type.settings.xyiconSize;

			if (xyiconSize.unit === "foot&inch") {
				xyiconSize.unit = "inch";
				xyiconSize.value = MathUtils.convertFeetAndInchesToInches(1, 0);
			}

			if (!type.settings.unitOfMeasure) {
				type.settings.unitOfMeasure = "foot&inch";
			}

			return (
				<>
					<Field
						label="Unit of Measure"
						tooltips={{postLabelIconTooltip: "Select the default unit of measurement for the space type."}}
						icons={{postLabelIcon: "info"}}
					>
						<UnitSelector
							unit={type.settings.unitOfMeasure}
							addFeetAndInches={true}
							onChange={this.onChangeUnitOfMeasurement}
						/>
					</Field>
					<RealSizeInput
						size={xyiconSize}
						label="Xyicon Size"
						infoText="Set the size for standard xyicons placed in a space of this type."
						onChange={this.onChangeXyiconSize}
					/>
				</>
			);
		} else if (type.feature === XyiconFeature.Boundary) {
			const boundaryHeight = type.settings.boundaryHeight;

			return (
				<>
					{this.getColorInput()}
					<RealSizeInput
						size={boundaryHeight}
						label="Height"
						onChange={this.onChangeBoundaryHeight}
						validateValue={(value: number) => value >= 0}
					/>
				</>
			);
		} else {
			return null;
		}
	}

	public override componentDidMount(): void {
		const {type} = this.props;

		if (type.feature === XyiconFeature.Space) {
			if (!type.settings.xyiconSize.unit) {
				type.settings.xyiconSize.unit = "inch";
			}
		}
		if (type.feature === XyiconFeature.Boundary) {
			if (!type.settings.boundaryHeight.unit) {
				type.settings.boundaryHeight.unit = "foot";
			}
		}
	}

	public override render() {
		const {type, appState, avatar, createPanelNameInput} = this.props;
		const editMode = !!type.id;

		return (
			<>
				<Field
					label="Name"
					className={!editMode && "focused"}
				>
					<ClickToEditInput
						value={type.name}
						onChange={this.onChange}
						onBlur={Functions.emptyFunction}
						valueValidator={this.isNameValid}
						onLiveChange={createPanelNameInput}
						dataType={FieldDataType.SingleLineText}
						noButtons={true}
						focused={!!createPanelNameInput}
					/>
				</Field>
				{this.renderForm()}
				{editMode && (
					<>
						<Field label="Updated By">{appState.actions.renderName(type.lastModifiedBy)}</Field>
						<Field label="Updated Date">{DateFormatter.format(type.lastModifiedAt)}</Field>
						{avatar && (
							<Initials
								color={type.settings.color?.hex || "FFFFFF"}
								name={type.name}
							/>
						)}
					</>
				)}
			</>
		);
	}
}

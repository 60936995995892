import {FieldDataType, XyiconFeature} from "../../generated/api/base";
import type {IDefaultField} from "../models/field/Field";
import {Field} from "../models/field/Field";
import type {IDateFieldSettingsDefinition} from "../../ui/modules/settings/modules/field/datatypes/DateFieldSettings";
import type {AppState} from "./AppState";

export const REPORT_COUNT_FIELD = "ResultCount";
export const LINKED_FIELD_STRING = "linkedFields-";

export class AppFieldActions {
	public static getRefId(feature: XyiconFeature, id: string) {
		return `${feature}/${id}`;
	}

	public static isLastModifiedAt(refId: string = "") {
		return refId?.split("/")[1] === "lastModifiedAt";
	}

	public static isRefId(refId: string) {
		return refId?.includes("/refId");
	}

	private readonly _appState: AppState;

	constructor(appState: AppState) {
		this._appState = appState;
	}

	public createDefaultFields() {
		// Special "COUNT" field for reports
		this._appState.fieldsByRef[REPORT_COUNT_FIELD] = {
			refId: REPORT_COUNT_FIELD,
			name: "Count",
			dataType: FieldDataType.SingleLineText,
			default: true,
			feature: XyiconFeature.Report,
			displayOnLinks: true,
		};

		this.createDefaultField(XyiconFeature.Portfolio, "refId", "Portfolio ID", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Portfolio, "name", "Portfolio Name", undefined, true, true);
		this.createDefaultField(XyiconFeature.Portfolio, "type", "Portfolio Type", FieldDataType.Type, true, true);
		this.createDefaultField(XyiconFeature.Portfolio, "lastModifiedBy", "Last Modified By", FieldDataType.User);
		this.createDefaultField(XyiconFeature.Portfolio, "lastModifiedAt", "Last Modified At", FieldDataType.DateTime);

		this.createDefaultField(XyiconFeature.XyiconCatalog, "refId", "Catalog ID", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.XyiconCatalog, "type", "Xyicon Type", FieldDataType.Type, false, true);
		this.createDefaultField(XyiconFeature.XyiconCatalog, "model", "Xyicon Model", undefined, false, true);
		this.createDefaultField(XyiconFeature.XyiconCatalog, "lastModifiedBy", "Last Modified By", FieldDataType.User);
		this.createDefaultField(XyiconFeature.XyiconCatalog, "lastModifiedAt", "Last Modified At", FieldDataType.DateTime);
		this.createDefaultField(XyiconFeature.XyiconCatalog, "icon", "Icon", undefined);

		this.createDefaultField(XyiconFeature.Space, "refId", "Space ID", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Space, "name", "Space Name", undefined, true, true);
		this.createDefaultField(XyiconFeature.Space, "type", "Space Type", FieldDataType.Type, true, true);
		this.createDefaultField(XyiconFeature.Space, "versionName", "Version Set", undefined, false);
		this.createDefaultField(XyiconFeature.Space, "issuanceDate", "Issuance Date", FieldDataType.DateTime, false);
		this.createDefaultField(XyiconFeature.Space, "lastModifiedBy", "Last Modified By", FieldDataType.User);
		this.createDefaultField(XyiconFeature.Space, "lastModifiedAt", "Last Modified At", FieldDataType.DateTime);

		this.createDefaultField(XyiconFeature.Boundary, "refId", "Boundary ID", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Boundary, "type", "Boundary Type", FieldDataType.Type, true, true);
		this.createDefaultField(XyiconFeature.Boundary, "lastModifiedBy", "Last Modified By", FieldDataType.User);
		this.createDefaultField(XyiconFeature.Boundary, "lastModifiedAt", "Last Modified At", FieldDataType.DateTime);

		this.createDefaultField(XyiconFeature.Xyicon, "refId", "Xyicon ID", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Xyicon, "type", "Xyicon Type", FieldDataType.Type, true, true);
		this.createDefaultField(XyiconFeature.Xyicon, "model", "Xyicon Model", FieldDataType.SingleLineText, true, true);
		this.createDefaultField(XyiconFeature.Xyicon, "lastModifiedBy", "Last Modified By", FieldDataType.User);
		this.createDefaultField(XyiconFeature.Xyicon, "lastModifiedAt", "Last Modified At", FieldDataType.DateTime);
		this.createDefaultField(XyiconFeature.Xyicon, "icon", "Icon", undefined);

		this.createDefaultField(XyiconFeature.Report, "refId", "Report ID", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Report, "name", "Name", FieldDataType.SingleLineText, true, true);
		this.createDefaultField(XyiconFeature.Report, "reportFeatureTitle", "Module", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Report, "scopeTitle", "Scope", undefined, undefined, true);
		this.createDefaultField(XyiconFeature.Report, "category", "Category", FieldDataType.SingleLineText, true, true);
		this.createDefaultField(XyiconFeature.Report, "description", "Description", FieldDataType.MultiLineText, true, true);
		this.createDefaultField(XyiconFeature.Report, "lastModifiedBy", "Last Modified By", FieldDataType.User);
		this.createDefaultField(XyiconFeature.Report, "lastModifiedAt", "Last Modified At", FieldDataType.DateTime);
	}

	private createDefaultField(
		feature: XyiconFeature,
		id: string,
		name: string,
		dataType: FieldDataType = FieldDataType.SingleLineText,
		displayOnLinks = false,
		fixed = false,
	) {
		const refId = AppFieldActions.getRefId(feature, id);

		if (this._appState.fieldsById[refId]) {
			throw new Error(`This field id is already used: ${refId}`);
		}

		let dataTypeSettings = Field.defaultSettings[dataType];

		// system fields default to datetime
		if (dataType === FieldDataType.DateTime) {
			dataTypeSettings = {
				format: "datetime",
			} as IDateFieldSettingsDefinition;
		}

		const field: IDefaultField = {
			refId: refId,
			getterName: id === "type" ? "typeId" : id, // Xyicon.typeId is used to get the type's id
			name: name,
			dataType: dataType,
			dataTypeSettings: dataTypeSettings,
			feature: feature,
			default: true,
			fixed: fixed,
			displayOnLinks: displayOnLinks,
			unique: id === "refId",
		};

		this._appState.fieldsByRef[refId] = field;
		this._appState.defaultFields[feature].push(field);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {CheckboxInput} from "../../../../widgets/input/checkbox/CheckboxInput";
import {Field} from "../../../../widgets/form/field/Field";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";

interface INavigationSettingsProps {
	feature: XyiconFeature;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("transport")
@inject("appState")
@observer
export class NavigationSettings extends React.Component<INavigationSettingsProps> {
	public override componentDidMount(): void {
		const {transport, feature} = this.props;

		transport.services.view.loadViews(feature);
	}

	public override render() {
		const {transport, feature, appState} = this.props;

		const viewsSharedWithEveryone = appState.views[feature]
			.filter((view) => view.isSystem || view.viewSharingSettings.find((sh) => sh.userGroupID))
			.sort((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));

		return (
			<div className="NavigationSettings">
				<h2>Select views to be pinned to main navigation</h2>
				{viewsSharedWithEveryone.map((view) => (
					<Field
						key={view.id}
						label={view.name}
					>
						<CheckboxInput
							value={view.showOnNavigation}
							onChange={(value) => {
								view.showOnNavigation = value;
								transport.services.view.update(view.getData());
							}}
						/>
					</Field>
				))}
			</div>
		);
	}
}

import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {SpaceViewRenderer} from "../../ui/modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Xyicon} from "./Xyicon";

export class XyiconUtils {
	public static readonly iconSize = 51; // px

	public static getXyiconSize(spaceViewRenderer: SpaceViewRenderer): number {
		if (spaceViewRenderer.isMounted) {
			const correctionMultiplier = spaceViewRenderer.correctionMultiplier.current;
			const originalSize = Constants.SIZE.XYICON * correctionMultiplier;
			const currentZoomValue = spaceViewRenderer.currentZoomValue;

			return ((Constants.SIZE.XYICON * (spaceViewRenderer.xyiconManager.xyiconSize / originalSize)) / this.iconSize) * currentZoomValue;
		} else {
			return 1;
		}
	}

	public static getScaleForCSSXyicon(xyicon: Xyicon, spaceViewRenderer: SpaceViewRenderer): string {
		const xyiconSize = this.getXyiconSize(spaceViewRenderer);
		const flipScaleX = xyicon.isFlippedX ? -1 : 1;
		const flipScaleY = xyicon.isFlippedY ? -1 : 1;

		return `scale(${flipScaleX * xyiconSize}, ${flipScaleY * xyiconSize})`;
	}
}

import type {Color} from "../../../../../generated/api/base/models/Color";
import type {SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {SpaceToolBarStyled} from "./SpaceToolBarStyled";
import type {TTitleAlignment} from "./ToolButtonV5";
import {ToolButtonV5} from "./ToolButtonV5";

interface ISpaceAlignToolBarProps {
	readonly active: boolean;
	readonly createToolButton: (id: SpaceTool, tooltipAlignment: TTitleAlignment, color: Color) => React.JSX.Element;
	readonly openDefaultToolBar?: () => void;
}

export const SpaceAlignToolBarV5 = (props: ISpaceAlignToolBarProps) => {
	return (
		<SpaceToolBarStyled
			className={ReactUtils.cls({SpaceToolBar: true, collapsed: !props.active})}
			$activeBackgroundColor={colorPalette.primary.c500Primary}
		>
			{props.openDefaultToolBar && (
				<ToolButtonV5
					icon="back"
					isActive={false}
					title="Back"
					onClick={props.openDefaultToolBar}
				/>
			)}
			{props.createToolButton("pan", "right", null)}
			{props.createToolButton("moveBackground", "right", null)}
			{props.createToolButton("setPin", "right", null)}
		</SpaceToolBarStyled>
	);
};

import * as React from "react";
import * as ReactDOM from "react-dom";
import {Button} from "../widgets/button/Button";
import {IconButton} from "../widgets/button/IconButton";
import {MathUtils} from "../../utils/math/MathUtils";
import {Functions} from "../../utils/function/Functions";
import {HTMLUtils} from "../../utils/HTML/HTMLUtils";

/**
 * error: red border, X on the right side,
 * message: blue border, no X
 * warning: yellow border, no X
 * success: green border, undo button and X on the right side,
 * announcement: full width after nav bar, yellow background, X on the right side
 */
export enum NotificationType {
	Error = 1,
	Message = 2,
	Warning = 3,
	Success = 4,
	Announcement = 5,
}

export interface INotificationParams {
	/*
	TODO:
		- animation for X button
		- implement different behaviours
	*/
	title: string;
	description?: string;
	link?: string;
	type: NotificationType;
	className?: string;
	cancelable?: boolean;
	buttonLabel?: string; // Action button appear only if buttonLabel is passed
	style?: React.CSSProperties;
	// If you want it to be there until the user explicitly closes it, pass "Infinity" here
	// Any non-valid and/or non-positive number is accepted, and work the same though
	lifeTime?: number; // ms
	onActionButtonClick?: () => void | Promise<void>;
	backdropDisable?: boolean;
}

export interface INotificationProps extends INotificationParams {
	onClose?: () => void;
}

interface INotificationConfig {
	backdrop?: boolean;
	parentElement?: HTMLElement;
}

interface INotificationState {
	isLoading: boolean;
	isBackdropDisable: boolean;
}

/**
 * Normally you'd want to create a new notification (toast message) with the globally available "notify" method, like this:
 * notify(params);
 */
export class Notification extends React.PureComponent<INotificationProps, INotificationState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _container: HTMLDivElement = document.createElement("div");
	private _timeoutId: number;
	private _isMounted: boolean = false;
	protected readonly _config: INotificationConfig;

	public static defaultProps: Partial<INotificationProps> = {
		title: "",
		description: "",
		link: "",
		type: NotificationType.Message,
		className: "",
		cancelable: true,
		buttonLabel: "",
		style: {},
		onClose: Functions.emptyFunction,
		onActionButtonClick: Functions.emptyFunction,
		lifeTime: 5000,
		backdropDisable: false,
	};

	constructor(props: INotificationProps) {
		super(props);
		this.state = {
			isLoading: false,
			isBackdropDisable: this.props.backdropDisable,
		};

		this._config = {
			backdrop: true,
			parentElement: document.body,
		};
	}

	private onClick = async () => {
		try {
			this.setState({
				isLoading: true,
				isBackdropDisable: false,
			});
			HTMLUtils.detach(this._container);
			await this.props.onActionButtonClick();
			this.setState({
				isLoading: false,
			});

			this.onCancelClick();
		} catch (error) {
			console.warn("error ", error);
		}
	};

	private onCancelClick = () => {
		this.onClose();
	};

	private onClose() {
		if (this._isMounted) {
			if (this._ref.current) {
				this._ref.current.style.transform = "scaleY(0)";
			}
			setTimeout(this.props.onClose, 300); // 0.3s -> from css transition
		}
		clearTimeout(this._timeoutId);
	}

	private get isLifeTimeLimited() {
		const {lifeTime} = this.props;

		return MathUtils.isValidNumber(lifeTime) && lifeTime > 0;
	}

	public override componentDidMount() {
		this._isMounted = true;

		if (this._ref.current) {
			this._ref.current.style.transform = "scaleY(1)";
		}

		if (this.isLifeTimeLimited) {
			this._timeoutId = window.setTimeout(this.onCancelClick, this.props.lifeTime);
		}
	}

	public override componentWillUnmount() {
		this._isMounted = false;
	}

	private renderNotificationBackdropDisabled() {
		this._container.className = "notificationBackdrop vbox alignCenter";
		if (this._config.backdrop) {
			this._container.onclick = Functions.emptyFunction;
		}

		ReactDOM.render(this.renderNotificationContent(), this._container);

		this._config.parentElement.appendChild(this._container);
	}

	private renderNotificationContent() {
		const {title, description, className, cancelable, buttonLabel, type, link} = this.props;

		return (
			<div
				className={`Notification ${NotificationType[type]?.toLowerCase()} ${className || ""}`}
				style={this.props.style || {}}
				ref={this._ref}
				data-cy="Notification"
			>
				<div className="notificationContent">
					<div className="details">
						<h3>{title}</h3>
						{description && <div dangerouslySetInnerHTML={{__html: description}} />}
						{type === NotificationType.Announcement && (
							<a
								className="textLink"
								href={link}
							>
								Read more
							</a>
						)}
					</div>
					<div className="actions hbox">
						{buttonLabel && this.props.onActionButtonClick && (
							<Button
								className="primary"
								disabled={this.state.isLoading}
								label={this.state.isLoading ? "Loading..." : buttonLabel}
								onClick={this.onClick}
							/>
						)}
						<div
							className="loading hbox alignCenter justifyCenter"
							data-cy="CancelButton"
						>
							{this.isLifeTimeLimited && <span className="spinner" />}
							{cancelable && (
								<IconButton
									icon="cancel"
									className="cancel"
									onClick={this.onCancelClick}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	public override render() {
		if (this.state.isBackdropDisable) {
			this.renderNotificationBackdropDisabled();
			return null;
		} else {
			return this.renderNotificationContent();
		}
	}
}

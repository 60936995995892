import {useAppStore} from "../../../StateManager";
import type {ViewSharingSettingsData} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {UserGroup} from "../../../data/models/UserGroup";
import type {User} from "../../../data/models/User";
import type {Report} from "../../../data/models/Report";
import {getLabelFromViewReportOrViewFolder} from "./SharingUtils";
import {SharingPopupV5} from "./SharingPopupV5";

interface IViewSharingPopupProps {
	report: Report;
	onClose: () => void;
}

export const ReportSharingPopup = (props: IViewSharingPopupProps) => {
	const {report, onClose} = props;
	const appState = useAppStore((state) => state.appState);

	const onSaveChanges = () => {
		return appState.actions.updateReport(report);
	};

	const onAddClick = (id: string, permission: Permission.View | Permission.Update, feature: XyiconFeature.User | XyiconFeature.UserGroup) => {
		const item = appState.actions.getFeatureItemById<User | UserGroup>(id, feature);
		const sharingSettings = report.sharingSettings || [];
		const permissionType = permission == Permission.Update;

		if (feature === XyiconFeature.User) {
			sharingSettings.push({
				userID: item.id,
				userGroupID: null,
				canEditSharedReport: permissionType,
			});
		} else if (feature === XyiconFeature.UserGroup) {
			sharingSettings.push({
				userID: null,
				userGroupID: item.id,
				canEditSharedReport: permissionType,
			});
		}

		return onSaveChanges();
	};

	const onDeleteUserGroup = (userGroup: UserGroup) => {
		report.removeUserGroupFromSharing(userGroup.id);

		return onSaveChanges();
	};

	const onDeleteUser = (user: User) => {
		report.removeUserFromSharing(user.id);

		return onSaveChanges();
	};

	const onConfirmOwnershipChange = (sharing: ViewSharingSettingsData) => {
		const tempOwnerId = report.ownedBy;

		// set new view owner
		report.ownedBy = sharing.userID;

		// set editing permission to old owner
		const oldOwner = report.sharingSettings.find((sharing) => sharing.userID === tempOwnerId);

		if (oldOwner) {
			oldOwner.canEditSharedReport = true;
		} else {
			const tempOwner = appState.actions.findUser(tempOwnerId);

			report.sharingSettings.push({
				userID: tempOwner.id,
				userGroupID: null,
				canEditSharedReport: true,
			});
		}
	};

	const onSetUserGroupPermission = (permission: number, sharing: ViewSharingSettingsData) => {
		sharing.canEditSharedView = permission === Permission.Update;

		return onSaveChanges();
	};

	console.log(report.sharingSettings.length);

	return (
		<SharingPopupV5
			onAddClick={onAddClick}
			onDeleteUserGroup={onDeleteUserGroup}
			onDeleteUser={onDeleteUser}
			onConfirmOwnershipChange={onConfirmOwnershipChange}
			setUserGroupPermission={onSetUserGroupPermission}
			onSaveChanges={onSaveChanges}
			ownedByUserId={report.ownedBy}
			itemLabel={getLabelFromViewReportOrViewFolder(report)}
			itemName={report.name}
			onClose={onClose}
			sharingSettings={report.sharingSettings}
			showShareOption={true}
		/>
	);
};

import type {CSSProperties} from "react";
import React, {useRef, useState} from "react";
import styled from "styled-components";
import type {Link} from "../../../../../data/models/Link";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {useClickOutside} from "../../../utils";
import {InfoBubbleV5} from "../../../button/InfoBubbleV5";
import {SpaceItemContainerStyled} from "../../../spaceeditor/SpaceItemContainer.Style";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {FlexCenterStyle} from "../../../styles/styles";
import {DropdownButtonStyled, DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import Dots from "../../../icons/dots-horizontal.svg?react";
import BreakLink from "../../../icons/unlink.svg?react";
import Trash from "../../../icons/trash.svg?react";
import GrabbableIcon from "../../../icons/grip-dots-vertical.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {LinkBreakersV5} from "./LinkBreakersV5";

interface ICrossPortfolioLinkData {
	link: Link;
	crossPortfolioXyiconId: string;
	otherPortfolioId: string;
	otherPortId: string;
	onePortId: string;
}

interface ICrossPortfolioXyiconProps {
	readonly transport: TransportLayer;
	readonly linkData: ICrossPortfolioLinkData;
	readonly showIconOnly?: boolean;
	readonly showBreakLinkAndDeleteButton: boolean;
}

export const CrossPortfolioXyiconV5 = (props: ICrossPortfolioXyiconProps) => {
	const transport = props.transport;
	const {linkData, showIconOnly, showBreakLinkAndDeleteButton} = props;
	const parentRef = useRef<HTMLDivElement>();
	const infoRef = useRef<HTMLDivElement>();
	const contextMenuRef = useRef<HTMLDivElement>();
	const [isInfoBubbleVisible, setIsInfoBubbleVisible] = useState<boolean>(false);
	const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

	useClickOutside([parentRef, contextMenuRef], () => {
		setIsContextMenuOpen(false);
	});

	const onContextMenu = (event: React.MouseEvent) => {
		if (showIconOnly) {
			event.preventDefault();
			setIsContextMenuOpen(true);
		}
	};

	const infoText = `UUID: ${linkData.crossPortfolioXyiconId}`;

	const transformForInfoRef =
		parentRef.current &&
		infoRef.current &&
		DomUtils.getFixedFloatingElementPosition(parentRef.current, infoRef.current, VerticalAlignment.topOuter, HorizontalAlignment.center);

	const transformForContextMenuRef =
		parentRef.current &&
		contextMenuRef.current &&
		DomUtils.getFixedFloatingElementPosition(parentRef.current, contextMenuRef.current, VerticalAlignment.bottomOuter, HorizontalAlignment.center);

	const inlineStyleForInfoText: CSSProperties = {};

	if (transformForInfoRef) {
		inlineStyleForInfoText.transform = transformForInfoRef.translate;
		inlineStyleForInfoText.left = "0";
	}

	inlineStyleForInfoText.visibility = isInfoBubbleVisible ? "visible" : "hidden";

	const inlineStyleForContextMenu: CSSProperties = {};

	if (transformForContextMenuRef) {
		inlineStyleForContextMenu.transform = transformForContextMenuRef.translate;
		inlineStyleForContextMenu.left = "0";
	}

	inlineStyleForContextMenu.visibility = isContextMenuOpen ? "visible" : "hidden";

	const onBreakLinkClickMaybe = showBreakLinkAndDeleteButton ? () => LinkBreakersV5.breakLinks(transport, [linkData.link.id], true) : undefined;
	const onDeleteClickMaybe = showBreakLinkAndDeleteButton
		? async () => {
				const isConfirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item(s)?");

				if (isConfirmed) {
					return transport.requestForOrganization({
						url: "xyicons/delete?v=2.0",
						method: XHRLoader.METHOD_DELETE,
						params: {
							portfolioID: linkData.otherPortfolioId,
							xyiconIDList: [linkData.crossPortfolioXyiconId],
						},
					});
				}
			}
		: undefined;

	return (
		<CrossPortfolioXyiconStyled
			className={ReactUtils.cls("CrossPortfolioXyicon DraggableXyiconCatalogItem", {showIconOnly})}
			onMouseEnter={() => setIsInfoBubbleVisible(true)}
			onMouseLeave={() => setIsInfoBubbleVisible(false)}
			onContextMenu={onContextMenu}
		>
			<SpaceItemContainerStyled
				className="SpaceItemContainer hbox"
				ref={parentRef}
				$grababble={false}
				$noPermission={false}
			>
				<div className={ReactUtils.cls("SpaceItem hbox alignCenter", {showIconOnly})}>
					{!showIconOnly && <GrabbableIcon style={{color: colorPalette.gray.c400, minWidth: 16}} />}
					<div className="thumbnailContainer flexCenter">
						<SVGIcon
							icon="xyicons"
							style={{width: "28px", height: "28px", fill: "#9E9E9E"}}
						/>
					</div>
					{!showIconOnly && (
						<>
							<div className="guid">{linkData.crossPortfolioXyiconId}</div>
							<DropdownButtonV5
								button={<Dots />}
								horizontalAlignment={HorizontalAlignment.right}
								options={[
									{
										label: "Break Link",
										onClick: onBreakLinkClickMaybe,
										IconComponent: BreakLink,
									},
									{
										label: "Delete",
										onClick: onDeleteClickMaybe,
										IconComponent: Trash,
									},
								]}
							/>
						</>
					)}
				</div>
				<DomPortal destination={transport.appState.app.modalContainer}>
					<InfoBubbleV5
						content={infoText}
						style={inlineStyleForInfoText}
						divRef={infoRef}
					/>
				</DomPortal>
			</SpaceItemContainerStyled>
		</CrossPortfolioXyiconStyled>
	);
};

const CrossPortfolioXyiconStyled = styled.div`
	${FlexCenterStyle};

	${DropdownButtonStyled} {
		display: none;
	}

	&:hover {
		${DropdownButtonStyled} {
			display: flex;
		}
	}

	.SpaceItem {
		flex-wrap: nowrap;
		gap: 8px;
		width: 100%;

		.thumbnailContainer {
			width: 40px;
			height: 40px;
			position: relative;
			background-color: #dfdfdf;
			border-radius: 4px;
		}
	}

	.guid {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {SpaceEditorMode} from "../renderers/SpaceViewRendererUtils";
import type {ITileData} from "../../../../../../offline_utils/utils/PDFRendererUtils";

export interface ITileRasterizer {
	getTileUrl: (tileId: string) => Promise<ITileData> | ITileData;
	numberOfPendingTiles: number;
	isPending: boolean;
}

export class OfflineTileRasterizer implements ITileRasterizer {
	private _spaceViewRenderer: SpaceViewRenderer;
	public numberOfPendingTiles: number = 0;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public getTileUrl(tileId: string): Promise<ITileData> {
		const tileManager = this._spaceViewRenderer.tileManager;
		const zoomInfoObject = tileManager.zoomInfo[tileManager.currentZoomLevel];

		return this._spaceViewRenderer.pdfRenderer.addTileToQueue(
			tileId,
			zoomInfoObject,
			this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN,
			this._spaceViewRenderer.tileResolution,
		);
	}

	public get isPending() {
		return this.numberOfPendingTiles > 0;
	}
}

export class OnlineTileFetcher implements ITileRasterizer {
	private _spaceViewRenderer: SpaceViewRenderer;
	public numberOfPendingTiles: number = 0;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public getTileUrl(tileId: string): ITileData {
		const {selectedSpaceFile} = this._spaceViewRenderer.space;
		const strippedTileId: string = tileId.split("_").slice(0, 3).join("_");
		const tileManager = this._spaceViewRenderer.tileManager;
		const zoomInfoObject = tileManager.zoomInfo[tileManager.currentZoomLevel];

		const url = this._spaceViewRenderer.transport.getFullPathFromServer(`spaces/tilesets/${selectedSpaceFile.tileSetId}/${strippedTileId}.webp`);

		const tileData: ITileData = {
			url: url,
			insertionInfo: this._spaceViewRenderer.space.newestSpaceFile.insertionInfo,
			tileId: tileId,
			context: null,
			zoomInfoObject: zoomInfoObject,
		};

		return tileData;
	}

	public get isPending() {
		return this.numberOfPendingTiles > 0;
	}
}

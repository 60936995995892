import * as React from "react";

import {ChartWidget} from "../ChartWidget";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../utils/ReactUtils";

export interface IBarChartProps<T> {
	data: T[];
	labels: {
		title: string;
		selector: (item: T) => boolean;
	}[];
	onExpand: () => void;
}

interface IBarChartState {
	selectedIndex: number;
}

interface ILabelData {
	title: string;
	count: number;
}

export class BarChart<T = {}> extends React.PureComponent<IBarChartProps<T>, IBarChartState> {
	private _size = [300, 300];
	private _colors = ["#4CAF50", "#00BCD4", "#E91E63", "#FFC107", "#9E9E9E", "#CDDC39"];

	constructor(props: IBarChartProps<T>) {
		super(props);

		this.state = {
			selectedIndex: -1,
		};
	}

	private getLabelData() {
		const {data, labels} = this.props;
		const result: ILabelData[] = [];

		for (let i = 0; i < labels.length; ++i) {
			const label = labels[i];

			result.push({
				title: label.title,
				count: 0,
			});
		}

		for (const row of data) {
			for (let i = 0; i < labels.length; ++i) {
				const label = labels[i];

				if (label.selector(row)) {
					result[i].count++;
					break;
				}
			}
		}

		return result;
	}

	private getLabelColor(index: number) {
		return this._colors[index % this._colors.length];
	}

	private onHover = (index: number) => {
		this.setState({selectedIndex: index});
	};

	private onHoverOut = () => {
		this.setState({selectedIndex: -1});
	};

	public override render() {
		const {data} = this.props;
		const labelData = this.getLabelData();

		const interacting = this.state.selectedIndex !== -1;

		const offset = 40;
		const maxWidth = 300;

		var left = 10;
		const bottom = labelData.length * offset + 30;

		let maxCount = labelData[0].count;

		return (
			<ChartWidget
				className={ReactUtils.cls("BarChart", {interacting: interacting})}
				onExpand={this.props.onExpand}
			>
				<>
					<svg
						viewBox={"0, -160, 300, 320"}
						style={{
							width: "100%",
							bottom: "0",
						}}
						className={ReactUtils.cls({interacting: interacting})}
						onMouseLeave={this.onHoverOut}
						onMouseOut={this.onHoverOut}
					>
						{labelData.map((label: ILabelData, index: number) => {
							maxCount = Math.max(maxCount, label.count);

							const y = index * offset + 20;
							const percent = label.count / data.length;

							const width = maxWidth * percent;

							const d = `M${left},${0} L${left + width},${0} z`;

							const color = this.getLabelColor(index);

							const title = `${label.title} - ${label.count} (${MathUtils.setPrecision(percent * 100, 1)}%)`;

							return (
								<React.Fragment key={index}>
									<text
										className={ReactUtils.cls("entry", {selected: index === this.state.selectedIndex})}
										x={left}
										y={y + 5}
										onMouseOver={(event) => this.onHover(index)}
										onMouseOut={this.onHoverOut}
										onMouseLeave={this.onHoverOut}
									>
										{label.title}
										<title>{title}</title>
									</text>
									<path
										key={index}
										stroke={color}
										strokeWidth="10px"
										d={d}
										onMouseOver={(event) => this.onHover(index)}
										className={ReactUtils.cls("slice", {selected: index === this.state.selectedIndex})}
										transform={`translate(${100}, ${y})`}
									>
										<title>{title}</title>
									</path>
								</React.Fragment>
							);
						})}
						<path
							d={`M${0},${bottom}, L${maxWidth + 40},${bottom} z`}
							stroke="#000000"
							strokeWidth="1px"
						/>
						<path
							d={`M${0},${bottom}, L${0},${-200} z`}
							stroke="#000000"
							strokeWidth="1px"
						/>
						<text
							x={maxWidth - 30}
							y={bottom - 10}
						>
							{maxCount}
						</text>
					</svg>
				</>
			</ChartWidget>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {User} from "../../../../../data/models/User";
import {Field} from "../../../../widgets/form/field/Field";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {Button} from "../../../../widgets/button/Button";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {UserForm} from "./UserForm";

interface IUserDetailsTabProps {
	users: User[];
	appState?: AppState;
	transport?: TransportLayer;
}

interface IUserDetailsTabStates {
	resendingInviteInProgress: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class UserDetailsTab extends React.Component<IUserDetailsTabProps, IUserDetailsTabStates> {
	constructor(props: IUserDetailsTabProps) {
		super(props);
		this.state = {
			resendingInviteInProgress: false,
		};
	}

	private onResendInviteClick = async () => {
		this.setState({resendingInviteInProgress: true});

		const result = await this.props.transport.requestForOrganization({
			url: "users/resendinvite",
			method: XHRLoader.METHOD_POST,
			params: {
				userID: this.props.users[0].id,
			},
		});

		this.setState({resendingInviteInProgress: false});
	};

	public override render() {
		const {users, appState} = this.props;
		const user = users[0];

		return (
			<div className="DetailsTab">
				{users.length === 0 && <div className="noData">{"Please select a User."}</div>}
				{users.length === 1 && (
					<>
						<div className="header">
							<Field label="Name">{user.fullName}</Field>
							<Field label="Email">{user.email}</Field>
							{appState.user?.isAdmin && user.status === "invited" && (
								<Button
									className="secondary resendInviteButton"
									onClick={this.onResendInviteClick}
									loading={this.state.resendingInviteInProgress}
									label="Resend Invite"
								/>
							)}
							<Field label="Status">{user.status}</Field>
							<Field label="Last Updated">{DateFormatter.format(user.lastModifiedAt)}</Field>
						</div>
						<UserForm users={users} />
					</>
				)}
			</div>
		);
	}
}

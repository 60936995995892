import {XyiconFeature} from "../../../../../generated/api/base";
import {AppFieldActions} from "../../../../../data/state/AppFields";

export const simpleFilterPositions = {
	[XyiconFeature.Portfolio]: [
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "name"),
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "type"),
	],
	[XyiconFeature.Space]: [
		AppFieldActions.getRefId(XyiconFeature.Space, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Space, "name"),
		AppFieldActions.getRefId(XyiconFeature.Space, "type"),
	],
	[XyiconFeature.Boundary]: [AppFieldActions.getRefId(XyiconFeature.Boundary, "refId"), AppFieldActions.getRefId(XyiconFeature.Boundary, "type")],
	[XyiconFeature.XyiconCatalog]: [
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "refId"),
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "type"),
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "model"),
	],
	[XyiconFeature.Xyicon]: [
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "type"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "model"),
	],
	[XyiconFeature.SpaceEditor]: [
		AppFieldActions.getRefId(XyiconFeature.Boundary, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Boundary, "type"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "type"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "model"),
	],
};

import {ObjectUtils} from "../data/ObjectUtils";
import {DebugInformation} from "../DebugInformation";

interface IXHRLoaderCallback {
	(xhr: XHRLoader): void;
}

export interface IXHRLoaderConfig {
	url: string;
	params?: any;
	userData?: any; // TODO could use template argument if there was default..
	method?: "POST" | "GET" | "DELETE" | "PUT";
	async?: boolean;
	addNoCacheUrl?: boolean;
	json?: boolean;
	responseType?: XMLHttpRequestResponseType;

	onSuccess?: IXHRLoaderCallback;
	onFail?: IXHRLoaderCallback;
	onComplete?: IXHRLoaderCallback;

	maxTries?: number;
	retryDelay?: number;
	headers?: {[key: string]: string};

	proxy?: string;
	withCredentials?: boolean;

	cancelToken?: XHRCancelToken;
}

export class XHRLoader {
	public static readonly METHOD_POST = "POST";
	public static readonly METHOD_GET = "GET";
	public static readonly METHOD_DELETE = "DELETE";
	public static readonly METHOD_PUT = "PUT";

	public static defaultConfig: IXHRLoaderConfig = {
		url: null,
		params: {},
		method: XHRLoader.METHOD_GET,
		async: true,
		addNoCacheUrl: false,
		json: false,
		maxTries: 0,
		retryDelay: 5000,
	};

	public static load(config: IXHRLoaderConfig): XHRLoader {
		return new XHRLoader(config);
	}

	public static loadAsync<T = any>(config: IXHRLoaderConfig): Promise<T> {
		return new Promise((resolve, reject) => {
			const debugLogId = `Fetching ${config.url}`;

			DebugInformation.start(debugLogId);
			config = {...config};
			config.onSuccess = (xhr: XHRLoader) => {
				DebugInformation.end(debugLogId);
				resolve(xhr.result);
			};
			config.onFail = (xhr: XHRLoader) => {
				DebugInformation.end(debugLogId);
				reject(xhr);
			};
			XHRLoader.load(config);
		});
	}

	public static encodeParams(params: any): string {
		const str: string[] = [];

		for (const key in params) {
			const encodedParam = XHRLoader.encodeParam(key, params[key]);

			str.push(encodedParam);
		}
		return str.join("&");
	}

	public static encodeParam(key: string, value: any) {
		try {
			if (typeof value !== "string") {
				value = JSON.stringify(value);
			}
		} catch (error) {
			console.error(error);
		}

		return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
	}

	// --------------------------------------------------------------------------------------------------
	// Instance

	protected _config: IXHRLoaderConfig;

	protected _xhr: XMLHttpRequest;

	protected _result: string;
	protected _responseText: string;

	protected _success: boolean;
	protected _resultMessage: string;
	protected _event: Event;

	protected _timeStarted: number;
	protected _timeFinished: number;
	protected _tryCount: number;

	constructor(config: IXHRLoaderConfig) {
		this._config = ObjectUtils.mergeConfig(XHRLoader.defaultConfig, config);

		this._timeStarted = Date.now();
		this._timeFinished = -1;
		this._tryCount = 0;

		this._resultMessage = "";

		const cancelToken = config.cancelToken;

		if (cancelToken) {
			cancelToken.cancel = this.onCancel;
			this._config.cancelToken = cancelToken;
		}

		this.load();
	}

	// XHR is cancelled by the user of XHR using the cancel token
	private onCancel = () => {
		this._xhr?.abort();
	};

	protected load() {
		let url = this._config.url;
		const method = this._config.method;
		const params = this._config.params || {};

		if (this._config.addNoCacheUrl) {
			params.noCache = Date.now();
		}

		if (method === XHRLoader.METHOD_GET && Object.keys(params).length > 0) {
			url += `?${XHRLoader.encodeParams(params)}`;
		}

		this._xhr = new XMLHttpRequest();

		if (this._config.responseType) {
			this._xhr.responseType = this._config.responseType;
		}

		const urlToUse = this._config.proxy || url;

		this._xhr.open(method, urlToUse, this._config.async);

		if (this._config.withCredentials) {
			this._xhr.withCredentials = true;
		}

		this.addListeners();

		this._tryCount++;

		if (this._config.proxy) {
			this._xhr.setRequestHeader("X-Proxy-URL", url);
		}

		this.setHeaders();

		const isFormData = typeof (params as FormData).append === "function";

		const contentType = this.config.json && !isFormData ? "application/json" : "x-www-form-urlencoded";

		this._xhr.setRequestHeader("Content-type", contentType);

		if (method !== XHRLoader.METHOD_GET) {
			let encoded_params = params;

			if (!isFormData) {
				encoded_params = this.config.json ? JSON.stringify(params) : XHRLoader.encodeParams(params);
			}
			this._xhr.send(encoded_params);
		} else {
			this._xhr.send();
		}
	}

	protected setHeaders() {
		const headers = this._config.headers;

		if (headers) {
			for (const key in headers) {
				this._xhr.setRequestHeader(key, headers[key]);
			}
		}
	}

	protected addListeners() {
		this._xhr.addEventListener("load", this.onLoad);
		// TODO
		//this._xhr.addEventListener("progress", this.onProgress);
		this._xhr.addEventListener("error", this.onError);
		this._xhr.addEventListener("timeout", this.onTimeout);
		this._xhr.addEventListener("abort", this.onAbort);
	}

	public abort() {
		this._xhr.abort();
	}

	// --------------------------------------------------------------------------------------------------
	// Event handlers

	private onLoad = (event: Event) => {
		const parseSuccess = this.parseResponse();

		if (parseSuccess) {
			if (this._xhr.status === 200) {
				this.terminate(true, "success", event);
			} else {
				this.terminate(false, "Response status is not 200!", event);
			}
		}
	};

	private parseResponse() {
		if (!this._xhr.responseType || this._xhr.responseType === "text") {
			this._responseText = this._xhr.responseText;
		}

		if (this._config.json) {
			try {
				if (this._responseText) {
					this._result = JSON.parse(this._responseText);
				}
			} catch (exception) {
				// parse error (not valid JSON)
				this.terminate(false, "Invalid JSON! Check the responseText property to debug!", event);
				return false;
			}
		} else if (this._xhr.responseType === "arraybuffer") {
			this._result = this._xhr.response;
		} else {
			this._result = this._responseText;
		}

		return true;
	}

	private onAbort = (event: Event) => {
		// transfer has been canceled (by programatically calling xhr.abort())
		this.terminate(false, "aborted", event);
	};

	private onError = (event: Event) => {
		// eg.: no internet
		this.terminate(false, "error", event, true);
	};

	private onTimeout = (event: Event) => {
		this.terminate(false, "timeout", event);
	};

	private terminate(success: boolean, resultMessage?: string, event?: Event, retry?: boolean) {
		this._success = success;
		this._resultMessage = resultMessage;
		this._event = event;

		if (this._success) {
			if (this._config.onSuccess) {
				this._config.onSuccess(this);
			}
		} else {
			if (retry) {
				if (this._tryCount < this._config.maxTries) {
					// this will create a new XmlHttpRequest instance
					setTimeout(this.retry, this._config.retryDelay);
				} else {
					// maxTries exceeded -> fail
					this.fail();
				}
			} else {
				// no point retrying -> fail
				this.fail();
			}
		}

		if (this._config.onComplete) {
			this._config.onComplete(this);
		}
	}

	private retry = () => {
		this.load();
	};

	private fail() {
		if (this._config.onFail) {
			this._config.onFail(this);
		}
	}

	public get config() {
		return this._config;
	}

	public get result(): any {
		return this._result;
	}

	public get responseText(): string {
		return this._responseText;
	}

	public get successful(): boolean {
		return this._success;
	}

	public get resultMessage() {
		return this._resultMessage;
	}

	public get xhr(): XMLHttpRequest {
		return this._xhr;
	}

	public get status(): number {
		return this._xhr.status;
	}
}

export class XHRCancelToken {
	public cancel: () => void = null;
}

import {computed, observable, makeObservable} from "mobx";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import type {IModel, IModelData} from "./Model";

export interface IPortfolioGroupData extends IModelData {
	portfolioGroupID: string; //"f25f4914-6115-45fb-a000-aaaaaaaa0001"
	portfolios: string[]; //"f25f4914-6115-45fb-a000-aaaaaaaa0001"
	name: string;
	lastModifiedAt: string; //"2020-04-12T18:55:00.4361514+00:00"
	lastModifiedBy: string; //"f25f4914-6115-45fb-a000-aaaaaaaa0023"
}

export class PortfolioGroup implements IModel {
	public static id = 1;

	@observable
	private _data: IPortfolioGroupData;

	constructor(data: IPortfolioGroupData) {
		makeObservable(this);
		this._data = data;
	}

	@computed
	public get id() {
		return this._data.portfolioGroupID;
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	public addPortfolio(portfolioId: string) {
		ArrayUtils.addMutable(this._data.portfolios, portfolioId);
	}

	public removePortfolio(portfolioId: string) {
		ArrayUtils.removeMutable(this._data.portfolios, portfolioId);
	}

	@computed
	public get portfolioIds() {
		return this._data.portfolios;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import * as React from "react";
import type {IToolConfig, SpaceTool} from "../../logic3d/features/tools/Tools";
import type {Color} from "../../../../../../generated/api/base";
import type {IToolButtonProps} from "./ToolButton";
import {SharedToolGroup} from "./SharedToolGroup";

interface IToolGroupProps {
	chosenTool?: SpaceTool;
	tools: {
		[id in SpaceTool]: IToolConfig;
	};
	activeTool: SpaceTool;
	onClick: (id: SpaceTool) => void;
	children: React.ReactElement<IToolButtonProps>[];
	color?: Color;
}

interface IToolGroupState {
	isOpen: boolean;
	chosenTool: SpaceTool;
}

export class ToolGroup extends React.Component<IToolGroupProps, IToolGroupState> {
	constructor(props: IToolGroupProps) {
		super(props);
		this.state = {
			isOpen: false,
			chosenTool: props.chosenTool || null,
		};
	}

	public override render() {
		const children = this.props.children;

		if (!this.state.chosenTool) {
			this.state = {
				isOpen: this.state.isOpen,
				chosenTool: children[0].props.toolId,
			};
		}

		const subMenuItems = children.map((container: React.ReactElement<IToolButtonProps>, index: number) => {
			const onClick = () => {
				container.props.onClick();
				this.setState({
					isOpen: false,
					chosenTool: container.props.toolId,
				});
			};

			return React.cloneElement(container, {key: index, onClick: onClick});
		});

		return (
			<SharedToolGroup
				tools={this.props.tools}
				activeTool={this.props.activeTool}
				chosenTool={this.state.chosenTool}
				onClick={this.props.onClick}
				color={this.props.color}
			>
				{subMenuItems}
			</SharedToolGroup>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Field} from "../../../../../data/models/field/Field";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {FieldDataTypes} from "../../../../../data/models/field/FieldDataTypes";
import type {IHeader} from "../../../../widgets/table/SimpleTable";
import {SettingTableTabV5} from "../abstract/SettingTableTabV5";
import {CreateFieldPanel} from "../../../../modules/settings/modules/field/CreateFieldPanel";
import {FieldDetailsTabV5} from "./FieldDetailsTabV5";

interface IFieldSettingsProps {
	feature: XyiconFeature;
	typesFeature: XyiconFeature;
	appState?: AppState;
	transport?: TransportLayer;
}

interface IFieldSettingsState {
	headers: IHeader[];
	selected: Field[];
}

@inject("appState")
@inject("transport")
@observer
export class FieldSettingsV5 extends React.Component<IFieldSettingsProps, IFieldSettingsState> {
	constructor(props: IFieldSettingsProps) {
		super(props);
		this.state = {
			headers: [
				{
					id: "refId",
					title: "ID",
				},
				{
					id: "name",
					title: "Name",
				},
				{
					id: "type",
					title: "Type",
				},
				{
					id: "displayOnLinks",
					title: "Display On Links",
				},
				[XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(props.feature) && {
					id: "hasFormula",
					title: "Has Formula",
				},
				props.appState.actions.isFieldValidationRuleAvailableInOrganization(props.feature) && {
					id: "validation",
					title: "Validation Rule",
				},
				{
					id: "helperText",
					title: "Helper Text",
				},
				{
					id: "description",
					title: "Field Description",
				},
			].filter((i) => !!i),
			selected: [],
		};
	}

	private getFields = (item: Field, index: number) => {
		return this.state.headers.map((header) => {
			// TODO
			const id = header.id;

			switch (id) {
				case "displayOnLinks":
					return this.props.appState.actions.getBooleanFieldTitle(item.displayOnLinks);
				case "hasFormula":
					return this.props.appState.actions.getBooleanFieldTitle(item.hasFormula);
				case "type":
					const dataType = FieldDataTypes.map[item.dataType];

					if (dataType) {
						return dataType.title;
					}
					break;
				case "validation":
					return item.validation;
				case "description":
					return item.fieldDescription;
				default:
					return item[id as "id" | "name"];
			}
		});
	};

	public override render() {
		const {headers} = this.state;
		const {feature, typesFeature} = this.props;

		return (
			<SettingTableTabV5
				headers={headers}
				getFields={this.getFields}
				feature={feature}
				typesFeature={typesFeature}
				kind="fields"
				create={(onCancel, onCreated) => (
					<CreateFieldPanel
						feature={feature}
						typesFeature={typesFeature}
						onCancel={onCancel}
						onCreated={onCreated}
					/>
				)}
				right={(selected) => (
					<FieldDetailsTabV5
						selected={selected}
						feature={feature}
						typesFeature={typesFeature}
					/>
				)}
				emptyListText={`There are no ${featureTitles[feature]} Fields to list.`}
			/>
		);
	}
}

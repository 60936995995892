import type {Texture, WebGLProgramParametersWithUniforms} from "three";
import {LinearSRGBColorSpace, DataTexture, RGBAFormat, FloatType, UVMapping, ClampToEdgeWrapping, NearestFilter, RawShaderMaterial} from "three";
import {ColorUtils} from "../../../../../../utils/ColorUtils";

export class HighlightMaterial extends RawShaderMaterial {
	private static readonly _MAP_RESOLUTION = 8;
	public static readonly ABSOLUTE_ARRAY_MAX = HighlightMaterial._MAP_RESOLUTION ** 2;
	// uniforms
	private _geometryDataSize: {
		type: "i";
		value: number;
	} = {
		type: "i",
		value: 0,
	};

	private _map: {
		type: "t";
		value: Texture;
	};

	private _color: {
		type: "3fv";
		value: number[];
	};

	private _opacity: {
		type: "f";
		value: number;
	};

	private _isGrayScaled: {
		type: "f";
		value: number;
	} = {
		type: "f",
		value: 0,
	};

	private _vertexShader: string = `precision highp float;

attribute vec3 position;

uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

varying vec3 worldPos;
varying vec2 vUv;

void main()
{
	vec4 transformed = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

	worldPos = (modelMatrix * vec4(position.rgb, 1.0)).xyz;
	vUv = vec2(position.xy + 0.5) / 1.0;

	gl_Position = transformed;
}`;

	private _fragmentShader: string;

	/**
	 *
	 * @param color eg.: 0x335555
	 * @param opacity [0-1]
	 * @param radius model space units
	 */
	constructor(color: number, opacity: number, radius: number) {
		super({transparent: true, depthTest: false});

		this._map = {
			type: "t",
			value: HighlightMaterial.createDataTexture(),
		};

		this._color = {
			type: "3fv",
			value: [],
		};

		this.setColor(color);

		this._opacity = {
			type: "f",
			value: opacity,
		};

		this.createFragmentShader(radius);

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;
			program.uniforms = {
				color: this._color,
				opacity: this._opacity,
				isGrayScaled: this._isGrayScaled,
				map: this._map,
				geometryDataSize: this._geometryDataSize,
			};
		};
	}

	private createFragmentShader(radius: number) {
		this._fragmentShader = `precision highp float;

#define MAP_RESOLUTION ${HighlightMaterial._MAP_RESOLUTION}
#define ARRAY_MAX ${HighlightMaterial.ABSOLUTE_ARRAY_MAX}
#define RADIUS ${radius}

//https://www.shadertoy.com/view/XdXSzX
#define SRGB_COEFFICIENTS vec3(0.2126, 0.7152, 0.0722)

varying vec2 vUv;

varying vec3 worldPos;

uniform vec3 color;
uniform float opacity;
uniform float isGrayScaled;

// geometrydata is stored in map
uniform sampler2D map;
uniform int geometryDataSize;

float sdSegment(in vec2 p, in vec2 a, in vec2 b)
{
	vec2 pa = p - a;
	vec2 ba = b - a;
	float h = clamp(dot(pa, ba) / dot(ba, ba), 0.0, 1.0);
	
	return length(pa - ba * h);
}

bool shouldBeColored(in vec2 fragCoord)
{
	vec2 a;
	vec2 b;

	vec2 offset = vec2(0.5, 0.5); // so it points to the center of the texel
	vec2 texelCoords = vec2(0, 0) + offset;

	vec4 geometryData = texture2D(map, texelCoords / vec2(MAP_RESOLUTION, MAP_RESOLUTION));

	for (int i = 0; i < ARRAY_MAX; ++i)
	{
		if ((i*4 + 4) > geometryDataSize)
		{
			return false;
		}

		a = geometryData.xy;
		b = geometryData.zw;

		if (sdSegment(fragCoord, a, b) < RADIUS)
		{
			return true;
		}

		if ((i*4 + 6) > geometryDataSize)
		{
			return false;
		}

		a = geometryData.zw;
		texelCoords = vec2(mod(float(i + 1), float(MAP_RESOLUTION)), (i + 1) / MAP_RESOLUTION) + offset;
		geometryData = texture2D(map, texelCoords / vec2(MAP_RESOLUTION, MAP_RESOLUTION));
		b = geometryData.xy;

		if (sdSegment(fragCoord, a, b) < RADIUS)
		{
			return true;
		}
	}

	return false;
}

void main()
{
	vec2 fragCoord = worldPos.xy;

	vec4 outputColor = shouldBeColored(fragCoord) ? vec4(color, opacity) : vec4(0.0);
	gl_FragColor = isGrayScaled > 0.5 ? vec4(vec3(dot(outputColor.rgb, SRGB_COEFFICIENTS)), outputColor.a) : outputColor;
}`;
	}

	private static createDataTexture() {
		return new DataTexture(
			new Float32Array(HighlightMaterial.ABSOLUTE_ARRAY_MAX * 4), // rgba
			HighlightMaterial._MAP_RESOLUTION,
			HighlightMaterial._MAP_RESOLUTION,
			RGBAFormat,
			FloatType,
			UVMapping,
			ClampToEdgeWrapping,
			ClampToEdgeWrapping,
			NearestFilter,
			NearestFilter,
			0,
			LinearSRGBColorSpace,
		);
	}

	public setGeometryData(worldGeometryData: number[]) {
		if (worldGeometryData.length <= HighlightMaterial.ABSOLUTE_ARRAY_MAX * 4) {
			// vec4
			for (let i = 0; i < worldGeometryData.length; ++i) {
				this._map.value.image.data[i] = worldGeometryData[i];
			}
			this._geometryDataSize.value = worldGeometryData.length;
			this._map.value.needsUpdate = true;
		} else {
			console.log("Reached maximum geometry data");
		}
	}

	private getArrayFromColor(color: number) {
		return ColorUtils.hex2Array(color).slice(0, 3);
	}

	public setColor(color: number) {
		this._color.value = this.getArrayFromColor(color);
	}

	public setGrayScaled(value: boolean) {
		this._isGrayScaled.value = value ? 1.0 : 0.0;
	}

	public setOpacity(value: number) {
		this._opacity.value = value;
	}

	public get opacityValue() {
		return this._opacity.value;
	}

	// So it can be disposed, when the scene is disposed
	public get map() {
		return this._map.value;
	}
}

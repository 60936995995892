import type {Texture} from "three";
import {MathUtils as THREEMath, TextureLoader, ClampToEdgeWrapping} from "three";
import {ImageUtils} from "../../../../../../utils/image/ImageUtils";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {Catalog} from "../../../../../../data/models/Catalog";
import type {PointDouble} from "../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../utils/math/MathUtils";

interface ITextureAtlasElement {
	x: number;
	y: number;
}

export class XyiconTextureAtlas {
	private static readonly _maxResolution: number = 4096;
	private static readonly _elementResolution: number = 128;
	private static readonly _padding: number = 0;

	private _transport: TransportLayer;
	private _map: Map<string, ITextureAtlasElement> = new Map();

	private _textureURL: string;
	private _texture: Texture;

	private _atlasSize: PointDouble; // columns, rows
	private readonly _id: number;

	constructor(transport: TransportLayer, id: number) {
		this._transport = transport;
		this._id = id;
	}

	private putImageOnAtlas(catalog: Catalog, x: number, y: number, isReplacing: boolean) {
		return new Promise<boolean>((resolve, reject) => {
			const ctx = ImageUtils.ctx;
			const img = document.createElement("img");

			img.onload = () => {
				img.width = img.height = XyiconTextureAtlas._elementResolution;

				const padding = XyiconTextureAtlas._padding;
				const iconSize = XyiconTextureAtlas._elementResolution - 2 * padding;
				const coord = {
					x: x * XyiconTextureAtlas._elementResolution + padding,
					y: y * XyiconTextureAtlas._elementResolution + padding,
				};

				if (isReplacing) {
					ctx.clearRect(coord.x, coord.y, iconSize, iconSize);
					ctx.fillStyle = ImageUtils.clearFillStyle;
					ctx.fillRect(coord.x, coord.y, iconSize, iconSize);
				}
				ctx.drawImage(img, coord.x, coord.y, iconSize, iconSize);

				this._map.set(catalog.id, {x: x, y: y});
				resolve(true);
			};
			// As per: https://stackoverflow.com/questions/28690643/firefox-error-rendering-an-svg-image-to-html5-canvas-with-drawimage
			//
			// Firefox does not support drawing SVG images to canvas unless the svg file has width / height attributes
			// on the root < svg > element and those width / height attributes are not percentages.This is a longstanding bug.
			// You will need to edit the icon.svg file so it meets the above criteria.
			//
			// https://bugzilla.mozilla.org/show_bug.cgi?id=700533
			img.src = catalog.thumbnail.replace(
				">",
				` width="${XyiconTextureAtlas._elementResolution}" height="${XyiconTextureAtlas._elementResolution}">`,
			);
		});
	}

	public putCatalogById(catalogId: string, x?: number, y?: number): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if (this._map.size < XyiconTextureAtlas.maxElementCount) {
				const isReplacingIcon = x != null && y != null;

				this.prepareCanvas(isReplacingIcon ? this._map.size : this._map.size + 1);

				const previousImg = document.createElement("img");

				previousImg.onload = async () => {
					ImageUtils.ctx.drawImage(previousImg, 0, ImageUtils.ctx.canvas.height - previousImg.height);

					if (!isReplacingIcon) {
						const newIndex = this._map.size;

						({x, y} = MathUtils.indexToXY(newIndex, XyiconTextureAtlas.maxColumnSize));
					}
					const catalog = this._transport.appState.actions.getFeatureItemById(catalogId, XyiconFeature.XyiconCatalog) as Catalog;

					await this.putImageOnAtlas(catalog, x, y, isReplacingIcon);
					await this.updateTexture();

					resolve(true);
				};
				previousImg.src = this._textureURL;
			} else {
				resolve(false);
			}
		});
	}

	private async updateTexture() {
		this._textureURL = ImageUtils.canvas.toDataURL();
		this._texture = await new TextureLoader().loadAsync(this._textureURL);
		this._texture.wrapS = this._texture.wrapT = ClampToEdgeWrapping;
		this._texture.flipY = false;
	}

	private prepareCanvas(catalogCount: number) {
		const maxColumnSize = XyiconTextureAtlas.maxColumnSize;
		const numberOfColumns = Math.min(catalogCount, maxColumnSize);
		const numberOfRows = Math.ceil(catalogCount / maxColumnSize);

		const resolution = {
			width: THREEMath.ceilPowerOfTwo(numberOfColumns * XyiconTextureAtlas._elementResolution),
			height: THREEMath.ceilPowerOfTwo(numberOfRows * XyiconTextureAtlas._elementResolution),
		};

		this._atlasSize = {
			x: resolution.width / XyiconTextureAtlas._elementResolution,
			y: resolution.height / XyiconTextureAtlas._elementResolution,
		};

		ImageUtils.canvas.width = resolution.width;
		ImageUtils.canvas.height = resolution.height;

		ImageUtils.removeBlackBorders(ImageUtils.ctx);

		return {
			resolution: resolution,
			numberOfColumns: numberOfColumns,
			numberOfRows: numberOfRows,
		};
	}

	/**
	 *
	 * @param catalogItemIDs must be filtered! If the same catalogItemID is present multiple times in this array, then it will be loaded multiple times!
	 */
	public init(catalogItemIDs: string[]) {
		return new Promise<boolean>(async (resolve, reject) => {
			const {numberOfColumns, numberOfRows} = this.prepareCanvas(catalogItemIDs.length);

			let counter = 0;

			for (let i = 0; i < numberOfRows; ++i) {
				for (let j = 0; j < numberOfColumns; ++j) {
					if (counter < catalogItemIDs.length) {
						const catalogID = catalogItemIDs[counter++];
						const catalog = this._transport.appState.actions.getFeatureItemById(catalogID, XyiconFeature.XyiconCatalog) as Catalog;

						await this.putImageOnAtlas(catalog, j, i, false);
					} else {
						break;
					}
				}
			}

			await this.updateTexture();

			resolve(true);
		});
	}

	public dispose() {
		this._texture.dispose();
	}

	public get texture() {
		return this._texture;
	}

	public get atlasSize() {
		return this._atlasSize;
	}

	private static get maxColumnSize() {
		return Math.floor(XyiconTextureAtlas._maxResolution / XyiconTextureAtlas._elementResolution);
	}

	public static get maxElementCount() {
		return this.maxColumnSize ** 2;
	}

	public get id() {
		return this._id;
	}

	public getXYByCatalogId(catalogId: string) {
		return this._map.get(catalogId);
	}
}

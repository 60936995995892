import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../generated/api/base";
import type {App} from "../../../../App";
import type {AppState} from "../../../../data/state/AppState";
import type {IModel} from "../../../../data/models/Model";
import type {Boundary} from "../../../../data/models/Boundary";
import type {Xyicon} from "../../../../data/models/Xyicon";
import {BoundaryUtils} from "../../../../data/models/BoundaryUtils";
import {Functions} from "../../../../utils/function/Functions";
import type {Portfolio} from "../../../../data/models/Portfolio";
import OpenIcon from "../../icons/book-open.svg?react";
import PinIcon from "../../icons/pin.svg?react";
import CopyIcon from "../../icons/copy.svg?react";
import type {Navigation} from "../../../../Navigation";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {ReactUtils} from "../../../utils/ReactUtils";
import {BoundarySpaceMapSelectorV5} from "../../spaceeditor/BoundarySpaceMapSelectorV5";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {colorPalette} from "../../styles/colorPalette";
import {zIndex} from "../../styles/styles";
import {NavigationEnum} from "../../../../Enums";
import {SideBarNavItemV5} from "./SideBarNavItemV5";

interface IMenuWithIconComponent {
	label: string;
	nav: NavigationEnum;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;
	component?: React.ComponentClass<{param1?: string; param2?: string}>;
	hasSub: boolean;
	//section: "portfolio" | "modules";
	feature: XyiconFeature;
	admin?: boolean;
}

interface IQuickLinksProps<T> {
	className?: string;
	hoveringModel: T;
	xPos: number;
	yPos: number;
	navigation?: Navigation;
	transport?: TransportLayer;
	feature?: XyiconFeature;
	onDuplicateClick?: (model: T) => void;
	isVisibleInSpaceEditor?: (item: T) => boolean;
	showNotification?: (item: T) => void;

	app?: App;
	appState?: AppState;
}

interface IQuickLinksState {
	isBoundarySelectorOpen: boolean;
	updatedX: number;
	updatedY: number;
}

@inject("app")
@inject("appState")
@inject("transport")
@inject("navigation")
export class QuickLinksV5<T> extends React.Component<IQuickLinksProps<T>, IQuickLinksState> {
	private static _portfolioMenuItems = ["Spaces", "Xyicons", "Boundaries"];
	private static _availableInTheseFeatures = [
		XyiconFeature.Portfolio,
		XyiconFeature.Space,
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
		XyiconFeature.XyiconCatalog,
	];
	private _quickLinkOptionRef = React.createRef<HTMLDivElement>();
	private _quickLinkRef = React.createRef<HTMLDivElement>();

	constructor(props: IQuickLinksProps<T>) {
		super(props);

		this.state = {
			isBoundarySelectorOpen: false,
			updatedX: this.props.xPos,
			updatedY: this.props.yPos,
		};
	}

	private onShowBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: true,
		});
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: false,
		});
	};

	private onQuickLinkOptionClick = () => {
		const {hoveringModel, appState, navigation, isVisibleInSpaceEditor, showNotification} = this.props;
		const item = hoveringModel;
		const itemOwnFeature = (item as unknown as IModel).ownFeature;

		if (itemOwnFeature === XyiconFeature.Space) {
			navigation.goApp(NavigationEnum.NAV_SPACE, (item as unknown as IModel).id);
		} else if (itemOwnFeature === XyiconFeature.XyiconCatalog) {
			this.props.onDuplicateClick(item);
		} else {
			if (!isVisibleInSpaceEditor(item)) {
				showNotification(item);
			}
			if (itemOwnFeature === XyiconFeature.Xyicon) {
				appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true);
			} else if ((item as unknown as Boundary).isBoundary) {
				if (BoundaryUtils.doesHaveMultipleSpaceMaps(item as unknown as Boundary)) {
					this.onShowBoundarySpaceMaps();
				} else {
					appState.actions.navigateToSpaceItem(item as unknown as Boundary, true);
				}
			}
		}
	};

	private getIcon() {
		switch (this.props.feature) {
			case XyiconFeature.Space:
				return <OpenIcon />;
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return <PinIcon />;
			case XyiconFeature.XyiconCatalog:
				return <CopyIcon />;
			default:
				break;
		}
	}

	private getIconLabel() {
		switch (this.props.feature) {
			case XyiconFeature.Space:
				return "Open this Space";
			case XyiconFeature.Xyicon:
				return "Open in Space Editor";
			case XyiconFeature.Boundary:
				return "Open in Space Editor";
			case XyiconFeature.XyiconCatalog:
				return "Duplicate";
			default:
				break;
		}
	}

	private getMenuItems = (): IMenuWithIconComponent[] => {
		return this.props.navigation?.menus.map((menu) => {
			const menuClone = ObjectUtils.deepClone(menu) as unknown as IMenuWithIconComponent;

			menuClone.icon = PinIcon;

			return menuClone;
		});
	};

	private getContent() {
		const {hoveringModel, navigation} = this.props;
		const activeNav = location.hash.split("/").pop();

		return (hoveringModel as unknown as IModel).ownFeature === XyiconFeature.Portfolio ? (
			<div className="vbox">
				<ul>
					{this.getMenuItems()
						.filter((menu) => QuickLinksV5._portfolioMenuItems.includes(menu.label))
						.map((menu, index) => (
							<SideBarNavItemV5
								key={menu.label}
								feature={menu.feature}
								menuNav={menu.nav}
								MenuIcon={menu.icon}
								menuLabel={menu.label}
								onToggleNavigation={Functions.emptyFunction}
								activeNav={activeNav}
								portfolio={hoveringModel as unknown as Portfolio}
								className="SideBarNavItem"
								isOpenByDefault={true}
							/>
						))}
				</ul>
			</div>
		) : (
			<div
				className="hbox alignCenter"
				onClick={this.onQuickLinkOptionClick}
				ref={this._quickLinkOptionRef}
			>
				{this.getIcon()}
				<span>{this.getIconLabel()}</span>
				{this.state.isBoundarySelectorOpen && (
					<BoundarySpaceMapSelectorV5
						item={hoveringModel as unknown as Boundary}
						onClose={this.onHideBoundarySpaceMaps}
					/>
				)}
			</div>
		);
	}

	private corrigatePosition() {
		const {xPos, yPos} = this.props;
		const {clientHeight: windowHeight, clientWidth: windowWidth} = document.body;
		const {clientHeight: menuHeight, clientWidth: menuWidth} = this._quickLinkRef.current;

		let updatedX = xPos;
		let updatedY = yPos;

		if (xPos + menuWidth > windowWidth) {
			updatedX = windowWidth - menuWidth;
		}
		if (yPos + menuHeight > windowHeight) {
			updatedY = windowHeight - menuHeight;
		}

		return {updatedX, updatedY};
	}

	public override componentDidMount() {
		const {xPos, yPos} = this.props;

		if (this._quickLinkRef.current) {
			const {updatedX, updatedY} = this.corrigatePosition();

			this._quickLinkRef.current.style.transform = `translate(${updatedX - xPos}px, ${updatedY - yPos}px)`;
		}
	}

	public override componentDidUpdate() {
		const {xPos, yPos} = this.props;

		if (this._quickLinkRef.current) {
			const {updatedX, updatedY} = this.corrigatePosition();

			this._quickLinkRef.current.style.transform = `translate(${updatedX - xPos}px, ${updatedY - yPos}px)`;
		}
	}

	public override render() {
		const {app, hoveringModel, feature, xPos, yPos} = this.props;

		return (
			<DomPortal destination={app.modalContainer}>
				{QuickLinksV5._availableInTheseFeatures.includes(feature) && (
					<QuickLinksStyled
						ref={this._quickLinkRef}
						className={ReactUtils.cls("QuickLinks", {navigateLink: !((hoveringModel as unknown as IModel).ownFeature === XyiconFeature.Portfolio)})}
						style={{left: `${xPos}px`, top: `${yPos}px`}}
					>
						{this.getContent()}
					</QuickLinksStyled>
				)}
			</DomPortal>
		);
	}
}

const QuickLinksStyled = styled.div`
	position: absolute;
	z-index: ${zIndex.modal};
	left: 0;
	top: 0;
	background: white;
	width: 180px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		.SideBarNavItem {
			display: flex;
			align-items: center;
			flex-direction: column;
			border-bottom: solid 1px ${colorPalette.gray.c900};

			.linkContainer {
				width: 100%;
				padding: 0;

				&:hover {
					background: ${colorPalette.primary.c500Primary};
					color: white;

					svg {
						fill: ${colorPalette.white};
					}

					.toggleContainer {
						.dropDownToggle {
							border-top: 5px solid ${colorPalette.white};
						}
					}
				}

				.navLink {
					display: flex;
					font-weight: 700;
					height: auto;
					padding: 8px 0;
					flex-grow: 1;

					&:hover {
						background: none;
					}
				}

				.iconContainer {
					margin: 0 15px 0 10px;
					height: 16px;

					svg {
						width: 16px;
						height: 16px;
					}
				}

				.toggleContainer {
					width: 35px;

					&:hover {
						background: none;
					}

					.dropDownToggle {
						border-top: 5px solid ${colorPalette.gray.c700Dark};
					}
				}
			}

			.views {
				display: none;
				align-self: flex-start;
				width: 100%;

				&.open {
					display: block;
				}

				li.view {
					min-height: 20px;
					padding: 5px 5px 5px 40px;
					padding-left: 16px;
					display: flex;
					align-items: center;

					&:hover {
						background: ${colorPalette.primary.c500Primary};
						color: ${colorPalette.white};
					}

					.viewItem {
						font-weight: 200;
					}
				}
			}
		}
	}

	&.navigateLink {
		font-weight: 500;
		height: auto;
		padding: 8px 0;
		cursor: pointer;

		.icon {
			width: 16px;
			height: 16px;
			margin: 0 15px 0 10px;
			fill: var(--icon);
		}

		&:hover {
			background: ${colorPalette.primary.c500Primary};
			color: white;

			svg {
				fill: ${colorPalette.white};
			}

			.toggleContainer {
				.dropDownToggle {
					border-top: 5px solid ${colorPalette.white};
				}
			}
		}
	}
`;

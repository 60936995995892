import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {SingleLineLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/singleline/SingleLineLabel";
import {IPInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/ip/IPInput";
import {SingleLineLabelV5} from "../../../../ui/5.0/details/datatypes/SingleLineLabelV5";
import {IPInputV5} from "../../../../ui/5.0/details/datatypes/IPInputV5";

export function configureIP() {
	FieldDataTypes.configure({
		id: FieldDataType.IPAddress,
		title: "IP",
		label: SingleLineLabel,
		labelV5: SingleLineLabelV5,
		input: IPInput,
		inputV5: IPInputV5,
		operatorsArray: stringOperators,
	});
}

import * as React from "react";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {changeMarkupType, markupTypeToMarkupToolName} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import type {MarkupType} from "../../../../../../generated/api/base";
import {MarkupsWithCustomText} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import {MarkupTypeOptions} from "./MarkupTypeOptions";

interface IMarkupTypeChangerProps {
	spaceViewRenderer: SpaceViewRenderer;
}

interface IMarkupTypeChangerState {
	isOpen: boolean;
}

export class MarkupTypeChanger extends React.Component<IMarkupTypeChangerProps, IMarkupTypeChangerState> {
	constructor(props: IMarkupTypeChangerProps) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	private get _selectedItems() {
		return this.props.spaceViewRenderer.spaceItemController.selectedItems;
	}

	private onMarkupTypeClick = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	private onMarkupTypeChange = (newType: MarkupType) => {
		return changeMarkupType(this._selectedItems as Markup3D[], newType, this.props.spaceViewRenderer);
	};

	private getActiveIcon() {
		const firstSelectedObject = this._selectedItems[0] as Markup3D;

		const toolName = markupTypeToMarkupToolName(firstSelectedObject?.type);
		const tool = this.props.spaceViewRenderer.tools[toolName];

		return tool.icon;
	}

	public override render() {
		const selectedItems = this._selectedItems;
		const areOnlySupportedTypesSelected = selectedItems.every(
			(item) => item.spaceItemType === "markup" && MarkupsWithCustomText.includes((item as Markup3D).type),
		);

		const style: React.CSSProperties = {};

		return (
			areOnlySupportedTypesSelected &&
			selectedItems.length === 1 && (
				<span
					onClick={this.onMarkupTypeClick}
					className="button flexCenter MarkupTypeChanger"
					title="Change Markup Type..."
				>
					<SVGIcon
						icon={this.getActiveIcon()}
						style={style}
					/>
					<div className="arrowWrapper">
						<i className="arrowDown corner"></i>
					</div>
					{this.state.isOpen && (
						<MarkupTypeOptions
							activeType={(this._selectedItems[0] as Markup3D).type}
							spaceViewRenderer={this.props.spaceViewRenderer}
							onChange={this.onMarkupTypeChange}
						/>
					)}
				</span>
			)
		);
	}
}

import type {Pointer} from "../../utils/interaction/Pointer";
import type {IPointerDetectorConfig} from "../../utils/interaction/PointerDetector";
import {PointerDetector} from "../../utils/interaction/PointerDetector";
import type {IWrapperProps} from "../widgets/wrapper/Wrapper";
import {Wrapper} from "../widgets/wrapper/Wrapper";

interface IPointerDetectorProps extends IWrapperProps, Partial<IPointerDetectorConfig> {
	onDown?: (pointer: Pointer) => void;
	onMove?: (pointer: Pointer) => void;
	onHoverMove?: (pointer: Pointer) => void;
	onUp?: (pointer: Pointer) => void;
	parent?: Element;
	element?: Element;
}

export class PointerDetectorReact extends Wrapper<IPointerDetectorProps, HTMLElement> {
	private _pointerDetector: PointerDetector;

	protected override onChildMounted(child: HTMLElement) {
		this._pointerDetector = new PointerDetector({
			...this.props,
			element: this.props.element || child,
			parent: this.props.parent !== undefined ? this.props.parent : (child.parentNode as HTMLElement),
		});

		this.addEventListeners(this.props);
	}

	private removeEventListeners() {
		this._pointerDetector.signals.down.removeAll();
		this._pointerDetector.signals.move.removeAll();
		this._pointerDetector.signals.hoverMove.removeAll();
		this._pointerDetector.signals.up.removeAll();
	}

	private addEventListeners(props: IPointerDetectorProps) {
		props.onDown && this._pointerDetector.signals.down.add(props.onDown);
		props.onMove && this._pointerDetector.signals.move.add(props.onMove);
		props.onHoverMove && this._pointerDetector.signals.hoverMove.add(props.onHoverMove);
		props.onUp && this._pointerDetector.signals.up.add(props.onUp);
	}

	private updateConfig(param: string) {
		const currentParam = this._pointerDetector.config[param as "element" | "parent"];

		if (this.props[param as "element" | "parent"] != null) {
			if (this.props[param as "element" | "parent"] !== currentParam) {
				this._pointerDetector.disable();
				this._pointerDetector.config[param as "element" | "parent"] = this.props[param as "element" | "parent"];
				this._pointerDetector.enable();
			}
		}
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IPointerDetectorProps>, nextContext: any): void {
		if (
			nextProps.onDown !== this.props.onDown ||
			nextProps.onMove !== this.props.onMove ||
			nextProps.onHoverMove !== this.props.onHoverMove ||
			nextProps.onUp !== this.props.onUp
		) {
			this.removeEventListeners();
			this.addEventListeners(nextProps);
		}
	}

	public override componentDidUpdate() {
		if (this._pointerDetector) {
			this.updateConfig("element");
			this.updateConfig("parent");
		}
	}

	public override componentWillUnmount() {
		if (this._pointerDetector) {
			this._pointerDetector.dispose();
			this._pointerDetector = null;
		}
	}
}

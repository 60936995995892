import type {PDFDocument} from "pdf-lib";
import {degrees} from "pdf-lib";
import {useCallback, useEffect, useState} from "react";
import {styled} from "styled-components";
import {IconButton} from "../../../widgets/button/IconButton";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {
	CreateSpaceFileRequest,
	CreateSpaceRequest,
	SpaceData,
	SpaceFileData,
	SpaceFileDto,
	SpaceFileInsertionInfo,
} from "../../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../../generated/api/base";
import type {Type} from "../../../../data/models/Type";
import type {Space} from "../../../../data/models/Space";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {WarningWindow} from "../../../modules/abstract/popups/WarningWindow";
import {PDFSplitter} from "../../../modules/space/create/PDFSplitter";
import {isSpaceNamePresentInAnotherSpaceVersion} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {useAppStore} from "../../../../StateManager";
import {radius} from "../../styles/styles";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {DateTimeInputV5} from "../../input/datetime/DateTimeInputV5";
import {ButtonV5} from "../../button/ButtonV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import {InfoButtonV5} from "../../button/InfoButtonV5";
import {DateUtils} from "../../../../utils/DateUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {StepIndicatorV5} from "../../abstract/view/StepIndicatorV5";
import {FieldV5} from "../../details/FieldV5";
import {DropdownButtonStyled} from "../../interaction/DropdownButtonV5";
import {FileDropperReactV5} from "./FileDropperReactV5";
import {PDFListV5} from "./PDFListV5";
import {SpaceConfirmSettingsV5} from "./SpaceConfirmSettingsV5";

const ExistingVersionSetsStyled = styled.div`
	overflow-y: auto;
	max-height: 200px;
	display: none;
	justify-content: left;
	align-items: left;
	flex: none;
	display: block;

	&.flexCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.flex1 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
`;

const SpaceTypeStyled = styled.div`
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	.element {
		flex-direction: row;
		align-items: center;
	}
`;

const PdfContainerStyled = styled.div`
	white-space: nowrap;
	padding: 5px;
	align-items: center;
	border: 2px solid #f5f5f5;

	.delete {
		margin-left: auto;
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}
`;

const SelectVersionStyled = styled.div`
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;

	h4 {
		font-weight: normal;
		margin-bottom: 25px;
	}
`;

const ButtonStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	padding-bottom: 20px;
	padding-right: 20px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
`;

const CreateSpacePanelStyled = styled.div`
	${DropdownButtonStyled} {
		margin: 0 8px;
	}

	.spaceName {
		max-width: 200px;
	}

	.uploadingArea {
		height: 100%;
	}

	.version {
		cursor: pointer;
		border: 1px solid rgba(0, 0, 0, 0);
		border-radius: 8px;
		p {
			margin: 0;
		}
		.date {
			margin-top: 5px;
		}
	}

	.SpaceScaleSettings {
		position: relative;
		height: 50vh;
		margin-left: initial;
		margin-right: initial;

		.PDFListV5 {
			width: 210px;
		}

		.PDFList {
			width: 100%;
			max-width: 200px;
			overflow: auto;
		}

		.pdfContainer {
			cursor: pointer;
		}
	}
	.SpaceEditor {
		margin-left: 20px;
	}

	.disabled {
		opacity: 0.5;
	}

	.FileDropper {
		margin: 20px 0;
		min-height: 175px;
	}

	&:hover::-webkit-scrollbar {
		height: 12px;
		border-radius: 6px;
	}
`;

const FileContainerStyled = styled.div`
	padding: 10px;
	margin: 10px 0 0 0;
	border: 2px solid #3495f0;
	overflow-y: auto;
	border-radius: 8px;
	height: auto;
	max-height: 19vh;

	.pdfContainer {
		padding: 10px;
	}
`;

const StyledVBox = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border: 2px solid #c8c8c8;
	cursor: pointer;
	transition: border-color 0.3s ease;
	margin: 8px;
	border-radius: 8px;
	width: 328px;

	h4 {
		font-weight: normal;
		margin-bottom: 25px;
		font-size: 18px;
		font-family: Roboto, sans-serif;
	}

	&.active {
		border: 2px solid #3495f0;
	}
`;

const VersionStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: 2px solid transparent;
	cursor: pointer;
	transition:
		border-color 0.3s ease,
		background-color 0.3s ease;

	div {
		display: flex;
		justify-content: space-between;
	}

	span {
		margin: 0;
		font-size: 16px;
		color: #363636;
	}

	.date {
		text-align: right;
		color: #7b7b7b;
	}

	&.active {
		border: 2px solid #3495f0 !important;
	}
`;

const FieldStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	.Field {
		display: flex;
		align-items: center;
		border-radius: ${radius.sm};

		.label {
			max-width: 150px;
			min-width: 120px;
			margin: 0;

			span {
				font-size: 16px;
			}
		}

		.element {
			width: 200px;
		}
	}
`;

export interface IOnePagePDFDoc {
	doc: PDFDocument;
	spaceId: string; // if there's already a space in an older version, with the same name provided
	insertionInfo: SpaceFileInsertionInfo; // if it's not the first background of a space, we have to provide this
	isAlignmentConfirmed: boolean;
	spaceUnitsPerMeter: number; // if it's the first background of a space, we have to provide a value for this
	spaceName: string;
	thumbnail: string;
	originalRotation: number; // 0 | 90 | 180 | 270
	originalPageNumber: number; // its pagenumber in the original pdf document
}
interface IModulePanelProps {
	onClose: (createdId?: string) => void;
}
interface ICreateSpacePanelState {
	pdfFiles: {
		originalFiles: any[];
		onePagePDFDocs: any[];
	};
}

const _steps: string[] = ["Select Version", "Upload PDF", "Review", "Set scale", "Create Spaces"];

export const CreateSpacePanelV5 = (props: IModulePanelProps) => {
	const appState = useAppStore((state) => state.appState);

	const [count, setCount] = useState(0);
	const [stepIndex, setStepIndex] = useState<number>(0);
	const [completedIndex, setCompletedIndex] = useState<number>(0);
	const [newVersionName, setNewVersionName] = useState<string>("");
	const [isVersionNameAvailable, setIsVersionNameAvailable] = useState<boolean>(true);
	const [selectedVersion, setSelectedVersion] = useState<SpaceVersion>(null);
	const [existingVersions, setExistingVersions] = useState<SpaceVersion[]>([]);
	const [issuanceDate, setIssuanceDate] = useState<string>(DateUtils.stringify(new Date()));
	const [isNewVersionSet, setIsNewVersionSet] = useState<boolean>(true);
	const [pdfFiles, setPdfFiles] = useState<ICreateSpacePanelState["pdfFiles"]>({
		originalFiles: [],
		onePagePDFDocs: [],
	});
	const [selectedType, setSelectedType] = useState<Type>(null);
	const [spaceNamesInSelectedVersion, setSpaceNamesInSelectedVersion] = useState<string[]>([]);
	const [uploadInfo, setUploadInfo] = useState<string>("");
	const [loadBarWidth, setLoadBarWidth] = useState<number>(0);
	const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

	const forceUpdate = () => {
		setCount(count + 1);
		isNextButtonEnabled();
	};

	const onBackClick = () => {
		if (stepIndex > 0) {
			setStepIndex(stepIndex - 1);
		} else {
			props.onClose();
		}
	};

	const onNextClick = async () => {
		if (stepIndex < _steps.length - 1) {
			if (stepIndex === 0 && isNewVersionSet) {
				const createData = {
					name: newVersionName,
					issuanceDate: issuanceDate,
					portfolioID: appState.portfolioId,
				};
				const services = appState.app.transport.services;
				const newSpaceVersionArray = await services.feature.create(createData, XyiconFeature.SpaceVersion);

				if (newSpaceVersionArray.length > 0) {
					const newSpaceVersion = newSpaceVersionArray[0];
					const spaceVersions = (await services.feature.refreshList(XyiconFeature.SpaceVersion)) as SpaceVersion[];
					const selectedVersion = spaceVersions.find((spaceVersion) => spaceVersion.id === newSpaceVersion.id);

					setSelectedVersion(selectedVersion);
					setExistingVersions(spaceVersions);
					setIsNewVersionSet(false);
				} else {
					await WarningWindow.open("Space Version couldn't be created.", "Error");
					return; // don't go to the next step
				}
			}
			setStepIndex(stepIndex + 1);
		} else if (stepIndex === _steps.length - 1) {
			const spaceFiles: (SpaceData & SpaceFileData)[] = [];

			for (const onePagePDFDocs of pdfFiles.onePagePDFDocs) {
				for (const onePagePDFDoc of onePagePDFDocs) {
					if (onePagePDFDoc.spaceId) {
						// "Replace" background
						spaceFiles.push({
							spaceVersionID: selectedVersion.id,
							spaceID: onePagePDFDoc.spaceId,
							sourceFile: await onePagePDFDoc.doc.saveAsBase64(),
							thumbnail: onePagePDFDoc.thumbnail,
							settings: {
								insertionInfo: onePagePDFDoc.insertionInfo,
							},
						});
					} // create completely new space
					else {
						spaceFiles.push({
							name: onePagePDFDoc.spaceName,
							sourceFile: await onePagePDFDoc.doc.saveAsBase64(),
							spaceUnitsPerMeter: onePagePDFDoc.spaceUnitsPerMeter,
							thumbnail: onePagePDFDoc.thumbnail,
							settings: {
								insertionInfo: onePagePDFDoc.insertionInfo,
							},
						});
					}
				}
			}

			setUploadInfo("Uploading PDFs to server...");

			requestAnimationFrame(async () => {
				try {
					// Upload them one-by-one, because it's safer (the ones that are successfully uploaded are done), and we can have more feedback
					for (let i = 0; i < spaceFiles.length; ++i) {
						const spaceFile = spaceFiles[i];

						if (spaceFile.spaceID) {
							// "Replace background"
							const params: CreateSpaceFileRequest = {
								portfolioID: appState.portfolioId,
								spaceFileList: [spaceFile] as SpaceFileData[],
							};

							setUploadInfo(`Uploading PDFs to server: ${i + 1} / ${spaceFiles.length}`);

							const {result} = await appState.app.transport.requestForOrganization<SpaceFileDto[]>({
								url: "spacefiles/create",
								method: XHRLoader.METHOD_PUT,
								params: params,
							});

							const space = appState.actions.getFeatureItemById(spaceFile.spaceID, XyiconFeature.Space) as Space;
							const newSpaceFileData = result[0] as SpaceFileDto;

							space.addSpaceFile(newSpaceFileData);
						} // Create completely new space
						else {
							const createData: CreateSpaceRequest = {
								portfolioID: appState.portfolioId,
								spaceTypeID: selectedType.id,
								spaceVersionID: selectedVersion.id,
								spaceData: [spaceFile] as SpaceData[],
							};

							setUploadInfo(`Uploading PDFs to server: ${i + 1} / ${spaceFiles.length}`);
							setLoadBarWidth(Math.round(((i + 1) / spaceFiles.length) * 100));

							const result = await appState.app.transport.services.feature.create<Space>(createData, XyiconFeature.Space);
						}
					}

					setUploadInfo("Uploading PDFs to the server has finished successfully");
					setUploadSuccess(true);
				} catch (error) {
					setUploadInfo("An error has occured while uploading PDFs to the server, please try again");
				}
				removeTemporarySpaceFiles();
				setTimeout(() => props.onClose("true"), 2000);
			});
		}
	};

	const spaces = appState.actions.getList<Space>(XyiconFeature.Space);

	const removeTemporarySpaceFiles = useCallback(() => {
		for (const space of spaces) {
			space.removeTemporarySpaceFiles();
		}
	}, [spaces]);

	const getSpaceNamesInVersion = (version: SpaceVersion) => {
		const spaceNamesInVersion = [];

		for (const space of spaces) {
			if (space.spaceFiles.some((spaceFile) => spaceFile.spaceVersionName === version.name)) {
				spaceNamesInVersion.push(space.name);
			}
		}

		return spaceNamesInVersion;
	};

	const getNewSpaceNames = () => {
		const newSpaceNames = [];

		for (const onePagePDFDocs of pdfFiles.onePagePDFDocs) {
			for (const onePagePDFDoc of onePagePDFDocs) {
				newSpaceNames.push(onePagePDFDoc.spaceName);
			}
		}

		return newSpaceNames;
	};

	const selectExistingVersion = (version: SpaceVersion) => {
		setSelectedVersion(version), setSpaceNamesInSelectedVersion(getSpaceNamesInVersion(version));
	};

	const getVersions = () => {
		const eVersions = existingVersions.filter((spaceVersion) => appState.actions.getFeatureItemById(spaceVersion.id, XyiconFeature.SpaceVersion));

		return eVersions.map((version: SpaceVersion, index: number) => {
			const isSelected = version === selectedVersion;

			return (
				<VersionStyled
					className={ReactUtils.cls("vbox version", {bordered: isSelected, active: isSelected})}
					key={index}
					onClick={() => selectExistingVersion(version)}
				>
					<div>
						<span>{version.name}</span>
						<span className="date">{DateFormatter.format(version.date.toString())}</span>
					</div>
				</VersionStyled>
			);
		});
	};

	const removePDF = (pdf: File) => {
		const index = pdfFiles.originalFiles.indexOf(pdf);

		removePDFByIndex(index);
	};

	const removePDFByIndex = (index: number) => {
		if (index > -1) {
			pdfFiles.originalFiles.splice(index, 1);
			pdfFiles.onePagePDFDocs.splice(index, 1);

			updatePDFsByFiles([]);
		}

		removeTemporarySpaceFiles();
	};

	const getOriginalPDFList = () => {
		return pdfFiles.originalFiles.map((pdf: File, index: number) => {
			return (
				<PdfContainerStyled
					className="hbox"
					key={index}
				>
					<div className="vbox">
						<div>{pdf.name}</div>
						<div style={{color: "#BFBFBF"}}>{FileUtils.getSizeLabelOfFile(pdf)}</div>
					</div>
					<IconButton
						icon="delete"
						title="Delete"
						className="delete"
						onClick={() => removePDF(pdf)}
					/>
				</PdfContainerStyled>
			);
		});
	};

	const isFileAlreadyInTheList = (file: File, files: File[]) => {
		for (let i = 0; i < files.length; ++i) {
			//if (ObjectUtils.compare(file, files[i]))

			// We don't allow the same name either, as it could easily cause bugs
			if (file.name === files[i].name) {
				return true;
			}
		}

		return false;
	};

	const onFileInputChange = (files: FileList) => {
		const newOriginalPDFFiles: File[] = [];

		for (let i = 0; i < files.length; ++i) {
			const file = files[i];

			if (!isFileAlreadyInTheList(file, pdfFiles.originalFiles)) {
				newOriginalPDFFiles.push(file);
			}
		}

		updatePDFsByFiles(newOriginalPDFFiles);
	};

	const generateSpaceName = (pageCounter: number): string => {
		const spaceName = `Floor ${pageCounter}`;

		if (isSpaceNameAvailable(spaceName)) {
			spaceNamesInSelectedVersion.push(spaceName);

			return spaceName;
		} else {
			return generateSpaceName(pageCounter + 1);
		}
	};

	const updatePDFsByFiles = async (files: File[]) => {
		const previousOnePagePDFObjects = pdfFiles.onePagePDFDocs;

		let pageCounter = 0;

		for (const file of files) {
			pdfFiles.originalFiles.push(file);

			const newPDFs = await PDFSplitter.split(file);
			const newOnePagePDFObjects: IOnePagePDFDoc[] = [];

			for (let j = 0; j < newPDFs.length; ++j) {
				const newPDF = newPDFs[j];

				const firstPage = newPDF.getPages()[0];
				const originalRotation = MathUtils.clampDegreeBetween0And360(firstPage.getRotation().angle);

				newOnePagePDFObjects.push({
					doc: newPDF,
					spaceId: null,
					insertionInfo: null,
					isAlignmentConfirmed: false,
					originalRotation: originalRotation,
					spaceName: generateSpaceName(++pageCounter),
					originalPageNumber: j,
					spaceUnitsPerMeter: null,
					thumbnail: null,
				});
			}

			previousOnePagePDFObjects.push(newOnePagePDFObjects);
		}

		const alreadyExistingSpaceNames = getSpaceNamesInVersion(selectedVersion);

		setSpaceNamesInSelectedVersion([...alreadyExistingSpaceNames, ...getNewSpaceNames()]);
	};

	const onPDFListItemDeleteClick = (originalPDFIndex: number, originalPageNumber: number) => {
		const onePagePDFDocs = pdfFiles.onePagePDFDocs;

		onePagePDFDocs[originalPDFIndex].splice(originalPageNumber, 1);

		if (onePagePDFDocs[originalPDFIndex].length < 1) {
			removePDFByIndex(originalPDFIndex);
		}

		forceUpdate();
	};

	const onPDFListItemRotate = (originalPDFIndex: number, originalPageNumber: number, deltaAngle: number) => {
		const onePagePDFDoc = pdfFiles.onePagePDFDocs[originalPDFIndex][originalPageNumber];

		const pdf = onePagePDFDoc.doc;
		const firstPage = pdf.getPages()[0];
		const rotation = MathUtils.clampDegreeBetween0And360(firstPage.getRotation().angle);

		const newRotation = MathUtils.clampDegreeBetween0And360(rotation + deltaAngle);

		firstPage.setRotation(degrees(newRotation));

		// force update need to be called within the PDFListItem event that called this method!
	};

	/**
	 * Space Names need to be unique
	 * Returns true if it's available, otherwise false
	 * @param spaceName
	 */
	const isSpaceNameAvailable = (spaceName: string) => {
		return !spaceNamesInSelectedVersion.includes(spaceName);
	};

	const onSpaceNameChange = (originalPDFIndex: number, originalPageNumber: number, newName: string) => {
		const newOnePagePDFDocs = pdfFiles.onePagePDFDocs;

		newOnePagePDFDocs[originalPDFIndex][originalPageNumber].spaceName = newName;

		const alreadyExistingSpaceNames = getSpaceNamesInVersion(selectedVersion);

		setSpaceNamesInSelectedVersion([...alreadyExistingSpaceNames, ...getNewSpaceNames()]);
	};

	const isVNameAvailable = (versionName: string) => {
		return appState.actions.isVersionSetNameValid(versionName, selectedVersion?.id);
	};

	const onVersionNameChange = (newValue: string) => {
		if (newValue !== newVersionName && isVersionNameAvailable) {
			setNewVersionName(newValue);
		}
	};

	const applySpaceUnitsPerMeterToAllSpaces = (spaceUnitsPerMeter: number) => {
		for (const onePagePDFDocs of pdfFiles.onePagePDFDocs) {
			for (const onePagePDFDoc of onePagePDFDocs) {
				const isInAlignMode = isSpaceNamePresentInAnotherSpaceVersion(appState.actions, onePagePDFDoc.spaceName);

				if (!isInAlignMode) {
					onePagePDFDoc.spaceUnitsPerMeter = spaceUnitsPerMeter;
				}
			}
		}

		setPdfFiles({
			onePagePDFDocs: [...pdfFiles.onePagePDFDocs],
			originalFiles: pdfFiles.originalFiles,
		});
	};

	const getCreatableTypes = () => {
		const {user, actions, types} = appState;
		const allTypes = types[XyiconFeature.Space];

		if (!user) {
			return [];
		}
		if (user.isAdmin) {
			return allTypes;
		}

		return allTypes.filter((type) => actions.getModuleTypePermission(type.id, XyiconFeature.Space) > Permission.View);
	};

	const getForm = () => {
		switch (stepIndex) {
			case 0:
				const versions = getVersions();
				const areThereVersions = versions.length > 0;

				return (
					<SelectVersionStyled>
						<StyledVBox
							onClick={() => {
								setIsNewVersionSet(true);
								setSelectedVersion(null);
							}}
							className={ReactUtils.cls({active: isNewVersionSet})}
						>
							<h4 style={{fontSize: "18px", color: "#000000"}}>Create New Version Set</h4>
							<FieldStyled>
								<FieldV5
									label="New Version Name"
									className="focused"
								>
									<ClickToEditInputV5
										value={newVersionName}
										onLiveChange={onVersionNameChange}
										onChange={onVersionNameChange}
										valueValidator={isVNameAvailable}
										placeholder="Version Name"
									/>
								</FieldV5>
								<FieldV5
									label="Issuance Date"
									className="focused"
								>
									<DateTimeInputV5
										value={issuanceDate}
										onChange={(value: string) => {
											setIssuanceDate(value);
										}}
										format="date"
									/>
								</FieldV5>
							</FieldStyled>
						</StyledVBox>
						<StyledVBox
							onClick={() => {
								setIsNewVersionSet(false);
							}}
							className={ReactUtils.cls({active: !isNewVersionSet})}
						>
							<h4 style={{fontSize: "18px", color: "#000000"}}>Select From Existing Version Set</h4>
							<FieldStyled>
								<ExistingVersionSetsStyled
									className={ReactUtils.cls("vbox overflowYAuto", {flexCenter: !areThereVersions, flex1: !areThereVersions})}
									data-cy="ExistingVersionSets"
								>
									{areThereVersions ? versions : <div style={{color: "#BFBFBF"}}>There are no versions added yet...</div>}
								</ExistingVersionSetsStyled>
							</FieldStyled>
						</StyledVBox>
					</SelectVersionStyled>
				);

			case 1:
				const types = getCreatableTypes();

				return (
					<div className="vbox uploadingArea">
						<SpaceTypeStyled>
							<InfoButtonV5
								bubbleText="A scale that determines the resolution of the rasterized PDF. Bigger resolution means higher maximum zoom level."
								icon="question-mark"
							/>

							<FieldStyled>
								<FieldV5
									label="Space Type"
									className="focused spaceType"
								>
									<SelectInputV5
										options={types}
										render={(type) => (type ? type.name : "")}
										selected={selectedType}
										onChange={(type) => setSelectedType(type)}
										fullWidth={true}
										placeholder="Type"
										isSameWidth={true}
									/>
								</FieldV5>
							</FieldStyled>
						</SpaceTypeStyled>

						{
							<>
								<FileDropperReactV5
									accept="application/pdf"
									multiple={true}
									purpose="Drag and drop or click here to add new PDF"
									onFileInputChange={onFileInputChange}
									forceUpdate={forceUpdate}
								/>
								{pdfFiles.originalFiles.length > 0 && (
									<FileContainerStyled className="vbox fileContainer bordered active">{getOriginalPDFList()}</FileContainerStyled>
								)}
							</>
						}
					</div>
				);

			case 2:
				return (
					<PDFListV5
						selectedVersion={selectedVersion}
						pdfFiles={pdfFiles}
						mode="EditSpaceNames"
						onSpaceNameChange={onSpaceNameChange}
						isSpaceNameAvailable={isSpaceNameAvailable}
						onRotate={onPDFListItemRotate}
						onDeleteClick={onPDFListItemDeleteClick}
						forceUpdate={forceUpdate}
					/>
				);
			case 3:
				return (
					<SpaceConfirmSettingsV5
						spaceType={selectedType}
						selectedVersion={selectedVersion}
						pdfFiles={pdfFiles}
						applySpaceUnitsPerMeterToAllSpaces={applySpaceUnitsPerMeterToAllSpaces}
						forceUpdate={forceUpdate}
					/>
				);
			case 4:
				return (
					<PDFListV5
						selectedVersion={selectedVersion}
						pdfFiles={pdfFiles}
						mode="Confirm"
						forceUpdate={forceUpdate}
						uploadInfo={uploadInfo}
						loadBarWidth={loadBarWidth}
						finalScreen={uploadSuccess}
					/>
				);
		}

		return null;
	};

	const isNextButtonEnabled = () => {
		if (
			(stepIndex === 0 && appState.portfolioId && ((isNewVersionSet && !newVersionName) || (!isNewVersionSet && selectedVersion == null))) ||
			!appState.portfolioId
		) {
			return false;
		}

		if (stepIndex >= 1 && (pdfFiles.originalFiles.length < 1 || selectedType == null)) {
			return false;
		}

		if (stepIndex === 2 && appState.app.graphicalTools.pdfRenderer.isPending) {
			if (completedIndex < stepIndex) {
				return false;
			}
		}

		if (stepIndex === 3) {
			for (const pdfArray of pdfFiles.onePagePDFDocs) {
				for (const pdfDoc of pdfArray) {
					if (!MathUtils.isValidNumber(pdfDoc.spaceUnitsPerMeter) && !pdfDoc.isAlignmentConfirmed) {
						return false;
					}
				}
			}
		}

		if (stepIndex === 4) {
			return !uploadInfo;
		}
		if (completedIndex < stepIndex) {
			setCompletedIndex(completedIndex + 1);
		}
		return true;
	};

	const handleStepClick = (clickedStepIndex: number): void => {
		if (clickedStepIndex <= completedIndex) {
			setStepIndex(clickedStepIndex);
			return;
		}

		if (clickedStepIndex === completedIndex + 1 && isNextButtonEnabled()) {
			setStepIndex(clickedStepIndex);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const spaceVersions = (await appState.app.transport.services.feature.refreshList(XyiconFeature.SpaceVersion)) as SpaceVersion[];

			setExistingVersions(spaceVersions?.slice().sort((a: SpaceVersion, b: SpaceVersion) => (a.date < b.date ? 1 : -1)) || []);
		};

		fetchData();

		return () => {
			removeTemporarySpaceFiles();
			appState.app.graphicalTools.pdfRenderer.clearCache();
		};
	}, [appState.app, completedIndex, removeTemporarySpaceFiles]);

	const nextLabel = stepIndex < _steps.length - 1 ? "Next" : uploadInfo ? "Creating..." : "Create";

	return (
		<CreateSpacePanelStyled className="CreateSpacePanel SidePanel overflowYAuto">
			<StepIndicatorV5
				steps={["Select Version", "Upload PDF", "Review", "Set Scale", "Create Spaces"]}
				currentStepIndex={stepIndex}
				onStepClick={handleStepClick}
			/>
			{getForm()}
			<ButtonStyled>
				{stepIndex > 0 && (
					<ButtonV5
						type="secondary"
						label={"Back"}
						title={"Back"}
						onClick={onBackClick}
					/>
				)}
				<ButtonV5
					disabled={!isNextButtonEnabled()}
					label={nextLabel}
					title={nextLabel}
					onClick={onNextClick}
				/>
				{false && stepIndex < (_steps?.length || 0) - 1 && (
					<ButtonV5
						disabled={true}
						label={""}
						title={""}
						onClick={function (): void {
							throw new Error("Function not implemented.");
						}}
					/>
				)}
			</ButtonStyled>
		</CreateSpacePanelStyled>
	);
};

import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {SupportedFontName} from "../../logic3d/managers/MSDF/TextGroupManager";
import {TextGroupManager} from "../../logic3d/managers/MSDF/TextGroupManager";
import type {View} from "../../../../../../data/models/View";
import type {ICaptionSettings} from "../../../../../../data/models/ViewUtils";
import {SizeChanger} from "../../../../abstract/common/sizechanger/SizeChanger";
import {Field} from "../../../../../widgets/form/field/Field";
import type {Color} from "../../../../../../generated/api/base";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import {FontStyleContainer} from "./FontStyleContainer";

interface ICaptionSettingsProps {
	spaceViewRenderer: SpaceViewRenderer;
	selectedSpaceEditorView: View;
	view: View;
	isLoading: boolean;
	captionSettings: ICaptionSettings;
	onCaptionFontFamilyChange: (newFontFamily: SupportedFontName) => void;
	onCaptionFontSizeChange: (newFontSize: number) => void;
	onCaptionBackgroundColorChange: (newColor: Color) => void;
	onCaptionFontColorChange: (newColor: Color) => void;
	onCaptionIsBoldChange: (isBold: boolean) => void;
	onCaptionIsItalicChange: (isItalic: boolean) => void;
	onCaptionIsUnderlinedChange: (isUnderlined: boolean) => void;
}

export function CaptionSettings(props: ICaptionSettingsProps) {
	const {captionSettings, isLoading} = props;
	const {eyeDropperProps} = props.spaceViewRenderer;

	return (
		<div className="CaptionSettings">
			<Field label="Font Style">
				<FontStyleContainer
					fontStyleSettings={captionSettings}
					onIsBoldChange={props.onCaptionIsBoldChange}
					onIsItalicChange={props.onCaptionIsItalicChange}
					onIsUnderlinedChange={props.onCaptionIsUnderlinedChange}
				/>
			</Field>
			<Field label="Font Size">
				<SizeChanger
					disabled={isLoading}
					value={captionSettings.fontSize}
					onChange={props.onCaptionFontSizeChange}
				/>
			</Field>
			<Field label="Font Family">
				<SelectInput
					onChange={props.onCaptionFontFamilyChange}
					options={TextGroupManager.supportedFontNames}
					selected={captionSettings.fontFamily}
				/>
			</Field>
			<Field label="Font Color">
				<ColorSelector
					title="Font Color"
					icon="text-color"
					color={captionSettings.fontColor}
					onColorChange={props.onCaptionFontColorChange}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.center}
					verticalAlignment={VerticalAlignment.topOuter}
				/>
			</Field>
			<Field label="Fill Color">
				<ColorSelector
					title="Fill Color"
					icon="paint-bucket"
					color={captionSettings.backgroundColor}
					onColorChange={props.onCaptionBackgroundColorChange}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.center}
					verticalAlignment={VerticalAlignment.topOuter}
				/>
			</Field>
		</div>
	);
}

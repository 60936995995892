import type {Type} from "../../../../../data/models/Type";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature} from "../../../../../generated/api/base";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import type {GridItem} from "../../../modules/spaceeditor/spaceselector/GridTypes";

type ViewTemplateCardType = `${GridItem} list` | "space"; // | "Report";

export const getTypeFromFeatureForCard = (feature: XyiconFeature): ViewTemplateCardType => {
	switch (feature) {
		case XyiconFeature.Xyicon:
			return "xyicon list";
		case XyiconFeature.Boundary:
			return "boundary list";
		case XyiconFeature.SpaceEditor:
			return "space";
	}
};

export const getBackgroundColorForCard = (type: ViewTemplateCardType): string => {
	switch (type) {
		case "xyicon list":
			return "#F5D254";
		case "boundary list":
			return "#468361";
		case "space":
			return "#A46AE2";
	}
};

export const getFeatureTypesForCard = (appState: AppState, feature: XyiconFeature): Type[] => {
	const featuresForTypes: XyiconFeature[] = [];

	if (feature === XyiconFeature.SpaceEditor) {
		featuresForTypes.push(XyiconFeature.Xyicon, XyiconFeature.Boundary);
	} else {
		featuresForTypes.push(feature);
	}

	const types: Type[] = [];

	for (const f of featuresForTypes) {
		types.push(...appState.actions.getTypesByFeature(f));
	}

	return types.toSorted((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));
};

import * as React from "react";
import type {PanCameraControls} from "../../logic3d/features/PanCameraControls";
import {Constants} from "../../logic3d/Constants";
import {FullscreenManager} from "../../../../../../utils/resize/FullscreenManager";
import {IconButton} from "../../../../../widgets/button/IconButton";

interface INavigationControlButtonsProps {
	cameraControls: PanCameraControls;
	onCloseClick: () => void;
}

export class NavigationControlButtons extends React.Component<INavigationControlButtonsProps> {
	private onFullScreenClick = () => {
		FullscreenManager.toggle();
	};

	private onFitToScreenClick = () => {
		this.props.cameraControls.fitToScreen();
	};

	private onZoomToMaxClick = () => {
		this.props.cameraControls.zoom(1, undefined, undefined, Constants.DURATIONS.CAMERA_MOVEMENT);
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	public override componentDidMount(): void {
		FullscreenManager.addChangeListener(this.forceUpdateArrow);
	}

	public override componentWillUnmount(): void {
		FullscreenManager.removeChangeListener(this.forceUpdateArrow);
	}

	public override render() {
		const {cameraControls} = this.props;
		const isInFullScreen = FullscreenManager.isFullscreen();

		return (
			<div className="NavigationControlButtons hbox">
				<div
					className="zoomPercentage"
					title="Zoom To 100%"
					onClick={this.onZoomToMaxClick}
				>
					{`${(cameraControls.cameraZoomValue * 100).toFixed(0)}%`}
				</div>
				<IconButton
					icon="reset"
					title="Reset to Viewport"
					onClick={this.onFitToScreenClick}
				/>
				<IconButton
					icon={isInFullScreen ? "exitFullScreen" : "fullScreen"}
					title={isInFullScreen ? "Exit Full Screen" : "Full Screen"}
					onClick={this.onFullScreenClick}
				/>
				<IconButton
					icon="close"
					title="Close"
					onClick={this.props.onCloseClick}
				/>
			</div>
		);
	}
}

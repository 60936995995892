import type {PointDouble} from "../../../../../../generated/api/base";
import type {IBox2} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";

interface IBoundingBox {
	position: PointDouble;
	scale: PointDouble;
	boundingBox: IBox2;
	expandByBoundingBox: (bbox: IBox2) => void;
	startTranslating: () => void;
	reset: () => void;
}

export const ItemSelectionBoxName = "SelectionBox";

/**
 * The boundingbox of all the selected items: xyicons, markups, etc
 */

export class ItemSelectionBox implements IBoundingBox {
	public readonly typeName = ItemSelectionBoxName;
	private _boundingBox: IBox2;
	private _savedBoundingBox: IBox2;

	constructor() {
		this._boundingBox = {
			max: {
				x: null,
				y: null,
			},
			min: {
				x: null,
				y: null,
			},
		};
	}

	public startTranslating() {
		this._savedBoundingBox = {
			max: {
				x: this._boundingBox.max.x,
				y: this._boundingBox.max.y,
			},
			min: {
				x: this._boundingBox.min.x,
				y: this._boundingBox.min.y,
			},
		};
	}

	public translate(x: number, y: number) {
		if (this._savedBoundingBox.max.x != null) {
			this._boundingBox.max.x = this._savedBoundingBox.max.x + x;
			this._boundingBox.min.x = this._savedBoundingBox.min.x + x;
			this._boundingBox.max.y = this._savedBoundingBox.max.y + y;
			this._boundingBox.min.y = this._savedBoundingBox.min.y + y;
		}
	}

	public reset() {
		this._boundingBox.max.x = this._boundingBox.max.y = this._boundingBox.min.x = this._boundingBox.min.y = null;
	}

	public expandByBoundingBox(bbox: IBox2) {
		if (this._boundingBox.max.x == null) {
			this.setFromBoundingBox(bbox);
		} else {
			if (bbox.max.x != null && this._boundingBox.max.x < bbox.max.x) {
				this._boundingBox.max.x = bbox.max.x;
			}
			if (bbox.max.y != null && this._boundingBox.max.y < bbox.max.y) {
				this._boundingBox.max.y = bbox.max.y;
			}
			if (bbox.min.x != null && this._boundingBox.min.x > bbox.min.x) {
				this._boundingBox.min.x = bbox.min.x;
			}
			if (bbox.min.y != null && this._boundingBox.min.y > bbox.min.y) {
				this._boundingBox.min.y = bbox.min.y;
			}
		}
	}

	public expandByGeometryData(geometryData: PointDouble[]) {
		for (const vec of geometryData) {
			this.expandByBoundingBox({
				min: {
					x: vec.x,
					y: vec.y,
				},
				max: {
					x: vec.x,
					y: vec.y,
				},
			});
		}
	}

	public expandByBoundingBoxes(bboxes: IBox2[]) {
		for (const bbox of bboxes) {
			this.expandByBoundingBox(bbox);
		}
	}

	public setFromBoundingBox(bbox: IBox2) {
		this._boundingBox.max.x = bbox.max.x;
		this._boundingBox.max.y = bbox.max.y;
		this._boundingBox.min.x = bbox.min.x;
		this._boundingBox.min.y = bbox.min.y;
	}

	public setFromBoundingBoxes(bboxes: IBox2[]) {
		this.reset();
		this.expandByBoundingBoxes(bboxes);
	}

	public get boundingBox() {
		return this._boundingBox;
	}

	public get position() {
		return THREEUtils.getCenterOfBoundingBox(this._boundingBox);
	}

	public get scale() {
		return THREEUtils.getSizeOfBoundingBox2(this._boundingBox);
	}
}

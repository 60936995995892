import * as React from "react";
import {inject} from "mobx-react";
import {DomPortal} from "../../portal/DomPortal";
import type {Navigation} from "../../../../../Navigation";
import type {Portfolio} from "../../../../../data/models/Portfolio";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {SideBarNavItem} from "../../../../sidebar/SideBarNavItem";
import {Functions} from "../../../../../utils/function/Functions";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {Boundary} from "../../../../../data/models/Boundary";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import type {App} from "../../../../../App";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {BoundarySpaceMapSelector} from "../../../../widgets/BoundarySpaceMapSelector";
import type {IModel} from "../../../../../data/models/Model";
import {BoundaryUtils} from "../../../../../data/models/BoundaryUtils";
import {NavigationEnum} from "../../../../../Enums";

interface IQuickLinksProps<T> {
	className?: string;
	hoveringModel: T;
	xPos: number;
	yPos: number;
	navigation?: Navigation;
	transport?: TransportLayer;
	feature?: XyiconFeature;
	onDuplicateClick?: (model: T) => void;
	isVisibleInSpaceEditor?: (item: T) => boolean;
	showNotification?: (item: T) => void;

	app?: App;
	appState?: AppState;
}

interface IQuickLinksState {
	isBoundarySelectorOpen: boolean;
	updatedX: number;
	updatedY: number;
}

@inject("app")
@inject("appState")
@inject("transport")
@inject("navigation")
export class QuickLinks<T> extends React.Component<IQuickLinksProps<T>, IQuickLinksState> {
	private static _portfolioMenuItems = ["Spaces", "Xyicons", "Boundaries"];
	private static _availableInTheseFeatures = [
		XyiconFeature.Portfolio,
		XyiconFeature.Space,
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
		XyiconFeature.XyiconCatalog,
	];
	private _quickLinkOptionRef = React.createRef<HTMLDivElement>();
	private _quickLinkRef = React.createRef<HTMLDivElement>();

	constructor(props: IQuickLinksProps<T>) {
		super(props);

		this.state = {
			isBoundarySelectorOpen: false,
			updatedX: this.props.xPos,
			updatedY: this.props.yPos,
		};
	}

	private onShowBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: true,
		});
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: false,
		});
	};

	private onQuickLinkOptionClick = () => {
		const {hoveringModel, appState, navigation, isVisibleInSpaceEditor, showNotification} = this.props;
		const item = hoveringModel;
		const itemOwnFeature = (item as unknown as IModel).ownFeature;

		if (itemOwnFeature === XyiconFeature.Space) {
			navigation.goApp(NavigationEnum.NAV_SPACE, (item as unknown as IModel).id);
		} else if (itemOwnFeature === XyiconFeature.XyiconCatalog) {
			this.props.onDuplicateClick(item);
		} else {
			if (!isVisibleInSpaceEditor(item)) {
				showNotification(item);
			}
			if (itemOwnFeature === XyiconFeature.Xyicon) {
				appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true);
			} else if ((item as unknown as Boundary).isBoundary) {
				if (BoundaryUtils.doesHaveMultipleSpaceMaps(item as unknown as Boundary)) {
					this.onShowBoundarySpaceMaps();
				} else {
					appState.actions.navigateToSpaceItem(item as unknown as Boundary, true);
				}
			}
		}
	};

	private getIcon() {
		switch (this.props.feature) {
			case XyiconFeature.Space:
				return "open";
			case XyiconFeature.Xyicon:
				return "pin";
			case XyiconFeature.Boundary:
				return "pin";
			case XyiconFeature.XyiconCatalog:
				return "duplicate";
			default:
				break;
		}
	}

	private getIconLabel() {
		switch (this.props.feature) {
			case XyiconFeature.Space:
				return "Open this Space";
			case XyiconFeature.Xyicon:
				return "Open in Space Editor";
			case XyiconFeature.Boundary:
				return "Open in Space Editor";
			case XyiconFeature.XyiconCatalog:
				return "Duplicate";
			default:
				break;
		}
	}

	private getContent() {
		const {hoveringModel, navigation} = this.props;
		const activeNav = location.hash.split("/").pop();

		return (hoveringModel as unknown as IModel).ownFeature === XyiconFeature.Portfolio ? (
			<div className="vbox">
				<ul>
					{navigation.menus
						.filter((menu) => QuickLinks._portfolioMenuItems.includes(menu.label))
						.map((menu, index) => (
							<SideBarNavItem
								key={index}
								feature={menu.feature}
								menuNav={menu.nav}
								menuIcon={menu.icon}
								menuLabel={menu.label}
								onToggleNavigation={Functions.emptyFunction}
								activeNav={activeNav}
								portfolio={hoveringModel as unknown as Portfolio}
								className="SideBarNavItem"
								isOpenByDefault={true}
							/>
						))}
				</ul>
			</div>
		) : (
			<div
				className="hbox alignCenter"
				onClick={this.onQuickLinkOptionClick}
				ref={this._quickLinkOptionRef}
			>
				<SVGIcon icon={this.getIcon()} />
				<span>{this.getIconLabel()}</span>
				{this.state.isBoundarySelectorOpen && (
					<BoundarySpaceMapSelector
						item={hoveringModel as unknown as Boundary}
						onClose={this.onHideBoundarySpaceMaps}
					/>
				)}
			</div>
		);
	}

	private corrigatePosition() {
		const {xPos, yPos} = this.props;
		const {clientHeight: windowHeight, clientWidth: windowWidth} = document.body;
		const {clientHeight: menuHeight, clientWidth: menuWidth} = this._quickLinkRef.current;

		let updatedX = xPos;
		let updatedY = yPos;

		if (xPos + menuWidth > windowWidth) {
			updatedX = windowWidth - menuWidth;
		}
		if (yPos + menuHeight > windowHeight) {
			updatedY = windowHeight - menuHeight;
		}

		return {updatedX, updatedY};
	}

	public override componentDidMount() {
		const {xPos, yPos} = this.props;

		if (this._quickLinkRef.current) {
			const {updatedX, updatedY} = this.corrigatePosition();

			this._quickLinkRef.current.style.transform = `translate(${updatedX - xPos}px, ${updatedY - yPos}px)`;
		}
	}

	public override componentDidUpdate() {
		const {xPos, yPos} = this.props;

		if (this._quickLinkRef.current) {
			const {updatedX, updatedY} = this.corrigatePosition();

			this._quickLinkRef.current.style.transform = `translate(${updatedX - xPos}px, ${updatedY - yPos}px)`;
		}
	}

	public override render() {
		const {app, hoveringModel, feature, xPos, yPos} = this.props;

		return (
			<DomPortal destination={app.modalContainer}>
				<>
					{QuickLinks._availableInTheseFeatures.includes(feature) ? (
						<div
							ref={this._quickLinkRef}
							className={ReactUtils.cls("QuickLinks", {navigateLink: !((hoveringModel as unknown as IModel).ownFeature === XyiconFeature.Portfolio)})}
							style={{left: `${xPos}px`, top: `${yPos}px`}}
						>
							{this.getContent()}
						</div>
					) : null}
				</>
			</DomPortal>
		);
	}
}

import * as React from "react";
import {useState} from "react";
import styled from "styled-components";
import type {IDistanceUnit, DistanceUnitName} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldDataType} from "../../../../generated/api/base";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {IRealSize} from "../../../modules/settings/modules/type/form/RealSizeInput";
import {Field} from "../../../../data/models/field/Field";
import {UnitSelectorV5} from "../../details/UnitSelectorV5";
import {radius} from "../../styles/styles";
import {ClickToEditInputV5} from "./ClickToEditInputV5";

interface IClickToEditDistanceV5Props {
	value: number;
	unit: IDistanceUnit;
	onChange: (size: IRealSize) => void;
	validateValue: (value: number) => boolean;
}

const ClickToEditDistanceV5Styled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;

	> div {
		width: 100%;
		height: 32px;

		&.RealSizeInputUnitSelector {
			border-radius: ${radius.sm};
			width: 100%;
		}
	}
`;

ClickToEditDistanceV5.defaultProps = {
	validateValue: (value: number) => value > 0,
};

export function ClickToEditDistanceV5(props: IClickToEditDistanceV5Props) {
	const [errorMessage, setErrorMessage] = useState<string>(null);

	const isValueValid = (value: number) => {
		return MathUtils.isValidNumber(value) && props.validateValue(value);
	};

	const onValueChange = (value: number) => {
		if (isValueValid(value)) {
			props.onChange({
				value: value,
				unit: props.unit.name,
			});
		}
	};

	const onValueLiveChange = (value: number) => {
		setErrorMessage(isValueValid(value) ? "" : "Please provide a valid number");
	};

	{
		return (
			<ClickToEditDistanceV5Styled>
				<ClickToEditInputV5
					dataTypeSettings={Field.defaultSettings[FieldDataType.Numeric]}
					dataType={FieldDataType.Numeric}
					value={props.value}
					onChange={onValueChange}
					onLiveChange={onValueLiveChange}
					errorMessage={errorMessage}
				/>
				<UnitSelectorV5
					unit={props.unit.name}
					onChange={(unitName: DistanceUnitName) => props.onChange({value: props.value, unit: unitName})}
					className="RealSizeInputUnitSelector"
				/>
			</ClickToEditDistanceV5Styled>
		);
	}
}

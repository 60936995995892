import type {TransportLayer} from "../TransportLayer";
import type {DocumentModel} from "../models/DocumentModel";
import {isImage} from "../models/FileType";
import {FileType, XyiconFeature} from "../../generated/api/base";
import {ConfirmWindow} from "../../ui/modules/abstract/popups/ConfirmWindow";
import {FileUtils} from "../../utils/file/FileUtils";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import type {IModel} from "../models/Model";
import {ImageUtils} from "../../utils/image/ImageUtils";
import {DateFormatter} from "../../utils/format/DateFormatter";
import {ConfirmWindowV5} from "../../ui/5.0/popup/ConfirmWindowV5";

export class DocumentService {
	private readonly _transportLayer: TransportLayer;

	constructor(transportLayer: TransportLayer) {
		this._transportLayer = transportLayer;
	}

	public getFilePath(document: DocumentModel) {
		return this._transportLayer.getFullPathFromServer(`documents/files/${document.id}.${document.fileExtension}`);
	}

	public getThumbnailPath(document: DocumentModel) {
		if (isImage(document.fileExtension)) {
			// thumbnail is always a png
			return this._transportLayer.getFullPathFromServer(`documents/thumbnails/${document.id}.png`);
		} else if (document.fileType === FileType.PDF) {
			return "src/assets/images/common/pdf.svg";
		} else {
			return "src/assets/images/common/other.svg";
		}
	}

	public download(document: DocumentModel) {
		const fileUrl = this.getFilePath(document);
		const {fileName, fileExtension} = document;
		const fileNameWithoutExtension = fileName.substring(0, fileName.indexOf(`.${fileExtension}`)) || fileName;

		// in case of a user uploads a file without extension (in that way the name === extension)
		const extension = fileName === fileExtension ? "" : `.${fileExtension}`;

		FileUtils.downloadFileFromUrl(fileUrl, `${fileNameWithoutExtension}_${DateFormatter.timeStampForDownload()}${extension}`);
	}

	public async createDocument(file: File, item: IModel) {
		const arrayBuffer = await FileUtils.readAsArrayBuffer(file);
		const sourceFile = Array.from(new Uint8Array(arrayBuffer));
		const thumbnail = await this.createThumbnailData(file);

		const {name, extension} = FileUtils.decomposeFileName(file.name);

		const data = {
			sourceFile: sourceFile,
			thumbnail: thumbnail,
			fileName: file.name || "",
			fileExtension: extension,
			//fileType: getFileType(file),
			portfolioID: this._transportLayer.appState.portfolioId,
		};
		// const data = new FormData();
		// data.append("sourceFile", new Blob([file]))
		// data.append("thumbnail", new Blob([file]))
		// data.append("fileType", getFileType(file))
		// data.append("portfolioID", this.props.appState.portfolioId)

		const documents = await this._transportLayer.services.feature.create(data, XyiconFeature.Document);
		const document = documents[0];

		if (document) {
			const {result, error} = await this._transportLayer.requestForOrganization({
				url: "documents/attach",
				method: XHRLoader.METHOD_POST,
				params: {
					documentID: document.id,
					objectList: [
						{
							feature: item.ownFeature,
							objectIDList: [item.id],
						},
					],
				},
			});

			if (result) {
				document.applyData(result);
			}
		}
	}

	private async createThumbnailData(file: File) {
		const isImage = FileUtils.isImageFile(file);

		if (isImage) {
			// Thumbnail has to be a png image for backend

			const image = await FileUtils.readAsImage(file);

			return ImageUtils.image2Base64String(image, 256, false);
		}
		return "";
	}

	public async deleteDocument(document: DocumentModel) {
		let confirmed: boolean;
		const appState = this._transportLayer.appState;
		const modal = appState.currentUIVersion === "4.0" ? ConfirmWindow : ConfirmWindowV5;

		if (document.documentMap.length > 1) {
			confirmed = await modal.open("This document is attached to multiple objects. Are you sure you want to delete it?");
		} else {
			confirmed = await modal.open("Are you sure you want to delete this document?");
		}

		if (confirmed) {
			await appState.actions.deleteItems([document], XyiconFeature.Document);
		}
	}
}

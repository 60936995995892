import * as React from "react";
import {inject} from "mobx-react";
import {ModuleView} from "../abstract/ModuleView";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {IModel} from "../../../data/models/Model";

export interface BoundaryViewProps {
	param1?: string;
	param2?: string;
	appState?: AppState;
}

@inject("appState")
export class BoundaryView extends React.Component<BoundaryViewProps> {
	private canDelete = (selectedItems: IModel[]) => {
		const {actions} = this.props.appState;

		return selectedItems.every((item) => actions.getModuleTypePermission(item.typeId, item.ownFeature) > Permission.Update);
	};

	public override render() {
		return (
			<ModuleView
				feature={XyiconFeature.Boundary}
				className="XyiconView"
				actionBar={[
					{
						id: "import",
						title: "Update Boundaries with an Excel file",
						label: "Import",
					},
					{
						id: "delete",
						title: "Delete Boundary",
						label: "Delete",
						enabled: (selectedItems) => selectedItems.length > 0 && this.canDelete(selectedItems),
					},
				]}
			/>
		);
	}
}

import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes} from "../FieldDataTypes";
import {SingleSelectInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/singleselect/SingleSelectInput";
import {SingleSelectFieldSettings} from "../../../../ui/modules/settings/modules/field/datatypes/SingleSelectFieldSettings";
import {FilterOperator} from "../../filter/operator/FilterOperator";
import {SingleSelectFieldSettingsV5} from "../../../../ui/5.0/details/SingleSelectFieldSettingsV5";
import {SingleSelectInputV5} from "../../../../ui/5.0/details/datatypes/SingleSelectInputV5";

export function configureSingleSelect() {
	FieldDataTypes.configure({
		id: FieldDataType.SingleChoiceList,
		title: "Single-select",
		settings: SingleSelectFieldSettings,
		settingsV5: SingleSelectFieldSettingsV5,
		input: SingleSelectInput,
		inputV5: SingleSelectInputV5,
		defaultSettings: {
			choiceList: [],
		} as ISingleSelectFieldSettingsDefinition,
		operatorsArray: [
			FilterOperator.IS_EQUAL_TO_STR,
			FilterOperator.CONTAINS,
			FilterOperator.DOES_NOT_CONTAIN,
			FilterOperator.IS_BLANK,
			FilterOperator.IS_NOT_BLANK,
			FilterOperator.IS_ANY_OF,
			FilterOperator.IS_NOT_ANY_OF,
		],
	});
}

export interface ISingleSelectFieldSettingsDefinition {
	choiceList: string[];
}

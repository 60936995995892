import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {Navigation} from "../../../../../Navigation";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {TextInputV5} from "../../../details/datatypes/TextInputV5";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import DotsHorizontalIcon from "../../../icons/dots-horizontal.svg?react";
import {type IDropdownOption} from "../../../interaction/DropdownOptionsV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {Functions} from "../../../../../utils/function/Functions";
import {radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import PenFieldIcon from "../../../icons/pen-field.svg?react";
import DeleteIcon from "../../../icons/delete.svg?react";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";

const PermissionSetItemStyled = styled.div`
	.PermissionSetItem {
		cursor: pointer;
		height: 48px;
		padding: 4px 8px 4px 8px;

		.name {
			font-size: 16px;
			margin-top: auto;
			margin-bottom: auto;
		}

		.description {
			font-size: 14px;
			margin-top: auto;
			margin-bottom: auto;
			margin-right: 8px;
		}

		.dropdownButton {
			width: 24px;
			height: 24px;
			margin-top: auto;
			margin-bottom: auto;
		}

		&:hover {
			background: ${colorPalette.gray.c200Light};
			border-radius: ${radius.sm};
		}

		&.selected,
		&.editMode {
			background: ${colorPalette.primary.c500Primary};
			border-radius: ${radius.sm};

			.name {
				color: ${colorPalette.white};
				margin-top: auto;
				margin-bottom: auto;
			}

			.description {
				color: ${colorPalette.white};
				margin-top: auto;
				margin-bottom: auto;
			}

			.dropdownButton {
				background: ${colorPalette.primary.c500Primary};
			}

			.dropdownButton svg path {
				stroke: ${colorPalette.white};
			}

			.ItemNameInput input {
				background: ${colorPalette.white};
				margin-top: auto;
				margin-bottom: auto;
			}
		}

		.dropdownButton:hover {
			background: ${colorPalette.gray.c200Light};
			border: 1px solid black;

			svg path {
				stroke: black;
			}
		}

		.ItemNameInput {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 3px;
		}
	}
`;

interface IPermissionSetItemPropsV5 {
	id: string;
	selected: boolean;
	appState?: AppState;
	transport?: TransportLayer;
	navigation?: Navigation;
	onSelect: (id: string) => void;
	onDelete: (id: string) => void;
	onSave: (id: string, name: string) => void;
}

interface IPermissionSetItemStateV5 {
	editMode: boolean;
	optionsOpen: boolean;
	errorMessage: string;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class PermissionSetItemV5 extends React.Component<IPermissionSetItemPropsV5, IPermissionSetItemStateV5> {
	private _permissionSetTempName = "";
	private _prevName = "";
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IPermissionSetItemPropsV5) {
		super(props);
		this.state = {
			editMode: false,
			optionsOpen: false,
			errorMessage: "",
		};
	}

	private reset() {
		const {appState, id} = this.props;

		this.setState({editMode: false});
	}

	private onBlur = () => {
		const name = this.props.appState.actions.getFeatureItemById<PermissionSet>(this.props.id, XyiconFeature.PermissionSet).name;

		if (!this.state.errorMessage) {
			if (this._permissionSetTempName !== name) {
				this.props.onSave(this.props.id, this._permissionSetTempName);
				console.log("now");
				console.log("now_id", this.props.id);
				console.log("now_name", this._permissionSetTempName);
			}
		} else {
			this.props.onSave(this.props.id, this._prevName);
			this.setState({errorMessage: ""});
			console.log("then");
		}

		this.reset();
	};

	private onRenameClick = () => {
		const {id, appState} = this.props;

		requestAnimationFrame(() => {
			if (!this.state.editMode) {
				this.setState({
					editMode: true,
				});
			}

			this._permissionSetTempName = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet).name;
			this._prevName = this._permissionSetTempName;
		});
	};

	private onRename = (value: string) => {
		const {appState, id} = this.props;
		const isValid = appState.actions.isPermissionSetNameValid(value, id);
		let errorMessage = "";

		if (!isValid) {
			errorMessage = "Name needs to be unique!";
		}

		if (!value) {
			errorMessage = "Name cannot be empty!";
		}

		this.setState({errorMessage});
		this._permissionSetTempName = value;
	};

	private getOptions = () => {
		const {onDelete, id} = this.props;

		const options: IDropdownOption[] = [
			{
				label: "Rename",
				IconComponent: PenFieldIcon,
				iconCustomStyle: {width: "16px", height: "16px"},
				onClick: () => this.onRenameClick(),
			},
			{
				label: "Delete",
				IconComponent: DeleteIcon,
				iconCustomStyle: {width: "16px", height: "16px"},
				onClick: () => onDelete(id),
			},
		];

		return options;
	};

	private onSelect = () => {
		this.props.onSelect(this.props.id);
	};

	public override render() {
		const {appState, id, selected} = this.props;
		const {editMode, errorMessage} = this.state;
		const permissionSet = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

		return (
			<PermissionSetItemStyled>
				<div
					ref={this._container}
					className={ReactUtils.cls("PermissionSetItem hbox listItem", {selected, editMode})}
					onClick={this.onSelect}
				>
					<div className="ItemNameInput vbox flex_1">
						{editMode ? (
							<TextInputV5
								value={this._permissionSetTempName}
								onInput={this.onRename}
								autoFocus={true}
								onBlur={this.onBlur}
								errorMessage={errorMessage}
								errorMessageTop={-22}
							/>
						) : (
							<div className="name">{permissionSet?.name}</div>
						)}
						<span className="description">{appState.actions.renderName(permissionSet?.lastModifiedBy)}</span>
					</div>
					<DropdownButtonV5
						options={this.getOptions()}
						button={
							<IconButtonV5
								IconComponent={DotsHorizontalIcon}
								onClick={Functions.emptyFunction}
							/>
						}
						className="dropdownButton"
						horizontalAlignment={HorizontalAlignment.right}
					/>
				</div>
			</PermissionSetItemStyled>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {TableView} from "../../../abstract/TableView";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import {UserGroupDetailsTab} from "./UserGroupDetailsTab";
import {CreateUserGroupPanel} from "./CreateUserGroupPanel";

interface IUserGroupsViewProps {
	appState?: AppState;
}

@inject("appState")
@observer
export class UserGroupsView extends React.Component<IUserGroupsViewProps> {
	private _headers = [
		{
			id: "name",
			title: "Group Name",
		},
		{
			id: "userIds",
			title: "Number of Users",
		},
		{
			id: "lastModifiedAt",
			title: "Last Updated",
		},
		{
			id: "lastModifiedBy",
			title: "Updated By",
		},
	];

	public override render() {
		return (
			<TableView
				feature={XyiconFeature.UserGroup}
				headers={this._headers}
				create={(onCreated, onCancel, creating) => (
					<CreateUserGroupPanel
						onCancel={onCancel}
						onCreated={onCreated}
						creating={creating}
					/>
				)}
				details={(selected: UserGroup[]) => <UserGroupDetailsTab userGroups={selected} />}
			/>
		);
	}
}

import styled from "styled-components";
import type {CSSProperties} from "react";
import {radius} from "../styles/styles";
import {SliderV5} from "./SliderV5";

interface IOpacitySliderProps {
	value: number;
	colorHex: string;
	labeled?: boolean;
	onValueChange: (newValue: number) => void;
	disabled?: boolean;
	sliderWidth?: number;
}

export const OpacitySlider = (props: IOpacitySliderProps) => {
	const sliderWidth = props.sliderWidth || 232;

	const colorStyle: CSSProperties = {
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		borderRadius: radius.md,
		backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), #${props.colorHex})`,
	};

	return (
		<OpacitySliderStyled className="OpacitySlider">
			<SliderV5
				value={props.value}
				width={sliderWidth}
				largeStepValue={0.1}
				dataLabel={props.labeled ? "Opacity" : ""}
				classNames="Opacity"
				onValueChange={props.onValueChange}
				disabled={props.disabled}
			>
				<div
					className="after"
					style={colorStyle}
				/>
			</SliderV5>
		</OpacitySliderStyled>
	);
};

const OpacitySliderStyled = styled.div`
	position: relative;
`;

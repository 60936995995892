import styled from "styled-components";
import type {ExtendedDistanceUnitName} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldV5} from "../../../details/FieldV5";
import {ClickToEditDistanceV5} from "../../../input/clicktoedit/ClickToEditDistanceV5";
import {ClickToEditInputStyled} from "../../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled} from "../../../input/select/SelectInputV5";
import {colorPalette} from "../../../styles/colorPalette";

interface IRealSize {
	value: number;
	unit: ExtendedDistanceUnitName;
}

interface IRealSizeInputProps {
	readonly size: IRealSize;
	readonly onChange: (size: IRealSize) => void;
	readonly label: string;
	readonly validateValue?: (value: number) => boolean;
	readonly infoText?: string;
}

const RealSizeInputV5Styled = styled.div`
	.Field {
		align-items: center;

		.label {
			line-height: 24px;
			height: 24px;
			margin: 0;
		}

		.element {
			width: 248px;
			display: flex;
			align-items: center;

			${ClickToEditInputStyled} .unfocused, ${SelectInputStyled} {
				color: ${colorPalette.gray.c950};
			}
		}
	}
`;

export const RealSizeInputV5 = (props: IRealSizeInputProps) => {
	const {validateValue = (value: number) => value > 0, size, onChange, label, infoText} = props;

	return (
		<RealSizeInputV5Styled className="RealSizeInput">
			<FieldV5
				label={label}
				tooltips={{postLabelIconTooltip: infoText}}
			>
				<ClickToEditDistanceV5
					value={size.value}
					unit={Constants.DISTANCE_UNITS[size.unit]}
					onChange={onChange}
					validateValue={validateValue}
				/>
			</FieldV5>
		</RealSizeInputV5Styled>
	);
};

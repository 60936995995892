// Inspired by this: https://github.com/mourner/tinyqueue

type compareFn<T> = (a: T, b: T) => number;

export class TinyQueue<T> {
	private _data: T[];
	private _length: number;
	private _compare: compareFn<T>;

	constructor(data: T[] = [], compare: compareFn<T> = TinyQueue.defaultCompare) {
		this._data = data;
		this._length = this._data.length;
		this._compare = compare;

		if (this._length > 0) {
			for (let i = (this._length >> 1) - 1; i >= 0; i--) {
				this.down(i);
			}
		}
	}

	private down(pos: number) {
		const {_data, _compare} = this;
		const halfLength = this._length >> 1;
		const item = _data[pos];

		while (pos < halfLength) {
			let bestChild = (pos << 1) + 1; // initially it is the left child
			const right = bestChild + 1;

			if (right < this._length && _compare(_data[right], _data[bestChild]) < 0) {
				bestChild = right;
			}
			if (_compare(_data[bestChild], item) >= 0) {
				break;
			}

			_data[pos] = _data[bestChild];
			pos = bestChild;
		}

		_data[pos] = item;
	}

	private up(pos: number) {
		const {_data, _compare} = this;
		const item = _data[pos];

		while (pos > 0) {
			const parent = (pos - 1) >> 1;
			const current = _data[parent];

			if (_compare(item, current) >= 0) {
				break;
			}
			_data[pos] = current;
			pos = parent;
		}

		_data[pos] = item;
	}

	public push(item: T) {
		this._data.push(item);
		this.up(this._length++);
	}

	public pop() {
		if (this._length === 0) {
			return undefined;
		}

		const top = this._data[0];
		const bottom = this._data.pop();

		if (--this._length > 0) {
			this._data[0] = bottom;
			this.down(0);
		}

		return top;
	}

	public get length() {
		return this._length;
	}

	private static readonly defaultCompare = (a: any, b: any) => {
		return a < b ? -1 : a > b ? 1 : 0;
	};
}

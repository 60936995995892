import * as React from "react";

export class LoaderIcon extends React.Component {
	public override render() {
		return (
			<div className="LoaderIcon">
				<div className="loader">
					<div className="dots">
						<div />
						<div />
						<div />
						<div />
					</div>
				</div>
			</div>
		);
	}
}

import {NavigationEnum} from "../../../Enums";
import type {Space} from "../../../data/models/Space";
import type {View} from "../../../data/models/View";
import {XyiconFeature} from "../../../generated/api/base";

export const EventNameForViewsChangeInLocalStorage = "viewsModifiedInLocalStorage";
const getLocalStorageKeyForWorkspaceViews = (userId: string, orgId: string) => `workspace_viewtabs_${userId}_${orgId}`;
const getLocalStorageKeyForActiveWorkspaceView = (userId: string, orgId: string) => `active_workspace_viewtabs_${userId}_${orgId}`;

interface IViewTab {
	viewId: string;
	lastUsed: number; // Date.now()
}

export const getWorkspaceViewTabsFromLocalStorage = (userId: string, orgId: string): IViewTab[] => {
	const localStorageKey = getLocalStorageKeyForWorkspaceViews(userId, orgId);
	const localStorageValue = localStorage.getItem(localStorageKey);
	const viewIdsInLocalStorage: IViewTab[] = localStorageValue ? JSON.parse(localStorageValue) : [];

	return viewIdsInLocalStorage;
};

export const getActiveWorkspaceViewTabFromLocalStorage = (userId: string, orgId: string): IViewTab | null => {
	const localStorageKey = getLocalStorageKeyForActiveWorkspaceView(userId, orgId);
	const localStorageValue = localStorage.getItem(localStorageKey);
	const activeWorkspaceView = localStorageValue ? JSON.parse(localStorageValue) : null;

	return activeWorkspaceView;
};

export const removeAllWorkspaceViewTabsExceptSelected = (userId: string, orgId: string) => {
	const localStorageKeyForWorkspaceViews = getLocalStorageKeyForWorkspaceViews(userId, orgId);
	const activeViewTab = getActiveWorkspaceViewTabFromLocalStorage(userId, orgId);

	localStorage.setItem(localStorageKeyForWorkspaceViews, JSON.stringify([activeViewTab]));
	window.dispatchEvent(new Event(EventNameForViewsChangeInLocalStorage));
};

export const removeWorkspaceViewFromTabs = (view: View, setViewForOpenSpaceSelector: (value: View) => void) => {
	const appState = view.appState;
	const userId = appState.user?.id;
	const orgId = appState.organizationId;
	const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(userId, orgId);
	const activeViewTab = getActiveWorkspaceViewTabFromLocalStorage(userId, orgId);

	const indexOfViewToBeDeleted = viewTabsInLocalStorage.findIndex((v) => v.viewId === view.id);

	if (indexOfViewToBeDeleted !== -1) {
		viewTabsInLocalStorage.splice(indexOfViewToBeDeleted, 1);

		const localStorageKeyForWorkspaceViews = getLocalStorageKeyForWorkspaceViews(appState.user?.id, appState.organizationId);

		localStorage.setItem(localStorageKeyForWorkspaceViews, JSON.stringify(viewTabsInLocalStorage));

		if (activeViewTab.viewId === view.id) {
			if (viewTabsInLocalStorage.length === 0) {
				const newlySelected = appState.actions.getSelectedView(view.itemFeature);

				onWorkspaceViewClick(newlySelected, setViewForOpenSpaceSelector);
			} else {
				let viewTabMostRecentlyUsed = viewTabsInLocalStorage[0];

				for (let i = 1; i < viewTabsInLocalStorage.length; ++i) {
					if (viewTabMostRecentlyUsed.lastUsed < viewTabsInLocalStorage[i].lastUsed) {
						viewTabMostRecentlyUsed = viewTabsInLocalStorage[i];
					}
				}

				onWorkspaceViewClick(appState.actions.getViewById(viewTabMostRecentlyUsed.viewId), setViewForOpenSpaceSelector);
			}
		} else {
			window.dispatchEvent(new Event(EventNameForViewsChangeInLocalStorage));
		}
	}
};

export const onWorkspaceViewSelect = (view: View, forcePutInBeginningOfList: boolean = false) => {
	const appState = view.appState;

	appState.actions.selectView(view);
	const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(appState.user?.id, appState.organizationId);

	const newViewTab: IViewTab = {
		viewId: view.id,
		lastUsed: Date.now(),
	};

	const itemIndexInListMaybe = viewTabsInLocalStorage.findIndex((v) => v.viewId === view.id);

	if (itemIndexInListMaybe !== -1) {
		const itemInListMaybe = viewTabsInLocalStorage[itemIndexInListMaybe];

		itemInListMaybe.lastUsed = Date.now();

		if (forcePutInBeginningOfList) {
			viewTabsInLocalStorage.splice(itemIndexInListMaybe, 1);
			viewTabsInLocalStorage.unshift(newViewTab);
		}
	} else {
		viewTabsInLocalStorage.unshift(newViewTab);
	}

	const localStorageKeyForWorkspaceViews = getLocalStorageKeyForWorkspaceViews(appState.user?.id, appState.organizationId);

	localStorage.setItem(localStorageKeyForWorkspaceViews, JSON.stringify(viewTabsInLocalStorage));
	const localStorageKeyForActiveWorkspaceView = getLocalStorageKeyForActiveWorkspaceView(appState.user?.id, appState.organizationId);

	localStorage.setItem(localStorageKeyForActiveWorkspaceView, JSON.stringify(newViewTab));

	window.dispatchEvent(new Event(EventNameForViewsChangeInLocalStorage));
};

export const onWorkspaceViewClick = (view: View, setViewForOpenSpaceSelector: (value: View) => void, forcePutInBeginningOfList: boolean = false) => {
	const appState = view.appState;

	if (view.itemFeature === XyiconFeature.SpaceEditor) {
		if (appState.app.spaceViewRenderer.isMounted) {
			onWorkspaceViewSelect(view, forcePutInBeginningOfList);
		} else {
			const spaces = appState.actions.getList<Space>(XyiconFeature.Space);

			if (spaces.length > 0) {
				let newestSpace = spaces[0];

				for (let i = 1; i < spaces.length; ++i) {
					if (spaces[i].lastModifiedAt < newestSpace.lastModifiedAt) {
						newestSpace = spaces[i];
					}
				}

				appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, newestSpace.id);
				onWorkspaceViewSelect(view, forcePutInBeginningOfList);

				// setViewForOpenSpaceSelector(view);
			} else {
				appState.app.navigation.goApp(NavigationEnum.NAV_SPACES);
				onWorkspaceViewSelect(view, forcePutInBeginningOfList);
			}
		}
	} else {
		if (view.itemFeature === XyiconFeature.Xyicon) {
			appState.app.navigation.goApp(NavigationEnum.NAV_XYICONS);
			onWorkspaceViewSelect(view, forcePutInBeginningOfList);
		}
		if (view.itemFeature === XyiconFeature.Boundary) {
			appState.app.navigation.goApp(NavigationEnum.NAV_BOUNDARIES);
			onWorkspaceViewSelect(view, forcePutInBeginningOfList);
		}
	}
};

import styled from "styled-components";
import {FlexCenterStyle, VerticalFlexStyle} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

export const SimpleFilterFieldStyled = styled.div`
	${VerticalFlexStyle};
	gap: 16px;

	.SearchField {
		width: 100%;
	}

	.title {
		text-transform: capitalize;
	}

	.appliedFilterTitle {
		color: ${colorPalette.primary.c500Primary};
		position: relative;
		text-transform: capitalize;
	}

	.values {
		${VerticalFlexStyle};
		gap: 16px;
		border-top: 1px solid ${colorPalette.gray.c400};
		padding: 8px 0;

		.checkbox {
			accent-color: ${colorPalette.primary.c500Primary};
			background: ${colorPalette.gray.c200Light};
		}

		&.multiline {
			column-width: initial;

			.label {
				white-space: pre-line;
			}
		}

		&.multi {
			max-height: 100px;
			overflow-y: auto;
		}
	}
`;

export const FilterFieldItem = styled.div`
	${FlexCenterStyle};
	align-items: flex-start;
	gap: 8px;
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../../../widgets/button/IconButton";
import {Field} from "../../../widgets/form/field/Field";
import {Button} from "../../../widgets/button/Button";
import type {CreateSpaceVersionRequest, UpdateSpaceVersionRequest} from "../../../../generated/api/base";
import {XyiconFeature} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {DateTimeInput} from "../../../widgets/input/datetime/DateTimeInput";
import {ClickToEditInput} from "../../../widgets/input/clicktoedit/ClickToEditInput";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {DateUtils} from "../../../../utils/DateUtils";

interface ICreateVersionSetState {
	name: string;
	createClicked: boolean;
	issuanceDate: string;
}

interface ICreateVersionSetProps {
	onClose: () => void;
	editingVersionSet: SpaceVersion;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class CreateVersionSet extends React.PureComponent<ICreateVersionSetProps, ICreateVersionSetState> {
	private _oldDate = DateUtils.stringify(this.props.editingVersionSet?.date);
	private _oldName = this.props.editingVersionSet?.name;

	constructor(props: ICreateVersionSetProps) {
		super(props);
		this.state = {
			name: props.editingVersionSet?.name || "",
			createClicked: false,
			issuanceDate: DateUtils.stringify(props.editingVersionSet?.date) || DateUtils.todayStringified(),
		};
	}

	private isNameUnique = (name: string) => {
		const {appState, editingVersionSet} = this.props;

		return appState.actions.isVersionSetNameValid(name, editingVersionSet?.id);
	};

	private onCreateClicked = async () => {
		this.setState({createClicked: true});

		const {name, issuanceDate} = this.state;
		const {appState, editingVersionSet} = this.props;

		if (editingVersionSet) {
			editingVersionSet.name = name;
			editingVersionSet.date = new Date(issuanceDate);

			const params: UpdateSpaceVersionRequest = {
				portfolioID: this.props.appState.portfolioId,
				spaceVersionID: editingVersionSet.id,
				name: name,
				issuanceDate: issuanceDate,
			};

			await this.props.transport.requestForOrganization({
				url: "spaceversions/update",
				method: XHRLoader.METHOD_POST,
				params: params,
			});
		} else {
			const params: CreateSpaceVersionRequest = {
				organizationID: appState.organizationId,
				name: name,
				issuanceDate: issuanceDate,
				portfolioID: appState.portfolioId,
			};

			await this.props.transport.services.feature.create(params, XyiconFeature.SpaceVersion);
		}

		this.props.onClose();
	};

	private onIssuanceDateChange = (value: string) => {
		this.setState({issuanceDate: value});
	};

	private onInput = (name: string) => {
		this.setState({name});
	};

	private isNameOrDateChanged = () => {
		const {name, issuanceDate} = this.state;

		return name !== this._oldName || issuanceDate !== this._oldDate;
	};

	public override render() {
		const {name, createClicked, issuanceDate} = this.state;
		const {editingVersionSet, onClose} = this.props;

		const isFormValid = issuanceDate && name && this.isNameUnique(name) && this.isNameOrDateChanged();

		return (
			<>
				<div className="heading createBox hbox">
					<h4>{editingVersionSet ? "Edit a Version Set" : "Create New Version Set"}</h4>
					<IconButton
						className="closePanel"
						icon="close"
						onClick={onClose}
					/>
				</div>
				<div className="sidePanelButtons">
					<Button
						label="Cancel"
						title="Cancel"
						onClick={onClose}
					/>
					<Button
						label={editingVersionSet ? "Save" : "Create"}
						title={editingVersionSet ? "Save" : "Create"}
						onClick={this.onCreateClicked}
						disabled={!isFormValid || createClicked}
						className="primary"
					/>
				</div>
				<Field
					label="New Version Name"
					className="focused"
				>
					<ClickToEditInput
						value={name}
						onLiveChange={this.onInput}
						valueValidator={this.isNameUnique}
					/>
				</Field>
				<Field
					label="Issuance Date"
					className="focused"
				>
					<DateTimeInput
						value={issuanceDate}
						onChange={this.onIssuanceDateChange}
						format="date"
					/>
				</Field>
			</>
		);
	}
}

import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Observer} from "mobx-react";
import {useAppStore} from "../../../../../StateManager";
import type {Catalog} from "../../../../../data/models/Catalog";
import type {IModel} from "../../../../../data/models/Model";
import type {View} from "../../../../../data/models/View";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import {filterModels} from "../../../../../data/models/filter/Filter";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import {DebugInformation} from "../../../../../utils/DebugInformation";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {Debouncer} from "../../../../../utils/function/Debouncer";
import type {ICreateUnplottedXyiconParam} from "../../../../modules/abstract/ModuleView";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {ToggleSwitchField} from "../../../../widgets/button/switch/ToggleSwitchField";
import {DraggableXyiconCatalogContainerV5} from "../../../dockable/DraggableXyiconCatalogContainerV5";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {SelectInputStyled, SelectInputV5} from "../../../input/select/SelectInputV5";
import {LoaderIconV5} from "../../../loader/LoaderIconV5";
import {VerticalFlex, FlexCenter, VerticalFlexStyle} from "../../../styles/styles";
import CirclePlusIcon from "../../../icons/circle-plus.svg?react";
import type {GridViewV5} from "../../../abstract/GridViewV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {colorPalette} from "../../../styles/colorPalette";
import type {DockableTitle} from "./DockableV5";

const logId: string = "Rendering XyiconCatalogContainer";

interface IXyiconCatalogContainerProps {
	spaceViewRenderer: SpaceViewRenderer;
	gridView?: React.RefObject<GridViewV5<IModel>>;
	renderTitle: DockableTitle;
	isDocked: boolean;
	isOpen: boolean;
	onClose: () => void;
	onCloseClick: () => void;
	onAddCatalogClick: () => void;
	onDuplicateCatalogClick: (catalog: Catalog) => void;
	onCreateUnplottedXyicons: (params: ICreateUnplottedXyiconParam[]) => Promise<void> | void;
}

export const XyiconCatalogContainerV5 = (props: IXyiconCatalogContainerProps) => {
	const store = useAppStore();
	const {appState} = store;
	const _debouncer: Debouncer = new Debouncer(100);

	const [search, setSearch] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [onlyFavorites, setOnlyFavorites] = useState<boolean>(false);

	function getKeyForLocalStorage() {
		const module = props.spaceViewRenderer.isMounted ? "space-editor" : "xyicon-panel";

		return `srv4-org-${appState.organizationId}-${module}-catalog-panel-only-favorites`;
	}

	const keyForLocalStorage = getKeyForLocalStorage();

	function userCatalogPermission(): Permission {
		return appState.user.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	}

	function getFilteredItemsByView() {
		const feature = getFeature();
		const items = feature === XyiconFeature.Xyicon ? appState.actions.getUnplottedXyicons() : appState.actions.getList(feature);

		return filterModels(items, appState.actions.getSelectedView(feature).filters, appState);
	}

	const filterItemBySearch = (catalogOrXyicon: Catalog | Xyicon) => {
		const feature = getFeature();
		const stringsToCheck = [catalogOrXyicon.model, catalogOrXyicon.type.name];

		const actions = props.spaceViewRenderer.actions;
		const fields = actions.getFieldsByFeature(feature).filter((f) => f.name !== "Icon");

		for (const field of fields) {
			const fieldValue = actions.renderValue(catalogOrXyicon, field.refId) as string;

			if (fieldValue.length > 0) {
				stringsToCheck.push(fieldValue);
			}
		}

		return stringsToCheck.some((str) => StringUtils.containsIgnoreCase(str, search));
	};

	const filterItemByFavoriteToggler = (catalogOrXyicon: Catalog | Xyicon) => {
		if (onlyFavorites) {
			if (catalogOrXyicon.ownFeature === XyiconFeature.XyiconCatalog) {
				return catalogOrXyicon.isFavorite;
			}
		}

		return true;
	};

	const onSearchInput = (value: string) => {
		_debouncer.debounce(() => setSearch(value));
	};

	const onViewChange = (view: View) => {
		appState.actions.selectView(view);
	};

	function getFeature() {
		if (props.renderTitle === "Catalog") {
			return XyiconFeature.XyiconCatalog;
		} else if (props.renderTitle === "Unplotted Xyicons") {
			return XyiconFeature.Xyicon;
		}
	}

	function getItems(feature: XyiconFeature.XyiconCatalog | XyiconFeature.Xyicon) {
		const filteredItems = getFilteredItemsByView()
			.filter(filterItemByFavoriteToggler)
			.filter(filterItemBySearch)
			.sort((a: Catalog | Xyicon, b: Catalog | Xyicon) => {
				const {actions} = appState;

				const aPermission = actions.getModuleTypePermission(a.typeId, XyiconFeature.Xyicon);
				const bPermission = actions.getModuleTypePermission(b.typeId, XyiconFeature.Xyicon);

				if (aPermission === bPermission) {
					if (a.type.name === b.type.name) {
						return StringUtils.sortIgnoreCase(a.model, b.model);
					} else {
						return StringUtils.sortIgnoreCase(a.type.name, b.type.name);
					}
				} else {
					return aPermission < bPermission ? 1 : -1;
				}
			}) as (Xyicon | Catalog)[];

		if (filteredItems.length > 0) {
			const items = filteredItems.map((v) => ({object: v}));

			return (
				<>
					<small style={{color: colorPalette.gray.c500Primary, padding: "0 16px"}}>{`${items.length} results`}</small>
					<VerticalFlex
						$gap="8px"
						style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))"}}
					>
						<DraggableXyiconCatalogContainerV5
							spaceViewRenderer={props.spaceViewRenderer}
							gridView={props.gridView}
							feature={feature}
							items={items}
							onPointerMove={onGlyphPointerMove}
							onPointerUp={onGlyphPointerUp}
							onDuplicateCatalogClick={props.onDuplicateCatalogClick}
							onCreateUnplottedXyicons={props.onCreateUnplottedXyicons}
							queryString={search}
						/>
					</VerticalFlex>
				</>
			);
		} else {
			return <div className="flexCenter">There are no {feature === XyiconFeature.Xyicon ? "unplotted xyicons" : "catalog items"} to display</div>;
		}
	}

	const onGlyphPointerMove = () => {
		if (!props.isDocked) {
			props.onClose();
		}
	};

	const onGlyphPointerUp = () => {
		if (!props.isDocked && !props.isOpen) {
			props.onCloseClick();
		}
	};

	const onOnlyFavoritesChange = async (value: boolean) => {
		appState.app.transport.services.localStorage.set(keyForLocalStorage, value);

		setOnlyFavorites(value);

		await TimeUtils.wait(300);

		// TODO
		//_selectInput.current?.setState({open: false});
	};

	function getOnlyFavorites() {
		if (getFeature() === XyiconFeature.XyiconCatalog) {
			return (
				<ToggleSwitchField
					classNames="onlyFavoritesToggler hbox alignCenter"
					label="Only Favorites"
					value={onlyFavorites}
					onChange={onOnlyFavoritesChange}
				/>
			);
		}

		return null;
	}

	useEffect(() => {
		setSearch("");
	}, [props.renderTitle]);

	useEffect(() => {
		const onlyFavorites = appState.app.transport.services.localStorage.get(keyForLocalStorage);

		setOnlyFavorites(onlyFavorites);

		setTimeout(() => {
			setIsLoading(false);

			requestAnimationFrame(() => DebugInformation.end(logId));
		}, 300); // derived from css: 0.3s transition
	}, [appState.app.transport.services.localStorage, keyForLocalStorage]);

	const {actions} = appState;
	const feature = getFeature();

	return (
		<Observer>
			{() => {
				const titems = getItems(feature);

				return (
					<XyiconCatalogContainerWrapperStyled>
						<XyiconCatalogContainerStyled>
							<SearchFieldV5
								onInput={onSearchInput}
								value={search}
							/>
							<FlexCenter $gap="8px">
								<SelectInputV5
									childBeforeOptions={getOnlyFavorites()}
									options={actions.getViews(feature)}
									selected={actions.getSelectedView(feature)}
									render={(view) => view.name}
									onChange={onViewChange}
									style={{flex: 1}}
									isSameWidth={true}
								/>
								{feature === XyiconFeature.XyiconCatalog && userCatalogPermission() > Permission.View && (
									<IconButtonV5
										onClick={props.onAddCatalogClick}
										title="Create Catalog Item"
										IconComponent={CirclePlusIcon}
										titleAlignment="top"
									/>
								)}
							</FlexCenter>
						</XyiconCatalogContainerStyled>
						{isLoading ? (
							<div className="dockableLoader flexCenter vbox">
								<LoaderIconV5 />
								<p>{`Loading ${feature === XyiconFeature.Xyicon ? "Unplotted Xyicons" : "Catalog"}...`}</p>
							</div>
						) : (
							<div className="glyphContainer">{titems}</div>
						)}
					</XyiconCatalogContainerWrapperStyled>
				);
			}}
		</Observer>
	);
};

const XyiconCatalogContainerWrapperStyled = styled.div`
	${VerticalFlexStyle};
	flex: 1;
	min-height: 0;
	gap: 16px;

	.glyphContainer {
		${VerticalFlexStyle};
		gap: 4px;
		flex: 1;
		min-height: 0;
		overflow-y: auto;
	}
`;

const XyiconCatalogContainerStyled = styled.div`
	gap: 8px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
	padding: 0 16px;

	.SearchField {
		width: 100%;
		height: 32px;
	}

	${SelectInputStyled} {
		width: 100%;
	}
`;

import {computed, observable, makeObservable} from "mobx";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import type {AppState} from "../state/AppState";
import type {UserGroupDto} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import type {IModel} from "./Model";
import type {User} from "./User";

export class UserGroup implements IModel {
	public static id = 1;

	public static search(userGroup: UserGroup, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();
		const name = userGroup.name?.toLowerCase();

		if (name.indexOf(search) > -1) {
			return true;
		}

		return false;
	}

	public readonly ownFeature = XyiconFeature.UserGroup;

	@observable
	private _data: UserGroupDto;

	constructor(data: UserGroupDto) {
		makeObservable(this);
		this._data = data;
	}

	@computed
	public get id() {
		return this._data.userGroupID;
	}

	public get status() {
		return "Active";
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	public addUser(userId: string, appState: AppState) {
		ArrayUtils.addMutable(this._data.userIDList, userId);

		const user = appState.actions.getFeatureItemById<User>(userId, XyiconFeature.User);

		if (user) {
			user._addToUserGroup(this.id);
		}
	}

	public removeUser(userId: string, appState: AppState) {
		ArrayUtils.removeMutable(this._data.userIDList, userId);

		const user = appState.actions.getFeatureItemById<User>(userId, XyiconFeature.User);

		if (user) {
			user._removeFromUserGroup(this.id);
		}
	}

	public renderMemberCount() {
		const count = this.memberCount;

		if (count === 1) {
			return "1 Member";
		}
		return `${count} Members`;
	}

	@computed
	public get memberCount() {
		return this._data.userIDList.length;
	}

	@computed
	public get userIds(): string[] {
		return this._data.userIDList;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get isSystem() {
		return this._data.isSystem;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import {ListBuilderInput} from "../../../../../widgets/input/listbuilder/ListBuilderInput";
import type {IMultiSelectFieldSettingsDefinition} from "../../../../../../data/models/field/datatypes/MultiSelect";
import type {IFieldDataTypeSettingsProps} from "./IFieldDataTypeSettingsProps";

@observer
export class MultiSelectFieldSettings extends React.Component<IFieldDataTypeSettingsProps> {
	public override render() {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as IMultiSelectFieldSettingsDefinition;

		return (
			<Field label="Items In Dropdown">
				<ListBuilderInput
					list={settings.choiceList}
					onChange={(value: string[]) => {
						settings.choiceList = value;
						onChange();
					}}
				/>
			</Field>
		);
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import type {AppState} from "../../../../../data/state/AppState";
import {TableViewV5} from "../../../abstract/TableViewV5";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import type {IActionBarItem} from "../../../abstract/ModuleViewV5";
import DeleteIcon from "../../../icons/delete.svg?react";
import {UserGroupDetailsTabV5} from "./UserGroupDetailsTabV5";
import {CreateUserGroupPanelV5} from "./CreateUserGroupPanelV5";

interface IUserGroupsViewProps {
	appState?: AppState;
}

@inject("appState")
@observer
export class UserGroupsViewV5 extends React.Component<IUserGroupsViewProps> {
	private _headers = [
		{
			id: "name",
			title: "Group Name",
		},
		{
			id: "userIds",
			title: "Number of Users",
		},
		{
			id: "lastModifiedAt",
			title: "Last Updated",
		},
		{
			id: "lastModifiedBy",
			title: "Updated By",
		},
	];

	private canDeleteUser = (users: UserGroup[]) => {
		if (users.length < 1) {
			return false;
		}

		const areThereAnySystemItemsInSelectedItems = users.some((item) => (item as unknown as UserGroup).isSystem);

		if (areThereAnySystemItemsInSelectedItems) {
			return false;
		}

		return true;
	};

	public override render() {
		const getActionBar = () => {
			const result: IActionBarItem<UserGroup>[] = [
				{
					id: "delete",
					title: "Delete Report",
					label: "Delete",
					IconComponent: DeleteIcon,
					visible: () => true,
					enabled: this.canDeleteUser,
				},
			];

			return result;
		};

		return (
			<TableViewV5
				ownFeature={XyiconFeature.UserGroup}
				headers={this._headers}
				create={(onCreated, onCancel, creating) => (
					<CreateUserGroupPanelV5
						onCancel={onCancel}
						onCreated={onCreated}
						creating={creating}
					/>
				)}
				details={(selected: UserGroup[]) => <UserGroupDetailsTabV5 userGroups={selected} />}
				actionBar={getActionBar()}
				canDeleteUser={this.canDeleteUser}
			/>
		);
	}
}

import styled from "styled-components";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import AlignTopLeftIcon from "../icons/align-top-left.svg?react";
import AlignCenterLeftIcon from "../icons/align-center-left.svg?react";
import AlignBottomLeftIcon from "../icons/align-bottom-left.svg?react";
import AlignTopCenterIcon from "../icons/align-top-center.svg?react";
import AlignCenterCenterIcon from "../icons/align-center-center.svg?react";
import AlignBottomCenterIcon from "../icons/align-bottom-center.svg?react";
import AlignTopRightIcon from "../icons/align-top-right.svg?react";
import AlignCenterRightIcon from "../icons/align-center-right.svg?react";
import AlignBottomRightIcon from "../icons/align-bottom-right.svg?react";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {colorPalette} from "../styles/colorPalette";
import {radius} from "../styles/styles";

interface ITextAlignOptionsProps {
	readonly activeAlignment: {horizontalAlignment: HorizontalAlignment; verticalAlignment: VerticalAlignment};
	readonly onChange: (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => void;
}

export const TextAlignOptionsV5 = (props: ITextAlignOptionsProps) => {
	return (
		<TextAlignOptionsStyled>
			{arrayOfAlignments.map((alignment) => {
				const IconComponent = getIconFromAlignments(alignment.horizontalAlign, alignment.verticalAlign);

				return (
					<IconButtonV5
						key={`${alignment.horizontalAlign}_${alignment.verticalAlign}`}
						isActive={
							props.activeAlignment.horizontalAlignment === alignment.horizontalAlign &&
							props.activeAlignment.verticalAlignment === alignment.verticalAlign
						}
						IconComponent={IconComponent}
						onClick={() => props.onChange(alignment.horizontalAlign, alignment.verticalAlign)}
					/>
				);
			})}
		</TextAlignOptionsStyled>
	);
};

const TextAlignOptionsStyled = styled.div`
	position: absolute;
	top: calc(100% + 8px);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 8px;
	gap: 4px;
	border-radius: ${radius.md};
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px 0px #00000080;
	color: ${colorPalette.gray.c950};

	${IconButtonStyled} {
		&:hover {
			background-color: ${colorPalette.gray.c200Light};
			color: ${colorPalette.gray.c950};
		}
	}
`;

const arrayOfAlignments = [
	{
		horizontalAlign: HorizontalAlignment.left,
		verticalAlign: VerticalAlignment.top,
	},
	{
		horizontalAlign: HorizontalAlignment.center,
		verticalAlign: VerticalAlignment.top,
	},
	{
		horizontalAlign: HorizontalAlignment.right,
		verticalAlign: VerticalAlignment.top,
	},
	{
		horizontalAlign: HorizontalAlignment.left,
		verticalAlign: VerticalAlignment.center,
	},
	{
		horizontalAlign: HorizontalAlignment.center,
		verticalAlign: VerticalAlignment.center,
	},
	{
		horizontalAlign: HorizontalAlignment.right,
		verticalAlign: VerticalAlignment.center,
	},
	{
		horizontalAlign: HorizontalAlignment.left,
		verticalAlign: VerticalAlignment.bottom,
	},
	{
		horizontalAlign: HorizontalAlignment.center,
		verticalAlign: VerticalAlignment.bottom,
	},
	{
		horizontalAlign: HorizontalAlignment.right,
		verticalAlign: VerticalAlignment.bottom,
	},
];

export const getIconFromAlignments = (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => {
	switch (horizontalAlignment) {
		case HorizontalAlignment.left:
			switch (verticalAlignment) {
				case VerticalAlignment.top:
					return AlignTopLeftIcon;
				case VerticalAlignment.center:
					return AlignCenterLeftIcon;
				case VerticalAlignment.bottom:
					return AlignBottomLeftIcon;
			}
		case HorizontalAlignment.center:
			switch (verticalAlignment) {
				case VerticalAlignment.top:
					return AlignTopCenterIcon;
				case VerticalAlignment.center:
					return AlignCenterCenterIcon;
				case VerticalAlignment.bottom:
					return AlignBottomCenterIcon;
			}
		case HorizontalAlignment.right:
			switch (verticalAlignment) {
				case VerticalAlignment.top:
					return AlignTopRightIcon;
				case VerticalAlignment.center:
					return AlignCenterRightIcon;
				case VerticalAlignment.bottom:
					return AlignBottomRightIcon;
			}
	}

	return AlignCenterCenterIcon;
};

import {Object3D} from "three";
import type {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {Constants} from "../Constants";
import type {HighlightMaterial} from "../materials/HighlightMaterial";
import type {BasicMaterial} from "../materials/BasicMaterial";
import type {IBox2} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import type {IEditableItemModel} from "../../../../../../data/models/Model";
import {ColorUtils} from "../../../../../../utils/ColorUtils";
import {Functions} from "../../../../../../utils/function/Functions";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import type {MarkupType, PointDouble} from "../../../../../../generated/api/base";
import {GrabbableCorner} from "./GrabbableCorner";
import {SpaceItem} from "./SpaceItem";
import {MarkupsWithTextOffset} from "./markups/MarkupStaticElements";

export abstract class EditableSpaceItem extends SpaceItem {
	private _boundingBox: IBox2;
	protected _grabbableCorners: GrabbableCorner[] = []; // Used by markups and boundaries
	protected _worldGeometryData: PointDouble[];
	protected _worldGeometryDataOnStartTranslating: PointDouble[];
	protected _isInEditMode: boolean = false;
	protected _indicesOfSelectedVertices: number[] = [];
	protected _savedVertex: PointDouble;
	protected _intersectables = new Object3D();
	protected _meshMaterial: BasicMaterial | HighlightMaterial;
	protected _lineMaterial: BasicMaterial | LineMaterial;
	protected onCameraZoomChangeCallback: () => void = Functions.emptyFunction;
	public abstract isValid: boolean;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer);
		this._intersectables.name = "intersectables";
		this._intersectables.userData.spaceItem = this;

		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.add(this.onCameraZoomChange);
	}

	protected onCameraZoomChange = () => {
		this.updateGrabbableCornerSize();
		this.onCameraZoomChangeCallback();
	};

	protected getDefaultDataToCalculateBBoxFrom(): PointDouble[] {
		return this._isInEditMode && this._grabbableCorners.length > 0
			? this._grabbableCorners.map((corner) => corner.mesh.position)
			: this._worldGeometryData;
	}

	protected calculateBoundingBox(dataToCalculateFrom: PointDouble[] = this.getDefaultDataToCalculateBBoxFrom()) {
		return THREEUtils.calculateBox(dataToCalculateFrom);
	}

	protected updateBoundingBox(dataToCalculateFrom: PointDouble[] = this.getDefaultDataToCalculateBBoxFrom()) {
		this._boundingBox = this.calculateBoundingBox(dataToCalculateFrom);
	}

	protected updateGrabbableCorners(vertices: PointDouble[] = this._worldGeometryData, recreateThem: boolean = true) {
		if (recreateThem) {
			this.removeGrabbableCorners();
		}

		for (let i = 0; i < vertices.length; ++i) {
			const vertex = vertices[i];

			if (recreateThem) {
				const grabbableCorner = new GrabbableCorner(this, vertex.x, vertex.y, this._color, "grabbableCorner");

				this._grabbableCorners.push(grabbableCorner);
			} else {
				const grabbableCorner = this._grabbableCorners[i];
				const mesh = grabbableCorner.mesh;

				THREEUtils.setPosition(mesh, vertex.x, vertex.y, 0);
				THREEUtils.updateMatrices(mesh.children[0]);

				const firstGrabbedIndex = this._indicesOfSelectedVertices[0];

				if (mesh.position.x === this._worldGeometryData[firstGrabbedIndex]?.x && mesh.position.y === this._worldGeometryData[firstGrabbedIndex]?.y) {
					// When a MarkupAB2D is being edited, the order of the 4 corners can be dynamically changing,
					// based on where the user moves the mouse (eg.: you can move the bottom-left corner to the top-right position)
					// In these cases, we'd like upgrade the selection for a fluid experience, so always the grabbed one is
					// marked as selected
					this.resetFillColorOfGrabbableCorners();
					grabbableCorner.setFillColor(0xffffff, SpaceItem.COLOR_INTENSITY.SELECTED);
					THREEUtils.renderToTop(grabbableCorner.mesh);
				}
			}
		}

		if (recreateThem) {
			this.updateGrabbableCornerSize();
		}
	}

	private updateGrabbableCornerSize() {
		for (const grabbableCorner of this._grabbableCorners) {
			const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
			const newSize = (Constants.SIZE.GRABBABLE_ITEM_PX * this._spaceViewRenderer.correctionMultiplier.current) / cameraZoomLevel;

			grabbableCorner.setSize(newSize);
		}
	}

	private removeGrabbableCorners() {
		for (const grabbableCorner of this._grabbableCorners) {
			grabbableCorner.dispose();
			this._intersectables.remove(grabbableCorner.mesh);
		}

		this._grabbableCorners.length = 0;
	}

	public switchEditMode(enabled: boolean, updateBackendOnFinish: boolean) {
		const hasChanged = this._isInEditMode !== enabled;

		if (hasChanged) {
			this._isInEditMode = enabled;

			if (this._isInEditMode) {
				// This way it's easier with the transformations
				this.resetGroupTransformations();
				this.updateGeometry(this._worldGeometryData, false);
				this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
				this.updateGrabbableCorners();
			} else {
				this._indicesOfSelectedVertices.length = 0;
				if (this.isValid) {
					this.removeGrabbableCorners();
					this.setColor(this._color, this._isSelected ? SpaceItem.COLOR_INTENSITY.SELECTED : SpaceItem.COLOR_INTENSITY.DESELECTED);

					this.updateGeometry(this._worldGeometryData, false);
					this.applyOrientation(this._lastSavedOrientation);
					this.updateCenter();

					if (updateBackendOnFinish) {
						this.updateOnBackend();
					}
				}
			}

			this._spaceViewRenderer.needsRender = true;
		}

		return hasChanged;
	}

	private resetGroupTransformations() {
		this._group.rotation.z = 0;
		THREEUtils.setPosition(this._group, 0, 0, 0);
	}

	/**
	 * Don't call server API from this
	 * @param model
	 * @param keepEditMode if it's in edit mode, keep it in edit mode after updating
	 */
	public updateByModel(model: IEditableItemModel, keepEditMode: boolean = true) {
		this._modelData = model;
		const geometryData = model.geometryData;
		const isBeingInEditMode = this._isInEditMode;

		if (isBeingInEditMode) {
			this.switchEditMode(false, false);
		}

		this.resetGroupTransformations();
		this.updateGeometry(THREEUtils.cloneGeometryData(geometryData), false);
		this.applyOrientation(model.orientation, true); // force the update, because "resetGroupTransformations" resets group.rotation.z to 0, so if the new orientation is also 0, it wouldn't do anything
		if (model.color) {
			this.setColor(parseInt(`0x${model.color}`), this._isSelected ? SpaceItem.COLOR_INTENSITY.SELECTED : SpaceItem.COLOR_INTENSITY.DESELECTED, true);
		}
		this.updateCenter();
		this.addModelData(model);

		if (isBeingInEditMode && keepEditMode) {
			this.switchEditMode(true, false);
		}

		this.updateBoundingBox();

		if (this._isSelected) {
			this.itemManager.updateSelectionBox();
			this._spaceViewRenderer.spaceItemController.updateActionBar();
		}
	}

	public override select(updateSelectionBox: boolean = true): boolean {
		const hasChanged = super.select(updateSelectionBox);

		if (hasChanged) {
			THREEUtils.renderToTop(this._group);
		}

		return hasChanged;
	}

	public override deselect(): boolean {
		if (this._isInEditMode) {
			this._spaceViewRenderer.spaceItemController.switchEditMode(false);
		}

		const hasChanged = super.deselect();

		return hasChanged;
	}

	public isObjectPartOfThis(object: Object3D) {
		return this._intersectables.children.includes(object);
	}

	private updateWorldGeometryDataForTranslation() {
		if (this._savedPosition && this._worldGeometryDataOnStartTranslating) {
			const offset = {
				x: this._group.position.x - this._savedPosition.x,
				y: this._group.position.y - this._savedPosition.y,
			};

			this._worldGeometryData = THREEUtils.applyOffsetToGeometryData(ObjectUtils.deepClone(this._worldGeometryDataOnStartTranslating), offset);

			this.updateBoundingBox();
		}
	}

	public override startTranslating() {
		super.startTranslating();
		this._worldGeometryDataOnStartTranslating = ObjectUtils.deepClone(this._worldGeometryData);
	}

	public override translate(x: number, y: number, z: number, force: boolean = false) {
		const hasChanged = super.translate(x, y, z, force);

		if (hasChanged) {
			this.updateWorldGeometryDataForTranslation();
		}

		return hasChanged;
	}

	public override stopTranslating() {
		super.stopTranslating();
		if (!this.isPositionLocked) {
			this.updateWorldGeometryDataForTranslation();
			this._worldGeometryDataOnStartTranslating = null;
		}
	}

	// deltaAngle is compared to this._lastSavedOrientation, not the previous angle!
	public override rotateWithHandlerByDelta(deltaAngle: number, pivot?: PointDouble) {
		if (this.hasPermissionToMoveOrRotate) {
			this.turnToSemiTransparent();
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);

			this._group.rotation.z = MathUtils.calculateNewOrientation(this._lastSavedOrientation, deltaAngle, pivot ? false : undefined);
			if (pivot && this._savedPosition && !this.isPositionLocked) {
				const {x, y} = THREEUtils.getRotatedVertex(this._savedPosition, deltaAngle, pivot);

				this._group.position.setX(x);
				this._group.position.setY(y);
			}
			THREEUtils.updateMatrices(this._group);

			this._spaceViewRenderer.needsRender = true;
		}
	}

	public stopRotating() {
		this.turnToOpaque();
		this.setColor(this._color, SpaceItem.COLOR_INTENSITY.SELECTED);

		const deltaAngle = this._group.rotation.z - this._lastSavedOrientation;
		const deltaPosition = this._savedPosition ? THREEUtils.subVec2fromVec2(this._group.position, this._savedPosition) : {x: 0, y: 0};

		this._worldGeometryData = THREEUtils.applyOffsetToGeometryData(this._worldGeometryData, deltaPosition);
		this._worldGeometryData = THREEUtils.getRotatedVertices(this._worldGeometryData, deltaAngle, this.position);
		this.saveOrientation();
		this.updateCenter(!this._savedPosition);
		this.stopTranslating(); // yes, if it's rotated around a specific pivot point, it can be translated as well, so we must call this here
	}

	private saveOrientation() {
		if (this._lastSavedOrientation !== this._group.rotation.z) {
			this._lastSavedOrientation = this._group.rotation.z;
			this.updateBoundingBox();
		}
	}

	protected resetFillColorOfGrabbableCorners() {
		for (const grabbableCorner of this._grabbableCorners) {
			grabbableCorner.setFillColor(0xffffff, SpaceItem.COLOR_INTENSITY.DESELECTED);
		}
	}

	protected setPositionOfVertexAtIndex(index: number, worldX: number, worldY: number) {
		this._worldGeometryData[index].x = worldX;
		this._worldGeometryData[index].y = worldY;

		this.setPosOfGrabbableCorner(index, worldX, worldY);
	}

	protected setPosOfGrabbableCorner(index: number, worldX: number, worldY: number) {
		const grabbableCorner = this._grabbableCorners[index];

		if (grabbableCorner) {
			THREEUtils.setPosition(grabbableCorner.mesh, worldX, worldY, 0);
			THREEUtils.updateMatrices(grabbableCorner.mesh.children[0]);
		}
	}

	// Used for initializing the orientation. DON'T UPDATE THE SERVER FROM THIS, IT CAN CREATE AN INFINITE LOOP
	public applyOrientation(newOrientation: number, force: boolean = false) {
		if (this._group.rotation.z !== newOrientation || force) {
			this._group.rotation.z = this._lastSavedOrientation = newOrientation;
			THREEUtils.updateMatrices(this._group);
			this.updateBoundingBox();
			this._spaceViewRenderer.needsRender = true;
		}
	}

	private calculateNewCenter() {
		const bbox = THREEUtils.calculateBox(this.unrotatedGeometryData);

		this._meshHeight = bbox.max.y - bbox.min.y;

		return THREEUtils.getCenterFromGeometryData(this._worldGeometryData, this._lastSavedOrientation);
	}

	public finalize() {
		this.updateCenter();
		this.onLayerSettingsModified();
		this.onFormattingRulesModified();

		this._spaceViewRenderer.needsRender = true;
	}

	protected updateCenter(calledByStopRotationAroundItsOwnPivot: boolean = false) {
		// Put group to geometrical center, so we can rotate it later
		this._worldGeometryData = [...this._worldGeometryData]; // copy it, because the original one will get cleared (this._savedVertices.length = 0;)

		const center = {
			x: this._group.position.x,
			y: this._group.position.y,
		};

		if (calledByStopRotationAroundItsOwnPivot) {
		} else {
			const newCenter = this.calculateNewCenter();

			center.x = newCenter.x;
			center.y = newCenter.y;
		}

		this._group.position.setX(center.x);
		this._group.position.setY(center.y);
		this._group.rotation.z = 0;

		const offset = {
			x: -center.x,
			y: -center.y,
		};

		const localGeometryData = THREEUtils.cloneGeometryData(this._worldGeometryData);

		THREEUtils.applyOffsetToGeometryData(localGeometryData, offset);

		this.updateGeometry(localGeometryData, true);

		return localGeometryData;
	}

	/** The only scenario when we wouldn't want removeFromCollections to be true is when we delete everything
	 * Because we take care of removing everything after this from the collections with one call,
	 * since it's much faster then removing them one by one.
	 */
	protected destroyCallback(notifyServer: boolean = false, removeFromCollections: boolean = true) {
		if (removeFromCollections) {
			if (this._group.parent) {
				THREEUtils.disposeAndClearContainer(this._group);
				this._group.parent.remove(this._group);
			}
			this.itemManager.deleteItem(this, notifyServer);
			this._spaceViewRenderer.needsRender = true;
		}

		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.remove(this.onCameraZoomChange);
	}

	public override mouseOver() {
		if (!this._isSelected) {
			const highlightedColorHsl = this.shiftedColor.hex;

			this.setColor(parseInt(`0x${highlightedColorHsl}`), SpaceItem.COLOR_INTENSITY.DESELECTED);
			this._spaceViewRenderer.needsRender = true;
		}
	}

	protected get shiftedColor() {
		const highlightedColorHSL = ColorUtils.hex2hsl(this._color);
		const highlightedColorHex = ColorUtils.hsl2hex(
			highlightedColorHSL.h,
			MathUtils.clamp(highlightedColorHSL.s * 1.5, 0, 1),
			MathUtils.clamp(highlightedColorHSL.l * 0.5, 0, 1),
			"string",
		) as string;

		return {
			hex: `${highlightedColorHex}`,
			transparency: 0,
		};
	}

	protected get unrotatedGeometryData(): PointDouble[] {
		let unrotatedGeometryData = THREEUtils.cloneGeometryData(this._worldGeometryData);

		unrotatedGeometryData = THREEUtils.getRotatedVertices(unrotatedGeometryData, -this._lastSavedOrientation);

		return unrotatedGeometryData;
	}

	public get boundingBox() {
		return this._boundingBox;
	}

	public get intersectables() {
		return this._intersectables;
	}

	public get scale() {
		const unrotatedBbox = THREEUtils.calculateBox(this.unrotatedGeometryData);

		return THREEUtils.getSizeOfBoundingBox2(unrotatedBbox);
	}

	public abstract updateGeometry(geometryData: PointDouble[], isLocal: boolean): void;

	protected abstract onGeometryUpdated(): void;

	public onGrabbableCornerPointerDown(grabbableCorner: GrabbableCorner) {
		if (this._isInEditMode) {
			const index = this._grabbableCorners.indexOf(grabbableCorner);

			if (index > -1) {
				const grabbableCorner = this._grabbableCorners[index];
				const isMarkupWithTextOffset =
					MarkupsWithTextOffset.includes(this.type as MarkupType) && this._grabbableCorners.length === this._worldGeometryData.length + 1;

				if (this._grabbableCorners.length === this._worldGeometryData.length || isMarkupWithTextOffset) {
					this._indicesOfSelectedVertices = [index];
				} else {
					const isMarkupCalloutTargetGrabbed = index > 3; // index === 4 would be just as good, but maybe there will be additional arrows/targets in the future

					if (isMarkupCalloutTargetGrabbed) {
						this._indicesOfSelectedVertices = [index];
					} else {
						// MarkupAB2D
						this._indicesOfSelectedVertices = [1];

						const fixedCornerIndex = (index + 2) % 4; // the corner at the opposite side (diagonal)

						this._worldGeometryData[0].x = this._grabbableCorners[fixedCornerIndex].mesh.position.x;
						this._worldGeometryData[0].y = this._grabbableCorners[fixedCornerIndex].mesh.position.y;
					}
				}

				this._savedVertex = {
					x: grabbableCorner.mesh.position.x,
					y: grabbableCorner.mesh.position.y,
				};

				this.resetFillColorOfGrabbableCorners();
				grabbableCorner.setFillColor(0xffffff, SpaceItem.COLOR_INTENSITY.SELECTED);
				THREEUtils.renderToTop(grabbableCorner.mesh);

				this._spaceViewRenderer.needsRender = true;
			}
		}
	}

	public updateOnBackend() {
		return this.itemManager.updateItems([this]);
	}

	public abstract onGrabbableCornerPointerMove(deltaX: number, deltaY: number): void;
	public abstract onGrabbableCornerPointerUp(): void;
}

import * as React from "react";

interface INoResultFilterViewProps {}

export class NoResultFilterView extends React.PureComponent<INoResultFilterViewProps> {
	public override render() {
		return (
			<div className="message">
				No results found.
				<br />
				Try clearing a few filters to see results.
			</div>
		);
	}
}

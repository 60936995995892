import {FieldDataType} from "../../../../../../generated/api/base";
import {FieldDataTypes, stringOperators} from "../../../../../../data/models/field/FieldDataTypes";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import {GeoLocationInputV5} from "../../../../../5.0/details/GeoLocationInputV5";
import {GeoLocationInput} from "./GeoLocationInput";

export interface IGeoLocation {
	lat: number;
	long: number;
}

function formatGeoLocation(value: IGeoLocation) {
	if (!ObjectUtils.isNullish(value?.lat) && !ObjectUtils.isNullish(value?.long)) {
		return `${value.lat}, ${value.long}`;
	}
	return "";
}

function changeGeoLocation(value: any, inputValue: any, selectedOptionValue?: any) {
	if (selectedOptionValue === undefined) {
		return !ObjectUtils.compare(value, inputValue);
	}
	return ObjectUtils.compare(value, selectedOptionValue) && !ObjectUtils.compare(value, inputValue);
}

export function configureGeoLocation() {
	FieldDataTypes.configure({
		id: FieldDataType.GeoLocation,
		title: "Geo Location",
		label: GeoLocationLabel,
		labelV5: GeoLocationLabelV5,
		input: GeoLocationInput,
		inputV5: GeoLocationInputV5,
		operatorsArray: stringOperators,
		formatter: formatGeoLocation,
		changeChecker: changeGeoLocation,
	});
}

interface ISingleLineLabelProps {
	value: IGeoLocation;
	dataTypeSettings?: any;
}

const GeoLocationLabel = (props: ISingleLineLabelProps) => {
	const {value} = props;

	return formatGeoLocation(value);
};

const GeoLocationLabelV5 = (props: ISingleLineLabelProps) => {
	const {value} = props;

	return formatGeoLocation(value);
};

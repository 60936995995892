import {useAppStore} from "../../../StateManager";
import type {XyiconFeature} from "../../../generated/api/base";
import {Functions} from "../../../utils/function/Functions";
import {GridViewV5} from "./GridViewV5";

interface ISideGridProps {
	viewFeature: XyiconFeature;
	itemFeature: XyiconFeature;
}

export const SideGrid = (props: ISideGridProps) => {
	const appState = useAppStore((state) => state.appState);
	const {viewFeature, itemFeature} = props;
	// const selectedView = appState.actions.getSelectedView(viewFeature);

	const spaceItems = appState.app.graphicalTools.spaceViewRenderer.spaceItemController.getFilteredItems();

	// Apply local filters
	const items = spaceItems.filter((item) => item.ownFeature === itemFeature);

	return (
		<GridViewV5
			items={items}
			feature={itemFeature}
			selected={[]}
			focused={[]}
			onFocus={Functions.emptyFunction}
		/>
	);
};

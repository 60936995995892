import type {Texture, WebGLProgramParametersWithUniforms} from "three";
import {RawShaderMaterial} from "three";
import {BasicMaterial} from "./BasicMaterial";

export class EyeDropperMaterial extends RawShaderMaterial {
	private _map: {
		type: "t";
		value: Texture;
	};

	private _resolution: {
		type: "2fv";
		value: number[];
	};

	private readonly _vertexShader: string = BasicMaterial.vertexShader;

	private readonly _fragmentShader: string = `precision highp float;

#define gridThickness 1.0
#define gridSpacing 10.0

varying vec2 vUv;

uniform vec2 resolution;
uniform sampler2D map;

void main()
{
	vec4 outputColor = texture2D(map, vUv);

	vec2 fragCoord = gl_FragCoord.xy;

	if (mod(fragCoord.x + 0.5, gridSpacing) < gridThickness || mod(fragCoord.y + 0.5, gridSpacing) < gridThickness)
	{
		outputColor.r = outputColor.g = outputColor.b = 0.5;
		
		vec2 halfResolution = resolution / 2.0;
		float halfGridSpacing = gridSpacing / 2.0;


		if (   fragCoord.x - halfGridSpacing <= halfResolution.x && halfResolution.x <= fragCoord.x + halfGridSpacing
			&& fragCoord.y - halfGridSpacing <= halfResolution.y && halfResolution.y <= fragCoord.y + halfGridSpacing)
		{
			outputColor.r = 1.0;
			outputColor.g = outputColor.b = 0.0;
		}
	}

	gl_FragColor = outputColor;
}`;

	constructor(map: Texture, canvasResolution: number[]) {
		super({depthTest: false});

		this._map = {
			type: "t",
			value: map,
		};

		this._resolution = {
			type: "2fv",
			value: canvasResolution,
		};

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;
			program.uniforms = {
				map: this._map,
				resolution: this._resolution,
			};
		};
	}

	// So it can be disposed, when the scene is disposed
	public get map() {
		return this._map.value;
	}
}

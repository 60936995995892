import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes} from "../FieldDataTypes";
import {DateInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/date/DateInput";
import type {IDateFieldSettingsDefinition} from "../../../../ui/modules/settings/modules/field/datatypes/DateFieldSettings";
import {DateFieldSettings, IDateFieldFormat} from "../../../../ui/modules/settings/modules/field/datatypes/DateFieldSettings";
import {FilterOperator} from "../../filter/operator/FilterOperator";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import type {AppState} from "../../../state/AppState";
import {DateInputV5} from "../../../../ui/5.0/details/datatypes/DateInputV5";
import {DateFieldSettingsV5} from "../../../../ui/5.0/details/datatypes/DateFieldSettingsV5";

const operators = {
	[IDateFieldFormat.DATE]: [
		FilterOperator.IS_EQUAL_TO_DATE,
		FilterOperator.IS_LESS_THAN_DATE,
		FilterOperator.IS_GREATER_THAN_DATE,
		FilterOperator.IS_IN_BETWEEN_DATE,
		FilterOperator.IS_IN_THE_LAST_DATE,
		FilterOperator.IS_IN_THE_NEXT_DATE,
		FilterOperator.IS_TODAY,
		FilterOperator.IS_YESTERDAY,
		FilterOperator.IS_IN_THIS_WEEK,
		FilterOperator.IS_IN_THIS_MONTH,
		FilterOperator.IS_IN_LAST_WEEK,
		FilterOperator.IS_IN_LAST_MONTH,
		FilterOperator.IS_BLANK,
		FilterOperator.IS_NOT_BLANK,
	],
	[IDateFieldFormat.DATETIME]: [
		FilterOperator.IS_EQUAL_TO_DATE,
		FilterOperator.IS_LESS_THAN_DATE,
		FilterOperator.IS_GREATER_THAN_DATE,
		FilterOperator.IS_IN_BETWEEN_DATE,
		FilterOperator.IS_IN_THE_LAST_DATE,
		FilterOperator.IS_IN_THE_NEXT_DATE,
		FilterOperator.IS_TODAY,
		FilterOperator.IS_YESTERDAY,
		FilterOperator.IS_IN_THIS_WEEK,
		FilterOperator.IS_IN_THIS_MONTH,
		FilterOperator.IS_IN_LAST_WEEK,
		FilterOperator.IS_IN_LAST_MONTH,
		FilterOperator.IS_BLANK,
		FilterOperator.IS_NOT_BLANK,
	],
	[IDateFieldFormat.TIME]: [
		FilterOperator.IS_AT_TIME,
		FilterOperator.IS_BEFORE_TIME,
		FilterOperator.IS_AFTER_TIME,
		FilterOperator.IS_BETWEEN_TIME,
		FilterOperator.IS_BLANK,
		FilterOperator.IS_NOT_BLANK,
	],
};

function changeDateTime(value: any, inputValue: any, selectedOptionValue?: any, refId?: string, appState?: AppState) {
	const formattedValue = appState.actions.formatValue(value, refId);

	if (selectedOptionValue === undefined) {
		return formattedValue !== appState.actions.formatValue(inputValue, refId);
	}
	return (
		appState.actions.formatValue(selectedOptionValue, refId) === formattedValue && formattedValue !== appState.actions.formatValue(inputValue, refId)
	);
}

export function configureDate() {
	FieldDataTypes.configure({
		id: FieldDataType.DateTime,
		title: "Date - Time",
		input: DateInput,
		inputV5: DateInputV5,
		formatter: (value, settings) => DateFormatter.format(value, settings?.format),
		changeChecker: changeDateTime,
		settings: DateFieldSettings,
		settingsV5: DateFieldSettingsV5,
		defaultSettings: {
			format: "date", // This will be the default option used when creating a new field with date-time datatype
		} as IDateFieldSettingsDefinition,
		operators: (settings: IDateFieldSettingsDefinition) => operators[settings?.format as IDateFieldFormat.DATETIME] || [],
	});
}

import {useRef, useState} from "react";
import styled from "styled-components";
import type {Markup} from "../../../../../data/models/Markup";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import type {FillOpacity} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import FillTransparentIcon from "../../../icons/fill-transparent.svg?react";
import FillSemiTransparentIcon from "../../../icons/fill-semi-transparent.svg?react";
import FillOpaqueIcon from "../../../icons/fill-opaque.svg?react";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {Functions} from "../../../../../utils/function/Functions";
import {useClickOutside} from "../../../utils";
import {MarkupsWithCustomizableFillOpacity} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {radius} from "../../../styles/styles";
import {FillOptionsV5} from "./FillOptionsV5";

interface IFillButtonProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
}

export const FillButtonV5 = (props: IFillButtonProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const _ref = useRef<HTMLDivElement>();

	const _selectedItems = props.spaceViewRenderer.spaceItemController.selectedItems;

	const onFillClick = () => {
		setIsOpen((open) => !open);
	};

	useClickOutside([_ref], () => setIsOpen(false));

	const onFillChange = (fillOpacity: FillOpacity) => {
		const markupsToUpdate: Markup3D[] = [];

		const markup3Ds = _selectedItems as Markup3D[];

		for (const markup3D of markup3Ds) {
			const markup = markup3D.modelData as Markup;
			const hasChanged = markup.fillTransparency !== 1 - fillOpacity;

			if (hasChanged) {
				markup.setFillTransparency?.(1 - fillOpacity);
				markupsToUpdate.push(markup3D);
			}
		}

		for (const markup3D of markupsToUpdate) {
			markup3D.updateByModel(markup3D.modelData as Markup);
		}

		props.spaceViewRenderer.markupManager.updateItems(markupsToUpdate, true);
	};

	const getActiveIcon = () => {
		const firstSelectedObject = _selectedItems[0]?.modelData as Markup;

		if (!firstSelectedObject) {
			console.warn("There's no data for selected object. Please report this to the developers.");
		}

		switch (firstSelectedObject?.fillTransparency) {
			case 0:
				return FillOpaqueIcon;
			case 1:
				return FillTransparentIcon;
			default:
				return FillSemiTransparentIcon;
		}
	};

	const areFillOptionsAvailable =
		_selectedItems.length > 0 &&
		_selectedItems.every((item) => item.spaceItemType === "markup" && MarkupsWithCustomizableFillOpacity.includes((item as Markup3D).type));
	const activeIcon = getActiveIcon();

	return (
		areFillOptionsAvailable && (
			<FillButtonStyled
				ref={_ref}
				onClick={onFillClick}
				className="button flexCenter FillButton"
			>
				<IconButtonV5
					IconComponent={activeIcon}
					className={activeIcon === FillSemiTransparentIcon ? "semiTransparent" : ""}
					onClick={Functions.emptyFunction}
					title="Fill Transparency..."
					titleAlignment="top"
				/>
				{isOpen && <FillOptionsV5 onChange={onFillChange} />}
			</FillButtonStyled>
		)
	);
};

const FillButtonStyled = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	> ${IconButtonStyled}.semiTransparent {
		&::before {
			content: "";
			position: absolute;
			background-color: white;
			width: 16px;
			height: 16px;
			border-radius: ${radius.sm};
		}
		svg {
			z-index: 1;
		}
	}
`;

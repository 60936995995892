import {PDFDocument} from "pdf-lib";
import * as pdfjs from "pdfjs-dist";
import type {PDFDocumentProxy, PDFPageProxy} from "pdfjs-dist/types/src/display/api";
import {Constants} from "../Constants";
import type {ISize} from "../../../../../../utils/THREEUtils";
import {FileUtils} from "../../../../../../utils/file/FileUtils";
import {ImageUtils} from "../../../../../../utils/image/ImageUtils";
import {Signal} from "../../../../../../utils/signal/Signal";
import {DebugInformation} from "../../../../../../utils/DebugInformation";
import type {IPDFRenderer, ISnapShotConfig, ITileData, IZoomInfo} from "../../../../../../offline_utils/utils/PDFRendererUtils";
import {getSnapShot, getSpaceSize, getSnapShotConfig} from "../../../../../../offline_utils/utils/PDFRendererUtils";
import type {SpaceFileInsertionInfo} from "../../../../../../generated/api/base";
pdfjs.GlobalWorkerOptions.workerSrc = "src/temp/libs/pdfjs/pdf.worker.min.mjs";

type Cvas = HTMLCanvasElement; // | Canvas;
type Ctext = CanvasRenderingContext2D; // | NodeCanvasRenderingContext2D;

// There's supposed to be only one instance of this
// Feel free to use the one already defined instead of creating a new one

export class PDFRenderer implements IPDFRenderer {
	private _spaceSize: ISize = {width: 1, height: 1}; // model space units
	private _url: string;
	private _pdf: PDFDocumentProxy;
	private _page: PDFPageProxy;
	private _cropLeft: number;
	private _cropBottom: number;
	private _pdfPageWidth: number;
	private _pdfPageHeight: number;
	private _spaceToPDFRatio: number;
	private _canvas: Cvas;
	private _context: Ctext;

	public signals = {
		tileRasterized: Signal.create<void>(),
	};

	private _queueToProcess: {
		snapShotConfig: ISnapShotConfig;
		zoomInfoObject?: IZoomInfo;
		pdf?: PDFDocument;
		insertionInfo?: SpaceFileInsertionInfo;
		tileId?: string;
		resolve: (data: ITileData) => void;
	}[] = [];
	private _isProcessing: boolean = false;
	private _isSnapShotBeingCreated: boolean = false;

	private _thumbnailCache: {
		[key: string]: Promise<{url: string; insertionInfo: SpaceFileInsertionInfo}>;
	} = {};

	private _processOrder: "LIFO" | "FIFO" = "FIFO";

	constructor(canvas: Cvas) {
		this._canvas = canvas;
		// Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true. See: https://html.spec.whatwg.org/multipage/canvas.html#concept-canvas-will-read-frequently
		this._context = this._canvas.getContext("2d", {willReadFrequently: true, desynchronized: true}) as CanvasRenderingContext2D;
	}

	public setProcessOrder(order: "LIFO" | "FIFO") {
		this._processOrder = order;
	}

	public async savePDFValues(pdf: string | PDFDocument) {
		let url: string = null;

		if (pdf instanceof PDFDocument) {
			const byteArray = await pdf.save();

			url = FileUtils.createURLFromData(byteArray);
		} else {
			url = pdf;
		}
		this._url = url;

		if (this._pdf) {
			if (this._isSnapShotBeingCreated) {
				await this.tileHasRasterized();
			}
			await this._pdf.cleanup();
		}

		const results = await getSpaceSize(this._url, pdfjs, pdfjs.VerbosityLevel.ERRORS);

		this._page = results.page;
		this._pdfPageWidth = results.pageWidth;
		this._pdfPageHeight = results.pageHeight;
		this._cropBottom = results.cropBottom;
		this._cropLeft = results.cropLeft;

		console.log(`PDF size in PDF units: ${this._pdfPageWidth} x ${this._pdfPageHeight}`);
	}

	private tileHasRasterized() {
		return new Promise<void>((resolve, reject) => {
			const done = () => {
				this.signals.tileRasterized.remove(done);
				resolve();
			};

			this.signals.tileRasterized.add(done);
		});
	}

	public saveSpaceSize(spaceWidth: number) {
		this._spaceSize.width = spaceWidth;
		this._spaceToPDFRatio = this._spaceSize.width / this._pdfPageWidth;
		this._spaceSize.height = this._pdfPageHeight * this._spaceToPDFRatio;
	}

	public async init(pdf: string | PDFDocument, spaceWidth: number) {
		await this.savePDFValues(pdf);

		this.saveSpaceSize(spaceWidth);
	}

	private isCanvasCompletelyWhite(ctx: CanvasRenderingContext2D) {
		return ImageUtils.isCanvasCompletelyWhite(ctx);
	}

	public async getSnapShot(config: ISnapShotConfig) {
		if (this._page) {
			this._isSnapShotBeingCreated = true;
			const context = await getSnapShot(config, this._context, this);

			this._isSnapShotBeingCreated = false;

			return this._context;
		} else {
			return null;
		}
	}

	public clearQueue() {
		this._queueToProcess.length = 0;
	}

	public async processQueue() {
		if (!this._isProcessing) {
			if (this._queueToProcess.length > 0) {
				this._isProcessing = true;
				const queueElement = this._processOrder === "LIFO" ? this._queueToProcess.pop() : this._queueToProcess.shift();
				const resolve = queueElement.resolve;
				const snapShotConfig = queueElement.snapShotConfig;

				const pdf = queueElement.pdf;

				if (pdf) {
					await this.init(pdf, Constants.DEFAULT_SPACE_WIDTH);

					const aspect = this._pdfPageWidth / this._pdfPageHeight;

					snapShotConfig.scale = snapShotConfig.maxSize / (aspect >= 1 ? this._pdfPageWidth : this._pdfPageHeight);
				}

				const logId = `PDF Tile rendering (zoomlevel_x_y) - ${queueElement.tileId ? queueElement.tileId.split("_").slice(0, 3).join("_") : "thumbnail"}`;

				DebugInformation.start(logId);
				const context = await this.getSnapShot(snapShotConfig);

				DebugInformation.end(logId);
				this.signals.tileRasterized.dispatch(null);
				const canvasContext = this.isCanvasCompletelyWhite(context) ? null : context;

				const url = canvasContext ? URL.createObjectURL(await FileUtils.canvasToBlob(canvasContext.canvas)) : null;

				resolve({
					context: canvasContext,
					url: url,
					insertionInfo: {
						width: this._spaceSize.width,
						height: this._spaceSize.height,
					},
					tileId: queueElement.tileId,
					zoomInfoObject: queueElement.zoomInfoObject,
				});

				this._isProcessing = false;

				if (this._queueToProcess.length > 0) {
					requestAnimationFrame(() => {
						this.processQueue();
					});
				}
			}
		}
	}

	private getSnapShotConfig(tileId: string, zoomInfoObject: IZoomInfo, isTransparent: boolean, desiredTileResolution: number) {
		return getSnapShotConfig({
			tileId: tileId,
			zoomInfoObject: zoomInfoObject,
			isTransparent: isTransparent,
			desiredTileResolution: desiredTileResolution,
			pdfPageHeight: this._pdfPageHeight,
			spaceToPDFRatio: this._spaceToPDFRatio,
		});
	}

	public addTileToQueue(tileId: string, zoomInfoObject: IZoomInfo, isTransparent: boolean, desiredTileResolution: number) {
		/**
		 * tileId: <zoomlevel>_<x>_<y>
		 * example:
		 * tileId: 3_0_1
		 */
		return new Promise<ITileData>((resolve, reject) => {
			if (this._page) {
				this._queueToProcess.push({
					snapShotConfig: this.getSnapShotConfig(tileId, zoomInfoObject, isTransparent, desiredTileResolution),
					zoomInfoObject: zoomInfoObject,
					tileId: tileId,
					resolve: resolve,
				});

				// have to manually start processing the Queue!
			} else {
				resolve(null);
			}
		});
	}

	/**
	 *
	 * @param maxSize max(width, height)
	 */
	private getFullImageURLFromPDF(maxSize: number, pdf: PDFDocument) {
		return new Promise<{url: string; insertionInfo: SpaceFileInsertionInfo}>((resolve, reject) => {
			this._queueToProcess.push({
				snapShotConfig: {
					scale: null, // will be calculated on the fly
					maxSize: maxSize,
					offsetX: 0,
					offsetY: 0,
					isBackground: true,
					isTransparent: true,
				},
				pdf: pdf,
				resolve: resolve,
			});

			if (!this._isProcessing) {
				requestAnimationFrame(() => {
					this.processQueue();
				});
			}
		});
	}

	/**
	 *
	 * @param maxSize max(width, height)
	 * @param pdf
	 * @param cacheKey You should give a unique name. It will be saved at _cache[cacheKey]
	 * @param rotation In degrees, 0 | 90 | 180 | 270
	 */
	public async getThumbnailAndViewBox(maxSize: number, pdf: PDFDocument, cacheKey: string, rotation: number) {
		if (cacheKey) {
			if (!this._thumbnailCache[cacheKey]) {
				this._thumbnailCache[cacheKey] = this.getFullImageURLFromPDF(maxSize, pdf);
			}

			if (rotation != null) {
				if (!this._thumbnailCache[`${cacheKey}_${rotation}`]) {
					const imgSrcWithoutRotation = await this._thumbnailCache[cacheKey];
					const img = await ImageUtils.loadImage(imgSrcWithoutRotation.url);
					let {width, height} = imgSrcWithoutRotation.insertionInfo;

					const shouldSwapWidthAndHeight = (rotation / 90) % 2 === 1;

					if (shouldSwapWidthAndHeight) {
						[width, height] = [height, width];
					}

					this._thumbnailCache[`${cacheKey}_${rotation}`] = new Promise<{url: string; insertionInfo: SpaceFileInsertionInfo}>((resolve, reject) => {
						resolve({
							url: ImageUtils.rotateImage(img, rotation),
							insertionInfo: {
								width: width,
								height: height,
								offsetX: 0,
								offsetY: 0,
								offsetZ: 0,
							},
						});
					});
				}

				return this._thumbnailCache[`${cacheKey}_${rotation}`];
			} else {
				return this._thumbnailCache[cacheKey];
			}
		} else {
			return this.getFullImageURLFromPDF(maxSize, pdf);
		}
	}

	public clearCache() {
		this._thumbnailCache = {};
	}

	public get cropLeft() {
		return this._cropLeft;
	}

	public get cropBottom() {
		return this._cropBottom;
	}

	public get page() {
		return this._page;
	}

	public get pdfPageWidth() {
		return this._pdfPageWidth;
	}

	public get pdfPageHeight() {
		return this._pdfPageHeight;
	}

	public get spaceToPDFRatio() {
		return this._spaceToPDFRatio;
	}

	public get spaceSize() {
		return this._spaceSize;
	}

	public get isPending() {
		return this._isProcessing || this._queueToProcess.length > 0;
	}
}

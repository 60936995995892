import {ColorSelector} from "../../../common/colorselector/ColorSelector";
import {SizeChanger} from "../../../common/sizechanger/SizeChanger";
import {Field} from "../../../../../widgets/form/field/Field";
import type {SpaceViewRenderer} from "../../../../space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Markup3D} from "../../../../space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import type {Color, TextDto} from "../../../../../../generated/api/base";
import {FieldDataType, MarkupType} from "../../../../../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {FillButton} from "../../../../space/spaceeditor/ui/actionbar/FillButton";
import {LineThicknessChanger} from "../../../../space/spaceeditor/ui/actionbar/LineThicknessChanger";
import {ArrowHeadSizeChanger} from "../../../../space/spaceeditor/ui/actionbar/ArrowHeadSizeChanger";
import {ClickToEditInput} from "../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {FontStyleContainer} from "../../../../space/spaceeditor/ui/viewbar/FontStyleContainer";
import type {Markup} from "../../../../../../data/models/Markup";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import {TextGroupManager} from "../../../../space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import {TextAlignOptions} from "../../../../space/spaceeditor/ui/actionbar/TextAlignOptions";
import {
	MarkupsWithArrowHeads,
	MarkupsWithChangeableLineThickness,
	MarkupsWithCustomText,
	MarkupsWithCustomizableColor,
	MarkupsWithCustomizableFillOpacity,
	MarkupsWithCustomizableTextAlignment,
} from "../../../../space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";

interface ICaptionSettingsProps {
	spaceViewRenderer: SpaceViewRenderer;
	markups: Markup3D[];
}

export function MarkupProperties(props: ICaptionSettingsProps) {
	const {spaceViewRenderer, markups} = props;
	const firstItem = markups[0];
	const modelData = firstItem.modelData as Markup;
	const eyeDropperProps = spaceViewRenderer.eyeDropperProps;
	const typesWithMarkupStyleSet = MarkupsWithCustomizableColor;
	const typesWithMarkupTextSet = MarkupsWithCustomText;
	let _timeOutId: number = null;

	const getColor = () => {
		return {hex: (markups.find((markup) => typesWithMarkupStyleSet.includes(markup.type)).modelData as Markup).color, transparency: 0};
	};

	const onColorChange = (markupPropNames: (keyof TextDto | "color" | "fillTransparency")[], newValues: (boolean | string | number | Color)[]) => {
		if (_timeOutId) {
			clearTimeout(_timeOutId);
		}

		_timeOutId = window.setTimeout(() => {
			updateMarkupProp(markupPropNames, newValues);
		}, 300);
	};

	const updateMarkupProp = (markupPropNames: (keyof TextDto | "color" | "fillTransparency")[], newValues: (boolean | string | number | Color)[]) => {
		markups.forEach((markup) => {
			const data = markup.modelData as Markup;

			markupPropNames.forEach((markupPropName, index) => {
				if (markupPropName === "color") {
					data.setColor(newValues[index] as string);
				} else if (markupPropName === "fillTransparency") {
					data.setFillTransparency(newValues[index] as number);
				} else {
					(data.text[markupPropName] as boolean | string | number | Color) = newValues[index];
				}
			});

			markup.updateByModel(data);
		});

		spaceViewRenderer.markupManager.updateItems(markups, true);

		if (markupPropNames.includes("content")) {
			spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	};

	return (
		<div className="MarkupProperties">
			{!markups.every((markup) => markup.type === MarkupType.Text_Box) && (
				<div className="propertyContainer markupStyle">
					<span className="propertySetLabel">Markup Style</span>
					{!markups.every((markup) => markup.type === MarkupType.Text_Box) && (
						<Field label="Fill Color">
							<ColorSelector
								title="Fill Color"
								color={getColor()}
								onColorChange={(color) => onColorChange(["color"], [color.hex])}
								eyeDropperProps={eyeDropperProps}
								isTransparencyEnabled={false}
								horizontalAlignment={HorizontalAlignment.outerRight}
								verticalAlignment={VerticalAlignment.bottom}
								icon="paint-bucket"
								classNames="text-colorselector"
							/>
						</Field>
					)}
					{markups.every((markup) => MarkupsWithCustomizableFillOpacity.includes(markup.type)) && (
						<Field label="Fill transparency">
							<FillButton spaceViewRenderer={spaceViewRenderer} />
						</Field>
					)}
					{markups.every((markup) => MarkupsWithChangeableLineThickness.includes(markup.type)) && (
						<Field label="Stroke Width">
							<LineThicknessChanger
								spaceViewRenderer={spaceViewRenderer}
								items={markups}
							/>
						</Field>
					)}
					{markups.every((markup) => MarkupsWithArrowHeads.includes(markup.type)) && (
						<Field label="Arrow Size">
							<ArrowHeadSizeChanger spaceViewRenderer={spaceViewRenderer} />
						</Field>
					)}
				</div>
			)}
			{markups.length === 1 && markups.every((markup) => typesWithMarkupTextSet.includes(markup.type)) && (
				<div className="propertyContainer markupText">
					<span className="propertySetLabel">Markup Text</span>
					{markups.every((markup) => MarkupsWithCustomText.includes(markup.type)) && (
						<>
							<Field
								label="Text"
								className="markupTextInput"
							>
								<ClickToEditInput
									value={modelData.textContent}
									onChange={(text) => updateMarkupProp(["content"], [text])}
									dataType={FieldDataType.MultiLineText}
								/>
								<div className="clear flexCenter">
									<div
										title="Clear Text"
										className="btn"
										onClick={() => updateMarkupProp(["content"], [""])}
										style={{backgroundImage: "url(src/assets/images/spaceviewer/clearText.svg)"}}
									/>
								</div>
							</Field>
							<Field label="Font Style">
								<FontStyleContainer
									fontStyleSettings={{isBold: firstItem.isBold, isItalic: firstItem.isItalic, isUnderlined: firstItem.isUnderlined}}
									onIsBoldChange={(isBold) => updateMarkupProp(["isBold"], [isBold])}
									onIsItalicChange={(isItalic) => updateMarkupProp(["isItalic"], [isItalic])}
									onIsUnderlinedChange={(isUnderLined) => updateMarkupProp(["isUnderlined"], [isUnderLined])}
								/>
							</Field>
							<Field label="Font Family">
								<SelectInput
									onChange={(fontFamily) => updateMarkupProp(["fontFamily"], [fontFamily])}
									options={TextGroupManager.supportedFontNames}
									selected={firstItem.fontFamily}
								/>
							</Field>
							<Field label="Font Size">
								<SizeChanger
									disabled={false}
									value={firstItem.fontSize}
									onChange={(size) => updateMarkupProp(["fontSize"], [size])}
								/>
							</Field>
							<Field label="Font Color">
								<ColorSelector
									title="Font Color"
									icon="text-color"
									color={modelData.text.fontColor}
									onColorChange={(color) => onColorChange(["fontColor"], [color])}
									eyeDropperProps={eyeDropperProps}
									horizontalAlignment={HorizontalAlignment.center}
									verticalAlignment={VerticalAlignment.topOuter}
								/>
							</Field>
						</>
					)}
					{markups.every((markup) => MarkupsWithCustomizableTextAlignment.includes(markup.type)) && (
						<Field label="Align Text">
							<TextAlignOptions
								verticalAlignment={modelData.text.verticalAlignment}
								horizontalAlignment={modelData.text.horizontalAlignment}
								onChange={(hA, vA) => updateMarkupProp(["horizontalAlignment", "verticalAlignment"], [hA, vA])}
							/>
						</Field>
					)}
				</div>
			)}
		</div>
	);
}

import {Vector2} from "three";
import type {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {ICornerLetter} from "./abstract/MarkupAB";
import {MarkupAB2D} from "./abstract/MarkupAB2D";

export class MarkupTriangle extends MarkupAB2D {
	protected _geometryData: PointDouble[] = [];

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const worldVertices = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);

		this._vertices.length = 0;
		this._2dVectors.length = 0;

		// Triangle has 3 corners: p0, p1, p2

		const p0 = worldVertices[0];
		const p1 = worldVertices[1];
		const p2 = {
			x: (worldVertices[2].x + worldVertices[3].x) / 2,
			y: (worldVertices[2].y + worldVertices[3].y) / 2,
		};

		this._vertices.push(p0.x);
		this._vertices.push(p0.y);
		this._vertices.push(0);
		this._2dVectors.push(new Vector2(p0.x, p0.y));

		this._vertices.push(p1.x);
		this._vertices.push(p1.y);
		this._vertices.push(0);
		this._2dVectors.push(new Vector2(p1.x, p1.y));

		this._vertices.push(p2.x);
		this._vertices.push(p2.y);
		this._vertices.push(0);
		this._2dVectors.push(new Vector2(p2.x, p2.y));

		this._vertices.push(p0.x);
		this._vertices.push(p0.y);
		this._vertices.push(0);

		(this._lineGeometry as LineGeometry).setPositions(this._vertices);

		this.updateMesh(this._2dVectors);
		if (isLocal) {
			this._selectionPart.update(this._vertices);
			this._textArea.update(this._vertices);
		}
		this.updateBoundingBox();
	}

	public get type() {
		return MarkupType.Triangle;
	}
}

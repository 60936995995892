import type {Matrix4, Object3D} from "three";
import {BufferAttribute, InstancedBufferGeometry, InstancedMesh} from "three";
import {BasicInstanceMaterial} from "../materials/BasicInstanceMaterial";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {Color} from "../../../../../../generated/api/base";
import type {IObjectWithText} from "./MSDF/TextUtils";

export interface IInstancedRectangleObject {
	matrix: Matrix4;
	color: number[];
	opacity: number;
	associatedObject: IObjectWithText;
}

export class InstancedRectangleManager {
	private _container: Object3D;
	private _geometry: InstancedBufferGeometry;
	private _instancedMesh: InstancedMesh;
	private _material: BasicInstanceMaterial;
	private readonly _renderOnTop: boolean;

	constructor(container: Object3D, renderOnTop: boolean) {
		this._container = container;
		this._renderOnTop = renderOnTop;
	}

	private createInstancedBufferGeometry(objectCount: number) {
		const geometry = new InstancedBufferGeometry();
		const positions = new Float32Array([-0.5, -0.5, 0, 0.5, -0.5, 0, 0.5, 0.5, 0, -0.5, 0.5, 0]);

		geometry.setAttribute("position", new BufferAttribute(positions, 3));
		geometry.setIndex(new BufferAttribute(new Uint8Array([0, 1, 2, 0, 2, 3]), 1));

		geometry.setAttribute("color", THREEUtils.createInstancedBufferAttribute(new Float32Array(objectCount * 3), 3));
		geometry.setAttribute("opacity", THREEUtils.createInstancedBufferAttribute(new Float32Array(objectCount * 1), 1));

		return geometry;
	}

	private updateTransformations(rectangles: IInstancedRectangleObject[]) {
		const geometry = this._geometry;

		if (!geometry) {
			return;
		}

		const attributes = geometry.attributes as {[name: string]: BufferAttribute};

		for (let i = 0; i < rectangles.length; ++i) {
			const rectangle = rectangles[i];

			this._instancedMesh.setMatrixAt(i, rectangle.matrix);

			THREEUtils.setAttributeXYZ(attributes.color, i, rectangle.color[0], rectangle.color[1], rectangle.color[2]);
			THREEUtils.setAttributeX(attributes.opacity, i, rectangle.opacity);
		}

		if (this._instancedMesh) {
			this._instancedMesh.instanceMatrix.needsUpdate = true;

			if (this._renderOnTop && this._instancedMesh.parent) {
				THREEUtils.renderToTop(this._instancedMesh);
			}
		}
	}

	public hide() {
		if (this._instancedMesh) {
			this._instancedMesh.visible = false;
		}
	}

	public show() {
		if (this._instancedMesh) {
			this._instancedMesh.visible = true;
		}
	}

	public setColor(color: Color) {
		THREEUtils.setColorForInstancedMesh(this._instancedMesh, color);
	}

	public update(rectangles: IInstancedRectangleObject[]) {
		if (!this._material) {
			this._material = new BasicInstanceMaterial();
		}
		if (!this._instancedMesh?.parent || (this._instancedMesh?.parent && rectangles.length !== this._geometry.instanceCount)) {
			const isVisible = this._instancedMesh?.visible !== false;

			this._instancedMesh?.parent?.remove(this._instancedMesh);

			if (this._geometry) {
				this._geometry.dispose();
			}

			if (this._instancedMesh) {
				this._instancedMesh.dispose();
			}

			if (rectangles.length > 0) {
				this._geometry = this.createInstancedBufferGeometry(rectangles.length);
				this._instancedMesh = new InstancedMesh(this._geometry, this._material, rectangles.length);
				this._instancedMesh.visible = isVisible;
				this._instancedMesh.name = `Rectangles - ${rectangles.map((r) => r.associatedObject.id).join("_")}`;

				if (this._renderOnTop) {
					THREEUtils.add(this._container, this._instancedMesh);
				} else {
					THREEUtils.addFront(this._container, this._instancedMesh);
				}
			}
		}

		this.updateTransformations(rectangles);
	}
}

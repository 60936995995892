import * as React from "react";
import {SelectInput} from "../select/SelectInput";
import {IconButton} from "../../button/IconButton";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {ReactUtils} from "../../../utils/ReactUtils";
import {DateUtils} from "../../../../utils/DateUtils";

interface IDatePickerProps {
	value: string; // date
	onChange: (value: string) => void;
}

interface IDatePickerState {
	currentYear: number;
	currentMonth: number;
}

export class DatePicker extends React.PureComponent<IDatePickerProps, IDatePickerState> {
	private _dateFormatter = new DateFormatter();

	constructor(props: IDatePickerProps) {
		super(props);

		const date = this.getSelectedDate();

		this.state = {
			currentYear: date.getFullYear(),
			currentMonth: date.getMonth(),
		};
	}

	private onPreviousMonthClick = () => {
		if (this.state.currentMonth > 0) {
			this.setState({
				currentMonth: this.state.currentMonth - 1,
			});
		} else {
			this.setState({
				currentYear: this.state.currentYear - 1,
				currentMonth: 11,
			});
		}
	};

	private onNextMonthClick = () => {
		if (this.state.currentMonth < 11) {
			this.setState({
				currentMonth: this.state.currentMonth + 1,
			});
		} else {
			this.setState({
				currentYear: this.state.currentYear + 1,
				currentMonth: 0,
			});
		}
	};

	private onClickDay = (day: number) => {
		const {onChange, value} = this.props;
		const {currentYear, currentMonth} = this.state;

		let date = DateUtils.parse(value);

		date.setMonth(currentMonth);
		date.setFullYear(currentYear);
		date.setDate(day + 1);

		onChange(DateUtils.stringify(date));
	};

	private getSelectedDate() {
		let result = new Date(this.props.value);

		if (isNaN(result.getTime())) {
			result = new Date();
		}
		return result;
	}

	private getYears = (yearNow: number) => {
		let yearsArray: number[] = [];

		for (let i = 0; i < 50; i++) {
			yearsArray.push(yearNow - 50 + i);
		}

		for (let i = 0; i < 50; i++) {
			yearsArray.push(yearNow + i);
		}

		return yearsArray;
	};

	private changeYear = (value: number) => {
		this.setState({currentYear: value});
	};

	// It needs because of "Today" button in DateTimeInput component
	public override componentDidUpdate(prevProps: IDatePickerProps) {
		if (this.props.value !== prevProps.value) {
			const date = DateUtils.parse(this.props.value);

			this.setState({
				currentYear: date.getFullYear(),
				currentMonth: date.getMonth(),
			});
		}
	}

	public override render() {
		const {currentYear, currentMonth} = this.state;

		const daysInMonth = DateFormatter.daysInMonth(currentYear, currentMonth);
		const navDate = new Date(currentYear, currentMonth, 1);
		const firstDay = navDate.getDay();
		const selected = DateUtils.parse(this.props.value);
		const years = this.getYears(currentYear) as number[];

		return (
			<div className="DatePicker">
				<div className="hbox">
					<div className="month">
						<IconButton
							icon="angle_left"
							onClick={this.onPreviousMonthClick}
						/>
						<div className="label">{this._dateFormatter.monthNames[currentMonth]}</div>
						<IconButton
							icon="angle_right"
							onClick={this.onNextMonthClick}
						/>
					</div>
					<div className="year">
						<SelectInput
							options={years}
							selected={currentYear}
							onChange={(option) => this.changeYear(option)}
							searchBar={false}
							noFixedPosition={true}
							onFocusLossForceBlur={true}
						/>
					</div>
				</div>
				<div className="weekdays">
					{this._dateFormatter.dayNames.map((day, index) => (
						<div
							className="weekday"
							key={index}
						>
							{day.charAt(0).toUpperCase()}
						</div>
					))}
				</div>
				<div className="days">
					{
						// Empty days before the first day
						ReactUtils.loop(firstDay, (index: number) => (
							<span key={index} />
						))
					}
					{ReactUtils.loop(daysInMonth, (date) => {
						const currentDate = new Date(currentYear, currentMonth, date + 1);
						const today = new Date();

						return (
							<span
								key={date}
								onClick={(e) => this.onClickDay(date)}
								className={ReactUtils.cls("day", {
									selected: DateUtils.sameDay(currentDate, selected),
									today: DateUtils.sameDay(currentDate, today),
								})}
							>
								{date + 1}
							</span>
						);
					})}
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {Constants} from "../../logic3d/Constants";
import type {IBasicPropsFor3DTweaking} from "../../../SpaceView";

interface IXyiconSizeChangerState {
	xyiconSize: number;
}

export class XyiconSizeChanger extends React.Component<IBasicPropsFor3DTweaking, IXyiconSizeChangerState> {
	constructor(props: IBasicPropsFor3DTweaking) {
		super(props);
		this.state = {
			xyiconSize: Constants.SIZE.XYICON,
		};
	}

	private onDecreaseClick = () => {
		this.applyNewSize(Math.round(this.state.xyiconSize / 1.1));
	};

	private onIncreaseClick = () => {
		this.applyNewSize(Math.round(this.state.xyiconSize * 1.1));
	};

	private applyNewSize = (newSize: number) => {
		const spaceViewRenderer = this.props.spaceViewRenderer;

		this.props.spaceViewRenderer.xyiconManager.setSize((newSize * spaceViewRenderer.spaceSize.width) / spaceViewRenderer.spaceResolution.x);
		this.setState({xyiconSize: newSize});
	};

	public override render() {
		return (
			<div className="XyiconSizeChanger">
				<div
					className="button"
					onClick={this.onDecreaseClick}
				>
					-
				</div>
				<div>{this.state.xyiconSize}</div>
				<div
					className="button"
					onClick={this.onIncreaseClick}
				>
					+
				</div>
			</div>
		);
	}
}

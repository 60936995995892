import {useState} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {fontSize} from "../../../styles/styles";

interface IMultiLineLabelProps {
	readonly value: string;
	readonly divRef: any;
	readonly height: number;
	readonly onLabelStateChange?: (value: boolean) => void;
}

export const MultiLineLabelV5 = (props: IMultiLineLabelProps) => {
	const {value, divRef, height, onLabelStateChange} = props;
	const [isExtended, setIsExtended] = useState<boolean>(false);

	const onClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		onLabelStateChange?.(!isExtended);
		setIsExtended((e) => !e);
	};

	const rows = height / 16; // 16 is the standard lineheight

	return (
		value && (
			<MultiLineLabelStyled className={ReactUtils.cls("MultiLineLabel", {extended: isExtended})}>
				<div
					className="content"
					dangerouslySetInnerHTML={{__html: value}}
					ref={divRef}
				/>
				{rows > 5 && <MoreButtonStyled onClick={onClick}>{`See ${isExtended ? "Less" : "More"}...`}</MoreButtonStyled>}
			</MultiLineLabelStyled>
		)
	);
};

const MultiLineLabelStyled = styled.div`
	.content {
		white-space: break-spaces;
		word-break: break-word;
		max-height: 82px;
		position: relative;
		overflow: hidden;
		line-height: 16px;
	}

	&.extended {
		.content {
			max-height: 400px;
			overflow-y: auto;
		}
	}
`;

export const MoreButtonStyled = styled.div`
	margin-left: -4px;
	margin-top: 4px;
	font-size: ${fontSize.sm};
	color: ${colorPalette.primary.c500Primary};
	cursor: pointer;
`;

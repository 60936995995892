import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import {ClickToEditInput} from "../../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {FieldDataType} from "../../../../../../../generated/api/base";
import type {AppState} from "../../../../../../../data/state/AppState";
import {featureTitles} from "../../../../../../../data/state/AppStateConstants";
import type {Report} from "../../../../../../../data/models/Report";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";

interface IReportDefaultFieldsProps {
	item: Report;
	appState?: AppState;
	transport?: TransportLayer;
}

interface IReportDefaultFieldsState {
	updatingName: boolean;
	updatingDescription: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class ReportDefaultFields extends React.Component<IReportDefaultFieldsProps, IReportDefaultFieldsState> {
	constructor(props: IReportDefaultFieldsProps) {
		super(props);
		this.state = {
			updatingName: false,
			updatingDescription: false,
		};
	}

	private onNameChange = async (value: string) => {
		const {item, transport} = this.props;

		item.name = value;

		this.setState({updatingName: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateReport(item), this.props.appState.app.notificationContainer);
		this.setState({updatingName: false});
	};

	private onDescriptionChange = async (value: string) => {
		const {item, transport} = this.props;

		item.description = value;

		this.setState({updatingDescription: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateReport(item), this.props.appState.app.notificationContainer);
		this.setState({updatingDescription: false});
	};

	public override render() {
		const {item, appState} = this.props;
		const {updatingName, updatingDescription} = this.state;

		return (
			<>
				<Field label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</Field>
				<Field label="Name">
					<ClickToEditInput
						value={item.name}
						onChange={this.onNameChange}
						dataType={FieldDataType.SingleLineText}
						updating={updatingName}
						noButtons={true}
					/>
				</Field>
				<Field label="Category">{item.category}</Field>
				<Field label="Module">{featureTitles[item.reportFeature]}</Field>
				<Field label="Description">
					<ClickToEditInput
						dataType={FieldDataType.MultiLineText}
						onChange={this.onDescriptionChange}
						value={item.description}
						updating={updatingDescription}
					/>
				</Field>
			</>
		);
	}
}

import styled from "styled-components";
import type {MouseEvent} from "react";
import {cloneElement, useRef, useState} from "react";
import type {IToolConfig, SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {Color} from "../../../../../generated/api/base";
import {HTMLUtils} from "../../../../../utils/HTML/HTMLUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {useClickOutside} from "../../../utils";
import {ToolButtonV5, type IToolButtonProps} from "./ToolButtonV5";

interface ISharedToolGroupProps {
	readonly tools: {
		[id in SpaceTool]: IToolConfig;
	};
	readonly activeTool: SpaceTool;
	readonly chosenTool: SpaceTool; // the subtool that is currently chosen within the toolgroup
	readonly children: React.ReactElement<IToolButtonProps>[];
	readonly onClick: (id: SpaceTool) => void;
	readonly color?: Color;
}

/**
 * Represents a ToolGroup, but it's chosen tool is a property, so it can be synced across the instances
 */

export const SharedToolGroupV5 = (props: ISharedToolGroupProps) => {
	const _element = useRef<HTMLDivElement>();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onClick = (event: MouseEvent<HTMLDivElement>) => {
		const clickedOnSubButton = HTMLUtils.doesAncestorHaveClass(event.target as HTMLElement, "subMenu");

		if (clickedOnSubButton) {
			return;
		} else {
			setIsOpen((open) => !open);
			props.onClick(props.chosenTool);
		}
	};

	const onClose = () => {
		setIsOpen(false);
	};

	useClickOutside([_element], onClose);

	const children = props.children as React.ReactElement<IToolButtonProps>[];

	const subMenuItems = children.map((container: React.ReactElement<IToolButtonProps>, index: number) => {
		const onClick = () => {
			container.props.onClick();
			setIsOpen(false);
		};

		return cloneElement(container, {key: container.props.title, onClick: onClick});
	});

	const chosenTool = props.tools[props.chosenTool];

	return (
		<SharedToolGroupStyled
			ref={_element}
			title={chosenTool.title}
			onClick={onClick}
		>
			<ToolButtonV5
				isActive={props.activeTool === props.chosenTool}
				icon={props.chosenTool}
				color={props.color}
				title={chosenTool.title}
			/>
			<SubMenuStyled className={ReactUtils.cls("subMenu", {open: isOpen})}>{subMenuItems}</SubMenuStyled>
		</SharedToolGroupStyled>
	);
};

const SubMenuStyled = styled.div`
	position: absolute;
	left: 40px;
	top: -8px;
	display: none;
	background-color: ${colorPalette.gray.c950};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	border-radius: 0 ${radius.md} ${radius.md} 0;
	padding: 8px;

	&.open {
		display: flex;
		flex-direction: row;
	}
`;

const SharedToolGroupStyled = styled.div`
	position: relative;
`;

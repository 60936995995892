import type {Type} from "../../../../data/models/Type";
import {FieldDataTypes} from "../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../generated/api/base";

interface ITypeLabelProps {
	value: Type;
	dataTypeSettings?: any;
}

export const TypeLabelV5 = (props: ITypeLabelProps) => {
	const {value} = props;

	// Converts type id -> type name
	const formatted = FieldDataTypes.map[FieldDataType.Type].formatter(value);

	return formatted || "";
};

import type * as React from "react";

export class Functions {
	// eslint-disable-next-line no-empty-function
	public static emptyFunction() {}

	public static stopPropagation(event: React.MouseEvent | React.TouchEvent) {
		event.stopPropagation();
	}

	public static stopImmediatePropagation(event: MouseEvent | TouchEvent) {
		event.stopImmediatePropagation();
	}

	public static preventDefault(event: Event | React.MouseEvent | React.TouchEvent) {
		event.preventDefault();
	}
}

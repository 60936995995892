import styled from "styled-components";
import {colorPalette} from "../../styles/colorPalette";
import {baseDistance} from "../../styles/styles";

export const SpecialFilterField = styled.div`
	display: flex;
	gap: ${baseDistance.sm};

	.SelectInput {
		background: ${colorPalette.white};
	}
`;

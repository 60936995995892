import {MarkupType} from "../../../../../../../generated/api/base";

export const curveHeight = 10; // px
export const curvePeriod = 2 * curveHeight;
export const dashSize = 30; // px
export const gapSize = 15; // px
export const dottedLineDashSize = 4;
export const dottedLineGapSize = 4;
export const highlightRadius: number = 40; // px
export const highlightOpacity: number = 0.2;

export const MarkupsWithCustomizableColor: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Arrow,
	MarkupType.Bidirectional_Arrow,
	MarkupType.Line,
	MarkupType.Dashed_Line,
	MarkupType.Pencil_Drawing,
	MarkupType.Highlight_Drawing,
	MarkupType.Rectangle,
	MarkupType.Ellipse,
	MarkupType.Triangle,
	MarkupType.Cross,
	MarkupType.Linear_Distance,
	MarkupType.Rectangle_Area,
	MarkupType.Nonlinear_Distance,
	MarkupType.Irregular_Area,
	MarkupType.Callout,
];

export const MarkupsWithCustomText: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Callout,
	MarkupType.Text_Box,
	MarkupType.Arrow,
	MarkupType.Bidirectional_Arrow,
	MarkupType.Line,
	MarkupType.Dashed_Line,
	MarkupType.Rectangle,
	MarkupType.Ellipse,
	MarkupType.Triangle,
];

export const MarkupsWithTextOffset: MarkupType[] = [MarkupType.Arrow, MarkupType.Bidirectional_Arrow, MarkupType.Line, MarkupType.Dashed_Line];

export const MarkupsWithCustomizableTextAlignment: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Rectangle,
	MarkupType.Ellipse,
	MarkupType.Triangle,
	MarkupType.Callout,
];

export const MarkupsWithIndependentTextSize: MarkupType[] = [
	MarkupType.Arrow,
	MarkupType.Bidirectional_Arrow,
	MarkupType.Line,
	MarkupType.Dashed_Line,
];

export const MarkupsWithCustomizableFillOpacity: MarkupType[] = [
	MarkupType.Cloud,
	MarkupType.Rectangle,
	MarkupType.Rectangle_Area,
	MarkupType.Ellipse,
	MarkupType.Irregular_Area,
	MarkupType.Triangle,
	MarkupType.Callout,
];

export const MarkupsWithChangeableLineThickness: MarkupType[] = [
	MarkupType.Line,
	MarkupType.Dashed_Line,
	MarkupType.Arrow,
	MarkupType.Bidirectional_Arrow,
	MarkupType.Callout,
];

export const MarkupsWithArrowHeads: MarkupType[] = [MarkupType.Arrow, MarkupType.Bidirectional_Arrow, MarkupType.Callout];

import {computed, makeObservable, observable} from "mobx";
import type {IModel} from "./Model";

export interface ISpaceVersionData {
	spaceVersionID: string;
	name: string;
	date: Date;
}

export class SpaceVersion implements IModel {
	@observable
	private _data: ISpaceVersionData;

	constructor(data: ISpaceVersionData) {
		makeObservable(this);
		this._data = data;
	}

	public get id() {
		return this._data.spaceVersionID;
	}

	public set name(newName: string) {
		this._data.name = newName;
	}

	public set date(newDate: Date) {
		this._data.date = newDate;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	@computed
	public get date() {
		return new Date(this._data.date);
	}

	@computed
	public get data() {
		return this._data;
	}
}

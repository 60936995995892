import {forwardRef, useImperativeHandle, useState} from "react";
import type {IMassInputV5Props} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import type {IResponse} from "../../../../../data/TransportLayer";
import type {IModel} from "../../../../../data/models/Model";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {FieldInputsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {SingleLineInputV5} from "../SingleLineInputV5";
import {useAppStore} from "../../../../../StateManager";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {MassFieldInputStyled} from "./MassFieldInputStyled";
import {OptionSelectorInputStyled} from "./MassFieldInputV5";

type ISelectOptionId = "append" | "replace";

interface ISelectOption {
	id: ISelectOptionId;
	label: string;
}

export const MassFieldMultiLineInputV5 = forwardRef((props: IMassInputV5Props, ref) => {
	const _options: ISelectOption[] = [
		{
			id: "append",
			label: "Append Text",
		},
		{
			id: "replace",
			label: "Replace Text",
		},
	];

	const appState = useAppStore((store) => store.appState);

	const [selectedOption, setSelectedOption] = useState<ISelectOptionId>("replace");
	const [inputValue, setInputValue] = useState<string>("");

	const onApply = async (items: IModel[]) => {
		const {field, onClose} = props;
		const itemsToUpdate = items.filter((item) => appState.actions.hasOwnFieldValue(item, field.refId));
		const confirmed = await ConfirmWindowV5.open(
			`Are you sure you want to update ${itemsToUpdate.length} item${itemsToUpdate.length === 1 ? "" : "s"}?`,
			"Confirm Update",
			{ok: "Yes", cancel: "Cancel"},
		);

		if (confirmed) {
			if (selectedOption === "append") {
				const promises: Promise<IResponse>[] = [];

				for (const item of itemsToUpdate) {
					let value = (appState.actions.getOwnFieldValue(item, field.refId) || "") as string;

					value += inputValue;
					promises.push(
						appState.actions.updateFields([item], {
							[field.refId]: value,
						}),
					);
				}

				await TimeUtils.waitUpdates(promises, itemsToUpdate, appState.app.notificationContainer);
			} else if (selectedOption === "replace") {
				await TimeUtils.waitUpdate(
					appState.actions.updateMassFields(itemsToUpdate, {
						[field.refId]: inputValue,
					}),
					appState.app.notificationContainer,
				);
			}
			onClose();
		}
	};

	const onOptionChange = (option: ISelectOption) => {
		setSelectedOption(option.id);
	};

	const onInputChange = (value: any) => {
		setInputValue(value);
	};

	const getEditInput = () => {
		const {field} = props;

		let InputComponent = FieldInputsV5[field.dataType];

		InputComponent = InputComponent || SingleLineInputV5;

		return (
			<InputComponent
				value={inputValue}
				dataTypeSettings={field.dataTypeSettings}
				onChange={onInputChange}
				onInput={onInputChange}
			/>
		);
	};

	useImperativeHandle(ref, () => ({
		onApply,
	}));

	{
		const {items, field} = props;
		const actions = appState.actions;

		let allValuesMatch = true;

		let first = true;
		let firstValue;

		for (const item of items) {
			const hasValue = actions.hasOwnFieldValue(item, field.refId);

			if (!hasValue) {
				continue;
			}

			const value = actions.renderValue(item, field.refId);

			if (first) {
				firstValue = value;
				first = false;
			}
			if (value !== firstValue) {
				allValuesMatch = false;
			}
		}

		return (
			<MassFieldInputStyled className="MassFieldInput">
				<div className="container">
					<OptionSelectorInputStyled>
						<SelectInputV5
							options={_options}
							render={(option) => option.label}
							selected={_options.find((option) => option.id === selectedOption)}
							onChange={onOptionChange}
						/>
					</OptionSelectorInputStyled>
					{getEditInput()}
				</div>
			</MassFieldInputStyled>
		);
	}
});

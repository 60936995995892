import type {ISingleSelectFieldSettingsDefinition} from "../../../data/models/field/datatypes/SingleSelect";
import type {IFieldDataTypeSettingsProps} from "../../modules/settings/modules/field/datatypes/IFieldDataTypeSettingsProps";
import {FieldV5} from "./FieldV5";
import {ListBuilderInputV5} from "./ListBuilderInputV5";

export const SingleSelectFieldSettingsV5 = (props: IFieldDataTypeSettingsProps) => {
	const {field, onChange} = props;
	const settings = field.dataTypeSettings as ISingleSelectFieldSettingsDefinition;

	return (
		<FieldV5 label="Items In Dropdown">
			<ListBuilderInputV5
				list={settings.choiceList}
				onChange={(value) => {
					settings.choiceList = value;
					onChange();
				}}
			/>
		</FieldV5>
	);
};

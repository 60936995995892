import * as React from "react";
import {Functions} from "../../../../utils/function/Functions";
import {InfoButton} from "../../../modules/abstract/common/infobutton/InfoButton";
import {ToggleSwitch} from "./ToggleSwitch";

interface IToggleSwitchFieldProps {
	value: boolean;
	onChange: (value: boolean) => void;
	label: string;
	disabled?: boolean;
	labelFirst?: boolean; // changes the order of toggler and label
	classNames?: string;
	divRef?: React.RefObject<HTMLDivElement>;
	bubbleText?: string;
	noBooleanLabel?: boolean;
	onMouseOver?: () => void;
	onMouseLeave?: () => void;
}

export class ToggleSwitchField extends React.Component<IToggleSwitchFieldProps> {
	private onChange = (value: boolean) => {
		this.props.onChange(value);
	};

	private onLabelClick = (event: React.MouseEvent) => {
		this.props.onChange(!this.props.value);
	};

	public override render() {
		const {value, label, classNames, disabled, divRef, bubbleText, noBooleanLabel, onMouseLeave, onMouseOver} = this.props;

		let style: React.CSSProperties = {};

		if (this.props.labelFirst) {
			style.flexDirection = "row-reverse";
			style.justifyContent = "flex-end";
		}

		return (
			<div
				ref={divRef}
				className={`ToggleSwitchField ${classNames || ""}`}
				style={style}
				onMouseOver={onMouseOver ? onMouseOver : Functions.emptyFunction}
				onMouseLeave={onMouseLeave ? onMouseLeave : Functions.emptyFunction}
			>
				<ToggleSwitch
					value={value}
					onChange={this.onChange}
					disabled={disabled ?? false}
					noBooleanLabel={noBooleanLabel}
				/>
				<label
					className="label"
					onClick={this.onLabelClick}
				>
					{label}
				</label>
				{bubbleText && <InfoButton bubbleText={bubbleText} />}
			</div>
		);
	}
}

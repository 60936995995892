import type {LinkBasicDto} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import type {IModel} from "./Model";

export class Link implements IModel {
	private _data: LinkBasicDto;
	public readonly ownFeature: XyiconFeature = XyiconFeature.Link;

	constructor(data: LinkBasicDto) {
		this._data = data;
	}

	public get id() {
		return this._data.linkID;
	}

	public get fromPortfolioId() {
		return this._data.fromPortfolioID;
	}

	public get toPortfolioId() {
		return this._data.toPortfolioID;
	}

	public get fromObjectId() {
		return this._data.fromObjectID;
	}

	public get toObjectId() {
		return this._data.toObjectID;
	}

	public get fromPortId() {
		return this._data.fromPortID;
	}

	public get toPortId() {
		return this._data.toPortID;
	}

	public get isEmbedded() {
		return this._data.isEmbedded;
	}

	public get fromType() {
		return this._data.fromLinkType;
	}

	public get toType() {
		return this._data.toLinkType;
	}

	public get data() {
		return this._data;
	}
}

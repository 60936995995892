import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {HTMLUtils} from "../../../utils/HTML/HTMLUtils";

/**
 * You should use one of the descendants of this class: WarningWindow, ConfirmWindow, SpaceExportWindow, or PromptWindow
 * Example: const confirmed = await ConfirmWindow.open("Are you sure you want to delete this item?");
 */

export interface IPopupWindowConfigV5 {
	backdrop?: boolean;
	ok?: string; // The label of the OK button
	cancel?: string; // The label of the Cancel button
	closeBtn?: boolean; // close button in the top right corner
	parentElement?: HTMLElement;
	disableButtonOnEmptyInput?: boolean;
}

interface IPopupWindowProps {
	title: string;
	message: string;
	config?: IPopupWindowConfigV5;
}

export abstract class PopupWindowV5<T> {
	private _isOkButtonEnabled: boolean = true;
	private _container: HTMLDivElement = document.createElement("div");
	protected _okButton: HTMLDivElement = document.createElement("div");
	private readonly _props: IPopupWindowProps;
	protected readonly _config: IPopupWindowConfigV5;

	private static _numberOfOpenPopups: number = 0;
	protected static readonly _defaultConfig: IPopupWindowConfigV5 = {
		backdrop: true,
		ok: "Yes",
		cancel: "No",
		closeBtn: true,
		parentElement: document.body,
	};
	protected abstract _okValue: T | T[]; // the return value when the user clicks "ok"
	protected abstract _cancelValue: T; // the return value when the user clicks "cancel"
	protected _additionalElements: HTMLElement;
	protected _exportInputs: HTMLElement[] = [];
	protected resolve: (isOk?: T | T[]) => void;

	constructor(props: IPopupWindowProps) {
		this._props = props;
		this._config = ObjectUtils.mergeConfig(PopupWindowV5._defaultConfig, props.config || {});
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				if (this._isOkButtonEnabled) {
					this.onOkClick();
				}
				break;
			case KeyboardListener.KEY_ESCAPE:
				this.onCloseClick();
				break;
		}
	};

	protected close() {
		KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
		HTMLUtils.detach(this._container);

		PopupWindowV5._numberOfOpenPopups--;
	}

	private onCloseClick = () => {
		this.close();
		this.resolve(null);
	};

	private onCancelClick = () => {
		this.close();
		this.resolve(this._cancelValue);
	};

	private onOkClick = () => {
		if (this._isOkButtonEnabled) {
			this.close();
			this.resolve(this._okValue);
		}
	};

	protected enableOkButton() {
		this._isOkButtonEnabled = true;
		this._okButton.classList.remove("disabled");
	}

	protected disableOkButton() {
		this._isOkButtonEnabled = false;
		this._okButton.classList.add("disabled");
	}

	protected open() {
		KeyboardListener.getInstance().signals.down.add(this.onKeyDown);

		this.draw();

		return new Promise<T | T[]>((resolve, reject) => {
			this.resolve = resolve;
		});
	}

	private draw() {
		this._container.className = "popupBackdropV5 flexCenter";
		if (this._config.backdrop) {
			this._container.onclick = this.onCloseClick;
		}

		const popupWindow = document.createElement("div");

		popupWindow.className = "PopupWindowV5";

		const title = document.createElement("div");

		if (this._config.ok === "Delete") {
			title.className = "DeleteTitle title hbox alignCenter";
		} else if (this._props.title === "Warning") {
			title.className = "WarningTitle title hbox alignCenter";
		} else {
			title.className = "title hbox alignCenter";
		}

		title.textContent = this._props.title;
		popupWindow.appendChild(title);

		const message = document.createElement("div");

		message.className = "message";
		message.innerHTML = this._props.message;
		popupWindow.appendChild(message);

		if (this._additionalElements) {
			popupWindow.appendChild(this._additionalElements);
		}

		if (this._exportInputs.length > 0) {
			const inputContainer = document.createElement("div");

			inputContainer.className = "InputContainer";
			popupWindow.appendChild(inputContainer);
			this._exportInputs.forEach((el) => inputContainer.appendChild(el));
		}

		const buttonContainer = document.createElement("div");

		buttonContainer.className = "bottom hbox flexCenter justifyEnd";

		if (this._config.closeBtn) {
			const closeBtn = document.createElement("div");

			closeBtn.className = "closeBtn";
			closeBtn.innerHTML = "<svg class='icon'><use xlink:href='#icon-close'></use></svg>";
			closeBtn.onclick = this.onCloseClick;
			title.appendChild(closeBtn);
		}

		if (this._config.cancel) {
			const cancelButton = document.createElement("div");

			cancelButton.className = "Button";
			this._okButton.setAttribute("data-cy", "CancelButton");
			cancelButton.textContent = this._config.cancel;
			cancelButton.onclick = this.onCancelClick;
			buttonContainer.appendChild(cancelButton);
		}

		this._okButton.textContent = this._config.ok;
		this._okButton.className = this._okButton.textContent === "Delete" ? "DeleteButton primary Button" : "primary Button";
		this._okButton.setAttribute("data-cy", "OkButton");
		this._okButton.onclick = this.onOkClick;

		if (this._okButton.textContent === "Delete") {
			this._okButton.innerHTML = `
			<span class="deleteIcon">
				<svg class='icon'><use xlink:href='#icon-delete'></use></svg>
			</span>
			Delete
			`;
		}

		if (!this._isOkButtonEnabled) {
			this._okButton.classList.add("disabled");
		}

		buttonContainer.appendChild(this._okButton);

		popupWindow.appendChild(buttonContainer);

		this._container.appendChild(popupWindow);

		this._config.parentElement.appendChild(this._container);

		PopupWindowV5._numberOfOpenPopups++;
	}

	public static get numberOfOpenPopups() {
		return PopupWindowV5._numberOfOpenPopups;
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {Navigation} from "../../../../../Navigation";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {OptionsButton} from "../../../../widgets/button/options/OptionsButton";
import {TextInput} from "../../../../widgets/input/text/TextInput";

interface IPermissionSetItemProps {
	id: string;
	selected: boolean;
	appState?: AppState;
	transport?: TransportLayer;
	navigation?: Navigation;
	onSelect: (id: string) => void;
	onDelete: (id: string) => void;
	onSave: (id: string, name: string) => void;
}

interface IPermissionSetItemState {
	editMode: boolean;
	optionsOpen: boolean;
	errorMessage: string;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class PermissionSetItem extends React.Component<IPermissionSetItemProps, IPermissionSetItemState> {
	private _permissionSetTempName = "";
	private _prevName = "";
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IPermissionSetItemProps) {
		super(props);
		this.state = {
			editMode: false,
			optionsOpen: false,
			errorMessage: "",
		};
	}

	private reset() {
		const {appState, id} = this.props;

		this._permissionSetTempName = "";
		this._prevName = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet).name;
		this.setState({editMode: false});
	}

	private onBlur = () => {
		const name = this.props.appState.actions.getFeatureItemById<PermissionSet>(this.props.id, XyiconFeature.PermissionSet).name;

		if (!this.state.errorMessage) {
			if (this._permissionSetTempName !== name) {
				this.props.onSave(this.props.id, this._permissionSetTempName);
			}
		} else {
			this.props.onSave(this.props.id, this._prevName);
			this.setState({errorMessage: ""});
		}

		this.reset();
	};

	private onRenameClick = () => {
		const {id, appState} = this.props;

		this.setState({editMode: true});
		this._permissionSetTempName = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet).name;
		this._prevName = this._permissionSetTempName;
	};

	private onRename = (value: string) => {
		const {appState, id} = this.props;
		const isValid = appState.actions.isPermissionSetNameValid(value, id);
		let errorMessage = "";

		if (!isValid) {
			errorMessage = "Name needs to be unique!";
		}

		if (!value) {
			errorMessage = "Name cannot be empty!";
		}

		this.setState({errorMessage});
		this._permissionSetTempName = value;
	};

	private getOptions = () => {
		const {onDelete, id} = this.props;
		const options = [];

		options.push(
			{
				label: "Rename",
				onSelect: () => this.onRenameClick(),
			},
			{
				label: "Delete",
				onSelect: () => onDelete(id),
			},
		);

		return options;
	};

	private onSelect = () => {
		this.props.onSelect(this.props.id);
	};

	public override render() {
		const {appState, id, selected} = this.props;
		const {editMode, errorMessage} = this.state;
		const permissionSet = appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("PermissionSetItem hbox listItem", {selected, editMode})}
				onClick={this.onSelect}
			>
				<div className="ItemNameInput vbox flex_1">
					{editMode ? (
						<TextInput
							value={this._permissionSetTempName}
							onInput={this.onRename}
							autoFocus={true}
							onBlur={this.onBlur}
							errorMessage={errorMessage}
							errorMessageTop={-22}
						/>
					) : (
						<div className="name">{permissionSet?.name}</div>
					)}
					<span className="description">{appState.actions.renderName(permissionSet?.lastModifiedBy)}</span>
				</div>
				<OptionsButton
					onOpenChange={(open) => {
						this.setState({optionsOpen: open});
					}}
					options={this.getOptions()}
				/>
			</div>
		);
	}
}

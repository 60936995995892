import type {Vector3} from "three";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {PointerDetector} from "../../../../../../utils/interaction/PointerDetector";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {PanCameraControls} from "./PanCameraControls";
import {Tool} from "./tools/Tool";
import {LinkManager} from "./LinkManager";

/**
 * This is kind of an abstraction for all the tools.
 * The activeTool will be the currently selected tool, while preserving cameraControls as a "permanent active" tool
 */

export class ToolManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _pointerDetector: PointerDetector;
	private _cameraControls: PanCameraControls;
	private _enabled: boolean = false;
	private _linkManager: LinkManager;

	public activeTool: Tool;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._linkManager = new LinkManager(this._spaceViewRenderer);
		this._pointerDetector = new PointerDetector({
			element: this._spaceViewRenderer.domElement,
			maxPointers: 2,
			disableContextMenu: true,
			ignoreMiddleButton: false,
			ignoreRightButton: false,
			autoEnable: false,
		});

		this._cameraControls = new PanCameraControls(this._spaceViewRenderer, this._pointerDetector);
	}

	public init(canvasWidth: number, canvasHeight: number, spaceOffset: Vector3, numberOfZoomLevels: number) {
		Tool.reset();
		this._cameraControls.setSize(canvasWidth, canvasHeight, spaceOffset, numberOfZoomLevels);
		this._pointerDetector.config.element = this._spaceViewRenderer.domElement;
		if (!this._enabled) {
			KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
			this._pointerDetector.enable();
			this._enabled = true;
		}
	}

	public unmount() {
		this.activeTool.deactivate();
		this.deactivateCameraControls();
		if (this._enabled) {
			KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
			this._pointerDetector.dispose();
			this._enabled = false;
		}
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this._linkManager.disableLinkMode();
				break;
		}
	};

	public resize(canvasWidth: number, canvasHeight: number) {
		this._cameraControls.resize(canvasWidth, canvasHeight);
	}

	public activateCameraControls() {
		this._cameraControls.activate();
	}

	public deactivateCameraControls() {
		this._cameraControls.deactivate();
	}

	public update() {
		this._cameraControls.update();
	}

	public get isInLinkMode() {
		return this._linkManager.isInLinkMode;
	}

	public get pointerDetector() {
		return this._pointerDetector;
	}

	public get cameraControls() {
		return this._cameraControls;
	}

	public get linkManager() {
		return this._linkManager;
	}
}

import {Constants} from "../Constants";
import {LeaderLine} from "../elements3d/LeaderLine";
import type {Xyicon3D} from "../elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../utils/THREEUtils";

export class LinkLineManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _lines: LeaderLine[] = [];
	private _currentXyiconIds: Set<string> = new Set<string>();
	public readonly lineColor: string = `#${Constants.COLORS.PICTON_BLUE}`;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public destroyAll() {
		for (const line of this._lines) {
			line.destroy();
		}

		this._lines.length = 0;
		this._currentXyiconIds.clear();
		this._spaceViewRenderer.needsRender = true;
	}

	public update(lineSets: {fromXyicon: Xyicon3D; toXyicons: Xyicon3D[]}[]) {
		this.destroyAll();

		for (const lineSet of lineSets) {
			const {fromXyicon, toXyicons} = lineSet;

			this._currentXyiconIds.add(fromXyicon.modelData.id);
			toXyicons.forEach((x) => this._currentXyiconIds.add(x.modelData.id));
			const fromPosition = fromXyicon.position;

			for (const toXyicon of toXyicons) {
				const toPosition = toXyicon.position;
				const dx = toPosition.x - fromPosition.x;
				const dy = toPosition.y - fromPosition.y;
				const line = new LeaderLine(
					this._spaceViewRenderer,
					fromXyicon.group,
					dx,
					dy,
					fromXyicon.group,
					parseInt(this.lineColor.replace("#", "0x")),
					false,
				);

				line.line.rotateZ(-fromXyicon.orientation);
				THREEUtils.updateMatrices(line.line);
				this._lines.push(line);
				if (line.isVisible) {
					fromXyicon.addGroupToSceneIfNeeded();
				}
			}
		}
	}

	public get currentXyiconIds() {
		return this._currentXyiconIds;
	}

	public get lines() {
		return this._lines;
	}
}

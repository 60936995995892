import * as React from "react";
import {TransparencySlider} from "../TransparencySlider";
import type {IPropsForEyeDropper} from "../EyeDropper";
import {EyeDropper} from "../EyeDropper";
import type {Color} from "../../../../../../../generated/api/base";
import {BasicColors} from "../../../../../../../utils/BasicColors";
import {ColorPalette} from "./ColorPalette";

interface IBasicColorPanelProps {
	color: Color;
	lastUsedColors: Color[];
	onColorChange: (newColor: Color) => void;
	isTransparencyEnabled: boolean;
	eyeDropperProps?: IPropsForEyeDropper;
}

export class BasicColorPanel extends React.Component<IBasicColorPanelProps> {
	private _colorsOfPalette: Color[] = BasicColors.getBasicColors(this.props.isTransparencyEnabled);

	private onColorClick = (newColor: Color) => {
		this.props.onColorChange({
			hex: newColor.hex,
			transparency: newColor.transparency,
		});
	};

	private onColorChange = (newHexValue: string) => {
		this.props.onColorChange({
			hex: newHexValue,
			transparency: 0,
		});
	};

	private onTransparencyChange = (newValue: number) => {
		this.props.onColorChange({
			hex: this.props.color.hex,
			transparency: newValue,
		});
	};

	public override render() {
		const {color, eyeDropperProps, isTransparencyEnabled, lastUsedColors} = this.props;

		return (
			<div className="ColorPanelBasic">
				<div className="hbox alignCenter justifySpaceBetween">
					{eyeDropperProps && (
						<EyeDropper
							canvas={eyeDropperProps.canvas}
							elementForPointerEvents={eyeDropperProps.elementForPointerEvents}
							textureNeedsUpdateSignal={eyeDropperProps.textureNeedsUpdateSignal}
							onActivateStateChanged={eyeDropperProps.onActivateStateChanged}
							onColorClick={this.onColorChange}
						/>
					)}
					<TransparencySlider
						onValueChange={this.onTransparencyChange}
						value={color.transparency}
						color={color.hex}
						disabled={!isTransparencyEnabled}
						sliderWidth={!!eyeDropperProps ? 125 : 160}
					/>
				</div>
				<ColorPalette
					label="Last Used"
					activeColor={color}
					colors={lastUsedColors}
					isTransparencyEnabled={isTransparencyEnabled}
					onColorClick={this.onColorClick}
				/>
				<ColorPalette
					label="Palette"
					activeColor={color}
					colors={this._colorsOfPalette}
					isTransparencyEnabled={isTransparencyEnabled}
					onColorClick={this.onColorClick}
				/>
			</div>
		);
	}
}

import type {Object3D, InstancedBufferGeometry, RawShaderMaterial, BufferAttribute} from "three";
import {InstancedMesh} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {IXyiconMinimumData} from "../../../../../../../data/models/Xyicon";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {XyiconManager} from "./XyiconManager";

type AttributeKey = "color" | "opacity" | "isGrayScaled" | "isFlipped" | "uvOffset";
interface IAttributeProps {
	size: number;
	defaultValue: number;
}

type AttributeDataType = {
	[key in AttributeKey]: IAttributeProps;
};

export abstract class InstancedXyiconManager {
	protected _spaceViewRenderer: SpaceViewRenderer;
	protected _xyiconManager: XyiconManager;
	protected _container: Object3D;
	protected _attributeData: AttributeDataType = {
		color: {
			size: 3,
			defaultValue: 1,
		},
		opacity: {
			size: 1,
			defaultValue: 1,
		},
		isGrayScaled: {
			size: 1,
			defaultValue: 0,
		},
		isFlipped: {
			size: 2, // x and y
			defaultValue: 0,
		},
		uvOffset: {
			size: 2,
			defaultValue: 0,
		},
	};

	constructor(spaceViewRenderer: SpaceViewRenderer, xyiconManager: XyiconManager, container: Object3D) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._xyiconManager = xyiconManager;
		this._container = container;
	}

	protected initAttributesOfNewInstance(instancedMesh: InstancedMesh, instanceId: number) {
		const attributes = (instancedMesh.geometry as InstancedBufferGeometry).attributes as {[key in keyof typeof this._attributeData]: BufferAttribute};

		THREEUtils.setAttributeXYZ(attributes.color, instanceId, 1, 1, 1);
		THREEUtils.setAttributeX(attributes.opacity, instanceId, 1);
		THREEUtils.setAttributeX(attributes.isGrayScaled, instanceId, 0);
		THREEUtils.setAttributeXY(attributes.isFlipped, instanceId, 0, 0);
		THREEUtils.setAttributeXY(attributes.uvOffset, instanceId, 0, 0);

		return attributes;
	}

	protected initInstancedMeshAttributes(instancedMesh: InstancedMesh) {
		const count = instancedMesh.instanceMatrix.array.length / 16;
		const geometry = instancedMesh.geometry as InstancedBufferGeometry;
		const attributeData = this._attributeData;

		for (const key in attributeData) {
			const typedKey = key as keyof typeof attributeData;
			const attribute = attributeData[typedKey];
			const attributeItemSize = attribute.size;
			const typedArray = new Float32Array(count * attributeItemSize);

			typedArray.fill(attribute.defaultValue);
			geometry.setAttribute(typedKey, THREEUtils.createInstancedBufferAttribute(typedArray, attributeItemSize));
		}
	}

	protected async createInstancedMesh(maxCount: number, geometryOrTextureId: string | number) {
		const {geometry, material} = await this.createGeometryAndMaterial(geometryOrTextureId);
		const instancedMesh = new InstancedMesh(geometry, material, maxCount);

		instancedMesh.name = `${geometryOrTextureId}`; // This is important, we can find the necessary instancedmesh based on the id this way
		instancedMesh.count = 0;
		this.initInstancedMeshAttributes(instancedMesh);
		geometry.instanceCount = maxCount;
		instancedMesh.computeBoundingSphere();
		this.instancedMeshes.push(instancedMesh);
		THREEUtils.add(this._container, instancedMesh);

		return instancedMesh;
	}

	protected getInstancedMeshIndexByName(geometryOrTextureId: string | number) {
		return this._xyiconManager.instancedMeshes.findIndex((instancedMesh: InstancedMesh) => instancedMesh.name === `${geometryOrTextureId}`);
	}

	protected abstract createGeometryAndMaterial(
		geometryOrTextureId: string | number,
	): Promise<{geometry: InstancedBufferGeometry; material: RawShaderMaterial}>;

	public abstract createNecessaryDataFromXyiconModel(
		model: IXyiconMinimumData,
	): Promise<{color: number; opacity: number; instancedMeshId: number; instanceId: number}>;

	protected get instancedMeshes() {
		return this._xyiconManager.instancedMeshes;
	}
}

import {computed, observable, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {XyiconFeature} from "../../generated/api/base";
import type {BoundarySpaceMap3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {BoundaryDto, PointDouble, BoundaryFieldUpdateDto} from "../../generated/api/base";
import {THREEUtils} from "../../utils/THREEUtils";
import type {ISpaceItemModel, IEditableItemModel} from "./Model";
import {BoundarySpaceMap} from "./BoundarySpaceMap";
import {Type} from "./Type";
import type {Space} from "./Space";

/**
 * The minimum necessary data to create a boundary in space
 */
export interface IBoundaryMinimumData extends IEditableItemModel, BoundaryDto {
	boundaryTypeId: string;
	fieldData?: any;
}

export class Boundary implements ISpaceItemModel {
	public static search(boundary: Boundary, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();
		const refId = boundary.refId.toLowerCase();

		if (refId.indexOf(search) > -1) {
			return true;
		}

		return false;
	}

	public readonly ownFeature = XyiconFeature.Boundary;

	@observable
	private _data: Omit<BoundaryDto, "boundarySpaceMaps">;

	private _appState: AppState;

	@observable
	private _boundarySpaceMaps: BoundarySpaceMap[] = [];

	constructor(data: BoundaryDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data);
	}

	public applyData(data_: BoundaryDto) {
		const {boundarySpaceMaps, ...data} = data_;

		this._data = data;
		this._data.fieldData = this._data.fieldData || {};

		this._boundarySpaceMaps = [];

		for (const boundarySpaceMapData of boundarySpaceMaps) {
			const boundarySpaceMap = new BoundarySpaceMap(boundarySpaceMapData, this);

			this._boundarySpaceMaps.push(boundarySpaceMap);
			this._appState.boundarySpaceMaps.add(boundarySpaceMap);
		}
	}

	public applyUpdate(data: BoundaryFieldUpdateDto) {
		if (data.boundaryIDList.includes(this.id)) {
			this._data.lastModifiedAt = data.lastModifiedAt || this._data.lastModifiedAt;
			this._data.lastModifiedBy = data.lastModifiedBy || this._data.lastModifiedBy;
		}
	}

	public removeBoundarySpaceMaps(boundarySpaceMaps: BoundarySpaceMap[]) {
		this._boundarySpaceMaps = this._boundarySpaceMaps.filter((boundarySpaceMap: BoundarySpaceMap) => !boundarySpaceMaps.includes(boundarySpaceMap));

		this._appState.boundarySpaceMaps.deleteByIds(boundarySpaceMaps.map((bsm) => bsm.id));

		// Boundaries can't exist without boundaryspacemaps...
		if (this._boundarySpaceMaps.length < 1) {
			this._appState.actions.applyDelete([this.id], XyiconFeature.Boundary);
		}
	}

	@computed
	public get id() {
		return this._data.boundaryID;
	}

	@computed
	public get refId() {
		return this._data.boundaryRefID;
	}

	@computed
	public get boundarySpaceMaps() {
		return this._boundarySpaceMaps;
	}

	@computed
	public get portfolioId() {
		return this._data.portfolioID;
	}

	@computed
	public get fieldData() {
		return this._data.fieldData;
	}

	public get appState() {
		return this._appState;
	}

	// Used by AppActions.findInheritedObject
	// in case of multiple spaces, should we display all of them on the Details tab?
	public get spaceId() {
		const currentSpaceId = this._appState.space?.id;

		if (currentSpaceId) {
			const spaceMap = this._boundarySpaceMaps.find((spaceMap) => spaceMap.spaceId === currentSpaceId);

			if (spaceMap) {
				return spaceMap.spaceId;
			}
		}

		if (this._boundarySpaceMaps.length === 0) {
			console.warn(`Corrupt data: Boundary (id: ${this.id}, refId: ${this.refId}) doesn't have boundarySpaceMaps`);
			return "";
		}

		return this.selectedBoundarySpaceMap.spaceId;
	}

	@computed
	public get space() {
		const spaceId = this.spaceId;

		return spaceId ? this._appState.actions.getFeatureItemById<Space>(spaceId, XyiconFeature.Space) : null;
	}

	@computed
	public get typeId() {
		return this._data.boundaryTypeID;
	}

	public set typeId(value: string) {
		this._data.boundaryTypeID = value;
	}

	@computed
	public get type() {
		return this._appState.actions.getTypeById(this.typeId);
	}

	@computed
	public get typeName() {
		return this.type?.name || "";
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get color() {
		return this.type?.settings.color || Type.defaultColor;
	}

	@computed
	public get selectedBoundarySpaceMap() {
		const {spaceViewRenderer} = this._appState.app;

		if (spaceViewRenderer?.isMounted) {
			const selectedSpaceMap = spaceViewRenderer.boundaryManager.selectedItems.find(
				(boundarySpaceMap3D: BoundarySpaceMap3D) => (boundarySpaceMap3D?.modelData as BoundarySpaceMap)?.parent === this,
			)?.modelData as BoundarySpaceMap;

			if (selectedSpaceMap) {
				return selectedSpaceMap;
			}
		}

		return this.boundarySpaceMaps.length > 0 ? this.boundarySpaceMaps[0] : null;
	}

	@computed
	public get thumbnail() {
		return this.selectedBoundarySpaceMap?.thumbnail;
	}

	@computed
	public get position() {
		return {
			...THREEUtils.getCenterFromGeometryData(this.selectedBoundarySpaceMap.geometryData, this.selectedBoundarySpaceMap.orientation),
			z: 0,
		};
	}

	public get isBoundary() {
		return true;
	}

	public setPosition(newPos: PointDouble) {
		const center = this.position;
		const xDiff = newPos.x - center.x;
		const yDiff = newPos.y - center.y;

		const newGeometryData = this.selectedBoundarySpaceMap.geometryData.map((data) => {
			return {
				x: data.x + xDiff,
				y: data.y + yDiff,
			} as PointDouble;
		});

		this.selectedBoundarySpaceMap.setGeometryData(newGeometryData);
	}

	public setDimensions(newDimensions: PointDouble) {
		const xMultiplier = newDimensions.x / this.dimension.x;
		const yMultiplier = newDimensions.y / this.dimension.y;
		const boundary = this.selectedBoundarySpaceMap;

		const unrotatedVertices = THREEUtils.getRotatedVertices(boundary.geometryData, -boundary.orientation);

		const position = this.position;
		const newUnrotatedGeometryData = unrotatedVertices.map((data) => {
			return {
				x: (data.x - position.x) * xMultiplier + position.x,
				y: (data.y - position.y) * yMultiplier + position.y,
			};
		});

		const newGeometryData = THREEUtils.getRotatedVertices(newUnrotatedGeometryData, boundary.orientation);

		this.selectedBoundarySpaceMap.setGeometryData(newGeometryData);
	}

	public setOrientation(orientation: number) {
		this.selectedBoundarySpaceMap.setOrientation(orientation);
	}

	@computed
	private get dimension() {
		const boundary = this.selectedBoundarySpaceMap;
		const unrotatedVertices = THREEUtils.getRotatedVertices(boundary.geometryData, -boundary.orientation);
		const bbox = THREEUtils.calculateBox(unrotatedVertices);

		return THREEUtils.getSizeOfBoundingBox2(bbox);
	}

	@computed
	public get orientation() {
		return this.selectedBoundarySpaceMap.orientation;
	}

	@computed
	public get data() {
		return this._data;
	}
}

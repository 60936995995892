import {inject, observer} from "mobx-react";
import * as React from "react";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {Boundary} from "../../../../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {Catalog} from "../../../../../../data/models/Catalog";
import type {DocumentModel} from "../../../../../../data/models/DocumentModel";
import {getFileTypeLabel} from "../../../../../../data/models/FileType";
import type {Type} from "../../../../../../data/models/Type";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {AppActions} from "../../../../../../data/state/AppActions";
import type {AppState} from "../../../../../../data/state/AppState";
import type {ICardLayout} from "../../../../settings/modules/type/form/CardLayoutEditor";
import {getDefaultCardLayout, getKeyDisplayPairsForCardLayout} from "../../../../settings/modules/type/form/CardLayoutEditor";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import type {Markup} from "../../../../../../data/models/Markup";
import {XyiconFeature, FieldDataType} from "../../../../../../generated/api/base";
import type {Space} from "../../../../../../data/models/Space";

export interface IToolTipRow {
	key: string;
	value: string;
}

interface ICardLayoutToolTipProps {
	item: Catalog | Xyicon | Boundary | BoundarySpaceMap | Space;
	style?: React.CSSProperties;
	appState?: AppState;
	divRef: React.RefObject<HTMLDivElement>;
	className?: string;
	queryString?: string;
	changeDefaultLayout?: boolean;
}

export const addCommasToMultiSelectFieldValues = (value: string, field: IFieldAdapter) => {
	if (field?.dataType === FieldDataType.MultipleChoiceList) {
		return value.replaceAll("\n", ", ");
	}

	return value;
};

@inject("appState")
@observer
export class CardLayoutToolTip extends React.Component<ICardLayoutToolTipProps> {
	private _rowNames: (keyof ICardLayout)[] = ["row 1", "row 2", "row 3", "hover row 1", "hover row 2", "hover row 3"];

	public static getKeyAndValue(
		propertyOrFieldRefId: string,
		actions: AppActions,
		item: Catalog | Xyicon | Boundary | BoundarySpaceMap | DocumentModel | Markup | Space,
		isKeyNeeded: boolean,
	): IToolTipRow {
		let key = "";

		const type: Type | null = actions.getTypeById((item as Catalog | Xyicon | Boundary | BoundarySpaceMap | Space).typeId);

		if (isKeyNeeded) {
			const {ownFeature} = item;

			const pairs = getKeyDisplayPairsForCardLayout(actions, type, ownFeature);

			key = pairs.find((s) => s.key === propertyOrFieldRefId)?.display || "";
		}

		const field = actions.getFieldByRefId(propertyOrFieldRefId);
		const isFieldHidden = actions.isFieldHiddenByMasking(item, field);

		let value = actions.renderValue(item, propertyOrFieldRefId);

		//can be another way to solve the Document fileType prop display issue?
		if (item.ownFeature === XyiconFeature.Document && propertyOrFieldRefId === "fileType") {
			value = getFileTypeLabel(item.fileType);
		}

		if (field) {
			const propagations = actions.getDynamicFieldPropagations(item, field);

			if (propagations) {
				const result = propagations.map((propagation) => actions.formatValue(propagation.value, field.refId));

				const ownValue = actions.renderValue(item, propertyOrFieldRefId);

				if (!StringUtils.isBlank(ownValue)) {
					result.unshift(ownValue);
				}

				value = result.join(", ");
			}
		}

		value =
			value !== ""
				? value
				: (field
						? actions.getHumanFriendlyFieldValueForItem(item, field)
						: (item[propertyOrFieldRefId as keyof (Catalog | Xyicon | Boundary | BoundarySpaceMap | DocumentModel | Space)] as string)) || "";
		value = addCommasToMultiSelectFieldValues(value, field);

		if (field && isFieldHidden) {
			value = "";
		}

		return {
			key,
			value,
		};
	}

	private getToolTipRow(toolTipRow: IToolTipRow, key: number) {
		return (
			toolTipRow.key &&
			toolTipRow.value && (
				<div
					key={key}
					className="typeName"
				>{`${toolTipRow.key}: ${toolTipRow.value}`}</div>
			)
		);
	}

	public override render() {
		const {item, className, queryString, appState, changeDefaultLayout} = this.props;
		const {actions} = this.props.appState;
		const type = actions.getTypeById(item.typeId);
		const cardLayout = type?.settings.cardLayout?.[item.ownFeature] || getDefaultCardLayout(item.ownFeature);
		const customRows: IFieldAdapter[] = [];
		const rows: IToolTipRow[] = [];
		const needCustomRows = queryString && changeDefaultLayout;

		if (needCustomRows) {
			customRows.push(...appState.actions.searchModelCustomFields(item, queryString, item.ownFeature));
			customRows.splice(6);
		} else {
			this._rowNames.forEach((name) => rows.push(CardLayoutToolTip.getKeyAndValue(cardLayout[name], actions, item, true)));
		}

		return (
			<div
				className={`CardLayoutToolTip vbox ${className || ""}`}
				style={this.props.style || {}}
				ref={this.props.divRef}
			>
				{needCustomRows
					? customRows.map((field, index) => this.getToolTipRow({key: field.name, value: appState.actions.renderValue(item, field.refId)}, index))
					: rows.map((row, index) => this.getToolTipRow(row, index))}
			</div>
		);
	}
}

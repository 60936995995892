import {useReducer} from "react";
import type {Markup} from "../../../../../data/models/Markup";
import type {ISizeRange} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import type {SpaceItem} from "../../../../modules/space/spaceeditor/logic3d/elements3d/SpaceItem";
import {MarkupsWithChangeableLineThickness} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {MarkupUtils} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import LineWidthIcon from "../../../icons/line-width.svg?react";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";

const _range: ISizeRange = {
	values: [1, 2, 4, 8, 10, 20, 50],
	default: MarkupUtils.defaultLineThickness,
	min: 1,
	max: 50,
};

interface ILineThicknessChangerProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly items: SpaceItem[];
}

export const LineThicknessChangerV5 = (props: ILineThicknessChangerProps) => {
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const onChange = (newValue: number) => {
		const {items, spaceViewRenderer} = props;

		const markupsToUpdate: Markup3D[] = [];

		const markup3Ds = items as Markup3D[];

		for (const markup3D of markup3Ds) {
			const markup = markup3D.modelData as Markup;
			const hasChanged = markup.lineThickness !== newValue;

			if (hasChanged) {
				markup.setLineThickness(newValue);
				markupsToUpdate.push(markup3D);
			}
		}

		for (const markup3D of markupsToUpdate) {
			markup3D.updateByModel(markup3D.modelData as Markup);
		}

		spaceViewRenderer.markupManager.updateItems(markupsToUpdate, true);

		forceUpdate();
	};

	const {items} = props;
	const areOnlyLineTypesSelected =
		items.length > 0 &&
		items.every((item) => item.spaceItemType === "markup" && MarkupsWithChangeableLineThickness.includes((item as Markup3D).type));

	return (
		areOnlyLineTypesSelected && (
			<div
				className="hbox"
				title="Change line width"
			>
				<SelectInputV5
					selected={(items[0].modelData as Markup).lineThickness}
					onChange={onChange}
					options={_range.values}
					sort={false}
					searchBarMode="always off"
					IconComponent={LineWidthIcon}
					horizontalAlignment={HorizontalAlignment.center}
				/>
			</div>
		)
	);
};

import type {CSSProperties, RefObject} from "react";
import styled from "styled-components";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {useAppStore} from "../../../StateManager";
import {SpaceList} from "../modules/spaceeditor/spaceselector/SpaceList";
import type {Space} from "../../../data/models/Space";
import {XyiconFeature} from "../../../generated/api/base";
import {Functions} from "../../../utils/function/Functions";
import {zIndex} from "../styles/styles";
import {NavigationEnum} from "../../../Enums";
import {ClassNameOfViewPopout} from "./FloatingMenu";

interface ISpaceSelectorPopupProps {
	readonly divRef: RefObject<HTMLDivElement>;
}

export const SpaceSelectorPopup = (props: ISpaceSelectorPopupProps) => {
	const appState = useAppStore((state) => state.appState);
	const navBarState = useAppStore((state) => state.navBarState);
	const viewForOpenSpaceSelector = useAppStore((state) => state.viewForOpenSpaceSelector);

	if (!viewForOpenSpaceSelector) {
		return <></>;
	}

	const {divRef} = props;

	const inlineStyle: CSSProperties = {
		left: "80px",
	};

	if (navBarState !== "closed") {
		inlineStyle.left = "300px";
	}

	const elementMaybe = document.querySelector(`.${ClassNameOfViewPopout}`);

	if (elementMaybe) {
		const rect = elementMaybe.getBoundingClientRect();

		inlineStyle.left = rect.right + 8;
	}

	return (
		<DomPortal destination={appState.app.modalContainer}>
			<SpaceSelectorWrapper
				ref={divRef}
				onClick={Functions.stopPropagation}
				style={inlineStyle}
			>
				<SpaceList
					spaces={appState.actions.getList(XyiconFeature.Space)}
					onSpaceSelect={(space: Space) => appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, space.id)}
					selectedSpace={appState.space}
				/>
			</SpaceSelectorWrapper>
		</DomPortal>
	);
};

const SpaceSelectorWrapper = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: ${zIndex.primaryNavigation + 1};
`;

import * as React from "react";
import {inject} from "mobx-react";
import {ClickToEditInput} from "../../../widgets/input/clicktoedit/ClickToEditInput";
import {OptionsButton} from "../../../widgets/button/options/OptionsButton";
import type {IContextOption} from "../../../widgets/context/ContextOptions";
import {ReactUtils} from "../../../utils/ReactUtils";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature} from "../../../../generated/api/base";
import type {Space} from "../../../../data/models/Space";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {Functions} from "../../../../utils/function/Functions";
import type {IOnePagePDFDoc} from "./CreateSpacePanel";

export type PDFListItemMode = "EditSpaceNames" | "SetScaleOrAlign" | "Confirm";

interface IPDFListItemProps {
	appState?: AppState;
	selectedVersion: SpaceVersion;
	onePagePDFDoc: IOnePagePDFDoc;
	mode: PDFListItemMode;
	active: boolean;
	originalPDFIndex: number;
	originalPageNumber: number;
	originalPDFFileName: string;
	forceUpdate: () => void;
	onSpaceNameChange: (originalPDFIndex: number, originalPageNumber: number, newName: string) => void;
	isSpaceNameAvailable: (spaceName: string) => boolean;
	onRotate: (originalPDFIndex: number, originalPageNumber: number, deltaAngle: number) => void;
	onDeleteClick: (originalPDFIndex: number, originalPageNumber: number) => void;
	onSelect: (originalPDFIndex: number, originalPageNumber: number) => void;
}

interface IPDFListItemState {
	thumbnailURL: string;
	isWaitingOnThumbnail: boolean;
	isSpaceNameAvailable: boolean;
	isSpaceNameEmpty: boolean;
}

@inject("appState")
export class PDFListItem extends React.Component<IPDFListItemProps, IPDFListItemState> {
	private _originalRotation: number;
	private _rotation: number;

	private _isMounted: boolean = false;

	constructor(props: IPDFListItemProps) {
		super(props);

		this.state = {
			isSpaceNameAvailable: true,
			isSpaceNameEmpty: false,
			thumbnailURL: "",
			isWaitingOnThumbnail: true,
		};
	}

	private onSpaceNameChange = (newName: string) => {
		if (newName !== this.props.onePagePDFDoc.spaceName) {
			if (!this.state.isSpaceNameEmpty && this.state.isSpaceNameAvailable) {
				this.props.onSpaceNameChange(this.props.originalPDFIndex, this.props.originalPageNumber, newName);
			}
		}
	};

	private onClick = () => {
		if (this.props.onSelect) {
			this.props.onSelect(this.props.originalPDFIndex, this.props.originalPageNumber);
		}
	};

	private onRotateCWClick = () => {
		this.props.onRotate(this.props.originalPDFIndex, this.props.originalPageNumber, 90);
		this.updateThumbnail();
	};

	private onRotateCCWClick = () => {
		this.props.onRotate(this.props.originalPDFIndex, this.props.originalPageNumber, -90);
		this.updateThumbnail();
	};

	private async updateThumbnail() {
		if (this._isMounted) {
			this.setState({
				isWaitingOnThumbnail: true,
			});
			this.props.forceUpdate();

			this._originalRotation = this.props.onePagePDFDoc.originalRotation;
			const pdf = this.props.onePagePDFDoc.doc;
			const firstPage = pdf.getPages()[0];

			this._rotation = MathUtils.clampDegreeBetween0And360(firstPage.getRotation().angle);

			const deltaAngle = MathUtils.clampDegreeBetween0And360(this._rotation - this._originalRotation);

			const thumbnailObject = await this.props.appState.app.graphicalTools.pdfRenderer.getThumbnailAndViewBox(
				400,
				this.props.onePagePDFDoc.doc,
				`${this.props.originalPDFFileName}_${this.props.originalPageNumber}`,
				deltaAngle,
			);

			if (this._isMounted) {
				if (!this.props.onePagePDFDoc.thumbnail || !this.props.onePagePDFDoc.insertionInfo) {
					if (!this.props.onePagePDFDoc.thumbnail) {
						this.props.onePagePDFDoc.thumbnail = thumbnailObject.url;
					}
					if (!this.props.onePagePDFDoc.insertionInfo) {
						this.props.onePagePDFDoc.insertionInfo = thumbnailObject.insertionInfo;
					}
					this.props.forceUpdate();
				}

				this.setState({
					thumbnailURL: thumbnailObject.url,
					isWaitingOnThumbnail: false,
				});
			}
		}
	}

	private getVersionHistory() {
		const {actions} = this.props.appState;
		const spaces = actions.getList(XyiconFeature.Space) as Space[];
		const spaceWithTheSameName = spaces.find((space: Space) => space.name === this.props.onePagePDFDoc.spaceName);

		const spaceVersions = [this.props.selectedVersion];

		if (spaceWithTheSameName) {
			const relatedSpaceVersions = spaceWithTheSameName.versions;

			this.props.onePagePDFDoc.spaceId = spaceWithTheSameName.id;

			for (const spaceVersion of relatedSpaceVersions) {
				if (!spaceVersions.includes(spaceVersion)) {
					spaceVersions.push(spaceVersion);
				}
			}
		} else {
			this.props.onePagePDFDoc.spaceId = null;
		}

		return spaceVersions.map((spaceVersion: SpaceVersion, index: number) => {
			return (
				<div
					className="vbox flexCenter"
					key={index}
				>
					<div>{spaceVersion.name}</div>
					<div className="darkSilverText">{DateFormatter.format(spaceVersion.date.toString())}</div>
				</div>
			);
		});
	}

	public override componentDidMount() {
		this._isMounted = true;
		this.updateThumbnail();
	}

	public override componentWillUnmount() {
		this._isMounted = false;
	}

	public override render() {
		const options: IContextOption[] = [
			{
				label: "Rotate 90° CW",
				onSelect: this.onRotateCWClick,
			},
			{
				label: "Rotate 90° CCW",
				onSelect: this.onRotateCCWClick,
			},
			{
				label: "Delete",
				onSelect: () => {
					this.props.onDeleteClick(this.props.originalPDFIndex, this.props.originalPageNumber);
				},
			},
		];

		const isScaleSet = this.props.mode === "SetScaleOrAlign" && MathUtils.isValidNumber(this.props.onePagePDFDoc.spaceUnitsPerMeter);
		const isAlignConfirmed = this.props.mode === "SetScaleOrAlign" && this.props.onePagePDFDoc.isAlignmentConfirmed;

		return (
			<div
				className={ReactUtils.cls("pdfContainer hbox bordered", {
					disabled: this.state.isWaitingOnThumbnail,
					active: this.props.active,
					confirmed: isScaleSet || isAlignConfirmed,
				})}
				onClick={this.onClick}
			>
				<div className="hbox flex1">
					<div
						style={{backgroundImage: `url("${this.state.thumbnailURL}")`}}
						className="thumbnail"
					></div>
					<div className="vbox justifyCenter">
						{this.props.mode === "EditSpaceNames" ? (
							<>
								<div className="bold">{`Page ${this.props.onePagePDFDoc.originalPageNumber + 1}`}</div>
								<div className="darkSilverText">{this.props.originalPDFFileName}</div>
							</>
						) : (
							<>
								<div className="bold">{this.props.onePagePDFDoc.spaceName}</div>
								{(isScaleSet || isAlignConfirmed) && <div className="greenText">{isScaleSet ? "Scale Set ✓" : "Aligned ✓"}</div>}
							</>
						)}
					</div>
				</div>
				{this.props.mode === "EditSpaceNames" && (
					<>
						<ClickToEditInput
							value={this.props.onePagePDFDoc.spaceName}
							className="flexCenter flex1 spaceName center"
							onChange={this.onSpaceNameChange}
							onBlur={Functions.emptyFunction}
							valueValidator={this.props.isSpaceNameAvailable}
							noButtons={true}
						/>
						<div className="vbox flexCenter flex1">{this.getVersionHistory()}</div>

						<OptionsButton
							className="options flexCenter"
							options={options}
						/>
					</>
				)}
			</div>
		);
	}
}

import {render} from "react-dom";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {Functions} from "../../../utils/function/Functions";
import {HTMLUtils} from "../../../utils/HTML/HTMLUtils";
import {MathUtils} from "../../../utils/math/MathUtils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {ButtonV5} from "../button/ButtonV5";
import XMarkIcon from "../icons/xmark.svg?react";
import {FLEXCENTER, FlexCenterStyle, fontSize, fontWeight, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import ExclamationAltIcon from "../icons/exclamation-alt.svg?react";
import CheckIcon from "../icons/check.svg?react";
import InformationAltIcon from "../icons/information-alt.svg?react";

/**
 * error: red border, X on the right side,
 * message: blue border, no X
 * warning: yellow border, no X
 * success: green border, undo button and X on the right side,
 * announcement: full width after nav bar, yellow background, X on the right side
 */
enum NotificationType {
	Error = 1,
	Message = 2,
	Warning = 3,
	Success = 4,
	Announcement = 5,
}

export interface INotificationParams {
	title: string;
	description?: string;
	link?: string;
	type: NotificationType;
	className?: string;
	cancelable?: boolean;
	buttonLabel?: string; // Action button appear only if buttonLabel is passed
	style?: React.CSSProperties;
	// If you want it to be there until the user explicitly closes it, pass "Infinity" here
	// Any non-valid and/or non-positive number is accepted, and work the same though
	lifeTime?: number; // ms
	onActionButtonClick?: () => void | Promise<void>;
	backdropDisable?: boolean;
}

const NotificationV5Styled = styled.div<{$color?: string}>`
	z-index: ${zIndex.popup};
	position: relative;
	background: ${colorPalette.white};
	width: 800px;
	height: 80px;
	margin-top: 24px;
	justify-content: space-between;
	overflow: hidden;
	transform-origin: bottom center;
	transition: transform 0.3s ease-out;
	border-radius: ${radius.md};
	box-shadow: 0px 4px 50px 0px #00000033;
	border: 2px solid;
	border-left: 8px solid;
	border-color: ${(props) => props.$color};
	${FlexCenterStyle}

	&.message {
		background-color: ${colorPalette.primary.c200Light};
	}

	&.warning {
		background-color: ${colorPalette.warning.c200Light};
	}

	&.success {
		background-color: ${colorPalette.positive.c200Light};
	}

	&.error {
		background-color: ${colorPalette.negative.c200Light};
	}

	&.announcement {
		width: 100%;
		max-width: initial;
		margin: 0;
		top: 0;
		background: #f2c110;
		border: none;
		height: 36px;
		box-shadow: none;

		.details {
			${FlexCenterStyle}

			h3 {
				margin-right: 5px;
			}

			.textLink {
				margin-left: 15px;
			}
		}

		.actions {
			.cancel.icon {
				width: 16px;
				height: 16px;
				color: var(--text1);
			}
		}
	}

	.notificationBackdrop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: ${zIndex.popup};
		background-color: rgba(0, 0, 0, 0.65);

		.Notification {
			margin-top: 20px;
		}
	}
`;

const NotificationIconStyled = styled.div<{$color?: string}>`
	${FLEXCENTER}
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: ${(props) => props.$color};

	svg {
		height: 16px;
		color: white;
	}
`;

const NotificationContentStyled = styled.div`
	${FlexCenterStyle}
	justify-content: flex-start;
	margin: 8px;
	gap: 8px;
`;

const NotificationTextStyled = styled.div`
	color: #37474f;
	margin-right: 20px;

	div {
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.md};
		overflow: hidden;
		word-break: break-word;
		color: #7b7b7b;
		max-width: 590px;
	}

	p {
		font-weight: ${fontWeight.thin};
		font-size: ${fontSize.sm};
		line-height: 16px;
		margin: 0;
	}

	ul {
		margin: 10px 0;

		li {
			font-family: "Roboto";
			font-size: ${fontSize.md};
			font-weight: ${fontWeight.normal};
			line-height: 20px;
		}
	}
`;

const TitleStyled = styled.h3`
	font-family: "Roboto";
	font-style: normal;
	font-weight: ${fontWeight.bold};
	font-size: ${fontSize.lg};
	line-height: 16px;
	padding: 0;
	margin: 2px 0 3px;
	color: #363636;
`;

const ActionButtonsStyled = styled.div`
	${FlexCenterStyle}
	right: 8px;
	position: absolute;

	.button {
		padding: 16px;
		white-space: nowrap;
		border-radius: 4px;

		&.message {
			background-color: ${colorPalette.primary.c500Primary};
		}

		&.warning {
			background-color: ${colorPalette.warning.c500Primary};
		}

		&.success {
			background-color: ${colorPalette.positive.c500Primary};
		}

		&.error {
			background-color: ${colorPalette.negative.c600};
		}
	}
`;

const CancelButtonStyled = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	right: 8px;
	margin-left: 8px;

	svg {
		cursor: pointer;
		z-index: 1;
	}

	.IconButton {
		position: absolute;
	}
`;

export interface INotificationProps extends INotificationParams {
	onClose?: () => void;
}

interface INotificationConfig {
	backdrop?: boolean;
	parentElement?: HTMLElement;
}

/**
 * Normally you'd want to create a new notification (toast message) with the globally available "notify" method, like this:
 * notify(params);
 */

NotificationV5.defaultProps = {
	title: "",
	description: "",
	link: "",
	type: NotificationType.Message,
	className: "",
	cancelable: true,
	buttonLabel: "",
	style: {},
	onClose: Functions.emptyFunction,
	onActionButtonClick: Functions.emptyFunction,
	lifeTime: 5000,
	backdropDisable: false,
};

export function NotificationV5(props: INotificationProps) {
	const {lifeTime} = props;
	const _ref = useRef<HTMLDivElement>();
	const _isMounted = useRef<boolean>(false);
	const _timeoutId = useRef<number>();
	const _container: HTMLDivElement = document.createElement("div");
	let _config: INotificationConfig;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isBackdropDisable, setIsBackdropDisable] = useState<boolean>(props.backdropDisable);

	const onClick = async () => {
		try {
			setIsLoading(true);
			setIsBackdropDisable(false), HTMLUtils.detach(_container);
			await props.onActionButtonClick();
			setIsLoading(false);

			onCancelClick();
		} catch (error) {
			console.warn("error ", error);
		}
	};

	const onCancelClick = () => {
		onClose();
	};

	const onClose = () => {
		if (_isMounted.current) {
			if (_ref.current) {
				_ref.current.style.transform = "scaleY(0)";
			}
			setTimeout(props.onClose, 300); // 0.3s -> from css transition
		}
		clearTimeout(_timeoutId.current);
	};

	const IsLifeTimeLimited = MathUtils.isValidNumber(lifeTime) && lifeTime > 0;

	const getColor = () => {
		switch (props.type) {
			case NotificationType.Error:
				return colorPalette.negative.c600;
			case NotificationType.Message:
				return colorPalette.primary.c500Primary;
			case NotificationType.Success:
				return colorPalette.positive.c500Primary;
			case NotificationType.Warning:
				return colorPalette.warning.c500Primary;
		}
	};

	const getIcon = () => {
		switch (props.type) {
			case NotificationType.Error:
				return <XMarkIcon />;
			case NotificationType.Message:
				return <InformationAltIcon />;
			case NotificationType.Success:
				return <CheckIcon />;
			case NotificationType.Warning:
				return <ExclamationAltIcon />;
		}
	};

	useEffect(() => {
		_isMounted.current = true;

		if (_ref.current) {
			_ref.current.style.transform = "scaleY(1)";
		}

		if (IsLifeTimeLimited) {
			_timeoutId.current = window.setTimeout(onCancelClick, lifeTime);
		}

		return () => {
			_isMounted.current = false;
			clearTimeout(_timeoutId.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderNotificationBackdropDisabled = () => {
		_container.className = "notificationBackdrop vbox alignCenter";
		if (_config.backdrop) {
			_container.onclick = Functions.emptyFunction;
		}

		render(renderNotificationContent(), _container);

		_config.parentElement.appendChild(_container);
	};

	const renderNotificationContent = () => {
		const {title, description, className, cancelable, buttonLabel, type, link} = props;
		const spinnerColor = getColor();

		return (
			<NotificationV5Styled
				className={`${NotificationType[type]?.toLowerCase()} ${className || ""}`}
				style={props.style || {}}
				ref={_ref}
				data-cy="Notification"
				$color={spinnerColor}
			>
				<NotificationContentStyled>
					<NotificationIconStyled $color={spinnerColor}>{getIcon()}</NotificationIconStyled>
					<NotificationTextStyled>
						<TitleStyled>{title}</TitleStyled>
						{description && (
							<div
								className="description"
								dangerouslySetInnerHTML={{__html: description}}
							/>
						)}
						{type === NotificationType.Announcement && (
							<a
								className="textLink"
								href={link}
							>
								Read more
							</a>
						)}
					</NotificationTextStyled>
					<ActionButtonsStyled>
						{buttonLabel && props.onActionButtonClick && (
							<ButtonV5
								className={`button buttonLabel ${NotificationType[type]?.toLowerCase()}`}
								disabled={isLoading}
								label={isLoading ? "Loading..." : buttonLabel}
								onClick={onClick}
							/>
						)}
						<CancelButtonStyled data-cy="CancelButton">
							{IsLifeTimeLimited && (
								<CountdownCircleTimer
									isPlaying
									duration={lifeTime / 1000}
									colors={spinnerColor as `#${string}`}
									size={32}
									strokeWidth={2}
								/>
							)}
							{cancelable && (
								<IconButtonV5
									IconComponent={XMarkIcon}
									onClick={onCancelClick}
								/>
							)}
						</CancelButtonStyled>
					</ActionButtonsStyled>
				</NotificationContentStyled>
			</NotificationV5Styled>
		);
	};

	{
		if (isBackdropDisable) {
			renderNotificationBackdropDisabled();
			return null;
		} else {
			return renderNotificationContent();
		}
	}
}

import {Vector2} from "three";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import {Constants} from "../../Constants";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";
import {MarkupAB2D} from "./abstract/MarkupAB2D";
import {curveHeight, curvePeriod} from "./MarkupStaticElements";

export class MarkupCloud extends MarkupAB2D {
	private _divisionsPerCurve: number = 5;
	private _origo: PointDouble = {
		x: 0,
		y: 0,
	};

	private get _curveHeight(): number {
		return curveHeight * this._correctionMultiplier;
	}

	private get _curvePeriod(): number {
		return curvePeriod * this._correctionMultiplier;
	}

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const worldVertices = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);

		const p0 = worldVertices[0];
		const p1 = worldVertices[1];
		const p2 = worldVertices[2];
		const p3 = worldVertices[3];

		const maxCurveCount = 1000000000;
		const curveCount = {
			x: MathUtils.clamp(Math.floor(THREEUtils.calculateDistance([p0, p1]) / this._curvePeriod), 1, maxCurveCount),
			y: MathUtils.clamp(Math.floor(THREEUtils.calculateDistance([p1, p2]) / this._curvePeriod), 1, maxCurveCount),
		};

		const p3toP2 = this.getCurves(
			p3.x,
			p3.y,
			isLocal ? 0 : this._lastSavedOrientation,
			this._curvePeriod,
			this._curveHeight,
			curveCount.x,
			this._divisionsPerCurve,
		);
		const p2toP1 = this.getCurves(
			p2.x,
			p2.y,
			isLocal ? -Math.PI / 2 : this._lastSavedOrientation - Math.PI / 2,
			this._curvePeriod,
			this._curveHeight,
			curveCount.y,
			this._divisionsPerCurve,
		);
		const p1toP0 = this.getCurves(
			p1.x,
			p1.y,
			isLocal ? -Math.PI : this._lastSavedOrientation - Math.PI,
			this._curvePeriod,
			this._curveHeight,
			curveCount.x,
			this._divisionsPerCurve,
		);
		const p0toP3 = this.getCurves(
			p0.x,
			p0.y,
			isLocal ? Math.PI / 2 : this._lastSavedOrientation + Math.PI / 2,
			this._curvePeriod,
			this._curveHeight,
			curveCount.y,
			this._divisionsPerCurve,
		);

		this._2dVectors = [...p3toP2, ...p2toP1, ...p1toP0, ...p0toP3, new Vector2(p3toP2[0].x, p3toP2[0].y)];

		this._vertices.length = 0;
		for (const vec of this._2dVectors) {
			this._vertices.push(vec.x);
			this._vertices.push(vec.y);
			this._vertices.push(0);
		}

		this._lineGeometry.dispose();
		this._lineGeometry = new LineGeometry();
		(this._lineGeometry as LineGeometry).setPositions(this._vertices);
		this._line.geometry = this._lineGeometry as LineGeometry;

		this.updateMesh(this._2dVectors);
		if (isLocal) {
			const cornerVertices = [];

			for (const worldVertex of worldVertices) {
				cornerVertices.push(worldVertex.x);
				cornerVertices.push(worldVertex.y);
				cornerVertices.push(0);
			}
			cornerVertices.push(worldVertices[0].x);
			cornerVertices.push(worldVertices[0].y);
			cornerVertices.push(0);
			this._selectionPart.update(cornerVertices);
			this._textArea.update(cornerVertices);
		}
		this.updateBoundingBox();
	}

	// Starting at the origo, without orientation
	private basicCurveFunction = (period: number, amplitude: number, x: number) => {
		return amplitude * Math.abs(Math.sin((x * Math.PI) / period));
	};

	private getCurves(
		startX: number,
		startY: number,
		orientation: number,
		period: number,
		amplitude: number,
		count: number,
		divisionsPerCurve: number,
	) {
		const tinc = period / divisionsPerCurve;

		const _2dVectors = [];

		for (let i = 0; i < count; ++i) {
			for (let x = i * period; x < (i + 1) * period - Constants.EPSILON; x += tinc) {
				let newCurvePoint = {
					x: x,
					y: this.basicCurveFunction(period, amplitude, x),
				};

				if (orientation !== 0) {
					newCurvePoint = THREEUtils.getRotatedVertices([newCurvePoint], orientation, this._origo)[0];
				}
				_2dVectors.push(new Vector2(newCurvePoint.x, newCurvePoint.y));
			}
		}

		const lastX = count * period;
		let newCurvePoint = {
			x: lastX,
			y: this.basicCurveFunction(period, amplitude, lastX),
		};

		if (orientation !== 0) {
			newCurvePoint = THREEUtils.getRotatedVertices([newCurvePoint], orientation, this._origo)[0];
		}
		_2dVectors.push(new Vector2(newCurvePoint.x, newCurvePoint.y));

		THREEUtils.applyOffsetToGeometryData(_2dVectors, {x: startX, y: startY});

		return _2dVectors;
	}

	public get type() {
		return MarkupType.Cloud;
	}
}

import * as React from "react";
import styled from "styled-components";
import type {PointDouble} from "../../../../../generated/api/base";
import {Functions} from "../../../../../utils/function/Functions";
import type {IEditableIconProps} from "../../../../modules/abstract/common/iconeditor/EditableIcon";
import {LogarithmicSliderV5} from "../../../colors/LogarithmicSliderV5";
import {EditableIconV5} from "./EditableIconV5";

export type IconLayer = "Background" | "Image" | "Text";

interface IIconEditorProps extends IEditableIconProps {
	selectedLayer: IconLayer;
	isTextBeingModified: boolean;
	onIsTextBeingModifiedChange: (value: boolean) => void;
	onIconTranslateChange: (newTranslate: PointDouble) => void;
	onIconOrientationChange: (newOrientation: number) => void;
	onScaleChange: (newScale: number) => void;
	onTextTranslateChange: (newTranslate: PointDouble) => void;
	onTextOrientationChange: (newOrientation: number) => void;
	onBorderRadiusChange: (newBorderRadius: number) => void;
	svgRef: React.RefObject<SVGSVGElement>;
	sliderWidth?: number;
}

interface IIconEditorState {
	// we only update the other icons' properties on pointerup, otherwise it's slow
	scale: number;
	borderRadius: number;
}

export class IconEditorV5 extends React.Component<IIconEditorProps, IIconEditorState> {
	public static defaultProps: Partial<IIconEditorProps> = {
		selectedLayer: "Image",
		borderRadius: 5,
		backgroundColor: {hex: "FFFFFF", transparency: 1},
		iconColor: {hex: "FFFFFF", transparency: 1},
		replaceColor: false,
		text: "",
		textTranslate: {x: 0.5, y: 0.5},
		textOrientation: 0,
		onTextTranslateChange: Functions.emptyFunction,
		onTextOrientationChange: Functions.emptyFunction,
		onTextChange: Functions.emptyFunction,
		onBorderRadiusChange: Functions.emptyFunction,
		fontSize: 10,
		fontFamily: "Roboto",
		fontColor: {hex: "FFFFFF", transparency: 1},
		isBold: false,
		isItalic: false,
		isUnderlined: false,
		isTextBeingModified: false,
		onIsTextBeingModifiedChange: Functions.emptyFunction,
	};

	constructor(props: IIconEditorProps) {
		super(props);
		this.state = {
			scale: this.props.scale,
			borderRadius: this.props.borderRadius,
		};
	}

	private onScaleChange = (newScale: number) => {
		this.setState({
			scale: newScale,
		});
	};

	private onScaleEnd = () => {
		this.props.onScaleChange(this.state.scale);
	};

	private onBorderRadiusChange = (newBorderRadius: number) => {
		this.setState({
			borderRadius: newBorderRadius,
		});
	};

	private onBorderRadiusEnd = () => {
		this.props.onBorderRadiusChange(this.state.borderRadius);
	};

	private onTextDoubleClick = () => {
		if (this.props.selectedLayer === "Text") {
			this.props.onIsTextBeingModifiedChange(true);
		}
	};

	private onTextChange = (newText: string) => {
		this.props.onTextChange(newText);
		this.props.onIsTextBeingModifiedChange(false);
	};

	public override render() {
		const {
			innerPart,
			imageAspectRatio,
			isSVG,
			selectedLayer,
			backgroundColor,
			iconColor,
			isIconColorChanged,
			iconColors,
			replaceColor,
			iconOrientation,
			iconTranslate,
			isBold,
			isFlippedX,
			isFlippedY,
			text,
			textOrientation,
			textTranslate,
			onIconOrientationChange,
			onIconTranslateChange,
			onIsTextBeingModifiedChange,
			onTextOrientationChange,
			onTextTranslateChange,
			fontColor,
			fontFamily,
			fontSize,
			isItalic,
			isTextBeingModified,
			isUnderlined,
			horizontalAlignment,
			svgRef,
			selectedChildIndex,
			onSelectedChildIndexChange,
			sliderWidth,
		} = this.props;
		const {borderRadius, scale} = this.state;

		return (
			<IconEditorStyled>
				<EditableIconV5
					innerPart={innerPart}
					imageAspectRatio={imageAspectRatio}
					isSVG={isSVG}
					classNames="IconEditor"
					backgroundColor={backgroundColor}
					isIconColorChanged={isIconColorChanged}
					iconColor={iconColor}
					iconColors={iconColors}
					selectedChildIndex={selectedChildIndex}
					onSelectedChildIndexChange={onSelectedChildIndexChange}
					replaceColor={replaceColor}
					borderRadius={borderRadius}
					iconTranslate={iconTranslate}
					iconOrientation={iconOrientation}
					scale={scale}
					isFlippedX={isFlippedX}
					isFlippedY={isFlippedY}
					text={text}
					textTranslate={textTranslate}
					textOrientation={textOrientation}
					onTextTranslateChange={onTextTranslateChange}
					onTextOrientationChange={onTextOrientationChange}
					onTextChange={this.onTextChange}
					onIsTextBeingModifiedChange={onIsTextBeingModifiedChange}
					onIconTranslateChange={onIconTranslateChange}
					onIconOrientationChange={onIconOrientationChange}
					selectedLayer={selectedLayer}
					fontColor={fontColor}
					fontSize={fontSize}
					fontFamily={fontFamily}
					isBold={isBold}
					isItalic={isItalic}
					isUnderlined={isUnderlined}
					horizontalAlignment={horizontalAlignment}
					isTextBeingModified={isTextBeingModified}
					onTextDoubleClick={this.onTextDoubleClick}
					svgRef={svgRef}
				/>
				{selectedLayer === "Image" && (
					<>
						<LogarithmicSliderV5
							width={sliderWidth ? sliderWidth : 228}
							value={scale}
							largeStepValue={0.25}
							min={0.25}
							max={4}
							classNames="iconEditorScale"
							title="Scale"
							onValueChange={this.onScaleChange}
							onPointerUp={this.onScaleEnd}
							arrows={true}
						/>
					</>
				)}
			</IconEditorStyled>
		);
	}
}

const IconEditorStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	.IconEditor {
		width: 340px;
		height: 340px;
		position: relative;
	}
`;

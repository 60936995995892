import {DoubleClickGesture} from "../../utils/interaction/gestures/DoubleClickGesture";
import type {IWrapperProps} from "../widgets/wrapper/Wrapper";
import {Wrapper} from "../widgets/wrapper/Wrapper";

interface IDoubleClickDetectorProps extends IWrapperProps {
	onClick?: () => void;
	onDoubleClick?: () => void;
}

export class DoubleClickDetector extends Wrapper<IDoubleClickDetectorProps, HTMLElement> {
	private _doubleClickGesture: DoubleClickGesture;

	protected override onChildMounted(child: HTMLElement) {
		if (this._doubleClickGesture) {
			this._doubleClickGesture.disable();
		}

		this._doubleClickGesture = new DoubleClickGesture({
			target: child,
		});

		this._doubleClickGesture.signals.click.add(this.onClick);
		this._doubleClickGesture.signals.doubleClick.add(this.onDoubleClick);

		this._doubleClickGesture.enable();
	}

	private onClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	private onDoubleClick = () => {
		if (this.props.onDoubleClick) {
			this.props.onDoubleClick();
		}
	};

	public get gesture() {
		return this._doubleClickGesture;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {SpaceItemType} from "../../logic3d/managers/spaceitems/ItemManager";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {getFeatureBySpaceItemType} from "../../logic3d/renderers/SpaceViewRendererUtils";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import {isMarkupFilteredOutByColor} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {View} from "../../../../../../data/models/View";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {AppState} from "../../../../../../data/state/AppState";
import {HideAndLock} from "./HideAndLock";

export interface ILayerSection {
	title: string;
	isHidden: boolean;
	isPositionLocked: boolean;
}

export interface ILayerColumn {
	[key: string]: ILayerSection;
}

export interface ILayerSettings {
	included: ILayerColumn;
}

interface ILayerViewProps {
	view: View;
	spaceViewRenderer: SpaceViewRenderer;
	showNumbers: boolean;
	searchString: string;
	activeSection: SpaceItemType;
	appState?: AppState;
}

@inject("appState")
@observer
export class LayerView extends React.Component<ILayerViewProps> {
	private readonly _tooltipForNumbers = "Filters are applied";

	private onLayerSettingsChange() {
		if (this.props.view === this.selectedSpaceEditorView) {
			this.itemManager.onLayerSettingsModified();
		}
	}

	private get selectedSpaceEditorView() {
		return this.props.appState.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private get feature() {
		return getFeatureBySpaceItemType(this.props.activeSection);
	}

	private get itemManager() {
		return this.props.spaceViewRenderer.getItemManager(this.feature);
	}

	private getFilteredItemsCountForSection(section: ILayerSection) {
		const hiddenMarkupColors = new Set<string>(this.selectedSpaceEditorView.spaceEditorViewSettings.layers.hiddenMarkupColors);

		return this.itemManager.items.array.filter((spaceItem) => {
			if (spaceItem.spaceItemType === "markup") {
				const markup3D = spaceItem as Markup3D;

				return (
					!markup3D?.layerSettings?.isHidden &&
					!isMarkupFilteredOutByColor(hiddenMarkupColors, markup3D) &&
					markup3D?.typeName === section.title.replaceAll(" ", "_")
				);
			}
			return !spaceItem.layerSettings?.isHidden && spaceItem.typeName === section.title;
		}).length;
	}

	private getAllItemsCountForSection(section: ILayerSection) {
		return this.itemManager.items.array.filter((spaceItem) => {
			if (spaceItem.spaceItemType === "markup") {
				return spaceItem.typeName === section.title.replaceAll(" ", "_");
			}
			return spaceItem.typeName === section.title;
		}).length;
	}

	private getHideAndLockSectionElement(section: ILayerSection, index: number) {
		let title = section.title;

		let tooltip = "";

		if (this.props.showNumbers) {
			const filteredItemsCount = this.getFilteredItemsCountForSection(section);
			const allItemsCount = this.getAllItemsCountForSection(section);

			title = `${title} (${filteredItemsCount}/${allItemsCount})`;

			if (filteredItemsCount !== allItemsCount) {
				tooltip = this._tooltipForNumbers;
			}
		}

		if (this.props.activeSection === "markup") {
			title = title.replace(/_/g, " ");
		}

		return (
			<div
				className="field hbox alignCenter"
				key={index}
			>
				<HideAndLock
					active={section.isHidden}
					type="hide"
					onChange={(active: boolean) => {
						section.isHidden = active;
						this.onLayerSettingsChange();
					}}
				/>
				<HideAndLock
					active={section.isPositionLocked}
					type="lock"
					onChange={(active: boolean) => {
						section.isPositionLocked = active;
						this.onLayerSettingsChange();
					}}
				/>
				<div
					className="title"
					title={tooltip}
				>
					{title}
				</div>
			</div>
		);
	}

	private getSumOfItems() {
		return this.itemManager.items.array.length;
	}

	private getSumOfFilteredItems() {
		const hiddenMarkupColors = new Set<string>(this.selectedSpaceEditorView.spaceEditorViewSettings.layers.hiddenMarkupColors);

		return this.itemManager.items.array.filter(
			(spaceItem) =>
				!spaceItem.layerSettings?.isHidden &&
				(spaceItem.spaceItemType !== "markup" || !isMarkupFilteredOutByColor(hiddenMarkupColors, spaceItem as Markup3D)),
		).length;
	}

	private get layerSettings() {
		return this.props.view.spaceEditorViewSettings.layers[this.props.activeSection];
	}

	private renderTogglers() {
		const layerSettings = this.layerSettings;

		const layerColumns = {
			included: Object.values(layerSettings.included),
		};

		const filterBySearch = (layerSettings: ILayerSection) => StringUtils.containsIgnoreCase(`${layerSettings.title}`, this.props.searchString);

		const fieldElements: React.ReactNode[] = [];
		const sortedIncludedSections = layerColumns.included
			.filter(filterBySearch)
			.sort((a: ILayerSection, b: ILayerSection) => StringUtils.sortIgnoreCase(a.title, b.title));

		fieldElements.push(...sortedIncludedSections.map((section: ILayerSection, index: number) => this.getHideAndLockSectionElement(section, index)));

		const allTitleBase = "All";
		let allTitle = allTitleBase;

		const sumOfFilteredItems = this.getSumOfFilteredItems();
		const sumOfItems = this.getSumOfItems();
		let tooltip = "";

		if (this.props.showNumbers) {
			allTitle = `${allTitle} (${sumOfFilteredItems}/${sumOfItems})`;

			if (sumOfFilteredItems !== sumOfItems) {
				tooltip = this._tooltipForNumbers;
			}
		}

		return (
			<div className="fieldContainer">
				{StringUtils.containsIgnoreCase(allTitleBase, this.props.searchString) && (
					<div className="field hbox alignCenter">
						<HideAndLock
							active={sortedIncludedSections.every((layerSettings: ILayerSection) => layerSettings.isHidden)}
							type="hide"
							onChange={(active: boolean) => {
								for (const key in layerSettings.included) {
									layerSettings.included[key].isHidden = active;
								}
								this.onLayerSettingsChange();
							}}
							isItForModifyAll={true}
						/>
						<HideAndLock
							active={sortedIncludedSections.every((layerSettings: ILayerSection) => layerSettings.isPositionLocked)}
							type="lock"
							onChange={(active: boolean) => {
								for (const key in layerSettings.included) {
									layerSettings.included[key].isPositionLocked = active;
								}
								this.onLayerSettingsChange();
							}}
							isItForModifyAll={true}
						/>
						<div
							className="title"
							title={tooltip}
						>
							{allTitle}
						</div>
					</div>
				)}
				<div
					className="fields"
					data-cy="HideAndLockByType"
				>
					{fieldElements}
				</div>
			</div>
		);
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	public override componentDidMount() {
		const {spaceItemController} = this.props.spaceViewRenderer;

		spaceItemController.signals.filterUpdated.add(this.forceUpdateArrow);

		const feature = this.feature;

		const itemManager = this.props.spaceViewRenderer.getItemManager(feature);

		itemManager.signals.itemsAdd.add(this.forceUpdateArrow);
		itemManager.signals.itemsRemove.add(this.forceUpdateArrow);
	}

	public override componentWillUnmount() {
		const {spaceItemController} = this.props.spaceViewRenderer;

		spaceItemController.signals.filterUpdated.remove(this.forceUpdateArrow);

		const itemManager = this.props.spaceViewRenderer.getItemManager(this.feature);

		itemManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		itemManager.signals.itemsRemove.remove(this.forceUpdateArrow);
	}

	public override render() {
		return <div className="vbox">{this.renderTogglers()}</div>;
	}
}

import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {EmailInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/email/EmailInput";
import {SingleLineLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/singleline/SingleLineLabel";
import {SingleLineLabelV5} from "../../../../ui/5.0/details/datatypes/SingleLineLabelV5";
import {EmailInputV5} from "../../../../ui/5.0/details/datatypes/EmailInputV5";

export function configureEmail() {
	FieldDataTypes.configure({
		id: FieldDataType.Email,
		title: "Email",
		label: SingleLineLabel,
		labelV5: SingleLineLabelV5,
		input: EmailInput,
		inputV5: EmailInputV5,
		operatorsArray: stringOperators,
	});
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {InfoButton} from "../../../common/infobutton/InfoButton";
import type {AppState} from "../../../../../../data/state/AppState";
import {ToggleContainer} from "../../../../../widgets/container/ToggleContainer";
import {Field} from "../../../../../widgets/form/field/Field";
import type {DistanceUnitName, ExtendedDistanceUnitName} from "../../../../space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../../space/spaceeditor/logic3d/Constants";
import {ClickToEditInput} from "../../../../../widgets/input/clicktoedit/ClickToEditInput";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import type {Xyicon3D} from "../../../../space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {BoundarySpaceMap3D} from "../../../../space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {SpaceItem} from "../../../../space/spaceeditor/logic3d/elements3d/SpaceItem";
import {Permission} from "../../../../../../generated/api/base";
import type {Markup3D} from "../../../../space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {UnitSelector} from "../../../../../widgets/input/clicktoedit/UnitSelector";
import {MarkupProperties} from "./MarkupProperties";
import {MassPropertyInput} from "./field/mass/MassPropertyInput";

interface IPropertiesSectionProps {
	appState?: AppState;
	transport?: TransportLayer;
	saveStateToLocalStorage: boolean;
	properties: IPropertyDetails[];
}

interface IPropertiesSectionState {
	updatingValue: PropertyName | "";
	distanceUnitName: DistanceUnitName;
	openedProperty: PropertyName | "";
}

const pzDzArray = ["pz", "dz"];

export type spaceItemForProperties = Xyicon3D | BoundarySpaceMap3D | Markup3D;

export interface IPropertyDetails {
	name: string;
	measure: boolean;
	parts: {
		label?: string;
		name: PropertyName;
		ref: string;
	}[];
}

export type PropertyName = "px" | "py" | "pz" | "dx" | "dy" | "dz" | "o" | "area";

@inject("appState")
@inject("transport")
@observer
export class PropertiesSection extends React.Component<IPropertiesSectionProps, IPropertiesSectionState> {
	constructor(props: IPropertiesSectionProps) {
		super(props);
		this.state = {
			updatingValue: "",
			distanceUnitName: Constants.DISTANCE_UNITS.inch.name as DistanceUnitName,
			openedProperty: "",
		};
	}

	private getAreaSum(items: BoundarySpaceMap3D[]) {
		let areaSum = 0;

		for (const item of items) {
			areaSum += item.area;
		}

		const {spaceUnitsPerMeter} = this.props.appState.app.graphicalTools.spaceViewRenderer.space;

		return MathUtils.convertAreaFromSpaceUnit(areaSum, this.state.distanceUnitName, spaceUnitsPerMeter).toFixed(2);
	}

	private onMassInputOpen = (value: PropertyName) => this.setState({openedProperty: value});

	private async onChangeItemProp(value: string, propName: PropertyName, item: spaceItemForProperties) {
		const {spaceOffsetInDistanceUnit, spaceSizeInDistanceUnit} = this.getSpaceInfo();
		let valueAsNumber = parseFloat(value);

		if (isNaN(valueAsNumber)) {
			valueAsNumber = Constants.EPSILON;
		}

		switch (propName) {
			case "px":
				valueAsNumber = MathUtils.clamp(valueAsNumber, spaceOffsetInDistanceUnit.x, spaceOffsetInDistanceUnit.x + spaceSizeInDistanceUnit.x);
				break;
			case "py":
				valueAsNumber = MathUtils.clamp(valueAsNumber, spaceOffsetInDistanceUnit.y, spaceOffsetInDistanceUnit.y + spaceSizeInDistanceUnit.y);
				break;
			case "dx":
				valueAsNumber = MathUtils.clamp(valueAsNumber, Constants.EPSILON, spaceSizeInDistanceUnit.x);
				break;
			case "dy":
				valueAsNumber = MathUtils.clamp(valueAsNumber, Constants.EPSILON, spaceSizeInDistanceUnit.y);
				break;
		}

		this.setState({updatingValue: propName});
		await this.props.appState.actions.updateProperties(valueAsNumber, propName, [item], this.state.distanceUnitName as DistanceUnitName);
		this.setState({updatingValue: ""});
	}

	private onChangeDistanceUnit = (value: ExtendedDistanceUnitName) => {
		this.setState({distanceUnitName: value as DistanceUnitName});
	};

	private getSpaceInfo() {
		const {spaceViewRenderer} = this.props.appState.app;

		if (spaceViewRenderer.isMounted) {
			const {space, spaceOffset, spaceSize} = spaceViewRenderer;
			const {distanceUnitName} = this.state;
			const spaceOffsetInDistanceUnit = {
				x: MathUtils.convertDistanceFromSpaceUnit(spaceOffset.x, distanceUnitName, space.spaceUnitsPerMeter),
				y: MathUtils.convertDistanceFromSpaceUnit(spaceOffset.y, distanceUnitName, space.spaceUnitsPerMeter),
			};

			const spaceSizeInDistanceUnit = {
				x: MathUtils.convertDistanceFromSpaceUnit(spaceSize.x, distanceUnitName, space.spaceUnitsPerMeter),
				y: MathUtils.convertDistanceFromSpaceUnit(spaceSize.y, distanceUnitName, space.spaceUnitsPerMeter),
			};

			return {
				spaceOffsetInDistanceUnit,
				spaceSizeInDistanceUnit,
			};
		}

		return null;
	}

	private getBubbleText(min: number, max: number) {
		return `Enter a value between ${min.toFixed(2)} and ${max.toFixed(2)}`;
	}

	private getInfoButton(propName: PropertyName, hasAnyItemPermission: boolean) {
		const {spaceViewRenderer} = this.props.appState.app;

		if (spaceViewRenderer.isMounted && hasAnyItemPermission) {
			const {spaceOffsetInDistanceUnit, spaceSizeInDistanceUnit} = this.getSpaceInfo();

			switch (propName) {
				case "px":
					return <InfoButton bubbleText={this.getBubbleText(spaceOffsetInDistanceUnit.x, spaceOffsetInDistanceUnit.x + spaceSizeInDistanceUnit.x)} />;
				case "py":
					return <InfoButton bubbleText={this.getBubbleText(spaceOffsetInDistanceUnit.y, spaceOffsetInDistanceUnit.y + spaceSizeInDistanceUnit.y)} />;
				case "dx":
					return <InfoButton bubbleText={this.getBubbleText(0, spaceSizeInDistanceUnit.x)} />;
				case "dy":
					return <InfoButton bubbleText={this.getBubbleText(0, spaceSizeInDistanceUnit.y)} />;
			}
		}

		return null;
	}

	private getSpaceItemValue(partName: string, partRef: string, spaceItem3D: spaceItemForProperties) {
		if (partName === "Position") {
			return spaceItem3D.position[partRef as "x" | "y"];
		} else if (partName === "Dimensions") {
			// only boundaries have dimension
			return spaceItem3D[partRef as keyof SpaceItem] as number;
		}
		//orientation
		return spaceItem3D[partRef as keyof SpaceItem] as number;
	}

	private getMarkupProps() {
		const {appState} = this.props;
		const spaceViewRenderer = appState.app.spaceViewRenderer;
		const selectedMarkups = spaceViewRenderer.spaceItemController.markupManager.selectedItems as Markup3D[];
		const allSelectedItems = spaceViewRenderer.spaceItemController.selectedItems;

		return (
			selectedMarkups.length > 0 &&
			allSelectedItems.length === selectedMarkups.length && (
				<MarkupProperties
					markups={selectedMarkups}
					spaceViewRenderer={spaceViewRenderer}
				></MarkupProperties>
			)
		);
	}

	private getFields() {
		const {properties, appState} = this.props;
		const {actions} = appState;
		const {spaceViewRenderer} = appState.app;

		const selectedBoundaries = spaceViewRenderer.spaceItemController.boundaryManager.selectedItems as BoundarySpaceMap3D[];
		const selectedXyicons = spaceViewRenderer.spaceItemController.xyiconManager.selectedItems as Xyicon3D[];
		const selectedMarkups = spaceViewRenderer.spaceItemController.markupManager.selectedItems as Markup3D[];
		const allItems = [...selectedXyicons, ...selectedBoundaries, ...selectedMarkups];

		const firstItem = allItems[0];

		const hasPermission = actions.someSpaceItemsHaveGivenPermission(allItems, Permission.Update);

		return properties.map((property) => {
			const isArea = property.name === "Area";

			return (
				<React.Fragment key={`${allItems.map((item) => item?.id).join("_")}_${property.name}`}>
					<Field
						label={property.name}
						className={ReactUtils.cls("propertySetLabel", {hasInfo: property.measure, PropertySet: isArea})}
					>
						{property.name === "Position" && (
							<InfoButton bubbleText="An object's position is calculated starting from the origin point (0,0) of your space (located at the bottom left corner by default). Use the Space Editor's Re-align Background setting to change your space's origin point." />
						)}
						{isArea && (
							<div className="hbox alignCenter width100">
								<ClickToEditInput
									value={this.getAreaSum(allItems.filter((item) => item.spaceItemType === "boundary") as BoundarySpaceMap3D[])}
									disabled={true}
								/>
							</div>
						)}
					</Field>
					{property.measure && (
						<UnitSelector
							unit={this.state.distanceUnitName}
							onChange={this.onChangeDistanceUnit}
						/>
					)}
					<div className="PropertySet">
						{property.parts.map((part) => {
							return allItems.length > 1 ? (
								<MassPropertyInput
									key={part.name}
									propertyName={property.name}
									propertyPartName={part.name}
									propertyRef={part.ref}
									propertyLabel={part.label}
									items={allItems}
									open={this.state.openedProperty === part.name}
									onOpen={this.onMassInputOpen}
									distanceUnitName={this.state.distanceUnitName}
									disabled={
										!hasPermission ||
										allItems.some((item) => (item.spaceItemType === "boundary" || item.spaceItemType === "markup") && pzDzArray.includes(part.name))
									}
									getSpaceItemValue={this.getSpaceItemValue}
								/>
							) : (
								firstItem && (
									<Field
										label={part.label || part.name}
										className="coordinate"
										key={part.name}
									>
										<div className="hbox alignCenter width100">
											<ClickToEditInput
												value={
													actions.formatPropValue(
														this.getSpaceItemValue(property.name, part.ref, firstItem),
														part.name,
														firstItem,
														this.state.distanceUnitName,
													) || "0"
												}
												onChange={(value) => this.onChangeItemProp(value, part.name, firstItem)}
												updating={this.state.updatingValue === part.name}
												inputType="number"
												disabled={
													!hasPermission ||
													((firstItem.spaceItemType === "boundary" || firstItem.spaceItemType === "markup") && pzDzArray.includes(part.name))
												}
											/>
											{this.getInfoButton(part.name, hasPermission)}
										</div>
									</Field>
								)
							);
						})}
					</div>
				</React.Fragment>
			);
		});
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	public override componentDidMount() {
		const {signals} = this.props.appState.app.spaceViewRenderer.spaceItemController;

		signals.itemsTranslated.add(this.forceUpdateArrow);
		signals.itemsRotated.add(this.forceUpdateArrow);
		signals.editedItemModified.add(this.forceUpdateArrow);
	}

	public override componentWillUnmount() {
		const {signals} = this.props.appState.app.spaceViewRenderer.spaceItemController;

		signals.itemsTranslated.remove(this.forceUpdateArrow);
		signals.itemsRotated.remove(this.forceUpdateArrow);
		signals.editedItemModified.remove(this.forceUpdateArrow);
	}

	public override render() {
		const {saveStateToLocalStorage} = this.props;

		return (
			<ToggleContainer
				title="Properties"
				className="Properties"
				saveStateToLocalStorage={saveStateToLocalStorage}
			>
				{this.getMarkupProps()}
				{this.getFields()}
			</ToggleContainer>
		);
	}
}

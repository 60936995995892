import * as React from "react";
import {SVGIcon} from "../SVGIcon";
import {ReactUtils} from "../../../utils/ReactUtils";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {IconButton} from "../IconButton";

export interface IMultiActionButtonProps {
	options: IMultiActionButtonOption[];
	className?: string;
	disabled?: boolean;
	onClick?: (selectedId: string) => void;
	icon?: string;
}

interface IMultiActionButtonOption {
	id: string;
	label: string;
	onClick?: () => void;
	disabled?: boolean;
	icon?: string;
}

interface IMultiActionButtonState {
	open: boolean;
	isMouseOverSmallArrow: boolean;
}

export class MultiActionButton extends React.Component<IMultiActionButtonProps, IMultiActionButtonState> {
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IMultiActionButtonProps) {
		super(props);
		this.state = {
			open: false,
			isMouseOverSmallArrow: false,
		};
	}

	private onFocusLoss = () => {
		this.close();
	};

	private close() {
		this.setState({open: false});
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._container.current, this.onFocusLoss);
	}

	private onClickOption = (id: string, event: React.MouseEvent) => {
		event.stopPropagation();
		this.close();
		const option = this.props.options.find((o) => o.id === id);

		option?.onClick?.();
	};

	private onArrowClick = (event: React.MouseEvent) => {
		event.stopPropagation();

		this.setState({
			open: true,
		});

		FocusLoss.listen(this._container.current, this.onFocusLoss);
	};

	private onClick = (event: React.MouseEvent) => {
		const option = this.props.options[0];

		option?.onClick?.();

		this.props.onClick?.(option?.id);
	};

	private onMouseEnter = () => {
		this.setState({isMouseOverSmallArrow: true});
	};

	private onMouseLeave = () => {
		this.setState({isMouseOverSmallArrow: false});
	};

	public override render() {
		const {options, className, disabled, icon} = this.props;
		const {open, isMouseOverSmallArrow} = this.state;

		const firstOption = options[0];
		const restOptions = options.slice(1).filter((option) => !option.disabled);

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("MultiActionButton Button", {
					[className]: className,
					disabled,
					smallArrowHover: isMouseOverSmallArrow,
				})}
				onClick={this.onClick}
			>
				{icon && <SVGIcon icon={icon} />}
				<span className={ReactUtils.cls("label", {disabled})}>{firstOption?.label}</span>
				{restOptions.length > 0 && (
					<IconButton
						icon="down"
						className="smallArrowDown"
						onClick={this.onArrowClick}
						onMouseEnter={this.onMouseEnter}
						onMouseLeave={this.onMouseLeave}
					/>
				)}
				<div
					className={ReactUtils.cls("options", {open: open})}
					onMouseEnter={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
				>
					{restOptions.map((option) => (
						<div
							key={option.id}
							className="option hbox alignCenter"
							onClick={(e) => this.onClickOption(option.id, e)}
						>
							{option.icon && <SVGIcon icon={option.icon} />}
							<div className="label">{option.label}</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

import type {Object3D} from "three";
import type {PointDouble} from "../../../../../../../../generated/api/base";

export interface IObjectWithRotationHandler {
	getRotationIconObject: (target: Object3D) => Object3D;
	position: PointDouble;
	orientation: number;
	typeName: string;
}

export const keyForObjectsWithRotationHandlersInUserData = "objectsWithRotationHandlers";

import styled from "styled-components";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Catalog} from "../../../data/models/Catalog";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {AppState} from "../../../data/state/AppState";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {ISpaceItemContainerProps} from "../../modules/space/spaceeditor/ui/toolbar/SpaceItemContainer";
import {SpaceItemContainerV5} from "../spaceeditor/SpaceItemContainerV5";
import {useAppStore} from "../../../StateManager";
import {FlexCenterStyle} from "../styles/styles";
import GripDotsVerticalIcon from "../icons/grip-dots-vertical.svg?react";

interface IDraggableXyiconCatalogItemProps extends ISpaceItemContainerProps {
	item: Catalog | Xyicon;
	isSelected: boolean;
	transport?: TransportLayer;
	appState?: AppState;
	queryString: string;
	userCatalogPermission?: Permission;
	isExternalXyicon?: boolean;
}

export function DraggableXyiconCatalogItemV5(props: IDraggableXyiconCatalogItemProps) {
	const store = useAppStore();
	const {appState} = store;

	function userXyiconPermission() {
		return appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);
	}

	const {
		item,
		isSelected,
		onPointerDown,
		onPointerMove,
		onPointerUp,
		showInfoButton,
		onBreakLinkClick,
		onDuplicateCatalogClick,
		onCreateUnplottedXyiconClick,
		queryString,
		userCatalogPermission,
		isExternalXyicon,
	} = props;

	return (
		<DraggableXyiconCatalogItemStyled
			$selected={isSelected}
			$noPermission={userXyiconPermission() < Permission.Update}
			$isExternalXyicon={isExternalXyicon}
		>
			{!isExternalXyicon && <GripDotsVerticalIcon style={{width: "16px", left: 0, position: "absolute"}} />}
			<SpaceItemContainerV5
				item={item}
				onPointerDown={onPointerDown}
				onPointerMove={onPointerMove}
				onPointerUp={onPointerUp}
				showInfoButton={showInfoButton}
				showDeleteButton={item.ownFeature === XyiconFeature.Xyicon}
				onBreakLinkClick={onBreakLinkClick}
				onDuplicateCatalogClick={onDuplicateCatalogClick}
				onCreateUnplottedXyiconClick={onCreateUnplottedXyiconClick}
				queryString={queryString}
				userCatalogPermission={userCatalogPermission}
			/>
		</DraggableXyiconCatalogItemStyled>
	);
}

const DraggableXyiconCatalogItemStyled = styled.div<{$selected: boolean; $noPermission: boolean; $isExternalXyicon: boolean}>`
	${FlexCenterStyle};
	padding: 4px 8px 4px 16px;
	cursor: pointer;
	position: relative;

	& > svg {
		opacity: 0;
		transition: ease-in-out opacity 0.2s;
	}

	&:hover {
		& > svg {
			opacity: 1;
		}

		.buttonContainer {
			border: solid 2px transparent;
			border-radius: 4px;
		}
	}
`;

import styled from "styled-components";
import {colorPalette} from "../../../styles/colorPalette";
import {VerticalFlexStyle, baseDistance, fontSize, fontWeight, radius} from "../../../styles/styles";

export const CaptionPanel = styled.div`
	${VerticalFlexStyle};
	min-height: 0;
	flex: 1;

	.caption {
		padding-bottom: ${baseDistance.sm};
	}

	.container {
		overflow-y: auto;

		.head {
			display: flex;
			padding-bottom: ${baseDistance.oh};
			flex-direction: column;
			gap: ${baseDistance.sm};

			.SearchField {
				width: 100%;
				border-radius: ${radius.sm};
			}
		}

		.ToggleContainer {
			&:not(.FieldSection) {
				border-top: none;
			}

			.filter-title {
				font-size: ${fontSize.lg};
				gap: ${baseDistance.xs};
				display: flex;
				align-items: center;
				color: ${colorPalette.primary.c500Primary};
				text-transform: uppercase;
				font-weight: ${fontWeight.bold};
				cursor: pointer;

				span.btn {
					fill: ${colorPalette.primary.c500Primary};
				}
			}

			.children {
				flex-wrap: wrap;
				flex-direction: row;
				gap: 0px;
				padding: ${baseDistance.sm} 0px 0px;

				.CaptionSettings {
					display: flex;
					flex-direction: column;
					gap: ${baseDistance.sm};
					padding: ${baseDistance.sm};
				}

				.checkBoxContainer {
					padding-left: ${baseDistance.xs};
					min-height: 32px;
					border-radius: ${radius.sm};
					font-size: ${fontSize.md};
					flex: 1 1 240px;
					gap: ${baseDistance.sm};

					.checkBoxLabel {
						display: flex;
						align-items: center;
					}

					.CaptionStyleButtonContainer {
						margin-left: auto;
						padding: ${baseDistance.xs};
						visibility: hidden;

						svg.icon {
							width: 16px;
							height: 16px;
						}

						> span {
							margin-right: ${baseDistance.xs};
						}

						&.active {
							visibility: visible;
						}

						.resetBtn {
							display: none;
						}

						> .active {
							fill: ${colorPalette.primary.c500Primary};
						}
					}

					&:hover {
						background-color: ${colorPalette.gray.c200Light};

						.CaptionStyleButtonContainer {
							visibility: visible;
							padding: ${baseDistance.xs};
						}

						.resetBtn.active {
							display: flex;
						}
					}
				}
			}
		}
	}
`;

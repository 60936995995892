import {css, styled} from "styled-components";
import type {MouseEvent} from "react";
import {cloneElement, useEffect, useReducer, useState} from "react";
import {useAppStore} from "../../StateManager";
import {XyiconFeature} from "../../generated/api/base";
import {View} from "../../data/models/View";
import type {ExportFormat} from "../../data/exporters/BaseExporter";
import {ExporterFactory} from "../../data/exporters/ExporterFactory";
import {Functions} from "../../utils/function/Functions";
import type {IModel} from "../../data/models/Model";
import {HorizontalAlignment} from "../../utils/dom/DomUtils";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {notify} from "../../utils/Notify";
import {NotificationType} from "../notification/Notification";
import {ButtonStyled, ButtonV5} from "./button/ButtonV5";
import CirclePlusIcon from "./icons/circle-plus.svg?react";
import SlidersIcon from "./icons/sliders.svg?react";
import Columns3Icon from "./icons/columns-3.svg?react";
import DotsHorizontalIcon from "./icons/dots-horizontal.svg?react";
import ArrowDownToSquareIcon from "./icons/arrow-down-to-square.svg?react";
import FileArrowRightIcon from "./icons/file-arrow-right.svg?react";
import GlobeIcon from "./icons/globe.svg?react";
import UserIcon from "./icons/user.svg?react";
import UsersIcon from "./icons/users.svg?react";
import CloseIcon from "./icons/xmark.svg?react";
import {FlexCenter, FlexCenterStyle, baseDistance, fontWeight, zIndex} from "./styles/styles";
import {ManageVersionSetsV5} from "./modules/space/versionset/ManageVersionSetsV5";
import {DropdownButtonStyled, DropdownButtonV5} from "./interaction/DropdownButtonV5";
import {FeatureImportPanelV5} from "./abstract/view/FeatureImportPanelV5";
import {ViewSelectorStyled} from "./widgets/ViewSelector.styled";
import {SearchFieldV5} from "./input/search/SearchFieldV5";
import {UnsavedLayoutV5} from "./abstract/table/UnsavedLayoutV5";
import {IconButtonStyled, IconButtonV5} from "./interaction/IconButtonV5";
import {SearchFieldStyled} from "./input/search/SearchField.styled";
import {TextInputV5} from "./details/datatypes/TextInputV5";
import {ConfirmWindowV5} from "./popup/ConfirmWindowV5";
import {colorPalette} from "./styles/colorPalette";
import {SelectInputStyled, SelectInputV5} from "./input/select/SelectInputV5";
import {FilterChangeNotificationV5} from "./viewbar/FilterChangeNotificationV5";
import {ViewSharingPopup} from "./sharing/ViewSharingPopup";
import {ManageColumnsPanelV5} from "./abstract/table/ManageColumnsPanelV5";

const ActionBarV5Styled = styled.div`
	height: 64px;
	align-items: center;
	padding: 0 16px;
	${FlexCenterStyle};
	justify-content: space-between;

	${SearchFieldStyled}, ${SelectInputStyled} {
		border: none;
	}

	${DropdownButtonStyled} {
		width: 32px;
		margin: 0;

		&.view {
			width: auto;
		}
	}

	.moduleName {
		font-weight: ${fontWeight.bold};
	}
`;

const LineStyled = styled.div`
	height: 32px;
	width: 1px;
	background-color: #37474f;
	position: relative;
	left: 0;
	margin: 8px;
`;

const ViewRowStyled = styled.div<{$isTitle?: boolean}>`
	display: flex;
	align-items: center;
	width: 100%;
	gap: ${baseDistance.xs};

	${DropdownButtonStyled} {
		margin-left: auto;
	}

	.title {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 280px;
		display: flex;
		align-items: end;
		gap: 8px;

		${(props) => {
			if (props.$isTitle) {
				return css`
					font-weight: ${fontWeight.semiBold};
					color: black;
				`;
			}
		}}
	}

	input {
		border: 1px solid ${colorPalette.primary.c500Primary};
		color: ${colorPalette.primary.c500Primary};
	}
`;

const ExportDotsStyled = styled(IconButtonV5)`
	svg {
		width: 16px;
		height: 16px;
	}
`;

interface IActionBarV5Props {
	readonly feature: XyiconFeature;
	readonly items: IModel[];
	readonly createButtonRef: React.MutableRefObject<HTMLDivElement>;
	readonly manageColumnRef: React.MutableRefObject<HTMLDivElement>;
	readonly filterCount?: number;
	readonly isFilterPanelOpen?: boolean;
	readonly toggleFilterPanel: (e: React.MouseEvent) => void;
	readonly onSearchChange: (value: string) => void;
	readonly create: (onClose?: () => void) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	readonly onCloseCreate: () => void;
	readonly onResetFilters: () => void;
}

let notificationShown: boolean = false;

export const ActionBarV5 = (props: IActionBarV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const {toggleFilterPanel, feature, items, onSearchChange} = props;
	const [openManageVersionsPanel, setOpenManageVersionsPanel] = useState<boolean>(false);
	const [openManageColumns, setOpenManageColumns] = useState<boolean>(false);
	const [openImportPanel, setOpenImportPanel] = useState<boolean>(false);
	const [viewInEditMode, setViewInEditMode] = useState<View | null>(null);
	const [openCreatePopup, setOpenCreatePopup] = useState<boolean>(false);
	const [isOpenSelectView, setIsOpenSelectView] = useState<boolean>(false);
	const [viewItemForSharePanel, setViewItemForSharePanel] = useState<View | null>(null);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const onChangeView = (view: View) => {
		appState.actions.selectView(view);

		if (viewInEditMode) {
			setViewInEditMode(null);
		}
	};

	const onExport = (type: ExportFormat) => {
		const exporter = ExporterFactory.createExporter(type, appState);

		if (exporter) {
			exporter.exportView(appState.actions.getViewById(appState.selectedViewId[feature]), items);
		}
	};

	const onImport = () => {
		setOpenImportPanel(true);
	};

	const onDuplicateClick = async (view: View) => {
		if (view && !viewInEditMode) {
			const duplicate = await view.duplicate();

			if (duplicate) {
				setViewInEditMode(duplicate);
			}
		}
	};

	const onDeleteClick = async (view: View) => {
		const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

		if (confirmed) {
			await appState.app.transport.services.view.delete(view.id);
			forceUpdate();
		}
	};

	const onCloseSharePanel = () => {
		setViewItemForSharePanel(null);
	};

	const onRenameClick = (view: View) => {
		setViewInEditMode(view);
	};

	const onMarkAsGlobalClick = async (view: View) => {
		await appState.app.transport.services.view.markViewAsGlobal(view);
		forceUpdate();
	};

	const getOptions = (view: View) => {
		const user = appState.user;
		const sharedWithMe = view.ownedBy !== user?.id && !view.isGlobal;
		const sharedWithMeWithEdit = sharedWithMe && view?.getPermission(user.id) > 1;
		const userIsOwner = view?.ownedBy === user.id && !view.isGlobal;
		const global = view.isGlobal;
		const options = [];

		if (userIsOwner || sharedWithMeWithEdit) {
			options.push({
				label: "Share",
				onClick: () => setViewItemForSharePanel(view),
			});
		}

		if (userIsOwner) {
			options.push({
				label: "Change Owner",
				onClick: () => setViewItemForSharePanel(view),
			});
		}

		if (user.isAdmin && (userIsOwner || sharedWithMeWithEdit)) {
			options.push({
				label: "Mark as Global View",
				onClick: () => onMarkAsGlobalClick(view),
			});
		}

		if (userIsOwner || sharedWithMeWithEdit || (global && user.isAdmin)) {
			options.push({
				label: "Rename",
				onClick: () => onRenameClick(view),
			});
		}

		if (userIsOwner || (global && user.isAdmin)) {
			options.push({
				label: "Delete",
				onClick: () => onDeleteClick(view),
			});
		}

		options.push({
			label: "Duplicate",
			onClick: () => onDuplicateClick(view),
		});

		return options;
	};

	const openSelectView = () => {
		setIsOpenSelectView(true);
		notificationShown = false;
		onNewViewClick();
	};

	const {create, createButtonRef, manageColumnRef} = props;

	const views = appState.actions.getViews(feature).toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name));
	const selectedView = appState.actions.getSelectedView(feature);

	useEffect(() => {
		if (views.length === 0 && !notificationShown) {
			notify(appState.app.notificationContainer, {
				type: NotificationType.Message,
				title: `You don't have any views`,
				lifeTime: Infinity,
				description: "You can request views to be shared with you directly from an admin, or create one yourself",
				buttonLabel: "Create",
				onActionButtonClick: openSelectView,
			});
			notificationShown = true;
		}
	});

	const onRenameApply = async (value: string, view: View) => {
		const isNameValid = appState.actions.isNameValidForView(value, feature, view.id);

		if (isNameValid) {
			if (value !== view.name) {
				view.name = value;
				await appState.app.transport.services.view.update(view.getData());
				setViewInEditMode(null);
			}
		}
	};

	const onNewViewClick = async (event?: MouseEvent) => {
		event?.stopPropagation();

		const baseName = "New View";
		const view = View.createNew(props.feature, baseName, appState);

		const actions = appState.actions;
		let counter = 1;

		while (!actions.isNameValidForView(view.name, feature, view.id)) {
			view.name = `${baseName} (${counter++})`;
		}

		const {result: viewData} = await appState.app.transport.services.view.create(view.getData(), props.feature);

		// Select the newly created view
		const newView = appState.actions.getViewById(viewData.viewID);

		onChangeView(newView);
		setViewInEditMode(newView);

		setTimeout(() => {
			const objDiv = document.getElementById("DropdownOptionsV5");

			objDiv.scrollTop = objDiv.scrollHeight;
		});
	};

	const renderOption = (view: View, renderSelectedWhenClosed: boolean = false, isTitle: boolean = false) => {
		const user = appState.user;
		const userIsOwner = view?.ownedBy === user.id;
		const IconComponent = view?.isGlobal ? GlobeIcon : userIsOwner ? UserIcon : UsersIcon;

		if (!renderSelectedWhenClosed && view === viewInEditMode) {
			return (
				<ViewRowStyled onClick={Functions.stopPropagation}>
					<IconComponent />
					<TextInputV5
						style={{color: colorPalette.primary.c500Primary, borderColor: colorPalette.primary.c500Primary}}
						value={view.name}
						onChange={(value) => {
							onRenameApply(value, view);
						}}
						autoFocus={true}
					/>
				</ViewRowStyled>
			);
		}

		return (
			<ViewRowStyled $isTitle={isTitle}>
				<div className="title">
					{!isTitle && <IconComponent />}
					{view.name}
				</div>
				{!renderSelectedWhenClosed && (
					<DropdownButtonV5
						button={<DotsHorizontalIcon />}
						options={getOptions(view)}
						optionsZIndex={zIndex.contextOptions + 1}
					/>
				)}
			</ViewRowStyled>
		);
	};

	const onCloseCreate = () => {
		props.onCloseCreate?.();
		setOpenCreatePopup(false);
	};

	return (
		<>
			<ActionBarV5Styled>
				<FlexCenter $gap="8px">
					{[XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog, XyiconFeature.Space].includes(appState.selectedFeature) && (
						<ViewSelectorStyled>
							<SelectInputV5
								options={views}
								render={renderOption}
								renderSelectedWhenClosed={(view: View) => renderOption(view, true, true)}
								selected={selectedView}
								onChange={onChangeView}
								stringifyOption={(view: View) => view.name}
								searchBarMode={views.length > 0 ? "always on" : "always off"}
								nextToSearchBarNode={
									<IconButtonV5
										IconComponent={CirclePlusIcon}
										onClick={onNewViewClick}
									/>
								}
								searchBarFullWidth
								dropdownFixedWidth="280px"
								dropdownMaxHeight="400px"
								onClose={() => setViewInEditMode(null)}
								preventOptionClickOnSubItem={true}
								focused={isOpenSelectView}
							/>
						</ViewSelectorStyled>
					)}
					{feature == XyiconFeature.Report && (
						<>
							<div className="moduleName">REPORTS</div>
							<LineStyled />
						</>
					)}
					<SearchFieldV5
						value=""
						onInput={onSearchChange}
						minimized={true}
					/>
					{feature != XyiconFeature.Report && (
						<FilterButtonStyled $active={props.isFilterPanelOpen}>
							<ButtonV5
								onClick={toggleFilterPanel}
								label={props.filterCount ? props.filterCount.toString() : "Filter"}
								type="tertiary"
								active={props.isFilterPanelOpen}
							>
								<SlidersIcon />
							</ButtonV5>
							{props.filterCount > 0 && (
								<IconButtonV5
									onClick={props.onResetFilters}
									IconComponent={CloseIcon}
								/>
							)}
						</FilterButtonStyled>
					)}
					{feature === XyiconFeature.Space && (
						<>
							<ButtonV5
								onClick={() => setOpenManageVersionsPanel(true)}
								label="Manage Versions"
								type="tertiary"
							>
								<SlidersIcon />
							</ButtonV5>
						</>
					)}
					{[XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog, XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(feature) && (
						<>
							<ButtonV5
								onClick={() => setOpenManageColumns(true)}
								label="Manage Columns"
								type="tertiary"
								ref={manageColumnRef}
							>
								<Columns3Icon />
							</ButtonV5>
						</>
					)}
					{feature != XyiconFeature.Report && (
						<DropdownButtonV5
							options={[
								{
									label: "Export",
									IconComponent: FileArrowRightIcon,
									options: [
										{
											onClick: () => onExport("excel"),
											label: "Export to Excel",
										},
										{
											onClick: () => onExport("csv"),
											label: "Export to CSV",
										},
									],
								},
								{
									label: "Import",
									onClick: onImport,
									IconComponent: ArrowDownToSquareIcon,
								},
							]}
							horizontalAlignment={HorizontalAlignment.left}
							button={
								<ExportDotsStyled
									IconComponent={DotsHorizontalIcon}
									onClick={Functions.emptyFunction}
								/>
							}
						/>
					)}
				</FlexCenter>
				<FlexCenter $gap="8px">
					<UnsavedLayoutV5 selectedView={selectedView} />
					<FilterChangeNotificationV5
						actions={appState.actions}
						feature={feature}
					/>
					<ButtonV5
						onClick={() => setOpenCreatePopup(true)}
						label="CREATE"
						ref={createButtonRef}
					>
						<CirclePlusIcon />
					</ButtonV5>
				</FlexCenter>
			</ActionBarV5Styled>
			{openManageVersionsPanel && <ManageVersionSetsV5 onClose={() => setOpenManageVersionsPanel(false)} />}
			{openManageColumns && (
				<ManageColumnsPanelV5
					feature={feature}
					view={selectedView}
					onClose={() => setOpenManageColumns(false)}
					parentRef={manageColumnRef}
				/>
			)}
			{openImportPanel && (
				<FeatureImportPanelV5
					onClose={() => setOpenImportPanel(false)}
					calledFromThisModule={props.feature}
				/>
			)}
			{openCreatePopup && create && cloneElement(create(onCloseCreate))}
			{viewItemForSharePanel && (
				<ViewSharingPopup
					view={viewItemForSharePanel}
					onClose={onCloseSharePanel}
				/>
			)}
		</>
	);
};

const FilterButtonStyled = styled.div<{$active: boolean}>`
	${FlexCenterStyle};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	${(props) =>
		props.$active &&
		css`
			background-color: ${colorPalette.primary.c200Light};
		`};
	border-radius: 4px;

	${ButtonStyled} {
		background-color: inherit;
	}

	${IconButtonStyled} {
		transform: scale(0.7);
		background-color: inherit;
		color: ${colorPalette.primary.c500Primary};
	}
`;

import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";
import {SVGIcon} from "./SVGIcon";
import type {IToggleButtonProps} from "./ToggleButton";
import {ToggleButton} from "./ToggleButton";

export class IconToggle extends React.PureComponent<IToggleButtonProps, {}> {
	public override render() {
		return (
			<ToggleButton
				{...this.props}
				render={(props, state, onClick) => {
					const cls = ReactUtils.cls(this.props.className, {
						button: true,
						selected: state.value,
						disabled: props.disabled,
					});

					return (
						<span
							className={cls}
							onClick={onClick}
							onMouseDown={(event) => event.preventDefault()}
							title={props.title}
						>
							<SVGIcon
								icon={props.icon}
								classNames={`${props.icon || ""}`}
							/>
							{props.label}
						</span>
					);
				}}
			/>
		);
	}
}

import * as React from "react";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {SpaceEditorMode} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import type {DockableTitle} from "../../../../modules/space/spaceeditor/ui/toolbar/Dockable";
import type {SelectionToolType, SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {SpaceToolBarType} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {createToolButtonV5} from "../../../../modules/space/spaceeditor/ui/toolbar/ToolBarUtils";
import type {Color} from "../../../../../generated/api/base";
import type {SetPinTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/SetPinTool";
import {SpaceAlignToolBarV5} from "./SpaceAlignToolBarV5";
import {PrimaryToolBarV5} from "./PrimaryToolBarV5";
import {MarkupToolBarV5} from "./MarkupToolBarV5";
import type {TTitleAlignment} from "./ToolButtonV5";

interface ISpaceToolBarProps {
	spaceViewRenderer: SpaceViewRenderer;
	mode: SpaceEditorMode;
	setDockableOpen: (value: boolean, title?: DockableTitle) => void;
	isCatalogOpen: boolean;
	isBoundaryTypesWindowOpen: boolean;
	isUnplottedXyiconsOpen: boolean;
	activeToolId: SpaceTool;
	setActiveTool: (id: SpaceTool) => void;
	selectionToolType: SelectionToolType;
	activeToolBar: SpaceToolBarType;
	setActiveToolBar: (toolbar: SpaceToolBarType) => void;
}
export class SpaceToolBarV5 extends React.Component<ISpaceToolBarProps> {
	private _primaryToolBarRef = React.createRef<PrimaryToolBarV5>();

	constructor(props: ISpaceToolBarProps) {
		super(props);

		this.updateActiveTool();
	}

	private openDefaultToolBar = () => {
		this.openToolBar(SpaceToolBarType.PrimaryToolBar);
	};

	private openMarkupToolBar = () => {
		this.openToolBar(SpaceToolBarType.MarkupToolBar);
	};

	private openSpaceAlignToolBar = () => {
		this.openToolBar(SpaceToolBarType.SpaceAlignToolBar);
	};

	private openToolBar(toolBar: SpaceToolBarType) {
		if (!this.props.activeToolId.includes("tempMeasure")) {
			this.props.setActiveTool(
				this.props.mode === SpaceEditorMode.ALIGN || toolBar === SpaceToolBarType.SpaceAlignToolBar ? "pan" : this.props.selectionToolType,
			);
		}
		this.props.setActiveToolBar(toolBar);
	}

	private createToolButton = (id: SpaceTool, tooltipAlignment: TTitleAlignment, color?: Color) => {
		return createToolButtonV5(this.props.spaceViewRenderer, id, this.props.activeToolId, this.props.setActiveTool, tooltipAlignment, color);
	};

	private updateActiveTool() {
		const toolManager = this.props.spaceViewRenderer.toolManager;
		const toolConfig = this.props.spaceViewRenderer.tools[this.props.activeToolId];

		if (toolManager.activeTool !== toolConfig.tool) {
			toolManager.activeTool?.deactivate();
			toolManager.activeTool = toolConfig.tool;
			toolManager.activeTool.activate();
		}
	}

	public get primaryToolBarRef() {
		return this._primaryToolBarRef;
	}

	private updateSetPinTool() {
		const setPinTool = this.props.spaceViewRenderer.tools.setPin.tool as SetPinTool;

		if (this.props.activeToolBar === SpaceToolBarType.SpaceAlignToolBar || this.props.mode === SpaceEditorMode.ALIGN) {
			setPinTool.addListeners();
		} else {
			setPinTool.removeListeners();
		}
	}

	public override componentDidMount() {
		this.props.setActiveTool(this.props.activeToolId);
		this.updateSetPinTool();
	}

	public override componentDidUpdate() {
		this.updateActiveTool();
		this.updateSetPinTool();
	}

	public override componentWillUnmount() {
		const toolManager = this.props.spaceViewRenderer.toolManager;

		toolManager.activeTool.deactivate();

		const setPinTool = this.props.spaceViewRenderer.tools.setPin.tool as SetPinTool;

		setPinTool.removeListeners();
	}

	public override render() {
		return (
			<>
				<PrimaryToolBarV5
					ref={this._primaryToolBarRef}
					active={this.props.activeToolBar === SpaceToolBarType.PrimaryToolBar}
					mode={this.props.mode}
					activeToolId={this.props.activeToolId}
					setActiveTool={this.props.setActiveTool}
					spaceViewRenderer={this.props.spaceViewRenderer}
					setDockableOpen={this.props.setDockableOpen}
					isCatalogOpen={this.props.isCatalogOpen}
					isBoundaryTypesWindowOpen={this.props.isBoundaryTypesWindowOpen}
					isUnplottedXyiconsOpen={this.props.isUnplottedXyiconsOpen}
					openMarkupToolBar={this.openMarkupToolBar}
					openSpaceAlignToolBar={this.openSpaceAlignToolBar}
					selectionToolType={this.props.selectionToolType}
					createToolButton={this.createToolButton}
					onToolClick={this.props.setActiveTool}
					tools={this.props.spaceViewRenderer.tools}
				/>
				{this.props.mode === SpaceEditorMode.NORMAL && (
					<MarkupToolBarV5
						active={this.props.activeToolBar === SpaceToolBarType.MarkupToolBar}
						activeToolId={this.props.activeToolId}
						openDefaultToolBar={this.openDefaultToolBar}
						selectionToolType={this.props.selectionToolType}
						createToolButton={this.createToolButton}
						onToolClick={this.props.setActiveTool}
						tools={this.props.spaceViewRenderer.tools}
						spaceViewRenderer={this.props.spaceViewRenderer}
					/>
				)}
				{this.props.mode === SpaceEditorMode.NORMAL && (
					<SpaceAlignToolBarV5
						active={this.props.activeToolBar === SpaceToolBarType.SpaceAlignToolBar}
						createToolButton={this.createToolButton}
						openDefaultToolBar={this.openDefaultToolBar}
					/>
				)}
			</>
		);
	}
}

import {computed, observable, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {XyiconFeature, MarkupType} from "../../generated/api/base";
import {THREEUtils} from "../../utils/THREEUtils";
import {
	MarkupsWithArrowHeads,
	MarkupsWithCustomText,
	curveHeight,
	curvePeriod,
	dashSize,
	gapSize,
	highlightOpacity,
	highlightRadius,
} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import type {IRGBObject} from "../../utils/ColorUtils";
import {ColorUtils} from "../../utils/ColorUtils";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {HorizontalAlignment, VerticalAlignment} from "../../utils/dom/DomUtils";
import {ImageUtils} from "../../utils/image/ImageUtils";
import type {MarkupCallout} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/markups/MarkupCallout";
import {
	calculateArrowHead,
	getArrowPointsForMarkupCallout,
	getDefaultTargetForMarkupCallout,
	getUnrotatedCornersFromAB,
	MarkupUtils,
} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {Markup3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import type {MarkupDto, TextDto, PointDouble} from "../../generated/api/base";
import {getCorrectionMultiplierForSpaceItem} from "../../ui/modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {MathUtils} from "../../utils/math/MathUtils";
import type {SupportedFontName} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import type {IEditableItemModel} from "./Model";

/**
 * The minimum necessary data to create a markup in space
 */
export interface IMarkupMinimumData extends IEditableItemModel, MarkupDto {
	type: MarkupType;
	color: string;
	text: TextDto;
	fillTransparency: number;
	lineThickness: number;
	settings: string;
}

export class Markup implements IEditableItemModel, IMarkupMinimumData, MarkupDto {
	public readonly ownFeature = XyiconFeature.Markup;
	private _appState: AppState;
	private _isTemp: boolean;

	@observable
	private _data: MarkupDto;
	@observable
	private _savedSettings: string = null;

	constructor(data: MarkupDto, appState: AppState, isTemp: boolean = false) {
		makeObservable(this);
		this._appState = appState;
		this._isTemp = isTemp;
		this.applyData(data);
	}

	public applyData(data: Partial<MarkupDto>) {
		const isTextObjectValid = !!data.svgData.text?.fontFamily;
		const markupText = isTextObjectValid ? data.svgData.text : Markup.defaultText;

		this._data = {
			...this._data,
			...data,
		};

		if (!isTextObjectValid) {
			this._data.svgData.text = markupText;
		}

		this._data.svgData.lineThickness = this._data.svgData.lineThickness ?? MarkupUtils.defaultLineThickness;
		this._data.svgData.fillTransparency = this._data.svgData.fillTransparency ?? 1 - MarkupUtils.getDefaultFillOpacityForType(this.type);

		this.settings = this._data.settings;
		this._savedSettings = this._data.settings;
	}

	public static get defaultText(): TextDto {
		return {
			content: "",
			fontColor: {
				hex: "000000",
				transparency: 0,
			},
			fontSize: 48,
			fontFamily: "Roboto",
			isBold: false,
			isItalic: false,
			isUnderlined: false,
			verticalAlignment: VerticalAlignment.center,
			horizontalAlignment: HorizontalAlignment.center,
		};
	}

	public set settings(settings: string) {
		this._data.settings = settings;

		if (!this._data.settings) {
			const defaultSettings: IMarkupSettingsData = {};

			if (MarkupsWithArrowHeads.includes(this.type)) {
				defaultSettings.arrowHeadSize = MarkupUtils.defaultArrowHeadSize;
			}

			if (this.type === MarkupType.Text_Box || this.type === MarkupType.Callout) {
				defaultSettings.isSizeFixed = false;
			}

			if (this.type === MarkupType.Callout) {
				defaultSettings.target = getDefaultTargetForMarkupCallout(
					this._data.svgData.geometryData,
					this.fontFamily,
					this._appState.app.graphicalTools.spaceViewRenderer,
				);
			}

			this._data.settings = JSON.stringify(defaultSettings);
		}
	}

	@computed
	public get settings() {
		return this._data.settings || null;
	}

	@computed
	private get settingsData(): IMarkupSettingsData {
		return JSON.parse(this._data.settings);
	}

	@computed
	public get isSettingsSaved(): boolean {
		return this.settings === this._savedSettings;
	}

	@computed
	public get savedSettingsData(): IMarkupSettingsData {
		return JSON.parse(this._savedSettings);
	}

	public setGeometryData(geometryData: PointDouble[]) {
		this._data.svgData.geometryData = geometryData;
	}

	public applyTextData(data: Partial<TextDto>) {
		this._data.svgData.text = {
			...this._data.svgData.text,
			...data,
		};
	}

	private spaceCoordsToCanvasCoordsTransition(
		geometryData: PointDouble[],
		dimension: number,
		strokeWidthToBBoxRatio: number = this.lineThickness / dimension,
	): PointDouble[] {
		return THREEUtils.spaceCoordsToThumbnailCoords(geometryData, dimension, strokeWidthToBBoxRatio);
	}

	@computed
	public get text() {
		return this._data.svgData.text;
	}

	public get fontFamily(): SupportedFontName {
		return (this._data.svgData?.text?.fontFamily as SupportedFontName) || "Roboto";
	}

	public setFillTransparency(value: number) {
		this._data.svgData.fillTransparency = value;
	}

	public setLineThickness(value: number) {
		this._data.svgData.lineThickness = value;
	}

	public setArrowHeadSize(value: number) {
		const settingsData = this.settingsData;

		settingsData.arrowHeadSize = value;

		this._data.settings = JSON.stringify(settingsData);
	}

	public setIsSizeFixed(value: boolean) {
		const settingsData = this.settingsData;

		settingsData.isSizeFixed = value;

		this._data.settings = JSON.stringify(settingsData);
	}

	public setTarget(value: PointDouble) {
		const settingsData = this.settingsData;

		settingsData.target = value;
		this._data.settings = JSON.stringify(settingsData);
	}

	public setTextOffset(value: PointDouble) {
		const settingsData = this.settingsData;

		settingsData.textOffset = value;
		this._data.settings = JSON.stringify(settingsData);
	}

	public setTextOrientation(value: number) {
		const settingsData = this.settingsData;

		settingsData.textOrientation = MathUtils.clampRadianBetween0And2PI(value);
		this._data.settings = JSON.stringify(settingsData);
	}

	public get textOffset(): PointDouble {
		return this.settingsData.textOffset ?? {x: 0, y: 0};
	}

	public get textOrientation(): number {
		return this.settingsData.textOrientation ?? 0;
	}

	public resetSettingsToSaved() {
		this._data.settings = this._savedSettings;
	}

	// hex without #
	public setColor(color: string) {
		this._data.svgData.color = color;
	}

	public setSpaceId(spaceId: string) {
		this._data.spaceID = spaceId;
	}

	public setType(type: MarkupType) {
		this._data.type = type;
	}

	public get spaceId() {
		return this._data.spaceID;
	}

	public get space() {
		return this._appState.actions.getSpaceById(this.spaceId);
	}

	public get id() {
		return this._data.markupID;
	}

	@computed
	public get portfolioId() {
		return this._data.portfolioID;
	}

	@computed
	public get type() {
		return this._data.type;
	}

	@computed
	public get fontColor() {
		return this.text.fontColor;
	}

	@computed
	public get typeName() {
		return MarkupType[this.type];
	}

	public get geometryData() {
		return this._data.svgData.geometryData;
	}

	@computed
	public get fillTransparency() {
		return this._data.svgData.fillTransparency;
	}

	@computed
	public get lineThickness() {
		return this._data.svgData.lineThickness;
	}

	@computed
	public get arrowHeadSize() {
		return this.settingsData.arrowHeadSize || MarkupUtils.defaultArrowHeadSize;
	}

	@computed
	public get isSizeFixed() {
		return this.settingsData.isSizeFixed;
	}

	@computed
	public get target(): PointDouble {
		return (
			this.settingsData.target ||
			getDefaultTargetForMarkupCallout(this._data.svgData.geometryData, this.fontFamily, this._appState.app.graphicalTools.spaceViewRenderer)
		);
	}

	public get color() {
		return this._data.svgData.color;
	}

	public get orientation() {
		return this._data.orientation ?? 0;
	}

	public setOrientation(orientation: number) {
		this._data.orientation = orientation;
	}

	@computed
	public get position() {
		return {
			...THREEUtils.getCenterFromGeometryData(this.geometryData, this.orientation),
			z: 0,
		};
	}

	@computed
	public get positionX() {
		return this.position.x;
	}

	@computed
	public get positionY() {
		return this.position.y;
	}

	@computed
	public get positionZ() {
		return this.position.z;
	}

	public setPosition(newPos: PointDouble) {
		const center = this.position;
		const xDiff = newPos.x - center.x;
		const yDiff = newPos.y - center.y;

		const newGeometryData = this.geometryData.map((data) => {
			return {
				x: data.x + xDiff,
				y: data.y + yDiff,
			};
		});

		this.setGeometryData(newGeometryData);
	}

	public get isTypeChangeable(): boolean {
		return MarkupsWithCustomText.includes(this.type);
	}

	@computed
	public get spaceName() {
		return this._appState.actions.getSpaceById(this._data.spaceID).name || "";
	}

	@computed
	public get textContent() {
		const {spaceViewRenderer} = this._appState.app;
		const isMarkupOnMountedSpace = spaceViewRenderer.space?.id === this.spaceId;
		const markup3D = isMarkupOnMountedSpace
			? (spaceViewRenderer.markupManager.getItemById(this.id) as Markup3D)
			: spaceViewRenderer.markupManager.createSpaceItem3DFromModel(this);
		const textContent = markup3D?.textContent ?? "";

		if (!isMarkupOnMountedSpace) {
			markup3D?.destroy(false);
		}

		return textContent;
	}

	public get isTemp() {
		return this._isTemp;
	}

	@computed
	public get typeNameAsText() {
		return this.typeName.replaceAll("_", " ");
	}

	@computed
	public get thumbnail() {
		const spaceViewRenderer = this._appState.app.spaceViewRenderer;
		const isMarkupOnMountedSpace = spaceViewRenderer.space?.id === this.spaceId;
		const markup3D = isMarkupOnMountedSpace
			? (spaceViewRenderer.markupManager.getItemById(this.id) as Markup3D)
			: spaceViewRenderer.markupManager.createSpaceItem3DFromModel(this);

		if (!markup3D) {
			// This can happen, if the markup has just been deleted
			return "";
		}

		const canvas = ImageUtils.canvas;
		const ctx = ImageUtils.ctx;

		ctx.save();
		const dimension = Constants.RESOLUTION.XYICON;
		const correctionMultiplier = getCorrectionMultiplierForSpaceItem(spaceViewRenderer, this);

		canvas.width = canvas.height = dimension;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		const colorAsRgb = ColorUtils.hex2rgb(this.color, 1, "RGBObject") as IRGBObject;

		ctx.strokeStyle = `#${this.color}`;
		ctx.lineWidth = this.lineThickness;
		ctx.lineCap = "round";
		ctx.lineJoin = "round";

		let canvasCoords: PointDouble[];

		switch (this.type) {
			case MarkupType.Text_Box:
				ctx.arc(dimension / 2, dimension / 2, dimension / 2 - 5, 0, Math.PI * 2);

				const strokeRgba = ColorUtils.hex2rgb("555555", 0.1, "RGBObject") as IRGBObject;

				ctx.strokeStyle = `rgba(${strokeRgba.r}, ${strokeRgba.g}, ${strokeRgba.b}, ${strokeRgba.a})`;
				ctx.lineWidth = 4;
				ctx.stroke();

				ctx.font = "bold 16px Roboto";
				const fillRgba = ColorUtils.hex2rgb(markup3D.fontColor.hex, markup3D.fontColor.transparency, "RGBObject") as IRGBObject;

				ctx.fillStyle = `rgba(${fillRgba.r}, ${fillRgba.g}, ${fillRgba.b}, ${1})`;
				ctx.textAlign = "center";
				ctx.fillText("Text", canvas.width / 2, canvas.height / 2);
				ctx.font = "11px Roboto";
				ctx.fillText("MARKUP", canvas.width / 2, canvas.height / 2 + 10);
				break;
			case MarkupType.Line:
			case MarkupType.Linear_Distance:
				canvasCoords = this.spaceCoordsToCanvasCoordsTransition(this.geometryData, dimension);

				ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
				ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);

				ctx.stroke();
				break;
			case MarkupType.Arrow:
			case MarkupType.Bidirectional_Arrow:
				const a = this.geometryData[0];
				const b = this.geometryData[1];
				const {bc, bd} = calculateArrowHead(a, b, this.arrowHeadSize, correctionMultiplier);

				if (this.type === MarkupType.Arrow) {
					canvasCoords = this.spaceCoordsToCanvasCoordsTransition(
						[...this.geometryData, {x: b.x + bc.x, y: b.y + bc.y}, {x: b.x + bd.x, y: b.y + bd.y}],
						dimension,
					);
					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);

					ctx.lineTo(canvasCoords[2].x, canvasCoords[2].y);

					ctx.moveTo(canvasCoords[1].x, canvasCoords[1].y);
					ctx.lineTo(canvasCoords[3].x, canvasCoords[3].y);
				} else {
					canvasCoords = this.spaceCoordsToCanvasCoordsTransition(
						[
							...this.geometryData,
							{x: b.x + bc.x, y: b.y + bc.y},
							{x: b.x + bd.x, y: b.y + bd.y},
							{x: a.x - bc.x, y: a.y - bc.y},
							{x: a.x - bd.x, y: a.y - bd.y},
						],
						dimension,
					);
					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);

					ctx.lineTo(canvasCoords[2].x, canvasCoords[2].y);

					ctx.moveTo(canvasCoords[1].x, canvasCoords[1].y);
					ctx.lineTo(canvasCoords[3].x, canvasCoords[3].y);

					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[4].x, canvasCoords[4].y);

					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[5].x, canvasCoords[5].y);
				}

				ctx.stroke();
				break;
			case MarkupType.Dashed_Line:
				const dashDiff =
					this.geometryData[1].x - this.geometryData[0].x === 0
						? this.geometryData[1].y - this.geometryData[0].y
						: this.geometryData[1].x - this.geometryData[0].x;
				const dashToLineRatio = (dashSize * correctionMultiplier) / dashDiff;
				const gapToLineRatio = (gapSize * correctionMultiplier) / dashDiff;

				canvasCoords = this.spaceCoordsToCanvasCoordsTransition(this.geometryData, dimension);

				const newDashDiff =
					canvasCoords[1].x - canvasCoords[0].x === 0 ? canvasCoords[1].y - canvasCoords[0].y : canvasCoords[1].x - canvasCoords[0].x;
				const newDashSize = dashToLineRatio * newDashDiff;
				const newGapSize = gapToLineRatio * newDashDiff;

				ctx.beginPath();
				ctx.setLineDash([newDashSize, newGapSize]);
				ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
				ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);

				ctx.stroke();
				break;
			case MarkupType.Pencil_Drawing:
			case MarkupType.Nonlinear_Distance:
			case MarkupType.Irregular_Area:
				canvasCoords = this.spaceCoordsToCanvasCoordsTransition(this.geometryData, dimension);

				ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
				for (let i = 1; i < canvasCoords.length; i++) {
					ctx.lineTo(canvasCoords[i].x, canvasCoords[i].y);
				}

				if (this.type === MarkupType.Irregular_Area) {
					ctx.closePath();
					ctx.fillStyle = `rgba(${colorAsRgb.r}, ${colorAsRgb.g}, ${colorAsRgb.b}, ${1 - this.fillTransparency})`;
					ctx.fill();
				}

				ctx.stroke();
				break;
			case MarkupType.Highlight_Drawing:
				const box = THREEUtils.calculateBox(this.geometryData);
				const boxSize = THREEUtils.getSizeOfBoundingBox2(box);
				const strokeWidthInSpaceUnits = 2 * highlightRadius * correctionMultiplier;

				// boxSize doesn't contain the strokeWidth. It gets calculated purely from the geometryData
				const strokeWidthToBBoxRatio = strokeWidthInSpaceUnits / (Math.max(boxSize.x, boxSize.y) + strokeWidthInSpaceUnits);

				canvasCoords = this.spaceCoordsToCanvasCoordsTransition(this.geometryData, dimension, strokeWidthToBBoxRatio);

				ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
				for (let i = 1; i < canvasCoords.length; i++) {
					ctx.lineTo(canvasCoords[i].x, canvasCoords[i].y);
				}

				ctx.strokeStyle = `rgba(${colorAsRgb.r}, ${colorAsRgb.g}, ${colorAsRgb.b}, ${highlightOpacity})`;
				ctx.lineWidth = strokeWidthToBBoxRatio * dimension;

				ctx.stroke();
				break;
			case MarkupType.Rectangle:
			case MarkupType.Rectangle_Area:
			case MarkupType.Ellipse:
			case MarkupType.Triangle:
			case MarkupType.Cross:
			case MarkupType.Cloud:
			case MarkupType.Callout:
				ctx.fillStyle = `rgba(${colorAsRgb.r}, ${colorAsRgb.g}, ${colorAsRgb.b}, ${1 - this.fillTransparency})`;

				const geometryData = [...this.geometryData];

				if (this.type === MarkupType.Callout) {
					// Use the saved one, otherwise the thumbnail gets updated before the object is actually updated on the backend
					const {startPos, elbowPos, target} = getArrowPointsForMarkupCallout(markup3D as MarkupCallout, true);
					const a = elbowPos;
					const b = target;
					const {bc, bd} = calculateArrowHead(a, b, this.arrowHeadSize, correctionMultiplier);

					geometryData.push(startPos, elbowPos, target, {x: b.x + bc.x, y: b.y + bc.y}, {x: b.x + bd.x, y: b.y + bd.y});
				}
				const unrotated = THREEUtils.getRotatedVertices(geometryData, -this.orientation);
				const unrotatedCorners = [...getUnrotatedCornersFromAB(unrotated[0], unrotated[1])];

				const rotated = THREEUtils.getRotatedVertices([...unrotatedCorners, ...unrotated.slice(2)], this.orientation);

				canvasCoords = this.spaceCoordsToCanvasCoordsTransition(rotated, dimension);

				if (this.type === MarkupType.Rectangle || this.type === MarkupType.Rectangle_Area || this.type === MarkupType.Callout) {
					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);
					ctx.lineTo(canvasCoords[2].x, canvasCoords[2].y);
					ctx.lineTo(canvasCoords[3].x, canvasCoords[3].y);
					ctx.closePath();
					ctx.fill();

					if (this.type === MarkupType.Callout) {
						// arrow target

						ctx.moveTo(canvasCoords[4].x, canvasCoords[4].y);
						ctx.lineTo(canvasCoords[5].x, canvasCoords[5].y);

						ctx.lineTo(canvasCoords[6].x, canvasCoords[6].y);
						ctx.lineTo(canvasCoords[7].x, canvasCoords[7].y);

						ctx.moveTo(canvasCoords[6].x, canvasCoords[6].y);
						ctx.lineTo(canvasCoords[8].x, canvasCoords[8].y);
					}
				} else if (this.type === MarkupType.Triangle) {
					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);
					ctx.lineTo((canvasCoords[2].x + canvasCoords[3].x) / 2, (canvasCoords[2].y + canvasCoords[3].y) / 2);
					ctx.closePath();
					ctx.fill();
				} else if (this.type === MarkupType.Ellipse) {
					//if transition is calculated for the unrotated canvasCoords, we don't need to use sqrt
					const unrotatedTransitioned = this.spaceCoordsToCanvasCoordsTransition(unrotated, dimension);

					ctx.ellipse(
						dimension / 2,
						dimension / 2,
						Math.abs((unrotatedTransitioned[1].x - unrotatedTransitioned[0].x) / 2),
						Math.abs((unrotatedTransitioned[1].y - unrotatedTransitioned[0].y) / 2),
						-this.orientation,
						0,
						Math.PI * 2,
					);
					ctx.fill();
				} else if (this.type === MarkupType.Cross) {
					ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);
					ctx.lineTo(canvasCoords[2].x, canvasCoords[2].y);

					ctx.moveTo(canvasCoords[1].x, canvasCoords[1].y);
					ctx.lineTo(canvasCoords[3].x, canvasCoords[3].y);
				} else if (this.type === MarkupType.Cloud) {
					// 3    2
					// 0    1

					const curveHeightMultiplier = 1.5;

					ctx.translate(dimension / 2, dimension / 2);
					ctx.rotate(-this.orientation);
					ctx.translate(-dimension / 2, -dimension / 2);

					canvasCoords = this.spaceCoordsToCanvasCoordsTransition(unrotatedCorners, dimension, (curveHeightMultiplier * curveHeight * 2) / dimension);
					const curveDiff = Math.max(
						Math.abs(unrotatedCorners[1].y - unrotatedCorners[3].y),
						Math.abs(unrotatedCorners[1].x - unrotatedCorners[3].x),
					);
					const curveHeightRatio = (curveHeight * correctionMultiplier) / curveDiff;
					const curvePeriodRatio = (curvePeriod * correctionMultiplier) / curveDiff;

					const newCurveDiff = Math.max(Math.abs(canvasCoords[1].y - canvasCoords[3].y), Math.abs(canvasCoords[1].x - canvasCoords[3].x));
					const newCurveHeight = curveHeightRatio * newCurveDiff;
					const newCurvePeriod = curvePeriodRatio * newCurveDiff;

					ctx.moveTo(canvasCoords[3].x, canvasCoords[3].y);
					let cp1x = canvasCoords[3].x;
					let cp1y = canvasCoords[3].y - curveHeightMultiplier * newCurveHeight;
					let cp2x = canvasCoords[3].x + newCurvePeriod;
					let cp2y = canvasCoords[3].y - curveHeightMultiplier * newCurveHeight;
					let endx = canvasCoords[3].x + newCurvePeriod;
					let endy = canvasCoords[3].y;

					for (let i = 0; endx < canvasCoords[2].x; i++) {
						ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, endx, endy);
						cp1x = cp2x;
						cp2x += newCurvePeriod;
						endx += newCurvePeriod;
					}
					ctx.lineTo(canvasCoords[2].x, canvasCoords[2].y);
					cp1x = canvasCoords[2].x + curveHeightMultiplier * newCurveHeight;
					cp1y = canvasCoords[2].y;
					cp2x = canvasCoords[2].x + curveHeightMultiplier * newCurveHeight;
					cp2y = canvasCoords[2].y + newCurvePeriod;
					endx = canvasCoords[2].x;
					endy = canvasCoords[2].y + newCurvePeriod;
					for (let i = 0; endy < canvasCoords[1].y; i++) {
						ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, endx, endy);
						cp1y += newCurvePeriod;
						cp2y += newCurvePeriod;
						endy += newCurvePeriod;
					}
					ctx.lineTo(canvasCoords[1].x, canvasCoords[1].y);
					cp1x = canvasCoords[1].x;
					cp1y = canvasCoords[1].y + curveHeightMultiplier * newCurveHeight;
					cp2x = canvasCoords[1].x - newCurvePeriod;
					cp2y = canvasCoords[1].y + curveHeightMultiplier * newCurveHeight;
					endx = canvasCoords[1].x - newCurvePeriod;
					endy = canvasCoords[1].y;
					for (let i = 0; endx > canvasCoords[0].x; i++) {
						ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, endx, endy);
						cp1x -= newCurvePeriod;
						cp2x -= newCurvePeriod;
						endx -= newCurvePeriod;
					}
					ctx.lineTo(canvasCoords[0].x, canvasCoords[0].y);
					cp1x = canvasCoords[0].x - curveHeightMultiplier * newCurveHeight;
					cp1y = canvasCoords[0].y;
					cp2x = canvasCoords[0].x - curveHeightMultiplier * newCurveHeight;
					cp2y = canvasCoords[0].y - newCurvePeriod;
					endx = canvasCoords[0].x;
					endy = canvasCoords[0].y - newCurvePeriod;
					for (let i = 0; endy > canvasCoords[3].y; i++) {
						ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, endx, endy);
						cp1y -= newCurvePeriod;
						cp2y -= newCurvePeriod;
						endy -= newCurvePeriod;
					}
					ctx.lineTo(canvasCoords[3].x, canvasCoords[3].y);

					ctx.closePath();
					ctx.fill();
				}

				ctx.stroke();

				break;
		}

		if (!isMarkupOnMountedSpace) {
			markup3D.destroy(false);
		}
		ctx.restore();

		return canvas.toDataURL();
	}

	@computed
	public get data() {
		return this._data;
	}
}

export interface IMarkupSettingsData {
	arrowHeadSize?: number;
	// Only for textboxes and callouts. It's set to true when the user explicitly sets a size for the textbox.
	// Otherwise, it's false, meaning the textbox grows horizontally as the user types
	isSizeFixed?: boolean;
	// Target of the callout. The arrow points to this point in world-space
	target?: PointDouble;
	// For MarkupsWithTextOffset
	textOffset?: PointDouble;
	textOrientation?: number;
}

import * as React from "react";
import type {Xyicon3D} from "../../logic3d/elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import type {Link} from "../../../../../../data/models/Link";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {IconButton} from "../../../../../widgets/button/IconButton";
import type {PointDouble} from "../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {ConfirmWindow} from "../../../../abstract/popups/ConfirmWindow";
import type {SpaceItem} from "../../logic3d/elements3d/SpaceItem";

interface ILinkBreakersProps {
	spaceViewRenderer: SpaceViewRenderer;
	fromObjectIds: string[];
}

export class LinkBreakers extends React.Component<ILinkBreakersProps> {
	private _fromXyicons: Xyicon3D[] = [];

	private getStyle(fromPosition: PointDouble, toPosition: PointDouble) {
		return THREEUtils.getStyleForFloatingUIElement(
			(fromPosition.x + toPosition.x) / 2,
			(fromPosition.y + toPosition.y) / 2,
			this.props.spaceViewRenderer.spaceOffset.z,
			this.props.spaceViewRenderer,
			false,
			"center",
		);
	}

	private updateConnectionLines(lineSets: {fromXyicon: Xyicon3D; toXyicons: Xyicon3D[]}[]) {
		const {spaceItemController} = this.props.spaceViewRenderer;

		spaceItemController.linkLineManager.update(lineSets);
		for (const lineSet of lineSets) {
			spaceItemController.linkIconManager.selectIcon(lineSet.fromXyicon.linkInstanceId);
		}
	}

	private forceUpdateArrow = () => {
		if (this._fromXyicons.length > 0) {
			const filteredLinkObjects = this.getFilteredLinkObjects();

			if (filteredLinkObjects.length > 0) {
				const lineSets: {fromXyicon: Xyicon3D; toXyicons: Xyicon3D[]}[] = this.createLineSetsFromFilteredLinkObjects(filteredLinkObjects);

				this.updateConnectionLines(lineSets);
			}
		}

		this.forceUpdate();
	};

	private getFilteredLinkObjects() {
		const {spaceViewRenderer, fromObjectIds} = this.props;

		const ret: {
			fromObjectId: string;
			link: Link;
			xyicon3D: SpaceItem;
		}[] = [];

		for (const fromObjectId of fromObjectIds) {
			const connections = spaceViewRenderer.actions
				.getLinksXyiconXyicon(fromObjectId)
				.filter((l) => !l.link.isEmbedded && (l.object as Xyicon).spaceId === spaceViewRenderer.space.id)
				.map((l) => {
					return {
						fromObjectId,
						link: l.link,
						xyicon3D: spaceViewRenderer.xyiconManager.getItemById(l.object.id),
					};
				})
				.filter((obj: {fromObjectId: string; link: Link; xyicon3D: Xyicon3D}) => obj.xyicon3D?.isVisible);

			for (const connection of connections) {
				if (!ret.some((r) => r.link.id === connection.link.id)) {
					ret.push(connection);
				}
			}
		}

		return ret;
	}

	private reset3DElements() {
		const {spaceItemController} = this.props.spaceViewRenderer;

		spaceItemController.linkLineManager.destroyAll();
		this.resetLinkIconSelection();
	}

	private resetLinkIconSelection() {
		for (const fromXyicon of this._fromXyicons) {
			this.props.spaceViewRenderer.spaceItemController.linkIconManager.deselectIcon(fromXyicon.linkInstanceId);
		}
	}

	private close() {
		// RequestAnimationFrame is to prevent react warning: state shouldn't be modified from a render function
		requestAnimationFrame(() => {
			this.reset3DElements();
			this.props.spaceViewRenderer.spaceItemController.closeLinks();
		});
	}

	public static async breakLinks(transport: TransportLayer, linkIds: string[], isCrossPortfolioConfirmNeeded: boolean = false) {
		if (isCrossPortfolioConfirmNeeded) {
			const isConfirmed = await ConfirmWindow.open(
				"The xyicon linked is located in a different portfolio. If you break this link, it will affect the relationship of the xyicons across both portfolios. Are you certain you want to proceed?",
				"Confirm Breaking Link",
			);

			if (!isConfirmed) {
				return;
			}
		}

		return transport.requestForOrganization({
			url: "xyicons/deletelink",
			method: XHRLoader.METHOD_DELETE,
			params: {
				portfolioID: transport.appState.portfolioId,
				linkIDList: linkIds,
			},
		});
	}

	private createLineSetsFromFilteredLinkObjects(filteredLinkObjects: {fromObjectId: string; link: Link; xyicon3D: SpaceItem}[]) {
		const lineSets: {fromXyicon: Xyicon3D; toXyicons: Xyicon3D[]}[] = [];

		for (const filteredLinkObject of filteredLinkObjects) {
			const lineSetMaybe = lineSets.find((ls) => ls.fromXyicon.id === filteredLinkObject.fromObjectId);

			if (lineSetMaybe) {
				if (!lineSetMaybe.toXyicons.includes(filteredLinkObject.xyicon3D as Xyicon3D)) {
					lineSetMaybe.toXyicons.push(filteredLinkObject.xyicon3D as Xyicon3D);
				}
			} else {
				lineSets.push({
					fromXyicon: this._fromXyicons.find((x) => x.id === filteredLinkObject.fromObjectId),
					toXyicons: [filteredLinkObject.xyicon3D as Xyicon3D],
				});
			}
		}

		return lineSets;
	}

	public override componentWillUnmount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.spaceItemController.signals.itemsTranslated.remove(this.forceUpdateArrow);
		spaceViewRenderer.signals.onCanvasResized.remove(this.forceUpdateArrow);
		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.forceUpdateArrow);
		this.reset3DElements();
	}

	public override componentDidMount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.spaceItemController.signals.itemsTranslated.add(this.forceUpdateArrow);
		spaceViewRenderer.signals.onCanvasResized.add(this.forceUpdateArrow);
		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.forceUpdateArrow);
	}

	public override render() {
		const {spaceViewRenderer, fromObjectIds} = this.props;

		if (this._fromXyicons.length > 0) {
			this.resetLinkIconSelection();
		}
		this._fromXyicons = fromObjectIds
			.map((fromObjectId: string) => spaceViewRenderer.xyiconManager.getItemById(fromObjectId) as Xyicon3D)
			.filter((x) => x);
		if (this._fromXyicons.length === 0) {
			this.close();
			return false;
		}

		const filteredLinkObjects = this.getFilteredLinkObjects();

		if (filteredLinkObjects.length === 0) {
			this.close();
			return false;
		}

		const lineSets: {fromXyicon: Xyicon3D; toXyicons: Xyicon3D[]}[] = this.createLineSetsFromFilteredLinkObjects(filteredLinkObjects);

		this.updateConnectionLines(lineSets);

		const arrayOfIcons = filteredLinkObjects.map((obj: {fromObjectId: string; link: Link; xyicon3D: Xyicon3D}, index: number) => {
			const fromXyicon = this._fromXyicons.find((x) => x.id === obj.fromObjectId);
			const fromPosition = fromXyicon.position;
			const toXyicon3D = obj.xyicon3D;
			const toPosition = toXyicon3D.position;

			return (
				<IconButton
					key={obj.link.id}
					className="LinkBreaker"
					icon="breakLink"
					title="Break Link"
					style={this.getStyle(fromPosition, toPosition)}
					onClick={() => LinkBreakers.breakLinks(this.props.spaceViewRenderer.transport, [obj.link.id])}
				/>
			);
		});

		return arrayOfIcons;
	}
}

import {InstancedBufferGeometry} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {Constants} from "../../Constants";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import type {Catalog} from "../../../../../../../data/models/Catalog";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {LibraryModel} from "../../../../../../../data/models/LibraryModel";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";

export class GeometryManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _cache: {
		[key: string]: Promise<InstancedBufferGeometry>;
	} = {};

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	private getInstancedBufferGeometry(catalogId: string) {
		return new Promise<InstancedBufferGeometry>(async (resolve, reject) => {
			const {transport} = this._spaceViewRenderer;
			const {actions} = transport.appState;
			const catalog = actions.getFeatureItemById<Catalog>(catalogId, XyiconFeature.XyiconCatalog);

			try {
				const modelParameters = catalog.iconData.modelParameters;
				const libraryModel = actions.getFeatureItemById<LibraryModel>(modelParameters.libraryModelID, XyiconFeature.LibraryModel);

				const originalSizeInMeters = await libraryModel.getSizeInMeters();
				const newSizeInMeters = modelParameters.dimensions;
				const gltf = await THREEUtils.loadGLTF(libraryModel.url);
				const {spaceUnitsPerMeter} = this._spaceViewRenderer.space;

				THREEUtils.scaleObject3DForSpaceEditor(gltf.scene, spaceUnitsPerMeter, originalSizeInMeters, newSizeInMeters);

				gltf.scene.rotation.x += Math.PI / 2;

				const mergedGeometry = THREEUtils.mergeGeometriesOfObject(gltf.scene);
				const modelHeight = MathUtils.convertDistanceToSpaceUnit(newSizeInMeters.y, Constants.DEFAULT_UNIT, spaceUnitsPerMeter);

				THREEUtils.applyOffsetToBufferGeometry(mergedGeometry, {x: 0, y: 0, z: modelHeight / 2});

				resolve(new InstancedBufferGeometry().copy(mergedGeometry as InstancedBufferGeometry));
			} catch (error: unknown) {
				console.warn(
					`Something went wrong with setting up the glTF model on the space for catalog ${catalog.refId}. The initialization has been interrupted. Probably the loading process of the space has been interrupted while the glTF file was loading from the server, or the associated glTF file is not valid.`,
				);
				console.warn(error);
			}
		});
	}

	public deleteGoemetryFromCache(catalogId: string) {
		delete this._cache[catalogId];
	}

	public clearCache() {
		this._cache = {};
	}

	public init(modelBasedXyicons: Xyicon[]) {
		const catalogIds: string[] = [];

		for (const xyicon of modelBasedXyicons) {
			if (!catalogIds.includes(xyicon.catalogId)) {
				catalogIds.push(xyicon.catalogId);
			}
		}

		const promises = [];

		for (const catalogId of catalogIds) {
			this._cache[catalogId] = this.getInstancedBufferGeometry(catalogId);
			promises.push(this._cache[catalogId]);
		}

		return Promise.all(promises);
	}

	public getGeometry(catalogId: string, forceReload: boolean = false) {
		if (!this._cache[catalogId] || forceReload) {
			this._cache[catalogId] = this.getInstancedBufferGeometry(catalogId);
		}

		return this._cache[catalogId];
	}
}

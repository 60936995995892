import * as React from "react";
import {inject, observer} from "mobx-react";
import {DomPortal} from "../portal/DomPortal";
import {InfoBubble} from "../common/infobutton/InfoBubble";
import type {IModel} from "../../../../data/models/Model";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {ReactUtils} from "../../../utils/ReactUtils";
import {DoubleClickGesture} from "../../../../utils/interaction/gestures/DoubleClickGesture";
import type {Space} from "../../../../data/models/Space";
import {TableHeaderDropDown} from "../../../widgets/table/TableHeaderDropDown";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature} from "../../../../generated/api/base";
import type {SpaceFile} from "../../../../data/models/SpaceFile";

interface ICardProps<T extends IModel> {
	item: T;
	transport?: TransportLayer;
	selected: boolean;
	onSelect: (item: T[]) => void;
	onDoubleClick: (item: T) => void;
	appState?: AppState;
}

interface ICardState {
	open: boolean;
	toolTipTransform?: TransformObj;
	isToolTipOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class Card<T extends IModel> extends React.Component<ICardProps<T>, ICardState> {
	private _parent = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	private _timeStamp: number;

	constructor(props: ICardProps<T>) {
		super(props);
		this.state = {
			open: false,
			isToolTipOpen: false,
			toolTipTransform: null,
		};
	}

	private onClick = () => {
		const timeStamp = performance.now();
		const dt = !!this._timeStamp ? timeStamp - this._timeStamp : Infinity;

		this._timeStamp = timeStamp;

		if (!this.props.selected) {
			this.props.onSelect([this.props.item]);
		} else if (DoubleClickGesture.defaultConfig.threshold > dt) {
			this.props.onDoubleClick(this.props.item);
		}
	};

	private onOpenChange = (value: boolean) => {
		this.setState({open: value});
	};

	private onMouseOverSpaceName = (event: React.MouseEvent) => {
		const div = event.currentTarget;

		if (div.scrollWidth > div.clientWidth) {
			this.setState({isToolTipOpen: true});
		}
	};

	private onMouseLeaveSpaceName = () => {
		this.setState({isToolTipOpen: false});
	};

	private onClickOption = (spaceFile: SpaceFile) => {
		const space = this.props.item as IModel as Space;

		if (space.ownFeature === XyiconFeature.Space) {
			space.setSelectedSpaceFile(spaceFile);

			this.setState({open: false});
		}
	};

	public override componentDidUpdate(prevProps: ICardProps<T>, prevState: ICardState): void {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent?.current && this._floating?.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.top,
					HorizontalAlignment.left,
				),
			});
		}
	}

	public override render() {
		const {item, selected} = this.props;
		const {open, toolTipTransform, isToolTipOpen} = this.state;
		const thumbnailURL = item.thumbnailFileURL;
		const inlineStyle = this._floating && {
			transform: toolTipTransform?.translate,
		};

		return (
			<div
				className={ReactUtils.cls("Card vbox", {selected})}
				onClick={this.onClick}
			>
				<div className="header hbox alignCenter">
					<div
						ref={this._parent}
						className="itemName"
						title={item.name}
						onMouseOver={this.onMouseOverSpaceName}
						onMouseLeave={this.onMouseLeaveSpaceName}
					>
						{item.name}
					</div>
					<TableHeaderDropDown
						open={open}
						onOpenChange={this.onOpenChange}
						dropdownOptions={[
							...(item as IModel as Space).spaceFiles.map((spaceFile) => ({
								label: spaceFile.spaceVersion?.name,
								selected: spaceFile.spaceVersionId === (item as IModel as Space).versionId,
								onSelect: () => this.onClickOption(spaceFile),
							})),
						]}
					/>
				</div>
				<div
					className="thumbnail"
					style={{backgroundImage: thumbnailURL ? `url("${thumbnailURL}")` : ""}}
				/>
				{isToolTipOpen && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<InfoBubble
							divRef={this._floating}
							content={item.name}
							style={inlineStyle}
							className="DeleteButtonToolTip"
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

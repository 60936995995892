import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IModulePanelProps} from "../../abstract/ModuleView";
import {IconButton} from "../../../widgets/button/IconButton";
import {XyiconFeature} from "../../../../generated/api/base";
import type {View} from "../../../../data/models/View";
import type {IViewColumn} from "../../../../data/models/ViewUtils";
import {EditViewColumns} from "../view/columns/EditViewColumns";
import {SaveToViewButton} from "../common/savetoview/SaveToViewButton";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {MultiActionButton} from "../../../widgets/button/multiaction/MultiActionButton";

interface IManageColumnsPanelProps extends IModulePanelProps {
	view: View;
	feature: XyiconFeature;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class ManageColumnsPanel extends React.PureComponent<IManageColumnsPanelProps> {
	private _columns = this.props.view.getColumnsToCompareWithoutWidth();
	private onClose = () => {
		this.props.onClose();
	};

	private onSaveClick = () => {
		this._columns = this.props.view.getColumnsToCompareWithoutWidth();
		this.onClose();
	};

	private isResetButtonEnabled() {
		const savedColumns = this.props.view.getColumnsToCompareWithoutWidth();

		return JSON.stringify(this._columns) !== JSON.stringify(savedColumns);
	}

	private onResetClick = () => {
		const {feature, view} = this.props;

		if (view.itemFeature === XyiconFeature.SpaceEditor) {
			view.setColumns(this._columns[feature as XyiconFeature.Xyicon | XyiconFeature.Boundary] as IViewColumn[], feature);
		} else {
			view.setColumns(this._columns as IViewColumn[], feature);
		}
	};

	public override render() {
		const {view, feature} = this.props;

		return (
			<div className="ManageColumnsPanel CreatePortfolioPanel SidePanel">
				<div className="heading createBox hbox">
					<h4>Manage Columns</h4>
					<IconButton
						className="closePanel"
						icon="close"
						onClick={this.onClose}
					/>
				</div>
				<div className="sidePanelButtons">
					<div className="Buttons">
						<div className="saveToView hbox">
							<SaveToViewButton
								feature={view.itemFeature}
								viewChangeType="columns"
								manageColumns={true}
								noIcon={true}
								onSaveClicked={this.onSaveClick}
							/>
						</div>
						<MultiActionButton
							className="secondary"
							disabled={!this.isResetButtonEnabled()}
							options={[
								{
									id: "reset",
									label: "Reset",
									onClick: this.onResetClick,
								},
							]}
						/>
					</div>
					<div className="bottomShadow" />
					<EditViewColumns
						title=""
						view={view}
						feature={feature}
					/>
				</div>
			</div>
		);
	}
}

export class Formatter {
	public static leftPad(value: number | string, width: number, char = "0"): string {
		const n: string = `${value}`;

		return n.length >= width ? n : new Array(width - n.length + 1).join(char) + n;
	}

	// Converts "etc/something.txt" -> "etc/something.jpg" where jpg is the newExtension
	public static changeExtension(value: string, newExtension: string) {
		const valueWithoutExtension = Formatter.removeExtension(value);

		return `${valueWithoutExtension}.${newExtension}`;
	}

	// Converts "etc/something.txt" -> "etc/something"
	public static removeExtension(value: string) {
		return value.replace(/\.[^/.]+$/, "");
	}

	public static capitalize(value: string) {
		value = value || "";
		if (typeof value !== "string") {
			return "";
		}
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	public static decapitalize(value: string) {
		value = value || "";
		if (typeof value !== "string") {
			return "";
		}
		return value.charAt(0).toLowerCase() + value.slice(1);
	}
}

import {useEffect, useRef} from "react";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import type {PointDouble} from "../../../../../generated/api/base";
import {THREEUtils} from "../../../../../utils/THREEUtils";
import type {ThemeType} from "../../../../ThemeType";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";

interface INavigationCameraBoxProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly domWidth: number;
	readonly domHeight: number;
	readonly bgWidth: number;
	readonly bgHeight: number;
	readonly themeType: ThemeType;
}

export const NavigationCameraBoxV5 = (props: INavigationCameraBoxProps) => {
	const _canvasRef = useRef<HTMLCanvasElement>();

	const update = () => {
		const canvas = _canvasRef.current;

		if (canvas) {
			const domWidth = props.domWidth;
			const domHeight = props.domHeight;

			const _ctx = canvas.getContext("2d");

			_ctx.clearRect(0, 0, domWidth, domHeight);
			_ctx.beginPath();

			_ctx.strokeStyle = `#${Constants.COLORS.PICTON_BLUE}`;
			_ctx.fillStyle = ColorUtils.hex2rgb(_ctx.strokeStyle, 0.1) as string;

			// with 0 camerarotation in mind
			const arrayOfNDCCoords = [
				{x: 1, y: 1},
				{x: -1, y: 1},
				{x: -1, y: -1},
				{x: 1, y: -1},
			];

			const arrayOfDomCoords: PointDouble[] = [];
			const spaceSize = props.spaceViewRenderer.spaceSize;

			if (spaceSize) {
				const bgElement = {
					offsetWidth: props.bgWidth,
					offsetHeight: props.bgHeight,
				};

				const offset = {
					x: (props.domWidth - props.bgWidth) / 2,
					y: (props.domHeight - props.bgHeight) / 2,
				};

				const spaceOffset = props.spaceViewRenderer.spaceOffset;

				for (const coord of arrayOfNDCCoords) {
					const worldZ = props.spaceViewRenderer.spaceOffset.z;
					let worldCoord = THREEUtils.NDCtoWorldCoordinates(coord.x, coord.y, worldZ, props.spaceViewRenderer.activeCamera);

					if (!worldCoord) {
						worldCoord = THREEUtils.NDCtoWorldCoordinates(coord.x, 0, worldZ, props.spaceViewRenderer.activeCamera);
					}
					if (!worldCoord) {
						return;
					}
					// Bottom left corner is spaceOffset.xy by default (which is (0, 0) by default)
					arrayOfDomCoords.push(
						THREEUtils.worldCoordinatesToDomCoordinates(worldCoord.x - spaceOffset.x, worldCoord.y - spaceOffset.y, worldZ, bgElement, spaceSize),
					);
					arrayOfDomCoords[arrayOfDomCoords.length - 1].x += offset.x;
					arrayOfDomCoords[arrayOfDomCoords.length - 1].y += offset.y;
				}

				_ctx.moveTo(arrayOfDomCoords[0].x, arrayOfDomCoords[0].y);
				for (let i = 1; i < arrayOfDomCoords.length; ++i) {
					_ctx.lineTo(arrayOfDomCoords[i].x, arrayOfDomCoords[i].y);
				}
			}
			_ctx.closePath();
			_ctx.stroke();
			_ctx.fill();
		}
	};

	useEffect(() => {
		update();
	});

	const style = {
		filter: props.themeType === "light" ? "none" : "invert(1)",
	};

	return (
		<canvas
			ref={_canvasRef}
			style={style}
			width={props.domWidth}
			height={props.domHeight}
		></canvas>
	);
};

import * as React from "react";
import styled, {css} from "styled-components";
import {Functions} from "../../../../utils/function/Functions";
import {FlexCenterStyle, VerticalFlexStyle} from "../../styles/styles";
import CheckMarkIcon from "../../icons/check.svg?react";
import {colorPalette} from "../../styles/colorPalette";

interface IStepIndicatorProps {
	steps: string[];
	currentStepIndex: number;
	editing?: boolean;
	customWidth?: number;
	onStepClick: (stepIndex: number) => void;
}

StepIndicatorV5.defaultProps = {
	onStepClick: Functions.emptyFunction,
};

const StepIndicatorStyled = styled.div<{$donePercent: number; $customWidth: number}>`
	${FlexCenterStyle};
	justify-content: space-between;
	position: relative;
	width: ${(props) => (!!props.$customWidth ? `${props.$customWidth}px` : "600px")};
	align-self: center;
	padding-bottom: 50px;
	margin: auto;
	z-index: 2;

	&::after {
		position: absolute;
		content: "";
		width: 95%;
		height: 2px;
		background-color: ${colorPalette.gray.c300};
		z-index: -2;
	}

	&::before {
		position: absolute;
		content: "";
		background-color: ${colorPalette.blueGray.c500Primary};
		height: 2px;
		z-index: -1;
		transition: cubic-bezier(0, 0, 0.2, 1) width 0.4s;
		${(props) => css`
			width: ${props.$donePercent}%;
		`};
	}
`;

const StepIndicatorStepStyled = styled.div<{$cursor: boolean; $active: boolean; $isDone: boolean}>`
	${VerticalFlexStyle};
	color: ${colorPalette.white};
	width: 32px;
	height: 32px;
	border: solid 2px currentColor;
	border: solid 2px ${(props) => (props.$active || props.$isDone ? "#1E88E5" : colorPalette.gray.c300)};
	border-radius: 50%;
	background-color: ${(props) => (props.$isDone ? "#1E88E5" : colorPalette.white)};
	align-items: center;
	justify-content: center;

	svg {
		stroke: currentColor;
	}

	span {
		width: 10px;
		height: 10px;
		background-color: ${colorPalette.blueGray.c500Primary};
		border-radius: 50%;
	}

	h3 {
		font-size: 18px;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		width: max-content;
		position: absolute;
		top: 40px;
		color: ${colorPalette.gray.c700Dark};
	}

	${(props) => {
		if (props.$cursor) {
			return css`
				cursor: pointer;
			`;
		}

		if (props.$active || props.$isDone) {
			return css`
				color: ${colorPalette.blueGray.c500Primary};
			`;
		}
	}}

	${(props) => {
		if (props.$isDone) {
			return css`
				background-color: ${colorPalette.blueGray.c500Primary};
				color: ${colorPalette.white};
				border-color: ${colorPalette.blueGray.c500Primary};
			`;
		}
	}}
`;

export function StepIndicatorV5(props: IStepIndicatorProps) {
	const {steps, currentStepIndex, customWidth, onStepClick} = props;

	return (
		<StepIndicatorStyled
			$donePercent={(currentStepIndex / (steps.length - 1)) * 100}
			$customWidth={customWidth}
		>
			{steps.map((value: string, index: number) => {
				const isDone = index < currentStepIndex;

				return (
					<StepIndicatorStepStyled
						key={index}
						data-content={value}
						$cursor={onStepClick !== Functions.emptyFunction}
						$active={index === currentStepIndex}
						$isDone={isDone}
						onClick={() => onStepClick(index)}
					>
						{index === currentStepIndex && <span />}
						{isDone && <CheckMarkIcon />}
						<h3>{steps[index]}</h3>
					</StepIndicatorStepStyled>
				);
			})}
		</StepIndicatorStyled>
	);
}

import type {IUniform, Texture, WebGLProgramParametersWithUniforms} from "three";
import {RawShaderMaterial} from "three";
import type {MSDFFont} from "../managers/MSDF/MSDFFont";
import type {SupportedFontName} from "../managers/MSDF/TextGroupManager";

export class MSDFTextShaderMaterial extends RawShaderMaterial {
	// !IMPORTANT! if you want to add new elements to this array, you need to modify the shader below as well
	private _maps: {
		value: Texture;
	}[] = [];

	private _vertexShader: string = `precision highp float;

#define skewMultiplicator 0.25

attribute vec3 position;
attribute mat4 instanceMatrix;
attribute vec2 uvOffset;
attribute vec2 uvSize;
attribute vec2 glyphSize; // worldSize
attribute vec3 color;
attribute float opacity;
attribute float isBold;
attribute float isItalic;
attribute float mapIndex;

uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;

varying vec2 vUv;
varying vec3 vColor;
varying float vOpacity;
varying float vIsBold;
varying float vMapIndex;

void main()
{
	vUv = uvOffset + vec2(position.xy) * uvSize;
	vColor = color;
	vOpacity = opacity;
	vIsBold = isBold;
	vMapIndex = mapIndex;

	vec3 pos = vec3(position);

	if (isItalic > 0.5)
	{
		pos.x += pos.y * (glyphSize.y / glyphSize.x) * skewMultiplicator;
	}
	
	gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(pos, 1.0);
}
`;

	private _fragmentShader: string = `#ifdef GL_OES_standard_derivatives
	#extension GL_OES_standard_derivatives : enable
#endif

precision highp float;

#define underlineThickness 3.0
#define EPSILON 0.0001

varying vec2 vUv;
varying vec3 vColor;
varying float vOpacity;
varying float vIsBold;
varying float vMapIndex;

uniform sampler2D maps0;
uniform sampler2D maps1;
uniform sampler2D maps2;
uniform sampler2D maps3;
uniform sampler2D maps4;
uniform sampler2D maps5;
uniform sampler2D maps6;

// Can't use maps[mapIndex], because glsl needs to check the mapIndex compile time...
// error: sampler arrays indexed with non-constant expressions are forbidden in GLSL 1.30 and later' in fragment shader
// Also...: warning X4000: use of potentially uninitialized variable -> that's why we don't just return with the texture2D...
vec3 getTexel()
{
	vec2 uv = vUv;

	vec3 texel;
	
	if (abs(vMapIndex - 0.0) < EPSILON)
	{
		texel = texture2D(maps0, uv).rgb;
	}
	else if (abs(vMapIndex - 1.0) < EPSILON)
	{
		texel = texture2D(maps1, uv).rgb;
	}
	else if (abs(vMapIndex - 2.0) < EPSILON)
	{
		texel = texture2D(maps2, uv).rgb;
	}
	else if (abs(vMapIndex - 3.0) < EPSILON)
	{
		texel = texture2D(maps3, uv).rgb;
	}
	else if (abs(vMapIndex - 4.0) < EPSILON)
	{
		texel = texture2D(maps4, uv).rgb;
	}
	else if (abs(vMapIndex - 5.0) < EPSILON)
	{
		texel = texture2D(maps5, uv).rgb;
	}
	else if (abs(vMapIndex - 6.0) < EPSILON)
	{
		texel = texture2D(maps6, uv).rgb;
	}
	else
	{
		texel = texture2D(maps0, uv).rgb;
	}

	return texel;
}

float median(float r, float g, float b)
{
	return max(min(r, g), min(max(r, g), b));
}

void main()
{
	vec3 font = getTexel();
	float offset = vIsBold < 0.5 ? 0.5 : 0.3;
	float sigDist = median(font.r, font.g, font.b) - offset;
	float alpha = clamp(sigDist / fwidth(sigDist) + 0.5, 0.0, vOpacity);
	
	if (alpha < EPSILON)
	{
		discard;
	}

	gl_FragColor = vec4(vColor, alpha);
}
`;
	constructor(msdfFonts: {[key in SupportedFontName]: MSDFFont}, isWebGL2: boolean) {
		super({transparent: true, depthTest: false, glslVersion: isWebGL2 ? "300 es" : "100"});

		if (isWebGL2) {
			this._vertexShader = this._vertexShader.replace(/varying/g, "out").replace(/attribute/g, "in");

			this._fragmentShader = this._fragmentShader
				.replace(/varying/g, "in")
				.replace(/texture2D/g, "texture")
				.replace(/precision highp float;/, "precision highp float;\nout vec4 outputColor;")
				.replace(/gl_FragColor/g, "outputColor");
		}

		for (const key in msdfFonts) {
			this._maps.push({
				value: msdfFonts[key as keyof typeof msdfFonts].textureAtlas,
			});
		}

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;

			const uniforms: {[key: string]: IUniform} = {};

			for (let i = 0; i < this._maps.length; ++i) {
				uniforms[`maps${i}`] = this._maps[i];
			}

			program.uniforms = uniforms;
		};
	}
}

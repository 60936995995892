/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum XyiconFeature {
	Organization = 1,
	Setting = 10,
	OrganizationSetting = 11,
	PortfolioSetting = 12,
	ProfileSetting = 13,
	Portfolio = 20,
	XyiconCatalog = 30,
	Document = 40,
	PortfolioDocument = 41,
	OrganizationDocument = 42,
	Report = 50,
	Space = 60,
	SpaceEditor = 70,
	Xyicon = 80,
	Boundary = 90,
	Markup = 100,
	Event = 110,
	Task = 120,
	User = 130,
	ExternalUser = 131,
	View = 140,
	PortfolioType = 200,
	SpaceType = 201,
	BoundaryType = 202,
	XyiconType = 203,
	LibraryImage = 910,
	LibraryModel = 911,
	SpaceVersion = 920,
	Link = 930,
	PortfolioGroup = 971,
	UserGroup = 972,
	PermissionSet = 974,
}

import * as signalR from "@microsoft/signalr";
import {HubConnectionState} from "@microsoft/signalr";
import type {TransportLayer} from "../TransportLayer";
import {SignalRListener} from "./SignalRListener";

export class SignalRLayer {
	private readonly _transportLayer: TransportLayer;
	private readonly _listener: SignalRListener;

	private _connection: signalR.HubConnection;

	constructor(transportLayer: TransportLayer) {
		this._transportLayer = transportLayer;
		this._listener = new SignalRListener(transportLayer);
	}

	// Call this after token change (portfolio switch, token refresh)
	public async start(accessToken: string) {
		await this.stop();

		this._connection = new signalR.HubConnectionBuilder().withUrl(this._transportLayer.signalRUrl, {accessTokenFactory: () => accessToken}).build();

		// closes connection if time since last message received was >= this value
		this._connection.serverTimeoutInMilliseconds = 20 * 60 * 1000; // 20 minutes
		// SignalR 1.1: interval at which the client will send pings to the server to keep the server from closing the connection
		this._connection.keepAliveIntervalInMilliseconds = 3 * 60 * 1000; // 3 minutes

		// this._connection.serverTimeoutInMilliseconds = 60000

		this._connection.onclose(this.onConnectionClose);

		this.addListeners();
		await this.connect();
	}

	public async stop() {
		if (this._connection) {
			const connection = this._connection;

			this._connection = null;
			await connection.stop();
		}
	}

	private onConnectionClose = (e: Error) => {
		console.log("connection closed", e);
		setTimeout(this.connect, 6000);
	};

	private connect = async () => {
		if (!this._connection) {
			return;
		}

		if (this._connection.state !== HubConnectionState.Disconnected) {
			return;
		}

		try {
			await this._connection.start();
			console.log("connected");
		} catch (err) {
			console.log(err);

			if (err.statusCode === 401) {
				// Token expired -> log in again
				this._transportLayer.services.auth.logout();
			} else {
				setTimeout(() => this.connect(), 5000);
			}
		}
	};

	private addListeners() {
		this._listener.addListeners(this._connection);
	}

	public get listener() {
		return this._listener;
	}

	public get connectionId() {
		return this._connection?.connectionId;
	}
}

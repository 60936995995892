import * as React from "react";
import {styled} from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {FlexCenterStyle, baseDistance, fontSize, fontWeight, zIndex} from "../styles/styles";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {ResizeDetector} from "../../../utils/resize/ResizeDetector";
import {shortcuts} from "../../modules/abstract/popups/ShortcutWindow";
import XmarkLargeIcon from "../icons/xmark-large.svg?react";
import {IconButtonV5} from "../interaction/IconButtonV5";

const ShortcutWindowStyled = styled.div`
	background: ${colorPalette.white};
	box-shadow:
		0px 16px 16px rgba(50, 50, 71, 0.08),
		0px 24px 32px rgba(50, 50, 71, 0.08);
	max-width: 500px;
	width: 430px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: ${zIndex.popup};
`;

const ShortcutWindowTitleStyled = styled.div`
	padding: ${baseDistance.sm};
	background: var(--bg3);
	font-size: ${fontSize.md};
	font-weight: ${fontWeight.normal};
	justify-content: space-between;
	${FlexCenterStyle}

	.draghandle {
		cursor: pointer;

		svg {
			width: 14px;
			height: 14px;
			fill: ${colorPalette.gray.c400};
			margin: 0 ${baseDistance.xs};
			margin-right: 10px;
		}
	}

	svg {
		cursor: pointer;

		path {
			width: 14px;
			height: 14px;
			fill: ${colorPalette.gray.c400};
			margin: 0 ${baseDistance.xs};
		}
	}
`;

const TitleContentStyled = styled.div`
	width: 400px;
	font-size: 16px;
`;

const ShortcutWindowMessageStyled = styled.div`
	padding: ${baseDistance.sm} ${baseDistance.md};
	font-size: 14px;
`;

const ShortcutStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin: ${baseDistance.sm} 0;
`;

const KeysStyled = styled.div`
	display: flex;
`;

const KeyStyled = styled.div`
	background-color: #4e5c68;
	color: white;
	padding: 3px;
	margin: 0 1px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 500;
`;

interface IShortcutWindowProps {
	onClose: () => void;
}

export class ShortcutWindowV5 extends React.Component<IShortcutWindowProps> {
	private _shortcutWindowRef = React.createRef<HTMLDivElement>();
	private _dragHandleRef = React.createRef<HTMLDivElement>();
	private _initPosX: number = 0; // initial position of the drag-handle when popup is opened
	private _initPosY: number = 0;
	private _actualPosX: number = 0; // saved when the user moves the popup
	private _actualPosY: number = 0;
	private _offsetX: number = 0; // resize width
	private _offsetY: number = 0;
	private _isDragIconGrabbed = false;
	private _resizeDetector: ResizeDetector;

	private onGrabDragHandle = () => {
		this._isDragIconGrabbed = true;

		// if the page was resized, the initPos needs to be updated
		this._initPosX -= this._offsetX;
		this._initPosY -= this._offsetY;
		this._offsetX = 0;
		this._offsetY = 0;
	};

	private onReleaseDragHandle = () => {
		if (this._isDragIconGrabbed) {
			this._isDragIconGrabbed = false;
		}
	};

	private onMoveWindow = (e: MouseEvent) => {
		if (this._isDragIconGrabbed) {
			this._shortcutWindowRef.current.style.transform = `translate(calc(${e.clientX - (this._initPosX - this._offsetX)}px - 50%), calc(${e.clientY - (this._initPosY - this._offsetY)}px - 50%))`;

			// save the actual position to calculate the difference when page is resized
			this._actualPosX = e.clientX;
			this._actualPosY = e.clientY;
		}
	};

	private calculateInitPosition = () => {
		const bbox = this._dragHandleRef.current.getBoundingClientRect();

		this._initPosX = (bbox.left + bbox.right) / 2;
		this._initPosY = (bbox.top + bbox.bottom) / 2;
	};

	private calculateOffset = () => {
		const bbox = this._dragHandleRef.current.getBoundingClientRect();
		const x = (bbox.left + bbox.right) / 2;
		const y = (bbox.top + bbox.bottom) / 2;

		// the amount of resize can be calculated from the difference of the last moved position (actualPos) and the position after resize (x,y)
		this._offsetX = this._actualPosX - x;
		this._offsetY = this._actualPosY - y;
	};

	public override componentDidMount(): void {
		document.addEventListener("mouseup", this.onReleaseDragHandle);
		document.addEventListener("mousemove", this.onMoveWindow);

		this.calculateInitPosition();

		this._resizeDetector = new ResizeDetector(document.body);
		this._resizeDetector.resize.add(this.calculateOffset, this);
	}

	public override componentWillUnmount(): void {
		document.removeEventListener("mouseup", this.onReleaseDragHandle);
		document.removeEventListener("mousemove", this.onMoveWindow);

		this._resizeDetector.resize.remove(this.calculateOffset, this);
		this._resizeDetector.dispose();
	}

	public override render() {
		return (
			<ShortcutWindowStyled ref={this._shortcutWindowRef}>
				<ShortcutWindowTitleStyled>
					<div
						ref={this._dragHandleRef}
						className="draghandle hbox alignCenter"
						onMouseDown={this.onGrabDragHandle}
					>
						<SVGIcon
							icon="drag"
							classNames="drag-icon"
						/>
					</div>
					<TitleContentStyled>Xyicon Keyboard Shortcuts</TitleContentStyled>
					<IconButtonV5
						IconComponent={XmarkLargeIcon}
						onClick={() => this.props.onClose()}
					/>
				</ShortcutWindowTitleStyled>
				<ShortcutWindowMessageStyled>
					{shortcuts.map((shortcut, index) => {
						return (
							<ShortcutStyled key={`${shortcut.description}_${shortcut.keys.join(",")}`}>
								<span>{shortcut.description}</span>
								<KeysStyled>
									{shortcut.keys.map((key, index) => (
										<KeyStyled key={key}>{key}</KeyStyled>
									))}
								</KeysStyled>
							</ShortcutStyled>
						);
					})}
				</ShortcutWindowMessageStyled>
			</ShortcutWindowStyled>
		);
	}
}

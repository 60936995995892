import * as React from "react";
import {inject, observer} from "mobx-react";
import {ReactSortable} from "react-sortablejs";
import styled, {css} from "styled-components";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {findFieldDataTypeKey} from "../../../../../data/models/field/FieldDataType";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {ToggleSwitchField} from "../../../../widgets/button/switch/ToggleSwitchField";
import type {IFieldAdapter} from "../../../../../data/models/field/Field";
import {isDefaultField} from "../../../../../data/models/field/FieldUtils";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {Functions} from "../../../../../utils/function/Functions";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import AngleUp from "../../../icons/chevron-up.svg?react";
import AngleDown from "../../../icons/chevron-down.svg?react";
import Delete from "../../../icons/delete.svg?react";
import Edit from "../../../icons/pen-field.svg?react";
import CirclePlus from "../../../icons/circle-plus.svg?react";
import Xmark from "../../../icons/xmark.svg?react";
import {ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {colorPalette} from "../../../styles/colorPalette";
import {fontSize, fontWeight, radius} from "../../../styles/styles";

interface ISectionData {
	label: string;
	fields: ISectionField[];
}

interface ISectionField {
	id: string; // refId
	noWrap: boolean;
}

interface ISortableFields {
	id: string;
	field: IFieldAdapter;
}

interface ISection extends ISectionData {
	id: string;
	dragCount: number;
	dragOver?: boolean;
	minimized?: boolean;
	focused?: boolean;
}

interface ILayoutSettingsProps {
	feature: XyiconFeature;
	appState?: AppState;
	transport?: TransportLayer;
}

interface ILayoutSettingsState {
	findString: string;
	sections: ISection[];
	openedUnassignedFieldID: number;
	collapsedSectionsIdArray: string[];
	isSaving: boolean;
	openedFeatures: XyiconFeature[];
	tempOpenedFeature: XyiconFeature[];
	typeOfDraggedElement: draggedElementType;
}

interface ILayoutDefinition {
	sections: ISectionData[];
}

enum draggedElementType {
	null = 0,
	availableField = 1,
	sectionField = 2,
	section = 3,
}

enum DragGroup {
	fields = "fields",
	sections = "sections",
}

@inject("transport")
@inject("appState")
@observer
export class LayoutSettingsV5 extends React.Component<ILayoutSettingsProps, ILayoutSettingsState> {
	private _isMounted: boolean = false;
	private _sortableStylingProps = {
		animation: 150,
		easing: "cubic-bezier(.17,.67,.83,.67)",
		ghostClass: "sortable-ghost-class__layouts",
		dragClass: "sortable-drag-class__layouts",
		chosenClass: "sortable-chosen__layouts",
		handle: ".drag-handle",
	};

	constructor(props: ILayoutSettingsProps) {
		super(props);

		this.state = {
			findString: "",
			sections: [],
			openedUnassignedFieldID: null,
			collapsedSectionsIdArray: [],
			isSaving: false,
			openedFeatures: [this.props.feature],
			tempOpenedFeature: [],
			typeOfDraggedElement: draggedElementType.null,
		};
	}

	private getId(field: IFieldAdapter) {
		return field.refId;
	}

	private getLayoutDefinition(feature: XyiconFeature = this.props.feature): ILayoutDefinition {
		const {appState} = this.props;

		const layout = appState.layouts[feature];

		return (
			layout || {
				sections: [],
			}
		);
	}

	private getSavedSections(): ISectionData[] {
		return this.getLayoutDefinition().sections;
	}

	private getCurrentSections(): ISectionData[] {
		if (this._isMounted) {
			return this.state.sections.map((section) => ({
				label: section?.label,
				fields: section?.fields?.map((field) => ({
					id: field.id,
					noWrap: field?.noWrap || false,
				})),
			}));
		}
	}

	private isSaveEnabled() {
		const savedSections = JSON.stringify(this.getSavedSections());
		const currentSections = JSON.stringify(this.getCurrentSections());

		const areThereUnsavedChanges = savedSections !== currentSections;

		this.props.appState.app.navigation.unsavedChanges = areThereUnsavedChanges
			? {
					title: "You have changed the Layout",
					description: "Click <b>Save</b> to continue, or <b>Reset</b> to discard the changes",
					onSave: this.onSaveClick,
				}
			: null;

		// If there is anything to save -> allow save button
		return areThereUnsavedChanges && !this.state.isSaving;
	}

	private onSaveClick = async () => {
		if (this._isMounted) {
			this.setState({isSaving: true});
			const {feature, transport} = this.props;
			const serializedData = this.serialize();

			await transport.services.fieldLayout.updateLayout(serializedData, feature);
			this.setState({isSaving: false});
		}
	};

	private onResetClick = () => {
		this.refreshLayout();
	};

	private sectionToSectionData = (section: ISection, index: number) => ({
		label: section.label,
		fields: section.fields.filter((f) => {
			const field = this.props.appState.actions.getFieldByRefId(f?.id);

			return this.filterField(field);
		}),
		id: `${index + 1}`,
		dragCount: 0,
		dragOver: false,
	});

	private serialize() {
		const layoutDefinition: ILayoutDefinition = {
			sections: this.state.sections.map(this.sectionToSectionData),
		};

		return layoutDefinition;
	}

	private onAddNewSectionClicked = () => {
		const {sections} = this.state;

		const newSections = sections.slice(0);

		newSections.push({
			label: "Section",
			id: `${sections.length + 1}`,
			dragCount: 0,
			fields: [],
		});

		if (this._isMounted) {
			this.setState({sections: newSections});
		}
	};

	private onDeleteSectionClicked = (index: number) => {
		const {sections} = this.state;

		const newSections = sections.slice(0);

		newSections.splice(index, 1);

		if (this._isMounted) {
			this.setState({sections: newSections});
		}
	};

	private getFieldsByFeature(feature: XyiconFeature) {
		const {appState} = this.props;
		const result: IFieldAdapter[] = [];

		const defaultFields = appState.defaultFields[feature] || [];
		const customFields = appState.fields[feature] || [];

		result.push(...defaultFields);
		result.push(...customFields);
		return result.filter(this.filterField);
	}

	private filterField = (field: IFieldAdapter) => {
		const {feature, appState} = this.props;

		if (!field) {
			return false;
		}
		if (isDefaultField(field)) {
			// Handle exceptions:
			// (Note: these could be enforced on the SidePanel too.)

			// Exception 1
			// Only custom fields are inherited from Boundary to xyicon
			if (feature === XyiconFeature.Xyicon && field.feature === XyiconFeature.Boundary) {
				if (field.default) {
					return false;
				}
			}

			// Exception 2
			// Xyicon inherits Catalog.model and Catalog.type but we don't want those to be shown in the layout
			// because they are already shown in XyiconDefaultFields (or should be)
			if (field.fixed) {
				if (feature === field.feature) {
					// Don't show the feature's own fixed fields
					return false;
				}
			}

			if (field.refId.includes("versionName") || field.refId.includes("issuanceDate")) {
				return false;
			}
		}
		return appState.actions.isFieldShownForFeature(field, feature);
	};

	private getSectionFieldById(id: string) {
		for (const section of this.state.sections) {
			const sectionField = section?.fields?.find((sectionField) => sectionField?.id === id);

			if (sectionField) {
				return sectionField;
			}
		}
		return null;
	}

	private onFindInput = (value: string) => {
		const {openedFeatures, findString, tempOpenedFeature} = this.state;
		const groups: XyiconFeature[] = this.props.appState.actions.getInheritedFeatures(this.props.feature);

		let features: XyiconFeature[] = [];

		ArrayUtils.copy(features, openedFeatures);

		if (this._isMounted) {
			if (!findString && value) {
				this.setState({tempOpenedFeature: features});
			} else if (findString && !value) {
				this.setState({
					openedFeatures: tempOpenedFeature,
					tempOpenedFeature: [],
				});
			}

			if (value) {
				groups.map((feature) =>
					this.onToggle(!!this.getFieldsByFeature(this.props.feature).find((field) => StringUtils.containsIgnoreCase(field.name, value)), feature),
				);
			}

			this.setState({findString: value});
		}
	};

	private onRemoveFieldFromSection = (field: IFieldAdapter) => {
		const {sections} = this.state;
		const id = this.getId(field);

		const section = sections.find((section) => section.fields.find((f) => f.id === id));
		const fieldIndex = section.fields.findIndex((f) => f.id === id);

		const newFields = section.fields.slice(0);

		newFields.splice(fieldIndex, 1);
		section.fields = newFields;

		const newSections = sections.slice(0);

		if (this._isMounted) {
			this.setState({sections: newSections});
		}
	};

	private onToggleFieldWrap = (field: ISectionField) => {
		field.noWrap = !field.noWrap;
		this.forceUpdate();
	};

	private filterSearch = (field: IFieldAdapter) => {
		const {findString} = this.state;

		if (findString) {
			if (!StringUtils.containsIgnoreCase(field.name, findString)) {
				return false;
			}
		}

		return true;
	};

	private findSectionForField(fieldId: string) {
		return this.state.sections.filter((section) => section?.fields.find((f) => f?.id === fieldId))[0] || null;
	}

	private isFieldAddedToSection(fieldId: string) {
		return !!this.findSectionForField(fieldId);
	}

	private renderField = (field: IFieldAdapter, inSection: boolean) => {
		const id = this.getId(field);
		const sectionField = this.getSectionFieldById(id);

		return (
			<LayoutSettingsFieldStyled className="field">
				<LayoutSettingsFieldSVGContainer className="drag-handle">
					<SVGIcon
						icon="drag"
						classNames="drag-icon"
					/>
				</LayoutSettingsFieldSVGContainer>
				<FieldTitleAndActionContainerStyled>
					<FieldTitleStyled className="label">
						<div className="fieldName">{field.name}</div>
						<div className="fieldType">{findFieldDataTypeKey(field.dataType)}</div>
					</FieldTitleStyled>
					<div className="flex_1" />
					{inSection && (
						<>
							{field.isNew && <div className="banner">New Field</div>}
							<FieldActionsContainerStyled>
								<ToggleSwitchField
									label="Full width"
									value={sectionField?.noWrap || false}
									onChange={() => this.onToggleFieldWrap(sectionField)}
									labelFirst={true}
									classNames="toggle-button"
								/>
								<IconButtonV5
									title="Remove"
									IconComponent={Xmark}
									className="close-button"
									onClick={() => this.onRemoveFieldFromSection(field)}
								/>
							</FieldActionsContainerStyled>
						</>
					)}
				</FieldTitleAndActionContainerStyled>
			</LayoutSettingsFieldStyled>
		);
	};

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<ILayoutSettingsProps>, nextContext: any): void {
		if (this.props.feature !== nextProps.feature) {
			this.refreshLayout(nextProps.feature);
		}
	}

	public override componentDidMount() {
		this._isMounted = true;
		this.refreshLayout();
	}

	public override componentWillUnmount() {
		const fields = this.getFieldsByFeature(this.props.feature);

		fields.forEach((field) => (field.isNew = false));

		this.props.appState.app.navigation.unsavedChanges = null;
		this._isMounted = false;
	}

	public refreshLayout(feature?: XyiconFeature) {
		const layout = this.getLayoutDefinition(feature);

		if (this._isMounted) {
			this.setState({sections: ObjectUtils.deepClone(layout.sections.map(this.sectionToSectionData))});
		}
	}

	private shouldBeOpen = (feature: XyiconFeature) => {
		const {openedFeatures} = this.state;

		return feature === this.props.feature || openedFeatures.includes(feature);
	};

	private getUnassignedFields = (feature: XyiconFeature) => {
		const fields = this.getFieldsByFeature(feature)
			.filter(this.filterSearch)
			.filter((field) => !this.isFieldAddedToSection(this.getId(field)) && !field.refId.includes("icon"))
			.sort((fieldA, fieldB) => StringUtils.sortIgnoreCase(fieldA.name, fieldB.name));

		return fields;
	};

	private renderUnassignedFields = (feature: XyiconFeature) => {
		const fields = this.getUnassignedFields(feature);
		const sortableFields: ISortableFields[] = fields.map((field) => ({id: field.refId, field}));

		return (
			<ReactSortable
				list={sortableFields}
				setList={Functions.emptyFunction}
				onStart={this.availableFieldDraggingStart}
				onEnd={this.draggingEnd}
				className="sortable vbox flexWrap"
				group={DragGroup.fields}
				forceFallback={true}
				filter=".ignore-element"
				{...this._sortableStylingProps}
			>
				{fields.length === 0 ? (
					<div className="empty hbox ignore-element">There are no available fields.</div>
				) : (
					fields.map((field, index) => (
						<div
							className="fieldCell"
							key={index}
						>
							{this.renderField(field, false)}
						</div>
					))
				)}
			</ReactSortable>
		);
	};

	private onSectionFieldCollapse = (sectionId: string) => {
		const sectionIndexArray = this.state.collapsedSectionsIdArray;

		if (sectionIndexArray.includes(sectionId)) {
			sectionIndexArray.splice(sectionIndexArray.indexOf(sectionId), 1);
		} else {
			sectionIndexArray.push(sectionId);
		}

		if (this._isMounted) {
			this.setState({collapsedSectionsIdArray: sectionIndexArray});
		}
	};

	private editSectionName = (section: ISection) => {
		section.focused = undefined ? true : !section.focused;
		this.forceUpdate();
	};

	private onToggle = (value: boolean, feature: XyiconFeature) => {
		const openedFeatures = this.state.openedFeatures;

		if (value) {
			ArrayUtils.addMutable(openedFeatures, feature);
		} else {
			ArrayUtils.removeMutable(openedFeatures, feature);
		}

		if (this._isMounted) {
			this.setState({openedFeatures});
		}
	};

	private onSetListOfSectionFields = async (newState: ISectionField[], sectionIndex: number) => {
		const {sections, typeOfDraggedElement} = this.state;

		if (
			!ObjectUtils.compare(newState, sections[sectionIndex]) &&
			(typeOfDraggedElement === draggedElementType.sectionField || typeOfDraggedElement === draggedElementType.availableField)
		) {
			await TimeUtils.waitForNextFrame();

			const tempSections: ISection[] = sections;

			tempSections[sectionIndex].fields = newState;

			if (this._isMounted) {
				this.setState({sections: tempSections});
			}
		}
	};

	private onSetListOfSection = (newState: ISection[]) => {
		const {sections, typeOfDraggedElement} = this.state;

		if (!ObjectUtils.compare(newState, sections) && typeOfDraggedElement === draggedElementType.section) {
			const tempSections: ISection[] = newState;

			if (this._isMounted) {
				this.setState({sections: tempSections});
			}
		}
	};

	private onSectionNameChange = (value: string, section: ISection) => {
		section.label = value;
		this.forceUpdate();
	};

	private dragging = (type: draggedElementType) => {
		if (this._isMounted) {
			this.setState({typeOfDraggedElement: type});
		}
	};

	private sectionDraggingStart = () => {
		this.dragging(draggedElementType.section);
	};

	private sectionFieldDraggingStart = () => {
		this.dragging(draggedElementType.sectionField);
	};

	private availableFieldDraggingStart = () => {
		this.dragging(draggedElementType.availableField);
	};

	private draggingEnd = () => {
		this.dragging(draggedElementType.null);
	};

	public override render() {
		const {feature, appState} = this.props;
		const {sections, findString, collapsedSectionsIdArray, typeOfDraggedElement} = this.state;
		const groups = appState.actions.getInheritedFeatures(feature);
		const isSaveEnabled = this.isSaveEnabled();

		return (
			<LayoutSettingsContainerStyled>
				<LayoutSettingsLeftContainerStyled className="left">
					<LayoutSettingsFindContainerStyled>
						<h3 className="label">Available Fields</h3>
						<SearchFieldV5
							value={findString}
							onInput={this.onFindInput}
							fullWidth
							placeholder="Input"
						/>
					</LayoutSettingsFindContainerStyled>
					<LayoutSettingsUnassignedFieldsContainerStyled>
						{typeOfDraggedElement === draggedElementType.sectionField ? (
							<ReactSortable
								className="availableDropArea"
								list={[]}
								setList={Functions.emptyFunction}
								group={DragGroup.fields}
								filter=".empty-text"
								{...this._sortableStylingProps}
							>
								<EmptyFieldContentStyled className="empty-text">Drop Field here</EmptyFieldContentStyled>
							</ReactSortable>
						) : (
							groups.map((feature: XyiconFeature, index: number) => (
								<ToggleContainerV5
									key={index}
									title={featureTitles[feature]}
									className="group"
									open={this.shouldBeOpen(feature)}
									onToggleOpen={(value) => this.onToggle(value, feature)}
									itemCount={this.getUnassignedFields(feature).length}
									showItemCount={true}
								>
									{this.renderUnassignedFields(feature)}
								</ToggleContainerV5>
							))
						)}
					</LayoutSettingsUnassignedFieldsContainerStyled>
				</LayoutSettingsLeftContainerStyled>
				<LayoutSettingsRightContainerStyled className="right">
					<TitleContainerStyled className="hbox">
						<div className="flex_1">
							<h3 className="title">Layout</h3>
						</div>
						<RightContainerTopActionButtonsStyled>
							<ButtonV5
								label="Reset"
								onClick={this.onResetClick}
								disabled={!isSaveEnabled}
								type="secondary"
							/>
							<ButtonV5
								label="Save"
								onClick={this.onSaveClick}
								disabled={!isSaveEnabled}
							/>
						</RightContainerTopActionButtonsStyled>
					</TitleContainerStyled>
					<RightContainerAddNewSectionContentStyled onClick={this.onAddNewSectionClicked}>
						<CirclePlus className="circle-plus" />
						<AddNewSectionTitleStyled>add new section</AddNewSectionTitleStyled>
					</RightContainerAddNewSectionContentStyled>
					<LayoutSettingsRightSectionContainerStyled
						$sectionDraggingActive={typeOfDraggedElement === draggedElementType.section}
						className={ReactUtils.cls("sections", {sectionDraggingActive: typeOfDraggedElement === draggedElementType.section})}
					>
						{sections.length === 0 ? (
							<div
								className="empty hbox"
								onClick={this.onAddNewSectionClicked}
							>
								<div className="vbox alignCenter">
									<SVGIcon icon="add" />
									<div>Add new section to start</div>
								</div>
							</div>
						) : (
							<ReactSortable
								list={sections}
								setList={this.onSetListOfSection}
								onStart={this.sectionDraggingStart}
								onEnd={this.draggingEnd}
								className="sections"
								group={DragGroup.sections}
								forceFallback={true}
								{...this._sortableStylingProps}
							>
								{sections.map((section, sectionIndex) => (
									<SectionContainerLayoutStyled
										className="section-container__layouts"
										key={sectionIndex}
									>
										<SectionHeaderStyled>
											<LayoutRightContainerHeaderDragHandleStyled className="drag-handle">
												<SVGIcon
													icon="drag"
													classNames="drag-icon"
												/>
											</LayoutRightContainerHeaderDragHandleStyled>
											<ClickToEditInputV5
												value={section?.label}
												focused={section?.focused || false}
												onChange={(value) => this.onSectionNameChange(value, section)}
											/>
											<SectionHeaderActionButtonsContainer>
												<IconButtonV5
													title="Edit Section name"
													IconComponent={Edit}
													className="edit"
													onClick={() => this.editSectionName(section)}
												/>
												<IconButtonV5
													title="Delete Section"
													IconComponent={Delete}
													className="delete"
													onClick={() => this.onDeleteSectionClicked(sectionIndex)}
												/>
												<IconButtonV5
													title="Collapse Section"
													IconComponent={collapsedSectionsIdArray.includes(section?.id) ? AngleDown : AngleUp}
													className="collapse"
													onClick={() => this.onSectionFieldCollapse(section?.id)}
												/>
											</SectionHeaderActionButtonsContainer>
										</SectionHeaderStyled>
										{!collapsedSectionsIdArray.includes(section?.id) && typeOfDraggedElement !== draggedElementType.section && (
											<CollappsedSectionFieldsStyled
												className={ReactUtils.cls("fields", {
													empty: section?.fields.length === 0,
												})}
											>
												<ReactSortable
													list={section?.fields}
													setList={(newState) => this.onSetListOfSectionFields(newState, sectionIndex)}
													onStart={this.sectionFieldDraggingStart}
													onEnd={this.draggingEnd}
													className="sortable"
													group={DragGroup.fields}
													forceFallback={true}
													{...this._sortableStylingProps}
												>
													{section?.fields.length === 0 ? (
														<div className="empty-fields noWrap">
															Drag and drop unassigned fields in this section.
															<br />
															These fields will show up in the details panel.
														</div>
													) : (
														section?.fields.map((sectionField, fieldsInSectionIndex) => {
															const field = appState.actions.getFieldByRefId(sectionField?.id);

															if (!this.filterField(field)) {
																return null;
															}
															return (
																<div
																	key={fieldsInSectionIndex}
																	className={ReactUtils.cls("fieldCell", {noWrap: sectionField?.noWrap})}
																>
																	{this.renderField(field, true)}
																</div>
															);
														})
													)}
												</ReactSortable>
											</CollappsedSectionFieldsStyled>
										)}
									</SectionContainerLayoutStyled>
								))}
							</ReactSortable>
						)}
					</LayoutSettingsRightSectionContainerStyled>
				</LayoutSettingsRightContainerStyled>
				<LayoutSettingsBottomShadowStyled />
			</LayoutSettingsContainerStyled>
		);
	}
}

const LayoutSettingsContainerStyled = styled.div`
	display: flex;
	height: 920px;

	.fieldCell {
		&.noWrap {
			grid-column: 1 / -1;
		}
	}
`;

const LayoutSettingsLeftContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 362px;
	gap: 8px;
	padding: 16px;
	border-right: 2px solid ${colorPalette.gray.c300};
`;

const LayoutSettingsFindContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	.label {
		font-size: ${fontSize.xl};
		font-weight: 800;
	}
`;

const LayoutSettingsUnassignedFieldsContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	.availableDropArea {
		height: 100%;
		border: dashed 2px #404653;
		transition: ease-in-out border-color 0.2s;

		&:hover {
			border-color: #3495f0;
		}

		& *:not(.empty-text) {
			display: none;
			color: #404653;
		}
	}

	.ToggleContainer {
		padding-right: base.$sm;

		.children.open {
			padding: 0;
			margin-top: base.$md;

			.sortable {
				margin-top: 8px;

				.empty {
					height: 100px;
					border: solid 1px #f5f5f5;
					align-items: center;
					justify-content: center;
					color: ${colorPalette.libraryColors.explosive};
				}
			}
		}
	}

	.group {
		.filter-title {
			color: ${colorPalette.gray.c950};
		}
	}
`;

const EmptyFieldContentStyled = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TitleContainerStyled = styled.div`
	display: flex;

	.title {
		font-size: ${fontSize.xl};
		font-weight: ${fontWeight.bold};
		line-height: 24px;
	}
`;

const LayoutSettingsBottomShadowStyled = styled.div`
	width: 100%;
	height: 50px;
	bottom: 0px;
	position: absolute;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 6.77%, rgba(255, 255, 255, 0.75) 51.04%, #ffffff 100%);
`;

const FieldTitleAndActionContainerStyled = styled.div`
	display: flex;
	align-items: center;
	padding: 0px 8px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-radius: ${radius.sm};
`;

const LayoutSettingsFieldStyled = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	gap: 4px;
	height: 40px;

	svg.drag-icon {
		display: none;
		width: 8px;
		height: 14px;
	}

	&:hover {
		${FieldTitleAndActionContainerStyled} {
			background-color: ${colorPalette.gray.c200Light};
		}
		.actions {
			visibility: visible;
		}

		svg.drag-icon {
			display: flex;
		}

		.banner {
			display: none;
		}
	}
`;

const LayoutSettingsFieldSVGContainer = styled.div`
	min-width: 8px;
`;

const FieldTitleStyled = styled.div`
	display: flex;
	flex-direction: column;
	height: 100;
	justify-content: center;

	.fieldName {
		font-size: ${fontSize.lg};
		font-weight: ${fontWeight.normal};
		color: ${colorPalette.gray.c950};
	}

	.fieldType {
		font-size: ${fontSize.md};
		font-weight: ${fontWeight.normal};
		color: ${colorPalette.gray.c950};
	}
`;

const FieldActionsContainerStyled = styled.div`
	display: flex;
	align-items: center;
	height: 40px;

	.toggle-button {
		display: flex;
		align-items: center;

		.label {
			padding: 4px;
			font-size: ${fontSize.sm};
			font-weight: ${fontWeight.normal};
			color: ${colorPalette.gray.c950};
		}
	}

	.close-button {
		svg {
			width: 16px;
			height: 16px;
		}
	}
`;

const LayoutSettingsRightContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 18px 17px;
	width: 800px;
`;

const RightContainerAddNewSectionContentStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	color: ${colorPalette.blueGray.c500Primary};

	svg.circle-plus {
		width: 16px;
		height: 16px;
	}

	&:hover {
		color: ${colorPalette.blueGray.c700Dark};
	}
`;

const RightContainerTopActionButtonsStyled = styled.div`
	display: flex;
	gap: 8px;
`;

const AddNewSectionTitleStyled = styled.div`
	font-size: ${fontSize.md};
	font-weight: ${fontWeight.normal};
`;

const LayoutSettingsRightSectionContainerStyled = styled.div<{$sectionDraggingActive: boolean}>`
	.sections {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 8px;
	}

	${(props) => {
		if (props.$sectionDraggingActive) {
			return css`
				overflow: scroll;
				height: 100%;
			`;
		}
	}}

	svg.drag-icon {
		width: 8px;
		height: 14px;
	}
`;

const LayoutRightContainerHeaderDragHandleStyled = styled.div`
	svg.drag-icon {
		&:hover {
			fill: ${colorPalette.blueGray.c500Primary};
		}

		fill: ${colorPalette.gray.c950};
	}
`;

const SectionContainerLayoutStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	border: 1px solid ${colorPalette.gray.c950};
	border-radius: ${radius.xl};
	padding: 8px;
	background-color: ${colorPalette.gray.c100};

	&:hover {
		background-color: ${colorPalette.primary.c200Light};
		border: 1px solid ${colorPalette.blueGray.c500Primary};
	}
`;

const SectionHeaderStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	padding-left: 8px;

	${ClickToEditInputStyled} {
		.unfocused {
			border: 0;
		}
	}
`;

const SectionHeaderActionButtonsContainer = styled.div`
	display: flex;

	.collapse {
		&:hover {
			background-color: ${colorPalette.primary.c200Light};
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.delete,
	.edit {
		&:hover {
			background-color: ${colorPalette.primary.c200Light};
		}

		svg {
			width: 16px;
			height: 16px;

			path {
				stroke-width: 2px;
			}
		}
	}
`;

const CollappsedSectionFieldsStyled = styled.div`
	padding: 8px;
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: ${radius.md};
	background-color: #ffffff;

	.sortable {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.fieldCell + .empty-fields {
			display: none;
		}
	}

	&.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 100px;
	}

	.empty-fields {
		font-size: base.$font-md;
		color: #a9a3a3;
		line-height: 125%;

		&.noWrap {
			grid-column: 1 / -1;
		}
	}

	&.over {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&.invisible {
		height: 0;
		padding: 0;
		border: none;
	}
`;

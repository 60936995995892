import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import type {IViewColumn} from "../../../../data/models/ViewUtils";
import {XyiconFeature} from "../../../../generated/api/base";
import type {Navigation} from "../../../../Navigation";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {Pointer} from "../../../../utils/interaction/Pointer";
import type {AppState} from "../../../../data/state/AppState";
import type {App} from "../../../../App";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {IModel} from "../../../../data/models/Model";
import {BoundaryUtils} from "../../../../data/models/BoundaryUtils";
import LocationPinIcon from "../../icons/location-pin.svg?react";
import type {Report} from "../../../../data/models/Report";
import {ReactUtils} from "../../../utils/ReactUtils";
import {PointerDetectorReact} from "../../../interaction/PointerDetectorReact";
import {CheckboxInputStyled, CheckboxInputV5} from "../../details/datatypes/CheckboxInputV5";
import type {Boundary} from "../../../../data/models/Boundary";
import {minHeaderWidth, minIconHeaderWidth} from "../../../widgets/table/TableConstants";
import {Functions} from "../../../../utils/function/Functions";
import {BoundarySpaceMapSelectorV5} from "../../spaceeditor/BoundarySpaceMapSelectorV5";
import {colorPalette} from "../../styles/colorPalette";
import {ELLIPSIS} from "../../styles/styles";
import {SelectInputStyled} from "../../input/select/SelectInputV5";
import {DateTimeInputStyled} from "../../input/datetime/DateTimeInputV5";
import {NavigationEnum} from "../../../../Enums";
import {TableCellV5} from "./TableCellV5";

interface ITableRowProps<T> {
	id: string | number;
	item: T;
	fields: IFieldAdapter[];
	headers: Partial<IViewColumn>[];
	index: number;
	selected: boolean;
	focused?: boolean;
	onFocus?: (event: React.MouseEvent, item: T) => void;
	isVisibleInSpaceEditor?: (item: T) => boolean;
	showNotification?: (item: T) => void;
	onDoubleClick?: (item: T) => void;
	onDuplicateClick?: (item: T) => void;
	onCheckboxRowClick?: (event: React.MouseEvent, item: T) => void;
	showQuickLinks: (e: React.MouseEvent, item: T, index: number) => void;
	showCheckboxColumn: boolean;
	quickLinksActive: boolean;
	moduleName: string;
	feature?: XyiconFeature;
	navigation?: Navigation;
	transport?: TransportLayer;
	tableRowIcon?: boolean;
	isCellContentsWrappingOn?: boolean;
	onMouseMove?: (pointer: Pointer, row: TableRowV5<T>) => void;
	selectedItems?: T[];
	focusedItems?: T[];
	scrollTableToShowFullCell?: (e: React.MouseEvent) => void;
	isSimpleTable?: boolean;
	app?: App;
	appState?: AppState;
}

type Highlight = "Top" | "Middle" | "Bottom" | "None";

interface ITableRowState {
	expand: boolean;
	isBoundarySelectorOpen: boolean;
	highlight: Highlight; // Top/Bottom: Shows an empty row with dashed border on top/bottom of this one. Middle: show dashed border with blue background on this row
	isGreyedOut: boolean;
	isNotAllowed: boolean;
	dragNdrop: boolean;
}

@inject("app")
@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class TableRowV5<T> extends React.Component<ITableRowProps<T>, ITableRowState> {
	private readonly _hoverClassName = "hoverDashed";

	constructor(props: ITableRowProps<T>) {
		super(props);
		this.state = {
			expand: false,
			isBoundarySelectorOpen: false,
			highlight: "None",
			isGreyedOut: false,
			isNotAllowed: false,
			dragNdrop: false,
		};
	}

	private onMouseDown = (event: React.MouseEvent) => {
		if (event.shiftKey) {
			event.preventDefault();
		}
	};

	private onMouseMove = (pointer: Pointer) => {
		this.props.onMouseMove?.(pointer, this);
	};

	private onClick = (event: React.MouseEvent) => {
		// Ignore double click events
		if (event.detail === 1) {
			this.props.onFocus(event, this.props.item);
		}
	};

	private onDoubleClick = (event: React.MouseEvent) => {
		const item: any = this.props.item;

		if (!item.isUnplotted) {
			this.props.onDoubleClick?.(item);
		}
	};

	private onShowBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: true,
		});
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: false,
		});
	};

	private get isUnplotted() {
		const item = this.props.item as unknown as Xyicon;

		return item.isUnplotted;
	}

	private onItemActionClick = () => {
		const {item, feature, appState, navigation, isVisibleInSpaceEditor, showNotification} = this.props;

		if (!this.isUnplotted) {
			if ((item as unknown as IModel).ownFeature === XyiconFeature.Space) {
				navigation.goApp(NavigationEnum.NAV_SPACE, (item as unknown as IModel).id);
			} else {
				if (!isVisibleInSpaceEditor(item)) {
					showNotification(item);
				}
				if ((item as unknown as IModel).ownFeature === XyiconFeature.Xyicon && feature === XyiconFeature.Xyicon) {
					appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true);
				} else if ((item as unknown as IModel).ownFeature === XyiconFeature.Boundary && feature === XyiconFeature.Boundary) {
					if (BoundaryUtils.doesHaveMultipleSpaceMaps(item as unknown as IModel)) {
						this.onShowBoundarySpaceMaps();
					} else {
						appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true);
					}
				}
			}
		}
	};

	private getIcon() {
		return <LocationPinIcon />;
	}

	private onOpenContextMenu = (e: React.MouseEvent) => {
		const {item, index, showQuickLinks} = this.props;

		if (!this.isUnplotted) {
			showQuickLinks(e, item, index);
		} else {
			e.preventDefault();
		}
	};

	private getRowIcon() {
		const {quickLinksActive} = this.props;
		const item = this.props.item as unknown as IModel;

		if (item.ownFeature === XyiconFeature.Boundary || item.ownFeature === XyiconFeature.Xyicon) {
			return (
				<div
					className={ReactUtils.cls("quickLinksButton link", {active: quickLinksActive, disabled: this.isUnplotted})}
					onClick={this.onItemActionClick}
					onContextMenu={this.onOpenContextMenu}
					title={
						item.ownFeature === XyiconFeature.Xyicon
							? this.isUnplotted
								? "Cannot open unplotted xyicons in the Space Editor"
								: "Open in Space Editor"
							: ""
					}
				>
					{this.getIcon()}
				</div>
			);
		}
	}

	private getEmptyRow() {
		return <div className={`tr ${this._hoverClassName} empty`} />;
	}

	public override render() {
		const {
			item,
			id,
			fields,
			headers,
			selected,
			showCheckboxColumn,
			tableRowIcon,
			isCellContentsWrappingOn,
			onCheckboxRowClick,
			appState,
			scrollTableToShowFullCell,
			focused,
			isSimpleTable,
			focusedItems,
		} = this.props;

		const {isGreyedOut, isNotAllowed, dragNdrop, isBoundarySelectorOpen, highlight} = this.state;
		const highlightClassName = highlight === "Middle" ? this._hoverClassName : "";

		return (
			<>
				{highlight === "Top" && this.getEmptyRow()}
				<PointerDetectorReact
					onHoverMove={this.onMouseMove}
					preventDefaultOnTouch={false}
					preventDefaultOnMouseMove={false}
					preventDefaultOnMouseUp={false}
				>
					<TableRowStyled
						data-key={id}
						onMouseDown={this.onMouseDown} // Option A to remove selection
						onClick={this.onClick}
						onDoubleClick={this.onDoubleClick}
						onContextMenu={this.onOpenContextMenu}
						className={ReactUtils.cls(`tr ${highlightClassName}`, {
							wrapped: isCellContentsWrappingOn,
							hasQuickLinks: tableRowIcon,
							lower: tableRowIcon,
							focused,
							selected,
							isGreyedOut,
							isNotAllowed,
							dragNdrop,
						})}
					>
						{tableRowIcon && this.getRowIcon()}
						{showCheckboxColumn && (
							<div
								className="td"
								onClick={(event) => onCheckboxRowClick(event, item)}
								onDoubleClick={Functions.stopPropagation}
							>
								<CheckboxInputV5
									value={selected}
									disabled={true}
								/>
							</div>
						)}
						{isBoundarySelectorOpen && (
							<BoundarySpaceMapSelectorV5
								item={item as unknown as Boundary}
								onClose={this.onHideBoundarySpaceMaps}
							/>
						)}
						{fields.map((field, col) => {
							const w = headers[col].field.includes("icon")
								? `${headers[col]?.width || minIconHeaderWidth}px`
								: `${Math.max(headers[col]?.width, minHeaderWidth) || minHeaderWidth}px`;
							const style: React.CSSProperties = {
								width: w,
								minWidth: w,
							};

							return (
								<TableCellV5
									isCellContentsWrappingOn={isCellContentsWrappingOn}
									key={isSimpleTable ? col : `${field.refId}${col}`}
									field={appState.actions.getFieldByRefId(headers[col].field)}
									style={style}
									fieldValue={field}
									item={item as unknown as IModel}
									selectedItems={focusedItems as unknown as IModel[]}
									scrollTableToShowFullCell={scrollTableToShowFullCell}
								/>
							);
						})}
					</TableRowStyled>
				</PointerDetectorReact>
				{highlight === "Bottom" && this.getEmptyRow()}
			</>
		);
	}
}

const TableRowStyled = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	min-height: 40px;
	cursor: pointer;
	transition: all 0.3s ease-out;
	border-bottom: solid 1px ${colorPalette.gray.c200Light};
	padding: 2px 0;

	&.hasQuickLinks {
		padding-left: 40px;
	}

	&.lower {
		min-height: 30px;
	}

	.BoundarySpaceMapSelector {
		top: initial;
		right: initial;
		left: 0;
		bottom: 0;
		color: black;
	}

	&:hover {
		background: ${colorPalette.gray.c100};

		.favoriteCatalogItems {
			background: ${colorPalette.primary.c700Dark};
			box-shadow: none;
			opacity: 1;
		}
	}

	&.wrapped {
		.td {
			position: relative;
			padding: 4px 4px 0 4px;

			${CheckboxInputStyled} {
				padding-top: 7px;
			}

			&.boolean .ToggleSwitch {
				display: none;
			}

			&.editable.boolean {
				> div {
					border: 2px solid transparent;
					border-radius: 4px;
					padding: 0 8px;

					&:hover {
						border-color: ${colorPalette.primary.c500Primary};

						.ToggleSwitch {
							display: initial;
						}
					}
				}

				.value {
					width: 100%;

					${ELLIPSIS}

					&.blank {
						color: ${colorPalette.gray.c300};
					}
				}

				.ToggleSwitch .slider {
					background-color: ${colorPalette.primary.c200Light};
				}
			}

			&.editable {
				margin: 0;

				.textCursor {
					cursor: text;
				}

				&:focus-within {
					input {
						background-color: ${colorPalette.white};
						color: ${colorPalette.gray.c950};
						transition: none;
						margin: 0;
						padding: 5px;
						border-radius: 4px;
						max-width: 100%;

						&.number {
							text-align: right;
						}

						&:hover {
							border: solid 2px ${colorPalette.primary.c500Primary};
						}
					}
				}

				.modelField,
				.cellContent,
				${SelectInputStyled}, ${DateTimeInputStyled} {
					border: 2px solid transparent;
					border-radius: 4px;
					min-height: 29px;

					&:hover {
						border-color: ${colorPalette.primary.c500Primary};
					}
				}

				.MultiSelectInput {
					.list {
						.item {
							&:first-child,
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}

			.cellContent {
				white-space: pre;
				flex-grow: 1;
				padding: 2px;

				&.disabled {
					padding-top: 7px;
				}

				&.overflowhidden {
					max-height: 160px;
				}

				.spinner {
					position: initial;
				}

				.tdRow {
					display: block;

					&:not(:last-child):after {
						content: "";
					}
				}
			}

			&.textAlignRight {
				.cellContent {
					text-align: right;
				}
			}

			.multiline {
				&.inline {
					textarea {
						padding: 2px;
						max-height: 160px;
					}
				}
			}
		}

		&:hover {
			.td {
				&.editable {
					.cellContent,
					${SelectInputStyled}, ${DateTimeInputStyled} {
						.ToggleSwitch {
							&.inline {
								.slider {
									background-color: #a9a3a3;
								}

								input:checked ~ .slider {
									background-color: ${colorPalette.primary.c200Light};
								}
							}
						}
					}
				}
			}

			&.selected {
				.td {
					&.editable {
						&.boolean {
							&:hover:first-child {
								background-color: ${colorPalette.primary.c200Light};
							}
						}

						& > div > .cellContent {
							&:hover {
								background-color: ${colorPalette.primary.c200Light};
							}
						}

						.modelField .cellContent {
							&:hover {
								background-color: ${colorPalette.primary.c200Light};
							}
						}
					}
				}
			}
		}
	}

	&.hoverDashed {
		background: #d6eafc;
		border: 1px dashed ${colorPalette.primary.c500Primary};
		border-bottom: 1px dashed ${colorPalette.primary.c500Primary};
	}

	&.empty {
		min-height: 0px;
		height: 1px;
	}

	&.isGreyedOut {
		filter: grayscale(1);
	}

	&.isNotAllowed {
		border: 1px dashed #ef5350;
		background-color: #fcdddc;
	}

	&:not(.dragNdrop):hover {
		.quickLinksButton {
			opacity: 1;

			&.disabled {
				opacity: 0.5;
			}
		}
	}

	.td {
		.naked.Button {
			cursor: pointer;
			background: none;

			.label {
				color: ${colorPalette.white};
			}
		}
	}

	.quickLinksButton {
		min-width: 32px;
		min-height: 100%;
		display: flex;
		align-self: flex-start;
		justify-content: space-around;
		flex-direction: column;
		padding: 4px;
		opacity: 0;
		transition: opacity 0.4s ease;
		position: absolute;
		top: 0;
		left: 0;
		color: ${colorPalette.primary.c500Primary};

		&.active {
			opacity: 1;
		}

		&.disabled {
			pointer-events: auto;
		}

		&.link {
			width: 40px;

			.icon {
				fill: ${colorPalette.primary.c200Light};
			}
		}

		> div {
			background: ${colorPalette.primary.c200Light};
			width: 100%;
			height: 2px;

			&:last-child {
				width: 50%;
			}
		}

		&.favoriteCatalogItems {
			opacity: 1;
			background: none;
			box-shadow: none;

			.favorite-star {
				display: block;
				fill: #f2c010;
			}

			.favorite-unstar,
			.unfavorite-star,
			.unfavorite-unstar {
				display: none;
			}
		}
	}

	transition: background-color 0.4s ease;

	&.selected {
		background: ${colorPalette.primary.c200Light};

		.quickLinksButton {
			opacity: 1;

			&.disabled {
				opacity: 0.5;
			}
		}
	}

	&.focused {
		background-color: ${colorPalette.gray.c200Light};
		outline: 2px solid ${colorPalette.primary.c500Primary};
		z-index: 1;
		&.selected {
			background: ${colorPalette.primary.c200Light};
		}
	}

	.td {
		min-width: 40px;
		padding-left: 4px;

		.cellContent {
			span {
				${ELLIPSIS}
			}

			.tdRow {
				${ELLIPSIS}
				display: block;

				&:not(:last-child):after {
					content: "";
				}
			}
		}

		.naked.Button {
			width: fit-content;
			margin: 2px 0 4px;
			padding: 0;
			height: 16px;

			.label {
				color: ${colorPalette.primary.c500Primary};
				font-size: 14px;
			}
		}

		&.italic {
			font-style: italic;
			color: ${colorPalette.gray.c400};
		}
	}

	.placeholderCell {
		min-width: 40px;
	}
`;

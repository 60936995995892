import {ConversionUtils} from "../data/ConversionUtils";

export class BrowserWindow {
	// --------------------------------------------------------------------------------------------------
	// Platform detection

	public static inBrowser(): boolean {
		return !BrowserWindow.onNode();
	}

	public static onNode(): boolean {
		return typeof window === "undefined";
	}

	public static getURLPathWithoutQuery() {
		return `${location.protocol}//${location.host}${location.pathname}`;
	}

	private static _isDesktop: boolean = undefined;

	public static isDesktop(): boolean {
		if (BrowserWindow._isDesktop === undefined) {
			BrowserWindow._isDesktop = !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
		}

		return BrowserWindow._isDesktop;
	}

	public static isIE() {
		return !!navigator.userAgent.match(/Trident/);
		// navigator.userAgent.indexOf("Trident/7.0")
	}

	public static isEdge() {
		return /Edge\/\d./i.test(navigator.userAgent);
		// navigator.userAgent.indexOf("Edge") > -1
	}

	public static isMS() {
		return this.isIE() || this.isEdge();
	}

	// --------------------------------------------------------------------------------------------------
	// Query params

	public static getQueryParams(): any {
		if (!BrowserWindow.inBrowser()) {
			// we"re on node
			return {};
		}
		return ConversionUtils.htmlDecode(window.location.search);
	}

	// --------------------------------------------------------------------------------------------------
	// Scroll functions

	public static getScrollX(): number {
		return window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
	}

	public static getScrollY(): number {
		return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
	}

	// --------------------------------------------------------------------------------------------------
	// Clear

	public static clearSelection() {
		if (window.getSelection) {
			const selection = window.getSelection();

			if (selection.empty) {
				// Chrome
				selection.empty();
			} else if (selection.removeAllRanges) {
				// FireFox
				selection.removeAllRanges();
			}
		} else if ((document as any).selection) {
			// old IE
			(document as any).selection.empty();
		}
	}

	public static selectAll(element: HTMLElement) {
		const range = document.createRange();

		range.selectNodeContents(element);
		const sel = window.getSelection();

		sel.removeAllRanges();
		sel.addRange(range);
	}

	public static setCaretPosition(element: any, caretPos: number) {
		if (element) {
			if (element.createTextRange) {
				const range = element.createTextRange();

				range.move("character", caretPos);
				range.select();
			} else {
				if (element.selectionStart) {
					element.focus();
					element.setSelectionRange(caretPos, caretPos);
				} else {
					element.focus();
				}

				element.blur();
			}
		}
	}

	// --------------------------------------------------------------------------------------------------
	// Clipboard

	public static copyToClipboard(data: any) {
		const textArea = document.createElement("textarea");

		textArea.value = data;

		document.body.appendChild(textArea);

		textArea.select();

		try {
			const successful = document.execCommand("copy");
			const msg = successful ? "successful" : "unsuccessful";

			console.log(`Copying text command was ${msg}`);
		} catch (err) {
			console.log("Error: unable to copy");
		}

		document.body.removeChild(textArea);
	}

	// --------------------------------------------------------------------------------------------------
	// System info

	// public static getSystemInfo(): any
	// {
	// 	const result: {[key: string]: any} = {};

	// 	BrowserWindow.addProperties(result, window.navigator, "", [
	// 		"appCodeName",
	// 		"appName",
	// 		"appVersion",
	// 		"cookieEnabled",
	// 		"doNotTrack",
	// 		"hardwareConcurrency",
	// 		"language",
	// 		"maxTouchPoints",
	// 		"platform",
	// 		"product",
	// 		"productSub",
	// 		"userAgent",
	// 		"vendor",
	// 		"vendorSub"
	// 	]);

	// 	BrowserWindow.addProperties(result, window.screen, "screen_", [
	// 		"width",
	// 		"height",
	// 		"availHeight",
	// 		"availWidth",
	// 		"availTop",
	// 		"availLeft",
	// 		"colorDepth",
	// 	]);

	// 	const orientation = window.screen && window.screen["orientation"];

	// 	if (orientation)
	// 	{
	// 		result["orientation_angle"] = orientation.angle;
	// 		result["orientation_type"] = orientation.type;
	// 	}

	// 	result["screenX"] = window.screenX;
	// 	result["screenY"] = window.screenY;

	// 	return result;
	// }

	// private static addProperties(result: any, object: any, prefix: string, keys: string[])
	// {
	// 	if (object)
	// 	{
	// 		for (const key of keys)
	// 		{
	// 			result[prefix + key] = object[key];
	// 		}
	// 	}
	// }

	// --------------------------------------------------------------------------------------------------
	// Navigation utils

	public static redirectToURL(url: string) {
		window.location.href = url;
	}
}

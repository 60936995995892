import * as React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import styled from "styled-components";
import type {Field, IFieldAdapter} from "../../../../data/models/field/Field";
import type {IModel} from "../../../../data/models/Model";
import type {AppState} from "../../../../data/state/AppState";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FieldDataType, Permission, XyiconFeature} from "../../../../generated/api/base";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {LookupUtils} from "../../../modules/abstract/sidepanel/tabs/details/LookupUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {FLEXCENTER, radius} from "../../styles/styles";
import {TableCellValueV5} from "./TableCellValueV5";

interface ITableCellProps {
	isCellContentsWrappingOn?: boolean;
	style: React.CSSProperties;
	field: IFieldAdapter;
	fieldValue: any;
	item: IModel;
	appState?: AppState;
	selectedItems?: IModel[];
	scrollTableToShowFullCell?: (e: React.MouseEvent) => void;
}

interface ITableCellState {
	isContentOverflow: boolean;
	inEditMode: boolean;
	isChanged: boolean;
	value: string;
	caretPosition: number;
}

@inject("appState")
@observer
export class TableCellV5 extends React.Component<ITableCellProps, ITableCellState> {
	private _tableCell = React.createRef<HTMLDivElement>();
	private _maxCellContentHeight = 200;

	constructor(props: ITableCellProps) {
		super(props);
		this.state = {
			isContentOverflow: false,
			inEditMode: false,
			isChanged: false,
			value: props.appState.actions.getOwnFieldValue(props.item, props.field?.refId),
			caretPosition: 0,
		};
	}

	@computed
	private get isUpdating(): boolean {
		const {item, field, appState} = this.props;

		if (!item || !field || !appState) {
			return false;
		}

		return appState.itemFieldUpdateManager.isItemFieldBeingUpdated(item.id, field.refId, item.ownFeature);
	}

	private calculateIfCellContentOverflow() {
		this.setState({
			isContentOverflow: this._tableCell.current?.offsetHeight > this._maxCellContentHeight,
		});
	}

	private onEditCell = (e: React.MouseEvent) => {
		const {selectedItems, item, scrollTableToShowFullCell, fieldValue} = this.props;
		const isCellEditable = this.isCellEditable;
		const sel = window?.getSelection();

		if (sel?.rangeCount) {
			const range = sel.getRangeAt(0);
			const preRange = range.cloneRange();
			const element = this._tableCell.current;
			const cellContent = this.splitCellContent(fieldValue);

			preRange.selectNodeContents(element);
			preRange.setEnd(range.endContainer, range.endOffset);

			let caretPosition = preRange.toString().length;

			if (Array.isArray(cellContent)) {
				let i = 0;
				let sum = 0;

				while (!isNaN(sum) && sum < caretPosition) {
					sum += (cellContent[i] as string)?.length;
					i++;
				}

				if (!isNaN(sum)) {
					// caretPosition will be the same in end of n. line and start of (n + 1). line
					// if endOffset is 0, we know that we are in the (n + 1). line, so caretPosition should be incremented
					if (caretPosition === sum && preRange.endOffset === 0) {
						caretPosition++;
					}
					caretPosition += i - 1;
				} else {
					caretPosition = 0;
				}
			}

			this.setState({caretPosition});
		}

		scrollTableToShowFullCell?.(e);

		// I uncommented this, because it stopped checked rows to be focused when clicked...
		// if ((this.isSpaceViewRendererMounted && isCellEditable) || selectedItems.some(i => i.id === item.id))
		// {
		// 	e.stopPropagation();
		// }

		if (isCellEditable && !this.state.inEditMode) {
			e.stopPropagation();
			this.setState({inEditMode: true});
		}
	};

	private onKeyboardPress = (event: KeyboardEvent) => {
		if (this.isCellEditable && this.state.inEditMode) {
			const {field} = this.props;

			switch (event.key) {
				case KeyboardListener.KEY_ENTER:
					// Enter keypress doesn't call onBlur for Numeric fields (NumberInput is different from other input types)
					if (field.dataType === FieldDataType.Numeric) {
						this.onBlur();
					}
					break;
				case KeyboardListener.KEY_ESCAPE:
					this.setState({inEditMode: false});
					break;
			}
		}
	};

	private onBlur = () => {
		const {appState, field, selectedItems, item} = this.props;

		this.setState({inEditMode: false});
		if (this.state.isChanged) {
			const itemsToCheckPermission = this.isSpaceViewRendererMounted ? [item] : selectedItems;
			const itemsToUpdate = this.getItemsWithPermission(itemsToCheckPermission);
			// if the Xyicon Model is changed inline in Catalog Grid View, the id should be model instead of 30/model
			const refId = field.refId.includes("model") && item.ownFeature === XyiconFeature.XyiconCatalog ? "model" : field.refId;

			this.setState({isChanged: false});
			TimeUtils.waitUpdate(appState.actions.updateFields(itemsToUpdate, {[refId]: this.state.value}), this.props.appState.app.notificationContainer);
		}
	};

	private onChange = (value: string) => {
		const {appState, field, selectedItems, item} = this.props;

		// onChange messes up the other field types, however SingleSelect, MultiSelect, Boolean and DateTime needs it
		if ([FieldDataType.Boolean, FieldDataType.Email, ...this.fieldTypesWithDropdown, FieldDataType.Numeric].includes(field.dataType)) {
			const itemsToCheckPermission = selectedItems.includes(item) ? selectedItems : [item];
			const itemsToUpdate = this.getItemsWithPermission(itemsToCheckPermission);

			TimeUtils.waitUpdate(appState.actions.updateFields(itemsToUpdate, {[field.refId]: value}), this.props.appState.app.notificationContainer);
		}
	};

	private onInput = (value: string) => {
		this.setState({value, isChanged: true});
	};

	private onDoubleClick = (e: React.MouseEvent) => {
		if (this.state.inEditMode) {
			e.stopPropagation();
		}
	};

	private splitCellContent = (value: string) => {
		if (typeof value === "string") {
			if (value.includes(";")) {
				return value.split(";");
			} else if (value.includes("\n")) {
				return value.split("\n");
			}
		}

		return value;
	};

	private getFieldValue = (item: IModel, field: IFieldAdapter) => {
		const {appState} = this.props;
		const fieldInherited =
			(field?.feature === XyiconFeature.Portfolio && item.ownFeature === XyiconFeature.Space) ||
			([XyiconFeature.XyiconCatalog, XyiconFeature.Portfolio, XyiconFeature.Space].includes(field?.feature) &&
				[XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(item.ownFeature));

		// getOwnFieldValue doesn't return anything if the field is inherited from other feature. In that case, the renderValue should be used
		if (!fieldInherited && (field?.dataType === FieldDataType.Boolean || field?.dataType === FieldDataType.MultipleChoiceList)) {
			return appState.actions.getOwnFieldValue(item, field?.refId);
		}
		return appState.actions.renderValue(item, field?.refId);
	};

	private getInheritedValues = () => {
		const {appState, item, field} = this.props;
		const xxLinks = appState.actions.getLinksXyiconXyicon(item.id);
		const bbLinks = appState.actions.getLinksBoundaryBoundary(item.id);
		const xbLinks = appState.actions.getLinksXyiconBoundary(item.id);
		const linkItems = item.ownFeature === XyiconFeature.Xyicon ? [...xxLinks, ...xbLinks] : bbLinks;

		const fieldIdToGetInheritedValues = appState.actions.getFieldsForType(item.typeId, field?.feature).find((f) => f.id === (field as Field).id);
		const customField = fieldIdToGetInheritedValues && appState.actions.getFieldById(fieldIdToGetInheritedValues.id);
		const isLookupField = LookupUtils.isLookupField(field, item, appState.actions);

		if (
			customField &&
			customField.displayOnLinks &&
			![XyiconFeature.XyiconCatalog, XyiconFeature.Portfolio, XyiconFeature.Space].includes(customField.feature) &&
			!isLookupField
		) {
			return linkItems
				.map((l) => {
					return this.getFieldValue(l.object, customField);
				})
				.filter(
					(e) => e != undefined && e != null && ((customField.dataType === FieldDataType.Boolean && !this.isXyiconBoundaryInheritedField) || e != ""),
				);
		}
		return [];
	};

	private getItemsWithPermission = (itemsToCheck: IModel[]) => {
		const {field, appState} = this.props;

		return itemsToCheck.filter((item) => appState.actions.getFieldPermission(field, [item]) > Permission.View);
	};

	@computed
	private get isCellEditable() {
		const {field, item, appState} = this.props;
		const {actions, user} = appState;

		if (field && item && user) {
			// We don't support updating multiple fields that have validation
			// Simply because the the first validation to be implemented is the "UniqueGIAI" validation
			// Which means all values must be unique. So if we're about to update more than one of them with the same value
			// It would lead to problems, or at least invalid values.
			// If we say boolean fields are not editable, then the little toggler is not visible, meaning it breaks the animation of the toggleswitch
			// as soon you click it, because it becomes non editable while we're waiting for the response from the backend.
			// So for boolean fields, and multiplechoicelist, we're making an exception
			if (
				(field.hasValidation && this.props.selectedItems.length > 1) ||
				(this.isUpdating && ![FieldDataType.MultipleChoiceList, FieldDataType.Boolean].includes(field.dataType))
			) {
				return false;
			}
			// 'assigned' will be undefined for system/readonly fields (id, type, lastModifiedBy, lastModifiedAt, model, name)
			const assigned = actions.getFieldsForType(item.typeId, field.feature).find((f) => f.id === (field as Field).id)?.value;
			const isPermission = actions.getFieldPermission(field, [item]) > Permission.View;
			const inherited = field.displayOnLinks && field.feature !== item.ownFeature && !Object.keys(item.fieldData).includes(field.refId);
			const isHidden = appState.actions.isFieldHiddenByMasking(item, field);

			const isType =
				(field.refId.includes("type") && ![XyiconFeature.Xyicon].includes(item.ownFeature)) ||
				(field.refId.includes("model") && item.ownFeature === XyiconFeature.Xyicon);
			const isTypeEditable = this.isTypeEditable;
			const isCatalogModel = field.refId.includes("model") && item.ownFeature !== XyiconFeature.Xyicon;
			const isCatalogModelEditable = user?.getOrganizationPermission(XyiconFeature.XyiconCatalog) > Permission.View;

			return (
				this.editableFieldTypes.includes(field.dataType) &&
				!field.hasFormula &&
				!inherited &&
				!isHidden &&
				((assigned && isPermission) || (isType && isTypeEditable) || (isCatalogModel && isCatalogModelEditable))
			);
		}
		return false;
	}

	private get isTypeEditable() {
		const {item, appState} = this.props;
		const {user, actions} = appState;
		let permission = Permission.None;

		if (item.ownFeature === XyiconFeature.Portfolio) {
			permission = actions.getPortfolioPermission(item.id);
		} else if (item.ownFeature === XyiconFeature.XyiconCatalog) {
			permission = user?.isAdmin
				? Permission.Delete
				: user?.organizationFeaturePermissionList.find((p) => p.feature === XyiconFeature.XyiconCatalog).permission ?? Permission.None;
		} else {
			permission = actions.getModuleTypePermission(item.typeId, item.ownFeature);
		}

		return permission > Permission.View;
	}

	private get editableFieldTypes() {
		return [
			FieldDataType.Type,
			FieldDataType.SingleLineText,
			FieldDataType.MultiLineText,
			FieldDataType.Numeric,
			FieldDataType.SingleChoiceList,
			FieldDataType.Boolean,
			FieldDataType.DateTime,
			FieldDataType.MultipleChoiceList,
			FieldDataType.Email,
			FieldDataType.IPAddress,
		];
	}

	private get fieldTypesWithDropdown() {
		return [FieldDataType.Type, FieldDataType.SingleChoiceList, FieldDataType.MultipleChoiceList, FieldDataType.DateTime];
	}

	private get isSpaceViewRendererMounted() {
		return this.props.appState.app.spaceViewRenderer.isMounted;
	}

	private get isXyiconBoundaryInheritedField() {
		const {field, item} = this.props;

		return item.ownFeature === XyiconFeature.Xyicon && field?.feature === XyiconFeature.Boundary;
	}

	public override componentDidMount() {
		this.calculateIfCellContentOverflow();
		KeyboardListener.getInstance().signals.down.add(this.onKeyboardPress);
	}

	public override componentWillUnmount(): void {
		KeyboardListener.getInstance().signals.down.remove(this.onKeyboardPress);
	}

	public override componentDidUpdate(prevProps: ITableCellProps) {
		if (prevProps.style.width !== this.props.style.width || prevProps.fieldValue !== this.props.fieldValue) {
			this.calculateIfCellContentOverflow();
		}
	}

	public override render() {
		const {style, field, appState, item, fieldValue, selectedItems} = this.props;
		const {inEditMode, caretPosition} = this.state;
		const isFieldHidden = appState.actions.isFieldHiddenByMasking(item, field);
		const isEditable = this.isCellEditable;
		const isUpdating = this.isUpdating;

		const inheritedValues = field && this.getInheritedValues();
		const ownValue =
			field?.refId === `${XyiconFeature.XyiconCatalog}/icon` || field?.refId === `${XyiconFeature.Xyicon}/icon`
				? fieldValue
				: this.getFieldValue(item, field);

		const isInputComponentBooleanField =
			field?.dataType === FieldDataType.Boolean &&
			!isFieldHidden &&
			appState.actions.getFieldRefIdsForType(item.typeId, item.ownFeature).includes(field.refId);
		const cellContent = this.splitCellContent(ownValue || fieldValue);

		const isArray = Array.isArray(cellContent);

		return (
			<TableCellStyled
				className={ReactUtils.cls("td TableCell", {
					italic: !fieldValue && item.ownFeature === XyiconFeature.User,
					editable: isEditable,
					array: isArray,
					date: field?.dataType === FieldDataType.DateTime,
					boolean: isInputComponentBooleanField,
					emptySingleSelectOrDateTime: [FieldDataType.SingleChoiceList, FieldDataType.DateTime].includes(field?.dataType) && !fieldValue,
					textAlignRight: [FieldDataType.Numeric].includes(field?.dataType),
					multiline: field?.dataType === FieldDataType.MultiLineText,
				})}
				ref={this._tableCell}
				style={style}
				onBlur={this.onBlur}
				onDoubleClick={this.onDoubleClick}
				onClick={(e) => e.stopPropagation()}
			>
				{!this.isXyiconBoundaryInheritedField && ( // if a xyicon has a boundary inherited field, the own field value will be null, so we need the inherited value. In that case we must not render the own value's empty space in the grid: ;
					<TableCellValueV5
						value={field ? ownValue : fieldValue}
						field={field}
						item={item}
						isEditable={isEditable}
						isUpdating={isUpdating}
						disabled={false}
						selectedItems={selectedItems}
						onBlur={this.onBlur}
						onClick={this.onEditCell}
						onInput={this.onInput}
						onChange={this.onChange}
						inEditMode={inEditMode}
						caretPosition={caretPosition}
						fieldTypesWithDropdown={this.fieldTypesWithDropdown}
						className={ReactUtils.cls({
							textCursor:
								[FieldDataType.MultiLineText, FieldDataType.Numeric, FieldDataType.SingleLineText, FieldDataType.Email].includes(field?.dataType) &&
								!LookupUtils.isLookupField(field, item, appState.actions),
						})}
					/>
				)}
				{field && inheritedValues.length > 0 && (
					<div className="vbox">
						{inheritedValues.map((inheritedVal, index) => {
							return (
								<TableCellValueV5
									key={`${field.refId}_${item.refId}_${inheritedVal}_${index}`}
									value={inheritedVal}
									field={field}
									item={item}
									isEditable={isEditable}
									isUpdating={false}
									disabled={true}
									selectedItems={selectedItems}
									fieldTypesWithDropdown={this.fieldTypesWithDropdown}
								/>
							);
						})}
					</div>
				)}
			</TableCellStyled>
		);
	}
}

const TableCellStyled = styled.div`
	border-radius: ${radius.xs};

	.catalog-thumbnail {
		position: relative;

		svg {
			position: absolute;
			top: 0;
			right: 0;
			width: 22px;
			height: 28px;
		}
	}

	&.editable {
		&.boolean {
			height: 40px;
			${FLEXCENTER};
		}

		:focus-visible {
			outline: none;
		}
	}
`;

import type {SpaceTool} from "../../logic3d/features/tools/Tools";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {Color} from "../../../../../../generated/api/base";
import type {TTitleAlignment} from "../../../../../5.0/modules/spaceeditor/toolbar/ToolButtonV5";
import {ToolButtonV5} from "../../../../../5.0/modules/spaceeditor/toolbar/ToolButtonV5";
import {ToolButton} from "./ToolButton";

export const createToolButton = (
	spaceViewRenderer: SpaceViewRenderer,
	id: SpaceTool,
	activeToolId: SpaceTool,
	onToolClick: (id: SpaceTool) => void,
	color?: Color,
) => {
	const toolConfig = spaceViewRenderer.tools[id];
	const isActive = activeToolId === id;
	const isTempMeasureTool = id.includes("tempMeasure");

	return (
		<ToolButton
			active={isActive}
			isBordered={id.includes("markup")}
			toolId={id}
			icon={toolConfig.icon}
			title={toolConfig.title}
			onClick={() => onToolClick(id)}
			color={color}
			backgroundColor={isTempMeasureTool && isActive ? spaceViewRenderer.measureToolColor : undefined}
		/>
	);
};

export const createToolButtonV5 = (
	spaceViewRenderer: SpaceViewRenderer,
	id: SpaceTool,
	activeToolId: SpaceTool,
	onClick: (id: SpaceTool) => void,
	tooltipAlignment: TTitleAlignment,
	color?: Color,
) => {
	const toolConfig = spaceViewRenderer.tools[id];

	return (
		<ToolButtonV5
			isActive={activeToolId === id}
			icon={id}
			title={toolConfig.title}
			onClick={() => onClick(id)}
			color={color}
			titleAlignment={tooltipAlignment}
		/>
	);
};

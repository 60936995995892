import {css, styled} from "styled-components";
import type {ForwardedRef} from "react";
import {forwardRef, useEffect, useRef, useState} from "react";
import {colorPalette} from "../styles/colorPalette";
import {FlexCenterStyle, VerticalFlexStyle, fontSize, fontWeight, radius, zIndex} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import XmarkLargeIcon from "../icons/xmark-large.svg?react";
import XmarkIcon from "../icons/xmark.svg?react";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment, DomUtils} from "../../../utils/dom/DomUtils";
import type {IButtonV5Props} from "../button/ButtonV5";
import {ButtonV5} from "../button/ButtonV5";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";

interface IButtonProps extends IButtonV5Props {
	hideIcon?: boolean;
}

interface IPopupV5Props {
	readonly label?: string;
	readonly buttonProps?: IButtonProps;
	readonly parentRef?: HTMLDivElement;
	readonly children: React.ReactNode;
	readonly verticalAlignment?: VerticalAlignment;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly centerOnScreen?: boolean;
	readonly noButtons?: boolean;
	readonly width?: string;
	readonly height?: string;
	readonly freezeRoot?: boolean;
	readonly className?: string;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly smallCancelIcon?: boolean;
	readonly onClose?: (e?: React.MouseEvent) => void;
}

interface IPopupStyledProps {
	$floatingTransform: string;
	$center: boolean;
	$width: string;
	$height: string;
	$className: string;
}

const PopupStyled = styled.div<IPopupStyledProps>`
	${VerticalFlexStyle};
	background-color: ${colorPalette.white};
	border-radius: ${radius.xl};
	min-width: 368px;
	box-shadow: 0px 4px 20px 0px #00000033;
	margin: 16px;
	z-index: ${zIndex.createPanel + 1};
	position: absolute;
	visibility: hidden;

	${IconButtonStyled} {
		svg {
			width: 16px;
			height: 16px;
		}
	}

	${(props) => {
		if (props.$floatingTransform !== "") {
			return css`
				transform: ${props.$floatingTransform};
				visibility: visible;
			`;
		}
	}};

	${(props) => {
		if (props.$center) {
			return css`
				position: fixed;
				visibility: visible;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			`;
		}
	}};

	${(props) =>
		(props.$className === "SpaceCreation" || props.$className === "ReportCreation") &&
		css`
			min-width: 810px;
			min-height: 600px;
			max-width: 1080px;
			max-height: 800px;
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		`};

	${(props) =>
		props.$className === "SettingsUserCreation" &&
		css`
			min-width: 528px;
			min-height: 528px;
			max-width: 528px;
			max-height: 528px;
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin-left: 0px;
		`};

	${(props) =>
		props.$className === "PermissionSetCreation" &&
		css`
			min-width: 320px;
			min-height: 162px;
			max-width: 320px;
			max-height: 162px;
			position: absolute;
		`};

	${(props) =>
		props.$className === "OrganizationCreation" &&
		css`
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		`};

	${(props) => css`
		width: ${props.$width || "auto"};
		height: ${props.$height || "auto"};
	`};

	&.SharingPopup {
		.body {
			padding: 8px;
		}
	}
`;

export const PopupHeaderStyled = styled.div`
	${FlexCenterStyle}
	justify-content: space-between;
	padding: 16px;

	.IconButton {
		height: 24px;
		min-width: 24px;
	}
`;

export const NameStyled = styled.span`
	${FlexCenterStyle};
	font-size: ${fontSize.xl};
	font-weight: ${fontWeight.bold};
	color: ${colorPalette.gray.c950};
	height: 24px;
`;

export const PopupBodyStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => (props.className === "SharingPopup" ? "0" : "24px")};
	padding: 0 16px;
	flex: 1;

	.threeColumns {
		display: grid;
		grid-template-columns: 1fr 0.5fr 1fr;
	}
`;

const CreateButtonStyled = styled.div`
	display: flex;
	justify-content: flex-end;

	&.button.popupButton {
		cursor: auto;

		.Button &:not(.disabled) {
			cursor: pointer;
		}
	}
`;

export const PopupV5 = forwardRef((props: IPopupV5Props, ref: ForwardedRef<HTMLDivElement>) => {
	const _floating = useRef<HTMLDivElement>();
	const appState = useAppStore((state) => state.appState);
	const {
		parentRef,
		verticalAlignment = VerticalAlignment.bottom,
		horizontalAlignment = HorizontalAlignment.outerRight,
		offsetY = -8,
		offsetX = 16,
		smallCancelIcon = false,
		freezeRoot = false,
		buttonProps = {
			label: "Create",
			create: Functions.emptyFunction,
		},
		className = "",
		noButtons = false,
	} = props;
	const setIsRootFrozen = useAppStore((state) => state.setIsRootFrozen);
	const [transform, setTransform] = useState<TransformObj>(null);

	useEffect(() => {
		if (freezeRoot) {
			setIsRootFrozen(true);
		}

		if (parentRef && _floating.current) {
			setTransform(DomUtils.getFixedFloatingElementPosition(parentRef, _floating.current, verticalAlignment, horizontalAlignment, offsetY, offsetX));
		}

		return () => setIsRootFrozen(false);
	}, [horizontalAlignment, parentRef, verticalAlignment, freezeRoot, setIsRootFrozen, offsetY, offsetX]);

	{
		const {centerOnScreen, width, height, label, onClose, children, buttonProps} = props;

		return (
			<DomPortal destination={appState.app.modalContainer}>
				<PopupStyled
					ref={_floating}
					$floatingTransform={_floating.current ? transform?.translate : ""}
					$center={centerOnScreen}
					$width={width}
					$height={height}
					$className={className}
					className={className}
				>
					<div ref={ref}>
						{label && onClose && (
							<PopupHeaderStyled className="header">
								<NameStyled className="name">{label}</NameStyled>
								<IconButtonV5
									IconComponent={smallCancelIcon ? XmarkIcon : XmarkLargeIcon}
									onClick={onClose}
								/>
							</PopupHeaderStyled>
						)}
						<PopupBodyStyled className="body">
							{children}
							{!noButtons &&
								!["SpaceCreation", "OrganizationCreation", "PermissionSetCreation", "ReportCreation", "ReportRun", "SettingsUserCreation"].includes(
									className,
								) && (
									<CreateButtonStyled className="button popupButton">
										<ButtonV5
											onClick={buttonProps.onClick}
											disabled={buttonProps.disabled}
											label={buttonProps.label}
										>
											{!buttonProps.hideIcon && <CirclePlusIcon />}
										</ButtonV5>
									</CreateButtonStyled>
								)}
						</PopupBodyStyled>
					</div>
				</PopupStyled>
			</DomPortal>
		);
	}
});

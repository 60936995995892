import type {IDefaultField, IFieldAdapter, IFieldPointer} from "./Field";

export const fieldEquals = (a: IFieldPointer, b: IFieldPointer) => {
	return a === b;
	//
	// if (!a && !b) return true;  // both null
	// if (!a || !b) return false; // only one is null
	//
	// if (a.refId !== b.refId)
	// {
	// 	return false;
	// }
	//
	// // No feature given or features match
	// return (!a.feature || !b.feature) || a.feature === b.feature;
};

export const isDefaultField = (field: IFieldAdapter): field is IDefaultField => {
	return !!field.default;
};

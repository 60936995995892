import {EllipseCurve} from "three";
import type {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import {Constants} from "../../Constants";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {MarkupAB2D} from "./abstract/MarkupAB2D";
import type {ICornerLetter} from "./abstract/MarkupAB";

export class MarkupEllipse extends MarkupAB2D {
	private _curve: EllipseCurve;

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const worldVertices = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);

		const {max, min} = THREEUtils.calculateBox(isLocal ? worldVertices : this.unrotatedGeometryData);

		const center = {
			x: (min.x + max.x) / 2,
			y: (min.y + max.y) / 2,
		};

		const radius = {
			x: Math.max(Constants.EPSILON, (max.x - min.x) / 2),
			y: Math.max(Constants.EPSILON, (max.y - min.y) / 2),
		};

		this._curve = new EllipseCurve(center.x, center.y, radius.x, radius.y, 0, 2 * Math.PI, false, isLocal ? 0 : this._lastSavedOrientation);
		this._2dVectors = this._curve.getPoints(64);

		this._vertices.length = 0;
		for (const point of this._2dVectors) {
			this._vertices.push(point.x);
			this._vertices.push(point.y);
			this._vertices.push(0);
		}

		(this._lineGeometry as LineGeometry).setPositions(this._vertices);

		this.updateMesh(this._2dVectors);
		if (isLocal) {
			this._selectionPart.update(this._vertices);
			this._textArea.update(this._vertices);
		}
		this.updateBoundingBox();
	}

	public get type() {
		return MarkupType.Ellipse;
	}
}

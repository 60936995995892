import type {App} from "../../../../../../App";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {Permission} from "../../../../../../generated/api/base";
import type {IContextOption} from "../../../../../widgets/context/ContextOptions";

interface IOptionsProps {
	app: App;
	item: Catalog;
	userCatalogPermission: Permission;
	onDuplicateCatalogClick: (item: Catalog) => void;
	onDeleteCatalogClick: (item: Catalog) => void;
}

export const getCatalogItemOptions = (props: IOptionsProps): IContextOption[] => {
	const {app, item, userCatalogPermission, onDuplicateCatalogClick, onDeleteCatalogClick} = props;
	const options: IContextOption[] = [
		{
			label: "Duplicate",
			onSelect: () => onDuplicateCatalogClick(item),
		},
		{
			label: "Edit",
			onSelect: () => app.onDetailsClick(item),
		},
	];

	if (userCatalogPermission === Permission.Delete) {
		options.push({
			label: "Delete",
			onSelect: () => onDeleteCatalogClick(item),
		});
	}

	return options;
};

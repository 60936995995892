export class ScriptLoader {
	private static _scripts: {
		[url: string]: {
			loaded: boolean;
			script: HTMLScriptElement;
		};
	} = {};

	public static async loadScriptsInSequence(urls: string[]) {
		for (const url of urls) {
			await this.loadScript(url);
		}
	}

	public static loadScript(url: string) {
		if (!this._scripts[url]) {
			const script = document.createElement("script");

			this._scripts[url] = {
				script: script,
				loaded: false,
			};
			script.src = url;
		}

		return new Promise<void>((resolve, reject) => {
			if (this._scripts[url].loaded) {
				resolve();
			} else {
				const script = this._scripts[url].script;

				script.addEventListener("load", () => {
					this._scripts[url].loaded = true;
					resolve();
				});

				if (!script.parentNode) {
					document.body.appendChild(script);
				}
			}
		});
	}
}

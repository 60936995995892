import * as React from "react";
import {observer} from "mobx-react";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {InteractiveToggleContainer} from "../../../../../widgets/container/InteractiveToggleContainer";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import {XyiconFeature} from "../../../../../../generated/api/base";
import {Button} from "../../../../../widgets/button/Button";
import {SaveToViewButton} from "../../../../abstract/common/savetoview/SaveToViewButton";
import {TabChild} from "../../../../../widgets/tab/TabChild";
import {TabView} from "../../../../../widgets/tab/TabView";
import {ColorRules} from "./ColorRules";
import type {IOverlayPanelProps, IOverlayPanelState} from "./LayerPanel";

@observer
export class ConditionalFormattingPanel extends React.Component<IOverlayPanelProps, IOverlayPanelState> {
	constructor(props: IOverlayPanelProps) {
		super(props);

		this.state = {
			isOpen: false,
			searchString: "",
		};
	}

	private get boundaryManager() {
		return this.props.spaceViewRenderer.boundaryManager;
	}

	private get xyiconManager() {
		return this.props.spaceViewRenderer.xyiconManager;
	}

	private onToggleColorRulesForBoundaries = () => {
		this.boundaryManager.formattingRules.enabled = !this.boundaryManager.formattingRules.enabled;
		this.boundaryManager.onFormattingRulesModified();
	};

	private onToggleColorRulesForXyicons = () => {
		this.xyiconManager.formattingRules.enabled = !this.xyiconManager.formattingRules.enabled;
		this.xyiconManager.onFormattingRulesModified();
	};

	private getSavedFormattingRules() {
		return this.props.view.getSavedSpaceEditorViewSettings()?.formattingRules || this.props.view.getDefaultFormattingRules();
	}

	private getFormattingRules() {
		return this.props.view.spaceEditorViewSettings.formattingRules;
	}

	private isResetButtonEnabled() {
		const layerSettings = this.getFormattingRules();

		return JSON.stringify(layerSettings) !== JSON.stringify(this.getSavedFormattingRules());
	}

	private onResetClick = () => {
		this.props.view.spaceEditorViewSettings.formattingRules = this.getSavedFormattingRules();
		this.props.spaceViewRenderer.boundaryManager.onFormattingRulesModified();
		this.props.spaceViewRenderer.xyiconManager.onFormattingRulesModified();
	};

	public override componentDidMount() {
		this.setState({
			isOpen: true,
		});
	}

	public override render() {
		return (
			<div className={ReactUtils.cls("ConditionalFormattingPanel overlayPanel", {open: this.state.isOpen})}>
				<TabView
					selectedTabId="formatting"
					onClose={this.props.onToggle}
				>
					<TabChild
						id="formatting"
						icon="conformatting"
						title="Formatting"
						label="Formatting"
					>
						<div className="topButtons hbox">
							<SaveToViewButton
								feature={XyiconFeature.SpaceEditor}
								viewChangeType="conditional formatting"
							/>
							<div className="flex_1" />
							<Button
								className="secondary"
								label="Reset"
								disabled={!this.isResetButtonEnabled()}
								onClick={this.onResetClick}
								icon="reset"
							/>
						</div>
						<div className="container">
							<div className="head">
								<SearchField
									value={this.state.searchString}
									onInput={(searchString: string) => this.setState({searchString: searchString})}
								/>
							</div>
							<InteractiveToggleContainer
								title="Boundaries"
								open={true}
								isEnabled={this.boundaryManager.formattingRules.enabled}
								onEnabledChange={this.onToggleColorRulesForBoundaries}
							>
								<ColorRules
									spaceViewRenderer={this.props.spaceViewRenderer}
									activeSection="boundary"
									view={this.props.view}
									searchString={this.state.searchString}
								/>
							</InteractiveToggleContainer>
							<InteractiveToggleContainer
								title="Xyicons"
								open={true}
								isEnabled={this.xyiconManager.formattingRules.enabled}
								onEnabledChange={this.onToggleColorRulesForXyicons}
							>
								<ColorRules
									spaceViewRenderer={this.props.spaceViewRenderer}
									activeSection="xyicon"
									view={this.props.view}
									searchString={this.state.searchString}
								/>
							</InteractiveToggleContainer>
						</div>
					</TabChild>
				</TabView>
			</div>
		);
	}
}

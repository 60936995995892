import type {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";
import type {IMarkupConfig} from "./abstract/MarkupUtils";
import {MarkupAB2D} from "./abstract/MarkupAB2D";

export class MarkupCross extends MarkupAB2D {
	private _segments: number[] = this._vertices;

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, ObjectUtils.mergeConfig(config, {useSegments: true}));
	}

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const worldVertices = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);

		this._segments = [
			worldVertices[0].x,
			worldVertices[0].y,
			0,
			worldVertices[2].x,
			worldVertices[2].y,
			0,
			worldVertices[1].x,
			worldVertices[1].y,
			0,
			worldVertices[3].x,
			worldVertices[3].y,
			0,
		];

		(this._lineGeometry as LineSegmentsGeometry).setPositions(this._segments);
		if (isLocal) {
			this._selectionPart.update(this._segments);
		}
		this.updateBoundingBox();
	}

	public get type() {
		return MarkupType.Cross;
	}
}

import {styled} from "styled-components";
import type {CSSProperties, FunctionComponent, MouseEvent, ReactNode, SVGProps, TouchEvent} from "react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {colorPalette} from "../../styles/colorPalette";
import {ELLIPSIS, FlexCenterStyle, VerticalFlexStyle, fontSize, radius, zIndex} from "../../styles/styles";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {useAppStore} from "../../../../StateManager";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {SearchFieldV5} from "../search/SearchFieldV5";
import ChevronDownIcon from "../../icons/chevron-down.svg?react";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {useClickOutside} from "../../utils";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";

export const SelectInputDropdownClass = "SelectInputDropDownV5";

const svgWidth = "18px";

export const SelectInputStyled = styled.div<{$inline?: boolean}>`
	cursor: pointer;
	${FlexCenterStyle};
	justify-content: space-between;
	gap: 8px;
	height: 32px;
	padding-left: 8px;
	padding-right: 4px;
	border: ${(props) => (props.$inline ? "none" : `1px solid ${colorPalette.gray.c300}`)};
	border-radius: ${radius.sm};
	/* color: ${colorPalette.gray.c700Dark}; */
	font-size: ${fontSize.md};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	svg {
		width: ${svgWidth};
		height: ${svgWidth};
	}

	.empty {
		width: calc(100% - ${svgWidth});
		text-align: center;
	}

	.input {
		position: relative;
		padding-right: 30px;
		font-size: ${fontSize.md};
		flex: 1;
		line-height: 14px;
		${ELLIPSIS}

		.fieldOption {
			.linkIcon {
				right: 20px;
			}
		}

		svg {
			width: 12px;
			min-width: 12px;
			height: 12px;
			min-height: 12px;
			position: absolute;
			right: 5px;
			top: 2px;

			path {
				width: 6px;
				height: 3px;
			}
		}
	}
`;

const SelectInputDropdownStyled = styled.div<{$isConfirmXyiconModelChangesForm: boolean; $numberOfOptions: number}>`
	${VerticalFlexStyle};
	visibility: hidden;
	background-color: ${colorPalette.white};
	border-radius: ${radius.sm};
	padding: 8px;
	box-shadow: 0px 4px 8px 0px #00000033;

	svg.icon {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	.filterField {
		width: 100%;
		justify-content: space-between;
	}

	.SearchField {
		width: 99%;
	}

	${(props) =>
		props.$isConfirmXyiconModelChangesForm &&
		props.$numberOfOptions != 2 &&
		`.Options:nth-last-child(2) {
				margin-top: 8px;
				border-top: 1px solid ${colorPalette.gray.c300};
				border-radius: 0;
			}`}
`;

const ListElementContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;

	.favorite {
		justify-content: left;
		padding-left: 8px;
	}
`;

const SearchWrapper = styled.div`
	${FlexCenterStyle};
	gap: 8px;
	margin-bottom: 8px;
`;

export const OptionStyled = styled.div`
	${FlexCenterStyle};
	cursor: pointer;
	min-height: 32px;
	padding: 4px 8px;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.selected {
		color: ${colorPalette.primary.c500Primary};
		background-color: ${colorPalette.primary.c200Light};
	}

	.fieldOption {
		width: 100%;
		justify-content: space-between;
	}

	svg {
		flex-shrink: 0;
	}
`;

interface ISelectInputPropsV5<T> {
	readonly className?: string;
	readonly childBeforeOptions?: ReactNode;
	readonly nextToSearchBarNode?: ReactNode;
	readonly options: T[];
	readonly nullOption?: boolean;
	readonly selected?: T;
	readonly disabled?: boolean;
	readonly nullLabel?: string;
	readonly fullWidth?: boolean;
	readonly searchBarFullWidth?: boolean;
	readonly dropdownFixedWidth?: string;
	readonly dropdownMaxHeight?: string;
	readonly sort?: boolean;
	readonly searchBarMode?: "always on" | "always off" | "when many items";
	readonly isOpenByDefault?: boolean;
	readonly noFixedPosition?: boolean;
	readonly onFocusLossForceBlur?: boolean;
	readonly optionsZIndex?: number;
	readonly placeholder?: string;
	readonly focused?: boolean;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly dropdownIcon?: boolean;
	readonly focusLossStarterEvent?: "down" | "up";
	readonly style?: React.CSSProperties;
	readonly isSameWidth?: boolean;
	readonly render?: (item: T) => ReactNode;
	readonly renderSelectedWhenClosed?: (item: T) => ReactNode;
	readonly stringifyOption?: (item: T) => string;
	readonly onChange?: (option: T) => void;
	readonly onFocus?: (value: boolean) => void;
	readonly onClick?: () => void;
	readonly onClose?: () => void;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly preventOptionClickOnSubItem?: boolean;
	readonly isConfirmXyiconModelChangesForm?: boolean;
	readonly inline?: boolean;
}

export function SelectInputV5<T = any>({
	options = [],
	nullOption = false,
	disabled = false,
	render = (option: any) => option,
	renderSelectedWhenClosed,
	nullLabel = "",
	sort = true,
	searchBarMode = "when many items",
	IconComponent,
	dropdownIcon = true,
	onClick,
	onFocus,
	onClose,
	focused,
	onChange,
	selected,
	nextToSearchBarNode,
	childBeforeOptions,
	noFixedPosition,
	optionsZIndex,
	stringifyOption,
	className,
	fullWidth,
	searchBarFullWidth,
	dropdownFixedWidth,
	dropdownMaxHeight,
	placeholder,
	isSameWidth,
	verticalAlignment = VerticalAlignment.bottomOuter,
	horizontalAlignment = HorizontalAlignment.left,
	offsetY = 8,
	offsetX = 0,
	preventOptionClickOnSubItem = false,
	isConfirmXyiconModelChangesForm = false,
	inline,
}: ISelectInputPropsV5<T>) {
	const _element = useRef<HTMLDivElement>();
	const _list = useRef<HTMLDivElement>();
	const _searchBar = useRef<HTMLDivElement>();
	const _prevFocused = useRef<boolean>();
	let _touchMove = false;

	const appState = useAppStore((state) => state.appState);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [search, setSearch] = useState<string>("");
	const [transform, setTransform] = useState<TransformObj>(null);

	const placeholderProp = placeholder;

	useEffect(() => {
		if (_element.current && _list.current && isOpen) {
			setTransform(
				DomUtils.getFixedFloatingElementPosition(_element.current, _list.current, verticalAlignment, horizontalAlignment, offsetY, offsetX, true),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_element.current, _list.current, verticalAlignment, horizontalAlignment, offsetY, offsetX, setTransform, isOpen, disabled]);

	useEffect(() => {
		_prevFocused.current = focused;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (focused && !_prevFocused.current) {
			if (!disabled) {
				setIsOpen(true);
			}
		} else {
			setIsOpen(false);
		}

		_prevFocused.current = focused;
	}, [focused, disabled]);

	const onOpen = useCallback(() => {
		if (disabled) {
			return;
		}

		const isOpenCallback = async (event?: MouseEvent) => {
			if (!isOpen) {
				try {
					onClick?.();
					event?.stopPropagation();
					onFocus?.(true);
					setIsOpen(true);
					AppUtils.disableScrolling(true);

					await TimeUtils.wait(100);

					// Auto-scroll to selected option when isOpening
					// requestAnimationFrame is needed because the div is not visible immediately after calling setState(isOpen)
					requestAnimationFrame(() => {
						const selectedOption = _element.current?.querySelector(".option.selected") as HTMLDivElement;

						DomUtils.scrollIntoViewIfNeeded(selectedOption);
					});
				} catch (error) {
					console.warn(error);
				}
			}
		};

		isOpenCallback();
	}, [onFocus, onClick, isOpen, disabled]);

	const onCloseMethod = useCallback(() => {
		if (isOpen) {
			AppUtils.disableScrolling(false);
			setIsOpen(false);
			setSearch("");
			onFocus?.(false);
			onClose?.();
		}
	}, [isOpen, onFocus, onClose]);

	const onToggle = (event: MouseEvent) => {
		if (_searchBar.current && (event.target as Element).contains(_searchBar.current)) {
			return;
		}

		if (isOpen) {
			onCloseMethod();
		} else {
			onOpen();
		}
	};

	useClickOutside([_element, _list], onCloseMethod);

	const onOptionTouchMove = () => {
		_touchMove = true;
	};

	const onOptionTouchEnd = (e: TouchEvent, option: T = null) => {
		if (!_touchMove) {
			e.nativeEvent.stopImmediatePropagation();
			AppUtils.disableScrolling(false);
			setIsOpen(false);
			setSearch("");
			onChange?.(option);
		}

		_touchMove = false;
	};

	const onOptionClick = (e: MouseEvent, option: T = null) => {
		e.nativeEvent.stopImmediatePropagation();
		AppUtils.disableScrolling(false);
		setIsOpen(false);
		setSearch("");
		onChange?.(option);
	};

	const renderLabel = (option: T, isPlaceholder: boolean) => {
		if (isPlaceholder && selected === undefined) {
			return <div className="placeholder">{placeholderProp}</div>;
		}

		if (!option) {
			if (inline) {
				return <div className="empty">-</div>;
			}
			return nullOption ? nullLabel : placeholder;
		} else {
			return render(option);
		}
	};

	{
		const inlineStyle: CSSProperties = _list.current && {
			maxWidth: dropdownFixedWidth ?? "500px",
			minWidth: isSameWidth ? _element.current.offsetWidth : dropdownFixedWidth ?? "150px",
			transform: noFixedPosition ? "" : transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
			zIndex: optionsZIndex ?? zIndex.contextOptions,
			visibility: "visible",
		};

		const optionsClone = options.slice(); // [mobx] `observableArray.sort()` will not update the array in place. Use `observableArray.slice().sort()` to suppress this warning and perform the operation on a copy, or `observableArray.replace(observableArray.slice().sort())` to sort & update in place

		if (sort) {
			optionsClone.sort((optionA, optionB) => {
				let a = renderLabel(optionA, false).toString();
				let b = renderLabel(optionB, false).toString();

				if (stringifyOption) {
					a = stringifyOption(optionA);
					b = stringifyOption(optionB);
				}

				return StringUtils.sortIgnoreCase(a, b);
			});
		}

		const optionElements = optionsClone
			.filter((option) => {
				let o: any = option;

				if (stringifyOption) {
					o = stringifyOption(option);
				}

				if (search) {
					const value = typeof o === "string" ? o : renderLabel(o, false).toString();

					return StringUtils.containsIgnoreCase(value, search);
				} else {
					return true;
				}
			})
			.map((option, index) => {
				const isSelected = ObjectUtils.compare(selected, option);

				return (
					<OptionStyled
						key={index}
						className={ReactUtils.cls("Options", {
							selected: isSelected,
						})}
						onMouseDown={(event) => {
							// onMouseDown will get triggered on a submenu click within the main option. This will prevent clicking the
							// main option when submenu is clicked
							if (!preventOptionClickOnSubItem) {
								onOptionClick(event, option);
							}
						}}
						onClick={(event) => {
							if (preventOptionClickOnSubItem) {
								onOptionClick(event, option);
							}
						}}
						onTouchMove={onOptionTouchMove}
						onTouchEnd={(event) => onOptionTouchEnd(event, option)}
					>
						{renderLabel(option, false)}
					</OptionStyled>
				);
			});

		return (
			<SelectInputStyled
				ref={_element}
				className={ReactUtils.cls(`${className || ""}`, {
					disabled,
					fullWidth,
					isOpen,
				})}
				onClick={onToggle}
				$inline={inline}
			>
				{IconComponent && <IconComponent />}
				{renderSelectedWhenClosed ? renderSelectedWhenClosed(selected) : renderLabel(selected, true)}
				{dropdownIcon && <ChevronDownIcon />}
				{isOpen && (
					<DomPortal
						destination={appState.app.modalContainer}
						noPortal={noFixedPosition}
					>
						<>
							<SelectInputDropdownStyled
								className={SelectInputDropdownClass}
								style={inlineStyle}
								ref={_list}
								$isConfirmXyiconModelChangesForm={isConfirmXyiconModelChangesForm}
								$numberOfOptions={options.length}
							>
								{(searchBarMode === "always on" || (searchBarMode === "when many items" && options.length > 5)) && (
									<SearchWrapper>
										<SearchFieldV5
											value={search}
											onInput={(value) => setSearch(value)}
											placeholder="Find"
											divRef={_searchBar}
											fullWidth={searchBarFullWidth}
										/>
										{nextToSearchBarNode}
									</SearchWrapper>
								)}
								<ListElementContainerStyled style={{maxHeight: dropdownMaxHeight ?? "140px"}}>
									{childBeforeOptions}
									{nullOption && optionElements.length > 0 && !search && (
										<OptionStyled
											className={ReactUtils.cls("Options", {
												selected: !selected,
											})}
											onMouseDown={(event) => {
												// onMouseDown will get triggered on a submenu click within the main option. This will prevent clicking the
												// main option when submenu is clicked
												if (!preventOptionClickOnSubItem) {
													onOptionClick(event);
												}
											}}
											onClick={(event) => {
												if (preventOptionClickOnSubItem) {
													onOptionClick(event);
												}
											}}
											onTouchMove={onOptionTouchMove}
											onTouchEnd={onOptionTouchEnd}
										>
											{renderLabel(null, false)}
										</OptionStyled>
									)}
									{optionElements.length === 0 ? (
										<div className="bottom-section noValues">
											No values found.
											<br />
											{!search && "Contact your admin."}
										</div>
									) : (
										optionElements
									)}
								</ListElementContainerStyled>
							</SelectInputDropdownStyled>
						</>
					</DomPortal>
				)}
			</SelectInputStyled>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {NoResultSearchView} from "../../abstract/grid/NoResultSearchView";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import type {LibraryImage} from "../../../../data/models/LibraryImage";
import type {LibraryModel} from "../../../../data/models/LibraryModel";
import {LibraryModelType} from "../../../../data/models/LibraryModel";
import {FileDropperReact} from "../../../interaction/draganddrop/FileDropperReact";
import {FileDropperWrapper} from "../../../interaction/draganddrop/FileDropperWrapper";
import {ReactUtils} from "../../../utils/ReactUtils";
import {TextInput} from "../../../widgets/input/text/TextInput";
import {SVGIcon} from "../../../widgets/button/SVGIcon";
import {OptionsButton} from "../../../widgets/button/options/OptionsButton";
import {FileUtils} from "../../../../utils/file/FileUtils";
import type {AppState} from "../../../../data/state/AppState";
import {openKeywordsPopupForFile} from "./CatalogUtils";

type LibraryItemType = "image" | "glb";

type LibraryItem = LibraryImage | LibraryModel;

interface ILibraryItemsProps {
	libraryItems: LibraryItem[];
	selectedLibraryItems: LibraryItem[];
	type: LibraryItemType;
	onFileInputChange: (file: File, keywords: string[]) => Promise<void>;
	onItemsSelected: (items: LibraryItem[]) => void;
	onSetItemActive: (item: LibraryItem) => Promise<void> | void;
	onDeleteSelectedClick: () => Promise<void>;
	isDeleteSelectedDisabled: boolean;
	onDeleteClick: (item: LibraryItem) => Promise<void>;
	onGoogleImageClick?: () => void;
	appState?: AppState;
}

interface ILibraryItemsState {
	search: string;
}

@inject("appState")
@observer
export class LibraryItems extends React.Component<ILibraryItemsProps, ILibraryItemsState> {
	constructor(props: ILibraryItemsProps) {
		super(props);
		this.state = {
			search: "",
		};
	}

	private onSearchInput = (value: string) => this.setState({search: value});

	private onItemClick = (libraryItem: LibraryItem) => {
		if (KeyboardListener.isCtrlDown) {
			const newSelectedLibraryItems = [...this.props.selectedLibraryItems];
			const indexOfClicked = newSelectedLibraryItems.indexOf(libraryItem);

			if (indexOfClicked > -1) {
				newSelectedLibraryItems.splice(indexOfClicked, 1);
			} else {
				newSelectedLibraryItems.push(libraryItem);
			}
			this.props.onItemsSelected(newSelectedLibraryItems);
		} else {
			this.props.onItemsSelected([libraryItem]);
			this.props.onSetItemActive(libraryItem);
		}
	};

	private get label() {
		return this.props.type === "image" ? "image" : "model";
	}

	private get accept() {
		return this.props.type === "image" ? "image/*" : ".glb";
	}

	private onFileInputChange = (files: FileList) => {
		if (files.length > 0) {
			const file = files[0];

			return openKeywordsPopupForFile(file, this.label, this.props.onFileInputChange, this.props.appState.currentUIVersion);
		}
	};

	private onAddItemClick = () => {
		const input = document.createElement("input");

		input.type = "file";
		input.multiple = false;
		input.accept = this.accept;
		input.onchange = (event: InputEvent) => {
			const files = (event.currentTarget as HTMLInputElement).files;

			this.onFileInputChange(files);
		};

		input.click();
	};

	private getFilteredElements() {
		return StringUtils.filterByKeywords(this.props.libraryItems, this.state.search);
	}

	public override render() {
		const label = this.label;
		const items = this.getFilteredElements() as (LibraryItem | LibraryModel)[];
		const isTheStandard = (items[0] as LibraryModel)?.geometryType === LibraryModelType.STANDARD;

		const cube = isTheStandard && items[0];
		const customItems = isTheStandard ? items.slice(1) : items;

		return (
			<div className="LibraryItems">
				<div className="vbox buttonContainer">
					{this.props.onGoogleImageClick && (
						<div
							className="googleBtn btn hbox alignCenter"
							onClick={this.props.onGoogleImageClick}
						>
							<SVGIcon icon="google" />
							Search
						</div>
					)}
					<div className="hbox">
						<TextInput
							placeholder="Search..."
							onInput={this.onSearchInput}
						/>
						<div
							className="btn hbox alignCenter"
							onClick={this.onAddItemClick}
							title={`Add new ${label}`}
						>
							<SVGIcon icon="upload" />
							Upload
						</div>
						<div
							className={ReactUtils.cls("btn hbox alignCenter", {
								disabled: this.props.selectedLibraryItems.length === 0 || this.props.isDeleteSelectedDisabled,
							})}
							onClick={this.props.onDeleteSelectedClick}
							title={`Delete Selected ${label}s`}
						>
							<SVGIcon icon="delete" />
							Delete
						</div>
					</div>
				</div>
				{this.props.libraryItems.length > 0 ? (
					items.length > 0 ? (
						<FileDropperWrapper
							accept={this.accept}
							onFileInputChange={this.onFileInputChange}
							multiple={false}
						>
							<div className="libraryItemContainer">
								{cube && (
									<div
										title={cube.keywords.join(", ")}
										style={{backgroundImage: `url(${cube.thumbnail})`}}
										className={ReactUtils.cls("thumbnail standard", {selected: this.props.selectedLibraryItems.includes(cube)})}
										onClick={() => this.onItemClick(cube)}
									/>
								)}
								<div className="customLibraryItems">
									{customItems.map((libraryItem: LibraryImage | LibraryModel, index: number) => {
										const isSelected = this.props.selectedLibraryItems.includes(libraryItem);
										const title = libraryItem.keywords.join(", ");
										const style: React.CSSProperties = {
											backgroundImage: `url(${libraryItem.thumbnail})`,
										};

										return (
											<div
												key={index}
												title={title.endsWith(", ") ? title.slice(0, -2) : title}
												style={style}
												className={ReactUtils.cls("thumbnail", {selected: isSelected})}
												onClick={() => this.onItemClick(libraryItem)}
											>
												<SVGIcon
													icon="dogear-uploaded"
													classNames="dogear"
												/>
												<OptionsButton
													options={[
														{
															label: "Download",
															onSelect: () => FileUtils.downloadFileFromUrl(libraryItem.url, libraryItem.fileName),
														},
														{
															label: "Delete",
															onSelect: () => this.props.onDeleteClick(libraryItem),
														},
													]}
													optionsZIndex={10000}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</FileDropperWrapper>
					) : (
						<div className="noResult">
							<NoResultSearchView search={this.state.search}></NoResultSearchView>
						</div>
					)
				) : (
					<FileDropperReact
						onFileInputChange={this.onFileInputChange}
						purpose="Drag and drop or click here to add new images"
						accept={this.accept}
						multiple={false}
					/>
				)}
			</div>
		);
	}
}

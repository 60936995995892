import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconEditor} from "../../abstract/common/iconeditor/IconEditor";
import {Constants} from "../../space/spaceeditor/logic3d/Constants";
import {Button} from "../../../widgets/button/Button";
import {Field} from "../../../widgets/form/field/Field";
import type {AppState} from "../../../../data/state/AppState";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {ImageUploadPreprocessor} from "../../../../utils/image/ImageUploadPreprocessor";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {PointDouble} from "../../../../generated/api/base";
import {ImageType} from "../../../../generated/api/base";
import {FileDropperReact} from "../../../interaction/draganddrop/FileDropperReact";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import {ReactUtils} from "../../../utils/ReactUtils";
import {ImageUtils} from "../../../../utils/image/ImageUtils";

interface IOrganizationSettingsProps {
	appState?: AppState;
	transport?: TransportLayer;
}

interface IOrganizationSettingsStates {
	newImage: string;
	existingImageURL: string;
	existingImage: string;
	imageAspectRatio: number;
	iconTranslate: PointDouble;
	iconOrientation: number;
	isIconFlippedX: boolean;
	isIconFlippedY: boolean;
	scale: number;
	isSidePanelOpen: boolean;
	isScaleChanged: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class OrganizationSettings extends React.Component<IOrganizationSettingsProps, IOrganizationSettingsStates> {
	private _svgRef = React.createRef<SVGSVGElement>();
	private _newImageType: ImageType;
	private _prevScale: number = 1;

	constructor(props: IOrganizationSettingsProps) {
		super(props);
		this.state = {
			newImage: null,
			existingImageURL: null,
			existingImage: null,
			imageAspectRatio: 1,
			iconTranslate: {x: 0.5, y: 0.5},
			iconOrientation: 0,
			isIconFlippedX: false,
			isIconFlippedY: false,
			scale: 1,
			isSidePanelOpen: false,
			isScaleChanged: false,
		};
	}

	private onFileInputChange = async (files: FileList) => {
		if (files.length > 0) {
			const file = files[0];

			const data = await ImageUploadPreprocessor.getImageDataForUpload(file, false);

			this._newImageType = data.imageType;

			if (this._newImageType === ImageType.SVG) {
				data.fullImageData = await FileUtils.readAsText(file);
			}

			this.setState({
				newImage: data.fullImageData,
				imageAspectRatio: data.aspectRatio,
			});
		}
	};

	private openSidePanel = () => !this.state.isSidePanelOpen && this.setState({isSidePanelOpen: true});
	private closeSidePanel = () => this.state.isSidePanelOpen && this.setState({isSidePanelOpen: false});

	private onSaveClick = async () => {
		const {newImage, scale} = this.state;
		const isScaleChanged = this._prevScale !== scale;

		if (newImage || isScaleChanged) {
			const isSVG = this._newImageType === ImageType.SVG;
			const generatedImage = await ImageUploadPreprocessor.createCompressedImageFromSVG(this._svgRef.current, isSVG, true, Constants.RESOLUTION.LOGO);
			const result = await this.props.transport
				.requestForOrganization({
					url: "organizations/uploadlogo",
					method: XHRLoader.METHOD_POST,
					params: {
						image: generatedImage,
						imageType: isSVG ? ImageType.SVG : ImageType.PNG,
					},
				})
				.catch((error) => console.log(error));

			this.props.appState.actions.getCurrentOrganization().logoVersion++;

			this.loadLogo(generatedImage);

			this._prevScale = scale;

			this.closeSidePanel();
		} else {
			console.log(newImage, "No file selected, or something went wrong");
		}
	};

	private async loadLogo(orgLogoURL?: string) {
		const {transport} = this.props;
		let innerPart = this.state.existingImage;

		if (!orgLogoURL) {
			orgLogoURL = transport.getCurrentOrganizationLogo();
			const img = orgLogoURL && (await ImageUtils.loadImage(orgLogoURL));

			innerPart = img && ImageUtils.image2Base64String(img);
		}

		this.setState({
			existingImageURL: orgLogoURL,
			newImage: null,
			existingImage: innerPart,
		});
	}

	public override componentDidMount() {
		this.loadLogo();
	}

	public override render() {
		const {appState} = this.props;
		const {existingImageURL, newImage, scale, existingImage, isSidePanelOpen, imageAspectRatio, iconOrientation, iconTranslate} = this.state;
		const orgName = appState.actions.getCurrentOrganizationName();
		const isUserAdmin = !!appState.user?.isAdmin;

		return (
			<div className="OrganizationSettings">
				<div className="settingsContainer vbox">
					<div className="logoContainer">
						<div className="logo">
							{existingImageURL ? (
								<img
									src={existingImageURL}
									alt="Organization logo"
								/>
							) : (
								<div className="noLogo" />
							)}
						</div>
						{isUserAdmin && (
							<Button
								className="primary"
								label={`${existingImageURL ? "Edit Logo" : "Upload Logo"}`}
								onClick={this.openSidePanel}
							/>
						)}
					</div>
					<div>
						<Field label="Organization name">{orgName}</Field>
					</div>
				</div>
				{isUserAdmin && (
					<div className={ReactUtils.cls("simpleSidePanel", {open: isSidePanelOpen})}>
						<div className="heading hbox createBox">
							<h4>{existingImageURL ? "Edit organization logo" : "Upload organization logo"}</h4>
							<IconButton
								icon="close"
								onClick={this.closeSidePanel}
							/>
						</div>
						<div className="buttons hbox">
							<Button
								label="Cancel"
								className="secondary"
								onClick={this.closeSidePanel}
							/>
							<Button
								label="Save"
								className="primary"
								onClick={this.onSaveClick}
								disabled={!newImage && this._prevScale === scale}
							/>
						</div>
						<div className="imageUpload">
							<div className="logoContainer">
								{(existingImageURL || newImage) && (
									<IconEditor
										selectedLayer="Image"
										replaceColor={false}
										isSVG={this._newImageType === ImageType.SVG}
										innerPart={newImage || existingImage}
										imageAspectRatio={imageAspectRatio}
										iconTranslate={iconTranslate}
										iconOrientation={iconOrientation}
										scale={scale}
										onIconTranslateChange={(newTranslate: PointDouble) => this.setState({iconTranslate: newTranslate})}
										onScaleChange={(newScale: number) => this.setState({scale: newScale})}
										svgRef={this._svgRef}
									/>
								)}
							</div>
							<FileDropperReact
								onFileInputChange={this.onFileInputChange}
								purpose="Drag and drop or click here to add new logo"
								accept="image/*"
								multiple={false}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
}

import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";

interface ISVGIconProps {
	icon: string;
	classNames?: string;
	strokeOnly?: boolean;
	style?: React.CSSProperties;
	divRef?: React.RefObject<SVGSVGElement>;
}

export class SVGIcon extends React.PureComponent<ISVGIconProps> {
	public override render() {
		// pointer-events:none to prevent default tooltips (mouse hover labels)
		const style: React.CSSProperties = {
			...(this.props.style || {}),
			pointerEvents: "none",
		};

		return (
			<svg
				ref={this.props.divRef}
				className={ReactUtils.cls(`icon ${this.props.classNames || ""}`, {strokeOnly: this.props.strokeOnly})}
				style={style}
			>
				<use xlinkHref={`#icon-${this.props.icon}`}></use>
			</svg>
		);
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {Button} from "../../../widgets/button/Button";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import type {XyiconFeature} from "../../../../generated/api/base";
import {Permission} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";

interface IEmptyListViewProps {
	onAddClick?: () => void;
	feature?: XyiconFeature;
	text?: React.ReactNode;
	appState?: AppState;
}

@inject("appState")
export class EmptyListView extends React.Component<IEmptyListViewProps> {
	private get hasPermission() {
		const {appState, feature} = this.props;

		return appState.actions.hasAnyTypeTheGivenPermissionInModule(feature, Permission.Update);
	}

	public override render() {
		const {feature, onAddClick, text} = this.props;
		const moduleName = featureTitles[feature] || "";

		return (
			<div className="EmptyListView">
				<div className="content">
					<p className="description">
						{text || `There are no ${moduleName || ""} items to list.`}
						{onAddClick && (
							<>
								<br />
								Start by adding your first ...
							</>
						)}
					</p>
					{onAddClick && (
						<Button
							icon="add"
							label={`Add New ${moduleName || "item"}`}
							disabled={!this.hasPermission}
							onClick={onAddClick}
						/>
					)}
				</div>
			</div>
		);
	}
}

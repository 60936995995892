import {computed, makeObservable} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {SupportedFontName} from "../../logic3d/managers/MSDF/TextGroupManager";
import {TextStyleModifier} from "../../../../catalog/create/TextStyleModifier";
import type {Color} from "../../../../../../generated/api/base";
import {Markup} from "../../../../../../data/models/Markup";
import type {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {MarkupsWithCustomizableTextAlignment} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import type {MarkupTextInputElement} from "./MarkupTextInputElement";

interface IMarkupTextActionBarProps {
	inputElementRef: React.RefObject<MarkupTextInputElement>;
	markup3D: Markup3D;
	onClose: () => void;
}

@observer
export class MarkupTextActionBar extends React.Component<IMarkupTextActionBarProps> {
	constructor(props: IMarkupTextActionBarProps) {
		super(props);
		makeObservable(this);
	}

	@computed
	private get _text() {
		return this._markup?.text || Markup.defaultText;
	}

	private get _markup() {
		return this.props.markup3D.modelData as Markup;
	}

	private onIsBoldChange = (value: boolean) => {
		this._text.isBold = value;
	};

	private onIsItalicChange = (value: boolean) => {
		this._text.isItalic = value;
	};

	private onIsUnderlinedChange = (value: boolean) => {
		this._text.isUnderlined = value;
	};

	private onFontColorChange = (newColor: Color) => {
		this._text.fontColor = newColor;
	};

	private onFontSizeChange = (newFontSize: number) => {
		this._text.fontSize = newFontSize;
		this.props.inputElementRef.current?.onTextInputChange();
	};

	private onFontFamilyChange = (newFontFamily: SupportedFontName) => {
		this._text.fontFamily = newFontFamily;
	};

	private onClearTextClick = () => {
		this._text.content = "";
		this.props.inputElementRef.current?.clearText();
	};

	private onTextAlignmentChange = (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => {
		this._text.horizontalAlignment = horizontalAlignment;
		this._text.verticalAlignment = verticalAlignment;
	};

	public override render() {
		const text = this._text;

		return (
			<TextStyleModifier
				isBold={text.isBold}
				isItalic={text.isItalic}
				isUnderlined={text.isUnderlined}
				fontColor={text.fontColor}
				fontFamily={text.fontFamily as SupportedFontName}
				fontSize={text.fontSize}
				onIsBoldChange={this.onIsBoldChange}
				onIsItalicChange={this.onIsItalicChange}
				onIsUnderlinedChange={this.onIsUnderlinedChange}
				onFontColorChange={this.onFontColorChange}
				onFontSizeChange={this.onFontSizeChange}
				onFontFamilyChange={this.onFontFamilyChange}
				onClearTextClick={this.onClearTextClick}
				onTextBoxAlignmentChange={MarkupsWithCustomizableTextAlignment.includes(this.props.markup3D.type) ? this.onTextAlignmentChange : null}
				horizontalAlignment={text.horizontalAlignment}
				verticalAlignment={text.verticalAlignment}
				onClose={this.props.onClose}
			/>
		);
	}
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MarkupType {
	Cloud = 1,
	Arrow = 2,
	Bidirectional_Arrow = 3,
	Line = 4,
	Dashed_Line = 5,
	Pencil_Drawing = 6,
	Highlight_Drawing = 7,
	Text_Box = 8,
	Rectangle = 9,
	Ellipse = 10,
	Triangle = 11,
	Cross = 12,
	Linear_Distance = 13,
	Rectangle_Area = 14,
	Nonlinear_Distance = 15,
	Irregular_Area = 16,
	Stamp = 17,
	Photo = 18,
	Callout = 19,
}

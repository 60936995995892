import {Tool} from "../Tool";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {MarkupManager} from "../../../managers/spaceitems/MarkupManager";
import type {MarkupAB} from "../../../elements3d/markups/abstract/MarkupAB";
import {MarkupCloud} from "../../../elements3d/markups/MarkupCloud";
import {MarkupLine} from "../../../elements3d/markups/MarkupLine";
import {MarkupArrow} from "../../../elements3d/markups/MarkupArrow";
import {MarkupEllipse} from "../../../elements3d/markups/MarkupEllipse";
import {MarkupRectangle} from "../../../elements3d/markups/MarkupRectangle";
import {MarkupTriangle} from "../../../elements3d/markups/MarkupTriangle";
import {MarkupCross} from "../../../elements3d/markups/MarkupCross";
import {SetScaleLine} from "../../../elements3d/markups/SetScaleLine";
import {MarkupTextBox} from "../../../elements3d/markups/MarkupTextBox";
import type {SupportedFontName} from "../../../managers/MSDF/TextGroupManager";
import {TextGroupManager} from "../../../managers/MSDF/TextGroupManager";
import {MeasureType} from "../../../renderers/SpaceViewRendererUtils";
import {dashSize, dottedLineDashSize, dottedLineGapSize, gapSize} from "../../../elements3d/markups/MarkupStaticElements";
import {MarkupCallout} from "../../../elements3d/markups/MarkupCallout";
import {getDefaultMarkupFontSize} from "../../../elements3d/markups/MarkupText.utils";
import {MarkupUtils, type IMarkupConfig, TempMarkupLineWidth} from "../../../elements3d/markups/abstract/MarkupUtils";
import type {ToolType} from "../Tools";
import type {IMarkupSettingsData} from "../../../../../../../../data/models/Markup";
import type {Pointer} from "../../../../../../../../utils/interaction/Pointer";
import {KeyboardListener} from "../../../../../../../../utils/interaction/key/KeyboardListener";
import {MarkupType} from "../../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../../generated/api/base";

type MarkupABType =
	| "cloud"
	| "arrow"
	| "bidirectional-arrow"
	| "line"
	| "dashed-line"
	| "ellipse"
	| "rectangle"
	| "triangle"
	| "cross"
	| "set-scale"
	| "text"
	| "callout";

/**
 * A tool that can create MarkupAB: a common markup family that can be defined by 2 pairs of world coordinates: A, and B
 */

export class ABTool extends Tool {
	protected _markupManager: MarkupManager;
	protected _markup: MarkupAB;
	protected override _toolType: ToolType = "markup";
	private _type: MarkupABType;
	private _isMarkupTextBoxBeingCreated: boolean = false;
	private _measureType: MeasureType;
	private readonly _isTemp: boolean = false;

	constructor(spaceViewRenderer: SpaceViewRenderer, type: MarkupABType, measureType: MeasureType = MeasureType.NONE, isTemp: boolean = false) {
		super(spaceViewRenderer, false, "crosshair");
		this._isTemp = isTemp;
		this._markupManager = spaceViewRenderer.markupManager;
		this._type = type;
		this._measureType = measureType;
	}

	private getCurrentMarkupType(): MarkupType {
		if (this._measureType === MeasureType.DISTANCE) {
			if (this._type === "line") {
				return MarkupType.Linear_Distance;
			}
		} else if (this._measureType === MeasureType.AREA) {
			if (this._type === "rectangle") {
				return MarkupType.Rectangle_Area;
			}
		} else {
			switch (this._type) {
				case "arrow":
					return MarkupType.Arrow;
				case "bidirectional-arrow":
					return MarkupType.Bidirectional_Arrow;
				case "callout":
					return MarkupType.Callout;
				case "cloud":
					return MarkupType.Cloud;
				case "cross":
					return MarkupType.Cross;
				case "dashed-line":
					return MarkupType.Dashed_Line;
				case "ellipse":
					return MarkupType.Ellipse;
				case "line":
					return MarkupType.Line;
				case "rectangle":
					return MarkupType.Rectangle;
				case "set-scale":
					return null;
				case "text":
					return MarkupType.Text_Box;
				case "triangle":
					return MarkupType.Triangle;
			}
		}
	}

	public override deactivate(): boolean {
		this.abortMarkup();
		return super.deactivate();
	}

	protected onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		// a markup text box is being created
		// we need to wait for the response from the server before we can create a new markup
		if (!this._isMarkupTextBoxBeingCreated) {
			const color = this._isTemp ? this._spaceViewRenderer.measureToolColor.hex : this._markupManager.markupColor;
			const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;
			const strokeConfig: IMarkupConfig = {
				measureType: this._measureType,
				strokeColor: color,
				isTemp: this._isTemp,
			};

			const fillConfig: IMarkupConfig = {
				strokeColor: color,
				fill: true,
				measureType: this._measureType,
				isTemp: this._isTemp,
				fillOpacity: MarkupUtils.getDefaultFillOpacityForType(this.getCurrentMarkupType()),
			};

			if (this._isTemp) {
				strokeConfig.dashSize = dottedLineDashSize * correctionMultiplier;
				strokeConfig.gapSize = dottedLineGapSize * correctionMultiplier;
				fillConfig.dashSize = dottedLineDashSize * correctionMultiplier;
				fillConfig.gapSize = dottedLineGapSize * correctionMultiplier;
				fillConfig.lineWidth = strokeConfig.lineWidth = TempMarkupLineWidth;
			}

			switch (this._type) {
				case "cloud":
					this._markup = new MarkupCloud(this._spaceViewRenderer, fillConfig);
					break;
				case "arrow":
					this._markup = new MarkupArrow(this._spaceViewRenderer, strokeConfig);
					break;
				case "bidirectional-arrow":
					if (this._isTemp) {
						strokeConfig.measureType = MeasureType.DISTANCE;
					}
					this._markup = new MarkupArrow(this._spaceViewRenderer, strokeConfig, true);
					break;
				case "line":
					this._markup = new MarkupLine(this._spaceViewRenderer, strokeConfig);
					break;
				case "dashed-line":
					strokeConfig.dashSize = dashSize * correctionMultiplier;
					strokeConfig.gapSize = gapSize * correctionMultiplier;
					this._markup = new MarkupLine(this._spaceViewRenderer, strokeConfig);
					break;
				case "rectangle":
					this._markup = new MarkupRectangle(this._spaceViewRenderer, fillConfig);
					break;
				case "ellipse":
					this._markup = new MarkupEllipse(this._spaceViewRenderer, fillConfig);
					break;
				case "triangle":
					this._markup = new MarkupTriangle(this._spaceViewRenderer, fillConfig);
					break;
				case "cross":
					this._markup = new MarkupCross(this._spaceViewRenderer, strokeConfig);
					break;
				case "set-scale":
					this._markup = new SetScaleLine(this._spaceViewRenderer);
					break;
				case "text":
				case "callout":
					const isTextBox = this._type === "text";

					if (isTextBox) {
						fillConfig.strokeColor = MarkupTextBox.outlineColor;
					}
					const MarkupTextOrCallout = isTextBox ? MarkupTextBox : MarkupCallout;
					const markupTextBox = new MarkupTextOrCallout(this._spaceViewRenderer, fillConfig);

					this._markup = markupTextBox;

					const defaultSize = TextGroupManager.getDefaultMarkupTextBoxSize(
						markupTextBox.type,
						markupTextBox.fontFamily,
						this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue,
						this._spaceViewRenderer.correctionMultiplier.current,
					);

					if (isTextBox) {
						this._markup.updateGeometry(
							[this._pointerWorldStart, {x: worldX + defaultSize.x, y: worldY - defaultSize.y}],
							false,
							KeyboardListener.isAltDown,
							"a",
						);
					} else {
						(this._markup as MarkupCallout).setTargetOnPointerDown(this._pointerWorldStart.x, this._pointerWorldStart.y);
						// callout
						this._markup.updateGeometry(
							[
								{
									x: this._pointerWorldStart.x + defaultSize.x / 2,
									y: this._pointerWorldStart.y + defaultSize.y / 2,
								},
								{
									x: worldX + defaultSize.x * 1.5,
									y: worldY + defaultSize.y * 1.5,
								},
							],
							false,
							KeyboardListener.isAltDown,
							"a",
						);
					}
					break;
			}

			this._markup?.showLayerWithNotification();
			this._spaceViewRenderer.spaceItemController.deselectAll();
		}
	};

	protected onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		// a markup text box is being created
		// we need to wait for the response from the server before we can create a new markup
		if (!this._isMarkupTextBoxBeingCreated) {
			if (this._type === "callout") {
				const defaultSize = TextGroupManager.getDefaultMarkupTextBoxSize(
					this._markup.type,
					this._markup.fontFamily,
					this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue,
					this._spaceViewRenderer.correctionMultiplier.current,
				);

				this._markup.updateGeometry(
					[
						{x: worldX - defaultSize.x / 2, y: worldY + defaultSize.y / 2},
						{x: worldX + defaultSize.x / 2, y: worldY - defaultSize.y / 2},
					],
					false,
					KeyboardListener.isAltDown,
					"a",
				);
			} else {
				this._markup?.updateGeometry([this._pointerWorldStart, {x: worldX, y: worldY}], false, KeyboardListener.isAltDown, "a");
			}
		}
	};

	protected onPointerUpCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this.finalizeMarkup(worldX, worldY);
	};

	private async finalizeMarkup(worldX: number, worldY: number) {
		if (this._markup && !this._isMarkupTextBoxBeingCreated) {
			const isCallout = this._markup.type === MarkupType.Callout;
			const isTextBox = this._markup.type === MarkupType.Text_Box;

			if (isTextBox || isCallout) {
				const geometryData = [...this._markup.data.geometryData].sort((a: PointDouble, b: PointDouble) => a.y - b.y);

				if (isTextBox) {
					const fontSize = getDefaultMarkupFontSize(this._markup.type, this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue);
					const lineHeight = TextGroupManager.getLineHeight(this._markup.data.text.fontFamily as SupportedFontName, fontSize);

					geometryData[0].y = geometryData[geometryData.length - 1].y - lineHeight;
				}

				this._markup.updateGeometry(geometryData);
				const markupSettings: IMarkupSettingsData = {};

				// If the user sets any size, that will be considered as a fixed size (for width)
				if (this._pointerWorldStart.x !== worldX || this._pointerWorldStart.y !== worldY) {
					markupSettings.isSizeFixed = true;
				}
				if (isCallout) {
					markupSettings.target = (this._markup as MarkupCallout).targetOnPointerDown;
				}

				this._markup.tempSettings = JSON.stringify(markupSettings);
				this._isMarkupTextBoxBeingCreated = true;
				await this._markupManager.add([this._markup]);

				const {cameraTarget} = this._spaceViewRenderer.toolManager.cameraControls;
				const markupPosition = this._markup.position;

				cameraTarget.x.setEnd(markupPosition.x);
				cameraTarget.y.setEnd(markupPosition.y);

				this._spaceViewRenderer.inheritedMethods.onSwitchToTextEditMode();
				this._isMarkupTextBoxBeingCreated = false;
			} else if (this._markup.isValid) {
				this._markupManager.add([this._markup]);
			} else {
				this.abortMarkup();
			}

			this._markup = null;
		}
	}

	public get markup() {
		return this._markup;
	}

	public abortMarkup() {
		this._markup?.destroy(false, true);
		this._markup = null;
	}
}

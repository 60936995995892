import * as React from "react";
import {DateTimeInput} from "../../../../../widgets/input/datetime/DateTimeInput";
import {DateUtils} from "../../../../../../utils/DateUtils";

export interface IBetweenDateParamProps {
	param: {from: string; to: string};
	onChange(value: {from: string; to: string}): void;
}

export class BetweenDateParam extends React.Component<IBetweenDateParamProps> {
	private get _from() {
		return this.props.param?.from;
	}

	private get _to() {
		return this.props.param?.to;
	}

	public override componentDidMount() {
		if (!this.props.param) {
			this.props.onChange({from: this._from, to: this._to});
		}
	}

	public override componentDidUpdate() {
		if (!this.props.param) {
			this.props.onChange({from: this._from, to: this._to});
		}
	}

	public override render() {
		const {onChange} = this.props;

		return (
			<DateTimeInput
				value={this._from || ""}
				valueToDate={this._to || ""}
				onChange={(value) => onChange(DateUtils.parseDateRange(value))}
				format="daterange"
			/>
		);
	}
}

export const convertColumnNameToPropertyName = (columnName: string) => {
	switch (columnName.toLowerCase()) {
		case "xyicon model":
			return "model";
		case "xyicon type":
		case "boundary type":
		case "markup type":
			return "typeName";
		case "xyicon x":
			return "iconX";
		case "xyicon y":
			return "iconY";
		case "xyicon z":
			return "iconZ";
		case "boundary geometry":
		case "markup geometry":
			return "geometryData";
		case "xyicon orientation":
		case "boundary orientation":
		case "markup orientation":
			return "orientation";
		case "xyicon port data":
			return "portData";
		case "parent xyicon id":
			return "parentXyiconRefId";
		case "xyicon id":
		case "boundary id":
			return "refId";
		case "markup color":
			return "color";
		case "markup text":
			return "text";
		case "markup fill transparency":
			return "fillTransparency";
		case "markup line thickness":
			return "lineThickness";
		case "markup settings":
		case "xyicon settings":
			return "settings";
		case "guid":
			return "id";
	}
};

import {ObjectUtils} from "../data/ObjectUtils";
import {Signal} from "../signal/Signal";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {MathUtils} from "../math/MathUtils";

/** This class is mainly for animating anything seamlessly and smoothly.
 *  If you modify the "end", end you keep calling "update", then "start" will get closer and closer to the value of "end"
 *  The higher the dampingFactor is, the faster the "animation" is. It should be between 0 and 1.*/

export enum Easing {
	EASE_OUT,
	EASE_IN_OUT,
}

interface ITimeStampManager {
	timeStamp: number;
	activeConvergences: Convergence[];
}

export interface IConvergenceParameters {
	start: number;
	end: number;
	easing?: Easing;
	animationDuration?: number;
	triggerRender?: boolean;
	timeStampManager: ITimeStampManager;
}

export class Convergence {
	protected static readonly _defaultConfig: IConvergenceParameters = {
		start: 0,
		end: 0,
		easing: Easing.EASE_OUT,
		animationDuration: Constants.DURATIONS.DEFAULT_ANIMATION,
		triggerRender: true,
		timeStampManager: {timeStamp: 0, activeConvergences: []},
	};
	private _timeStampAtSetEnd: number = 0;
	private _value: number; // current value (between start and end)
	private _animationDuration: number; // ms
	private _originalAnimationDuration: number; // ms
	private _hasChanged: boolean = false;
	private _prevDeltaValue: number = 0;
	private _prevTimeStamp: number = 1;
	private _prevDeltaTime: number = 1;
	private _easing: Easing = Easing.EASE_OUT;
	private _timeoutId: number = -1;
	private _triggerRender: boolean;
	protected _originalStart: number;
	protected _originalEnd: number;
	protected _start: number;
	protected _end: number;
	protected _min: number = -Infinity;
	protected _max: number = Infinity;
	private _timeStampManager: ITimeStampManager;
	public signals = {
		onUpdate: Signal.create<number>(),
		onComplete: Signal.create<number>(), // dispatches one frame after the last modification (when newValue === prevValue)
	};

	constructor(parameters: IConvergenceParameters) {
		const config = ObjectUtils.mergeConfig(Convergence._defaultConfig, parameters);

		this._originalStart = config.start;
		this._start = config.start;
		this._originalEnd = config.end;
		this._end = config.end;
		this._value = this._start;
		this._originalAnimationDuration = this._animationDuration = config.animationDuration;
		this._easing = config.easing;
		this._triggerRender = config.triggerRender;
		this._timeStampManager = config.timeStampManager;

		if (config.start !== config.end) {
			Convergence.addToActiveOnes(this, this._timeStampManager);
			this._timeStampAtSetEnd = this._timeStampManager.timeStamp;
		}
	}

	private static removeFromActiveOnes(convergenceToRemove: Convergence, timeStampManager: ITimeStampManager) {
		timeStampManager.activeConvergences = timeStampManager.activeConvergences.filter(
			(convergence: Convergence) => convergence !== convergenceToRemove,
		);
	}

	private static addToActiveOnes(convergenceToAdd: Convergence, timeStampManager: ITimeStampManager) {
		if (!timeStampManager.activeConvergences.includes(convergenceToAdd)) {
			timeStampManager.activeConvergences.push(convergenceToAdd);
		}
	}

	public static updateActiveOnes(timeStamp: number, timeStampManager: ITimeStampManager) {
		let triggerRender = false;

		for (const c of timeStampManager.activeConvergences) {
			triggerRender = triggerRender || c._triggerRender;
			c.update(timeStamp);
		}

		return triggerRender;
	}

	private smoothStep(elapsedTime: number) {
		if (elapsedTime < this._animationDuration) {
			const x = elapsedTime / this._animationDuration;

			return MathUtils.clamp(x ** 2 * (3 - 2 * x) * (this._end - this._start) + this._start, this._min, this._max);
		} else {
			this._end = MathUtils.clamp(this._end, this._min, this._max);
			return this._end;
		}
	}

	private exponentialOut(elapsedTime: number) {
		if (elapsedTime < this._animationDuration) {
			const x = elapsedTime / this._animationDuration;

			return MathUtils.clamp((1 - 2 ** (-10 * x)) * (1024 / 1023) * (this._end - this._start) + this._start, this._min, this._max);
		} else {
			this._end = MathUtils.clamp(this._end, this._min, this._max);
			return this._end;
		}
	}

	// elapsedTime since "setEnd" called in ms
	private getNextValue(elapsedTime: number) {
		return this._easing === Easing.EASE_IN_OUT ? this.smoothStep(elapsedTime) : this.exponentialOut(elapsedTime);
	}

	public increaseEndBy(value: number, clampBetweenMinAndMax: boolean = false) {
		this.setEnd(this._end + value, clampBetweenMinAndMax);
	}

	public decreaseEndBy(value: number, clampBetweenMinAndMax: boolean = false) {
		this.setEnd(this._end - value, clampBetweenMinAndMax);
	}

	/**
	 *
	 * @param value
	 * @param clampBetweenMinAndMax if true, and the "value" is not within [min, max], then the animation is going smoothly to the clamped value
	 * Otherwise, the animation is going as if there were no min, or max, but when it reaches the limit, it stops suddenly
	 * @param animationDuration
	 */
	public setEnd(value: number, clampBetweenMinAndMax: boolean = false, animationDuration: number = this._originalAnimationDuration) {
		this._animationDuration = animationDuration;
		const newEnd = clampBetweenMinAndMax ? MathUtils.clamp(value, this._min, this._max) : value;

		Convergence.addToActiveOnes(this, this._timeStampManager);
		this._timeStampAtSetEnd = this._timeStampManager.timeStamp;
		this._start = this._value;
		this._end = newEnd;

		if (!clampBetweenMinAndMax) {
			clearTimeout(this._timeoutId);
			this._timeoutId = window.setTimeout(() => {
				this._end = MathUtils.clamp(this._end, this._min, this._max);
			}, this._animationDuration);
		}
	}

	public reset(start?: number, end?: number, animationDuration: number = this._originalAnimationDuration) {
		this._animationDuration = animationDuration;
		Convergence.addToActiveOnes(this, this._timeStampManager);
		this._timeStampAtSetEnd = this._timeStampManager.timeStamp;
		this._start = start != null ? start : this._originalStart;
		this._end = end != null ? end : this._originalEnd;
	}

	private update(timeStamp: number) {
		this._prevDeltaTime = timeStamp - this._prevTimeStamp;
		const elapsedTime = timeStamp - this._timeStampAtSetEnd;
		const prevValue = this._value;

		this._value = this.getNextValue(elapsedTime);
		this._prevDeltaValue = this._value - prevValue;
		this._prevTimeStamp = timeStamp;

		if (this._value === prevValue) {
			this._start = this._end;
			this._hasChanged = false;
			Convergence.removeFromActiveOnes(this, this._timeStampManager);

			this.signals.onComplete.dispatch(this._value);
		} else {
			this._hasChanged = true;
			this.signals.onUpdate.dispatch(this._value);
		}
	}

	public removeListeners() {
		for (const key in this.signals) {
			this.signals[key as keyof typeof this.signals].removeAll();
		}
	}

	public get animationDuration() {
		return this._animationDuration;
	}

	public get originalAnimationDuration() {
		return this._originalAnimationDuration;
	}

	public get start() {
		return this._start;
	}

	public get value() {
		return this._value;
	}

	public get end() {
		return this._end;
	}

	public get hasChangedSinceLastTick() {
		return this._hasChanged;
	}

	public get prevDeltaValue() {
		return this._prevDeltaValue;
	}

	public get prevDeltaTime() {
		return this._prevDeltaTime;
	}

	public get derivateAt0() {
		return this._easing === Easing.EASE_OUT
			? 6.938247437862991 // Equals: (5*Math.log(2) * 2**11) / 1023;
			: 0; // Smoothstep's derivate is 0 at 0
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Space} from "../../../../data/models/Space";
import {XyiconFeature} from "../../../../generated/api/base";
import type {SpaceFile} from "../../../../data/models/SpaceFile";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {AppState} from "../../../../data/state/AppState";

interface ISpaceVersionSelectorProps {
	transport?: TransportLayer;
	appState?: AppState;
	item: Space;
	onChoose?: (spaceFile: SpaceFile) => void;
}

@inject("transport")
@inject("appState")
@observer
export class SpaceVersionSelector extends React.Component<ISpaceVersionSelectorProps> {
	private getOptions() {
		const space = this.props.item as Space;
		const spaceFiles = space.spaceFiles.filter((spaceFile: SpaceFile) => spaceFile.id != null); // filter out temporary spacefiles

		return spaceFiles;
	}

	private onChoose = (spaceFile: SpaceFile) => {
		const space = this.props.item;

		if (space.ownFeature === XyiconFeature.Space) {
			space.setSelectedSpaceFile(spaceFile);

			this.setState({
				isDropdownOpen: false,
			});

			this.props.onChoose?.(spaceFile);
		}
	};

	public override render() {
		const {item, appState} = this.props;

		const props = {
			selected: item.selectedSpaceFile,
			options: this.getOptions(),
			render: (spaceFile: SpaceFile) => spaceFile.spaceVersion?.name,
			onChange: this.onChoose,
			sort: false,
		};

		if (item?.ownFeature === XyiconFeature.Space) {
			return (
				<div className="SpaceVersionSelector">
					<SelectInput {...props} />
				</div>
			);
		} else {
			return null;
		}
	}
}

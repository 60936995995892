import * as React from "react";
import {NumberInput} from "../../../../../widgets/input/number/NumberInput";

interface INumberParamProps {
	param: number;
	onChange(value: number): void;
}

export class NumberParam extends React.Component<INumberParamProps> {
	public override render() {
		const {param, onChange} = this.props;

		return (
			<NumberInput
				value={param || 0}
				onInput={(value) => onChange(value)}
			/>
		);
	}
}

import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {IPopupWindowConfig} from "./PopupWindow";
import {PopupWindow} from "./PopupWindow";

export class SpaceExportWindow extends PopupWindow<string | null> {
	protected static override readonly _defaultConfig: IPopupWindowConfig = {
		ok: "Export",
		cancel: "Cancel",
		backdrop: false,
		parentElement: document.body,
		disableButtonOnEmptyInput: true,
	};

	protected _okValue: string[] = [];
	protected _cancelValue: null = null;

	constructor(message: string, title: string, placeholders: string[], defaultValues: string[], inputCount: number, config: IPopupWindowConfig = {}) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(SpaceExportWindow._defaultConfig, config),
		});

		for (let i = 0; i < inputCount; i++) {
			this._exportInputs.push(document.createElement("input"));
			const element = this._exportInputs[i];

			(element as HTMLInputElement).placeholder = placeholders[i];
			(element as HTMLInputElement).value = defaultValues[i];
			element.oninput = (event: Event) => this.onInputFieldChange(event, i);
		}
		this._okValue = defaultValues;

		requestAnimationFrame(() => {
			if (this._exportInputs[0]?.parentElement) {
				this._exportInputs[0].focus();
			}
		});
	}

	private onInputFieldChange = (event: Event, index: number) => {
		this._okValue[index] = (event.currentTarget as HTMLInputElement).value;

		if (this._config.disableButtonOnEmptyInput) {
			if (!this._okValue[index]) {
				this.disableOkButton();
			} else {
				this.enableOkButton();
			}
		}
	};

	public static open(
		message: string,
		title: string = "Submit",
		placeholders: string[] = [],
		defaultValues: string[] = [],
		inputCount: number,
		config?: IPopupWindowConfig,
	) {
		return new SpaceExportWindow(message, title, placeholders, defaultValues, inputCount, config).open() as Promise<string[]>;
	}
}

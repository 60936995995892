export interface IClsObj {
	[key: string]: any; // any: usually boolean
}

export class ReactUtils {
	public static cls(obj: IClsObj): string;
	public static cls(str: string, obj?: IClsObj): string;
	public static cls(objOrStr: string | IClsObj, obj?: IClsObj): string {
		if (!obj) {
			obj = objOrStr as IClsObj;
			objOrStr = "";
		}

		let result = (objOrStr as string) || "";

		for (const className in obj) {
			if (obj[className]) {
				if (result.length > 0) {
					result += " ";
				}
				result += className;
			}
		}

		return result;
	}

	public static loop(count: number, factoryMethod: (index: number) => React.ReactChild) {
		let result: React.ReactChild[] = [];

		for (let i = 0; i < count; ++i) {
			const child = factoryMethod(i);

			result.push(child);
		}

		return result;
	}

	// https://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters#answer-979997
	// (function called "gup")
	public static searchForParamInUrl(name: string, url: string) {
		if (!url) {
			url = location.href;
		}
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regexS = `[\\?&]${name}=([^&#]*)`;
		var regex = new RegExp(regexS);
		var results = regex.exec(url);

		return results == null ? null : results[1];
	}

	// https://stackoverflow.com/questions/63141123/get-text-content-from-react-element-stored-in-a-variable
	public static getTextContent = (elem: React.ReactElement | string): string => {
		if (!elem) {
			return "";
		}
		if (typeof elem === "string") {
			return elem;
		}
		// Debugging for basic content shows that props.children, if any, is either a
		// ReactElement, or a string, or an Array with any combination. Like for
		// `<p>Hello <em>world</em>!</p>`:
		//
		//   $$typeof: Symbol(react.element)
		//   type: "p"
		//   props:
		//     children:
		//       - "Hello "
		//       - $$typeof: Symbol(react.element)
		//         type: "em"
		//         props:
		//           children: "world"
		//       - "!"
		const children = elem.props?.children || elem.props?.textContent;

		if (Array.isArray(children)) {
			return children.map(ReactUtils.getTextContent).join("");
		}

		return ReactUtils.getTextContent(children);
	};
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "../../../../widgets/button/Button";
import {IconButton} from "../../../../widgets/button/IconButton";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {TextInput} from "../../../../widgets/input/text/TextInput";
import {Field} from "../../../../widgets/form/field/Field";
import {User} from "../../../../../data/models/User";
import type {AppState} from "../../../../../data/state/AppState";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {ISwitchListChange} from "../../../../widgets/button/switch/SwitchList";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import {UserForm} from "./UserForm";

interface ICreateUserPanelProps {
	onCancel: () => void;
	onCreated: (id: string) => void;
	creating?: boolean;
	transport?: TransportLayer;
	appState?: AppState;
}

interface ICreateUserPanelState {
	errorMessage: string;
	loading: boolean;
	userGroups: string[];
}

@inject("transport")
@inject("appState")
@inject("navigation")
@observer
export class CreateUserPanel extends React.PureComponent<ICreateUserPanelProps, ICreateUserPanelState> {
	private _user = new User(
		{
			userID: "",
			email: "",
		} as any,
		this.props.appState,
	);

	constructor(props: ICreateUserPanelProps) {
		super(props);
		this.state = {
			errorMessage: "",
			loading: false,
			userGroups: [],
		};
	}

	private resetCreatePanel() {
		this.setState({
			errorMessage: "",
			userGroups: [],
		});

		this._user = new User(
			{
				userID: "",
				email: "",
			} as any,
			this.props.appState,
		);

		this.forceUpdate();
	}

	private onChangeUserGroup = (changes: ISwitchListChange[]) => {
		const newList = [...this.state.userGroups];

		for (const change of changes) {
			if (change.value) {
				ArrayUtils.addMutable(newList, change.id);
			} else {
				ArrayUtils.removeMutable(newList, change.id);
			}
		}

		this.setState({userGroups: newList});
	};

	private onCreateClicked = async () => {
		this.setState({loading: true});

		const user = this._user;
		const {transport} = this.props;
		const {userGroups} = this.state;

		user.updatePermissions();
		const data = user.getCreateData();

		data.userGroupIDList = userGroups;

		const models = await transport.services.feature.create<User>(data, XyiconFeature.User);
		const newUser = models?.[0];

		if (newUser) {
			this.setState({loading: false});
			this.resetCreatePanel();

			[...newUser.userGroupIds, ...userGroups].forEach((userGroupId) => {
				const userGroup = this.props.appState.actions.getFeatureItemById<UserGroup>(userGroupId, XyiconFeature.UserGroup);

				if (userGroup) {
					userGroup.addUser(newUser.id, this.props.appState);
				}
			});

			this.props.onCreated(newUser.id);
		}

		this.setState({userGroups: []});
	};

	private isEmailValid(): boolean {
		return StringUtils.emailValidator(this._user.email);
	}

	private isUniqEmail(): boolean {
		return !this.props.appState.actions.getList(XyiconFeature.User).find((user: User) => StringUtils.equalsIgnoreCase(user.email, this._user.email));
	}

	private shouldInviteButtonAvailable(): boolean {
		return this.isUniqEmail() && this.isEmailValid() && !this.state.loading;
	}

	private onCancelClicked() {
		this.resetCreatePanel();
		this.props.onCancel();
	}

	public override render() {
		const user = this._user;

		return (
			<div className="CreateUserPanel SidePanel DetailsTab">
				<div className="heading hbox createBox">
					<h4>Create a New User</h4>
					<IconButton
						className="close"
						icon="close"
						onClick={() => this.onCancelClicked()}
					/>
				</div>
				<div className="sidePanelButtons hbox">
					<Button
						className="secondary"
						label="Cancel"
						onClick={() => this.onCancelClicked()}
					/>
					<Button
						label="Invite"
						onClick={this.onCreateClicked}
						disabled={!this.shouldInviteButtonAvailable()}
						className="primary"
						loading={this.state.loading}
					/>
				</div>
				<div className="scrollContainer">
					{!this.isUniqEmail() && (
						<div className="errorMessage">
							<p>A user with this email already exists.</p>
						</div>
					)}
					<Field label="Email">
						<TextInput
							placeholder="Email"
							value={user.email}
							onInput={(value) => (user.email = value)}
							autoFocus={this.props.creating}
						/>
					</Field>
					<UserForm
						users={[this._user]}
						onChangeUserGroup={this.onChangeUserGroup}
						assignedUserGroups={this.state.userGroups}
						hideSystemUserGroups={true}
					/>
				</div>
			</div>
		);
	}
}

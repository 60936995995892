import type {IModel} from "../../data/models/Model";

export const getNewDetailedItems = (stateDetailedItems: IModel[], item: IModel) => {
	// const indexOfItem = stateDetailedItems.indexOf(item);

	// return indexOfItem > -1 ? [...stateDetailedItems].slice(0, indexOfItem) : [...stateDetailedItems, item];

	return [...stateDetailedItems, item];
};

export const onDetailsContainerClose = (cmp: React.Component<any, {overlayedDetailedItems: IModel[]}>, closeAll: boolean = false) => {
	if (cmp.state.overlayedDetailedItems.length > 0) {
		if (closeAll) {
			cmp.setState({
				overlayedDetailedItems: [],
			});
		} else {
			const newOverlayedDetailedItems = [...cmp.state.overlayedDetailedItems];

			newOverlayedDetailedItems.pop();
			cmp.setState({
				overlayedDetailedItems: newOverlayedDetailedItems,
			});
		}
	}
};

import {useEffect, useState} from "react";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {Xyicon} from "../../../data/models/Xyicon";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import LocationPinIcon from "../icons/location-pin.svg?react";
import PenFieldIcon from "../icons/pen-field.svg?react";
import UnplotIcon from "../icons/unplot.svg?react";
import DeleteIcon from "../icons/delete.svg?react";
import {useAppStore} from "../../../StateManager";

interface IXyiconViewProps {
	param1?: string;
	param2?: string;
}

export const XyiconViewV5 = (props: IXyiconViewProps) => {
	const appState = useAppStore((state) => state.appState);
	const {user, actions, organizationId, app} = appState;

	const [isCatalogOpen, setIsCatalogOpen] = useState<boolean>(false);
	const [isCatalogDocked, setIsCatalogDocked] = useState<boolean>(false);

	const canDelete = (selectedItems: Xyicon[]) => {
		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return selectedItems.every((item) => {
			const permission = actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);

			return permission >= Permission.Delete;
		});
	};

	const setCatalogOpen = (isOpen: boolean) => {
		setIsCatalogOpen(isOpen);
	};

	const setCatalogDocked = (isDocked: boolean) => {
		setIsCatalogDocked(isDocked);
	};

	const getLocalStorageKey = (organizationId: string) => {
		return `srv4-org-${organizationId}-spaceeditor-xyiconview-dockables-open-state`;
	};

	const unplotXyicons = (items: Xyicon[]) => {
		items.forEach((item) => item.unplot());
	};

	useEffect(() => {
		const loadStateFromLocalStorage = () => {
			if (organizationId) {
				const savedDockedState = app.transport.services.localStorage.get(getLocalStorageKey(organizationId)) as {
					isCatalogOpen: boolean;
					isCatalogDocked: boolean;
				};

				if (savedDockedState) {
					setIsCatalogDocked(savedDockedState.isCatalogDocked);
					setIsCatalogOpen(savedDockedState.isCatalogOpen);
				}
			}
		};

		loadStateFromLocalStorage();
	}, [app.transport.services.localStorage, organizationId]);

	useEffect(() => {
		const saveStateToLocalStorage = () => {
			if (organizationId) {
				const stateToSave = {
					isCatalogDocked: isCatalogDocked,
					isCatalogOpen: isCatalogOpen && isCatalogDocked,
				};

				app.transport.services.localStorage.set(getLocalStorageKey(organizationId), stateToSave);
			}
		};

		saveStateToLocalStorage();
	}, [isCatalogDocked, isCatalogOpen, organizationId, app.transport.services.localStorage]);

	{
		return (
			<ModuleViewV5
				feature={XyiconFeature.Xyicon}
				className="XyiconView"
				actionBar={[
					{
						id: "locate",
						title: "Locate Xyicon",
						label: "Locate",
						IconComponent: LocationPinIcon,
						visible: (selectedItems) => selectedItems.length === 1,
						enabled: (selectedItems) => selectedItems.every((item) => !(item as Xyicon).isUnplotted),
					},
					{
						id: "unplot",
						title: "Unplot Xyicon",
						label: "Unplot",
						IconComponent: UnplotIcon,
						visible: (selectedItems) => selectedItems.length === 1,
						onClick: unplotXyicons,
						enabled: (selectedItems) => selectedItems.every((x) => !x.isUnplotted),
					},
					{
						id: "fieldUpdate",
						title: "Update Field",
						label: "Update Field",
						IconComponent: PenFieldIcon,
						visible: () => true,
					},
					{
						id: "delete",
						title: "Delete Xyicon",
						label: "Delete",
						IconComponent: DeleteIcon,
						visible: (selectedItems: Xyicon[]) => canDelete(selectedItems),
					},
				]}
				isCatalogPanelOpen={isCatalogOpen}
				isCatalogPanelDocked={isCatalogDocked}
				setCatalogPanelOpen={setCatalogOpen}
				setCatalogPanelDocked={setCatalogDocked}
			/>
		);
	}
};

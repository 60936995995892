import {forwardRef, useImperativeHandle, useState} from "react";
import type {IMassInputV5Props} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {FieldInputsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {SingleLineInputV5} from "../SingleLineInputV5";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {useAppStore} from "../../../../../StateManager";
import {MassFieldInputStyled} from "./MassFieldInputStyled";
import {OptionSelectorInputStyled} from "./MassFieldInputV5";

type ISelectOptionId = "add" | "remove" | "overwrite";

interface ISelectOption {
	id: ISelectOptionId;
	label: string;
}

export const MassFieldMultiInputV5 = forwardRef((props: IMassInputV5Props, ref) => {
	const _options: ISelectOption[] = [
		{
			id: "add",
			label: "Add to List",
		},
		{
			id: "remove",
			label: "Remove from List",
		},
		{
			id: "overwrite",
			label: "Overwrite List",
		},
	];

	const appState = useAppStore((store) => store.appState);

	const [selectedOption, setSelectedOption] = useState<ISelectOptionId>("add");
	const [inputValue, setInputValue] = useState<string[]>([]);

	const onOptionChange = (option: ISelectOption) => {
		setSelectedOption(option.id);
	};

	const onInputChange = (value: any) => {
		setInputValue(value);
	};

	const getEditInput = () => {
		const {field} = props;

		let InputComponent = FieldInputsV5[field.dataType];

		InputComponent = InputComponent || SingleLineInputV5;

		return (
			<InputComponent
				value={inputValue}
				dataTypeSettings={field.dataTypeSettings}
				onChange={onInputChange}
				onInput={onInputChange}
				onFocusLossForceBlur={true}
				noButtons={true}
			/>
		);
	};

	useImperativeHandle(ref, () => ({
		async onApply() {
			const {field, items} = props;

			const itemsToUpdate = items.filter((item) => {
				const values = appState.actions.getOwnFieldValue(item, field.refId) ?? ([] as unknown as string[]);

				if (selectedOption === "add") {
					return inputValue.some((value) => !values.includes(value));
				} else if (selectedOption === "remove") {
					return inputValue.some((value) => values.includes(value));
				} else if (selectedOption === "overwrite") {
					return !ObjectUtils.compare(inputValue, values);
				}
			});
			const confirmed = await ConfirmWindowV5.open(`Are you sure you want to update ${itemsToUpdate.length} items?`, "Confirm Update", {
				ok: "Yes",
				cancel: "Cancel",
			});

			if (confirmed) {
				if (selectedOption === "add") {
					for (const item of itemsToUpdate) {
						const value = (appState.actions.getOwnFieldValue(item, field.refId) || []).slice() as string[];

						for (const v of inputValue) {
							if (!value.includes(v)) {
								value.push(v);
							}
						}

						appState.actions.updateFields([item], {
							[field.refId]: value,
						});
					}
				} else if (selectedOption === "remove") {
					for (const item of itemsToUpdate) {
						let value = (appState.actions.getOwnFieldValue(item, field.refId) || []).slice() as string[];

						for (const v of inputValue) {
							if (value.includes(v)) {
								value = value.filter((vv) => vv !== v);
							}
						}

						appState.actions.updateFields([item], {
							[field.refId]: value,
						});
					}
				} else if (selectedOption === "overwrite") {
					appState.actions.updateMassFields(itemsToUpdate, {
						[field.refId]: inputValue,
					});
				}

				props.onClose();
			}
		},
	}));

	{
		const {items, field} = props;
		const {actions} = appState;

		let allValuesMatch = true;

		let first = true;
		let firstValue;

		for (const item of items) {
			const hasValue = actions.hasOwnFieldValue(item, field.refId);

			if (!hasValue) {
				continue;
			}

			const value = actions.renderValue(item, field.refId);

			if (first) {
				firstValue = value;
				first = false;
			}
			if (value !== firstValue) {
				allValuesMatch = false;
			}
		}

		return (
			<MassFieldInputStyled className="MassFieldInput">
				<div className="container">
					<OptionSelectorInputStyled>
						<SelectInputV5
							options={_options}
							render={(option) => option.label}
							selected={_options.find((option) => option.id === selectedOption)}
							onChange={onOptionChange}
							onFocusLossForceBlur={true}
						/>
					</OptionSelectorInputStyled>
					{getEditInput()}
				</div>
			</MassFieldInputStyled>
		);
	}
});

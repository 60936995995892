import {inject, observer} from "mobx-react";
import * as React from "react";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {AppState} from "../../../../../data/state/AppState";
import {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import {XyiconFeature} from "../../../../../generated/api/base";
import {CreateUserGroupPanelStyled} from "../usergroups/CreateUserGroupPanelV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {ButtonV5} from "../../../button/ButtonV5";

interface ICreatePortfolioGroupPanelProps {
	onCancel: () => void;
	onCreated: (id: string) => void;
	creating: boolean;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePortfolioGroupPanelV5 extends React.PureComponent<ICreatePortfolioGroupPanelProps> {
	private _portfolioGroup = this.createNewPortfolioGroup();

	private createNewPortfolioGroup() {
		return new PortfolioGroup({
			portfolioGroupID: "",
			portfolios: [],
			name: "",
			lastModifiedAt: "",
			lastModifiedBy: "",
		});
	}

	private onCreateClicked = async () => {
		const data = {
			name: this._portfolioGroup.name,
			portfolioList: this._portfolioGroup.portfolioIds,
		};
		const models = await this.props.transport.services.feature.create(data, XyiconFeature.PortfolioGroup);

		this.props.onCreated(models?.[0]?.id);
		this._portfolioGroup = this.createNewPortfolioGroup();
		this.forceUpdate();
	};

	private isNameValid = (value: string) => {
		return this.props.appState.actions.isPortfolioGroupNameValid(value, this._portfolioGroup);
	};

	private onInput = (value: string) => {
		this._portfolioGroup.name = value;
	};

	public override render() {
		const portfolioGroup = this._portfolioGroup;
		const isValid = portfolioGroup.name && this.isNameValid(portfolioGroup.name);

		return (
			<CreateUserGroupPanelStyled>
				<ClickToEditInputV5
					value={portfolioGroup.name}
					onLiveChange={this.onInput}
					valueValidator={this.isNameValid}
					placeholder="Name Value"
					focused={this.props.creating}
				/>
				<br />
				<ButtonV5
					label="Confirm"
					title="Create"
					onClick={this.onCreateClicked}
					disabled={!isValid}
					className="primary"
				/>
			</CreateUserGroupPanelStyled>
		);
	}
}

import {Constants} from "../../Constants";
import type {PanCameraControls} from "../PanCameraControls";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import {PointerDetector} from "../../../../../../../utils/interaction/PointerDetector";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";

export class CameraScrollbars {
	private _enabled: boolean = false;
	private _panCameraControls: PanCameraControls;
	private _horizontal: HTMLElement = document.createElement("div");
	private _vertical: HTMLElement = document.createElement("div");

	// we save only one of the axis-value to these variables because only one bar can be grabbed at a time
	private _pageXYOnPointerStart: number = 0;
	private _cameraPosOnPointerStart: number = 0;
	private _pointerDetectorForHorizontal: PointerDetector = new PointerDetector({element: this._horizontal});
	private _pointerDetectorForVertical: PointerDetector = new PointerDetector({element: this._vertical});

	constructor(panCameraControls: PanCameraControls) {
		this._panCameraControls = panCameraControls;

		this._horizontal.classList.add("scrollbar");
		this._horizontal.classList.add("horizontal");
		this._vertical.classList.add("scrollbar");
		this._vertical.classList.add("vertical");
	}

	public init() {
		this.loadDataFromLocalStorage();
	}

	private loadDataFromLocalStorage() {
		const organizationId = this._spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const savedValue = this._spaceViewRenderer.transport.services.localStorage.get(this.getKeyLocalStorageForScrollbars(organizationId));

			if (savedValue) {
				this.enable();
			} else {
				this.disable();
			}
		}
	}

	private saveDataToLocalStorage() {
		const organizationId = this._spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			this._spaceViewRenderer.transport.services.localStorage.set(this.getKeyLocalStorageForScrollbars(organizationId), this._enabled);
		}
	}

	private getKeyLocalStorageForScrollbars(organizationId: string) {
		return `srv4-org-${organizationId}-spaceeditor-scrollbars`;
	}

	private get _spaceViewRenderer() {
		return this._panCameraControls.spaceViewRenderer;
	}

	private get _cameraTarget() {
		return this._panCameraControls.cameraTarget;
	}

	private get _normalizedCameraTarget() {
		const {x, y} = this._cameraTarget;

		return {
			x: MathUtils.getInterpolation(x.value, x.min, x.max),
			y: MathUtils.getInterpolation(y.value, y.min, y.max),
		};
	}

	private get _domElement() {
		return this._panCameraControls.domElement;
	}

	public enable() {
		if (!this._enabled) {
			this._domElement.appendChild(this._horizontal);
			this._domElement.appendChild(this._vertical);
			this._enabled = true;

			this._pointerDetectorForHorizontal.signals.down.add(this.onHorizontalPointerDown);
			this._pointerDetectorForHorizontal.signals.move.add(this.onHorizontalPointerMove);
			this._pointerDetectorForVertical.signals.down.add(this.onVerticalPointerDown);
			this._pointerDetectorForVertical.signals.move.add(this.onVerticalPointerMove);

			this.update();

			this.saveDataToLocalStorage();
		}
	}

	public disable() {
		if (this._enabled) {
			this._pointerDetectorForHorizontal.signals.down.remove(this.onHorizontalPointerDown);
			this._pointerDetectorForHorizontal.signals.move.remove(this.onHorizontalPointerMove);
			this._pointerDetectorForVertical.signals.down.remove(this.onVerticalPointerDown);
			this._pointerDetectorForVertical.signals.move.remove(this.onVerticalPointerMove);

			this._horizontal.remove();
			this._vertical.remove();
			this._enabled = false;

			this.saveDataToLocalStorage();
		}
	}

	private onHorizontalPointerDown = (pointer: Pointer) => {
		pointer.originalEvent.stopImmediatePropagation();
		this._pageXYOnPointerStart = pointer.pageX;
		this._cameraPosOnPointerStart = this._normalizedCameraTarget.x;
	};

	private onHorizontalPointerMove = (pointer: Pointer) => {
		pointer.originalEvent.stopImmediatePropagation();
		const deltaFromStartInPx = pointer.pageX - this._pageXYOnPointerStart;
		const trackSizeInPx = this.getTrackSizeInPx().x;

		const deltaFromStart = deltaFromStartInPx / trackSizeInPx;
		const normalizedCoord = MathUtils.clamp(this._cameraPosOnPointerStart + deltaFromStart, 0, 1);

		this._panCameraControls.moveCameraTo(
			this._cameraTarget.x.min + normalizedCoord * (this._cameraTarget.x.max - this._cameraTarget.x.min),
			this._cameraTarget.y.end,
			true,
			Constants.DURATIONS.DEFAULT_ANIMATION,
		);
	};

	private onVerticalPointerDown = (pointer: Pointer) => {
		pointer.originalEvent.stopImmediatePropagation();
		this._pageXYOnPointerStart = pointer.pageY;
		this._cameraPosOnPointerStart = this._normalizedCameraTarget.y;
	};

	private onVerticalPointerMove = (pointer: Pointer) => {
		pointer.originalEvent.stopImmediatePropagation();
		const deltaFromStartInPx = this._pageXYOnPointerStart - pointer.pageY;

		const trackSizeInPx = this.getTrackSizeInPx().y;

		const deltaFromStart = deltaFromStartInPx / trackSizeInPx;
		const normalizedCoord = MathUtils.clamp(this._cameraPosOnPointerStart + deltaFromStart, 0, 1);

		this._panCameraControls.moveCameraTo(
			this._cameraTarget.x.end,
			this._cameraTarget.y.min + normalizedCoord * (this._cameraTarget.y.max - this._cameraTarget.y.min),
			true,
			Constants.DURATIONS.DEFAULT_ANIMATION,
		);
	};

	private getTrackSizeInPx() {
		const horizontalBarWidth = this._horizontal.offsetWidth;
		const verticalBarHeight = this._vertical.offsetHeight;
		const fullWidth = this._domElement.offsetWidth;
		const fullHeight = this._domElement.offsetHeight;
		const trackWidth = fullWidth - horizontalBarWidth;
		const trackHeight = fullHeight - verticalBarHeight;

		return {
			x: trackWidth,
			y: trackHeight,
		};
	}

	public update() {
		if (this._enabled) {
			const horizontalBarWidth = this._horizontal.offsetWidth;
			const verticalBarHeight = this._vertical.offsetHeight;
			const trackSize = this.getTrackSizeInPx();
			const normalizedCameraTarget = this._normalizedCameraTarget;

			this._horizontal.style.transform = `translateX(${trackSize.x * normalizedCameraTarget.x + horizontalBarWidth / 2}px) translateX(-50%)`;
			this._vertical.style.transform = `translateY(${trackSize.y * (1 - normalizedCameraTarget.y) + verticalBarHeight / 2}px) translateY(-50%)`;
		}
	}

	public get enabled() {
		return this._enabled;
	}
}

import type {IModel} from "../../../../data/models/Model";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import type {IInputType} from "../../../5.0/details/datatypes/TextInputV5";
import type {IMassInputProps, IMassInputV5Props} from "../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import type {IBooleanFieldSettingsDefinition} from "../../../modules/settings/modules/field/datatypes/BooleanFieldSettings";
import type {IInputProps} from "./FieldInput";

export interface IInputPropsV5<T = any, V = any> {
	value: V;
	onInput?: (value: V) => void;
	onCancel?: () => void;
	onApply?: () => void;
	onChange?: (value: V) => void;
	onBlur?: () => void;
	onClick?: (e?: React.MouseEvent) => void;
	dataTypeSettings?: T;
	disabled?: boolean;
	updating?: boolean;
	height?: number;
	scrollHeight?: number;
	onFocusLossForceBlur?: boolean;
	noButtons?: boolean;
	inputType?: IInputType;
	field?: IFieldAdapter;
	focused?: boolean;
	inline?: boolean;
	expand?: boolean;
	defaultSettings?: IBooleanFieldSettingsDefinition;
	focusLossStarterEvent?: "up" | "down";
	item?: IModel;
	selectedItems?: IModel[];
	secondaryState?: boolean;
	caretPosition?: number;
	isSameWidth?: boolean;
	noBooleanLabel?: boolean;
	dropdownIcon?: boolean;
}

export const FieldInputs: {
	[dataType: number]: React.ComponentType<IInputProps>;
} = {};

export const FieldInputsV5: {
	[dataType: number]: React.FunctionComponent<IInputPropsV5>;
} = {};

export const FieldMassEditInputs: {
	[dataType: number]: React.ComponentType<IInputProps>;
} = {};

export const FieldMassEditInputsV5: {
	[dataType: number]: React.ComponentType<IInputPropsV5>;
} = {};

export const FieldInputLabels: {
	[dataType: number]: React.ComponentType<{
		value: any;
		dataTypeSettings?: any;
		onLabelStateChange?: (value: boolean) => void;
		divRef?: any;
		height?: number;
	}>;
} = {};

export const FieldInputLabelsV5: {
	[dataType: number]: React.FunctionComponent<{
		value: any;
		dataTypeSettings?: any;
		onLabelStateChange?: (value: boolean) => void;
		divRef?: any;
		height?: number;
	}>;
} = {};

export const FieldInlineInputs: {
	[dataType: number]: React.ComponentType<IInputProps>;
} = {};

export const FieldInlineInputsV5: {
	[dataType: number]: React.ComponentType<IInputProps>;
} = {};

export const MassInputs: {
	[dataType: number]: React.ComponentType<IMassInputProps>;
} = {};

export const MassInputsV5: {
	[dataType: number]: React.FunctionComponent<IMassInputV5Props>;
} = {};

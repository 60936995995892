import type {RefObject} from "react";
import {useState} from "react";
import styled from "styled-components";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled, SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";
import {XyiconFeature} from "../../../../generated/api/base";
import {useAppStore} from "../../../../StateManager";
import {CatalogItemPanelV5} from "../../abstract/view/catalog/CatalogItemPanelV5";
import {CreatePopupStyled} from "../portfolio/PortfolioCreatePopupV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {colorPalette} from "../../styles/colorPalette";
import {SingleLineInputStyled} from "../../details/datatypes/SingleLineInputV5";
import {SingleLineLabelStyled} from "../../details/datatypes/SingleLineLabelV5";
import {FieldV5} from "../../details/FieldV5";
import {CreatePopupFieldStyled} from "../../popup/CreatePopupField.styled";

interface ICatalogCreatePopupV5Props {
	onClose: () => void;
	parentRef: RefObject<HTMLDivElement>;
}

const CatalogCreatePopupStyled = styled(CreatePopupStyled)`
	${SelectInputStyled}, ${SingleLineInputStyled}>input, ${SingleLineLabelStyled} {
		color: ${colorPalette.gray.c950};
		background-color: ${colorPalette.white};
	}
`;

export type ShapeType = "Default Shape" | "Custom Shape";

const geometries: ShapeType[] = ["Default Shape", "Custom Shape"];

export const CatalogCreatePopupV5 = (props: ICatalogCreatePopupV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const [model, setModel] = useState<string>("");
	const [selectedType, setSelectedType] = useState<Type>(null);
	const [selectedGeometry, setSelectedGeometry] = useState<ShapeType>("Default Shape");
	const [createClicked, setCreateClicked] = useState<boolean>(false);

	const isModelNameValid = (model: string) => {
		if (!model) {
			return false;
		}

		return appState.actions.isModelValidForCatalog(model, null);
	};

	const types = appState.types[XyiconFeature.Xyicon];
	const isFormValid = selectedType && selectedType.id && isModelNameValid(model);

	return createClicked ? (
		<CatalogItemPanelV5
			onClose={props.onClose}
			shape={selectedGeometry}
			mode={"create"}
			model={model}
			type={selectedType}
		/>
	) : (
		<CatalogCreatePopupStyled
			label="Create Catalog"
			onClose={props.onClose}
			parentRef={props.parentRef.current}
			buttonProps={{
				onClick: () => setCreateClicked(true),
				disabled: !isFormValid,
				label: "Create",
			}}
			verticalAlignment={VerticalAlignment.bottomOuter}
			horizontalAlignment={HorizontalAlignment.right}
		>
			<CreatePopupFieldStyled>
				<FieldV5
					label="Type"
					className="focused"
				>
					<SelectInputV5
						options={types}
						render={(type) => (type ? type.name : "")}
						selected={selectedType}
						onChange={(type) => setSelectedType(type)}
						placeholder="Type"
						isSameWidth={true}
						dropdownFixedWidth="200px"
					/>
				</FieldV5>
				<FieldV5
					label="Model"
					className="focused"
				>
					<ClickToEditInputV5
						value={model}
						onLiveChange={(name) => setModel(name)}
						onChange={(name) => setModel(name)}
						valueValidator={isModelNameValid}
						placeholder="Catalog Model"
					/>
				</FieldV5>
				<FieldV5
					label="Geometry"
					className="focused"
				>
					<SelectInputV5
						options={geometries}
						selected={geometries.find((geometry) => geometry === selectedGeometry)}
						onChange={(geometry) => setSelectedGeometry(geometry)}
						fullWidth={true}
						isSameWidth={true}
					/>
				</FieldV5>
			</CreatePopupFieldStyled>
		</CatalogCreatePopupStyled>
	);
};

import * as React from "react";
import {MathUtils as THREEMath} from "three";
import {Constants} from "../../../space/spaceeditor/logic3d/Constants";
import type {IRGBObject} from "../../../../../utils/ColorUtils";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import type {Color, PointDouble} from "../../../../../generated/api/base";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {TextInput} from "../../../../widgets/input/text/TextInput";
import {HTMLUtils} from "../../../../../utils/HTML/HTMLUtils";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import type {IconLayer} from "./IconEditor";
import {IconImage} from "./IconImage";
import {IconText} from "./IconText";

export interface IEditableIconProps {
	isSVG: boolean;
	innerPart: string;
	imageAspectRatio: number;
	backgroundColor: Color;
	isIconColorChanged: boolean;
	iconColor: Color;
	iconColors: Color[];
	selectedChildIndex: number;
	replaceColor: boolean;
	borderRadius: number;
	scale: number;
	isFlippedX: boolean;
	isFlippedY: boolean;
	iconTranslate: PointDouble; // normalized to [0, 1]
	iconOrientation: number;

	text: string;
	textOrientation: number;
	textTranslate: PointDouble; // normalized to [0, 1]
	fontSize: number;
	fontColor: Color;
	fontFamily: string;
	isBold: boolean;
	isItalic: boolean;
	isUnderlined: boolean;
	horizontalAlignment: HorizontalAlignment;

	selectedLayer?: IconLayer;
	outline?: boolean;

	classNames?: string;
	onClick?: (innerPart: string) => void;

	onSelectedChildIndexChange?: (newIndex: number) => void;

	// below ones are for the IconEditor
	onIconTranslateChange?: (newTranslate: PointDouble) => void;
	onIconOrientationChange?: (newOrientation: number) => void;
	onTextTranslateChange?: (newTranslate: PointDouble) => void;
	onTextOrientationChange?: (newOrientation: number) => void;
	onTextChange?: (newText: string) => void;
	isTextBeingModified?: boolean;
	onIsTextBeingModifiedChange?: (value: boolean) => void;
	onTextDoubleClick?: () => void;
	svgRef?: React.RefObject<SVGSVGElement>;
	title?: string;
}

export class EditableIcon extends React.Component<IEditableIconProps> {
	private _svgRef: React.RefObject<SVGSVGElement>;
	private _borderColorThreshold = 180;

	constructor(props: IEditableIconProps) {
		super(props);
		this._svgRef = this.props.svgRef || React.createRef<SVGSVGElement>();
	}

	private onTextInputBlur = () => {
		this.props.onIsTextBeingModifiedChange?.(false);
	};

	private onClick = () => {
		if (this.props.onClick) {
			this.props.onClick(this.props.innerPart);
		}
	};

	private getBorderColor = () => {
		const defaultBorderColor = "#3495F0";

		const {r, g, b} = ColorUtils.hex2rgb(this.props.backgroundColor.hex, 1, "RGBObject") as IRGBObject;

		const {r: defR, g: defG, b: defB} = ColorUtils.hex2rgb(defaultBorderColor, 1, "RGBObject") as IRGBObject;

		if (Math.abs(r - defR) + Math.abs(g - defG) + Math.abs(b - defB) >= this._borderColorThreshold) {
			return defaultBorderColor;
		} else {
			return ColorUtils.rgb2hex(255 - r, 255 - g, 255 - b, "string", true) as string;
		}
	};

	private getAlignment = () => {
		const {horizontalAlignment} = this.props;

		if (horizontalAlignment === HorizontalAlignment.left) {
			return "left";
		}
		if (horizontalAlignment === HorizontalAlignment.center) {
			return "center";
		}
		return "right";
	};

	public override render() {
		const {
			textOrientation,
			textTranslate,
			isTextBeingModified,
			fontColor,
			fontFamily,
			fontSize,
			isBold,
			isItalic,
			isUnderlined,
			classNames,
			borderRadius,
			isIconColorChanged,
			innerPart,
			imageAspectRatio,
			iconColor,
			iconColors,
			replaceColor,
			isSVG,
			iconTranslate,
			iconOrientation,
			scale,
			isFlippedX,
			isFlippedY,
			onIconOrientationChange,
			onIconTranslateChange,
			selectedLayer,
			text,
			backgroundColor,
			onTextTranslateChange,
			onTextOrientationChange,
			onTextDoubleClick,
			outline,
			title,
			onTextChange,
			horizontalAlignment,
			selectedChildIndex,
			onSelectedChildIndexChange,
		} = this.props;
		const bgColorAsRGB = ColorUtils.hex2rgb(backgroundColor.hex, 1 - backgroundColor.transparency) as string;
		const borderColor = this.getBorderColor();

		let inputStyle: React.CSSProperties;

		if (isTextBeingModified) {
			const svgSize = HTMLUtils.getSize(this._svgRef.current);

			inputStyle = {
				position: "absolute",
				top: `${textTranslate.y * 100}%`,
				left: `${textTranslate.x * 100}%`,
				fontFamily: fontFamily,
				fontSize: fontSize,
				textAlign: this.getAlignment(),
				color: ColorUtils.hex2rgb(fontColor.hex, 1 - fontColor.transparency, "string") as string,
				fontWeight: isBold ? "bold" : "normal",
				fontStyle: isItalic ? "italic" : "normal",
				textDecoration: isUnderlined ? "underline" : "none",
				paddingBottom: `${(3 * fontSize) / 20}px`,
				transformOrigin: "center",
				transform: `translate(-50%, -50%) rotate(${THREEMath.radToDeg(textOrientation)}deg) scale(${svgSize.width / Constants.CATALOG_SVG_VIEWBOXSIZE})`,
				backgroundColor: "transparent",
			};
		}

		const isEditor = classNames.includes("IconEditor");

		const svg = (
			<svg
				xmlns={Constants.SVG_NAMESPACE}
				viewBox={`0 0 ${Constants.CATALOG_SVG_VIEWBOXSIZE} ${Constants.CATALOG_SVG_VIEWBOXSIZE}`}
				ref={this._svgRef}
			>
				<rect
					x={0}
					y={0}
					width={Constants.CATALOG_SVG_VIEWBOXSIZE}
					height={Constants.CATALOG_SVG_VIEWBOXSIZE}
					rx={borderRadius}
					ry={borderRadius}
					fill={bgColorAsRGB}
					stroke="none"
				/>
				<IconImage
					innerPart={innerPart}
					imageAspectRatio={imageAspectRatio}
					isIconColorChanged={isIconColorChanged}
					backgroundColor={backgroundColor}
					color={iconColor}
					colors={iconColors}
					selectedChildIndex={selectedChildIndex}
					onSelectedChildIndexChange={onSelectedChildIndexChange}
					replaceColor={replaceColor}
					isSVG={isSVG}
					viewBoxSize={Constants.CATALOG_SVG_VIEWBOXSIZE}
					translate={iconTranslate}
					orientation={iconOrientation}
					scale={scale}
					isFlippedX={isFlippedX}
					isFlippedY={isFlippedY}
					onTranslateChange={onIconTranslateChange}
					onOrientationChange={onIconOrientationChange}
					isSelected={selectedLayer === "Image"}
					parentRef={this._svgRef}
					borderColor={borderColor}
				/>
				{!isTextBeingModified && (
					<IconText
						x={textTranslate.x}
						y={textTranslate.y}
						viewBoxSize={Constants.CATALOG_SVG_VIEWBOXSIZE}
						orientation={textOrientation}
						text={text}
						onTranslateChange={onTextTranslateChange}
						onOrientationChange={onTextOrientationChange}
						isSelected={selectedLayer === "Text"}
						parentRef={this._svgRef}
						color={fontColor}
						fontFamily={fontFamily}
						fontSize={fontSize}
						isBold={isBold}
						isItalic={isItalic}
						isUnderlined={isUnderlined}
						onTextDoubleClick={onTextDoubleClick}
						borderColor={borderColor}
						horizontalAlignment={horizontalAlignment}
					/>
				)}
			</svg>
		);

		return (
			<div
				className={ReactUtils.cls(classNames, {outline: outline || isEditor})}
				onClick={this.onClick}
				title={title ? title : ""}
			>
				{isEditor ? <div className="svgContainer">{svg}</div> : svg}
				{isEditor && isTextBeingModified && (
					<div className="invisibleDiv">
						<TextInput
							onBlur={this.onTextInputBlur}
							style={inputStyle}
							onChange={onTextChange}
							value={text}
							autoFocus={true}
							isTextArea={true}
							borderColor={borderColor}
							viewBoxSize={Constants.CATALOG_SVG_VIEWBOXSIZE}
						/>
					</div>
				)}
			</div>
		);
	}
}

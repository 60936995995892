import {FieldDataTypes} from "../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../generated/api/base";

interface ISingleLineLabelProps {
	value: string;
	dataTypeSettings?: any;
}

export const UserLabelV5 = (props: ISingleLineLabelProps) => {
	const {value} = props;

	// Converts user id -> user.fullName
	const formatted = FieldDataTypes.map[FieldDataType.User].formatter(value);

	return formatted || "";
};

import {useEffect} from "react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {SupportedFontName} from "../../logic3d/managers/MSDF/TextGroupManager";
import {TextGroupManager} from "../../logic3d/managers/MSDF/TextGroupManager";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {VerticalAlignment, type TransformObj, HorizontalAlignment} from "../../../../../../utils/dom/DomUtils";
import type {ICaptionSettings, IFontStyleSettings} from "../../../../../../data/models/ViewUtils";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import {SizeChanger} from "../../../../abstract/common/sizechanger/SizeChanger";
import {XyiconFeature, type Color} from "../../../../../../generated/api/base";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {FontStyleContainer} from "./FontStyleContainer";

interface IFloatingCaptionConfiguratorProps {
	divRef: React.MutableRefObject<HTMLDivElement>;
	transform: TransformObj | null;
	captionLabel: string;
	fieldRefId: string;
	onCloseClick: () => void;
	spaceViewRenderer: SpaceViewRenderer;
	captionSettings: ICaptionSettings;
}

export function FloatingCaptionConfigurator(props: IFloatingCaptionConfiguratorProps) {
	const individualCaptionStyles = props.captionSettings.individualCaptionStyles;

	const fontStyleSettings: IFontStyleSettings = {
		isBold: individualCaptionStyles[props.fieldRefId]?.isBold ?? props.captionSettings.isBold,
		isItalic: individualCaptionStyles[props.fieldRefId]?.isItalic ?? props.captionSettings.isItalic,
		isUnderlined: individualCaptionStyles[props.fieldRefId]?.isUnderlined ?? props.captionSettings.isUnderlined,
	};
	const {captionSettings} = props;
	const {eyeDropperProps} = props.spaceViewRenderer;

	const createIndividualStylesIfDoesntExist = () => {
		if (!individualCaptionStyles[props.fieldRefId]) {
			individualCaptionStyles[props.fieldRefId] = {};
		}
	};

	const redrawCaptions = () => {
		props.spaceViewRenderer.actions.updateSpaceEditorCaptions(XyiconFeature.Boundary);
	};

	useEffect(() => {
		const onBlur = (event: MouseEvent) => {
			if (!HTMLUtils.isDescendant(props.spaceViewRenderer.transport.appState.app.modalContainer, event.target as HTMLElement)) {
				props.onCloseClick();
			}
		};

		FocusLoss.listen(props.divRef.current, onBlur);

		return () => {
			FocusLoss.stopListen(props.divRef.current, onBlur);
		};
	}, [props]);

	return (
		<div
			ref={props.divRef}
			style={{transform: props.transform?.translate}}
			className={ReactUtils.cls("FloatingCaptionConfigurator vbox", {invisible: !props.transform})}
		>
			<div className="title hbox">
				<div>
					Change caption settings for <strong>{props.captionLabel}</strong>
				</div>
				<IconButton
					icon="close"
					className="close"
					onClick={props.onCloseClick}
				/>
			</div>
			<div className="hbox alignCenter fontSettings">
				<FontStyleContainer
					fontStyleSettings={fontStyleSettings}
					onIsBoldChange={(newValue: boolean) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].isBold = newValue;
						redrawCaptions();
					}}
					onIsItalicChange={(newValue: boolean) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].isItalic = newValue;
						redrawCaptions();
					}}
					onIsUnderlinedChange={(newValue: boolean) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].isUnderlined = newValue;
						redrawCaptions();
					}}
				/>
				<SizeChanger
					disabled={false}
					value={individualCaptionStyles[props.fieldRefId]?.fontSize ?? captionSettings.fontSize}
					onChange={(newValue: number) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].fontSize = newValue;
						redrawCaptions();
					}}
				/>
				<SelectInput
					selected={individualCaptionStyles[props.fieldRefId]?.fontFamily ?? captionSettings.fontFamily}
					onChange={(newValue: SupportedFontName) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].fontFamily = newValue;
						redrawCaptions();
					}}
					options={TextGroupManager.supportedFontNames}
				/>
				<ColorSelector
					title="Font Color"
					icon="text-color"
					color={individualCaptionStyles[props.fieldRefId]?.fontColor ?? captionSettings.fontColor}
					onColorChange={(newColor: Color) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].fontColor = newColor;
						redrawCaptions();
					}}
					eyeDropperProps={eyeDropperProps}
					verticalAlignment={VerticalAlignment.topOuter}
				/>
				<ColorSelector
					title="Fill Color"
					icon="paint-bucket"
					color={individualCaptionStyles[props.fieldRefId]?.backgroundColor ?? captionSettings.backgroundColor}
					onColorChange={(newColor: Color) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].backgroundColor = newColor;
						redrawCaptions();
					}}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.center}
					verticalAlignment={VerticalAlignment.topOuter}
				/>
			</div>
		</div>
	);
}

import {Formatter} from "../../../utils/format/Formatter";
import type {DistanceUnitName, ExtendedDistanceUnitName, IDistanceUnit} from "../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import {SelectInputV5} from "../input/select/SelectInputV5";

interface IUnitSelectorProps {
	readonly unit: DistanceUnitName | ExtendedDistanceUnitName;
	readonly addFeetAndInches?: boolean;
	readonly onChange: (unitName: ExtendedDistanceUnitName) => void;
	readonly className?: string;
}

export const UnitSelectorV5 = (props: IUnitSelectorProps) => {
	const addFeetAndInches = !!props.addFeetAndInches;
	const units: IDistanceUnit[] = [];

	for (const key in Constants.DISTANCE_UNITS) {
		const unit = Constants.DISTANCE_UNITS[key as DistanceUnitName] as IDistanceUnit;
		const typedKey = key as ExtendedDistanceUnitName;

		if (typedKey !== "foot&inch" || addFeetAndInches) {
			units.push(unit);
		}
	}

	return (
		<SelectInputV5
			sort={false}
			className={props.className}
			options={units}
			selected={Constants.DISTANCE_UNITS[props.unit]}
			onChange={(unit: IDistanceUnit) => props.onChange(unit.name)}
			render={(item: IDistanceUnit) => Formatter.capitalize(item.plural)}
		/>
	);
};

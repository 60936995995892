import React, {useEffect, useRef, useState} from "react";
import {Observer} from "mobx-react";
import styled from "styled-components";
import {useAppStore} from "../../../../../StateManager";
import type {IHeader} from "../../../../widgets/table/SimpleTable";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {IModel} from "../../../../../data/models/Model";
import type {Type} from "../../../../../data/models/Type";
import type {Field} from "../../../../../data/models/field/Field";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {SimpleTableV5} from "../../../abstract/table/SimpleTableV5";
import {SortDirection} from "../../../../../data/models/ViewUtils";
import {EmptyListViewV5} from "../../../details/EmptyListViewV5";
import {PopupUtilsV5} from "../../../popup/PopupUtilsV5";
import {WarningWindowV5} from "../../../popup/WarningWindowV5";
import {colorPalette} from "../../../styles/colorPalette";

interface ISettingTableTabProps<T> {
	headers: IHeader[];
	feature: XyiconFeature;
	typesFeature: XyiconFeature;
	kind: "types" | "fields";
	getFields: (item: T, index?: number) => (string | number)[];
	right: (selected: T[]) => React.ReactNode;
	create: (onCancel: () => void, onCreated: (id: string) => void) => React.ReactNode;
	emptyListText?: string;
}

export const SettingTableTabV5 = <T extends IModel = Type | Field>(props: ISettingTableTabProps<T>) => {
	const {headers, typesFeature, feature, kind, emptyListText, getFields, create, right} = props;
	const appState = useAppStore((state) => state.appState);
	const modulesTypeAndFieldSearchString = useAppStore((state) => state.modulesTypeAndFieldSearchString);
	const modulesTypeAndFieldCreating = useAppStore((state) => state.modulesTypeAndFieldCreating);
	const modulesCreatingType = useAppStore((state) => state.modulesCreatingType);
	const setModulesTypeAndFieldCreating = useAppStore((state) => state.setModulesTypeAndFieldCreating);
	const {
		app: {transport},
	} = appState;

	const featureRef = useRef<XyiconFeature>(feature);

	// This is for actually checking whether the component is currently mounted
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [didMount, setDidMount] = useState<boolean>(false); // this is for opacity animation

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const getTypesFeature = (props: ISettingTableTabProps<T>) => {
		return props.kind === "types" ? props.typesFeature : props.feature;
	};

	const onDeleteClicked = async () => {
		const count = selectedIds.length;

		if (isDeleteAllowed(selectedIds)) {
			const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(null, count);

			if (confirmed) {
				await transport.services.typefield.remove(kind, selectedIds, getTypesFeature(props));
				setSelectedIds([]);
			}
		} else {
			await WarningWindowV5.open("You can't delete this type because it's currently being used.");
		}
	};

	const initList = () => {
		const features = getListFeatures();

		const promises: Promise<IModel[]>[] = [];

		if (appState.portfolioId) {
			for (const feature of features) {
				promises.push(transport.services.feature.refreshList(feature));
			}
		}

		return Promise.all(promises);
	};

	const isDeleteAllowed = (ids: string[]) => {
		if (kind === "types") {
			const features = getListFeatures();

			for (const feature of features) {
				const list = appState.actions.getList(feature);

				for (const item of list) {
					if (item.typeId && ids.includes(item.typeId)) {
						return false;
					}
				}
			}
		}
		return true;
	};

	const getListFeatures = () => {
		const features = [typesFeature];

		if (typesFeature === XyiconFeature.Xyicon) {
			features.push(XyiconFeature.XyiconCatalog); // Catalog items also use type feature
		}

		return features;
	};

	const onCancelCreate = () => {
		setModulesTypeAndFieldCreating(false, "");
	};

	const onCreated = (createdId: string) => {
		setModulesTypeAndFieldCreating(false, "");
		setSelectedIds([createdId]);
		// TODO scrollto there
	};

	const onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				setModulesTypeAndFieldCreating(false, "");
				break;
			case KeyboardListener.KEY_DELETE:
				onDeleteClicked();
				break;
		}
	};

	useEffect(() => {
		KeyboardListener.getInstance().signals.up.add(onKeyUp);
		setSelectedIds([]);

		window.setTimeout(() => {
			setDidMount(true);
		}, 0);

		const handleInitList = async () => {
			await initList();
		};

		handleInitList();
		setIsLoading(false);

		return () => {
			setDidMount(false);
			KeyboardListener.getInstance().signals.up.remove(onKeyUp);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (featureRef.current !== feature) {
			setSelectedIds([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIds]);

	const data = kind === "fields" ? (appState.fields[feature] as unknown as T[]) : (appState.types[getTypesFeature(props)] as unknown as T[]);

	const selected = selectedIds.map((id) => data.find((item) => item.id === id)).filter((item) => !!item);

	return (
		<Observer>
			{() => {
				return (
					<SettingTableTabContainerStyled className={ReactUtils.cls("SettingsTableTab", {loaded: didMount})}>
						<SettingTableTabCreatePanelStyled className={ReactUtils.cls("createPanel", {open: modulesCreatingType === "fields"})}>
							{modulesTypeAndFieldCreating && create(onCancelCreate, onCreated)}
						</SettingTableTabCreatePanelStyled>
						<TableAndDetailsContainerStyled>
							<SettingTableStyled>
								{data.length > 0 ? (
									<SimpleTableV5
										data={data}
										headers={headers}
										getFields={getFields}
										selected={selected}
										onSelect={(selected) => {
											setSelectedIds(selected.map((item) => item.id));
										}}
										defaultSort={{
											column: "name",
											direction: SortDirection.ASC,
										}}
										tableSearchQuery={modulesTypeAndFieldSearchString}
									/>
								) : (
									<EmptyListViewV5 text={emptyListText} />
								)}
							</SettingTableStyled>
							<SettingTableTapSidePanelStyled>{right(selected)}</SettingTableTapSidePanelStyled>
						</TableAndDetailsContainerStyled>
					</SettingTableTabContainerStyled>
				);
			}}
		</Observer>
	);
};

const SettingTableTabContainerStyled = styled.div`
	height: 100%;
`;

const SettingTableTabCreatePanelStyled = styled.div`
	position: absolute;
	z-index: 9;
	background: ${colorPalette.white};
	right: 0;
	top: 0;
	width: 415px;
	height: 100%;
	padding: 16px;
	transform: translateX(100%);
	transition: ease-in-out 0.2s transform;
	&.open {
		transform: translateX(0%);
	}
`;

const SettingTableTapSidePanelStyled = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	border-left: 1px solid #c8c8c8;
	height: 100%;
	padding: 16px;
	box-sizing: border-box;
	width: 416px;
	overflow: auto;
	background-color: #ffffff;
	max-height: calc(100% - 64px);
`;

const TableAndDetailsContainerStyled = styled.div`
	display: flex;
`;

const SettingTableStyled = styled.div`
	display: flex;
	width: 100%;
	max-width: calc(100% - 416px);
	height: 100%;
`;

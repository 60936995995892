import * as React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "../../../../widgets/button/Button";
import {IconButton} from "../../../../widgets/button/IconButton";
import {Field} from "../../../../widgets/form/field/Field";
import {UserGroup} from "../../../../../data/models/UserGroup";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature, Permission} from "../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import {UserGroupUsers} from "./UserGroupUsers";

interface ICreateUserGroupPanelProps {
	onCancel: () => void;
	onCreated: (id: string) => void;
	creating: boolean;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class CreateUserGroupPanel extends React.PureComponent<ICreateUserGroupPanelProps> {
	private createNewUserGroup = () => {
		return new UserGroup({
			userGroupID: "",
			userIDList: [],
			name: "",
			lastModifiedAt: "",
			lastModifiedBy: "",
		});
	};

	private _userGroup = this.createNewUserGroup();

	private onCreateClicked = async () => {
		const data = {
			name: this._userGroup.name,
			userIDList: this._userGroup.userIds,
			shareViewPermission: Permission.Delete,
			canPinToNavigation: true,
		};
		const models = await this.props.transport.services.feature.create(data, XyiconFeature.UserGroup);

		this.props.onCreated(models?.[0]?.id);
		this._userGroup = this.createNewUserGroup();
		this.forceUpdate();
	};

	private onInput = (value: string) => {
		this._userGroup.name = value;
	};

	private isNameValid = (name: string) => {
		const userGroup = this._userGroup;

		return this.props.appState.actions.isUserGroupNameValid(name, userGroup);
	};

	private onCancel = () => {
		this._userGroup.name = "";
		this.props.onCancel();
	};

	public override render() {
		const userGroup = this._userGroup;
		const isValid = !!userGroup.name && this.isNameValid(userGroup.name);

		return (
			<div className="SidePanel CreateUserGroupPanel">
				<div className="heading hbox createBox">
					<h4>Create New User Group</h4>
					<IconButton
						className="close"
						icon="close"
						onClick={this.onCancel}
					/>
				</div>
				<div className="sidePanelButtons hbox">
					<Button
						label="Cancel"
						onClick={this.onCancel}
					/>
					<Button
						label="Create"
						onClick={this.onCreateClicked}
						disabled={!isValid}
						className="primary"
					/>
				</div>
				<div className="container">
					<Field label="Group Name">
						<ClickToEditInput
							value={userGroup.name}
							onLiveChange={this.onInput}
							valueValidator={this.isNameValid}
							noButtons={true}
							focused={this.props.creating}
						/>
					</Field>
				</div>
				<UserGroupUsers userGroup={userGroup} />
			</div>
		);
	}
}

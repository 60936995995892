import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Lambda} from "mobx";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import type {BeforeCapture, DragStart, DragUpdate, DropResult, ResponderProvided} from "@hello-pangea/dnd";
import {Draggable, Droppable, DragDropContext} from "@hello-pangea/dnd";
import styled from "styled-components";
import type {Link} from "../../../../../data/models/Link";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {Catalog} from "../../../../../data/models/Catalog";
import {PortLayoutType} from "../../../../modules/abstract/sidepanel/tabs/details/PortLayoutType";
import type {App} from "../../../../../App";
import type {AppState} from "../../../../../data/state/AppState";
import type {PortDataDto, PortTemplateDto, XyiconLinkDetail} from "../../../../../generated/api/base";
import {LinkType, XyiconFeature} from "../../../../../generated/api/base";
import type {IModel} from "../../../../../data/models/Model";
import type {AppActions} from "../../../../../data/state/AppActions";
import {DRAGGABLE_ID_SEPARATOR, FULL_LIST, Port} from "../../../../modules/catalog/port/Port";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {ButtonV5} from "../../../button/ButtonV5";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {PopupUtilsV5} from "../../../popup/PopupUtilsV5";
import PenIcon from "../../../icons/pen-with-line.svg?react";
import {Grabbable, VerticalFlexStyle} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {LinkBreakersV5} from "./LinkBreakersV5";
import {PortComponentLinkV5} from "./PortComponentLinkV5";
import {PortComponentV5} from "./PortComponentV5";
import {CrossPortfolioXyiconV5} from "./CrossPortfolioXyiconV5";

const orphanedDroppableId = "orphaned";

// we modify the link by deleting it and creating a new one. We wait this many ms after the deletion,
// because there might be a follow-up create request coming as well,
// causing a little flash / disappearance of port-links for a split-second
const waitAmountBeforeUIUpdate = 150;

export interface IPortMap {
	[portId: string]: {xyiconId: string; link: Link}[];
}

interface IPortsProps {
	item: Catalog | Xyicon;
	layout?: PortLayoutType;
	onEditPortTemplateClick?: (() => void) | null;
	onLabelChange?: (newValue: string, portId: string) => Promise<void>;
	app?: App;
	appState?: AppState;
}

interface IPortsState {
	grabbedXyiconId: string;
	grabbedItemDraggableId: string;
	linkMovingIsInProgress: boolean;
}

@inject("app")
@inject("appState")
@observer
export class PortsV5 extends React.Component<IPortsProps, IPortsState> {
	private _updateTimeoutId: number = -1;
	@observable
	private _portMap: {labelOverrides: PortDataDto[]; portMap: IPortMap} = {labelOverrides: [], portMap: {}};
	@observable
	private _orphanedList: Link[] = [];
	private _activeRequests: number = 0;
	private _isBreakLinksPopupWindowOpen = false;
	private _disposer: Lambda;

	constructor(props: IPortsProps) {
		super(props);
		makeObservable(this);
		this.state = {
			grabbedXyiconId: "",
			grabbedItemDraggableId: "",
			linkMovingIsInProgress: false,
		};
	}

	public static defaultProps: Partial<IPortsProps> = {
		layout: PortLayoutType.Icon,
	};

	private onPortTemplateChange = () => {
		this._orphanedList = this.getOrphanedXyiconLinks(this.props);
	};

	private getOrphanedXyiconLinks(props: IPortsProps): Link[] {
		const {item, appState} = props;

		if (item.ownFeature !== XyiconFeature.Xyicon) {
			return [];
		} else {
			return appState.actions.getList<Link>(XyiconFeature.Link).filter((link: Link) => {
				if (link.fromType === LinkType.Xyicon && link.toType === LinkType.Xyicon && !link.isEmbedded) {
					if (!!(item as Xyicon).ports.length && (item.id === link.fromObjectId || item.id === link.toObjectId)) {
						const xyicon = item as Xyicon;

						if (xyicon.id === link.fromObjectId) {
							return !!link.fromPortId && !this.isPortIdInPorts(link.fromPortId, xyicon.ports);
						} else if (xyicon.id === link.toObjectId) {
							return !!link.toPortId && !this.isPortIdInPorts(link.toPortId, xyicon.ports);
						}
					}
				}

				return false;
			});
		}
	}

	private static getPortMapAndLabelOverrides(feature: XyiconFeature, item: IModel, actions: AppActions) {
		const portMap: IPortMap = {};
		const labelOverrides: PortDataDto[] = [];

		if (feature === XyiconFeature.Xyicon) {
			labelOverrides.push(...(item as Xyicon).portData);

			const itemId = item.id;
			const xyiconLinks = actions
				.getList<Link>(XyiconFeature.Link)
				.filter(
					(link: Link) =>
						((itemId === link.toObjectId && link.toPortId) || (itemId === link.fromObjectId && link.fromPortId)) &&
						link.fromType === LinkType.Xyicon &&
						link.toType === LinkType.Xyicon &&
						actions.getFeatureItemById<Xyicon>(link.fromObjectId, XyiconFeature.Xyicon) &&
						actions.getFeatureItemById<Xyicon>(link.toObjectId, XyiconFeature.Xyicon),
				);

			for (const xyiconLink of xyiconLinks) {
				let directionA: "to" | "from" = "to";
				let directionB: "to" | "from" = "from";

				if (itemId === xyiconLink.fromObjectId) {
					[directionA, directionB] = [directionB, directionA];
				}

				const recordsMaybe = portMap[xyiconLink[`${directionA}PortId`]];

				if (recordsMaybe) {
					recordsMaybe.push({
						xyiconId: xyiconLink[`${directionB}ObjectId`],
						link: xyiconLink,
					});
				} else {
					portMap[xyiconLink[`${directionA}PortId`]] = [
						{
							xyiconId: xyiconLink[`${directionB}ObjectId`],
							link: xyiconLink,
						},
					];
				}
			}

			const crossPortfolioXyiconLinks = actions.getCrossPortfolioLinksXyiconXyicon(itemId);

			for (const linkObject of crossPortfolioXyiconLinks) {
				if (linkObject.onePortId) {
					const recordsMaybe = portMap[linkObject.onePortId];

					if (recordsMaybe) {
						recordsMaybe.push({
							xyiconId: linkObject.crossPortfolioXyiconId,
							link: linkObject.link,
						});
					} else {
						portMap[linkObject.onePortId] = [
							{
								xyiconId: linkObject.crossPortfolioXyiconId,
								link: linkObject.link,
							},
						];
					}
				}
			}
		}

		return {
			portMap,
			labelOverrides,
		};
	}

	private async breakLinks(links: Link[]) {
		if (!this._isBreakLinksPopupWindowOpen) {
			const count = links.length;

			this._isBreakLinksPopupWindowOpen = true;
			const confirmed = await PopupUtilsV5.getBreakLinksConfirmationPopupV5(count);

			this._isBreakLinksPopupWindowOpen = false;

			if (confirmed) {
				runInAction(async () => {
					await LinkBreakersV5.breakLinks(
						this.props.appState.app.spaceViewRenderer.transport,
						links.map((l) => l.id),
					);
					this.updateLocalLists(this.props);
				});
			}
		}
	}

	private isPortIdInPorts(id: string, ports: PortTemplateDto[]): boolean {
		return !!Port.getPortById(ports, id);
	}

	private movePortComponentLink = (dragUpdate: DragUpdate | DropResult) => {
		const {source, destination, draggableId} = dragUpdate;
		const {item, app, appState} = this.props;
		const {linkManager} = app.spaceViewRenderer.toolManager;
		const linkId = draggableId.split(DRAGGABLE_ID_SEPARATOR)[0];
		const xyiconId = draggableId.split(DRAGGABLE_ID_SEPARATOR)[1];
		const existingLinkMaybe = appState.actions.getFeatureItemById<Link>(linkId, XyiconFeature.Link);
		const {portMap} = PortsV5.getPortMapAndLabelOverrides(item.ownFeature, item, appState.actions);
		const sourceDroppableId = source.droppableId.split(FULL_LIST)[0];

		if (existingLinkMaybe && destination) {
			if (destination.droppableId !== orphanedDroppableId && source.droppableId === orphanedDroppableId) {
				this.setState({
					linkMovingIsInProgress: true,
				});

				// Handle link moving in the virtual list
				const destinationPort = this._portMap.portMap[destination.droppableId] || [];

				this._orphanedList = ArrayUtils.remove(
					this._orphanedList,
					this._orphanedList.find((ol) => ol.fromObjectId === draggableId || ol.toObjectId === draggableId),
				);
				this._portMap.portMap[destination.droppableId] = ArrayUtils.add(destinationPort, {xyiconId, link: existingLinkMaybe});

				runInAction(async () => {
					const promises: Promise<any>[] = [];

					promises.push(LinkBreakersV5.breakLinks(app.spaceViewRenderer.transport, [existingLinkMaybe.id]));

					const createData: XyiconLinkDetail[] = [
						{
							fromXyiconID: item.id,
							toXyiconID: xyiconId,
							fromPortID: destination.droppableId,
							toPortID: (existingLinkMaybe?.fromObjectId === xyiconId ? existingLinkMaybe?.fromPortId : existingLinkMaybe?.toPortId) || null,
							isEmbedded: item.ownFeature === XyiconFeature.Xyicon ? item.isEmbedded : false,
						},
					];

					promises.push(
						linkManager.sendCreateRequest({
							fromPortfolioID: this.props.appState.portfolioId,
							toPortfolioID: this.props.appState.portfolioId,
							xyiconLinkDetails: createData,
						}),
					);

					await Promise.all(promises);

					this.setState({
						linkMovingIsInProgress: false,
					});
				});
			} else if (sourceDroppableId !== destination.droppableId && !portMap[destination?.droppableId]?.find((o) => o.xyiconId === xyiconId)) {
				this.setState({
					linkMovingIsInProgress: true,
				});

				this._activeRequests++;

				// Handle link moving in the virtual list
				const sourcePort = this._portMap.portMap[sourceDroppableId];
				const destinationPort = this._portMap.portMap[destination.droppableId] || [];

				this._portMap.portMap[sourceDroppableId] = sourcePort.filter((o) => o.link.id !== existingLinkMaybe?.id);
				this._portMap.portMap[destination.droppableId] = [...destinationPort, {xyiconId, link: existingLinkMaybe}];

				if (this._portMap.portMap[sourceDroppableId].length === 0) {
					delete this._portMap.portMap[sourceDroppableId];
				}

				// Handle link moving in the backend
				runInAction(async () => {
					const promises: Promise<any>[] = [];

					promises.push(LinkBreakersV5.breakLinks(app.spaceViewRenderer.transport, [existingLinkMaybe.id]));

					const createData: XyiconLinkDetail[] = [
						{
							fromXyiconID: item.id,
							toXyiconID: xyiconId,
							fromPortID: destination.droppableId,
							toPortID: (existingLinkMaybe?.fromObjectId === xyiconId ? existingLinkMaybe?.fromPortId : existingLinkMaybe?.toPortId) || null,
							isEmbedded: item.ownFeature === XyiconFeature.Xyicon ? item.isEmbedded : false,
						},
					];

					promises.push(
						linkManager.sendCreateRequest({
							fromPortfolioID: this.props.appState.portfolioId,
							toPortfolioID: this.props.appState.portfolioId,
							xyiconLinkDetails: createData,
						}),
					);

					await Promise.all(promises);
				});
			}
		}
	};

	private onBeforeCapture = (initial: BeforeCapture) => {
		//Please do not delete it, this is for debugging purposes.
		//console.log(initial, `onBeforeCapture, draggableId: ${initial.draggableId}`)
		const xyiconId = initial.draggableId.split(DRAGGABLE_ID_SEPARATOR)[1];

		this.setState({
			grabbedXyiconId: xyiconId,
			grabbedItemDraggableId: initial.draggableId,
			linkMovingIsInProgress: true,
		});
	};

	private onDragStart = (initial: DragStart) => {
		const {source, draggableId} = initial;
		//Please do not delete it, this is for debugging purposes.
		//console.log(source, `onDragStart, source: ${source.droppableId}, draggableId: ${draggableId}`)
	};

	private onDragUpdate = (initial: DragUpdate, provided: ResponderProvided) => {
		//Please do not delete it, this is for debugging purposes.
		//console.log(initial, `onDragUpdate, source: ${initial.source.droppableId} | ${initial.source.index}, destination: ${initial.destination?.droppableId} | ${initial.destination?.index}, draggableId: ${initial.draggableId}`)
	};

	private onDragEnd = (result: DropResult) => {
		//Please do not delete it, this is for debugging purposes.
		//console.log(`onDragEnd, source: ${result.source?.droppableId} | ${result.source?.index}, destination: ${result.destination?.droppableId} | ${result.destination?.index}, draggableId: ${result?.draggableId}`)

		this.movePortComponentLink(result);

		this.setState({
			grabbedXyiconId: "",
			grabbedItemDraggableId: "",
		});
	};

	private updateLocalLists(props: IPortsProps) {
		this._orphanedList = this.getOrphanedXyiconLinks(props);
		this._portMap = PortsV5.getPortMapAndLabelOverrides(props.item.ownFeature, props.item, props.appState.actions);

		this._disposer?.();
		if (props.item.ownFeature === XyiconFeature.Xyicon) {
			this._disposer = reaction(() => JSON.stringify((props.item as Xyicon).catalog.portTemplate), this.onPortTemplateChange);
		}
	}

	private onLinksUpdated = () => {
		clearTimeout(this._updateTimeoutId);
		this._updateTimeoutId = window.setTimeout(() => {
			this.updateLocalLists(this.props);
		}, waitAmountBeforeUIUpdate);
	};

	private onLabelChange = (newValue: string, portId: string) => {
		const labelOverrideMaybe = this._portMap.labelOverrides.find((l) => l.id === portId);

		if (labelOverrideMaybe) {
			if (labelOverrideMaybe.label === newValue) {
				// No change, don't call BE API for change
				return;
			} else {
				labelOverrideMaybe.label = newValue;
			}
		} else {
			this._portMap.labelOverrides.push({id: portId, label: newValue});
		}

		return this.props.onLabelChange?.(newValue, portId);
	};

	public override componentDidMount(): void {
		this.updateLocalLists(this.props);
		this.props.app.transport.signalR.listener.signals.linksUpdated.add(this.onLinksUpdated);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: IPortsProps) {
		this.updateLocalLists(nextProps);
	}

	public override componentWillUnmount(): void {
		this._disposer?.();
		this.props.app.transport.signalR.listener.signals.linksUpdated.remove(this.onLinksUpdated);
	}

	public override render() {
		const {item, appState} = this.props;
		const portTemplate = item.ownFeature === XyiconFeature.XyiconCatalog ? item.portTemplate : item.catalog.portTemplate;
		const lastChildHasChild = Port.lastChildHasChild(portTemplate);
		const {portMap, labelOverrides} = this._portMap;
		const crossPortfolioLinks = this.props.appState.actions.getCrossPortfolioLinksXyiconXyicon(this.props.item.id);
		// After we delete the orphaned links, it takes a couple ms to receive the signalr events
		// So we need to filter out the non-existent (deleted) crossportfolio orphaned links instead,
		// to prevent runtime errors
		const orphanedLinks = this._orphanedList.filter((o) => {
			if (o.fromPortfolioId === o.toPortfolioId) {
				return true;
			} // crossportfolio links
			else {
				const crossPortfolioLinkMaybe = crossPortfolioLinks.find((cpl) => cpl.link.id === o.id);

				return !!crossPortfolioLinkMaybe;
			}
		});

		return (
			<PortContainerStyled className={ReactUtils.cls("PortContainer", {dndActive: this.state.grabbedXyiconId})}>
				{this.props.onEditPortTemplateClick && (
					<ButtonV5
						className="editPortTemplateButton"
						type="secondary"
						IconComponent={PenIcon}
						label="Edit"
						title="Edit Port Template"
						onClick={this.props.onEditPortTemplateClick}
						style={{alignSelf: "baseLine"}}
					/>
				)}
				<DragDropContext
					onBeforeCapture={this.onBeforeCapture}
					onDragStart={this.onDragStart}
					onDragUpdate={this.onDragUpdate}
					onDragEnd={this.onDragEnd}
				>
					{!!orphanedLinks.length && (
						<div className="orphanedLinks">
							<h3>{`Orphaned Xyicon Count: ${orphanedLinks.length}`}</h3>
							<p>
								Due to the port template change or a xyicon model update, the following xyicons are no longer linked to a port. To re-link, drag the
								xyicons to the desired port.
							</p>
							<Droppable
								droppableId={orphanedDroppableId}
								direction="horizontal"
								isDropDisabled={true}
							>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										className="hbox orphaned flex_1"
									>
										{orphanedLinks.map((ox: Link, index: number) => {
											const xyiconId = ox.fromObjectId === item.id ? ox.toObjectId : ox.fromObjectId;
											const xyicon = appState.actions.getFeatureItemById<Xyicon>(xyiconId, XyiconFeature.Xyicon);
											const portId = -1; // orphaned, therefore it's not attached to a part, so as a convention, we use -1
											const dragId = `${ox.id}${DRAGGABLE_ID_SEPARATOR}${xyiconId}${DRAGGABLE_ID_SEPARATOR}${portId}`;

											if (xyicon) {
												return (
													<Draggable
														draggableId={dragId}
														key={dragId}
														index={index}
													>
														{(provided, snapshot) => (
															<div
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																className={ReactUtils.cls("link", {dragging: snapshot.isDragging})}
															>
																<PortComponentLinkV5
																	xyicon={xyicon}
																	layout={this.props.layout}
																	isDraggingActive={false}
																	link={ox}
																/>
															</div>
														)}
													</Draggable>
												);
											} else {
												const isCrossPortfolioLink = ox.fromType === LinkType.Xyicon && ox.toType === LinkType.Xyicon;

												if (isCrossPortfolioLink) {
													const linkData = crossPortfolioLinks.find((o) => o.link.id === ox.id);

													if (linkData) {
														return (
															<CrossPortfolioXyiconV5
																key={linkData.link.id}
																transport={this.props.app.transport}
																linkData={linkData}
																showIconOnly={this.props.layout === PortLayoutType.Icon}
																showBreakLinkAndDeleteButton={false}
															/>
														);
													}
												}

												// The linked object is a boundary. This shouldn't happen, but for extra safety...
												return null;
											}
										})}
									</div>
								)}
							</Droppable>
							<ButtonV5
								label="Break Links"
								className="primary"
								onClick={() => this.breakLinks(orphanedLinks)}
							/>
						</div>
					)}
					<div className="childrenContainer">
						{portTemplate.map((port: PortTemplateDto, index: number) => {
							const id = port.id;
							const lastChild = index === portTemplate.length - 1;

							return (
								<PortComponentV5
									key={`${item.id}_${id}`}
									item={item}
									id={id}
									children={port.children}
									portMap={portMap}
									feature={item.ownFeature}
									label={port.label}
									isReadOnly={port.isReadOnly}
									isStructurallyEditable={false}
									lastChildHasChild={lastChild && lastChildHasChild}
									onLabelChange={this.props.onLabelChange ? this.onLabelChange : null}
									labelOverrides={labelOverrides}
									layout={this.props.layout}
									grabbedItemDraggableId={this.state.grabbedItemDraggableId}
								/>
							);
						})}
					</div>
				</DragDropContext>
			</PortContainerStyled>
		);
	}
}

const childContainerMargin = "30px";
const lineStyle = "1px dashed #DADADA";
const heightOfPort = "40px";
const halfHeightOfPort = "20px";

export const PortContainerStyled = styled.div`
	${VerticalFlexStyle};
	margin: 15px;

	.SpaceItemContainer {
		background: #232a31;

		.SpaceItem:not(.showIconOnly) {
			${Grabbable}

			.thumbnailContainer {
				position: relative;
				margin-left: 28px;
				.thumbnail {
					position: absolute;
				}
			}
		}
	}

	.fullListContainer {
		width: 431px;
		max-height: 320px;
		position: absolute;
		top: -60px;
		right: 0;
		z-index: 8000;
		background: white;
		visibility: visible;
		box-shadow:
			0px 24px 32px 0px #32324714,
			0px 16px 16px 0px #32324714;

		.SpaceItemContainer {
			background-color: ${colorPalette.gray.c200Light};
		}

		.SpaceItem {
			.guid {
				//width: 80px;
				font-size: 14px;
			}
		}

		.header {
			padding: 10px;
			justify-content: space-between;
			background: ${colorPalette.gray.c200Light};

			h4 {
				font-size: 14px;
				font-weight: 300;
				color: ${colorPalette.primary.c500Primary};
				text-transform: initial;
			}
		}

		.cardsContainer {
			overflow: auto;
			padding: 10px;
			display: grid;
			grid-template-columns: repeat(2, 49%);
			gap: 8px;
		}
	}

	.viewButton {
		color: #aaaaaa;
		width: 40px;
		height: 40px;

		&:hover {
			color: #3495f0;
		}

		.icon {
			width: 22px;
			height: 22px;
		}

		&.add {
			color: #3495f0;
			border-radius: 4px;
			border: 1px dashed #3495f0;
			margin-right: auto;
		}
	}

	.PortComponent {
		position: relative;
		margin-top: 16px;
		margin-bottom: 16px;

		.link {
			&.dragging {
				filter: drop-shadow(9px 17px 8px rgba(0, 0, 0, 0.25));
			}

			.CrossPortfolioXyicon {
				.SpaceItemContainer {
					.SpaceItem {
						&::before {
							display: none;
						}

						.thumbnailContainer {
							margin-left: 0;
						}
					}
				}
			}
		}

		.interactionsDiv {
			margin-left: 20px;
			width: fit-content;
			background: none;
			box-shadow: none;
			padding: 0;

			.ToggleSwitchField {
				margin: 0;
				padding: 0;
				color: #37474f;
				white-space: nowrap;
			}
		}

		.thumbnailWrapper {
			height: 100%;

			.thumbnailContainer {
				height: 100%;
				display: flex;
				gap: 8px;

				.more {
					font-size: 12px;
					cursor: pointer;
					color: #3495f0;
				}

				.thumbnail {
					margin: 0 3px;
				}

				.SpaceItemContainer {
					background: #232a31;

					.SpaceItem:not(.showIconOnly) {
						${Grabbable}

						.thumbnailContainer {
							position: relative;
							margin-left: 28px;
							.thumbnail {
								position: absolute;
							}
						}
					}
				}
			}
		}

		.textContainer {
			position: relative;

			.TextInput {
				width: 138px;
				font-size: 14px;

				.field-input-container input {
					font-weight: normal;
				}
			}

			.node {
				display: inline-block;
				border: solid 1px #f5f5f5;
				padding: 10px;
				margin: 0;
				font-size: 14px;
			}
		}

		&.locked {
			.textContainer {
				&::after {
					position: absolute;
					left: 123px;
					display: inline-block;
					content: "";
					background-image: url(../assets/images/common/lock.svg);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center center;
					filter: grayscale(1);
					width: 10px;
					height: 100%;
				}
			}
		}

		.viewButton.add {
			margin-left: 10px;
		}
	}

	.childrenContainer {
		margin-left: ${childContainerMargin};
		position: relative;

		&::before {
			position: absolute;
			content: "";
			height: calc(100% - 4px);
			margin-left: -${childContainerMargin};
			bottom: ${halfHeightOfPort};
			border-left: ${lineStyle};
		}

		&.lastChildHasChild::after {
			position: absolute;
			content: "";
			width: 5px;
			height: calc(100% + 14px);
			background: white;
			margin-left: -62px;
			bottom: ${halfHeightOfPort};
		}

		.PortComponent {
			.droparea {
				height: 100%;
				border: dashed 1px transparent;

				&.cardLayout {
					.link {
						width: 200px;
					}
				}
			}

			&::before {
				position: absolute;
				content: "";
				border-bottom: ${lineStyle};
				width: ${childContainerMargin};
				margin-left: -${childContainerMargin};
				margin-top: ${halfHeightOfPort};
			}

			&.leaf {
				.droparea {
					&.active {
						border-color: #3495f0;
					}
				}
			}
		}
	}

	> .childrenContainer {
		&::before {
			height: calc(100% - 70px);
			bottom: 33px;
		}
	}

	.orphanedLinks {
		border-left: solid 5px #f2c110;
		background: white;
		box-shadow:
			0px 16px 16px rgba(50, 50, 71, 0.08),
			0px 24px 32px rgba(50, 50, 71, 0.08);
		padding: 10px 24px;
		margin-bottom: 30px;
		min-width: 450px;

		h3 {
			font-size: 14px;
			font-weight: normal;
			margin-bottom: 10px;
		}

		p {
			font-size: 12px;
			line-height: 18px;
			color: #37474f;
		}

		.Button {
			width: min-content;
			margin: 0;
		}

		.orphaned {
			overflow-x: auto;
			margin-right: 10px;
			margin-bottom: 10px;
			flex-wrap: wrap;
			max-height: 300px;

			& > * {
				margin: 2px;
			}

			.link {
				&.dragging {
					filter: drop-shadow(9px 17px 8px rgba(0, 0, 0, 0.25));
				}
			}

			.SpaceItemContainer {
				width: 140px;
				background: #c4c4c4;
			}

			.PortComponentLink {
				&.thumbnail {
					width: 40px;
					height: 40px;
				}
			}
		}
	}
`;

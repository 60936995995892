import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import {Field} from "../../../../widgets/form/field/Field";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import {FieldDataType, XyiconFeature} from "../../../../../generated/api/base";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {PortfolioGroupPortfolios} from "./PortfolioGroupPortfolios";

interface IPortfolioGroupDetailsTabProps {
	portfolioGroups: PortfolioGroup[];
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class PortfolioGroupDetailsTab extends React.Component<IPortfolioGroupDetailsTabProps> {
	constructor(props: IPortfolioGroupDetailsTabProps) {
		super(props);
	}

	private async updateApi() {
		const portfolioGroup = this.props.portfolioGroups[0];

		if (portfolioGroup.id) {
			// we're in edit mode
			await this.props.transport.services.feature.update(portfolioGroup.id, XyiconFeature.PortfolioGroup, {
				portfolios: portfolioGroup.portfolioIds,
				name: portfolioGroup.name,
			});
		}
	}

	private isNameValid = (name: string) => {
		const {portfolioGroups, appState} = this.props;
		const portfolioGroup = portfolioGroups[0];

		const portfolioGroupListArray = appState.actions.getList<PortfolioGroup>(XyiconFeature.PortfolioGroup);
		const otherGroupsWithSameName = portfolioGroupListArray.find((g) => g.id !== portfolioGroup.id && StringUtils.equalsIgnoreCase(g.name, name));

		return !otherGroupsWithSameName;
	};

	private onChangeName = (name: string) => {
		if (this.isNameValid(name)) {
			this.props.portfolioGroups[0].name = name;
			this.updateApi();
		}
	};

	private onBlur = () => {
		const portfolioGroup = this.props.portfolioGroups[0];

		if (portfolioGroup) {
			this.updateApi();
		}
	};

	private get portfolioGroupMemberCount() {
		const {appState, portfolioGroups} = this.props;

		return portfolioGroups[0].portfolioIds.filter((id) => appState.actions.getFeatureItemById(id, XyiconFeature.Portfolio)).length;
	}

	public override render() {
		const {portfolioGroups, appState} = this.props;
		const portfolioGroup = portfolioGroups[0];

		return (
			<div className="DetailsTab">
				{portfolioGroups.length === 0 && <div className="noData">Please select a Portfolio Group</div>}
				{portfolioGroups.length === 1 && (
					<>
						<div className="header">
							<Field label="Name">
								<ClickToEditInput
									value={portfolioGroup.name}
									onChange={this.onChangeName}
									onBlur={this.onBlur}
									valueValidator={this.isNameValid}
									dataType={FieldDataType.SingleLineText}
									noButtons={true}
								/>
							</Field>
							<Field label="Portfolios">{this.portfolioGroupMemberCount}</Field>
							<Field label="Last Updated">{DateFormatter.format(portfolioGroup.lastModifiedAt)}</Field>
							<Field label="Last Updated By">{appState.actions.renderName(portfolioGroup.lastModifiedBy)}</Field>
						</div>
						<PortfolioGroupPortfolios portfolioGroup={portfolioGroup} />
					</>
				)}
			</div>
		);
	}
}

import * as React from "react";
import {HueSlider} from "../HueSlider";
import {TransparencySlider} from "../TransparencySlider";
import type {IPropsForEyeDropper} from "../EyeDropper";
import {EyeDropper} from "../EyeDropper";
import {ColorUtils} from "../../../../../../../utils/ColorUtils";
import {ColorInput} from "./ColorInput";
import {ColorGradient} from "./ColorGradient";

export interface IHSLColor {
	/** HexToHSL is not an injective function: hue value can be anything when the input is a monochrome color
	 * In this case, we don't want the hue level to change, so we show the last valid hue value instead */
	hue: number; // [0 - 360]
	saturation: number; // [0 - 1]
	lightness: number; // [0 - 1]
	transparency: number; // [0 - 1]
}

interface IAdvancedColorPanelProps {
	color: IHSLColor;
	onColorChange: (newColor: IHSLColor) => void;
	isTransparencyEnabled: boolean;
	eyeDropperProps?: IPropsForEyeDropper;
}

export class AdvancedColorPanel extends React.Component<IAdvancedColorPanelProps> {
	private onSLChange = (newSaturation: number, newLightness: number) => {
		this.props.onColorChange({
			hue: this.props.color.hue,
			saturation: newSaturation,
			lightness: newLightness,
			transparency: this.props.color.transparency,
		});
	};

	private onHueChange = (newValue: number) => {
		this.props.onColorChange({
			hue: newValue,
			saturation: this.props.color.saturation,
			lightness: this.props.color.lightness,
			transparency: this.props.color.transparency,
		});
	};

	private onTransparencySliderChange = (newValue: number) => {
		this.props.onColorChange({
			hue: this.props.color.hue,
			saturation: this.props.color.saturation,
			lightness: this.props.color.lightness,
			transparency: newValue,
		});
	};

	private onHexChange = (newHexValue: string) => {
		const hslColor = ColorUtils.hex2hsl(newHexValue);

		this.props.onColorChange({
			hue: hslColor.h,
			saturation: hslColor.s,
			lightness: hslColor.l,
			transparency: this.props.color.transparency,
		});
	};

	private onTransparencyInputChange = (newValue: number) => {
		this.onTransparencySliderChange(newValue);
	};

	private onDispatchColorChange = () => {
		this.props.onColorChange({
			hue: this.props.color.hue,
			saturation: this.props.color.saturation,
			lightness: this.props.color.lightness,
			transparency: this.props.color.transparency,
		});
	};

	public override render() {
		const {color, eyeDropperProps, isTransparencyEnabled} = this.props;
		const hex = ColorUtils.hsl2hex(this.props.color.hue, this.props.color.saturation, this.props.color.lightness) as string;

		return (
			<div className="ColorPanelAdvanced">
				<ColorGradient
					color={color}
					width={180}
					height={102}
					onSLChange={this.onSLChange}
					onPointerUp={this.onDispatchColorChange}
				/>
				<div className="hbox alignCenter">
					{eyeDropperProps && (
						<EyeDropper
							canvas={eyeDropperProps.canvas}
							elementForPointerEvents={eyeDropperProps.elementForPointerEvents}
							textureNeedsUpdateSignal={eyeDropperProps.textureNeedsUpdateSignal}
							onActivateStateChanged={eyeDropperProps.onActivateStateChanged}
							onColorClick={this.onHexChange}
						/>
					)}
					<div className="sliders vbox">
						<HueSlider
							value={color.hue}
							onValueChange={this.onHueChange}
							sliderWidth={eyeDropperProps ? 125 : 160}
						/>
						{isTransparencyEnabled && (
							<TransparencySlider
								value={color.transparency}
								onValueChange={this.onTransparencySliderChange}
								color={hex}
								disabled={!isTransparencyEnabled}
								sliderWidth={eyeDropperProps ? 125 : 160}
							/>
						)}
					</div>
				</div>
				<ColorInput
					hex={hex}
					onHexInputChange={this.onHexChange}
					transparency={color.transparency}
					isTransparencyEnabled={isTransparencyEnabled}
					onTransparencyInputChange={this.onTransparencyInputChange}
				/>
			</div>
		);
	}
}

import type {INumericFieldSettingsDefinition} from "../models/field/datatypes/Numeric";
import {FileUtils} from "../../utils/file/FileUtils";
import type {View} from "../models/View";
import type {IModel} from "../models/Model";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {FieldDataType} from "../../generated/api/base";
import {BaseExporter} from "./BaseExporter";

export class CSVExporter extends BaseExporter {
	protected readonly _extension = "csv";

	protected _exportView<T extends IModel>(view: View, items: T[]) {
		let result = "";
		const fieldRefIds = this.getFieldRefIds(view).filter((f) => !f.includes("icon"));

		const headers = this.getHeaders(fieldRefIds);

		result += `${headers.map((header) => `"${header}"`).join(",")}\n`;

		for (const item of items) {
			result += `${fieldRefIds
				.map(
					(fieldRefId) =>
						`"${
							this.getFieldDataForCSV(item, fieldRefId)
								.filter(StringUtils.isTruthy)
								.flat() // multiselect fields contain array values
								.join("\n")
								.replace(/"/g, '""') // escape " characters with another "
						}"`,
				)
				.join(",")}\n`;
		}

		FileUtils.downloadFileGivenByData(result, this.getFileName(view));
	}

	private getFieldDataForCSV = (item: IModel, fieldRefId: string) => {
		const field = this._appState.actions.getFieldByRefId(fieldRefId);
		const settings = field.dataTypeSettings as INumericFieldSettingsDefinition;

		if (field.dataType === FieldDataType.Numeric && settings.formatting === "percentage") {
			return [this._appState.actions.getOwnFieldValue(item, fieldRefId)];
		}
		return this.getFieldData(item, fieldRefId);
	};

	public exportTable(data: (string | number)[][], fileName: string) {
		let result = "";

		if (data.length > 0) {
			const headers = data[0].map((v) => `${v}`);

			data = data.slice(1);

			result += `${headers.map((header) => `"${header}"`).join(",")}\r\n`;

			for (const d of data) {
				result += `${d
					.map((dd) => {
						if (dd === undefined) {
							dd = "";
						}
						if (dd === null) {
							dd = "";
						}
						if (typeof dd === "number") {
							return dd;
						}
						return `"${dd}"`;
					})
					.join(",")}\r\n`;
			}
		}

		FileUtils.downloadFileGivenByData(result, fileName);
	}
}

import styled from "styled-components";
import {useRef, useState} from "react";
import type {Space} from "../../../../../data/models/Space";
import ArrowDownIcon from "../../../icons/chevron-down.svg?react";
import SearchIcon from "../../../icons/search.svg?react";
import ListIcon from "../../../icons/list.svg?react";
import {ELLIPSIS, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {useClickOutside} from "../../../utils";
import {VerticalLine} from "../../../widgets/VerticalLine";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {Functions} from "../../../../../utils/function/Functions";
import {SpaceList} from "./SpaceList";
import {SpaceExporter} from "./SpaceExporter";
import {GridSelector} from "./GridSelector";

interface ISpaceSelectorProps {
	readonly spaces: Space[];
	readonly selectedSpace: Space;
	readonly onSpaceSelect: (selectedSpace: Space) => void;
}

export const SpaceSelector = (props: ISpaceSelectorProps) => {
	const {selectedSpace, spaces, onSpaceSelect} = props;
	const wrapperRef = useRef<HTMLDivElement>();
	const [isSpaceContainerOpen, setIsSpaceContainerOpen] = useState<boolean>(false);
	const [isGridSelectorOpen, setIsGridSelectorOpen] = useState<boolean>(false);

	useClickOutside([wrapperRef], (event: MouseEvent) => {
		setIsSpaceContainerOpen(false);
		setIsGridSelectorOpen(false);
	});

	const onCurrentSpaceContainerClick = () => {
		setIsSpaceContainerOpen(!isSpaceContainerOpen);
		setIsGridSelectorOpen(false);
	};

	const onGridSelectorClick = () => {
		setIsGridSelectorOpen(!isGridSelectorOpen);
		setIsSpaceContainerOpen(false);
	};

	const onSpaceExporterClick = () => {
		setIsGridSelectorOpen(false);
		setIsSpaceContainerOpen(false);
	};

	return (
		<SpaceSelectorWrapper ref={wrapperRef}>
			<SpaceSelectorStyled>
				<CurrentSpaceContainer onClick={onCurrentSpaceContainerClick}>
					<CurrentSpace title={selectedSpace.name}>{selectedSpace.name}</CurrentSpace>
					<ArrowDownIcon />
				</CurrentSpaceContainer>
				<ButtonContainer>
					<VerticalLine />
					<IconButtonV5
						IconComponent={SearchIcon}
						onClick={Functions.emptyFunction}
					/>
					<IconButtonV5
						IconComponent={ListIcon}
						onClick={onGridSelectorClick}
					/>
					<SpaceExporter onClick={onSpaceExporterClick} />
				</ButtonContainer>
			</SpaceSelectorStyled>
			{isSpaceContainerOpen && (
				<SpaceList
					spaces={spaces}
					onSpaceSelect={onSpaceSelect}
					selectedSpace={selectedSpace}
				/>
			)}
			{isGridSelectorOpen && <GridSelector onClose={() => setIsGridSelectorOpen(false)} />}
		</SpaceSelectorWrapper>
	);
};

const SpaceSelectorWrapper = styled.div`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 300px;
	z-index: 1;
`;

const SpaceSelectorStyled = styled.div`
	background-color: ${colorPalette.white};
	height: 48px;
	padding-right: 8px;
	border-radius: ${radius.md};
	box-shadow: 0px 4px 8px 0px #00000080;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CurrentSpace = styled.div`
	flex: 1;
	${ELLIPSIS}
`;

const CurrentSpaceContainer = styled.div`
	height: 32px;
	width: 154px;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding-left: 8px;
	margin: 0 8px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: ${radius.sm};
	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	> ${IconButtonStyled} {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: ${radius.md};
		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
	}
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../data/state/AppState";
import type {IToggleContainerProps} from "./ToggleContainer";
import {ToggleContainer} from "./ToggleContainer";

interface IInteractiveToggleContainerProps<T> extends IToggleContainerProps<T> {
	isEnabled: boolean;
	onEnabledChange: (value: boolean) => void;
	appState?: AppState;
}

@inject("appState")
@observer
export class InteractiveToggleContainer<T = any> extends React.PureComponent<IInteractiveToggleContainerProps<T>> {
	public override render() {
		const {title, children, open, onToggleOpen, onAddClicked, className, isEnabled, onEnabledChange, appState} = this.props;
		const props = {title, children, open, onToggleOpen, onAddClicked, className, isEnableTogglerVisible: true, isEnabled, onEnabledChange};

		return <ToggleContainer {...props} />;
	}
}

import * as React from "react";
import {TextInput} from "../../../text/TextInput";
import type {IInputProps} from "../../FieldInput";
import {IconButton} from "../../../../button/IconButton";

export class IPInput extends React.PureComponent<IInputProps> {
	public override render() {
		const {value, onApply, onCancel, onBlur, onInput, caretPosition, noButtons, disabled} = this.props;

		return (
			<div className="hbox field-input-container">
				<TextInput
					value={value}
					onInput={onInput}
					caretPosition={caretPosition}
					autoFocus={true}
					onBlur={onBlur}
				/>
				{!disabled && !noButtons && (
					<>
						<IconButton
							className="apply"
							icon="apply"
							title="Apply"
							onClick={onApply}
						/>
						<IconButton
							className="cancel"
							icon="cancel"
							title="Cancel"
							onClick={onCancel}
						/>
					</>
				)}
			</div>
		);
	}
}

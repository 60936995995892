import type {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {MeasureType, getCorrectionMultiplierForSpaceItem} from "../../renderers/SpaceViewRendererUtils";
import {Constants} from "../../Constants";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {MarkupAB1D} from "./abstract/MarkupAB1D";
import {calculateArrowHead} from "./abstract/MarkupUtils";
import type {IMarkupConfig} from "./abstract/MarkupUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";

export class MarkupArrow extends MarkupAB1D {
	private _bidirectional: boolean;
	private _arrowSegments: number[] = [];
	private _textContent: string = "";

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig, bidirectional: boolean = false) {
		super(spaceViewRenderer, ObjectUtils.mergeConfig(config, {useSegments: true}));
		this._bidirectional = bidirectional;
	}

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const geometryData = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);
		const processedA = geometryData[0];
		const processedB = geometryData[1];

		const {bc, bd} = calculateArrowHead(
			processedA,
			processedB,
			this._arrowHeadSize,
			getCorrectionMultiplierForSpaceItem(this._spaceViewRenderer, this._modelData),
		);

		this._arrowSegments = [
			processedA.x,
			processedA.y,
			0,
			processedB.x,
			processedB.y,
			0,
			processedB.x,
			processedB.y,
			0,
			processedB.x + bc.x,
			processedB.y + bc.y,
			0,
			processedB.x,
			processedB.y,
			0,
			processedB.x + bd.x,
			processedB.y + bd.y,
			0,
		];

		if (this._bidirectional) {
			this._arrowSegments.push(processedA.x, processedA.y, 0);
			this._arrowSegments.push(processedA.x - bc.x, processedA.y - bc.y, 0);
			this._arrowSegments.push(processedA.x, processedA.y, 0);
			this._arrowSegments.push(processedA.x - bd.x, processedA.y - bd.y, 0);
		}

		(this._lineGeometry as LineSegmentsGeometry).setPositions(this._arrowSegments);

		if (this.measureType === MeasureType.DISTANCE) {
			const distanceInSpaceUnit = THREEUtils.calculateDistance(this._worldGeometryData);
			const space = this._modelData?.space ? this._modelData.space : this._spaceViewRenderer.space;
			const unitName = this.unitName;
			const unit = Constants.DISTANCE_UNITS[unitName];
			const distance = MathUtils.convertDistanceFromSpaceUnit(distanceInSpaceUnit, unit.name, space.spaceUnitsPerMeter);

			if (unitName === "foot&inch") {
				const feetAndInches = MathUtils.convertFeetInDecimalToFeetAndInches(distance);

				this._textContent = MathUtils.formatFeetAndInchesArrayToText(feetAndInches);
			} else {
				this._textContent = `${distance.toFixed(Constants.TO_FIXED)} ${unit.abbreviation}`;
			}
			this.recreateTextGroups();
		}

		if (this._isDashed) {
			this._line.computeLineDistances();
		}

		if (isLocal) {
			this._selectionPart.update(this._arrowSegments);
		}
		this.updateBoundingBox();
	}

	public override get textContent() {
		return this.measureType !== MeasureType.NONE ? this._textContent : super.textContent;
	}

	public get measureType() {
		return this.config.measureType;
	}

	public get type() {
		return this._bidirectional ? MarkupType.Bidirectional_Arrow : MarkupType.Arrow;
	}
}

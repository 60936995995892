import {useEffect, useRef} from "react";
import {Observer} from "mobx-react";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {Portfolio} from "../../../data/models/Portfolio";
import type {INotificationParams} from "../../notification/Notification";
import {NotificationType} from "../../notification/Notification";
import type {IPortfolioDuplicationStatus} from "../../../data/signalr/SignalRListener";
import type {INotificationElementParams} from "../../notification/AppNotifications";
import {notify} from "../../../utils/Notify";
import type {IModel} from "../../../data/models/Model";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import CloneIcon from "../icons/clone.svg?react";
import PenFieldIcon from "../icons/pen-field.svg?react";
import DeleteIcon from "../icons/delete.svg?react";
import {PortfolioCreatePopupV5} from "./portfolio/PortfolioCreatePopupV5";

interface IPortfolioViewV5Props {
	param1?: string;
	param2?: string;
}

interface IDuplicationRequest {
	requestId: string;
	portfolio: Portfolio;
	notification: INotificationElementParams | null;
}

export const PortfolioViewV5 = (props: IPortfolioViewV5Props) => {
	const _moduleView = useRef<ModuleViewV5>();
	const createButtonRef = useRef<HTMLDivElement>();
	let _duplicationRequests: IDuplicationRequest[] = [];

	const appState = useAppStore((state) => state.appState);
	const {transport} = appState.app;
	const {actions} = appState;

	const onDuplicatePortfolioClick = async (model: Portfolio) => {
		try {
			const result = await transport.duplicatePortfolio(model);

			_duplicationRequests.push({requestId: result.requestID, portfolio: model, notification: null});
			getPortfolioDuplicationStatusReceivedSignal().add(onStatusUpdated);
		} catch (error) {
			console.error(error);
		}
	};

	const onStatusUpdated = (data: IPortfolioDuplicationStatus) => {
		const duplicationRequest = _duplicationRequests.find((request) => request.requestId === data.requestID);

		if (duplicationRequest) {
			duplicationRequest.notification?.onClose();

			if (data.isCompleted) {
				getPortfolioDuplicationStatusReceivedSignal().remove(onStatusUpdated);
				_duplicationRequests = _duplicationRequests.filter((request) => request !== duplicationRequest);

				const notificationParams: INotificationParams = {
					type: NotificationType.Success,
					title: `Duplicating ${duplicationRequest.portfolio.name} Portfolio is Completed!`,
					lifeTime: 10000,
				};

				if (data.isError) {
					notificationParams.title = `Duplicating ${duplicationRequest.portfolio.name} Portfolio Failed.`;
					notificationParams.type = NotificationType.Error;
					notificationParams.description = data.message;
					notificationParams.lifeTime = Infinity;
				}

				duplicationRequest.notification = notify(appState.app.notificationContainer, notificationParams);
			} else {
				duplicationRequest.notification = notify(appState.app.notificationContainer, {
					type: NotificationType.Message,
					title: `Duplicating ${duplicationRequest.portfolio.name} Portfolio`,
					description: data.message,
					lifeTime: 10000,
				});
			}
		}
	};

	const canDeletePortfolios = (selectedItems: IModel[]) => {
		const {actions, user} = appState;

		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return selectedItems.every((item: IModel) => {
			const permission = actions.getPortfolioPermission(item.id);

			return permission >= Permission.Delete;
		});
	};

	const isAdmin = () => {
		return appState.user?.isAdmin;
	};

	const getPortfolioDuplicationStatusReceivedSignal = () => {
		return transport.signalR.listener.signals.portfolioDuplicationStatusReceived;
	};

	useEffect(() => {
		const {actions} = appState;

		const portfolioId = transport.services.localStorage.get(actions.getKeyForLocalStorageSelectedPortfolio());
		const currentPortfolio = actions.getPortfolioById(portfolioId) ?? actions.getCurrentPortfolio();

		if (currentPortfolio) {
			if (currentPortfolio.id !== actions.getCurrentPortfolio()?.id) {
				transport.services.auth.switchPortfolio(currentPortfolio.id);
			}

			_moduleView.current?.focusItem(currentPortfolio);
		}
	}, [appState, transport.services.auth, transport.services.localStorage]);

	{
		return (
			<Observer>
				{() => {
					/* const currentPortfolio = actions.getCurrentPortfolio();

						if (_moduleView.current?.state.focusedItems[0]?.id !== currentPortfolio?.id)
						{
							_moduleView.current?.focusItem(currentPortfolio);
						} */
					return (
						<ModuleViewV5
							ref={_moduleView}
							feature={XyiconFeature.Portfolio}
							actionBar={[
								{
									id: "duplicate",
									title: "Duplicate Portfolio Item",
									label: "Duplicate",
									IconComponent: CloneIcon,
									visible: (selectedItems) => selectedItems.length === 1,
									onClick: (selectedItems) => onDuplicatePortfolioClick(selectedItems[0] as Portfolio),
								},
								{
									id: "fieldUpdate",
									title: "Update Field",
									label: "Update Field",
									IconComponent: PenFieldIcon,
									visible: () => true,
								},
								{
									id: "delete",
									title: "Delete Portfolio Item",
									label: "Delete",
									IconComponent: DeleteIcon,
									visible: (selectedItems) => true,
								},
							]}
							maps={true}
							create={(onClose) => (
								<PortfolioCreatePopupV5
									onClose={() => {
										onClose();
									}}
									parentRef={createButtonRef}
								/>
							)}
							createButtonRef={createButtonRef}
						/>
					);
				}}
			</Observer>
		);
	}
};

import * as React from "react";
import type {IToolConfig, SpaceTool} from "../../logic3d/features/tools/Tools";
import {GestureDetectorReact} from "../../../../../interaction/GestureDetectorReact";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import type {Color} from "../../../../../../generated/api/base";
import type {IToolButtonProps} from "./ToolButton";

interface ISharedToolGroupProps {
	tools: {
		[id in SpaceTool]: IToolConfig;
	};
	activeTool: SpaceTool;
	chosenTool: SpaceTool; // the subtool that is currently chosen within the toolgroup
	children: React.ReactElement<IToolButtonProps>[];
	onClick: (id: SpaceTool) => void;
	color?: Color;
}

interface ISharedToolGroupState {
	isOpen: boolean;
}

/**
 * Represents a ToolGroup, but it's chosen tool is a property, so it can be synced across the instances
 */

export class SharedToolGroup extends React.Component<ISharedToolGroupProps, ISharedToolGroupState> {
	private _element = React.createRef<HTMLDivElement>();

	constructor(props: ISharedToolGroupProps) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}

	private onClick = (pointer: Pointer) => {
		const clickedOnSubButton = HTMLUtils.doesAncestorHaveClass(pointer.originalEvent.target as HTMLElement, "subMenu");

		if (clickedOnSubButton) {
			return;
		} else {
			this.setState({
				isOpen: this.isActive && !this.state.isOpen,
			});

			this.props.onClick(this.props.chosenTool);
		}
	};

	private onLongPress = () => {
		this.props.onClick(this.props.chosenTool);
		this.setState({
			isOpen: true,
		});
	};

	private get isActive(): boolean {
		const children = this.props.children as React.ReactElement<IToolButtonProps>[];

		return children.some((child) => child.props.active);
	}

	private onBlur = (event: MouseEvent | TouchEvent) => {
		this.setState({
			isOpen: false,
		});

		return false;
	};

	public override componentDidMount() {
		FocusLoss.listen(this._element.current, this.onBlur);
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._element.current, this.onBlur);
	}

	public override render() {
		const children = this.props.children as React.ReactElement<IToolButtonProps>[];
		const svgStyle: React.CSSProperties = {};

		const subMenuItems = children.map((container: React.ReactElement<IToolButtonProps>, index: number) => {
			const onClick = () => {
				container.props.onClick();
				this.setState({
					isOpen: false,
				});
			};

			return React.cloneElement(container, {key: index, onClick: onClick});
		});

		const chosenTool = this.props.tools[this.props.chosenTool];

		if (this.props.color) {
			svgStyle.fill = `#${this.props.color.hex}`;
		}

		return (
			<GestureDetectorReact
				onLongPress={this.onLongPress}
				onClick={this.onClick}
			>
				<div
					ref={this._element}
					className={ReactUtils.cls("btn", {active: this.isActive, isBordered: this.isActive})}
					title={chosenTool.title}
				>
					<SVGIcon
						icon={chosenTool.icon}
						style={svgStyle}
					/>
					<div className="arrowWrapper">
						<i className="arrowDown corner"></i>
					</div>
					<div className={ReactUtils.cls("subMenu", {open: this.state.isOpen})}>{subMenuItems}</div>
				</div>
			</GestureDetectorReact>
		);
	}
}

import type {Mesh} from "three";
import {Scene, PerspectiveCamera, WebGLRenderer, AmbientLight, DirectionalLight, HemisphereLight, Box3, Vector3, OrthographicCamera} from "three";
import {Constants} from "../Constants";
import {BoundedConvergence} from "../../../../../../utils/animation/BoundedConvergence";
import {Convergence, Easing} from "../../../../../../utils/animation/Convergence";
import {Signal} from "../../../../../../utils/signal/Signal";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {VectorUtils} from "../../../../../../utils/VectorUtils";
import {OrbitCameraControls} from "./OrbitCameraControls";

export type DefaultCameraPos = "side" | "top";

const getCameraPosFromSide = () => [-1, 0.6, 1.3];
const getCameraPosFromTop = () => [0, 1, Constants.EPSILON];

export class SceneManager {
	private _canvas: HTMLCanvasElement;
	private _scene: Scene;
	private _mesh: Mesh;
	private _oCamera: OrthographicCamera;
	private _pCamera: PerspectiveCamera;
	private _activeCamera: PerspectiveCamera | OrthographicCamera;
	private _controls: OrbitCameraControls;
	private _renderer: WebGLRenderer;
	private _distance: BoundedConvergence = new BoundedConvergence({
		start: 10,
		end: 10,
		min: 1,
		max: 100,
		easing: Easing.EASE_OUT,
		animationDuration: Constants.DURATIONS.CAMERA_MOVEMENT,
		timeStampManager: this,
	});
	public activeConvergences: Convergence[] = [];

	public signals = {
		onAfterRender: Signal.create(),
	};

	private _previousCanvasParentSize = {
		width: 0,
		height: 0,
	};

	private _requestAnimationFrameId: number = null;
	private _normalizedCameraPosition: number[];
	private _timeStamp: number = 0;
	private _deltaFrame: number = 1000;
	private _prevTimeStamp: number = 0;
	public needsRender = true;

	constructor(canvas: HTMLCanvasElement, mesh: Mesh, autoInit: boolean = true, defaultCameraPos: DefaultCameraPos = "side") {
		this._canvas = canvas;
		this._scene = new Scene();
		this._mesh = mesh;
		this._oCamera = new OrthographicCamera(-canvas.width / 2, canvas.width / 2, canvas.height / 2, -canvas.height / 2, 0.05, 70);
		this._pCamera = new PerspectiveCamera(60, canvas.width / canvas.height, 0.05, 70);
		this._activeCamera = defaultCameraPos === "side" ? this._pCamera : this._oCamera;

		this.setCameraToDefaults(defaultCameraPos);

		if (autoInit) {
			this.init();
		}
	}

	private getDefaultCameraPosVectorFromLabel(defaultCameraPos: DefaultCameraPos) {
		return defaultCameraPos === "side" ? getCameraPosFromSide() : getCameraPosFromTop();
	}

	private setCameraToDefaults(defaultCameraPos: DefaultCameraPos) {
		this._normalizedCameraPosition = VectorUtils.normalize(this.getDefaultCameraPosVectorFromLabel(defaultCameraPos));
		this.updateCameraProps();
		this._controls?.setUVFromSphereSufracePoint(this._normalizedCameraPosition);
		this._controls?.startAutoRotating();
	}

	public updateCameraProps() {
		const bbox = new Box3().expandByObject(this._mesh);
		const boxSize = bbox.getSize(new Vector3());
		const size = boxSize.length();

		const minDistance = new Vector3(size / 2, size / 5, size / 2).length();
		const defaultDistance = minDistance * 1.25;
		const maxDistance = minDistance * 2;

		this._distance.reset(defaultDistance, defaultDistance, minDistance, maxDistance);

		this._activeCamera.near = size / 100;
		this._activeCamera.far = size * 100;

		const frustumSize = Math.max(boxSize.x, boxSize.z);

		this._oCamera.left = -frustumSize / 2;
		this._oCamera.right = frustumSize / 2;
		this._oCamera.top = frustumSize / 2;
		this._oCamera.bottom = -frustumSize / 2;
		this._oCamera.zoom = 1;

		this._activeCamera.updateProjectionMatrix();
	}

	public init() {
		this.initLights();
		this.initControls();
		this.initRenderer();
		this.initMeshes();
		cancelAnimationFrame(this._requestAnimationFrameId);
		this.update();
	}

	private initLights() {
		const light1 = new AmbientLight(0xffffff, 0.1);

		const light2 = new DirectionalLight(0xffffff, 0.1);

		light2.position.set(0.5, 0.5, 0.866).normalize();

		const light3 = new DirectionalLight(0xffffff, 0.075);

		light3.position.set(-0.5, -0.5, 0.866).normalize();

		const light4 = new HemisphereLight(0xffffff, 0x202020, 0.75);

		this._scene.add(light1, light2, light3, light4);
	}

	private initControls() {
		this._controls = new OrbitCameraControls(this._canvas?.parentElement || this._canvas, this, this._normalizedCameraPosition);
		this._controls.activate();
	}

	private initMeshes() {
		this._scene.add(this._mesh);
	}

	private initRenderer() {
		this._renderer = new WebGLRenderer({
			canvas: this._canvas,
			antialias: true,
			alpha: true,
		});
		this._renderer.setPixelRatio(window.devicePixelRatio);
		this._renderer.setClearColor(0);
		this._renderer.setClearAlpha(0);

		this._canvas.addEventListener("webglcontextlost", this.onContextLost);
	}

	private onContextLost = (event: Event) => {
		event.preventDefault();

		//alert("Unfortunately WebGL has crashed. Please reload the page to continue!");
	};

	private resizeCanvasIfNeeded() {
		const canvas = this._canvas;

		if (canvas.parentElement) {
			const size = HTMLUtils.getSize(canvas.parentElement);

			if (size.width !== this._previousCanvasParentSize.width || size.height !== this._previousCanvasParentSize.height) {
				const w = size.width;
				const h = size.height;

				canvas.width = w;
				canvas.height = h;

				this._renderer.setSize(w, h);

				canvas.style.width = "100%";
				canvas.style.height = "100%";

				this._previousCanvasParentSize.width = w;
				this._previousCanvasParentSize.height = h;

				if (!isNaN(w / h)) {
					this._pCamera.aspect = w / h;
					this._oCamera.left = -w / 2;
					this._oCamera.right = w / 2;
					this._oCamera.top = h / 2;
					this._oCamera.bottom = -h / 2;
					this._oCamera.zoom = this._distance.min / this._distance.value;
					this._activeCamera.updateProjectionMatrix();
				}

				this.needsRender = true;
			}
		}
	}

	public get scene() {
		return this._scene;
	}

	private update = () => {
		this._requestAnimationFrameId = requestAnimationFrame(this.update);

		this.resizeCanvasIfNeeded();

		this._timeStamp = performance.now();
		this._deltaFrame = this._timeStamp - this._prevTimeStamp;
		this._prevTimeStamp = this._timeStamp;
		this.needsRender = Convergence.updateActiveOnes(this._timeStamp, this) || this.needsRender;
		if (this.needsRender) {
			this._normalizedCameraPosition = this._controls.update();
			this._activeCamera.position.set(
				this._normalizedCameraPosition[0] * this._distance.value,
				this._normalizedCameraPosition[1] * this._distance.value,
				this._normalizedCameraPosition[2] * this._distance.value,
			);
			this._activeCamera.lookAt(0, 0, 0);
			this._renderer.render(this._scene, this._activeCamera);
			this.signals.onAfterRender.dispatch();
			this.needsRender = false;
		}
	};

	public replaceMesh(newMesh: Mesh) {
		this._scene.remove(this._mesh);
		THREEUtils.disposeAndClearContainer(this._mesh);
		this._mesh = newMesh;
		this._scene.add(this._mesh);
		this.setCameraToDefaults("side");
	}

	public dispose() {
		cancelAnimationFrame(this._requestAnimationFrameId);
		THREEUtils.disposeAndClearContainer(this._scene);
		this._renderer.dispose();
		this._renderer.forceContextLoss();
		this._controls.deactivate();
	}

	/** Returns the timestamp of the newest render run  */
	public get timeStamp() {
		return this._timeStamp;
	}

	/** Returns the time between the last 2 frames, so we can get an idea of the user's FPS */
	public get deltaFrame() {
		return this._deltaFrame;
	}

	public get distance() {
		return this._distance;
	}

	public get mesh() {
		return this._mesh;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "../../../../widgets/button/Button";
import type {Report} from "../../../../../data/models/Report";
import {ReportScope} from "../../../../../generated/api/reports";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import {MultiActionButton} from "../../../../widgets/button/multiaction/MultiActionButton";
import {PromptWindow} from "../../../abstract/popups/PromptWindow";
import {NotificationType} from "../../../../notification/Notification";
import {notify} from "../../../../../utils/Notify";
import {StepIndicator} from "../../../../widgets/form/stepindicator/StepIndicator";
import {ReportDisplayNames} from "./pages/displaynames/ReportDisplayNames";
import {ReportFilter} from "./pages/filters/ReportFilter";
import {ReportSortOrder} from "./pages/sortorder/ReportSortOrder";
import {ReportColumns} from "./pages/columns/ReportColumns";
import {ReportDetails} from "./pages/details/ReportDetails";

interface IReportWizardProps {
	report: Report;
	onClose: (reportId?: string) => void;
	onRunReport: (report: Report) => void;
	appState?: AppState;
	transport?: TransportLayer;
}

interface IReportWizardState {
	stepIndex: number;
}

interface IReportWizardPage {
	title: string;
	stepTitle: string;
	component: React.ComponentClass<{report: Report}> & {isValid?(report: Report): boolean};
}

@inject("appState")
@inject("transport")
@observer
export class ReportWizard extends React.Component<IReportWizardProps, IReportWizardState> {
	private _pages: IReportWizardPage[] = [
		{
			title: "Enter report details",
			stepTitle: "Enter details",
			component: ReportDetails,
		},
		{
			title: "Select columns to be included",
			stepTitle: "Select columns",
			component: ReportColumns,
		},
		{
			title: "Sort your columns",
			stepTitle: "Set sort order",
			component: ReportSortOrder,
		},
		{
			title: "Filter your data",
			stepTitle: "Apply filters",
			component: ReportFilter,
		},
		{
			title: "Customize column names",
			stepTitle: "Customize headings",
			component: ReportDisplayNames,
		},
	];

	constructor(props: IReportWizardProps) {
		super(props);
		this.state = {
			stepIndex: 0,
		};
	}

	private onBackClick = () => {
		const {stepIndex} = this.state;

		if (stepIndex > 0) {
			this.setState({
				stepIndex: stepIndex - 1,
			});
		} else {
			this.props.report.cancelEditing();
			this.props.onClose();
		}
	};

	private onNextClick = () => {
		const {stepIndex} = this.state;

		this.setState({
			stepIndex: stepIndex + 1,
		});
	};

	private onSaveClick = async () => {
		if (!this.validateReport()) {
			return;
		}

		const {report, transport} = this.props;

		if (report.id) {
			await transport.services.feature.updateReport(report);
			this.props.onClose(report.id);
		} else {
			const reports = await transport.services.feature.create(report.serializeData(), XyiconFeature.Report);

			this.props.onClose(reports?.[0]?.id);
		}
	};

	private onSaveAndRunClick = async () => {
		if (!this.validateReport()) {
			return;
		}

		const {report, onClose, onRunReport, transport} = this.props;

		if (report.id) {
			await transport.services.feature.updateReport(report);
			onClose(report.id);
			onRunReport(report);
		} else {
			const reports = await transport.services.feature.create<Report>(report.serializeData(), XyiconFeature.Report);
			const firstReport = reports[0];

			if (firstReport) {
				onClose(firstReport.id);
				onRunReport(firstReport);
			}
		}
	};

	// Note little copy-paste from RunReportPage
	private onSaveAsClick = async () => {
		if (!this.validateReport()) {
			return;
		}

		const {report, transport} = this.props;
		const reportName = report.name;

		const placeholder = `${reportName} - copy`;

		let name = await PromptWindow.open("Please enter the new name you want this to be saved with", `Save ${reportName} As`, placeholder);

		if (name !== null) {
			name = name || placeholder;
			const newReport = report.duplicate(name);

			const models = await transport.services.feature.create(newReport.serializeData(), XyiconFeature.Report);

			this.props.onClose(models?.[0]?.id);
		}
	};

	private validateReport() {
		const {report} = this.props;
		let errorMessage = "";

		if (!report.name) {
			errorMessage = "Please specify a name for your report!";
		}
		if (report.scope === ReportScope.PortfolioIDList && report.portfolioIDList?.length < 1) {
			errorMessage = "Portfolio list cannot be empty!";
		}

		if (errorMessage) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Error,
				title: "Warning",
				description: errorMessage,
			});
		}

		return !errorMessage;
	}

	private onSelectStep = (index: number) => {
		const {report} = this.props;
		const {stepIndex} = this.state;

		if (stepIndex === 0 && !this._pages[stepIndex].component.isValid(report)) {
			return;
		}

		this.setState({stepIndex: index});
	};

	public override render() {
		const {report} = this.props;
		const {stepIndex} = this.state;

		const page = this._pages[stepIndex];
		const backButtonLabel = stepIndex === 0 ? "Cancel" : "Back";
		const Component = page.component;
		const isValid = Component.isValid ? Component.isValid(report) : true;

		return (
			<div className="ReportWizard">
				<div className="sidePanelButtons">
					<div className="title">
						<div className="step">Step {stepIndex + 1}:</div>
						<div>{page.title}</div>
					</div>
					<div className="flex_1" />
					<Button
						className="secondary"
						label={backButtonLabel}
						title={backButtonLabel}
						onClick={this.onBackClick}
					/>
					{stepIndex < this._pages.length - 1 && (
						<Button
							className="secondary"
							label="Next"
							title="Next"
							onClick={this.onNextClick}
							disabled={!isValid}
						/>
					)}
					<MultiActionButton
						disabled={!isValid}
						className="primary"
						options={[
							{
								id: "saveAndRun",
								label: "Save & Run",
								onClick: this.onSaveAndRunClick,
							},
							{
								id: "save",
								label: "Save",
								onClick: this.onSaveClick,
							},
						]}
					/>
				</div>

				<StepIndicator
					onStepClick={this.onSelectStep}
					currentStepIndex={stepIndex}
					steps={this._pages.map((page) => page.stepTitle)}
				/>
				<div className="reportPage">
					<Component report={report} />
				</div>
			</div>
		);
	}
}

import {SelectInput} from "../select/SelectInput";
import type {DistanceUnitName, ExtendedDistanceUnitName, IDistanceUnit} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {Formatter} from "../../../../utils/format/Formatter";

interface IUnitSelectorProps {
	unit: DistanceUnitName | ExtendedDistanceUnitName;
	addFeetAndInches?: boolean;
	isDark?: boolean;
	optionsZIndex?: number;
	onChange: (unitName: ExtendedDistanceUnitName) => void;
}

export function UnitSelector(props: IUnitSelectorProps) {
	const units: IDistanceUnit[] = [];

	for (const key in Constants.DISTANCE_UNITS) {
		const typedKey = key as ExtendedDistanceUnitName;
		const unit = Constants.DISTANCE_UNITS[typedKey];

		if (typedKey !== "foot&inch" || props.addFeetAndInches) {
			units.push(unit);
		}
	}

	return (
		<SelectInput
			sort={false}
			options={units}
			isDark={props.isDark}
			selected={Constants.DISTANCE_UNITS[props.unit]}
			onChange={(unit: IDistanceUnit) => props.onChange(unit.name)}
			render={(item: IDistanceUnit) => Formatter.capitalize(item.plural)}
			optionsZIndex={props.optionsZIndex}
		/>
	);
}

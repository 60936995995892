import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {CreatePortfolioPermissionSetRequest} from "../../../../../generated/api/base";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ButtonV5} from "../../../button/ButtonV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {GeneralPermissionsV5} from "./GeneralPermissionsV5";

const CreatePermissionSetPanelStyled = styled.div`
	padding-left: 8px;
	padding-right: 8px;

	.primary {
		width: 70px;
		margin-left: 217px;
	}
`;

interface ICreatePermissionSetPanelProps {
	onClose: (createdId?: string) => void;
	appState?: AppState;
	transport?: TransportLayer;
	open?: boolean;
}

interface ICreatePermissionSetPanelState {
	name: string;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePermissionSetPanelV5 extends React.PureComponent<ICreatePermissionSetPanelProps, ICreatePermissionSetPanelState> {
	constructor(props: ICreatePermissionSetPanelProps) {
		super(props);
		this.state = {
			name: "",
		};
	}

	private onCreateClicked = async () => {
		const {name} = this.state;

		const data: CreatePortfolioPermissionSetRequest = {
			name: name,
			portfolioFieldPermissions: [],
			portfolioPermission: GeneralPermissionsV5.generalPermissionOptions[0].id,
			featurePermissions: [],
		};
		const models = await this.props.transport.services.feature.create<PermissionSet>(data, XyiconFeature.PermissionSet);

		this.setState({name: ""});

		this.props.onClose(models?.[0]?.id);
	};

	private isValid = (value: string) => {
		return this.props.appState.actions.isPermissionSetNameValid(value, "");
	};

	private onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			this.onCreateClicked();
		}
	};

	private onTextInput = (value: string) => {
		this.setState({name: value});
	};

	private onCancel = () => {
		this.setState({name: ""});
		this.props.onClose();
	};

	public override render() {
		const {open} = this.props;
		const {name} = this.state;

		const isFormValid = name && this.isValid(name);

		return (
			<CreatePermissionSetPanelStyled>
				<ClickToEditInputV5
					value={name}
					onLiveChange={this.onTextInput}
					valueValidator={this.isValid}
					focused={open}
					placeholder="Name Value"
				/>
				<br />
				<ButtonV5
					label="Confirm"
					title="Create"
					onClick={this.onCreateClicked}
					disabled={!isFormValid}
					className="primary"
				/>
			</CreatePermissionSetPanelStyled>
		);
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import type {DocumentModel} from "../../../../data/models/DocumentModel";
import {IconButton} from "../../../widgets/button/IconButton";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {isImage} from "../../../../data/models/FileType";

export interface IDocumentExplorerProps {
	document: DocumentModel;
	documents: DocumentModel[];
	onClose?: () => void;
	transport?: TransportLayer;
}

interface IDocumentExplorerState {
	index: number;
}

@inject("transport")
export class DocumentExplorer extends React.PureComponent<IDocumentExplorerProps, IDocumentExplorerState> {
	constructor(props: IDocumentExplorerProps) {
		super(props);

		this.state = {
			index: this.documents.indexOf(props.document),
		};
	}

	private get documents() {
		return this.props.documents.filter((document) => isImage(document.fileExtension));
	}

	private onPrevClick = () => {
		this.setState((prevState) => ({
			index: MathUtils.mod(prevState.index - 1, this.documents.length),
		}));
	};

	private onNextClick = () => {
		this.setState((prevState) => ({
			index: MathUtils.mod(prevState.index + 1, this.documents.length),
		}));
	};

	private onDownloadClick = () => {
		this.props.transport.services.document.download(this.getCurrentDocument());
	};

	private onCloseClick = () => {
		this.props.onClose();
	};

	private getCurrentDocument() {
		return this.documents[this.state.index] || this.documents[0];
	}

	public override render() {
		const {transport} = this.props;
		const {index} = this.state;
		const document = this.getCurrentDocument();

		const imagePath = transport.services.document.getFilePath(document);

		return (
			<div className="DocumentExplorer">
				<IconButton
					className="button left"
					icon="angle_left"
					onClick={this.onPrevClick}
				/>
				<div className="content">
					<div className="header">
						<div className="topLeft">{document.fileName}</div>
						<div className="topRight">
							<div className="hbox">
								<IconButton
									className="button download"
									icon="download"
									onClick={this.onDownloadClick}
								/>
								{/*<IconButton className="button zoomIn" icon="zoom-in"/>*/}
								{/*<IconButton className="button zoomOut"icon="zoom-out"/>*/}
								<IconButton
									className="button close"
									icon="close"
									onClick={this.onCloseClick}
								/>
							</div>
							<div className="date">{DateFormatter.format(document.lastModifiedBy)}</div>
						</div>
					</div>
					<div className="preview hbox alignCenter justifyCenter">
						<img
							src={imagePath}
							key={imagePath} // To clear previous image when navigating
						/>
					</div>
					<div className="bottomRight">
						Showing {index + 1} of {this.documents.length} items
					</div>
				</div>
				<IconButton
					className="button right"
					icon="angle_right"
					onClick={this.onNextClick}
				/>
			</div>
		);
	}
}

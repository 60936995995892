import {computed, observable, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {XyiconFeature} from "../../generated/api/base";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import type {PortDataDto, XyiconDto, Dimensions} from "../../generated/api/base";
import type {SpaceItemType} from "../../ui/modules/space/spaceeditor/logic3d/managers/spaceitems/ItemManager";
import type {Catalog} from "./Catalog";
import type {Link} from "./Link";
import type {Space} from "./Space";
import type {IModel, ISpaceItemModel} from "./Model";

export class Xyicon implements ISpaceItemModel, IXyiconMinimumData, IModel, XyiconDto {
	public static search(xyicon: Xyicon, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();
		const refId = xyicon.refId.toLowerCase();

		if (refId.includes(search)) {
			return true;
		}

		return false;
	}

	public readonly ownFeature = XyiconFeature.Xyicon;

	@observable
	private _data: XyiconDto;
	private _appState: AppState;

	constructor(data: XyiconDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data);
	}

	public applyData(data: Partial<XyiconDto>) {
		if (typeof data.settings === "string") {
			data.settings = JSON.parse(data.settings);
		}

		this._data = {
			...this._data,
			...data,
		};

		this._data.fieldData = this._data.fieldData || {};

		if (!this._data.portData) {
			this._data.portData = [];
		}

		if (!this._data.settings) {
			this._data.settings = {};
		}
	}

	public applyUpdate(data: IXyiconUpdate) {
		if (data.xyiconIDList.includes(this.id)) {
			this._data.lastModifiedAt = data.lastModifiedAt || this._data.lastModifiedAt;
			this._data.lastModifiedBy = data.lastModifiedBy || this._data.lastModifiedBy;
		}
	}

	public addPortData(portDataParam: PortDataDto) {
		const portLabelObject = this._data.portData.find((portDataLabel: PortDataDto) => portDataLabel.id === portDataParam.id);

		if (portLabelObject) {
			portLabelObject.label = portDataParam.label;
		} else {
			this._data.portData.push({...portDataParam});
		}
	}

	public get settings() {
		return this._data.settings || {};
	}

	public get isFlippedX(): boolean {
		return !!this.settings.isFlippedX;
	}

	public get isFlippedY(): boolean {
		return !!this.settings.isFlippedY;
	}

	public get ports() {
		const ports = ObjectUtils.deepClone(this.catalog.portTemplate);
		const labelOverrides = this.portData;

		for (const labelOverride of labelOverrides) {
			const endPoint = ports.find((c) => c.id === labelOverride.id);

			// label override can be applied to middle-layer-ports as well, not just endpoints
			if (endPoint) {
				endPoint.label = labelOverride.label;
			}
		}

		return ports;
	}

	@computed
	public get id() {
		return this._data.xyiconID;
	}

	@computed
	public get refId() {
		return this._data.xyiconRefID;
	}

	public set typeId(value: string) {
		this._data.xyiconTypeID = value;
	}

	@computed
	public get typeId() {
		return this._data.xyiconTypeID;
	}

	public setSpaceId(spaceId: string) {
		this._data.spaceID = spaceId;
		for (const child of this.embeddedXyicons) {
			child.setSpaceId(spaceId);
		}
	}

	@computed
	public get spaceId(): string {
		// If it has a parent, use its spaceId. In theory, embedded xyicons should be in the same space as their parents, but there might be corrupt data
		// Note that `return this.parentXyicon?.spaceId || this._data.spaceID` is wrong, because parentXyicon might be unplotted, so it's spaceId === null,
		// and in that case `this._data.spaceID` is returned
		const parentXyicon = this.parentXyicon;

		if (parentXyicon) {
			if (parentXyicon.parentXyicon === this) {
				return this._data.spaceID;
			}
		}

		return parentXyicon ? parentXyicon.spaceId : this._data.spaceID;
	}

	@computed
	public get space() {
		const spaceId = this.spaceId;

		return spaceId ? this._appState.actions.getFeatureItemById<Space>(spaceId, XyiconFeature.Space) : null;
	}

	public set catalogId(value: string) {
		this._data.xyiconCatalogID = value;
	}

	@computed
	public get catalogId() {
		return this._data.xyiconCatalogID;
	}

	public setPosition(x: number, y: number, z: number) {
		this._data.iconX = x;
		this._data.iconY = y;
		this._data.iconZ = z;
	}

	public setOrientation(orientation: number) {
		this._data.orientation = orientation;
	}

	public unplot() {
		this.setSpaceId(null);
	}

	@computed
	public get iconX() {
		return this._data.iconX;
	}

	@computed
	public get iconY() {
		return this._data.iconY;
	}

	@computed
	public get iconZ() {
		return this._data.iconZ || 0;
	}

	@computed
	public get position(): Dimensions {
		return {
			x: this.iconX,
			y: this.iconY,
			z: this.iconZ,
		};
	}

	@computed
	public get orientation() {
		return this._data.orientation;
	}

	@computed
	public get iconCategory() {
		return this._data.iconType;
	}

	@computed
	public get fieldData() {
		return this._data.fieldData;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get catalog() {
		const catalog = this._appState.actions.getFeatureItemById(this.catalogId, XyiconFeature.XyiconCatalog) as Catalog;

		if (!catalog) {
			console.warn(
				`Catalog doesn't exist for this xyicon: guid: ${this.id}, refId: ${this.refId}\nIt's either deleted, or maybe the catalog hasn't been loaded before we tried to access the xyicon.`,
			);
		}

		return catalog;
	}

	@computed
	public get type() {
		return this._appState.actions.getTypeById(this.typeId);
	}

	@computed
	public get typeName() {
		return this.type?.name || "";
	}

	private get sanitizedId() {
		// in some cases, we have some postfix after the id, eg.: "_ghost"
		return this.id.split("_")[0];
	}

	@computed
	public get embeddedXyicons() {
		const itemId = this.sanitizedId;
		const xyiconsForXyicon: {link: Link; object: Xyicon}[] = this._appState.actions.getLinksXyiconXyicon(itemId);

		const embeddedXyicons: Xyicon[] = [];

		for (const obj of xyiconsForXyicon) {
			const {link, object} = obj;
			const isSelectedTypeFrom = itemId === link.fromObjectId;

			if (isSelectedTypeFrom && link.isEmbedded) {
				embeddedXyicons.push(object);
			}
		}

		return embeddedXyicons;
	}

	@computed
	public get parentXyicon() {
		const itemId = this.sanitizedId; // in some cases, we have some postfix after the id, eg.: "_ghost"
		const xyiconsForXyicon: {link: Link; object: Xyicon}[] = this._appState.actions.getLinksXyiconXyicon(itemId);

		for (const obj of xyiconsForXyicon) {
			const {link, object} = obj;
			const isSelectedTypeTo = itemId === link.toObjectId;

			if (isSelectedTypeTo && link.isEmbedded) {
				return object;
			}
		}

		return null;
	}

	@computed
	public get parentXyiconRefId() {
		return this.parentXyicon?.refId || "";
	}

	@computed
	public get tempId() {
		return this.metaData?.tempId || null; // created by copied the xyicon with this refId
	}

	@computed
	public get tempGuid() {
		return this.metaData?.guid || null; // created by copied the xyicon with this guid
	}

	@computed
	private get metaData() {
		return this._data.settings?.metadata;
	}

	@computed
	public get model() {
		return this.catalog?.model || "";
	}

	@computed
	public get icon() {
		return this.catalog?.icon;
	}

	@computed
	public get thumbnail() {
		return this.catalog?.thumbnail || "";
	}

	@computed
	public get backgroundTransform(): string {
		const transforms: string[] = [];

		if (this.isFlippedX) {
			transforms.push("scaleX(-1)");
		}
		if (this.isFlippedY) {
			transforms.push("scaleY(-1)");
		}

		return transforms.join(" ");
	}

	@computed
	public get isEmbedded() {
		return !!this.parentXyicon;
	}

	@computed
	public get isExternal() {
		const activeSpaceId = this._appState.space?.id;
		const xyiconSpaceId = this.spaceId;

		return activeSpaceId && xyiconSpaceId && activeSpaceId !== xyiconSpaceId;
	}

	@computed
	public get portData() {
		return this._data.portData.filter((data) => !!data.label);
	}

	@computed
	public get isUnplotted() {
		return !this.spaceId;
	}

	@computed
	public get data() {
		return this._data;
	}

	public get portfolioId() {
		return this._data.portfolioID;
	}

	public get spaceItemType(): SpaceItemType {
		return "xyicon";
	}
}

/**
 * The minimum necessary data to create a xyicon in space
 */
export interface IXyiconMinimumData extends XyiconDto {
	guid?: string; // for cut-paste
	tempId?: string;
	tempParentXyiconId?: string;
	iconX: number;
	iconY: number;
	iconZ: number;
	orientation: number;
	catalogId: string;
	catalog?: Catalog;
	embeddedXyicons: Xyicon[];
	parentXyicon: IXyiconMinimumData | null;
	fieldData: Record<string, any> | null;
	portData: PortDataDto[];
}

interface IXyiconUpdate {
	fieldValues?: IFieldValues;
	lastModifiedAt?: string;
	lastModifiedBy?: string;
	xyiconIDList?: string[];
}

interface IFieldValues {
	[refId: string]: string;
}

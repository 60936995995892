import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Catalog} from "../../../../../../../data/models/Catalog";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {AppState} from "../../../../../../../data/state/AppState";
import {FieldDataType, Permission, XyiconFeature} from "../../../../../../../generated/api/base";
import {ClickToEditInput} from "../../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";
import {StringUtils} from "../../../../../../../utils/data/string/StringUtils";
import {Functions} from "../../../../../../../utils/function/Functions";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import type {Type} from "../../../../../../../data/models/Type";
import {ConfirmWindow} from "../../../../popups/ConfirmWindow";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";

interface IPortfolioDefaultFieldsProps {
	item: Catalog;
	transport?: TransportLayer;
	appState?: AppState;
}

interface IPortfolioDefaultFieldsState {
	updating: boolean;
}

@inject("transport")
@inject("appState")
@observer
export class CatalogDefaultFields extends React.Component<IPortfolioDefaultFieldsProps, IPortfolioDefaultFieldsState> {
	constructor(props: IPortfolioDefaultFieldsProps) {
		super(props);
		this.state = {
			updating: false,
		};
	}

	private isNameValid = (value: string) => {
		return this.props.appState.actions.isModelValidForCatalog(value, this.props.item);
	};

	private onModelChange = async (value: string) => {
		value = StringUtils.removeWhiteSpaces(value);
		const {item, transport} = this.props;

		item.setModel(value);

		this.setState({updating: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateFields([item], {model: value}), this.props.appState.app.notificationContainer);
		this.setState({updating: false});
	};

	private onTypeChange = async (type: Type) => {
		const {item} = this.props;
		const xyicons = this.props.appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((xyicon) => xyicon.model === item.model);

		const title = "Confirm Type Change";
		const message = `Once you change the catalog type, fields (and data) not assigned to the new catalog type will be removed. There are ${xyicons.length} xyicons that will be updated with the new catalog type and assigned fields.  Do you wish to continue?`;
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed) {
			item.typeId = type.id;
			const result = await this.props.transport.updateCatalogType(item);

			if (result) {
				xyicons.forEach((xyicon) => (xyicon.typeId = type.id));
			}
		}
	};

	private get catalogPermission() {
		return this.props.appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	}

	public override render() {
		const {item, appState} = this.props;
		const {updating} = this.state;
		const permission = this.catalogPermission;

		return (
			<>
				<Field label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</Field>
				<Field
					className="TypeSelector"
					label="Type"
				>
					<SelectInput
						options={appState.types[XyiconFeature.Xyicon]}
						render={(item) => item.name}
						selected={appState.actions.getTypeById(item.typeId)}
						searchBar={true}
						onChange={this.onTypeChange}
						disabled={permission <= Permission.View}
					/>
				</Field>
				<Field label="Model">
					<ClickToEditInput
						value={item.model}
						onChange={this.onModelChange}
						dataType={FieldDataType.SingleLineText}
						updating={updating}
						disabled={permission <= Permission.View}
						onBlur={Functions.emptyFunction}
						valueValidator={this.isNameValid}
						noButtons={true}
					/>
				</Field>
			</>
		);
	}
}

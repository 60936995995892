import * as React from "react";
import {observer} from "mobx-react";
import type {XyiconManager} from "../../logic3d/managers/spaceitems/XyiconManager";
import type {BoundaryManager} from "../../logic3d/managers/spaceitems/BoundaryManager";
import type {SupportedFontName} from "../../logic3d/managers/MSDF/TextGroupManager";
import type {Color} from "../../../../../../generated/api/base";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {XyiconFeature} from "../../../../../../generated/api/base";
import {CheckboxInput} from "../../../../../widgets/input/checkbox/CheckboxInput";
import {ToggleContainer} from "../../../../../widgets/container/ToggleContainer";
import {Button} from "../../../../../widgets/button/Button";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import type {ICaptionConfig} from "../../../../../../data/models/ViewUtils";
import {SaveToViewButton} from "../../../../abstract/common/savetoview/SaveToViewButton";
import {TabChild} from "../../../../../widgets/tab/TabChild";
import {TabView} from "../../../../../widgets/tab/TabView";
import {featureTitles} from "../../../../../../data/state/AppStateConstants";
import {IndividualCaptionStyle} from "./IndividualCaptionStyle";
import {CaptionSettings} from "./CaptionSettings";
import type {IOverlayPanelProps} from "./LayerPanel";

interface ICaptionPanelState {
	isOpen: boolean;
	searchString: string;
	isLoading: boolean;
}

@observer
export class CaptionPanel extends React.Component<IOverlayPanelProps, ICaptionPanelState> {
	constructor(props: IOverlayPanelProps) {
		super(props);
		this.state = {
			isOpen: false,
			searchString: "",
			isLoading: false,
		};
	}

	private get selectedSpaceEditorView() {
		return this.props.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private getCaptionSettings(itemType: "boundary" | "xyicon") {
		return this.getCaptionConfig()[itemType];
	}

	private getCaptionConfig() {
		return this.props.view.spaceEditorViewSettings.captions;
	}

	private getSavedCaptionConfig(): ICaptionConfig {
		return this.props.view.getSavedSpaceEditorViewSettings()?.captions || this.props.view.getDefaultCaptionConfig();
	}

	private onCaptionFontColorChange = (newColor: Color) => {
		this.getCaptionSettings("boundary").fontColor = newColor;
		this.getCaptionSettings("xyicon").fontColor = newColor;

		if (this.selectedSpaceEditorView === this.props.view) {
			this.props.spaceViewRenderer.boundaryManager.captionManager.updateTextTransformations();
			this.props.spaceViewRenderer.xyiconManager.captionManager.updateTextTransformations();
		}
	};

	private onCaptionBackgroundColorChange = (newColor: Color) => {
		this.getCaptionSettings("boundary").backgroundColor = newColor;
		this.getCaptionSettings("xyicon").backgroundColor = newColor;

		if (this.selectedSpaceEditorView === this.props.view) {
			this.props.spaceViewRenderer.boundaryManager.captionManager.updateTextTransformations();
			this.props.spaceViewRenderer.xyiconManager.captionManager.updateTextTransformations();
		}
	};

	private updateCaptionsIfNecessary() {
		if (this.selectedSpaceEditorView === this.props.view) {
			const {boundaryManager, xyiconManager} = this.props.spaceViewRenderer;

			this.updateCaptions([boundaryManager, xyiconManager]);
		}
	}

	private onCaptionFontSizeChange = (newFontSize: number) => {
		this.getCaptionSettings("boundary").fontSize = newFontSize;
		this.getCaptionSettings("xyicon").fontSize = newFontSize;

		this.updateCaptionsIfNecessary();
	};

	private onCaptionFontFamilyChange = (newFontFamily: SupportedFontName) => {
		this.getCaptionSettings("boundary").fontFamily = newFontFamily;
		this.getCaptionSettings("xyicon").fontFamily = newFontFamily;

		this.updateCaptionsIfNecessary();
	};

	private onCaptionIsBoldChange = (isBold: boolean) => {
		this.getCaptionSettings("boundary").isBold = isBold;
		this.getCaptionSettings("xyicon").isBold = isBold;

		this.updateCaptionsIfNecessary();
	};

	private onCaptionIsItalicChange = (isItalic: boolean) => {
		this.getCaptionSettings("boundary").isItalic = isItalic;
		this.getCaptionSettings("xyicon").isItalic = isItalic;

		this.updateCaptionsIfNecessary();
	};

	private onCaptionIsUnderlinedChange = (isUnderlined: boolean) => {
		this.getCaptionSettings("boundary").isUnderlined = isUnderlined;
		this.getCaptionSettings("xyicon").isUnderlined = isUnderlined;

		this.updateCaptionsIfNecessary();
	};

	private activeItemManager(feature: XyiconFeature) {
		return feature === XyiconFeature.Xyicon ? this.props.spaceViewRenderer.xyiconManager : this.props.spaceViewRenderer.boundaryManager;
	}

	private updateCaptions(itemManagers: (XyiconManager | BoundaryManager)[]) {
		this.setState({
			isLoading: true,
		});
		// Make sure we show the change in the UI before doing any heavy process that can freeze up the app for a long time
		requestAnimationFrame(() => {
			requestAnimationFrame(async () => {
				for (const itemManager of itemManagers) {
					await itemManager.captionManager.updateCaptions();
				}

				requestAnimationFrame(() => {
					if (this.state.isLoading) {
						this.setState({
							isLoading: false,
						});
					}
				});
			});
		});
	}

	private getLayoutFieldIds(feature: XyiconFeature) {
		const fieldIds: string[] = [];
		const layout = this.props.spaceViewRenderer.actions.getLayout(feature);

		if (layout?.sections) {
			for (const section of layout.sections) {
				for (const field of section.fields) {
					if (!fieldIds.includes(field.id)) {
						fieldIds.push(field.id);
					}
				}
			}
		}

		return fieldIds;
	}

	private getFieldsWithCheckboxes(feature: XyiconFeature) {
		const fieldIdsInLayout = this.getLayoutFieldIds(feature);
		const fields = this.props.spaceViewRenderer.actions
			.getFieldsByFeature(feature, true)
			.filter((field: IFieldAdapter) => fieldIdsInLayout.includes(field.refId));

		// some fields are not present in the layout section, like id, type, etc.
		// Add own (non inherited) fields if they're not added yet
		const ownFields = this.props.spaceViewRenderer.actions.getFieldsByFeature(feature, false);

		for (const field of ownFields) {
			if (!fields.includes(field)) {
				fields.push(field);
			}
		}

		const itemManager = this.activeItemManager(feature);
		const itemType = feature === XyiconFeature.Xyicon ? "xyicon" : "boundary";
		const captionSettingsMaybe = this.getCaptionSettings(itemType);
		const captionFields = captionSettingsMaybe?.checkList || [];
		const sortedElements = fields.sort((a: IFieldAdapter, b: IFieldAdapter) => StringUtils.sortIgnoreCase(a.name, b.name));
		const fieldNames = fields.map((field) => field.name);

		const elements = sortedElements
			.filter((field: IFieldAdapter) => field.name !== "Icon")
			.filter((field: IFieldAdapter) => StringUtils.containsIgnoreCase(field.name, this.state.searchString))
			.map((field: IFieldAdapter, index: number) => {
				const needModuleName = fieldNames.filter((name) => name === field.name).length > 1;
				const captionLabel = `${field.name}${needModuleName ? ` (${featureTitles[field.feature]})` : ""}`;

				return (
					<div
						className="hbox checkBoxContainer alignCenter"
						key={field.refId}
					>
						<CheckboxInput
							label={field.name}
							disabled={this.state.isLoading}
							value={captionFields.includes(field.refId)}
							onChange={(value: boolean) => {
								if (value) {
									captionFields.push(field.refId);
								} else {
									const index = captionFields.indexOf(field.refId);

									if (index > -1) {
										captionFields.splice(index, 1);
									}
								}

								if (this.selectedSpaceEditorView === this.props.view) {
									this.updateCaptions([itemManager]);
								}
							}}
						/>
						<div className="checkBoxLabel">{captionLabel}</div>
						<IndividualCaptionStyle
							appState={this.props.spaceViewRenderer.transport.appState}
							captionLabel={captionLabel}
							fieldRefId={field.refId}
							captionSettings={captionSettingsMaybe}
						/>
					</div>
				);
			});

		return elements;
	}

	private isResetButtonEnabled() {
		const captionConfig = this.getCaptionConfig();

		return JSON.stringify(captionConfig) !== JSON.stringify(this.getSavedCaptionConfig());
	}

	private onResetClick = async () => {
		this.props.view.spaceEditorViewSettings.captions = this.getSavedCaptionConfig();
		await this.props.spaceViewRenderer.boundaryManager.captionManager.updateCaptions();
		await this.props.spaceViewRenderer.xyiconManager.captionManager.updateCaptions();
	};

	private onSearchInput = (searchString: string) => {
		this.setState({
			searchString: searchString,
		});
	};

	public override componentWillUnmount() {
		this.setState({
			isOpen: false,
		});
	}

	public override componentDidMount() {
		this.setState({
			isOpen: true,
		});
	}

	public override render() {
		return (
			<div className={ReactUtils.cls("CaptionPanel overlayPanel", {open: this.state.isOpen, disabled: this.state.isLoading})}>
				<TabView
					selectedTabId="captions"
					onClose={this.props.onToggle}
				>
					<TabChild
						id="captions"
						icon="captions"
						title="Captions"
						label="Captions"
					>
						<div className="topButtons hbox">
							<SaveToViewButton
								feature={XyiconFeature.SpaceEditor}
								viewChangeType="captions"
							/>
							<div className="flex_1" />
							<Button
								className="secondary"
								label="Reset"
								disabled={!this.isResetButtonEnabled()}
								onClick={this.onResetClick}
								icon="reset"
							/>
						</div>
						<div className="container">
							<div className="head hbox">
								<SearchField
									value={this.state.searchString}
									onInput={this.onSearchInput}
								/>
							</div>
							<div className="checkBoxes">
								<ToggleContainer
									title="Boundaries"
									open={true}
								>
									{this.getFieldsWithCheckboxes(XyiconFeature.Boundary)}
								</ToggleContainer>
								<ToggleContainer
									title="Xyicons"
									open={true}
								>
									{this.getFieldsWithCheckboxes(XyiconFeature.Xyicon)}
								</ToggleContainer>
							</div>
							<ToggleContainer
								title="Caption Settings"
								open={true}
								className="CaptionSettingsContainer"
							>
								<CaptionSettings
									captionSettings={this.getCaptionSettings("xyicon")}
									isLoading={this.state.isLoading}
									onCaptionBackgroundColorChange={this.onCaptionBackgroundColorChange}
									onCaptionFontColorChange={this.onCaptionFontColorChange}
									onCaptionFontSizeChange={this.onCaptionFontSizeChange}
									onCaptionFontFamilyChange={this.onCaptionFontFamilyChange}
									onCaptionIsBoldChange={this.onCaptionIsBoldChange}
									onCaptionIsItalicChange={this.onCaptionIsItalicChange}
									onCaptionIsUnderlinedChange={this.onCaptionIsUnderlinedChange}
									selectedSpaceEditorView={this.selectedSpaceEditorView}
									view={this.props.view}
									spaceViewRenderer={this.props.spaceViewRenderer}
								/>
							</ToggleContainer>
						</div>
					</TabChild>
				</TabView>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../data/state/AppState";
import type {Space} from "../../../../data/models/Space";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {XyiconFeature} from "../../../../generated/api/base";
import type {SpaceToPDFExportType} from "../../../modules/space/spaceeditor/ui/viewbar/SpaceToPDFExporter";
import {SpaceToPDFExporter} from "../../../modules/space/spaceeditor/ui/viewbar/SpaceToPDFExporter";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {ReactUtils} from "../../../utils/ReactUtils";
import CloseIcon from "../../icons/xmark.svg?react";
import {colorPalette} from "../../styles/colorPalette";
import {radius} from "../../styles/styles";
import {SearchFieldV5} from "../../input/search/SearchFieldV5";
import {ButtonV5} from "../../button/ButtonV5";
import {IconButtonStyled, IconButtonV5} from "../../interaction/IconButtonV5";
import {SpaceExportWindowV5} from "../../popup/SpaceExportWindowV5";
import {CheckboxInputV5} from "../../details/datatypes/CheckboxInputV5";
import {LoaderIconV5} from "../../loader/LoaderIconV5";
import {PopupBackdropV5} from "../../popup/PopupBackdropV5";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";

interface ISpaceToPDFExportPanelProps {
	appState?: AppState;
	onClose: () => void;
}

interface ISpaceToPDFExportPanelState {
	checkedSpaceIds: Set<string>;
	searchString: string;
	isLoading: boolean;
	exportingProgress: number;
}

@inject("appState")
@observer
export class SpaceToPDFExportPanelV5 extends React.Component<ISpaceToPDFExportPanelProps, ISpaceToPDFExportPanelState> {
	private _checkboxAll = React.createRef<HTMLInputElement>();

	constructor(props: ISpaceToPDFExportPanelProps) {
		super(props);

		this.state = {
			checkedSpaceIds: new Set(this.getAllSpaces().map((s) => s.id)),
			searchString: "",
			isLoading: false,
			exportingProgress: 0,
		};
	}

	private sortSpace = (a: Space, b: Space) => {
		return StringUtils.sortIgnoreCase(a.name, b.name);
	};

	private getAllSpaces() {
		return this.props.appState.actions.getList<Space>(XyiconFeature.Space).slice().sort(this.sortSpace);
	}

	private onSpaceToggle = (spaceId: string, isChecked: boolean) => {
		const newSet = new Set(this.state.checkedSpaceIds);

		if (isChecked) {
			newSet.add(spaceId);
		} else {
			newSet.delete(spaceId);
		}

		this.setState({
			checkedSpaceIds: newSet,
		});
	};

	private onExportAsSingleFileClick = async () => {
		const isExported = await this.exportSpacesAsPDF("SingleFile");

		if (isExported) {
			this.props.onClose();
		}
	};

	private onExportAsMultipleFilesClick = async () => {
		const isExported = await this.exportSpacesAsPDF("MultipleFiles");

		if (isExported) {
			this.props.onClose();
		}
	};

	private getExportWindowData = (type: SpaceToPDFExportType, spacesToExport: Space[]): [string[], string[], number] => {
		const {portfolioId, actions} = this.props.appState;

		if (type === "SingleFile") {
			return [[""], [actions.getPortfolioById(portfolioId).name], 1];
		} else {
			const placeHolders: string[] = [];
			const defaultValues: string[] = [];

			spacesToExport.forEach((space) => {
				placeHolders.push("");
				defaultValues.push(space.name);
			});

			return [placeHolders, defaultValues, spacesToExport.length];
		}
	};

	private async exportSpacesAsPDF(type: SpaceToPDFExportType) {
		const spacesToExport = this.getAllSpaces().filter((s) => this.state.checkedSpaceIds.has(s.id));
		const [placeHolders, defaultValues, fileCount] = this.getExportWindowData(type, spacesToExport);
		const names = await SpaceExportWindowV5.open("", "Export file as", placeHolders, defaultValues, fileCount);

		if (!names) {
			return false;
		}

		this.setState({
			isLoading: true,
		});

		if (type === "SingleFile") {
			const pdfExporter = new SpaceToPDFExporter(this.props.appState);

			await pdfExporter.export("entire", spacesToExport, names[0], this.onPDFExportProgress);
		} else {
			for (let i = 0; i < spacesToExport.length; ++i) {
				const space = spacesToExport[i];
				const pdfExporter = new SpaceToPDFExporter(this.props.appState);

				await pdfExporter.export("entire", [space], names[i]);
				this.onPDFExportProgress((i + 1) / spacesToExport.length);
			}
		}

		this.setState({
			isLoading: false,
			exportingProgress: 0,
		});

		return true;
	}

	private onPDFExportProgress = (progress: number) => {
		this.setState({
			exportingProgress: progress,
		});
	};

	private areAllSpacesChecked = () => {
		const checkBox = this._checkboxAll.current;
		const allSpaces = this.getAllSpaces().length;
		const allCheckedSpaces = this.state.checkedSpaceIds.size;

		if (checkBox) {
			checkBox.indeterminate = allCheckedSpaces > 0 && allSpaces !== allCheckedSpaces;
		}

		return allSpaces === allCheckedSpaces;
	};

	private onToggleAll = (value: boolean) => {
		const newSet = new Set<string>(value ? this.getAllSpaces().map((s) => s.id) : []);

		this.setState({
			checkedSpaceIds: newSet,
		});
	};

	private onSearchChange = (value: string) => {
		this.setState({
			searchString: value,
		});
	};

	private isExportDisabled() {
		return this.state.isLoading || this.state.checkedSpaceIds.size === 0;
	}

	private areCheckboxesDisabled() {
		return this.state.isLoading;
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.onCloseClick();
				break;
		}
	};

	private onCloseClick = () => {
		if (!this.state.isLoading) {
			// We can't actually cancel the export progress, so it's better to disable it...
			this.props.onClose();
		}
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
	}

	public override render() {
		const isExportDisabled = this.isExportDisabled();
		const areCheckboxesDisabled = this.areCheckboxesDisabled();

		const rowClassNames = ReactUtils.cls("item hbox alignCenter", {disabled: areCheckboxesDisabled});

		return (
			<DomPortal destination={this.props.appState.app.modalContainer}>
				<PopupBackdropV5>
					<SpaceToPDFExportPanelStyled className={"SpaceToPDFExportPanel"}>
						<div className="heading hbox createBox">
							<h4 className="detailsTitle">{`Export Multiple Spaces from ${this.props.appState.actions.getCurrentPortfolioName()}`}</h4>
							<IconButtonV5
								IconComponent={CloseIcon}
								onClick={this.onCloseClick}
								disabled={this.state.isLoading}
							/>
						</div>
						<PortfolioName>{this.props.appState.actions.getCurrentPortfolioName()}</PortfolioName>
						<SearchFieldV5
							placeholder="Find in List..."
							value={this.state.searchString}
							onInput={this.onSearchChange}
						/>
						<div className="spaceList">
							<div className={rowClassNames}>
								<CheckboxInputV5
									inputRef={this._checkboxAll}
									value={this.areAllSpacesChecked()}
									onChange={this.onToggleAll}
									disabled={this.state.isLoading}
								/>
								<div className="label">
									<strong>All</strong>
								</div>
							</div>
							{this.getAllSpaces().map(
								(space: Space) =>
									StringUtils.containsIgnoreCase(space.name, this.state.searchString) && (
										<div
											className={rowClassNames}
											key={space.id}
										>
											<CheckboxInputV5
												value={this.state.checkedSpaceIds.has(space.id)}
												onChange={(value: boolean) => this.onSpaceToggle(space.id, value)}
												disabled={this.state.isLoading}
											/>
											<div className="label">{space.name}</div>
										</div>
									),
							)}
						</div>
						<div className="buttonWrapper hbox">
							{this.state.isLoading ? (
								<LoaderIndicator>
									<span>Exporting... {Math.round(this.state.exportingProgress * 100)}%</span>
									<LoaderIconV5 />
								</LoaderIndicator>
							) : (
								<>
									<ButtonV5
										type="secondary"
										label="Export as Multiple Files"
										onClick={this.onExportAsMultipleFilesClick}
										disabled={isExportDisabled || this.state.checkedSpaceIds.size < 2}
									/>
									<ButtonV5
										label="Export as Single File"
										onClick={this.onExportAsSingleFileClick}
										disabled={isExportDisabled}
									/>
								</>
							)}
						</div>
					</SpaceToPDFExportPanelStyled>
				</PopupBackdropV5>
			</DomPortal>
		);
	}
}

const LoaderIndicator = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const PortfolioName = styled.div`
	color: ${colorPalette.primary.c500Primary};
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
`;

const SpaceToPDFExportPanelStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 440px;
	padding: 16px;
	background-color: ${colorPalette.white};
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
	border-radius: ${radius.md};
	z-index: 1;

	.heading {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h4 {
			margin-right: 16px;
			line-height: 24px;
		}

		${IconButtonStyled} {
			min-width: 32px;
			min-height: 32px;
		}
	}
	.buttonWrapper {
		justify-content: flex-end;
		gap: 16px;
	}
	.spaceList {
		margin-left: 4px;
		max-height: 500px;
		overflow-y: auto;
		margin-top: 8px;
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		font-size: 14px;
		line-height: 16px;
		.item {
			display: flex;
			align-items: center;
			gap: 8px;
			&.disabled {
				opacity: 0.5;
			}
		}
	}
`;

import {useRef} from "react";
import styled from "styled-components";
import {useClickOutside} from "../utils";
import type {Boundary} from "../../../data/models/Boundary";
import {XyiconFeature} from "../../../generated/api/base";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import PinIcon from "../icons/pin.svg?react";
import CloseIcon from "../icons/xmark-large.svg?react";
import {useAppStore} from "../../../StateManager";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {colorPalette} from "../styles/colorPalette";

interface IBoundarySpaceMapDataSelectorProps {
	readonly item: Boundary;
	readonly onClose: () => void;
	readonly closePortfolioWideSearch?: () => void;
}

export const BoundarySpaceMapSelectorV5 = (props: IBoundarySpaceMapDataSelectorProps) => {
	const appState = useAppStore((state) => state.appState);
	const {item, onClose, closePortfolioWideSearch} = props;

	const _ref = useRef<HTMLDivElement>();

	const getSpaceNameById = (spaceId: string) => {
		return appState.actions.getFeatureItemById(spaceId, XyiconFeature.Space).name || "";
	};

	const onCloseLocal = (event: MouseEvent) => {
		event.stopPropagation();
		onClose();
	};

	useClickOutside([_ref], onCloseLocal);

	const getRows = () => {
		return item.boundarySpaceMaps
			.toSorted((a: BoundarySpaceMap, b: BoundarySpaceMap) => StringUtils.sortIgnoreCase(a.spaceName, b.spaceName))
			.map((boundarySpaceMap: BoundarySpaceMap, index: number) => (
				<div
					key={boundarySpaceMap.id}
					className="boundarySpaceMapRow hbox alignCenter"
					onClick={(event: React.MouseEvent<HTMLDivElement>) => {
						event.stopPropagation(); // workaround for this: in portfoliowidesearch, when you click this, its parent is also getting clicked, and the details panel opens
						appState.actions.navigateToSpaceItem(boundarySpaceMap, true);
						closePortfolioWideSearch?.();
					}}
				>
					<div
						className="thumbnail"
						style={{backgroundImage: `url(${boundarySpaceMap.thumbnail})`}}
					/>
					<div className="info vbox">{<div>{getSpaceNameById(boundarySpaceMap.spaceId)}</div>}</div>
					<PinIcon />
				</div>
			));
	};

	return (
		<BoundarySpaceMapSelectorStyled
			className="BoundarySpaceMapSelector window vbox"
			ref={_ref}
		>
			<div className="header">
				<div className="title">Merged with this boundary...</div>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={onClose}
				/>
			</div>
			<div className="boundaryContainer">{getRows()}</div>
		</BoundarySpaceMapSelectorStyled>
	);
};

const BoundarySpaceMapSelectorStyled = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	width: 350px;
	z-index: 1;

	.boundaryContainer {
		max-height: 400px;
		overflow-y: auto;
	}

	.boundarySpaceMapRow {
		margin-top: 3px;
		margin-bottom: 3px;
		height: 40px;
		border: 1px solid ${colorPalette.white};

		&:hover {
			border-color: ${colorPalette.primary.c500Primary};
		}

		.pin {
			width: 25px;
			height: 25px;
			margin-left: auto;
			margin-right: 15px;
		}
	}

	.thumbnail {
		min-width: 38px;
		min-height: 38px;
		margin-right: 10px;
	}
`;

import type {IModel} from "../../../../../../../data/models/Model";
import type {Field, IFieldAdapter} from "../../../../../../../data/models/field/Field";
import type {AppActions} from "../../../../../../../data/state/AppActions";
import {FieldDataType, XyiconFeature} from "../../../../../../../generated/api/base";
import CalculatedFieldIcon from "../../../../../../5.0/icons/calculated-field.svg?react";
import DOLFieldIcon from "../../../../../../5.0/icons/link-alt.svg?react";
import CustomValidationFieldIcon from "../../../../../../5.0/icons/custom-validation.svg?react";

export type FieldIconName = "" | "calculated-field" | "dol-field" | "custom-validation-badge";

export const getTooltipByIcon = (icon: FieldIconName) => {
	switch (icon) {
		case "dol-field":
			return "This field's value is inherited from another object.";
		case "calculated-field":
			return "This field's value is derived using a formula configured in Settings.";
		case "custom-validation-badge":
			return "This field's value will be validated according to the validation rule defined in Settings.";
	}

	return "";
};

export const getIconComponentFromFieldIconName = (icon: FieldIconName) => {
	// TODO: Import, and return the correct V5 icons!
	switch (icon) {
		case "dol-field":
			return DOLFieldIcon;
		case "calculated-field":
			return CalculatedFieldIcon;
		case "custom-validation-badge":
			return CustomValidationFieldIcon;
	}
};

export const getFieldIconAndInheritance = (field: IFieldAdapter, item: IModel, sectionFeature: XyiconFeature, actions: AppActions) => {
	let icon: FieldIconName = "";
	let isXyiconXyiconLink = false;
	let isInheritedFromBoundary = false;

	if (field.hasFormula && field.feature === sectionFeature) {
		icon = "calculated-field";
	} else if (field.hasValidation && field.feature === sectionFeature) {
		icon = "custom-validation-badge";
	} else if (field.displayOnLinks && item.fieldData) {
		//responsible for Module A to Module B (A !== B and B has inherited field) DOL fields icon
		if (!Object.keys(item.fieldData).includes(field.refId)) {
			icon = "dol-field";
			if (field.feature === XyiconFeature.Boundary) {
				isInheritedFromBoundary = true;
			}
		}

		//responsible for module's own DOL field not show the icon
		if (field.feature === item.ownFeature) {
			icon = "";
		}

		//responsible for Xyicon to Xyicon DOL fields correct icon showing
		if (item.ownFeature === XyiconFeature.Xyicon) {
			const links = actions.getLinksXyiconXyicon(item.id);

			if (
				links?.some((link) => {
					const isFieldAssignedToType = actions
						.getFieldsForType(link.object.typeId, field.feature)
						.find((obj) => obj.id === (field as unknown as Field).id)?.value;

					return (link.object.fieldData[field.refId] && isFieldAssignedToType) || field.dataType === FieldDataType.Boolean;
				})
			) {
				icon = "dol-field";
				if (field.feature === XyiconFeature.Xyicon) {
					isXyiconXyiconLink = true;
				}
			}
		}

		//responsible for Boundary to Boundary DOL fields correct icon showing
		if (item.ownFeature === XyiconFeature.Boundary) {
			const links = actions.getLinksBoundaryBoundary(item.id);

			if (links?.some((link) => link.object.fieldData[field.refId])) {
				icon = "dol-field";
			}
		}
	}

	return {icon, isXyiconXyiconLink, isInheritedFromBoundary};
};

import * as React from "react";
import {inject} from "mobx-react";
import type {Portfolio} from "../../../../data/models/Portfolio";
import {PieChart} from "../../../widgets/charts/pie/PieChart";
import {BarChart} from "../../../widgets/charts/bar/BarChart";
import {ReactUtils} from "../../../utils/ReactUtils";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature} from "../../../../generated/api/base";

interface IChartsViewProps {
	portfolios: Portfolio[];
	appState?: AppState;
}

interface IChartsViewState {
	selectedChart: string;
}

@inject("appState")
export class ChartsView extends React.Component<IChartsViewProps, IChartsViewState> {
	constructor(props: IChartsViewProps) {
		super(props);

		this.state = {
			selectedChart: "",
		};
	}

	private onExpand = (id: string) => {
		this.setState((prevState) => {
			let selectedChart = prevState.selectedChart;

			if (prevState.selectedChart === id) {
				selectedChart = "";
			} else {
				selectedChart = id;
			}

			return {
				selectedChart: selectedChart,
			};
		});
	};

	public override render() {
		const data = this.props.portfolios;
		const types = this.props.appState.types[XyiconFeature.Portfolio] || [];

		return (
			<div className="ChartsView hbox flex_1">
				{this.renderWidget("pie1", (onExpand) => (
					<PieChart
						data={data}
						onExpand={onExpand}
						labels={types.map((type) => ({
							title: type.name,
							selector: (portfolio: Portfolio) => portfolio.typeId === type.id,
						}))}
					/>
				))}
				{this.renderWidget("bar1", (onExpand) => (
					<BarChart
						data={data}
						onExpand={onExpand}
						labels={[
							{
								title: "Active",
								selector: (portfolio: Portfolio) => portfolio.status === "Active",
							},
							{
								title: "Deactivated",
								selector: (portfolio: Portfolio) => portfolio.status === "Deleted",
							},
						]}
					/>
				))}
			</div>
		);
	}

	private renderWidget(id: string, widgetCreator: (onExpand: () => void) => React.ReactNode) {
		const {selectedChart} = this.state;

		const isAnythingExpanded = !!selectedChart;
		const isExpanded = selectedChart === id;

		return (
			<div
				className={ReactUtils.cls("widgetContainer", {
					expanded: selectedChart === id,
					hidden: isAnythingExpanded && !isExpanded,
				})}
			>
				{widgetCreator(() => this.onExpand(id))}
			</div>
		);
	}
}

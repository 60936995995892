import * as React from "react";
import {ToggleSwitchField} from "./ToggleSwitchField";

interface ISwitchListProps {
	list: {
		id: string;
		label: string;
		value: boolean;
		disabled?: boolean;
	}[];
	onChange: (changes: ISwitchListChange[]) => void;
}

export interface ISwitchListChange {
	id: string;
	value: boolean;
}

export class SwitchList extends React.PureComponent<ISwitchListProps> {
	private onChangeAll = (value: boolean) => {
		const changes = this.props.list
			.filter((item) => !item.disabled)
			.map((item, index) => ({
				id: item.id,
				value: value,
			}));

		this.props.onChange(changes);
	};

	private getAllValue() {
		const {list} = this.props;

		if (list.every((item) => item.disabled)) {
			return false;
		}

		return !list.filter((item) => !item.disabled).find((item) => !item.value);
	}

	public override render() {
		const {list, onChange} = this.props;

		return (
			<div className="SwitchList">
				<ToggleSwitchField
					label="All"
					value={this.getAllValue()}
					onChange={this.onChangeAll}
					disabled={list.every((item) => item.disabled)}
				/>
				{list.map((item, index) => (
					<ToggleSwitchField
						key={index}
						label={item.label}
						value={item.value}
						disabled={item.disabled}
						onChange={(value) => {
							onChange([{id: item.id, value: value}]);
						}}
					/>
				))}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Field} from "../../../../../data/models/field/Field";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {XyiconFeature} from "../../../../../generated/api/base";
import {FieldForm} from "./FieldForm";

interface IFieldDetailsTabProps {
	selected: Field[];
	feature: XyiconFeature;
	typesFeature: XyiconFeature;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class FieldDetailsTab extends React.Component<IFieldDetailsTabProps> {
	public override render() {
		const {selected, typesFeature} = this.props;
		const field = selected[0];

		return (
			<>
				{selected.length === 0 && <div className="noData">Please select a field.</div>}
				{selected.length === 1 && (
					<FieldForm
						field={field}
						typesFeature={typesFeature}
					/>
				)}
			</>
		);
	}
}

import * as React from "react";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {TextAlignOptions} from "./TextAlignOptions";

interface ITextBoxAlignButtonProps {
	horizontalAlignment: HorizontalAlignment;
	verticalAlignment: VerticalAlignment;
	onTextAlignmentChange: (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => void;
}

interface ITextBoxAlignButtonState {
	isOpen: boolean;
}

export class TextBoxAlignButton extends React.Component<ITextBoxAlignButtonProps, ITextBoxAlignButtonState> {
	private _alignButton = React.createRef<HTMLSpanElement>();

	constructor(props: ITextBoxAlignButtonProps) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	private onTextAlignClick = () => {
		this.setState({isOpen: !this.state.isOpen});

		FocusLoss.listen(this._alignButton.current, this.onFocusLoss);
	};

	private onFocusLoss = () => {
		this.setState({isOpen: false});

		FocusLoss.stopListen(this._alignButton.current, this.onFocusLoss);
	};

	public override render() {
		const {horizontalAlignment, verticalAlignment, onTextAlignmentChange} = this.props;

		return (
			<span
				ref={this._alignButton}
				onClick={this.onTextAlignClick}
				className="button flexCenter textAlignButton"
				title="Align text..."
			>
				<div
					className="textAlignIcon"
					style={{
						justifyContent: `${TextAlignOptions.getFlexAttribForHorizontalAlignment(horizontalAlignment ?? HorizontalAlignment.left)}`,
						alignItems: `${TextAlignOptions.getFlexAttribForVerticalAlignment(verticalAlignment ?? VerticalAlignment.top)}`,
					}}
				>
					<div className="alignIndicator"></div>
				</div>
				<div className="arrowWrapper">
					<i className="arrowDown corner"></i>
				</div>
				{this.state.isOpen && <TextAlignOptions onChange={onTextAlignmentChange} />}
			</span>
		);
	}
}

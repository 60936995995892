import type {AppState} from "../../../state/AppState";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {FieldDataType} from "../../../../generated/api/base";
import {UserLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/user/UserLabel";
import {UserLabelV5} from "../../../../ui/5.0/details/datatypes/UserLabelV5";

export const configureUser = (appState: AppState) => {
	FieldDataTypes.configure({
		id: FieldDataType.User,
		title: "User",
		operatorsArray: stringOperators,
		formatter: (value) => {
			return appState.actions.renderName(value);
		},
		label: UserLabel,
		labelV5: UserLabelV5,
		system: true,
	});
};

import type {Dispatch, SetStateAction} from "react";
import {useEffect, useMemo, useReducer, useState} from "react";
import {useAppStore} from "../../../../../StateManager";
import {FileUtils} from "../../../../../utils/file/FileUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import ExportIcon from "../../../icons/file-arrow-right.svg?react";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import {SpaceToPDFExporter} from "../../../../modules/space/spaceeditor/ui/viewbar/SpaceToPDFExporter";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {SpaceExportWindowV5} from "../../../popup/SpaceExportWindowV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {SpaceToPDFExportPanelV5} from "../SpaceToPDFExportPanelV5";

const onExport = async (
	type: "png" | "jpg" | "pdf",
	area: "entire" | "visible",
	isMultiple: boolean,
	spaceViewRenderer: SpaceViewRenderer,
	setIsSpaceToPDFExportPanelOpen: Dispatch<SetStateAction<boolean>>,
	spaceToPDFExporter: SpaceToPDFExporter,
) => {
	// getting the file name as a 1 element array
	const nameAsArray = !isMultiple && (await SpaceExportWindowV5.open("", "Export file as", [""], [spaceViewRenderer.space.name], 1));

	if (isMultiple || nameAsArray) {
		return new Promise<boolean>((resolve, reject) => {
			switch (type) {
				case "png":
				case "jpg":
					const onSpaceRendered = () => {
						spaceViewRenderer.signals.onAfterRender.remove(onSpaceRendered);
						const data = spaceViewRenderer.canvas.toDataURL(`image/${type === "png" ? type : "jpeg"}`);

						FileUtils.downloadFileFromUrl(data, nameAsArray[0]);
						resolve(true);
					};

					spaceViewRenderer.signals.onAfterRender.add(onSpaceRendered);
					spaceViewRenderer.needsRender = true;
					break;
				case "pdf":
					if (isMultiple) {
						setIsSpaceToPDFExportPanelOpen(true);
					} else {
						const space = spaceViewRenderer.space;

						spaceToPDFExporter.export(area, [space], nameAsArray[0]);
					}
					break;
			}
		});
	}
};

interface ISpaceExporterProps {
	onClick: () => void;
}

export const SpaceExporter = (props: ISpaceExporterProps) => {
	const appState = useAppStore((state) => state.appState);
	const {spaceViewRenderer} = appState.app;
	const {cameraPropsChange} = spaceViewRenderer.toolManager.cameraControls.signals;
	const [isSpaceToPDFExportPanelOpen, setIsSpaceToPDFExportPanelOpen] = useState<boolean>(false);
	const spaceToPDFExporter: SpaceToPDFExporter = useMemo(() => new SpaceToPDFExporter(appState), [appState]);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const isPDFVisibleAreaDisabled =
		spaceViewRenderer.activeCamera.type === "PerspectiveCamera" ||
		Math.abs(spaceViewRenderer.toolManager.cameraControls.azimuthAngle) > Constants.EPSILON;

	useEffect(() => {
		cameraPropsChange.add(forceUpdate);

		return () => {
			cameraPropsChange.remove(forceUpdate);
		};
	}, [cameraPropsChange]);

	return (
		<>
			<DropdownButtonV5
				button={
					<IconButtonV5
						IconComponent={ExportIcon}
						onClick={props.onClick}
						title="Export"
						titleAlignment="top"
					/>
				}
				offsetY={16}
				options={[
					{
						label: "Export to PNG",
						onClick: () => onExport("png", "visible", false, spaceViewRenderer, setIsSpaceToPDFExportPanelOpen, spaceToPDFExporter),
					},
					{
						label: "Export to JPG",
						onClick: () => onExport("jpg", "visible", false, spaceViewRenderer, setIsSpaceToPDFExportPanelOpen, spaceToPDFExporter),
					},
					{
						label: "Export to PDF",
						options: [
							{
								label: "Visible Area",
								onClick: () => onExport("pdf", "visible", false, spaceViewRenderer, setIsSpaceToPDFExportPanelOpen, spaceToPDFExporter),
								disabled: isPDFVisibleAreaDisabled,
								infoText: isPDFVisibleAreaDisabled ? "This option is only available in 2D mode" : undefined,
							},
							{
								label: "Entire Space",
								onClick: () => onExport("pdf", "entire", false, spaceViewRenderer, setIsSpaceToPDFExportPanelOpen, spaceToPDFExporter),
							},
							{
								label: "Multiple Spaces",
								onClick: () => onExport("pdf", "entire", true, spaceViewRenderer, setIsSpaceToPDFExportPanelOpen, spaceToPDFExporter),
							},
						],
					},
				]}
			/>
			{isSpaceToPDFExportPanelOpen && (
				<SpaceToPDFExportPanelV5
					onClose={() => setIsSpaceToPDFExportPanelOpen(false)}
					appState={appState}
				/>
			)}
		</>
	);
};

import {inject, observer} from "mobx-react";
import * as React from "react";
import styled, {css} from "styled-components";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import {PortLayoutType} from "../../../../modules/abstract/sidepanel/tabs/details/PortLayoutType";
import type {Link} from "../../../../../data/models/Link";
import type {App} from "../../../../../App";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {FocusLoss} from "../../../../../utils/ui/focus/FocusLoss";
import {XyiconFeature} from "../../../../../generated/api/base";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {SpaceItemContainerV5} from "../../../spaceeditor/SpaceItemContainerV5";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {CardLayoutToolTip} from "../../../../modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {DropdownOptionsV5} from "../../../interaction/DropdownOptionsV5";
import {colorPalette} from "../../../styles/colorPalette";
import {PopupUtilsV5} from "../../../popup/PopupUtilsV5";
import {LinkBreakersV5} from "./LinkBreakersV5";

interface IPortComponentLinkProps {
	xyicon: Xyicon;
	layout: PortLayoutType;
	isDraggingActive: boolean;
	link: Link;
	app?: App;
	appState?: AppState;
}

interface IPortComponentLinkState {
	isToolTipOpen: boolean;
	isContextOptionOpen: boolean;
	toolTipTransform: TransformObj;
	contextTransform: TransformObj;
}

@inject("app")
@inject("appState")
@observer
export class PortComponentLinkV5 extends React.Component<IPortComponentLinkProps, IPortComponentLinkState> {
	private _parent = React.createRef<HTMLDivElement>();
	private _contextMenu = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _timeoutId: number = null;
	private _isMounted: boolean = false;
	private _isDeletePopupWindowOpen: boolean = false;

	public static defaultProps: Partial<IPortComponentLinkProps> = {
		layout: PortLayoutType.Icon,
	};

	constructor(props: IPortComponentLinkProps) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			isContextOptionOpen: false,
			toolTipTransform: null,
			contextTransform: null,
		};
	}

	private openTooltip = () => {
		if (this._timeoutId) {
			clearTimeout(this._timeoutId);
		}

		this._timeoutId = window.setTimeout(() => {
			if (this._isMounted) {
				this.setState({
					isToolTipOpen: true,
				});
			}
		}, 1000);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeoutId);

		if (this._isMounted) {
			this.setState({
				isToolTipOpen: false,
			});
		}
	};

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override componentDidUpdate(prevProps: IPortComponentLinkProps, prevState: IPortComponentLinkState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}

		if (!prevState.isContextOptionOpen && this.state.isContextOptionOpen && this._parent.current && this._contextMenu.current) {
			this.setState({
				contextTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._contextMenu.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.left,
				),
			});
		}
	}

	public override componentWillUnmount() {
		this._isMounted = false;
		FocusLoss.stopListen(this._contextMenu.current, this.closeContextMenu);
	}

	private onDelete = async () => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(
				XyiconFeature.Xyicon,
				this.props.appState.actions.getNumberOfModels([this.props.xyicon]),
			);

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				await this.props.appState.actions.deleteSpaceItem(this.props.xyicon);
			}
		}
	};

	private onDeleteLink = () => {
		const {appState, link} = this.props;

		return LinkBreakersV5.breakLinks(appState.app.transport, [link.id]);
	};

	private openContextMenu = async (event: React.MouseEvent) => {
		event.preventDefault();

		if (this._isMounted) {
			this.setState({isContextOptionOpen: true});
		}

		await TimeUtils.waitForNextFrame();

		if (this._isMounted) {
			FocusLoss.stopListen(this._contextMenu.current, this.closeContextMenu);
			FocusLoss.listen(this._contextMenu.current, this.closeContextMenu);
		}
	};

	private closeContextMenu = () => {
		if (this._isMounted && this._contextMenu.current) {
			this.setState({isContextOptionOpen: false});
		}
	};

	public override render() {
		const {app, xyicon} = this.props;
		const floatingElement = this._floating.current;
		const {layout, isDraggingActive} = this.props;

		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: this.state.toolTipTransform?.translate,
			position: "fixed",
			zIndex: 8000,
		};

		const inlineStyleContextMenu: React.CSSProperties = this._contextMenu.current && {
			transform: this.state.contextTransform?.translate,
			position: "fixed",
			zIndex: 8000,
		};

		const options = [
			{
				label: "Break Link",
				onClick: this.onDeleteLink,
			},
			{
				label: "Delete",
				onClick: this.onDelete,
			},
		];

		if (layout === PortLayoutType.Card) {
			return (
				<PortComponentSpaceItemStyled>
					<SpaceItemContainerV5
						queryString=""
						item={this.props.xyicon}
						options={options}
						className={ReactUtils.cls("PortComponentLink", {visible: isDraggingActive})}
					/>
				</PortComponentSpaceItemStyled>
			);
		} else {
			return (
				<PortComponentSpaceItemStyled
					style={{backgroundImage: `url('${xyicon.thumbnail}')`, transform: xyicon.backgroundTransform}}
					onMouseOver={this.openTooltip}
					onMouseLeave={this.closeTooltip}
					onContextMenu={this.openContextMenu}
					ref={this._parent}
					$onlyThumbnail={true}
				>
					{this.state.isToolTipOpen && (
						<DomPortal destination={app.modalContainer}>
							<CardLayoutToolTip
								item={xyicon}
								divRef={this._floating}
								style={inlineStyle}
							/>
						</DomPortal>
					)}
					{this.state.isContextOptionOpen && (
						<DomPortal destination={app.modalContainer}>
							<DropdownOptionsV5
								options={[
									{
										label: "Break Link",
										onClick: this.onDeleteLink,
									},
									{
										label: "Delete",
										onClick: this.onDelete,
									},
								]}
								onClose={this.closeContextMenu}
								style={inlineStyleContextMenu}
								divRef={this._contextMenu}
							/>
						</DomPortal>
					)}
				</PortComponentSpaceItemStyled>
			);
		}
	}
}

const PortComponentSpaceItemStyled = styled.div<{$onlyThumbnail?: boolean}>`
	.PortComponentLink {
		background: none;
		padding: 7px 8px;
		border: solid 1px transparent;

		&:hover {
			border-color: ${colorPalette.gray.c200Light};
		}
	}

	${(props) => {
		if (props.$onlyThumbnail) {
			return css`
				width: 40px;
				height: 40px;
			`;
		}
	}}
`;

import * as React from "react";
import {TextInput} from "../../../text/TextInput";
import type {IInputProps} from "../../FieldInput";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import type {IGeoLocation} from "./GeoLocationLabel";

export class GeoLocationInput extends React.Component<IInputProps<any, IGeoLocation>> {
	private onLatChange = (lat: string) => {
		lat = StringUtils.trim(lat);
		const {long} = this.getValues();

		const value = {
			long: Number(long),
			lat: Number(lat),
		};

		this.props.onChange(value);
	};

	private onLongChange = (long: string) => {
		long = StringUtils.trim(long);
		const {lat} = this.getValues();

		const value = {
			long: Number(long),
			lat: Number(lat),
		};

		this.props.onChange(value);
	};

	private getValues() {
		let value = this.props.value;

		if (MathUtils.isValidNumber(value?.long) && MathUtils.isValidNumber(value?.lat)) {
			return {
				long: String(value.long),
				lat: String(value.lat),
			};
		}
		return {
			lat: "",
			long: "",
		};
	}

	public override render() {
		const {lat, long} = this.getValues();

		return (
			<div className="GeoLocationInput field-input-container">
				<div className="hbox">
					<span>Latitude</span>
					<TextInput
						value={lat}
						onInput={this.onLatChange}
						autoFocus={true}
						className="geoLat"
					/>
				</div>
				<div className="hbox">
					<span>Longitude</span>
					<TextInput
						value={long}
						onInput={this.onLongChange}
					/>
				</div>
			</div>
		);
	}
}

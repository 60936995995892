import * as React from "react";
import type {IInputProps} from "../../FieldInput";
import {SelectInput} from "../../../select/SelectInput";
import type {ISingleSelectFieldSettingsDefinition} from "../../../../../../data/models/field/datatypes/SingleSelect";
import {HorizontalAlignment} from "../../../../../../utils/dom/DomUtils";

export function SingleSelectInput(props: IInputProps<ISingleSelectFieldSettingsDefinition>) {
	const {value, onChange, disabled, onFocusLossForceBlur, inline, focusLossStarterEvent, focused} = props;
	const list = [...props.dataTypeSettings.choiceList] || [];
	const selectedIsRemoved = value && !list.includes(value);

	if (selectedIsRemoved) {
		list.push(value);
	}

	let focus = focused;

	const onFocus = (value: boolean) => {
		if (!value) {
			focus = false;
		}
	};

	const onClick = () => {
		props.onClick?.();
	};

	return (
		<SelectInput
			options={list}
			selected={value}
			selectedIsRemoved={selectedIsRemoved}
			disabled={disabled}
			nullOption={true}
			fullWidth={true}
			sort={false}
			onFocusLossForceBlur={onFocusLossForceBlur}
			focused={focus}
			onChange={onChange}
			onClick={onClick}
			onFocus={onFocus}
			inline={inline}
			horizontalAlignment={HorizontalAlignment.center}
			focusLossStarterEvent={focusLossStarterEvent}
		/>
	);
}

import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {ReactUtils} from "../../utils/ReactUtils";
import {useAppStore} from "../../../StateManager";
import CircleInformationIcon from "../icons/circle-information.svg?react";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import {zIndex} from "../styles/styles";
import {InfoBubbleV5} from "./InfoBubbleV5";

export const InfoButtonStyled = styled.div`
	position: relative;
	margin: 10px;
	top: 3px;
	left: 4px;

	&.isError {
		svg {
			color: red;
		}
	}
`;

interface IInfoButtonV5Props {
	bubbleText: string | React.ReactNode;
	hoverDelay?: number;
	isError?: boolean;
	className?: "onPopup" | "leftAlign" | (string & {});
	icon?: "info" | "question-mark";
	style?: React.CSSProperties;
	verticalOpen?: VerticalAlignment.bottomOuter;
	onClick?: () => void;
}

export const InfoButtonV5 = (props: IInfoButtonV5Props) => {
	const {icon = "info", hoverDelay = 0, style = {}} = props;
	const appState = useAppStore((state) => state.appState);
	const _parentElement = useRef<HTMLDivElement>(null);
	const _floatingElement = useRef<HTMLDivElement>(null);
	const prevIsInfoBubbleOpen = useRef(null);
	const [_timeOutId, setTimeOutId] = useState<number | null>(null);

	const [isInfoBubbleOpen, setIsInfoBubbleOpen] = useState<boolean>(false);
	const [transform, setTransform] = useState<TransformObj>(null);

	const openBubble = () => {
		if (_timeOutId) {
			clearTimeout(_timeOutId);
		}

		setTimeOutId(
			window.setTimeout(() => {
				setIsInfoBubbleOpen(true);
			}, hoverDelay),
		);
	};

	const closeBubble = () => {
		clearTimeout(_timeOutId);

		setIsInfoBubbleOpen(false);
	};

	useEffect(() => {
		if (!prevIsInfoBubbleOpen.current && isInfoBubbleOpen && _parentElement.current && _floatingElement.current) {
			const transformValue = DomUtils.getFixedFloatingElementPosition(
				_parentElement.current,
				_floatingElement.current,
				props.verticalOpen || VerticalAlignment.top,
				HorizontalAlignment.center,
				10,
				0,
			);

			setTransform(transformValue);
		}

		prevIsInfoBubbleOpen.current = isInfoBubbleOpen;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInfoBubbleOpen, _parentElement.current, _floatingElement.current, props.verticalOpen]);

	const element = _parentElement.current;
	const inlineStyle: React.CSSProperties = element && {
		...style,
		zIndex: zIndex.popup,
		transform: transform?.translate,
		position: "fixed",
		left: "auto",
		top: "-30px",
	};

	return (
		<InfoButtonStyled
			ref={_parentElement}
			onMouseEnter={openBubble}
			onMouseLeave={closeBubble}
			onClick={props.onClick}
			className={ReactUtils.cls("InfoButtonV5", {isError: props.isError})}
		>
			{props.icon == "info" ? <CircleInformationIcon style={{height: "14px", width: "14px"}} /> : <CircleQuestionIcon />}
			{isInfoBubbleOpen && (
				<DomPortal destination={document.body}>
					<InfoBubbleV5
						content={props.bubbleText}
						isErrorMessage={props.isError}
						divRef={_floatingElement}
						style={inlineStyle}
						className={ReactUtils.cls(`${props.className || ""} infobutton`, {
							left: transform?.horizontal === HorizontalAlignment.right,
							right: transform?.horizontal === HorizontalAlignment.left,
							[VerticalAlignment[props.verticalOpen]]: !!props.verticalOpen,
						})}
					/>
				</DomPortal>
			)}
		</InfoButtonStyled>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Table} from "../../../widgets/table/Table";
import {toggleTableSort} from "../../../widgets/table/TableUtils";
import type {AppState} from "../../../../data/state/AppState";
import type {IModel} from "../../../../data/models/Model";
import {getModelId} from "../../../../data/models/Model";
import type {View} from "../../../../data/models/View";
import type {IViewColumn} from "../../../../data/models/ViewUtils";
import {getSortForField} from "../../../../data/models/ViewUtils";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../generated/api/base";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import type {ManageColumnParam} from "../../../widgets/table/TableHeaderDropDown";
import type {Pointer} from "../../../../utils/interaction/Pointer";
import type {TableRow} from "../../../widgets/table/TableRow";
import {Signal} from "../../../../utils/signal/Signal";
import {Initials} from "../../../widgets/Initials";
import {EmptyListView} from "./EmptyListView";

interface IGridViewProps<T extends IModel> {
	feature: XyiconFeature;
	items: T[];
	selected: T[];
	view: View;
	onSelect: (item: T[], isGoToItemNeeded?: boolean) => void;
	onClick: (item: T) => void;
	onDoubleClick: (item: T) => void;
	onAddClick: () => void;
	onDuplicateClick?: (item: T) => void;
	appState?: AppState;
	transport?: TransportLayer;
	checkboxColumn?: boolean;
	search: string;
	searching: boolean;
	filterSuppression?: boolean;
	tableRowIcon?: boolean;
	isCellContentsWrappingOn?: boolean;
	emptyListText?: React.ReactNode;
	onManageColumns?: () => void;
}

@inject("appState")
@inject("transport")
@observer
export class GridView<T extends IModel> extends React.Component<IGridViewProps<T>> {
	public static defaultProps: Partial<IGridViewProps<any>> = {
		checkboxColumn: true,
	};

	public signals = {
		onMouseMoveOnRow: Signal.create<Pointer, TableRow<T>>(),
	};

	private _lastFilteredData: T[];
	private _lastSelection: T[];
	public _lastItemCount: number;

	private onTableRowMouseMove = (pointer: Pointer, row: TableRow<T>) => {
		this.signals.onMouseMoveOnRow.dispatch(pointer, row);
	};

	private onHeaderWidthChange = (index: number, width: number) => {
		this.getView().resizeColumn(index, width, this.props.feature);
	};

	private onReorderColumn = (index: number, newIndex: number) => {
		this.getView().reorderColumn(index, newIndex, this.props.feature);
	};

	private onToggleSort = (field: IFieldPointer) => {
		const view = this.getView();

		const sort = getSortForField(view.sorts, field);

		field !== `${XyiconFeature.Xyicon}/icon` && field !== `${XyiconFeature.XyiconCatalog}/icon` && view.addSort(toggleTableSort(sort, field));
	};

	private filterHeaders = (columns: IViewColumn[]) => {
		return columns.filter((col) => !col.field.includes("versionName") && !col.field.includes("issuanceDate"));
	};

	private getHeaders = (): IViewColumn[] => {
		const {feature, appState} = this.props;
		const view = this.getView();

		if (view) {
			const columns = this.filterHeaders(view.getValidViewColumns(feature));

			return columns.map((column) => ({
				...column,
				title: appState.actions.getFieldTitle(column.field),
			}));
		}

		return [];
	};

	private getFields = (item: T, index?: number) => {
		const {appState} = this.props;
		const headers = this.getHeaders();

		return headers.map((column) => {
			return appState.actions.renderValue(item, column.field);
		});
	};

	private renderInitialComponent(item: T) {
		const itemType = this.props.appState.actions.getTypeById(item?.typeId);
		const color = itemType?.settings.color.hex || "FFFFFF";

		return (
			<Initials
				item={item}
				color={color}
				name={itemType?.name}
				size={30}
			/>
		);
	}

	private getFieldsArray = (item: T, index?: number) => {
		const {actions} = this.props.appState;
		const headers = this.getHeaders();

		return headers.map((column) => {
			if (column.field.includes("icon")) {
				return this.renderInitialComponent(item);
			}
			// single value
			return actions.getValueFromPropagation(item, column.field) ?? actions.renderValue(item, column.field);
		});
	};

	private getSearchedItems = (items: T[]) => {
		const {search, searching, feature, appState} = this.props;

		if (searching) {
			// When searching, don't call searchModels as that takes long
			return [];
		}

		let result: T[];

		if (items.length > 300 && this._lastFilteredData && this.didSelectionChange() && this._lastItemCount === items.length) {
			// Most likely only the selection changed -> use lastFilteredData (it can be very expensive to call searchModels)
			result = this._lastFilteredData;
		} else {
			this._lastFilteredData = appState.actions.searchModelsCached(items, search, feature);
			result = this._lastFilteredData;
		}

		this._lastSelection = this.props.selected;
		return result;
	};

	private didSelectionChange() {
		if (this._lastSelection === undefined) {
			return true;
		} else {
			return !ArrayUtils.equals(this._lastSelection, this.props.selected);
		}
	}

	private getView(): View {
		const {view, appState, feature} = this.props;

		return view || appState.defaultViews[feature];
	}

	private onManageColumns = (param: ManageColumnParam) => {
		if (param.type === "manage") {
			this.props.onManageColumns();
		} else if (param.type === "hide") {
			const field = this.getHeaders()[param.index]?.field;

			if (field) {
				const {view} = this.props;

				view?.removeColumnsByRefId?.([field], this.props.feature);
			}
		}
	};

	private get table() {
		return this.props.appState.tableComponent.current;
	}

	public override componentDidMount() {
		const {selected, items} = this.props;

		if (selected.length > 0) {
			this.table?.goToItem(selected[0]);
		}

		this._lastItemCount = items.length;
	}

	public override componentDidUpdate(prevProps: IGridViewProps<T>) {
		if (this.props.selected.length > 0 && (this.props.feature !== prevProps.feature || this._lastItemCount < this.props.items.length)) {
			this.table?.goToItem(this.props.selected[0]);
		}

		this._lastItemCount = prevProps.items.length;
	}

	public override render() {
		const {
			items,
			checkboxColumn,
			selected,
			onSelect,
			onClick,
			onDoubleClick,
			feature,
			tableRowIcon,
			onDuplicateClick,
			onAddClick,
			search,
			searching,
			appState,
			filterSuppression,
			isCellContentsWrappingOn,
		} = this.props;
		const view = this.getView();
		const data = appState.actions.sortItems(items, view);

		return (
			<>
				{items.length > 0 || filterSuppression || searching ? (
					<Table
						ref={appState.tableComponent}
						getHeaders={this.getHeaders}
						onHeaderWidthChange={this.onHeaderWidthChange}
						data={data}
						searchedData={this.getSearchedItems(data)}
						getKey={getModelId}
						getFields={this.getFields}
						getFieldsArray={this.getFieldsArray}
						selected={selected}
						onSelect={onSelect}
						onClick={onClick}
						onDoubleClick={onDoubleClick}
						onReorderColumn={this.onReorderColumn}
						onDuplicateClick={onDuplicateClick}
						checkboxColumn={checkboxColumn}
						sorts={view.sorts}
						view={view}
						onToggleSort={this.onToggleSort}
						moduleName={appState.selectedModuleTitle}
						feature={feature}
						tableRowIcon={tableRowIcon}
						search={search}
						searching={searching}
						filterSuppression={filterSuppression}
						isCellContentsWrappingOn={isCellContentsWrappingOn}
						onManageColumns={this.props.onManageColumns && this.onManageColumns}
						onTableRowMouseMove={this.onTableRowMouseMove}
					/>
				) : (
					<EmptyListView
						onAddClick={onAddClick}
						feature={feature}
						text={this.props.emptyListText}
					/>
				)}
			</>
		);
	}
}

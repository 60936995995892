import type {Color} from "../../generated/api/base";
import {ViewPreferenceCategory, XyiconFeature} from "../../generated/api/base";
import type {AppActions} from "../state/AppActions";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import {StringUtils} from "../../utils/data/string/StringUtils";
import type {AppState} from "../state/AppState";
import type {SupportedFontName} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import type {View} from "./View";
import type {IFieldPointer} from "./field/Field";

export interface IViewFolderStructures {
	[organizationId: string]: {
		[feature in XyiconFeature]?: ViewFolderStructure;
	};
}

export interface IViewItem {
	id: string;
	category: ViewPreferenceCategory.View;
}

export interface IViewFolder {
	id: string;
	category: ViewPreferenceCategory.Folder;
	name: string;
	isOpen: boolean;
	children: ViewFolderStructure;
}

export const getSortForField = (sorts: IViewSort[], field: IFieldPointer) => {
	return sorts.find((s) => s.column === field) || {column: null, direction: null};
};

export const getSortIndex = (view: View, refId: IFieldPointer, appState: AppState, feature: XyiconFeature) => {
	return appState.actions.getSortsForFeature(view, feature).findIndex((s) => s.column === refId);
};

export enum SortDirection {
	ASC = 1,
	DESC = -1,
}

export type ViewChangeType = "filters" | "captions" | "columns" | "column sorts" | "conditional formatting" | "layers";

export interface IViewColumn {
	field: IFieldPointer;
	feature: XyiconFeature;
	default: boolean;
	title?: string;
	width?: number;
	//hidden?: boolean;
	//headerTemplate?: string;
	//filterable?: boolean;
	//groupable?: boolean;
}

export interface IViewSort {
	column: IFieldPointer;
	direction: SortDirection;
}

export interface IFormattingRuleSet {
	enabled: boolean;
	indicator: {
		[key: string]: string;
	};
	highlight: {
		[key: string]: string;
	};
}

export interface IFontStyleSettings {
	isBold: boolean;
	isItalic: boolean;
	isUnderlined: boolean;
}

export interface ICaptionStyle extends IFontStyleSettings {
	fontColor: Color;
	backgroundColor?: Color;
	fontSize: number;
	fontFamily: SupportedFontName;
}

interface IIndividualCaptionStyles {
	[fieldRefId: string]: Partial<ICaptionStyle>;
}

export interface ICaptionSettings extends ICaptionStyle {
	checkList: string[]; // fieldRefIds
	individualCaptionStyles: IIndividualCaptionStyles;
}

export interface ICaptionConfig {
	boundary: ICaptionSettings; // this is not accessible anymore, we don't have a separate UI for the boundarycaptions, so we're using the "xyicon" for those as well...
	xyicon: ICaptionSettings;
}

export type ViewFolderStructure = (IViewItem | IViewFolder)[];

export const featuresWithViews: XyiconFeature[] = [
	XyiconFeature.Portfolio,
	XyiconFeature.Xyicon,
	XyiconFeature.Boundary,
	XyiconFeature.Space,
	XyiconFeature.XyiconCatalog,
	XyiconFeature.SpaceEditor,
];

export const doesItemExistInViewFolderStructure = (viewFolderStructure: ViewFolderStructure, viewId: string): boolean => {
	return !!getItemByIdInViewFolderStructure(viewFolderStructure, viewId);
};

export const getItemByIdInViewFolderStructure = (viewFolderStructure: ViewFolderStructure, itemId: string): IViewItem | IViewFolder | null => {
	for (const item of viewFolderStructure) {
		if (item.id === itemId) {
			return item;
		}

		if (item.category === ViewPreferenceCategory.Folder) {
			const itemMaybe = getItemByIdInViewFolderStructure(item.children, itemId);

			if (itemMaybe) {
				return itemMaybe;
			}
		}
	}

	return null;
};

// Removes views from the structure that are no longer available (deleted, or unshared)
export const filterViewFolderStructure = (viewFolderStructure: ViewFolderStructure, availableViewIds: string[]): ViewFolderStructure => {
	const filteredViewFolderStructure: ViewFolderStructure = [];

	for (const item of viewFolderStructure) {
		const isFolder = item.category === ViewPreferenceCategory.Folder;

		if (isFolder || availableViewIds.includes(item.id)) {
			if (isFolder) {
				item.children = filterViewFolderStructure(item.children, availableViewIds);
			}
			filteredViewFolderStructure.push(item);
		}
	}

	return filteredViewFolderStructure;
};

// Removes view, or folder from the structure
// Returns if the deletion was successful
export const removeElementFromViewFolderStructureById = (viewFolderStructure: ViewFolderStructure, idToDelete: string): boolean => {
	for (let i = 0; i < viewFolderStructure.length; ++i) {
		const element = viewFolderStructure[i];

		if (element.id === idToDelete) {
			viewFolderStructure.splice(i, 1);

			return true;
		} else if (element.category === ViewPreferenceCategory.Folder) {
			if (removeElementFromViewFolderStructureById(element.children, idToDelete)) {
				return true;
			}
		}
	}

	return false;
};

const getNameOfElement = (viewElement: IViewItem | IViewFolder, actions: AppActions) => {
	if (viewElement.category === ViewPreferenceCategory.Folder) {
		return viewElement.name;
	} else {
		const view = actions.getViewById(viewElement.id);

		return view.name ?? "";
	}
};

export const doesStructureContainElement = (viewFolderStructure: ViewFolderStructure, searchString: string, actions: AppActions): boolean => {
	for (let i = 0; i < viewFolderStructure.length; ++i) {
		const element = viewFolderStructure[i];

		if (StringUtils.containsIgnoreCase(getNameOfElement(element, actions), searchString)) {
			return true;
		} else if (element.category === ViewPreferenceCategory.Folder) {
			if (doesStructureContainElement(element.children, searchString, actions)) {
				return true;
			}
		}
	}

	return false;
};

export const getFoldersFromViewFolderStructure = (viewFolderStructure: ViewFolderStructure): IViewFolder[] => {
	const viewFolders: IViewFolder[] = [];

	traverseViewFolderStructure(viewFolderStructure, (node: IViewItem | IViewFolder) => {
		if (node.category === ViewPreferenceCategory.Folder) {
			viewFolders.push(node);
		}
	});

	return viewFolders;
};

const traverseViewFolderStructure = (viewFolderStructure: ViewFolderStructure, callback: (node: IViewItem | IViewFolder) => any) => {
	for (const element of viewFolderStructure) {
		callback(element);

		if (element.category === ViewPreferenceCategory.Folder) {
			traverseViewFolderStructure(element.children, callback);
		}
	}
};

// Some keys are added to the list elements by the dnd lib we're using
// We need to remove those before we save the object to the backend
// https://github.com/SortableJS/react-sortablejs
const keysToRemove = ["selected", "chosen", "filtered"];

export const cleanViewFolderStructureFromAdditionalProps = (viewFolderStructure: ViewFolderStructure): ViewFolderStructure => {
	const clonedViewFolderStructure = ObjectUtils.deepClone<ViewFolderStructure>(viewFolderStructure);

	traverseViewFolderStructure(clonedViewFolderStructure, (node: IViewItem | IViewFolder) => {
		for (const key of keysToRemove) {
			delete (node as any)[key];
		}
	});

	return clonedViewFolderStructure;
};

export const rootFolderId = "root";

import * as React from "react";
import {inject, observer} from "mobx-react";
import {maxNumberOfIcons} from "../MultiDefaultFieldUtils";
import type {Navigation} from "../../../../../../../../Navigation";
import {FocusLoss} from "../../../../../../../../utils/ui/focus/FocusLoss";
import {ReactUtils} from "../../../../../../../utils/ReactUtils";
import type {IModel} from "../../../../../../../../data/models/Model";
import {IconButton} from "../../../../../../../widgets/button/IconButton";
import {MarkupType, XyiconFeature} from "../../../../../../../../generated/api/base";
import type {AppState} from "../../../../../../../../data/state/AppState";
import type {Markup} from "../../../../../../../../data/models/Markup";
import {DomPortal} from "../../../../../portal/DomPortal";
import type {TransformObj} from "../../../../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../../../../../../utils/TimeUtils";
import {Initials} from "../../../../../../../widgets/Initials";

interface IMoreIconsWidgetProps {
	items: IModel[];
	onClick?: (item: IModel) => void;
	appState?: AppState;
	navigation?: Navigation;
}

interface IIMoreIconsWidgetState {
	open: boolean;
	search: string;
	transform: TransformObj;
}

@inject("appState")
@inject("navigation")
@observer
export class MoreIconsWidget extends React.Component<IMoreIconsWidgetProps, IIMoreIconsWidgetState> {
	private _container = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: IMoreIconsWidgetProps) {
		super(props);
		this.state = {
			open: false,
			search: "",
			transform: null,
		};
	}

	private onMoreButtonClick = async () => {
		this.setState({
			open: true,
		});

		await TimeUtils.wait(100);
		FocusLoss.listen(this._floating.current, this.onFocusLoss);
	};

	private onCloseClick = () => {
		FocusLoss.stopListen(this._floating.current, this.onFocusLoss);

		this.setState({
			open: false,
		});
	};

	private onClickIcon = (model: IModel) => {
		this.props.onClick?.(model);
	};

	private onFocusLoss = () => {
		FocusLoss.stopListen(this._floating.current, this.onFocusLoss);

		this.setState({
			open: false,
			search: "",
		});

		return false;
	};

	private renderFirstLine(item: IModel): string {
		switch (item.ownFeature) {
			case XyiconFeature.Portfolio:
			case XyiconFeature.Space:
			case XyiconFeature.XyiconCatalog:
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return item.refId;
			case XyiconFeature.Markup:
				return "Markup";
		}

		return "";
	}

	private renderSecondLine(item: IModel) {
		switch (item.ownFeature) {
			case XyiconFeature.Portfolio:
			case XyiconFeature.Space:
			case XyiconFeature.XyiconCatalog:
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return `Type: ${item.typeName}`;
			case XyiconFeature.Markup:
				return MarkupType[(item as Markup).type];
		}

		return "";
	}

	public override componentDidUpdate(prevProps: IMoreIconsWidgetProps, prevState: IIMoreIconsWidgetState) {
		if (!prevState.open && this.state.open && this._container.current && this._floating.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.right,
					-10,
					0,
				),
			});
		}
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._floating.current, this.onFocusLoss);
	}

	public override render() {
		const {items} = this.props;
		const {open, transform} = this.state;

		const remainingItems = items.slice(maxNumberOfIcons);

		let inlineStyle: React.CSSProperties = this._container && {
			transform: transform?.translate,
		};

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("MoreIconsWidget", {open})}
			>
				<div
					className="moreButton"
					onClick={this.onMoreButtonClick}
				>
					{`${items.length - maxNumberOfIcons} more...`}
				</div>
				{open && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<div
							className="moreIconsWindow"
							ref={this._floating}
							style={inlineStyle}
						>
							<div className="title">
								Selected Objects
								<IconButton
									icon="close"
									className="close"
									onClick={this.onCloseClick}
								/>
							</div>
							<div className="list">
								{remainingItems.map((item: IModel, index: number) => {
									const detailedItem = this.props.appState.actions.getTypeById(item?.typeId);
									const color = detailedItem?.settings.color.hex || "FFFFFF";

									return (
										<div
											key={item.id}
											className={ReactUtils.cls("itemIcon", {noClick: item.ownFeature === XyiconFeature.XyiconCatalog})}
											onClick={() => this.onClickIcon(item)}
										>
											<Initials
												item={item}
												color={color}
												name={detailedItem?.name}
												thumbnailSize={38}
												className={`MoreIcons ${item.ownFeature === XyiconFeature.XyiconCatalog ? "noClick" : ""} ${item.ownFeature === XyiconFeature.Portfolio ? "portfolioThumbnail" : ""}`}
											/>
											<div className="description">
												<div className="firstLine">{this.renderFirstLine(item)}</div>
												<div className="secondLine">{this.renderSecondLine(item)}</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</DomPortal>
				)}
			</div>
		);
	}
}

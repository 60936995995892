import type {IReactionDisposer} from "mobx";
import {computed, observable, action, makeObservable, reaction} from "mobx";
import {Signal} from "../../../utils/signal/Signal";

export class Collection<T extends {id: string; data: Record<string, any>}> {
	@observable
	public loaded = false;

	@observable
	private _array: T[];

	@observable
	private _idMap: {
		[id: string]: T;
	};

	private _keys: string[];

	@observable
	public map: {
		[key: string]: {
			[key: string]: T[];
		};
	} = {};

	@observable
	private _version = 0;
	private _disposer: IReactionDisposer;

	public signals = {
		itemsAdded: Signal.create<T[]>(),
	};

	constructor(array: T[] = [], keys: string[] = []) {
		makeObservable(this);
		this._array = array;
		this._keys = keys;
		this._idMap = {};
		this.initMap();
	}

	private initMap() {
		const idMap: {[key: string]: T} = {};
		const map: {[key: string]: {}} = {};

		for (const key of this._keys) {
			map[key] = {};
		}

		for (const item of this.array) {
			const id = this.getId(item);

			idMap[id] = item;
			this.addKeys(item, map);
		}

		// Very important to only update the observable properties after all modifications are done abouve.
		// Without this, the UI components would keep getting updated during the while loop (which is potentially big)
		// causing long freezes in the app.

		this.map = map;
		this._idMap = idMap;
	}

	@action
	public addMultiple(items: T[]) {
		const newItemArray = [...this._array, ...items];

		this.replaceByArray(newItemArray);
		this.signals.itemsAdded.dispatch(newItemArray);
	}

	public add(item: T) {
		this._array.push(item);
		this._idMap[this.getId(item)] = item;
		this.addKeys(item);
		this.signals.itemsAdded.dispatch([item]);
	}

	private addKeys(item: T, map = this.map) {
		for (const key of this._keys) {
			const keyValue = (item as any)[key];

			if (!map[key][keyValue]) {
				map[key][keyValue] = [];
			}
			map[key][keyValue].push(item);
		}
	}

	public deleteByIds(ids: string[]) {
		if (!ids.length) {
			return;
		}

		// Keep items where id is not in ids list
		const newArray = this.array.filter((model) => !ids.includes(this.getId(model)));

		this.replaceByArray(newArray);
	}

	public deleteById(id: string) {
		const index = this.array.findIndex((model) => this.getId(model) === id);

		if (index > -1) {
			this.array.splice(index, 1);

			// Remove from idMap
			const item = this._idMap[id];

			delete this._idMap[id];

			// Remove from map
			for (const key of this._keys) {
				if (this.map[key]) {
					const keyValue = (item as any)[key];
					const arr = this.map[key][keyValue];

					if (arr) {
						const i = arr.findIndex((model) => this.getId(model) === id);

						if (i > -1) {
							arr.splice(i, 1);
						}
					}
				}
			}
		}
	}

	@action
	public replaceByArray(array: T[] = []) {
		this.updateArray(array);
		this.loaded = true;
	}

	public clear() {
		this.updateArray([]);
		this.loaded = false;
	}

	private stringifyArrayData = () => {
		return this._array.map((item) => JSON.stringify(item.data)).join(",");
	};

	@action
	private updateArray(array: T[]) {
		this._array = array;

		this.initMap();

		this._version++;

		if (this._disposer) {
			this._disposer();
			this._disposer = reaction(this.stringifyArrayData, this.onDataChange);
		}
	}

	private onDataChange = () => {
		this._version++;
	};

	public getVersion() {
		if (!this._disposer) {
			this._disposer = reaction(this.stringifyArrayData, this.onDataChange);
		}
		return this._version;
	}

	private getId(model: T) {
		return model.id;
	}

	public getById(id: string) {
		return this._idMap[id];
	}

	@computed
	public get array() {
		return this._array;
	}
}

import type {AppState} from "../../../state/AppState";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {FieldDataType} from "../../../../generated/api/base";
import {TypeLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/type/TypeLabel";
import {TypeInlineInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/type/TypeInlineInput";
import {TypeInlineInputV5} from "../../../../ui/5.0/details/TypeInlineInputV5";
import {TypeLabelV5} from "../../../../ui/5.0/details/datatypes/TypeLabelV5";

export const configureType = (appState: AppState) => {
	FieldDataTypes.configure({
		id: FieldDataType.Type,
		title: "Type",
		operatorsArray: stringOperators,
		formatter: (value) => {
			return appState.actions.getTypeName(value);
		},
		label: TypeLabel,
		labelV5: TypeLabelV5,
		inlineInput: TypeInlineInput,
		inlineInputV5: TypeInlineInputV5,
		system: true,
	});
};

import {computed, observable, makeObservable} from "mobx";
import type {DocumentDto, DocumentMapDto} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import type {IModel} from "./Model";

export class DocumentModel implements IModel {
	public readonly ownFeature = XyiconFeature.Document;

	@observable
	private _data: DocumentDto = {};

	@observable
	private _objectMap: {
		[objectId: string]: boolean;
	} = {};

	constructor(data: DocumentDto) {
		makeObservable(this);
		this.applyData(data);
	}

	public applyData(data: DocumentDto) {
		for (const key in data) {
			this._data[key as keyof DocumentDto] = data[key as keyof DocumentDto];
		}

		// TODO review if it exists! Based on the API schema, it doesn't...
		if ((data as any).documentMap) {
			this.updateObjectMap();
		}
	}

	private updateObjectMap() {
		this._objectMap = {};
		(this._data as any).documentMap?.forEach((map: DocumentMapDto) => {
			this._objectMap[map.objectID] = true;
		});
	}

	public isAttachedToObject(objectID: string) {
		return !!this._objectMap[objectID];
		// Slower:
		// const documentMap = this._data.documentMap;
		// return !!(documentMap && documentMap.find(map => map.objectID === objectID));
	}

	@computed
	public get id() {
		return this._data.documentID;
	}

	@computed
	public get fileName() {
		return this._data.fileName;
	}

	@computed
	public get fileExtension() {
		return this._data.fileExtension;
	}

	@computed
	public get fileType() {
		return this._data.fileType;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get documentMap(): DocumentMapDto[] {
		return (this._data as any).documentMap;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import {Signal} from "../../signal/Signal";
import {ObjectUtils} from "../../data/ObjectUtils";
import {FullscreenManager} from "../../resize/FullscreenManager";
import {PointerDetector} from "../PointerDetector";

interface IDoubleClickGestureConfig {
	target: HTMLElement;
	threshold?: number;
	toggleFullScreen?: boolean;
}

export class DoubleClickGesture {
	public static defaultConfig: IDoubleClickGestureConfig = {
		target: null,
		threshold: 275,
		toggleFullScreen: false,
	};

	public signals = {
		click: Signal.create(),
		doubleClick: Signal.create(),
	};

	protected _config: IDoubleClickGestureConfig;

	protected _lastClick = 0;
	protected _clickTimeoutId = -1;

	protected _pd: PointerDetector;

	constructor(config: IDoubleClickGestureConfig) {
		this._config = ObjectUtils.mergeConfig(DoubleClickGesture.defaultConfig, config);

		this._pd = new PointerDetector({
			element: this._config.target,
			maxPointers: 1,
			ignoreRightButton: true,
		});
	}

	public enable() {
		this._pd.enable();
		this._pd.signals.up.add(this.onClick);
	}

	public disable() {
		this._pd.signals.up.remove(this.onClick);
		this._pd.disable();

		clearTimeout(this._clickTimeoutId);
		this._clickTimeoutId = -1;
	}

	protected onClick = () => {
		clearTimeout(this._clickTimeoutId);
		this._clickTimeoutId = -1;

		const now = Date.now();
		const dt = now - this._lastClick;

		if (dt < this._config.threshold) {
			this.doubleClick();
		} else {
			this._clickTimeoutId = window.setTimeout(this.clickTimeout, this._config.threshold);
		}

		this._lastClick = now;
	};

	protected clickTimeout = (event: MouseEvent) => {
		this._clickTimeoutId = -1;

		this.click();
	};

	protected click() {
		this.signals.click.dispatch();
	}

	protected doubleClick() {
		if (this._config.toggleFullScreen) {
			FullscreenManager.toggle(this._config.target);
		}

		this.signals.doubleClick.dispatch();
	}
}

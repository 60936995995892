import * as React from "react";
import {inject, observer} from "mobx-react";
import {Report} from "../../../../../../../data/models/Report";
import {ReportScope, ReportType} from "../../../../../../../generated/api/reports";
import type {AppState} from "../../../../../../../data/state/AppState";
import {AdvancedFilterEditor} from "../../../../../abstract/filter/advanced/AdvancedFilterEditor";
import {FilterOperator} from "../../../../../../../data/models/filter/operator/FilterOperator";
import {TabView} from "../../../../../../widgets/tab/TabView";
import {TabChild} from "../../../../../../widgets/tab/TabChild";
import {ReactUtils} from "../../../../../../utils/ReactUtils";
import type {IFieldAdapter} from "../../../../../../../data/models/field/Field";
import {FieldDataType, XyiconFeature} from "../../../../../../../generated/api/base";

interface ReportDataFilterParameterProps {
	report: Report;
	appState?: AppState;
}

interface ReportDataFilterParameterState {
	search: string;
}

@inject("appState")
@observer
export class ReportFilter extends React.Component<ReportDataFilterParameterProps, ReportDataFilterParameterState> {
	public static isFieldSupported(field: IFieldAdapter, report: Report): boolean {
		// Temporarily disable date fields until backend api supports it
		// Disable icons, disable refId if summarize result is true
		const id = [`${XyiconFeature.Xyicon}/icon`, `${XyiconFeature.XyiconCatalog}/icon`];

		if (
			field?.dataType === FieldDataType.DateTime ||
			id.includes(field?.refId) ||
			(report.summarizeResults && field?.refId === `${report.reportFeature}/refId`)
		) {
			return false;
		}
		return true;
	}

	private _filterEditor = React.createRef<AdvancedFilterEditor>();
	private _filterEditorLinkedFields = React.createRef<AdvancedFilterEditor>();

	constructor(props: ReportDataFilterParameterProps) {
		super(props);
		this.state = {
			search: "",
		};
	}

	private operatorFilter = (operator: FilterOperator) => {
		if (operator === FilterOperator.IS_TRUE || operator === FilterOperator.IS_FALSE) {
			return true;
		}
		return !!Report.serializeOperator(operator);
	};

	public override render() {
		const {report} = this.props;

		return (
			<div className={ReactUtils.cls("ReportFilter", {linkedFields: report.type === ReportType.UserDefinedLinkedXyiconReport})}>
				<h4>Add fields</h4>
				<TabView>
					<TabChild
						label="Xyicon Fields"
						title="Xyicon Fields"
					>
						<AdvancedFilterEditor
							ref={this._filterEditor}
							features={[report.reportFeature]}
							filters={report.filters}
							autoUpdate={true}
							operatorFilter={this.operatorFilter}
							fieldFilter={(field) => ReportFilter.isFieldSupported(field, report)}
							multiplePortfolios={report.scope === ReportScope.PortfolioIDList || report.scope === ReportScope.Organization}
						/>
					</TabChild>
					{report.type === ReportType.UserDefinedLinkedXyiconReport && (
						<TabChild
							label="Linked Xyicon Fields"
							title="Linked Xyicon Fields"
						>
							<AdvancedFilterEditor
								ref={this._filterEditorLinkedFields}
								features={[report.reportFeature]}
								filters={report.linkedFilters}
								autoUpdate={true}
								operatorFilter={this.operatorFilter}
								fieldFilter={(field) => ReportFilter.isFieldSupported(field, report)}
								multiplePortfolios={report.scope === ReportScope.PortfolioIDList || report.scope === ReportScope.Organization}
								linkedFields={true}
							/>
						</TabChild>
					)}
				</TabView>
			</div>
		);
	}
}

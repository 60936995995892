import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {AppState} from "../../../../../data/state/AppState";
import {UserGroup} from "../../../../../data/models/UserGroup";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import {ButtonV5} from "../../../button/ButtonV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";

export const CreateUserGroupPanelStyled = styled.div`
	padding-left: 8px;
	padding-right: 8px;

	.primary {
		width: 70px;
		margin-left: 215px;
		margin-right: 0;
	}
`;

interface ICreateUserGroupPanelProps {
	onCancel: () => void;
	onCreated: (id: string) => void;
	creating: boolean;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class CreateUserGroupPanelV5 extends React.PureComponent<ICreateUserGroupPanelProps> {
	private createNewUserGroup = () => {
		return new UserGroup({
			userGroupID: "",
			userIDList: [],
			name: "",
			lastModifiedAt: "",
			lastModifiedBy: "",
		});
	};

	private _userGroup = this.createNewUserGroup();

	private onCreateClicked = async () => {
		const data = {
			name: this._userGroup.name,
			userIDList: this._userGroup.userIds,
			shareViewPermission: Permission.Delete,
			canPinToNavigation: true,
		};
		const models = await this.props.transport.services.feature.create(data, XyiconFeature.UserGroup);

		this.props.onCreated(models?.[0]?.id);
		this._userGroup = this.createNewUserGroup();
		this.forceUpdate();
	};

	private onInput = (value: string) => {
		this._userGroup.name = value;
	};

	private isNameValid = (name: string) => {
		const userGroup = this._userGroup;

		return this.props.appState.actions.isUserGroupNameValid(name, userGroup);
	};

	public override render() {
		const userGroup = this._userGroup;
		const isValid = !!userGroup.name && this.isNameValid(userGroup.name);

		return (
			<CreateUserGroupPanelStyled>
				<ClickToEditInputV5
					value={userGroup.name}
					onLiveChange={this.onInput}
					valueValidator={this.isNameValid}
					placeholder="Name Value"
					focused={this.props.creating}
				/>
				<br />
				<ButtonV5
					label="Confirm"
					title="Create"
					onClick={this.onCreateClicked}
					disabled={!isValid}
					className="primary"
				/>
			</CreateUserGroupPanelStyled>
		);
	}
}

import {XyiconFeature} from "../../generated/api/base";
import type {Boundary} from "./Boundary";
import type {IModel} from "./Model";

export class BoundaryUtils {
	public static doesHaveMultipleSpaceMaps(model: IModel): boolean {
		if (model.ownFeature === XyiconFeature.Boundary) {
			return (model as Boundary).boundarySpaceMaps.length > 1;
		}

		return false;
	}
}

import type {FormEvent} from "react";
import {Component, createRef} from "react";
import {DomPortal} from "../modules/abstract/portal/DomPortal";
import {featureTitles} from "../../data/state/AppStateConstants";
import type {XyiconFeature} from "../../generated/api/base/models/XyiconFeature";
import {AppUtils} from "../../utils/AppUtils";
import type {TransformObj} from "../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../utils/dom/DomUtils";
import {TimeUtils} from "../../utils/TimeUtils";
import {FocusLoss} from "../../utils/ui/focus/FocusLoss";
import type {IModel} from "../../data/models/Model";
import {SearchField} from "./input/search/SearchField";
import {IconButton} from "./button/IconButton";

interface IFeatureSection<T> {
	items: T[];
	feature: XyiconFeature;
	sortFunction: (a: T, b: T) => number;
	renderAddedListItem: (item: T) => React.ReactNode;
	renderAddListItem: (item: T) => React.ReactNode;
	onItemAdd: (item: T) => void;
	onItemRemove: (item: T) => void;
	lockItems?: boolean;
}

interface ISearchAddAndListItemsProps<T> {
	addedItems: T[];
	search: string;
	sections: IFeatureSection<T>[];
	searchTextPlaceholder: string;
	onSearch: (value: string) => void;
	prohibitSearch?: boolean;
}

interface ISearchAddAndListItemsState {
	open: boolean;
	transform: TransformObj;
}

export class SearchAddAndListItems<T extends IModel> extends Component<ISearchAddAndListItemsProps<T>, ISearchAddAndListItemsState> {
	private _container = createRef<HTMLDivElement>();
	private _list = createRef<HTMLDivElement>();

	constructor(props: ISearchAddAndListItemsProps<T>) {
		super(props);
		this.state = {
			open: false,
			transform: null,
		};
	}

	public override componentDidUpdate(prevProps: ISearchAddAndListItemsProps<T>, prevState: ISearchAddAndListItemsState) {
		const {addedItems, search} = this.props;
		const {open} = this.state;

		if ((!prevState.open && open) || (open && prevProps.search !== search && this._container.current && this._list.current)) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._list.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.center,
					-15,
				),
			});
		}

		if (addedItems.length > prevProps.addedItems.length) {
			// A new item has been added, so the dropdown list should be closed.
			this.toggleSelector(false);
		}
	}

	private onSearchInput = (value: string, event: FormEvent) => {
		this.props.onSearch(value);
	};

	private toggleSelector = async (value?: boolean) => {
		const open = this.state.open;

		if (!value && open) {
			this.props.onSearch("");
			AppUtils.disableScrolling(false);
		}

		this.setState({open: value ?? !open});

		if (!open && value) {
			FocusLoss.stopListen(this._list.current, this.onFocusLoss);
			AppUtils.disableScrolling(true);
			await TimeUtils.wait(100);
			FocusLoss.listen(this._list.current, this.onFocusLoss);
		}
	};

	private onFocusLoss = () => {
		this.toggleSelector(false);
	};

	public override componentWillUnmount(): void {
		FocusLoss.stopListen(this._list.current, this.onFocusLoss);
	}

	public override render() {
		const {searchTextPlaceholder, search, addedItems, sections, prohibitSearch} = this.props;
		const {transform, open} = this.state;

		const inlineStyle: React.CSSProperties = {
			position: "fixed",
			transform: transform?.translate,
			zIndex: 8000,
			width: this._container.current?.getBoundingClientRect().width - 30,
		};

		if (!transform) {
			inlineStyle.transform = "";
		}

		return (
			<div className="SearchAddAndListItems">
				{!prohibitSearch && (
					<SearchField
						className="hbox flex_1"
						value={search}
						onInput={this.onSearchInput}
						onClick={() => this.toggleSelector(true)}
						placeholder={!open ? searchTextPlaceholder || "Search users or groups to share" : " "}
						divRef={this._container}
					/>
				)}
				{open && (
					<DomPortal destination={DomPortal._body}>
						<div
							className="vbox SearchAddAndListItems__selector"
							style={inlineStyle}
							ref={this._list}
						>
							<div className="list addList overflowYAuto">
								{sections.map((section) => section.items).every((item) => item.length === 0) ? (
									<div className="empty">"We couldn't find a match."</div>
								) : (
									sections.map((section, index) => {
										return (
											<div
												className="section"
												key={index}
											>
												<h3>{featureTitles[section.feature]}</h3>
												{section.items.sort(section.sortFunction).map((item, index) => {
													return (
														<div
															className="item hbox alignCenter"
															key={item.id || index}
															onClick={() => section.onItemAdd(item)}
														>
															{section.renderAddListItem(item)}
														</div>
													);
												})}
											</div>
										);
									})
								)}
							</div>
						</div>
					</DomPortal>
				)}
				<div className="vbox flex_1 overflowYAuto">
					<div className="list">
						<div className="section">
							{addedItems.length === 0 ? (
								<p className="noItem">"No item in the list."</p>
							) : (
								sections.map((section) => {
									return addedItems
										.filter((addedItem) => addedItem.ownFeature === section.feature)
										.toSorted(section.sortFunction)
										.map((item, index) => {
											const featureSection = sections.find((section) => section.feature === item.ownFeature);

											return (
												<div
													key={item.id || index}
													className="item hbox"
												>
													{featureSection.renderAddedListItem(item)}
													<div className="flex_1" />
													{!section.lockItems && (
														<IconButton
															icon="delete"
															onClick={() => featureSection.onItemRemove(item)}
														/>
													)}
												</div>
											);
										});
								})
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Lambda} from "mobx";
import {observe, reaction} from "mobx";
import {SidePanel} from "../abstract/sidepanel/SidePanel";
import {CatalogItemPanel} from "../catalog/create/CatalogItemPanel";
import {EditViewSharing} from "../abstract/view/sharing/EditViewSharing";
import type {IIconConfig} from "../catalog/create/CatalogTypes";
import {XyiconFeature} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import {Splitter} from "../../widgets/splitter/Splitter";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {ISpaceItemModel, IModel} from "../../../data/models/Model";
import type {IFilterState} from "../../../data/models/filter/Filter";
import type {Space} from "../../../data/models/Space";
import type {Boundary} from "../../../data/models/Boundary";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {SpaceFileInsertionInfo, RescaleSpaceRequest, SpaceFileDto, UpdateSpaceFileRequest} from "../../../generated/api/base";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {Functions} from "../../../utils/function/Functions";
import type {Catalog} from "../../../data/models/Catalog";
import {ReactUtils} from "../../utils/ReactUtils";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {ManageColumnsPanel} from "../abstract/columns/ManageColumnsPanel";
import {SpaceToPDFExportPanel} from "./spaceeditor/ui/viewbar/SpaceToPDFExportPanel";
import {SpaceEditorMode} from "./spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {arrayOfSelectionToolTypes} from "./spaceeditor/logic3d/features/tools/Tools";
import type {SelectionToolType, SpaceTool} from "./spaceeditor/logic3d/features/tools/Tools";
import type {OverlayPanel} from "./SpaceView";
import {CaptionPanel} from "./spaceeditor/ui/viewbar/CaptionPanel";
import {ConditionalFormattingPanel} from "./spaceeditor/ui/viewbar/ConditionalFormattingPanel";
import {LayerPanel} from "./spaceeditor/ui/viewbar/LayerPanel";
import {Dockable} from "./spaceeditor/ui/toolbar/Dockable";
import type {DockableTitle} from "./spaceeditor/ui/toolbar/Dockable";
import {SpaceEditor} from "./spaceeditor/ui/SpaceEditor";
import type {SpaceViewRenderer} from "./spaceeditor/logic3d/renderers/SpaceViewRenderer";

interface IBasicPropsFor3DTweaking {
	spaceViewRenderer: SpaceViewRenderer;
	appState?: AppState;
	transport?: TransportLayer;
	param1?: string;
}

interface ISpaceViewState {
	selectedItems: ISpaceItemModel[];
	isCatalogOpen: boolean;
	isCatalogDocked: boolean;
	isBoundaryTypesWindowOpen: boolean;
	isBoundaryTypesWindowDocked: boolean;
	isUnplottedXyiconsOpen: boolean;
	isUnplottedXyiconsDocked: boolean;
	space: Space;
	activeToolId: SpaceTool;
	selectionToolType: SelectionToolType;
	isLayerPanelOpen: boolean;
	isConditionalFormattingPanelOpen: boolean;
	isCaptionPanelOpen: boolean;
	isCreatePanelOpen: boolean;
	editedCatalog: {
		config: IIconConfig;
		catalog: Catalog;
	};
	catalogMode: "create" | "edit";
	managingColumns: boolean;
	sharingViewId: string;
	isSpaceToPDFExportPanelOpen: boolean;
}

@inject("appState")
@inject("transport")
@inject("app")
@observer
export class SpaceEditorView extends React.Component<IBasicPropsFor3DTweaking, ISpaceViewState> {
	private readonly _feature = XyiconFeature.SpaceEditor;
	private _requestAnimationFrameId: number = null;
	private _sidePanel = React.createRef<SidePanel<IModel>>();
	private _layerPanel = React.createRef<LayerPanel>();
	private _conditionalFormattingPanel = React.createRef<ConditionalFormattingPanel>();
	private _captionPanel = React.createRef<CaptionPanel>();
	private _filteredItems: ISpaceItemModel[] = [];
	private _splitter: Splitter;

	private _disposerForViewChangeListener: Lambda;
	private _disposerForFilterListener: Lambda;

	private _lastViewId: string = "";
	private _timeoutIds: Record<string, number> = {};

	constructor(props: IBasicPropsFor3DTweaking) {
		super(props);

		this.state = {
			selectedItems: [],
			isCatalogOpen: false,
			isCatalogDocked: false,
			isBoundaryTypesWindowOpen: false,
			isBoundaryTypesWindowDocked: false,
			isUnplottedXyiconsOpen: false,
			isUnplottedXyiconsDocked: false,
			space: null,
			activeToolId: "selection",
			selectionToolType: "selection",
			isLayerPanelOpen: false,
			isConditionalFormattingPanelOpen: false,
			isCaptionPanelOpen: false,
			isCreatePanelOpen: false,
			editedCatalog: null,
			catalogMode: "create",
			managingColumns: false,
			sharingViewId: null,
			isSpaceToPDFExportPanelOpen: false,
		};

		this.resetFilters();
	}

	private resetFilters() {
		const selectedView = this.selectedView;

		if (selectedView.id !== this._lastViewId) {
			// Selected view changed, or opening view the first time
			// -> copy filters from view
			this._lastViewId = selectedView.id;

			const savedFilters = selectedView.getSavedFilters();

			this.updateSpaceEditorFilterState(savedFilters);
			this.listenFilters();

			selectedView.setFilters(ObjectUtils.deepClone(savedFilters));
			this._sidePanel.current?.filterEditor?.reset(savedFilters);
		}
	}

	private onOpenSpaceToPDFExportPanel = () => {
		this.setState({
			isSpaceToPDFExportPanelOpen: true,
		});
	};

	private onCloseSpaceToPDFExportPanel = () => {
		this.setState({
			isSpaceToPDFExportPanelOpen: false,
		});
	};

	private setDockableDocked = (value: boolean, title: DockableTitle) => {
		if (title === "Catalog") {
			this.setState({
				isCatalogDocked: value,
			});

			if (value === this.state.isBoundaryTypesWindowDocked) {
				this.setState({
					isBoundaryTypesWindowOpen: false,
				});
			} else if (value === this.state.isUnplottedXyiconsDocked) {
				this.setState({
					isUnplottedXyiconsOpen: false,
				});
			}
		} else if (title === "Boundary") {
			this.setState({
				isBoundaryTypesWindowDocked: value,
			});

			if (value === this.state.isCatalogDocked) {
				this.setState({
					isCatalogOpen: false,
				});
			} else if (value === this.state.isUnplottedXyiconsDocked) {
				this.setState({
					isUnplottedXyiconsOpen: false,
				});
			}
		} else if (title === "Unplotted Xyicons") {
			this.setState({
				isUnplottedXyiconsDocked: value,
			});

			if (value === this.state.isCatalogDocked) {
				this.setState({
					isCatalogOpen: false,
				});
			}
			if (value === this.state.isBoundaryTypesWindowDocked) {
				this.setState({
					isBoundaryTypesWindowOpen: false,
				});
			}
		}
	};

	private isDocked(title: DockableTitle) {
		if (title === "Catalog") {
			return this.state.isCatalogDocked;
		} else if (title === "Boundary") {
			return this.state.isBoundaryTypesWindowDocked;
		} else if (title === "Unplotted Xyicons") {
			return this.state.isUnplottedXyiconsDocked;
		}
	}

	private setDockableOpen = (value: boolean, title: DockableTitle, onlyIfNotDocked: boolean = false) => {
		const isDocked = this.isDocked(title);

		if ((onlyIfNotDocked && !isDocked) || !onlyIfNotDocked) {
			if (title === "Catalog") {
				this.setState({
					isCatalogOpen: value,
				});

				if (value && this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked === this.state.isCatalogDocked) {
					this.setState({
						isBoundaryTypesWindowOpen: false,
					});
				}
				if (value && this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked === this.state.isCatalogDocked) {
					this.setState({
						isUnplottedXyiconsOpen: false,
					});
				}
			} else if (title === "Boundary") {
				this.setState({
					isBoundaryTypesWindowOpen: value,
				});

				if (value && this.state.isCatalogOpen && this.state.isCatalogDocked === this.state.isBoundaryTypesWindowDocked) {
					this.setState({
						isCatalogOpen: false,
					});
				}
				if (value && this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked === this.state.isBoundaryTypesWindowDocked) {
					this.setState({
						isUnplottedXyiconsOpen: false,
					});
				}
			} else if (title === "Unplotted Xyicons") {
				this.setState({
					isUnplottedXyiconsOpen: value,
				});

				if (value && this.state.isCatalogOpen && this.state.isCatalogDocked === this.state.isUnplottedXyiconsDocked) {
					this.setState({
						isCatalogOpen: false,
					});
				}
				if (value && this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked === this.state.isUnplottedXyiconsDocked) {
					this.setState({
						isBoundaryTypesWindowOpen: false,
					});
				}
			}
		}
	};

	private selectItems = (spaceItems: ISpaceItemModel[], selectDetailsTab?: boolean, forceUpdate: boolean = false) => {
		if (forceUpdate || !ObjectUtils.compare(this.state.selectedItems, spaceItems)) {
			this.setState({
				selectedItems: spaceItems,
			});

			if (spaceItems.length > 0) {
				if (selectDetailsTab) {
					this.selectTab("details");
					this.closeOverlayPanel("Layer");
					this.closeOverlayPanel("ConditionalFormatting");
					this.closeOverlayPanel("Caption");
				}
			}
		}
	};

	private setScale = async (spaceUnitsPerMeter: number) => {
		const {space} = this.props.appState;
		const params: RescaleSpaceRequest = {
			spaceID: space.id,
			unitsPerMeter: spaceUnitsPerMeter,
			portfolioID: this.props.appState.portfolioId,
		};

		await this.props.transport.requestForOrganization<SpaceFileDto>({
			url: "spaces/rescale",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		space.setSpaceUnitsPerMeter(spaceUnitsPerMeter);

		await this.props.spaceViewRenderer.refreshSpace(true);
	};

	private confirmAlignment = async (insertionInfo: SpaceFileInsertionInfo) => {
		const activePDFFile = this.props.spaceViewRenderer.space.selectedSpaceFile;

		activePDFFile.setInsertionInfo(insertionInfo);

		const params: UpdateSpaceFileRequest = {
			spaceFileList: [
				{
					spaceFileID: activePDFFile.id,
					spaceVersionID: activePDFFile.spaceVersionId,
					spaceID: activePDFFile.parent.id,
					settings: {
						insertionInfo: activePDFFile.insertionInfo,
					},
				},
			],
			portfolioID: activePDFFile.parent.portfolioId,
		};

		await this.props.transport.updateSpaceFiles(params);
		this.props.spaceViewRenderer.setInsertionInfo(activePDFFile.insertionInfo);

		this.forceUpdate();
	};

	private async loadSpace(space: Space) {
		await this.props.spaceViewRenderer.populateData(space);
		this.setState({
			space: space,
		});
	}

	private async updateSpace() {
		const space = this.props.appState.space;

		if (space) {
			if (this.props.spaceViewRenderer.space?.id !== space.id) {
				await this.loadSpace(space);
			}
		}
	}

	private setActiveTool = (id: SpaceTool) => {
		const {spaceViewRenderer} = this.props;

		if (arrayOfSelectionToolTypes.includes(id as SelectionToolType)) {
			this.setSelectionToolType(id as SelectionToolType);
		}

		if (this.state.activeToolId !== id) {
			this.setState({
				activeToolId: id,
			});
		}

		if (id !== "selection") {
			spaceViewRenderer.spaceItemController.closeLinks();
			spaceViewRenderer.spaceItemController.deselectAll();
		}

		if (id !== "selection" && id !== "pan" && !id.includes("tempMeasure") && spaceViewRenderer.isMeasureToolBarOpen) {
			if (spaceViewRenderer.isMeasureToolBarOpen) {
				spaceViewRenderer.markupManager.removeAllTempMarkups();
				spaceViewRenderer.isMeasureToolBarOpen = false;
			}
		}
	};

	private setSelectionToolType = (type: SelectionToolType) => {
		if (type !== this.state.selectionToolType) {
			this.setState({
				selectionToolType: type,
			});
		}
	};

	private getDockedTitle(): DockableTitle {
		if (this.state.isCatalogOpen && this.state.isCatalogDocked) {
			return "Catalog";
		} else if (this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked) {
			return "Boundary";
		} else if (this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked) {
			return "Unplotted Xyicons";
		} else {
			return null;
		}
	}

	private getFilteredItems() {
		return this.props.spaceViewRenderer.spaceItemController.getFilteredItems();
	}

	public selectTab = (id: string) => {
		this._sidePanel.current?.selectTab(id);
	};

	private onClickOnItem = (item: Boundary | Xyicon) => {
		if (this.props.spaceViewRenderer.isMounted) {
			this.props.spaceViewRenderer.toolManager.cameraControls.focusOn(item, false);
		}
	};

	private onAddCatalogClick = () => {
		this.setState({
			isCreatePanelOpen: true,
			editedCatalog: null,
			catalogMode: "create",
		});
	};

	private onDuplicateCatalogClick = async (catalog: Catalog) => {
		const iconConfig = await this.props.transport.getIconConfigOfCatalog(catalog);

		this.setState({
			isCreatePanelOpen: true,
			editedCatalog: {
				catalog: catalog,
				config: iconConfig,
			},
			catalogMode: "create",
		});
	};

	private closeCreatePanel = () => {
		this.setState({
			isCreatePanelOpen: false,
			editedCatalog: null,
		});
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private getOverlayPanelRef(type: OverlayPanel) {
		switch (type) {
			case "Layer":
				return this._layerPanel;
			case "ConditionalFormatting":
				return this._conditionalFormattingPanel;
			case "Caption":
				return this._captionPanel;
		}
	}

	private closeOverlayPanel(type: OverlayPanel) {
		const stateName: keyof ISpaceViewState = `is${type}PanelOpen`;
		const ref = this.getOverlayPanelRef(type).current as any;

		if (ref?.state?.isOpen) {
			ref?.setState({
				isOpen: false,
			});
		}

		clearTimeout(this._timeoutIds[stateName]);

		this._timeoutIds[stateName] = window.setTimeout(() => {
			if (this.state[stateName]) {
				this.setState({
					[stateName]: false,
				} as any);
			}
		}, 200); // derivated from CSS (0.2s)
	}

	private onToggleOverlayPanel(type: OverlayPanel) {
		const stateName = `is${type}PanelOpen`;
		const otherStateNames = ["isLayerPanelOpen", "isConditionalFormattingPanelOpen", "isCaptionPanelOpen"].filter((value) => value !== stateName);
		const currentState = this.state[stateName as keyof ISpaceViewState];

		if (!currentState) {
			this.setState({
				[stateName]: true,
			} as any);

			for (const otherStateName of otherStateNames) {
				this.setState({
					[otherStateName]: false,
				} as any);
			}

			const minWidth = 550; //px
			// Set the width of the splitter to ${minWidth}px, if it's smaller than that, and turning on captions, formatting, or layers
			// See #4102 for more details: https://dev.azure.com/xyicon/SpaceRunner%20V4/_workitems/edit/4102)

			const ratios = [...this._splitter.ratios];
			const numberOfSections = ratios.length;
			const ratioOfRightSection = ratios.pop();
			const ratioOfMiddleSection = ratios.pop();
			const browserWidth = window.innerWidth; // 1920; // We designed our webapplication with full hd screen size in mind, because most of our customers use this resolution
			const widthOfRightSection = ratioOfRightSection * browserWidth;
			const widthOfMiddleSection = ratioOfMiddleSection * browserWidth;

			if (widthOfRightSection < minWidth) {
				const difference = minWidth - widthOfRightSection;
				const ratioOfLeftSection = ratios.pop();

				if (widthOfMiddleSection < difference) {
					// can't set it to negative, so let's split it up to 2 equal parts
					if (numberOfSections === 2) {
						this._splitter.setRatios([0.5, 0.5]);
					} // if (numberOfSections === 3)
					else {
						const newRatioOfMiddleAndRight = (1 - ratioOfLeftSection) / 2;

						this._splitter.setRatios([ratioOfLeftSection, newRatioOfMiddleAndRight, newRatioOfMiddleAndRight]);
					}
				} else {
					const newRatioOfRightSection = minWidth / browserWidth;

					if (numberOfSections === 2) {
						this._splitter.setRatios([1 - newRatioOfRightSection, newRatioOfRightSection]);
					} // if (numberOfSections === 3)
					else {
						this._splitter.setRatios([ratioOfLeftSection, 1 - ratioOfLeftSection - newRatioOfRightSection, newRatioOfRightSection]);
					}
				}
			}
		} else {
			this.closeOverlayPanel(type);
		}
	}

	private spaceViewShareClick = (viewId: string) => {
		this.toggleSharingPanel(viewId);
	};

	private onToggleLayerPanel = () => {
		this.onToggleOverlayPanel("Layer");
	};

	private onToggleConditionalFormattingPanel = () => {
		this.onToggleOverlayPanel("ConditionalFormatting");
	};

	private onToggleCaptionPanel = () => {
		this.onToggleOverlayPanel("Caption");
	};

	private getOpenOverlayPanelType(): OverlayPanel {
		if (this.state.isLayerPanelOpen) {
			return "Layer";
		} else if (this.state.isConditionalFormattingPanelOpen) {
			return "ConditionalFormatting";
		} else if (this.state.isCaptionPanelOpen) {
			return "Caption";
		}

		return null;
	}

	private getOverlayPanel() {
		if (this.state.isLayerPanelOpen) {
			return (
				<LayerPanel
					ref={this._layerPanel}
					spaceViewRenderer={this.props.spaceViewRenderer}
					showNumbers={true}
					view={this.selectedView}
					onToggle={this.onToggleLayerPanel}
				/>
			);
		} else if (this.state.isConditionalFormattingPanelOpen) {
			return (
				<ConditionalFormattingPanel
					ref={this._conditionalFormattingPanel}
					spaceViewRenderer={this.props.spaceViewRenderer}
					view={this.selectedView}
					onToggle={this.onToggleConditionalFormattingPanel}
				/>
			);
		} else if (this.state.isCaptionPanelOpen) {
			return (
				<CaptionPanel
					ref={this._captionPanel}
					spaceViewRenderer={this.props.spaceViewRenderer}
					view={this.selectedView}
					onToggle={this.onToggleCaptionPanel}
				/>
			);
		} else {
			return null;
		}
	}

	private get selectedView() {
		return this.props.appState.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private onManageColumns = () => {
		this.setState({managingColumns: true});
	};

	private onCloseManageColumns = () => {
		this.setState({managingColumns: false});
	};

	private toggleSharingPanel = (viewId?: string) => {
		this.setState({sharingViewId: viewId});
	};

	private getSidePanel() {
		const features = [XyiconFeature.Xyicon, XyiconFeature.Boundary];

		this._filteredItems = this.getFilteredItems();

		return (
			<div className="relative">
				<SidePanel
					features={features}
					feature={XyiconFeature.SpaceEditor}
					items={this._filteredItems}
					selected={this.state.selectedItems}
					onSelect={Functions.emptyFunction}
					onClick={this.onClickOnItem}
					showGridForFeatures={[XyiconFeature.Xyicon, XyiconFeature.Boundary]}
					ref={this._sidePanel}
					isPortTemplateEditorOpen={false}
					setPortTemplateEditorOpen={Functions.emptyFunction}
					onManageColumns={this.onManageColumns}
					loading={!this.props.spaceViewRenderer.isMounted}
				/>
				{this.getOverlayPanel()}
			</div>
		);
	}

	private stringifyFilters = () => {
		return JSON.stringify(this.selectedView.filters);
	};

	private startListening() {
		this._disposerForViewChangeListener = observe(this.props.appState.selectedViewId, (this._feature as any).toString(), (a) => {
			this.resetFilters();
		});

		this.listenFilters();
	}

	private listenFilters() {
		this._disposerForFilterListener?.();
		this._disposerForFilterListener = reaction(this.stringifyFilters, () => {
			this.updateSpaceEditorFilterState(this.selectedView.filters);
		});
	}

	private stopListening() {
		this._disposerForViewChangeListener?.();
		this._disposerForViewChangeListener = null;

		this._disposerForFilterListener?.();
		this._disposerForFilterListener = null;
	}

	private onSplitterDidMount = (splitter: Splitter) => {
		if (splitter) {
			this._splitter = splitter;
			const ratios = this.props.transport.services.localStorage.getSplitterRatios();

			if (ratios && ratios.length >= 2) {
				splitter.setRatios(ratios);
				this.props.spaceViewRenderer.onResize();
			}
		}
	};

	private onSplitterChange = (ratios: number[]) => {
		const {transport} = this.props;

		transport.services.localStorage.setSplitterRatios(ratios);
	};

	private saveStateToLocalStorage() {
		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const stateToSave = {
				isBoundaryTypesWindowDocked: this.state.isBoundaryTypesWindowDocked,
				isBoundaryTypesWindowOpen: this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked,
				isCatalogDocked: this.state.isCatalogDocked,
				isCatalogOpen: this.state.isCatalogOpen && this.state.isCatalogDocked,
				isUnplottedXyiconsDocked: this.state.isUnplottedXyiconsDocked,
				isUnplottedXyiconsOpen: this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked,
			};

			this.props.transport.services.localStorage.set(this.getLocalStorageKey(organizationId), stateToSave);
		}
	}

	private getLocalStorageKey(organizationId: string) {
		return `srv4-org-${organizationId}-spaceeditor-spaceview-dockables-open-state`;
	}

	private loadStateFromLocalStorage() {
		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const savedDockedState = this.props.transport.services.localStorage.get(this.getLocalStorageKey(organizationId));

			if (savedDockedState) {
				this.setState({
					...savedDockedState,
				});
			}
		}
	}

	private updateSpaceEditorFilterState = (filters: IFilterState = this.selectedView.filters) => {
		cancelAnimationFrame(this._requestAnimationFrameId);
		this._requestAnimationFrameId = window.requestAnimationFrame(() => {
			this.props.spaceViewRenderer.actions.updateSpaceEditorFilterState(filters);
		});
	};

	private onSpaceEditorDidMount = () => {
		this.updateSpaceEditorFilterState();
		this.forceUpdate();
	};

	public override async componentDidMount() {
		this.startListening();

		this.loadStateFromLocalStorage();

		const {actions} = this.props.appState;

		const features = actions.getLoadingDependencies(XyiconFeature.SpaceEditor);

		for (const feature of features) {
			await this.props.transport.services.feature.refreshList(feature);
		}
		await this.updateSpace();
		const {xyiconManager, boundaryManager, markupManager} = this.props.spaceViewRenderer;

		xyiconManager.signals.itemsAdd.add(this.forceUpdateArrow);
		boundaryManager.signals.itemsAdd.add(this.forceUpdateArrow);
		markupManager.signals.itemsAdd.add(this.forceUpdateArrow);

		xyiconManager.signals.itemsRemove.add(this.forceUpdateArrow);
		boundaryManager.signals.itemsRemove.add(this.forceUpdateArrow);
		markupManager.signals.itemsRemove.add(this.forceUpdateArrow);

		// Add potential new types
		actions.getSelectedView(XyiconFeature.SpaceEditor).updateSpaceEditorViewSettings();

		// There's a chance we get to this line when the spaceeditor is already fully loaded,
		// So it can't hurt to call this function again, just to be sure
		this.onSpaceEditorDidMount();
		this.props.spaceViewRenderer.signals.spaceLoadReady.add(this.onSpaceEditorDidMount);
	}

	public override async componentDidUpdate() {
		await this.updateSpace();
		this.saveStateToLocalStorage();
	}

	public override componentWillUnmount() {
		this.stopListening();

		const {xyiconManager, boundaryManager, markupManager} = this.props.spaceViewRenderer;

		xyiconManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		boundaryManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		markupManager.signals.itemsAdd.remove(this.forceUpdateArrow);

		xyiconManager.signals.itemsRemove.remove(this.forceUpdateArrow);
		boundaryManager.signals.itemsRemove.remove(this.forceUpdateArrow);
		markupManager.signals.itemsRemove.remove(this.forceUpdateArrow);

		this.props.spaceViewRenderer.signals.spaceLoadReady.remove(this.onSpaceEditorDidMount);
	}

	private onDeleteCurrentUserFromViewShareList = () => {
		const {actions} = this.props.spaceViewRenderer;

		actions.selectViewById(actions.getViews(XyiconFeature.Space)[0].id);
	};

	public override render() {
		const space = this.props.appState.space;

		const dockedTitle = this.getDockedTitle();
		const isDockedAndOpen = !!dockedTitle;

		if (space) {
			return (
				<div className="SpaceView">
					<Splitter
						ref={this.onSplitterDidMount}
						className={ReactUtils.cls({blurred: this.state.isCreatePanelOpen || this.state.sharingViewId})}
						onChange={this.onSplitterChange}
					>
						{isDockedAndOpen && (
							<Dockable
								spaceViewRenderer={this.props.spaceViewRenderer}
								setDocked={this.setDockableDocked}
								setOpen={this.setDockableOpen}
								isDocked={isDockedAndOpen}
								title={dockedTitle}
								setActiveTool={this.setActiveTool}
								onAddCatalogClick={this.onAddCatalogClick}
								onDuplicateCatalogClick={this.onDuplicateCatalogClick}
								onCreateUnplottedXyicons={Functions.emptyFunction}
							/>
						)}
						<SpaceEditor
							spaceViewRenderer={this.props.spaceViewRenderer}
							setDockableDocked={this.setDockableDocked}
							setDockableOpen={this.setDockableOpen}
							isCatalogOpen={this.state.isCatalogOpen}
							isBoundaryTypesWindowOpen={this.state.isBoundaryTypesWindowOpen}
							isUnplottedXyiconsOpen={this.state.isUnplottedXyiconsOpen}
							isCatalogDocked={this.state.isCatalogDocked}
							isBoundaryTypesWindowDocked={this.state.isBoundaryTypesWindowDocked}
							isUnplottedXyiconsDocked={this.state.isUnplottedXyiconsDocked}
							setScale={this.setScale}
							confirmAlignment={this.confirmAlignment}
							thumbnail={this.state.space?.thumbnailFileURL}
							mode={SpaceEditorMode.NORMAL}
							activeToolId={this.state.activeToolId}
							setActiveTool={this.setActiveTool}
							selectionToolType={this.state.selectionToolType}
							selectItems={this.selectItems}
							sidePanelRef={this._sidePanel}
							onToggleConditionalFormattingPanel={this.onToggleConditionalFormattingPanel}
							onToggleLayerPanel={this.onToggleLayerPanel}
							onToggleCaptionPanel={this.onToggleCaptionPanel}
							openOverlayerPanel={this.getOpenOverlayPanelType()}
							isLayerPanelOpen={this.state.isLayerPanelOpen}
							isCaptionPanelOpen={this.state.isCaptionPanelOpen}
							onAddCatalogClick={this.onAddCatalogClick}
							onDuplicateCatalogClick={this.onDuplicateCatalogClick}
							spaceViewShareClick={this.spaceViewShareClick}
							onOpenSpaceToPDFExportPanel={this.onOpenSpaceToPDFExportPanel}
						/>
						{this.getSidePanel()}
					</Splitter>
					<SpaceToPDFExportPanel
						isOpen={this.state.isSpaceToPDFExportPanelOpen}
						onClose={this.onCloseSpaceToPDFExportPanel}
					/>
					<div
						className={ReactUtils.cls("createPanel CatalogItemPanel SidePanel", {
							open: this.state.isCreatePanelOpen || this.state.managingColumns,
							manageColumns: this.state.managingColumns,
						})}
					>
						{this.state.isCreatePanelOpen && (
							<CatalogItemPanel
								onClose={this.closeCreatePanel}
								iconConfig={this.state.editedCatalog?.config}
								catalog={this.state.editedCatalog?.catalog}
								mode={this.state.catalogMode}
							/>
						)}
						{this.state.managingColumns && (
							<ManageColumnsPanel
								feature={this._sidePanel.current?.getSelectedGridTabFeature()}
								view={this.selectedView}
								onClose={this.onCloseManageColumns}
							/>
						)}
					</div>
					<div className={ReactUtils.cls("sharingPanel", {open: this.state.sharingViewId})}>
						<EditViewSharing
							view={this.props.appState.actions.getViewById(this.state.sharingViewId)}
							feature={XyiconFeature.SpaceEditor}
							close={() => this.toggleSharingPanel()}
							onDeleteCurrentUser={this.onDeleteCurrentUserFromViewShareList}
						/>
					</div>
				</div>
			);
		}

		// TODO loading?
		return null;
	}
}

import * as React from "react";
import {Button} from "../../button/Button";
import {DateUtils} from "../../../../utils/DateUtils";
import {NumberSlider} from "./NumberSlider";

interface ITimePickerProps {
	value: Date;
	onChange: (value: string) => void;
}

enum TimeFormat {
	h12 = 1,
	h24 = 2,
}

interface ITimePickerState {
	format: TimeFormat;
}

export class TimePicker extends React.PureComponent<ITimePickerProps, ITimePickerState> {
	constructor(props: ITimePickerProps) {
		super(props);

		this.state = {
			format: TimeFormat.h12,
		};
	}

	private onHourChange = (value: number) => {
		const date = this.props.value;
		const hourDifference = (value % 12) - (date.getHours() % 12);

		date.setHours(date.getHours() + hourDifference);
		this.props.onChange(DateUtils.stringify(date));
	};

	private onMinuteChange = (value: number) => {
		const date = this.props.value;

		date.setMinutes(value);
		this.props.onChange(DateUtils.stringify(date));
	};

	private on24hChange = (value: string) => {
		const date = new Date(this.props.value);

		if (value === "AM" && value !== this.period) {
			date.setHours(date.getHours() - 12);
		}
		if (value === "PM" && value !== this.period) {
			date.setHours(date.getHours() + 12);
		}

		this.props.onChange(DateUtils.stringify(date));
	};

	private get hour() {
		return this.props.value.getHours() % 12;
	}

	private get minute() {
		return this.props.value.getMinutes();
	}

	private get period() {
		const hours = this.props.value.getHours();

		return hours % 12 === hours ? "AM" : "PM";
	}

	public override render() {
		const {format} = this.state;

		return (
			<div className="TimePicker hbox justifyCenter">
				<div className="sliderContainer">
					<NumberSlider
						time24hFormat={true}
						value={this.hour === 0 ? 12 : this.hour}
						min={0}
						max={11}
						onChange={this.onHourChange}
					/>
					<div className="dots vbox">
						<span>:</span>
					</div>
					<NumberSlider
						time24hFormat={true}
						value={this.minute}
						min={0}
						max={59}
						onChange={this.onMinuteChange}
					/>
				</div>
				{format === TimeFormat.h12 && (
					<div className="vbox switch">
						<Button
							onClick={() => this.on24hChange("AM")}
							label="AM"
							className={this.period === "AM" ? "primary" : "secondary"}
						/>
						<Button
							onClick={() => this.on24hChange("PM")}
							label="PM"
							className={this.period === "PM" ? "primary" : "secondary"}
						/>
					</div>
				)}
			</div>
		);
	}
}

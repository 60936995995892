import styled from "styled-components";
import {baseDistance, fontSize, radius} from "../../../../styles/styles";
import {colorPalette} from "../../../../styles/colorPalette";

export const ReportWizardStyled = styled.div`
	.reportPage {
		margin-top: 20px;
		align-items: center;
		display: flex;
		flex-direction: column;
		min-height: 350px;
		max-height: 450px;

		.ReportDetails {
			width: 500px;
			margin-left: auto;
			margin-right: auto;

			.Field {
				padding: 5px;
				color: ${colorPalette.gray.c950};

				&.currenPortfolio .element {
					font-size: 14px;
					margin-left: 8px;
					color: ${colorPalette.gray.c700Dark};
				}

				textarea,
				input {
					border: solid 1px #c8c8c8;
					border-radius: ${radius.sm};
					width: 100%;
					background-color: white;
					color: ${colorPalette.gray.c700Dark};
				}

				textarea {
					height: 32px;
					padding: 6px 8px;
				}
			}
		}

		.btn-container {
			padding: ${baseDistance.sm};
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.clear-all {
				font-size: ${fontSize.md};
				color: ${colorPalette.gray.c400};
				cursor: pointer;
				padding-top: ${baseDistance.sm};
			}
		}
	}
`;

export const ButtonContainerStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	padding-bottom: 16px;
	padding-right: 16px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	.saveDropdown {
		width: auto;
		background-color: ${colorPalette.blueGray.c500Primary};
		color: white;
		font-size: ${fontSize.md};

		&:hover {
			background-color: ${colorPalette.primary.c700Dark};
		}
	}
`;

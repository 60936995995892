import type {Object3D} from "three";
import {BufferAttribute, BufferGeometry, DynamicDrawUsage, Line} from "three";

import {Constants} from "../Constants";
import type {SpaceItem} from "../elements3d/SpaceItem";
import {BasicMaterial} from "../materials/BasicMaterial";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../utils/THREEUtils";

export class ZLineManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _container: Object3D;
	private _lines: Line[] = [];

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._container = this._spaceViewRenderer.topLayerScene;
	}

	private destroy(line: Line) {
		THREEUtils.disposeAndClearContainer(line);
		line.geometry.dispose();
		line.parent?.remove(line);
	}

	public destroyAll() {
		for (const line of this._lines) {
			this.destroy(line);
		}

		this._lines.length = 0;

		this._spaceViewRenderer.needsRender = true;
	}

	private addZLineToSpaceItem(spaceItem: SpaceItem, zDepth: number) {
		const {x, y} = spaceItem.position;

		const vertices = new Float32Array([x, y, -zDepth, x, y, zDepth]);

		const geometry = new BufferGeometry();

		geometry.setAttribute("position", new BufferAttribute(vertices, 3));
		(geometry.attributes.position as BufferAttribute).setUsage(DynamicDrawUsage);

		const material = new BasicMaterial(parseInt(`0x${Constants.COLORS.PICTON_BLUE_HOVER}`), 1.0, false, true);

		const line = new Line(geometry, material);

		line.name = `ZLine of ${spaceItem.modelData.refId ?? "item"}`;

		THREEUtils.add(this._container, line);
		this._lines.push(line);
	}

	public update() {
		this.destroyAll();

		const selectedItems = this._spaceViewRenderer.xyiconManager.selectedItems;

		const zDepth = Math.max(this._spaceViewRenderer.spaceSize.x, this._spaceViewRenderer.spaceSize.y);

		for (const selectedItem of selectedItems) {
			this.addZLineToSpaceItem(selectedItem, zDepth);
		}

		this._spaceViewRenderer.needsRender = true;
	}
}

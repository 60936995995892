import {observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {View} from "../../../../data/models/View";
import type {ViewChangeType} from "../../../../data/models/ViewUtils";
import {SaveToViewButtonV5} from "../view/SaveToViewButtonV5";
import {AlertV5} from "../../popup/AlertV5";
import {colorPalette} from "../../styles/colorPalette";
import {FlexCenterStyle} from "../../styles/styles";

interface IUnsavedLayoutProps {
	selectedView: View;
	label?: boolean;
	noIcon?: boolean;
}

@observer
export class UnsavedLayoutV5 extends React.Component<IUnsavedLayoutProps> {
	private getViewChangeType(): ViewChangeType | null {
		const typesToCheckChangesFor: ViewChangeType[] = ["columns", "column sorts"];

		for (const type of typesToCheckChangesFor) {
			const hasUnsavedData = this.props.selectedView.hasUnsavedChanges(type);

			if (hasUnsavedData) {
				return type;
			}
		}

		return null;
	}

	public override render() {
		const {selectedView, label, noIcon} = this.props;

		if (selectedView?.id) {
			const viewChangeType = this.getViewChangeType();

			if (viewChangeType) {
				return (
					<AlertV5>
						<>
							{label !== false && (
								<TextStyled>
									<PrimaryText>Grid layout modified</PrimaryText>
									<SecondaryText>Would you like to save the changes?</SecondaryText>
								</TextStyled>
							)}
							<SaveToViewButtonV5
								feature={selectedView.itemFeature}
								viewChangeType={viewChangeType}
								noIcon={!!noIcon}
							/>
						</>
					</AlertV5>
				);
			}
		}

		return null;
	}
}

const TextStyled = styled.div`
	${FlexCenterStyle};
	flex-direction: column;
	align-items: flex-start;
	font-size: 14px;
	line-height: 16px;
	width: 200px;
`;

const PrimaryText = styled.div`
	color: ${colorPalette.gray.c950};
`;

const SecondaryText = styled.div`
	color: ${colorPalette.gray.c700Dark};
	font-size: 12px;
`;

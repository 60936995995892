import {Functions} from "./function/Functions";

export class AppUtils {
	public static disableScrolling(switchOn: boolean) {
		const appContent = document.querySelector(".appContent");

		if (switchOn) {
			appContent.addEventListener("wheel", Functions.preventDefault, {capture: false, passive: false});
		} else {
			appContent.removeEventListener("wheel", Functions.preventDefault);
		}
	}
}

export const appHolder = () => document.getElementById("appHolder");

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature, Permission} from "../../../../../generated/api/base";
import {SelectSlider} from "../../../../widgets/input/selectslider/SelectSlider";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {SearchField} from "../../../../widgets/input/search/SearchField";
import {ReactUtils} from "../../../../utils/ReactUtils";

interface IFieldPermissionsProps {
	permissionSet: PermissionSet;
	feature: XyiconFeature;
	appState?: AppState;
	transport?: TransportLayer;
}

interface IFieldPermissionState {
	searchQuery: string;
}

@inject("appState")
@inject("transport")
@observer
export class FieldPermissions extends React.Component<IFieldPermissionsProps, IFieldPermissionState> {
	constructor(props: IFieldPermissionsProps) {
		super(props);
		this.state = {
			searchQuery: "",
		};
	}

	public override render() {
		const {permissionSet, feature, appState, transport} = this.props;
		const {searchQuery} = this.state;
		const rows = appState.fields[feature].slice().sort((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));

		return (
			<div className={ReactUtils.cls("vbox container", {paddingTop: feature !== XyiconFeature.Portfolio})}>
				<h4>Field Permissions</h4>
				<SearchField
					value={searchQuery}
					onInput={(value) => this.setState({searchQuery: value})}
				/>
				<SelectSlider
					options={[
						{
							id: Permission.None,
							label: "Hide",
						},
						{
							id: Permission.View,
							label: "Read Only",
						},
						{
							id: Permission.Update,
							label: "Write",
						},
					]}
					rows={rows
						.filter((field) => StringUtils.containsIgnoreCase(field.name, searchQuery))
						.map((field) => {
							return {
								label: field.name,
								value:
									feature === XyiconFeature.Portfolio
										? permissionSet.getPortfolioFieldPermission(field.refId)
										: permissionSet.getFieldPermission(field.refId, feature),
							};
						})}
					onChange={(rowLabels: string[], value) => {
						rowLabels.forEach((label) => {
							const field = rows.find((row) => row.name === label);

							if (field) {
								if (feature === XyiconFeature.Portfolio) {
									permissionSet.setPortfolioFieldPermission(field.refId, value as Permission);
								} else {
									permissionSet.setFieldPermission(feature, field.refId, value as Permission);
								}
							}
						});

						transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
					}}
					fixedSliderLength={true}
				/>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IModulePanelProps} from "../../abstract/ModuleView";
import {IconButton} from "../../../widgets/button/IconButton";
import {Field} from "../../../widgets/form/field/Field";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import {Button} from "../../../widgets/button/Button";
import {XyiconFeature} from "../../../../generated/api/base";
import type {Type} from "../../../../data/models/Type";
import {ClickToEditInput} from "../../../widgets/input/clicktoedit/ClickToEditInput";

interface ICreatePortfolioPanelState {
	name: string;
	selectedType: Type;
	createClicked: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePortfolioPanel extends React.PureComponent<IModulePanelProps, ICreatePortfolioPanelState> {
	constructor(props: IModulePanelProps) {
		super(props);

		this.state = {
			name: "",
			selectedType: null,
			createClicked: false,
		};
	}

	public override componentDidMount() {
		const types = this.props.appState.types[XyiconFeature.Portfolio];

		if (types) {
			this.setState({
				selectedType: null,
			});
		}
	}

	private isValid = (name: string) => {
		return this.props.appState.actions.isPortfolioNameValid(name);
	};

	private onCreateClicked = async () => {
		this.setState({createClicked: true});

		const {name} = this.state;

		try {
			const models = await this.props.transport.services.feature.create(
				{
					name: name,
					portfolioTypeID: this.state.selectedType.id,
				},
				XyiconFeature.Portfolio,
			);

			this.props.onClose(models?.[0]?.id);
		} catch (error) {
			console.warn("error", error);
		}
	};

	private onInput = (name: string) => {
		this.setState({name});
	};

	public override render() {
		const {appState} = this.props;
		const {selectedType, name, createClicked} = this.state;

		const isFormValid = selectedType && selectedType.id && this.isValid(name);
		const types = appState.types[XyiconFeature.Portfolio];

		return (
			<div className="CreatePortfolioPanel SidePanel">
				<div className="heading createBox hbox">
					<h4>Create Portfolio Item</h4>
					<IconButton
						className="closePanel"
						icon="close"
						onClick={(event) => this.props.onClose()}
					/>
				</div>
				<div className="sidePanelButtons">
					<Button
						label="Cancel"
						title="Cancel"
						onClick={(event) => this.props.onClose()}
					/>
					<Button
						label="Create"
						title="Create"
						onClick={this.onCreateClicked}
						disabled={!isFormValid || createClicked}
						className="primary"
					/>
				</div>
				<Field
					label="Name"
					className="focused"
				>
					<ClickToEditInput
						value={name}
						onLiveChange={this.onInput}
						valueValidator={this.isValid}
						focused={true}
						noButtons={true}
					/>
				</Field>
				<Field
					label="Type"
					className="focused"
				>
					<SelectInput
						options={types}
						render={(type) => (type ? type.name : "")}
						selected={selectedType}
						onChange={(type) => this.setState({selectedType: type})}
						fullWidth={true}
					/>
				</Field>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {XyiconFeature} from "../../../generated/api/base";
import type {DocumentModel} from "../../../data/models/DocumentModel";
import {isOpenableInNewTab} from "../../../data/models/FileType";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {LoaderIconV5} from "../loader/LoaderIconV5";
import type {AppState} from "../../../data/state/AppState";
import type {App} from "../../../App";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {IModel} from "../../../data/models/Model";
import {FileUtils} from "../../../utils/file/FileUtils";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {FlexCenter, VerticalFlex} from "../styles/styles";
import DownloadIcon from "../icons/download.svg?react";
import DeleteIcon from "../icons/delete.svg?react";
import OpenInNewTabIcon from "../icons/arrow-up-right-from-square.svg?react";
import TileViewIcon from "../icons/table-cells-large.svg?react";
import CardViewIcon from "../icons/table-list.svg?react";
import {colorPalette} from "../styles/colorPalette";
import {DocumentViewV5} from "./DocumentViewV5";

interface IDocumentSectionProps {
	items: IModel[];
	feature: XyiconFeature;
	app?: App;
	appState?: AppState;
	transport?: TransportLayer;
	saveStateToLocalStorage?: boolean;
}

interface IDocumentSectionState {
	search: string;
	listView: boolean;
	loading: boolean;
	uploadArray: IDocumentUpload[];
}

interface IDocumentUpload {
	count: number;
	id: string;
}

export interface DocumentSectionItem {
	document: DocumentModel;
	inherited: boolean;
	allowDelete: boolean;
}

@inject("app")
@inject("appState")
@inject("transport")
@observer
export class DocumentSectionV5 extends React.Component<IDocumentSectionProps, IDocumentSectionState> {
	private static readonly allowedFeatures = [
		XyiconFeature.Portfolio,
		XyiconFeature.Space,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
	];

	private _documentItems: DocumentSectionItem[] = [];

	constructor(props: IDocumentSectionProps) {
		super(props);
		this.state = {
			search: "",
			listView: this.readListView(),
			loading: false,
			uploadArray: [],
		};
	}

	private readListView() {
		const {appState, transport} = this.props;
		const organizationId = appState.organizationId;

		if (organizationId) {
			return Boolean(transport.services.localStorage.get(this.getKey(organizationId)));
		}
		return false;
	}

	private saveListView(value: boolean) {
		const {appState, transport} = this.props;
		const organizationId = appState.organizationId;

		if (organizationId) {
			transport.services.localStorage.set(this.getKey(organizationId), value);
		}
	}

	private getKey(organizationId: string = "") {
		return `srv4-org-${organizationId}-documentsection-listview`;
	}

	private getOptions = (docItem: DocumentSectionItem): IDropdownOption[] => {
		const document = docItem.document;
		const isOpenableInNewTabSupported = isOpenableInNewTab(document.fileExtension);

		return [
			isOpenableInNewTabSupported && {
				label: "Open in a new tab",
				onClick: () => this.openInNewTab(document),
				IconComponent: OpenInNewTabIcon,
			},
			{
				label: "Download",
				onClick: () => this.onDownloadClick(document),
				IconComponent: DownloadIcon,
			},
			docItem.allowDelete && {
				label: "Delete",
				onClick: () => this.onDeleteClick(document),
				IconComponent: DeleteIcon,
			},
		].filter((option) => !!option);
	};

	private openInNewTab = (document: DocumentModel) => {
		const path = this.props.transport.services.document.getFilePath(document);

		window.open(path);
	};

	private onDownloadClick = (document: DocumentModel) => {
		this.props.transport.services.document.download(document);
	};

	private onDeleteClick = async (document: DocumentModel) => {
		await this.props.transport.services.document.deleteDocument(document);
	};

	private onAddClicked = () => {
		const {items, transport} = this.props;
		const array = [...this.state.uploadArray];

		FileUtils.openFileDialogue(true, null, (fileList: FileList) => {
			{
				const element = array.find((el) => el.id === items[0].id);

				if (element) {
					element.count += fileList.length;
				} else {
					array.push({id: items[0].id, count: fileList.length});
				}
			}

			this.setState({uploadArray: array});

			for (let i = 0; i < fileList.length; ++i) {
				const file = fileList[i];

				transport.services.document.createDocument(file, items[0]).then(() => {
					array[array.findIndex((i) => i.id === items[0].id)].count -= 1;
					const newArray = [...array];

					this.setState({uploadArray: newArray});
				});
			}
		});
	};

	private onSearchChange = (value: string) => {
		this.setState({search: value});
	};

	private onListViewClicked = () => {
		this.setState({listView: true});
		this.saveListView(true);
	};

	private onCardViewClicked = () => {
		this.setState({listView: false});
		this.saveListView(false);
	};

	public override componentDidMount() {
		this.props.transport.services.feature.refreshList(XyiconFeature.Document);
	}

	private getDocuments() {
		const {items, appState} = this.props;
		const {search} = this.state;

		// TODO optimize in case there'll be lots of documents
		const results: DocumentSectionItem[] = [];

		appState.actions.getList<DocumentModel>(XyiconFeature.Document).forEach((doc) => {
			if (search) {
				if (!StringUtils.containsIgnoreCase(doc.fileName, search)) {
					return;
				}
			}

			let inherited = false;
			let isAttached = false;

			let usageCount = 0;

			for (const item of items) {
				if (item.ownFeature === XyiconFeature.Xyicon) {
					if (doc.isAttachedToObject(item.catalogId)) {
						inherited = true;
						usageCount++;
					}
				}

				if (doc.isAttachedToObject(item.id)) {
					isAttached = true;
					usageCount++;
				}
			}

			if (isAttached) {
				inherited = false;
			}

			if (isAttached || inherited) {
				results.push({
					document: doc,
					// We only allow deleting a document if it belong to one of the selected items and
					// it doesn't belong to more than one of the selected items
					inherited: inherited,
					allowDelete: !inherited && usageCount < 2,
				});
			}
		});

		return results;
	}

	public override render() {
		const {items, feature, appState} = this.props;
		const {listView, search, uploadArray} = this.state;
		const isLoading = !appState.lists[feature].loaded;
		const count = uploadArray.find((i) => i.id === items[0].id)?.count || 0;

		if (!DocumentSectionV5.allowedFeatures.includes(feature)) {
			return null;
		}

		this._documentItems = this.getDocuments();

		return (
			<ToggleContainerV5
				title="Documents"
				onAddClicked={items.length === 1 ? this.onAddClicked : null}
				saveStateToLocalStorage={this.props.saveStateToLocalStorage}
			>
				<DocumentSectionStyled>
					{!isLoading ? (
						<VerticalFlex $gap="16px">
							<FlexCenter $gap="8px">
								<SearchFieldV5
									value={search}
									placeholder={"Find ..."}
									onInput={this.onSearchChange}
								/>
								<IconButtonV5
									IconComponent={CardViewIcon}
									isActive={listView}
									onClick={this.onListViewClicked}
									className="layout-icon"
								/>
								<IconButtonV5
									IconComponent={TileViewIcon}
									isActive={!listView}
									onClick={this.onCardViewClicked}
									className="layout-icon"
								/>
							</FlexCenter>
							<DocumentViewV5
								documentItems={this._documentItems}
								getOptions={this.getOptions}
								uploadingArraySize={count}
								type={listView ? "card" : "tile"}
							/>
						</VerticalFlex>
					) : (
						<VerticalFlex>
							<LoaderIconV5 />
							<span>Loading Documents...</span>
						</VerticalFlex>
					)}
				</DocumentSectionStyled>
			</ToggleContainerV5>
		);
	}
}

const DocumentSectionStyled = styled.div`
	.layout-icon {
		&.isActive {
			background-color: ${colorPalette.primary.c200Light};

			svg path {
				stroke: ${colorPalette.blueGray.c500Primary};
			}
		}
	}
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {Constants} from "../../logic3d/Constants";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {PointerDetectorReact} from "../../../../../interaction/PointerDetectorReact";
import {LogarithmicSlider} from "../../../../abstract/common/slider/LogarithmicSlider";
import {Xyicon} from "../../../../../../data/models/Xyicon";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import type {BoundaryDto, BoundarySpaceMapDto, MarkupDto, XyiconDto} from "../../../../../../generated/api/base";
import type {AppState} from "../../../../../../data/state/AppState";
import {Markup} from "../../../../../../data/models/Markup";
import {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import {NavigationCameraBox} from "./NavigationCameraBox";
import {NavigationControlButtons} from "./NavigationControlButtons";

interface INavigationBoxProps {
	spaceViewRenderer: SpaceViewRenderer;
	navigationBoxImgSrc: string;
	onCloseClick: () => void;
	appState?: AppState;
}

@inject("appState")
@observer
export class NavigationBox extends React.Component<INavigationBoxProps> {
	private _background = React.createRef<HTMLDivElement>();
	private _ghostXyicons: Xyicon[] = [];
	private _ghostBoundaries: BoundarySpaceMap[] = [];
	private _ghostMarkups: Markup[] = [];

	private copyGhostItems = () => {
		const postFix = "_ghost";

		this._ghostXyicons = this.props.spaceViewRenderer.ghostModeManager.ghostXyicons.map((xyicon3D) => {
			const clonedData = ObjectUtils.deepClone(xyicon3D.modelData.data) as XyiconDto;

			clonedData.xyiconID += postFix;

			return new Xyicon(clonedData as XyiconDto, this.props.appState);
		});

		this._ghostBoundaries = this.props.spaceViewRenderer.ghostModeManager.ghostBoundaries.map((boundary) => {
			const clonedData = ObjectUtils.deepClone(boundary.modelData.data) as BoundarySpaceMapDto;

			clonedData.boundarySpaceMapID += postFix;

			return new BoundarySpaceMap(clonedData as BoundaryDto, (boundary.modelData as BoundarySpaceMap).parent);
		});

		this._ghostMarkups = this.props.spaceViewRenderer.ghostModeManager.ghostMarkups.map((markup3D) => {
			const clonedData = ObjectUtils.deepClone(markup3D.modelData.data) as MarkupDto;

			clonedData.markupID += postFix;

			return new Markup(clonedData as MarkupDto, this.props.appState);
		});
	};

	private onPointerDown = (pointer: Pointer) => {
		this.copyGhostItems();

		this.moveCameraTo(pointer.localX, pointer.localY);
	};

	private getBackgroundSize() {
		const spaceSize = this.props.spaceViewRenderer.spaceSize;
		const background = this._background.current;
		let width: number = 0;
		let height: number = 0;

		if (background) {
			if (this.getSpaceToNavigationBoxRatio(background) > 1) {
				width = background.offsetWidth;
				height = width * (spaceSize.height / spaceSize.width);
			} else {
				height = background.offsetHeight;
				width = height * (spaceSize.width / spaceSize.height);
			}
		}

		return {
			bgImageWidth: width,
			bgImageHeight: height,
		};
	}

	private onPointerMove = (pointer: Pointer) => {
		this.moveCameraTo(pointer.localX, pointer.localY);
	};

	private onPointerUp = () => {
		this.props.spaceViewRenderer.ghostModeManager.start(this._ghostXyicons, this._ghostBoundaries, this._ghostMarkups);
	};

	private moveCameraTo(x: number, y: number) {
		const background = this._background.current;
		const {bgImageWidth, bgImageHeight} = this.getBackgroundSize();
		const ratioOffset = {
			x: (background.offsetWidth - bgImageWidth) / 2,
			y: (background.offsetHeight - bgImageHeight) / 2,
		};
		const posOnImage = {
			x: MathUtils.clamp(x, ratioOffset.x, ratioOffset.x + bgImageWidth),
			y: MathUtils.clamp(y, ratioOffset.y, ratioOffset.y + bgImageHeight),
		};

		const posToNDC = {
			x: MathUtils.getInterpolation(posOnImage.x, ratioOffset.x, ratioOffset.x + bgImageWidth),
			y: 1 - MathUtils.getInterpolation(posOnImage.y, ratioOffset.y, ratioOffset.y + bgImageHeight),
		};

		const spaceOffset = this.props.spaceViewRenderer.spaceOffset;
		const NDCToWorldSpace = THREEUtils.NDCtoWorldCoordinates(
			posToNDC.x,
			posToNDC.y,
			this.props.spaceViewRenderer.spaceOffset.z,
			this.props.spaceViewRenderer.spaceSize,
		);

		this.props.spaceViewRenderer.toolManager.cameraControls.moveCameraTo(
			NDCToWorldSpace.x + spaceOffset.x,
			NDCToWorldSpace.y + spaceOffset.y,
			true,
			Constants.DURATIONS.DEFAULT_ANIMATION,
		);
	}

	private onMouseWheel = (event: React.WheelEvent) => {
		const direction = -Math.sign(event.deltaY); /** direction--> zoom-in: positive, zoom-out: negative */

		this.props.spaceViewRenderer.toolManager.cameraControls.zoomToDirection(direction);
	};

	private getSpaceToNavigationBoxRatio(background: HTMLElement) {
		const spaceSize = this.props.spaceViewRenderer.spaceSize;

		return spaceSize.width / spaceSize.height / (background.offsetWidth / background.offsetHeight);
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private onZoomLevelChange = (newValue: number) => {
		this.props.spaceViewRenderer.toolManager.cameraControls.zoom(newValue);
	};

	public override componentDidMount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.forceUpdateArrow);
		spaceViewRenderer.resizeDetector.resize.add(this.forceUpdateArrow);
		this.forceUpdate();
	}

	public override componentWillUnmount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.forceUpdateArrow);
		spaceViewRenderer.resizeDetector.resize.remove(this.forceUpdateArrow);
	}

	public override render() {
		const {spaceViewRenderer} = this.props;
		const {themeType} = spaceViewRenderer;

		const bgStyle = {
			backgroundImage: `url(${this.props.navigationBoxImgSrc})`,
			filter: themeType === "light" ? "none" : "invert(1)",
		};

		const cameraControls = spaceViewRenderer.toolManager.cameraControls;

		const {bgImageWidth, bgImageHeight} = this.getBackgroundSize();

		return (
			<div className="NavigationBox">
				<PointerDetectorReact
					onDown={this.onPointerDown}
					onMove={this.onPointerMove}
					onUp={this.onPointerUp}
					parent={null}
				>
					<div
						ref={this._background}
						className="navigationBackground"
						style={bgStyle}
						onWheel={this.onMouseWheel}
					>
						<NavigationCameraBox
							spaceViewRenderer={spaceViewRenderer}
							domWidth={Constants.SIZE.NAVIGATION_BG.WIDTH}
							domHeight={Constants.SIZE.NAVIGATION_BG.HEIGHT}
							bgWidth={bgImageWidth}
							bgHeight={bgImageHeight}
							themeType={themeType}
						/>
					</div>
				</PointerDetectorReact>
				<div className="hbox">
					<LogarithmicSlider
						width={68}
						min={cameraControls.cameraZoomMin}
						max={cameraControls.cameraZoomMax}
						value={cameraControls.cameraZoomValue}
						title="Zoom Level"
						onValueChange={this.onZoomLevelChange}
						largeStepValue={0.05}
						arrows={true}
					/>
					<NavigationControlButtons
						cameraControls={cameraControls}
						onCloseClick={this.props.onCloseClick}
					/>
				</div>
			</div>
		);
	}
}

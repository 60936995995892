import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {MathUtils} from "../../../utils/math/MathUtils";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import {ReactUtils} from "../../utils/ReactUtils";
import type {TransportLayer} from "../../../data/TransportLayer";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {AppState} from "../../../data/state/AppState";

interface ISplitterProps {
	children: React.ReactNode[];
	className?: string;
	transport?: TransportLayer;
	onChange?: (ratios: number[]) => void;
	appState?: AppState;
}

interface ISplitterSizes {
	parentElementSize: number /** Without the draggers */;
	childElementRatios: number[] /** Sum should be always 1 */;
}

@inject("transport")
@inject("appState")
@observer
export class SplitterV5 extends React.Component<ISplitterProps> {
	private _sizes: ISplitterSizes = {
		parentElementSize: null,
		childElementRatios: [],
	};
	private _splitterElement = React.createRef<HTMLDivElement>();
	private _children: React.ReactElement[];
	private _savedBodyCursor: string;
	private _dragger = React.createRef<HTMLDivElement>(); // one of the draggers. Needed to get the size of the dragger element
	private _draggerWidth: number;
	private _currentlyDraggedDragger: HTMLElement;
	private _dragIndex: number; // index of dragger that is currently dragged
	private _leftContainerOriginalWidth: number; // the width of the left container before we start dragging the dragger
	private _rightContainerOriginalWidth: number; // the width of the right container before we start dragging the dragger
	private _resizingIsActive: boolean = false;
	private _rightPanelSnappingWidths: number[] = [450, 840, 1225];
	private _snappingTolerance: number = 25;
	private _minWidth: number = 184;
	private _maxWidth: number = 800;

	private onResize = () => {
		console.log("resize");

		requestAnimationFrame(() => {
			this.onToggleSidePanel(this._sizes.childElementRatios.length - 2);
			this.forceUpdate();
		});
	};

	private calculateParentSize() {
		let parentSize = this._splitterElement.current.offsetWidth;

		if (this._children.length > 1 && this._dragger.current) {
			this._draggerWidth = this._draggerWidth || this.getWidth(this._dragger.current);
			parentSize = this.getWidth(this._splitterElement.current) - this._draggerWidth * Math.max(0, this._children.length - 1); // minus the draggers
		}

		this._sizes.parentElementSize = parentSize;
	}

	private calculateRatios() {
		if (this._splitterElement.current) {
			this.calculateParentSize();

			const childElementRatios = [];
			const children = this._splitterElement.current.children;

			if (children.length === 1) {
				childElementRatios.length = 0;
				childElementRatios.push(1);
			} else {
				for (let i = 0; i < children.length; i += 2) {
					const child = children[i] as HTMLElement;

					childElementRatios.push(this.getWidth(child) / this._sizes.parentElementSize);
				}
			}

			this._sizes.childElementRatios = childElementRatios;
		}
	}

	private getValidChildren = (props: ISplitterProps) => props.children.filter((element: React.ReactNode) => !!element);

	/**
	 *
	 * @param ratios The sum of ratios should be exactly 1
	 */
	public setRatios(ratios: number[], props: ISplitterProps = this.props, update: boolean = true) {
		const validChildren = this.getValidChildren(props);

		if (ratios.length !== validChildren.length) {
			if (validChildren.length === 3) {
				this.setDefaultRatiosFor3WaySplitter();
				return;
			}
		}

		if (ratios.length > 1) {
			this._sizes.childElementRatios = ratios;
		}

		if (update) {
			this.forceUpdate();
		}
	}

	private setDefaultRatiosFor3WaySplitter() {
		const last = this._sizes.childElementRatios[this._sizes.childElementRatios.length - 1] || 0.3;
		const first = 267 / 1912;
		const second = 1 - first - last;

		this._sizes.childElementRatios = [first, second, last];
	}

	private onRatiosChange() {
		this.props.onChange?.([...this._sizes.childElementRatios]);
	}

	private getMinWidth(width: number) {
		let minWidth = 0;

		if (width < 500) {
			minWidth = 500;
		}
		if (width < 400) {
			minWidth = 400;
		}
		if (width < 300) {
			minWidth = 300;
		}
		if (width < 200) {
			minWidth = 200;
		}

		return minWidth;
	}

	private onToggleSidePanel = (divKey: number) => {
		if (!this._resizingIsActive) {
			if (this._sizes.childElementRatios.length < 3) {
				this.calculateRatios();
			}

			const childRatios = (this._sizes.childElementRatios.length === 0 ? [0.3, 0.3, 0.3] : this._sizes.childElementRatios) ?? [0.3, 0.3, 0.3];
			const locallySavedRatios = this.props.transport.services.localStorage.getSplitterRatios() || [];
			const first = childRatios[0];
			const second = childRatios[1];
			const third = childRatios[2];
			const firstLs = locallySavedRatios[0] || 0.25;
			const secondLs = locallySavedRatios[1] || 0.25;
			const thirdLs = locallySavedRatios[2] || 0.25;

			let newRatio: number[] = [];

			if (!divKey) {
				// left dragger
				if (first === 0) {
					newRatio = [firstLs, secondLs, thirdLs];
				} else {
					newRatio = [0, first + second, third];
				}
			} else if (divKey === 2) {
				// right dragger
				if (childRatios.length === 2) {
					if (!second) {
						newRatio = [firstLs, secondLs];

						if (childRatios.length === 2 && locallySavedRatios.length === 3) {
							newRatio = [firstLs + secondLs, thirdLs];
						}
					} else {
						newRatio = [first + second, 0];
					}
				} else if (childRatios.length === 3) {
					if (!third) {
						newRatio = [firstLs, secondLs, thirdLs];
					} else {
						newRatio = [first, third + second, 0];
					}
				}
			}

			this.setRatios(newRatio, this.props, true);
		}
	};

	private createChildElements() {
		const children = this.props.children as React.ReactElement[];
		const validChildren = children.filter((element: React.ReactElement) => !!element);
		const childRatios = this._sizes.childElementRatios;
		const divs = [];

		for (let i = 0; i < children.length; ++i) {
			const container = children[i];

			if (container) {
				const validIndex = validChildren.indexOf(container);

				let style = {
					width: "",
				};

				if (validChildren.length === this._sizes.childElementRatios.length) {
					let childRatio = this._sizes.childElementRatios[validIndex];

					if (childRatio != null) {
						style.width = `${childRatio * this._sizes.parentElementSize}px`;
					}
				}

				const div =
					typeof container.type === "string" ? (
						React.cloneElement(container, {
							className: `${container.props.className} ${parseInt(style.width) < 450 ? "thin" : "wide"} width${this.getMinWidth(parseInt(style.width))}`,
							key: i * 2,
							style: style,
						})
					) : (
						<div
							className={`${parseInt(style.width) < 450 ? "thin" : "wide"}`}
							key={i * 2}
							style={style}
						>
							{container}
						</div>
					);

				divs.push(div);
			}
		}

		this._children = [];

		for (let i = 0; i < divs.length - 1; ++i) {
			const div = divs[i];
			const divKey = parseInt(div.key as string);
			const draggerKey = divKey + 1;

			this._children.push(div);
			this._children.push(
				<PointerDetectorReact
					onDown={this.onPointerDown}
					onMove={this.onPointerMove}
					onUp={this.onPointerUp}
					key={draggerKey}
				>
					<div
						className="dragger"
						ref={this._dragger}
					/>
				</PointerDetectorReact>,
			);
		}

		this._children.push(divs[divs.length - 1]);

		if (validChildren.length !== this._sizes.childElementRatios.length) {
			if (validChildren.length === 3 && this._sizes.childElementRatios.length > 0) {
				this.setDefaultRatiosFor3WaySplitter();
				this.createChildElements();
			}
		}
	}

	private getIndexOfDragger(dragger: HTMLElement) {
		const gridElements = this._splitterElement.current.children;

		for (let i = 1; i < gridElements.length; i += 2) {
			if (gridElements[i] === dragger) {
				return i;
			}
		}

		return -1;
	}

	private onPointerDown = (pointer: Pointer) => {
		this._savedBodyCursor = document.body.style.cursor;
		document.body.style.cursor = "ew-resize";

		this._currentlyDraggedDragger = pointer.currentTarget as HTMLElement;
		this._dragIndex = this.getIndexOfDragger(this._currentlyDraggedDragger);

		const containerIndex = Math.floor(this._dragIndex / 2);

		this._leftContainerOriginalWidth = this._sizes.childElementRatios[containerIndex] * this._sizes.parentElementSize;
		this._rightContainerOriginalWidth = this._sizes.childElementRatios[containerIndex + 1] * this._sizes.parentElementSize;
	};

	private onPointerMove = (pointer: Pointer) => {
		this._resizingIsActive = true;

		if (this._currentlyDraggedDragger) {
			const containerIndex = Math.floor(this._dragIndex / 2);
			const offsetX = MathUtils.clamp(pointer.offsetX, -this._leftContainerOriginalWidth, this._rightContainerOriginalWidth);
			const snappingPoint = this.getASnappingPoint(pointer.pageX);

			let newLeftContainerWidth = this._leftContainerOriginalWidth + offsetX;
			let newRightContainerWidth = this._rightContainerOriginalWidth - offsetX;

			if (snappingPoint && this._children.length === 3 && !KeyboardListener.isAltDown) {
				newLeftContainerWidth = newLeftContainerWidth + newRightContainerWidth - snappingPoint;
				newRightContainerWidth = snappingPoint;
			}

			// if (newLeftContainerWidth >= this._minWidth && newLeftContainerWidth <= this._maxWidth)
			// {
			this._sizes.childElementRatios[containerIndex] = newLeftContainerWidth / this._sizes.parentElementSize;
			this._sizes.childElementRatios[containerIndex + 1] = newRightContainerWidth / this._sizes.parentElementSize;

			this.forceUpdate();
			//}
		}
	};

	private onPointerUp = (pointer: Pointer) => {
		if (this._resizingIsActive) {
			this.onRatiosChange();
		}

		document.body.style.cursor = this._savedBodyCursor;
		this._dragIndex = null;
		this._currentlyDraggedDragger = null;
		this._resizingIsActive = false;
	};

	private getWidth(element: HTMLElement) {
		return element.getBoundingClientRect().width;
	}

	private getASnappingPoint(x: number): number {
		for (let i = 0; i < this._rightPanelSnappingWidths.length; i++) {
			const sPoint = this._rightPanelSnappingWidths[i];
			const xDistanceFromRight = window.innerWidth - x;

			if (xDistanceFromRight >= sPoint - this._snappingTolerance && xDistanceFromRight <= sPoint + this._snappingTolerance) {
				return this._rightPanelSnappingWidths[i];
			}
		}

		return null;
	}

	public get ratios() {
		return [...this._sizes.childElementRatios];
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: ISplitterProps) {
		const numberOfCurrentValidChildren = this.getValidChildren(this.props).length;
		const numberOfNextValidChildren = this.getValidChildren(nextProps).length;

		if (numberOfCurrentValidChildren !== numberOfNextValidChildren) {
			this.setRatios([], nextProps, false);
			this.onRatiosChange();
		}
	}

	public override componentDidUpdate() {
		this.calculateRatios();
	}

	public override componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.calculateRatios();
	}

	public override componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	public override render() {
		this.createChildElements();

		const {className} = this.props;

		return (
			<SplitterStyled
				ref={this._splitterElement}
				className={ReactUtils.cls("Splitter", {[className]: !!className})}
			>
				{this._children}
			</SplitterStyled>
		);
	}
}

const SplitterStyled = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: row;
	/* To allow table scroll & disable horizontal scroll */
	overflow: hidden;
	transition: ease-in-out filter 0.2s;

	> * {
		background: var(--bg1);
		/* Without this, that could be a horizontal scroll if SidePanel has a ColorPicker or an opened ClickToEditInput
		 TODO: check if that's really the case?
		*/
		overflow-x: hidden;
	}

	> div {
		&:first-of-type {
			width: 440px;
		}
		&:last-of-type {
			width: calc(100% - 440px);
		}
	}

	//when create panel is active
	&.blurred {
		//filter: blur(2px);
		pointer-events: none;
		filter: brightness(0.7);
		transition: ease-in-out filter 0.2s;
	}

	.dragger {
		cursor: col-resize;
		background: var(--bg4);
		z-index: 2;
		width: 2px;
		overflow: visible;

		&::after,
		&::before {
			content: "";
			width: 10px;
			height: 100%;
			position: absolute;
			top: 0;
		}

		&::before {
			transform: translateX(-10px);
			background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%);
		}

		&::after {
			background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%);
			transform: translateX(2px);
		}

		&:hover {
			background: var(--blue);
			transition: ease color 0.2s;
		}
	}

	.right {
		right: 0;
	}

	.thin {
		.overlayPanel,
		* {
			.topButtons {
				.Button,
				.MultiActionButton {
					> svg.icon {
						display: block;
					}
				}

				.Button {
					padding: 7px;
					margin: 0;

					> svg.icon {
						width: 16px;
						height: 16px;
						margin: 0 4px;
					}

					&.MultiActionButton {
						padding: 0 0 0 7px;

						> svg.icon {
							margin-right: base.$sm;
						}
					}

					&:not(:last-child) {
						margin-right: 8px;
					}
				}
			}

			.GridTab {
				.Button {
					padding: base.$sm;
				}
			}
		}

		.FilterEditor {
			.heading {
				flex-direction: column-reverse;
				align-items: flex-end;

				.SearchField {
					max-width: 100%;
				}

				& > *:first-child {
					margin-top: base.$md;
				}
			}
		}

		.GridTab {
			.header {
				.MultiActionButton {
					> .label {
						display: none;
					}

					> svg.icon {
						display: block;
					}
				}
			}
		}

		.Dockable {
			&.docked {
				.secondaryHeader {
					.SelectInput {
						max-width: none;
						width: calc(100% - 40px);
						margin-bottom: base.$sm;
					}

					.SearchField {
						width: 100%;
						margin-right: 0;
					}
				}
			}
		}
	}

	// This isn't so nice, need to find some better solution
	.width500 {
		.TabView.tab4 {
			.tabLabel {
				display: none;
			}
		}
	}

	.width400 {
		.TabView.tab4,
		.TabView.tab3 {
			.tabLabel {
				display: none;
			}
		}
	}

	.width300 {
		.TabView.tab4,
		.TabView.tab3,
		.TabView.tab2 {
			.tabLabel {
				display: none;
			}
		}
	}

	.width200 {
		.TabView.tab4,
		.TabView.tab3,
		.TabView.tab2,
		.TabView.tab1 {
			.tabLabel {
				display: none;
			}
		}
	}

	.mainpanel {
		container-type: inline-size;
	}

	> .thin {
		.SidePanel {
			.DetailsTab {
				.header {
					.Initials {
						display: none;
					}

					.icons .Initials {
						display: flex;
					}

					.Field {
						width: 100%;
					}
				}
			}
		}

		.FilterEditor {
			.AdvancedFilterEditor {
				.AdvancedFilterField {
					margin-bottom: base.$md;

					.criterias {
						grid-template-columns: 1fr;
						grid-gap: base.$xs;

						.separator:empty {
							display: none;
						}
					}
				}
			}
		}
	}
`;

import type {InstancedBufferGeometry, Object3D} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {ModelBasedXyiconMaterial} from "../../materials/ModelBasedXyiconMaterial";
import {Xyicon3D} from "../../elements3d/Xyicon3D";
import type {IXyiconMinimumData, Xyicon} from "../../../../../../../data/models/Xyicon";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import type {Catalog} from "../../../../../../../data/models/Catalog";
import {InstancedXyiconManager} from "./InstancedXyiconManager";
import {GeometryManager} from "./GeometryManager";
import type {XyiconManager} from "./XyiconManager";

export class ModelBasedXyiconManager extends InstancedXyiconManager {
	private _geometryManager: GeometryManager;

	constructor(spaceViewRenderer: SpaceViewRenderer, xyiconManager: XyiconManager, container: Object3D) {
		super(spaceViewRenderer, xyiconManager, container);
		this._geometryManager = new GeometryManager(this._spaceViewRenderer);
	}

	protected async createGeometryAndMaterial(geometryId: string, forceGeometryReload: boolean = false) {
		return {
			geometry: await this._geometryManager.getGeometry(geometryId, forceGeometryReload),
			material: new ModelBasedXyiconMaterial(),
		};
	}

	private getHexAndOpacityFromCatalogId(catalogId: string) {
		const catalog = this._spaceViewRenderer.actions.getFeatureItemById(catalogId, XyiconFeature.XyiconCatalog) as Catalog;
		const color = catalog.iconData.modelParameters.bodyColor;

		return {
			hex: parseInt(`0x${color.hex}`),
			opacity: 1 - color.transparency,
		};
	}

	public deleteGeometryFromCache(catalogId: string) {
		this._geometryManager.deleteGoemetryFromCache(catalogId);
	}

	public clearCache() {
		this._geometryManager.clearCache();
	}

	public async init(modelBasedXyicons: Xyicon[]) {
		await this._geometryManager.init(modelBasedXyicons);

		const catalogIds = [];
		const xyicon3DObjects: Xyicon3D[] = [];

		for (const xyicon of modelBasedXyicons) {
			let indexOfAssociatedInstancedMesh = this.getInstancedMeshIndexByName(xyicon.catalogId);

			if (indexOfAssociatedInstancedMesh === -1) {
				catalogIds.push(xyicon.catalogId);

				await this.createInstancedMesh(modelBasedXyicons.length * 2, xyicon.catalogId);
				indexOfAssociatedInstancedMesh = this.instancedMeshes.length - 1;
			}
			const {hex, opacity} = this.getHexAndOpacityFromCatalogId(xyicon.catalogId);

			xyicon3DObjects.push(
				new Xyicon3D(
					this._spaceViewRenderer,
					indexOfAssociatedInstancedMesh,
					this.instancedMeshes[indexOfAssociatedInstancedMesh].count++,
					xyicon,
					hex,
					opacity,
				),
			);
		}

		this._xyiconManager.add(xyicon3DObjects, false);
	}

	public async createNecessaryDataFromXyiconModel(model: IXyiconMinimumData) {
		let instancedMeshId = this.getInstancedMeshIndexByName(model.catalogId);

		if (instancedMeshId === -1) {
			const newMaxCount = 1;

			await this.createInstancedMesh(newMaxCount, model.catalogId);
			instancedMeshId = this.instancedMeshes.length - 1;
		}

		let instancedMesh = this.instancedMeshes[instancedMeshId];
		const previousCount = instancedMesh.count++;
		const previousMaxCount = (instancedMesh.geometry as InstancedBufferGeometry).instanceCount;

		if (previousCount >= previousMaxCount) {
			instancedMesh = THREEUtils.cloneInstancedMeshWithLargerMaxCount(instancedMesh);
			this.instancedMeshes[instancedMeshId] = instancedMesh;
		}

		const instanceId = previousCount;

		this.initAttributesOfNewInstance(instancedMesh, instanceId);

		const {hex, opacity} = this.getHexAndOpacityFromCatalogId(model.catalogId);

		return {
			color: hex,
			opacity: opacity,
			instancedMeshId: instancedMeshId,
			instanceId: instanceId,
		};
	}
}

import type {Texture, WebGLProgramParametersWithUniforms} from "three";
import {RawShaderMaterial} from "three";
import {ColorUtils} from "../../../../../../utils/ColorUtils";

interface ITileMaterialParameters {
	map: Texture;
	cropUV: number[];
	transparent: boolean;
	inverted: boolean;
	useGradientBackground: boolean;
	customLineColor: number;
}

//
// Based on THREE.MeshBasicMaterial.
// Differences:
// Simplified: no envmap, aomap, lightmap, fog, skinning, uv2, etc, because we don't need them
// Added `inverted` uniform (and ability to invert the colors)
//
export class TileMaterial extends RawShaderMaterial {
	// uniforms
	private _map: {
		type: "t";
		value: Texture;
	};

	private _inverted: {
		type: "b";
		value: boolean;
	};

	private _useCustomColor: {
		type: "b";
		value: boolean;
	};

	private _useGradientBackground: {
		type: "b";
		value: boolean;
	};

	private _lineColor: {
		type: "3fv";
		value: number[];
	};

	private _cropUV: {
		type: "2fv";
		value: number[];
	};

	private readonly _vertexShader: string = `precision highp float;

attribute vec3 position;

uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

varying vec2 vUv;

void main()
{
	vUv = vec2(position.xy + 0.5) / 1.0;
	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;

	private readonly _fragmentShader: string = `precision highp float;

varying vec2 vUv;

uniform bool inverted;
uniform bool useCustomColor;
uniform bool useGradientBackground;
uniform vec3 lineColor;
uniform sampler2D map;
uniform vec2 cropUV; // crop if uv is larger than this (think about the right, or top edge of a space)

void main()
{
	if (vUv.x > cropUV.x || vUv.y > cropUV.y)
	{
		discard;
	}

	vec4 outputColor = texture2D(map, vUv);

	if (inverted || useCustomColor)
	{
		outputColor.r = 1.0 - outputColor.r;
		outputColor.g = 1.0 - outputColor.g;
		outputColor.b = 1.0 - outputColor.b;
	}

	if (useCustomColor)
	{
		outputColor = vec4(outputColor.rgb * lineColor, outputColor.a);
	}

	if (useGradientBackground)
	{
		outputColor *= vec4(0.0, 1.0 - vUv.xy, 1.0);
	}

	gl_FragColor = outputColor;
}`;

	constructor(parameters: ITileMaterialParameters) {
		super({depthTest: false, transparent: parameters.transparent});

		this._map = {
			type: "t",
			value: parameters.map,
		};

		this._cropUV = {
			type: "2fv",
			value: parameters.cropUV,
		};

		this._inverted = {
			type: "b",
			value: parameters.inverted,
		};

		this._useCustomColor = {
			type: "b",
			value: parameters.customLineColor != null,
		};

		this._useGradientBackground = {
			type: "b",
			value: parameters.useGradientBackground,
		};

		this._lineColor = {
			type: "3fv",
			value: ColorUtils.hex2Array(parameters.customLineColor != null ? parameters.customLineColor : 0x000000).slice(0, 3),
		};

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;
			program.uniforms = {
				map: this._map,
				inverted: this._inverted,
				cropUV: this._cropUV,
				useCustomColor: this._useCustomColor,
				useGradientBackground: this._useGradientBackground,
				lineColor: this._lineColor,
			};
		};
	}

	public setInvert(value: boolean) {
		this._inverted.value = value;
	}

	public get inverted() {
		return this._inverted.value;
	}

	public setGradientBackground(value: boolean) {
		this._useGradientBackground.value = value;
	}

	// So it can be disposed, when the scene is disposed
	public get map() {
		return this._map.value;
	}
}

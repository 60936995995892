import {observer} from "mobx-react";
import * as React from "react";
import type {ISizeRange} from "../../logic3d/Constants";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import {MarkupUtils} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {SpaceItem} from "../../logic3d/elements3d/SpaceItem";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {SizeChanger} from "../../../../abstract/common/sizechanger/SizeChanger";
import type {Markup} from "../../../../../../data/models/Markup";
import {MarkupsWithChangeableLineThickness} from "../../logic3d/elements3d/markups/MarkupStaticElements";

interface ILineThicknessChanger {
	spaceViewRenderer: SpaceViewRenderer;
	items: SpaceItem[];
}

@observer
export class LineThicknessChanger extends React.Component<ILineThicknessChanger> {
	private _range: ISizeRange = {
		values: [1, 2, 4, 8, 10, 20, 50],
		default: MarkupUtils.defaultLineThickness,
		min: 1,
		max: 50,
	};

	private onChange = (newValue: number) => {
		const {items, spaceViewRenderer} = this.props;

		const markupsToUpdate: Markup3D[] = [];

		const markup3Ds = items as Markup3D[];

		for (const markup3D of markup3Ds) {
			const markup = markup3D.modelData as Markup;
			const hasChanged = markup.lineThickness !== newValue;

			if (hasChanged) {
				markup.setLineThickness(newValue);
				markupsToUpdate.push(markup3D);
			}
		}

		for (const markup3D of markupsToUpdate) {
			markup3D.updateByModel(markup3D.modelData as Markup);
		}

		spaceViewRenderer.markupManager.updateItems(markupsToUpdate, true);
	};

	public override render() {
		const {items} = this.props;
		const areOnlyLineTypesSelected =
			items.length > 0 &&
			items.every((item) => item.spaceItemType === "markup" && MarkupsWithChangeableLineThickness.includes((item as Markup3D).type));

		return (
			areOnlyLineTypesSelected && (
				<div
					className="hbox"
					title="Change line width"
				>
					<SVGIcon
						icon="lineWidth"
						style={{width: "40px"}}
					/>
					<SizeChanger
						value={(items[0].modelData as Markup).lineThickness}
						onChange={this.onChange}
						range={this._range}
					/>
				</div>
			)
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import {ReactUtils} from "../../../utils/ReactUtils";

interface ITabChildProps {
	id?: string;
	label?: string;
	title?: string;
	icon?: string;
	active?: boolean;
	disabled?: boolean;
	children: React.ReactNode;
	className?: string;
}

export const TabChildV5 = (props: ITabChildProps) => {
	const {label = "", title = "", active = false, disabled = false, children = <></>} = props;

	return <TabChildStyled className={ReactUtils.cls(`${props.className ?? ""}`, {"DetailsTab active": active, disabled})}>{children}</TabChildStyled>;
};

const TabChildStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

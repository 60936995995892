import * as React from "react";
import {inject, observer} from "mobx-react";
import {ToggleContainer} from "../../../../../../widgets/container/ToggleContainer";
import type {Report} from "../../../../../../../data/models/Report";
import type {ReportSharingSettingsData} from "../../../../../../../generated/api/reports";
import type {AppState} from "../../../../../../../data/state/AppState";
import {AddUserOrUserGroup} from "../../../../../../widgets/user/AddUserOrUserGroup";
import {ShareOption} from "../../../../view/sharing/ShareOption";
import {Permission, XyiconFeature} from "../../../../../../../generated/api/base";
import type {User} from "../../../../../../../data/models/User";
import type {IModel} from "../../../../../../../data/models/Model";
import type {UserGroup} from "../../../../../../../data/models/UserGroup";
import {IconButton} from "../../../../../../widgets/button/IconButton";
import {Initials} from "../../../../../../widgets/Initials";
import {ReactUtils} from "../../../../../../utils/ReactUtils";
import {StringUtils} from "../../../../../../../utils/data/string/StringUtils";
import {SVGIcon} from "../../../../../../widgets/button/SVGIcon";

interface IReportSharingSectionProps {
	report: Report;
	appState?: AppState;
}

interface IReportSharingSectionState {
	search: string;
	newPermission: Permission;
	newShareWith: IModel;
}

/**
 * Note: this component is very similar to EditViewSharing
 */
@inject("appState")
@observer
export class ReportSharingSection extends React.Component<IReportSharingSectionProps, IReportSharingSectionState> {
	constructor(props: IReportSharingSectionProps) {
		super(props);
		this.state = {
			search: "",
			newPermission: Permission.View,
			newShareWith: null,
		};
	}

	private onSearch = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private onAddClick = (id: string, feature: XyiconFeature.User | XyiconFeature.UserGroup) => {
		const {report, appState} = this.props;
		const item = appState.actions.getFeatureItemById<User | UserGroup>(id, feature);
		const sharingSettings = report.sharingSettings || [];

		if (feature === XyiconFeature.User) {
			sharingSettings.push({
				userID: item.id,
				userGroupID: null,
				canEditSharedReport: this.state.newPermission === Permission.Update,
			});

			appState.actions.updateReport(report);
		} else if (feature === XyiconFeature.UserGroup) {
			sharingSettings.push({
				userID: null,
				userGroupID: item.id,
				canEditSharedReport: this.state.newPermission === Permission.Update,
			});

			appState.actions.updateReport(report);
		}

		this.setState({
			newShareWith: null,
			search: "",
		});
	};

	private onRemoveUserGroup = (userGroup: UserGroup) => {
		const {report, appState} = this.props;

		report.removeUserGroupFromSharing(userGroup.id);
		appState.actions.updateReport(report);
	};

	private onRemoveUser = (user: User) => {
		const {report, appState} = this.props;

		report.removeUserFromSharing(user.id);
		appState.actions.updateReport(report);
	};

	private onChangeSharingPermission = (sharing: ReportSharingSettingsData, permission: Permission) => {
		const {report, appState} = this.props;

		sharing.canEditSharedReport = permission === Permission.Update;
		appState.actions.updateReport(report);
	};

	public override render() {
		const {report, appState} = this.props;
		const {search, newShareWith} = this.state;

		const users = appState.lists[XyiconFeature.User];
		const userGroups = appState.lists[XyiconFeature.UserGroup];

		const reportSharing = report.sharingSettings || [];
		const userGroupSharingList = reportSharing.filter((sh) => sh.userGroupID);
		const userSharingList = reportSharing.filter((sh) => sh.userID);

		const exceptionIds: string[] = [...userGroupSharingList.map((sh) => sh.userGroupID), ...userSharingList.map((sh) => sh.userID)];

		const reportOwner = appState.lists[XyiconFeature.User].getById(report.ownerUserID);

		let allowNewEdit = true;

		if (newShareWith?.ownFeature === XyiconFeature.User && !(newShareWith as User).isAdmin) {
			allowNewEdit = false;
		}

		return (
			<ToggleContainer
				title="Sharing"
				open={true}
				className="ReportSharingSection"
			>
				<AddUserOrUserGroup
					search={search}
					onSearch={this.onSearch}
					onAdd={this.onAddClick}
					exceptions={exceptionIds}
					report={this.props.report}
				/>
				<div className="section">
					<h3>Owner</h3>
					<div className="list">
						<div className="item hbox owner">
							<div className="avatar">
								{reportOwner?.profileFileName ? (
									<img
										src={reportOwner.profileFileName}
										alt={`${reportOwner?.fullName} profile image`}
									/>
								) : (
									<Initials name={reportOwner?.fullName || reportOwner?.email} />
								)}
							</div>
							<div className="vbox flex_1">
								<div className="name">{`${reportOwner?.fullName}${reportOwner === appState.user ? " (Me)" : ""}`}</div>
								<div className="email">{reportOwner?.email}</div>
							</div>
							<SVGIcon
								icon="crown"
								classNames="owner-icon"
							/>
						</div>
					</div>
				</div>
				<div className="section">
					<h3>User Groups</h3>
					{userGroupSharingList.find((sharing) => userGroups.getById(sharing.userGroupID)) ? (
						userGroupSharingList
							.sort((a: ReportSharingSettingsData, b: ReportSharingSettingsData) =>
								StringUtils.sortIgnoreCase(userGroups.getById(a.userGroupID).name, userGroups.getById(b.userGroupID).name),
							)
							.map((sharing, index) => {
								const userGroup = userGroups.getById(sharing.userGroupID);

								if (!userGroup) {
									return null;
								}
								return (
									<div
										key={index}
										className="item hbox"
									>
										<div className="avatar">
											<Initials name={userGroup.name} />
										</div>
										<div className="vbox flex_1">
											<div className="name">{userGroup.name}</div>
											<div className="counter">{userGroup.renderMemberCount()}</div>
										</div>
										<ShareOption
											value={sharing.canEditSharedReport ? Permission.Update : Permission.View}
											onChange={(permission) => {
												this.onChangeSharingPermission(sharing, permission);
											}}
											hasOwner={false}
										/>
										<IconButton
											icon="delete"
											onClick={() => {
												this.onRemoveUserGroup(userGroup);
											}}
										/>
									</div>
								);
							})
					) : (
						<div className="empty">
							<div>Report not shared with a user group</div>
						</div>
					)}
				</div>
				<div className="section">
					<h3>Users</h3>
					{userSharingList.find((sharing) => users.getById(sharing.userID)) ? (
						userSharingList
							.sort((a: ReportSharingSettingsData, b: ReportSharingSettingsData) =>
								StringUtils.sortIgnoreCase(users.getById(a.userID)?.fullName, users.getById(b.userID)?.fullName),
							)
							.map((sharing, index) => {
								const user = users.getById(sharing.userID);

								if (!user) {
									return null;
								}
								return (
									<div
										key={index}
										className={ReactUtils.cls("item hbox", {unregistered: (users.getById(sharing.userID) as User).status === "invited"})}
									>
										<div className="avatar">
											{user.profileFileName ? (
												<img
													src={user.profileFileName}
													alt={`${user.fullName} profile image`}
												/>
											) : (
												<Initials name={user.fullName || user.email} />
											)}
										</div>
										<div className="vbox flex_1">
											<div className="name">{user.fullName}</div>
											<div className="email">{user.email}</div>
										</div>
										<ShareOption
											value={sharing.canEditSharedReport ? Permission.Update : Permission.View}
											onChange={(permission) => {
												this.onChangeSharingPermission(sharing, permission);
											}}
											hasOwner={false}
											hasEdit={user.isAdmin}
										/>
										<IconButton
											icon="delete"
											onClick={() => {
												this.onRemoveUser(user);
											}}
										/>
									</div>
								);
							})
					) : (
						<div className="empty">
							<div>Report not shared with a user</div>
						</div>
					)}
				</div>
			</ToggleContainer>
		);
	}
}

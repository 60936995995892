import {inject, observer} from "mobx-react";
import * as React from "react";
import {XyiconFeature} from "../../../../generated/api/base";
import type {IModel} from "../../../../data/models/Model";
import type {User} from "../../../../data/models/User";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {Button} from "../../../widgets/button/Button";
import {IconButton} from "../../../widgets/button/IconButton";
import {Initials} from "../../../widgets/Initials";
import {AddUserOrUserGroup} from "../../../widgets/user/AddUserOrUserGroup";

interface IDeleteUserAndTransfrOwnerShipPopupProps<T> {
	selectedIds: string[];
	appState?: AppState;
	transport?: TransportLayer;
	deleteItems: (items: T[]) => Promise<void>;
	closeUserDeletePopup: () => void;
}

interface IDeleteUserAndTransfrOwnerShipPopupState {
	userSearch: string;
	newOwnerUser: User;
	listMaxHeigt: number;
}

@inject("appState")
@inject("transport")
@observer
export class DeleteUserAndTransfrOwnerShipPopup<T extends IModel> extends React.Component<
	IDeleteUserAndTransfrOwnerShipPopupProps<T>,
	IDeleteUserAndTransfrOwnerShipPopupState
> {
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IDeleteUserAndTransfrOwnerShipPopupProps<T>) {
		super(props);
		this.state = {
			userSearch: "",
			newOwnerUser: null,
			listMaxHeigt: 0,
		};
	}

	private onSearchUser = (value: string) => {
		this.setState({
			userSearch: value,
		});
	};

	private resetNewUser = () => {
		this.setState({newOwnerUser: null});
	};

	private transferAndDelete = async () => {
		const {selectedIds} = this.props;
		const users = selectedIds.map((id) => this.props.appState.actions.getFeatureItemById<User>(id, XyiconFeature.User));
		const {newOwnerUser} = this.state;
		const {result, error} = await this.props.appState.actions.deleteUsers(users, newOwnerUser);

		if (error) {
			console.warn(error);
		}
	};

	private onTransferAndDelete = async () => {
		await this.transferAndDelete();
		this.closeUserDeletePopup();
	};

	private closeUserDeletePopup = () => {
		this.props.closeUserDeletePopup();
	};

	private onDeleteAllUser = async () => {
		await this.props.deleteItems(this.props.selectedIds.map((id) => this.props.appState.actions.getFeatureItemById<T>(id, XyiconFeature.User)));
		this.closeUserDeletePopup();
	};

	private onAddUser = (id: string) => {
		const user: User = this.props.appState.actions.findUser(id);

		if (user) {
			this.setState({newOwnerUser: user});
		}
	};

	public override componentDidMount(): void {
		if (this._container.current) {
			this.setState({listMaxHeigt: window.innerHeight - this._container.current.getBoundingClientRect().bottom + 50});
		}
	}

	public override render() {
		const {selectedIds, closeUserDeletePopup} = this.props;
		const {newOwnerUser, userSearch, listMaxHeigt} = this.state;

		return (
			<div className="DeleteUserAndTransfrOwnerShipPopup popupBackdrop hbox alignCenter">
				<div
					className="PopupWindow"
					ref={this._container}
				>
					<div className="title hbox alignCenter justifySpaceBetween">
						{`Delete ${selectedIds.length} users from Organization`}
						<IconButton
							icon="close"
							onClick={closeUserDeletePopup}
						/>
					</div>
					<div className="message">
						<p>
							The selected users own views and reports which are shared. To delete the users along with their shared views and reports click Delete
							All.
						</p>
						<p>
							<b>To transfer view and report ownership to another user, add the user below and click Transfer and Delete.</b>
						</p>
					</div>
					<AddUserOrUserGroup
						onAdd={this.onAddUser}
						search={userSearch}
						onSearch={this.onSearchUser}
						usersOnly={true}
						noFixedPosition={true}
						addButtonText="Add"
						searchTextPlaceholder="Search users to transfer ownership..."
						listMaxHeight={listMaxHeigt}
					/>
					{newOwnerUser && (
						<div className="hbox selectedUser alignCenter">
							<div className="avatar">
								{newOwnerUser.profileFileName ? (
									<img
										src={newOwnerUser.profileFileName}
										alt={`${newOwnerUser.fullName} profile image`}
									/>
								) : (
									<Initials name={newOwnerUser.fullName || newOwnerUser.email} />
								)}
							</div>
							<div className="vbox">
								<div className="name">{newOwnerUser.fullName}</div>
								<div className="email">{newOwnerUser.email}</div>
							</div>
							<div className="flex_1" />
							<IconButton
								icon="close"
								onClick={this.resetNewUser}
							/>
						</div>
					)}
					<div className="bottom hbox justifyEnd">
						<Button
							className="secondary"
							title="Cancel"
							label="Cancel"
							onClick={closeUserDeletePopup}
						/>
						<Button
							className="secondary"
							title="Delete All"
							label="Delete All"
							onClick={this.onDeleteAllUser}
						/>
						<Button
							className="primary"
							title="Transfer and Delete"
							label="Transfer and Delete"
							onClick={this.onTransferAndDelete}
							disabled={!newOwnerUser}
						/>
					</div>
				</div>
			</div>
		);
	}
}

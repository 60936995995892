import {type FunctionComponent, type SVGProps, type CSSProperties, type RefObject, useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {colorPalette} from "../styles/colorPalette";
import {radius, zIndex} from "../styles/styles";
import type {TTitle, TTitleAlignment} from "../modules/spaceeditor/toolbar/ToolButtonV5";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {useAppStore} from "../../../StateManager";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment, HorizontalAlignment} from "../../../utils/dom/DomUtils";

interface IIconButtonProps {
	readonly IconComponent: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly className?: string;
	readonly title?: string;
	readonly titleAlignment?: TTitleAlignment;
	readonly label?: string;
	readonly onClick: (e: React.MouseEvent) => void;
	readonly isActive?: boolean;
	readonly style?: CSSProperties;
	readonly disabled?: boolean;
	readonly divRef?: RefObject<HTMLDivElement>;
}

export const IconButtonV5 = ({
	title = "",
	IconComponent,
	onClick,
	isActive,
	style,
	disabled = false,
	label = "",
	className = "",
	titleAlignment = "right",
}: IIconButtonProps) => {
	const appState = useAppStore((state) => state.appState);
	const [transform, setTransform] = useState<TransformObj>(null);
	const _parentElement = useRef<HTMLDivElement>(null);
	const _floatingElement = useRef<HTMLDivElement>(null);
	const prevIsHovered = useRef(null);
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const element = _parentElement.current;
	const inlineStyle: React.CSSProperties = element && {
		...style,
		zIndex: zIndex.popup,
		transform: transform?.translate,
		position: "fixed",
		left: "auto",
		top: "-30px",
	};

	useEffect(() => {
		if (!prevIsHovered.current && setIsHovered && element && _floatingElement.current) {
			const transformValue = DomUtils.getFixedFloatingElementPosition(
				element,
				_floatingElement.current,
				VerticalAlignment.top,
				HorizontalAlignment.center,
				10,
				0,
			);

			setTransform(transformValue);
		}

		prevIsHovered.current = isHovered;
	}, [isHovered, element]);

	return (
		<IconButtonStyled
			$title={title}
			$titleAlignment={titleAlignment}
			onClick={onClick}
			className={ReactUtils.cls(`IconButton ${className}`, {isActive, disabled})}
			$label={label}
			style={style}
			ref={_parentElement}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<IconComponent />
			{label && <span>{label}</span>}
			{title && isHovered && (
				<DomPortal destination={appState.app.modalContainer}>
					<IconTooltipStyled
						$titleAlignment={titleAlignment}
						ref={_floatingElement}
						style={inlineStyle}
					>
						{title}
					</IconTooltipStyled>
				</DomPortal>
			)}
		</IconButtonStyled>
	);
};

export const IconButtonStyled = styled.div<TTitle & {$label: string}>`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: ${(props) => (props.$label ? "initial" : "32px")};
	height: 32px;
	gap: 8px;
	border-radius: ${radius.sm};
	position: relative;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.isActive {
		background: ${colorPalette.primary.c500Primary};
		color: ${colorPalette.white};
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`;

const IconTooltipStyled = styled.div<{$titleAlignment: string}>`
	background-color: ${colorPalette.gray.c950};
	border-radius: 4px;
	position: absolute;
	color: ${colorPalette.white};
	display: block;
	font-size: 12px;
	padding: 4px;
	width: max-content;
	z-index: 999;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

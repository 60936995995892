import React, {useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {AppUtils} from "../../../../../utils/AppUtils";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import {CheckboxInputV5} from "../CheckboxInputV5";
import {useAppStore} from "../../../../../StateManager";
import {OptionStyled} from "../../../input/select/SelectInputV5";
import {ELLIPSIS, fontSize, radius, zIndex} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {useClickOutside} from "../../../utils";
import {SearchFieldStyled} from "../../../input/search/SearchField.styled";
import {MoreButtonStyled} from "./MultiLineLabelV5";

interface IMultiSelectInputProps<T> {
	options: T[];
	selected?: T[];
	disabled?: boolean;
	searchBar?: boolean;
	errorMessage?: string;
	noFixedPosition?: boolean;
	onFocusLossForceBlur?: boolean;
	focused?: boolean;
	inline?: boolean;
	expand?: boolean;
	isSameWidth?: boolean;

	render?: (item: T) => React.ReactNode;
	onChange?: (selectedOptions: T[]) => void;
	onClick?: (e: React.MouseEvent) => void;
}

enum MultiSelectInputState {
	Default = 1,
	Expanded = 2,
	Editing = 3,
}

export const MultiSelectInputStyled = styled.div<{$emptyInline?: boolean}>`
	border-radius: ${radius.sm};
	border: 1px solid ${colorPalette.gray.c300};
	padding: 8px;

	${(props) => {
		if (props.$emptyInline) {
			return css`
				max-height: 29px;
			`;
		}
	}}

	&.empty {
		height: 32px;
		cursor: pointer;

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
	}
`;

const MultiSelectOptionStyled = styled(OptionStyled)`
	height: 16px;
	justify-content: space-between;
	font-size: ${fontSize.md};
	/* color: ${colorPalette.gray.c700Dark}; */

	${IconButtonStyled} {
		visibility: hidden;
		width: 16px;
		height: 16px;

		/* svg {
			color: ${colorPalette.gray.c700Dark};
		} */
	}

	&:hover {
		${IconButtonStyled} {
			visibility: visible;
		}
	}

	.label {
		${ELLIPSIS};
	}
`;

const DropdownStyled = styled.div<{$noFixedPosition: boolean; $translate: string}>`
	z-index: ${zIndex.dropdowns};
	background-color: ${colorPalette.white};
	max-height: 288px;
	transform: ${(props) => (props.$noFixedPosition ? "" : props.$translate)};
	position: ${(props) => (props.$noFixedPosition ? "absolute" : "fixed")};
	box-shadow: 0px 4px 8px 0px #00000033;
	padding: 8px 8px 4px 8px;
	border-radius: ${radius.md};
	display: flex;
	flex-direction: column;
	gap: 8px;
	/* inlineStyle.top = transform?.vertical === VerticalAlignment.top ? 42 : -42; */

	${SearchFieldStyled} {
		width: 100%;

		input {
			height: 32px;
		}
	}
`;

const ItemsLabelStyled = styled.div`
	font-size: 12px;
	height: 16px;
	letter-spacing: 0.0025em;
	color: ${colorPalette.gray.c500Primary};
`;

const ListStyled = styled.div`
	overflow: auto;
`;

const ListItemStyled = styled.div`
	display: flex;
	gap: 8px;
	height: 32px;
	align-items: center;
	cursor: pointer;
	border-radius: ${radius.sm};
	${ELLIPSIS};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.selected {
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
	}
`;

MultiSelectInputV5.defaultProps = {
	options: [],
	disabled: false,
	render: (option: any) => option,
	searchBar: true,
	isSameWidth: true,
};

export function MultiSelectInputV5<T = any>({
	options = [],
	disabled = false,
	render = (option: any) => option,
	searchBar = true,
	isSameWidth = true,
	selected,
	errorMessage,
	noFixedPosition,
	onFocusLossForceBlur,
	focused,
	inline,
	expand,
	onChange,
	onClick,
}: IMultiSelectInputProps<T>) {
	let _element = useRef<HTMLDivElement>();
	let _list = useRef<HTMLDivElement>();
	let _checkboxAll = useRef<HTMLInputElement>();

	const appState = useAppStore((store) => store.appState);

	const [search, setSearch] = useState<string>("");
	const [multiSelectState, setMultiSelectState] = useState<MultiSelectInputState>(MultiSelectInputState.Default);
	const [transform, setTransform] = useState<TransformObj>(null);

	const onToggleValue = (value: T, event?: React.MouseEvent) => {
		// if T is an object selected does not includes value
		const valueToRemove = selected.find((o) => value === o);

		event?.stopPropagation();

		if (valueToRemove) {
			const newSelected = ArrayUtils.remove(selected, valueToRemove);

			dispatchChange(newSelected);
		} else {
			const newSelected = ArrayUtils.add(selected, value);

			dispatchChange(newSelected);
		}
	};

	const onToggleOptionAll = (isChecked: boolean) => {
		const filteredOptions = options.filter(filterOption);
		let newSelected: T[] = [];

		if (isChecked) {
			newSelected = ArrayUtils.removeDuplicates([...selected, ...filteredOptions]);
		} else {
			newSelected = selected.filter((s) => !filteredOptions.includes(s));
		}
		dispatchChange(newSelected);
	};

	const dispatchChange = (value: T[]) => {
		value = value.filter((item) => item && !Array.isArray(item));
		value = ArrayUtils.uniq(value);
		onChange?.(value);
	};

	const onListClick = (event?: React.MouseEvent) => {
		onClick?.(event);

		setMultiSelectState(MultiSelectInputState.Editing);

		AppUtils.disableScrolling(true);
	};

	const pressButton = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				setMultiSelectState(MultiSelectInputState.Default);
				break;
		}
	};

	const onClose = () => {
		AppUtils.disableScrolling(false);

		setMultiSelectState(MultiSelectInputState.Default);
		setSearch("");
	};

	const filterOption = (option: T) => {
		if (search) {
			const reactElement = render(option);
			const text = (ReactUtils.getTextContent(reactElement as string) || "").toLowerCase();

			return text.includes(search.toLowerCase());
		}

		return true;
	};

	const onSearchStringChange = (value: string) => {
		setSearch(value);
	};

	const isValueChecked = (option: T, selected: T[]) => {
		return selected.some((o) => option === o);
	};

	const isOptionAllChecked = (selected: T[]) => {
		const filteredOptions = options.filter(filterOption);
		const isAllChecked = filteredOptions.every((o) => selected.includes(o));
		const intermediate = !isAllChecked && filteredOptions.some((o) => selected.includes(o));
		const checkbox = _checkboxAll.current;

		if (checkbox /* && intermediate && !isAllChecked */) {
			//checkbox.checked = isAllChecked;
			checkbox.indeterminate = intermediate;
		}
		return isAllChecked;
	};

	const expandList = (e: React.MouseEvent) => {
		e.stopPropagation();
		setMultiSelectState(MultiSelectInputState.Expanded);
	};

	const shrinkList = (e: React.MouseEvent) => {
		e.stopPropagation();
		setMultiSelectState(MultiSelectInputState.Default);
	};

	const getCurrentState = () => {
		const removedSelected = selected.filter((option) => !options.some((o) => option === o));
		const allOptions = [...options, ...removedSelected];
		const maxItemCount = 10;

		if (selected.length === 0 && multiSelectState !== MultiSelectInputState.Editing) {
			return null;
		} else {
			switch (multiSelectState) {
				case MultiSelectInputState.Default:
					return (
						<div onClick={onListClick}>
							{selected
								.slice()
								.sort((a, b) => StringUtils.sortIgnoreCase(render(a).toString(), render(b).toString()))
								.map((option, index) => {
									if (index < maxItemCount) {
										return (
											<MultiSelectOptionStyled key={index}>
												<div
													className="label"
													onClick={onListClick}
												>
													{render(option)}
												</div>
												{!inline && !disabled && (
													<IconButtonV5
														IconComponent={CloseIcon}
														onClick={(e) => onToggleValue(option, e)}
													/>
												)}
											</MultiSelectOptionStyled>
										);
									}
								})}
							{selected.length > maxItemCount && !inline && (
								<MoreButtonStyled onClick={expandList}>{`${selected.length - maxItemCount} More`}</MoreButtonStyled>
							)}
						</div>
					);
				case MultiSelectInputState.Expanded:
					return (
						<div onClick={onListClick}>
							{selected
								.slice()
								.sort((a, b) => StringUtils.sortIgnoreCase(render(a).toString(), render(b).toString()))
								.map((option, index) => {
									return (
										<MultiSelectOptionStyled key={index}>
											<div
												className="label"
												onClick={onListClick}
											>
												{render(option)}
											</div>
										</MultiSelectOptionStyled>
									);
								})}
							{selected.length > maxItemCount && !inline && <MoreButtonStyled onClick={shrinkList}>{"Less"}</MoreButtonStyled>}
						</div>
					);
				case MultiSelectInputState.Editing:
					const filteredOptions = allOptions.filter(filterOption);
					const realParentId = MathUtils.getNewRandomGUID();

					return (
						<div
							className="list"
							id={realParentId}
						>
							<DomPortal destination={appState.app.modalContainer}>
								<DropdownStyled
									className={ReactUtils.cls({inline})}
									ref={_list}
									style={{minWidth: isSameWidth ? _element.current.offsetWidth : "200px"}}
									$noFixedPosition={noFixedPosition}
									$translate={transform?.translate ?? ""}
								>
									{options.length > 5 && searchBar && (
										<SearchFieldV5
											value={search}
											onInput={onSearchStringChange}
											placeholder="Find"
											autoFocus={true}
										/>
									)}
									<ItemsLabelStyled>{`Showing ${filteredOptions.length}/${options.length} Items`}</ItemsLabelStyled>
									<ListStyled>
										<ListItemStyled
											className={ReactUtils.cls({selected: selected.length === filteredOptions.length})}
											onClick={() => inline && onToggleOptionAll(selected.length !== filteredOptions.length)}
										>
											{!inline && (
												<CheckboxInputV5
													inputRef={_checkboxAll}
													value={isOptionAllChecked(selected)}
													onChange={onToggleOptionAll}
												/>
											)}
											<div className="label">All</div>
										</ListItemStyled>
										{filteredOptions.map((option, index) => (
											<ListItemStyled
												key={index}
												onClick={() => inline && onToggleValue(option)}
												className={ReactUtils.cls({removed: !options.includes(option), selected: selected.includes(option)})}
											>
												{!inline && (
													<CheckboxInputV5
														value={isValueChecked(option, selected)}
														onChange={() => onToggleValue(option)}
													/>
												)}
												<div className="label">{render(option)}</div>
											</ListItemStyled>
										))}
									</ListStyled>
									{filteredOptions.length === 0 ? (
										<div className="bottom-section expanded noValues">
											No values found.
											<br />
											Contact your admin.
										</div>
									) : (
										<ItemsLabelStyled>{`${selected.length} item${selected.length > 1 ? "s" : ""} selected`}</ItemsLabelStyled>
									)}
								</DropdownStyled>
							</DomPortal>
						</div>
					);
			}
		}
	};

	useClickOutside([_element, _list], onClose);

	useEffect(() => {
		if (_element.current && _list.current && multiSelectState === MultiSelectInputState.Editing) {
			setTransform(
				DomUtils.getFixedFloatingElementPosition(_element.current, _list.current, VerticalAlignment.bottom, HorizontalAlignment.center, -8, 0),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_element.current, _list.current, setTransform, multiSelectState]);

	{
		return (
			!(disabled && selected.length === 0) && (
				<MultiSelectInputStyled
					ref={_element}
					onClick={onListClick}
					className={ReactUtils.cls({
						disabled,
						inline,
						empty: selected.length === 0,
						cellContent: inline,
					})}
					$emptyInline={inline && selected.length === 0}
				>
					{getCurrentState()}
				</MultiSelectInputStyled>
			)
		);
	}
}

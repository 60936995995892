import {computed, observable, makeObservable} from "mobx";
import type {Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {MarkupCloud} from "../../elements3d/markups/MarkupCloud";
import {MarkupArrow} from "../../elements3d/markups/MarkupArrow";
import {MarkupLine} from "../../elements3d/markups/MarkupLine";
import {MarkupDrawing} from "../../elements3d/markups/MarkupDrawing";
import {MarkupRectangle} from "../../elements3d/markups/MarkupRectangle";
import {MarkupEllipse} from "../../elements3d/markups/MarkupEllipse";
import {MarkupTriangle} from "../../elements3d/markups/MarkupTriangle";
import {MarkupCross} from "../../elements3d/markups/MarkupCross";
import {PencilTool} from "../../features/tools/markuptools/PencilTool";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import {MarkupTextBox} from "../../elements3d/markups/MarkupTextBox";
import {dashSize, gapSize} from "../../elements3d/markups/MarkupStaticElements";
import {MarkupCallout} from "../../elements3d/markups/MarkupCallout";
import type {FillOpacity, IMarkupConfig} from "../../elements3d/markups/abstract/MarkupUtils";
import {getCorrectionMultiplierForSpaceItem, MeasureType} from "../../renderers/SpaceViewRendererUtils";
import type {ABTool} from "../../features/tools/markuptools/ABTool";
import {Collection} from "../../../../../../../data/models/abstract/Collection";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {XHRLoader} from "../../../../../../../utils/loader/XHRLoader";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import type {IMarkupMinimumData} from "../../../../../../../data/models/Markup";
import {Markup} from "../../../../../../../data/models/Markup";
import {MarkupType, XyiconFeature} from "../../../../../../../generated/api/base";
import type {
	CreateMarkupRequest,
	MarkupCreateDetail,
	MarkupDto,
	MarkupUpdateDetail,
	UpdateMarkupRequest,
} from "../../../../../../../generated/api/base";
import type {IModel} from "../../../../../../../data/models/Model";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {EditableItemManager} from "./EditableItemManager";

export class MarkupManager extends EditableItemManager {
	public static defaultMarkupColor: string = "F44336";
	@observable
	protected _items: Collection<Markup3D> = new Collection();
	@observable
	private _markupColor: string = MarkupManager.defaultMarkupColor;

	protected _excludedLayerSettings = {};

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "markup");
		makeObservable(this);
	}

	public override init() {
		THREEUtils.setPosition(this._container, this._container.position.x, this._container.position.y, this._spaceViewRenderer.spaceOffset.z);
	}

	public override clear() {
		super.clear();
		this._spaceViewRenderer.spaceItemController.markupTextManager?.clear();
	}

	public onCanvasResize() {
		for (const markup of this._items.array) {
			markup.onCanvasResize();
		}
	}

	public createSpaceItem3DFromModel(markup: IMarkupMinimumData) {
		const spaceViewRenderer = this._spaceViewRenderer;
		const color = markup.color;
		const correctionMultiplier = getCorrectionMultiplierForSpaceItem(spaceViewRenderer, markup);

		const strokeConfig: IMarkupConfig = {
			strokeColor: color,
			fill: false,
		};

		const fillConfig: IMarkupConfig = {
			strokeColor: color,
			fill: true,
			fillOpacity: (1 - markup.fillTransparency) as FillOpacity,
		};

		let markup3D: Markup3D;

		switch (markup.type) {
			case MarkupType.Cloud:
				markup3D = new MarkupCloud(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Arrow:
				markup3D = new MarkupArrow(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Bidirectional_Arrow:
				markup3D = new MarkupArrow(spaceViewRenderer, strokeConfig, true);
				break;
			case MarkupType.Line:
				markup3D = new MarkupLine(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Dashed_Line:
				markup3D = new MarkupLine(spaceViewRenderer, {
					strokeColor: color,
					dashSize: dashSize * correctionMultiplier,
					gapSize: gapSize * correctionMultiplier,
				});
				break;
			case MarkupType.Pencil_Drawing:
				strokeConfig.strokeOpacity = PencilTool.strokeOpacity.pencil;
				markup3D = new MarkupDrawing(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Highlight_Drawing:
				strokeConfig.strokeOpacity = PencilTool.strokeOpacity.highlight;
				strokeConfig.isHighLight = true;
				markup3D = new MarkupDrawing(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Text_Box:
				fillConfig.fillOpacity = 0;
				fillConfig.strokeOpacity = 0;
				markup3D = new MarkupTextBox(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Rectangle:
				markup3D = new MarkupRectangle(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Ellipse:
				markup3D = new MarkupEllipse(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Triangle:
				markup3D = new MarkupTriangle(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Cross:
				markup3D = new MarkupCross(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Linear_Distance:
				strokeConfig.measureType = MeasureType.DISTANCE;
				markup3D = new MarkupLine(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Rectangle_Area:
				fillConfig.measureType = MeasureType.AREA;
				markup3D = new MarkupRectangle(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Nonlinear_Distance:
				strokeConfig.measureType = MeasureType.DISTANCE;
				markup3D = new MarkupDrawing(spaceViewRenderer, strokeConfig);
				break;
			case MarkupType.Irregular_Area:
				fillConfig.fillOpacity = 0.1;
				fillConfig.measureType = MeasureType.AREA;
				markup3D = new MarkupDrawing(spaceViewRenderer, fillConfig);
				break;
			case MarkupType.Stamp:
				break;
			case MarkupType.Photo:
				break;
			case MarkupType.Callout:
				fillConfig.fillOpacity = 0;
				markup3D = new MarkupCallout(spaceViewRenderer, fillConfig);
				break;
		}

		if (!markup3D) {
			console.warn("Wrong markup type!");
			return null;
		}

		markup3D.updateByModel(markup, false);

		return markup3D;
	}

	public addItemsByModel(markupModels: Markup[]) {
		const markups: Markup3D[] = [];

		for (const markupModel of markupModels) {
			const markup = this.createSpaceItem3DFromModel(markupModel);

			if (markup) {
				if (markupModel.type === MarkupType.Highlight_Drawing) {
					markupModel.setGeometryData(PencilTool.getOptimizedPathCoords(markupModel.geometryData));
				}
				markup.updateByModel(markupModel);
				markups.push(markup);
			} else {
				console.warn("Error while loading markup");
				console.warn(markupModel);
			}
		}
		this.add(markups, false);

		return markups;
	}

	public initMarkups(markupArray: Markup[]) {
		this.addItemsByModel(markupArray);
	}

	protected async addItems(items: Markup3D[], addToServer: boolean = true) {
		for (const item of items) {
			item.finalize();
			if (!addToServer) {
				(item as MarkupTextBox).hideOutline?.();
			}
		}

		let textNeedsUpdate: boolean = false;

		if (addToServer) {
			const appState = this._spaceViewRenderer.transport.appState;
			const spaceID = appState.space.id;
			const portfolioID = appState.portfolioId;

			const markupCreateDetail: MarkupCreateDetail[] = [];

			for (const item of items) {
				const data = item.data;

				const markupCreateDetailObject: MarkupCreateDetail = {
					spaceID: spaceID,
					orientation: data.orientation,
					svgData: {
						geometryData: data.geometryData,
						color: data.color,
						fillTransparency: data.fillTransparency,
						lineThickness: data.lineThickness,
						text: data.text,
					},
					type: data.type,
					settings: data.settings,
				};

				// We're not saving temp markups into the db
				if (item.config.isTemp) {
					const markupDto: MarkupDto = {
						...markupCreateDetailObject,
						markupID: MathUtils.getNewRandomGUID(),
					};
					const markupModel = new Markup(markupDto, this._spaceViewRenderer.transport.appState, true);

					item.addModelData(markupModel);
				}
				// Eg.: Set-scale markup shouldn't be saved, so it has a "null" type
				else if (data.type != null) {
					if (!textNeedsUpdate && item.textContent.length > 0) {
						textNeedsUpdate = true;
					}

					markupCreateDetail.push(markupCreateDetailObject);
				}
			}

			if (markupCreateDetail.length > 0) {
				try {
					const createData: CreateMarkupRequest = {
						portfolioID,
						markupCreateDetail,
					};

					const markupModels = await this._spaceViewRenderer.transport.services.feature.create<Markup>(createData, XyiconFeature.Markup);

					if (markupModels?.length === items.length) {
						for (let i = 0; i < items.length; ++i) {
							const item = items[i];
							const markupModel = markupModels[i];

							item.addModelData(markupModel);
							(item as MarkupTextBox).hideOutline?.();
						}
					}
				} catch (error) {
					console.warn(`Markup not saved: ${error}`);
				}
			}
		}

		this._items.addMultiple(items);

		if (textNeedsUpdate) {
			// Workaround for the bug, when text is "below" the new markup
			requestAnimationFrame(() => {
				this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
			});
		}
	}

	public override async updateItems(items: SpaceItem[], force: boolean = false) {
		let textNeedsUpdate: boolean = false;

		const markups = [...items]; // the original array can be modified (eg.: length = 0), and it can cause bugs if we don't clone the array like this
		const markupUpdateDetail: MarkupUpdateDetail[] = [];

		for (const item of markups as Markup3D[]) {
			const modelData = item.modelData as Markup;
			const previousData = {
				svgData: {
					geometryData: modelData.geometryData,
					color: modelData.color,
					fillTransparency: modelData.fillTransparency,
					lineThickness: modelData.lineThickness,
					text: modelData.text,
				},
				orientation: modelData.orientation,
				type: modelData.type,
				settings: modelData.settings,
			};

			const currentData = item.data;

			const dataToCompare = {
				svgData: {
					geometryData: currentData.geometryData,
					color: currentData.color,
					fillTransparency: currentData.fillTransparency,
					lineThickness: currentData.lineThickness,
					text: currentData.text,
				},
				orientation: currentData.orientation,
				type: currentData.type,
				settings: currentData.settings,
			};

			const hasChanged = force || !modelData.isSettingsSaved || JSON.stringify(previousData) !== JSON.stringify(dataToCompare);

			if (hasChanged) {
				const dataToSend: MarkupUpdateDetail = {
					...dataToCompare,
					markupID: modelData.id,
				};

				if (currentData.text.content.length > 0) {
					textNeedsUpdate = true;
				}

				if (modelData.isTemp) {
					modelData.applyData(dataToSend);
				} else {
					markupUpdateDetail.push(dataToSend);
				}
			}
		}

		if (markupUpdateDetail.length > 0) {
			try {
				const appState = this._spaceViewRenderer.transport.appState;
				const spaceID = appState.space.id;
				const portfolioID = appState.portfolioId;

				const params: UpdateMarkupRequest = {
					markupUpdateDetail: markupUpdateDetail,
					spaceID: spaceID,
					portfolioID: portfolioID,
				};

				const {result} = await this._spaceViewRenderer.transport.requestForOrganization<MarkupDto[]>({
					url: "markups/update",
					method: XHRLoader.METHOD_POST,
					params: params,
				});

				const updatedMarkupArray: IModel[] = [];

				for (const newModelData of result) {
					const markup3D = markups.find((item) => item.modelData.id === newModelData.markupID);

					markup3D.applyModelData(ObjectUtils.apply(JSON.parse(JSON.stringify((markup3D.modelData as Markup).data)), newModelData));
					updatedMarkupArray.push(markup3D.modelData);
				}
				this.signals.itemsUpdate.dispatch(updatedMarkupArray);
			} catch (error) {
				console.warn("Items couldn't be updated on the backend!");
				console.warn(error);
			}
		}

		if (textNeedsUpdate) {
			// Workaround for the bug, when text is "below" the new markup
			requestAnimationFrame(() => {
				this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
			});
		}
	}

	public get tempMarkups() {
		// In practice, this only contains the one currently being created, if any
		const additionalTempMarkups: Markup3D[] = [(this._spaceViewRenderer.toolManager.activeTool as ABTool | PencilTool).markup];
		const tempMarkups = [...this._items.array, ...additionalTempMarkups].filter((item) => item?.isTemp);

		return tempMarkups;
	}

	public updateAllTempMarkups() {
		const {tempMarkups} = this;

		for (const markup of tempMarkups) {
			markup.updateByModel(markup.modelData as Markup);
		}

		if (tempMarkups.length > 0) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	}

	public removeNewestTempMarkup() {
		const {tempMarkups} = this;

		if (tempMarkups.length > 0) {
			const tool = this._spaceViewRenderer.toolManager.activeTool as ABTool | PencilTool;

			if (tool.markup?.isTemp) {
				tool.abortMarkup();
			} else {
				const item = tempMarkups[tempMarkups.length - 1];

				item.destroy(false, true);
			}

			this._spaceViewRenderer.spaceItemController.deselectAll();
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	}

	public removeAllTempMarkups = () => {
		const tempMarkups = this.tempMarkups;
		const areThereTempMarkups = tempMarkups.length > 0;

		if (areThereTempMarkups) {
			this._spaceViewRenderer.spaceItemController.deselectAll();
		}

		for (const item of tempMarkups) {
			item.destroy(false, true);
		}

		if (areThereTempMarkups) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	};

	public setColor(hex: string) {
		this._markupColor = hex;
	}

	public override getItemsByType(type: MarkupType) {
		return this._items.array.filter((markup: Markup3D) => markup.type === type);
	}

	@computed
	public get markupColor() {
		return this._markupColor;
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import type {IViewColumn, IViewSort} from "../../../data/models/ViewUtils";
import {SortDirection} from "../../../data/models/ViewUtils";
import {SVGIcon} from "../button/SVGIcon";
import {ReactUtils} from "../../utils/ReactUtils";
import type {IFieldPointer} from "../../../data/models/field/Field";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {XyiconFeature} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import {Functions} from "../../../utils/function/Functions";
import type {ManageColumnCallback} from "./TableHeaderDropDown";
import {TableHeaderDropDown} from "./TableHeaderDropDown";
import {minHeaderWidth, minIconHeaderWidth} from "./TableConstants";
import {getSortCicleNextElement} from "./TableUtils";

interface ITableHeaderProps<T> {
	header: Partial<IViewColumn>;
	index: number;
	sort?: IViewSort;
	sortIndex: number;
	multipleSorts: boolean;
	dragSource: boolean;
	dragTarget: boolean;
	onDragStart: (index: number, event: React.DragEvent) => void;
	onDragEnd: (index: number, event: React.DragEvent) => void;
	onDragEnter: (index: number, event: React.DragEvent) => void;
	onDragOver: (index: number, event: React.DragEvent) => void;
	onDragLeave: (index: number, event: React.DragEvent) => void;
	onColResizeStart: (index: number, pointer: Pointer) => void;
	onColResizeMove: (index: number, pointer: Pointer, header: Partial<IViewColumn>) => void;
	onToggleSort?: (column: IFieldPointer) => void;
	onAutoResizeColumn: (index: number, header: Partial<IViewColumn>) => void;
	onManageColumns: ManageColumnCallback;
	onSortAsc?: (id: IFieldPointer) => void;
	onSortDesc?: (id: IFieldPointer) => void;
	onClearSort?: (id: IFieldPointer) => void;
	allowHide: boolean;
	feature: XyiconFeature;
	appState?: AppState;
}

interface ITableHeaderState<T> {
	hover: boolean;
	open: boolean;
	sortOrder: SortDirection;
	isColBeingResized: boolean;
}

@inject("appState")
export class TableHeader<T> extends React.Component<ITableHeaderProps<T>, ITableHeaderState<T>> {
	constructor(props: ITableHeaderProps<T>) {
		super(props);
		this.state = {
			hover: false,
			open: false,
			sortOrder: this.props.sort?.direction,
			isColBeingResized: false,
		};
	}

	public static getDerivedStateFromProps(props: ITableHeaderProps<any>, state: ITableHeaderState<any>) {
		if (!state.sortOrder && props.sort?.direction) {
			return {sortOrder: props.sort?.direction};
		}
		return null;
	}

	private onDragStart = (event: React.DragEvent) => {
		this.props.onDragStart(this.props.index, event);
	};

	private onDragEnd = (event: React.DragEvent) => {
		this.props.onDragEnd(this.props.index, event);
	};

	private onDragEnter = (event: React.DragEvent) => {
		this.props.onDragEnter(this.props.index, event);
	};

	private onDragOver = (event: React.DragEvent) => {
		this.props.onDragOver(this.props.index, event);
	};

	private onDragLeave = (event: React.DragEvent) => {
		this.props.onDragLeave(this.props.index, event);
	};

	private onColResizeStart = (pointer: Pointer) => {
		this.setState({
			isColBeingResized: true,
		});
		this.props.onColResizeStart(this.props.index, pointer);
	};

	private onColResizeMove = (pointer: Pointer) => {
		this.props.onColResizeMove(this.props.index, pointer, this.props.header);
	};

	private onColResizeEnd = (pointer: Pointer) => {
		this.setState({
			isColBeingResized: false,
		});
	};

	private onHeaderClick = (event: React.MouseEvent) => {
		if (!(event.target as HTMLElement).classList.contains("resizer")) {
			this.props.onToggleSort(this.props.header.field);

			const newSortOrder = getSortCicleNextElement(this.state.sortOrder);

			this.setState({sortOrder: newSortOrder});
		}
	};

	private onHeaderDoubleClick = () => {
		this.props.onAutoResizeColumn(this.props.index, this.props.header);
	};

	private onMouseOver = () => {
		this.setState({hover: true});
	};

	private onMouseOut = () => {
		this.setState({hover: false});
	};

	private onOpenChange = (openValue: boolean, hoverValue?: boolean) => {
		this.setState({open: openValue});
		if (hoverValue !== undefined) {
			this.setState({hover: hoverValue});
		}
	};

	private onManageColumns = () => {
		this.props.onManageColumns({
			type: "manage",
			index: this.props.index,
		});
	};

	private onHideColumn = () => {
		this.props.onManageColumns({
			type: "hide",
			index: this.props.index,
		});
	};

	private onSortAsc = () => {
		const {appState, header, feature, onSortAsc} = this.props;

		onSortAsc?.(header.field);
		const realFeature = this.props.appState.app.spaceViewRenderer.isMounted ? XyiconFeature.SpaceEditor : feature;
		const view = appState.actions.getSelectedView(realFeature);

		view?.addSort({column: header.field, direction: SortDirection.ASC});

		this.setState({sortOrder: SortDirection.ASC});
	};

	private onSortDesc = () => {
		const {appState, header, feature, onSortDesc} = this.props;

		onSortDesc?.(header.field);
		const realFeature = this.props.appState.app.spaceViewRenderer.isMounted ? XyiconFeature.SpaceEditor : feature;
		const view = appState.actions.getSelectedView(realFeature);

		view?.addSort({column: header.field, direction: SortDirection.DESC});

		this.setState({sortOrder: SortDirection.DESC});
	};

	private onClearSort = (e?: React.MouseEvent) => {
		e?.stopPropagation();

		const {appState, header, feature, onClearSort} = this.props;

		onClearSort?.(header.field);
		const realFeature = this.props.appState.app.spaceViewRenderer.isMounted ? XyiconFeature.SpaceEditor : feature;
		const view = appState.actions.getSelectedView(realFeature);

		view?.addSort({column: header.field, direction: null});

		this.setState({sortOrder: null});
	};

	public override componentDidUpdate() {
		if (this.props.sort?.column !== this.props.header.field && this.state.sortOrder) {
			this.setState({sortOrder: null});
		}
	}

	public override render() {
		const {header, index, dragSource, dragTarget, allowHide, sortIndex, multipleSorts} = this.props;
		const {hover, open, sortOrder} = this.state;
		const iconField = header.field.includes("icon");

		return (
			<div
				className={ReactUtils.cls("th", {
					dragSource: dragSource,
					dragTarget: dragTarget,
					icon: header.field.includes("icon"),
				})}
				key={index}
				style={{
					width: iconField ? `${header.width || minIconHeaderWidth}px` : `${Math.max(header.width, minHeaderWidth) || minHeaderWidth}px`,
					minWidth: iconField ? `${header.width || minIconHeaderWidth}px` : `${Math.max(header.width, minHeaderWidth) || minHeaderWidth}px`,
				}}
				draggable={!this.state.isColBeingResized}
				onDragStart={this.onDragStart}
				onDragEnd={this.onDragEnd}
				onDragOver={this.onDragOver}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onClick={this.onHeaderClick}
				onMouseEnter={this.onMouseOver}
				onMouseLeave={this.onMouseOut}
			>
				{iconField ? (
					<span className="label icon">{header.title || `Column ${index + 1}`}</span>
				) : (
					<span className="label">{header.title || `Column ${index + 1}`}</span>
				)}
				{sortOrder && this.props.sort?.column === header.field && !iconField && (
					<span className={ReactUtils.cls("sort sortIndicator", {large: multipleSorts})}>
						<SVGIcon icon={sortOrder === SortDirection.ASC ? "arrow-up" : "arrow-down"} />
						{multipleSorts && sortIndex + 1}
					</span>
				)}
				{sortOrder && hover && this.props.sort?.column === header.field && !iconField && (
					<div
						className="sort cancelSort"
						onClick={this.onClearSort}
					>
						<SVGIcon icon="cancel" />
					</div>
				)}
				{(hover || open) && (
					<TableHeaderDropDown
						open={open}
						onOpenChange={this.onOpenChange}
						dropdownOptions={[
							...(!iconField
								? [
										{
											label: "Sort Ascending",
											onSelect: this.onSortAsc,
											selected: sortOrder === SortDirection.ASC,
										},
										{
											label: "Sort Descending",
											onSelect: this.onSortDesc,
											selected: sortOrder === SortDirection.DESC,
										},
										{
											label: "Clear Sort",
											onSelect: this.onClearSort,
											disabled: !sortOrder,
										},
									]
								: []),
							...(this.props.onManageColumns
								? [
										{
											label: "Manage Columns",
											onSelect: this.onManageColumns,
										},
										...(allowHide
											? [
													{
														label: "Hide Column",
														onSelect: this.onHideColumn,
													},
												]
											: []),
									]
								: []),
						]}
					/>
				)}
				{header.title == "Icon" ? (
					<PointerDetectorReact>
						<div className="resizer icon" />
					</PointerDetectorReact>
				) : (
					<PointerDetectorReact
						onDown={this.onColResizeStart}
						onMove={this.onColResizeMove}
						onUp={this.onColResizeEnd}
					>
						<div
							className="resizer"
							onDoubleClick={this.onHeaderDoubleClick}
							onClick={Functions.stopPropagation}
						/>
					</PointerDetectorReact>
				)}
			</div>
		);
	}
}

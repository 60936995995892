import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {AppState} from "../../../../../../../data/state/AppState";
import {IDateFieldFormat} from "../../../../../settings/modules/field/datatypes/DateFieldSettings";
import type {Markup} from "../../../../../../../data/models/Markup";
import {DateFormatter} from "../../../../../../../utils/format/DateFormatter";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import {changeMarkupType, markupTypeToMarkupToolName} from "../../../../../space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {Markup3D} from "../../../../../space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {SVGIcon} from "../../../../../../widgets/button/SVGIcon";
import {MarkupsWithCustomText} from "../../../../../space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";

interface IMarkupDefaultFieldsProps {
	item: Markup;
	appState?: AppState;
}

@inject("appState")
@observer
export class MarkupDefaultFields extends React.Component<IMarkupDefaultFieldsProps> {
	private get spaceViewRenderer() {
		return this.props.appState.app.graphicalTools.spaceViewRenderer;
	}

	private get markup3D(): Markup3D | null {
		return this.spaceViewRenderer.markupManager.getItemById(this.props.item.id) as Markup3D;
	}

	private onMarkupTypeChange = (markupType: MarkupType) => {
		const {markup3D} = this;

		if (markup3D) {
			changeMarkupType([markup3D], markupType, this.spaceViewRenderer);
		}
	};

	private markupTypeRender = (markupType: MarkupType) => {
		const formattedMarkupType = `${MarkupType[markupType].replaceAll("_", " ")} Markup`;

		const tools = this.props.appState.app.graphicalTools.spaceViewRenderer.tools;
		const icon = (
			<SVGIcon
				icon={tools[markupTypeToMarkupToolName(markupType)].icon}
				style={{width: "24px", height: "24px", marginRight: "10px", fill: "initial"}}
			/>
		);

		return (
			<div className="hbox alignCenter">
				{icon}
				{formattedMarkupType}
			</div>
		);
	};

	public override render() {
		const {item, appState} = this.props;
		const {isTypeChangeable} = item;

		return (
			<>
				<Field label="Markup Type">
					{isTypeChangeable ? (
						<SelectInput
							options={MarkupsWithCustomText}
							selected={item.type}
							render={this.markupTypeRender}
							onChange={this.onMarkupTypeChange}
						/>
					) : (
						item.typeName
					)}
				</Field>
				<Field label="Last Modified At">{DateFormatter.format(item.data.lastModifiedAt, IDateFieldFormat.DATETIME)}</Field>
				<Field label="Last Modified By">{appState.actions.findUser(item.data.lastModifiedBy)?.fullName || ""}</Field>
			</>
		);
	}
}

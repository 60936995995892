import type {IInputProps} from "../../FieldInput";
import {DateTimeInput} from "../../../datetime/DateTimeInput";
import type {IDateFieldSettingsDefinition} from "../../../../../modules/settings/modules/field/datatypes/DateFieldSettings";

export function DateInput(props: IInputProps<IDateFieldSettingsDefinition>) {
	const {value, onClick, onChange, dataTypeSettings, disabled, onFocusLossForceBlur, inline, focused} = props;

	return (
		<DateTimeInput
			value={value}
			format={dataTypeSettings.format}
			disabled={disabled}
			onFocusLossForceBlur={onFocusLossForceBlur}
			focused={focused}
			onChange={onChange}
			onClick={onClick}
			inline={inline}
		/>
	);
}

import {ReactSortable} from "react-sortablejs";
import type {Sortable} from "react-sortablejs";
import {useRef, useState} from "react";
import styled from "styled-components";
import {TimeUtils} from "../../../utils/TimeUtils";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import {InfoBubbleV5} from "../button/InfoBubbleV5";
import InfoIcon from "../icons/circle-information.svg?react";
import TrashIcon from "../icons/trash.svg?react";
import {ReactUtils} from "../../utils/ReactUtils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {ButtonV5} from "../button/ButtonV5";
import {colorPalette} from "../styles/colorPalette";
import {ToggleSwitchFieldV5} from "./ToggleSwitchFieldV5";
import {TextInputV5} from "./datatypes/TextInputV5";

interface IListBuilderInputProps {
	list: string[];
	onChange: (list: string[]) => void;
}

interface IListBuilderInputSortableList {
	id: string;
	value: string;
}

const dragTolerance = 4;
const defaultItemString = "New Item";

const generateSortableListFromSourceList = (source: string[]): IListBuilderInputSortableList[] => {
	return source.map((item, index) => ({id: `${item}-${index}`, value: item}));
};

const createNewItemName = (count: number) => `${defaultItemString} (${count})`;

function filterDragElement(this: Sortable, event: Event | TouchEvent, target: HTMLElement, sortable: Sortable): boolean {
	const element = event.target as HTMLElement;
	const elementName = element.nodeName.toLowerCase();

	return elementName === "input" || elementName === "textarea";
}

export const ListBuilderInputV5 = (props: IListBuilderInputProps) => {
	const [isUsingTextArea, setUsingTextArea] = useState<boolean>(false);
	const [textArea, setTextArea] = useState<string>(props.list.join("\n"));
	const [editedItem, setEditedItem] = useState<{index: number; value: string}>({index: -1, value: ""});
	const _listRef = useRef<HTMLDivElement>();

	const onAddItemClick = async () => {
		let count: number = 1;
		let newItemName = createNewItemName(count);

		while (props.list.includes(newItemName)) {
			newItemName = createNewItemName(++count);
		}

		props.onChange([...props.list, newItemName]);

		await TimeUtils.waitForNextFrame();
		await TimeUtils.waitForNextFrame();

		// Number.MAX_SAFE_INTEGER is not working reliably in Safari and Firefox
		_listRef.current.scrollTo({top: _listRef.current.scrollHeight, behavior: "smooth"});
	};

	const isCurrentListValid = (currentList: string[] = props.list) => {
		let currList: string[] = currentList;

		if (isUsingTextArea) {
			currList = textArea.split("\n").filter((line) => !!line);
		} else {
			currList = ObjectUtils.deepClone(currList);

			if (editedItem.index !== -1) {
				const {value} = editedItem;

				currList[editedItem.index] = value.trim();
				if (!value) {
					return false;
				}
			}
		}

		const caseInsensitiveList = currList.map((el) => el.toLowerCase());

		const s = new Set<string>(caseInsensitiveList);
		const isEveryItemUnique = s.size === caseInsensitiveList.length;

		return isEveryItemUnique;
	};

	const onEditItem = (value: string, index: number) => {
		setEditedItem({value, index});
	};

	const onBlurTextArea = () => {
		const newList = textArea
			.split("\n")
			.filter((line) => !!line)
			.map((line) => line.trim());

		if (isCurrentListValid() && !ObjectUtils.compare(newList, props.list)) {
			props.onChange(newList);
		} else {
			setTextArea(props.list.join("\n"));
		}
	};

	const onItemSave = (id: string, newValue: string) => {
		if (isCurrentListValid()) {
			const list = generateSortableListFromSourceList(props.list);
			const newList = generateSortableListFromSourceList(props.list);
			const listItem = newList.find((el) => el.id === id);

			listItem.value = newValue.trim();
			if (!ObjectUtils.compare(newList, list) && isCurrentListValid(newList.map((v) => v.value))) {
				props.onChange(newList.map((item) => item.value));
			}
		}
	};

	const onDeleteClick = (index: number) => {
		if (index < props.list.length) {
			props.onChange(ArrayUtils.removeAtIndex(props.list, index));
		}
	};

	const onSwitchChange = () => {
		if (!isUsingTextArea) {
			setTextArea(props.list.join("\n"));
		}
		setUsingTextArea(!isUsingTextArea);
	};

	const onEditTextArea = (value: string) => {
		setTextArea(value);
	};

	const onSetList = (newList: IListBuilderInputSortableList[]) => {
		const newArray = newList.map((l) => l.value);
		const oldArray = props.list;

		if (!ObjectUtils.compare(newArray, oldArray)) {
			props.onChange(newArray);
		}
	};

	const listToRender = generateSortableListFromSourceList(props.list);
	const isValid = isCurrentListValid();

	return (
		<ListBuilderInputWrapper className="ListBuilderInputWrapper">
			{!isValid && (
				<div className="infoIcon">
					<InfoIcon />
					<InfoBubbleV5
						content="Items must be unique."
						isErrorMessage={true}
						className="left"
					/>
				</div>
			)}
			<ListBuilderInputStyled
				className={ReactUtils.cls("ListBuilderInput", {error: !isValid})}
				ref={_listRef}
			>
				{isUsingTextArea ? (
					<TextInputV5
						isTextArea={true}
						value={textArea}
						onInput={onEditTextArea}
						onBlur={onBlurTextArea}
					/>
				) : (
					<ReactSortable
						list={listToRender}
						setList={onSetList}
						animation={150}
						filter={filterDragElement}
						fallbackOnBody={true}
						forceFallback={true}
						swapThreshold={0.45}
						fallbackTolerance={dragTolerance}
						touchStartThreshold={dragTolerance}
						preventOnFilter={false}
						delay={500}
						delayOnTouchOnly={true}
					>
						{listToRender.map((item, index) => {
							const onItemChange = (newValue: string) => onItemSave(item.id, newValue);

							return (
								<div
									className="item hbox grabbable"
									key={item.id}
								>
									<TextInputV5
										value={editedItem.index === index ? editedItem.value : item.value}
										onInput={(value) => onEditItem(value, index)}
										onChange={onItemChange}
										onBlur={() => {
											if (editedItem.index === index) {
												if (isValid) {
													onItemChange(editedItem.value);
												}
												setEditedItem({index: -1, value: ""});
											}
										}}
									/>
									<IconButtonV5
										IconComponent={TrashIcon}
										title="Delete"
										onClick={() => onDeleteClick(index)}
									/>
								</div>
							);
						})}
					</ReactSortable>
				)}
			</ListBuilderInputStyled>
			<div className="hbox add-item alignCenter">
				{!isUsingTextArea && (
					<ButtonV5
						label="Add Item"
						className="small"
						onClick={onAddItemClick}
					/>
				)}
				<div className="flex_1" />
				<ToggleSwitchFieldV5
					value={isUsingTextArea}
					onChange={onSwitchChange}
					label="Add multiple items at once"
				/>
			</div>
		</ListBuilderInputWrapper>
	);
};

const ListBuilderInputWrapper = styled.div`
	width: 100%;
	position: relative;
	.infoIcon {
		position: absolute;
		top: 3px;
		right: 10px;
		z-index: 1;
	}
`;

const ListBuilderInputStyled = styled.div`
	width: 100%;
	min-width: 240px;
	max-height: 250px;
	overflow-y: scroll;
	padding: base.$xs;
	border: 1px solid ${colorPalette.gray.c100};
	position: relative;
	border-bottom: none;
	padding-bottom: 0;
	padding-right: 0;

	&.error {
		border-color: ${colorPalette.warning.c700Dark};

		& + .add-item {
			border-color: ${colorPalette.warning.c700Dark};
		}
	}

	textarea {
		min-height: 150px;
		line-height: 16px;
	}

	.item {
		align-items: center;
		height: 35px;
		padding: 2px 2px 2px 8px;
		background-color: ${colorPalette.gray.c100};
		margin-bottom: 2px;

		.delete {
			fill: var(--icon);
			margin-left: 4px;
			display: none;
		}

		input {
			background: none;
			transition: none;
			padding: 0 0 0 2px;
			border: 0;
			flex-grow: 1;
			text-overflow: ellipsis;
		}

		&:hover {
			background-color: ${colorPalette.primary.c700Dark};

			.delete {
				display: block;
			}
		}

		input:focus {
			background-color: ${colorPalette.primary.c700Dark};
			color: white;
		}

		&:focus-within {
			background-color: ${colorPalette.primary.c500Primary};

			.delete {
				fill: white;
				display: block;
			}
		}

		&::before {
			content: "";
			background: url(../assets/images/common/drag-icon.svg) no-repeat center;
			background-size: contain;
			width: 8px;
			height: 14px;
			left: -8px;
			top: 0;
			position: relative;
			cursor: move;
		}

		&:focus-within::before {
			background: url(../assets/images/common/drag-icon-white.svg) no-repeat center;
		}

		.cancel.button {
			margin-left: auto;
		}
	}
`;

import styled from "styled-components";
import type {Catalog} from "../../../data/models/Catalog";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {Functions} from "../../../utils/function/Functions";
import DotsHorizontalIcon from "../icons/dots-horizontal.svg?react";
import TrashIcon from "../icons/trash.svg?react";
import InfoIcon from "../icons/info.svg?react";
import UnlinkIcon from "../icons/unlink.svg?react";
import PlusIcon from "../icons/plus.svg?react";
import {DropdownButtonV5} from "../interaction/DropdownButtonV5";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {useAppStore} from "../../../StateManager";
import {colorPalette} from "../styles/colorPalette";
import {getCatalogItemOptionsV5} from "./CatalogItemOptionsV5";

interface ISpaceItemActionButtons {
	readonly feature: XyiconFeature;
	readonly isFavorite?: boolean;
	readonly itemTypeId?: string;
	readonly isUnplottedXyicon: boolean;
	readonly isExternalXyicon: boolean;
	readonly isCrossPortfolioXyicon: boolean;
	readonly showDeleteButton: boolean;
	readonly showInfoButton: boolean;
	readonly catalog?: Catalog;
	readonly options?: IDropdownOption[];
	readonly onFavoriteClick?: () => void;
	readonly onUnFavoriteClick?: () => void;
	readonly onCreateUnplottedXyiconClick?: () => void;
	readonly onBreakLinkClick?: () => void;
	readonly onDetailsClick?: () => void;
	readonly deleteItem?: () => void;
	readonly onDuplicateCatalogClick?: (item: Catalog) => void;
	readonly onDeleteCatalogClick?: (item: Catalog) => void;
}

export function SpaceItemActionButtonsV5(props: ISpaceItemActionButtons) {
	const appState = useAppStore((state) => state.appState);

	const {
		feature,
		isFavorite,
		itemTypeId,
		isUnplottedXyicon,
		catalog,
		isExternalXyicon,
		isCrossPortfolioXyicon,
		showInfoButton,
		showDeleteButton,
		onBreakLinkClick,
		onDetailsClick,
		onFavoriteClick,
		onUnFavoriteClick,
		onCreateUnplottedXyiconClick,
		onDuplicateCatalogClick,
		onDeleteCatalogClick,
		deleteItem,
		options,
	} = props;

	const isSpaceEditorMounted = location.hash.includes("space/");
	const userCatalogPermission = appState.user.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	const userXyiconPermission = appState.actions.getModuleTypePermission(itemTypeId, XyiconFeature.Xyicon);
	const hasSpaceItemPermission = feature === XyiconFeature.Boundary || userXyiconPermission > Permission.View;

	return (
		<SpaceItemActionButtonsStyled className="SpaceItemActionButtons buttonContainer hbox alignCenter">
			{!isSpaceEditorMounted && feature === XyiconFeature.XyiconCatalog && hasSpaceItemPermission && (
				<PlusIcon
					title="Create unplotted xyicon"
					onClick={onCreateUnplottedXyiconClick}
				/>
			)}
			{options ? (
				<DropdownButtonV5
					button={<DotsHorizontalIcon />}
					options={options}
				/>
			) : (
				feature === XyiconFeature.XyiconCatalog &&
				userCatalogPermission > Permission.View &&
				hasSpaceItemPermission && (
					<DropdownButtonV5
						button={<DotsHorizontalIcon />}
						options={getCatalogItemOptionsV5({
							isFavorite,
							app: appState.app,
							item: catalog,
							onDeleteCatalogClick,
							onDuplicateCatalogClick,
							userCatalogPermission,
							onFavoriteClick,
							onUnFavoriteClick,
						})}
					/>
				)
			)}
			{(isExternalXyicon || isUnplottedXyicon || isCrossPortfolioXyicon) &&
				onBreakLinkClick &&
				onBreakLinkClick !== Functions.emptyFunction &&
				hasSpaceItemPermission && (
					<UnlinkIcon
						title="Break Link"
						onClick={onBreakLinkClick}
					/>
				)}
			{showInfoButton && hasSpaceItemPermission && (
				<InfoIcon
					title="Details"
					onClick={onDetailsClick}
				/>
			)}
			{showDeleteButton && deleteItem && userXyiconPermission === Permission.Delete && hasSpaceItemPermission && (
				<TrashIcon
					title="Delete"
					onClick={deleteItem}
				/>
			)}
		</SpaceItemActionButtonsStyled>
	);
}

const SpaceItemActionButtonsStyled = styled.div`
	svg {
		margin: 0 4px;
		padding: 4px;
		height: 24px;
		width: 24px;
		border: 1px solid transparent;
		border-radius: 4px;
		&:hover {
			border-color: ${colorPalette.gray.c950};
		}
	}
`;

import {useRef} from "react";
import {XyiconFeature} from "../../../generated/api/base";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import CloneIcon from "../icons/clone.svg?react";
import PenFieldIcon from "../icons/pen-field.svg?react";
import DeleteIcon from "../icons/delete.svg?react";
import PenWithLineIcon from "../icons/pen-with-line.svg?react";
import StarIcon from "../icons/star.svg?react";
import type {Catalog} from "../../../data/models/Catalog";
import {SetFavoriteButton} from "../abstract/table/SetFavoriteButton";
import {CatalogCreatePopupV5} from "./catalog/CatalogCreatePopupV5";

interface ICatalogViewV5Props {
	param1?: string;
	param2?: string;
}

export const CatalogViewV5 = (props: ICatalogViewV5Props) => {
	const createButtonRef = useRef<HTMLDivElement>();

	const appState = useAppStore((state) => state.appState);

	const getCatalogPermission = () => {
		return appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	};

	const setFavorite = (items: Catalog[]) => {
		items.forEach((item) => item.setFavorite(!item.isFavorite));
	};

	{
		return (
			<ModuleViewV5
				feature={XyiconFeature.XyiconCatalog}
				className="CatalogView"
				actionBar={[
					{
						id: "favorite",
						title: "Add to Favorites", // gets overwritten within SetFavoriteButton
						IconComponent: StarIcon,
						visible: (selectedItems: Catalog[]) => selectedItems.length >= 1,
						onClick: setFavorite,
						componentFactory: (selectedItems: Catalog[]) => (
							<SetFavoriteButton
								items={selectedItems}
								setFavorite={setFavorite}
							/>
						),
					},
					{
						id: "duplicate",
						title: "Duplicate Catalog Item",
						label: "Duplicate",
						IconComponent: CloneIcon,
						visible: (selectedItems) => selectedItems.length === 1,
					},
					{
						id: "edit",
						title: "Edit Catalog Item",
						label: "Edit",
						IconComponent: PenWithLineIcon,
						visible: (selectedItems) => selectedItems.length === 1,
					},
					{
						id: "fieldUpdate",
						title: "Update Field",
						label: "Update Field",
						IconComponent: PenFieldIcon,
						visible: () => true,
					},
					{
						id: "delete",
						title: "Delete Catalog Item",
						label: "Delete",
						IconComponent: DeleteIcon,
						visible: () => true,
					},
				]}
				userPermission={getCatalogPermission()}
				create={(onClose) => (
					<CatalogCreatePopupV5
						onClose={() => {
							onClose();
						}}
						parentRef={createButtonRef}
					/>
				)}
				createButtonRef={createButtonRef}
			/>
		);
	}
};

import * as React from "react";
import {inject} from "mobx-react";
import {SVGIcon} from "../../button/SVGIcon";
import {ToggleSwitch} from "../../button/switch/ToggleSwitch";
import {CheckboxInput} from "../../input/checkbox/CheckboxInput";
import {FieldDataType} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import type {App} from "../../../../App";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";

export interface IFieldProps {
	label: string | React.ReactNode;
	reverse?: boolean;
	className?: string;
	disabled?: boolean;
	noWrap?: boolean;
	icons?: IFieldIcon;
	tooltips?: IFieldtooltip;
	app?: App;
	children: React.ReactNode;
}

interface IFieldIcon {
	preLabelIcon?: string;
	postLabelIcon?: string;
}

interface IFieldtooltip {
	preLabelIconTooltip?: string;
	postLabelIconTooltip?: string;
	labelTooltip?: string;
}

export enum tooltipPosition {
	PreIcon = 1,
	PostIcon = 2,
	Label = 3,
}

interface IFieldState {
	tooltipStatus: boolean | tooltipPosition;
	tooltipTransform: TransformObj | null;
	tooltipContent: string;
}

@inject("app")
export class Field extends React.Component<IFieldProps, IFieldState> {
	private _preIconRef = React.createRef<HTMLDivElement>();
	private _postIconRef = React.createRef<HTMLDivElement>();
	private _labelRef = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _timeoutId: number = null;

	constructor(props: IFieldProps) {
		super(props);
		this.state = {
			tooltipStatus: false,
			tooltipTransform: null,
			tooltipContent: "",
		};
	}

	public static defaultProps: IFieldProps = {
		label: "",
		children: <></>,
	};

	public override componentDidUpdate(prevProps: IFieldProps, prevState: IFieldState) {
		const {tooltipStatus} = this.state;

		if (!prevState.tooltipStatus && tooltipStatus && this._floating.current) {
			let ref: React.RefObject<HTMLDivElement> = this._preIconRef;

			if (tooltipStatus === tooltipPosition.PostIcon) {
				ref = this._postIconRef;
			} else if (tooltipStatus === tooltipPosition.Label) {
				ref = this._labelRef;
			}

			if (ref.current) {
				this.setState({
					tooltipTransform: DomUtils.getFixedFloatingElementPosition(
						ref.current,
						this._floating.current,
						VerticalAlignment.topOuter,
						HorizontalAlignment.center,
						5,
						0,
					),
				});
			}
		}
	}

	private openTooltip = (position: tooltipPosition) => {
		const {tooltips} = this.props;
		const {tooltipStatus} = this.state;

		this._timeoutId = window.setTimeout(() => {
			let text = "";

			switch (position) {
				case tooltipPosition.PreIcon:
					text = tooltips?.preLabelIconTooltip;
					break;
				case tooltipPosition.PostIcon:
					text = tooltips?.postLabelIconTooltip;
					break;
				case tooltipPosition.Label:
					text = tooltips?.labelTooltip;
					break;
				default:
					break;
			}

			if (!tooltipStatus && !!text?.length) {
				this.setState({
					tooltipStatus: position,
					tooltipContent: text,
				});
			}
		}, 300);
	};

	private closetooltip = () => {
		clearTimeout(this._timeoutId);

		if (this.state.tooltipStatus) {
			this.setState({
				tooltipStatus: false,
			});
		}
	};

	private reverseChildren() {
		if (this.props.reverse !== undefined) {
			return this.props.reverse;
		} else {
			// If child is a CheckboxInput -> reverse by default (checkbox will be on the left)
			const child = this.props.children as React.ReactElement<any>;

			return child && (child.type === CheckboxInput || child.type === ToggleSwitch);
		}
	}

	public override render() {
		const {children, label, className, disabled, noWrap, app, icons} = this.props;
		const {tooltipTransform, tooltipStatus, tooltipContent} = this.state;

		let child1: React.ReactNode = (
			<label className="label">
				<span
					ref={this._labelRef}
					onMouseOver={() => this.openTooltip(tooltipPosition.Label)}
					onMouseLeave={this.closetooltip}
				>
					{label}
				</span>
				{icons?.postLabelIcon && (
					<div
						className="fieldIconWrapper hbox alignCenter justifyCenter"
						ref={this._postIconRef}
						onMouseOver={() => this.openTooltip(tooltipPosition.PostIcon)}
						onMouseLeave={this.closetooltip}
					>
						<SVGIcon
							icon={icons.postLabelIcon}
							classNames="fieldIcon"
						/>
					</div>
				)}
			</label>
		);
		let child2: React.ReactNode = children;
		let inputType = FieldDataType[((child2 as React.ReactNode[])?.[0] as React.ReactElement)?.props?.dataType];

		if (this.reverseChildren()) {
			[child1, child2] = [child2, child1];
		}

		if (child1 instanceof ToggleSwitch || child2 instanceof ToggleSwitch) {
			throw new Error("ToggleSwitch in Field not supported. Use ToggleSwitchField instead!");
		}

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: tooltipTransform?.translate,
			left: "auto",
			textAlign: "left",
			maxWidth: 300,
			lineHeight: "140%",
			color: "#F0F0F0",
			fontSize: "12px",
			zIndex: 9999,
		};

		return (
			<div
				className={ReactUtils.cls(`Field ${className || ""}`, {
					disabled: disabled,
					noWrap: noWrap,
				})}
				data-inputtype={inputType}
				data-cy="xyicon-fields"
			>
				{icons?.preLabelIcon && (
					<div
						className="fieldIconWrapper flexCenter"
						ref={this._preIconRef}
						onMouseOver={() => this.openTooltip(tooltipPosition.PreIcon)}
						onMouseLeave={this.closetooltip}
					>
						<SVGIcon
							icon={icons.preLabelIcon}
							classNames="fieldIcon"
						/>
					</div>
				)}
				{!!tooltipStatus && tooltipContent && (
					<DomPortal destination={app.modalContainer}>
						<InfoBubble
							title={tooltipStatus === tooltipPosition.Label && typeof label === "string" && label}
							content={tooltipContent}
							isErrorMessage={false}
							divRef={this._floating}
							style={inlineStyle}
							className={ReactUtils.cls({
								left: tooltipTransform?.horizontal === HorizontalAlignment.right,
								right: tooltipTransform?.horizontal === HorizontalAlignment.left,
							})}
						/>
					</DomPortal>
				)}
				{child1}
				<span className="element">{child2}</span>
			</div>
		);
	}
}

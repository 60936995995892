import {ObjectUtils} from "../data/ObjectUtils";
import {MathUtils} from "../math/MathUtils";
import type {IConvergenceParameters} from "./Convergence";
import {Convergence} from "./Convergence";

interface IBoundedConvergenceParameters extends IConvergenceParameters {
	min: number;
	max: number;
}

export class BoundedConvergence extends Convergence {
	protected static override _defaultConfig: IBoundedConvergenceParameters = {
		...Convergence._defaultConfig,
		min: 0,
		max: 0,
	};
	private _originalMax: number;
	private _originalMin: number;

	constructor(parameters: IBoundedConvergenceParameters) {
		super(ObjectUtils.mergeConfig(BoundedConvergence._defaultConfig, parameters));
		this._originalMin = this._min = parameters.min;
		this._originalMax = this._max = parameters.max;
	}

	public get min() {
		return this._min;
	}

	public get max() {
		return this._max;
	}

	public get originalMin() {
		return this._originalMin;
	}

	public get originalMax() {
		return this._originalMax;
	}

	public setMin(min: number) {
		this._min = min;

		const newStart = MathUtils.clamp(this._start, this._min, this._max);
		const newEnd = MathUtils.clamp(this._end, this._min, this._max);

		super.reset(newStart, newEnd);
	}

	public setMax(max: number) {
		this._max = max;

		const newStart = MathUtils.clamp(this._start, this._min, this._max);
		const newEnd = MathUtils.clamp(this._end, this._min, this._max);

		super.reset(newStart, newEnd);
	}

	public override reset(
		start?: number,
		end?: number,
		min?: number,
		max?: number,
		clampBetweenMinAndMax: boolean = false,
		animationDuration: number = this.originalAnimationDuration,
	) {
		this._min = min != null ? min : this._min;
		this._max = max != null ? max : this._max;
		const newStartCandidate = start != null ? start : this._originalStart;
		const newStart = clampBetweenMinAndMax ? MathUtils.clamp(newStartCandidate, this._min, this._max) : newStartCandidate;
		const newEndCandidate = end != null ? end : this._originalEnd;
		const newEnd = clampBetweenMinAndMax ? MathUtils.clamp(newEndCandidate, this._min, this._max) : newEndCandidate;

		super.reset(newStart, newEnd, animationDuration);
	}

	public isPlaying() {
		return this.value !== this.end;
	}
}

import type {Field} from "../../../../../data/models/field/Field";

export const FieldDetailsTabSettings: {
	[dataType: number]: React.ComponentType<{field: Field; onChange: () => void}>;
} = {};

export const FieldDetailsTabSettingsV5: {
	[dataType: number]: React.ComponentType<{field: Field; onChange: () => void}>;
} = {};

export const FieldDetailsTabFormattingSettings: {
	[dataType: number]: React.ComponentType<{field: Field}>;
} = {};

import * as React from "react";
import {inject, observer} from "mobx-react";
import {TableView} from "../../../abstract/TableView";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {User} from "../../../../../data/models/User";
import type {App} from "../../../../../App";
import {CreateUserPanel} from "./CreateUserPanel";
import {UserDetailsTab} from "./UserDetailsTab";

interface IUsersProps {
	app?: App;
}

@inject("app")
@observer
export class UsersView extends React.Component<IUsersProps> {
	private _headers = [
		{
			id: "fullName",
			title: "Name",
		},
		{
			id: "email",
			title: "Email",
		},
		{
			id: "status",
			title: "Status",
		},
		{
			id: "lastModifiedAt",
			title: "Last Updated",
		},
	];

	private _tableView = React.createRef<TableView<User>>();

	public override componentDidMount() {
		this.props.app.moduleViews[XyiconFeature.User] = this._tableView.current;
	}

	public override render() {
		return (
			<TableView
				ref={this._tableView}
				feature={XyiconFeature.User}
				defaultSort="fullName"
				headers={this._headers}
				create={(onCreated, onCancel, creating) => (
					<CreateUserPanel
						onCancel={onCancel}
						onCreated={onCreated}
						creating={creating}
					/>
				)}
				details={(selected: User[]) => <UserDetailsTab users={selected} />}
			/>
		);
	}
}

import * as React from "react";
import * as Excel from "exceljs";
import {inject, observer} from "mobx-react";
import {WarningWindow} from "../popups/WarningWindow";
import {InfoButton} from "../common/infobutton/InfoButton";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {FileDropperReact} from "../../../interaction/draganddrop/FileDropperReact";
import {IconButton} from "../../../widgets/button/IconButton";
import {SVGIcon} from "../../../widgets/button/SVGIcon";
import {StepIndicator} from "../../../widgets/form/stepindicator/StepIndicator";
import {StepLabel} from "../../../widgets/form/stepindicator/StepLabel";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {App} from "../../../../App";
import {notify} from "../../../../utils/Notify";
import type {AppState} from "../../../../data/state/AppState";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import type {IImportStatus} from "../../../../data/signalr/SignalRListener";
import type {INotificationParams} from "../../../notification/Notification";
import {NotificationType} from "../../../notification/Notification";
import type {INotificationElementParams} from "../../../notification/AppNotifications";
import type {Portfolio} from "../../../../data/models/Portfolio";
import {XyiconFeature, FieldDataType, Permission} from "../../../../generated/api/base";
import type {ImportUpdatesRequest} from "../../../../generated/api/base";

interface IFeatureImportPanelProps {
	feature: XyiconFeature;
	onClose: () => void;
	app?: App;
	appState?: AppState;
}

type ScopeValue = "organization" | "currentPortfolio";

interface IScopeOption {
	label: string;
	value: ScopeValue;
}

interface IFeatureImportPanelState {
	stepIndex: number;
	excelFile: File | null;
	excelColumns: string[];
	excelIdColumn: string | null;
	excelData: string[][];
	fieldMapping: IFieldAdapter[];
	isLoading: boolean;
	selectedScopeValue: ScopeValue;
}

type StepType = "Upload File" | "Match IDs" | "Map Columns to Fields";

const fileSizeLimitInMB = 5;
const rowCountLimit = 10_000;

@inject("app")
@inject("appState")
@observer
export class FeatureImportPanel extends React.Component<IFeatureImportPanelProps, IFeatureImportPanelState> {
	private _steps: StepType[] = ["Upload File", "Match IDs", "Map Columns to Fields"];
	private _scopeOptions: IScopeOption[] = [];
	private _importRequestId: string | null = null;
	private _notification: INotificationElementParams | null = null;

	constructor(props: IFeatureImportPanelProps) {
		super(props);

		const currentPortfolio = this.props.appState.actions.getFeatureItemById<Portfolio>(this.props.appState.portfolioId, XyiconFeature.Portfolio);
		const isPortfolioOrCatalog = [XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog].includes(props.feature);

		if (!isPortfolioOrCatalog) {
			this._scopeOptions.push({
				label: `Current portfolio (${currentPortfolio.name})`,
				value: "currentPortfolio",
			});
		}

		this._scopeOptions.push({
			label: "Organization",
			value: "organization",
		});

		this.state = {
			stepIndex: 0,
			excelFile: null,
			excelColumns: [],
			excelIdColumn: null,
			excelData: [],
			fieldMapping: [],
			isLoading: false,
			selectedScopeValue: isPortfolioOrCatalog ? "organization" : "currentPortfolio",
		};
	}

	private getIgnoreField(): IFieldAdapter {
		return {
			refId: "",
			name: "(Ignore this column)",
			dataType: FieldDataType.SingleLineText,
			feature: this.props.feature,
			default: true, // aka hardcoded field
			displayOnLinks: false,
		};
	}

	private getStepDetails = (step: StepType) => {
		switch (step) {
			case "Upload File":
				return "Choose an Excel file to upload. The file should not exceed 5MB in size.";
			case "Match IDs":
				return `Select the unique ID column in Excel that matches the ${featureTitles[this.props.feature]} ID field.`;
			case "Map Columns to Fields":
				return `Map columns from Excel to fields in the ${featureTitles[this.props.feature]} Module. Ignored columns will not be imported.`;
			default:
				return "";
		}
	};

	private isThereAValidFieldMapping() {
		const ignoreField = this.getIgnoreField();

		return this.state.fieldMapping.slice(1).some((f) => f && f !== ignoreField);
	}

	private isNextButtonEnabled(): boolean {
		switch (this._steps[this.state.stepIndex]) {
			case "Upload File":
				return !!this.state.excelFile;
			case "Match IDs":
				return !!this.state.excelIdColumn;
			case "Map Columns to Fields":
				return this.isThereAValidFieldMapping() && !this.state.isLoading;
			default:
				return false;
		}
	}

	private getFilteredDataForUpload() {
		const headerRow = this.state.excelColumns.map((column: string, index: number) => this.state.fieldMapping[index]?.refId);
		const filteredHeaderRow: string[] = [];

		const indicesOfColumnsToIgnore: number[] = [];

		for (let i = 0; i < headerRow.length; ++i) {
			if (headerRow[i]) {
				if (headerRow[i].includes("/refId")) {
					filteredHeaderRow.push("id");
				} else {
					const fieldName = headerRow[i].split("/");

					filteredHeaderRow.push(fieldName[fieldName.length - 1]);
				}
			} else {
				indicesOfColumnsToIgnore.push(i);
			}
		}

		const filteredData: string[][] = [];

		for (const row of this.state.excelData) {
			const filteredRow: string[] = [];

			for (let i = 0; i < row.length; ++i) {
				if (!indicesOfColumnsToIgnore.includes(i)) {
					const field = this.props.appState.actions.getFieldByRefId(headerRow[i]);
					const fileNameSplit = this.state.excelFile.name.split(".");
					const extension = fileNameSplit[fileNameSplit.length - 1] as "xlsx" | "csv";
					let value = row[i];

					if (field?.dataType === FieldDataType.DateTime) {
						if (extension === "csv" && (row[i] === "undefined" || row[i] === "0")) {
							value = null;
						}

						if (extension === "xlsx" && row[i]) {
							const valueAsDate = new Date(row[i]);

							value = valueAsDate.toString() !== "Invalid Date" ? valueAsDate.toISOString() : "Invalid Date";
						}
					}
					filteredRow.push(value);
				}
			}
			filteredData.push(filteredRow);
		}

		return {
			filteredHeaderRow,
			filteredData,
		};
	}

	private onBackClick = () => {
		if (this.state.stepIndex > 0) {
			this.setState({
				stepIndex: this.state.stepIndex - 1,
			});
		} else {
			this.props.onClose();
		}
	};

	private onNextClick = async () => {
		if (this.state.stepIndex < this._steps.length - 1) {
			this.setState({
				stepIndex: this.state.stepIndex + 1,
			});
		} else if (this.state.stepIndex === this._steps.length - 1) {
			// Upload data

			const {filteredHeaderRow, filteredData} = this.getFilteredDataForUpload();

			const data: ImportUpdatesRequest = {
				portfolioID: this.props.appState.portfolioId,
				headerRow: filteredHeaderRow,
				data: filteredData,
				feature: this.props.feature,
				isOrganizationWideImport: this.state.selectedScopeValue === "organization",
			};

			this.setState({
				isLoading: true,
			});
			const {result, error} = await this.props.app.transport.requestForOrganization<{requestID: string}>({
				url: "import",
				method: XHRLoader.METHOD_POST,
				params: data,
			});

			this._importRequestId = result.requestID;
			this.dataImportStatusReceivedSignal.add(this.onDataImportStatusUpdate);
			this.setState({
				isLoading: false,
			});

			this.props.onClose();
		}
	};

	private get dataImportStatusReceivedSignal() {
		return this.props.app.transport.signalR.listener.signals.importStatusReceived;
	}

	private onDataImportStatusUpdate = (data: IImportStatus) => {
		if (this._importRequestId === data.requestID) {
			this._notification?.onClose();

			if (data.isCompleted) {
				this.dataImportStatusReceivedSignal.remove(this.onDataImportStatusUpdate);

				const notificationParams: INotificationParams = {
					type: NotificationType.Success,
					title: "",
					lifeTime: Infinity,
				};

				if (data.errorRows > 0) {
					if (data.errorRows === data.totalRows) {
						if (data.validRows > 0) {
							console.log("The numbers don't add up... This shouldn't have happened.");
						}

						notificationParams.type = NotificationType.Error;
						notificationParams.title = "Update unsuccessful!";
						notificationParams.description = "No records were updated due to errors.";
					} else {
						notificationParams.type = NotificationType.Warning;
						notificationParams.title = `${data.validRows} of ${data.totalRows} records updated successfully!`;
						notificationParams.description = `${data.errorRows} records were not updated due to errors`;
					}

					notificationParams.buttonLabel = "Download Error Report";
					notificationParams.onActionButtonClick = () => {
						const errorFilePath = this.props.app.transport.getFullPathFromServer(`uploads/imports/errors/${data.requestID}.csv`);

						FileUtils.downloadFileFromUrl(errorFilePath, `${data.requestID}.csv`);
					};
				} else {
					if (data.validRows !== data.totalRows) {
						console.warn("ValidRows != TotalRows. This shouldn't have happened.");
					}
					notificationParams.type = NotificationType.Success;
					notificationParams.title = `All ${data.validRows} records updated successfully!`;
					notificationParams.lifeTime = 10000;
				}

				this._notification = notify(this.props.appState.app.notificationContainer, notificationParams);
			} else {
				this._notification = notify(this.props.appState.app.notificationContainer, {
					type: NotificationType.Message,
					title: `Update in progress! ${data.processedRows} of ${data.totalRows} completed`,
					description: `${data.errorRows} records failed to update due to errors`,
					lifeTime: Infinity,
				});
			}
		}
	};

	private addCommaToNumbers = (num: number) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	private async getWorkSheetData(file: File): Promise<string[][]> {
		try {
			const extension = FileUtils.getExtension(file.name);

			const workBook = new Excel.Workbook();

			if (extension === "csv") {
				const csv = await FileUtils.readAsText(file);
				const rows = StringUtils.CSVToArray(csv).map((r) => r.map((v) => `${v}`));
				const filteredRows = rows.filter((r) => r.join().length > 0);

				return filteredRows;
			} else {
				const arrayBuffer = await FileUtils.readAsArrayBuffer(file);
				const xlsx = await workBook.xlsx.load(arrayBuffer);
				const workSheets = xlsx?.worksheets;

				if (xlsx?.worksheets?.length > 0) {
					const workSheet = workSheets[0];

					const rowCount = workSheet.rowCount;

					if (rowCount > rowCountLimit) {
						const rowCountLimitString = this.addCommaToNumbers(rowCountLimit);

						throw `Row Count Exceeds Limit. To update more than ${rowCountLimitString} rows, create multiple Excel files of less than ${rowCountLimitString} rows and import each file separately.`;
					}

					const rows: string[][] = [];

					const columnLetters = workSheet.columns.map((col) => col.letter);

					workSheet.eachRow((row: Excel.Row) => {
						const cells: string[] = [];
						let index = 0;

						columnLetters.forEach((letter) => {
							const cell = row.model.cells[index];
							const cellLetter = (cell?.address as unknown as string)?.replace(/\d/g, "");
							const propName = cell?.hyperlink ? "text" : "value";

							if (cellLetter === letter) {
								if (cell?.[propName] !== undefined) {
									cells.push(`${cell[propName]}`);
								} else {
									cells.push(undefined);
								}
								index++;
							} else {
								cells.push(undefined);
							}
						});
						rows.push(cells);
					});
					return rows;
				} else {
					throw "Sorry, your file cannot be opened: it seems this excel file doesn't have any worksheets in it";
				}
			}
		} catch (error: unknown) {
			console.log(error);
			await WarningWindow.open(`${error}`, "Cannot Upload File");
		}
	}

	private onFileInputChange = async (files: FileList) => {
		const ignoreField = this.getIgnoreField();
		const newFile = files[0];

		if (newFile && newFile !== this.state.excelFile) {
			if (newFile.size > 1024 * 1024 * fileSizeLimitInMB) {
				await WarningWindow.open(`Sorry, you can't use excel files that are larger than ${fileSizeLimitInMB} MB`, "Error");
			} else {
				const workSheetData = await this.getWorkSheetData(newFile);

				if (workSheetData) {
					const rowCount = workSheetData.length;

					if (rowCount > rowCountLimit) {
						const rowCountLimitString = this.addCommaToNumbers(rowCountLimit);

						await WarningWindow.open(
							`Row Count Exceeds Limit. To update more than ${rowCountLimitString} rows, create multiple Excel files of less than ${rowCountLimitString} rows and import each file separately.`,
							"Cannot Upload File",
						);
					} else if (rowCount === 0 || workSheetData[0]?.length === 0) {
						await WarningWindow.open("Sorry, this file doesn't seem to contain valid data", "Error");
					} else {
						const columns: string[] = workSheetData[0].filter((column) => !!column);

						const featureIdColumn = columns.find((column) => {
							const simplifiedColumn = column?.replace(/ /g, "").toLowerCase();

							return simplifiedColumn === `${featureTitles[this.props.feature]}id`.toLowerCase() || simplifiedColumn === "id";
						});

						const ownFields = this._updatableFields;

						let fieldMapping: IFieldAdapter[] = [];

						for (const column of columns) {
							const columnAsLowerCase = column?.toLowerCase();
							const ownField = ownFields.find((f) => f.name.toLowerCase() === columnAsLowerCase);

							fieldMapping.push(ownField || ignoreField);
						}

						fieldMapping = this.addIdToFieldMapping(fieldMapping, columns, featureIdColumn);

						this.setState({
							excelFile: newFile,
							excelColumns: columns,
							excelIdColumn: featureIdColumn,
							excelData: workSheetData.slice(1),
							fieldMapping: fieldMapping,
						});
					}
				}
			}
		}
	};

	private addIdToFieldMapping(fieldMapping: IFieldAdapter[], columns: string[], featureIdColumn: string): IFieldAdapter[] {
		const idFieldName = `${featureTitles[this.props.feature]} id`.toLowerCase(); // eg.: "xyicon id"
		const indexOfIdColumn = columns.findIndex((v) => v === featureIdColumn);

		if (indexOfIdColumn > -1) {
			const newFieldMapping = [...fieldMapping];

			newFieldMapping[indexOfIdColumn] = this._updatableFields.find((f) => f.name.toLowerCase() === idFieldName);

			return newFieldMapping;
		}

		return fieldMapping;
	}

	private onFileClear = () => {
		this.setState({
			excelFile: null,
			excelIdColumn: null,
			excelColumns: [],
			excelData: [],
			fieldMapping: [],
		});
	};

	private onExcelIdColumnChange = (newExcelIdColumn: string) => {
		const newFieldMapping = this.addIdToFieldMapping(this.state.fieldMapping, this.state.excelColumns, newExcelIdColumn);

		this.setState({
			excelIdColumn: newExcelIdColumn,
			fieldMapping: newFieldMapping,
		});
	};

	private hasUserPermission = (field: IFieldAdapter) => {
		const {feature, appState} = this.props;
		const {user, actions} = appState;

		switch (feature) {
			case XyiconFeature.Portfolio:
				// only the users with admin rights can import portfolios
				return user?.isAdmin;
			case XyiconFeature.XyiconCatalog:
				return user?.getOrganizationPermission(XyiconFeature.XyiconCatalog) >= Permission.Update;
			case XyiconFeature.Space:
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return actions.getFieldPermission(field) >= Permission.Update;
		}
	};

	private get _updatableFields() {
		const defaultFieldsToIgnore = ["/type", "/model", "/lastModifiedBy", "/lastModifiedAt", "/icon"];

		const {feature, appState} = this.props;
		const {actions} = appState;

		return actions
			.getFieldsByFeature(feature, false)
			.filter(
				(field: IFieldAdapter) =>
					field.refId.includes("/refId") ||
					(!field.hasFormula &&
						this.hasUserPermission(field) &&
						defaultFieldsToIgnore.every((fieldNameToIgnore) => !field.refId.includes(fieldNameToIgnore))),
			);
	}

	private get _updatableFieldsWithoutId() {
		return this._updatableFields.filter((f) => !f.refId.includes("/refId"));
	}

	private getFieldRows() {
		const {excelColumns} = this.state;
		const ignoreField = this.getIgnoreField();

		const ownFields = this._updatableFieldsWithoutId;
		const options = [ignoreField, ...ownFields];

		return excelColumns.map((column: string, index: number) => {
			if (column === this.state.excelIdColumn) {
				return null;
			} else {
				const selected = this.state.fieldMapping[index] || ignoreField;

				return (
					<div
						className="hbox alignCenter row"
						key={index}
					>
						<div>{column}</div>
						<SVGIcon
							icon="arrow"
							classNames="arrow"
						/>
						<SelectInput
							options={options}
							selected={selected}
							render={(f) => f.name}
							onChange={(newSelected: IFieldAdapter) => {
								const newFieldMapping = [...this.state.fieldMapping];

								newFieldMapping[index] = newSelected;
								this.setState({
									fieldMapping: newFieldMapping,
								});
							}}
						/>
					</div>
				);
			}
		});
	}

	private onScopeChange = (o: IScopeOption) => {
		this.setState({
			selectedScopeValue: o.value,
		});
	};

	private getForm() {
		switch (this._steps[this.state.stepIndex]) {
			case "Upload File":
				return (
					<>
						<div
							className="hbox flexCenter"
							style={{marginBottom: "10px"}}
						>
							<p>Scope</p>
							<InfoButton bubbleText={`Choose the scope of your ${featureTitles[this.props.feature].toLowerCase()} data import.`} />
							<SelectInput
								options={this._scopeOptions}
								render={(o) => o.label}
								selected={this._scopeOptions.find((o) => o.value === this.state.selectedScopeValue)}
								onFocusLossForceBlur={true}
								onChange={this.onScopeChange}
								disabled={!this.props.appState.user?.isAdmin}
							/>
						</div>
						<FileDropperReact
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							multiple={false}
							onFileInputChange={this.onFileInputChange}
							purpose="Drag and drop file (.csv or .xlsx) or click to browse."
						/>
						{this.state.excelFile && (
							<div className="excelFile hbox alignCenter">
								<SVGIcon icon="excel" />
								<div className="vbox">
									{this.state.excelFile.name}
									<div className="darkSilverText">{FileUtils.getSizeLabelOfFile(this.state.excelFile)}</div>
								</div>
								<IconButton
									icon="delete"
									onClick={this.onFileClear}
									className="marginLeftAuto"
								/>
							</div>
						)}
					</>
				);
			case "Match IDs":
				return (
					<>
						<div className="featureFieldChooserWrapper vbox alignCenter">
							<div className="hbox alignCenter row">
								<strong>Excel Column</strong>
								<span className="empty"></span>
								<strong>{featureTitles[this.props.feature]} Field</strong>
							</div>
							<div className="hbox alignCenter row">
								<SelectInput
									options={this.state.excelColumns}
									selected={this.state.excelIdColumn}
									onChange={this.onExcelIdColumnChange}
								/>
								<SVGIcon
									icon="arrow"
									classNames="arrow"
								/>
								<div>{featureTitles[this.props.feature]} ID</div>
							</div>
						</div>
					</>
				);
			case "Map Columns to Fields":
				return (
					<div className="featureFieldChooserWrapper vbox alignCenter">
						<div className="hbox alignCenter row">
							<strong>Excel Columns</strong>
							<span className="empty"></span>
							<strong>{featureTitles[this.props.feature]} Fields</strong>
						</div>
						{this.getFieldRows()}
					</div>
				);
		}
	}

	public override render() {
		const nextLabel = this.state.stepIndex < this._steps.length - 1 ? "Next" : this.state.isLoading ? "Updating..." : "Start Update";
		const stepDetails = this._steps.map(this.getStepDetails);

		return (
			<div className="FeatureImportPanel SidePanel overflowYAuto">
				<div className="heading createBox hbox">
					<h4 className="detailsTitle">{`Import ${featureTitles[this.props.feature]} Updates from Excel`}</h4>
					<IconButton
						icon="close"
						title="Close Panel"
						className="close"
						onClick={this.props.onClose}
					/>
				</div>
				<StepLabel
					nextLabel={nextLabel}
					onBackClick={this.onBackClick}
					onNextClick={this.onNextClick}
					isNextButtonEnabled={this.isNextButtonEnabled()}
					stepIndex={this.state.stepIndex}
					stepLabels={stepDetails}
				/>
				<StepIndicator
					steps={this._steps}
					currentStepIndex={this.state.stepIndex}
				/>
				<div className="content">{this.getForm()}</div>
			</div>
		);
	}
}

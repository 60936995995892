import {useEffect} from "react";
import type {ICaptionSettings, IFontStyleSettings} from "../../../../../data/models/ViewUtils";
import type {Color} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import {HTMLUtils} from "../../../../../utils/HTML/HTMLUtils";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {VerticalAlignment, HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {FocusLoss} from "../../../../../utils/ui/focus/FocusLoss";
import type {SupportedFontName} from "../../../../modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import {TextGroupManager} from "../../../../modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {FontStyleContainerV5} from "../../../text/FontStyleContainerV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import FontIcon from "../../../icons/font-icon.svg?react";
import {FloatingCaptionConfigurator} from "./FloatingCaptionConfigurator.styled";

interface IFloatingCaptionConfiguratorProps {
	divRef: React.MutableRefObject<HTMLDivElement>;
	transform: TransformObj | null;
	captionLabel: string;
	fieldRefId: string;
	onCloseClick: () => void;
	spaceViewRenderer: SpaceViewRenderer;
	captionSettings: ICaptionSettings;
}

export function FloatingCaptionConfiguratorV5(props: IFloatingCaptionConfiguratorProps) {
	const individualCaptionStyles = props.captionSettings.individualCaptionStyles;

	const fontStyleSettings: IFontStyleSettings = {
		isBold: individualCaptionStyles[props.fieldRefId]?.isBold ?? props.captionSettings.isBold,
		isItalic: individualCaptionStyles[props.fieldRefId]?.isItalic ?? props.captionSettings.isItalic,
		isUnderlined: individualCaptionStyles[props.fieldRefId]?.isUnderlined ?? props.captionSettings.isUnderlined,
	};
	const {captionSettings} = props;
	const {eyeDropperProps} = props.spaceViewRenderer;

	const createIndividualStylesIfDoesntExist = () => {
		if (!individualCaptionStyles[props.fieldRefId]) {
			individualCaptionStyles[props.fieldRefId] = {};
		}
	};

	const redrawCaptions = () => {
		props.spaceViewRenderer.actions.updateSpaceEditorCaptions(XyiconFeature.Boundary);
	};

	useEffect(() => {
		const onBlur = (event: MouseEvent) => {
			if (!HTMLUtils.isDescendant(props.spaceViewRenderer.transport.appState.app.modalContainer, event.target as HTMLElement)) {
				props.onCloseClick();
			}
		};

		FocusLoss.listen(props.divRef.current, onBlur);

		return () => {
			FocusLoss.stopListen(props.divRef.current, onBlur);
		};
	}, [props]);

	return (
		<FloatingCaptionConfigurator
			ref={props.divRef}
			style={{transform: props.transform?.translate}}
			className={ReactUtils.cls("FloatingCaptionConfigurator vbox", {invisible: !props.transform})}
		>
			<div className="title hbox">
				<div>
					Change caption settings for <strong>{props.captionLabel}</strong>
				</div>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={props.onCloseClick}
				/>
			</div>
			<div className="hbox alignCenter fontSettings">
				<FontStyleContainerV5
					fontStyleSettings={fontStyleSettings}
					onIsBoldChange={(newValue: boolean) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].isBold = newValue;
						redrawCaptions();
					}}
					onIsItalicChange={(newValue: boolean) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].isItalic = newValue;
						redrawCaptions();
					}}
					onIsUnderlinedChange={(newValue: boolean) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].isUnderlined = newValue;
						redrawCaptions();
					}}
				/>
				<SelectInputV5
					disabled={false}
					selected={individualCaptionStyles[props.fieldRefId]?.fontSize ?? captionSettings.fontSize}
					onChange={(newValue: number) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].fontSize = newValue;
						redrawCaptions();
					}}
					className="SelectInput"
					dropdownIcon={false}
					options={Constants.SIZE.FONT.values}
					sort={false}
					searchBarMode="always off"
				/>
				<SelectInputV5
					selected={individualCaptionStyles[props.fieldRefId]?.fontFamily ?? captionSettings.fontFamily}
					onChange={(newValue: SupportedFontName) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].fontFamily = newValue;
						redrawCaptions();
					}}
					className="SelectInput"
					dropdownIcon={false}
					options={TextGroupManager.supportedFontNames}
					searchBarMode="always off"
				/>
				<ColorSelectorV5
					title="Font Color"
					color={individualCaptionStyles[props.fieldRefId]?.fontColor ?? captionSettings.fontColor}
					onColorChange={(newColor: Color) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].fontColor = newColor;
						redrawCaptions();
					}}
					eyeDropperProps={eyeDropperProps}
					verticalAlignment={VerticalAlignment.topOuter}
					isTransparencyEnabled={true}
					IconComponent={FontIcon}
				/>
				<ColorSelectorV5
					title="Fill Color"
					color={individualCaptionStyles[props.fieldRefId]?.backgroundColor ?? captionSettings.backgroundColor}
					onColorChange={(newColor: Color) => {
						createIndividualStylesIfDoesntExist();
						individualCaptionStyles[props.fieldRefId].backgroundColor = newColor;
						redrawCaptions();
					}}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.center}
					verticalAlignment={VerticalAlignment.topOuter}
					isTransparencyEnabled={true}
				/>
			</div>
		</FloatingCaptionConfigurator>
	);
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {runInAction} from "mobx";
import type {MoveEvent, Sortable} from "react-sortablejs";
import {ReactSortable} from "react-sortablejs";
import styled from "styled-components";
import {ViewPreferenceCategory} from "../../../../generated/api/base";
import type {IViewFolder, ViewFolderStructure} from "../../../../data/models/ViewUtils";
import {cleanViewFolderStructureFromAdditionalProps, rootFolderId} from "../../../../data/models/ViewUtils";
import type {AppState} from "../../../../data/state/AppState";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {View} from "../../../../data/models/View";
import {ViewFolderClassName, ViewFolderV5} from "./ViewFolderV5";
import {ViewItemV5} from "./ViewItemV5";
import type {WorkspaceViewType} from "./WorkspaceViewCommon";

interface IViewFolderStructureProps {
	appState?: AppState;
	type: WorkspaceViewType;
	level: number;
	viewFolder: IViewFolder;
	onViewClick: (view: View) => void;
	saveViewFolderStructureToDatabase: () => void;
	removeViewElementFromStructure: (viewElementId: string) => boolean;
	forceUpdateParent: () => void;
	onShareClick: (viewItem: View | IViewFolder) => void;
	onAddClick?: (viewFolderId: string) => void;
}

const indentationSize = 16;
const dragTolerance = 4;

@inject("appState")
@observer
export class ViewFolderStructureReactV5 extends React.Component<IViewFolderStructureProps> {
	// TODO: Probably when this returns with true, sortable.js calls event.preventDefault,
	// So it's not possible right now to change the text cursor (caret), or select the text
	// within the input element with draggable parents...
	private filterDragElement(this: Sortable, event: Event | TouchEvent, target: HTMLElement, sortable: Sortable): boolean {
		const element = event.target as HTMLElement;
		const elementName = element.nodeName.toLowerCase();

		return elementName === "input" || elementName === "textarea";
	}

	private setViewFolderStructure = (newViewFolderStructure: ViewFolderStructure) => {
		runInAction(() => {
			if (
				!ObjectUtils.compare(
					cleanViewFolderStructureFromAdditionalProps(this.props.viewFolder.children),
					cleanViewFolderStructureFromAdditionalProps(newViewFolderStructure),
				)
			) {
				this.props.viewFolder.children.length = 0;
				this.props.viewFolder.children.push(...newViewFolderStructure);

				return this.props.saveViewFolderStructureToDatabase();
			}
		});
	};

	private getMarginLeftForLevel(level: number) {
		return `${indentationSize * Math.sign(level)}px`;
	}

	public override render() {
		const {viewFolder, saveViewFolderStructureToDatabase, level} = this.props;

		if (viewFolder.children.length === 0 && viewFolder.id === "root") {
			return null;
		}

		const children = viewFolder.children;

		return (
			<div>
				{viewFolder.id !== rootFolderId && (
					<ViewFolderV5
						data={viewFolder}
						onShareClick={this.props.onShareClick}
						saveViewFolderStructureToDatabase={saveViewFolderStructureToDatabase}
						removeViewElementFromStructure={this.props.removeViewElementFromStructure}
						onAddClick={this.props.onAddClick}
					/>
				)}
				<ReactSortable
					className={!(viewFolder.isOpen && viewFolder.children.length === 0) ? "ReactSortable" : ""}
					list={children}
					setList={this.setViewFolderStructure}
					group={`${this.props.type}_nested`}
					animation={150}
					fallbackOnBody={true}
					forceFallback={true}
					swapThreshold={0.45}
					fallbackTolerance={dragTolerance}
					touchStartThreshold={dragTolerance}
					preventOnFilter={false}
					onMove={(event: MoveEvent) => {
						//
						// LIMIT DEPTH
						//
						const isFolder = event.dragged.children?.[0].classList.contains(ViewFolderClassName);
						const intoFolder = event.to.style.marginLeft !== this.getMarginLeftForLevel(0);

						if (intoFolder && isFolder) {
							return false;
						}

						return true;
					}}
					delay={500}
					delayOnTouchOnly={true}
					style={{
						paddingLeft: this.getMarginLeftForLevel(level),
						marginBottom: "0px", // to be able to drop an item below the folder (and not inside)
						display: "flex",
						flexDirection: "column",
						gap: "0px",
						paddingBottom:
							level === 0 && children.length > 1 && children[children.length - 1].category === ViewPreferenceCategory.Folder ? "8px" : "0",
					}}
					dragClass="dragged"
					filter={this.filterDragElement}
					disabled={level > 1}
				>
					{viewFolder.isOpen && viewFolder.children.length === 0 ? (
						<ViewContainerStyled>
							<span>There are no views in the folder</span>
						</ViewContainerStyled>
					) : (
						viewFolder.isOpen &&
						viewFolder.children.length > 0 &&
						viewFolder.children.map((v) => {
							if (v.category === ViewPreferenceCategory.View) {
								const viewMaybe = this.props.appState.actions.getViewById(v.id);

								if (!viewMaybe) {
									console.warn(`Current user doesn't have access to view with id ${v.id}, or view doesn't exist anymore`);

									return <></>;
								}

								return (
									<ViewItemV5
										key={v.id}
										view={viewMaybe}
										type={this.props.type}
										onViewClick={this.props.onViewClick}
										onShareClick={this.props.onShareClick}
										forceUpdateParent={this.props.forceUpdateParent}
										level={level}
									/>
								);
							} else {
								return (
									<ViewFolderStructureReactV5
										key={v.id}
										viewFolder={v}
										level={level + 1}
										saveViewFolderStructureToDatabase={saveViewFolderStructureToDatabase}
										type={this.props.type}
										removeViewElementFromStructure={this.props.removeViewElementFromStructure}
										onAddClick={this.props.onAddClick}
										onViewClick={this.props.onViewClick}
										onShareClick={this.props.onShareClick}
										forceUpdateParent={this.props.forceUpdateParent}
									/>
								);
							}
						})
					)}
				</ReactSortable>
			</div>
		);
	}
}

const ViewContainerStyled = styled.span`
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-size: 13px;
	line-height: 16px;
	margin-left: 24px;
`;

import {Vector2} from "three";
import {ItemSelectionBoxName} from "../elements3d/ItemSelectionBox";
import type {Markup3D} from "../elements3d/markups/abstract/Markup3D";
import {MarkupTextRotationName} from "../elements3d/markups/abstract/Markup3D";
import type {MarkupTextManager} from "../managers/MSDF/MarkupTextManager";
import type {EditableSpaceItem} from "../elements3d/EditableSpaceItem";
import type {SpaceItemController} from "../managers/spaceitems/SpaceItemController";
import type {Markup} from "../../../../../../data/models/Markup";
import type {PointDouble} from "../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../utils/math/MathUtils";

interface IItemController {
	startRotatingSelectedItems: (pivot?: PointDouble) => void;
	rotateSelectedItems: (deltaAngle: number, pivot?: PointDouble) => void;
	stopRotatingSelectedItems: () => void;
	currentlyEditedItem?: EditableSpaceItem;
	markupTextManager?: MarkupTextManager;
}

export class RotationChanger {
	private _itemController: IItemController;
	private _isRotating: boolean;
	private _pivot: Vector2;
	private _pivotToCursor: Vector2;
	private _type: string = "";
	private _startAngle: number;
	private _currentAngle: number;
	private _textOrientationOnStart: number;

	constructor(itemController: IItemController) {
		this._itemController = itemController;

		this._isRotating = false;
		this._startAngle = this._currentAngle = null;
		this._pivot = new Vector2();
		this._pivotToCursor = new Vector2();
	}

	private get _rotateAroundOnePoint(): boolean {
		return this._type === ItemSelectionBoxName;
	}

	private get _currentlyEditedMarkup(): Markup3D {
		return this._itemController.currentlyEditedItem as Markup3D;
	}

	/**
	 *
	 * @param cursorX
	 * @param cursorY
	 * @param pivotX
	 * @param pivotY
	 * @param rotateAroundOnePoint if true, we rotate all the selected objects around the same pivot point. Otherwise, they're rotated around their own center
	 */
	public startRotating(cursorX: number, cursorY: number, pivotX: number, pivotY: number, type: string = "") {
		this._type = type;
		this.setPivot(pivotX, pivotY);
		this.setStartXY(cursorX, cursorY);

		if (this._type === MarkupTextRotationName) {
			this._textOrientationOnStart = (this._currentlyEditedMarkup.modelData as Markup).textOrientation;
		} else {
			this._itemController.startRotatingSelectedItems(this._rotateAroundOnePoint ? this._pivot : undefined);
		}
	}

	public updateXY(cursorX: number, cursorY: number) {
		this._pivotToCursor.set(cursorX - this._pivot.x, cursorY - this._pivot.y);
		this._currentAngle = this._pivotToCursor.angle();

		const deltaAngle = this._currentAngle - this._startAngle;

		if (this._type === MarkupTextRotationName) {
			const markup = this._currentlyEditedMarkup.modelData as Markup;
			const newOrientation = MathUtils.calculateNewOrientation(this._textOrientationOnStart, deltaAngle);

			markup.setTextOrientation(newOrientation);
			this._currentlyEditedMarkup.onTextRotated();

			this._itemController.markupTextManager.updateTextTransformations();
			(this._itemController as SpaceItemController).rotationIconManager.updateTransformations();
		} else {
			this._itemController.rotateSelectedItems(deltaAngle, this._rotateAroundOnePoint ? this._pivot : undefined);
		}
	}

	private setStartXY(x: number, y: number) {
		this._pivotToCursor.set(x - this._pivot.x, y - this._pivot.y);
		this._startAngle = this._pivotToCursor.angle();
	}

	private setPivot(x: number, y: number) {
		this._isRotating = true;
		this._pivot.set(x, y);
	}

	public get isRotating() {
		return this._isRotating;
	}

	public stopRotating() {
		if (this._isRotating) {
			if (this._type === MarkupTextRotationName) {
			} else {
				this._itemController.stopRotatingSelectedItems();
				this.reset();
			}
		}
	}

	private reset() {
		this._isRotating = false;
		this._startAngle = this._currentAngle = null;
		this._pivot.set(0, 0);
		this._pivotToCursor.set(0, 0);
		this._type = "";
	}
}

import type {MouseEvent} from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import SearchIcon from "../../icons/search.svg?react";
import type {ITextInputProps} from "../../../widgets/input/text/TextInput";
import XmarkIcon from "../../icons/xmark.svg?react";
import {useClickOutside} from "../../utils";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import {DropDownTitleStyle, PillsStyle} from "../../sharing/SharingPanel.styled";
import ChevronDownIcon from "../../icons/chevron-down.svg?react";
import {Permission} from "../../../../generated/api/base";
import {colorPalette} from "../../styles/colorPalette";
import {fontSize, fontWeight, radius} from "../../styles/styles";

interface ISearchFieldV5Props extends Partial<ITextInputProps> {
	value: string;
	placeholder?: string;
	showCancelIcon?: boolean;
	showClearIcon?: boolean;
	selectedItems?: any[];
	getItems?: (items: any[]) => void;
	getPermissionType?: (permission: Permission) => void;

	isDropdown?: boolean;
}

export const SelectSearchFieldV5 = (props: ISearchFieldV5Props) => {
	const {placeholder = "Find", value, selectedItems, getItems, isDropdown} = props;
	const [extended, setExtended] = useState<boolean>(false);
	const [itemsSelected, setItemsSelected] = useState(selectedItems);

	const deleteSearch = (ev: MouseEvent<SVGSVGElement>) => {
		ev.stopPropagation();
		props.onInput?.("");
	};

	const onOpen = () => setExtended(true);
	const onClose = () => setExtended(false);

	const onSearchFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
		// e.stopPropagation();
		onOpen();
	};

	const removeSelectedItem = (id: string) => {
		setItemsSelected(itemsSelected.filter((item) => item.id !== id));
		props.getItems(itemsSelected);
	};

	const onSetPermission = (value: Permission) => {
		props.getPermissionType(value);
	};

	useEffect(() => {
		setItemsSelected(selectedItems);
	}, [selectedItems]);

	useEffect(() => {
		getItems(itemsSelected);
	}, [getItems, itemsSelected]);

	useClickOutside([], onClose);

	return (
		<SelectSearchFieldStyled
			className="SearchField"
			onClick={onSearchFieldClick}
			$multiselectDropdown={true}
		>
			<SearchIcon />
			<SelectedOptionStyled>
				{itemsSelected.map((item) => (
					<PillsStyle
						className="selectedItem"
						key={item.id}
					>
						<span>{item.itemName}</span>
						<span onClick={() => removeSelectedItem(item.id)}>&times;</span>
					</PillsStyle>
				))}
			</SelectedOptionStyled>
			<TextInputV5
				{...props}
				placeholder={placeholder || "Find"}
			/>
			{(props.showClearIcon || value?.length > 0) && (
				<XmarkIcon
					className="cancel"
					onClick={deleteSearch}
				/>
			)}
			{!isDropdown ? (
				""
			) : (
				<DropdownButtonV5
					className="saveDropdown "
					options={[
						{
							label: "Can Edit",
							onClick: () => onSetPermission(Permission.Update),
						},
						{
							label: "Can View",
							onClick: () => onSetPermission(Permission.View),
						},
					]}
					horizontalAlignment={HorizontalAlignment.left}
					button={
						<DropDownTitleStyle>
							can edit
							<ChevronDownIcon />
						</DropDownTitleStyle>
					}
				/>
			)}
		</SelectSearchFieldStyled>
	);
};

const SelectSearchFieldStyled = styled.div<{$multiselectDropdown?: boolean}>`
	width: 200px;
	border-radius: ${radius.sm};
	background-color: ${colorPalette.white};
	border: 1px solid ${colorPalette.gray.c300};
	display: flex;
	align-items: center;
	position: relative;
	font-size: 14px;

	input {
		height: 32px;
		border: none;
		background-color: transparent;
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.md};
		position: relative;
		padding: 0;
		width: 100%;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: ${colorPalette.gray.c950};
			opacity: 1;
		}
	}

	svg {
		width: 32px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
		left: 8px;

		&.cancel {
			left: auto;
			right: 8px;
			cursor: pointer;
		}

		path {
			width: 12px;
			height: 12px;
		}
	}

	.saveDropdown {
		width: 90px;

		svg {
			margin: 0;
			width: 16px;
		}
	}
`;

const SelectedOptionStyled = styled.div`
	top: 0px;
	left: 15px;
`;

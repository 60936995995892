import styled from "styled-components";
import {Observer} from "mobx-react";
import type {SpaceFile} from "../../../../data/models/SpaceFile";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import ChevronDownIcon from "../../icons/chevron-down.svg?react";
import type {Space} from "../../../../data/models/Space";
import {ELLIPSIS} from "../../styles/styles";

interface ISpaceVersionSelectorV5 {
	readonly space: Space;
	readonly onVersionClick: (spaceFile: SpaceFile) => void;
}

export const SpaceVersionSelectorV5 = (props: ISpaceVersionSelectorV5) => {
	const {space, onVersionClick} = props;

	return (
		<Observer>
			{() => {
				return (
					<DropdownButtonV5
						button={
							<VersionSelector title={space.versionName}>
								<VersionName>{space.versionName}</VersionName>
								<ChevronDownIcon
									width="16"
									height="16"
								/>
							</VersionSelector>
						}
						options={[
							...space.spaceFiles.map((spaceFile) => ({
								label: spaceFile.spaceVersion?.name,
								isActive: spaceFile.spaceVersionId === space.versionId,
								onClick: () => onVersionClick(spaceFile),
							})),
						]}
						className="VersionSelector"
						horizontalAlignment={HorizontalAlignment.left}
					/>
				);
			}}
		</Observer>
	);
};

const VersionName = styled.div`
	${ELLIPSIS}
`;

const VersionSelector = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	padding: 2px;
	font-size: 14px;
	line-height: 16px;
	height: 32px;
	width: 100%;
	justify-content: space-between;

	svg {
		min-width: 16px;
	}
`;

import {create} from "zustand";
import type {AppState} from "./data/state/AppState";
import type {View} from "./data/models/View";

export type NavBarState = "closed" | "open" | "docked" | "hidden";

type TStoreState = {
	appState: AppState;
	navBarState: NavBarState;
	isRootFrozen: boolean;
	viewForOpenSpaceSelector: View | null; // when we open the spaceselector, we open the selected space with this view as the selected one for spaceeditor. When it's null, the spaceselector is closed
	modulesTypeAndFieldSearchString: string;
	modulesTypeAndFieldCreating: boolean;
	modulesCreatingType: string;
};

type TStoreAction = {
	setAppState: (newAppState: AppState) => void;
	setNavBarState: (value: NavBarState) => void;
	setIsRootFrozen: (value: boolean) => void;
	setViewForOpenSpaceSelector: (value: View | null) => void;
	setModulesTypeAndFieldSearchString: (value: string) => void;
	setModulesTypeAndFieldCreating: (value: boolean, type: string) => void;
};

export const useAppStore = create<TStoreState & TStoreAction>((set) => ({
	appState: null,
	setAppState(newAppState: AppState) {
		set((state) => ({
			...state,
			appState: newAppState,
		}));
	},
	navBarState: "closed",
	setNavBarState: (value) => set(() => ({navBarState: value})),
	isRootFrozen: false,
	setIsRootFrozen: (value) => set(() => ({isRootFrozen: value})),
	viewForOpenSpaceSelector: null,
	setViewForOpenSpaceSelector: (value) => set(() => ({viewForOpenSpaceSelector: value})),
	modulesTypeAndFieldSearchString: "",
	setModulesTypeAndFieldSearchString: (value) => set(() => ({modulesTypeAndFieldSearchString: value})),
	modulesTypeAndFieldCreating: false,
	modulesCreatingType: "",
	setModulesTypeAndFieldCreating: (value, type) => set(() => ({modulesTypeAndFieldCreating: value, modulesCreatingType: type})),
}));

import * as React from "react";
import {inject, observer} from "mobx-react";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import {Button} from "../../../../widgets/button/Button";
import type {User} from "../../../../../data/models/User";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {PortfolioGroupPermission, PortfolioPermission} from "../../../../../generated/api/base";
import {PortfolioGroupPermissionView} from "./PortfolioGroupPermissionView";
import {PortfolioPermissionView} from "./PortfolioPermissionView";

interface IPortfolioPermissionsViewProps {
	user: User;
	onChange?: () => void;
	transport?: TransportLayer;
}

@inject("transport")
@observer
export class PortfolioPermissionsView extends React.Component<IPortfolioPermissionsViewProps> {
	private onAddIndividualPortfolioPermission = () => {
		const p: PortfolioPermission = {
			portfolioPermissionSetID: "",
			portfolioID: "",
		};

		this.props.user.portfolioPermissionList.push(p);
	};

	private onAddPortfolioGroupPermission = () => {
		const p: PortfolioGroupPermission = {
			portfolioPermissionSetID: "",
			portfolioGroupID: "",
		};

		this.props.user.portfolioGroupPermissionList.push(p);
	};

	private onRemovePortfolioPermission = (permission: PortfolioPermission) => {
		const {user} = this.props;

		ArrayUtils.removeMutable(user.portfolioPermissionList, permission);

		this.props.onChange?.();
	};

	private onRemovePortfolioGroupPermission = (permission: PortfolioGroupPermission) => {
		const {user} = this.props;

		ArrayUtils.removeMutable(user.portfolioGroupPermissionList, permission);

		this.props.onChange?.();
	};

	private onChangePermissionView = () => {
		this.props.onChange?.();
	};

	public override render() {
		const {user} = this.props;

		const individuals = user.portfolioPermissionList;
		const groups = user.portfolioGroupPermissionList;

		const addedPortfolioIds = individuals.map((ps) => ps.portfolioID);
		const addedGroupIds = groups.map((ps) => ps.portfolioGroupID);

		return (
			<>
				<ToggleContainer
					title="Individual Portfolios"
					open={true}
					className="FieldSection"
					helperText="Permissions of an individual portfolio take priority over permissions for portfolio groups. The first permission set on the list has the highest priority and priority reduces for all subsequent permission sets."
				>
					{individuals.map((p, i) => (
						<PortfolioPermissionView
							key={i}
							permission={p}
							exceptions={addedPortfolioIds}
							onRemove={this.onRemovePortfolioPermission}
							onChange={this.onChangePermissionView}
						/>
					))}
					<Button
						label="+"
						className="dashed"
						onClick={this.onAddIndividualPortfolioPermission}
					/>
				</ToggleContainer>
				<ToggleContainer
					title="Portfolio Groups"
					open={true}
					className="FieldSection"
					helperText="Permissions for multiple portfolio groups are applied in the order you list them. The first permission set on the list has the highest priority and priority reduces for all subsequent permission sets."
				>
					{groups.map((p, i) => (
						<PortfolioGroupPermissionView
							key={i}
							permission={p}
							exceptions={addedGroupIds}
							onRemove={this.onRemovePortfolioGroupPermission}
							onChange={this.onChangePermissionView}
						/>
					))}
					<Button
						label="+"
						className="dashed"
						onClick={this.onAddPortfolioGroupPermission}
					/>
				</ToggleContainer>
			</>
		);
	}
}

import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";
import type {BoundarySpaceMap} from "../../../../../../../data/models/BoundarySpaceMap";
import type {ToolType} from "./Tools";
import {Tool} from "./Tool";

export class BoundaryTool extends Tool {
	protected override _toolType: ToolType = "boundary";

	private _boundary: BoundarySpaceMap3D;
	private _startCoords: PointDouble = {
		x: null,
		y: null,
	};

	private _pointer: {
		startX: number;
		startY: number;
	} = {
		startX: null,
		startY: null,
	};

	private _isPointerDown: boolean = false;
	private _savedVertices: PointDouble[] = [];
	private readonly _localThreshold: number = 3;
	private _mode: "addVertex" | "rectangle" = "addVertex";

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, true, "crosshair");
	}

	public override activate() {
		const hasChanged = super.activate();

		if (hasChanged) {
			KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
			this._defaultCursorStyle = this._spaceViewRenderer.domElement.style.cursor;
			this._spaceViewRenderer.domElement.style.cursor = "crosshair";
		}

		return hasChanged;
	}

	public override deactivate() {
		const hasChanged = super.deactivate();

		if (hasChanged) {
			this.finalizeBoundary();
			KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
			this._spaceViewRenderer.domElement.style.cursor = this._defaultCursorStyle;
		}

		return hasChanged;
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.abortBoundary();
				break;
			case KeyboardListener.KEY_ENTER:
				this.finalizeBoundary();
				break;
		}
	};

	private get _boundaryManager() {
		return this._spaceViewRenderer.boundaryManager;
	}

	private getNextVertex(previousVertex: PointDouble, currentCursorWorldPos: PointDouble) {
		const currentCoords = {
			x: currentCursorWorldPos.x,
			y: currentCursorWorldPos.y,
		};
		const a = previousVertex;
		const b = currentCoords;

		let newB = b;

		if (this._mode === "addVertex") {
			if (this._boundaryManager.isSnapToAngleActive) {
				newB = THREEUtils.getSnappedPointIfCloseEnough(a, newB, this._boundaryManager.snapThreshold) as {x: number; y: number};

				if (this._savedVertices.length > 1) {
					newB = THREEUtils.getSnappedPointIfCloseEnough(this._savedVertices[0], newB, this._boundaryManager.snapThreshold) as {x: number; y: number};
				}
			}
		} else {
			if (KeyboardListener.isAltDown) {
				const bbox = THREEUtils.calculateBox([a, b]);
				const side = THREEUtils.getSizeOfBoundingBox2(bbox);

				newB = THREEUtils.constrainScale(side, a, b).b;
			}
		}

		return newB;
	}

	public onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._mode = "addVertex";

		if (!this._boundary) {
			if (!this._boundaryManager.isAddingItemsToServer && !this._spaceViewRenderer.xyiconManager.isAddingItemsToServer) {
				this._isPointerDown = true;
				this._pointer.startX = pointer.localX;
				this._pointer.startY = pointer.localY;
				this._startCoords.x = worldX;
				this._startCoords.y = worldY;
				this._savedVertices.push({
					x: worldX,
					y: worldY,
				});

				this._boundary = new BoundarySpaceMap3D(this._spaceViewRenderer, this._boundaryManager.typeId);

				this._spaceViewRenderer.spaceItemController.deselectAll();
			}
		} else {
			const currentCoords = {
				x: worldX,
				y: worldY,
			};

			const nextVertex = this.getNextVertex(this._savedVertices[this._savedVertices.length - 1], currentCoords);
			const vertices = [...this._savedVertices, nextVertex];

			this._boundary.updateGeometry(vertices);
		}
	};

	private getRectCoords(a: PointDouble, b: PointDouble) {
		return [
			{
				x: a.x,
				y: a.y,
			},
			{
				x: b.x,
				y: a.y,
			},
			{
				x: b.x,
				y: b.y,
			},
			{
				x: a.x,
				y: b.y,
			},
		];
	}

	public onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		if (this._boundary) {
			const currentCoords = {
				x: worldX,
				y: worldY,
			};

			const absDeltaX = Math.abs(pointer.localX - this._pointer.startX);
			const absDeltaY = Math.abs(pointer.localY - this._pointer.startY);

			if (this._isPointerDown && (this._localThreshold < absDeltaX || this._localThreshold < absDeltaY)) {
				this._mode = "rectangle";
			}

			if (this._mode === "rectangle") {
				const a = this._startCoords;
				const b = this.getNextVertex(a, currentCoords);

				const coords = this.getRectCoords(a, b);

				this._boundary.updateGeometry(coords);
			} else {
				const nextVertex = this.getNextVertex(this._savedVertices[this._savedVertices.length - 1], currentCoords);
				const vertices = [...this._savedVertices, nextVertex];

				this._boundary.updateGeometry(vertices);
			}
		}
	};

	public onPointerUpCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		if (this._savedVertices.length > 0) {
			const currentCoords = {
				x: worldX,
				y: worldY,
			};
			const previousVertex = this._savedVertices[this._savedVertices.length - 1];
			const nextVertex = this.getNextVertex(previousVertex, currentCoords);
			const absDeltaX = Math.abs(nextVertex.x - this._startCoords.x);
			const absDeltaY = Math.abs(nextVertex.y - this._startCoords.y);

			const threshold = this._boundaryManager.snapThreshold;

			if (this._mode === "rectangle" || (2 < this._savedVertices.length && absDeltaX <= threshold && absDeltaY <= threshold)) {
				this.finalizeBoundary();
			} else {
				this._mode = "addVertex";

				if (this._savedVertices.length > 1 || (this._savedVertices.length === 1 && (threshold < absDeltaX || threshold < absDeltaY))) {
					this._savedVertices.push(nextVertex);
					this._boundary.updateGeometry(this._savedVertices);
				}
			}
		}

		this._isPointerDown = false;
	};

	private onRedrawFinished() {
		this._boundary.select();
		this._boundaryManager.captionManager.updateCaptions([this._boundary]);
		// We need the "reset" before the "switchEditMode", because "setActiveTool" is called from that, and because of that
		// deactivate will be called on this tool, and it calls finalize, which calls abort, so it gets to a loop otherwise
		this.reset();
		this._spaceViewRenderer.spaceItemController.switchEditMode(true);
	}

	private abortBoundary() {
		const {currentlyRedrawnBoundary} = this._boundaryManager;

		if (currentlyRedrawnBoundary) {
			const modelData = currentlyRedrawnBoundary.modelData as BoundarySpaceMap;

			if (!this._boundary) {
				this._boundary = new BoundarySpaceMap3D(this._spaceViewRenderer, this._boundaryManager.typeId);
			}
			this._savedVertices = modelData.geometryData;
			this._boundary.addModelData(modelData);
			this._boundary.updateGeometry(modelData.geometryData);
			this._boundaryManager.add([this._boundary], false);
			this.onRedrawFinished();
		} else {
			this._boundary?.destroy();
			this.reset();
		}
	}

	private finalizeBoundary() {
		const {currentlyRedrawnBoundary} = this._boundaryManager;

		if (currentlyRedrawnBoundary) {
			if (!this._boundary) {
				this._boundary = new BoundarySpaceMap3D(this._spaceViewRenderer, this._boundaryManager.typeId);
			}
		}

		if (this._boundary) {
			if (this._mode === "addVertex") {
				// Remove the last one (that's moving with your mouse)
				if (this._savedVertices.length > 2) {
					this._boundary.updateGeometry(this._savedVertices);
				}
			}
			if (this._boundary.isValid) {
				this._boundaryManager.add([this._boundary], !currentlyRedrawnBoundary);

				if (currentlyRedrawnBoundary) {
					this._boundary.addModelData(currentlyRedrawnBoundary.modelData);
					this.onRedrawFinished();
				}
			} else {
				this.abortBoundary();
			}
		}

		this.reset();
	}

	private reset() {
		this._boundary = null;
		this._savedVertices.length = 0;
		this._startCoords.x = null;
		this._startCoords.y = null;
		this._boundaryManager.currentlyRedrawnBoundary = null;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {CatalogItemPanelV5} from "../abstract/view/catalog/CatalogItemPanelV5";
import {ReactUtils} from "../../utils/ReactUtils";
import type {IModel, ISpaceItemModel} from "../../../data/models/Model";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {Catalog} from "../../../data/models/Catalog";
import {XyiconFeature} from "../../../generated/api/base";
import type {IIconConfig} from "../../modules/catalog/create/CatalogTypes";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {AppState} from "../../../data/state/AppState";
import {DetailsTabV5} from "./DetailsTabV5";

interface IDetailsContainerProps {
	items: IModel[];
	transport?: TransportLayer;
	onClose: (closeAll?: boolean) => void;
	closeWideSearchPanel: () => void;
	appState?: AppState;
}

interface IDetailsContainerState {
	isCatalogEditPanelOpen: boolean;
	iconConfig: IIconConfig;
	editedCatalog: Catalog;
	isPortTemplateEditorOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class DetailsContainerV5 extends React.Component<IDetailsContainerProps, IDetailsContainerState> {
	// They're on top of each other, so we don't need to render more than 2, even if we introduce an opening/closing animation in the future
	private readonly _numberOfItemsToRender: number = 1;
	private _isLoadingCatalogEditor = false;
	private _containerRef = React.createRef<HTMLDivElement>();
	// Adds new item to the existing array, then they're rendered on each other

	constructor(props: IDetailsContainerProps) {
		super(props);

		this.state = {
			isCatalogEditPanelOpen: false,
			iconConfig: null,
			editedCatalog: null,
			isPortTemplateEditorOpen: false,
		};
	}

	private onCloseClick = () => {
		this.props.onClose(false);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ESCAPE && !this.state.isCatalogEditPanelOpen) {
			this.onCloseClick();
		}
	};

	private onCatalogIconClick = async () => {
		const {transport, items} = this.props;

		if (!this._isLoadingCatalogEditor) {
			this._isLoadingCatalogEditor = true;

			const catalogs = items.filter((item) => item.ownFeature === XyiconFeature.XyiconCatalog);
			const catalog = catalogs[0] as Catalog;

			const iconConfig = await transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				isCatalogEditPanelOpen: true,
			});

			this._isLoadingCatalogEditor = false;
		}
	};

	private onPortTemplateEditorChange = (value: boolean) => {
		this.setState({isPortTemplateEditorOpen: value});
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	public override componentDidUpdate(prevProps: Readonly<IDetailsContainerProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (prevProps.items.length !== this.props.items.length) {
			this.props.appState.isDetailsContainerOpened = !!this.props.items.length;
			if (this.props.items.length === 0) {
				this.setState({isCatalogEditPanelOpen: false});
			}
		}
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {items, closeWideSearchPanel} = this.props;
		const {isCatalogEditPanelOpen, editedCatalog, iconConfig, isPortTemplateEditorOpen} = this.state;
		const itemsToRender = items.filter((item: IModel, index: number) => item && index >= items.length - this._numberOfItemsToRender);

		return (
			<>
				{items.length > 0 && (
					<div
						className="shadowDiv"
						onClick={this.onCloseClick}
					/>
				)}
				<DetailsContainerStyled className={ReactUtils.cls(`DetailsContainer createPanel SidePanel infoPanel`, {open: items.length > 0})}>
					{itemsToRender.map((spaceItemModel: ISpaceItemModel, index: number) => {
						const detailsTabProps = {
							items: [spaceItemModel],
							feature: spaceItemModel.ownFeature,
							features: [spaceItemModel.ownFeature],
							isPortTemplateEditorOpen: isPortTemplateEditorOpen,
							insideDetailsContainer: true,
							setPortTemplateEditorOpen: this.onPortTemplateEditorChange,
							closeWideSearchPanel: closeWideSearchPanel,
							onCatalogIconEditClick: this.onCatalogIconClick,
						};

						return (
							<React.Fragment key={spaceItemModel.id}>
								<DetailsTabV5
									{...detailsTabProps}
									onCloseOverlayedDetaislPanel={this.onCloseClick}
								/>
							</React.Fragment>
						);
					})}
				</DetailsContainerStyled>
				<div className={ReactUtils.cls("createPanel detailsPopup", {open: isCatalogEditPanelOpen})}>
					{isCatalogEditPanelOpen && (
						<CatalogItemPanelV5
							key={`${items.map((item: IModel) => item.refId).join("_")}`}
							onClose={() => {
								this.setState({isCatalogEditPanelOpen: false});
							}}
							iconConfig={iconConfig}
							catalog={editedCatalog}
							mode="edit"
						/>
					)}
				</div>
			</>
		);
	}
}

const DetailsContainerStyled = styled.div`
	position: absolute;
	z-index: 9;
	background: white;
	right: 0;
	width: 600px;
	padding: 20px;
	top: 64px;
	height: calc(100vh - 65px);
	box-sizing: border-box;
	transform: translateX(100%);
	transition: ease-in-out 0.2s transform;

	&.open {
		transform: translateX(0%);
	}
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IActionBarItem} from "../abstract/ModuleView";
import {ModuleView} from "../abstract/ModuleView";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {App} from "../../../App";
import type {Space} from "../../../data/models/Space";
import type {IModel} from "../../../data/models/Model";
import type {SpaceViewRenderer} from "./spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {CreateSpacePanel} from "./create/CreateSpacePanel";

export type OverlayPanel = "Layer" | "ConditionalFormatting" | "Caption";

export interface IBasicPropsFor3DTweaking {
	spaceViewRenderer?: SpaceViewRenderer;
	appState?: AppState;
	app?: App;
	transport?: TransportLayer;
	param1?: string;
}

@inject("appState")
@inject("transport")
@inject("app")
@observer
export class SpaceView extends React.Component<IBasicPropsFor3DTweaking, {}> {
	private hasAnyTypePermission = () => {
		return this.props.appState.actions.hasAnyTypeTheGivenPermissionInModule(XyiconFeature.Space, Permission.Update);
	};

	private hasEveryTypePermission(items: Space[], givenPermission: Permission) {
		if (!this.props.appState.user) {
			return false;
		}
		if (this.props.appState.user.isAdmin) {
			return true;
		}

		return items.every((item) => {
			const permission = this.props.appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Space);

			return permission >= givenPermission;
		});
	}

	private isDeleteEnabled = (selectedItems: Space[]) => {
		if (selectedItems.length === 0) {
			return false;
		}

		return this.hasEveryTypePermission(selectedItems, Permission.Delete);
	};

	public override render() {
		const actionBarItems: IActionBarItem<IModel>[] = [
			{
				id: "import",
				title: "Update Spaces with an Excel file",
				label: "Import",
			},
			{
				id: "add",
				title: "Create Space",
				label: "Create",
				enabled: this.hasAnyTypePermission,
			},
			{
				id: "delete",
				title: "Delete Space",
				label: "Delete",
				enabled: this.isDeleteEnabled,
			},
			{
				id: "manageVersionSets",
				title: "Manage Version Sets",
				label: "Manage Versions",
				enabled: this.hasAnyTypePermission,
				icon: "manage-columns",
			},
		];

		return (
			<ModuleView
				feature={XyiconFeature.Space}
				className="SpaceView"
				actionBar={actionBarItems}
				create={(onClose) => <CreateSpacePanel onClose={onClose} />}
			/>
		);
	}
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import {inject, observer} from "mobx-react";
import {SearchField} from "../search/SearchField";
import {ReactUtils} from "../../../utils/ReactUtils";
import {CheckboxInput} from "../checkbox/CheckboxInput";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {IconButton} from "../../button/IconButton";
import {TextInput} from "../text/TextInput";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import type {App} from "../../../../App";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {MathUtils} from "../../../../utils/math/MathUtils";

interface IMultiSelectInputProps<T> {
	options: T[];
	selected?: T[];
	disabled?: boolean;
	searchBar?: boolean;
	errorMessage?: string;
	noFixedPosition?: boolean;
	app?: App;
	onFocusLossForceBlur?: boolean;
	focused?: boolean;
	inline?: boolean;
	expand?: boolean;

	render?: (item: T) => React.ReactNode;
	onChange?: (selectedOptions: T[]) => void;
	onClick?: (e: React.MouseEvent) => void;
}

enum MultiSelectInputState {
	Default = 1,
	Expanded = 2,
	Editing = 3,
}

interface IMultiSelectInputState {
	search: string;
	multiSelectState: MultiSelectInputState;
	transform: TransformObj;
}

@inject("app")
@observer
export class MultiSelectInput<T = any> extends React.Component<IMultiSelectInputProps<T>, IMultiSelectInputState> {
	public static defaultProps: IMultiSelectInputProps<any> = {
		options: [],
		disabled: false,
		render: (option) => option,
		searchBar: true,
	};

	private _element = React.createRef<HTMLDivElement>();
	private _list = React.createRef<HTMLDivElement>();
	private _checkboxAll = React.createRef<HTMLInputElement>();

	constructor(props: IMultiSelectInputProps<T>) {
		super(props);
		this.state = {
			search: "",
			multiSelectState: MultiSelectInputState.Default,
			transform: null,
		};
	}

	public static getDerivedStateFromProps(props: IMultiSelectInputProps<any>, state: IMultiSelectInputState) {
		if (props.inline && state.multiSelectState !== MultiSelectInputState.Editing) {
			return {
				multiSelectState: props.expand ? MultiSelectInputState.Expanded : MultiSelectInputState.Default,
			} as IMultiSelectInputState;
		}

		return null;
	}

	private onToggleValue = (value: T) => {
		const {selected} = this.props;
		// if T is an object selected does not includes value
		const valueToRemove = selected.find((o) => value === o);

		if (valueToRemove) {
			const newSelected = ArrayUtils.remove(selected, valueToRemove);

			this.dispatchChange(newSelected);
		} else {
			const newSelected = ArrayUtils.add(selected, value);

			this.dispatchChange(newSelected);
		}
	};

	private onToggleOptionAll = (isChecked: boolean) => {
		const {selected, options} = this.props;
		const filteredOptions = options.filter(this.filterOption);
		let newSelected: T[] = [];

		if (isChecked) {
			newSelected = ArrayUtils.removeDuplicates([...selected, ...filteredOptions]);
		} else {
			newSelected = selected.filter((s) => !filteredOptions.includes(s));
		}
		this.dispatchChange(newSelected);
	};

	private dispatchChange(value: T[]) {
		const {onChange} = this.props;

		value = value.filter((item) => item && !Array.isArray(item));
		value = ArrayUtils.uniq(value);
		onChange(value);
	}

	private onListClick = async (event?: React.MouseEvent) => {
		this.props.onClick?.(event);

		this.setState({
			multiSelectState: MultiSelectInputState.Editing,
		});

		AppUtils.disableScrolling(true);

		await TimeUtils.wait(500);
		FocusLoss.listen(this._list.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.add(this.pressButton);
	};

	private pressButton = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				this.setState({
					multiSelectState: MultiSelectInputState.Default,
				});
				break;
		}
	};

	private onFocusLoss = (event: MouseEvent) => {
		let eventTargetInModalContainer = null;

		if (event.target instanceof Element) {
			eventTargetInModalContainer = this.props.app.modalContainer.contains(ReactDOM.findDOMNode(event.target));
		}

		if ((event && !eventTargetInModalContainer) || this.props.onFocusLossForceBlur) {
			this.close();
			this.setState({
				multiSelectState: MultiSelectInputState.Default,
				search: "",
			});

			AppUtils.disableScrolling(false);

			KeyboardListener.getInstance().signals.up.remove(this.pressButton);
		}
	};

	private close = () => {
		AppUtils.disableScrolling(false);

		this.setState({
			multiSelectState: MultiSelectInputState.Default,
			search: "",
		});
	};

	private filterOption = (option: T) => {
		const {search} = this.state;

		if (search) {
			const {render} = this.props;

			const reactElement = render(option);
			const text = (ReactUtils.getTextContent(reactElement as string) || "").toLowerCase();

			return text.includes(search.toLowerCase());
		}

		return true;
	};

	private onSearchStringChange = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private isValueChecked = (option: T, selected: T[]) => {
		return selected.some((o) => option === o);
	};

	private isOptionAllChecked = (selected: T[]) => {
		const filteredOptions = this.props.options.filter(this.filterOption);
		const isAllChecked = filteredOptions.every((o) => selected.includes(o));
		const intermediate = !isAllChecked && filteredOptions.some((o) => selected.includes(o));
		const checkbox = this._checkboxAll.current;

		if (checkbox /* && intermediate && !isAllChecked */) {
			//checkbox.checked = isAllChecked;
			checkbox.indeterminate = intermediate;
		}
		return isAllChecked;
	};

	private expandList = (e: React.MouseEvent) => {
		e.stopPropagation();
		this.setState({multiSelectState: MultiSelectInputState.Expanded});
	};

	private shrinkList = (e: React.MouseEvent) => {
		e.stopPropagation();
		this.setState({multiSelectState: MultiSelectInputState.Default});
	};

	public override componentDidUpdate(prevProps: IMultiSelectInputProps<T>, prevState: IMultiSelectInputState) {
		if (
			this._element.current &&
			this._list.current &&
			!this.props.noFixedPosition &&
			((prevState.multiSelectState !== MultiSelectInputState.Editing && this.state.multiSelectState === MultiSelectInputState.Editing) ||
				prevState.search !== this.state.search)
		) {
			this.setState({transform: DomUtils.getFixedFloatingElementPosition(this._element.current, this._list.current)});
		}

		if (!prevProps.focused && this.props.focused) {
			this.onListClick();
		} else if (prevProps.focused && !this.props.focused) {
			this.close();
		}
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._list.current, this.onFocusLoss);
	}

	private getCurrentState = () => {
		const {search, multiSelectState, transform} = this.state;
		const {options, selected, render, searchBar, errorMessage, noFixedPosition, inline} = this.props;
		const removedSelected = selected.filter((option) => !options.some((o) => option === o));
		const allOptions = [...options, ...removedSelected];
		const maxItemCount = 10;

		let inlineStyle: React.CSSProperties = {
			width: this._element.current?.getBoundingClientRect().width,
			transform: transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
		};

		if (noFixedPosition) {
			inlineStyle.transform = "";
		} else {
			inlineStyle.top = transform?.vertical === VerticalAlignment.top ? 42 : -42;
		}

		if (selected.length === 0 && multiSelectState !== MultiSelectInputState.Editing) {
			return (
				<div className="list">
					<TextInput
						value=""
						onClick={this.onListClick}
						errorMessage={errorMessage}
						className={ReactUtils.cls({error: errorMessage})}
					/>
				</div>
			);
		} else {
			switch (multiSelectState) {
				case MultiSelectInputState.Default:
					return (
						<div className="list vbox">
							<div className="list">
								{selected
									.slice()
									.sort((a, b) => StringUtils.sortIgnoreCase(render(a).toString(), render(b).toString()))
									.map((option, index) => {
										if (index < maxItemCount) {
											return (
												<div
													className="item hbox"
													key={index}
												>
													<div
														className="label"
														onClick={this.onListClick}
													>
														{render(option)}
													</div>
													{!inline && (
														<IconButton
															icon="close"
															className="delete"
															onClick={() => this.onToggleValue(option)}
														/>
													)}
												</div>
											);
										}
									})}
								{selected.length > maxItemCount && !inline && (
									<div
										className="bottom-section hbox"
										onClick={this.expandList}
									>
										<p>{`${selected.length - maxItemCount} More`}</p>
									</div>
								)}
							</div>
						</div>
					);
				case MultiSelectInputState.Expanded:
					return (
						<div className="list vbox">
							<div className="list">
								{selected
									.slice()
									.sort((a, b) => StringUtils.sortIgnoreCase(render(a).toString(), render(b).toString()))
									.map((option, index) => {
										return (
											<div
												className="item hbox"
												key={index}
											>
												<div
													className="label"
													onClick={this.onListClick}
												>
													{render(option)}
												</div>
											</div>
										);
									})}
								{selected.length > maxItemCount && !inline && (
									<div
										className="bottom-section hbox"
										onClick={this.shrinkList}
									>
										<p>{"Less"}</p>
									</div>
								)}
							</div>
						</div>
					);
				case MultiSelectInputState.Editing:
					const filteredOptions = allOptions.filter(this.filterOption);
					const realParentId = MathUtils.getNewRandomGUID();

					return (
						<div
							className="list"
							id={realParentId}
						>
							<DomPortal destination={this.props.app.modalContainer}>
								<div
									className={ReactUtils.cls("MultiSelectInput__open-list-container", {inline})}
									style={inlineStyle}
									ref={this._list}
								>
									{options.length > 5 && searchBar && (
										<div className="list-search">
											<SearchField
												value={search}
												onInput={this.onSearchStringChange}
												placeholder="Find items ..."
												autoFocus={true}
												realParentId={realParentId}
											/>
										</div>
									)}
									<div className="top-section expanded hbox">
										<p>{`Found ${filteredOptions.length} of ${options.length}`}</p>
									</div>
									<div className="open-list">
										<div
											className={ReactUtils.cls("item option", {selected: selected.length === filteredOptions.length})}
											onClick={() => inline && this.onToggleOptionAll(selected.length !== filteredOptions.length)}
										>
											{!inline && (
												<CheckboxInput
													inputRef={this._checkboxAll}
													value={this.isOptionAllChecked(selected)}
													onChange={this.onToggleOptionAll}
												/>
											)}
											<div className="label">All</div>
										</div>
										{filteredOptions.map((option, index) => (
											<div
												key={index}
												onClick={() => inline && this.onToggleValue(option)}
												className={ReactUtils.cls("item option", {removed: !options.includes(option), selected: selected.includes(option)})}
											>
												{!inline && (
													<CheckboxInput
														value={this.isValueChecked(option, selected)}
														onChange={() => this.onToggleValue(option)}
													/>
												)}
												<div className="label">{render(option)}</div>
											</div>
										))}
									</div>
									{filteredOptions.length === 0 ? (
										<div className="bottom-section expanded noValues">
											No values found.
											<br />
											Contact your admin.
										</div>
									) : (
										<div className="bottom-section expanded hbox">
											<p>{`${selected.length} item${selected.length > 1 ? "s" : ""} selected`}</p>
										</div>
									)}
								</div>
							</DomPortal>
						</div>
					);
			}
		}
	};

	public override render() {
		const {selected, disabled, inline} = this.props;

		return (
			!(disabled && selected.length === 0) && (
				<div
					ref={this._element}
					className={ReactUtils.cls("MultiSelectInput open", {
						disabled,
						inline,
						empty: selected.length === 0,
						cellContent: inline,
					})}
				>
					{this.getCurrentState()}
				</div>
			)
		);
	}
}

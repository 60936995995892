import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {User} from "../../../../../data/models/User";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import type {ISwitchListChange} from "../../../../widgets/button/switch/SwitchList";
import {SwitchList} from "../../../../widgets/button/switch/SwitchList";
import {ToggleSwitchField} from "../../../../widgets/button/switch/ToggleSwitchField";
import type {ISelectSliderOption} from "../../../../widgets/input/selectslider/SelectSlider";
import {SelectSlider} from "../../../../widgets/input/selectslider/SelectSlider";
import type {UserDto} from "../../../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../../../generated/api/base";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import {DomPortal} from "../../../abstract/portal/DomPortal";
import {InfoBubble} from "../../../abstract/common/infobutton/InfoBubble";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {PortfolioPermissionsView} from "./PortfolioPermissionsView";

interface IUserFormProps {
	users: User[];
	onChangeUserGroup?: (changes: ISwitchListChange[]) => void;
	assignedUserGroups?: string[];
	appState?: AppState;
	transport?: TransportLayer;
	hideSystemUserGroups?: boolean;
}

interface IUserFormState {
	toolTipTransform: TransformObj;
	isToolTipOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class UserForm extends React.Component<IUserFormProps, IUserFormState> {
	private _modalContainer = this.props.appState.app.modalContainer;
	private _parent = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;
	private _timeOutId: number = null;
	private _options: ISelectSliderOption[] = [
		// {
		//	id: Permission.None,
		//	label: "Disabled"
		// },
		{
			id: Permission.View,
			label: "View",
		},
		{
			id: Permission.Update,
			label: "Edit",
		},
		// {
		// 	id: Permission.C,
		// 	label: "Create"
		// },
		{
			id: Permission.Delete,
			label: "Delete",
		},
	];

	constructor(props: IUserFormProps) {
		super(props);
		this.state = {
			toolTipTransform: null,
			isToolTipOpen: false,
		};
	}

	private onChangePermission = (rowKeys: string[], id: Permission) => {
		const user = this.props.users[0];

		if (rowKeys.includes("Catalog Module")) {
			user.setOrganizationPermission(XyiconFeature.XyiconCatalog, id);
		}

		// if (rowKeys.includes("Reports Module"))
		// {
		// 	user.setOrganizationPermission(XyiconFeature.Report, id);
		// }

		this.updateApi();
	};

	private onChangeAdmin = async (value: boolean) => {
		const user = this.props.users[0];

		user.isAdmin = value;

		await this.updateApi();

		if (this._isMounted) {
			this.forceUpdate();
		}
	};

	private onChangeUserGroup = (changes: ISwitchListChange[]) => {
		const user = this.props.users[0];

		for (const change of changes) {
			const userGroup = this.props.appState.actions.getFeatureItemById<UserGroup>(change.id, XyiconFeature.UserGroup);

			if (userGroup) {
				if (change.value) {
					userGroup.addUser(user.id, this.props.appState);
				} else {
					userGroup.removeUser(user.id, this.props.appState);
				}
			}
		}

		this.updateApi();
	};

	private onPortfolioPermissionsChange = () => {
		return this.updateApi();
	};

	private async updateApi() {
		const user = this.props.users[0];
		const {appState, transport} = this.props;

		if (user.id) {
			// we're in edit mode
			user.updatePermissions();
			const res = await transport.services.feature.update(user.id, XyiconFeature.User, user.getUpdateData());

			const newUserGroupIds = (res as UserDto)?.userGroupIDList || [];
			const oldUserGroupIds = [...user.userGroupIds]; // clone this, because the function below can modify the array directly

			user.addOrRemoveUserFromUserGroups(oldUserGroupIds, "remove", appState);
			user.addOrRemoveUserFromUserGroups(newUserGroupIds, "add", appState);
		}
	}

	private openTooltip = () => {
		if (this._timeOutId) {
			clearTimeout(this._timeOutId);
		}

		this._timeOutId = window.setTimeout(() => {
			if (this._isMounted) {
				this.setState({
					isToolTipOpen: true,
				});
			}
		}, 1000);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isToolTipOpen: false,
		});
	};

	public override componentDidMount(): void {
		this._isMounted = true;
	}

	public override componentDidUpdate = (prevProps: IUserFormProps, prevState: IUserFormState) => {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.top,
					HorizontalAlignment.left,
				),
			});
		}
	};

	public override componentWillUnmount(): void {
		this._isMounted = false;
	}

	public override render() {
		const {users, appState, assignedUserGroups, onChangeUserGroup, hideSystemUserGroups} = this.props;
		const {isToolTipOpen, toolTipTransform} = this.state;
		const user = users[0];
		const disabled = appState.user === user;

		if (!user) {
			return null;
		}

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		const userGroupList = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);
		const filteredUserGroupList = userGroupList.filter((userGroup) => !(hideSystemUserGroups && userGroup.data.isSystem));
		const switchList = filteredUserGroupList.map((userGroup) => ({
			id: userGroup.id,
			label: userGroup.name,
			value: assignedUserGroups ? assignedUserGroups.includes(userGroup.id) : userGroup.userIds.includes(user.id),
			disabled: userGroup.data.isSystem,
		}));

		return (
			<>
				<div className="header">
					<ToggleSwitchField
						divRef={this._parent}
						label="Organization Administrator"
						value={user.isAdmin}
						onChange={this.onChangeAdmin}
						disabled={disabled}
						onMouseLeave={disabled && this.closeTooltip}
						onMouseOver={disabled && this.openTooltip}
					/>
					<SelectSlider
						options={this._options}
						rows={[
							{
								label: "Catalog Module",
								value: user.getOrganizationPermission(XyiconFeature.XyiconCatalog),
							},
							// {
							// 	label: "Reports Module",
							// 	value: user.getOrganizationPermission(XyiconFeature.Report)
							// }
						]}
						onChange={this.onChangePermission}
						disabled={user?.isAdmin}
					/>
				</div>
				<ToggleContainer
					title="Portfolio Permissions"
					open={true}
				>
					{user.isAdmin ? (
						<p>An “organization administrator” will have the full access to all portfolios in this organization.</p>
					) : (
						<PortfolioPermissionsView
							user={user}
							onChange={this.onPortfolioPermissionsChange}
						/>
					)}
				</ToggleContainer>
				<ToggleContainer
					title="User Groups"
					open={true}
				>
					<SwitchList
						list={switchList}
						onChange={onChangeUserGroup || this.onChangeUserGroup}
					/>
				</ToggleContainer>
				{isToolTipOpen && disabled && (
					<DomPortal destination={this._modalContainer}>
						<InfoBubble
							className="UserForm"
							content="You cannot remove yourself as an organization administrator."
							style={inlineStyle}
							divRef={this._floating}
						/>
					</DomPortal>
				)}
			</>
		);
	}
}

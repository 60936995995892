import {FileDropper} from "../../../utils/interaction/draganddrop/FileDropper";
import {WarningWindow} from "../../modules/abstract/popups/WarningWindow";
import type {IWrapperProps} from "../../widgets/wrapper/Wrapper";
import {Wrapper} from "../../widgets/wrapper/Wrapper";

interface IFileDropperWrapperProps extends IWrapperProps {
	accept: string;
	multiple?: boolean;
	onFileInputChange: (files: FileList) => void;
}

export class FileDropperWrapper extends Wrapper<IFileDropperWrapperProps, HTMLElement> {
	private _dropper: FileDropper;

	private onDragOver = (event: DragEvent) => {
		// TODO: what's the purpose of this?

		return true;
	};

	private async onFileChange(files: FileList) {
		if (this._dropper.checkType(files)) {
			this.props.onFileInputChange(files);
		} else {
			await WarningWindow.open(`Wrong filetype! Only the following types are accepted: ${this.props.accept}`);
		}

		// To replace "value" with an empty string. So if the user deletes the previously selected item from a list, and then selects the same file again, onFileChange is triggered
		this.forceUpdate();
	}

	private onDrop = (event: DragEvent) => {
		this.onFileChange(event.dataTransfer.files);
	};

	protected override onChildMounted(child: HTMLElement) {
		this._dropper = new FileDropper({
			targetElement: child,
			onDragOver: this.onDragOver,
			onDrop: this.onDrop,
			accept: this.props.accept,
		});
	}

	public override componentWillUnmount() {
		if (this._dropper) {
			this._dropper.disable();
		}
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import type {XyiconFeature} from "../../../../generated/api/base";
import type {View} from "../../../../data/models/View";
import type {IFieldColumn} from "../../../../data/models/Report";
import {ColumnEditorV5} from "./ColumnEditorV5";

interface IEditViewColumnsProps {
	feature: XyiconFeature;
	view: View;
	title?: string;
}

@observer
export class EditViewColumnsV5 extends React.Component<IEditViewColumnsProps> {
	private addColumns = (fields: IFieldColumn[], index?: number) => {
		this.props.view.addColumns(
			fields.map((f) => f.field.refId),
			index,
			this.props.feature,
		);
	};

	private removeColumns = (fields: IFieldColumn[]) => {
		this.props.view.removeColumnsByRefId(
			fields.map((f) => f.field.refId),
			this.props.feature,
		);
	};

	private reorderColumn = (fromIndex: number, toIndex: number) => {
		this.props.view.reorderColumn(fromIndex, toIndex, this.props.feature);
	};

	private getColumnRefIds = () => {
		return this.props.view.getValidViewColumns(this.props.feature).map((c) => c.field);
	};

	public override render() {
		const {title, feature} = this.props;

		return (
			<ColumnEditorV5
				title={title ?? "Manage Columns"}
				leftHeader="Hidden Columns"
				rightHeader="Visible Columns"
				feature={feature}
				addColumns={this.addColumns}
				removeColumns={this.removeColumns}
				reorderColumn={this.reorderColumn}
				getColumnRefIds={this.getColumnRefIds}
				defaultOpenFeature={feature}
			/>
		);
	}
}

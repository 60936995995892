import type {RefObject} from "react";
import styled from "styled-components";
import {Functions} from "../../../../utils/function/Functions";
import {MathUtils} from "../../../../utils/math/MathUtils";

const validateValue = (value: boolean) => {
	return !!value;
};

interface ICheckboxInputProps {
	value: boolean;
	onChange?: (value: boolean) => void;
	disabled?: boolean;
	inputRef?: RefObject<HTMLInputElement>;
	label?: string;
	className?: string;
}

export const CheckboxInputV5 = (props: ICheckboxInputProps) => {
	const {value = false, onChange = Functions.emptyFunction, disabled, inputRef, className} = props;
	const _id = MathUtils.getNewRandomGUID();

	const triggerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			const value = validateValue(event.currentTarget.checked);

			onChange(value);
		}
	};

	return (
		<CheckboxInputStyled
			className="CheckboxInput hbox alignCenter"
			data-cy={`CheckboxInput_for_${props.label}`}
		>
			{props.label && <label htmlFor={_id}>{props.label}</label>}
			<input
				ref={inputRef}
				id={_id}
				checked={value}
				type="checkbox"
				onChange={triggerChange}
				disabled={disabled}
				className={className}
				value={value.toString()}
			/>
			<label htmlFor={_id} />
		</CheckboxInputStyled>
	);
};

export const CheckboxInputStyled = styled.div`
	display: flex;

	label {
		cursor: pointer;
	}
`;

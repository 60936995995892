import styled, {css} from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {baseDistance, fontSize, radius} from "../styles/styles";

export const SharingStyled = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	.container {
		display: flex;
		justify-content: space-between;
	}
`;

export const SharingPanelStyled = styled.div<{$isDropdown?: boolean}>`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: ${({$isDropdown}) => ($isDropdown ? "0px" : "8px 0")};

	.unshare {
		background-color: #f5423e;
		height: 24px;
		font-size: 12px;
		margin-left: auto;
		display: none;

		&:hover {
			background-color: #be1b17;
		}
	}
`;

export const RowStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 8px;
	cursor: pointer;

	&:hover {
		background-color: #dfdfdf;
		border-radius: 4px;

		.unshare {
			display: flex;
		}
	}

	.owner,
	.ShareOption {
		margin-left: auto;
		border: none;
		color: #363636;
		font-size: 12px;
	}

	&.selected {
		background-color: #bedcf9;
	}
`;

export const NameContainerStyled = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 246px;
	padding-left: 8px;

	> div {
		max-width: 100%;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	.name {
		line-height: 24px;
	}

	.counter,
	.email {
		color: #7b7b7b;
		font-size: 12px;
		line-height: 16px;
	}
`;

export const SectionTitle = styled.div`
	padding: 8px;
	font-size: ${fontSize.sm};
	color: ${colorPalette.gray.c700Dark};
`;

export const UserContainer = styled.div<{$isDropdown?: boolean}>`
	flex: 1;
	${({$isDropdown}) =>
		!$isDropdown &&
		css`
			max-height: 188px;
			overflow-y: auto;
		`}
`;

export const UserGroupContainer = styled.div<{$isDropdown?: boolean}>`
	flex: 1;
	border-bottom: 1px solid ${colorPalette.gray.c300};

	${({$isDropdown}) =>
		!$isDropdown &&
		css`
			max-height: 188px;
			overflow-y: auto;
		`}
`;

export const UserAndUserGroupContainer = styled.div<{$isDropdown?: boolean}>`
	border-radius: ${radius.sm};
	border: 1px solid ${colorPalette.gray.c300};
	max-height: 384px;

	${({$isDropdown}) =>
		$isDropdown &&
		css`
			overflow-y: auto;
		`}
`;

export const AddUserOrUserGroupStyled = styled.div`
	padding-right: 0 8px;

	.SearchField {
		width: 438px;
		margin-right: 8px;
	}
`;

export const AddUserOrUserGroupSelectorStyled = styled.div<{$isDropdown?: boolean}>`
	padding: ${({$isDropdown}) => ($isDropdown ? "0px" : "8px")};
	background: white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
	border-radius: 4px;
	position: absolute;
	width: 416px;
`;

export const DropDownTitleStyle = styled.div`
	padding: 0px ${baseDistance.sm};
	max-width: 340px;

	svg {
		margin-left: ${baseDistance.xs};
		width: 14px;
		height: 14px;
		position: relative;
		top: 2px;
	}
`;

export const PillsStyle = styled.div`
	display: inline-flex;
	align-items: center;
	background-color: #ededed;
	border-radius: ${radius.sm};
	padding: 4px 8px;
	margin: 4px 4px;
	font-size: ${fontSize.sm};
	color: #363636;
	cursor: pointer;

	span {
		margin-right: 4px;
	}

	svg {
		width: 12px;
		height: 12px;
		fill: ${colorPalette.gray.c600};
		cursor: pointer;
	}
`;

import styled from "styled-components";
import {Fragment, useState} from "react";
import {Observer} from "mobx-react";
import {SpaceActionBarStyled} from "../modules/spaceeditor/spaceactionbar/SpaceActionBar.styled";
import XmarkIcon from "../icons/xmark.svg?react";
import type {IModel} from "../../../data/models/Model";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {XyiconFeature} from "../../../generated/api/reports";
import {colorPalette} from "../styles/colorPalette";
import {radius} from "../styles/styles";
import {LineStyled} from "../topbar/ProjectName.styled";
import type {Catalog} from "../../../data/models/Catalog";
import {useAppStore} from "../../../StateManager";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {DropdownButtonStyled, DropdownButtonV5} from "../interaction/DropdownButtonV5";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {MassUpdatePopupV5} from "../details/datatypes/mass/MassUpdatePopupV5";
import type {IActionBarItem} from "./ModuleViewV5";

interface IGridActionBarV5Props<T extends IModel> {
	items: IModel[];
	buttons: IActionBarItem<T>[];
	feature: XyiconFeature;
	defaultClick: (item: IActionBarItem<T>) => void;
	onClose?: () => void;
}

const GridActionBarStyled = styled(SpaceActionBarStyled)`
	bottom: 88px;
	transform: translateX(-50%);
	left: 50%;
	white-space: nowrap;
	gap: 8px;
`;

const CountLabelStyled = styled.div`
	background-color: ${colorPalette.primary.c500Primary};
	border-radius: ${radius.sm};
	padding: 8px;
	height: 32px;
`;

const ButtonsStyled = styled.div`
	display: flex;
	gap: 8px;

	> ${IconButtonStyled} {
		padding: 0 4px;
	}

	> ${DropdownButtonStyled} {
		gap: 8px;
		padding: 0 4px;

		&.isOpen {
			color: ${colorPalette.primary.c500Primary};
			background-color: ${colorPalette.primary.c200Light};

			&:hover {
				background-color: ${colorPalette.primary.c200Light};
			}

			${IconButtonStyled} {
				background-color: ${colorPalette.primary.c200Light};
			}
		}
	}

	.SetFavoriteButton {
		&:hover,
		&.selected {
			color: ${colorPalette.libraryColors.eggYolk};
		}
	}
`;

const ActionBarLineStyled = styled(LineStyled)`
	left: 0;
	background-color: ${colorPalette.white};
	width: 1px;
`;

const CloseButtonStyled = styled.div`
	svg {
		width: 16px;
		height: 16px;
	}

	${IconButtonStyled} {
		width: 32px;
		height: 32px;
	}
`;

export const GridActionBarV5 = (props: IGridActionBarV5Props<IModel>) => {
	const appState = useAppStore((state) => state.appState);

	const [selectedFieldRefId, setSelectedFieldRefId] = useState<string>("");

	const onButtonClick = (button: IActionBarItem<IModel>) => {
		if (button.onClick) {
			button.onClick(props.items);
		} else {
			props.defaultClick(button);
		}
	};

	const getFieldsForMassUpdate = () => {
		const fieldRefIdsForType: string[] = [];

		for (const item of props.items) {
			if (item?.typeId) {
				const newRefIds = appState.actions.getFieldRefIdsForType(item.typeId, item.ownFeature);

				for (const refId of newRefIds) {
					if (!fieldRefIdsForType.includes(refId)) {
						fieldRefIdsForType.push(refId);
					}
				}
			}
		}

		return fieldRefIdsForType;
	};

	{
		const {items, onClose, buttons} = props;

		return (
			<Observer>
				{() => {
					return (
						<>
							<GridActionBarStyled className="GridActionBar">
								{props.feature === XyiconFeature.Space ? "" : <CountLabelStyled>{`${items.length} selected`}</CountLabelStyled>}
								<ButtonsStyled>
									{buttons.map((button, index) => {
										if (button.visible?.(items as Catalog[]) && button.IconComponent) {
											if (button.id === "fieldUpdate") {
												return (
													<DropdownButtonV5
														key={button.id}
														options={[
															...getFieldsForMassUpdate().map((fieldRefId) => {
																const field = appState.actions.getFieldByRefId(fieldRefId);

																return {
																	label: field.name,
																	onClick: () => setSelectedFieldRefId(fieldRefId),
																} as IDropdownOption;
															}),
														]}
														horizontalAlignment={HorizontalAlignment.left}
														verticalAlignment={VerticalAlignment.topOuter}
														changeAlignmentOnPositionCorrection={false}
														style={{
															maxHeight: "calc(100vh - 136px)",
															overflow: "auto",
														}}
														button={
															<IconButtonV5
																key={`${button.id}${index}`}
																title={button.title}
																onClick={() => onButtonClick(button)}
																label={button.label}
																IconComponent={button.IconComponent}
																titleAlignment="top"
															/>
														}
													/>
												);
											}

											return (
												<Fragment key={`${button.id}${index}`}>
													{button.componentFactory?.(items) ?? (
														<IconButtonV5
															title={button.title}
															onClick={() => onButtonClick(button)}
															label={button.label}
															IconComponent={button.IconComponent}
															disabled={button.enabled ? !button.enabled?.(props.items) : false}
															isActive={button.active}
															titleAlignment="top"
														/>
													)}
												</Fragment>
											);
										}

										return null;
									})}
								</ButtonsStyled>
								<ActionBarLineStyled />
								<CloseButtonStyled>
									<IconButtonV5
										onClick={onClose}
										IconComponent={XmarkIcon}
									/>
								</CloseButtonStyled>
							</GridActionBarStyled>
							{selectedFieldRefId && (
								<MassUpdatePopupV5
									fieldRefId={selectedFieldRefId}
									onClose={() => setSelectedFieldRefId("")}
									items={items}
								/>
							)}
						</>
					);
				}}
			</Observer>
		);
	}
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Lambda} from "mobx";
import {observable, observe, makeObservable} from "mobx";
import {FilterChangeNotification} from "../space/spaceeditor/ui/viewbar/FilterChangeNotification";
import type {IIconConfig} from "../catalog/create/CatalogTypes";
import {CatalogItemPanel} from "../catalog/create/CatalogItemPanel";
import {Dockable} from "../space/spaceeditor/ui/toolbar/Dockable";
import {ManageVersionSets} from "../space/versionset/ManageVersionSets";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {featureTitlePlurals} from "../../../data/state/AppStateConstants";
import {Splitter} from "../../widgets/splitter/Splitter";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {MapsPanel} from "../portfolio/maps/MapsPanel";
import {ViewStack} from "../../widgets/viewstack/ViewStack";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {IModel} from "../../../data/models/Model";
import {StackChild} from "../../widgets/viewstack/StackChild";
import {filterModels} from "../../../data/models/filter/Filter";
import {ReactUtils} from "../../utils/ReactUtils";
import {Functions} from "../../../utils/function/Functions";
import type {XyiconDto} from "../../../generated/api/base";
import type {App} from "../../../App";
import {notify} from "../../../utils/Notify";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {Debouncer} from "../../../utils/function/Debouncer";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {Button} from "../../widgets/button/Button";
import type {Catalog} from "../../../data/models/Catalog";
import {NotificationType} from "../../notification/Notification";
import {SearchField} from "../../widgets/input/search/SearchField";
import type {PermissionSet} from "../../../data/models/permission/PermissionSet";
import {NavigationEnum} from "../../../Enums";
import {ManageColumnsPanel} from "./columns/ManageColumnsPanel";
import {ChartsView} from "./charts/ChartsView";
import {GridView} from "./grid/GridView";
import {SidePanel} from "./sidepanel/SidePanel";
import {ActionBar} from "./bars/ActionBar";
import {ViewBar} from "./bars/ViewBar";
import {ScreenType} from "./ScreenType";
import {FeatureImportPanel} from "./view/FeatureImportPanel";
import {ConfirmWindow} from "./popups/ConfirmWindow";
import {EditViewSharing} from "./view/sharing/EditViewSharing";
import {UnsavedLayout} from "./view/UnsavedLayout";
import {PopupUtils} from "./popups/PopupUtils";
import {WarningWindow} from "./popups/WarningWindow";
import {CardView} from "./card/CardView";

export interface ICreateUnplottedXyiconParam {
	catalogId: string;
	parentXyiconId: string | null;
}

export interface IModulePanelProps {
	onClose: (createdId?: string) => void;
	appState?: AppState;
	transport?: TransportLayer;
}

export interface IActionBarItem<T extends IModel> {
	id: "add" | "delete" | "duplicate" | string;
	title: string;
	label?: string;
	className?: string;
	icon?: string;
	componentFactory?: (selectedItems: T[]) => React.JSX.Element;
	enabled?: (selectedItems: T[]) => boolean;
	onClick?: (selectedItems: T[]) => void;
}

interface IModuleViewProps<T extends IModel> {
	feature: XyiconFeature;
	className?: string;
	actionBar: IActionBarItem<T>[];
	viewBar?: boolean;

	create?: (onClose: () => void) => React.ReactElement;
	maps?: boolean;
	onAdd?: () => void;
	onDuplicate?: (model: T) => void;
	onEdit?: (model: T) => void;
	emptyListText?: React.ReactNode;

	filterPermissions?: (model: T) => boolean;

	isCatalogPanelDocked?: boolean;
	isCatalogPanelOpen?: boolean;
	setCatalogPanelDocked?: (isDocked: boolean) => void;
	setCatalogPanelOpen?: (isOpen: boolean) => void;

	canDeleteReports?: (selectedItems: T[]) => boolean;

	manageColumns?: boolean;

	navigation?: Navigation;
	transport?: TransportLayer;
	app?: App;
	appState?: AppState;
}

interface IModuleViewState<T extends IModel> {
	selectedScreen: ScreenType;
	selectedItems: T[];
	creating: boolean;
	managingColumns: boolean;
	search: string;
	searching: boolean;
	tableSearchActive: false;
	isPortTemplateEditorOpen: boolean;
	isCellContentsWrappingOn: boolean;
	initialLoading: boolean;
	isLoadingDependencies: boolean;
	sharingViewId: string;
	iconConfig: IIconConfig;
	editedCatalog: Catalog; // the icon of this catalog is being edited
	catalogMode: "create" | "edit" | null;
	isFeatureImportPanelOpen: boolean;
	loadingViewId: string;
	isManageVersionSetsPanelOpen: boolean;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@inject("app")
@observer
export class ModuleView<T extends IModel = IModel> extends React.Component<IModuleViewProps<T>, IModuleViewState<T>> {
	@observable
	private _sidePanel = React.createRef<SidePanel<T>>();
	private _sidePanelContainer = React.createRef<HTMLDivElement>();
	private _isLoadingCatalogEditor: boolean = false;

	private _isDeletePopupWindowOpen: boolean = false;
	private _gridView = React.createRef<GridView<T>>();
	private _disposer: Lambda;

	private _creationCount = 0;
	private _isMounted: boolean = false;

	private readonly _actionBar: {
		handlers: {
			[action: string]: () => void;
		};
		enabled?: {
			[action: string]: (items: T[]) => boolean;
		};
	};

	private readonly _listDebouncer = new Debouncer(1000);
	private readonly _typingDebouncer = new Debouncer(1000);

	// We assume ModuleView constructor runs every time a module is opened (is the case currently)
	private _lastViewId: string = "";

	constructor(props: IModuleViewProps<T>) {
		super(props);
		makeObservable(this);
		this.state = {
			selectedScreen: props.feature === XyiconFeature.Space ? ScreenType.CARD : ScreenType.GRID,
			selectedItems: [],
			creating: false,
			managingColumns: false,
			search: "",
			searching: false,
			tableSearchActive: false,
			isPortTemplateEditorOpen: false,
			// It is always true for now, maybe this will be changed in the future
			isCellContentsWrappingOn: true,
			initialLoading: props.appState.lists[props.feature]?.array.length > 100,
			isLoadingDependencies: true, // we set it to false when all the dependencies are loaded 100%
			sharingViewId: null,
			editedCatalog: null,
			iconConfig: null,
			catalogMode: null,
			isFeatureImportPanelOpen: false,
			loadingViewId: "",
			isManageVersionSetsPanelOpen: false,
		};

		props.app.moduleViews[props.feature] = this;

		this._actionBar = {
			handlers: {
				add: this.onAddClick,
				import: this.onImportClick,
				duplicate: this.onActionBarDuplicateClick,
				edit: this.onActionBarEditClick,
				delete: this.onDeleteClick,
				manageVersionSets: this.onManageVersionSetsClick,
			},
			enabled: {
				add: () => true,
				import: this.canImport,
				duplicate: (items) => items.length === 1,
				edit: (items) => items.length === 1,
				delete: (items) => items.length > 0,
				manageVersionSets: () => true,
			},
		};

		this.resetFilters();
	}

	private resetFilters() {
		const selectedView = this.selectedView;

		if (selectedView.id !== this._lastViewId) {
			// Selected view changed, or opening view the first time
			// -> copy filters from view
			this._lastViewId = selectedView.id;

			this.forceResetFilters();
		}
	}

	private forceResetFilters() {
		const {selectedView} = this;
		const savedFilters = selectedView.getSavedFilters();

		selectedView.setFilters(ObjectUtils.deepClone(savedFilters));
		this._sidePanel.current?.filterEditor?.reset(savedFilters);
	}

	public selectItem(item: T) {
		// Select the item
		this.setState({
			selectedItems: [item],
		});

		// Open Details tab
		this._sidePanel.current?.selectTab("details");

		// Go to the page on which the item is located
		this.table?.goToItem(item);
	}

	private onSelectScreen = (id: ScreenType) => {
		this.setState({selectedScreen: id});

		const sidePanel = this._sidePanel.current;

		if (sidePanel) {
			if (id === ScreenType.GRID || (id === ScreenType.CARD && this.props.feature === XyiconFeature.Space)) {
				sidePanel.selectTab("details");
			} else {
				sidePanel.selectTab("grid");
			}
		}
	};

	private onShareClick = (viewId: string) => {
		const {feature} = this.props;

		viewId = viewId || this.props.appState.selectedViewId[feature];

		this.toggleSharingPanel(viewId);
	};

	private toggleSharingPanel = (viewId?: string) => {
		this.setState({sharingViewId: viewId});
	};

	private onManageColumns = () => {
		this.setState({managingColumns: true});
	};

	private onCloseManageColumns = () => {
		this.setState({managingColumns: false});
	};

	private onCloseCreate = (createdId?: string) => {
		this.setState({creating: false});
		if (createdId) {
			const {feature, appState} = this.props;

			this.onSelect(
				appState.actions
					.getList(feature)
					.filter((item) => item.id === createdId) // TODO optimize
					.filter((item) => Boolean(item)) as T[],
			);
			// this.setState({
			// 	selectedItems: appState.actions.getList(feature)
			// 		.filter(item => item.id === createdId)// TODO optimize
			// 		.filter(item => Boolean(item)) as T[]
			// });
			this.table?.scrollToItemId(createdId);
		}
	};

	private async refreshList() {
		const {feature, transport} = this.props;

		this.setState({
			selectedItems: [],
			isPortTemplateEditorOpen: false,
			isLoadingDependencies: true,
		});

		await transport.services.feature.refreshList(feature);

		this.setState({
			isLoadingDependencies: false,
		});
	}

	private onSelect = (items: T[], isGoToItemNeeded: boolean = true, disableTabChange?: boolean) => {
		this.setState({
			selectedItems: items,
			isPortTemplateEditorOpen: false,
		});

		if (items.length) {
			const sidePanel = this._sidePanel.current;

			if (sidePanel && !disableTabChange) {
				sidePanel.selectTab("details");
			}

			// is there anything that need the items[0] if items.length > 1?
			isGoToItemNeeded && this.table?.goToItem(items[items.length - 1]);
		}

		if (items.length === 1) {
			this.switchToSelectedPortfolio(items[0].id);
		}
	};

	private onCreateUnplottedXyicons = async (params: ICreateUnplottedXyiconParam[]) => {
		const {result, error} = await this.props.transport.requestForOrganization<XyiconDto>({
			url: "xyicons/createunplotted",
			method: XHRLoader.METHOD_PUT,
			params: {
				portfolioID: this.props.appState.portfolioId,
				xyiconCreateDetail: params.map((p) => ({xyiconCatalogID: p.catalogId, parentXyiconID: p.parentXyiconId, fieldValues: {}})),
			},
		});

		if (Array.isArray(result) && result.length > 0) {
			const newXyicons: T[] = [];

			for (const element of result) {
				newXyicons.push(this.props.appState.actions.addToList(element, XyiconFeature.Xyicon) as T);
			}
			const {items: filteredItems} = this.getFilteredItems(newXyicons);

			if (filteredItems.length < result.length) {
				notify(this.props.app.notificationContainer, {
					lifeTime: Infinity,
					type: NotificationType.Warning,
					title: "Current view will hide your new xyicon!",
					description:
						"The current view prevents your new xyicon from displaying on the space. Click Edit Details and change the fields to make your new xyicon visible.",
					buttonLabel: "Edit Details",
					onActionButtonClick: () => this.onSelect(newXyicons),
				});
			} else {
				this.onSelect(newXyicons);
			}
		} else {
			notify(this.props.app.notificationContainer, {
				type: NotificationType.Error,
				title: "Error",
				description: "Sorry, something went wrong",
			});
		}
	};

	private switchToSelectedPortfolio(portfolioId: string) {
		const {transport, feature} = this.props;

		if (feature === XyiconFeature.Portfolio) {
			transport.services.auth.switchPortfolio(portfolioId);
		}
	}

	private onDoubleClick = (item: T) => {
		const {navigation} = this.props;

		if (item.ownFeature === XyiconFeature.Space) {
			navigation.goApp(NavigationEnum.NAV_SPACE, item.id);
		} else if (item.ownFeature === XyiconFeature.Portfolio) {
			this.onSelect([item]);
			navigation.goApp(NavigationEnum.NAV_SPACES);
		} else if (item.ownFeature === XyiconFeature.Boundary || item.ownFeature === XyiconFeature.Xyicon) {
			this.props.appState.actions.navigateToSpaceItemById(item.id, item.ownFeature, true);
		}
	};

	private getFilteredItems(unfilteredItems: T[]) {
		if (this.state.searching) {
			// Only showing the search spinner currently -> don't do any heavy calculations,
			// just return empty result
			return {
				items: [] as T[],
				filterSuppression: false,
			};
		}
		const {filterPermissions, appState} = this.props;

		let items = unfilteredItems;
		let filterSuppression = false;

		const itemCount = items.length;

		// Use local, applied filters
		items = filterModels(items, this.selectedView.filters, appState);

		if (items.length !== itemCount) {
			filterSuppression = true;
		}

		// Permissions
		if (filterPermissions) {
			items = items.filter(filterPermissions);
		}

		return {
			items: items as T[],
			filterSuppression: filterSuppression,
		};
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_BACKSPACE:
			case KeyboardListener.KEY_DELETE:
				if (this.state.selectedItems.length > 0) {
					this.onDeleteClick();
				}
				break;
			case KeyboardListener.KEY_ESCAPE:
				if (!KeyboardListener.isEventTargetAnInput(event)) {
					this.setState({creating: false});
				}
				break;
		}
	};

	private getNumberOfSelectedItems() {
		return this.props.appState.actions.getNumberOfModels(this.state.selectedItems);
	}

	private onManageVersionSetsClick = () => {
		this.setState({isManageVersionSetsPanelOpen: true});
	};

	private onCloseManageVersionSets = () => {
		this.setState({isManageVersionSetsPanelOpen: false});
	};

	private onDeleteClick = async () => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;

			const {selectedItems} = this.state;

			let isDeletingAllowed = true;

			const {feature, appState} = this.props;

			if (this.props.feature === XyiconFeature.XyiconCatalog) {
				const isSafeToDeleteResponse = await this.props.transport.requestForOrganization({
					url: "xyiconcatalogs/issafetodelete",
					method: XHRLoader.METHOD_POST,
					params: {
						xyiconCatalogIDList: selectedItems.map((catalog) => catalog.id),
					},
				});

				if (isSafeToDeleteResponse.result?.inUseList.length === selectedItems.length) {
					isDeletingAllowed = false;
					const isSingle = selectedItems.length === 1;

					await WarningWindow.open(
						`You can't delete ${isSingle ? "this" : "these"} item${isSingle ? "" : "s"}, because ${isSingle ? "it's" : "they're"} already used as xyicons.`,
					);
				}
			} else if (this.props.feature === XyiconFeature.Report && !this.props.canDeleteReports(selectedItems)) {
				isDeletingAllowed = false;

				await WarningWindow.open(`Sorry, you don't have permission to delete this report.`);
			}

			if (isDeletingAllowed) {
				const count = this.getNumberOfSelectedItems();
				const confirmed = await PopupUtils.getDeleteConfirmationPopup(this.props.feature, count);

				if (confirmed) {
					await this.deleteItems(selectedItems);
				}
			}

			this._isDeletePopupWindowOpen = false;
		}
	};

	private async deleteItems(items: T[]) {
		const res = await this.props.appState.actions.deleteItems(items, this.props.feature);

		if (res || true) {
			// TODO only temp until delete returns empty result
			await this.refreshList();
		}
	}

	// ActionBar

	private onActionBarClick = (item: IActionBarItem<T>) => {
		if (item.onClick) {
			item.onClick(this.state.selectedItems);
		} else {
			const handler = this._actionBar.handlers[item.id];

			if (handler) {
				handler();
			} else {
				console.warn("No handler and default onClick for actionBar", item);
			}
		}
	};

	private isActionBarItemDisabled = (item: IActionBarItem<T>) => {
		const {selectedItems} = this.state;

		if (item.enabled) {
			return !item.enabled(selectedItems);
		} else {
			const enabled = this._actionBar.enabled[item.id];

			if (enabled) {
				return !enabled(selectedItems);
			} else {
				console.warn("No default disabled for actionBar", item);
				return true;
			}
		}
	};

	private canImport = () => {
		const {appState, feature} = this.props;
		const {actions, user} = appState;

		if (user?.isAdmin) {
			return true;
		}

		//TODO?: add this logic to getFieldPermission and hasAnyTypeTheGivenPermissionInModule
		const permissionSet: PermissionSet = actions.getIndividualPortfolioPermissionSet(appState.portfolioId);

		if (permissionSet) {
			if (!permissionSet.isAccessEnabled(feature)) {
				return false;
			}
		} else {
			const groupPermissionSet = actions.getPortfolioGroupPermissionSets(appState.portfolioId)[0];

			if (!groupPermissionSet?.isAccessEnabled(feature)) {
				return false;
			}
		}

		if (actions.hasAnyTypeTheGivenPermissionInModule(feature, Permission.Update)) {
			const fields = actions.getFieldsByFeature(feature);

			return fields.some((field) => actions.getFieldPermission(field) >= Permission.Update);
		}
		return false;
	};

	private onDuplicateClick = (model: T, isCreatePanelNeeded: boolean) => {
		if (model) {
			this._creationCount++;
			this.setState({creating: isCreatePanelNeeded});
			this.props.onDuplicate(model);
		}
	};

	private onActionBarEditClick = () => {
		const model = this.state.selectedItems[0];

		if (model) {
			if (model.ownFeature === XyiconFeature.XyiconCatalog) {
				this.onCatalogEditClick(model as IModel as Catalog);
			} else {
				this._creationCount++;
				this.setState({creating: true});
				this.props.onEdit(model);
			}
		}
	};

	private onActionBarDuplicateClick = async (item?: T) => {
		if (this.props.feature === XyiconFeature.Portfolio) {
			const confirm = await ConfirmWindow.open(
				"Duplicating the selected portfolio will also duplicate the connected spaces, xyicons, boundaries, links, and markups. Do you wish to continue?",
				"Confirm Portfolio Duplication",
			);

			if (confirm) {
				this.onDuplicateClick(item ?? this.state.selectedItems[0], false);
			}
		} else {
			this.onDuplicateClick(item ?? this.state.selectedItems[0], true);
		}
	};

	private onAddClick = () => {
		if (this.props.feature === XyiconFeature.Xyicon) {
			this.props.setCatalogPanelOpen?.(true);
		} else {
			this.switchToCreatingMode();
			this.props.onAdd?.();
		}
	};

	private onImportClick = () => {
		this.setState({
			isFeatureImportPanelOpen: true,
		});
	};

	private onCloseImportClick = () => {
		this.setState({
			isFeatureImportPanelOpen: false,
		});
	};

	public switchToCreatingMode() {
		this._creationCount++;
		this.setState({creating: true});
	}

	private onSplitterChange = (ratios: number[]) => {
		const {transport} = this.props;

		transport.services.localStorage.setSplitterRatios(ratios);
	};

	private onTableSearch = (value: string) => {
		const {feature, appState} = this.props;
		const list = appState.actions.getList(feature);

		if (list.length > Debouncer.limit) {
			// search would be too slow to trigger for each keystroke if the list is long -> add debouncer
			// First wait until user stopped typing
			this._typingDebouncer.debounce(() => {
				// User stopped typing

				if (this.props.appState.actions.areModelsCached()) {
					// models are cached -> no need to show spinner because the search will be fast
					this.setState({
						search: value,
						searching: false,
					});
				} else {
					// show searching is in progress
					this.setState({searching: true});

					// Wait one more second before search starts to allow the search spinner to be rendered
					// (UI gets frozen during search so it helps the user to see a spinner)
					this._listDebouncer.debounceList(() => {
						// spinner is now visible, now start the search by setting the state
						this.setState({
							search: value,
							searching: false,
						});
					}, list);
				}
			});
		} else {
			this.setState({
				search: value,
				searching: false,
			});
		}
	};

	private subscribeViewSelection() {
		this._disposer = observe(this.props.appState.selectedViewId, (this.props.feature as any).toString(), (a) => {
			this.resetFilters();
		});
	}

	private unsubscribeViewSelection() {
		this._disposer?.();
		this._disposer = null;
	}

	private onSplitterMounted = (splitter: Splitter) => {
		if (splitter) {
			const ratios = this.props.transport.services.localStorage.getSplitterRatios();

			if (ratios && ratios.length >= 2) {
				splitter.setRatios(ratios);
			}
		}
	};

	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	private onCatalogEditClick = async (catalog: Catalog) => {
		if (!this._isLoadingCatalogEditor) {
			this._isLoadingCatalogEditor = true;

			const iconConfig = await this.props.transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				catalogMode: "edit",
			});

			this._isLoadingCatalogEditor = false;
		}
	};

	private onDuplicateCatalogClick = async (catalog: Catalog) => {
		if (!this._isLoadingCatalogEditor) {
			this._isLoadingCatalogEditor = true;
			const iconConfig = await this.props.transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				catalogMode: "create",
			});

			this._isLoadingCatalogEditor = false;
		}
	};

	private onAddCatalogClick = () => {
		this.setState({
			editedCatalog: null,
			iconConfig: null,
			catalogMode: "create",
		});
	};

	private onCloseCatalogEditor = () => {
		this.setState({
			editedCatalog: null,
			iconConfig: null,
			catalogMode: null,
		});
	};

	private getCatalogPanel() {
		return (
			<Dockable
				spaceViewRenderer={this.props.app.spaceViewRenderer}
				gridView={this._gridView}
				setDocked={this.props.setCatalogPanelDocked}
				setOpen={this.props.setCatalogPanelOpen}
				isDocked={this.props.isCatalogPanelDocked}
				title="Catalog"
				setActiveTool={Functions.emptyFunction}
				onAddCatalogClick={this.onAddCatalogClick}
				onDuplicateCatalogClick={this.onDuplicateCatalogClick}
				onCreateUnplottedXyicons={this.onCreateUnplottedXyicons}
			/>
		);
	}

	private onSelectView = (viewId: string) => {
		this.setState({loadingViewId: viewId});
		this.table?._table.current?.scroll({left: 0});
	};

	private get table() {
		return this.props.appState.tableComponent.current;
	}

	public override async componentDidMount() {
		this.subscribeViewSelection();

		this._isMounted = true;
		await this.refreshList();

		if (this._isMounted) {
			KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		}
	}

	public override componentWillUnmount() {
		this.unsubscribeViewSelection();
		this.forceResetFilters();
		this._isMounted = false;
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {feature, appState, className, actionBar, maps} = this.props;
		const {selectedScreen, selectedItems, creating, search, searching, initialLoading, sharingViewId, loadingViewId} = this.state;
		const loadingList = !appState.lists[feature].loaded || initialLoading || this.state.isLoadingDependencies;

		const loadingView = appState.actions.getViewById(loadingViewId);

		if (initialLoading) {
			window.setTimeout(() => {
				this.setState({initialLoading: false});
			}, 500);
		}

		const selectedView = this.selectedView;

		const unfilteredItems = appState.actions.getList(feature) as T[];
		const {items, filterSuppression} = this.getFilteredItems(unfilteredItems);

		const creatingOrEditingOrImporting = creating || this.state.catalogMode != null || this.state.isFeatureImportPanelOpen;

		return (
			<div className={`ModuleView ${className || ""}`}>
				{!this.props.isCatalogPanelDocked && this.props.isCatalogPanelOpen && this.getCatalogPanel()}
				{this.state.managingColumns && (
					<div
						className="shadowDiv"
						onClick={this.onCloseManageColumns}
					/>
				)}
				<div
					className={ReactUtils.cls("createPanel", {
						open: creatingOrEditingOrImporting || this.state.managingColumns || this.state.isManageVersionSetsPanelOpen,
						manageColumns: this.state.managingColumns,
						import: this.state.isFeatureImportPanelOpen,
						manageVersionSets: this.state.isManageVersionSetsPanelOpen,
					})}
				>
					{
						// Passing creationCount as key to reset state after create panel is closed
						creating && this.props.create && React.cloneElement(this.props.create(this.onCloseCreate), {key: this._creationCount})
					}
					{this.state.catalogMode && (
						<CatalogItemPanel
							onClose={this.onCloseCatalogEditor}
							catalog={this.state.editedCatalog}
							iconConfig={this.state.iconConfig}
							mode={this.state.catalogMode}
						/>
					)}
					{this.state.isFeatureImportPanelOpen && (
						<FeatureImportPanel
							onClose={this.onCloseImportClick}
							feature={this.props.feature}
						/>
					)}
					{this.state.isManageVersionSetsPanelOpen && <ManageVersionSets onClose={this.onCloseManageVersionSets} />}
					{this.state.managingColumns && (
						<ManageColumnsPanel
							feature={feature}
							view={selectedView}
							onClose={this.onCloseManageColumns}
						/>
					)}
				</div>
				<div className={ReactUtils.cls("sharingPanel", {open: sharingViewId})}>
					<EditViewSharing
						view={appState.actions.getViewById(sharingViewId)}
						feature={feature}
						close={() => this.toggleSharingPanel()}
					/>
				</div>
				<Splitter
					ref={this.onSplitterMounted}
					onChange={this.onSplitterChange}
					className={ReactUtils.cls({blurred: creatingOrEditingOrImporting || sharingViewId})}
				>
					{this.props.isCatalogPanelDocked && this.props.isCatalogPanelOpen && this.getCatalogPanel()}
					<div className="vbox flex_1 mainpanel">
						<div className="barContainer">
							<h3>{appState.selectedModuleTitle}</h3>
							{this.props.viewBar !== false && (
								<ViewBar
									feature={feature}
									selectedView={loadingView ?? selectedView}
									items={items}
									onSelect={this.onSelectView}
									selectedScreen={selectedScreen}
									onSelectScreen={this.onSelectScreen}
									onShareClick={this.onShareClick}
									maps={maps}
								/>
							)}
						</div>
						<div className="filtersAndSearch hbox alignCenter">
							{!this.state.managingColumns && (
								<UnsavedLayout
									feature={feature}
									selectedView={selectedView}
								/>
							)}
							<FilterChangeNotification
								actions={appState.actions}
								sidePanelRef={this._sidePanel}
								feature={feature}
							/>
							<SearchField
								value={search}
								onInput={this.onTableSearch}
								placeholder={"Find ..."}
							/>
							<ActionBar>
								{actionBar.map(
									(item) =>
										item.componentFactory?.(selectedItems) || (
											<Button
												key={item.id}
												className="gray large"
												title={item.title}
												icon={item.icon ?? item.id}
												disabled={this.isActionBarItemDisabled(item)}
												onClick={(e) => this.onActionBarClick(item)}
												label={item.label}
											/>
										),
								)}
							</ActionBar>
							{this.props.manageColumns !== false && selectedScreen === ScreenType.GRID && (
								<Button
									icon="manage-columns"
									label="Manage Columns"
									className="large gray"
									onClick={this.onManageColumns}
								/>
							)}
						</div>
						<ViewStack active={selectedScreen}>
							<StackChild id={ScreenType.GRID}>
								{loadingList ? (
									<div className="loaderText vbox alignCenter justifyCenter">
										<LoaderIcon />
										{`Loading ${featureTitlePlurals[feature as keyof typeof featureTitlePlurals]}...`}
									</div>
								) : (
									<>
										{loadingView && (
											<div className="loaderText vbox alignCenter justifyCenter">
												<LoaderIcon />
												{`Loading "${loadingView.name}" View`}
											</div>
										)}
										<GridView
											ref={this._gridView}
											feature={feature}
											items={items}
											filterSuppression={filterSuppression}
											selected={selectedItems}
											view={selectedView}
											onSelect={this.onSelect}
											onDoubleClick={this.onDoubleClick}
											onAddClick={this.props.onAdd && this.onAddClick}
											onDuplicateClick={this.onActionBarDuplicateClick}
											search={search}
											searching={searching}
											tableRowIcon={true}
											isCellContentsWrappingOn={this.state.isCellContentsWrappingOn}
											emptyListText={this.props.emptyListText}
											onManageColumns={this.props.manageColumns !== false && this.onManageColumns}
										/>
									</>
								)}
							</StackChild>
							<StackChild id={ScreenType.CARD}>
								{loadingList ? (
									<div className="loaderText vbox alignCenter justifyCenter">
										<LoaderIcon />
										{`Loading ${featureTitlePlurals[feature as keyof typeof featureTitlePlurals]}...`}
									</div>
								) : (
									<CardView
										feature={feature}
										items={items}
										filterSuppression={filterSuppression}
										selected={selectedItems}
										onSelect={this.onSelect}
										onDoubleClick={this.onDoubleClick}
										onAddClick={this.onAddClick}
										search={search}
									/>
								)}
							</StackChild>
							{maps && (
								<StackChild id={ScreenType.GMAP}>
									<MapsPanel />
								</StackChild>
							)}
							<StackChild id={ScreenType.CHART}>
								<ChartsView portfolios={items as any} />
							</StackChild>
						</ViewStack>
					</div>
					<div
						className="vbox sidePanelContainer"
						ref={this._sidePanelContainer}
					>
						{!initialLoading && (
							<SidePanel
								ref={this._sidePanel}
								features={[feature]}
								feature={feature}
								items={items}
								selected={selectedItems}
								onSelect={this.onSelect}
								isPortTemplateEditorOpen={this.state.isPortTemplateEditorOpen}
								setPortTemplateEditorOpen={(value: boolean) => this.setState({isPortTemplateEditorOpen: value})}
								showGridForFeatures={selectedScreen !== ScreenType.GRID ? [feature] : []}
								onCatalogIconEditClick={this.onCatalogEditClick}
								onClick={Functions.emptyFunction}
								onManageColumns={this.onManageColumns}
								loading={loadingList}
							/>
						)}
					</div>
				</Splitter>
			</div>
		);
	}
}

import {Observer} from "mobx-react";
import type {MouseEvent} from "react";
import {useEffect, useReducer, useRef, useState} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {useAppStore} from "../../../StateManager";
import ListIcon from "../icons/list.svg?react";
import FrameIcon from "../icons/frame.svg?react";
import CloseIcon from "../icons/xmark.svg?react";
import ChevronDownDouble from "../icons/chevron-down-double.svg?react";
import EyeIcon from "../icons/eye-alt.svg?react";
import EyeSlashIcon from "../icons/eye-slash-alt.svg?react";
import TrashIcon from "../icons/trash.svg?react";
import {XyiconFeature} from "../../../generated/api/base";
import {IconButtonV5, IconButtonStyled} from "../interaction/IconButtonV5";
import type {View} from "../../../data/models/View";
import {colorPalette} from "../styles/colorPalette";
import {ELLIPSIS, FLEXCENTER, radius} from "../styles/styles";
import type {AppState} from "../../../data/state/AppState";
import {useClickOutside} from "../utils";
import {
	EventNameForViewsChangeInLocalStorage,
	getActiveWorkspaceViewTabFromLocalStorage,
	getWorkspaceViewTabsFromLocalStorage,
	onWorkspaceViewClick,
	removeAllWorkspaceViewTabsExceptSelected,
	removeWorkspaceViewFromTabs,
} from "./ViewTabsCommon";

const viewTabWidth = 152;

interface IViewSimple {
	id: string;
	name: string;
	icon: "list" | "frame";
	isActive: boolean;
}

const renderViewTab = (
	viewSimple: IViewSimple,
	appState: AppState,
	setViewForOpenSpaceSelector: (value: View | null) => void,
	forceUpdate: () => void,
	forcePutInBeginningOfList: boolean,
) => {
	const view = appState.actions.getViewById(viewSimple.id);
	const onCloseClick = (event: MouseEvent) => {
		event.stopPropagation();
		removeWorkspaceViewFromTabs(view, setViewForOpenSpaceSelector);
		forceUpdate();
	};

	return (
		<ViewTabStyled
			key={view.id}
			className={ReactUtils.cls({selected: viewSimple.isActive})}
			onClick={() => onWorkspaceViewClick(appState.actions.getViewById(view.id), setViewForOpenSpaceSelector, forcePutInBeginningOfList)}
			title={view.name}
		>
			<IconStyled className="icon viewIcon">{viewSimple.icon === "frame" ? <FrameIcon /> : <ListIcon />}</IconStyled>
			<TabNameStyled>{view.name}</TabNameStyled>
			<IconButtonV5
				IconComponent={CloseIcon}
				onClick={onCloseClick}
			/>
		</ViewTabStyled>
	);
};

export const ViewTabsV5 = () => {
	const appState = useAppStore((state) => state.appState);
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);
	const viewTabsRef = useRef<HTMLDivElement>();
	const dropDownRef = useRef<HTMLDivElement>();
	const chevronDownRef = useRef<HTMLDivElement>();
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [areTabsVisible, setAreTabsVisible] = useState<boolean>(true);
	const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(appState.user?.id, appState.organizationId);
	const activeViewTabInLocalStorage = getActiveWorkspaceViewTabFromLocalStorage(appState.user?.id, appState.organizationId);

	const onToggleDropdown = () => {
		setIsDropdownOpen((o) => !o);
	};

	const onToggleTabsVisibility = () => {
		setAreTabsVisible((v) => !v);
	};

	const onCloseOtherTabsClick = () => {
		removeAllWorkspaceViewTabsExceptSelected(appState.user?.id, appState.organizationId);
		forceUpdate();
	};

	useEffect(() => {
		window.addEventListener(EventNameForViewsChangeInLocalStorage, forceUpdate);

		return () => {
			window.removeEventListener(EventNameForViewsChangeInLocalStorage, forceUpdate);
		};
	}, []);

	useEffect(() => {
		window.addEventListener("resize", forceUpdate);
		forceUpdate();

		return () => {
			window.removeEventListener("resize", forceUpdate);
		};
	}, []);

	useClickOutside([dropDownRef, chevronDownRef], () => {
		setIsDropdownOpen(false);
	});

	let maxNumberOfViewsOnTopBar: number = 8; // full hd

	if (viewTabsRef.current) {
		const rect = viewTabsRef.current.getBoundingClientRect();

		maxNumberOfViewsOnTopBar = Math.max(1, Math.floor(rect.width / viewTabWidth));
	}

	return (
		<Observer>
			{() => {
				const selectedFeature = appState.selectedFeature;
				const views: IViewSimple[] = [];

				if ([XyiconFeature.Boundary, XyiconFeature.Xyicon, XyiconFeature.SpaceEditor].includes(selectedFeature)) {
					views.push(
						...viewTabsInLocalStorage
							.map((viewTab) => appState.actions.getViewById(viewTab.viewId))
							.filter((v) => !!v)
							.map((view: View) => ({
								id: view.id,
								name: view.name,
								icon: (view.itemFeature === XyiconFeature.SpaceEditor ? "frame" : "list") as "frame" | "list",
								isActive: view.id === activeViewTabInLocalStorage?.viewId,
							})),
					);

					if (views.length === 0) {
						const selectedView = appState.actions.getSelectedView(selectedFeature);

						onWorkspaceViewClick(selectedView, setViewForOpenSpaceSelector);
					}

					const viewsInTopBar = areTabsVisible ? views.slice(0, maxNumberOfViewsOnTopBar) : [views.find((v) => v.isActive)];
					const viewsInDropdown = areTabsVisible ? views.slice(maxNumberOfViewsOnTopBar) : views.filter((v) => !v.isActive);

					return (
						<ViewTabsWrapper>
							<ViewTabsBarStyled
								className="ViewTabs"
								ref={viewTabsRef}
							>
								{viewsInTopBar.map((viewSimple) => renderViewTab(viewSimple, appState, setViewForOpenSpaceSelector, forceUpdate, false))}
							</ViewTabsBarStyled>
							<RightSideStyled>
								{!areTabsVisible && (
									<IconButtonV5
										IconComponent={EyeIcon}
										onClick={onToggleTabsVisibility}
										title="Hidden Tabs: Click to Show"
									/>
								)}
								<IconButtonV5
									IconComponent={ChevronDownDouble}
									onClick={onToggleDropdown}
									divRef={chevronDownRef}
								/>
								{isDropdownOpen && (
									<DropdownContentStyled ref={dropDownRef}>
										<ViewInsideDropdownContainer>
											{viewsInDropdown.map((viewSimple) => renderViewTab(viewSimple, appState, setViewForOpenSpaceSelector, forceUpdate, true))}
										</ViewInsideDropdownContainer>
										{viewsInDropdown.length > 0 && <HorizontalLine />}
										<ViewTabStyled onClick={onCloseOtherTabsClick}>
											<IconStyled>
												<TrashIcon />
											</IconStyled>
											<TabNameStyled>Close Other Tabs</TabNameStyled>
										</ViewTabStyled>
										<ViewTabStyled onClick={onToggleTabsVisibility}>
											<IconStyled>{areTabsVisible ? <EyeSlashIcon /> : <EyeIcon />}</IconStyled>
											<TabNameStyled>{areTabsVisible ? "Hide Tabs" : "Show Tabs"}</TabNameStyled>
										</ViewTabStyled>
									</DropdownContentStyled>
								)}
							</RightSideStyled>
						</ViewTabsWrapper>
					);
				}

				return <></>;
			}}
		</Observer>
	);
};

const HorizontalLine = styled.div`
	border-top: 1px solid #c8c8c8;
`;

const dropdownViewNumberScrollThreshold = 10;
const viewTabHeight = 32;
const dropdownPadding = 8;
const dropdownGap = 8;

const ViewInsideDropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${dropdownGap}px;
	max-height: ${dropdownViewNumberScrollThreshold * viewTabHeight + (dropdownViewNumberScrollThreshold - 1) * dropdownGap}px;
	overflow-y: auto;
`;

const DropdownContentStyled = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: calc(100% + 8px);
	right: 0;
	color: #333;
	background-color: #ffffff;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 2;
	padding: ${dropdownPadding}px;
	border-radius: ${radius.md};
	gap: ${dropdownGap}px;
`;

const RightSideStyled = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	svg {
		width: 16px;
		height: 16px;
	}
	${DropdownContentStyled} {
		z-index: 2;
		visibility: visible;
		top: calc(100% + 8px);
		min-width: initial;
		right: 0;
		left: initial;
	}
`;

const ViewTabStyled = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	min-height: ${viewTabHeight}px;
	padding: 0 8px;
	gap: 8px;
	border-radius: ${radius.md};
	position: relative;
	width: ${viewTabWidth}px;

	${IconButtonStyled} {
		min-width: 20px;
		width: 20px;
		height: 20px;
		display: none;
	}

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		${IconButtonStyled} {
			display: flex;
			&:hover {
				background-color: ${colorPalette.gray.c400};
			}
		}

		&.selected {
			${IconButtonStyled} {
				&:hover {
					background-color: ${colorPalette.primary.c950};
				}
			}
		}
	}

	&.selected {
		background-color: ${colorPalette.primary.c500Primary};
		color: white;

		.icon {
			fill: white;
		}

		.closeIcon {
			background-color: ${colorPalette.gray.c700Dark};

			&:hover {
				background-color: ${colorPalette.primary.c800};
			}
		}

		&:hover {
			background-color: ${colorPalette.primary.c700Dark};
		}
	}
`;

const IconStyled = styled.div`
	min-width: 16px;
	width: 16px;
	height: 16px;
	${FLEXCENTER}
	fill: ${colorPalette.gray.c950};

	.icon {
		width: 12px;
		height: 12px;
		min-width: 12px;
		min-height: 12px;
	}

	&.closeIcon {
		position: absolute;
		right: 8px;
		border-radius: ${radius.xs};
		background-color: ${colorPalette.gray.c200Light};

		&:hover {
			background-color: ${colorPalette.gray.c300};
		}
	}
`;

const TabNameStyled = styled.span`
	font-size: 14px;
	line-height: 16px;
	${ELLIPSIS}
	flex: 1;
`;

const ViewTabsBarStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	width: calc(100vw - 624px);
	overflow-x: hidden;
`;

const ViewTabsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	padding: 0 8px;
`;

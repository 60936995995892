import {Vector2} from "three";
import type {Xyicon3D} from "../elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {CaptionedItem} from "../managers/MSDF/CaptionManager";
import {CaptionManager} from "../managers/MSDF/CaptionManager";
import type {BoundarySpaceMap3D} from "../elements3d/BoundarySpaceMap3D";
import type {ICaption} from "../renderers/SpaceViewRendererUtils";
import {getRadiusForCaptionCollision, setCaptionToDefaultPosition, updateCaption} from "../renderers/SpaceViewRendererUtils";
import type {IRect} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {ICaptionConfig} from "../../../../../../data/models/ViewUtils";

export class CaptionCollisionSolver {
	private _spaceViewRenderer: SpaceViewRenderer;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	private get _xyiconManager() {
		return this._spaceViewRenderer.xyiconManager;
	}

	private get _boundaryManager() {
		return this._spaceViewRenderer.boundaryManager;
	}

	private get _captionedItems() {
		return [...this._boundaryManager.items.array, ...this._xyiconManager.items.array] as CaptionedItem[];
	}

	private getMaxCaptionFontSize() {
		const actions = this._spaceViewRenderer.actions;
		const captionConfigMaybe = actions.getSelectedView(XyiconFeature.SpaceEditor).spaceEditorViewSettings?.captions;

		const maxFontSizeMaybe = Math.max(captionConfigMaybe?.xyicon.fontSize, captionConfigMaybe?.boundary.fontSize);
		let maxFontSize = isNaN(maxFontSizeMaybe) ? actions.getDefaultCaptionSettings().fontSize : maxFontSizeMaybe;

		for (const key in captionConfigMaybe) {
			for (const fieldRefId in captionConfigMaybe[key as keyof ICaptionConfig].individualCaptionStyles) {
				const fontSize = captionConfigMaybe[key as keyof ICaptionConfig].individualCaptionStyles[fieldRefId].fontSize;

				if (maxFontSize < fontSize) {
					maxFontSize = fontSize;
				}
			}
		}

		return maxFontSize;
	}

	public updateCaptions(itemsToUpdate: CaptionedItem[]) {
		const captionedItems = this._captionedItems;

		const visibleXyicons: IRect[] = captionedItems
			.filter((spaceItem) => spaceItem.spaceItemType === "xyicon" && spaceItem.isVisible)
			.map((spaceItem) => ({
				position: spaceItem.position,
				scale: THREEUtils.getSizeOfBoundingBox2((spaceItem as Xyicon3D).boundingBoxOnMeshSizeEnd),
			}));
		const visibleCaptions: ICaption[] = [];

		for (const item of captionedItems) {
			if (item.caption?.isVisible && item.caption.scale) {
				visibleCaptions.push({
					text: item.caption.text,
					position: item.caption.position,
					scale: item.caption.scale,
					parent: {
						id: item.modelData.id,
						spaceItemType: item.spaceItemType as "xyicon" | "boundary",
						position: item.position,
						scale: THREEUtils.getSizeOfBoundingBox2(item.boundingBox),
					},
				});
			}
		}

		const captionsToUpdate: ICaption[] = itemsToUpdate
			.map((item) => item.caption)
			.filter((caption) => caption?.isVisible)
			.map((c) => ({
				text: c.text,
				position: c.position,
				scale: c.scale,
				parent: {
					id: c.parent.modelData.id,
					spaceItemType: c.parent.spaceItemType as "xyicon" | "boundary",
					position: c.parent.position,
					scale: THREEUtils.getSizeOfBoundingBox2(c.parent.boundingBox),
					geometryData: (c.parent as BoundarySpaceMap3D).data?.geometryData,
					item3d: c.parent,
				},
			}));

		const maxCaptionFontSize = this.getMaxCaptionFontSize();
		const radius = getRadiusForCaptionCollision(this._spaceViewRenderer.correctionMultiplier.current, maxCaptionFontSize);
		const v2 = new Vector2();
		const filterByDistance = (itemToCompare: ICaption | CaptionedItem) => v2.distanceTo(itemToCompare.position as unknown as Vector2) < radius;

		for (let i = 0; i < captionsToUpdate.length; ++i) {
			const caption = captionsToUpdate[i];

			setCaptionToDefaultPosition(caption, this._spaceViewRenderer.correctionMultiplier.current);
			v2.set(caption.position.x, caption.position.y);

			const captionsThatWillBeUpdated = captionsToUpdate.slice(i);

			updateCaption(
				caption,
				visibleXyicons.filter(filterByDistance),
				visibleCaptions.filter(
					(c) =>
						c.parent.id !== caption.parent.id &&
						v2.distanceTo(c.position as unknown as Vector2) < radius &&
						!captionsThatWillBeUpdated.some((cap) => cap.parent.id === c.parent.id),
				),
				this._spaceViewRenderer.correctionMultiplier.current,
			);

			if (caption.parent.spaceItemType === "xyicon") {
				(this._xyiconManager.getItemById(caption.parent.id) as Xyicon3D).updateCaptionLeaderLine();
			}
		}

		this._xyiconManager.captionManager.updateTextTransformations();
		this._boundaryManager.captionManager.updateTextTransformations();
	}

	public updateCaptionsInARadius(x: number, y: number, radius: number) {
		const radiusSq = radius * radius; // we compare to distanceSquared because it's a little bit faster
		const center = new Vector2(x, y);

		const spaceItemsToUpdate = (
			[
				...this._boundaryManager.items.array.sort((a: BoundarySpaceMap3D, b: BoundarySpaceMap3D) => a.area - b.area),
				...this._xyiconManager.items.array,
			] as CaptionedItem[]
		)
			.filter(CaptionManager.filterVisibleCaptionedItems)
			.filter(
				(item: CaptionedItem) =>
					center.distanceToSquared(item.position as unknown as Vector2) < radiusSq ||
					center.distanceToSquared(item.caption.position as Vector2) < radiusSq,
			);

		this.updateCaptions(spaceItemsToUpdate);
	}
}

import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {SingleLineLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/singleline/SingleLineLabel";
import {PhoneInput} from "../../../../ui/widgets/input/phone/PhoneInput";
import {SingleLineLabelV5} from "../../../../ui/5.0/details/datatypes/SingleLineLabelV5";
import {PhoneInputV5} from "../../../../ui/5.0/details/datatypes/PhoneInputV5";

export function configurePhone() {
	FieldDataTypes.configure({
		id: FieldDataType.PhoneNumber,
		title: "Phone",
		label: SingleLineLabel,
		labelV5: SingleLineLabelV5,
		input: PhoneInput,
		inputV5: PhoneInputV5,
		operatorsArray: stringOperators,
	});
}

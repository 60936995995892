import type {Object3D} from "three";
import {Mesh, PlaneGeometry, BufferAttribute, BufferGeometry, Line, LineBasicMaterial} from "three";
import {BasicMaterial} from "../../../materials/BasicMaterial";
import type {PointDouble} from "../../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../../../utils/math/MathUtils";

export class Rectangle {
	private _container: Object3D;
	private _planeGeometry: PlaneGeometry;
	private _planeMaterial: BasicMaterial;
	private _plane: Mesh;
	private _lineGeometry: BufferGeometry;
	private _lineMaterial: LineBasicMaterial;
	private _line: Line;
	private _size: PointDouble;

	constructor(container: Object3D, position: PointDouble, size: PointDouble, orientation: number, borderColor: number, fillColor?: number) {
		this._container = container;
		this._size = {...size};

		if (MathUtils.isValidNumber(fillColor)) {
			this.createPlane(fillColor);
		}

		this._lineMaterial = new LineBasicMaterial({color: borderColor});

		this._line = new Line(this._lineGeometry, this._lineMaterial);
		this._line.frustumCulled = false;

		this.update(position, size, orientation, false);
		THREEUtils.add(this._container, this._line);

		if (this._plane) {
			THREEUtils.add(this._container, this._plane);
		}
	}

	private createPlane(fillColor: number) {
		this._planeGeometry = new PlaneGeometry(1, 1);
		this._planeMaterial = new BasicMaterial(fillColor);
		this._plane = new Mesh(this._planeGeometry, this._planeMaterial);
		this._plane.scale.set(this._size.x, this._size.y, 1);
	}

	public update(position: PointDouble, size: PointDouble, orientation: number, updateMatrix: boolean = true) {
		this._size.x = size.x;
		this._size.y = size.y;

		const widthHalf = size.x / 2;
		const heightHalf = size.y / 2;

		const cornerVertices = new Float32Array([
			-widthHalf,
			heightHalf,
			0,
			-widthHalf,
			-heightHalf,
			0,
			widthHalf,
			-heightHalf,
			0,
			widthHalf,
			heightHalf,
			0,
			-widthHalf,
			heightHalf,
			0,
		]);

		const verticesAttribute = new BufferAttribute(cornerVertices, 3);

		this._lineGeometry?.dispose();
		this._lineGeometry = new BufferGeometry();
		this._lineGeometry.setAttribute("position", verticesAttribute);
		verticesAttribute.needsUpdate = true;
		this._line.geometry = this._lineGeometry;

		this._line.position.set(position.x, position.y, 0);
		this._line.rotation.z = orientation;

		if (this._plane) {
			this._plane.position.set(position.x, position.y, 0);
			this._plane.rotation.z = orientation;
			this._plane.scale.set(size.x, size.y, 1);
		}

		if (updateMatrix) {
			THREEUtils.updateMatrices(this._line);
			if (this._plane) {
				THREEUtils.updateMatrices(this._plane);
			}
		}
	}

	public updatePosition(position: PointDouble) {
		this._plane?.position.set(position.x, position.y, 0);
		this._line.position.set(position.x, position.y, 0);
		THREEUtils.updateMatrices(this._line);

		if (this._plane) {
			THREEUtils.updateMatrices(this._plane);
		}
	}

	public updateColor(borderColor: number, fillColor?: number) {
		this._lineMaterial.color.set(borderColor);

		if (MathUtils.isValidNumber(fillColor)) {
			if (this._plane) {
				this._planeMaterial?.setColor(fillColor);
			} else {
				this.createPlane(fillColor);
			}
		}
	}

	public get size() {
		return {...this._size};
	}

	public get position() {
		return {
			x: this._line.position.x,
			y: this._line.position.y,
		};
	}

	public get orientation() {
		return this._line.rotation.z;
	}

	public get fillColor() {
		return this._planeMaterial?.color;
	}

	public get opacity() {
		return this._planeMaterial?.opacityValue;
	}

	public dispose() {
		this._line?.removeFromParent();
		this._lineGeometry?.dispose();
		this._lineMaterial?.dispose();

		this._plane?.removeFromParent();
		this._planeGeometry?.dispose();
		this._planeMaterial?.dispose();
	}
}

import type {IModel} from "../models/Model";
import type {AppState} from "../state/AppState";
import type {View} from "../models/View";
import type {IFieldPointer} from "../models/field/Field";
import {DateFormatter} from "../../utils/format/DateFormatter";
import {XyiconFeature} from "../../generated/api/base";
import type {Report} from "../models/Report";

export type ExportFormat = "csv" | "pdf" | "excel";

export abstract class BaseExporter {
	protected abstract readonly _extension: "csv" | "pdf" | "xlsx";

	protected _appState: AppState;

	constructor(appState: AppState) {
		this._appState = appState;
	}

	public abstract exportTable(data: (string | number)[][], fileName: string, feature: XyiconFeature, report?: Report): void;

	public exportView<T extends IModel>(view: View, items: T[]) {
		const sortedItems = this._appState.actions.sortItems(items, view);

		this._exportView(view, sortedItems);
	}

	protected getFileName(view: View) {
		return `${view.name}_${DateFormatter.timeStampForDownload()}.${this._extension}`;
	}

	protected abstract _exportView<T extends IModel>(view: View, items: T[]): void;

	protected getFieldRefIds(view: View): IFieldPointer[] {
		// TODO for now hardcode SpaceEditor to Xyicon columns
		return view.getValidViewColumns(XyiconFeature.Xyicon).map((column) => column.field);
	}

	protected getHeaders(fields: IFieldPointer[]): string[] {
		return fields.map((field) => this._appState.actions.getFieldTitle(field));
	}

	protected getFieldData<T extends IModel>(item: T, fieldPointer: IFieldPointer): any[] {
		return this._appState.actions.renderValues(item, fieldPointer);
	}

	public get extension() {
		return this._extension;
	}
}

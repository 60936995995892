import * as React from "react";
import {inject} from "mobx-react";
import {FocusLoss} from "../../../utils/ui/focus/FocusLoss";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {AppState} from "../../../data/state/AppState";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {DropdownOptions} from "./DropdownOptions";
import type {IDropdownOption, ISubOptionWrapper} from "./DropdownOptions";

interface IDropdownButtonProps {
	appState?: AppState;
	button: React.ReactElement;
	options: (IDropdownOption | ISubOptionWrapper)[];
	className?: string;
	label?: string;
}

interface IDropdownButtonState {
	open: boolean;
	optionsTransform: TransformObj;
}

@inject("appState")
export class DropdownButton extends React.Component<IDropdownButtonProps, IDropdownButtonState> {
	private _container = React.createRef<HTMLDivElement>();
	private _floatingElement = React.createRef<HTMLDivElement>();

	constructor(props: IDropdownButtonProps) {
		super(props);

		this.state = {
			open: false,
			optionsTransform: null,
		};
	}

	private onClick = (event: React.MouseEvent<HTMLDivElement>) => {
		this.setState((prevState) => {
			if (prevState.open) {
				FocusLoss.stopListen(this._container.current, this.onFocusLoss);
			} else {
				FocusLoss.listen(this._container.current, this.onFocusLoss, undefined, "up");
			}

			return {open: !prevState.open};
		});
	};

	private onFocusLoss = (event: Event) => {
		if (!(event.target instanceof Window) && !this._floatingElement.current?.contains(event.target as HTMLElement)) {
			this.close();
		}

		return false;
	};

	private close = () => {
		this.setState({open: false});
	};

	private get modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	public override componentDidUpdate(prevProps: IDropdownButtonProps, prevState: IDropdownButtonState) {
		if (!prevState.open && this.state.open && this._container.current && this._floatingElement.current) {
			this.setState({
				optionsTransform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._floatingElement.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.center,
					0,
					-2,
					true,
				),
			});
		}
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._container.current, this.onFocusLoss);
	}

	public override render() {
		const {className, label, options} = this.props;
		const button = React.cloneElement(this.props.button, {onClick: this.onClick});

		const inlineStyle: React.CSSProperties = this._floatingElement.current && {
			transform: this.state.optionsTransform?.translate,
		};

		return (
			<div
				ref={this._container}
				className={`DropdownButton button ${className || ""}`}
			>
				{button}
				{this.state.open && (
					<DomPortal destination={this.modalContainer}>
						<DropdownOptions
							divRef={this._floatingElement}
							style={inlineStyle}
							label={label}
							options={options}
							onClose={this.close}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

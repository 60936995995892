import type {FormEvent, RefObject} from "react";
import React, {Component, createRef} from "react";
import styled from "styled-components";
import type {XyiconFeature} from "../../../generated/api/base/models/XyiconFeature";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {featureTitles} from "../../../data/state/AppStateConstants";
import {AppUtils} from "../../../utils/AppUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../utils/TimeUtils";
import {FocusLoss} from "../../../utils/ui/focus/FocusLoss";
import type {IModel} from "../../../data/models/Model";
import {colorPalette} from "../styles/colorPalette";
import {ButtonV5} from "../button/ButtonV5";
import {radius} from "../styles/styles";
import {PopupV5} from "../popup/PopupV5";
import {SelectSearchFieldV5} from "../input/search/SelectSearchFieldV5";

interface IFeatureSection<T> {
	items: T[];
	feature: XyiconFeature;
	sortFunction: (a: T, b: T) => number;
	renderAddedListItem: (item: T) => React.ReactNode;
	renderAddListItem: (item: T) => React.ReactNode;
	onItemAdd: (item: T) => void;
	onItemSelect: (item: T) => void;
	onItemRemove: (item: T) => void;
	lockItems?: boolean;
}

interface ISearchAddAndListItemsProps<T> {
	addedItems: T[];
	search: string;
	sections: IFeatureSection<T>[];
	searchTextPlaceholder: string;
	popupLable: string;
	onSearch: (value: string) => void;
	prohibitSearch?: boolean;
	isPopupOpen?: boolean;
	parentRef?: RefObject<HTMLDivElement>;
	onClose?: () => void;

	selectedItemList?: any[];
	getSelectedItems: (items: any[]) => void;
}

export interface SelectedItem {
	id: string;
	itemName: string;
	feature: XyiconFeature.User | XyiconFeature.Portfolio;
}

interface ISearchAddAndListItemsState {
	open: boolean;
	transform: TransformObj;

	selectedItems?: any[];
}

export class SearchAddAndListItemsV5<T extends IModel> extends Component<ISearchAddAndListItemsProps<T>, ISearchAddAndListItemsState> {
	private _container = createRef<HTMLDivElement>();
	private _list = createRef<HTMLDivElement>();

	constructor(props: ISearchAddAndListItemsProps<T>) {
		super(props);
		this.state = {
			open: false,
			transform: null,
			selectedItems: props.selectedItemList,
		};
	}

	public override componentDidUpdate(prevProps: ISearchAddAndListItemsProps<T>, prevState: ISearchAddAndListItemsState) {
		const {addedItems, search} = this.props;
		const {open} = this.state;

		if ((!prevState.open && open) || (open && prevProps.search !== search && this._container.current && this._list.current)) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._list.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.center,
					-15,
				),
			});
		}

		if (addedItems.length > prevProps.addedItems.length) {
			// A new item has been added, so the dropdown list should be closed.
			this.toggleSelector(false);
		}

		if (prevProps.selectedItemList !== this.props.selectedItemList) {
			// Update selectedItems when selectedItemList prop changes
			this.setSelectedItems(this.props.selectedItemList);
		}
	}

	private onSearchInput = (value: string, event: FormEvent) => {
		this.props.onSearch(value);
	};

	private toggleSelector = async (value?: boolean) => {
		const open = this.state.open;

		if (!value && open) {
			this.props.onSearch("");
			AppUtils.disableScrolling(false);
		}

		this.setState({open: value ?? !open});

		if (!open && value) {
			FocusLoss.stopListen(this._list.current, this.onFocusLoss);
			AppUtils.disableScrolling(true);
			await TimeUtils.wait(100);
			FocusLoss.listen(this._list.current, this.onFocusLoss);
		}
	};

	private onFocusLoss = () => {
		this.toggleSelector(false);
	};

	public override componentWillUnmount(): void {
		FocusLoss.stopListen(this._list.current, this.onFocusLoss);
	}

	public override componentDidMount() {
		this.setSelectedItems(this.props.selectedItemList);
	}

	private setSelectedItems = (selectedItemList: SelectedItem[]) => {
		this.setState({selectedItems: selectedItemList});
	};

	private onAddClick = () => {
		const {sections, selectedItemList} = this.props;

		sections.forEach((section) => {
			selectedItemList.forEach((item) => {
				section.onItemAdd(item);
			});
		});

		this.setState({
			selectedItems: [],
		});
	};

	public override render() {
		const {searchTextPlaceholder, popupLable, search, addedItems, sections, prohibitSearch} = this.props;
		const {transform, open} = this.state;

		const inlineStyle: React.CSSProperties = {
			position: "fixed",
			transform: transform?.translate,
			zIndex: 8000,
			width: this._container.current?.getBoundingClientRect().width - 30,
		};

		if (!transform) {
			inlineStyle.transform = "";
		}

		return (
			<div className="SearchAddAndListItems">
				{this.props.isPopupOpen && (
					<PopupV5
						onClose={this.props.onClose}
						label={popupLable}
						parentRef={this.props.parentRef.current}
						className="SettingsUserCreation"
						width="400px"
						height="480px"
						freezeRoot={true}
						noButtons={true}
					>
						<SearchAddAndListItemsPopupStyled>
							<>
								<div className="searchBar">
									{!prohibitSearch && (
										<SelectSearchFieldV5
											className="hbox flex_1"
											value={search}
											onInput={this.onSearchInput}
											onClick={() => this.toggleSelector(true)}
											selectedItems={this.state.selectedItems}
											getItems={this.props.getSelectedItems}
											placeholder={!open && this.state.selectedItems.length == 0 ? "Enter user/ portfolio group, comma separated" : " "}
											divRef={this._container}
											isDropdown={false}
										/>
									)}
									<ButtonV5
										onClick={this.onAddClick}
										label="Add"
										disabled={this.props.selectedItemList.length === 0}
									/>
								</div>
								{open && (
									<AddUserGroupOrPortfolioGroupSelectorStyled
										className="SearchAddAndListItems__selector vbox"
										ref={this._list}
									>
										{sections.map((section) => section.items).every((item) => item.length === 0) ? (
											<div className="empty">"We couldn't find a match."</div>
										) : (
											sections.map((section, index) => {
												return (
													<div
														className="section"
														key={index}
													>
														<h3>{featureTitles[section.feature]}</h3>
														{section.items.sort(section.sortFunction).map((item, index) => {
															const isItemAdded = addedItems.includes(item);

															return (
																<ListItemStyled>
																	<div className="listItemContainer">
																		<div className="popupItem">
																			<div
																				className="item hbox alignCenter"
																				key={item.id || index}
																				onClick={() => section.onItemSelect(item)}
																			>
																				{section.renderAddListItem(item)}
																			</div>
																			<div className="flex_1" />
																			{!section.lockItems && isItemAdded && (
																				<ButtonV5
																					onClick={() => section.onItemRemove(item)}
																					label="Remove"
																					className="removeButton"
																				/>
																			)}
																		</div>
																	</div>
																</ListItemStyled>
															);
														})}
													</div>
												);
											})
										)}
									</AddUserGroupOrPortfolioGroupSelectorStyled>
								)}
							</>

							<div className="vbox SearchAddAndListItems">
								<div className="list addList overflowYAuto">
									{addedItems.length === 0 ? (
										<p className="noItem">No item in the list.</p>
									) : (
										sections.map((section) => (
											<div key={section.feature}>
												<h3>{featureTitles[section.feature]}</h3>
												{addedItems.sort(section.sortFunction).map((item, index) => (
													<div key={item.id || index}>
														<div className="listItemContainer">
															<ListItemStyled>
																<div
																	className="item hbox"
																	key={item.id || index}
																>
																	{section.renderAddedListItem(item)}

																	<div className="flex_1" />

																	{!section.lockItems && (
																		<ButtonV5
																			onClick={() => section.onItemRemove(item)}
																			label="Remove"
																			className="removeButton"
																		/>
																	)}
																</div>
															</ListItemStyled>
														</div>
													</div>
												))}
											</div>
										))
									)}
								</div>
							</div>
						</SearchAddAndListItemsPopupStyled>
					</PopupV5>
				)}

				<SearchAddAndListItemsStyled>
					<div className="vbox flex_1 overflowYAuto">
						<div className="list">
							<div className="section">
								{addedItems.length === 0 ? (
									<p className="noItem">"No item in the list."</p>
								) : (
									sections.map((section) => {
										return addedItems
											.filter((addedItem) => addedItem.ownFeature === section.feature)
											.sort(section.sortFunction)
											.map((item, index) => {
												const featureSection = sections.find((section) => section.feature === item.ownFeature);

												return (
													<ListItemStyled>
														<div className="listItemContainer">
															<div
																key={item.id || index}
																className="item hbox"
															>
																{featureSection.renderAddedListItem(item)}
																<div className="flex_1" />
																{!section.lockItems && (
																	// <IconButtonV5 IconComponent={ChevronRightDouble} onClick={() => featureSection.onItemRemove(item)}/>
																	<ButtonV5
																		onClick={() => featureSection.onItemRemove(item)}
																		label="Remove"
																		className="removeButton"
																	/>
																)}
															</div>
														</div>
													</ListItemStyled>
												);
											});
									})
								)}
							</div>
						</div>
					</div>
				</SearchAddAndListItemsStyled>
			</div>
		);
	}
}

const SearchAddAndListItemsStyled = styled.div`
	.list .section .item {
		padding: 8px;
	}
`;

const ListItemStyled = styled.div`
	.listItemContainer {
		height: 48px;
	}

	.listItemContainer:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};

		.removeButton {
			display: block;
		}
	}

	.removeButton {
		display: none;
		background-color: ${colorPalette.gray.c200Light};
		border: 1px solid ${colorPalette.negative.c500Primary};
		color: ${colorPalette.negative.c500Primary};
		width: 54px;
		height: 24px;
		font-size: 12px;
		margin-top: auto;
		margin-bottom: auto;
		padding-top: auto;
		padding-bottom: auto;

		.label {
			position: absolute;
			transform: translate(-4px, 4px);
		}
	}

	.removeButton:hover {
		background-color: ${colorPalette.negative.c500Primary};
		color: ${colorPalette.white};
	}

	.name {
		font-size: 16px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.email {
		font-size: 12px;
		color: ${colorPalette.gray.c700Dark};
	}

	.popupItem {
		display: flex;

		.removeButton {
			margin-right: 8px;
		}
	}
`;

const SearchAddAndListItemsPopupStyled = styled.div`
	.searchBar {
		display: flex;
		gap: 8px;

		.SearchField {
			width: 600px;
		}
	}

	.SearchAddAndListItems__selector {
		margin-top: 8px;

		.section h3 {
			font-size: 12px;
			color: ${colorPalette.gray.c700Dark};
			margin-top: 8px;
			margin-bottom: 8px;
			margin-left: 8px;
			width: 488px;
		}

		.section .item {
			padding: 8px;

			.name {
				margin-right: 8px;
			}
		}
	}

	.addList {
		border: 1px solid ${colorPalette.gray.c300};
		border-radius: ${radius.sm};
		padding: 8px;
		margin-top: 8px;

		h3 {
			font-size: 12px;
			color: ${colorPalette.gray.c700Dark};
			margin-top: 8px;
			margin-bottom: 8px;
			margin-left: 8px;
			width: 488px;
		}

		.listItemContainer {
			height: 48px;
			padding: 8px;
		}

		.listItemContainer:hover {
			background-color: ${colorPalette.gray.c200Light};
			border-radius: ${radius.sm};

			.removeButton {
				display: block;
			}
		}
	}
`;

const AddUserGroupOrPortfolioGroupSelectorStyled = styled.div`
	padding: 8px;
	background: ${colorPalette.white};
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
	border-radius: ${radius.sm};
	position: absolute;
	width: 416px;
`;

import styled from "styled-components";
import {colorPalette} from "../styles/colorPalette";

export const LoaderIconV5 = () => {
	return (
		<LoaderIconStyled>
			<div className="loader">
				<div className="dots">
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		</LoaderIconStyled>
	);
};

const LoaderIconStyled = styled.div`
	width: 57px;
	height: 11px;

	.dots {
		position: relative;

		div {
			position: absolute;
			width: 11px;
			height: 11px;
			border-radius: 50%;
			background: ${colorPalette.primary.c500Primary};
			animation-timing-function: cubic-bezier(0, 1, 1, 0);

			&:nth-child(1) {
				left: 6px;
				animation: dot1 0.6s infinite;
			}
			&:nth-child(2) {
				left: 6px;
				animation: dot2 0.6s infinite;
			}
			&:nth-child(3) {
				left: 26px;
				animation: dot2 0.6s infinite;
			}
			&:nth-child(4) {
				left: 45px;
				animation: dot3 0.6s infinite;
			}
		}
	}

	@keyframes dot1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes dot2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(19px, 0);
		}
	}

	@keyframes dot3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
`;

import type {RefObject} from "react";
import {useState} from "react";
import styled from "styled-components";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled, SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";
import {XyiconFeature} from "../../../../generated/api/base";
import {useAppStore} from "../../../../StateManager";
import {PopupHeaderStyled, PopupV5} from "../../popup/PopupV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {SingleLineLabelStyled} from "../../details/datatypes/SingleLineLabelV5";
import {SingleLineInputStyled} from "../../details/datatypes/SingleLineInputV5";
import {colorPalette} from "../../styles/colorPalette";
import {IconButtonStyled} from "../../interaction/IconButtonV5";
import {FieldV5} from "../../details/FieldV5";
import {CreatePopupFieldStyled} from "../../popup/CreatePopupField.styled";
import {FieldStyled} from "../../details/Field.styled";

interface IPortfolioCreatePopupV5Props {
	onClose: () => void;
	parentRef: RefObject<HTMLDivElement>;
}

export const CreatePopupStyled = styled(PopupV5)`
	${PopupHeaderStyled} {
		padding: 0;

		.name {
			display: flex;
			align-items: center;
			margin: 8px;
		}

		${IconButtonStyled} {
			margin: 4px;
			justify-content: center;
		}
	}

	${FieldStyled} {
		margin-bottom: 0;
		.element {
			min-height: 32px;
		}
	}

	${SingleLineLabelStyled}, ${SingleLineInputStyled}, ${SelectInputStyled} {
		color: ${colorPalette.gray.c950};
	}
`;

export const PortfolioCreatePopupV5 = (props: IPortfolioCreatePopupV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const [name, setName] = useState<string>("");
	const [selectedType, setSelectedType] = useState<Type>(null);
	const [createClicked, setCreateClicked] = useState<boolean>(false);

	const types = appState.types[XyiconFeature.Portfolio];
	const isFormValid = selectedType && selectedType.id && name && appState.actions.isPortfolioNameValid(name);

	const onCreateClicked = async () => {
		setCreateClicked(true);

		try {
			const models = await appState.app.transport.services.feature.create(
				{
					name: name,
					portfolioTypeID: selectedType.id,
				},
				XyiconFeature.Portfolio,
			);

			props.onClose(/* models?.[0]?.id */);
		} catch (error) {
			console.warn("error", error);
		}

		setCreateClicked(false);
	};

	return (
		<CreatePopupStyled
			onClose={props.onClose}
			label="Create Portfolio"
			parentRef={props.parentRef.current}
			buttonProps={{
				onClick: onCreateClicked,
				disabled: createClicked || !isFormValid,
				label: "Create",
			}}
			verticalAlignment={VerticalAlignment.bottomOuter}
			horizontalAlignment={HorizontalAlignment.right}
		>
			<CreatePopupFieldStyled>
				<FieldV5
					label="Name"
					className="focused"
				>
					<ClickToEditInputV5
						value={name}
						onLiveChange={(name) => setName(name)}
						onChange={(name) => setName(name)}
						valueValidator={(name) => appState.actions.isPortfolioNameValid(name)}
						placeholder="Portfolio Name"
						className="portfolionameInput"
					/>
				</FieldV5>
				<FieldV5
					label="Type"
					className="focused"
				>
					<SelectInputV5
						options={types}
						render={(type) => (type ? type.name : "")}
						selected={selectedType}
						onChange={(type) => setSelectedType(type)}
						fullWidth={true}
						placeholder="Type"
						isSameWidth={true}
					/>
				</FieldV5>
			</CreatePopupFieldStyled>
		</CreatePopupStyled>
	);
};

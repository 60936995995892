import React, {useEffect, useState} from "react";

import {useAppStore} from "../../../StateManager";
import {NavigationEnum} from "../../../Enums";
import {IconButtonV5} from "../interaction/IconButtonV5";
import BackIcon from "../icons/arrow-narrow-left.svg?react";

export const TopBarBackButtonV5 = () => {
	const {
		appState: {
			app: {navigation},
		},
		setNavBarState,
	} = useAppStore();

	const [previousHash, setPreviousHash] = useState(navigation.previousHash);

	const onBackClick = () => {
		navigation.go(previousHash);
		setNavBarState("open");
	};

	useEffect(() => {
		if (previousHash.indexOf("#app/settings") > -1) {
			setPreviousHash(`app/${NavigationEnum.NAV_PORTFOLIOS}`);
		}

		return () => setNavBarState("open");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<IconButtonV5
			IconComponent={BackIcon}
			onClick={onBackClick}
		/>
	);
};

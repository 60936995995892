import {getDefaultPositionOfXyiconCaption} from "../../renderers/SpaceViewRendererUtils";
import {Caption} from "./Caption";

export class XyiconCaption extends Caption {
	public setToDefaultPosition() {
		if (this.scale) {
			const correctionMultiplier = this.parent.itemManager.correctionMultiplier;
			const parentPos = this.parent.position;
			const xyiconBBox = this.parent.boundingBox;
			const xyiconHeight = xyiconBBox.max.y - xyiconBBox.min.y;

			const {x, y} = getDefaultPositionOfXyiconCaption(parentPos, xyiconHeight, this.scale, correctionMultiplier);

			this.position.x = x;
			this.position.y = y;
		} else {
			console.warn("Get Peter, he messed up something");
		}
	}
}

import * as React from "react";
import {MathUtils} from "../../utils/math/MathUtils";
import type {INotificationProps, INotificationParams} from "./Notification";
import {Notification} from "./Notification";

interface IAppNotificationsProps {}

export interface INotificationElementParams extends INotificationProps {
	guid: string;
	onClose: () => void;
}

interface IAppNotificationsState {
	activeNotifications: INotificationElementParams[];
}

export class AppNotifications extends React.PureComponent<IAppNotificationsProps, IAppNotificationsState> {
	constructor(props: IAppNotificationsProps) {
		super(props);
		this.state = {
			activeNotifications: [],
		};
	}

	public notify(params: INotificationParams) {
		const guid = MathUtils.getNewRandomGUID();
		const extendedParams: INotificationElementParams = {
			...params,
			guid: guid,
			onClose: () => {
				this.setState((prevState: IAppNotificationsState) => ({
					activeNotifications: [...prevState.activeNotifications].filter((el) => el.guid !== guid),
				}));
			},
		};

		this.setState((prevState: IAppNotificationsState) => ({
			activeNotifications: [...prevState.activeNotifications, extendedParams],
		}));

		return extendedParams;
	}

	private getNotifications() {
		return this.state.activeNotifications.map((notificationParams: INotificationElementParams) => (
			<Notification
				key={notificationParams.guid}
				lifeTime={notificationParams.lifeTime}
				title={notificationParams.title}
				description={notificationParams.description}
				link={notificationParams.link}
				type={notificationParams.type}
				className={notificationParams.className}
				cancelable={notificationParams.cancelable}
				buttonLabel={notificationParams.buttonLabel}
				style={notificationParams.style}
				onActionButtonClick={notificationParams.onActionButtonClick}
				onClose={notificationParams.onClose}
				backdropDisable={notificationParams.backdropDisable}
			/>
		));
	}

	public override render() {
		return <div className="AppNotifications vbox alignCenter">{this.getNotifications()}</div>;
	}
}

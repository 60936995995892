import type {UIVersion} from "../../../../data/state/AppState";
import {SpaceExportWindowV5} from "../../../5.0/popup/SpaceExportWindowV5";
import {SpaceExportWindow} from "../../abstract/popups/SpaceExportWindow";

export const openKeywordsPopupForFile = async (
	file: File,
	label: string,
	callback: (file: File, keywords: string[]) => Promise<void>,
	currentUiVersion: UIVersion,
) => {
	const name = file.name;
	const fileName = name.includes(".") ? name.substring(0, file.name.lastIndexOf(".")) : name;
	const popup = currentUiVersion === "5.0" ? SpaceExportWindowV5 : SpaceExportWindow;
	const keywordInput = await popup.open(
		`Enter one or more keywords separated by a comma to describe your custom ${label}.<br>Use the keywords to search for your ${label} when creating new catalog items.`,
		`Add keywords to custom ${label}`,
		[""],
		[fileName],
		1,
		{
			backdrop: false,
			cancel: "Cancel",
			ok: "Add Keywords",
		},
	);

	if (keywordInput != null) {
		// Removing white-spaces, but keeping commas
		const keywords = keywordInput[0]
			.replace(/[\n\r\s\t]*,[\n\r\s\t]*/g, ",")
			.replace(/[\n\r\s\t]{2,}/g, "")
			.replace(/^[\n\r\s\t]+/g, "")
			.replace(/[\n\r\s\t]+$/g, "")
			.split(",");

		await callback(file, keywords);
	}
};

import * as React from "react";
import {Button} from "../../button/Button";
import {Functions} from "../../../../utils/function/Functions";

interface IStepLabelProps {
	stepIndex: number;
	stepLabels: string[];
	nextLabel: string;
	isSaveButtonVisible: boolean;
	isSaveButtonEnabled: boolean;
	saveLabel: string;
	onSaveClick: () => void;
	onBackClick: () => void;
	onNextClick: () => void;
	isNextButtonEnabled: boolean;
}

export class StepLabel extends React.Component<IStepLabelProps> {
	public static defaultProps: Partial<IStepLabelProps> = {
		isSaveButtonVisible: false,
		isSaveButtonEnabled: false,
		saveLabel: "Save",
		onSaveClick: Functions.emptyFunction,
	};

	public override render() {
		const backLabel = this.props.stepIndex > 0 ? "Back" : "Cancel";

		return (
			<div className="StepLabel hbox">
				<div className="stepLabel">
					<span className="bold">{`Step ${this.props.stepIndex + 1}: `}</span>
					{this.props.stepLabels[this.props.stepIndex] || ""}
				</div>
				<Button
					className="secondary"
					label={backLabel}
					title={backLabel}
					onClick={this.props.onBackClick}
				/>
				<Button
					className="primary"
					disabled={!this.props.isNextButtonEnabled}
					label={this.props.nextLabel}
					title={this.props.nextLabel}
					onClick={this.props.onNextClick}
				/>
				{this.props.isSaveButtonVisible && this.props.stepIndex < this.props.stepLabels.length - 1 && (
					<Button
						className="primary"
						disabled={!this.props.isSaveButtonEnabled}
						label={this.props.saveLabel}
						title={this.props.saveLabel}
						onClick={this.props.onSaveClick}
					/>
				)}
			</div>
		);
	}
}

import type {CSSProperties, Ref} from "react";
import {Fragment, useEffect, useReducer, useState} from "react";
import styled from "styled-components";
import type {AppState} from "../../../../../data/state/AppState";
import {Debouncer} from "../../../../../utils/function/Debouncer";
import {Xyicon} from "../../../../../data/models/Xyicon";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark-large.svg?react";
import BackArrowIcon from "../../../icons/arrow-back.svg?react";
import LinkIcon from "../../../icons/link.svg?react";
import UnlinkIcon from "../../../icons/unlink.svg?react";
import InfoIcon from "../../../icons/circle-information.svg?react";
import type {XyiconDto} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {LoaderIconV5} from "../../../loader/LoaderIconV5";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {colorPalette} from "../../../styles/colorPalette";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {radius} from "../../../styles/styles";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Portfolio} from "../../../../../data/models/Portfolio";
import type {IXyiconLinkObject} from "../../../../../data/state/AppActions";
import {LinkBreakers} from "../../../../modules/space/spaceeditor/ui/actionbar/LinkBreakers";
import {SpaceItemV5} from "../../../spaceeditor/SpaceItemV5";

type LinkButtonMode = "Link" | "Unlink";
type SearchScope = "current" | "other";

const listStep = 25;

interface IFindXyiconsWindowProps {
	readonly appState: AppState;
	readonly divRef: Ref<HTMLDivElement>;
	readonly style: CSSProperties;
	readonly onClose: () => void;
}

const debouncer = new Debouncer(1500);

export const FindXyiconsWindowV5 = (props: IFindXyiconsWindowProps) => {
	const {appState} = props;
	const spaceViewRenderer = appState.app.graphicalTools.spaceViewRenderer;
	const [searchString, setSearchString] = useState<string>("");
	const [searchScope, setSearchScope] = useState<SearchScope>("current"); // portfolio(s)
	const [isLoadingCrossPortfolioXyicons, setIsLoadingCrossPortfolioXyicons] = useState<boolean>(false);
	const [crossPortfolioXyicons, setCrossPortfolioXyicons] = useState<Xyicon[]>([]);
	const [numberOfXyiconsToShow, setNumberOfXyiconsToShow] = useState<number>(listStep);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const linksUpdatedSignal = appState.app.transport.signalR.listener.signals.linksUpdated;

	useEffect(() => {
		const signal = linksUpdatedSignal;

		signal.add(forceUpdate);

		return () => {
			signal.remove(forceUpdate);
		};
	}, [linksUpdatedSignal]);

	const triggerCrossPortfolioSearch = async (searchStr: string) => {
		setSearchString(searchStr);

		if (searchStr) {
			setIsLoadingCrossPortfolioXyicons(true);
			const {result, error} = await props.appState.app.transport.requestForOrganization<XyiconDto[]>({
				url: "xyicons/search",
				method: XHRLoader.METHOD_GET,
				params: {
					xyiconRefIdSearchString: searchStr,
				},
			});

			setCrossPortfolioXyicons(result.map((x) => new Xyicon(x, props.appState)).filter((x) => x.portfolioId !== appState.portfolioId));
			setIsLoadingCrossPortfolioXyicons(false);
		} else {
			setCrossPortfolioXyicons([]);
		}
	};

	const onSearchInput = (searchStr: string) => {
		if (searchScope === "other") {
			debouncer.debounce(() => {
				return triggerCrossPortfolioSearch(searchStr);
			});
		} else {
			setSearchString(searchStr);
		}
	};

	const xyiconsToShow = searchScope === "current" ? getXyiconsFromCurrentPortfolio(spaceViewRenderer, searchString) : crossPortfolioXyicons;

	return (
		<FindXyiconsWindowStyled
			ref={props.divRef}
			className="FindXyiconsWindow"
			style={props.style}
		>
			<div className="header">
				<div className="title">Create a Link</div>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={props.onClose}
				/>
			</div>
			<div className="vbox searchWrapper">
				<SearchFieldV5
					className="findInput"
					value={searchString}
					onInput={onSearchInput}
					autoFocus={true}
				/>
				{
					<div className="xyiconContainer vbox">
						{searchScope === "current" ? (
							renderXyicons(xyiconsToShow, spaceViewRenderer, searchString, searchScope, numberOfXyiconsToShow)
						) : isLoadingCrossPortfolioXyicons ? (
							<LoaderWrapper>
								<LoaderIconV5 />
								Loading...
							</LoaderWrapper>
						) : (
							renderXyicons(xyiconsToShow, spaceViewRenderer, searchString, searchScope, numberOfXyiconsToShow)
						)}
						{numberOfXyiconsToShow < xyiconsToShow.length && !isLoadingCrossPortfolioXyicons && (
							<BottomButtonContainer>
								<Button onClick={() => setNumberOfXyiconsToShow((n) => n + listStep)}>Load more...</Button>
							</BottomButtonContainer>
						)}
					</div>
				}
				{props.appState.user.isAdmin &&
					(searchScope === "current" ? (
						<BottomButtonContainer>
							<p>Can't find what you are looking for?</p>
							<Button
								onClick={() => {
									setSearchScope("other");
									triggerCrossPortfolioSearch(searchString);
								}}
							>
								Search in Other Portfolios
							</Button>
						</BottomButtonContainer>
					) : (
						<BottomButtonContainer>
							<Button
								onClick={() => {
									setSearchScope("current");
								}}
							>
								<BackArrowIcon /> Go back to Current Portfolio
							</Button>
						</BottomButtonContainer>
					))}
			</div>
		</FindXyiconsWindowStyled>
	);
};

const FindXyiconsWindowStyled = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 370px;
	background-color: ${colorPalette.white};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	z-index: 1;

	.header {
		background-color: ${colorPalette.gray.c100};
		min-height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
	}

	.searchWrapper {
		margin: 10px 20px;
		.SearchField {
			width: initial;
		}
		.xyiconContainer {
			display: flex;
			flex-direction: column;
			margin-top: 10px;
			width: 330px;
			padding: 1px;
			max-height: 329px;
			overflow-y: auto;

			.xyiconWrapper {
				.buttonWrapper {
					display: none;
					margin-right: 17px;
					.linkBtn {
						cursor: pointer;
						width: 60px;
						height: 30px;
						border-radius: 4px;
						background-color: ${colorPalette.primary.c500Primary};
						color: white;
						margin-right: 12px;
					}
				}
				&:hover {
					outline: ${colorPalette.primary.c500Primary} 1px solid;
					.buttonWrapper {
						display: flex;
					}
				}
				.SpaceItem {
					max-width: 220px;
					cursor: auto;
				}
			}
		}
	}
`;

const LoaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 70px;
	gap: 16px;
`;

const onLinkButtonClick = (spaceViewRenderer: SpaceViewRenderer, selectedXyicons: Xyicon[], xyicon: Xyicon, mode: LinkButtonMode) => {
	const appState = spaceViewRenderer.transport.appState;
	const spaceActionBarPos = spaceViewRenderer.spaceItemController.boundingBox.position;
	const {linkManager} = spaceViewRenderer.toolManager;

	if (mode === "Link") {
		linkManager.onEndXyiconClickInLinkMode(xyicon, spaceActionBarPos);
	} else {
		const linkObjectsToDelete = appState.actions.getLinksXyiconXyicon(xyicon.id).filter((o) => selectedXyicons.includes(o.object));
		const linkIdsToDelete: string[] = linkObjectsToDelete.map((l) => l.link.id);

		return LinkBreakers.breakLinks(appState.app.transport, linkIdsToDelete);
	}
};

const getLinkButtonLabel = (spaceViewRenderer: SpaceViewRenderer, selectedXyicons: Xyicon[], xyicon: Xyicon): LinkButtonMode => {
	const appState = spaceViewRenderer.transport.appState;
	const links = appState.actions.getLinksXyiconXyicon(xyicon.id);
	const areAlreadyLinked = selectedXyicons.every((x) => links.some((linkObject: IXyiconLinkObject) => linkObject.object === x));

	return areAlreadyLinked ? "Unlink" : "Link";
};

interface IXyiconGroupByPortfolio {
	portfolio: Portfolio;
	xyicons: Xyicon[];
}

const groupXyiconsByPortfolio = (xyicons: Xyicon[], spaceViewRenderer: SpaceViewRenderer): IXyiconGroupByPortfolio[] => {
	// group by portfolioIds
	const groups: {
		[portfolioId: string]: Xyicon[];
	} = {};

	for (const xyicon of xyicons) {
		if (!groups[xyicon.portfolioId]) {
			groups[xyicon.portfolioId] = [];
		}

		groups[xyicon.portfolioId].push(xyicon);
	}

	const actions = spaceViewRenderer.actions;

	const groupsArray: IXyiconGroupByPortfolio[] = [];

	for (const portfolioId in groups) {
		groupsArray.push({
			portfolio: actions.getPortfolioById(portfolioId),
			xyicons: groups[portfolioId].toSorted((a: Xyicon, b: Xyicon) => StringUtils.sortIgnoreCase(a.refId, b.refId)),
		});
	}

	return groupsArray.toSorted((a, b) => StringUtils.sortIgnoreCase(a.portfolio.name, b.portfolio.name));
};

const getFilteredGroups = (groups: IXyiconGroupByPortfolio[], numberOfXyiconsToShow: number): IXyiconGroupByPortfolio[] => {
	const filteredGroups: IXyiconGroupByPortfolio[] = [];
	let numberOfXyiconsInFilteredGroups = 0;

	for (const group of groups) {
		const maxNumberOfXyiconsToAdd = Math.max(0, numberOfXyiconsToShow - numberOfXyiconsInFilteredGroups);
		const filteredXyiconsInThisGroup = group.xyicons.slice(0, maxNumberOfXyiconsToAdd);

		group.xyicons = filteredXyiconsInThisGroup;
		numberOfXyiconsInFilteredGroups += filteredXyiconsInThisGroup.length;

		filteredGroups.push(group);

		if (numberOfXyiconsInFilteredGroups >= numberOfXyiconsToShow) {
			break;
		}
	}

	return filteredGroups;
};

const renderXyiconGroups = (
	xyiconsToRender: Xyicon[],
	selectedXyicons: Xyicon[],
	searchString: string,
	spaceViewRenderer: SpaceViewRenderer,
	numberOfXyiconsToShow: number,
) => {
	const groups = groupXyiconsByPortfolio(xyiconsToRender, spaceViewRenderer);
	const filteredGroups = getFilteredGroups(groups, numberOfXyiconsToShow);

	return filteredGroups.map((group: IXyiconGroupByPortfolio) => (
		<Fragment key={group.portfolio.name}>
			<PortfolioName>{group.portfolio.name}</PortfolioName>
			{renderXyiconBlock(group.xyicons, selectedXyicons, searchString, spaceViewRenderer)}
		</Fragment>
	));
};

const renderXyiconBlock = (
	xyicons: Xyicon[],
	selectedXyicons: Xyicon[],
	searchString: string,
	spaceViewRenderer: SpaceViewRenderer,
	numberOfXyiconsToShow?: number,
) => {
	const appState = spaceViewRenderer.transport.appState;
	const xyiconsToRender = xyicons.slice(0, numberOfXyiconsToShow);

	return xyiconsToRender.map((xyicon: Xyicon) => {
		const buttonLabel = getLinkButtonLabel(spaceViewRenderer, selectedXyicons, xyicon);

		return (
			<XyiconRowWrapper key={xyicon.refId}>
				<SpaceItemV5
					item={xyicon}
					queryString={searchString}
				/>
				<RowButtonWrapper>
					<IconButtonV5
						IconComponent={buttonLabel === "Link" ? LinkIcon : UnlinkIcon}
						title={buttonLabel}
						onClick={() => onLinkButtonClick(spaceViewRenderer, selectedXyicons, xyicon, buttonLabel)}
					/>
					<IconButtonV5
						IconComponent={InfoIcon}
						title="Details"
						onClick={() => appState.app.onDetailsClick(xyicon)}
					/>
				</RowButtonWrapper>
			</XyiconRowWrapper>
		);
	});
};

const renderXyicons = (
	xyiconsToRender: Xyicon[],
	spaceViewRenderer: SpaceViewRenderer,
	searchString: string,
	searchScope: SearchScope,
	numberOfXyiconsToShow: number,
) => {
	if (xyiconsToRender.length > 0) {
		const selectedXyicons = spaceViewRenderer.xyiconManager.selectedItems.map((x) => x.modelData) as Xyicon[];

		return (
			<XyiconRowsWrapper>
				<p>{xyiconsToRender.length} result(s) found</p>
				{searchScope === "current"
					? renderXyiconBlock(xyiconsToRender, selectedXyicons, searchString, spaceViewRenderer, numberOfXyiconsToShow)
					: renderXyiconGroups(xyiconsToRender, selectedXyicons, searchString, spaceViewRenderer, numberOfXyiconsToShow)}
			</XyiconRowsWrapper>
		);
	} else if (searchString) {
		return <NoResults>No results found for the term "{searchString}"</NoResults>;
	}
};

const getXyiconsFromCurrentPortfolio = (spaceViewRenderer: SpaceViewRenderer, searchString: string) => {
	const appState = spaceViewRenderer.transport.appState;
	const selectedXyicons = spaceViewRenderer.xyiconManager.selectedItems.map((x) => x.modelData) as Xyicon[];
	const allXyiconsExceptSelected = appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((x) => !selectedXyicons.includes(x));

	const xyiconsToShow: Xyicon[] = appState.actions
		.searchModelsCached(allXyiconsExceptSelected, searchString, XyiconFeature.Xyicon)
		.toSorted((a: Xyicon, b: Xyicon) => StringUtils.sortIgnoreCase(a.refId, b.refId));

	return xyiconsToShow;
};

const PortfolioName = styled.div`
	margin-top: 8px;
	font-size: 14px;
	color: ${colorPalette.gray.c700Dark};
`;

const NoResults = styled.p`
	width: 100%;
	text-align: center;
	color: #7b7b7b;
`;

const RowButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	visibility: hidden;
`;

const XyiconRowWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f5f5f5;
	height: 50px;
	&:hover {
		${RowButtonWrapper} {
			visibility: visible;
		}
	}

	${IconButtonStyled} {
		width: 32px;
		height: 32px;
		border-radius: ${radius.sm};
		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
	}
`;

const XyiconRowsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	> p {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 12px;
	}
`;

const Button = styled.div`
	color: #1e88e5;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	border-radius: ${radius.sm};
	display: flex;
	align-items: center;
	gap: 8px;
`;

const BottomButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 15px;
	margin-bottom: 15px;

	p {
		margin-top: 0;
		margin-bottom: 5px;
		color: #7b7b7b;
		font-style: italic;
	}
`;

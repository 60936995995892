import React, {useEffect, useRef, useState} from "react";
import {ReactUtils} from "../../../../utils/ReactUtils";

export interface IInfoBubbleProps {
	content: string | React.ReactNode;
	isErrorMessage?: boolean;
	divRef?: React.RefObject<HTMLDivElement>;
	style?: React.CSSProperties;
	className?: string;
	title?: string;
}

export function InfoBubble(props: IInfoBubbleProps) {
	const floatingElementRef = useRef<HTMLDivElement>(null);
	const {isErrorMessage, content, divRef, style, className, title} = props;
	const [left, setLeft] = useState<boolean>(false);

	useEffect(() => {
		if (floatingElementRef.current) {
			const rightPosition = floatingElementRef.current.getBoundingClientRect().right;
			const windowWidth = window.innerWidth;

			setLeft(rightPosition > windowWidth);
		}
	}, []);

	return (
		<div
			ref={divRef || floatingElementRef}
			style={style || {}}
			className={ReactUtils.cls(`InfoBubble flexCenter ${className || ""}`, {error: isErrorMessage, left})}
		>
			<div className="content vbox">
				{!!title?.length && <h4>{title}</h4>}
				{content}
			</div>
		</div>
	);
}

import * as React from "react";

interface INoResultSearchViewProps {
	search: string;
}

export class NoResultSearchView extends React.PureComponent<INoResultSearchViewProps> {
	public override render() {
		const {search} = this.props;

		return <div className="message">{`No results found for the term "${search}"`}</div>;
	}
}

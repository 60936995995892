import * as React from "react";
import styled from "styled-components";
import {FileDropper} from "../../../utils/interaction/draganddrop/FileDropper";
import {WarningWindow} from "../../modules/abstract/popups/WarningWindow";
import CloudIcon from "../icons/cloud-upload.svg?react";
import {colorPalette} from "../styles/colorPalette";
import {VerticalFlexStyle} from "../styles/styles";

interface IFileDropperProps {
	accept: string; // can be extensions, eg.: ".glb,.glTF", or mime types, eg.: "image/*"
	purpose: string;
	multiple?: boolean;
	onFileInputChange: (files: FileList) => void;
}

export class FileDropperReactV5 extends React.Component<IFileDropperProps> {
	private static _counter: number = 0;
	private _ref = React.createRef<HTMLDivElement>();
	private _dropper: FileDropper;

	private onDragOver = (event: DragEvent) => {
		// TODO: what's the purpose of this?

		return true;
	};

	private onDrop = (event: DragEvent) => {
		this.onFileChange(event.dataTransfer.files);
	};

	private onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.onFileChange(event.currentTarget.files);
	};

	private async onFileChange(files: FileList) {
		if (this._dropper.checkType(files)) {
			this.props.onFileInputChange(files);
		} else {
			await WarningWindow.open(`Wrong filetype! Only the following types are accepted: ${this.props.accept}`);
		}

		// To replace "value" with an empty string. So if the user deletes the previously selected item from a list, and then selects the same file again, onFileChange is triggered
		this.forceUpdate();
	}

	public override componentWillUnmount() {
		if (this._dropper) {
			this._dropper.disable();
		}
		FileDropperReactV5._counter--;
	}

	public override componentDidMount() {
		FileDropperReactV5._counter++;

		this._dropper = new FileDropper({
			targetElement: this._ref.current,
			onDragOver: this.onDragOver,
			onDrop: this.onDrop,
			accept: this.props.accept,
		});
	}

	public override render() {
		const inputId = `fileInput_${FileDropperReactV5._counter}`;

		return (
			<FileDropperStyled
				className="FileDropper"
				ref={this._ref}
			>
				<div className="label">{`${this.props.purpose}`}</div>
				<label htmlFor={inputId} />
				<CloudIcon />
				<input
					id={inputId}
					multiple={this.props.multiple}
					type="file"
					value=""
					accept={this.props.accept}
					onChange={this.onFileInputChange}
				/>
			</FileDropperStyled>
		);
	}
}

const FileDropperStyled = styled.div`
	${VerticalFlexStyle};
	border: 2px dashed ${colorPalette.gray.c200Light};
	align-self: stretch;
	align-items: center;
	justify-content: center;
	position: relative;

	&.dropping_files {
		border-color: ${colorPalette.gray.c500Primary};
	}

	label {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0 20px;
	}

	input[type="file"] {
		display: none;
	}

	.label {
		text-align: center;
		font-size: 14x;
		color: ${colorPalette.gray.c700Dark};
	}
`;

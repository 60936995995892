import type {LinkXyiconRequest, XyiconLinkDetail} from "../../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {Field, IFieldAdapter} from "../../../../../../data/models/field/Field";
import type {IModel} from "../../../../../../data/models/Model";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {AppActions} from "../../../../../../data/state/AppActions";
import type {AppState} from "../../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import type {ILookupFieldOption} from "./DetailsTab";

export class LookupUtils {
	public static getLookupFieldOptions = (appState: AppState, field: any, item: IModel) => {
		const items = appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((xyicon) => xyicon !== (item as any as Xyicon));

		const filteredItems = items
			.map((xyicon: Xyicon) => {
				return {
					value: appState.actions.getFieldValue(xyicon, field.refId) as string,
					xyicon,
				};
			})
			.filter((o) => o.value) as ILookupFieldOption[]; // Don't list xyicons that don't have the value on the field

		return filteredItems.sort((a, b) => StringUtils.sortIgnoreCase(a.value, b.value));
	};

	public static getSelectedLookupFieldOptions = (appState: AppState, lookupFieldOptions: ILookupFieldOption[], item: IModel) => {
		const existingLinks = appState.actions.getLinksXyiconXyicon(item.id);

		return lookupFieldOptions.filter((lookupFieldOption) => {
			for (const link of existingLinks) {
				if (link.object.id === lookupFieldOption.xyicon.id) {
					return true;
				}
			}
			return false;
		});
	};

	public static getOnChangeForLookup = (transport: TransportLayer, appState: AppState, lookupFieldOptions: ILookupFieldOption[], item: IModel) => {
		const existingLinks = appState.actions.getLinksXyiconXyicon(item.id);

		return (selectedOptions: ILookupFieldOption[]) => {
			const linkIdsToRemove: string[] = [];
			const linksToAdd: XyiconLinkDetail[] = [];

			for (const lookupFieldOption of lookupFieldOptions) {
				const existingLink = existingLinks.find(
					(l) =>
						(l.link.fromObjectId === item.id && l.link.toObjectId === lookupFieldOption.xyicon.id) ||
						(l.link.toObjectId === item.id && l.link.fromObjectId === lookupFieldOption.xyicon.id),
				);

				const isCurrentlySelected = selectedOptions.includes(lookupFieldOption);

				if (existingLink) {
					if (!isCurrentlySelected) {
						// Remove existing
						linkIdsToRemove.push(existingLink.link.id);
					}
				} else if (isCurrentlySelected) {
					linksToAdd.push({
						fromXyiconID: item.id,
						toXyiconID: lookupFieldOption.xyicon.id,
						toPortID: null,
						fromPortID: null,
						isEmbedded: false,
					});
				}
			}

			if (linkIdsToRemove.length > 0) {
				return transport.requestForOrganization({
					url: "xyicons/deletelink",
					method: XHRLoader.METHOD_DELETE,
					params: {
						portfolioID: transport.appState.portfolioId,
						linkIDList: linkIdsToRemove,
					},
				});
			}

			if (linksToAdd.length > 0) {
				const params: LinkXyiconRequest = {
					fromPortfolioID: transport.appState.portfolioId,
					toPortfolioID: transport.appState.portfolioId,
					xyiconLinkDetails: linksToAdd,
				};

				return transport.requestForOrganization({
					url: "xyicons/createlink",
					method: XHRLoader.METHOD_PUT,
					params: params,
				});
			}
		};
	};

	public static isLookupField = (field: IFieldAdapter, firstSelectedItem: IModel, actions: AppActions) => {
		const type = actions.getTypeById(firstSelectedItem.typeId);
		const xyiconLookupLinkFieldList = type?.settings.xyiconLookupLinkFieldList || [];

		return (field as Field).id && xyiconLookupLinkFieldList.includes((field as Field).id);
	};
}

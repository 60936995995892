export class DebugInformation {
	private static _debugIdsWithTimeStartEntries: Map<string, number> = new Map();
	private static readonly _prefix = "srlog";

	public static get isLoggingEnabled() {
		return typeof window === "undefined" ? true : localStorage.getItem(this._prefix) === "true";
	}

	public static start(id: string) {
		if (this.isLoggingEnabled) {
			this._debugIdsWithTimeStartEntries.set(id, performance.now());
		}
	}

	public static end(id: string) {
		if (this.isLoggingEnabled) {
			const debugIdEntry = this._debugIdsWithTimeStartEntries.get(id);

			if (debugIdEntry) {
				const now = performance.now();

				console.log(`${this._prefix} - ${id}: ${Math.round(now - this._debugIdsWithTimeStartEntries.get(id))} ms`);
				this._debugIdsWithTimeStartEntries.delete(id);
			}
		}
	}
}

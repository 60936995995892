import * as React from "react";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {ReactUtils} from "../../../../../utils/ReactUtils";

interface IViewComponentTogglerProps {
	onClick: (value: boolean) => void;
	isActive: boolean;
	type: string;
	title: string;
	classNames?: string;
	label?: string;
}

export class ViewComponentToggler extends React.Component<IViewComponentTogglerProps> {
	private onClick = () => {
		this.props.onClick(!this.props.isActive);
	};

	public override render() {
		return (
			<div
				className={ReactUtils.cls("btn", {active: this.props.isActive})}
				title={this.props.title}
				onClick={this.onClick}
			>
				<SVGIcon icon={this.props.type} />
				{this.props.label || ""}
			</div>
		);
	}
}

import {NoResultViewStyled} from "./NoResultStyled";

interface INoResultSearchViewProps {
	readonly search: string;
}

export const NoResultSearchViewV5 = (props: INoResultSearchViewProps) => {
	const {search} = props;

	return <NoResultViewStyled className="message">{`No results found for the term "${search}"`}</NoResultViewStyled>;
};

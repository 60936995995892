import type {MouseEvent} from "react";
import {useState} from "react";
import SearchIcon from "../../icons/search.svg?react";
import type {ITextInputProps} from "../../../widgets/input/text/TextInput";
import XmarkIcon from "../../icons/xmark.svg?react";
import {useClickOutside} from "../../utils";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {SearchFieldStyled} from "./SearchField.styled";

interface ISearchFieldV5Props extends Partial<ITextInputProps> {
	value: string;
	placeholder?: string;
	showCancelIcon?: boolean;
	minimized?: boolean;
	showClearIcon?: boolean;
	fullWidth?: boolean;
}

export const SearchFieldV5 = (props: ISearchFieldV5Props) => {
	const {placeholder = "Find", value, minimized, fullWidth = false} = props;
	const [extended, setExtended] = useState<boolean>(false);

	const deleteSearch = (ev: MouseEvent<SVGSVGElement>) => {
		ev.stopPropagation();
		props.onInput?.("");
	};

	const onOpen = () => setExtended(true);
	const onClose = () => setExtended(false);

	const onSearchFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
		// e.stopPropagation();
		onOpen();
	};

	useClickOutside([], onClose);

	return (
		<SearchFieldStyled
			className="SearchField"
			$fullWidth={fullWidth}
			$minimized={!!minimized}
			onClick={onSearchFieldClick}
			$extended={extended}
		>
			<TextInputV5
				{...props}
				placeholder={placeholder || "Find"}
			/>
			<SearchIcon />
			{(props.showClearIcon || value?.length > 0) && (
				<XmarkIcon
					className="cancel"
					onClick={deleteSearch}
				/>
			)}
		</SearchFieldStyled>
	);
};

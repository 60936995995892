import type {SpaceItem} from "../../../elements3d/SpaceItem";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {InstancedIconManager} from "./InstancedIconManager";
import type {IObjectWithRotationHandler} from "./RotationIconTypes";
import {keyForObjectsWithRotationHandlersInUserData} from "./RotationIconTypes";

export class RotationIconManager extends InstancedIconManager {
	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "rotation", [30 / 255, 136 / 255, 229 / 255]);
	}

	private get _additionalObjectsWithRotationHandlers(): IObjectWithRotationHandler[] {
		const ret: IObjectWithRotationHandler[] = [];

		if (this._spaceViewRenderer.spaceItemController.selectedItems.length > 1) {
			ret.push(this._spaceViewRenderer.spaceItemController.boundingBox);
		}

		return ret;
	}

	private get _selectedItems(): SpaceItem[] {
		return this._spaceViewRenderer.spaceItemController.selectedItems;
	}

	public override init() {
		THREEUtils.add(this._spaceViewRenderer.topLayerScene, this._iconContainer);
	}

	public updateTransformations() {
		if (this._icons) {
			this.updateTransformationsFromDummyObjects(
				(this._icons.userData[keyForObjectsWithRotationHandlersInUserData] as IObjectWithRotationHandler[]).map(
					(spaceItem) => spaceItem.getRotationIconObject,
				),
			);
		}
	}

	protected override prepareInstancedMesh() {
		return {
			material: this._spaceViewRenderer.textureManager.miscTextures.rotation.material,
			objects: [...this._selectedItems, ...this._additionalObjectsWithRotationHandlers],
		};
	}

	protected override onInstancedMeshCreated(objects: IObjectWithRotationHandler[]) {
		this._icons.userData[keyForObjectsWithRotationHandlersInUserData] = [...objects];
		const {_additionalObjectsWithRotationHandlers} = this;

		if (_additionalObjectsWithRotationHandlers.length > 0) {
			// Make the group rotation handler yellow: #F0CC29
			this.setIconColor(objects.length - 1, [240 / 255, 204 / 255, 41 / 255]);
		}
	}

	public get isRotationModeOn() {
		return !!this._icons;
	}
}

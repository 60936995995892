export class Debouncer {
	public static limit = 500;
	private readonly _delay: number;

	private _timeoutId: number;

	constructor(delay = 1000) {
		this._delay = delay;
	}

	public debounceList(handler: Function, list: any[]) {
		this.debounce(handler, list.length > Debouncer.limit ? this._delay : 0);
	}

	public debounce(handler: Function, delay?: number) {
		clearTimeout(this._timeoutId);
		this._timeoutId = window.setTimeout(handler, delay ?? this._delay);
	}
}

import * as React from "react";
import type {SpaceTool} from "../../logic3d/features/tools/Tools";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {ToolButton} from "./ToolButton";

interface ISpaceAlignToolBarProps {
	active: boolean;
	createToolButton: (id: SpaceTool) => React.JSX.Element;
	openDefaultToolBar?: () => void;
}

export class SpaceAlignToolBar extends React.Component<ISpaceAlignToolBarProps> {
	public override render() {
		return (
			<div className={ReactUtils.cls({SpaceToolBar: true, collapsed: !this.props.active})}>
				{this.props.openDefaultToolBar && (
					<ToolButton
						icon="back"
						active={false}
						classNames="back"
						title="Back"
						onClick={this.props.openDefaultToolBar}
					/>
				)}
				{this.props.createToolButton("pan")}
				{this.props.createToolButton("moveBackground")}
				{this.props.createToolButton("setPin")}
			</div>
		);
	}
}

import type {Object3D} from "three";
import {BufferGeometry, BufferAttribute, LineDashedMaterial, Line, DynamicDrawUsage, Vector2} from "three";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {IObjectWithRotationHandler} from "../managers/spaceitems/icons/RotationIconTypes";
import {Constants} from "../Constants";
import type {IBox2} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {PointDouble} from "../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {ItemSelectionBox} from "./ItemSelectionBox";

export class BoundingBox extends ItemSelectionBox implements IObjectWithRotationHandler {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _orientation: number = 0;
	private _rotationIconPos: Vector2 = new Vector2();
	private _geometry: BufferGeometry;
	private _line: Line; // LineLoop would be nicer here, but it's not well supported for LineDashedMaterial
	private _material: LineDashedMaterial;

	constructor(spaceViewRenderer: SpaceViewRenderer, color: number = Constants.COLORS.SELECTION_BOX) {
		super();
		this._spaceViewRenderer = spaceViewRenderer;

		const vertices = new Float32Array(15); // 5 vertices, 3 coords (xyz) each. The first and the last vertex values are the same

		this._geometry = new BufferGeometry();
		this._geometry.setAttribute("position", new BufferAttribute(vertices, 3));
		(this._geometry.attributes.position as BufferAttribute).setUsage(DynamicDrawUsage);

		this._material = new LineDashedMaterial({
			color: color,
			depthTest: false,
		});
		this.makeLineSolid();

		this._line = new Line(this._geometry, this._material);
		this._line.frustumCulled = false;
		this._line.name = "boundingBox";

		this._line.visible = false;

		THREEUtils.add(this._spaceViewRenderer.topLayerScene, this._line);
	}

	public rotate(delta: number) {
		this._orientation = delta;
		this.updateAttributes();
	}

	public stopRotation() {
		this._orientation = 0;
	}

	public getRotationIconObject = (target: Object3D) => {
		if (this.visible) {
			const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;
			const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;

			const defaultWorldSize: PointDouble = {
				x: (32 * correctionMultiplier) / cameraZoomLevel,
				y: (32 * correctionMultiplier) / cameraZoomLevel,
			};

			const {position, scale} = this;

			this._rotationIconPos.set(
				position.x + scale.x / 2 + 0.5 * defaultWorldSize.x * Math.SQRT1_2,
				position.y + scale.y / 2 + 0.5 * defaultWorldSize.y * Math.SQRT1_2,
			);
			this._rotationIconPos.rotateAround(position as Vector2, this.orientation);

			target.position.set(this._rotationIconPos.x, this._rotationIconPos.y, 1 * correctionMultiplier);
			target.rotation.z = -45 * MathUtils.DEG2RAD + this.orientation;
			target.scale.set(defaultWorldSize.x, defaultWorldSize.y, 1);
		} else {
			target.scale.set(0, 0, 0);
		}

		target.updateMatrix();

		return target;
	};

	public get orientation(): number {
		return this._orientation;
	}

	public initZIndex() {
		const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;

		this._line.position.setZ(1 * correctionMultiplier);
		this.reset();
	}

	public makeLineSolid() {
		this._material.setValues({
			dashSize: Infinity,
			gapSize: 0,
		});
	}

	public makeLineDashed() {
		const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;

		this._material.setValues({
			dashSize: 8 * correctionMultiplier,
			gapSize: 8 * correctionMultiplier,
		});
	}

	public override reset() {
		super.reset();
		this.hide();
	}

	public override translate(x: number, y: number) {
		super.translate(x, y);
		this.updateAttributes();
	}

	protected updateAttributes(updateMatrices: boolean = true) {
		const positionAttribute = this._geometry.attributes.position as BufferAttribute;
		const position = this.position;
		const scale = this.scale;

		positionAttribute.setXY(0, position.x - scale.x / 2, position.y - scale.y / 2);
		positionAttribute.setXY(1, position.x + scale.x / 2, position.y - scale.y / 2);
		positionAttribute.setXY(2, position.x + scale.x / 2, position.y + scale.y / 2);
		positionAttribute.setXY(3, position.x - scale.x / 2, position.y + scale.y / 2);
		positionAttribute.setXY(4, position.x - scale.x / 2, position.y - scale.y / 2);

		THREEUtils.applyRotationToBufferGeometry(this._geometry, this._orientation, position as Vector2);

		positionAttribute.needsUpdate = true;
		this._line.computeLineDistances();

		if (updateMatrices) {
			THREEUtils.updateMatrices(this._line);
		}
	}

	public override setFromBoundingBoxes(bboxes: IBox2[]) {
		super.setFromBoundingBoxes(bboxes);
		this.updateAttributes();
		this.show();
	}

	public show() {
		this._line.visible = true;
	}

	public hide() {
		this._line.visible = false;
	}

	public get visible() {
		return this._line.visible;
	}
}

import {Constants} from "../Constants";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";

export class SnapToGridManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _isActive: boolean = false;
	private _gridSize: number = Constants.SIZE.XYICON / 8; // px
	private _snapThreshold: number = this._gridSize; // px

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public init() {
		this.loadDataFromLocalStorage();
	}

	private loadDataFromLocalStorage() {
		const organizationId = this._spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const savedValue = this._spaceViewRenderer.transport.services.localStorage.get(this.getKeyLocalStorageForSnapToGrid(organizationId));

			// We only set it to false if the saved value is false. If nothing is saved (value == null),
			// we set it to true by default
			this._isActive = savedValue !== false;
		}
	}

	private saveDataToLocalStorage() {
		const organizationId = this._spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			this._spaceViewRenderer.transport.services.localStorage.set(this.getKeyLocalStorageForSnapToGrid(organizationId), this._isActive);
		}
	}

	private getKeyLocalStorageForSnapToGrid(organizationId: string) {
		return `srv4-org-${organizationId}-snap-to-grid`;
	}

	public activate() {
		if (!this._isActive) {
			this._isActive = true;
			this.saveDataToLocalStorage();
		}
	}

	public deactivate() {
		if (this._isActive) {
			this._isActive = false;
			this.saveDataToLocalStorage();
		}
	}

	/**
	 *
	 * @param x original x
	 * @param y original y
	 */
	public getUpdatedCoords(x: number, y: number) {
		let outX = x;
		let outY = y;

		if (this.isActive) {
			const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;
			const gridSizeInSpaceUnits = this._gridSize * correctionMultiplier;
			const snapTresholdInSpaceUnits = this._snapThreshold * correctionMultiplier;
			let closestGridX = Math.round(x / gridSizeInSpaceUnits) * gridSizeInSpaceUnits;
			let closestGridY = Math.round(y / gridSizeInSpaceUnits) * gridSizeInSpaceUnits;

			if (Math.abs(closestGridX - x) < snapTresholdInSpaceUnits) {
				outX = closestGridX;
			}
			if (Math.abs(closestGridY - y) < snapTresholdInSpaceUnits) {
				outY = closestGridY;
			}
		}

		return {
			x: outX,
			y: outY,
		};
	}

	public get isActive() {
		return this._isActive && !KeyboardListener.isAltDown;
	}
}

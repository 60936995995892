import * as React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {IModel} from "../../../../../data/models/Model";
import type {Catalog} from "../../../../../data/models/Catalog";
import type {ICreateUnplottedXyiconParam} from "../../../../modules/abstract/ModuleView";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";
import DockIcon from "../../../icons/dock.svg?react";
import UndockIcon from "../../../icons/undock.svg?react";
import CloseIcon from "../../../icons/xmark.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import type {GridViewV5} from "../../../abstract/GridViewV5";
import {XyiconCatalogContainerV5} from "./XyiconCatalogContainerV5";
import {BoundaryTypeContainerV5} from "./BoundaryTypeContainerV5";

export type DockableTitle = "Catalog" | "Boundary" | "Unplotted Xyicons";

interface IDockableProps {
	spaceViewRenderer: SpaceViewRenderer;
	gridView?: React.RefObject<GridViewV5<IModel>>;
	setDocked: (value: boolean, title: DockableTitle) => void;
	setOpen: (value: boolean, title: DockableTitle) => void;
	isDocked: boolean;
	title: DockableTitle;
	setActiveTool: (id: string) => void;
	onAddCatalogClick: () => void;
	onDuplicateCatalogClick: (catalog: Catalog) => void;
	onCreateUnplottedXyicons: (params: ICreateUnplottedXyiconParam[]) => Promise<void> | void;
}

interface IDockableState {
	search: string;
	open: boolean;
}

@observer
export class DockableV5 extends React.Component<IDockableProps, IDockableState> {
	private readonly _animationTime: number = 300;

	private _previousTitle: DockableTitle = null;
	private _element = React.createRef<HTMLDivElement>();

	constructor(props: IDockableProps) {
		super(props);
		this.state = {
			search: "",
			open: false,
		};
	}

	private onDockTogglerClick = () => {
		this.props.setDocked(!this.props.isDocked, this.props.title);
	};

	private close = () => {
		this.setState({
			open: false,
		});
	};

	public onCloseClick = () => {
		this.close();
		setTimeout(() => {
			this.props.setOpen(false, this.props.title);
		}, this._animationTime);
	};

	private onBoundaryTypeClick = (typeId: string) => {
		this.props.spaceViewRenderer.boundaryManager.setTypeId(typeId);
		this.props.setActiveTool("boundary");

		if (!this.props.isDocked) {
			this.onCloseClick();
		}
	};

	private fadeIn(milliseconds: number) {
		setTimeout(() => {
			this.forceUpdate();
		}, milliseconds);
	}

	private getElement(renderTitle: DockableTitle) {
		if (renderTitle === "Boundary") {
			return (
				<BoundaryTypeContainerV5
					spaceViewRenderer={this.props.spaceViewRenderer}
					onBoundaryTypeClick={this.onBoundaryTypeClick}
				/>
			);
		} else {
			return (
				<XyiconCatalogContainerV5
					spaceViewRenderer={this.props.spaceViewRenderer}
					gridView={this.props.gridView}
					renderTitle={renderTitle}
					onAddCatalogClick={this.props.onAddCatalogClick}
					onDuplicateCatalogClick={this.props.onDuplicateCatalogClick}
					onCreateUnplottedXyicons={this.props.onCreateUnplottedXyicons}
					isDocked={this.props.isDocked}
					isOpen={this.state.open}
					onClose={this.close}
					onCloseClick={this.onCloseClick}
				/>
			);
		}
	}

	public override componentDidUpdate() {
		this._previousTitle = this.props.title;
	}

	public override render() {
		const dockTitle = this.props.isDocked ? `Undock ${this.props.title}` : `Dock ${this.props.title}`;

		if (this._previousTitle == null) {
			this._previousTitle = this.props.title;
			requestAnimationFrame(() => {
				this.setState({
					open: true,
				});
			});
		}
		const hasChanged = this._previousTitle !== this.props.title;

		if (hasChanged) {
			this.fadeIn(this._previousTitle === null ? 0 : this._animationTime);
		}

		const renderTitle = this._previousTitle || this.props.title;

		return (
			<DockableStyled
				ref={this._element}
				className={ReactUtils.cls("Dockable", {docked: this.props.isDocked, visible: this.state.open && this.props.title === this._previousTitle})}
			>
				<Header>
					<Title className="title">{renderTitle}</Title>
					<ButtonContainer>
						<IconButtonV5
							onClick={this.onDockTogglerClick}
							title={dockTitle}
							IconComponent={this.props.isDocked ? UndockIcon : DockIcon}
							titleAlignment="top"
						/>
						<IconButtonV5
							onClick={this.onCloseClick}
							IconComponent={CloseIcon}
						/>
					</ButtonContainer>
				</Header>
				{this.getElement(renderTitle)}
			</DockableStyled>
		);
	}
}

const DockableStyled = styled.div`
	position: absolute;
	top: 50%;
	left: 75px;
	min-width: 200px;
	width: 336px;
	display: flex;
	flex-direction: column;
	flex: 1;
	opacity: 0;
	z-index: 99;
	white-space: nowrap;
	background-color: ${colorPalette.white};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
	transform: translateY(-50%);
	transition: ease-in-out all 0.3s;

	&:not(.docked) {
		max-height: calc(100% - 200px);
		border-radius: ${radius.md};
	}

	&.visible {
		opacity: 1;
	}

	&.docked {
		width: initial;
		position: relative;
		left: 0;
		top: 0;
		height: fit-content;
		display: flex;
		transform: initial;
		box-shadow: none;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
`;

const Title = styled.div`
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

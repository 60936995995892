import styled from "styled-components";
import type {IInputProps} from "../../../widgets/input/clicktoedit/FieldInput";
import {TextInputV5} from "./TextInputV5";

export const SingleLineInputV5 = (props: IInputProps) => {
	const {value, onInput, onBlur, disabled, inputType, caretPosition} = props;

	return (
		<SingleLineInputStyled className="hbox field-input-container">
			<TextInputV5
				value={value}
				onInput={onInput}
				onBlur={onBlur}
				autoFocus={true}
				disabled={disabled}
				caretPosition={caretPosition}
				inputType={inputType}
			/>
		</SingleLineInputStyled>
	);
};

export const SingleLineInputStyled = styled.div`
	display: flex;
	align-items: center;
`;

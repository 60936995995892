import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";

export interface ITabChildProps {
	id?: string;
	label?: string;
	title?: string;
	icon?: string;
	active?: boolean;
	disabled?: boolean;
	children: React.ReactNode;
}

export class TabChild extends React.Component<ITabChildProps> {
	public static defaultProps: ITabChildProps = {
		label: "",
		title: "",
		active: false,
		disabled: false,
		children: <></>,
	};

	public override render() {
		return <div className={ReactUtils.cls({"DetailsTab active": this.props.active})}>{this.props.children}</div>;
	}
}

import * as React from "react";
import styled from "styled-components";
import type {IBasicPropsFor3DTweaking} from "../../../modules/space/SpaceView";
import {THREEUtils} from "../../../../utils/THREEUtils";
import type {TileManager} from "../../../modules/space/spaceeditor/logic3d/managers/TileManager";
import {SpaceEditorMode} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {ButtonV5} from "../../button/ButtonV5";
import {LogarithmicSliderV5} from "../../colors/LogarithmicSliderV5";
import {ViewComponentTogglerV5} from "../spaceeditor/viewbar/ViewComponentTogglerV5";
import {radius} from "../../styles/styles";

const SpaceAlignViewBarStyled = styled.div`
	font-family: Roboto;
	position: absolute;
	z-index: 2;
	top: 16px;
	right: 16px;
	height: 40px;
	background: #363636;
	border: solid 1px #e9e9e9;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	display: flex;
	align-items: center;
	border-radius: 8px;
	padding: 5px;

	.Button {
		background: transparent;
		height: 30px;
		cursor: pointer;
		border: 1px solid #ffffff;
		color: #ffffff;
		margin: 0 5px;
		font-size: 14px;
		font-weight: 400;
		border-radius: 4px;
		align-items: center;
	}

	.btn {
		color: white;
		border: 1px solid white;
		border-radius: ${radius.sm};
		margin: 16px 8px;
		padding: 4px 8px;
		background-color: transparent;

		&.active {
			background-color: #1e88e5;
		}
	}

	.sliderTrack {
		background: white;
		height: 2px;
	}

	.dragHandle {
		width: 16px;
		height: 16px;
		background: #1e88e5;
	}
`;

interface ISpaceAlignViewBarState {
	isOldBackgroundVisible: boolean;
	isNewBackgroundVisible: boolean;
	centerScaleOnPointerDown: number;
	largeStepValue: number;
	backgroundScale: number;
}

export class SpaceAlignViewBarV5 extends React.Component<IBasicPropsFor3DTweaking, ISpaceAlignViewBarState> {
	private _tileManager: TileManager;

	constructor(props: IBasicPropsFor3DTweaking) {
		super(props);

		this._tileManager = this.props.spaceViewRenderer.tileManager;

		const defaultScale = 1;

		this.state = {
			isOldBackgroundVisible: true,
			isNewBackgroundVisible: true,
			largeStepValue: this.getLargeStepValue(defaultScale),
			backgroundScale: defaultScale,
			centerScaleOnPointerDown: defaultScale,
		};
	}

	private onOldLayerClick = () => {
		if (this._tileManager.oldBackgroundContainer) {
			this._tileManager.oldBackgroundContainer.visible = !this.state.isOldBackgroundVisible;

			this.setState({
				isOldBackgroundVisible: this._tileManager.oldBackgroundContainer.visible,
			});
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onNewLayerClick = () => {
		if (this._tileManager.firstChildOfPivotContainer) {
			this._tileManager.firstChildOfPivotContainer.visible = !this.state.isNewBackgroundVisible;

			this.setState({
				isNewBackgroundVisible: this._tileManager.firstChildOfPivotContainer.visible,
			});
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onScaleChange = (newScaleValue: number) => {
		if (this._tileManager.pivotContainer) {
			this._tileManager.pivotContainer.scale.set(newScaleValue, newScaleValue, newScaleValue);
			THREEUtils.updateMatrices(this._tileManager.pivotContainer);
			this.setState({
				backgroundScale: newScaleValue,
			});
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onScaleEnd = () => {
		this.setState({
			centerScaleOnPointerDown: this.state.backgroundScale,
		});
	};

	private onConfirmClick = () => {
		this.props.spaceViewRenderer.confirmAlignment();
		this.setState({
			backgroundScale: 1,
			centerScaleOnPointerDown: 1,
		});
	};

	private getLargeStepValue(centerScaleValue: number = this.state.centerScaleOnPointerDown) {
		return centerScaleValue / (this.props.spaceViewRenderer.toolManager.cameraControls.cameraZoomValue * 2000);
	}

	private onCameraZoomChange = () => {
		this.setState({
			largeStepValue: this.getLargeStepValue(),
		});
	};

	public override componentDidMount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.add(this.onCameraZoomChange);
	}

	public override componentWillUnmount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.remove(this.onCameraZoomChange);
	}

	public override render() {
		const confirmText = "Confirm Alignment";

		return (
			<SpaceAlignViewBarStyled className="SpaceAlignViewBar">
				<ButtonV5
					label={confirmText}
					title={confirmText}
					onClick={this.onConfirmClick}
				/>
				<LogarithmicSliderV5
					width={150}
					value={this.state.backgroundScale}
					largeStepValue={this.state.largeStepValue}
					min={this.state.centerScaleOnPointerDown / 2}
					max={this.state.centerScaleOnPointerDown * 2}
					title="Scale"
					onValueChange={this.onScaleChange}
					onPointerUp={this.onScaleEnd}
					arrows={true}
				/>
				{this.props.spaceViewRenderer.mode === SpaceEditorMode.ALIGN && (
					<>
						<ViewComponentTogglerV5
							title="Old Background"
							type="currentLayer"
							label="Current"
							isActive={this.state.isOldBackgroundVisible}
							onClick={this.onOldLayerClick}
						/>
						<ViewComponentTogglerV5
							title="New Background"
							type="newLayer"
							label="New"
							isActive={this.state.isNewBackgroundVisible}
							onClick={this.onNewLayerClick}
						/>
					</>
				)}
			</SpaceAlignViewBarStyled>
		);
	}
}

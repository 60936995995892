import styled from "styled-components";
import {Responsive, WidthProvider} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./HomeDashboard.css";
import {radius} from "../../styles/styles";
import {useAppStore} from "../../../../StateManager";
import {AvatarStyled} from "../../user/Avatar.styled";
import {DateUtils} from "../../../../utils/DateUtils";
import {colorPalette} from "../../styles/colorPalette";
import {PortfolioList} from "./PortfolioList";
import {NewFeatures} from "./NewFeatures";
import {Help} from "./Help";

const ResponsiveGridLayout = WidthProvider(Responsive);

type ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";
const availableHandles: ResizeHandle[] = ["se"];

export const HomeDashboard = () => {
	const appState = useAppStore((state) => state.appState);
	const user = appState.user;
	const initials = user ? appState.actions.renderInitials(user.id) : "";

	return (
		<HomeDashboardStyled>
			<ResponsiveGridLayout
				className="react-grid-layout"
				cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
				useCSSTransforms={true}
				isDraggable={true}
				compactType="vertical"
				resizeHandles={availableHandles}
			>
				<TileStyled
					className="react-grid-item"
					key="1"
					data-grid={{w: 12, h: 1.5, x: 0, y: 0, static: true}}
				>
					<WelcomeBackStyled>
						<WelcomeTextWrapper>
							<AvatarStyled>{initials}</AvatarStyled>
							<WelcomeTextStyled>Welcome back, {appState.user?.firstName || ""}!</WelcomeTextStyled>
						</WelcomeTextWrapper>
						<DateWrapper>{DateUtils.todayUSFormat()}</DateWrapper>
					</WelcomeBackStyled>
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="2"
					data-grid={{w: 3, h: 3.5, x: 0, y: 2}}
				>
					<PortfolioList />
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="3"
					data-grid={{w: 3, h: 3.5, x: 3, y: 2}}
				>
					3
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="4"
					data-grid={{w: 3, h: 2, x: 6, y: 2}}
				>
					4
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="5"
					data-grid={{w: 3, h: 1.5, x: 6, y: 4}}
				>
					5
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="6"
					data-grid={{w: 3, h: 2, x: 9, y: 2}}
				>
					6
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="7"
					data-grid={{w: 1.5, h: 1.5, x: 9, y: 6}}
				>
					<NewFeatures />
				</TileStyled>
				<TileStyled
					className="react-grid-item"
					key="8"
					data-grid={{w: 1.5, h: 1.5, x: 10.5, y: 6}}
				>
					<Help />
				</TileStyled>
			</ResponsiveGridLayout>
		</HomeDashboardStyled>
	);
};

const DateWrapper = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
`;

const WelcomeTextWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	${AvatarStyled} {
		width: 48px;
		height: 48px;
		font-size: 24px;
		line-height: 32px;
		font-weight: 400;
	}
`;

const WelcomeTextStyled = styled.div`
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
`;

const WelcomeBackStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	padding: 16px;
	background-color: ${colorPalette.primary.c500Primary};
	height: 100%;
	border-radius: ${radius.xl};
	color: white;
`;

const HomeDashboardStyled = styled.div`
	width: 100%;
	height: 100%;
`;

const TileStyled = styled.div`
	cursor: pointer;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 20px 0px #00000033;
	border-radius: ${radius.xl};
	user-select: none;

	&.resizing {
		opacity: 0.9;
	}
`;

import type {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {Constants} from "../../Constants";
import {MeasureType} from "../../renderers/SpaceViewRendererUtils";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {MarkupAB1D} from "./abstract/MarkupAB1D";
import type {IMarkupConfig} from "./abstract/MarkupUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";

export class MarkupLine extends MarkupAB1D {
	private _textContent: string = "";

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, {measureType: MeasureType.NONE, ...config});
	}

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const geometryData = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);
		const processedA = geometryData[0];
		const processedB = geometryData[1];

		if (this.measureType === MeasureType.DISTANCE) {
			const AB = {
				x: processedB.x - processedA.x,
				y: processedB.y - processedA.y,
			};
			const normalAB = THREEUtils.multiplyByScalar(THREEUtils.getNormal(AB), 20 * this._spaceViewRenderer.correctionMultiplier.current);
			const segments = [
				processedA.x + normalAB.x / 2,
				processedA.y + normalAB.y / 2,
				0,
				processedA.x - normalAB.x / 2,
				processedA.y - normalAB.y / 2,
				0,
				processedA.x,
				processedA.y,
				0,
				processedB.x,
				processedB.y,
				0,
				processedB.x + normalAB.x / 2,
				processedB.y + normalAB.y / 2,
				0,
				processedB.x - normalAB.x / 2,
				processedB.y - normalAB.y / 2,
				0,
			];

			(this._lineGeometry as LineSegmentsGeometry).setPositions(segments);
		} else {
			(this._lineGeometry as LineSegmentsGeometry).setPositions([processedA.x, processedA.y, 0, processedB.x, processedB.y, 0]);
		}

		if (this._isDashed) {
			this._line.computeLineDistances();
		}

		if (this.measureType === MeasureType.DISTANCE) {
			const distanceInSpaceUnit = THREEUtils.calculateDistance(this._worldGeometryData);
			const space = this._modelData?.space ? this._modelData.space : this._spaceViewRenderer.space;
			const unitName = this.unitName;
			const unit = Constants.DISTANCE_UNITS[unitName];
			const distance = MathUtils.convertDistanceFromSpaceUnit(distanceInSpaceUnit, unit.name, space.spaceUnitsPerMeter);

			if (unitName === "foot&inch") {
				const feetAndInches = MathUtils.convertFeetInDecimalToFeetAndInches(distance);

				this._textContent = MathUtils.formatFeetAndInchesArrayToText(feetAndInches);
			} else {
				this._textContent = `${distance.toFixed(Constants.TO_FIXED)} ${unit.abbreviation}`;
			}
			this.recreateTextGroups();
		}

		if (isLocal) {
			this._selectionPart.update([processedA.x, processedA.y, 0, processedB.x, processedB.y, 0]);
		}
		this.updateBoundingBox();
	}

	public override get textContent() {
		return this._textContent || super.textContent;
	}

	public override get fontColor() {
		return this.isTemp ? super.fontColor : this.measureType !== MeasureType.NONE ? this.shiftedColor : super.fontColor;
	}

	public get type() {
		return this.measureType === MeasureType.DISTANCE ? MarkupType.Linear_Distance : this._isDashed ? MarkupType.Dashed_Line : MarkupType.Line;
	}

	public get measureType() {
		return this.config.measureType;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Lambda} from "mobx";
import {observe} from "mobx";
import styled from "styled-components";
import type {XyiconCatalogSettingsModel, XyiconCatalogUpdateSettingsDto} from "../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {IModel} from "../../../data/models/Model";
import type {AppState} from "../../../data/state/AppState";
import type {FeatureMap} from "../../../data/state/AppStateConstants";
import {featureTitles} from "../../../data/state/AppStateConstants";
import type {ISectionData, ISectionField} from "../../modules/settings/modules/layout/LayoutSettings";
import type {Catalog} from "../../../data/models/Catalog";
import type {Space} from "../../../data/models/Space";
import type {Navigation} from "../../../Navigation";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import type {Report} from "../../../data/models/Report";
import {ReactUtils} from "../../utils/ReactUtils";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import {TimeUtils} from "../../../utils/TimeUtils";
import type {Field as FieldModel, IFieldAdapter} from "../../../data/models/field/Field";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {Boundary} from "../../../data/models/Boundary";
import {DebugInformation} from "../../../utils/DebugInformation";
import {AppUtils} from "../../../utils/AppUtils";
import type {ISelectSliderOption} from "../../widgets/input/selectslider/SelectSlider";
import {SelectSlider} from "../../widgets/input/selectslider/SelectSlider";
import {AssignType} from "../../modules/settings/modules/field/FieldForm";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {SideBar} from "../../sidebar/SideBar";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {LookupUtils} from "../../modules/abstract/sidepanel/tabs/details/LookupUtils";
import type {IPropertyDetails} from "../../modules/abstract/sidepanel/tabs/details/PropertiesSection";
import {getFieldIconAndInheritance} from "../../modules/abstract/sidepanel/tabs/details/field/FieldInputUtils";
import {Flex, FlexCenter, VerticalFlex, VerticalFlexStyle} from "../styles/styles";
import InfoIcon from "../icons/circle-information.svg?react";
import LookupFieldIcon from "../icons/arrow-up-right.svg?react";
import CloseIcon from "../icons/xmark.svg?react";
import PinIcon from "../icons/location-pin.svg?react";
import {SpaceCard} from "../modules/spaceeditor/spaceselector/SpaceCard";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import {PortfolioDefaultFieldsV5} from "../abstract/defaultfields/PortfolioDefaultFieldsV5";
import {CatalogDefaultFieldsV5} from "../abstract/defaultfields/CatalogDefaultFieldsV5";
import {SpaceDefaultFieldsV5} from "../abstract/defaultfields/SpaceDefaultFieldsV5";
import {BoundaryDefaultFieldsV5} from "../abstract/defaultfields/BoundaryDefaultFieldsV5";
import {XyiconDefaultFieldsV5} from "../abstract/defaultfields/XyiconDefaultFieldsV5";
import {ReportDefaultFieldsV5} from "../abstract/defaultfields/ReportDefaultFieldsV5";
import {MarkupDefaultFieldsV5} from "../abstract/defaultfields/MarkupDefaultFieldsV5";
import {InitialsV5} from "../widgets/InitialsV5";
import {ClickToEditInputStyled} from "../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled} from "../input/select/SelectInputV5";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {Functions} from "../../../utils/function/Functions";
import {NavigationEnum} from "../../../Enums";
import {DetailsTabV5HeaderStyles} from "./DetailsTabV5HeaderStyles";
import {FieldV5} from "./FieldV5";
import {MultiSelectInputV5} from "./datatypes/multi/MultiSelectInputV5";
import {MassFieldLookupInputV5} from "./datatypes/mass/MassFieldLookupInputV5";
import {SingleFieldInputV5} from "./datatypes/SingleFieldInputV5";
import {SelectSliderV5} from "./SelectSliderV5";
import {EmptyListViewV5} from "./EmptyListViewV5";
import {LinksSectionV5} from "./LinksSectionV5";
import {PortsSectionV5} from "./PortsSectionV5";
import {DocumentSectionV5} from "./DocumentSectionV5";
import {ReportScopeSectionV5} from "./ReportScopeSectionV5";
import {PropertiesSectionV5} from "./PropertiesSectionV5";
import {PhoneInputStyled} from "./datatypes/PhoneInputV5";

interface IDetailsTabV5Props<T extends IModel = IModel> {
	items: T[];
	feature: XyiconFeature;
	features: XyiconFeature[];
	isPortTemplateEditorOpen: boolean;
	permission?: Permission;
	noInitials?: boolean;
	appState?: AppState;
	transport?: TransportLayer;
	navigation?: Navigation;
	insideDetailsContainer?: boolean;
	onSelect?: (item: T[]) => void;
	setPortTemplateEditorOpen: (value: boolean) => void;
	onCatalogIconEditClick?: (catalog: Catalog) => void;
	closeWideSearchPanel?: () => void;
	onCloseOverlayedDetaislPanel?: () => void;
}

interface IDetailsTabV5State<T extends IModel = IModel> {
	hoveringModel: T;
	openFieldRefId: string;
	isSpaceListLoading: boolean;
	creating: boolean;
	focusedFieldParentSectionLabel: string;
	onKeyDownForceOpen: boolean;
}

const defaultFieldComponents: FeatureMap<React.ComponentClass<{item: IModel; permission?: Permission}>> = {
	[XyiconFeature.Portfolio]: PortfolioDefaultFieldsV5,
	[XyiconFeature.XyiconCatalog]: CatalogDefaultFieldsV5,
	[XyiconFeature.Space]: SpaceDefaultFieldsV5,
	[XyiconFeature.Boundary]: BoundaryDefaultFieldsV5,
	[XyiconFeature.Xyicon]: XyiconDefaultFieldsV5,
	[XyiconFeature.Report]: ReportDefaultFieldsV5,
	[XyiconFeature.Markup]: MarkupDefaultFieldsV5,
};

const lookupPrefix = "lookup-";
const logId: string = "Rendering DetailsTab UI";

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class DetailsTabV5<T extends IModel = IModel> extends React.Component<IDetailsTabV5Props<T>, IDetailsTabV5State<T>> {
	private _previousItems: T[] = [];
	private _ref = React.createRef<HTMLDivElement>();
	private _disposer: Lambda;
	private _sectionFields: string[] = [];
	private _disposerForPortfolioIdListener: Lambda | null = null;
	private _sectionFieldsRefArray: {label: string; ref: React.RefObject<ToggleContainerV5>}[] = [];
	private _onMouseDownTargetElement: Element = null;

	public _assignedTypes: ISelectSliderOption[] = [
		{
			id: AssignType.Unassigned,
			label: AssignType[AssignType.Unassigned],
		},
		{
			id: AssignType.Assigned,
			label: AssignType[AssignType.Assigned],
		},
	];

	public static getDerivedStateFromProps(props: IDetailsTabV5Props, state: IDetailsTabV5State) {
		// if selection changed:
		// forget openFieldRefId if it's not in current fields anymore or if there is 1 or no items selected
		if (props.items) {
			if (props.items.length < 2 || !DetailsTabV5.getFieldRefIds(props).includes(state.openFieldRefId)) {
				return {
					openFieldRefId: "",
				} as IDetailsTabV5State;
			}
		}

		return null;
	}

	private static getFieldRefIds(props: IDetailsTabV5Props, feature?: XyiconFeature) {
		const {items, appState} = props;

		const fieldRefIdsForType: string[] = [];

		for (const item of items) {
			if (item?.typeId) {
				const newRefIds = appState.actions.getFieldRefIdsForType(item.typeId, feature ?? item.ownFeature);

				for (const refId of newRefIds) {
					if (!fieldRefIdsForType.includes(refId)) {
						fieldRefIdsForType.push(refId);
					}
					if (LookupUtils.isLookupField(appState.actions.getFieldByRefId(refId) as FieldModel, item, appState.actions)) {
						fieldRefIdsForType.push(`${lookupPrefix}${refId}`);
					}
				}
			}
		}

		return fieldRefIdsForType;
	}

	constructor(props: IDetailsTabV5Props<T>) {
		super(props);
		this.state = {
			isSpaceListLoading: true,
			hoveringModel: null,
			openFieldRefId: "",
			creating: false,
			focusedFieldParentSectionLabel: "",
			onKeyDownForceOpen: false,
		};
	}

	private renderDefaultFields() {
		const DefaultFields = defaultFieldComponents[this.props.feature];

		if (DefaultFields) {
			const item = this.props.items[0];

			return (
				<DefaultFields
					item={item}
					permission={this.props.permission}
				/>
			);
		}
		return null;
	}

	private renderInitialComponent(feature: XyiconFeature) {
		const {items, appState, onCatalogIconEditClick, permission} = this.props;

		const item = items[0];
		const itemType = appState.actions.getTypeById(item?.typeId);
		const color = itemType?.settings.color.hex || "FFFFFF";
		let isFavorite = false;

		if (feature == XyiconFeature.XyiconCatalog) {
			const catalogItem = item as IModel as Catalog;

			isFavorite = catalogItem.isFavorite;
		}
		return (
			<InitialsV5
				item={item}
				color={color}
				name={itemType?.name}
				onCatalogIconEditClick={onCatalogIconEditClick}
				permission={permission}
				isFavorite={isFavorite}
				//onSpaceIconClick={this.onSpaceClick}
			/>
		);
	}

	private onSelectPropagatedSource = (model: IModel) => {
		if (this.props.features.includes(model.ownFeature)) {
			this.props.onSelect?.([model] as T[]);
		}
	};

	private onHoverPropagatedValue = (model: T) => {
		this.setState({hoveringModel: model});
	};

	private onOpenMultiInput = (refId: string) => {
		this.setState({openFieldRefId: refId});
	};

	private shouldFieldBeDisabledByPermission(field: IFieldAdapter) {
		const {permission, appState, items} = this.props;
		const perm = permission ?? appState.actions.getFieldPermission(field, items);

		return perm === Permission.None || perm === Permission.View;
	}

	private getLayoutSections() {
		const {items, feature, features} = this.props;

		if (items.length > 1 && features.length > 1) {
			return [...this.getSections(XyiconFeature.Boundary), ...this.getSections(XyiconFeature.Xyicon)];
		} else {
			return this.getSections(feature);
		}
	}

	private getSections(feature: XyiconFeature) {
		const layout = this.props.appState.layouts[feature];
		const sections: ISectionData[] = layout ? layout.sections : [];

		return sections.map((section) => {
			return {
				feature: feature,
				section: section,
			};
		});
	}

	private getAssignedList(feature: XyiconFeature) {
		const {items, appState} = this.props;

		if (!items[0] || !appState.user?.isAdmin || this.props.feature !== XyiconFeature.XyiconCatalog) {
			return [];
		}

		return DetailsTabV5.getFieldRefIds(this.props, feature).filter((f) => this.props.appState.actions.getFieldByRefId(f)?.isAssignedByModel);
	}

	private getSliderRowsFromFieldList(list: string[], items: T[], feature: XyiconFeature) {
		const {appState} = this.props;
		const catalogs = items as IModel[] as Catalog[];
		// mapping through all the items and get the necessary data, don't need to run this for every refId
		const dataArray = catalogs.map((cat) => {
			const visibleFields = (feature === XyiconFeature.Xyicon ? cat.xyiconVisibleFields : cat.catalogVisibleFields) as string[];
			const assignByModelList = appState.actions
				.getFieldRefIdsForType(cat.typeId, feature)
				.filter((f) => appState.actions.getFieldByRefId(f).isAssignedByModel);

			return {visibleFields, assignByModelList};
		});

		return list.map((refId) => {
			const field = appState.actions.getFieldByRefId(refId);
			const isFieldUnassignedToAnyItem = dataArray.some((el) => el.assignByModelList.includes(refId) && !el.visibleFields.includes(refId));

			return {
				label: field.name,
				value: isFieldUnassignedToAnyItem ? AssignType.Unassigned : AssignType.Assigned,
				disabledOptionsList: [],
			};
		});
	}

	private onSliderChange = async (fieldNames: string[], value: AssignType, feature: XyiconFeature) => {
		const {items, appState} = this.props;
		// the visible sliders are the first item's assigned list
		// we need to change the same fields in other Catalog items (if they have it)
		const fieldRefIdsToChange = fieldNames.map((rowKey) => appState.actions.getFieldByName(feature, rowKey).refId);
		const updatedXyiconCatalogs: XyiconCatalogSettingsModel[] = [];

		items.forEach((item: IModel) => {
			fieldRefIdsToChange.forEach((fieldRefId) => {
				const visibleFields =
					feature === XyiconFeature.XyiconCatalog ? (item as Catalog).catalogVisibleFields : (item as Catalog).xyiconVisibleFields;
				const assignByModelList = appState.actions
					.getFieldRefIdsForType(item.typeId, feature)
					.filter((f) => appState.actions.getFieldByRefId(f).isAssignedByModel);

				if (assignByModelList.includes(fieldRefId)) {
					if (value === AssignType.Unassigned) {
						ArrayUtils.removeMutable(visibleFields, fieldRefId);
					} else {
						ArrayUtils.addMutable(visibleFields, fieldRefId);
					}
				}
			});

			updatedXyiconCatalogs.push({xyiconCatalogID: item.id, settings: (item as Catalog).settings});
		});

		const {result, error} = await this.props.transport.requestForOrganization<XyiconCatalogUpdateSettingsDto>({
			url: "xyiconcatalogs/updatesettings",
			method: XHRLoader.METHOD_POST,
			params: {
				updatedXyiconCatalogs,
			},
		});

		if (error) {
			console.warn(error);
		}
	};

	private getProperties(item: Xyicon | Boundary) {
		const properties: IPropertyDetails[] = [];

		properties.push({
			name: "Position",
			measure: true,
			parts: [
				{
					name: "px",
					label: "X",
					ref: "x",
				},
				{
					name: "py",
					label: "Y",
					ref: "y",
				},
				{
					name: "pz",
					label: "Z",
					ref: "z",
				},
			],
		});

		if (item.ownFeature === XyiconFeature.Boundary) {
			properties.push(
				{
					name: "Dimensions",
					measure: true,
					parts: [
						{
							name: "dx",
							label: "X",
							ref: "dimensionX",
						},
						{
							name: "dy",
							label: "Y",
							ref: "dimensionY",
						},
						{
							name: "dz",
							label: "Z",
							ref: "dimensionZ",
						},
					],
				},
				{
					name: "Area",
					measure: false,
					parts: [],
				},
			);
		}

		properties.push({
			name: "Rotation",
			measure: false,
			parts: [
				{
					name: "o",
					label: "Z",
					ref: "orientation",
				},
			],
		});

		return properties;
	}

	private onLookupLinkedFieldsClick = (value: string) => {
		this.props.appState.selectedFieldInputRefId = value || "";
	};

	private renderFields() {
		const {items, appState, feature, transport, closeWideSearchPanel} = this.props;
		const {openFieldRefId, focusedFieldParentSectionLabel} = this.state;
		const actions = appState.actions;
		const firstItem = items[0];
		const multiSelection = items.length > 1;
		const fieldRefIdsForType = DetailsTabV5.getFieldRefIds(this.props);
		const sections = this.getLayoutSections();

		this._sectionFields.length = 0;
		this._sectionFieldsRefArray.length = 0;

		return sections.map((sectionData, index) => {
			// Only display fields that are assigned to the given type, unless they are inherited or default fields
			// Inherited fields don't need to be mapped to the selected items type.
			// Examples for inherited fields:
			// - xyicons inherit portfolio fields, in this case if the portfolio fields are added in the layout definition
			// they are displayed, regardless of the type of the selected item.
			// - linked fields

			const section = sectionData.section;
			const sectionFields = section.fields.filter((sectionField: ISectionField) => {
				const field = actions.getFieldByRefId(sectionField.id);

				if (!field) {
					// field not found (may have been deleted)
					return false;
				}

				const isDefault = field.default;
				// no need to check permission if the field is default
				const hasPermission = isDefault ? true : (this.props.permission ?? actions.getFieldPermission(field, items)) !== Permission.None;

				if (hasPermission) {
					// is this needed?
					if (field.refId.includes("versionName") || field.refId.includes("issuanceDate")) {
						return false;
					}

					const isMappedToType = fieldRefIdsForType.includes(field.refId);

					if (multiSelection) {
						if (isDefault || field.feature !== sectionData.feature || field.hasFormula) {
							// No default, calculated or inherited fields in multi selection
							return false;
						}
						return isMappedToType && items.some((item) => !actions.isFieldHiddenByMasking(item, field));
					} else {
						if (!isDefault && field.feature === feature) {
							// Custom fields that belong to this feature will be shown if:
							// - the type is mapped correctly OR
							// - there are propagated non-null values from linked objects (eg. boundary - boundary)
							return (
								(isMappedToType && !actions.isFieldHiddenByMasking(firstItem, field)) || actions.getDynamicFieldPropagations(firstItem, field)?.length
							);
						} else {
							// field is default or belongs to another feature
							return actions.isFieldShownForFeature(field, feature) && (isDefault || actions.getFieldPropagations(firstItem, field)?.length);
						}
					}
				} else {
					return false;
				}
			});

			if (sectionFields.length > 0) {
				// Check if this ToggleContainer should be in collapsed state because of tabbing in a collapsed fieldSection
				const collapsed = focusedFieldParentSectionLabel === section.label && !this.getSectionFieldParentSectionStatusByLabel(section.label);
				const ref = React.createRef<ToggleContainerV5>();

				this._sectionFieldsRefArray.push({label: section.label, ref});

				return (
					<ToggleContainerV5
						key={`${this.props.items.map((item) => item?.id).join("_")}_${section.label}_${index}`}
						title={section.label}
						saveStateToLocalStorage={true}
						className={ReactUtils.cls("FieldSection", {collapsed})}
						ref={ref}
					>
						{sectionFields.map((sectionField: ISectionField) => {
							const field = actions.getFieldByRefId(sectionField.id);
							const elements: React.ReactElement[] = [];
							const lookupLinkFields: T[] = [];
							const notLookupLinkFields: T[] = [];
							const shouldFieldBeDisabledByPermission = this.shouldFieldBeDisabledByPermission(field);

							items.forEach((item) => {
								LookupUtils.isLookupField(field, item, actions) ? lookupLinkFields.push(item) : notLookupLinkFields.push(item);
							});

							if (lookupLinkFields.length > 0) {
								// This is a lookup field!
								const lookupFieldOptions = LookupUtils.getLookupFieldOptions(appState, field, firstItem);
								const selectedLookupFieldOptions = LookupUtils.getSelectedLookupFieldOptions(appState, lookupFieldOptions, firstItem);
								const prefixedRefId = `${lookupPrefix}${field.refId}`;
								const onChange = LookupUtils.getOnChangeForLookup(transport, appState, lookupFieldOptions, firstItem);

								if (!shouldFieldBeDisabledByPermission && !field.hasFormula && !field.default && field.feature === feature) {
									ArrayUtils.addMutable(this._sectionFields, field.refId);
								}

								elements.push(
									!multiSelection ? (
										<FieldV5
											key={field.refId + field.name}
											label={field.name}
											disabled={false}
											noWrap={false}
											icons={{preLabelIcon: LookupFieldIcon}}
											tooltips={{
												preLabelIconTooltip:
													"This field's value is inherited from a linked object. A lookup field allows you to create or break links from within it.",
											}}
											className={ReactUtils.cls({calculated: field.hasFormula})}
										>
											<MultiSelectInputV5
												key={field.refId}
												options={lookupFieldOptions}
												selected={selectedLookupFieldOptions}
												render={(obj) => (
													<div className="hbox alignCenter">
														{obj.value}&nbsp;<span style={{color: "#A0A0A0"}}>({obj.xyicon.refId})</span>
													</div>
												)}
												onChange={onChange}
												focused={
													appState.selectedFieldInputRefId === field.refId && appState.isDetailsContainerOpened === this.props.insideDetailsContainer
												}
												onClick={() => this.onLookupLinkedFieldsClick(field.refId)}
											/>
										</FieldV5>
									) : (
										<MassFieldLookupInputV5
											key={prefixedRefId}
											field={field}
											prefixedRefId={prefixedRefId}
											items={lookupLinkFields}
											open={openFieldRefId === prefixedRefId}
											onOpen={this.onOpenMultiInput}
											selectableOptions={lookupFieldOptions}
											selectedOptions={selectedLookupFieldOptions}
											onChange={onChange}
										/>
									),
								);
							}

							if (notLookupLinkFields.length > 0) {
								const {icon, isXyiconXyiconLink, isInheritedFromBoundary} =
									!multiSelection && getFieldIconAndInheritance(field, items[0], sectionData.feature, actions);
								const shouldFieldBeDisabledByPermission = this.shouldFieldBeDisabledByPermission(field);

								if (!shouldFieldBeDisabledByPermission && !field.hasFormula && !field.default && field.feature === feature) {
									ArrayUtils.addMutable(this._sectionFields, field.refId);
								}

								elements.push(
									<SingleFieldInputV5
										key={field.refId}
										item={firstItem}
										field={field}
										className={ReactUtils.cls({inherited: icon === "dol-field"})}
										fieldRefIdsForType={fieldRefIdsForType}
										noWrap={sectionField.noWrap}
										feature={feature}
										hoveringModel={this.state.hoveringModel}
										onHoverPropagatedValue={this.onHoverPropagatedValue}
										onSelectPropagatedSource={this.onSelectPropagatedSource}
										disabled={this.shouldFieldBeDisabledByPermission(field)}
										icon={icon}
										isXyiconXyiconLink={isXyiconXyiconLink}
										isInheritedFromBoundary={isInheritedFromBoundary}
										closeWideSearchPanel={closeWideSearchPanel}
									/>,
								);
							}

							return elements;
						})}
					</ToggleContainerV5>
				);
			}
			return null;
		});
	}

	private getFieldInputRefId = (offset: number): string => {
		const {appState} = this.props;

		let index = this._sectionFields.indexOf(appState.selectedFieldInputRefId) + offset;

		if (index === this._sectionFields.length) {
			index = 0;
		} else if (index === -1) {
			// This is happening if the user is shift+tab at the first index
			index = this._sectionFields.length - 1;
		}

		if (appState.selectedFieldInputRefId) {
			return this._sectionFields.at(index) || "";
		}
	};

	private getSectionFieldParentSectionByRefId = (refId: string): ISectionData => {
		const sections = this.getLayoutSections();

		return sections.find((section) => section.section.fields.find((f) => f.id === refId)).section;
	};

	private getSectionFieldParentSectionStatusByLabel = (label: string): boolean => {
		return localStorage.getItem(`srv4-org-${this.props.appState.organizationId}-feature-${SideBar.activeNav}-section-${label}-state`) === "true";
	};

	private onKeyDown = (e: KeyboardEvent) => {
		const {appState} = this.props;
		const {focusedFieldParentSectionLabel} = this.state;
		const sections = this.getLayoutSections();
		const isTargetGeoLatInput = (e.target as HTMLInputElement).classList.contains("geoLat");

		let targetElement: Element = e.target as Element;

		const realParentId = targetElement.parentElement.dataset?.realparentid;

		if (realParentId) {
			targetElement = document.getElementById(realParentId);
		}

		const isTargetInDetailsContainer = document.querySelector(".DetailsContainer").contains(targetElement);

		if (
			!!this.props.insideDetailsContainer === isTargetInDetailsContainer &&
			isTargetInDetailsContainer === appState.isDetailsContainerOpened &&
			!isTargetGeoLatInput &&
			(!!appState.selectedFieldInputRefId || focusedFieldParentSectionLabel !== "")
		) {
			AppUtils.disableScrolling(false);

			let nextFieldInputRefId = "";
			let isOffsetNegative = 1;
			let focusedFieldParentSectionLabelVar = "";

			if (e.key === KeyboardListener.KEY_TAB) {
				e.preventDefault();

				if (e.shiftKey) {
					isOffsetNegative *= -1;
				}

				nextFieldInputRefId = this.getFieldInputRefId(isOffsetNegative);

				if (focusedFieldParentSectionLabel !== "") {
					const nextSectionFields = sections.at(
						sections.indexOf(sections.find((s) => s.section.label === focusedFieldParentSectionLabel)) + isOffsetNegative,
					).section.fields;

					nextFieldInputRefId = nextSectionFields[nextSectionFields.length - 2].id;
				} else {
					const nextFieldParentSection = this.getSectionFieldParentSectionByRefId(nextFieldInputRefId);
					const nextFieldParentSectionLabel = nextFieldParentSection.label;
					const isNextFieldsParentSectionOpened = this.getSectionFieldParentSectionStatusByLabel(nextFieldParentSectionLabel);

					if (!isNextFieldsParentSectionOpened) {
						focusedFieldParentSectionLabelVar = nextFieldParentSectionLabel;
						nextFieldInputRefId = "";
					}
				}

				appState.selectedFieldInputRefId = nextFieldInputRefId;
			} else if ((e.code === KeyboardListener.CODE_SPACE || e.key === KeyboardListener.KEY_ENTER) && focusedFieldParentSectionLabel !== "") {
				e.preventDefault();

				// Open closed section
				this._sectionFieldsRefArray.find((s) => s.label === focusedFieldParentSectionLabel)?.ref.current.onToggleOpen();

				// Select the first field
				nextFieldInputRefId = sections.find((s) => s.section.label === focusedFieldParentSectionLabel).section.fields[0].id;

				appState.selectedFieldInputRefId = nextFieldInputRefId;
			}

			this.setState({
				focusedFieldParentSectionLabel: focusedFieldParentSectionLabelVar,
				onKeyDownForceOpen: false,
			});
		}
	};

	private onMouseDown = (e: MouseEvent) => {
		// This is needed to check if the user clicks out of the field, or just selecting the fieldValue and ends the selection out of the input
		this._onMouseDownTargetElement = e.target as Element;
	};

	private onDocumentClick = (e: MouseEvent) => {
		// This selector only selects the already focused field, when clicking into it
		// This is needed to prevent focusloss when clicking into the edited field
		const currentlyEditedBasicInput = document.querySelector(`.Field ${ClickToEditInputStyled} .field-input-container input`);
		const currentlyEditedPhoneTextInput = document.querySelector(`.Field ${ClickToEditInputStyled} ${PhoneInputStyled} input`);
		const currentlyEditedPhoneSelectInput = document.querySelector(`.Field ${ClickToEditInputStyled} ${PhoneInputStyled} ${SelectInputStyled}`);
		const currentlyEditedFieldInput = [currentlyEditedBasicInput, currentlyEditedPhoneTextInput, currentlyEditedPhoneSelectInput];
		const eventTargetInModalContainer = this.props.appState.app.modalContainer.contains(e.target as Element);
		const singleSelectClick = this._onMouseDownTargetElement?.className === `${SelectInputStyled}`; // only singleSelect field
		const otherFieldClickOut =
			this._onMouseDownTargetElement === e.target && currentlyEditedFieldInput.includes(e.currentTarget as unknown as Element);

		// singleSelect works differently from other fields, it should be blurred and selectedFieldInputRefId should be set to ""
		if (e.target instanceof Element && !eventTargetInModalContainer && (otherFieldClickOut || singleSelectClick)) {
			this.props.appState.selectedFieldInputRefId = "";
		}
	};

	public override async componentDidMount() {
		// Also refresh when active portfolio changes
		this._disposerForPortfolioIdListener = observe(this.props.appState, "portfolioId", async () => {
			this.props.transport.services.feature.refreshList(XyiconFeature.Link);
			this.setState({isSpaceListLoading: true});

			try {
				await this.refreshSpaces();
			} catch (error) {
				console.warn(error);
			}

			this.setState({isSpaceListLoading: false});
		});

		// Refresh lists on mount (if needed)
		this.props.transport.services.feature.refreshList(XyiconFeature.Link);
		await this.refreshSpaces();
		this.setState({isSpaceListLoading: false});

		document.addEventListener("keydown", this.onKeyDown);
		document.addEventListener("mousedown", this.onMouseDown);
		document.addEventListener("click", this.onDocumentClick);
	}

	public override componentWillUnmount() {
		this._disposer?.();
		this._disposer = null;

		document.removeEventListener("keydown", this.onKeyDown);
		document.removeEventListener("click", this.onDocumentClick);
		document.removeEventListener("mousedown", this.onMouseDown);
		this._disposerForPortfolioIdListener?.();
		this._disposerForPortfolioIdListener = null;

		this.props.appState.selectedFieldInputRefId = "";
	}

	private onSpaceClick = (space: Space) => {
		this.props.navigation.goApp(NavigationEnum.NAV_SPACE, space.id);
	};

	private refreshSpaces = async () => {
		if (this.props.feature === XyiconFeature.Portfolio) {
			(await this.props.transport.services.feature.refreshList(XyiconFeature.Space)) as Space[];
		}
	};

	private onAddClick = async () => {
		this.props.navigation.go("app/spaces");

		//This is an ugly hack to open the "create spaces panel"
		//TODO: make not hacky solution
		await TimeUtils.wait(1000);

		const createSpaceButton = document.querySelector('[title="Create Space"]') as HTMLElement;

		if (createSpaceButton) {
			createSpaceButton.click();
		} else {
			console.warn("No button with title Create Space");
		}
	};

	override componentDidUpdate(prevProps: Readonly<IDetailsTabV5Props<T>>, prevState: Readonly<IDetailsTabV5State<T>>): void {
		// scroll to the top if the selection changes
		if (!ObjectUtils.compare(prevProps.items, this.props.items)) {
			if (this._ref.current?.parentElement?.scrollTop) {
				this._ref.current.parentElement.scrollTop = 0;
			}

			DebugInformation.end(logId);
		}
	}

	public override render() {
		DebugInformation.start(logId);
		const {feature, appState, noInitials} = this.props;
		const spaces = appState.actions.getList<Space>(XyiconFeature.Space);
		const {spaceViewRenderer} = appState.app;

		// Don't add the same element twice
		// Eg.: 2 boundaryspacemaps are selected, but their parent is the same boundary
		const items = ArrayUtils.removeDuplicates(this.props.items);
		const firstItem = items[0];

		const catalogFieldList = this.getAssignedList(XyiconFeature.XyiconCatalog);
		const xyiconFieldList = this.getAssignedList(XyiconFeature.Xyicon);

		return (
			<DetailsTabV5Styled>
				{items.length > 1 && (
					<div className="heading hbox">
						<h4>
							<span className="multiSelectHeader">{items.length} Objects selected</span>
						</h4>
					</div>
				)}
				<VerticalFlex
					$flex="1"
					className={ReactUtils.cls({multiSelect: items.length > 1})}
					ref={this._ref}
				>
					{items.length === 0 && <div className="noData">{"Please select one or more objects to display details."}</div>}
					{items.length > 0 && (
						<DetailsTabV5HeaderStyles className={ReactUtils.cls({noInitials, [featureTitles[feature]]: true})}>
							<FlexCenter $gap="10px">
								<InfoIcon />
								<h2>Details</h2>
								{feature === XyiconFeature.Space && (
									<IconButtonV5
										IconComponent={PinIcon}
										onClick={Functions.emptyFunction}
									/>
								)}
								<IconButtonV5
									onClick={this.props.onCloseOverlayedDetaislPanel}
									IconComponent={CloseIcon}
								/>
							</FlexCenter>
							<Flex $gap="16px">
								<VerticalFlex>{!noInitials && this.renderInitialComponent(feature)}</VerticalFlex>
								<VerticalFlex
									$flex="1"
									$gap="8px"
								>
									{this.renderDefaultFields()}
								</VerticalFlex>
							</Flex>
						</DetailsTabV5HeaderStyles>
					)}
					{items.length > 0 && ![XyiconFeature.Report, XyiconFeature.Markup].includes(feature) && (
						<ToggleContainerV5
							title="Fields"
							saveStateToLocalStorage={true}
						>
							{this.renderFields()}
						</ToggleContainerV5>
					)}
					{items.length === 1 && catalogFieldList.length > 0 && (
						<ToggleContainerV5
							title="Assign Fields by Catalog Model"
							saveStateToLocalStorage={true}
						>
							<SelectSliderV5
								options={this._assignedTypes}
								rows={this.getSliderRowsFromFieldList(catalogFieldList, items, XyiconFeature.XyiconCatalog)}
								onChange={(fieldNames: string[], value: AssignType) => this.onSliderChange(fieldNames, value, XyiconFeature.XyiconCatalog)}
							/>
						</ToggleContainerV5>
					)}
					{items.length === 1 && xyiconFieldList.length > 0 && (
						<ToggleContainerV5
							title="Assign Fields by Xyicon Model"
							saveStateToLocalStorage={true}
						>
							<SelectSlider
								options={this._assignedTypes}
								rows={this.getSliderRowsFromFieldList(xyiconFieldList, items, XyiconFeature.Xyicon)}
								onChange={(fieldNames: string[], value: AssignType) => this.onSliderChange(fieldNames, value, XyiconFeature.Xyicon)}
							></SelectSlider>
						</ToggleContainerV5>
					)}
					{items.length === 1 && (
						<>
							{feature === XyiconFeature.Portfolio && (
								<ToggleContainerV5
									title="Spaces"
									saveStateToLocalStorage={true}
								>
									{this.state.isSpaceListLoading ? (
										<div className="loadingSpaces">
											<LoaderIcon />
											<span className="loadText">Loading spaces...</span>
										</div>
									) : spaces.length === 0 ? (
										<div className="noDataSection">
											<EmptyListViewV5
												onAddClick={this.onAddClick}
												feature={XyiconFeature.Space}
											/>
										</div>
									) : (
										<SpaceThumbnailsStyled>
											{spaces
												.toSorted((a: Space, b: Space) => StringUtils.sortIgnoreCase(a.name, b.name))
												.map((space: Space) => {
													return (
														<SpaceCard
															key={space.id}
															isSelected={false}
															space={space}
															onClick={this.onSpaceClick}
														/>
													);
												})}
										</SpaceThumbnailsStyled>
									)}
								</ToggleContainerV5>
							)}
							<LinksSectionV5
								item={firstItem}
								feature={feature}
								saveStateToLocalStorage={true}
							/>
							{feature !== XyiconFeature.Report && (
								<PortsSectionV5
									item={firstItem}
									feature={feature}
									isPortTemplateEditorOpen={this.props.isPortTemplateEditorOpen}
									setPortTemplateEditorOpen={this.props.setPortTemplateEditorOpen}
									saveStateToLocalStorage={true}
								/>
							)}
						</>
					)}
					{items.length > 0 && (
						<DocumentSectionV5
							items={items}
							feature={feature}
							saveStateToLocalStorage={true}
						/>
					)}
					{items.length === 1 && feature === XyiconFeature.Report && appState.user?.isAdmin && (
						<>
							<ReportScopeSectionV5 report={firstItem as IModel as Report} />
						</>
					)}
					{items.length > 0 &&
						spaceViewRenderer.isMounted &&
						items.every(
							(item) =>
								(item.ownFeature === XyiconFeature.Xyicon && !(item as IModel as Xyicon).isEmbedded) || item.ownFeature === XyiconFeature.Boundary,
						) && (
							<PropertiesSectionV5
								saveStateToLocalStorage={true}
								properties={this.getProperties(firstItem as IModel as Xyicon)} // doesn't get dimension if both are selected because the order depends on SpaceItemController selectedItems getter
							/>
						)}
				</VerticalFlex>
			</DetailsTabV5Styled>
		);
	}
}

const DetailsTabV5Styled = styled.div`
	${VerticalFlexStyle};
	flex: 1;
	min-width: 0;
	padding: 16px;
`;

const SpaceThumbnailsStyled = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
	gap: 16px;
`;

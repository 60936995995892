import type {IModel} from "../../../../../../../data/models/Model";
import {XyiconFeature} from "../../../../../../../generated/api/base";

export const maxNumberOfIcons = 5;

export const MultiDefaultFieldSortOrder = [
	XyiconFeature.Xyicon,
	XyiconFeature.Boundary,
	XyiconFeature.Markup,
	XyiconFeature.XyiconCatalog,
	XyiconFeature.Space,
	XyiconFeature.Portfolio,
];

export const MultiDefaultFieldSortIcons = (a: IModel, b: IModel) => {
	let aIndex = MultiDefaultFieldSortOrder.indexOf(a.ownFeature);
	let bIndex = MultiDefaultFieldSortOrder.indexOf(b.ownFeature);

	return aIndex - bIndex;
};

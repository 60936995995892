import type {Pointer} from "../../utils/interaction/Pointer";
import type {IPointerDetectorConfig} from "../../utils/interaction/PointerDetector";
import {PanAndZoomGestures} from "../../utils/interaction/gestures/PanAndZoomGestures";
import type {IWrapperProps} from "../widgets/wrapper/Wrapper";
import {Wrapper} from "../widgets/wrapper/Wrapper";

interface IGestureDetectorProps extends IWrapperProps, Partial<IPointerDetectorConfig> {
	onClick?: (pointer: Pointer) => void;
	onLongPress?: (pointer: Pointer) => void;
	parent?: HTMLElement;
}

export class GestureDetectorReact extends Wrapper<IGestureDetectorProps, HTMLElement> {
	private _panAndZoomGestures: PanAndZoomGestures;

	protected override onChildMounted(child: HTMLElement) {
		this._panAndZoomGestures = new PanAndZoomGestures({
			clickDistanceTolerance: -1,
			element: child,
			longTap: {
				enabled: !!this.props.onLongPress,
				timeout: 750,
			},
		});

		this.props.onClick && this._panAndZoomGestures.signals.click.add(this.props.onClick);
		this.props.onLongPress && this._panAndZoomGestures.signals.longClick.add(this.props.onLongPress);
	}

	public override componentWillUnmount() {
		if (this._panAndZoomGestures) {
			this._panAndZoomGestures = null;
		}
	}
}

import {LogicalSeparator} from "./LogicalSeparator";

interface ILogicalSeparatorConfig {
	id: LogicalSeparator;
	label: string;
}

export class LogicalSeparators {
	public static readonly map: {[type: string]: ILogicalSeparatorConfig} = {};
	public static readonly array: ILogicalSeparatorConfig[] = [];

	public static add(separator: LogicalSeparator, label: string) {
		const config: ILogicalSeparatorConfig = {
			id: separator,
			label: label,
		};

		this.map[separator] = config;
		this.array.push(config);
	}
}

LogicalSeparators.add(LogicalSeparator.AND, "AND");
LogicalSeparators.add(LogicalSeparator.OR, "OR");

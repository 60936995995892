import type {Color} from "../generated/api/base";

export class BasicColors {
	public static getBasicColors(isTransparencyEnabled?: boolean): Color[] {
		return [
			{hex: "FFFFFF", transparency: isTransparencyEnabled ? 1 : 0},
			{hex: "FFFFFF", transparency: 0},
			{hex: "C4C4C4", transparency: 0},
			{hex: "887E7E", transparency: 0},
			{hex: "575050", transparency: 0},
			{hex: "FF0000", transparency: 0},
			{hex: "E91E63", transparency: 0},
			{hex: "9C27B0", transparency: 0},
			{hex: "3F51B5", transparency: 0},
			{hex: "2196F3", transparency: 0},
			{hex: "03A9F4", transparency: 0},
			{hex: "00BCD4", transparency: 0},
			{hex: "009688", transparency: 0},
			{hex: "4CAF50", transparency: 0},
			{hex: "8BC34A", transparency: 0},
			{hex: "CDDC39", transparency: 0},
			{hex: "FFEB3B", transparency: 0},
			{hex: "FFC107", transparency: 0},
			{hex: "FF9800", transparency: 0},
			{hex: "FF5722", transparency: 0},
			{hex: "795548", transparency: 0},
			{hex: "9E9E9E", transparency: 0},
			{hex: "607D8B", transparency: 0},
			{hex: "455A64", transparency: 0},
			{hex: "263238", transparency: 0},
		];
	}

	public static getBasicColorsV5(): Color[] {
		return [
			{hex: "4ECCC6", transparency: 0},
			{hex: "00C875", transparency: 0},
			{hex: "037F4C", transparency: 0},
			{hex: "9CD326", transparency: 0},
			{hex: "CAB641", transparency: 0},
			{hex: "FFCC00", transparency: 0},
			{hex: "FDAB3D", transparency: 0},
			{hex: "FF642E", transparency: 0},
			{hex: "FFADAD", transparency: 0},
			{hex: "FF7575", transparency: 0},
			{hex: "FAA1F1", transparency: 0},
			{hex: "FF5AC4", transparency: 0},
			{hex: "FF158A", transparency: 0},
			{hex: "E2445C", transparency: 0},
			{hex: "BA3254", transparency: 0},
			{hex: "A25DDC", transparency: 0},
			{hex: "784BD1", transparency: 0},
			{hex: "7E3B8A", transparency: 0},
			{hex: "66CCFF", transparency: 0},
			{hex: "579BFC", transparency: 0},
			{hex: "225091", transparency: 0},
			{hex: "000088", transparency: 0},
			{hex: "5559DF", transparency: 0},
			{hex: "401694", transparency: 0},
			{hex: "7F5347", transparency: 0},
			{hex: "9AADBD", transparency: 0},
			{hex: "68A1BD", transparency: 0},
			{hex: "C4C4C4", transparency: 0},
			{hex: "808080", transparency: 0},
			{hex: "333333", transparency: 0},
		];
	}
}

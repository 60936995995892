import type {IFieldAdapter} from "../../field/Field";
import {DateUtils} from "../../../../utils/DateUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {IBooleanFieldSettingsDefinition} from "../../../../ui/modules/settings/modules/field/datatypes/BooleanFieldSettings";

export type IFilterOperatorMethod = (source: any, ...params: any[]) => boolean;

export function isBlank(value: string | string[]) {
	return Array.isArray(value) ? value.length === 0 : !value;
}

export function isNotBlank(value: string | string[]) {
	return !isBlank(value);
}

export function isEqualTo_str(value: string, param: string) {
	return StringUtils.equalsIgnoreCase(value, param);
}

export function isNotEqualTo_str(value: string, param: string) {
	return !isEqualTo_str(value, param);
}

export function isEqualTo_num(value: string, param: number) {
	const valueAsNum = parseFloat(value);

	param = param ?? 0; // param can be undefined when it left to default 0
	return valueAsNum === param;
}

export function isNotEqualTo_num(value: string, param: number) {
	return !isEqualTo_num(value, param);
}

export function contains(value: string | string[], param: string) {
	if (!(param || "").toLowerCase) {
		return false;
	}

	value = Array.isArray(value) ? value.map((v) => v.toLowerCase()) : (value || "").toLowerCase();
	param = (param || "").toLowerCase();
	return value.includes(param);
}

export function doesNotContain(value: string, param: string) {
	return !contains(value, param);
}

export function isStartingWith(value: string, param: string) {
	value = value || "";
	param = param || "";
	if (!value.toLowerCase) {
		return false;
	}
	if (!param.toLowerCase) {
		return false;
	}
	value = value.toLowerCase();
	param = param.toLowerCase();

	return value.indexOf(param) === 0;
}

export function isEndingWith(value: string, param: string) {
	value = value || "";
	param = param || "";
	if (typeof value !== "string") {
		return false;
	}
	if (typeof param !== "string") {
		return false;
	}
	value = value.toLowerCase();
	param = param.toLowerCase();

	return value.indexOf(param) === value.length - param.length;
}

export function isLessThan(value: number, param: number) {
	return value < param;
}

export function isLessThanOrEqualTo(value: number, param: number) {
	return value <= param;
}

export function isGreaterThan(value: number, param: number) {
	return value > param;
}

export function isGreaterThanOrEqualTo(value: number, param: number) {
	return value >= param;
}

export function isInBetween(value: number, param: {min: number; max: number} = {min: -Infinity, max: Infinity}) {
	return param.min <= value && value <= param.max;
}

export function isNotBetween(value: number, param: {min: number; max: number} = {min: -Infinity, max: Infinity}) {
	return !isInBetween(value, param);
}

export function isEqualToDate(value: string, param: string) {
	if (!value) {
		return false;
	}
	return DateUtils.sameDay(DateUtils.parse(value), DateUtils.parse(param));
}

export function isLessThanDate(value: string, param: string) {
	if (!value) {
		return false;
	}
	return DateUtils.sortByDate(DateUtils.parse(value), DateUtils.parse(param)) === 1;
}

export function isGreaterThanDate(value: string, param: string) {
	if (!value) {
		return false;
	}
	return DateUtils.sortByDate(DateUtils.parse(value), DateUtils.parse(param)) === -1;
}

export function isInToday(value: string) {
	if (!value) {
		return false;
	}
	const today = new Date();

	return DateUtils.sameDay(DateUtils.parse(value), today);
}

export function isInYesterday(value: string) {
	if (!value) {
		return false;
	}

	const today = new Date();
	const yesterday = today;

	yesterday.setDate(today.getDate() - 1);

	return DateUtils.sameDay(DateUtils.parse(value), yesterday);
}

export function inThisWeek(value: string) {
	if (!value) {
		return false;
	}

	const todayWeek = DateUtils.getWeekNumber(new Date());
	const valueWeek = DateUtils.getWeekNumber(DateUtils.parse(value));

	// Note: getWeekNumber function returns with an array with the year and the number of the week
	// for example: getWeekNumber(new Date("2021.01.17")) returns this: [2021, 2]

	return equalsWeek(todayWeek, valueWeek);
}

export function inLastWeek(value: string) {
	if (!value) {
		return false;
	}

	const todayWeek = DateUtils.getWeekNumber(new Date());
	const valueWeek = DateUtils.getWeekNumber(DateUtils.parse(value));

	let lastWeek = todayWeek;

	lastWeek[1] = todayWeek[1] - 1;

	return equalsWeek(lastWeek, valueWeek);
}

export function inThisMonth(value: string) {
	if (!value) {
		return false;
	}

	const today = new Date();

	return DateUtils.sameMonth(DateUtils.parse(value), today);
}

export function inLastMonth(value: string) {
	if (!value) {
		return false;
	}

	const today = new Date();
	const date = DateUtils.parse(value);

	return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() - 1;
}

export function isAtTime(value: string, param: string) {
	if (!value) {
		return false;
	}
	return DateUtils.sameTime(DateUtils.parse(value), DateUtils.parse(param));
}

export function isBeforeTime(value: string, param: string) {
	if (!value) {
		return false;
	}
	return DateUtils.sortByTime(DateUtils.parse(value), DateUtils.parse(param)) === 1;
}

export function isAfterTime(value: string, param: string) {
	if (!value) {
		return false;
	}
	return DateUtils.sortByTime(DateUtils.parse(value), DateUtils.parse(param)) === -1;
}

export function isInBetweenDate(value: string, param?: {from: string; to: string}) {
	return isGreaterThanDate(value, param.from) && isLessThanDate(value, param.to);
}

export function isNotBetweenDate(value: string, param?: {from: string; to: string}) {
	return !isInBetweenDate(value, param);
}

export function inTheLastExplicitDate(value: string, param?: {length: number; type: string}) {
	if (!value) {
		return false;
	}

	let date = new Date();

	if (param.type === "Days") {
		date.setDate(date.getDate() - param.length);
	} else if (param.type === "Weeks") {
		date.setDate(date.getDate() - param.length * 7);
	} else if (param.type === "Months") {
		date.setDate(date.getMonth() - param.length);
	}

	return DateUtils.sortByDate(DateUtils.parse(value), date) === -1;
}

export function inTheNextExplicitDate(value: string, param?: {length: number; type: string}) {
	if (!value) {
		return false;
	}

	let date = new Date();

	if (param.type === "Days") {
		date.setDate(date.getDate() + param.length);
	} else if (param.type === "Weeks") {
		date.setDate(date.getDate() + param.length * 7);
	} else if (param.type === "Months") {
		date.setDate(date.getMonth() + param.length);
	}

	return DateUtils.sortByDate(DateUtils.parse(value), date) === 1;
}

export function isBetweenTime(value: string, param?: {from: string; to: string}) {
	if (!value) {
		return false;
	}
	return (
		DateUtils.sortByTime(DateUtils.parse(value), DateUtils.parse(param.from)) === -1 &&
		DateUtils.sortByTime(DateUtils.parse(value), DateUtils.parse(param.to)) === 1
	);
}

export function isTrue(value: string | boolean, param: any, field: IFieldAdapter) {
	return value === true || value === (field.dataTypeSettings as IBooleanFieldSettingsDefinition).displayLabelForTrue;
}

export function isFalse(value: string | boolean, param: any, field: IFieldAdapter) {
	return value === false || value === (field.dataTypeSettings as IBooleanFieldSettingsDefinition).displayLabelForFalse;
}

function equalsWeek(week1: [number, number], week2: [number, number]) {
	return week1[0] === week2[0] && week1[1] === week2[1];
}

export function isAnyOf(value: any, options: any[]) {
	if (!options) {
		return false;
	}

	return options.includes(value);
}

export function isNotAnyOf(value: any, options: any[]) {
	if (!options) {
		return false;
	}

	return !options.includes(value);
}

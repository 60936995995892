import styled from "styled-components";
import {ELLIPSIS} from "../../styles/styles";

interface ISingleLineLabelProps {
	value: string;
	dataTypeSettings?: any;
}

export const SingleLineLabelV5 = (props: ISingleLineLabelProps) => {
	return (
		<SingleLineLabelStyled
			className="SingleLineLabel"
			dangerouslySetInnerHTML={{__html: props.value || ""}}
		/>
	);
};

export const SingleLineLabelStyled = styled.div`
	${ELLIPSIS}
	min-height: 16px;
`;

import * as React from "react";
import {IconButton} from "../button/IconButton";
import {ReactUtils} from "../../utils/ReactUtils";
import {MathUtils} from "../../../utils/math/MathUtils";
import {Button} from "../button/Button";

interface ITableFooterProps {
	startIndex: number;
	lastIndex: number;
	totalCount: number;
	itemsPerPage: number;
	currentPage: number;
	onPageChange: (index: number) => void;
	onItemsPerPageChange: (itemsPerPage: number) => void;
}

export class TableFooter<T> extends React.PureComponent<ITableFooterProps> {
	public override render() {
		const {startIndex, lastIndex, totalCount, itemsPerPage, currentPage} = this.props;

		const numberOfPages = Math.ceil(totalCount / itemsPerPage);
		const numButtons = Math.min(10, numberOfPages);
		const minIndex = MathUtils.clamp(Math.floor(currentPage - numButtons / 2), 0, numberOfPages - numButtons);
		const firstPageIndex = 0;
		const lastPageIndex = numberOfPages - (numberOfPages === 0 ? 0 : 1);

		return (
			<div className="TableFooter">
				<span>
					{`${totalCount === 0 ? 0 : startIndex + 1}-${totalCount === 0 ? 0 : lastIndex + 1}`}&nbsp;of&nbsp;
					<span data-cy="TotalTableRowCount">{`${totalCount}`}</span>&nbsp;Items
				</span>
				<div className="pagination">
					<IconButton
						icon="previous"
						className="first"
						disabled={currentPage === firstPageIndex}
						onClick={() => this.selectPage(firstPageIndex)}
					/>
					<IconButton
						icon="angle_left"
						className="prev"
						disabled={currentPage === firstPageIndex}
						onClick={() => this.selectPage(this.props.currentPage - 1)}
					/>
					<div className="loop">
						{totalCount !== 0 ? (
							ReactUtils.loop(numButtons, (i) => {
								const index = minIndex + i;

								return (
									<Button
										data-type="numberBtn"
										key={index}
										label={index + 1}
										disabled={index === currentPage}
										onClick={() => this.selectPage(index)}
									/>
								);
							})
						) : (
							<Button
								data-type="numberBtn"
								key={0}
								label={1}
								disabled={true}
							/>
						)}
					</div>
					<IconButton
						icon="angle_right"
						className="next"
						disabled={currentPage === lastPageIndex}
						onClick={() => this.selectPage(this.props.currentPage + 1)}
					/>
					<IconButton
						icon="previous"
						className="last"
						disabled={currentPage === lastPageIndex}
						onClick={() => this.selectPage(lastPageIndex)}
					/>
					{/*<SelectInput options={[100, 200, 300, 500, 1000]}
								selected={this.props.itemsPerPage}
								onChange={value => this.props.onItemsPerPageChange(value)}
								/>
								<span className="TableFooterText">Items per page</span>*/}
				</div>
			</div>
		);
	}

	private selectPage(index: number) {
		this.props.onPageChange(index);
	}
}

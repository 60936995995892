import {useRef} from "react";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {Space} from "../../../data/models/Space";
import type {IActionBarItem} from "../abstract/ModuleViewV5";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import type {SpaceViewRenderer} from "../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {useAppStore} from "../../../StateManager";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import DeleteIcon from "../icons/delete.svg?react";
import {SpaceCreatePopupV5} from "./space/SpaceCreatePopupV5";

interface IBasicPropsFor3DTweaking {
	spaceViewRenderer?: SpaceViewRenderer;
	param1?: string;
}

export const SpaceViewV5 = (props: IBasicPropsFor3DTweaking) => {
	const appState = useAppStore((state) => state.appState);
	const {actions, user} = appState;
	const createButtonRef = useRef<HTMLDivElement>();

	const hasAnyTypePermission = () => {
		return actions.hasAnyTypeTheGivenPermissionInModule(XyiconFeature.Space, Permission.Update);
	};

	const hasEveryTypePermission = (items: Space[], givenPermission: Permission) => {
		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return items.every((item) => {
			const permission = actions.getModuleTypePermission(item.typeId, XyiconFeature.Space);

			return permission >= givenPermission;
		});
	};

	const isDeleteEnabled = (selectedItems: Space[]) => {
		if (selectedItems.length === 0) {
			return false;
		}

		return hasEveryTypePermission(selectedItems, Permission.Delete);
	};

	const getActionBar = () => {
		const result: IActionBarItem<Space>[] = [
			{
				id: "delete",
				title: "Delete Space",
				label: "Delete",
				IconComponent: DeleteIcon,
				visible: () => true,
				enabled: isDeleteEnabled,
			},
		];

		return result;
	};

	{
		return (
			<ModuleViewV5
				feature={XyiconFeature.Space}
				className="SpaceView"
				create={(onClose) => (
					<DomPortal destination={appState.app.modalContainer}>
						<SpaceCreatePopupV5
							onClose={onClose}
							parentRef={createButtonRef}
						/>
					</DomPortal>
				)}
				createButtonRef={createButtonRef}
				actionBar={getActionBar()}
			/>
		);
	}
};

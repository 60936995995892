import {CanvasTexture, SRGBColorSpace, MathUtils as THREEMath} from "three";
import {Constants} from "../Constants";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {ImageUtils} from "../../../../../../utils/image/ImageUtils";

interface ICanvasTexture extends CanvasTexture {
	resolution?: {x: number; y: number};
}

export class TextureFactory {
	private _spaceViewRenderer: SpaceViewRenderer;
	private readonly _fontFamily = "Roboto";

	private get _ctx() {
		return ImageUtils.ctx;
	}

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public createOrangeFlag() {
		return this.createFlag("#FFAB40");
	}

	public createRedFlag() {
		return this.createFlag("#ED1C24");
	}

	private createFlag(color: string) {
		this.drawFlagOnCanvas(color);

		const texture = new CanvasTexture(this._ctx.canvas);

		this._spaceViewRenderer.initTexture(texture);

		return texture;
	}

	private drawFlagOnCanvas(color: string) {
		const oldHeight = 32; // we made the first version with the default size (32) in mind
		const flagToXyiconRatio = oldHeight / 81;
		const newHeight = THREEMath.ceilPowerOfTwo(flagToXyiconRatio * Constants.RESOLUTION.XYICON);
		const newWidth = newHeight * 2;

		const canvas = this._ctx.canvas;

		canvas.width = newWidth;
		canvas.height = newHeight;
		ImageUtils.removeBlackBorders(this._ctx);

		const ratio = newHeight / oldHeight;

		this._ctx.fillStyle = color;
		this._ctx.strokeStyle = "#FFFFFF";
		this._ctx.lineWidth = 1.5 * ratio;
		this._ctx.beginPath();
		this._ctx.moveTo(25 * ratio, 1 * ratio);
		this._ctx.lineTo(62 * ratio, 1 * ratio);
		this._ctx.lineTo(47 * ratio, 16 * ratio);
		this._ctx.lineTo(62 * ratio, 31 * ratio);
		this._ctx.lineTo(25 * ratio, 31 * ratio);
		this._ctx.closePath();
		this._ctx.fill();
		this._ctx.stroke();
	}

	public createCounterTextureObject(counter: number) {
		this.drawCounterOnCanvas(counter);

		const texture: ICanvasTexture = new CanvasTexture(this._ctx.canvas);

		texture.name = `${counter}`;
		texture["resolution"] = {
			x: this._ctx.canvas.width,
			y: this._ctx.canvas.height,
		};
		texture.colorSpace = SRGBColorSpace;
		this._spaceViewRenderer.initTexture(texture);

		return texture;
	}

	private drawCounterOnCanvas(counter: number) {
		const defaultSize = 32;
		const counterToXyicon = defaultSize / 81; // we created the first version with the default xyicon size (81) in mind

		const newSize = THREEMath.ceilPowerOfTwo(counterToXyicon * Constants.RESOLUTION.XYICON);

		const ratio = newSize / defaultSize;

		const counterAsString = counter.toString();

		const radius = 13 * ratio;

		const capsuleDiameter = radius * 2 + (counterAsString.length - 1) * 12 * ratio;

		this._ctx.canvas.height = newSize;
		this._ctx.canvas.width = Math.max(this._ctx.canvas.height, THREEMath.ceilPowerOfTwo(capsuleDiameter));

		ImageUtils.removeBlackBorders(this._ctx);

		let leftX = radius + 3 * ratio;
		let rightX = leftX + (counterAsString.length - 1) * 12 * ratio;
		const topY = leftX;

		// Align to center of texture
		const rightEdgeToRightX = this._ctx.canvas.width - rightX;
		const offsetX = (rightEdgeToRightX - leftX) / 2;

		leftX += offsetX;
		rightX += offsetX;

		this._ctx.fillStyle =
			this._spaceViewRenderer.transport.appState.currentUIVersion === "5.0" ? `#${Constants.COUNTER_BG_COLORV5}` : `#${Constants.COUNTER_BG_COLOR}`;
		this._ctx.strokeStyle = `#${Constants.COUNTER_BORDER_COLOR}`;
		this._ctx.lineWidth = 2;
		this._ctx.beginPath();
		this._ctx.arc(leftX, topY, radius + 1.5 * ratio, Math.PI / 2, 1.5 * Math.PI);
		this._ctx.arc(rightX, topY, radius + 1.5 * ratio, 1.5 * Math.PI, Math.PI / 2);
		this._ctx.fill();
		this._ctx.stroke();

		// this._ctx.fillStyle = "#FFAB40";
		// this._ctx.beginPath();
		// this._ctx.arc(leftX, topY, radius, Math.PI / 2, 1.5 * Math.PI);
		// this._ctx.arc(rightX, topY, radius, 1.5 * Math.PI, Math.PI / 2);
		// this._ctx.fill();

		this._ctx.fillStyle = `#${Constants.COUNTER_TEXT_COLOR}`;
		const fontSize = 16 * ratio;

		this._ctx.font = `normal ${fontSize}px ${this._fontFamily}`;
		this._ctx.textAlign = "center";
		this._ctx.textBaseline = "alphabetic";
		this._ctx.fillText(counterAsString, (leftX + rightX) / 2, radius + fontSize / 2);
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {SVGIcon} from "../../button/SVGIcon";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import type {AppState} from "../../../../data/state/AppState";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {ReactUtils} from "../../../utils/ReactUtils";

export interface ITextInputProps {
	isTextArea?: boolean;
	defaultValue?: string;
	value?: string;
	placeholder?: string;
	className?: string;
	isTextInfo?: boolean;
	style?: React.CSSProperties;
	autoFocus?: boolean;
	disabled?: boolean;
	inputType?: IInputType;
	errorMessage?: string;
	isTooltip?: boolean;
	trimText?: boolean; // trim the whitespace from the beginning and the end of the string if true
	selectAll?: boolean;
	borderColor?: string;
	viewBoxSize?: number;
	inline?: boolean;
	noButtons?: boolean;
	errorMessageTop?: number;
	caretPosition?: number;
	getErrorMessage?: (value: string) => string;
	onChange?: (value: string) => void;
	onBlur?: (isEnterPressed?: boolean) => void;
	onInput?: (value: string, event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent, value: string) => void;
	appState?: AppState;
	divRef?: React.RefObject<HTMLDivElement>;
}

export type IInputType = "number" | "date" | "text" | "password";

export interface ITextInputState {
	value: string;
	propsValue: string;
	toolTipTransform: TransformObj;
	errorMessage: string;
}

@inject("appState")
export class TextInput extends React.Component<ITextInputProps, ITextInputState> {
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _toolTip = React.createRef<HTMLDivElement>();

	private _valueBeforeEditing: string = this.props.value;
	private _lastValidValue = this.props.value;
	private _isEscClicked: boolean = false;

	public static defaultProps: ITextInputProps = {
		defaultValue: "",
		value: "",
		placeholder: "",
		trimText: true,
	};

	public static getDerivedStateFromProps(props: ITextInputProps, state: ITextInputState) {
		// if props.value changed from its previous value -> update state.value
		if (props.value !== state.propsValue) {
			return {
				value: props.value,
				propsValue: props.value,
				errorMessage: props.errorMessage,
			};
		}
		return null;
	}

	constructor(props: ITextInputProps) {
		super(props);

		const value = this.props.defaultValue || this.props.value;

		this.state = {
			value: value,
			propsValue: value,
			toolTipTransform: null,
			errorMessage: "",
		};
	}

	private onInput = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;
		const errorMessage = this.props.getErrorMessage?.(value) || "";

		this.setState({value, errorMessage});

		if (!errorMessage) {
			this.props.onInput?.(value, event);
			this._lastValidValue = value;
		}
	};

	private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
			case KeyboardListener.KEY_ENTER:
				this.triggerChange(event);
				this.props.onBlur?.(true);
				break;
		}
	};

	private onBlur = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
		this.triggerChange(event);
		this.props.onBlur?.();

		return false;
	};

	private triggerChange = (
		event:
			| React.KeyboardEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLTextAreaElement>
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const {onChange, inline} = this.props;
		const {errorMessage} = this.state;

		if (onChange) {
			const eventTargetValue = this._ref.current?.value ?? event.currentTarget.value;

			if (eventTargetValue != null && !errorMessage) {
				const value = this.validateValue(eventTargetValue);

				if (value !== this.props.value) {
					this.props.onChange(value);
				}
			} else if (errorMessage && inline) {
				this.props.onChange(this._lastValidValue);
			}
		}
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this._isEscClicked = true;
		}
		this.props.onKeyDown?.(event, this.state.value);
	};

	protected validateValue(value: string) {
		return this.props.trimText ? value.trim() : value;
	}

	public override componentDidMount() {
		const {caretPosition, inputType, autoFocus, selectAll} = this.props;
		const input = this._ref.current;

		if (autoFocus) {
			input.focus();
		}

		if (selectAll) {
			input.select();
		}

		// number input has no setSelectionRange
		if (caretPosition !== undefined && inputType !== "number" && input?.setSelectionRange) {
			input.focus();
			input.setSelectionRange(caretPosition, caretPosition);
		}

		FocusLoss.listen(input, this.onBlur as any);
	}

	public override componentDidUpdate = (prevProps: ITextInputProps, prevState: ITextInputState) => {
		const input = this._ref.current;
		const infoBubble = this._floating.current;

		if ((prevProps.value !== this.props.value || prevState.value !== this.state.value) && this.state.errorMessage && input && infoBubble) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(input, infoBubble, VerticalAlignment.bottom, HorizontalAlignment.right),
			});
		}

		if (!prevProps.autoFocus && this.props.autoFocus) {
			this._ref.current.focus();
		}
	};

	public override componentWillUnmount() {
		const {onChange} = this.props;
		const input = this._ref.current;

		if (input && onChange) {
			const currentValue = input.value;

			const value = this.validateValue(currentValue);

			if (value !== this.props.value && !this.state.errorMessage && !this._isEscClicked) {
				onChange?.(value);
			} else if (this._isEscClicked) {
				onChange?.(this._valueBeforeEditing);
			}
		}

		FocusLoss.stopListen(input, this.onBlur as any);
	}

	public override render() {
		const {
			disabled,
			placeholder,
			className,
			style,
			autoFocus,
			onClick,
			errorMessageTop,
			inputType,
			borderColor,
			viewBoxSize,
			isTextArea,
			inline,
			appState,
			noButtons,
			isTooltip,
			isTextInfo,
		} = this.props;
		const {value, toolTipTransform, errorMessage} = this.state;
		const inputStyle: React.CSSProperties = {
			...style,
			width: viewBoxSize ?? style?.width,
			borderColor: borderColor ?? style?.borderColor,
		};

		if (isTextArea) {
			return (
				<textarea
					value={value}
					placeholder={placeholder}
					onClick={onClick}
					className={className || ""}
					style={inputStyle}
					autoFocus={autoFocus}
					onInput={this.onInput}
					onBlur={this.onBlur}
					disabled={!!disabled}
					ref={this._ref as React.RefObject<HTMLTextAreaElement>}
				/>
			);
		} else {
			const inlineStyle: React.CSSProperties = this._floating.current && {
				top: `${errorMessageTop || isTextInfo ? 5 : 0}px`,
				left: inline ? "-25px" : isTextInfo ? "-10px" : "-5px",
				width: inline ? "0px" : "14px",
				zIndex: "9999",
				transform: toolTipTransform?.translate,
			};
			const classname = isTooltip ? "tooltipmsg" : "errormsg";

			return (
				<>
					<input
						value={value}
						placeholder={placeholder}
						className={className || ""}
						style={inputStyle || null}
						autoFocus={autoFocus}
						onInput={this.onInput}
						onBlur={this.onBlur}
						onClick={onClick}
						disabled={!!disabled}
						ref={(this.props.divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
						onKeyUp={this.onKeyUp}
						onKeyDown={this.onKeyDown}
						type={inputType || ""}
					/>
					{errorMessage && !disabled && (
						<div className={ReactUtils.cls("infoIcon", {[className]: true, left: noButtons})}>
							<div ref={this._toolTip}>
								<SVGIcon icon="info" />
							</div>
							<InfoBubble
								content={errorMessage}
								isErrorMessage={true}
								divRef={this._toolTip}
							/>
						</div>
					)}
				</>
			);
		}
	}
}

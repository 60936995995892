import type {Texture, WebGLProgramParametersWithUniforms} from "three";
import {RawShaderMaterial} from "three";
import type {PointDouble} from "../../../../../../generated/api/base";

export class DoubleColorIconMaterial extends RawShaderMaterial {
	private _uniforms: {
		[key: string]: {
			type: "f" | "b" | "t" | "v2" | "v3" | "v4";
			value: number | number[] | PointDouble | boolean | Texture;
		};
	} = {};

	protected readonly _vertexShader: string = `precision highp float;

uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;

attribute vec3 position;
attribute vec2 uv;
attribute mat4 instanceMatrix;
attribute vec3 backgroundColor;
attribute vec3 iconColor;
attribute float opacity;

varying vec2 vUv;
varying float vOpacity;
varying vec3 vBackgroundColor;
varying vec3 vIconColor;

void main()
{
	vUv = uv;
	vOpacity = opacity;
	vBackgroundColor = backgroundColor;
	vIconColor = iconColor;
	gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(position, 1.0);
}
`;

	protected readonly _fragmentShader: string = `precision highp float;

varying vec2 vUv;
varying float vOpacity;
varying vec3 vBackgroundColor;
varying vec3 vIconColor;

uniform sampler2D map;

void main()
{
	vec4 texelColor = texture2D(map, vUv);
	gl_FragColor = vec4(mix(vBackgroundColor, vIconColor, texelColor.r), texelColor.a * vOpacity);
}
`;

	constructor(map: Texture, depthTest: boolean) {
		super({transparent: true, depthTest: depthTest});

		this._uniforms.map = {
			type: "t",
			value: map,
		};

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;

			program.uniforms = this._uniforms;
		};
	}

	// So it can be disposed, when the scene is disposed
	public get map() {
		return this._uniforms.map.value;
	}
}

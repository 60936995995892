import {useCallback, useEffect, useRef} from "react";
import styled from "styled-components";
import {WarningWindow} from "../../../modules/abstract/popups/WarningWindow";
import {FileDropper} from "../../../../utils/interaction/draganddrop/FileDropper";
import CloudIcon from "../../icons/cloud.svg?react";

const FileDropperStyled = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 26vh;
	border: 2px dashed #c8c8c8;
	border-radius: 8px;

	&.dropping_files {
		border-color: #3495f0;
		svg {
			filter: grayscale(0);
		}
	}

	label {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0 20px;
	}

	input[type="file"] {
		display: none;
	}

	.label {
		text-align: center;
		font-size: 16px;
		color: #7b7b7b;
	}

	svg {
		margin-top: 10px;
		width: 48px;
		height: 48px;
		filter: grayscale(1);
	}
`;

interface IFileDropperV5Props {
	accept: string;
	purpose: string;
	multiple?: boolean;
	onFileInputChange: (files: FileList) => void;
	forceUpdate: () => void;
}

let _counter: number = 0;

export function FileDropperReactV5(props: IFileDropperV5Props) {
	const {accept, onFileInputChange, forceUpdate} = props;
	const _ref = useRef<HTMLDivElement>(null);
	const _dropper = useRef<FileDropper>(null);

	const onFileChange = useCallback(
		async (files: FileList) => {
			if (_dropper.current.checkType(files)) {
				onFileInputChange(files);
			} else {
				await WarningWindow.open(`Wrong filetype! Only the following types are accepted: ${accept}`);
			}

			forceUpdate();
		},
		[onFileInputChange, forceUpdate, accept],
	);

	const onFileInputChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
		onFileChange(event.currentTarget.files);
	};

	useEffect(() => {
		const onDrop = (event: DragEvent) => {
			onFileChange(event.dataTransfer.files);
		};

		const onDragOver = (event: DragEvent) => {
			return true;
		};

		_dropper.current = new FileDropper({
			targetElement: _ref.current,
			accept: accept,
			onDragOver,
			onDrop,
		});

		return () => {
			if (_dropper.current) {
				_dropper.current.disable();
			}
		};
	}, [accept, onFileChange]);

	useEffect(() => {
		_counter++;

		return () => {
			_counter--;
		};
	}, []);

	const inputId = `fileInput_${_counter}`;

	return (
		<FileDropperStyled ref={_ref}>
			<div className="label">{`${props.purpose}`}</div>
			<CloudIcon
				width="48"
				height="48"
				stroke="#7B7B7B"
			/>
			<label htmlFor={inputId} />
			<input
				id={inputId}
				multiple={props.multiple}
				type="file"
				value=""
				accept={accept}
				onChange={onFileInputChangeLocal}
			/>
		</FileDropperStyled>
	);
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FieldDataType {
	SingleLineText = 1,
	MultiLineText = 2,
	Numeric = 3,
	DateTime = 4,
	SingleChoiceList = 5,
	MultipleChoiceList = 6,
	Boolean = 7,
	PhoneNumber = 8,
	Email = 9,
	IPAddress = 10,
	GeoLocation = 11,
	SystemFields = 12,
	User = 901,
	Type = 902,
	Model = 903,
}

import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {SingleLineLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/singleline/SingleLineLabel";
import {SingleLineInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/singleline/SingleLineInput";
import {SingleLineInputV5} from "../../../../ui/5.0/details/datatypes/SingleLineInputV5";
import {SingleLineLabelV5} from "../../../../ui/5.0/details/datatypes/SingleLineLabelV5";

export function configureSingleLine() {
	FieldDataTypes.configure({
		id: FieldDataType.SingleLineText,
		title: "Text (Single Line)",
		operatorsArray: [...stringOperators],
		label: SingleLineLabel,
		labelV5: SingleLineLabelV5,
		input: SingleLineInput,
		inputV5: SingleLineInputV5,
	});
}

import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../data/state/AppState";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {colorPalette} from "../../styles/colorPalette";
import {InfoIconStyled, radius} from "../../styles/styles";
import {InfoBubbleV5} from "../../button/InfoBubbleV5";
import ErrorInfoIcon from "../../icons/errorInfo.svg?react";
import {Functions} from "../../../../utils/function/Functions";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {ReactUtils} from "../../../utils/ReactUtils";

interface ITextInputProps {
	isTextArea?: boolean;
	defaultValue?: string;
	value?: string;
	placeholder?: string;
	className?: string;
	isTextInfo?: boolean;
	style?: React.CSSProperties;
	autoFocus?: boolean;
	disabled?: boolean;
	inputType?: IInputType;
	errorMessage?: string;
	isTooltip?: boolean;
	trimText?: boolean; // trim the whitespace from the beginning and the end of the string if true
	selectAll?: boolean;
	borderColor?: string;
	viewBoxSize?: number;
	inline?: boolean;
	noButtons?: boolean;
	errorMessageTop?: number;
	caretPosition?: number;
	getErrorMessage?: (value: string) => string;
	onChange?: (value: string) => void;
	onBlur?: (isEnterPressed?: boolean) => void;
	onInput?: (value: string, event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent, value: string) => void;
	appState?: AppState;
	divRef?: React.RefObject<HTMLDivElement>;
}

export type IInputType = "number" | "date" | "text" | "password";

interface ITextInputState {
	value: string;
	propsValue: string;
	toolTipTransform: TransformObj;
	errorMessage: string;
	isFocus: boolean;
}

@inject("appState")
export class TextInputV5 extends React.Component<ITextInputProps, ITextInputState> {
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	private _floating = React.createRef<HTMLDivElement>();

	private _valueBeforeEditing: string = this.props.value;
	private _lastValidValue = this.props.value;
	private _isEscClicked: boolean = false;

	public static defaultProps: ITextInputProps = {
		defaultValue: "",
		value: "",
		placeholder: "",
		trimText: true,
	};

	public static getDerivedStateFromProps(props: ITextInputProps, state: ITextInputState) {
		// if props.value changed from its previous value -> update state.value
		if (props.value !== state.propsValue) {
			return {
				value: props.value,
				propsValue: props.value,
				errorMessage: props.errorMessage,
			};
		}
		return null;
	}

	constructor(props: ITextInputProps) {
		super(props);

		const value = this.props.defaultValue || this.props.value;

		this.state = {
			value: value,
			propsValue: value,
			toolTipTransform: null,
			errorMessage: "",
			isFocus: false,
		};
	}

	private onInput = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;
		const errorMessage = this.props.getErrorMessage?.(value) || "";

		this.setState({
			value,
			errorMessage,
			isFocus: true,
		});

		if (!errorMessage) {
			this.props.onInput?.(value, event);
			this._lastValidValue = value;
		}
	};

	private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
			case KeyboardListener.KEY_ENTER:
				this.triggerChange(event);
				this.props.onBlur?.(true);
				break;
		}
	};

	private onBlur = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
		this.triggerChange(event);
		this.props.onBlur?.();

		this.setState({
			isFocus: false,
		});

		return false;
	};

	private triggerChange = (
		event:
			| React.KeyboardEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLTextAreaElement>
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const {onChange, inline} = this.props;
		const {errorMessage} = this.state;

		if (onChange) {
			const eventTargetValue = this._ref.current?.value ?? event.currentTarget.value;

			if (eventTargetValue != null && !errorMessage) {
				const value = this.validateValue(eventTargetValue);

				if (value !== this.props.value) {
					this.props.onChange(value);
				}
			} else if (errorMessage && inline) {
				this.props.onChange(this._lastValidValue);
			}
		}
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this._isEscClicked = true;
		}
		this.props.onKeyDown?.(event, this.state.value);
	};

	protected validateValue(value: string) {
		return this.props.trimText ? value.trim() : value;
	}

	public override async componentDidMount() {
		const {caretPosition, inputType, autoFocus, selectAll} = this.props;
		const input = this._ref.current;

		if (autoFocus) {
			// wait till input values are assgined before focus
			await TimeUtils.wait(0);
			input.focus();
		}

		if (selectAll) {
			input.select();
		}

		// number input has no setSelectionRange
		if (caretPosition !== undefined && inputType !== "number" && input?.setSelectionRange) {
			input.focus();
			input.setSelectionRange(caretPosition, caretPosition);
		}

		FocusLoss.stopListen(input, this.onBlur as any);
		FocusLoss.listen(input, this.onBlur as any);
	}

	private handleFocus = () => {
		this.setState({isFocus: true});
	};

	public override componentDidUpdate = (prevProps: ITextInputProps, prevState: ITextInputState) => {
		const input = this._ref.current;
		const infoBubble = this._floating.current;

		if ((prevProps.value !== this.props.value || prevState.value !== this.state.value) && this.state.errorMessage && input && infoBubble) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(input, infoBubble, VerticalAlignment.bottom, HorizontalAlignment.right),
			});
		}

		if (!prevProps.autoFocus && this.props.autoFocus) {
			input.focus();
		}
	};

	public override componentWillUnmount() {
		const {onChange} = this.props;
		const input = this._ref.current;

		if (input && onChange) {
			const currentValue = input.value;

			const value = this.validateValue(currentValue);

			if (value !== this.props.value && !this.state.errorMessage && !this._isEscClicked) {
				onChange?.(value);
			} else if (this._isEscClicked) {
				onChange?.(this._valueBeforeEditing);
			}
		}

		this.props.onBlur?.();

		FocusLoss.stopListen(input, this.onBlur as any);
	}

	public override render() {
		const {
			disabled,
			placeholder,
			className,
			style,
			autoFocus,
			onClick,
			errorMessageTop,
			inputType,
			borderColor,
			viewBoxSize,
			isTextArea,
			inline,
			isTooltip,
			isTextInfo,
		} = this.props;
		const {value, toolTipTransform, errorMessage, isFocus} = this.state;
		const inputStyle: React.CSSProperties = {
			...style,
			width: viewBoxSize ?? style?.width,
			borderColor: borderColor ?? style?.borderColor,
		};

		if (isTextArea) {
			return (
				<textarea
					value={value}
					placeholder={placeholder}
					onClick={onClick}
					className={className || ""}
					style={inputStyle}
					autoFocus={autoFocus}
					onInput={this.onInput}
					onBlur={this.onBlur}
					disabled={!!disabled}
					ref={this._ref as React.RefObject<HTMLTextAreaElement>}
				/>
			);
		} else {
			return (
				<>
					<TextInputStyled
						value={value}
						placeholder={placeholder}
						className={className || ""}
						style={inputStyle || null}
						autoFocus={autoFocus}
						onMouseDown={Functions.stopPropagation}
						onInput={this.onInput}
						onBlur={this.onBlur}
						onClick={onClick}
						disabled={!!disabled}
						ref={(this.props.divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
						onKeyUp={this.onKeyUp}
						onKeyDown={this.onKeyDown}
						type={inputType || ""}
						onFocus={this.handleFocus}
					/>
					{errorMessage && !disabled && (
						<InfoIconStyled
							className={ReactUtils.cls("infoIcon", {isFocus})}
							onMouseOver={this.handleFocus}
							onMouseOut={() => this.setState({isFocus: false})}
						>
							<ErrorInfoIcon />
							<InfoBubbleV5
								content={errorMessage}
								isErrorMessage={true}
								isFocus={this.state.isFocus}
							/>
						</InfoIconStyled>
					)}
				</>
			);
		}
	}
}

export const TextInputStyled = styled.input`
	background: none;
	max-width: 100%;
	height: 32px;
	border: 1px solid ${colorPalette.gray.c200Light};
	border-radius: ${radius.sm};
	padding: 0 8px;
	font-size: 14px;
	line-height: 16px;

	&:focus {
		border-color: ${colorPalette.primary.c500Primary};
		outline: none;
	}
`;

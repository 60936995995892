import type {ISingleSelectFieldSettingsDefinition} from "../../../../data/models/field/datatypes/SingleSelect";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";

export const SingleSelectInputV5 = (props: IInputPropsV5<ISingleSelectFieldSettingsDefinition>) => {
	const {value, onChange, disabled, onFocusLossForceBlur, inline, focusLossStarterEvent, focused, isSameWidth, dropdownIcon} = props;
	const list = [...props.dataTypeSettings.choiceList] || [];

	let focus = focused;

	const onFocus = (value: boolean) => {
		if (!value) {
			focus = false;
		}
	};

	const onClick = () => {
		props.onClick?.();
	};

	return (
		<SelectInputV5
			options={list}
			selected={value}
			disabled={disabled}
			nullOption={true}
			fullWidth={true}
			sort={false}
			onFocusLossForceBlur={onFocusLossForceBlur}
			focused={focus}
			onChange={onChange}
			onClick={onClick}
			onFocus={onFocus}
			horizontalAlignment={inline ? HorizontalAlignment.center : undefined}
			focusLossStarterEvent={focusLossStarterEvent}
			isSameWidth={isSameWidth}
			inline={inline}
			dropdownIcon={dropdownIcon}
		/>
	);
};

import * as React from "react";
import {MathUtils as THREEMath} from "three";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import type {Color, PointDouble} from "../../../../../generated/api/base";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {selectedSvgPartAttributeKey} from "../../../../modules/abstract/common/iconeditor/BorderRectAroundSvgPart";
import {catalogIconBGLightThreshold} from "../../../../modules/catalog/create/defaults";
import {TransformableSVGElementV5} from "./TransformableSVGElementV5";
import {BorderRectAroundSvgPartV5} from "./BorderRectAroundSvgPartV5";

const dataStaticColorAttributeName = "data-static-color";

interface IIconImageProps {
	viewBoxSize: number;
	isSVG: boolean;
	innerPart: string; // isSVG ? svg text : image url
	imageAspectRatio: number;
	replaceColor: boolean;
	isIconColorChanged: boolean;
	backgroundColor: Color;
	color: Color;
	colors: Color[]; // the number of elements in this array should be the same as the number of first-level children of the svg
	selectedChildIndex: number;
	translate: PointDouble; // normalized to [0, 1]
	orientation: number;
	scale: number;
	isFlippedX: boolean;
	isFlippedY: boolean;
	isSelected: boolean;
	parentRef: React.RefObject<SVGSVGElement>;
	borderColor: string;
	onSelectedChildIndexChange: (newIndex: number) => void;
	onTranslateChange?: (newTranslate: PointDouble) => void;
	onOrientationChange?: (newOrientation: number) => void;
}

interface IIconImageState {
	offset: PointDouble;
	deltaOrientation: number;
}

export class IconImageV5 extends React.Component<IIconImageProps, IIconImageState> {
	private _gRef = React.createRef<SVGGElement>();
	private _domParser: DOMParser = new DOMParser();
	private _xmlSerializer: XMLSerializer = new XMLSerializer();

	constructor(props: IIconImageProps) {
		super(props);
		this.state = {
			offset: {
				x: 0,
				y: 0,
			},
			deltaOrientation: 0,
		};
	}

	private getSvgFromInnerPart(innerPart: string) {
		const outerPart = `<svg xmlns="${Constants.SVG_NAMESPACE}" viewBox="0 0 ${Constants.CATALOG_SVG_VIEWBOXSIZE} ${Constants.CATALOG_SVG_VIEWBOXSIZE}">${innerPart}</svg>`;

		return this._domParser.parseFromString(outerPart, "image/svg+xml");
	}

	private getInnerPart() {
		let innerPart = this.props.innerPart;

		if (this.props.replaceColor) {
			const svg = this.getSvgFromInnerPart(innerPart);

			if (this.props.parentRef.current) {
				if (this.props.isSelected) {
					this.props.parentRef.current.style.cursor = this.props.selectedChildIndex > -1 ? "pointer" : "move";
				} else {
					this.props.parentRef.current.style.cursor = "";
				}
			}

			const {children} = svg.documentElement;
			const childrenWithDynamicColors = Array.from(children).filter((child: Element) => child.getAttribute(dataStaticColorAttributeName) !== "true");
			const newColorForBase = ColorUtils.hex2rgb(this.props.color.hex, 1 - this.props.color.transparency) as string;

			for (let i = 0; i < children.length; ++i) {
				const child = children[i];

				const isStatic = child.getAttribute(dataStaticColorAttributeName) === "true";

				if (!isStatic) {
					child.setAttribute("fill", newColorForBase);
				}

				if (childrenWithDynamicColors.length > 1) {
					if (i === this.props.selectedChildIndex) {
						child.setAttribute(selectedSvgPartAttributeKey, "true");
					} else {
						child.removeAttribute(selectedSvgPartAttributeKey);
					}

					let colorMaybe = this.props.colors[i];

					if (!this.props.isIconColorChanged && colorMaybe) {
						const iconHsl = ColorUtils.hex2hsl(colorMaybe.hex);
						const bgHsl = ColorUtils.hex2hsl(this.props.backgroundColor.hex);

						if (iconHsl.l > catalogIconBGLightThreshold && bgHsl.l > catalogIconBGLightThreshold) {
							colorMaybe = {
								hex: "000000",
								transparency: 0,
							};
						}
					}

					if (colorMaybe && !isStatic) {
						const newColor = ColorUtils.hex2rgb(colorMaybe.hex, 1 - colorMaybe.transparency) as string;

						child.setAttribute("fill", newColor);
					}
				}
			}

			// Serialize the SVG object back to an SVG string
			const svgString = this._xmlSerializer.serializeToString(svg);

			let newInnerPart = svgString.substring(svgString.indexOf(">") + 1);

			newInnerPart = newInnerPart.substring(0, newInnerPart.lastIndexOf("</svg>"));

			return newInnerPart;
		} else if (innerPart) {
			const newColor = ColorUtils.hex2rgb(this.props.color.hex, 1 - this.props.color.transparency) as string;

			// we have to replace the default "st0" class, because there are many of them, and they interpret with each other (for example it can give stroke to ones that shouldn't have strokes)
			innerPart = innerPart.replace(/st0/g, `st_${MathUtils.getNewRandomGUID()}`);

			// matches fill="#FFFFFF", fill="rgba(132, 122, 5, 0.5)", and fill="navyblue", but doesn't match fill="none"
			// note that it only matches the fill, and stroke attributes, if they don't have a `data-static-color="true"` attribute somewhere next to it in the same tag
			innerPart = innerPart.replace(/fill="(?!none)[^"]+"(?![^<]*\bdata-static-color="true")/g, `fill="${newColor}"`);
			innerPart = innerPart.replace(/stroke="(?!none)[^"]+"(?![^<]*\bdata-static-color="true")/g, `stroke="${newColor}"`);

			// matches fill:#FFFFFF;, fill:rgba(132, 122, 5, 0.5);, and fill: navyblue; but doesn't match fill:none;, nor fill: none;
			innerPart = innerPart.replace(/fill:(?!(\s{0,}none;))(.*?);/g, `fill:${newColor};`);
			innerPart = innerPart.replace(/stroke:(?!(\s{0,}none;))(.*?);/g, `stroke:${newColor};`);
		}

		return innerPart;
	}

	private onTranslateChange = (deltaOffset: PointDouble, propagate: boolean = false) => {
		if (propagate) {
			if (this.props.onTranslateChange) {
				this.props.onTranslateChange({
					x: this.props.translate.x + this.state.offset.x,
					y: this.props.translate.y + this.state.offset.y,
				});
				this.setState({
					offset: {
						x: 0,
						y: 0,
					},
				});
			}
		} else {
			this.setState({
				offset: deltaOffset,
			});
		}
	};

	private onOrientationChange = (deltaAngle: number, propagate: boolean = false) => {
		if (propagate) {
			if (this.props.onOrientationChange) {
				this.props.onOrientationChange(this.props.orientation + deltaAngle);
				this.setState({
					deltaOrientation: 0,
				});
			}
		} else {
			this.setState({
				deltaOrientation: deltaAngle,
			});
		}
	};

	private isImageEmpty() {
		const innerPart = this.props.innerPart;

		return innerPart == null || innerPart.length < 1 || (innerPart.length === 1 && innerPart.charCodeAt(0) === 13);
	}

	private draw = () => {
		this.forceUpdate();
	};

	private getUploadedImage() {
		const props: React.SVGProps<SVGImageElement> = {
			href: this.props.innerPart,
			onLoad: this.draw,
		};

		if (this.props.imageAspectRatio > 1) {
			props.width = this.props.viewBoxSize;
			props.transform = `translate(${-0.5 * this.props.viewBoxSize} ${(-0.5 * this.props.viewBoxSize) / this.props.imageAspectRatio})`;
		} else {
			props.height = this.props.viewBoxSize;
			props.transform = `translate(${-0.5 * this.props.viewBoxSize * this.props.imageAspectRatio} ${-0.5 * this.props.viewBoxSize})`;
		}

		return <image {...props} />;
	}

	public override componentDidUpdate() {
		if (this.props.isSelected && (this.props.isSVG || !this.props.innerPart)) {
			const g = this._gRef.current;

			if (g) {
				g.parentElement.parentElement.onclick = () => {
					this.props.onSelectedChildIndexChange?.(-1);
				};

				const innerGElementMaybe = g.children[0];

				const children = Array.from(innerGElementMaybe?.children ?? []);
				const childrenWithDynamicColors = children.filter((child) => child.getAttribute(dataStaticColorAttributeName) !== "true");

				if (innerGElementMaybe) {
					if (this.props.isSelected && childrenWithDynamicColors.length > 1) {
						innerGElementMaybe.classList.add("areChildrenSelectable");
					} else {
						innerGElementMaybe.classList.remove("areChildrenSelectable");
					}
				}

				if (childrenWithDynamicColors.length > 1) {
					for (let i = 0; i < children.length; ++i) {
						const child = children[i] as SVGElement;
						const isStatic = child.getAttribute(dataStaticColorAttributeName) === "true";

						if (!isStatic) {
							child.onclick = (ev: MouseEvent) => {
								ev.stopImmediatePropagation();
								this.props.onSelectedChildIndexChange?.(i);
							};

							child.onmousedown = (ev: MouseEvent) => {
								ev.stopImmediatePropagation();
							};
						}
					}
				}
			}
		}
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IIconImageProps>, nextContext: any): void {
		const isGlyphChanged = this.props.innerPart !== nextProps.innerPart;

		if (isGlyphChanged || (this.props.isSelected && !nextProps.isSelected)) {
			if (this.props.selectedChildIndex > -1) {
				this.props.onSelectedChildIndexChange?.(-1);
			}
		}
	}

	public override render() {
		const currentTranslate = {
			x: this.props.translate.x + this.state.offset.x,
			y: this.props.translate.y + this.state.offset.y,
		};
		const currentOrientation = this.props.orientation + this.state.deltaOrientation;
		const currentTranslateInSVGSpace = {
			x: currentTranslate.x * this.props.viewBoxSize,
			y: currentTranslate.y * this.props.viewBoxSize,
		};

		const scaleX = this.props.scale * (this.props.isFlippedX ? -1 : 1);
		const scaleY = this.props.scale * (this.props.isFlippedY ? -1 : 1);
		const outerIconTransform = `translate(${currentTranslateInSVGSpace.x} ${currentTranslateInSVGSpace.y}) rotate(${THREEMath.radToDeg(currentOrientation)}) scale(${scaleX} ${scaleY})`;

		const isTransformEnabled = this.props.onTranslateChange && this.props.isSelected && !this.isImageEmpty() && this.props.selectedChildIndex === -1;

		return (
			<>
				<TransformableSVGElementV5
					isTransformEnabled={isTransformEnabled}
					componentRef={this._gRef}
					lastSavedOrientation={this.props.orientation}
					orientation={currentOrientation}
					pivotX={currentTranslate.x}
					pivotY={currentTranslate.y}
					scale={this.props.scale}
					viewBoxSize={this.props.viewBoxSize}
					onTranslateChange={this.onTranslateChange}
					onOrientationChange={this.onOrientationChange}
					parentRef={this.props.parentRef}
					borderColor={this.props.borderColor}
				>
					<g
						transform={outerIconTransform}
						ref={this._gRef}
					>
						{this.props.isSVG || !this.props.innerPart ? (
							<>
								<g
									transform={`translate(${-0.5 * this.props.viewBoxSize} ${-0.5 * this.props.viewBoxSize})`}
									dangerouslySetInnerHTML={{__html: this.getInnerPart()}}
								></g>
							</>
						) : (
							this.getUploadedImage()
						)}
					</g>
					{this.props.selectedChildIndex > -1 && (
						<BorderRectAroundSvgPartV5
							borderColor={this.props.borderColor}
							orientation={currentOrientation}
							pivotX={currentTranslate.x}
							pivotY={currentTranslate.y}
							scale={this.props.scale}
							viewBoxSize={this.props.viewBoxSize}
						/>
					)}
				</TransformableSVGElementV5>
			</>
		);
	}
}

import styled from "styled-components";
import {VerticalFlexStyle} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";

export const DetailsTabV5HeaderStyles = styled.div`
	${VerticalFlexStyle};
	gap: 16px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	border-bottom: 2px solid ${colorPalette.gray.c200Light};

	h2 {
		flex: 1;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
	}

	.Initials {
		position: relative;
		top: 0;
		left: 0;
		width: 48px;
		height: 48px;
		font-size: 24px;

		img {
			width: 100%;
			height: 100%;
		}
	}
`;

import styled from "styled-components";
import {baseDistance} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";
import {IconButtonStyled} from "../../interaction/IconButtonV5";

export const TabViewStyled = styled.div<{$isSettings: boolean}>`
	.filters {
		padding-bottom: ${baseDistance.oh};
		padding-right: ${baseDistance.sm};
	}

	.buttons {
		display: flex;
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		color: ${colorPalette.gray.c700Dark};

		.selected {
			svg {
				color: ${colorPalette.primary.c500Primary};
			}
			text-decoration: ${(props) => (props.$isSettings ? `underline 3px` : "")};
			text-decoration-skip-ink: none;
			color: ${colorPalette.primary.c500Primary};
		}
		// used in space panels
		${IconButtonStyled} {
			color: ${colorPalette.gray.c950};
			height: 32px;
			svg {
				width: 16px;
				height: 16px;
			}
		}
		.selected {
			align-items: center;
		}
	}
`;

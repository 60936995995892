import {ReactSortable} from "react-sortablejs";
import type {Sortable} from "react-sortablejs";
import {useRef, useState} from "react";
import {SVGIcon} from "../../button/SVGIcon";
import {TextInput} from "../text/TextInput";
import {Button} from "../../button/Button";
import {ToggleSwitchField} from "../../button/switch/ToggleSwitchField";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import {IconButton} from "../../button/IconButton";
import {ReactUtils} from "../../../utils/ReactUtils";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";

interface IListBuilderInputProps {
	list: string[];
	onChange: (list: string[]) => void;
}

interface IListBuilderInputSortableList {
	id: string;
	value: string;
}

const dragTolerance = 4;
const defaultItemString = "New Item";

const generateSortableListFromSourceList = (source: string[]): IListBuilderInputSortableList[] => {
	return source.map((item, index) => ({id: `${item}-${index}`, value: item}));
};

const createNewItemName = (count: number) => `${defaultItemString} (${count})`;

function filterDragElement(this: Sortable, event: Event | TouchEvent, target: HTMLElement, sortable: Sortable): boolean {
	const element = event.target as HTMLElement;
	const elementName = element.nodeName.toLowerCase();

	return elementName === "input" || elementName === "textarea";
}

export function ListBuilderInput(props: IListBuilderInputProps) {
	const [isUsingTextArea, setUsingTextArea] = useState<boolean>(false);
	const [textArea, setTextArea] = useState<string>(props.list.join("\n"));
	const [editedItem, setEditedItem] = useState<{index: number; value: string}>({index: -1, value: ""});
	const _listRef = useRef<HTMLDivElement>(null);

	const onAddItemClick = async () => {
		let count: number = 1;
		let newItemName = createNewItemName(count);

		while (props.list.includes(newItemName)) {
			newItemName = createNewItemName(++count);
		}

		props.onChange([...props.list, newItemName]);

		await TimeUtils.waitForNextFrame();
		await TimeUtils.waitForNextFrame();

		// Number.MAX_SAFE_INTEGER is not working reliably in Safari and Firefox
		_listRef.current.scrollTo({top: _listRef.current.scrollHeight, behavior: "smooth"});
	};

	const isCurrentListValid = (currentList: string[] = props.list) => {
		let currList: string[] = currentList;

		if (isUsingTextArea) {
			currList = textArea.split("\n").filter((line) => !!line);
		} else {
			currList = ObjectUtils.deepClone(currList);

			if (editedItem.index !== -1) {
				const {value} = editedItem;

				currList[editedItem.index] = value.trim();
				if (!value) {
					return false;
				}
			}
		}

		const caseInsensitiveList = currList.map((el) => el.toLowerCase());

		const s = new Set<string>(caseInsensitiveList);
		const isEveryItemUnique = s.size === caseInsensitiveList.length;

		return isEveryItemUnique;
	};

	const onEditItem = (value: string, index: number) => {
		setEditedItem({value, index});
	};

	const onBlurTextArea = () => {
		const newList = textArea
			.split("\n")
			.filter((line) => !!line)
			.map((line) => line.trim());

		if (isCurrentListValid() && !ObjectUtils.compare(newList, props.list)) {
			props.onChange(newList);
		} else {
			setTextArea(props.list.join("\n"));
		}
	};

	const onItemSave = (id: string, newValue: string) => {
		if (isCurrentListValid()) {
			const list = generateSortableListFromSourceList(props.list);
			const newList = generateSortableListFromSourceList(props.list);
			const listItem = newList.find((el) => el.id === id);

			listItem.value = newValue.trim();
			if (!ObjectUtils.compare(newList, list) && isCurrentListValid(newList.map((v) => v.value))) {
				props.onChange(newList.map((item) => item.value));
			}
		}
	};

	const onDeleteClick = (index: number) => {
		if (index < props.list.length) {
			props.onChange(ArrayUtils.removeAtIndex(props.list, index));
		}
	};

	const onSwitchChange = () => {
		if (!isUsingTextArea) {
			setTextArea(props.list.join("\n"));
		}
		setUsingTextArea(!isUsingTextArea);
	};

	const onEditTextArea = (value: string) => {
		setTextArea(value);
	};

	const onSetList = (newList: IListBuilderInputSortableList[]) => {
		const newArray = newList.map((l) => l.value);
		const oldArray = props.list;

		if (!ObjectUtils.compare(newArray, oldArray)) {
			props.onChange(newArray);
		}
	};

	const listToRender = generateSortableListFromSourceList(props.list);
	const isValid = isCurrentListValid();

	return (
		<div className="ListBuilderInputWrapper">
			{!isValid && (
				<div className="infoIcon">
					<SVGIcon icon="info" />
					<InfoBubble
						content="Items must be unique."
						isErrorMessage={true}
						className="left"
					/>
				</div>
			)}
			<div
				className={ReactUtils.cls("ListBuilderInput", {error: !isValid})}
				ref={_listRef}
			>
				{isUsingTextArea ? (
					<TextInput
						isTextArea={true}
						value={textArea}
						onInput={onEditTextArea}
						onBlur={onBlurTextArea}
					/>
				) : (
					<ReactSortable
						list={listToRender}
						setList={onSetList}
						animation={150}
						filter={filterDragElement}
						fallbackOnBody={true}
						forceFallback={true}
						swapThreshold={0.45}
						fallbackTolerance={dragTolerance}
						touchStartThreshold={dragTolerance}
						preventOnFilter={false}
						delay={500}
						delayOnTouchOnly={true}
					>
						{listToRender.map((item, index) => {
							const onItemChange = (newValue: string) => onItemSave(item.id, newValue);

							return (
								<div
									className="item hbox grabbable"
									key={item.id}
								>
									<TextInput
										value={editedItem.index === index ? editedItem.value : item.value}
										onInput={(value) => onEditItem(value, index)}
										onChange={onItemChange}
										onBlur={() => {
											if (editedItem.index === index) {
												if (isValid) {
													onItemChange(editedItem.value);
												}
												setEditedItem({index: -1, value: ""});
											}
										}}
									/>
									<IconButton
										className="delete"
										icon="delete"
										title="Delete"
										onClick={() => onDeleteClick(index)}
									/>
								</div>
							);
						})}
					</ReactSortable>
				)}
			</div>
			<div className="hbox add-item alignCenter">
				{!isUsingTextArea && (
					<Button
						label="Add Item"
						className="small"
						onClick={onAddItemClick}
					/>
				)}
				<div className="flex_1" />
				<ToggleSwitchField
					value={isUsingTextArea}
					onChange={onSwitchChange}
					label="Add multiple items at once"
				/>
			</div>
		</div>
	);
}

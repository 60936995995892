import * as React from "react";
import {ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import type {IToggleContainerProps} from "../../../../widgets/container/ToggleContainer";

interface IInteractiveToggleContainerProps<T> extends IToggleContainerProps<T> {
	isEnabled: boolean;
	onEnabledChange: (value: boolean) => void;
}

export const InteractiveToggleContainerV5 = <T,>(props: IInteractiveToggleContainerProps<T>) => {
	const {title, children, open, onToggleOpen, onAddClicked, className, isEnabled, onEnabledChange} = props;
	const toggleProps = {title, children, open, onToggleOpen, onAddClicked, className, isEnableTogglerVisible: true, isEnabled, onEnabledChange};

	return (
		<ToggleContainerV5
			{...toggleProps}
			showToggleLabel={false}
		/>
	);
};

import type {View} from "../../../data/models/View";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import {ViewPreferenceCategory} from "../../../generated/api/base";
import {ViewFolderSharingPopup} from "./ViewFolderSharingPopup";
import {ViewSharingPopup} from "./ViewSharingPopup";

interface IViewItemSharingPopupProps {
	viewItem: View | IViewFolder;
	onClose: () => void;
}

export const ViewItemSharingPopup = (props: IViewItemSharingPopupProps) => {
	const {viewItem, onClose} = props;

	if ((viewItem as IViewFolder).category === ViewPreferenceCategory.Folder) {
		return (
			<ViewFolderSharingPopup
				viewFolder={viewItem as IViewFolder}
				onClose={onClose}
			/>
		);
	}

	return (
		<ViewSharingPopup
			view={viewItem as View}
			onClose={onClose}
		/>
	);
};

import * as React from "react";
import * as ReactDOM from "react-dom";
import {inject, observer} from "mobx-react";
import {IconButton} from "../../button/IconButton";
import {ToggleSwitchField} from "../../button/switch/ToggleSwitchField";
import {ClickToEditInput} from "../clicktoedit/ClickToEditInput";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {Button} from "../../button/Button";
import {ReactUtils} from "../../../utils/ReactUtils";
import {DateUtils} from "../../../../utils/DateUtils";
import {FieldDataType} from "../../../../generated/api/base";
import {IDateFieldFormat} from "../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import type {Alignment, TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils} from "../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import type {App} from "../../../../App";
import {TimePicker} from "./TimePicker";
import {DatePicker} from "./DatePicker";

interface IDateInputProps {
	value: string;
	defaultValue: string;
	format: IDateTimeInputFormat;
	disabled?: boolean;
	valueToDate?: string;
	alignment?: Alignment;
	onFocusLossForceBlur?: boolean;
	focused?: boolean;
	onChange: (value: string) => void;
	onClick?: () => void;
	onFocus?: (value: boolean) => void;
	inline?: boolean;

	app?: App;
}

interface IDateInputState {
	open: boolean;
	value: string;
	valueToDate: string;
	propsValue: string;
	activePicker: IDateTimeInputFormat;
	activePickerToDate: IDateTimeInputFormat;
	includeTime: boolean;
	includeTimeToDate: boolean;
	isToDateActive: boolean;
	errorMessage: string;
	alignment: Alignment;
	transform: TransformObj;
}

export type IDateTimeInputFormat = "" | "date" | "time" | "datetime" | "daterange" | "timerange";

@inject("app")
@observer
export class DateTimeInput extends React.PureComponent<IDateInputProps, IDateInputState> {
	private _container = React.createRef<HTMLDivElement>();
	private _pickerContainer = React.createRef<HTMLInputElement>();

	public static defaultProps: Partial<IDateInputProps> = {
		defaultValue: "",
		value: "",
	};

	public static getDerivedStateFromProps(props: IDateInputProps, state: IDateInputState) {
		// if props.value changed from its previous value -> update state.value
		if (props.value !== state.propsValue) {
			return {
				value: props.value,
				propsValue: props.value,
			};
		}
		return null;
	}

	public static formatProps = {
		year: "numeric",
		month: "short",
		day: "2-digit",
		weekday: "long",
		TimeZoneName: "short",
		hour: "2-digit",
		minute: "2-digit",
	};

	constructor(props: IDateInputProps) {
		super(props);

		const value = this.props.defaultValue || this.props.value;

		this.state = {
			open: false,
			value: value,
			valueToDate: value,
			propsValue: value,
			activePicker: this.props.format === "time" || this.props.format === "timerange" ? "time" : "date",
			activePickerToDate: this.props.format === "time" || this.props.format === "timerange" ? "time" : "date",
			includeTime: false,
			includeTimeToDate: false,
			isToDateActive: false,
			errorMessage: "",
			alignment: "bottom",
			transform: null,
		};
	}

	private onTogglePicker = async () => {
		this.props.onClick?.();

		AppUtils.disableScrolling(true);

		this.setState({
			open: this.props.focused || !this.state.open,
			alignment: DomUtils.calculateAlignment(this._container.current, this.props.alignment),
		});

		if (!this.state.open) {
			await TimeUtils.wait(500);
			FocusLoss.listen(this._pickerContainer.current, this.onFocusLoss);
			this.setState({errorMessage: ""});
		}
	};

	private closePicker = () => {
		AppUtils.disableScrolling(false);

		this.setState({
			open: false,
			errorMessage: "",
		});

		this.props.onFocus?.(false);
	};

	private onFocusLoss = (event: Event) => {
		let eventTargetInModalContainer = null;

		if (event.target instanceof Element) {
			eventTargetInModalContainer = this.props.app.modalContainer.contains(ReactDOM.findDOMNode(event.target));
		}

		if ((event && !eventTargetInModalContainer) || this.props.onFocusLossForceBlur) {
			this.closePicker();
			AppUtils.disableScrolling(false);

			this.setState({
				open: false,
				errorMessage: "",
			});
		}
	};

	private onInput = (value: string) => {
		const {isToDateActive} = this.state;

		if (isToDateActive) {
			this.setState({valueToDate: value});
		} else {
			this.setState({value: value});
		}
	};

	private onClearClicked = () => {
		this.setState({
			value: "",
			valueToDate: "",
		});

		this.props.onChange(null);
		this.onTogglePicker();
	};

	private onTodayClicked = () => {
		this.setState({
			value: DateUtils.stringify(new Date()),
		});
	};

	private getDateOrTime(time?: boolean, isToDate?: boolean) {
		const {value, valueToDate} = this.state;
		const date = isToDate ? valueToDate : value;
		const format = time ? "time" : "date";

		return DateFormatter.format(date, format);
	}

	private setActivePicker = (picker: IDateTimeInputFormat, isToDate?: boolean) => {
		if (isToDate) {
			this.setState({
				activePicker: "",
				activePickerToDate: picker,
				isToDateActive: true,
			});
		} else {
			this.setState({
				activePicker: picker,
				activePickerToDate: "",
				isToDateActive: false,
			});
		}
	};

	private getPickerTitle = () => {
		const {format} = this.props;
		let title = "";

		switch (format) {
			case "date":
				title = "Date";
				break;
			case "time":
				title = "Time";
				break;
			case "datetime":
				title = "Date & Time";
				break;
			case "daterange":
				title = "Daterange";
				break;
			case "timerange":
				title = "Timerange";
				break;
			default:
				title = "Date";
				break;
		}

		return title;
	};

	private onToggleIncludeTime = () => {
		this.setState((prevState) => ({includeTime: !prevState.includeTime}));
	};

	private onConfirmClicked = () => {
		const {format, onChange} = this.props;
		const {value, valueToDate} = this.state;
		const from = DateUtils.parse(value);
		let date = "";

		if (format === "daterange" || format === "timerange") {
			const toDate = DateUtils.parse(valueToDate);
			const dateRange = {
				from: from,
				to: toDate,
			};

			date = DateUtils.stringifyDateRange(dateRange);
		} else {
			date = DateUtils.stringify(from);
		}

		onChange(date);
		this.closePicker();
	};

	private hideErrorMessage = () => {
		this.setState({errorMessage: ""});
	};

	private validateDateInput = (value: string, format: IDateTimeInputFormat) => {
		const {onChange} = this.props;
		let inputValue = value;

		if (inputValue === "") {
			onChange(null);
			this.onTogglePicker();

			return;
		}

		// It's needed because of time only input validation. Eg.: "10:20" would be invalid without date data.
		const placeholderDate = "2021.01.01";

		if (format === "time") {
			inputValue = `${placeholderDate} ${value}`;
		} else if (format === "daterange" || format === "timerange") {
			const value = DateUtils.parseDateRange(inputValue);
			let from = value.from;
			let to = value.to;

			if (format === "timerange") {
				from = `${placeholderDate} ${from}`;
				to = `${placeholderDate} ${to}`;
			}

			if (DateUtils.dateValidation(from) && DateUtils.dateValidation(to)) {
				this.setState({
					value: from,
					valueToDate: to,
				});

				onChange(inputValue);
			} else {
				FocusLoss.listen(this._container.current, this.hideErrorMessage);
				this.setState({errorMessage: "Invalid format"});
			}

			this.onTogglePicker();
			return;
		}

		if (DateUtils.dateValidation(inputValue)) {
			onChange(inputValue);
		} else {
			FocusLoss.listen(this._container.current, this.hideErrorMessage);
			this.setState({errorMessage: "Invalid format"});
		}

		this.onTogglePicker();
	};

	private onChangeTimeInput = (value: string) => {
		const [time, postfix] = value.split(" ");
		const hourOffset = postfix?.toLowerCase() === "pm" ? 12 : 0;
		const split = time.split(":");
		const hours = parseInt(split[0]) + hourOffset;
		const minutes = parseInt(split[1]);

		const date = new Date();

		date.setHours(hours);
		date.setMinutes(minutes);

		this.setState({value: DateUtils.stringify(date)});
	};

	private getDisplayedDate = () => {
		const {format, value, valueToDate} = this.props;
		const {includeTime} = this.state;

		if (format === "daterange" || format === "timerange") {
			//const rangeFormat = format === "daterange" ? "date" : "time";

			let rangeFormat = IDateFieldFormat.DATE;

			if (format === "daterange" && includeTime) {
				rangeFormat = IDateFieldFormat.DATETIME;
			} else if (format === "timerange") {
				rangeFormat = IDateFieldFormat.TIME;
			}

			if (value === "" || valueToDate === "") {
				return "";
			}

			return `${DateFormatter.format(value, rangeFormat)} - ${DateFormatter.format(valueToDate, rangeFormat)}`;
		} else {
			return DateFormatter.format(value, format);
		}
	};

	public override componentDidUpdate(prevProps: IDateInputProps, prevState: IDateInputState) {
		if (!prevState.open && this.state.open && this._container.current && this._pickerContainer.current) {
			this.setState({transform: DomUtils.getFixedFloatingElementPosition(this._container.current, this._pickerContainer.current)});
		}

		if (!prevProps.focused && this.props.focused) {
			this.onTogglePicker();
		} else if (prevProps.focused && !this.props.focused) {
			this.closePicker();
		}
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._container.current, this.hideErrorMessage);
		FocusLoss.stopListen(this._pickerContainer.current, this.hideErrorMessage);
	}

	public override render() {
		const {format, disabled, app, inline, focused} = this.props;
		const {open, value, includeTime, activePicker, activePickerToDate, isToDateActive, valueToDate, transform} = this.state;
		const inlineStyle: React.CSSProperties = {transform: transform?.translate};

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("DateTimeInput hbox", {
					disabled,
					inline,
					cellContent: inline,
				})}
			>
				<ClickToEditInput
					value={this.getDisplayedDate()}
					onChange={(value) => this.validateDateInput(value, format)}
					dataTypeSettings={FieldDataType.DateTime}
					disabled={disabled}
					errorMessage={this.state.errorMessage}
					onClick={this.onTogglePicker}
					focused={focused}
					noButtons={inline ?? false}
				/>
				{!inline && (
					<IconButton
						icon={format === "time" || format === "timerange" ? "clock" : "calendar"}
						className="calendar"
						onClick={this.onTogglePicker}
					/>
				)}
				{
					// Need to simplify it somehow. Maybe we should make separate pickerwindow component.
					open && (
						<DomPortal destination={app.modalContainer}>
							<div
								className={`pickersContainer ${format}`}
								style={inlineStyle}
								ref={this._pickerContainer}
							>
								{format !== "daterange" && format !== "timerange" ? (
									<div className="pickerinputs hbox alignCenter">
										<div className="title">{this.getPickerTitle()}</div>
										<div className="row hbox">
											{(format === "date" || format === "datetime") && (
												<ClickToEditInput
													value={this.getDateOrTime()}
													onClick={() => this.setActivePicker("date")}
													dataTypeSettings={FieldDataType.SingleLineText}
													className={ReactUtils.cls({
														selected: activePicker === "date" && includeTime,
														fullWidth: !includeTime,
													})}
												/>
											)}
											{((format === "datetime" && includeTime) || format === "time") && (
												<ClickToEditInput
													value={this.getDateOrTime(true)}
													onClick={() => this.setActivePicker("time")}
													onChange={this.onChangeTimeInput}
													dataTypeSettings={FieldDataType.SingleLineText}
													className={ReactUtils.cls({
														selected: activePicker === "time" && includeTime,
														fullWidth: format === "time",
													})}
												/>
											)}
										</div>
									</div>
								) : format === "daterange" ? (
									<>
										<div className="pickerinputs hbox alignCenter">
											<div className="title">From Date</div>
											<div className="row hbox">
												<ClickToEditInput
													value={this.getDateOrTime()}
													onClick={() => this.setActivePicker("date")}
													dataTypeSettings={FieldDataType.SingleLineText}
													className={ReactUtils.cls({
														selected: activePicker === "date" && includeTime,
														fullWidth: !includeTime,
													})}
												/>
												{includeTime && (
													<ClickToEditInput
														value={this.getDateOrTime(true)}
														onClick={() => this.setActivePicker("time")}
														dataTypeSettings={FieldDataType.SingleLineText}
														className={ReactUtils.cls({selected: activePicker === "time" && includeTime})}
													/>
												)}
											</div>
										</div>
										<div className="pickerinputs hbox alignCenter">
											<div className="title">To Date</div>
											<div className="row hbox">
												<ClickToEditInput
													value={this.getDateOrTime(false, true)}
													onClick={() => this.setActivePicker("date", true)}
													dataTypeSettings={FieldDataType.SingleLineText}
													className={ReactUtils.cls({selected: activePickerToDate === "date" && includeTime})}
												/>
												{includeTime && (
													<ClickToEditInput
														value={this.getDateOrTime(true, true)}
														onClick={() => this.setActivePicker("time", true)}
														dataTypeSettings={FieldDataType.SingleLineText}
														className={ReactUtils.cls({selected: activePickerToDate === "time" && includeTime})}
													/>
												)}
											</div>
										</div>
									</>
								) : (
									<>
										<div className="pickerinputs hbox alignCenter">
											<div className="title">From Time</div>
											<div className="row hbox">
												<ClickToEditInput
													value={this.getDateOrTime(true)}
													onClick={() => this.setActivePicker("time")}
													dataTypeSettings={FieldDataType.SingleLineText}
													className={ReactUtils.cls({
														selected: activePicker === "time",
													})}
												/>
											</div>
										</div>
										<div className="pickerinputs hbox alignCenter">
											<div className="title">To Time</div>
											<div className="row hbox">
												<ClickToEditInput
													value={this.getDateOrTime(true, true)}
													onClick={() => this.setActivePicker("time", true)}
													dataTypeSettings={FieldDataType.SingleLineText}
													className={ReactUtils.cls({selected: activePickerToDate === "time"})}
												/>
											</div>
										</div>
									</>
								)}
								{activePicker === "date" || activePickerToDate === "date" ? (
									<DatePicker
										value={isToDateActive ? valueToDate : value}
										onChange={this.onInput}
									/>
								) : (
									<TimePicker
										value={DateUtils.parse(isToDateActive ? valueToDate : value)}
										onChange={this.onInput}
									/>
								)}
								<div className="btns hbox">
									{(format === "date" || format === "datetime") && (
										<Button
											label="Today"
											onClick={this.onTodayClicked}
										/>
									)}
									<Button
										label="Clear"
										onClick={this.onClearClicked}
									/>
									<Button
										label="Confirm"
										className="primary"
										onClick={this.onConfirmClicked}
									/>
								</div>
								{(format === "datetime" || format === "daterange") && (
									<ToggleSwitchField
										value={includeTime}
										onChange={this.onToggleIncludeTime}
										label="Include time"
									/>
								)}
							</div>
						</DomPortal>
					)
				}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {DomPortal} from "../../portal/DomPortal";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import type {App} from "../../../../../App";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {InfoBubble} from "./InfoBubble";

export interface IInfoButtonProps {
	bubbleText: string | React.ReactNode;
	hoverDelay?: number;
	isError?: boolean;
	className?: "onPopup" | "leftAlign" | (string & {});
	icon?: "info" | "question-mark";
	app?: App;
	style?: React.CSSProperties;
	verticalOpen?: VerticalAlignment.bottomOuter;
	onClick?: () => void;
}

interface IInfoButtonState {
	isInfoBubbleOpen: boolean;
	transform: TransformObj;
}

@inject("app")
@observer
export class InfoButton extends React.Component<IInfoButtonProps, IInfoButtonState> {
	private _parentElement = React.createRef<HTMLDivElement>();
	private _floatingElement = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;

	public static defaultProps: Partial<IInfoButtonProps> = {
		icon: "info",
		hoverDelay: 0,
		style: {},
	};

	constructor(props: IInfoButtonProps) {
		super(props);
		this.state = {
			isInfoBubbleOpen: false,
			transform: null,
		};
	}

	private openBubble = () => {
		if (this._timeOutId) {
			clearTimeout(this._timeOutId);
		}

		this._timeOutId = window.setTimeout(() => {
			this.setState({
				isInfoBubbleOpen: true,
			});
		}, this.props.hoverDelay);
	};

	private closeBubble = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isInfoBubbleOpen: false,
		});
	};

	public override componentDidUpdate(prevProps: IInfoButtonProps, prevState: IInfoButtonState) {
		if (!prevState.isInfoBubbleOpen && this.state.isInfoBubbleOpen && this._parentElement.current && this._floatingElement.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._parentElement.current,
					this._floatingElement.current,
					this.props.verticalOpen || VerticalAlignment.top,
					HorizontalAlignment.center,
					10,
					0,
				),
			});
		}
	}

	public override render() {
		const {app, className, bubbleText, isError, icon, style, onClick} = this.props;
		const {transform, isInfoBubbleOpen} = this.state;
		const element = this._parentElement.current;

		const inlineStyle: React.CSSProperties = element && {
			...style,
			zIndex: 999999,
			transform: transform?.translate,
			position: "fixed",
			left: "auto",
		};

		return (
			<div
				className={ReactUtils.cls("InfoButton", {pointer: !!onClick})}
				ref={this._parentElement}
				onMouseEnter={this.openBubble}
				onMouseLeave={this.closeBubble}
				onClick={onClick}
			>
				<SVGIcon icon={icon} />
				{isInfoBubbleOpen && (
					<DomPortal destination={app.modalContainer}>
						<InfoBubble
							content={bubbleText}
							isErrorMessage={isError}
							divRef={this._floatingElement}
							style={inlineStyle}
							className={ReactUtils.cls(className || "", {
								left: transform?.horizontal === HorizontalAlignment.right,
								right: transform?.horizontal === HorizontalAlignment.left,
								[VerticalAlignment[this.props.verticalOpen]]: !!this.props.verticalOpen,
							})}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import type {AppState} from "../../../../../../../data/state/AppState";
import {Permission} from "../../../../../../../generated/api/base";
import {XyiconModelField} from "./XyiconModelField";

interface IXyiconDefaultFieldsProps {
	item: Xyicon;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class XyiconDefaultFields extends React.Component<IXyiconDefaultFieldsProps> {
	private get hasPermission() {
		const {item, appState} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, item.ownFeature) > Permission.View;
	}

	public override render() {
		const {item, appState} = this.props;

		return (
			<>
				<Field label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</Field>
				<Field label="Type">{appState.actions.getTypeName(item.typeId)}</Field>
				<Field label="Model">
					<XyiconModelField
						disabled={!this.hasPermission}
						xyicons={[item]}
					/>
				</Field>
			</>
		);
	}
}

import type {Side, WebGLProgramParametersWithUniforms} from "three";
import {RawShaderMaterial, FrontSide} from "three";
import {ColorUtils} from "../../../../../../utils/ColorUtils";

//
// Simplified material: no textures, no envmap, aomap, lightmap, fog, skinning, uv2, etc, because we don't need them
//
export class BasicMaterial extends RawShaderMaterial {
	private _color: {
		type: "3fv";
		value: number[];
	} = {
		type: "3fv",
		value: [],
	};

	private _opacity: {
		type: "f";
		value: number;
	} = {
		type: "f",
		value: 1,
	};

	private _isGrayScaled: {
		type: "b";
		value: boolean;
	} = {
		type: "b",
		value: false,
	};

	public static readonly vertexShader: string = `precision highp float;

attribute vec3 position;

uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

varying vec2 vUv;

void main()
{
	// planeGeometry's vUv will be [0, 0] - [1, 1]
	vUv = vec2(position.xy + 0.5) / 1.0;

	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;

	private _vertexShader: string = BasicMaterial.vertexShader;

	private _fragmentShader: string = `precision highp float;
//https://www.shadertoy.com/view/XdXSzX
#define SRGB_COEFFICIENTS vec3(0.2126, 0.7152, 0.0722)

varying vec2 vUv;

uniform vec2 spaceSize;

uniform vec3 color;
uniform float opacity;
uniform bool isGrayScaled;

void main()
{
	vec4 outputColor = vec4(color, opacity);

	//gl_FragColor = vec4(0.0, 1.0-vUv.xy, 1.0)*outputColor;
	//gl_FragColor = vec4(0.0, vUv.xy, 1.0);
	gl_FragColor = isGrayScaled ? vec4(vec3(dot(outputColor.rgb, SRGB_COEFFICIENTS)), outputColor.a) : outputColor;
}`;

	constructor(color: number, opacity: number = 1.0, transparent: boolean = false, depthTest: boolean = false, side: Side = FrontSide) {
		super({
			depthTest: depthTest,
			transparent: transparent,
			side: side,
		});

		this.setColor(color);
		this.setOpacity(opacity);

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;
			program.uniforms = {
				color: this._color,
				opacity: this._opacity,
				isGrayScaled: this._isGrayScaled,
			};
		};
	}

	private getArrayFromColor(color: number) {
		return ColorUtils.hex2Array(color).slice(0, 3);
	}

	public setColor(color: number) {
		this._color.value = this.getArrayFromColor(color);
	}

	public setOpacity(opacity: number) {
		this._opacity.value = opacity;
	}

	public setGrayScaled(value: boolean) {
		this._isGrayScaled.value = value;
	}

	public get color() {
		return this._color.value;
	}

	public get opacityValue() {
		return this._opacity.value;
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {PortfolioWideSearch} from "../search/PortfolioWideSearch";
import {DomPortal} from "../modules/abstract/portal/DomPortal";
import {IconButton} from "../widgets/button/IconButton";
import {TextInput} from "../widgets/input/text/TextInput";
import type {Navigation} from "../../Navigation";
import {Debouncer} from "../../utils/function/Debouncer";
import {appHolder} from "../../utils/AppUtils";
import type {AppState} from "../../data/state/AppState";
import {KeyboardListener} from "../../utils/interaction/key/KeyboardListener";
import {Functions} from "../../utils/function/Functions";

interface IMainSearchProps {
	navigation?: Navigation;
	appState?: AppState;
}

interface IMainSearchState {
	isSearchInputOpen?: boolean;
	isPortfolioWideSearchOpen?: boolean;
	isTextInputActive?: boolean;
	isClearNeeded?: boolean;
	queryString?: string;
	loading: boolean;
}

@inject("appState")
@inject("navigation")
export class MainSearch extends React.Component<IMainSearchProps, IMainSearchState> {
	private _element = React.createRef<HTMLDivElement>();
	private _textInput = React.createRef<TextInput>();
	private readonly _loadingDebouncer = new Debouncer(1000);
	private readonly _typingDebouncer = new Debouncer(1000);

	constructor(props: IMainSearchProps) {
		super(props);
		this.state = {
			isSearchInputOpen: false,
			isPortfolioWideSearchOpen: false,
			isTextInputActive: false,
			isClearNeeded: false,
			queryString: "",
			loading: false,
		};
	}

	private pressEnter = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				this.openSearchInput();
				KeyboardListener.getInstance().signals.up.remove(this.pressEnter);
				break;
		}
	};

	private onNavigate = () => {
		// when navigating to another view -> close search
		this.closeSearchInput();
	};

	private openSearchInput = () => {
		this.setState({
			isSearchInputOpen: true,
		});
	};

	public closeSearchInput = () => {
		this.setState({
			isSearchInputOpen: false,
			queryString: "",
			isTextInputActive: false,
		});
	};

	private onInput = (value: string) => {
		this._typingDebouncer.debounce(() => {
			this.onUserStoppedTyping(value);
		});
	};

	private onUserStoppedTyping = (value: string) => {
		if (this.props.appState.actions.areModelsCached()) {
			this.updateQuery(value);
		} else {
			this.setState({
				loading: true,
			});

			this._loadingDebouncer.debounce(() => this.updateQuery(value));
		}
	};

	private updateQuery = (query: string) => {
		if (this.state.isClearNeeded) {
			this.setState({
				queryString: "",
				loading: false,
				isClearNeeded: false,
			});
			this._textInput.current?.setState({value: ""});
		} else {
			this.setState({
				queryString: query,
				loading: false,
			});
		}
	};

	private onBlur = () => {
		if (!this.state.isSearchInputOpen) {
			this._textInput.current?.setState({value: ""});
			this.setState({
				isTextInputActive: false,
				isClearNeeded: true,
				queryString: "",
			});
		} else {
			this.setState({isTextInputActive: false});
		}
		KeyboardListener.getInstance().signals.up.remove(this.pressEnter);
	};

	private onClick = () => {
		this.setState({
			isTextInputActive: true,
			isClearNeeded: false,
		});
		KeyboardListener.getInstance().signals.up.add(this.pressEnter);
	};

	private onCancelClick = (event: React.MouseEvent) => {
		this.setState({
			queryString: "",
			isClearNeeded: true,
		});
		this._textInput.current?.setState({value: ""});
	};

	public override componentDidMount() {
		this.props.navigation.signals.navigate.add(this.onNavigate);
	}

	public override componentWillUnmount() {
		this.props.navigation.signals.navigate.add(this.onNavigate);
	}

	public override render() {
		const {isSearchInputOpen, queryString, loading, isTextInputActive} = this.state;

		return (
			<div
				className="MainSearch hbox"
				ref={this._element}
				onBlur={this.onBlur}
			>
				<TextInput
					ref={this._textInput}
					className="searchInput"
					value={queryString}
					onInput={this.onInput}
					placeholder="Search within portfolio"
					onClick={this.onClick}
				/>
				{isTextInputActive && (
					<IconButton
						className="MainSearchButton cancel"
						icon="cancel"
						onClick={this.onCancelClick}
						onMouseDown={Functions.preventDefault}
						onTouchStart={Functions.preventDefault}
					/>
				)}
				<IconButton
					className="MainSearchButton search"
					icon="search"
					onClick={this.openSearchInput}
					onMouseDown={Functions.preventDefault}
					onTouchStart={Functions.preventDefault}
				/>
				{isSearchInputOpen && (
					<DomPortal destination={appHolder()}>
						<PortfolioWideSearch
							query={queryString}
							loading={loading}
							closeSearchInput={this.closeSearchInput}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

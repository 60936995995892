import {KeyboardListener} from "../interaction/key/KeyboardListener";
import type {ExtendedDistanceUnitName} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";

export class MathUtils {
	public static TWO_PI = Math.PI * 2.0;
	public static PI_HALF = Math.PI / 2.0;

	public static DEG2RAD = Math.PI / 180.0;
	public static RAD2DEG = 180.0 / Math.PI;

	/**
	 * Returns if x is power of two (POT).
	 *
	 * Taken from https://www.khronos.org/webgl/wiki/WebGL_and_OpenGL_Differences
	 */
	public static isPowerOfTwo(x: number): boolean {
		return (x & (x - 1)) === 0;
	}

	/**
	 * Returns the next power of two number after x (or x if x is POT)
	 * eg.:
	 * 2 -> 2
	 * 3 -> 4
	 * 5 -> 8
	 *
	 * Taken from https://www.khronos.org/webgl/wiki/WebGL_and_OpenGL_Differences
	 *
	 * Note this only works for integers (not for 4.7).
	 *
	 */
	public static nextHighestPowerOfTwo(x: number): number {
		--x;
		for (let i = 1; i < 32; i <<= 1) {
			x = x | (x >> i);
		}
		return x + 1;
	}

	// TODO this could be optimized
	public static previousHighestPowerOfTwo(x: number): number {
		if (x < 1) {
			return 0;
		}

		const nextHighestPowerOfTwo = MathUtils.nextHighestPowerOfTwo(x);

		if (nextHighestPowerOfTwo === x) {
			return nextHighestPowerOfTwo;
		} else {
			return nextHighestPowerOfTwo / 2;
		}
	}

	public static isWholeNum(value: number) {
		return value > 0 || value === 0;
	}

	public static isValidNumber(value: number) {
		if (value === null) {
			return false;
		}
		if (value === undefined) {
			return false;
		}
		if (isNaN(value)) {
			return false;
		}
		if (value === Infinity) {
			return false;
		}
		if (value === -Infinity) {
			return false;
		}

		return true;
	}

	public static validate(value: number, defaultValue = 0) {
		if (isFinite(value) && typeof value === "number") {
			return value;
		}

		return defaultValue;
	}

	/**
	 * Can be used to convert the 1D array index to a 2D array index,
	 * Order: stepping "horizontally" first
	 * @param index
	 * @param columnSize
	 */
	public static indexToXY(index: number, columnSize: number) {
		return {
			x: index % columnSize,
			y: Math.floor(index / columnSize),
		};
	}

	/**
	 * Wraps the given angle (in radian) in the range of (-PI, PI]
	 * theta = theta - 2PI * floor( (theta+PI) / 2PI )
	 *
	 * Not the same!
	 * TODO check with 184.9990156656489
	 * same as:
	 * (((theta % 2PI) + 3PI) % 2PI) - PI
	 * (((a % 360) + 540) % 360) - 180
	 */
	public static wrapPi(theta: number): number {
		if (Math.abs(theta) > Math.PI) {
			const revolutions = Math.floor((theta + Math.PI) * (1.0 / MathUtils.TWO_PI));

			theta -= revolutions * MathUtils.TWO_PI;
		}

		return theta;
	}

	//public static angleDiff(a: number, b: number): number
	//{
	//	return MathUtils.NormalizeAngle(a-b);
	//}

	/**
	 * Composes a value with the magnitude of x and the sign of y.
	 */
	public static copySign(x: number, y: number): number {
		return Math.abs(x) * Math.sign(y);
	}

	/**
	 * Returns and angle between -180 and 180
	 */
	//public static NormalizeAngle(a: number): number
	//{
	//	return (((a % 360) + 540) % 360) - 180;
	//
	//	// TODO This version caused problems for Walls!
	//	//return a - this.TWO_PI * Math.floor((a + this.PI) / this.TWO_PI);
	//}

	/**
	 * If min/max is undefined, it is not constrained.
	 */
	public static clamp(x: number, min: number | undefined, max: number | undefined): number {
		return x < min ? min : x > max ? max : x;
	}

	// Takes the modulus of x / n, returning it to the range of [0, total-1]
	public static mod(x: number, n: number) {
		return ((x % n) + n) % n;
	}

	public static setPrecision(x: number, precision: number = 2): number {
		precision = Math.pow(10, precision);
		return Math.round(x * precision) / precision;
	}

	public static convertDistanceToSpaceUnit(valueInRealUnit: number, fromUnit: ExtendedDistanceUnitName, spaceUnitsPerMeter: number) {
		return valueInRealUnit * Constants.DISTANCE_UNITS[fromUnit].multiplicator * spaceUnitsPerMeter;
	}

	public static convertDistanceFromSpaceUnit(valueInSpaceUnit: number, toUnit: ExtendedDistanceUnitName, spaceUnitsPerMeter: number) {
		return valueInSpaceUnit / Constants.DISTANCE_UNITS[toUnit].multiplicator / spaceUnitsPerMeter;
	}

	public static convertAreaFromSpaceUnit(valueInSpaceUnit: number, toUnit: ExtendedDistanceUnitName, spaceUnitsPerMeter: number) {
		return valueInSpaceUnit * (1 / Constants.DISTANCE_UNITS[toUnit].multiplicator / spaceUnitsPerMeter) ** 2;
	}

	// eg.: 1.5 ft² -> 1 ft² 72 in²
	public static convertFeetSqInDecimalToFeetSqAndInchesSq(feetSqInDecimal: number): [number, number] {
		const feetSqFloor = Math.floor(feetSqInDecimal);
		const remainderInDecimal = feetSqInDecimal % Math.max(feetSqFloor, 1);
		const remainderInSquareInches = remainderInDecimal * 144;

		return [feetSqFloor, remainderInSquareInches];
	}

	// eg.: 8.5 ft -> 8 ft 6 in
	public static convertFeetInDecimalToFeetAndInches(feetValueInDecimal: number): [number, number] {
		const feetFloor = Math.floor(feetValueInDecimal);
		const remainderInDecimal = feetValueInDecimal % Math.max(feetFloor, 1);
		const remainderInInches = remainderInDecimal * 12; // 1 ft = 12 in

		return [feetFloor, remainderInInches];
	}

	// eg.: 8.5 ft² -> 8 ft² 72 in²
	public static convertSquareFeetInDecimalToSquareFeetAndSquareInches(squareFeetInDecimal: number): [number, number] {
		const squareFeetFloor = Math.floor(squareFeetInDecimal);
		const remainderInDecimal = squareFeetInDecimal % Math.max(squareFeetFloor, 1);
		const remainderInSquareInches = remainderInDecimal * 144; // 1 ft² = 144 in²

		return [squareFeetFloor, remainderInSquareInches];
	}

	// eg.: 1 ft 6 in -> 18 in
	public static convertFeetAndInchesToInches(feet: number, inches: number): number {
		return 12 * feet + inches;
	}

	public static formatFeetAndInchesArrayToText(feetAndInches: [number, number]): string {
		return `${feetAndInches[0]} ft ${feetAndInches[1].toFixed(Constants.TO_FIXED)} in`;
	}

	public static formatFeetSqAndInchesSqArrayToText(feetSqAndInchesSq: [number, number]): string {
		return `${feetSqAndInchesSq[0]} ft² ${feetSqAndInchesSq[1].toFixed(Constants.TO_FIXED)} in²`;
	}

	/**
	 * `x` should be between `a` and `b`.
	 * The result will be between 0 and 1, keeping the proper ratios of `x` to `a` and `b`
	 */
	public static getInterpolation(x: number, a: number, b: number) {
		return (x - a) / (b - a);
	}

	// https://en.wikipedia.org/wiki/Linear_interpolation
	public static getInterpolant(x0: number, y0: number, x1: number, y1: number, x: number) {
		return (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0);
	}

	/**
	 * x.length should be equal to y.length
	 * https://www.khronos.org/registry/OpenGL-Refpages/gl4/html/mix.xhtml
	 * @param x Specify the start of the range in which to interpolate.
	 * @param y Specify the end of the range in which to interpolate.
	 * @param a Specify the value to use to interpolate between x and y.
	 */
	public static mixVectors(x: number[], y: number[], a: number) {
		const ret: number[] = [];

		for (let i = 0; i < x.length; ++i) {
			ret.push(x[i] * (1 - a) + y[i] * a);
		}

		return ret;
	}

	public static calculateNewOrientation(lastSavedOrientation: number, deltaAngle: number, snapToAngle = !KeyboardListener.isAltDown) {
		let newOrientation = (lastSavedOrientation + deltaAngle) % (2 * Math.PI);

		if (snapToAngle) {
			newOrientation = this.snapToAngle(newOrientation);
		}

		return newOrientation % (Math.PI * 2);
	}

	/**
	 *
	 * @param deltaAngle in radians -> related to the starting angle, NOT the previous angle
	 */
	private static snapToAngle(orientation: number): number {
		const sectorSize = Math.PI / 4; // split it up to 45 deg sectors

		let newOrientation: number = orientation;

		/**
		 * The rotation range is this interval: [-PI/2, 1.5 PI], instead of the usual: [0, 2 PI];
		 * because we consider the y axis as the reference, but threejs gives the angle with respect to the x axis
		 */
		if (newOrientation < 0) {
			newOrientation += 2 * Math.PI;
		}
		if (newOrientation % sectorSize < Constants.ANGLE_SNAP_THRESHOLD || newOrientation % sectorSize > sectorSize - Constants.ANGLE_SNAP_THRESHOLD) {
			newOrientation = Math.round(newOrientation / sectorSize) * sectorSize;
		}

		return newOrientation % (Math.PI * 2);
	}

	public static clampRadianBetween0And2PI(value: number) {
		let clampedValue = value % (2 * Math.PI);

		if (clampedValue <= 0) {
			clampedValue += 2 * Math.PI;
		}

		return clampedValue;
	}

	public static clampDegreeBetween0And360(value: number) {
		let clampedValue = value % 360;

		if (clampedValue < 0) {
			clampedValue += 360;
		}

		return clampedValue;
	}

	public static getNewRandomGUID() {
		return crypto.randomUUID();
	}
}

import {computed, makeObservable, observable} from "mobx";
import type {XyiconFeature} from "../../generated/api/base";

export class ItemFieldUpdateManager {
	@observable
	private _itemFieldsBeingUpdated: Set<string> = new Set<string>();

	constructor() {
		makeObservable(this);
	}

	public getKeyForItemFieldUpdate(itemId: string, fieldRefId: string, feature: XyiconFeature): string {
		return `${itemId}_${fieldRefId}_${feature}`;
	}

	public addItemFieldUpdateToUpdateList(itemId: string, fieldRefId: string, feature: XyiconFeature) {
		this._itemFieldsBeingUpdated.add(this.getKeyForItemFieldUpdate(itemId, fieldRefId, feature));
	}

	public removeItemFieldUpdateFromUpdateList(itemId: string, fieldRefId: string, feature: XyiconFeature) {
		this._itemFieldsBeingUpdated.delete(this.getKeyForItemFieldUpdate(itemId, fieldRefId, feature));
	}

	public isItemFieldBeingUpdated(itemId: string, fieldRefId: string, feature: XyiconFeature): boolean {
		return this._itemFieldsBeingUpdated.has(this.getKeyForItemFieldUpdate(itemId, fieldRefId, feature));
	}

	@computed
	public get itemFieldsBeingUpdated() {
		return this._itemFieldsBeingUpdated;
	}
}

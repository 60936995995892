import * as React from "react";
import {observer} from "mobx-react";
import {ToggleContainer} from "../../../../../../widgets/container/ToggleContainer";
import type {Report} from "../../../../../../../data/models/Report";
import {ReportScopeWidget} from "../../../../../report/create/wizard/pages/details/ReportScopeWidget";
import type {AppState} from "../../../../../../../data/state/AppState";

interface IReportScopeSectionProps {
	report: Report;
	appState?: AppState;
}

@observer
export class ReportScopeSection extends React.Component<IReportScopeSectionProps> {
	private onUpdate = () => {
		// const {report, appState} = this.props;
		// appState.actions.updateReport(report);
	};

	public override render() {
		const {report} = this.props;

		return (
			<ToggleContainer
				title="Scope"
				open={true}
			>
				<ReportScopeWidget
					report={report}
					onChange={this.onUpdate}
					disabled={true}
				/>
			</ToggleContainer>
		);
	}
}

import type {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {IDistanceUnit} from "../../Constants";
import {Constants} from "../../Constants";
import {MeasureType} from "../../renderers/SpaceViewRendererUtils";
import type {IObjectWithText} from "../../managers/MSDF/TextUtils";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../../utils/dom/DomUtils";
import type {ICornerLetter} from "./abstract/MarkupAB";
import {MarkupAB1D} from "./abstract/MarkupAB1D";
import type {Markup3D} from "./abstract/Markup3D";

export class SetScaleLine extends MarkupAB1D {
	private static _instances: SetScaleLine[] = [];
	private static _maxCount: number = 1;
	private _textA: IObjectWithText = {
		fontColor: this.fontColor,
		fontSize: this.fontSize,
		isVisible: true,
		opacity: 1,
		text: [{content: "A"}],
		orientation: 0,
		position: {x: 0, y: 0},
		sizeOfGeneratedText: {x: 0, y: 0},
		scale: {x: 0, y: 0},
		dimensionMultiplier: 0,
		textHAlign: HorizontalAlignment.center,
		textVAlign: VerticalAlignment.center,
		textInstanceIds: [],
		fontFamily: "Roboto",
		isBold: false,
		isItalic: false,
		isUnderlined: false,
		id: "SetScaleLine_A",
	};
	private _textB: IObjectWithText = {
		fontColor: this.fontColor,
		fontSize: this.fontSize,
		isVisible: true,
		opacity: 1,
		text: [{content: "B"}],
		orientation: 0,
		position: {x: 0, y: 0},
		sizeOfGeneratedText: {x: 0, y: 0},
		scale: {x: 0, y: 0},
		dimensionMultiplier: 0,
		textHAlign: HorizontalAlignment.center,
		textVAlign: VerticalAlignment.center,
		textInstanceIds: [],
		fontFamily: "Roboto",
		isBold: false,
		isItalic: false,
		isUnderlined: false,
		id: "SetScaleLine_B",
	};
	private _textContent: string = "";

	private _segments: number[] = [];

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, {strokeColor: Constants.COLORS.PICTON_BLUE, useSegments: true, isTemp: true});

		SetScaleLine._instances.push(this);

		if (SetScaleLine._instances.length > SetScaleLine._maxCount) {
			const itemToBeRemoved = SetScaleLine._instances.shift();

			itemToBeRemoved.destroy(false);
		}

		this.recreateTextGroups([this._textA, this._textB]);
	}

	protected updateAB(A: PointDouble, B: PointDouble, isLocal: boolean = false, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		const geometryData = this.processAB(A, B, isLocal, keepAspectRatio, fixedPoint);
		const processedA = geometryData[0];
		const processedB = geometryData[1];
		const AB = {
			x: processedB.x - processedA.x,
			y: processedB.y - processedA.y,
		};

		const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;
		const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;

		const normalAB = THREEUtils.multiplyByScalar(THREEUtils.getNormal(AB), (20 * correctionMultiplier) / cameraZoomLevel);

		this._segments = [
			processedA.x + normalAB.x / 2,
			processedA.y + normalAB.y / 2,
			0,
			processedA.x - normalAB.x / 2,
			processedA.y - normalAB.y / 2,
			0,
			processedA.x,
			processedA.y,
			0,
			processedB.x,
			processedB.y,
			0,
			processedB.x + normalAB.x / 2,
			processedB.y + normalAB.y / 2,
			0,
			processedB.x - normalAB.x / 2,
			processedB.y - normalAB.y / 2,
			0,
		];

		(this._lineGeometry as LineSegmentsGeometry).setPositions(this._segments);
		if (isLocal) {
			this._selectionPart.update(this._segments);
		}
		this.updateBoundingBox();

		const origin = this._group.position;

		this._textA.position.x = origin.x + processedA.x;
		this._textA.position.y = origin.y + processedA.y;

		this._textB.position.x = origin.x + processedB.x;
		this._textB.position.y = origin.y + processedB.y;

		this._textA.textHAlign = processedA.x <= processedB.x ? HorizontalAlignment.right : HorizontalAlignment.left;
		this._textB.textHAlign = processedA.x <= processedB.x ? HorizontalAlignment.left : HorizontalAlignment.right;

		this.updateDimensionMultiplier();
	}

	public showDistance(value: number, unit: IDistanceUnit) {
		this._textContent = `${value}${unit.abbreviation}`;
		this.recreateTextGroups([this._textA, this, this._textB]);
	}

	protected override onCameraZoomChangeCallback = () => {
		this.updateDimensionMultiplier();
	};

	private updateDimensionMultiplier() {
		const scale = this.dimensionMultiplier;

		this._textA.dimensionMultiplier = scale;
		this._textB.dimensionMultiplier = scale;

		if (this._worldGeometryData?.length > 0) {
			const items = [...(this.itemManager.items.array as Markup3D[]), this._textA, this._textB];

			if (!items.includes(this)) {
				items.push(this);
			}
			this._spaceViewRenderer.spaceItemController.markupTextManager.updateOnNextFrame = false;
			this._spaceViewRenderer.spaceItemController.markupTextManager.updateTextTransformations(items);
		}
	}

	public override get dimensionMultiplier() {
		const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
		const scale = 0.75 / cameraZoomLevel;

		return scale;
	}

	public override get textContent() {
		return this._textContent;
	}

	public override get fontColor() {
		return this.shiftedColor;
	}

	public get type(): null {
		// Type with "null" values are not going to be saved to the database
		return null;
	}

	public get measureType() {
		return MeasureType.DISTANCE;
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {TypePermissions} from "./TypePermissions";
import {GeneralPermissions} from "./GeneralPermissions";
import {FieldPermissions} from "./FieldPermissions";

interface IModulePermissionsProps {
	permissionSet: PermissionSet;
	feature: XyiconFeature;
}

interface IModulePermissionsState {
	creating: boolean;
	selectedIds: string[];
}

@observer
export class ModulePermissions extends React.Component<IModulePermissionsProps, IModulePermissionsState> {
	constructor(props: IModulePermissionsProps) {
		super(props);
		this.state = {
			creating: false,
			selectedIds: [],
		};
	}

	public override render() {
		const {permissionSet, feature} = this.props;

		return (
			<div className="ModulePermissions">
				<div className="flex_1">
					{feature === XyiconFeature.Portfolio ? (
						<GeneralPermissions permissionSet={permissionSet} />
					) : (
						<TypePermissions
							permissionSet={permissionSet}
							feature={feature}
						/>
					)}
				</div>
				<div className="flex_1">
					<FieldPermissions
						permissionSet={permissionSet}
						feature={feature}
					/>
				</div>
			</div>
		);
	}
}

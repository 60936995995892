import {MarkupType} from "../../../../../../../generated/api/base";

export const getDefaultMarkupFontSize = (type: MarkupType, cameraZoomValue: number) => {
	if (type === MarkupType.Text_Box || type === MarkupType.Callout) {
		if (cameraZoomValue >= 0.6) {
			return 80;
		} else if (cameraZoomValue >= 0.2) {
			return 100;
		} else {
			return 150;
		}
	} else {
		return 100;
	}
};

import styled from "styled-components";
import {baseDistance, fontSize} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export const FilterSwitch = styled.div`
	display: flex;
	align-items: center;

	label {
		font-size: ${fontSize.md};
		margin-left: ${baseDistance.sm};
		margin-right: ${baseDistance.xs};
		color: ${colorPalette.primary.c500Primary};
		cursor: pointer;
		text-decoration: underline;
	}
`;

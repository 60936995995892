import * as React from "react";
import {inject} from "mobx-react";
import {ModuleView} from "../abstract/ModuleView";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {Catalog} from "../../../data/models/Catalog";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {IModel} from "../../../data/models/Model";
import {CatalogItemPanel} from "./create/CatalogItemPanel";
import type {IIconConfig} from "./create/CatalogTypes";

export interface ICatalogViewProps {
	param1?: string;
	param2?: string;
	appState?: AppState;
	transport?: TransportLayer;
}

interface ICatalogViewStates {
	editedCatalog: {
		config: IIconConfig;
		catalog: Catalog;
	};
	loading: boolean;
}

@inject("appState")
@inject("transport")
export class CatalogView extends React.Component<ICatalogViewProps, ICatalogViewStates> {
	constructor(props: ICatalogViewProps) {
		super(props);
		this.state = {
			editedCatalog: null,
			loading: false,
		};
	}

	private onDuplicateCatalogClick = async (model: IModel) => {
		if (model.ownFeature === XyiconFeature.XyiconCatalog) {
			try {
				this.setState({loading: true});
				const catalog = model as Catalog;
				const iconConfig = await this.props.transport.getIconConfigOfCatalog(catalog);

				this.setState({loading: false});
				this.duplicateCatalogConfig(iconConfig, catalog);
			} catch (error) {
				console.log("error ", error);
			}
		}
	};

	private duplicateCatalogConfig = (config: IIconConfig, catalog: Catalog) => {
		this.setState({
			editedCatalog: {config, catalog},
		});
	};

	private onClearConfig = () => {
		this.setState({editedCatalog: null});
	};

	private get catalogPermission() {
		return this.props.appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	}

	public override render() {
		const {editedCatalog} = this.state;

		return (
			<ModuleView
				feature={XyiconFeature.XyiconCatalog}
				className="CatalogView"
				actionBar={[
					{
						id: "import",
						title: "Update Catalog Items with an Excel file",
						label: "Import",
						enabled: () => this.catalogPermission >= Permission.Update,
					},
					{
						id: "add",
						title: "Create Catalog Item",
						label: "Create",
						enabled: () => this.catalogPermission >= Permission.Update,
					},
					{
						id: "duplicate",
						title: "Duplicate Catalog Item",
						label: "Duplicate",
						enabled: (selectedItems) => selectedItems.length === 1 && this.catalogPermission >= Permission.Update,
					},
					{
						id: "edit",
						title: "Edit Catalog Item",
						label: "Edit",
						enabled: (selectedItems) => selectedItems.length === 1 && this.catalogPermission >= Permission.Update,
					},
					{
						id: "delete",
						title: "Delete Catalog Item",
						label: "Delete",
						enabled: (selectedItems) => selectedItems.length > 0 && this.catalogPermission === Permission.Delete,
					},
				]}
				onDuplicate={this.onDuplicateCatalogClick}
				create={(onClose) => (
					<CatalogItemPanel
						onClose={() => {
							onClose();
							this.onClearConfig();
						}}
						iconConfig={editedCatalog?.config}
						catalog={editedCatalog?.catalog}
						loading={this.state.loading}
					/>
				)}
			/>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IFieldAdapter} from "../../../../../../../../data/models/field/Field";
import {FilterOperator} from "../../../../../../../../data/models/filter/operator/FilterOperator";
import type {AppState} from "../../../../../../../../data/state/AppState";
import {XyiconFeature, FieldDataType} from "../../../../../../../../generated/api/base";
import {ReportType, ReportScope} from "../../../../../../../../generated/api/reports";
import {ReactUtils} from "../../../../../../../utils/ReactUtils";
import {Report} from "../../../../../../../../data/models/Report";
import {AdvancedFilterEditorV5} from "../../../../../../filter/advance/AdvancedFilterEditorV5";
import {TabViewV5} from "../../../../../../widgets/tab/TabViewV5";
import {TabChildV5} from "../../../../../../abstract/table/TabChildV5";
import {ReportFilterStyled} from "./ReportFilterV5.style";

interface ReportDataFilterParameterProps {
	report: Report;
	appState?: AppState;
}

interface ReportDataFilterParameterState {
	search: string;
}

@inject("appState")
@observer
export class ReportFilterV5 extends React.Component<ReportDataFilterParameterProps, ReportDataFilterParameterState> {
	public static isFieldSupported(field: IFieldAdapter, report: Report): boolean {
		// Temporarily disable date fields until backend api supports it
		// Disable icons, disable refId if summarize result is true
		const id = [`${XyiconFeature.Xyicon}/icon`, `${XyiconFeature.XyiconCatalog}/icon`];

		if (
			field?.dataType === FieldDataType.DateTime ||
			id.includes(field?.refId) ||
			(report.summarizeResults && field?.refId === `${report.reportFeature}/refId`)
		) {
			return false;
		}
		return true;
	}

	private _filterEditor = React.createRef<AdvancedFilterEditorV5>();
	private _filterEditorLinkedFields = React.createRef<AdvancedFilterEditorV5>();

	constructor(props: ReportDataFilterParameterProps) {
		super(props);
		this.state = {
			search: "",
		};
	}

	private operatorFilter = (operator: FilterOperator) => {
		if (operator === FilterOperator.IS_TRUE || operator === FilterOperator.IS_FALSE) {
			return true;
		}
		return !!Report.serializeOperator(operator);
	};

	public override render() {
		const {report} = this.props;

		return (
			<ReportFilterStyled className={ReactUtils.cls("ReportFilter", {linkedFields: report.type === ReportType.UserDefinedLinkedXyiconReport})}>
				<TabViewV5>
					<TabChildV5
						label="Xyicon Fields"
						title="Xyicon Fields"
					>
						<div style={{fontSize: "16px", padding: "16px 0"}}>Add filters</div>
						<div className="ReportFilterContainer">
							<AdvancedFilterEditorV5
								ref={this._filterEditor}
								features={[report.reportFeature]}
								filters={report.filters}
								autoUpdate={true}
								operatorFilter={this.operatorFilter}
								fieldFilter={(field) => ReportFilterV5.isFieldSupported(field, report)}
								multiplePortfolios={report.scope === ReportScope.PortfolioIDList || report.scope === ReportScope.Organization}
								isReport={true}
							/>
						</div>
					</TabChildV5>
					{report.type === ReportType.UserDefinedLinkedXyiconReport && (
						<TabChildV5
							label="Linked Xyicon Fields"
							title="Linked Xyicon Fields"
						>
							<div style={{fontSize: "16px", padding: "16px 0"}}>Add filters</div>
							<div className="ReportFilterContainer">
								<AdvancedFilterEditorV5
									ref={this._filterEditorLinkedFields}
									features={[report.reportFeature]}
									filters={report.linkedFilters}
									autoUpdate={true}
									operatorFilter={this.operatorFilter}
									fieldFilter={(field) => ReportFilterV5.isFieldSupported(field, report)}
									multiplePortfolios={report.scope === ReportScope.PortfolioIDList || report.scope === ReportScope.Organization}
									linkedFields={true}
									isReport={true}
								/>
							</div>
						</TabChildV5>
					)}
				</TabViewV5>
			</ReportFilterStyled>
		);
	}
}

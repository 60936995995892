import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {Xyicon3D} from "../elements3d/Xyicon3D";
import type {BoundarySpaceMap3D} from "../elements3d/BoundarySpaceMap3D";
import type {Markup3D} from "../elements3d/markups/abstract/Markup3D";
import type {SpaceTool} from "../features/tools/Tools";
import {BoundingBox} from "../elements3d/BoundingBox";
import {SelectionToolAction} from "../features/tools/SelectionTool";
import type {SelectionTool} from "../features/tools/SelectionTool";
import {Xyicon} from "../../../../../../data/models/Xyicon";
import {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import {Markup} from "../../../../../../data/models/Markup";
import type {BoundarySpaceMapDto, MarkupDto, PointDouble, XyiconDto} from "../../../../../../generated/api/base";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import {ClipboardManager} from "./ClipboardManager";
import {GHOST_ID_POSTFIX} from "./GhostModeConstants";

export class GhostModeManager {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _ghostXyicons: Xyicon3D[] = [];
	private _ghostBoundaries: BoundarySpaceMap3D[] = [];
	private _ghostMarkups: Markup3D[] = [];

	private _cursorStyleOnStart: string;
	private _spaceToolIdOnStart: SpaceTool;
	private _pointerWorldStart: PointDouble = {
		x: null,
		y: null,
	};
	private _savedBBoxPos: PointDouble = {x: null, y: null};
	private _isVisible: boolean = false;
	private _isActive: boolean = false;
	private _boundingBox: BoundingBox;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._boundingBox = new BoundingBox(this._spaceViewRenderer);
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		KeyboardListener.getInstance().signals.windowBlur.add(this.onBlur);
		FocusLoss.listen(this._spaceViewRenderer.domElement, this.onBlur, null, "up");
	}

	public init() {
		this._boundingBox.initZIndex();
	}

	private stopAsap = () => {
		return new Promise<void>((resolve, reject) => {
			const stopAsap = () => {
				this.stop();
				ClipboardManager.signals.pasteDone.remove(stopAsap);
				resolve();
			};

			ClipboardManager.signals.pasteDone.add(stopAsap);
		});
	};

	private onBlur = () => {
		this.stop();

		return false;
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.stop();
				break;
		}
	};

	private cloneData(data: XyiconDto | BoundarySpaceMapDto | MarkupDto) {
		const postFix = GHOST_ID_POSTFIX;
		const clonedData = ObjectUtils.deepClone(data);

		if ((clonedData as XyiconDto).xyiconID) {
			(clonedData as XyiconDto).xyiconID += postFix;
		} else if ((clonedData as BoundarySpaceMapDto).boundarySpaceMapID) {
			(clonedData as BoundarySpaceMapDto).boundarySpaceMapID += postFix;
		} else if ((clonedData as MarkupDto).markupID) {
			(clonedData as MarkupDto).markupID += postFix;
		}

		return clonedData;
	}

	public async start(xyicons: Xyicon[], boundaries: BoundarySpaceMap[], markups: Markup[]) {
		this.stop(); // stop the previous one: maximum one set of ghost copy at once
		this._isActive = true;

		const appState = this._spaceViewRenderer.transport.appState;

		for (const xyicon of xyicons) {
			const xyiconClone = new Xyicon(this.cloneData(xyicon.data) as XyiconDto, appState);
			const xyicon3D = await this._spaceViewRenderer.xyiconManager.createSpaceItem3DFromModel(xyiconClone);

			this._ghostXyicons.push(xyicon3D);
		}

		for (const boundarySpaceMap of boundaries) {
			const boundarySpaceMapClone = new BoundarySpaceMap(this.cloneData(boundarySpaceMap.data) as BoundarySpaceMapDto, boundarySpaceMap.parent);
			const boundarySpaceMap3D = this._spaceViewRenderer.boundaryManager.createSpaceItem3DFromModel(boundarySpaceMapClone);

			this._ghostBoundaries.push(boundarySpaceMap3D);
		}

		for (const markup of markups) {
			const markupClone = new Markup(this.cloneData(markup.data) as MarkupDto, appState);
			const markup3D = this._spaceViewRenderer.markupManager.createSpaceItem3DFromModel(markupClone);

			this._ghostMarkups.push(markup3D);
		}

		if (markups.length > 0) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry([
				...(this._spaceViewRenderer.markupManager.items.array as Markup3D[]),
				...this._ghostMarkups,
			]);
		}

		for (const ghostItem of this.ghostSpaceItems) {
			if (ghostItem.isVisible) {
				this._boundingBox.expandByBoundingBox(ghostItem.boundingBox);
			}
			ghostItem.setVisibility(false);
			ghostItem.turnToSemiTransparent();
			ghostItem.startTranslating();
		}
		const bbox = this._boundingBox.boundingBox;

		this._savedBBoxPos.x = bbox.min.x;
		this._savedBBoxPos.y = bbox.max.y;
		this._boundingBox.startTranslating();

		this._spaceViewRenderer.spaceItemController.boundingBox.makeLineDashed();

		const {activeTool} = this._spaceViewRenderer.toolManager;
		const bboxPos = this._spaceViewRenderer.spaceItemController.boundingBox.position;

		this._pointerWorldStart.x = bboxPos.x;
		this._pointerWorldStart.y = bboxPos.y;

		this._spaceToolIdOnStart = activeTool.toolId;
		this._spaceViewRenderer.inheritedMethods.setActiveTool("selection");

		this._cursorStyleOnStart = this._spaceViewRenderer.domElement.style.cursor;
		this._spaceViewRenderer.domElement.style.cursor = "grabbing";
	}

	public pointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		let deltaX = worldX - this._pointerWorldStart.x;
		let deltaY = worldY - this._pointerWorldStart.y;

		if (!this._isVisible) {
			this._boundingBox.show();
		}

		const {snapToGridManager} = this._spaceViewRenderer.spaceItemController;

		if (snapToGridManager.isActive) {
			const cursorPosToBBoxPos = {
				x: this._savedBBoxPos.x - this._pointerWorldStart.x,
				y: this._savedBBoxPos.y - this._pointerWorldStart.y,
			};

			const finalPos = snapToGridManager.getUpdatedCoords(
				this._pointerWorldStart.x + deltaX + cursorPosToBBoxPos.x,
				this._pointerWorldStart.y + deltaY + cursorPosToBBoxPos.y,
			);

			deltaX = finalPos.x - this._savedBBoxPos.x;
			deltaY = finalPos.y - this._savedBBoxPos.y;
		}

		for (const ghostItem of this.ghostSpaceItems) {
			if (!this._isVisible) {
				ghostItem.setVisibility(true);
			}
			ghostItem.translate(deltaX, deltaY, 0);
		}
		if (this._ghostMarkups.length > 0) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.updateTextTransformations([
				...(this._spaceViewRenderer.markupManager.items.array as Markup3D[]),
				...this._ghostMarkups,
			]);
		}

		this._boundingBox.translate(deltaX, deltaY);
		this._isVisible = true;
		this._spaceViewRenderer.needsRender = true;

		this._spaceViewRenderer.domElement.style.cursor = "grabbing";
	};

	public stop(force: boolean = false) {
		if (this.isActive && (force || (!ClipboardManager.isPasting && !this._spaceViewRenderer.spaceItemController.isAddingItemsToServer))) {
			this._isActive = false;
			// Reverse is for kind of a double-safety for this bug: https://dev.azure.com/xyicon/SpaceRunner%20V4/_workitems/edit/1320
			// "Xyicons disappear upon pressing ESC after few stamps"
			// Basically: we didn't decrease the instanceId for some cases upon deleting, because some items aren't part of "xyiconManager.items", only ghostModeManager.ghostXyicons
			// it lead to various bugs related to instanced meshes (xyicons)
			// With reverse, we delete from newest to oldest items, and it was good enough to fix the issues by itself, due to the nature of Xyicon3D.destroyCallback
			for (const ghostItem of this.ghostSpaceItems.reverse()) {
				// Don't call stopTranslating, because it saves the new data into the modelData, which can be shared with the source item (although it shouldn't, because it's deep cloned)!
				// ghostItem.stopTranslating();
				ghostItem.destroy(false);
			}

			const markupTextNeedsUpdate = this._ghostMarkups.some((markup: Markup3D) => markup.textContent.length > 0);

			this._ghostXyicons.length = 0;
			this._ghostBoundaries.length = 0;
			this._ghostMarkups.length = 0;

			if (markupTextNeedsUpdate) {
				this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
			}

			const {activeTool} = this._spaceViewRenderer.toolManager;

			if (activeTool?.toolId === "selection") {
				const selectionTool = activeTool as SelectionTool;

				if (selectionTool.currentAction === SelectionToolAction.GHOST_MODE) {
					selectionTool.currentAction = null;
				}
			}

			this._spaceViewRenderer.inheritedMethods.setActiveTool(this._spaceToolIdOnStart);
			this._spaceViewRenderer.domElement.style.cursor = this._cursorStyleOnStart;
			this._spaceViewRenderer.spaceItemController.boundingBox.makeLineSolid();

			this._isVisible = false;
			this._boundingBox.reset();
		} else if (ClipboardManager.isPasting) {
			// Make them disappear immediately
			for (const ghostItem of this.ghostSpaceItems.reverse()) {
				ghostItem.setVisibility(false);
			}
			this._boundingBox.reset();
			this._spaceViewRenderer.spaceItemController.boundingBox.makeLineSolid();
			// Kill them completely after pasting is done
			return this.stopAsap();
		}
	}

	private get ghostSpaceItems() {
		return [...this._ghostXyicons, ...this._ghostBoundaries, ...this._ghostMarkups];
	}

	public get bboxPos() {
		return this._boundingBox.position;
	}

	public get ghostXyicons() {
		return this._ghostXyicons;
	}

	public get ghostBoundaries() {
		return this._ghostBoundaries;
	}

	public get ghostMarkups() {
		return this._ghostMarkups;
	}

	public get isActive() {
		return this._isActive && this.ghostSpaceItems.length > 0;
	}
}

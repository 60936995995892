import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../data/TransportLayer";
import type {Navigation} from "../Navigation";
import type {AppState, UIVersion} from "../data/state/AppState";
import {NavigationEnum} from "../Enums";
import type {IDropdownElementProps} from "./topbar/TopBarDropdown";
import {TopBarDropdown} from "./topbar/TopBarDropdown";

interface IUserProfileProps {
	transport?: TransportLayer;
	navigation?: Navigation;
	appState?: AppState;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class UserProfile extends React.Component<IUserProfileProps> {
	private _avatar = React.createRef<HTMLDivElement>();

	private onSettingsClick = () => {
		this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal");
	};

	private onMyProfileClick = () => {
		this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal");
	};

	private onLogoutClick = () => {
		return this.props.transport.services.auth.logout();
	};

	private onUITogglerClick = () => {
		const newVersion: UIVersion = "5.0";

		this.props.appState.setUIVersion(newVersion);
		this.forceUpdate();
	};

	public override render() {
		const {appState} = this.props;
		const user = appState.user;
		const initials = user ? appState.actions.renderInitials(user.id) : "";

		const dropdownElements: IDropdownElementProps[] = [
			{
				icon: "user",
				onClick: this.onMyProfileClick,
				label: "My profile",
			},
			{
				icon: "settings",
				onClick: this.onSettingsClick,
				label: "Settings",
			},
		];

		if (this.props.appState.user?.email.endsWith("@xyicon.com")) {
			dropdownElements.push({
				icon: "hide",
				onClick: this.onUITogglerClick,
				label: "Switch to UI 5.0",
			});
		}

		dropdownElements.push({
			icon: "logout",
			onClick: this.onLogoutClick,
			label: "Logout",
			hasLineOnTop: true,
		});

		return (
			<div
				className="avatar avatarColor dropdown"
				ref={this._avatar}
			>
				<span className="initials">{initials}</span>
				<TopBarDropdown
					elements={dropdownElements}
					parent={this._avatar}
				/>
			</div>
		);
	}
}

import type {CatalogIconType, SpaceFileInsertionInfo, XyiconCatalogIconDto} from "../../../../generated/api/base";
export interface IIconConfig extends XyiconCatalogIconDto {
	iconCategory: CatalogIconType;
	innerPart: string;
}

export interface IGlyph {
	innerPart: string;
	keywords: string[];
	defaultImageID: string;
}

export const getDefaultInsertionInfo = (): SpaceFileInsertionInfo => {
	return {
		offsetX: 0,
		offsetY: 0,
		offsetZ: 0,
	};
};

export interface IDefaultGlyphData {
	defaultImageID: string; // guid
	type: CatalogIconType;
	imageData: string; // <svg>...</svg>
	keywords: string[];
}

import type {User} from "../data/models/User";

export class FormatUtils {
	public static formatInitials(user: User) {
		const firstName = user.firstName || "";
		const lastName = user.lastName || "";

		if (firstName && lastName) {
			return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
		}
		if (firstName) {
			return firstName.charAt(0).toUpperCase() + firstName.charAt(1);
		}
		if (lastName) {
			return lastName.charAt(0).toUpperCase() + lastName.charAt(1);
		}

		return "";
	}
}

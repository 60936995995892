import type {ComponentType} from "react";
import type {IFieldAdapter} from "../../field/Field";
import {StringParam} from "../../../../ui/modules/abstract/filter/advanced/controls/StringParam";
import {NumberParam} from "../../../../ui/modules/abstract/filter/advanced/controls/NumberParam";
import {BetweenParam} from "../../../../ui/modules/abstract/filter/advanced/controls/BetweenParam";
import {DateParam} from "../../../../ui/modules/abstract/filter/advanced/controls/DateParam";
import {BetweenDateParam} from "../../../../ui/modules/abstract/filter/advanced/controls/BetweenDateParam";
import {BetweenTimeParam} from "../../../../ui/modules/abstract/filter/advanced/controls/BetweenTimeParam";
import {ExplicitDateParam} from "../../../../ui/modules/abstract/filter/advanced/controls/ExplicitDateParam";
import {TimeParam} from "../../../../ui/modules/abstract/filter/advanced/controls/TimeParam";
import {AnyParam} from "../../../../ui/modules/abstract/filter/advanced/controls/AnyParam";
import {
	isNotAnyOf,
	isAnyOf,
	contains,
	doesNotContain,
	isBlank,
	isEndingWith,
	isEqualTo_str,
	isGreaterThan,
	isGreaterThanOrEqualTo,
	isInBetween,
	isLessThan,
	isLessThanOrEqualTo,
	isNotBetween,
	isNotBlank,
	isNotEqualTo_str,
	isStartingWith,
	isNotEqualTo_num,
	isEqualTo_num,
	isEqualToDate,
	isLessThanDate,
	isGreaterThanDate,
	isInToday,
	isInYesterday,
	inThisWeek,
	inThisMonth,
	inLastWeek,
	inLastMonth,
	isAtTime,
	isBeforeTime,
	isAfterTime,
	isInBetweenDate,
	isNotBetweenDate,
	inTheLastExplicitDate,
	inTheNextExplicitDate,
	isBetweenTime,
	isTrue,
	isFalse,
} from "./FilterOperatorMethods";
import type {IFilterOperatorMethod} from "./FilterOperatorMethods";
import {FilterOperator} from "./FilterOperator";

type FilterParamControl = ComponentType<{
	param: any;
	onChange(param: any): void;
	onFocus?(value: boolean): void;
	field?: IFieldAdapter;
}>;

export interface IFilterOperatorConfig {
	id: FilterOperator;
	label: string;
	method: IFilterOperatorMethod;
	control: FilterParamControl;
	validator: (param: any) => boolean;
}

const hasParam = (param: any) => param === 0 || !!param;
const alwaysTrue = () => true;

export class FilterOperators {
	public static readonly map: {[type: string]: IFilterOperatorConfig} = {};
	public static readonly array: IFilterOperatorConfig[] = [];

	public static add(operator: FilterOperator, label: string, method: IFilterOperatorMethod, control?: FilterParamControl) {
		const config: IFilterOperatorConfig = {
			id: operator,
			label: label,
			method: method,
			control: control,
			validator: control ? hasParam : alwaysTrue,
		};

		this.map[operator] = config;
		this.array.push(config);
	}
}

FilterOperators.add(FilterOperator.IS_BLANK, "Is Blank", isBlank);
FilterOperators.add(FilterOperator.IS_NOT_BLANK, "Is Not Blank", isNotBlank);
FilterOperators.add(FilterOperator.IS_EQUAL_TO_STR, "Is equal to", isEqualTo_str, StringParam);
FilterOperators.add(FilterOperator.IS_NOT_EQUAL_TO_STR, "Is not equal to", isNotEqualTo_str, StringParam);
FilterOperators.add(FilterOperator.CONTAINS, "Contains", contains, StringParam);
FilterOperators.add(FilterOperator.DOES_NOT_CONTAIN, "Does not contain", doesNotContain, StringParam);
FilterOperators.add(FilterOperator.IS_STARTING_WITH, "Is starting with", isStartingWith, StringParam);
FilterOperators.add(FilterOperator.IS_ENDING_WITH, "Is ending with", isEndingWith, StringParam);

FilterOperators.add(FilterOperator.IS_EQUAL_TO_NUM, "Is equal to", isEqualTo_num, NumberParam);
FilterOperators.add(FilterOperator.IS_NOT_EQUAL_TO_NUM, "Is not equal to", isNotEqualTo_num, NumberParam);
FilterOperators.add(FilterOperator.IS_LESS_THAN_NUM, "Is less than", isLessThan, NumberParam);
FilterOperators.add(FilterOperator.IS_LESS_THAN_OR_EQUAL_TO_NUM, "Is less than or equal to", isLessThanOrEqualTo, NumberParam);
FilterOperators.add(FilterOperator.IS_GREATER_THAN_NUM, "Is greater Than", isGreaterThan, NumberParam);
FilterOperators.add(FilterOperator.IS_GREATER_THAN_OR_EQUAL_TO_NUM, "Is greater than or equal to", isGreaterThanOrEqualTo, NumberParam);
FilterOperators.add(FilterOperator.IS_IN_BETWEEN_NUM, "Is in between", isInBetween, BetweenParam);
FilterOperators.add(FilterOperator.IS_NOT_BETWEEN_NUM, "Is not between", isNotBetween, BetweenParam);

FilterOperators.add(FilterOperator.IS_EQUAL_TO_DATE, "On", isEqualToDate, DateParam);
FilterOperators.add(FilterOperator.IS_LESS_THAN_DATE, "Before", isLessThanDate, DateParam);
FilterOperators.add(FilterOperator.IS_LESS_THAN_OR_EQUAL_TO_DATE, "Is less than or equal to", isLessThanOrEqualTo, DateParam);
FilterOperators.add(FilterOperator.IS_GREATER_THAN_DATE, "After", isGreaterThanDate, DateParam);
FilterOperators.add(FilterOperator.IS_GREATER_THAN_OR_EQUAL_TO_DATE, "Is greater than or equal to", isGreaterThanOrEqualTo, DateParam);
FilterOperators.add(FilterOperator.IS_IN_BETWEEN_DATE, "Between", isInBetweenDate, BetweenDateParam);
FilterOperators.add(FilterOperator.IS_NOT_BETWEEN_DATE, "Is not between", isNotBetweenDate, BetweenDateParam);
FilterOperators.add(FilterOperator.IS_IN_THE_LAST_DATE, "In the last", inTheLastExplicitDate, ExplicitDateParam);
FilterOperators.add(FilterOperator.IS_IN_THE_NEXT_DATE, "In the next", inTheNextExplicitDate, ExplicitDateParam);
FilterOperators.add(FilterOperator.IS_TODAY, "Today", isInToday);
FilterOperators.add(FilterOperator.IS_YESTERDAY, "Yesterday", isInYesterday);
FilterOperators.add(FilterOperator.IS_IN_THIS_WEEK, "This Week", inThisWeek);
FilterOperators.add(FilterOperator.IS_IN_THIS_MONTH, "This Month", inThisMonth);
FilterOperators.add(FilterOperator.IS_IN_LAST_WEEK, "Last Week", inLastWeek);
FilterOperators.add(FilterOperator.IS_IN_LAST_MONTH, "Last Month", inLastMonth);
FilterOperators.add(FilterOperator.IS_AT_TIME, "At", isAtTime, TimeParam);
FilterOperators.add(FilterOperator.IS_BEFORE_TIME, "Before", isBeforeTime, TimeParam);
FilterOperators.add(FilterOperator.IS_AFTER_TIME, "After", isAfterTime, TimeParam);
FilterOperators.add(FilterOperator.IS_BETWEEN_TIME, "Between", isBetweenTime, BetweenTimeParam);

FilterOperators.add(FilterOperator.IS_TRUE, "True", isTrue);
FilterOperators.add(FilterOperator.IS_FALSE, "False", isFalse);

FilterOperators.add(FilterOperator.IS_ONE_OF, "Is one of", isAnyOf); // this is an alias for "is any of"

FilterOperators.add(FilterOperator.IS_ANY_OF, "Is any of", isAnyOf, AnyParam);
FilterOperators.add(FilterOperator.IS_NOT_ANY_OF, "Is not any of", isNotAnyOf, AnyParam);

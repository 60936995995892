import * as React from "react";
import type {SVGProps, FunctionComponent} from "react";
import {ReactUtils} from "../../../../utils/ReactUtils";

interface IViewComponentTogglerProps {
	onClick: (value: boolean) => void;
	isActive: boolean;
	type: string;
	title: string;
	classNames?: string;
	label?: string;
	IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const ViewComponentTogglerV5 = (props: IViewComponentTogglerProps) => {
	const IconComponent = props.IconComponent;

	const onClick = () => {
		props.onClick(!props.isActive);
	};

	return (
		<div
			className={ReactUtils.cls("btn", {active: props.isActive})}
			title={props.title}
			onClick={onClick}
		>
			{IconComponent && <IconComponent />}
			{props.label || ""}
		</div>
	);
};

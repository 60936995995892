import styled, {css} from "styled-components";
import {useRef} from "react";
import {colorPalette} from "../styles/colorPalette";
import {FlexCenterStyle, radius, zIndex} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import PlusIcon from "../icons/circle-plus.svg?react";
import DotsHorizontalIcon from "../icons/dots-horizontal.svg?react";
import {DropdownButtonStyled, DropdownButtonV5} from "../interaction/DropdownButtonV5";
import {Functions} from "../../../utils/function/Functions";
import {useClickOutside} from "../utils";
import {ProjectNameDropDownStyled} from "../topbar/ProjectName.styled";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {TMenuV5} from "./NavigationCommon";
import {ClassNameOfViewPopout} from "./FloatingMenu";
import {ViewElementStyled} from "./views/WorkspaceViewCommon";

interface IMenuStyled {
	$onlyIcon?: boolean;
	$small?: boolean;
	$active?: boolean;
	$home?: boolean;
}

const TitleRowStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 4px;

	h4 {
		font-size: 16px;
		font-weight: 400;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
	}

	${IconButtonStyled} {
		min-width: 24px;
		max-width: 24px;
		height: 24px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	${DropdownButtonStyled} {
		min-width: 24px;
		height: 24px;
	}

	margin-bottom: 8px;
`;

const FloatingMenuStyled = styled.div`
	position: absolute;
	left: 64px;
	top: 65px;
	width: 336px;
	height: calc(100% - 65px);
	background-color: #e2e8eb;
	padding: 8px;
	overflow-y: auto;
	z-index: 11;

	${ProjectNameDropDownStyled} {
		border: 1px solid ${colorPalette.gray.c300};
	}
`;

const NavigationMenuItemStyled = styled.div<IMenuStyled>`
	${FlexCenterStyle}
	cursor: pointer;
	gap: 4px;
	padding: 4px;
	position: relative;
	border-radius: ${radius.sm};
	height: 32px;

	${FloatingMenuStyled} {
		${ViewElementStyled} {
			white-space: nowrap;
			margin-left: initial;
		}
	}

	> svg {
		min-width: 24px;
	}

	${(props) =>
		props.$active &&
		css`
			background-color: ${colorPalette.blueGray.c500Primary};
			color: ${colorPalette.white};

			h4 {
				background-color: ${colorPalette.blueGray.c500Primary};
				color: ${colorPalette.white};
			}
		`};

	${(props) =>
		!props.$active &&
		css`
			&:hover {
				background-color: ${colorPalette.blueGray.c200Light};
				color: ${colorPalette.gray.c950};
			}
		`};

	${IconButtonStyled} {
		min-width: 24px;
		max-width: 24px;
		height: 24px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	${DropdownButtonStyled} {
		min-width: 24px;
		height: 24px;
	}

	h4 {
		font-size: ${(props) => (props.$onlyIcon ? "14px" : "16px")};
		font-weight: 400;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
	}

	${(props) =>
		props.$onlyIcon &&
		css`
			align-items: center;

			&:hover {
				h4 {
					height: 32px;
					padding: 0 8px;
					${FlexCenterStyle};
				}
			}

			h4 {
				position: absolute;
				background-color: ${props.$active ? colorPalette.blueGray.c500Primary : colorPalette.blueGray.c200Light};
				color: ${props.$active ? colorPalette.white : colorPalette.gray.c950};
				border-radius: 4px;
				left: 38px;
				display: none;
				z-index: 10;
			}
		`};
`;

export const NavigationMenuItem = (props: TMenuV5) => {
	const appState = useAppStore((state) => state.appState);
	const ref = useRef<HTMLDivElement>();
	const floatingMenuRef = useRef<HTMLDivElement>();
	const modalContainerRef = useRef<HTMLDivElement>();

	modalContainerRef.current = appState.app.modalContainer;
	const isHome = appState.selectedMenu === "home";

	const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		props.onClick();
	};

	useClickOutside([ref, floatingMenuRef, modalContainerRef], props.closeFloatingMenu ? props.closeFloatingMenu : Functions.emptyFunction);

	const renderTitleRow = (renderButtons: boolean) => {
		return (
			<>
				<props.icon />
				<h4>{props.label}</h4>
				{renderButtons && props.onPlusClick && (
					<IconButtonV5
						onClick={props.onPlusClick}
						IconComponent={PlusIcon}
					/>
				)}
				{renderButtons && props.dropdownOptions?.length > 0 && (
					<DropdownButtonV5
						button={
							<IconButtonV5
								IconComponent={DotsHorizontalIcon}
								onClick={Functions.emptyFunction}
							/>
						}
						options={props.dropdownOptions}
						optionsZIndex={zIndex.primaryNavigation + 1}
					/>
				)}
			</>
		);
	};

	return (
		<NavigationMenuItemStyled
			$active={props.isActive}
			$onlyIcon={props.onlyIcon}
			$home={isHome}
			$small={props.small}
			onClick={onClick}
			ref={ref}
		>
			{renderTitleRow(!props.onlyIcon)}
			{props.floatingMenu && (
				<DomPortal destination={appState.app.modalContainer}>
					<FloatingMenuStyled
						ref={floatingMenuRef}
						className={ClassNameOfViewPopout}
					>
						<TitleRowStyled>{renderTitleRow(true)}</TitleRowStyled>
						{props.floatingMenu}
					</FloatingMenuStyled>
				</DomPortal>
			)}
		</NavigationMenuItemStyled>
	);
};

import * as React from "react";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";

interface ITextAlignOptionsProps {
	onChange: (horizontalAlign: HorizontalAlignment, verticalAlign: VerticalAlignment) => void;
	verticalAlignment?: VerticalAlignment;
	horizontalAlignment?: HorizontalAlignment;
}

export class TextAlignOptions extends React.Component<ITextAlignOptionsProps> {
	private onAlignButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const div = event.currentTarget;
		const horizontalAlign = parseInt(div.getAttribute("data-horizontalalign")) as HorizontalAlignment;
		const verticalAlign = parseInt(div.getAttribute("data-verticalalign")) as VerticalAlignment;

		this.props.onChange(horizontalAlign, verticalAlign);
	};

	public static getFlexAttribForHorizontalAlignment(horizontalAlign: HorizontalAlignment) {
		if (horizontalAlign === HorizontalAlignment.left) {
			return "flex-start";
		} else if (horizontalAlign === HorizontalAlignment.center) {
			return "center";
		} else {
			return "flex-end";
		}
	}

	public static getFlexAttribForVerticalAlignment(verticalAlign: VerticalAlignment) {
		if (verticalAlign === VerticalAlignment.top) {
			return "flex-start";
		} else if (verticalAlign === VerticalAlignment.center) {
			return "center";
		} else {
			return "flex-end";
		}
	}

	private createButtons() {
		const arrayOfAlignments = [
			{
				horizontalAlign: HorizontalAlignment.left,
				verticalAlign: VerticalAlignment.top,
			},
			{
				horizontalAlign: HorizontalAlignment.center,
				verticalAlign: VerticalAlignment.top,
			},
			{
				horizontalAlign: HorizontalAlignment.right,
				verticalAlign: VerticalAlignment.top,
			},
			{
				horizontalAlign: HorizontalAlignment.left,
				verticalAlign: VerticalAlignment.center,
			},
			{
				horizontalAlign: HorizontalAlignment.center,
				verticalAlign: VerticalAlignment.center,
			},
			{
				horizontalAlign: HorizontalAlignment.right,
				verticalAlign: VerticalAlignment.center,
			},
			{
				horizontalAlign: HorizontalAlignment.left,
				verticalAlign: VerticalAlignment.bottom,
			},
			{
				horizontalAlign: HorizontalAlignment.center,
				verticalAlign: VerticalAlignment.bottom,
			},
			{
				horizontalAlign: HorizontalAlignment.right,
				verticalAlign: VerticalAlignment.bottom,
			},
		];

		return arrayOfAlignments.map((value, index: number) => {
			const style = {
				justifyContent: TextAlignOptions.getFlexAttribForHorizontalAlignment(value.horizontalAlign),
				alignItems: TextAlignOptions.getFlexAttribForVerticalAlignment(value.verticalAlign),
			};

			const selected = value.horizontalAlign === this.props.horizontalAlignment && value.verticalAlign === this.props.verticalAlignment;

			return (
				<div
					key={index}
					className={ReactUtils.cls("textAlignButton flexCenter", {selected})}
					data-horizontalalign={value.horizontalAlign}
					data-verticalalign={value.verticalAlign}
					title={`${StringUtils.capitalize(VerticalAlignment[value.verticalAlign])} ${StringUtils.capitalize(HorizontalAlignment[value.horizontalAlign])}`}
					onClick={this.onAlignButtonClick}
				>
					<div
						className="textAlignIcon"
						style={style}
					>
						<div className="alignIndicator"></div>
					</div>
				</div>
			);
		});
	}

	public override render() {
		return <div className="TextAlignOptions">{this.createButtons()}</div>;
	}
}

import * as React from "react";
import {FileDropper} from "../../../utils/interaction/draganddrop/FileDropper";
import {WarningWindow} from "../../modules/abstract/popups/WarningWindow";

interface IFileDropperProps {
	accept: string; // can be extensions, eg.: ".glb,.glTF", or mime types, eg.: "image/*"
	purpose: string;
	multiple?: boolean;
	onFileInputChange: (files: FileList) => void;
}

export class FileDropperReact extends React.Component<IFileDropperProps> {
	private static _counter: number = 0;
	private _ref = React.createRef<HTMLDivElement>();
	private _dropper: FileDropper;

	private onDragOver = (event: DragEvent) => {
		// TODO: what's the purpose of this?

		return true;
	};

	private onDrop = (event: DragEvent) => {
		this.onFileChange(event.dataTransfer.files);
	};

	private onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.onFileChange(event.currentTarget.files);
	};

	private async onFileChange(files: FileList) {
		if (this._dropper.checkType(files)) {
			this.props.onFileInputChange(files);
		} else {
			await WarningWindow.open(`Wrong filetype! Only the following types are accepted: ${this.props.accept}`);
		}

		// To replace "value" with an empty string. So if the user deletes the previously selected item from a list, and then selects the same file again, onFileChange is triggered
		this.forceUpdate();
	}

	public override componentWillUnmount() {
		if (this._dropper) {
			this._dropper.disable();
		}
		FileDropperReact._counter--;
	}

	public override componentDidMount() {
		FileDropperReact._counter++;

		this._dropper = new FileDropper({
			targetElement: this._ref.current,
			onDragOver: this.onDragOver,
			onDrop: this.onDrop,
			accept: this.props.accept,
		});
	}

	public override render() {
		const inputId = `fileInput_${FileDropperReact._counter}`;

		return (
			<div
				className="FileDropper flexCenter"
				ref={this._ref}
			>
				<div className="label">{`${this.props.purpose}`}</div>
				<div className="cloudIcon"></div>
				<label htmlFor={inputId} />
				<input
					id={inputId}
					multiple={this.props.multiple}
					type="file"
					value=""
					accept={this.props.accept}
					onChange={this.onFileInputChange}
				/>
			</div>
		);
	}
}

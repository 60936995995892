import styled from "styled-components";
import {useRef, useState} from "react";
import {colorPalette} from "../styles/colorPalette";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {MathUtils} from "../../../utils/math/MathUtils";

export const Panel = (props: {children: React.ReactNode; open?: boolean; style?: React.CSSProperties}) => {
	const key = "v5-spaceeditor-resizeable-panel-width";
	const minWidth = 432;
	const maxWidth = window.innerWidth / 2 < 800 ? window.innerWidth / 2 : 800;
	const savedWidth = parseInt(localStorage.getItem(key)) || minWidth;
	const ref = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState<number>(savedWidth);

	const onColResizeMove = (pointer: Pointer) => {
		setWidth(MathUtils.clamp(width - pointer.localX, minWidth, maxWidth));
	};

	const onColResizeEnd = () => {
		localStorage.setItem(key, width.toString());
	};

	return (
		<PanelStyled
			$open={props.open}
			$width={width}
			$style={props.style || {}}
		>
			<PointerDetectorReact
				onMove={onColResizeMove}
				onUp={onColResizeEnd}
			>
				<ResizerStyled ref={ref} />
			</PointerDetectorReact>
			<OverflowAble>{props.children}</OverflowAble>
		</PanelStyled>
	);
};

const OverflowAble = styled.div`
	height: 100%;
	overflow-y: auto;
`;

const ResizerStyled = styled.div`
	width: 10px;
	position: absolute;
	height: 100%;
	left: 0;
	cursor: col-resize;
	border-left-color: ${colorPalette.gray.c300};
	border-left-width: 1px;
	border-left-style: solid;
`;

const PanelStyled = styled.div<{$open: boolean; $width: number; $style: React.CSSProperties}>`
	width: ${(props) => `${props.$width}px`};
	position: absolute;
	right: 0;
	z-index: 10;
	background-color: ${colorPalette.white};
	transform: translateX(${(props) => (props.$open ? 0 : "100%")});
	transition: ease-in-out transform 0.2s;
	height: calc(100vh - 66px);
	box-shadow: -4px 0px 12px 0px rgba(0, 0, 0, 0.2);
`;

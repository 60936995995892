import * as React from "react";
import styled from "styled-components";
import type {ILoadProgress, SpaceViewRenderer} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {SpaceEditorMode} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {Constants} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {LoaderIconV5} from "../../loader/LoaderIconV5";

interface ISpaceLoadingScreenProps {
	spaceViewRenderer: SpaceViewRenderer;
}

interface ISpaceLoadingScreenState {
	loadProgress: ILoadProgress;
	tilesRasterized: number;
}

export class SpaceLoadingScreenV5 extends React.Component<ISpaceLoadingScreenProps, ISpaceLoadingScreenState> {
	constructor(props: ISpaceLoadingScreenProps) {
		super(props);

		this.state = {
			loadProgress: {numberOfLoadedItems: 0, itemsToLoad: ["Loading..."]},
			tilesRasterized: 0,
		};
	}
	private get spaceViewRenderer() {
		return this.props.spaceViewRenderer;
	}

	private getLoadedPercentage() {
		const loadedRatio = this.state.loadProgress.numberOfLoadedItems / this.state.loadProgress.itemsToLoad.length;

		if (this.state.tilesRasterized === 0) {
			return Math.round(loadedRatio * 100);
		} else {
			const spaceViewRenderer = this.spaceViewRenderer;
			const zoomInfo = spaceViewRenderer.tileManager.zoomInfo[0];
			const allTiles = zoomInfo ? zoomInfo.columns * zoomInfo.rows : 1;

			if (spaceViewRenderer.mode === SpaceEditorMode.ALIGN) {
				const restRatio = (loadedRatio < 0.5 ? 0.5 : 1) - loadedRatio;

				return Math.round((loadedRatio + (restRatio * this.state.tilesRasterized) / allTiles) * 100);
			} else {
				const restRatio = 1 - loadedRatio;

				return Math.round((loadedRatio + (restRatio * this.state.tilesRasterized) / allTiles) * 100);
			}
		}
	}

	private onTileRasterized = () => {
		this.setState({
			tilesRasterized: this.state.tilesRasterized + 1,
		});
	};

	private onUpdateLoadPercentage = (loadProgress: ILoadProgress) => {
		this.setState({
			loadProgress,
		});

		if (
			loadProgress.numberOfLoadedItems === 0 ||
			(this.spaceViewRenderer.mode === SpaceEditorMode.ALIGN &&
				Math.abs(loadProgress.numberOfLoadedItems / loadProgress.itemsToLoad.length - 0.5) < Constants.EPSILON)
		) {
			this.setState({
				tilesRasterized: 0,
			});
		}
	};

	public override componentDidMount() {
		this.spaceViewRenderer.pdfRenderer.signals.tileRasterized.add(this.onTileRasterized);
		this.spaceViewRenderer.signals.loadProgress.add(this.onUpdateLoadPercentage);
	}

	public override componentWillUnmount() {
		this.spaceViewRenderer.pdfRenderer.signals.tileRasterized.remove(this.onTileRasterized);
		this.spaceViewRenderer.signals.loadProgress.remove(this.onUpdateLoadPercentage);
	}

	public override render() {
		const loadedPercentage = this.getLoadedPercentage();

		return (
			loadedPercentage < 100 && (
				<SpaceLoadingScreenStyled className="SpaceLoadingScreen">
					<CenterBox className="vbox centerBox flexCenter">
						<LoaderIconV5 />
						<div>
							{`${this.state.loadProgress.itemsToLoad[this.state.loadProgress.numberOfLoadedItems] ?? "Loading"}...`}
							{MathUtils.isValidNumber(loadedPercentage) ? ` ${loadedPercentage}%` : ""}
						</div>
					</CenterBox>
				</SpaceLoadingScreenStyled>
			)
		);
	}
}

const SpaceLoadingScreenStyled = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-color: rgba(255, 255, 255, 0.8);

	.centerBox {
		.LoaderIcon {
			margin-bottom: 15px;
			.dots div {
				background-color: var(--blue);
			}
		}
	}
`;

const CenterBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 8px;
`;

import * as React from "react";
import {inject} from "mobx-react";
import type {App} from "../../../App";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {FormContainerStyled, LoginWindowStyled} from "../user/UserStaticV5.Style";
import {LoaderIconV5} from "../loader/LoaderIconV5";
import CircleQuestionIcon from "../icons/circle-question.svg?react";

interface ILoginWindowProps {
	app: App;
	onClose?: () => void;
	navigation?: Navigation;
	transport?: TransportLayer;
	appState?: AppState;
}

interface ILoginWindowState {
	message: string;
	isLoading: boolean;
	username: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
export class LoginWindowV5 extends React.Component<ILoginWindowProps, ILoginWindowState> {
	private _submitted = false;

	constructor(props: ILoginWindowProps) {
		super(props);
		this.state = {
			message: "",
			isLoading: false,
			username: "",
		};
	}

	public override componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
	}

	public override componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	private onHashChange = () => {
		this.setState({isLoading: false});
	};

	private onForgottenPasswordClick = () => {
		this.props.navigation.go("auth/forgotten_password");
	};

	public override render() {
		const message = this.state.message || this.props.appState.lastError || "";

		return (
			<LoginWindowStyled className="LoginWindow">
				<FormContainerStyled className="formContainer hbox alignCenter login">
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<form
						className="vbox"
						role="form"
						name="formLogin"
						noValidate
						autoCapitalize="off"
						onSubmit={this.onSubmit}
					>
						<div className="inputGroup login">
							<div className="labelName">Username</div>
							<input
								id="userName"
								name="userName"
								type="text"
								className="Input"
								placeholder="example@xyicon.com"
							/>
						</div>
						<div className="inputGroup login">
							<div className="labelName">Password</div>
							<input
								id="pwInput"
								name="pwInput"
								type="password"
								className="Input"
								placeholder="************"
								autoComplete="off"
							/>

							<div
								className="forgotPassword labelName"
								onClick={this.onForgottenPasswordClick}
							>
								Forgot Password?
							</div>
						</div>

						<div className="inputGroup rememeberme">
							<input
								type="checkbox"
								id="rememberMe"
								name="rememberMe"
								className="Checkbox"
							/>
							<label htmlFor="rememberMe">Remember me</label>
						</div>
						<button
							name="submitButton"
							className="Button primary"
							type="submit"
							disabled={this.state.isLoading}
						>
							{this.state.isLoading ? (
								<div className="loading">
									<LoaderIconV5 />
								</div>
							) : (
								"Log in"
							)}
						</button>
						<div className="errorMessage">{message}</div>
						<div className="labelName help">
							<a
								href="https://support.xyicon.com/docs"
								target="_blank"
							>
								{" "}
								<CircleQuestionIcon /> <p>Help</p>{" "}
							</a>
						</div>
					</form>
				</FormContainerStyled>
			</LoginWindowStyled>
		);
	}

	private onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.props.appState.lastError = "";
		this.setState({isLoading: true});

		if (this._submitted) {
			return;
		}

		this._submitted = true;

		this.setState({
			message: "",
		});

		const formData = new FormData(event.target);
		const user = formData.get("userName") as string;
		const password = formData.get("pwInput") as string;
		const {error} = await this.props.transport.services.auth.loginPassword(user, password);

		this._submitted = false;

		if (error) {
			this.setState({message: error, isLoading: false});
		} else {
			this.setState({message: ""});
			await this.props.navigation.redirectAfterLogin();
			this.props.transport.services.auth.loadSecondaryList();
		}
	};
}

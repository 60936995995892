import styled from "styled-components";
import {VerticalFlexStyle, baseDistance, fontSize, fontWeight, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export const LayerPanelStyled = styled.div`
	${VerticalFlexStyle};
	min-height: 0;

	.VersionSelector {
		width: 150px;
		padding: 0px ${baseDistance.sm};
		border: 1px solid ${colorPalette.gray.c300};
	}

	.layers {
		padding-bottom: ${baseDistance.sm};
	}

	.container {
		height: calc(100vh - 175px);
		overflow-y: auto;

		.head {
			display: flex;
			padding-bottom: ${baseDistance.oh};
			flex-direction: column;
			gap: ${baseDistance.md};
			border-bottom: 1px solid ${colorPalette.gray.c400};

			.SearchField {
				width: 100%;
				border-radius: ${radius.sm};
			}

			.field {
				gap: ${baseDistance.oh};

				.checkbox {
					gap: ${baseDistance.sm};
					font-size: ${fontSize.md};

					.title {
						display: flex;
						align-items: center;
					}
				}
			}
		}

		.ToggleContainer {
			&:not(.FieldSection) {
				border-top: none;
			}

			.title {
				font-size: ${fontSize.lg};
			}

			.filter-title {
				font-size: ${fontSize.lg};
				gap: ${baseDistance.xs};
				display: flex;
				padding-top: ${baseDistance.sm};
				align-items: center;
				color: ${colorPalette.primary.c500Primary};
				text-transform: uppercase;
				font-weight: ${fontWeight.bold};
				cursor: pointer;

				span.btn {
					fill: ${colorPalette.primary.c500Primary};
				}
			}

			.children {
				flex-wrap: wrap;
				gap: 0px;
				padding: 0px;
			}
		}
	}

	.topButtons {
		display: flex;
		justify-content: end;
		gap: ${baseDistance.sm};
		padding-top: ${baseDistance.oh};
		padding-right: ${baseDistance.oh};
	}
`;

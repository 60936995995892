import styled from "styled-components";
import {baseDistance, fontSize, radius} from "../../../../../../styles/styles";
import {colorPalette} from "../../../../../../styles/colorPalette";

export const ReportDisplayNamesStyled = styled.div`
	width: 670px;
	min-height: 300px;
	overflow-y: auto;
	max-height: 35vh;

	.gap {
		width: 100px;
	}

	.header {
		padding: ${baseDistance.md} 0;
		display: grid;
		grid-template-columns: 1fr 100px 1fr;
	}

	.content {
		.displayField {
			margin-bottom: ${baseDistance.md};
			display: grid;
			grid-template-columns: 1fr 100px 1fr;
			justify-content: center;
			align-items: center;
			color: ${colorPalette.gray.c950};

			.item {
				svg {
					width: ${baseDistance.md};
					height: ${baseDistance.md};
					margin-right: ${baseDistance.xs};
					stroke-width: 1;
				}
			}

			input.flex_1 {
				font-size: 14px;
				padding: 11px;
				color: ${colorPalette.gray.c950};
				border: solid 1px var(--input-border);
				width: 100%;
				resize: none;
				border-radius: ${radius.sm};
			}

			.fieldName {
				margin-left: 20px;
				&.linkedField {
					margin-left: 0;
				}
			}

			.displayName {
				&.linkedField {
					position: relative;

					span {
						position: absolute;
						left: ${baseDistance.sm};
						font-size: ${fontSize.md};
						color: var(--gray);
						@include zindex.z-index(canvas);
					}

					input {
						padding-left: 55px;
					}
				}
			}
		}
	}
`;

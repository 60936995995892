import styled from "styled-components";
import {VerticalFlexStyle, baseDistance, fontSize, fontWeight, radius} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {IconButtonStyled} from "../interaction/IconButtonV5";

export const FilterEditorStyled = styled.div`
	${VerticalFlexStyle};
	overflow: auto;
	height: inherit;

	.top-section {
		overflow-y: auto;
		overflow-anchor: none;
		height: inherit;

		.SimpleFilterEditor {
			display: flex;
			flex-direction: column;
			border-top: none;

			.ToggleContainer {
				&:not(.FieldSection) {
					border-top: none;
					padding: 0px;
					padding-left: 4px;
				}

				&.open {
					background: ${colorPalette.gray.c100}; // used in default filter panel
					border-radius: ${radius.sm}; // used in default filter panel
				}

				.filter-title {
					font-weight: ${fontWeight.semiBold};
					font-size: ${fontSize.md};
					line-height: 16px;
					gap: ${baseDistance.xs};
					text-transform: none;
					color: ${colorPalette.gray.c950};
					height: 32px; // used in default filter panel

					svg {
						width: 16px;
						height: 16px;
						position: relative;
						padding: 0px !important;
					}

					.FilterTitle {
						&.applied {
							color: ${colorPalette.primary.c500Primary};
							display: flex;
							gap: ${baseDistance.xs};
							padding-left: 2px;

							span.close {
								svg.icon {
									width: 16px;
									height: 10px;
									fill: ${colorPalette.primary.c500Primary};
								}
							}
						}
					}
				}

				.children {
					// used in default filter panel
					&.open {
						padding: 0 ${baseDistance.sm};
					}
				}
			}
		}

		.separator {
			width: 100%;
			margin: ${baseDistance.md} 0px;
			border: 1px solid ${colorPalette.gray.c300};
		}

		.heading {
			flex-direction: column;

			.SearchField {
				width: 100%;
				margin: ${baseDistance.sm} 0px; // used in default filter panel
			}

			.FilterSwitch {
				display: flex;
				justify-content: end;
				padding-top: ${baseDistance.sm};
			}
		}
	}

	.bottom-buttons {
		display: flex;
		justify-content: end;
		gap: 10px;
		padding-top: ${baseDistance.oh};
		padding-right: ${baseDistance.oh};
	}

	.title-container {
		display: flex;
		justify-content: space-between;
		padding-bottom: ${baseDistance.sm};
		font-size: ${fontSize.xl};
		line-height: 16px;
		font-weight: 700;
		color: ${colorPalette.primary.c500Primary};

		.title {
			display: flex;
			gap: ${baseDistance.sm};
			align-items: center;

			span.filter {
				svg.icon {
					width: 18px;
					height: 18px;
					position: relative;
					fill: ${colorPalette.primary.c500Primary};
				}
			}
		}

		${IconButtonStyled} {
			color: ${colorPalette.gray.c950};
			height: 32px;
			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
`;

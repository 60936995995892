import * as React from "react";
import type {PDFDocument} from "pdf-lib";
import {degrees} from "pdf-lib";
import {inject} from "mobx-react";
import type {IModulePanelProps} from "../../abstract/ModuleView";
import {InfoButton} from "../../abstract/common/infobutton/InfoButton";
import {WarningWindow} from "../../abstract/popups/WarningWindow";
import {isSpaceNamePresentInAnotherSpaceVersion} from "../spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import {Field} from "../../../widgets/form/field/Field";
import {StepIndicator} from "../../../widgets/form/stepindicator/StepIndicator";
import {ReactUtils} from "../../../utils/ReactUtils";
import {FileDropperReact} from "../../../interaction/draganddrop/FileDropperReact";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {DateTimeInput} from "../../../widgets/input/datetime/DateTimeInput";
import {ClickToEditInput} from "../../../widgets/input/clicktoedit/ClickToEditInput";
import type {
	CreateSpaceFileRequest,
	CreateSpaceRequest,
	SpaceData,
	SpaceFileData,
	SpaceFileDto,
	SpaceFileInsertionInfo,
} from "../../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../../generated/api/base";
import type {Type} from "../../../../data/models/Type";
import type {Space} from "../../../../data/models/Space";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {DateUtils} from "../../../../utils/DateUtils";
import {StepLabel} from "../../../widgets/form/stepindicator/StepLabel";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {Functions} from "../../../../utils/function/Functions";
import {SpaceConfirmSettings} from "./SpaceConfirmSettings";
import {PDFList} from "./PDFList";
import {PDFSplitter} from "./PDFSplitter";

export interface IOnePagePDFDoc {
	doc: PDFDocument;
	spaceId: string; // if there's already a space in an older version, with the same name provided
	insertionInfo: SpaceFileInsertionInfo; // if it's not the first background of a space, we have to provide this
	isAlignmentConfirmed: boolean;
	spaceUnitsPerMeter: number; // if it's the first background of a space, we have to provide a value for this
	spaceName: string;
	thumbnail: string;
	originalRotation: number; // 0 | 90 | 180 | 270
	originalPageNumber: number; // its pagenumber in the original pdf document
}

interface ICreateSpacePanelState {
	stepIndex: number;
	existingVersions: SpaceVersion[];
	selectedVersion: SpaceVersion;
	newVersionName: string;
	isVersionNameAvailable: boolean;
	issuanceDate: string;
	isNewVersionSet: boolean;
	pdfFiles: {
		originalFiles: File[];
		onePagePDFDocs: IOnePagePDFDoc[][]; // OriginalPDFFiles split by pages. pdfFiles.onePagePDFDocs[3] is an array of PDF documents that is generated by the pages of pdpfFiles.originalFiles[3]
	};
	selectedType: Type;
	spaceNamesInSelectedVersion: string[];
	uploadInfo: string;
	loadBarWidth: number;
	uploadSuccess: boolean;
}

@inject("appState")
@inject("transport")
export class CreateSpacePanel extends React.PureComponent<IModulePanelProps, ICreateSpacePanelState> {
	private _steps: string[] = [
		"Select a version set for your space(s)",
		"Upload single or multiple paged PDFs",
		"Review space names and make edits to pages",
		"Set a scale for each space based on any unit",
		"Confirm creating spaces",
	];

	constructor(props: IModulePanelProps) {
		super(props);
		const newVersionName = "";

		this.state = {
			stepIndex: 0,
			newVersionName: newVersionName,
			isVersionNameAvailable: true,
			selectedVersion: null,
			existingVersions: [],
			issuanceDate: DateUtils.stringify(new Date()),
			isNewVersionSet: true,
			pdfFiles: {
				originalFiles: [],
				onePagePDFDocs: [],
			},
			selectedType: null,
			spaceNamesInSelectedVersion: [],
			uploadInfo: null,
			loadBarWidth: 0,
			uploadSuccess: false,
		};
	}

	private onBackClick = () => {
		if (this.state.stepIndex > 0) {
			this.setState({
				stepIndex: this.state.stepIndex - 1,
			});
		} else {
			this.props.onClose();
		}
	};

	private onNextClick = async () => {
		if (this.state.stepIndex < this._steps.length - 1) {
			if (this.state.stepIndex === 0 && this.state.isNewVersionSet) {
				const createData = {
					name: this.state.newVersionName,
					issuanceDate: this.state.issuanceDate,
					portfolioID: this.props.transport.appState.portfolioId,
				};
				const services = this.props.transport.services;
				const newSpaceVersionArray = await services.feature.create(createData, XyiconFeature.SpaceVersion);

				if (newSpaceVersionArray.length > 0) {
					const newSpaceVersion = newSpaceVersionArray[0];
					const spaceVersions = (await services.feature.refreshList(XyiconFeature.SpaceVersion, true)) as SpaceVersion[];
					const selectedVersion = spaceVersions.find((spaceVersion) => spaceVersion.id === newSpaceVersion.id);

					this.setState({
						selectedVersion: selectedVersion,
						existingVersions: spaceVersions,
						isNewVersionSet: false,
					});
				} else {
					await WarningWindow.open("Space Version couldn't be created.", "Error");
					return; // don't go to the next step
				}
			}
			this.setState({
				stepIndex: this.state.stepIndex + 1,
			});
		} else if (this.state.stepIndex === this._steps.length - 1) {
			const spaceFiles: (SpaceData & SpaceFileData)[] = [];

			for (const onePagePDFDocs of this.state.pdfFiles.onePagePDFDocs) {
				for (const onePagePDFDoc of onePagePDFDocs) {
					if (onePagePDFDoc.spaceId) {
						// "Replace" background
						spaceFiles.push({
							spaceVersionID: this.state.selectedVersion.id,
							spaceID: onePagePDFDoc.spaceId,
							sourceFile: await onePagePDFDoc.doc.saveAsBase64(),
							thumbnail: onePagePDFDoc.thumbnail,
							settings: {
								insertionInfo: onePagePDFDoc.insertionInfo,
							},
						});
					} // create completely new space
					else {
						spaceFiles.push({
							name: onePagePDFDoc.spaceName,
							sourceFile: await onePagePDFDoc.doc.saveAsBase64(),
							spaceUnitsPerMeter: onePagePDFDoc.spaceUnitsPerMeter,
							thumbnail: onePagePDFDoc.thumbnail,
							settings: {
								insertionInfo: onePagePDFDoc.insertionInfo,
							},
						});
					}
				}
			}

			this.setState({
				uploadInfo: "Uploading PDFs to server...",
			});

			requestAnimationFrame(async () => {
				try {
					// Upload them one-by-one, because it's safer (the ones that are successfully uploaded are done), and we can have more feedback
					for (let i = 0; i < spaceFiles.length; ++i) {
						const spaceFile = spaceFiles[i];

						if (spaceFile.spaceID) {
							// "Replace background"
							const params: CreateSpaceFileRequest = {
								portfolioID: this.props.transport.appState.portfolioId,
								spaceFileList: [spaceFile] as SpaceFileData[],
							};

							this.setState({
								uploadInfo: `Uploading PDFs to server: ${i + 1} / ${spaceFiles.length}`,
							});

							const {result} = await this.props.transport.requestForOrganization<SpaceFileDto[]>({
								url: "spacefiles/create",
								method: XHRLoader.METHOD_PUT,
								params: params,
							});

							const space = this.props.appState.actions.getFeatureItemById(spaceFile.spaceID, XyiconFeature.Space) as Space;
							const newSpaceFileData = result[0] as SpaceFileDto;

							space.addSpaceFile(newSpaceFileData);
						} // Create completely new space
						else {
							const createData: CreateSpaceRequest = {
								portfolioID: this.props.transport.appState.portfolioId,
								spaceTypeID: this.state.selectedType.id,
								spaceVersionID: this.state.selectedVersion.id,
								spaceData: [spaceFile] as SpaceData[],
							};

							this.setState({
								uploadInfo: `Uploading PDFs to server: ${i + 1} / ${spaceFiles.length}`,
								loadBarWidth: Math.round(((i + 1) / spaceFiles.length) * 100),
							});

							const result = await this.props.transport.services.feature.create<Space>(createData, XyiconFeature.Space);
						}
					}

					this.setState({
						uploadInfo: "Uploading PDFs to the server has finished successfully",
						uploadSuccess: true,
					});
				} catch (error) {
					this.setState({
						uploadInfo: "An error has occured while uploading PDFs to the server, please try again",
					});
				}
				this.removeTemporarySpaceFiles();
				setTimeout(() => this.props.onClose("true"), 2000);
			});
		}
	};

	private removeTemporarySpaceFiles() {
		const spaces = this.props.appState.actions.getList<Space>(XyiconFeature.Space);

		for (const space of spaces) {
			space.removeTemporarySpaceFiles();
		}
	}

	private getSpaceNamesInVersion(version: SpaceVersion) {
		const spaces = this.props.appState.actions.getList<Space>(XyiconFeature.Space);
		const spaceNamesInVersion = [];

		for (const space of spaces) {
			if (space.spaceFiles.some((spaceFile) => spaceFile.spaceVersionName === version.name)) {
				spaceNamesInVersion.push(space.name);
			}
		}

		return spaceNamesInVersion;
	}

	private getNewSpaceNames() {
		const newSpaceNames = [];

		for (const onePagePDFDocs of this.state.pdfFiles.onePagePDFDocs) {
			for (const onePagePDFDoc of onePagePDFDocs) {
				newSpaceNames.push(onePagePDFDoc.spaceName);
			}
		}

		return newSpaceNames;
	}

	private selectExistingVersion(version: SpaceVersion) {
		this.setState({
			selectedVersion: version,
			spaceNamesInSelectedVersion: this.getSpaceNamesInVersion(version),
		});
	}

	private getVersions() {
		const existingVersions = this.state.existingVersions.filter((spaceVersion) =>
			this.props.appState.actions.getFeatureItemById(spaceVersion.id, XyiconFeature.SpaceVersion),
		);

		return existingVersions.map((version: SpaceVersion, index: number) => {
			const isSelected = version === this.state.selectedVersion;

			return (
				<div
					className={ReactUtils.cls("vbox version", {bordered: isSelected, active: isSelected})}
					key={index}
					onClick={() => this.selectExistingVersion(version)}
				>
					<p>{version.name}</p>
					<p className="date darkSilverText">{DateFormatter.format(version.date.toString())}</p>
				</div>
			);
		});
	}

	private removePDF(pdf: File) {
		const index = this.state.pdfFiles.originalFiles.indexOf(pdf);

		this.removePDFByIndex(index);
	}

	private removePDFByIndex(index: number) {
		if (index > -1) {
			this.state.pdfFiles.originalFiles.splice(index, 1);
			this.state.pdfFiles.onePagePDFDocs.splice(index, 1);

			this.updatePDFsByFiles([]);
		}

		this.removeTemporarySpaceFiles();
	}

	private getOriginalPDFList() {
		return this.state.pdfFiles.originalFiles.map((pdf: File, index: number) => {
			return (
				<div
					className="pdfContainer hbox bordered"
					key={index}
				>
					<div className="vbox">
						<div>{pdf.name}</div>
						<div className="darkSilverText">{FileUtils.getSizeLabelOfFile(pdf)}</div>
					</div>
					<IconButton
						icon="delete"
						title="Delete"
						className="delete"
						onClick={() => this.removePDF(pdf)}
					/>
				</div>
			);
		});
	}

	private isFileAlreadyInTheList(file: File, files: File[]) {
		for (let i = 0; i < files.length; ++i) {
			//if (ObjectUtils.compare(file, files[i]))

			// We don't allow the same name either, as it could easily cause bugs
			if (file.name === files[i].name) {
				return true;
			}
		}

		return false;
	}

	private onFileInputChange = (files: FileList) => {
		const newOriginalPDFFiles: File[] = [];

		for (let i = 0; i < files.length; ++i) {
			const file = files[i];

			if (!this.isFileAlreadyInTheList(file, this.state.pdfFiles.originalFiles)) {
				newOriginalPDFFiles.push(file);
			}
		}

		this.updatePDFsByFiles(newOriginalPDFFiles);
	};

	private generateSpaceName(pageCounter: number): string {
		const spaceName = `Floor ${pageCounter}`;

		if (this.isSpaceNameAvailable(spaceName)) {
			this.state.spaceNamesInSelectedVersion.push(spaceName);

			return spaceName;
		} else {
			return this.generateSpaceName(pageCounter + 1);
		}
	}

	private async updatePDFsByFiles(files: File[]) {
		const previousOnePagePDFObjects = this.state.pdfFiles.onePagePDFDocs;

		let pageCounter = 0;

		for (const file of files) {
			this.state.pdfFiles.originalFiles.push(file);

			const newPDFs = await PDFSplitter.split(file);
			const newOnePagePDFObjects: IOnePagePDFDoc[] = [];

			for (let j = 0; j < newPDFs.length; ++j) {
				const newPDF = newPDFs[j];

				const firstPage = newPDF.getPages()[0];
				const originalRotation = MathUtils.clampDegreeBetween0And360(firstPage.getRotation().angle);

				newOnePagePDFObjects.push({
					doc: newPDF,
					spaceId: null,
					insertionInfo: null,
					isAlignmentConfirmed: false,
					originalRotation: originalRotation,
					spaceName: this.generateSpaceName(++pageCounter),
					originalPageNumber: j,
					spaceUnitsPerMeter: null,
					thumbnail: null,
				});
			}

			previousOnePagePDFObjects.push(newOnePagePDFObjects);
		}

		const alreadyExistingSpaceNames = this.getSpaceNamesInVersion(this.state.selectedVersion);

		this.setState({
			spaceNamesInSelectedVersion: [...alreadyExistingSpaceNames, ...this.getNewSpaceNames()],
		});
	}

	private onPDFListItemDeleteClick = (originalPDFIndex: number, originalPageNumber: number) => {
		const onePagePDFDocs = this.state.pdfFiles.onePagePDFDocs;

		onePagePDFDocs[originalPDFIndex].splice(originalPageNumber, 1);

		if (onePagePDFDocs[originalPDFIndex].length < 1) {
			this.removePDFByIndex(originalPDFIndex);
		}

		this.forceUpdate();
	};

	private onPDFListItemRotate = (originalPDFIndex: number, originalPageNumber: number, deltaAngle: number) => {
		const onePagePDFDoc = this.state.pdfFiles.onePagePDFDocs[originalPDFIndex][originalPageNumber];

		const pdf = onePagePDFDoc.doc;
		const firstPage = pdf.getPages()[0];
		const rotation = MathUtils.clampDegreeBetween0And360(firstPage.getRotation().angle);

		const newRotation = MathUtils.clampDegreeBetween0And360(rotation + deltaAngle);

		firstPage.setRotation(degrees(newRotation));

		// force update need to be called within the PDFListItem event that called this method!
	};

	/**
	 * Space Names need to be unique
	 * Returns true if it's available, otherwise false
	 * @param spaceName
	 */
	private isSpaceNameAvailable = (spaceName: string) => {
		return !this.state.spaceNamesInSelectedVersion.includes(spaceName);
	};

	private onSpaceNameChange = (originalPDFIndex: number, originalPageNumber: number, newName: string) => {
		const newOnePagePDFDocs = this.state.pdfFiles.onePagePDFDocs;

		newOnePagePDFDocs[originalPDFIndex][originalPageNumber].spaceName = newName;

		const alreadyExistingSpaceNames = this.getSpaceNamesInVersion(this.state.selectedVersion);

		this.setState({
			spaceNamesInSelectedVersion: [...alreadyExistingSpaceNames, ...this.getNewSpaceNames()],
		});
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private isVersionNameAvailable = (versionName: string) => {
		return this.props.appState.actions.isVersionSetNameValid(versionName, this.state.selectedVersion?.id);
	};

	private onVersionNameChange = (newValue: string) => {
		if (newValue !== this.state.newVersionName && this.state.isVersionNameAvailable) {
			this.setState({
				newVersionName: newValue,
			});
		}
	};

	private applySpaceUnitsPerMeterToAllSpaces = (spaceUnitsPerMeter: number) => {
		for (const onePagePDFDocs of this.state.pdfFiles.onePagePDFDocs) {
			for (const onePagePDFDoc of onePagePDFDocs) {
				const isInAlignMode = isSpaceNamePresentInAnotherSpaceVersion(this.props.appState.actions, onePagePDFDoc.spaceName);

				if (!isInAlignMode) {
					onePagePDFDoc.spaceUnitsPerMeter = spaceUnitsPerMeter;
				}
			}
		}

		this.setState({
			pdfFiles: {
				onePagePDFDocs: [...this.state.pdfFiles.onePagePDFDocs],
				originalFiles: this.state.pdfFiles.originalFiles,
			},
		});
	};

	private getCreatableTypes() {
		const {user, actions, types} = this.props.appState;
		const allTypes = types[XyiconFeature.Space];

		if (!user) {
			return [];
		}
		if (user.isAdmin) {
			return allTypes;
		}

		return allTypes.filter((type) => actions.getModuleTypePermission(type.id, XyiconFeature.Space) > Permission.View);
	}

	private getForm() {
		switch (this.state.stepIndex) {
			case 0:
				const versions = this.getVersions();
				const areThereVersions = versions.length > 0;

				return (
					<div className="hbox selectVersion">
						<div
							className={ReactUtils.cls("vbox bordered", {active: this.state.isNewVersionSet})}
							onClick={() => this.setState({isNewVersionSet: true, selectedVersion: null})}
						>
							<h4>Create a new version set</h4>
							<Field label="New Version Name">
								<ClickToEditInput
									className="flexCenter bordered"
									value={this.state.newVersionName}
									valueValidator={this.isVersionNameAvailable}
									onBlur={Functions.emptyFunction /* Needed for empty the errorMessage onBlur */}
									onChange={this.onVersionNameChange}
									focused={true}
									noButtons={true}
								/>
							</Field>
							<Field label="Issuance Date">
								<DateTimeInput
									value={this.state.issuanceDate}
									onChange={(value: string) => this.setState({issuanceDate: value})}
									format="date"
								/>
							</Field>
						</div>
						<div
							className={ReactUtils.cls("vbox bordered", {active: !this.state.isNewVersionSet})}
							onClick={() => this.setState({isNewVersionSet: false})}
						>
							<h4>Select from an existing version set</h4>
							<div
								className={ReactUtils.cls("vbox overflowYAuto", {flexCenter: !areThereVersions, flex1: !areThereVersions})}
								data-cy="ExistingVersionSets"
							>
								{areThereVersions ? versions : <div className="darkSilverText">There are no versions added yet...</div>}
							</div>
						</div>
					</div>
				);

			case 1:
				const types = this.getCreatableTypes();

				return (
					<div className="vbox uploadingArea">
						<Field className="spaceType">
							<h3>Space Type</h3>
							<InfoButton bubbleText="A scale that determines the resolution of the rasterized PDF. Bigger resolution means higher maximum zoom level." />
							<SelectInput
								options={types}
								render={(type) => type.name}
								selected={this.state.selectedType}
								onChange={(option) => this.setState({selectedType: option})}
								fullWidth={true}
							/>
						</Field>
						{
							<>
								<FileDropperReact
									accept="application/pdf"
									multiple={true}
									purpose="Drag and drop or click here to add new PDF"
									onFileInputChange={this.onFileInputChange}
								/>
								{this.state.pdfFiles.originalFiles.length > 0 && (
									<div className="vbox fileContainer bordered active">{this.getOriginalPDFList()}</div>
								)}
							</>
						}
					</div>
				);

			case 2:
				return (
					<PDFList
						selectedVersion={this.state.selectedVersion}
						pdfFiles={this.state.pdfFiles}
						mode="EditSpaceNames"
						onSpaceNameChange={this.onSpaceNameChange}
						isSpaceNameAvailable={this.isSpaceNameAvailable}
						onRotate={this.onPDFListItemRotate}
						onDeleteClick={this.onPDFListItemDeleteClick}
						forceUpdate={this.forceUpdateArrow}
					/>
				);
			case 3:
				return (
					<SpaceConfirmSettings
						spaceType={this.state.selectedType}
						selectedVersion={this.state.selectedVersion}
						pdfFiles={this.state.pdfFiles}
						applySpaceUnitsPerMeterToAllSpaces={this.applySpaceUnitsPerMeterToAllSpaces}
						forceUpdate={this.forceUpdateArrow}
					/>
				);
			case 4:
				return (
					<PDFList
						selectedVersion={this.state.selectedVersion}
						pdfFiles={this.state.pdfFiles}
						mode="Confirm"
						forceUpdate={this.forceUpdateArrow}
						uploadInfo={this.state.uploadInfo}
						loadBarWidth={this.state.loadBarWidth}
						finalScreen={this.state.uploadSuccess}
					/>
				);
		}

		return null;
	}

	private isNextButtonEnabled() {
		if (
			(this.state.stepIndex === 0 &&
				this.props.transport.appState.portfolioId &&
				((this.state.isNewVersionSet && !this.state.newVersionName) || (!this.state.isNewVersionSet && this.state.selectedVersion == null))) ||
			!this.props.appState.portfolioId
		) {
			return false;
		}

		if (this.state.stepIndex >= 1 && (this.state.pdfFiles.originalFiles.length < 1 || this.state.selectedType == null)) {
			return false;
		}

		if (this.state.stepIndex === 2 && this.props.appState.app.graphicalTools.pdfRenderer.isPending) {
			return false;
		}

		if (this.state.stepIndex === 3) {
			for (const pdfArray of this.state.pdfFiles.onePagePDFDocs) {
				for (const pdfDoc of pdfArray) {
					if (!MathUtils.isValidNumber(pdfDoc.spaceUnitsPerMeter) && !pdfDoc.isAlignmentConfirmed) {
						return false;
					}
				}
			}
		}

		if (this.state.stepIndex === 4) {
			return !this.state.uploadInfo;
		}

		return true;
	}

	private onCloseClick = () => {
		this.props.onClose();
	};

	public override async componentDidMount() {
		const spaceVersions = (await this.props.transport.services.feature.refreshList(XyiconFeature.SpaceVersion)) as SpaceVersion[];

		this.setState({
			existingVersions: spaceVersions?.slice().sort((a: SpaceVersion, b: SpaceVersion) => (a.date < b.date ? 1 : -1)) || [],
		});
	}

	public override componentWillUnmount() {
		this.removeTemporarySpaceFiles();
		this.props.appState.app.graphicalTools.pdfRenderer.clearCache();
	}

	public override render() {
		const nextLabel = this.state.stepIndex < this._steps.length - 1 ? "Next" : this.state.uploadInfo ? "Creating..." : "Create";

		return (
			<div className="CreateSpacePanel SidePanel overflowYAuto">
				<div className="heading createBox hbox">
					<h4 className="detailsTitle">Create New Space</h4>
					<IconButton
						icon="close"
						title="Close Panel"
						className="close"
						onClick={this.onCloseClick}
					/>
				</div>
				<StepLabel
					nextLabel={nextLabel}
					onBackClick={this.onBackClick}
					onNextClick={this.onNextClick}
					isNextButtonEnabled={this.isNextButtonEnabled()}
					stepIndex={this.state.stepIndex}
					stepLabels={this._steps}
				/>
				<StepIndicator
					steps={["Select a version set", "Upload PDF", "Review space names", "Set space scales", "Create spaces"]}
					currentStepIndex={this.state.stepIndex}
				/>
				{this.getForm()}
			</div>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import type {Color} from "../../../generated/api/base";
import {ColorUtils} from "../../../utils/ColorUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {radius} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";

interface ColorPaletteProps {
	activeColor: Color;
	colors: Color[];
	onColorClick: (newColor: Color) => void;
}

export const ColorPaletteV5 = (props: ColorPaletteProps) => {
	const onColorClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const div = event.currentTarget;
		const hex = div.getAttribute("data-hex");
		const transparency = parseFloat(div.getAttribute("data-transparency")) ?? 0;

		props.onColorClick({
			hex: hex,
			transparency: transparency,
		});
	};

	return (
		<ColorPaletteStyled className="ColorPalette">
			{props.colors.map((color: Color) => {
				const rgba = ColorUtils.hex2rgb(color.hex, 1 - color.transparency) as string;
				const style = {
					backgroundColor: rgba,
				};

				return (
					<div
						style={style}
						onClick={onColorClick}
						data-hex={color.hex} /** Needed to retrieve it with getAttribute in `onColorClick` */
						data-transparency={color.transparency}
						key={color.hex}
						className={ReactUtils.cls({
							active: color.hex === props.activeColor.hex && color.transparency === props.activeColor.transparency,
						})}
					></div>
				);
			})}
		</ColorPaletteStyled>
	);
};

const ColorPaletteStyled = styled.div`
	width: 232px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
	gap: 8px;

	div {
		width: 32px;
		height: 32px;
		cursor: pointer;
		border-radius: ${radius.xs};

		&:hover,
		&.active {
			outline: 2px solid ${colorPalette.primary.c500Primary};
		}

		&.transparent {
			&::after {
				content: "";
				@extend .checkeredPatternBackground;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: -1;
			}
		}
	}

	&::before {
		content: attr(data-label);
		font-size: base.$font-md;
		position: absolute;
		top: -23px;
		left: 0;
		color: var(--gray);
	}
`;

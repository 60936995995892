import type {IDateTimeInputFormat} from "../../ui/widgets/input/datetime/DateTimeInput";

export class DateFormatter {
	public static daysInMonth(year: number, month: number) {
		return new Date(year, month + 1, 0).getDate();
	}

	private static formatCache: {
		[key: string]: string;
	} = {};

	public static format(dateString: string = "", format: IDateTimeInputFormat = "datetime") {
		if (dateString) {
			const cacheKey = `${dateString}-${format}`;

			if (this.formatCache[cacheKey]) {
				return this.formatCache[cacheKey];
			}

			if (dateString.match && dateString.match(/\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/gm)) {
				// this is needed because the time field's value come as hh:mm AM/PM, we need to add a date to it, so this function can handle it
				dateString = `01/01/2022 ${dateString}`;
			}

			// TODO this is probably not efficient!
			const date = new Date(dateString);

			if (!isNaN(date.getTime())) {
				const options: Intl.DateTimeFormatOptions = {};

				if (format === "date" || format === "datetime") {
					options.year = "numeric";
					options.month = "numeric";
					options.day = "numeric";
				}
				if (format === "time" || format === "datetime") {
					options.hour = "2-digit";
					options.minute = "2-digit";
				}

				const result = date.toLocaleString("en-US", options).replace(/,/g, "");

				this.formatCache[cacheKey] = result;
				return result;
			}
		}
		return "";
	}

	public static now(): string {
		return DateFormatter.toDateString(new Date());
	}

	public static toDateString(date: Date): string {
		// TODO UTC

		const years: number = date.getFullYear();
		const hours: number = date.getHours();

		return null;
	}

	public static timeStampForDownload() {
		return DateFormatter.format(new Date().toString()).replace(/\//g, "-");
	}

	private readonly _format: string;
	private readonly _weekStartOffset: number; // if the week starts on sunday, use -1, 0 for monday, etc.
	private readonly _dayNames: string[] = []; // "Monday", ...
	private readonly _monthNames: string[] = []; // "January", ...

	constructor(format = "en-us", weekStartOffset = -1) {
		this._format = format;
		this._weekStartOffset = weekStartOffset;
		this.init();
	}

	private init() {
		for (let i = 0; i < 7; ++i) {
			const startDay = 16 + this._weekStartOffset;
			const day = new Date(`2019-12-${startDay + i}`).toLocaleString(this._format, {weekday: "long"});

			this._dayNames[i] = day;
		}

		for (let i = 0; i < 12; ++i) {
			const month = new Date(`2019-${1 + i}-10`).toLocaleString(this._format, {month: "long"});

			this._monthNames[i] = month;
		}
	}

	public get dayNames() {
		return this._dayNames;
	}

	public get monthNames() {
		return this._monthNames;
	}
}

import {useEffect} from "react";
import styled from "styled-components";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {Notification, NotificationType} from "../../../notification/Notification";
import type {PDFListItemMode} from "../../../modules/space/create/PDFListItem";
import {useAppStore} from "../../../../StateManager";
import type {IOnePagePDFDoc} from "./CreateSpacePanelV5";
import {PDFListItemV5} from "./PDFListItemV5";

interface IPDFListProps {
	mode?: PDFListItemMode;
}

const PDFListStyled = styled.div<IPDFListProps>`
	${(props) =>
		props.mode !== "SetScaleOrAlign" &&
		`
		border: 2px solid #C8C8C8;
		border-radius: 8px;
	`}

	.pdfContainer {
		> .hbox:first-of-type {
			.vbox {
				overflow: hidden;
				margin-right: 10px;
			}
		}
	}

	.uploadInfo {
		margin-bottom: 20px;

		.progressBar {
			height: 8px;
			width: 100%;
			background: var(--bg4);
			position: relative;

			.loadingBar {
				background: var(--green);
				height: 8px;
				width: 0%;
				transition: ease-in-out width 0.2s;
			}
		}
	}

	.Notification {
		top: 0;
		margin-bottom: 30px;
		max-width: 550px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
`;

interface IPDFListV5Props {
	selectedVersion: SpaceVersion;
	pdfFiles: {
		originalFiles: File[];
		onePagePDFDocs: IOnePagePDFDoc[][];
	};
	mode: PDFListItemMode;
	activePDFFile?: IOnePagePDFDoc;
	forceUpdate: () => void;
	onSpaceNameChange?: (originalPDFIndex: number, originalPageNumber: number, newName: string) => void;
	isSpaceNameAvailable?: (spaceName: string) => boolean;
	onRotate?: (originalPDFIndex: number, originalPageNumber: number, deltaAngle: number) => void;
	onDeleteClick?: (originalPDFIndex: number, originalPageNumber: number) => void;
	onSelect?: (originalPDFIndex: number, originalPageNumber: number) => void;
	uploadInfo?: string;
	loadBarWidth?: number;
	finalScreen?: boolean;
}

export const PDFListV5 = (props: IPDFListV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const getPDFList = () => {
		const array = [];

		for (let i = 0; i < props.pdfFiles.onePagePDFDocs.length; ++i) {
			const originalPDFFileName = props.pdfFiles.originalFiles[i].name;
			const onePagePDFDocs = props.pdfFiles.onePagePDFDocs[i];

			for (let j = 0; j < onePagePDFDocs.length; ++j) {
				const onePagePDFDoc = onePagePDFDocs[j];

				array.push(
					<PDFListItemV5
						key={onePagePDFDoc.spaceName}
						selectedVersion={props.selectedVersion}
						mode={props.mode}
						active={onePagePDFDoc === props.activePDFFile}
						originalPDFIndex={i}
						originalPageNumber={j}
						originalPDFFileName={originalPDFFileName}
						onePagePDFDoc={onePagePDFDoc}
						onSpaceNameChange={props.onSpaceNameChange}
						isSpaceNameAvailable={props.isSpaceNameAvailable}
						forceUpdate={props.forceUpdate}
						onRotate={props.onRotate}
						onDeleteClick={props.onDeleteClick}
						onSelect={props.onSelect}
					/>,
				);
			}
		}
		return array;
	};

	useEffect(() => {
		if (props.mode === "EditSpaceNames") {
			appState.app.graphicalTools.pdfRenderer.setProcessOrder("FIFO");
		}

		return () => {
			appState.app.graphicalTools.pdfRenderer.setProcessOrder("LIFO");
		};
	}, [props.mode, appState.app]);

	const htmlDescription = `
			<ul>
				<li><b>${getPDFList().length} Spaces</b> has been successfully created</li>
				<li>Added to version set - <b>${props.selectedVersion.name}</b></li>
			</ul>
			`;

	return (
		<PDFListStyled
			className="vbox PDFListV5"
			mode={props.mode}
		>
			{props.finalScreen ? (
				<Notification
					type={NotificationType.Success}
					title="Success"
					description={htmlDescription}
					lifeTime={10000}
					cancelable={false}
				/>
			) : (
				props.mode === "Confirm" &&
				props.uploadInfo && (
					<div className="vbox flexCenter uploadInfo">
						<div className="hbox progressBar">
							<div
								className="hbox loadingBar"
								style={{width: `${props.loadBarWidth || 0}%`}}
							/>
						</div>
						<p>{props.uploadInfo}</p>
					</div>
				)
			)}
			{getPDFList()}
		</PDFListStyled>
	);
};

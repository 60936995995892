import styled from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {ELLIPSIS, Flex, fontSize, fontWeight, radius} from "../styles/styles";
import {SelectInputStyled} from "../input/select/SelectInputV5";
import {PropagatedFieldInDetailsTab} from "./datatypes/SingleFieldInput.styled";
import {MultiSelectInputStyled} from "./datatypes/multi/MultiSelectInputV5";

export const FieldElementsContainerStyled = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;

	.label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 8px;
		height: 32px;

		span {
			${ELLIPSIS}
			height: 16px;
		}
	}

	.element {
		${MultiSelectInputStyled}, ${SelectInputStyled} {
			width: 100%;
		}
	}

	svg {
		width: 16px;
		height: 16px;
		color: #1e88e5;
	}
`;

export const FieldIconStyled = styled.div`
	position: absolute;
	left: -24px;
	top: 8px;
`;

export const FieldStyled = styled.div`
	display: flex;
	font-size: ${fontSize.md};
	align-items: flex-start;
	position: relative;
	height: auto;

	&.Field {
		align-items: flex-start;

		&.noWrap {
			grid-column: 1/-1;
		}
	}

	input:disabled {
		background: #eaeaea;
	}

	input[type="checkbox"] {
		margin-left: 13px;
	}

	.element {
		width: calc(100% - 155px);
		//align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		font-weight: ${fontWeight.thin};

		&.shouldOverflow {
			overflow: inherit;
		}
	}

	&.decimals .element {
		position: relative;
		display: inline;

		.infoIcon {
			display: inline;
			right: 75px;
		}
	}

	label {
		display: flex;
		width: 100%;
		max-width: 150px;
		align-items: center;
		.element {
			font-weight: 300;
		}
	}

	&[data-inputtype="MultiLineText"] {
		.unfocused {
			display: block;
			overflow: auto;
			height: 100%;
			max-height: 140px;
			padding: 10px 25px 10px 10px;
		}

		.multiline {
			min-height: 38px;
		}
	}

	&[data-inputtype="Type"] {
		/*
		"Type" and not "SingleLineInput"
		because of the settings in FieldDataTypes.ts
		*/
		.element {
			overflow: auto;
		}
	}

	.TextInput {
		& + svg.icon {
			display: none;
			position: absolute;
			width: 16px;
			height: 16px;
			top: 50%;
			transform: translateY(-50%);
			right: 11px;
		}

		&:hover,
		&:active,
		&:focus {
			& + svg.icon {
				display: block;
			}
		}
	}

	&:hover {
		.element {
			& > *:not(.add-item):not(.ClickToEditInput):not(.spinner):not(.disabled):not(.error):not(.ToggleSwitch) {
				// TODO this rule is bad
				border-color: #c8c8c8;
			}
		}
	}

	&.disabled {
		pointer-events: auto;

		.element {
			color: var(--gray);

			.SelectInput,
			.MultiSelectInput {
				border-color: transparent;

				.input::after {
					display: none;
				}
			}
		}

		&:hover {
			.element {
				.SelectInput,
				.MultiSelectInput {
					border-color: transparent;
				}
			}
		}
	}

	&.dol-field {
		position: relative;
		left: -40px;
		width: calc(100% + 40px);

		${FieldIconStyled} {
			position: relative;
			padding-right: 8px;
			padding-left: 16px;
			left: 0;
		}

		&:hover {
			background-color: ${colorPalette.gray.c100};
			border-radius: ${radius.sm};
			border: 1px solid ${colorPalette.primary.c500Primary};

			left: -41px;
			top: -1px;
			height: calc(100% - 1px);

			${FieldElementsContainerStyled} .element >:not(${PropagatedFieldInDetailsTab}) >* {
				// Boolean ToggleSwitch should not have white background
				&:not(${Flex}) {
					background-color: ${colorPalette.white};
				}
				border-radius: ${radius.sm};
			}
		}
	}
`;

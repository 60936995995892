import * as React from "react";
import {inject, observer} from "mobx-react";
import {observable, makeObservable} from "mobx";
import type {IModulePanelProps} from "../../abstract/ModuleView";
import {PortTemplateEditor} from "../port/PortTemplateEditor";
import {Constants} from "../../space/spaceeditor/logic3d/Constants";
import {ConfirmWindow} from "../../abstract/popups/ConfirmWindow";
import {IconButton} from "../../../widgets/button/IconButton";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import {CatalogIconType, XyiconFeature, FieldDataType, Permission} from "../../../../generated/api/base";
import type {Type} from "../../../../data/models/Type";
import type {
	PortTemplateDto,
	Color,
	Dimensions,
	CreateXyiconCatalogRequest,
	XyiconCatalogUpdateSettingsDto,
	XyiconCatalogSettingsModel,
} from "../../../../generated/api/base";
import {FieldInput} from "../../../widgets/input/clicktoedit/FieldInput";
import type {Catalog, ICatalogSettings} from "../../../../data/models/Catalog";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {ClickToEditInput} from "../../../widgets/input/clicktoedit/ClickToEditInput";
import {LoaderIcon} from "../../../widgets/button/LoaderIcon";
import {StepIndicator} from "../../../widgets/form/stepindicator/StepIndicator";
import {StepLabel} from "../../../widgets/form/stepindicator/StepLabel";
import {LibraryModel, LibraryModelType} from "../../../../data/models/LibraryModel";
import {ColorUtils} from "../../../../utils/ColorUtils";
import {ImageUploadPreprocessor} from "../../../../utils/image/ImageUploadPreprocessor";
import {notify} from "../../../../utils/Notify";
import {NotificationType} from "../../../notification/Notification";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {Functions} from "../../../../utils/function/Functions";
import type {Xyicon} from "../../../../data/models/Xyicon";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {GeometrySelector} from "./GeometrySelector";
import {CatalogItemEditor} from "./CatalogItemEditor";
import {CatalogGlyphs} from "./CatalogGlyphs";
import {MeshTweaker} from "./MeshTweaker";
import {getDefaultInsertionInfo} from "./CatalogTypes";
import type {IDefaultGlyphData, IIconConfig} from "./CatalogTypes";

interface ICatalogItemPanelProps extends IModulePanelProps {
	iconConfig?: IIconConfig;
	catalog?: Catalog;
	mode: "create" | "edit";
	loading: boolean;
}

type StepType = "Item properties" | "Select model" | "Customize model" | "Port template";

interface ICatalogItemPanelState {
	stepIndex: number;
	model: string;
	errorModel: string;
	selectedType: Type;
	thumbnail: string;
	fieldData: {[refId: string]: any};
	iconConfig: IIconConfig;
	libraryModel: LibraryModel;
	isSaving: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class CatalogItemPanel extends React.PureComponent<ICatalogItemPanelProps, ICatalogItemPanelState> {
	public static defaultProps: Partial<ICatalogItemPanelProps> = {
		mode: "create",
	};

	private _defaultBodyColor: Color = {
		hex: ColorUtils.getHexStringFromNumber(Constants.DEFAULT_3D_COLOR),
		transparency: 0,
	};

	private _standard: LibraryModel;
	private get standard() {
		if (!this._standard) {
			this._standard = new LibraryModel(
				{
					fileName: "",
					type: LibraryModelType.STANDARD,
					keywords: ["tile", "standard", "default"],
					libraryModelID: "",
					thumbnail: "src/assets/images/spaceviewer/standard_tile.png",
				},
				this.props.appState,
			);
		}

		return this._standard;
	}

	@observable
	private _catalogItemEditorRef = React.createRef<CatalogItemEditor>();
	private _meshTweakerRef = React.createRef<MeshTweaker>();
	private _portTemplateEditorRef = React.createRef<PortTemplateEditor>();
	private _modelInputRef = React.createRef<ClickToEditInput>();
	private _portTemplate: PortTemplateDto[] = [];

	constructor(props: ICatalogItemPanelProps) {
		super(props);
		makeObservable(this);
		const catalog = this.props.catalog;

		this._portTemplate = catalog?.portTemplate || [];

		this.state = {
			stepIndex: 0,
			model: this.getDefaultModelName(catalog),
			errorModel: "",
			selectedType: catalog?.type,
			thumbnail: catalog?.thumbnail,
			fieldData: catalog?.fieldData || {},
			iconConfig: this.props.iconConfig,
			isSaving: false,
			libraryModel: this.actions.getFeatureItemById(this.libraryModelId, XyiconFeature.LibraryModel) || this.standard,
		};
	}

	private getDefaultModelName(catalog: Catalog | undefined) {
		let defaultModelName = catalog?.model || "";
		const isCloneMode = catalog && this.props.mode === "create";

		if (isCloneMode) {
			defaultModelName += " (Duplicate)";
		}

		return defaultModelName;
	}

	private get libraryModelId() {
		return this.props.iconConfig?.modelParameters?.libraryModelID || "";
	}

	private get actions() {
		return this.props.transport.appState.actions;
	}

	private onBackClick = () => {
		if (this.state.stepIndex > 0) {
			if (this.steps[this.state.stepIndex] === "Port template") {
				this._portTemplateEditorRef.current?.onSaveClick();
			}

			this.setState({
				stepIndex: this.state.stepIndex - 1,
			});
		} else {
			this.onCloseClick();
		}
	};

	private updateFields(catalog: Catalog, fieldData: {[refId: string]: any}) {
		this.props.transport.appState.actions.updateFields([catalog], fieldData);

		catalog.setModel(this.state.model);
		catalog.setFieldData(fieldData);
	}

	private onSaveCatalogItemClick = async () => {
		const {catalog, mode, transport} = this.props;
		const isLocalSaveSuccessful = await this.saveDataFromSteps();

		if (isLocalSaveSuccessful) {
			// wait for this.state to be updated
			await TimeUtils.waitForNextFrame();
			await TimeUtils.waitForNextFrame();

			const {model, thumbnail, selectedType} = this.state;

			if (this.isModelNameValid(model)) {
				this.setState({
					isSaving: true,
				});
				const portTemplate = this._portTemplate;

				const fieldData = {
					...this.state.fieldData,
					model,
				};

				const newestGlyphChildrenColorsMaybe = this._catalogItemEditorRef.current?.state.glyphChildrenColors;

				if (catalog && mode === "edit") {
					if (this.props.iconConfig !== this.state.iconConfig || catalog.thumbnail !== thumbnail) {
						await transport.updateIconConfig(catalog, this.state.iconConfig, thumbnail);
					}
					if (catalog.portTemplate !== portTemplate) {
						await transport.updatePortTemplate(catalog, portTemplate);
					}
					if (
						catalog.model !== model ||
						!ObjectUtils.compare(catalog.fieldData, this.state.fieldData) ||
						!ObjectUtils.compare(fieldData, this.state.fieldData)
					) {
						this.updateFields(catalog, fieldData);
					}
					if (catalog.type !== selectedType) {
						catalog.typeId = selectedType.id;
						transport.updateCatalogType(catalog);
						this.props.appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).forEach((xyicon) => {
							if (xyicon.model === catalog.model) {
								xyicon.typeId = selectedType.id;
							}
						});
					}
					if (newestGlyphChildrenColorsMaybe && !ObjectUtils.compare(catalog.glyphChildrenColors, newestGlyphChildrenColorsMaybe)) {
						catalog.setGlyphChildrenColors(newestGlyphChildrenColorsMaybe);
						const {result, error} = await this.props.transport.requestForOrganization<XyiconCatalogUpdateSettingsDto>({
							url: "xyiconcatalogs/updatesettings",
							method: XHRLoader.METHOD_POST,
							params: {
								updatedXyiconCatalogs: [
									{
										xyiconCatalogID: catalog.id,
										settings: catalog.settings,
									},
								] as XyiconCatalogSettingsModel[],
							},
						});

						if (error) {
							console.warn(error);
						}
					}
					this.setState({
						isSaving: false,
					});
					requestAnimationFrame(() => {
						this.props.onClose();
					});
				} // create
				else {
					const createData: CreateXyiconCatalogRequest = {
						xyiconTypeID: this.state.selectedType.id,
						model: this.state.model,
						generatedIcon: this.state.thumbnail.replace(/\n\s*/g, ""), // remove white-spaces
						iconType: this.state.iconConfig.iconCategory, // on the server it's called iconType, but it's confusing, since we have "xyiconType" as well...
						iconData: {
							defaultIcon: this.state.iconConfig.defaultIcon,
							uploadedIcon: this.state.iconConfig.uploadedIcon,
							modelParameters: this.state.iconConfig.modelParameters,
						},
						portTemplate: portTemplate,
						fieldData: fieldData,
						settings: {
							xyiconFieldSettings: {visibleFields: []},
							catalogFieldSettings: {visibleFields: []},
							glyphChildrenColors: this._catalogItemEditorRef.current?.state.glyphChildrenColors || [],
						} as ICatalogSettings,
					};
					const result = await this.props.transport.services.feature.create<Catalog>(createData, XyiconFeature.XyiconCatalog);

					this.setState({
						isSaving: false,
					});
					requestAnimationFrame(() => {
						this.props.onClose(result?.[0]?.id);
					});
				}
			} else {
				this.updateModelError();
			}
		}
	};

	private updateModelError(newModel: string = this.state.model) {
		if (this.isModelNameValid(newModel)) {
			this.setState({
				errorModel: "",
			});
		} else {
			let errorModel = "";

			if (!newModel) {
				errorModel = "Model cannot be empty!";
			} else {
				errorModel = "Model needs to be unique!";
			}

			this.setState({
				errorModel,
			});
		}
	}

	private onCloseClick = () => {
		this.props.onClose();
	};

	private onSaveIconConfig = (iconConfig: IIconConfig, thumbnail: string) => {
		this.setState({
			thumbnail: thumbnail,
			iconConfig: iconConfig,
			libraryModel: this.standard,
		});
	};

	private onSavePortTemplate = (portData: PortTemplateDto[]) => {
		this._portTemplate = portData;
	};

	private getTitle() {
		if (!this.props.loading) {
			if (!this.props.catalog && this.props.mode === "create") {
				return "Create Catalog Item";
			} else if (this.props.catalog && this.props.mode === "edit") {
				return `Edit Catalog Item - ${this.props.catalog.model}`;
			} else if (this.props.catalog && this.props.mode === "create") {
				return `Duplicate Catalog Item - ${this.props.catalog.model}`;
			}
		}

		return "";
	}

	public override async UNSAFE_componentWillReceiveProps(nextProps: ICatalogItemPanelProps) {
		const catalog = nextProps.catalog;

		if (catalog !== this.props.catalog) {
			const newModelName = this.getDefaultModelName(catalog);

			this._portTemplate = catalog?.portTemplate || [];

			await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryModel);

			this.setState({
				stepIndex: this.steps.indexOf("Item properties"),
				iconConfig: nextProps.iconConfig,
				libraryModel:
					this.actions.getFeatureItemById(nextProps.iconConfig?.modelParameters?.libraryModelID || "", XyiconFeature.LibraryModel) || this.standard,
				model: newModelName,
				thumbnail: catalog?.thumbnail,
				selectedType: catalog?.type,
				fieldData: {...(catalog?.fieldData || this.state.fieldData)},
			});

			this.onModelInput(newModelName);

			// Autofocus model input field
			this._modelInputRef.current?.setState({
				editing: true,
				editingValue: newModelName,
			});
		}
	}

	private isModelNameValid = (modelName: string) => {
		if (!modelName) {
			return false;
		}

		return this.props.appState.actions.isModelValidForCatalog(modelName, this.props.mode === "edit" ? this.props.catalog : null);
	};

	private resetErrorMessage() {
		this.setState({errorModel: ""});
	}

	private onModelInput = (value: string) => {
		this.resetErrorMessage();
	};

	private onModelChange = (value: string) => {
		const newModel = StringUtils.removeWhiteSpaces(value);

		this.setState({model: newModel});
		this.updateModelError(newModel);
	};

	/**
	 *
	 * @returns if it was successful
	 */
	private async saveDataFromSteps(): Promise<boolean> {
		if (this.state.libraryModel === this.standard) {
			await this._catalogItemEditorRef.current?.onSaveClick();
		} else {
			const currentMesh = this._meshTweakerRef.current?.currentMesh;

			if (currentMesh) {
				const thumbnail = await ImageUploadPreprocessor.getThumbnailFromMesh(currentMesh, "side");

				this.setState({
					thumbnail: thumbnail,
				});
			} else {
				notify(this.props.appState.app.notificationContainer, {
					title: "Error while generating the thumbnail",
					type: NotificationType.Error,
				});
				return false; // don't let the user go to the next step
			}
		}

		this._portTemplateEditorRef.current?.onSaveClick();

		return true;
	}

	private onNextClick = async () => {
		if (this.state.stepIndex < this.steps.length - 1) {
			this.setState({
				stepIndex: this.state.stepIndex + 1,
			});
		} else {
			await this.onSaveCatalogItemClick();
		}
	};

	private onTypeChange = async (selectedType: Type) => {
		const {mode, catalog} = this.props;
		let confirmed = false;

		if (mode === "edit") {
			const xyicons = this.props.appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((xyicon) => xyicon.model === catalog?.model);
			const title = "Confirm Type Change";
			const message = `Once you change the catalog type, fields (and data) not assigned to the new catalog type will be removed. There are ${xyicons.length} xyicons that will be updated with the new catalog type and assigned fields. Do you wish to continue?`;
			const config = {
				ok: "Change",
				cancel: "Cancel",
			};

			confirmed = await ConfirmWindow.open(message, title, config);
		}

		await this.updateDefaultIconConfigAndThumbnail(selectedType);
		this.setState({selectedType});
	};

	private onLibraryModelSelect = async (newActiveLibraryModel: LibraryModel) => {
		if (newActiveLibraryModel.geometryType === LibraryModelType.STANDARD) {
			await this.updateDefaultIconConfigAndThumbnail();
		} else {
			const size = await newActiveLibraryModel.getSizeInMeters();

			this.setState({
				iconConfig: {
					iconCategory: CatalogIconType.ModelParameter,
					defaultIcon: null,
					uploadedIcon: null,
					modelParameters: {
						libraryModelID: newActiveLibraryModel.id,
						dimensions: size,
						bodyColor: this._defaultBodyColor,
						customParameters: "",
						insertionInfo: getDefaultInsertionInfo(),
					},
					innerPart: null,
				},
				libraryModel: newActiveLibraryModel,
			});
		}
	};

	private onBodyColorChange = (newColor: Color) => {
		this.setState({
			iconConfig: {
				iconCategory: CatalogIconType.ModelParameter,
				defaultIcon: null,
				uploadedIcon: null,
				modelParameters: {
					...this.state.iconConfig.modelParameters,
					bodyColor: newColor,
					insertionInfo: getDefaultInsertionInfo(),
				},
				innerPart: null,
			},
		});
	};

	private onDimensionChange = (dimensions: Dimensions) => {
		this.setState({
			iconConfig: {
				iconCategory: CatalogIconType.ModelParameter,
				defaultIcon: null,
				uploadedIcon: null,
				modelParameters: {
					...this.state.iconConfig.modelParameters,
					dimensions,
					insertionInfo: getDefaultInsertionInfo(),
				},
				innerPart: null,
			},
		});
	};

	private getActivePanel() {
		const {appState, catalog} = this.props;
		const {selectedType} = this.state;

		const types = appState.types[XyiconFeature.Xyicon];
		const fieldRefIdsForType = appState.actions.getFieldRefIdsForType(selectedType?.id, XyiconFeature.XyiconCatalog);
		const layoutDefinition = appState.layouts[XyiconFeature.XyiconCatalog];

		const refIds =
			layoutDefinition?.sections
				.map((section) => section.fields.filter((field) => fieldRefIdsForType.includes(field.id)).map((field) => field.id))
				.flat() || [];

		switch (this.steps[this.state.stepIndex]) {
			case "Item properties":
				return (
					<>
						<div className="panelContent hbox">
							<div className="form">
								<label>Type</label>
								<SelectInput
									options={types}
									render={(type) => type.name}
									selected={selectedType}
									onChange={this.onTypeChange}
									fullWidth={true}
									disabled={this.props.catalog && this.catalogPermission < Permission.Update}
								/>
								<label>Model</label>
								<ClickToEditInput
									ref={this._modelInputRef}
									value={this.state.model}
									onChange={this.onModelChange}
									valueValidator={this.isModelNameValid}
									onBlur={Functions.emptyFunction}
									dataType={FieldDataType.SingleLineText}
									noButtons={true}
								/>
								{refIds.map((refId) => {
									const field = appState.actions.getFieldByRefId(refId);
									const isHidden = catalog ? appState.actions.isFieldHiddenByMasking(catalog, field) : field.isAssignedByModel;

									return (
										!!field &&
										!isHidden && (
											<React.Fragment key={field.refId}>
												<label>{field.name}</label>
												<FieldInput
													dataType={field.dataType}
													dataTypeSettings={field.dataTypeSettings}
													value={this.state.fieldData[field.refId]}
													noButtons={true}
													onChange={(value) => {
														this.setState({
															fieldData: {
																...this.state.fieldData,
																[field.refId]: value,
															},
														});
													}}
												/>
											</React.Fragment>
										)
									);
								})}
							</div>
						</div>
					</>
				);
			case "Select model":
				return (
					<GeometrySelector
						standard={this.standard}
						setActiveLibraryModel={this.onLibraryModelSelect}
						activeLibraryModel={this.state.libraryModel}
					/>
				);
			case "Customize model":
				return null;
			case "Port template":
				return (
					<PortTemplateEditor
						ref={this._portTemplateEditorRef}
						portTemplate={this._portTemplate}
						onSaveClick={this.onSavePortTemplate}
					/>
				);
		}
	}

	private isNextButtonEnabled() {
		if (this.state.isSaving) {
			return false;
		}

		switch (this.steps[this.state.stepIndex]) {
			case "Item properties":
				if (!this.isModelNameValid(this.state.model) || !this.state.selectedType) {
					return false;
				}
				break;
			case "Select model":
				if (!this.state.libraryModel) {
					return false;
				}
				break;
			case "Customize model":
				if (this.state.libraryModel === this.standard) {
					if (!this.isStateValidAtCustomizeModelStep()) {
						return false;
					}
				}
				break;
			case "Port template":
				return this.isSaveButtonEnabled();
		}

		return true;
	}

	private isStateValidAtCustomizeModelStep() {
		if (this.state.libraryModel === this.standard) {
			if (!CatalogGlyphs.defaultGlyphs) {
				return false;
			}
			const state = this._catalogItemEditorRef.current?.state;

			if (!state) {
				return false;
			}

			if (state.selectedImageSet === "Custom Images" && !this._catalogItemEditorRef.current?.state.activeLibraryImage) {
				return false;
			} else if (state.selectedImageSet === "Standard Images" && !this._catalogItemEditorRef.current?.state.innerPart) {
				return false;
			}
		}

		return true;
	}

	private isSaveButtonEnabled() {
		if (this.state.isSaving) {
			return false;
		}

		if (!this.isModelNameValid(this.state.model) || !this.state.selectedType) {
			return false;
		}

		if (!this.isStateValidAtCustomizeModelStep()) {
			return false;
		}

		if (!this.state.iconConfig?.defaultIcon && !this.state.iconConfig?.uploadedIcon && !this.state.iconConfig?.modelParameters) {
			return false;
		}

		return true;
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private getStepDetails = (step: StepType) => {
		switch (step) {
			case "Item properties":
				return "Enter catalog item properties";
			case "Select model":
				return "Select the model for your catalog item";
			case "Customize model":
				return "Customize the selected model";
			case "Port template":
				return "Setup port template";
			default:
				return "";
		}
	};

	private onStepClick = (stepIndex: number) => {
		if (stepIndex !== this.state.stepIndex) {
			if (stepIndex > this.state.stepIndex) {
				const isValid = this.isNextButtonEnabled();

				if (!isValid) {
					return;
				}
			}

			this.setState({
				stepIndex: stepIndex,
			});
		}
	};

	private getDefaultBackgroundColor(selectedType: Type = this.state.selectedType) {
		const selectedTypeColor = selectedType?.settings?.color;

		return selectedTypeColor || CatalogItemEditor.defaultState.glyphBackgroundColor;
	}

	private async updateDefaultIconConfigAndThumbnail(selectedType: Type = this.state.selectedType) {
		if (CatalogGlyphs.defaultGlyphs && CatalogGlyphs.glyphs.length > 1) {
			const backgroundColor = this.getDefaultBackgroundColor(selectedType);
			const backgroundColorRGB = ColorUtils.hex2rgb(backgroundColor.hex, 1 - backgroundColor.transparency) as string;

			const defaultSvgElement = document.createElementNS(Constants.SVG_NAMESPACE, "svg");

			defaultSvgElement.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns", Constants.SVG_NAMESPACE);
			defaultSvgElement.setAttributeNS(null, "viewBox", `0 0 ${Constants.CATALOG_SVG_VIEWBOXSIZE} ${Constants.CATALOG_SVG_VIEWBOXSIZE}`);

			const rect = document.createElementNS(Constants.SVG_NAMESPACE, "rect");

			rect.setAttributeNS(null, "x", "0");
			rect.setAttributeNS(null, "y", "0");
			rect.setAttributeNS(null, "width", `${Constants.CATALOG_SVG_VIEWBOXSIZE}`);
			rect.setAttributeNS(null, "height", `${Constants.CATALOG_SVG_VIEWBOXSIZE}`);
			rect.setAttributeNS(null, "rx", `${CatalogItemEditor.defaultState.borderRadius}`);
			rect.setAttributeNS(null, "ry", `${CatalogItemEditor.defaultState.borderRadius}`);
			rect.setAttributeNS(null, "fill", `${backgroundColorRGB}`);
			rect.setAttributeNS(null, "stroke", "none");

			defaultSvgElement.appendChild(rect);

			const compressedImage = await ImageUploadPreprocessor.createCompressedImageFromSVG(defaultSvgElement, true);

			const iconConfig: IIconConfig = {
				defaultIcon: {
					borderRadius: CatalogItemEditor.defaultState.borderRadius,
					backgroundColor: backgroundColor,
					iconInfo: {
						defaultImageID: CatalogGlyphs.glyphs[0].defaultImageID,
						color: CatalogItemEditor.defaultState.glyphIconColor,
						translate: CatalogItemEditor.defaultState.iconTranslate,
						orientation: CatalogItemEditor.defaultState.iconOrientation,
						scale: CatalogItemEditor.defaultState.scale,
						isFlippedX: CatalogItemEditor.defaultState.isFlippedX,
						isFlippedY: CatalogItemEditor.defaultState.isFlippedY,
					},
					overlayText: {
						content: CatalogItemEditor.defaultState.text,
						fontFamily: CatalogItemEditor.defaultState.fontFamily,
						fontColor: CatalogItemEditor.defaultState.fontColor,
						fontSize: CatalogItemEditor.defaultState.fontSize,
						translate: CatalogItemEditor.defaultState.textTranslate,
						orientation: CatalogItemEditor.defaultState.textOrientation,
						isBold: CatalogItemEditor.defaultState.isBold,
						isItalic: CatalogItemEditor.defaultState.isItalic,
						isUnderlined: CatalogItemEditor.defaultState.isUnderlined,
					},
				},
				uploadedIcon: null,
				modelParameters: null,
				innerPart: null,
				iconCategory: CatalogIconType.Default,
			};

			this.onSaveIconConfig(iconConfig, compressedImage);
		}
	}

	private get catalogPermission() {
		const {user} = this.props.appState;

		if (user?.isAdmin) {
			return Permission.Delete;
		}
		return user?.organizationFeaturePermissionList.find((p) => p.feature === XyiconFeature.XyiconCatalog).permission ?? Permission.None;
	}

	private get steps() {
		return this.props.mode === "create"
			? ["Item properties", "Select model", "Customize model", "Port template"]
			: ["Select model", "Customize model"];
	}

	public override async componentDidMount() {
		try {
			await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryModel);
			this.setState({
				libraryModel: this.actions.getFeatureItemById(this.libraryModelId, XyiconFeature.LibraryModel) || this.standard,
			});

			await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryImage);

			if (!CatalogGlyphs.defaultGlyphs) {
				const transport = this.props.transport;

				const {result} = await transport.requestForOrganization({url: "defaultimages/all"});

				const defaultGlyphs: IDefaultGlyphData[] = result.sort(
					(a: IDefaultGlyphData, b: IDefaultGlyphData) => a.imageData.length - b.imageData.length,
				);

				CatalogGlyphs.glyphs = [];

				const outerSVGs: string[] = [];

				for (const defaultGlyph of defaultGlyphs) {
					outerSVGs.push(defaultGlyph.imageData);
				}

				for (let i = 0; i < outerSVGs.length; ++i) {
					const outerSVG = outerSVGs[i].trim();
					const glyphData = defaultGlyphs[i];
					const indexOfSVGTagEnd = outerSVG.indexOf(">"); // closing bracket of opening tag <svg...`>`
					const innerSVG = outerSVG.substring(indexOfSVGTagEnd + 1, outerSVG.lastIndexOf("</svg>"));

					CatalogGlyphs.glyphs.push({
						innerPart: innerSVG,
						keywords: glyphData.keywords,
						defaultImageID: glyphData.defaultImageID,
					});
				}

				CatalogGlyphs.defaultGlyphs = defaultGlyphs;

				this._catalogItemEditorRef.current?.initState();
				this.forceUpdate();
			}

			if (!this.state.iconConfig?.defaultIcon && !this.state.iconConfig?.uploadedIcon && !this.state.iconConfig?.modelParameters) {
				await this.updateDefaultIconConfigAndThumbnail();
			}
		} catch (error) {
			console.warn(error);
		}
	}

	public override render() {
		const saveOrSavingLabel = this.state.isSaving ? "Saving..." : "Save";
		const nextLabel = this.state.stepIndex < this.steps.length - 1 ? "Next" : saveOrSavingLabel;
		const stepDetails = this.steps.map(this.getStepDetails);

		return (
			<div className="CatalogItemPanel SidePanel">
				<div className="heading hbox createBox">
					<h4 className="detailsTitle">{this.getTitle()}</h4>
					<IconButton
						icon="close"
						title="Close Panel"
						className="close"
						onClick={this.onCloseClick}
					/>
				</div>
				<StepLabel
					nextLabel={nextLabel}
					onBackClick={this.onBackClick}
					onNextClick={this.onNextClick}
					isNextButtonEnabled={this.isNextButtonEnabled()}
					isSaveButtonVisible={true}
					isSaveButtonEnabled={this.isSaveButtonEnabled()}
					onSaveClick={this.onSaveCatalogItemClick}
					saveLabel={saveOrSavingLabel}
					stepIndex={this.state.stepIndex}
					stepLabels={stepDetails}
				/>
				<StepIndicator
					onStepClick={this.onStepClick}
					currentStepIndex={this.state.stepIndex}
					steps={this.steps}
				/>
				{this.props.loading ? (
					<LoaderIcon />
				) : (
					<>
						{
							// We need to include this component to the dom, (and hide it when we're not at the "Customize model" step),
							// otherwise we can't create thumbnail from the model at other steps, so we can't save the catalog item properly
							this.state.libraryModel === this.standard
								? (this.state.iconConfig?.defaultIcon || this.state.iconConfig?.uploadedIcon) &&
									this.state.selectedType && (
										<CatalogItemEditor
											ref={this._catalogItemEditorRef}
											hidden={this.steps[this.state.stepIndex] !== "Customize model"}
											onSaveClick={this.onSaveIconConfig}
											iconConfig={this.state.iconConfig}
											catalog={this.props.catalog}
											selectedType={this.state.selectedType}
											updateParent={this.forceUpdateArrow}
										/>
									)
								: this.state.iconConfig?.modelParameters && (
										<MeshTweaker
											ref={this._meshTweakerRef}
											hidden={this.steps[this.state.stepIndex] !== "Customize model"}
											modelParameters={this.state.iconConfig.modelParameters}
											libraryModel={this.state.libraryModel}
											onColorChange={this.onBodyColorChange}
											onDimensionChange={this.onDimensionChange}
										/>
									)
						}
						{this.getActivePanel()}
					</>
				)}
			</div>
		);
	}
}

import {Shape, BufferGeometry, BufferAttribute, Vector2, Mesh, LineSegments, Color, BackSide} from "three";
import type {ExtrudeGeometryOptions} from "three/src/geometries/ExtrudeGeometry.js";
import {ExtrudeGeometry} from "three/src/geometries/ExtrudeGeometry.js";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {Constants} from "../Constants";
import {BasicMaterial} from "../materials/BasicMaterial";
import type {SpaceItemType} from "../managers/spaceitems/ItemManager";
import type {ColorRuleCategory} from "../../ui/viewbar/ColorRules";
import {IndicatorMaterial} from "../materials/IndicatorMaterial";
import {BoundaryCaption} from "../managers/MSDF/BoundaryCaption";
import {getBoundaryIndicatorScale} from "../renderers/SpaceViewRendererUtils";
import type {PointDouble} from "../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import {Type} from "../../../../../../data/models/Type";
import type {IBoundaryMinimumData} from "../../../../../../data/models/Boundary";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import type {GrabbableCorner} from "./GrabbableCorner";
import {EditableSpaceItem} from "./EditableSpaceItem";
import type {Xyicon3D} from "./Xyicon3D";
import {SpaceItem} from "./SpaceItem";
import type {SpaceItemOpacityValue} from "./SpaceItem";

/**
 * Representation of a boundaryspacemap in the 3D world
 * Boundaries can have multiple BoundarySpaceMaps
 */
export class BoundarySpaceMap3D extends EditableSpaceItem {
	// _intersectables contains the lines and the rotationicon
	private _caption: BoundaryCaption;
	private _shape: Shape = new Shape();
	private _extrudeGeometry: ExtrudeGeometry;
	private _extrudeGeometryOptions: ExtrudeGeometryOptions;
	private _mesh: Mesh;
	private _indicatorMaterial: IndicatorMaterial;
	private _indicatorMesh: Mesh;
	private _vertices: BufferAttribute;
	private _2dVectors: Vector2[] = [];
	private _lineGeometry: BufferGeometry = new BufferGeometry();
	private _lineSegments: LineSegments;
	private _boundaryTypeId: string;
	private _deleteVertexOnPointerUp: boolean = false;
	private _grabbedLine: {
		// contains 2 grabbableCorners (which are connected with the edge itself)
		index: number;
		mesh: Mesh;
		savedPosition: PointDouble;
	}[] = [];
	private _height: number;
	protected _color: number = 0xffffff; // will be overwritten in "initMeshes" with the proper color
	protected _modelData: BoundarySpaceMap;
	protected _spaceItemType: SpaceItemType = "boundary";
	private _typeName: string;
	private _fieldData: any = {};
	public static readonly fillOpacity: number = 0.1;

	constructor(spaceViewRenderer: SpaceViewRenderer, boundaryTypeId: string, fieldData: any = {}) {
		super(spaceViewRenderer);

		this._boundaryTypeId = boundaryTypeId;

		this._fieldData = fieldData;

		const appState = this._spaceViewRenderer.transport.appState;
		const type = appState.actions.getTypeById(this._boundaryTypeId);
		const heightRealLife = type?.settings.boundaryHeight || Type.defaultBoundaryHeight;
		const heightInMeters = heightRealLife.value * Constants.DISTANCE_UNITS[heightRealLife.unit].multiplicator;

		this._height = heightInMeters * appState.space.spaceUnitsPerMeter;

		this._extrudeGeometryOptions = {
			depth: this._height,
			bevelEnabled: false,
		};

		this._typeName = type?.name || "";
		this.initMeshes(type);

		this.onLayerSettingsModified();
		this.onFormattingRulesModified();
	}

	private initMeshes(type: Type) {
		this._color = parseInt(`0x${(type?.settings.color || Type.defaultColor).hex}`);

		this._meshMaterial = new BasicMaterial(this._color, BoundarySpaceMap3D.fillOpacity, true, false, BackSide);
		this._lineMaterial = new BasicMaterial(this._color, 1.0, true, true);

		this._mesh = new Mesh(this._spaceViewRenderer.planeGeometry, this._meshMaterial);

		this._lineSegments = new LineSegments(this._lineGeometry, this._lineMaterial);
		this._lineSegments.frustumCulled = false;
		THREEUtils.add(this._intersectables, this._lineSegments);
		THREEUtils.add(this._group, this._intersectables);
		THREEUtils.add(this._group, this._mesh);
		THREEUtils.add(this.itemManager.container, this._group);
		this._group.visible = false;
	}

	protected setFormattingColor(colorRuleCategory: ColorRuleCategory, colorHex: string) {
		const formatColor = colorHex != null ? parseInt(`0x${colorHex}`) : null;
		const isColorFormatValid = formatColor != null && this.itemManager.areFormattingRulesEnabled;

		if (colorRuleCategory === "highlight") {
			this._color = isColorFormatValid
				? formatColor
				: parseInt(`0x${(this._spaceViewRenderer.actions.getTypeById(this._boundaryTypeId)?.settings.color || Type.defaultColor).hex}`);
			this.setColor(this._color, this._isSelected ? SpaceItem.COLOR_INTENSITY.SELECTED : SpaceItem.COLOR_INTENSITY.DESELECTED);
		} // indicator
		else {
			if (isColorFormatValid) {
				const colorObject = {
					hex: colorHex,
					transparency: 0,
				};

				if (!this._indicatorMaterial) {
					this._indicatorMaterial = new IndicatorMaterial(colorObject);
					this._indicatorMesh = new Mesh(this._spaceViewRenderer.planeGeometry, this._indicatorMaterial);
					this._indicatorMesh.position.setZ(-1 * this._spaceViewRenderer.correctionMultiplier.current);
					const scale = getBoundaryIndicatorScale(this._spaceViewRenderer.correctionMultiplier.current);

					this._indicatorMesh.scale.set(scale, scale, scale);
				} else {
					this._indicatorMaterial.setColor(colorObject);
				}
				THREEUtils.addFront(this._group, this._indicatorMesh);
			} else {
				this._group.remove(this._indicatorMesh);
			}
		}

		this._spaceViewRenderer.needsRender = true;
	}

	public updateGeometry(geometryData: PointDouble[], isLocal: boolean = false) {
		this._group.visible = true;
		if (isLocal) {
			geometryData = THREEUtils.getRotatedVertices(geometryData, -this._lastSavedOrientation, {x: 0, y: 0});
			this._group.rotation.z = this._lastSavedOrientation;
			THREEUtils.updateMatrices(this._group);
		} else {
			this._worldGeometryData = geometryData;
		}

		const vertices = [];

		this._2dVectors.length = 0;
		for (let i = 0; i < geometryData.length; ++i) {
			const coordinate = geometryData[i];

			this._2dVectors.push(new Vector2(coordinate.x, coordinate.y));

			vertices.push(coordinate.x);
			vertices.push(coordinate.y);
			vertices.push(0);

			vertices.push(coordinate.x);
			vertices.push(coordinate.y);
			vertices.push(this._height);

			const nextCoordinate = i < geometryData.length - 1 ? geometryData[i + 1] : geometryData[0];

			vertices.push(coordinate.x);
			vertices.push(coordinate.y);
			vertices.push(this._height);

			vertices.push(nextCoordinate.x);
			vertices.push(nextCoordinate.y);
			vertices.push(this._height);

			vertices.push(coordinate.x);
			vertices.push(coordinate.y);
			vertices.push(0);

			vertices.push(nextCoordinate.x);
			vertices.push(nextCoordinate.y);
			vertices.push(0);
		}

		const cornerVertices = new Float32Array(vertices);

		this._vertices = new BufferAttribute(cornerVertices, 3);

		this._lineGeometry.dispose();
		this._lineGeometry = new BufferGeometry();
		this._lineGeometry.setAttribute("position", this._vertices);
		this._vertices.needsUpdate = true;
		this._lineSegments.geometry = this._lineGeometry;

		this._shape.curves.length = 0;
		this._shape.setFromPoints(this._2dVectors);
		this._extrudeGeometry?.dispose();
		this._extrudeGeometry = new ExtrudeGeometry(this._shape, this._extrudeGeometryOptions);
		this._mesh.geometry = this._extrudeGeometry;

		this.updateBoundingBox();
		this.onGeometryUpdated();
	}

	protected override onGeometryUpdated() {
		this._spaceViewRenderer.needsRender = true;
	}

	protected override destroyCallback(notifyServer: boolean = false, removeFromCollections: boolean = true) {
		super.destroyCallback(notifyServer, removeFromCollections);

		this._extrudeGeometry?.dispose();
		this._lineGeometry.dispose();
		this._meshMaterial.dispose();
		this._lineMaterial.dispose();
		this._2dVectors.length = 0;
	}

	protected override updateCenter(calledByStopRotation: boolean = false) {
		const localGeometryData = super.updateCenter(calledByStopRotation);

		this._lineSegments.frustumCulled = true;

		return localGeometryData;
	}

	protected override setOpacity(value: SpaceItemOpacityValue) {
		super.setOpacity(value);
		this._indicatorMaterial?.setOpacity((1 - IndicatorMaterial.TRANSPARENCY.indicator) * value);
	}

	protected setColor(color: number, intensity: number) {
		const colorObj = new Color(color);

		colorObj.multiplyScalar(intensity);

		const hex = colorObj.getHex();

		this._meshMaterial.setColor(hex);
		(this._lineMaterial as BasicMaterial).setColor(hex);

		this._spaceViewRenderer.needsRender = true;
	}

	protected setGrayScaled(value: boolean) {
		this._meshMaterial.setGrayScaled(value);
		(this._lineMaterial as BasicMaterial).setGrayScaled(value);

		this._spaceViewRenderer.needsRender = true;
	}

	private getNeighbours(index: number) {
		const leftNeighbourIndex = index > 0 ? index - 1 : this._worldGeometryData.length - 1;
		const rightNeighbourIndex = index < this._worldGeometryData.length - 1 ? index + 1 : 0;

		return {
			leftNeighbour: this._worldGeometryData[leftNeighbourIndex],
			rightNeighbour: this._worldGeometryData[rightNeighbourIndex],
		};
	}

	private getClosestNeighbour(index: number, coord: PointDouble) {
		const {leftNeighbour, rightNeighbour} = this.getNeighbours(index);

		const distanceToLeft = THREEUtils.calculateDistance([coord, leftNeighbour]);
		const distanceToRight = THREEUtils.calculateDistance([coord, rightNeighbour]);

		return distanceToLeft < distanceToRight
			? {
					neighbour: leftNeighbour,
					distance: distanceToLeft,
				}
			: {
					neighbour: rightNeighbour,
					distance: distanceToRight,
				};
	}

	private getSnappedCornerIfCloseEnough(grabbableCorner: PointDouble, index: number) {
		const {leftNeighbour, rightNeighbour} = this.getNeighbours(index);

		let snappedCorner = grabbableCorner;

		const projectedPoint = THREEUtils.projectPointToLineSegment(grabbableCorner, leftNeighbour, rightNeighbour);

		snappedCorner = THREEUtils.getSnappedPointIfCloseEnough(leftNeighbour, snappedCorner, this.itemManager.snapThreshold);
		snappedCorner = THREEUtils.getSnappedPointIfCloseEnough(rightNeighbour, snappedCorner, this.itemManager.snapThreshold);
		snappedCorner = THREEUtils.calculateDistance([projectedPoint, snappedCorner]) < this.itemManager.snapThreshold ? projectedPoint : snappedCorner;

		return snappedCorner;
	}

	public override onGrabbableCornerPointerDown(grabbableCorner: GrabbableCorner) {
		super.onGrabbableCornerPointerDown(grabbableCorner);
		if (this._isInEditMode) {
			this._deleteVertexOnPointerUp = false;
		}
	}

	public onGrabbableCornerPointerMove(deltaX: number, deltaY: number) {
		if (this._isInEditMode) {
			const index = this._indicesOfSelectedVertices[0];

			let grabbedCorner = {
				x: this._savedVertex.x + deltaX,
				y: this._savedVertex.y + deltaY,
			};

			if (this.itemManager.isSnapToAngleActive) {
				grabbedCorner = this.getSnappedCornerIfCloseEnough(grabbedCorner, index) as {x: number; y: number};
			}

			this._deleteVertexOnPointerUp = false;
			if (this._grabbableCorners.length > 3) {
				const closestNeighbourObject = this.getClosestNeighbour(index, grabbedCorner);

				if (closestNeighbourObject.distance < this.itemManager.snapThreshold) {
					grabbedCorner = closestNeighbourObject.neighbour as {x: number; y: number};
					this._deleteVertexOnPointerUp = true;
				}
			}

			this.setPositionOfVertexAtIndex(index, grabbedCorner.x, grabbedCorner.y);
			this.updateGeometry(this._worldGeometryData);
		}
	}

	public onGrabbableCornerPointerUp() {
		if (this._deleteVertexOnPointerUp) {
			const index = this._indicesOfSelectedVertices[0];

			this.deleteVertex(index);
			this._indicesOfSelectedVertices.length = 0;
		}
	}

	private deleteVertex(index: number) {
		if (this._isInEditMode) {
			if (-1 < index && index < this._worldGeometryData.length && this._worldGeometryData.length > 2) {
				this._worldGeometryData.splice(index, 1);

				this.updateGrabbableCorners();
				this.updateGeometry(this._worldGeometryData);

				this._spaceViewRenderer.needsRender = true;

				this.itemManager.updateSelectionBox();
				this._spaceViewRenderer.spaceItemController.updateActionBar();
			}
		}
	}

	public onLinePointerDown(index: number, worldX: number, worldY: number, createVertexHere: boolean) {
		if (this._isInEditMode) {
			if (createVertexHere) {
				const projectedPoint = this.itemManager.isSnapToAngleActive
					? THREEUtils.projectPointToLineSegment(
							{x: worldX, y: worldY},
							this._worldGeometryData[index],
							this._worldGeometryData[(index + 1) % this._worldGeometryData.length],
						)
					: {
							x: worldX,
							y: worldY,
						};

				this._worldGeometryData.splice(index + 1, 0, {
					x: projectedPoint.x - this._group.position.x,
					y: projectedPoint.y - this._group.position.y,
				});

				this.updateGeometry(this._worldGeometryData);
				this.updateGrabbableCorners();

				this._spaceViewRenderer.needsRender = true;

				return this._grabbableCorners[index + 1];
			} // grab
			else {
				this._indicesOfSelectedVertices.length = 0;
				this._grabbedLine.length = 0;
				this._grabbedLine.push({
					index: index,
					mesh: this._grabbableCorners[index].mesh,
					savedPosition: {
						x: this._worldGeometryData[index].x,
						y: this._worldGeometryData[index].y,
					},
				});

				const otherIndex = index >= this._grabbableCorners.length - 1 ? 0 : index + 1;

				this._grabbedLine.push({
					index: otherIndex,
					mesh: this._grabbableCorners[otherIndex].mesh,
					savedPosition: {
						x: this._worldGeometryData[otherIndex].x,
						y: this._worldGeometryData[otherIndex].y,
					},
				});

				this.resetFillColorOfGrabbableCorners();
				for (const grabbableCorner of this._grabbedLine) {
					this._indicesOfSelectedVertices.push(grabbableCorner.index);
					const material = grabbableCorner.mesh.material as BasicMaterial;
					const colorArray = material.color;
					const colorObj = new Color(colorArray[0], colorArray[1], colorArray[2]);

					colorObj.multiplyScalar(SpaceItem.COLOR_INTENSITY.SELECTED);
					material.setColor(colorObj.getHex());
					THREEUtils.renderToTop(grabbableCorner.mesh);
				}
				this._spaceViewRenderer.needsRender = true;
			}
		}
	}

	public onLinePointerMove(deltaX: number, deltaY: number) {
		if (this._isInEditMode) {
			this._deleteVertexOnPointerUp = false;
			if (this._grabbedLine.length === 2) {
				for (const grabbableCorner of this._grabbedLine) {
					const newX = grabbableCorner.savedPosition.x + deltaX;
					const newY = grabbableCorner.savedPosition.y + deltaY;

					this.setPositionOfVertexAtIndex(grabbableCorner.index, newX, newY);
				}
				this.updateGeometry(this._worldGeometryData);
			}
		}
	}

	public deleteSelectedVertex() {
		if (this._isInEditMode) {
			this._indicesOfSelectedVertices.sort();
			for (let i = this._indicesOfSelectedVertices.length - 1; i >= 0; --i) {
				const index = this._indicesOfSelectedVertices[i];

				this.deleteVertex(index);
			}

			this._indicesOfSelectedVertices.length = 0;
		}
	}

	public isPointInside(point: PointDouble) {
		return THREEUtils.isPointInsidePolygon(point, this._worldGeometryData);
	}

	/**
	 * Returns an array of XyiconIDs, which elements' positions have overlap with this boundaryspacemap
	 */
	public getXyiconsWithOverlap() {
		const xyicon3DArray: string[] = [];

		for (const xyicon3D of this._spaceViewRenderer.xyiconManager.items.array as Xyicon3D[]) {
			if (this.isPointInside(xyicon3D.position)) {
				xyicon3DArray.push(xyicon3D.modelData.id);
			}
		}

		return xyicon3DArray;
	}

	public getRelatedBoundaries(type: "parent" | "child") {
		const isTypeParent = type === "parent";
		const boundarySpaceMap3DArray = [];

		for (const boundarySpaceMap3D of this.itemManager.items.array as BoundarySpaceMap3D[]) {
			if (boundarySpaceMap3D !== this && boundarySpaceMap3D.modelData?.id) {
				let child = this._worldGeometryData;
				let parent = boundarySpaceMap3D._worldGeometryData;

				if (!isTypeParent) {
					[child, parent] = [parent, child];
				}
				if (THREEUtils.isPolygonInsidePolygon(child, parent)) {
					boundarySpaceMap3DArray.push(boundarySpaceMap3D);
				}
			}
		}

		return boundarySpaceMap3DArray;
	}

	public override switchEditMode(enabled: boolean, updateBackendOnFinish: boolean) {
		const hasChanged = super.switchEditMode(enabled, updateBackendOnFinish);

		if (this._isInEditMode) {
			this.hideCaption();
		} else {
			this.showCaption();
		}

		if (hasChanged) {
			this.itemManager.captionManager.updateTextTransformations();
		}

		return hasChanged;
	}

	public addCaption(captionFields: IFieldAdapter[]) {
		if (!this._caption) {
			this._caption = new BoundaryCaption(this);
		}
		const captionFieldValues = this.getCaptionFieldValues(captionFields, this._modelData.parent);

		this._caption.updateText(captionFieldValues);

		if (captionFieldValues.length === 0) {
			this.removeCaption();
		}

		this._spaceViewRenderer.needsRender = true;
	}

	public hideCaption() {
		this._caption?.hide();
	}

	public showCaption() {
		this._caption?.show();
	}

	private removeCaption() {
		this._caption?.updateText([]);
		this._caption = null;
	}

	public override setVisibility(visible: boolean) {
		super.setVisibility(visible);
		if (visible) {
			this._caption?.show();
		} else {
			this._caption?.hide();
		}
	}

	public get dimensionX() {
		return this.scale.x;
	}

	public get dimensionY() {
		return this.scale.y;
	}

	public get dimensionZ() {
		return this._height;
	}

	public get conditionalFormattingElements() {
		return {
			indicatorMesh: this._indicatorMesh,
			indicatorMaterial: this._indicatorMaterial,
		};
	}

	public get color() {
		return this._color;
	}

	public get isValid() {
		return !this.isDestroyed && this._2dVectors.length > 2 && this.area > 0;
	}

	public get area(): number {
		return THREEUtils.calculateArea(this._worldGeometryData);
	}

	public get position() {
		if (this._isInEditMode) {
			const bbox = THREEUtils.calculateBox(this.unrotatedGeometryData);

			return {
				...THREEUtils.getCenterOfBoundingBox(bbox),
				z: this._group.position.z,
			};
		}

		return {
			x: this._group.position.x,
			y: this._group.position.y,
			z: this._group.position.z,
		};
	}

	public get orientation() {
		return this._group.rotation.z;
	}

	public override get data(): IBoundaryMinimumData {
		return {
			id: this._modelData?.id,
			boundaryTypeId: this._boundaryTypeId,
			geometryData: this._worldGeometryData,
			orientation: this.orientation,
			fieldData: this._fieldData,
		};
	}

	public get type() {
		return this._typeName;
	}

	public get caption() {
		return this._caption;
	}

	public get itemManager() {
		return this._spaceViewRenderer.boundaryManager;
	}
}

import type {IMultiSelectFieldSettingsDefinition} from "../../../../../data/models/field/datatypes/MultiSelect";
import type {IInputPropsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import {MultiSelectInputV5} from "./MultiSelectInputV5";

export const MultiInputV5 = (props: IInputPropsV5<IMultiSelectFieldSettingsDefinition>) => {
	const {dataTypeSettings, onChange, onClick, disabled, onFocusLossForceBlur, inline, expand, focused} = props;
	let {value} = props;

	if (!Array.isArray(value)) {
		value = [];
	}

	return (
		<MultiSelectInputV5
			options={dataTypeSettings.choiceList}
			selected={value}
			onChange={onChange}
			onClick={onClick}
			disabled={disabled}
			onFocusLossForceBlur={onFocusLossForceBlur}
			inline={inline}
			expand={expand}
			focused={focused}
		/>
	);
};

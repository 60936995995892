import * as React from "react";
import {inject, observer} from "mobx-react";
import {SVGIcon} from "../button/SVGIcon";
import {cleanViewFolderStructureFromAdditionalProps, rootFolderId} from "../../../data/models/ViewUtils";
import {SearchField} from "../input/search/SearchField";
import {MultiActionButton} from "../button/multiaction/MultiActionButton";
import {View} from "../../../data/models/View";
import type {AppState} from "../../../data/state/AppState";
import {MathUtils} from "../../../utils/math/MathUtils";
import {FocusLoss} from "../../../utils/ui/focus/FocusLoss";
import {TimeUtils} from "../../../utils/TimeUtils";
import {ViewPreferenceCategory} from "../../../generated/api/base";
import type {ViewItem} from "./ViewItem";
import {ViewFolderStructureReact} from "./ViewFolderStructureReact";

interface IViewSelectProps {
	appState?: AppState;
	selected: View;
	onShareClick: (viewId: string) => void;
	onSelect: (view: string) => void;
}

interface IViewSelectState {
	isOpen: boolean;
	search: string;
}

@inject("appState")
@observer
export class ViewSelect extends React.PureComponent<IViewSelectProps, IViewSelectState> {
	private _timeoutId: number = -1;
	private _isFocusListening: boolean = false;
	private _container = React.createRef<HTMLDivElement>();
	private _list = React.createRef<HTMLDivElement>();
	private _lastElementInList = React.createRef<ViewItem>();

	constructor(props: IViewSelectProps) {
		super(props);

		this.state = {
			isOpen: false,
			search: "",
		};
	}

	private onToggleOpen = () => {
		this.setState((state) => ({isOpen: !state.isOpen}));
	};

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private onNewFolderClick = async () => {
		this.viewFolderStructure.push({
			id: MathUtils.getNewRandomGUID(),
			category: ViewPreferenceCategory.Folder,
			name: "New Folder",
			isOpen: false,
			children: [],
		});
		await TimeUtils.waitForNextFrame();
		this.onElementAddedToList();
		this.saveViewFolderStructureToDatabase();
	};

	private onNewViewClick = async () => {
		const baseName = "New View";
		const view = View.createNew(this.props.selected.itemFeature, baseName, this.props.appState);

		const actions = this.props.appState.actions;
		let counter = 1;

		while (!actions.isNameValidForView(view.name, view.itemFeature, view.id)) {
			view.name = `${baseName} (${counter++})`;
		}

		const {result: viewData} = await this.props.appState.app.transport.services.view.create(view.getData(), this.props.selected.itemFeature);

		this.onElementAddedToList();
		this.saveViewFolderStructureToDatabase();
		// Select the newly created view
		this.props.appState.actions.selectViewById(viewData.viewID);
	};

	private saveViewFolderStructureToDatabase = () => {
		const transport = this.props.appState.app.transport;
		const selectedView = this.props.selected;

		if (transport && selectedView) {
			const viewFolderStructures = this.props.appState.user?.getViewFolderStructureForFeature(selectedView.itemFeature);

			if (viewFolderStructures) {
				const cleanedViewFolderStructure = cleanViewFolderStructureFromAdditionalProps(viewFolderStructures);

				// This function gets called by all levels of the ViewFolderStructureReact component
				// Eg.: if you move a view from a folder to root (or vica versa), this will get called once
				// from within the "folder" component (which modifies only the items within the folder),
				// then almost immediately the parent (root) element receives the item (from the folder),
				// so it gets called again. We don't want to save the first one to the database, as it's not an
				// in-between state: the item has been already moved out from the folder, but it's not present in the
				// root yet.
				// So for these scenarios, I applied this little timeout hack below...
				clearTimeout(this._timeoutId);
				this._timeoutId = window.setTimeout(() => {
					transport.saveViewFolderStructure(selectedView.itemFeature, cleanedViewFolderStructure);
				}, 50);
			}
		}
	};

	private onElementAddedToList = async () => {
		await TimeUtils.waitForNextFrame();
		await TimeUtils.waitForNextFrame();
		this._list.current?.scrollTo({top: Number.MAX_SAFE_INTEGER, behavior: "smooth"});

		// Hack to enter edit mode with the newly created item
		(this._lastElementInList.current as ViewItem)?.setState?.({
			isInEditMode: true,
		});
	};

	private onSelectView = async (viewId: string) => {
		const {appState, onSelect} = this.props;

		onSelect(viewId);
		await TimeUtils.waitForNextFrame(); // needed both to display loader when other view is selected
		await TimeUtils.waitForNextFrame();
		appState.actions.selectViewById(viewId);
		onSelect("");
	};

	private onFocusLoss = (event: Event) => {
		if (this.state.isOpen || this.state.search) {
			requestAnimationFrame(() => {
				if (this._isFocusListening) {
					this.setState({
						isOpen: false,
						search: "",
					});
				}
			});
		}

		return false;
	};

	private get viewFolderStructure() {
		return this.props.appState.user?.getViewFolderStructureForFeature(this.props.selected.itemFeature) || [];
	}

	private startFocusListen = () => {
		FocusLoss.listen(this._container.current, this.onFocusLoss, undefined, "up");
		this._isFocusListening = true;
	};

	private stopFocusListen = () => {
		FocusLoss.stopListen(this._container.current, this.onFocusLoss);
		this._isFocusListening = false;
	};

	public override componentDidMount() {
		this.startFocusListen();
	}

	public override componentWillUnmount() {
		this.stopFocusListen();
	}

	public override render() {
		const {selected} = this.props;
		const {viewFolderStructure} = this;

		const buttonOptions = [
			{
				id: "view",
				label: "Add New View",
				onClick: this.onNewViewClick,
			},
			{
				id: "folder",
				label: "Add New Folder",
				onClick: this.onNewFolderClick,
				icon: "addFolder",
			},
		];

		return (
			<div
				className="ViewSelect"
				ref={this._container}
			>
				<div
					className="selected"
					onClick={this.onToggleOpen}
				>
					{selected.name ?? ""}
					<SVGIcon icon="down" />
				</div>
				{this.state.isOpen && (
					<div className="content">
						<div className="header hbox alignCenter">
							<SearchField
								placeholder="Find..."
								value={this.state.search}
								onInput={this.onSearchInput}
								className="naked"
							/>
							<div className="flex_1" />
							<MultiActionButton
								options={buttonOptions}
								icon="add"
								className="secondary"
							/>
						</div>
						<div
							className="list overflowYAuto"
							ref={this._list}
						>
							<ViewFolderStructureReact
								level={0}
								viewFolder={{id: rootFolderId, name: "", category: ViewPreferenceCategory.Folder, children: viewFolderStructure, isOpen: true}}
								selectedViewId={selected.id}
								onShareClick={this.props.onShareClick}
								startFocusListen={this.startFocusListen}
								stopFocusListen={this.stopFocusListen}
								onElementAddedToList={this.onElementAddedToList}
								lastElementInList={this._lastElementInList}
								saveViewFolderStructureToDatabase={this.saveViewFolderStructureToDatabase}
								searchString={this.state.search}
								closeViewSelect={this.onToggleOpen}
								onSelect={this.onSelectView}
							/>
							{viewFolderStructure.length === 0 && (
								<>
									<div className="empty hbox flex_1 alignCenter">No views to display</div>
									<div className="emptyArea" />
								</>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

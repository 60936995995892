import type {ReactElement} from "react";
import {cloneElement, useState} from "react";
import type {IToolConfig, SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {Color} from "../../../../../generated/api/base";
import type {IToolButtonProps} from "./ToolButtonV5";
import {SharedToolGroupV5} from "./SharedToolGroupV5";

interface IToolGroupProps {
	tools: {
		[id in SpaceTool]: IToolConfig;
	};
	activeTool: SpaceTool;
	onClick: (id: SpaceTool) => void;
	children: ReactElement<IToolButtonProps>[];
	color?: Color;
}

export const ToolGroupV5 = (props: IToolGroupProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [chosenTool, setChosenTool] = useState<SpaceTool>(props.children[0].props.icon as SpaceTool);

	const subMenuItems = props.children.map((container: React.ReactElement<IToolButtonProps>, index: number) => {
		const onClick = () => {
			container.props.onClick();
			setIsOpen(false);
			setChosenTool(container.props.icon as SpaceTool);
		};

		return cloneElement(container, {key: `${container.props.icon}${container.props.title}`, onClick: onClick});
	});

	return (
		<SharedToolGroupV5
			tools={props.tools}
			activeTool={props.activeTool}
			chosenTool={chosenTool}
			onClick={props.onClick}
			color={props.color}
		>
			{subMenuItems}
		</SharedToolGroupV5>
	);
};

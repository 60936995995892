import type {IModel} from "../Model";
import type {AppState} from "../../state/AppState";
import type {IFieldPointer} from "../field/Field";
import {XyiconFeature} from "../../../generated/api/base";
import type {FilterOperator} from "./operator/FilterOperator";
import {LogicalSeparator} from "./LogicalSeparator";
import {FilterOperators} from "./operator/FilterOperators";
import type {IFilterOperatorConfig} from "./operator/FilterOperators";

export interface IFilter {
	field: IFieldPointer;
	operator: FilterOperator;
	param: IFilterParam;
}

export type IFilterParam = any;

export type FilterType = "simple" | "advanced";

export interface IFilterState {
	type: FilterType;
	filters: {
		simple: IFilterRow[];
		advanced: IFilterRow[];
	};
}

export type IFilterRow =
	| IFilterRowFilter
	| {
			type: "separator";
			value: LogicalSeparator;
	  };

export type IFilterRowFilter = {
	type: "filter";
	value: IFilter;
};

export function createFilterState(): IFilterState {
	const state: IFilterState = {
		type: "simple",
		filters: {
			simple: [],
			advanced: [],
		},
	};

	return state;
}

export function findOrGroups(filters: IFilterRow[]) {
	const orGroups: IFilterRowFilter[][] = [[]];

	for (const filter of filters) {
		if (filter.type === "separator" && filter.value === LogicalSeparator.OR) {
			orGroups.push([]);
		} else if (filter.type === "filter") {
			// we're ignoring "and" separators within or groups
			orGroups[orGroups.length - 1].push(filter);
		}
	}

	return orGroups;
}

export function filterModels<T extends IModel>(models: T[], state: IFilterState, appState: AppState, feature?: XyiconFeature) {
	const activeFilters = state.type === "simple" ? state.filters.simple : state.filters.advanced;

	if (activeFilters.length === 0) {
		return models;
	}

	const orGroups = findOrGroups(activeFilters);

	return models.filter((model) => {
		outer: for (const orGroup of orGroups) {
			for (const filter of orGroup) {
				// these are AND filters -> if any one fails, this orGroup fails
				if (filter.type === "filter") {
					// ignoring the operators
					const match = matchFilter(model, filter, appState, feature);

					if (!match) {
						// this filter fails -> this orGroup fails -> go to the next one
						continue outer;
					}
				}
			}
			// All AND filters succeeded -> this OR group succeeds -> this model matches the filter
			return true;
		}
		// All OR groups failed -> this model doesn't match the filter
		return false;
	});

	// Previously grouping by AND groups:
	// const andGroups = findOrGroups(activeFilters);
	// return models.filter(model => {
	// 	outer: for (const andGroup of andGroups)
	// 	{
	// 		for (const filter of andGroup)
	// 		{
	// 			// these are OR filters -> if any one succeeds, this andGroup succeeds
	// 			if (filter.type === "filter")
	// 			{
	// 				const match = matchFilter(model, filter, appState, feature);
	// 				if (match)
	// 				{
	// 					// andGroup succeeds
	// 					continue outer;
	// 				}
	// 			}
	// 		}
	// 		// All OR filters failed -> this AND group is false -> this model fails the filter
	// 		return false;
	// 	}
	// 	return true;
	// });
}

function matchFilter(model: IModel, filter: IFilterRowFilter, appState: AppState, feature?: XyiconFeature): boolean {
	const f = filter.value;
	const config: IFilterOperatorConfig = FilterOperators.map[f.operator];

	if (!config?.method) {
		return false;
	}
	const field = appState.actions.getFieldByRefId(f.field);

	{
		// If we're in SpaceEditor and the filter is a xyicon field,
		// all boundaries should match that filter, we don't want to filter out boundaries in this case:
		// https://hub.xyicon.com/docs/en/filters
		if (feature === XyiconFeature.SpaceEditor) {
			if (model.ownFeature === XyiconFeature.Boundary) {
				if (field?.feature === XyiconFeature.Xyicon) {
					return true;
				}
			}

			// As per #2369: https://dev.azure.com/xyicon/SpaceRunner%20V4/_sprints/taskboard/Product/SpaceRunner%20V4/2021%20November/S-79?workitem=2369
			// We don't want filter for boundary types to affect xyicons at all
			if (model.ownFeature === XyiconFeature.Xyicon) {
				if (field?.feature === XyiconFeature.Boundary && field.refId === `${XyiconFeature.Boundary}/type`) {
					return true;
				}
			}
		}
	}

	if (!field) {
		// field has been removed since -> consider this filter to pass successfully
		return true;
	}
	const values = appState.actions.filterActions.getFilterValues(model, f.field);

	// values can be "" too if eg. fieldData[f35] is not defined yet
	// (Note: we could return empty array from getFilterValues)
	if (values.some) {
		// If values is an empty array check blank match separately
		if (values?.length === 0) {
			const hasBlankMatch = config.method("", f.param, field); // TODO blank

			if (hasBlankMatch) {
				return true;
			}
		} else {
			// Result is true if at least one of the values matches the method
			const result = values.some((v) => config.method(v, f.param, field));

			if (result) {
				return true;
			}
		}
	}

	return false;
}

import {observable, computed, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {SpaceFileDto, SpaceFileInsertionInfo, SpaceFileTileMode} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import type {IModel} from "./Model";
import type {Space} from "./Space";
import type {SpaceVersion} from "./SpaceVersion";

export class SpaceFile implements IModel {
	private _parent: Space;
	private _appState: AppState;
	private _isInsertionInfoModified: boolean = false;

	@observable
	private _data: SpaceFileDto;

	constructor(data: SpaceFileDto, parent: Space, appState: AppState) {
		makeObservable(this);
		this._parent = parent;
		this._appState = appState;
		this.applyData(data);
	}

	public applyData(data: SpaceFileDto) {
		this._data = data;

		if (!this._data.settings?.insertionInfo) {
			this._data.settings = {
				insertionInfo: {
					offsetX: 0,
					offsetY: 0,
					offsetZ: 0,
					width: Constants.DEFAULT_SPACE_WIDTH,
				},
			};
		}

		if (!this._data.settings.insertionInfo.offsetZ) {
			this._data.settings.insertionInfo.offsetZ = 0;
		}
	}

	public setInsertionInfo(insertionInfo: SpaceFileInsertionInfo) {
		this._data.settings.insertionInfo = {...insertionInfo};
		this._isInsertionInfoModified = true;
	}

	@computed
	public get id() {
		return this._data.spaceFileID;
	}

	@computed
	public get thumbnailFileURL() {
		// in case it's not from the server, it's a generated URL (with canvas.toDataURL)
		return this._data.thumbnailFile.substring(this._data.sourceFile.length - 4) === ".png"
			? this._appState.app.transport.getFullPathFromServer(`spaces/thumbnails/${this._data.thumbnailFile}`)
			: this._data.thumbnailFile;
	}

	@computed
	public get sourceFileURL() {
		// in case it's not from the server, it's a generated URL (with URL.createObjectURL)
		return this._data.sourceFile.substring(this._data.sourceFile.length - 4) === ".pdf"
			? this._appState.app.transport.getFullPathFromServer(`spaces/files/${this._data.sourceFile}`)
			: this._data.sourceFile;
	}

	@computed
	public get spaceVersionId() {
		return this._data.spaceVersionID;
	}

	public set spaceVersionId(value: string) {
		this._data.spaceVersionID = value;
	}

	@computed
	public get spaceVersion() {
		return this._appState.actions.getFeatureItemById(this.spaceVersionId, XyiconFeature.SpaceVersion) as SpaceVersion;
	}

	public set spaceVersion(value: SpaceVersion) {
		this.spaceVersion = value;
	}

	@computed
	public get spaceVersionName() {
		return this.spaceVersion?.name || "";
	}

	@computed
	public get insertionInfo() {
		return this._data.settings.insertionInfo;
	}

	@computed
	public get parent() {
		return this._parent;
	}

	public get isInsertionInfoModified() {
		return this._isInsertionInfoModified;
	}

	@computed
	public get mode(): SpaceFileTileMode {
		return this._data.mode;
	}

	@computed
	public get tileSetId() {
		return this._data.tileSetID;
	}

	@computed
	public get data() {
		return this._data;
	}
}

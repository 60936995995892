import styled from "styled-components";
import {useState} from "react";
import {ELLIPSIS, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {Space} from "../../../../../data/models/Space";
import {DoubleClickGesture} from "../../../../../utils/interaction/gestures/DoubleClickGesture";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import type {SpaceFile} from "../../../../../data/models/SpaceFile";
import {DropdownButtonStyled} from "../../../interaction/DropdownButtonV5";
import {SpaceVersionSelectorV5} from "../SpaceVersionSelectorV5";

type TopRight = "Details" | "VersionSelector";

interface ISpaceCardProps {
	isSelected: boolean;
	space: Space;
	onClick: (space: Space) => void;
	topRight?: TopRight;
	onDoubleClick?: (space: Space) => void;
}

export const SpaceCard = (props: ISpaceCardProps) => {
	const {space, isSelected, onClick, topRight = "Details", onDoubleClick} = props;
	const [timeStamp, setTimeStamp] = useState<number>(-Infinity);

	const onVersionClick = (spaceFile: SpaceFile) => {
		space.setSelectedSpaceFile(spaceFile);
	};

	return (
		<SpaceItemStyled
			$active={isSelected}
			onClick={() => {
				const timeStampNow = window.performance.now();
				const dt = MathUtils.isValidNumber(timeStamp) ? timeStampNow - timeStamp : Infinity;

				setTimeStamp(timeStampNow);

				onClick(space);

				if (isSelected && DoubleClickGesture.defaultConfig.threshold > dt) {
					onDoubleClick?.(space);
				}
			}}
		>
			<SpaceItemHeader $active={isSelected}>
				<SpaceItemName title={space.name}>{space.name}</SpaceItemName>
				<SpaceItemHeaderDetails>
					{topRight === "Details" ? (
						<>
							<div>ID: {space.refId}</div>
							<div title={space.typeName}>{space.typeName}</div>
						</>
					) : (
						<SpaceVersionSelectorV5
							onVersionClick={onVersionClick}
							space={space}
						/>
					)}
				</SpaceItemHeaderDetails>
			</SpaceItemHeader>
			<SpaceItemBackground $imageUrl={space.thumbnailFileURL} />
		</SpaceItemStyled>
	);
};

const SpaceItemHeader = styled.div<{$active: boolean}>`
	height: 48px;
	border-radius: ${radius.md} ${radius.md} 0 0;
	padding: 8px;
	gap: 8px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => (props.$active ? colorPalette.primary.c200Light : colorPalette.gray.c100)};
	color: ${(props) => (props.$active ? colorPalette.primary.c500Primary : "")};
`;

const SpaceItemStyled = styled.div<{$active: boolean}>`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	box-shadow: 0px 2px 4px 0px #00000080;
	border-radius: ${radius.md};

	${DropdownButtonStyled} {
		padding: 0 4px;
		justify-content: flex-end;
		&:not(.isOpen) {
			display: none;
		}
	}

	&:hover {
		${DropdownButtonStyled} {
			display: flex;
		}
		${SpaceItemHeader} {
			background-color: ${(props) => (props.$active ? colorPalette.primary.c200Light : colorPalette.gray.c200Light)};
		}
	}
`;

const SpaceItemName = styled.div`
	flex: 1;
	font-size: 16px;
	line-height: 24px;
	overflow: hidden;
	white-space: initial;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow-wrap: break-word;
`;

const SpaceItemHeaderDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	font-size: 12px;
	white-space: nowrap;
	height: 32px;
	max-width: 102px;

	> div {
		width: 100%;
		${ELLIPSIS}
	}
`;

const SpaceItemBackground = styled.div<{$imageUrl: string}>`
	background: url(${(props) => props.$imageUrl});
	width: 250px;
	height: 150px;
	margin: auto auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
`;

import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {IBasicPropsFor3DTweaking} from "../../../../modules/space/SpaceView";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import type {Type} from "../../../../../data/models/Type";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {InfoBubble} from "../../../../modules/abstract/common/infobutton/InfoBubble";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";

interface IBoundaryTypeContainerProps extends IBasicPropsFor3DTweaking {
	onBoundaryTypeClick: (typeId: string) => void;
	appState?: AppState;
}

interface IBoundaryTypeContainerState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	content: React.ReactNode;
}

@inject("appState")
export class BoundaryTypeContainerV5 extends React.Component<IBoundaryTypeContainerProps, IBoundaryTypeContainerState> {
	private _parents: HTMLDivElement[] = [];
	private _floating = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;
	private _currentIndex: number = -1;

	constructor(props: IBoundaryTypeContainerProps) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
			content: "",
		};
	}

	private openTooltip = (event: React.MouseEvent, hasPermission: boolean, index: number, name: string) => {
		const isOverflowing = event.currentTarget.scrollWidth > event.currentTarget.clientWidth;

		if (!hasPermission || isOverflowing) {
			if (this._timeOutId) {
				clearTimeout(this._timeOutId);
			}

			this._timeOutId = window.setTimeout(() => {
				this._currentIndex = index;
				this.setState({
					isToolTipOpen: true,
					content: !hasPermission ? (
						<>
							<SVGIcon icon="locked" />
							<div>You do not have permission to use this boundary.</div>
						</>
					) : (
						name
					),
				});
			}, 1000);
		}
	};

	private closeTooltip = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isToolTipOpen: false,
		});
	};

	private getBoundaryList = () => {
		const {actions} = this.props.appState;

		return actions
			.getTypesByFeature(XyiconFeature.Boundary)
			.slice()
			.filter((a: Type) => actions.getModuleTypePermission(a.id, a.feature) !== Permission.None)
			.sort((a: Type, b: Type) => {
				const aHasPermission = actions.getModuleTypePermission(a.id, a.feature) >= Permission.Update;
				const bHasPermission = actions.getModuleTypePermission(b.id, b.feature) >= Permission.Update;

				if (aHasPermission && !bHasPermission) {
					return -1;
				} else if (!aHasPermission && bHasPermission) {
					return 1;
				} else {
					return StringUtils.sortIgnoreCase(a.name, b.name);
				}
			});
	};

	private onClick = (id: string, hasPermission: boolean) => {
		if (hasPermission) {
			this.props.onBoundaryTypeClick(id);
			this.forceUpdate();
		}
	};

	private get modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	public override componentDidUpdate(prevProps: IBoundaryTypeContainerProps, prevState: IBoundaryTypeContainerState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parents[this._currentIndex] && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parents[this._currentIndex],
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.left,
				),
			});
		}
	}

	public override componentWillUnmount() {
		clearTimeout(this._timeOutId);
	}

	public override render() {
		const {spaceViewRenderer} = this.props;
		const {isToolTipOpen, toolTipTransform, content} = this.state;

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		const boundaryTypeList = this.getBoundaryList();

		return (
			<BoundaryTypeContainerStyled className="BoundaryTypeContainer vbox">
				{boundaryTypeList.map((type: Type, index: number) => {
					const currentTypeId = spaceViewRenderer.boundaryManager.typeId;
					const typeId = type.id;
					const currentPermission = spaceViewRenderer.actions.getModuleTypePermission(typeId, XyiconFeature.Boundary);
					const hasPermission = currentPermission >= Permission.Update;

					return (
						<BoundaryTypeRow
							ref={(divRef) => (this._parents[index] = divRef)}
							className={ReactUtils.cls({active: typeId === currentTypeId, noPermission: !hasPermission})}
							onClick={() => this.onClick(typeId, hasPermission)}
							onMouseOver={(event) => this.openTooltip(event, hasPermission, index, type.name)}
							onMouseLeave={this.closeTooltip}
							key={type.id}
						>
							<ColorIconWrapper>
								<ColorIcon $color={type.settings.color.hex} />
							</ColorIconWrapper>
							<BoundaryTypeName>{type.name}</BoundaryTypeName>
						</BoundaryTypeRow>
					);
				})}
				{isToolTipOpen && (
					<DomPortal destination={this.modalContainer}>
						<InfoBubble
							divRef={this._floating}
							content={content}
							style={inlineStyle}
							className={ReactUtils.cls("SpaceItem", {nameToolTip: typeof content === "string"})}
						/>
					</DomPortal>
				)}
			</BoundaryTypeContainerStyled>
		);
	}
}

const ColorIconWrapper = styled.div`
	min-width: 24px;
	height: 24px;
	background-color: ${colorPalette.white};
	position: relative;
	border-radius: 100%;
`;

const ColorIcon = styled.div<{$color: string}>`
	width: 100%;
	height: 100%;
	border-radius: 100%;
	border: 2px solid #${(props) => props.$color};
	background-color: #${(props) => props.$color}22;
`;

const BoundaryTypeRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-radius: ${radius.sm};
	min-height: 32px;
	cursor: pointer;
	gap: 8px;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.active {
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
	}

	&.noPermission {
		cursor: not-allowed;
		opacity: 0.5;
		padding: 5px;
		pointer-events: none;
	}
`;

const BoundaryTypeName = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const BoundaryTypeContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: calc(100vh - 135px);
	gap: 8px;
	padding: 0 16px 16px;
`;

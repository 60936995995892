import * as React from "react";
import {inject, observer} from "mobx-react";
import {SelectInput} from "../../../../widgets/input/select/SelectInput";
import {Permission} from "../../../../../generated/api/base";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";

interface IShareOptionProps {
	value: number;
	onChange: (value: number) => void;
	hasOwner: boolean;
	hasEdit?: boolean;
	disabled?: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class ShareOption extends React.Component<IShareOptionProps> {
	public static OWNER = -9;

	private getOptions() {
		const options = [];

		if (this.props.hasOwner) {
			options.push({
				id: ShareOption.OWNER,
				label: "Owner",
				icon: "crown",
			});
		}

		if (this.props.hasEdit !== false) {
			options.push({
				id: Permission.Update,
				label: "Can Edit",
				icon: "user-edit",
			});
		}

		options.push({
			id: Permission.View,
			label: "Can View",
			icon: "user-read",
		});

		return options;
	}

	public override render() {
		const {value, disabled, onChange} = this.props;
		const options = this.getOptions();

		return (
			<SelectInput
				className="ShareOption"
				selected={options.find((o) => o.id === value)}
				onChange={(option) => onChange(option.id)}
				options={options}
				disabled={disabled}
				render={(option) => {
					return (
						<div className="hbox alignCenter shareOptionItem">
							<SVGIcon icon={option.icon} />
							<div className="label flex_1">{option.label}</div>
							{option.id === value && (
								<SVGIcon
									icon="checkmark"
									classNames="checkmark"
								/>
							)}
						</div>
					);
				}}
			/>
		);
	}
}

import type {PortTemplateDto} from "../../../../generated/api/base";

export const DRAGGABLE_ID_SEPARATOR = "-guid-";
export const FULL_LIST = "-inFullList";
export const PORTS_LAYOUT = "port-section-layout";

export class Port implements PortTemplateDto {
	private _collection: Port[]; // the whole collection of ports
	private _label: string = "";
	private _isReadOnly: boolean = false;
	private _parent: Port;
	private _children: Port[] = [];

	constructor(collection: Port[], parent: Port) {
		this._collection = collection;
		this._parent = parent;
	}

	public setLabel(label: string) {
		this._label = label;
	}

	public setReadOnly(value: boolean) {
		this._isReadOnly = value;
	}

	public get children() {
		return this._children;
	}

	public get isReadOnly() {
		return this._isReadOnly;
	}

	public get label() {
		return this._label;
	}

	public get id() {
		let port: Port = this;
		let arrayOfPorts = port._parent ? port._parent._children : this._collection;
		let idString = "";

		while (arrayOfPorts) {
			const index = arrayOfPorts.indexOf(port);

			idString = `${index}.${idString}`;

			port = port._parent;
			if (arrayOfPorts === this._collection || port === null) {
				arrayOfPorts = null;
			} else {
				arrayOfPorts = port._parent ? port._parent._children : this._collection;
			}
		}

		idString = idString.substring(0, idString.length - 1); // remove the last dot

		return idString;
	}

	public static getPortById(ports: (PortTemplateDto | Port)[], id: string): PortTemplateDto | Port | null {
		const arrayOfIndices = id.split(".");

		let port: Port | PortTemplateDto = ports[parseInt(arrayOfIndices[0])];

		for (let i = 1; i < arrayOfIndices.length; ++i) {
			port = port?.children[parseInt(arrayOfIndices[i])] || null;
		}

		return port;
	}

	public static getFullLabelById(ports: PortTemplateDto[], id: string): string[] {
		const arrayOfIndices = id.split(".");

		let port: Port | PortTemplateDto = ports[parseInt(arrayOfIndices[0])];
		const labels: string[] = [port.label];

		for (let i = 1; i < arrayOfIndices.length; ++i) {
			port = port.children[parseInt(arrayOfIndices[i])];
			labels.push(port.label);
		}

		return labels;
	}

	// Returns each PortTemplateDto as an element of an array
	public static getPortsAsArray(ports: PortTemplateDto[]): PortTemplateDto[] {
		const portArray: PortTemplateDto[] = [];

		const traverse = (ports: PortTemplateDto[]): void => {
			for (const port of ports) {
				portArray.push(port);
				traverse(port.children);
			}
		};

		traverse(ports);

		return portArray;
	}

	public static lastChildHasChild(ports: PortTemplateDto[]) {
		return ports.length > 0 ? ports[ports.length - 1].children.length > 0 : false;
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {DomPortal} from "../../portal/DomPortal";
import {FocusLoss} from "../../../../../utils/ui/focus/FocusLoss";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import type {Color} from "../../../../../generated/api/base";
import type {App} from "../../../../../App";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import type {IPropsForEyeDropper} from "./colorwindow/EyeDropper";
import type {IHSLColor} from "./colorwindow/advancedpanel/AdvancedColorPanel";
import {ColorWindow} from "./colorwindow/ColorWindow";

interface ColorSelectorProps {
	app?: App;
	title: string;
	color: Color;
	onColorChange: (newColor: Color) => void;
	classNames?: string;
	label?: string;
	isTransparencyEnabled: boolean;
	horizontalAlignment?: HorizontalAlignment;
	verticalAlignment?: VerticalAlignment;
	offsetY?: number;
	offsetX?: number;
	eyeDropperProps?: IPropsForEyeDropper;
	icon?: "paint-bucket" | "text-color";
	closeWindowOnSelect?: boolean;
	outerDivRef?: React.RefObject<HTMLDivElement>;
	changeAlignmentOnPositionCorrection?: boolean;
}

interface ColorSelectorState {
	isWindowOpen: boolean;
	activeColor: IHSLColor; // store the HSL values instead, because HSL-Hex conversion is injective, but the other way is not
}

@inject("app")
export class ColorSelector extends React.Component<ColorSelectorProps, ColorSelectorState> {
	private _isMounted: boolean = false;

	public static defaultProps: Partial<ColorSelectorProps> = {
		isTransparencyEnabled: true,
		horizontalAlignment: HorizontalAlignment.right,
		verticalAlignment: VerticalAlignment.bottom,
		offsetY: 0,
		offsetX: 0,
	};

	private _element = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _colorWindowRef = React.createRef<ColorWindow>();
	private _isEyeDropperActive: boolean;
	private _requestAnimationFrameId: number = -1;

	constructor(props: ColorSelectorProps) {
		super(props);
		this._isEyeDropperActive = false;

		const hsl = ColorUtils.hex2hsl(props.color.hex);

		this.state = {
			isWindowOpen: false,
			activeColor: {
				hue: hsl.h,
				saturation: hsl.s,
				lightness: hsl.l,
				transparency: props.color.transparency,
			},
		};
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: ColorSelectorProps) {
		const {activeColor} = this.state;
		const activeHex = ColorUtils.hsl2hex(activeColor.hue, activeColor.saturation, activeColor.lightness);

		if (nextProps.color.hex !== activeHex || nextProps.color.transparency !== activeColor.transparency) {
			const hsl = ColorUtils.hex2hsl(nextProps.color.hex);
			const newActiveColor = {
				hue: hsl.h,
				saturation: hsl.s,
				lightness: hsl.l,
				transparency: nextProps.color.transparency,
			};

			this.setState({
				activeColor: newActiveColor,
			});
		}
	}

	private onBlur = (event: Event) => {
		let clickedOutsideEyeDropperArea = true;

		if (this.props.eyeDropperProps) {
			const eyeDropperAreaElement = this.props.eyeDropperProps.elementForPointerEvents || this.props.eyeDropperProps.canvas;

			if (!(event.target instanceof Window)) {
				clickedOutsideEyeDropperArea = eyeDropperAreaElement ? !eyeDropperAreaElement.contains(event.target as HTMLElement) : true;
			}
		}

		if (!this._isEyeDropperActive || clickedOutsideEyeDropperArea) {
			requestAnimationFrame(() => this.closeWindow());
		}

		return false;
	};

	private closeWindow = () => {
		if (this._isMounted) {
			FocusLoss.stopListen(this._floating.current, this.onBlur);
			this.setState({
				isWindowOpen: false,
			});
		}
	};

	private onWindowToggle = () => {
		this.setState((prevState) => {
			const newValue = !prevState.isWindowOpen;

			if (newValue) {
				cancelAnimationFrame(this._requestAnimationFrameId);
				this._requestAnimationFrameId = requestAnimationFrame(() => {
					if (this._floating.current) {
						FocusLoss.listen(this._floating.current, this.onBlur, undefined, "up");
					}
				});
			} else {
				FocusLoss.stopListen(this._floating.current, this.onBlur);
			}

			return {
				isWindowOpen: newValue,
			};
		});
	};

	private onColorChange = (newColor: IHSLColor) => {
		this.setState({
			activeColor: newColor,
		});

		this.onDispatchColorChange(newColor);

		if (this.props.closeWindowOnSelect && this._colorWindowRef.current?.state.activeMode === "basic" && !this._isEyeDropperActive) {
			this.closeWindow();
		}
	};

	private onDispatchColorChange = (newColor: IHSLColor) => {
		const hex = this.colorToHex(newColor);

		this.props.onColorChange({
			hex: hex,
			transparency: newColor.transparency,
		});
	};

	private colorToHex(color: IHSLColor = this.state.activeColor) {
		return ColorUtils.hsl2hex(color.hue, color.saturation, color.lightness) as string;
	}

	private colorToRGB(color: IHSLColor = this.state.activeColor) {
		return ColorUtils.hsl2rgb(color.hue, color.saturation, color.lightness);
	}

	private onEyeDropperStateChanged = (active: boolean) => {
		this._isEyeDropperActive = active;
		this.props.eyeDropperProps.onActivateStateChanged(active);
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	public override componentDidMount() {
		this._isMounted = true;
		this.props.app.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.forceUpdateArrow);
		window.addEventListener("resize", this.forceUpdateArrow);
	}

	public override componentDidUpdate = () => {
		const {verticalAlignment, horizontalAlignment, changeAlignmentOnPositionCorrection, offsetX, offsetY, outerDivRef} = this.props;

		if (this._element.current && this._floating.current) {
			const newTransformObject = DomUtils.getFixedFloatingElementPosition(
				outerDivRef?.current || this._element.current,
				this._floating.current,
				verticalAlignment || VerticalAlignment.top,
				horizontalAlignment || HorizontalAlignment.outerRight,
				offsetY,
				offsetX,
				changeAlignmentOnPositionCorrection,
			);

			const colorWindowStyle: Partial<CSSStyleDeclaration> = {
				display: "",
			};

			if (!this.state.isWindowOpen) {
				colorWindowStyle.display = "none";
			} else {
				let {x, y} = newTransformObject;

				if (this.props.horizontalAlignment === HorizontalAlignment.right) {
					const parentSize = this._element.current.getBoundingClientRect();

					x += parentSize.width;
				}
				colorWindowStyle.transform = `translate(${x}px, ${y}px)`;
			}

			if (this._floating.current.style.transform !== colorWindowStyle.transform) {
				this._floating.current.style.transform = colorWindowStyle.transform;
			}

			if (this._floating.current.style.display !== colorWindowStyle.display) {
				this._floating.current.style.display = colorWindowStyle.display;
			}
		}
	};

	public override componentWillUnmount() {
		this._isMounted = false;
		FocusLoss.stopListen(this._floating.current, this.onBlur);
		this.props.app.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.forceUpdateArrow);
		window.removeEventListener("resize", this.forceUpdateArrow);
	}

	public override render() {
		const {label, title, icon, app, isTransparencyEnabled, classNames, eyeDropperProps} = this.props;
		const {isWindowOpen, activeColor} = this.state;

		const color = activeColor;
		const RGB = this.colorToRGB();
		const colorRGBAString = `rgba(${RGB.r}, ${RGB.g}, ${RGB.b}, ${1 - color.transparency})`;
		const indicatorStyle: React.CSSProperties = {
			backgroundColor: colorRGBAString,
		};
		const iconStyle: React.CSSProperties = {
			color: colorRGBAString,
			fill: colorRGBAString,
		};

		const eyeDropper: IPropsForEyeDropper = eyeDropperProps
			? {
					canvas: eyeDropperProps.canvas,
					elementForPointerEvents: eyeDropperProps.elementForPointerEvents,
					textureNeedsUpdateSignal: eyeDropperProps.textureNeedsUpdateSignal,
					onActivateStateChanged: this.onEyeDropperStateChanged,
				}
			: undefined;

		const cls = ReactUtils.cls(`ColorSelector ${classNames || ""}`, {
			labeled: label != null,
		});

		return (
			<div
				ref={this._element}
				className={cls}
				data-label={label}
				title={title}
			>
				<span
					className="innerSpan"
					onClick={this.onWindowToggle}
				>
					{icon ? (
						<SVGIcon
							icon={icon}
							style={iconStyle}
						/>
					) : (
						<div className="colorWindowToggler">
							<div className="colorIndicatorBG" />
							<div
								className="colorIndicator"
								style={indicatorStyle}
							/>
						</div>
					)}
				</span>
				{isWindowOpen && (
					<DomPortal destination={app.modalContainer}>
						<ColorWindow
							divRef={this._floating}
							onParentUpdate={this.forceUpdateArrow}
							onColorChange={this.onColorChange}
							isTransparencyEnabled={isTransparencyEnabled}
							colorHSL={activeColor}
							eyeDropperProps={eyeDropper}
							ref={this._colorWindowRef}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {FieldDataTypes} from "../../../../data/models/field/FieldDataTypes";
import type {IFilter} from "../../../../data/models/filter/Filter";
import type {FilterOperator} from "../../../../data/models/filter/operator/FilterOperator";
import type {IFilterOperatorConfig} from "../../../../data/models/filter/operator/FilterOperators";
import {FilterOperators} from "../../../../data/models/filter/operator/FilterOperators";
import type {AppState} from "../../../../data/state/AppState";
import {FieldDataType} from "../../../../generated/api/base";
import type {XyiconFeature} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import LinkIcon from "../../icons/link.svg?react";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import {AdvancedFilterField} from "./AdvancedFilterField.styled";

interface IAdvancedFilterFieldProps {
	fieldRefIds: IFieldPointer[];
	filter: IFilter;
	features: XyiconFeature[];
	onChangeField: (field: IFieldPointer) => void;
	onChangeOperator: (operator: FilterOperator) => void;
	onChangeParams: (params: any[]) => void;
	onRemove: () => void;
	operatorFilter?: (operator: FilterOperator) => boolean;
	appState?: AppState;
	linkedFields?: boolean;
	children: React.ReactNode;
	isReport?: boolean;
}

interface IAdvancedFilterFieldState {
	focus: boolean;
}

@inject("appState")
@observer
export class AdvancedFilterFieldV5 extends React.PureComponent<IAdvancedFilterFieldProps, IAdvancedFilterFieldState> {
	constructor(props: IAdvancedFilterFieldProps) {
		super(props);
		this.state = {
			focus: false,
		};
	}

	private renderField = (fieldRefId: string) => {
		const {appState, linkedFields} = this.props;

		return (
			<div className="hbox alignCenter filterField">
				<span>{appState.actions.getFieldTitle(fieldRefId)}</span>
				{linkedFields && (
					<LinkIcon
						color={"#9CD99E"}
						className="linkIcon"
					/>
				)}
			</div>
		);
	};

	private onFocus = (value: boolean) => {
		this.setState({focus: value});
	};

	public override render() {
		const {onRemove, appState, filter, fieldRefIds, onChangeField, onChangeOperator, onChangeParams, operatorFilter} = this.props;

		const {focus} = this.state;

		const selectedFieldRefId = fieldRefIds.includes(filter.field) ? filter.field : fieldRefIds[0];
		const selectedField = appState.actions.getFieldByRefId(selectedFieldRefId);

		//const fields        = this.getFields();
		//const selectedField = fields.find(field => field.refId === filter.field) || fields[0];

		let operators = FieldDataTypes.map[selectedField.dataType].operators(selectedField.dataTypeSettings);

		if (operatorFilter) {
			operators = operators.filter(operatorFilter);
		}

		const operator = FilterOperators.map[filter.operator] as IFilterOperatorConfig;
		const ParamControl = operator ? operator.control : null;

		return (
			<AdvancedFilterField className={ReactUtils.cls("AdvancedFilterField hbox", {focus})}>
				<div className="criterias">
					<div className="header">
						{this.props.children}
						<IconButton
							title="Delete"
							icon="close"
							className="delete"
							onClick={onRemove}
						/>
					</div>
					<SelectInputV5
						sort={true}
						options={fieldRefIds}
						selected={selectedFieldRefId}
						onChange={(fieldRefId) => onChangeField(fieldRefId)}
						render={this.renderField}
						onFocus={this.onFocus}
						stringifyOption={(fieldRefId) => appState.actions.getFieldTitle(fieldRefId)}
						className="SelectInput"
					/>
					<SelectInputV5
						options={operators}
						selected={filter.operator}
						onChange={(operator) => onChangeOperator(operator)}
						className="SelectInput"
						render={(operation) => {
							const op = FilterOperators.map[operation];
							let label = op.label;

							if (selectedField.dataType === FieldDataType.Boolean) {
								label = appState.actions.getBooleanFieldCustomLabelSettingsByFilterOperator(selectedField, op.id);
							}

							return label;
						}}
						onFocus={this.onFocus}
					/>
					{ParamControl && (
						<ParamControl
							param={filter.param}
							onChange={onChangeParams}
							onFocus={this.onFocus}
							field={selectedField}
						/>
					)}
				</div>
				{this.props.isReport && (
					<div className="deleteColumn">
						<IconButton
							title="Delete"
							icon="close"
							className="delete"
							onClick={onRemove}
						/>
					</div>
				)}
			</AdvancedFilterField>
		);
	}
}

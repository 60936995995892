import * as React from "react";
import type {IDistanceUnit, DistanceUnitName} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldDataType} from "../../../../generated/api/base";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {IRealSize} from "../../../modules/settings/modules/type/form/RealSizeInput";
import {Field} from "../../../../data/models/field/Field";
import {UnitSelector} from "./UnitSelector";
import {ClickToEditInput} from "./ClickToEditInput";

interface IClickToEditDistanceProps {
	layoutType: "vbox" | "hbox";
	value: number;
	unit: IDistanceUnit;
	onChange: (size: IRealSize) => void;
	validateValue: (value: number) => boolean;
	optionsZIndex?: number;
}

interface IClickToEditDistanceState {
	errorMessage: string;
}

export class ClickToEditDistance extends React.Component<IClickToEditDistanceProps, IClickToEditDistanceState> {
	public static defaultProps: Partial<IClickToEditDistanceProps> = {
		validateValue: (value: number) => value > 0,
		layoutType: "vbox",
	};

	constructor(props: IClickToEditDistanceProps) {
		super(props);

		this.state = {
			errorMessage: null,
		};
	}

	private isValueValid(value: number) {
		return MathUtils.isValidNumber(value) && this.props.validateValue(value);
	}

	private onValueChange = (value: number) => {
		if (this.isValueValid(value)) {
			this.props.onChange({
				value: value,
				unit: this.props.unit.name,
			});
		}
	};

	private onValueLiveChange = (value: number) => {
		this.setState({
			errorMessage: this.isValueValid(value) ? "" : "Please provide a valid number",
		});
	};

	public override render() {
		return (
			<div className={`ClickToEditDistance ${this.props.layoutType}`}>
				<ClickToEditInput
					dataTypeSettings={Field.defaultSettings[FieldDataType.Numeric]}
					dataType={FieldDataType.Numeric}
					value={this.props.value}
					onChange={this.onValueChange}
					onLiveChange={this.onValueLiveChange}
					errorMessage={this.state.errorMessage}
					noButtons={true}
				/>
				<UnitSelector
					unit={this.props.unit.name}
					onChange={(unitName: DistanceUnitName) => this.props.onChange({value: this.props.value, unit: unitName})}
					optionsZIndex={this.props.optionsZIndex}
				/>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {TableView} from "../../../abstract/TableView";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import {CreatePortfolioGroupPanel} from "./CreatePortfolioGroupPanel";
import {PortfolioGroupDetailsTab} from "./PortfolioGroupDetailsTab";

interface IPortfolioGroupsViewProps {
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class PortfolioGroupsView extends React.Component<IPortfolioGroupsViewProps> {
	private _headers = [
		{
			id: "name",
			title: "Group Name",
		},
		{
			id: "portfolioIds",
			title: "Number of portfolios",
		},
		{
			id: "lastModifiedAt",
			title: "Last Updated",
		},
		{
			id: "lastModifiedBy",
			title: "Updated By",
		},
	];

	public override render() {
		return (
			<TableView
				feature={XyiconFeature.PortfolioGroup}
				headers={this._headers}
				create={(onCreated, onCancel, creating) => (
					<CreatePortfolioGroupPanel
						onCancel={onCancel}
						onCreated={onCreated}
						creating={creating}
					/>
				)}
				details={(selected: PortfolioGroup[]) => <PortfolioGroupDetailsTab portfolioGroups={selected} />}
			/>
		);
	}
}

import {PointerDetector} from "../PointerDetector";
import {Signal} from "../../signal/Signal";
import type {Pointer} from "../Pointer";
import {ObjectUtils} from "../../data/ObjectUtils";
import type {IGesture} from "./IGesture";
import type {IPinchZoomGestureData} from "./PinchZoomGesture";
import {PinchZoomGesture} from "./PinchZoomGesture";

interface IGestureDetectorConfig {
	pointerDetector?: PointerDetector;
	element?: HTMLElement;
	parent?: HTMLElement;
	clickDistanceTolerance?: number; // -1 means any distance is tolerated
	disableContextMenu?: boolean;
	longTap?: {
		enabled: boolean;
		timeout: number;
	};
}

/**
 * Manages a a few simple gestures in a way that each always
 * completes a full event cycle (start, update, end) without another event
 * interleaving that cycle, eg.:
 * - possible: pinch start, [update,] end, pan start, [update,] end
 * - not possible: pinch start, pan start, etc.. (this is resolved by ending pinch first)
 *
 * So pinch start is always followed by pinch end (or updates in between),
 * but never a pan start, they are not interwoven.
 *
 * TODO incorporate longTap from webutils/src/GestureDetector
 * TODO convert to Signals
 *
 */
export class PanAndZoomGestures {
	public static defaultConfig: IGestureDetectorConfig = {
		clickDistanceTolerance: 3,
		disableContextMenu: false,
		longTap: {
			enabled: false,
			timeout: 2000,
		},
	};

	public signals = {
		pan: {
			start: Signal.create<Pointer, IGesture>(),
			update: Signal.create<Pointer>(),
			end: Signal.create<Pointer, IGesture, boolean>(),
		},
		click: Signal.create<Pointer>(), // aka tap
		longClick: Signal.create<Pointer>(),
	};

	private _config: IGestureDetectorConfig;

	private _pointerDetector: PointerDetector;

	private _panPointer: Pointer;
	private _panStarted = false;

	// aka tap
	private _longClickTimeout = -1;
	private _longClicked = false;

	private _isWithinClickTolerance = true;

	//private _multiDrag: utils.MultiDragGesture;

	private _pinchZoom: PinchZoomGesture;

	constructor(config: IGestureDetectorConfig) {
		config = ObjectUtils.mergeConfig(PanAndZoomGestures.defaultConfig, config);
		this._config = config;

		this._pointerDetector =
			config.pointerDetector ||
			new PointerDetector({
				element: config.element,
				parent: config.parent,
				maxPointers: 3,
				disableContextMenu: !!config.disableContextMenu,
			});

		this.init();
	}

	private init() {
		this._pointerDetector.signals.down.add(this.onPointerDown);
		this._pointerDetector.signals.move.add(this.onPointerMove);
		this._pointerDetector.signals.up.add(this.onPointerUp);

		this._pinchZoom = new PinchZoomGesture(this._pointerDetector);
		this._pinchZoom.signals.start.add(this.onStartPinchZoom);
		this._pinchZoom.signals.end.add(this.onEndPinchZoom);
		this._pinchZoom.listen();

		//this._multiDrag = new utils.MultiDragGesture(this._pointerDetector);
		//this._multiDrag.pan.add(this.onPan3);
	}

	// --------------------------------------------------------------------------------------------------
	// Pointer events

	private onPointerDown = (pointer: Pointer) => {
		if (pointer.isRightClick) {
			return;
		}

		// first pointer down -> start pan
		this.tryPanStart();

		this._longClicked = false;
		if (this._config.longTap.enabled) {
			clearTimeout(this._longClickTimeout);
			this._longClickTimeout = window.setTimeout(() => {
				this.onLongTap(pointer);
			}, this._config.longTap.timeout);
		}
	};

	private onLongTap(pointer: Pointer) {
		this._longClickTimeout = -1;

		this._longClicked = true;
		this.signals.longClick.dispatch(pointer);
	}

	private onPointerMove = (pointer: Pointer) => {
		if (pointer.isRightClick) {
			return;
		}

		// If 1 pointer is down -> update
		if (this._pointerDetector.pointersLength === 1 && this._panStarted) {
			if (!this.pointerIsWithinClickTolerance(pointer)) {
				// Once we're out of click tolerance, we're out until a new pointer is pressed down
				this._isWithinClickTolerance = false;
				clearTimeout(this._longClickTimeout);
			}

			if (this._isWithinClickTolerance) {
				// don't rotate or move if a click is still possible
				return;
			}

			this.signals.pan.update.dispatch(pointer);
		}
	};

	private onPointerUp = (pointer: Pointer) => {
		// this runs whenever a pointer is released:
		// A. when panning and that single finger is released
		// B. before pinch zoom completes (before onEndPinchZoom)

		if (this._pointerDetector.pointersLength === 0 && this._panStarted) {
			this.finishPan();
		}

		// Note: is it good here?
		clearTimeout(this._longClickTimeout);
	};

	private tryPanStart(startingGesture?: IGesture) {
		if (this._pointerDetector.pointersLength === 1 && !this._panStarted) {
			this._panStarted = true;
			this._panPointer = this._pointerDetector.pointerArray[0];

			// We need to reset dx, dy, offsetX, offsetY, because this is
			// considered a new pan start gesture (dispatched here manually).
			// Otherwise these properties may have big values, if the user
			// moved his fingers during pinching, which would cause some jumpingsure

			this._panPointer.dx = 0;
			this._panPointer.dy = 0;
			this._panPointer.offsetX = 0;
			this._panPointer.offsetY = 0;
			this._panPointer.startX = this._panPointer.localX;
			this._panPointer.startY = this._panPointer.localY;

			// Only reset the flag if the panstart is not initiated by a finished pinch zoom.
			if (!startingGesture) {
				this._isWithinClickTolerance = true;
			}

			this.signals.pan.start.dispatch(this._panPointer, startingGesture);
		}
	}

	private finishPan(breakingGesture: IGesture = null) {
		if (this._panStarted) {
			this._panStarted = false;

			const click = this.testClick(this._panPointer, breakingGesture);

			this.signals.pan.end.dispatch(this._panPointer, breakingGesture, click);

			if (click && !this._longClicked) {
				this.signals.click.dispatch(this._panPointer);
			}

			this._panPointer = null;
		}
	}

	// --------------------------------------------------------------------------------------------------
	// Click

	private testClick(pointer: Pointer, breakingGesture: IGesture): boolean {
		// If there is a breaking gesture it means the pan end didn't occur because there was a click
		// but only because another gesture started (example: pan end called when pinch zoom is started),
		// so in that case we don't want to dispatch a click.

		if (!breakingGesture && this._isWithinClickTolerance) {
			return true;
		}

		return false;
	}

	private pointerIsWithinClickTolerance(pointer: Pointer): boolean {
		if (this._config.clickDistanceTolerance < 0) {
			return true;
		}

		const dist = Math.sqrt(pointer.offsetX * pointer.offsetX + pointer.offsetY * pointer.offsetY);

		return dist < this._config.clickDistanceTolerance;
	}

	// --------------------------------------------------------------------------------------------------
	// Pinch zoom events

	private onStartPinchZoom = (zoomData: IPinchZoomGestureData) => {
		// Stop pan if it's in progress
		if (this._panStarted) {
			this.finishPan(this._pinchZoom);
		}

		// If a pinch zoom has started, make sure click will not trigger, only next time the user starts a touch
		this._isWithinClickTolerance = false;
	};

	private onEndPinchZoom = (zoomData: IPinchZoomGestureData) => {
		// Check if a pan needs to be started
		// (when switching from 2 fingers to 1)

		this.tryPanStart(this._pinchZoom);
	};

	//private onPan3(data: IMultiDragGestureData)
	//{
	//
	//}

	// --------------------------------------------------------------------------------------------------
	// Getters

	public get panPointer() {
		return this._panPointer;
	}

	public get isWithinClickTolerance() {
		return this._isWithinClickTolerance;
	}

	public get pinchZoom() {
		return this._pinchZoom;
	}

	public get pointerDetector() {
		return this._pointerDetector;
	}
}

import styled from "styled-components";
import {colorPalette} from "../../styles/colorPalette";
import {radius} from "../../styles/styles";

export const NewFeatures = () => {
	const featuresList = [
		{text: "Dashboard Tips", destination: ""},
		{text: "Workspace Tips", destination: ""},
		{text: "List View Tips", destination: ""},
		{text: "Space Tips", destination: ""},
		{text: "Learn More About Views", destination: ""},
	];

	return (
		<NewFeaturesStyled>
			<TitleStyled>What's New</TitleStyled>

			<ListStyled>
				{featuresList.map((item, index) => (
					<div key={index}>
						<ListItemStyled>
							<a href={item.destination}>{item.text}</a>
						</ListItemStyled>
					</div>
				))}
			</ListStyled>
		</NewFeaturesStyled>
	);
};

export const ListItemStyled = styled.div`
	padding: 8px;
	text-decoration-line: underline;
	text-underline-position: under;
	font-size: 16px;
	line-height: 24px;
	color: ${colorPalette.gray.c950};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};
	}

	a {
		color: inherit;
	}
`;

export const ListStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

export const TitleStyled = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	color: ${colorPalette.primary.c500Primary};
`;

export const NewFeaturesStyled = styled.div`
	height: 100%;
	width: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

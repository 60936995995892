import {styled} from "styled-components";
import {Observer} from "mobx-react";
import {UserProfileV5} from "../user/UserProfileV5";
import {FlexCenterStyle, TOPBAR_HEIGHT} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import {ProjectNameV5} from "./ProjectNameV5";
import {ViewTabsV5} from "./ViewTabsV5";
import {MainSearchV5} from "./MainSearchV5";

const TopBarStyled = styled.div`
	${FlexCenterStyle};
	justify-content: space-between;
	position: relative;
	min-height: ${TOPBAR_HEIGHT};
	background-color: white;
	border-bottom: 1px solid #c8c8c8;
	padding: 16px;
	z-index: 11;
`;

const TopBarActionsStyled = styled.div`
	${FlexCenterStyle};
	gap: 16px;

	&:only-of-type {
		margin-left: auto;
	}
`;

export const TopBarV5 = () => {
	const navBarState = useAppStore((state) => state.navBarState);
	const appState = useAppStore((state) => state.appState);

	return (
		<Observer>
			{() => {
				const isInSettingsView = ["settings"].includes(appState.selectedMenu);

				return (
					<TopBarStyled>
						{navBarState !== "docked" && <ProjectNameV5 />}
						<ViewTabsV5 />
						<TopBarActionsStyled>
							{!isInSettingsView && <MainSearchV5 />}
							<UserProfileV5 />
						</TopBarActionsStyled>
					</TopBarStyled>
				);
			}}
		</Observer>
	);
};

import type {Vector2} from "three";
import type {PointDouble} from "../../../../../../../../generated/api/base";
import type {MarkupABDimensions, ICornerLetter} from "./MarkupAB";
import {MarkupAB} from "./MarkupAB";

export abstract class MarkupAB2D extends MarkupAB {
	protected _dimensions: MarkupABDimensions = 2;
	protected _vertices: number[] = [];
	protected _2dVectors: Vector2[] = [];

	protected override updateGrabbableCorners(vertices: PointDouble[] = this.get4CornersAsWorldVertices(), recreateThem: boolean = true) {
		return super.updateGrabbableCorners(vertices, recreateThem);
	}

	protected override updateBoundingBox(dataToCalculateFrom: PointDouble[] = this.get4CornersAsWorldVertices()) {
		return super.updateBoundingBox(dataToCalculateFrom);
	}

	protected processAB(A: PointDouble, B: PointDouble, isLocal: boolean, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		return this.process2DAB(A, B, isLocal, keepAspectRatio, fixedPoint);
	}
}

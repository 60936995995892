import {NoResultViewStyled} from "./NoResultStyled";

interface INoResultFilterViewProps {}

export const NoResultFilterViewV5 = (props: INoResultFilterViewProps) => {
	return (
		<NoResultViewStyled className="message">
			No results found.
			<br />
			Try clearing a few filters to see results.
		</NoResultViewStyled>
	);
};

import * as React from "react";
import {Slider} from "../../slider/Slider";

interface IHueSliderProps {
	value: number;
	labeled?: boolean;
	onValueChange: (newValue: number) => void;
	sliderWidth?: number;
}

/**
 * HueSlider's "value" property's range: [0 - 360]
 */
export class HueSlider extends React.Component<IHueSliderProps> {
	private onValueChange = (newValue: number) => {
		this.props.onValueChange(newValue * 360);
	};

	public override render() {
		return (
			<Slider
				value={this.props.value / 360}
				width={this.props.sliderWidth || 136}
				classNames="Hue"
				onValueChange={this.onValueChange}
				largeStepValue={0.1}
			/>
		);
	}
}

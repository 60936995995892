import * as React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import type {Field as FieldModel, IFormattingRule} from "../../../../../../data/models/field/Field";
import type {IFilterOperatorConfig} from "../../../../../../data/models/filter/operator/FilterOperators";
import {FilterOperators} from "../../../../../../data/models/filter/operator/FilterOperators";
import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../../../generated/api/base";
import {FilterOperator} from "../../../../../../data/models/filter/operator/FilterOperator";
import {VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {ColorSelectorV5} from "../../../../colors/ColorSelectorV5";
import {FieldV5} from "../../../../details/FieldV5";
import {SelectInputV5} from "../../../../input/select/SelectInputV5";
import {IconButtonV5} from "../../../../interaction/IconButtonV5";
import Delete from "../../../../icons/delete.svg?react";
import type {IBooleanFieldSettingsDefinition} from "../../../../details/BooleanFieldSettingsV5";

interface ITextFormattingSettingsProps {
	field: FieldModel;
	onChange: () => void;
	onAddButtonClick?: () => void;
}

@observer
export class FormattingSettingsV5 extends React.Component<ITextFormattingSettingsProps> {
	private onRemoveRule = (rule: IFormattingRule) => {
		const {field, onChange} = this.props;
		const rules = field.formattingRules;

		if (rules) {
			const index = rules.indexOf(rule);

			if (index >= 0) {
				rules.splice(index, 1);
			}
		}

		onChange();
	};

	private getOperators = (field: FieldModel) => {
		// remove IS_ANY_OF and IS_NOT_ANY_OF operators
		const operators = FieldDataTypes.map[field.dataType].operators;

		return operators(field.dataTypeSettings).filter((o) => ![FilterOperator.IS_ANY_OF, FilterOperator.IS_NOT_ANY_OF].includes(o));
	};

	public override render() {
		const {field, onChange} = this.props;
		const rules = field.formattingRules || [];

		const isBoolean = field.dataType === FieldDataType.Boolean;
		const hasAddButton = !isBoolean || rules.length < 2;

		return (
			<FormattingSettingsContainerStyled className="FormattingSettings">
				{rules.map((rule, index) => {
					const color = rule.color || "00ff00";
					const ParamControl = (FilterOperators.map[rule.operator] as IFilterOperatorConfig)?.control;

					return (
						<div
							key={index}
							className="FormattingRule"
						>
							<FieldV5 label="When text">
								<SelectInputV5
									options={this.getOperators(field)}
									selected={rule.operator}
									onChange={(operator) => {
										rule.operator = operator;
										onChange();
									}}
									render={(operation) => {
										let label = "";

										if (isBoolean) {
											const dataTypeSettings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

											switch (operation) {
												case FilterOperator.IS_TRUE:
													label = dataTypeSettings.displayLabelForTrue;
													break;
												case FilterOperator.IS_FALSE:
													label = dataTypeSettings.displayLabelForFalse;
													break;
											}
										}

										return label || FilterOperators.map[operation].label;
									}}
								/>
								{ParamControl && (
									<ParamControl
										param={rule.params}
										onChange={(value) => {
											rule.params = value;
											onChange();
										}}
									/>
								)}
							</FieldV5>
							<FieldV5 label="Set color to">
								<ColorSelectorV5
									title="Color"
									color={{hex: color, transparency: 0}}
									onColorChange={(color) => {
										rule.color = color.hex;
										onChange();
									}}
									isTransparencyEnabled={false}
									verticalAlignment={VerticalAlignment.top}
								/>
							</FieldV5>
							<IconButtonV5
								title="Delete"
								IconComponent={Delete}
								className="delete"
								onClick={(e) => this.onRemoveRule(rule)}
							/>
						</div>
					);
				})}
			</FormattingSettingsContainerStyled>
		);
	}
}

const FormattingSettingsContainerStyled = styled.div``;

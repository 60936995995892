import * as React from "react";

import {TextInput} from "../../../text/TextInput";
import {IconButton} from "../../../../button/IconButton";
import type {IInputProps} from "../../FieldInput";

export const SingleLineInput = (props: IInputProps) => {
	const {value, onInput, onCancel, onApply, onBlur, disabled, noButtons, inputType, caretPosition} = props;

	return (
		<div className="hbox field-input-container">
			<TextInput
				value={value}
				onInput={onInput}
				onBlur={onBlur}
				autoFocus={true}
				disabled={disabled}
				caretPosition={caretPosition}
				inputType={inputType}
			/>
			{!disabled && !noButtons && (
				<>
					<IconButton
						className="apply"
						icon="apply"
						title="Apply"
						onClick={onApply}
					/>
					<IconButton
						className="cancel"
						icon="cancel"
						title="Cancel"
						onClick={onCancel}
					/>
				</>
			)}
		</div>
	);
};

import {inject, observer} from "mobx-react";
import * as React from "react";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {FieldV5} from "../../../details/FieldV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {FieldDataType, XyiconFeature} from "../../../../../generated/api/base";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {DetailsTabV5HeaderStyles} from "../../../details/DetailsTabV5HeaderStyles";
import {FlexCenter} from "../../../styles/styles";
import InfoIcon from "../../../icons/circle-information.svg?react";
import {UserGroupDetailsTabStyled} from "../usergroups/UserGroupDetailsTabV5";
import {NodataUserDetailsTabStyled, NoDataStyled} from "../users/UserDetailsTabV5";
import {PortfolioGroupPortfoliosV5} from "./PortfolioGroupPortfoliosV5";

interface IPortfolioGroupDetailsTabProps {
	portfolioGroups: PortfolioGroup[];
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class PortfolioGroupDetailsTabV5 extends React.Component<IPortfolioGroupDetailsTabProps> {
	constructor(props: IPortfolioGroupDetailsTabProps) {
		super(props);
	}

	private async updateApi() {
		const portfolioGroup = this.props.portfolioGroups[0];

		if (portfolioGroup.id) {
			// we're in edit mode
			await this.props.transport.services.feature.update(portfolioGroup.id, XyiconFeature.PortfolioGroup, {
				portfolios: portfolioGroup.portfolioIds,
				name: portfolioGroup.name,
			});
		}
	}

	private isNameValid = (name: string) => {
		const {portfolioGroups, appState} = this.props;
		const portfolioGroup = portfolioGroups[0];

		const portfolioGroupListArray = appState.actions.getList<PortfolioGroup>(XyiconFeature.PortfolioGroup);
		const otherGroupsWithSameName = portfolioGroupListArray.find((g) => g.id !== portfolioGroup.id && StringUtils.equalsIgnoreCase(g.name, name));

		return !otherGroupsWithSameName;
	};

	private onChangeName = (name: string) => {
		if (this.isNameValid(name)) {
			this.props.portfolioGroups[0].name = name;
			this.updateApi();
		}
	};

	private onBlur = () => {
		const portfolioGroup = this.props.portfolioGroups[0];

		if (portfolioGroup) {
			this.updateApi();
		}
	};

	private get portfolioGroupMemberCount() {
		const {appState, portfolioGroups} = this.props;

		return portfolioGroups[0].portfolioIds.filter((id) => appState.actions.getFeatureItemById(id, XyiconFeature.Portfolio)).length;
	}

	public override render() {
		const {portfolioGroups, appState} = this.props;
		const portfolioGroup = portfolioGroups[0];

		return (
			<NodataUserDetailsTabStyled>
				{portfolioGroups.length === 0 && <NoDataStyled>Please select a Portfolio Group</NoDataStyled>}
				{portfolioGroups.length === 1 && (
					<>
						<UserGroupDetailsTabStyled>
							<DetailsTabV5HeaderStyles>
								<FlexCenter $gap="10px">
									<InfoIcon />
									<h2>Details</h2>
								</FlexCenter>
							</DetailsTabV5HeaderStyles>
							<div className="header">
								<FieldV5 label="Name">
									<ClickToEditInputV5
										value={portfolioGroup.name}
										onChange={this.onChangeName}
										onBlur={this.onBlur}
										valueValidator={this.isNameValid}
										dataType={FieldDataType.SingleLineText}
									/>
								</FieldV5>

								<FieldV5 label="Portfolios">
									<ClickToEditInputV5
										value={this.portfolioGroupMemberCount}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<FieldV5 label="Last Updated">
									<ClickToEditInputV5
										value={DateFormatter.format(portfolioGroup.lastModifiedAt)}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<FieldV5 label="Last Updated By">
									<ClickToEditInputV5
										value={appState.actions.renderName(portfolioGroup.lastModifiedBy)}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>
							</div>
							<PortfolioGroupPortfoliosV5 portfolioGroup={portfolioGroup} />
						</UserGroupDetailsTabStyled>
					</>
				)}
			</NodataUserDetailsTabStyled>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {computed, observable, runInAction} from "mobx";
import styled from "styled-components";
import {Field} from "../../../widgets/form/field/Field";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {PassSecurityLevel, PasswordUtils} from "../../../widgets/password/PasswordUtils";
import {notify} from "../../../../utils/Notify";
import type {INotificationParams} from "../../../notification/Notification";
import {NotificationType} from "../../../notification/Notification";
import type {ChangePasswordRequest, UpdateUserProfileRequest, UserDto} from "../../../../generated/api/base";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {colorPalette} from "../../styles/colorPalette";
import {FlexCenterStyle, VerticalFlexStyle, fontSize, radius, zIndex} from "../../styles/styles";
import {ButtonV5} from "../../button/ButtonV5";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {PasswordInputV5} from "../../input/password/PasswordInputV5";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import CloseIcon from "../../icons/xmark-large.svg?react";
import PasswordIcon from "../../icons/password.svg?react";
import CheckCircleIcon from "../../icons/check-circle.svg?react";
import {InitialsV5} from "../../widgets/InitialsV5";
import {PopupBackdropV5} from "../../popup/PopupBackdropV5";
import {Functions} from "../../../../utils/function/Functions";
import {PasswordValidatorStyled} from "../../user/UserStaticV5.Style";

interface IPersonalSettingsV5Props {
	appState?: AppState;
	transport?: TransportLayer;
}

interface IMyProfileV5State {
	isChangePasswordPanelOpen: boolean;
	currentPassword: string;
	newPasswordConfirm: string;
	newPassword: string;
	isUserDataSaving: boolean;
	isPasswordSaving: boolean;
	passwordStrengthMessage: string;
}

interface IChangeableUserData {
	userName: string;
	firstName: string;
	lastName: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class MyProfileV5 extends React.Component<IPersonalSettingsV5Props, IMyProfileV5State> {
	private _isMounted: boolean = false;

	@observable
	private _savedUserData: string = "";

	private get _user() {
		return this.props.appState.user;
	}

	private get _transportLayer() {
		return this.props.transport;
	}

	constructor(props: IPersonalSettingsV5Props) {
		super(props);
		this.state = {
			isChangePasswordPanelOpen: false,
			currentPassword: "",
			newPassword: "",
			newPasswordConfirm: "",
			isUserDataSaving: false,
			isPasswordSaving: false,
			passwordStrengthMessage: "",
		};

		this.saveUserDataLocally();
	}

	private saveUserDataLocally() {
		const user = this._user;

		const currentUserData: IChangeableUserData = {
			userName: user.username,
			firstName: user.firstName,
			lastName: user.lastName,
		};

		this._savedUserData = JSON.stringify(currentUserData);
	}

	private revertUserDataToSavedValues = () => {
		const user = this._user;
		const savedData: IChangeableUserData = JSON.parse(this._savedUserData);

		runInAction(() => {
			user.username = savedData.userName;
			user.firstName = savedData.firstName;
			user.lastName = savedData.lastName;
		});
	};

	private onSave = async () => {
		const user = this._user;

		const params: UpdateUserProfileRequest = {
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			phoneNumber: user.phone,
			countryCode: user.countryCode,
			userGeneralSetting: {
				timeZone: "GMT+2",
				theme: this.props.appState.theme,
				language: "en",
			},
		};

		this.setState({
			isUserDataSaving: true,
		});
		const {result, error} = await this._transportLayer.request<UserDto>({
			url: "users/updateuserprofile",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		this.setState({
			isUserDataSaving: false,
		});

		const userNotificationParams: INotificationParams = {
			type: NotificationType.Success,
			title: "Change user data successful!",
			lifeTime: 10000,
			description: "Your user data is successfully changed",
		};

		if (error) {
			userNotificationParams.type = NotificationType.Error;
			userNotificationParams.title = "Change user data unsuccessful!";
			userNotificationParams.description = `Your user data has not been changed due to the following error: ${error.ErrorMessage}`;

			this.revertUserDataToSavedValues();
		} else {
			this.saveUserDataLocally();
		}

		notify(this.props.appState.app.notificationContainer, userNotificationParams);
	};

	private resetPasswordInputs() {
		this.setState({
			currentPassword: "",
			newPassword: "",
			newPasswordConfirm: "",
			isPasswordSaving: false,
		});
	}

	private onSavePasswordChange = async () => {
		if (this.state.newPassword === this.state.newPasswordConfirm) {
			const params: ChangePasswordRequest = {
				oldPassword: this.state.currentPassword,
				newPassword: this.state.newPassword,
			};

			this.setState({
				isPasswordSaving: true,
			});
			const {result, error} = await this._transportLayer.requestForOrganization<boolean>({
				url: "users/changepassword",
				method: XHRLoader.METHOD_POST,
				params: params,
			});

			const notificationParams: INotificationParams = {
				type: NotificationType.Error,
				title: "Change password unsuccessful!",
				lifeTime: 10000,
			};

			if (error) {
				if (this._isMounted) {
					this.resetPasswordInputs();
				}
				notificationParams.description = error.ErrorMessage;
			} else {
				window.setTimeout(() => {
					if (this._isMounted) {
						this.onCancelPasswordChange();
					}
				}, 2000);

				notificationParams.title = "Change password successful!";
				notificationParams.type = NotificationType.Success;
				notificationParams.description = "Your password is successfully changed.";
			}

			notify(this.props.appState.app.notificationContainer, notificationParams);
		}
	};

	private onCancelPasswordChange = () => {
		this.setState({isChangePasswordPanelOpen: false});
		this.resetPasswordInputs();
	};

	private onPasswordChange = () => {
		this.setState({isChangePasswordPanelOpen: true});
	};

	private handleCurrentPasswordInput = (value: string) => {
		this.setState({
			currentPassword: value,
		});
	};

	// Compared to what we have on the backend
	@computed
	private get isUserDataChangedLocally() {
		const user = this._user;
		const stringifiedUserData: IChangeableUserData = {
			userName: user.username,
			firstName: user.firstName,
			lastName: user.lastName,
		};

		const currentData = JSON.stringify(stringifiedUserData);

		return currentData !== this._savedUserData;
	}

	@computed
	private get isLocalUserDataValid() {
		const user = this._user;

		return !!user.username && !!user.firstName && !!user.lastName;
	}

	private get isPasswordSaveButtonEnabled() {
		const {currentPassword, newPassword, newPasswordConfirm, isPasswordSaving} = this.state;

		return (
			!isPasswordSaving &&
			PasswordUtils.getPasswordSecurityLevel(newPassword) > PassSecurityLevel.RequirementsNotMet &&
			newPassword === newPasswordConfirm &&
			currentPassword &&
			newPassword &&
			newPasswordConfirm &&
			newPassword.length <= 64
		);
	}

	private get passwordConfirmError() {
		return this.state.newPasswordConfirm && this.state.newPasswordConfirm !== this.state.newPassword ? "Passwords do not match." : "";
	}

	private handleNewPasswordInput = (value: string) => {
		let passwordStrengthMessage;

		if (value.length < 7 || !/[0-9]/.test(value) || !/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
			passwordStrengthMessage = "Password does not meet criteria. Please try again.";
		} else {
			passwordStrengthMessage = "";
		}

		this.setState({
			newPassword: value,
			passwordStrengthMessage: passwordStrengthMessage,
		});
	};

	private handleNewPasswordConfirmInput = (value: string) => {
		this.setState({
			newPasswordConfirm: value,
		});
	};

	private onFirstNameChange = (newFirstName: string) => {
		this._user.firstName = newFirstName;
	};

	private onLastNameChange = (newLastName: string) => {
		this._user.lastName = newLastName;
	};

	private onUserNameChange = (newUserName: string) => {
		this._user.username = newUserName;
	};

	private renderPasswordPanel() {
		const {currentPassword, newPassword, newPasswordConfirm, isChangePasswordPanelOpen, passwordStrengthMessage, isPasswordSaving} = this.state;
		const isPasswordSaveButtonEnabled = this.isPasswordSaveButtonEnabled;

		return (
			<>
				{isChangePasswordPanelOpen && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<PopupBackdropV5 onClick={Functions.stopPropagation}>
							<ChangePasswordPanelStyled>
								<HeaderStyled className="heading hbox createBox">
									<h4>Change Your password</h4>
									<IconButtonV5
										IconComponent={CloseIcon}
										onClick={this.onCancelPasswordChange}
									/>
								</HeaderStyled>
								<BodyStyled>
									<div className="fieldsContainer">
										<Field label="Current Password">
											<PasswordInputV5
												value={currentPassword}
												onInput={this.handleCurrentPasswordInput}
											/>
										</Field>
										<Field label="New Password">
											<PasswordInputV5
												value={newPassword}
												onInput={this.handleNewPasswordInput}
												infoText={
													passwordStrengthMessage !== ""
														? passwordStrengthMessage
														: newPassword !== "" && newPassword === currentPassword
															? "Your new password matches your old password. Saving it like this won't make any difference."
															: ""
												}
												isTooltip={true}
												isTextInfo={true}
											/>
											{newPassword !== "" && passwordStrengthMessage === "" && newPassword != currentPassword && (
												<CheckCircleIcon className="check-icon" />
											)}
										</Field>
										<Field label="Confirm Password">
											<PasswordInputV5
												value={newPasswordConfirm}
												onInput={this.handleNewPasswordConfirmInput}
												infoText={this.passwordConfirmError ? "Passwords do not match." : ""}
											/>
										</Field>
										<PasswordValidatorStyled className="PasswordValidator">
											<div className="pwddRequirementsMessage">
												Password must be 8+ characters and contain at least one number, uppercase letter, and lowercase letter. Special characters are
												optional
											</div>
										</PasswordValidatorStyled>
									</div>
									<div className="buttons hbox">
										<ButtonV5
											label={isPasswordSaving ? "Saving..." : "Save"}
											className="primary"
											disabled={!isPasswordSaveButtonEnabled}
											onClick={this.onSavePasswordChange}
										/>
									</div>
								</BodyStyled>
							</ChangePasswordPanelStyled>
						</PopupBackdropV5>
					</DomPortal>
				)}
			</>
		);
	}

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override componentWillUnmount() {
		if (!this.state.isUserDataSaving) {
			this.revertUserDataToSavedValues();
		}

		this._isMounted = false;
	}

	public override render() {
		const user = this._user;

		if (!user) {
			return null;
		}

		return (
			<MyProfileStyled className="MyProfile">
				<div className="vbox userdataContainer">
					<div className="avatarBox hbox justifyCenter alignCenter">
						<InitialsV5
							name={`${user.firstName} ${user.lastName}`}
							color="1874CD"
						/>
					</div>
					<div className="fieldsContainer">
						<Field
							label="First name"
							className="firstname"
						>
							<TextInputV5
								value={user.firstName}
								onInput={this.onFirstNameChange}
								errorMessage={!user.firstName ? "First name cannot be empty" : ""}
								isTextInfo={true}
							/>
						</Field>
						<Field label="Last name">
							<TextInputV5
								value={user.lastName}
								onInput={this.onLastNameChange}
								errorMessage={!user.lastName ? "Last name cannot be empty" : ""}
								isTextInfo={true}
							/>
						</Field>
						<Field
							label="Username"
							className="usernameField"
						>
							<TextInputV5
								value={user.username}
								onInput={this.onUserNameChange}
							/>
						</Field>
						<Field label="Email">
							<TextInputV5
								placeholder="Email"
								value={user.email}
								disabled={true}
							/>
						</Field>
						<div className="ChangePassword">
							<ButtonV5
								className="secondary ChangePasswordButton"
								label="Change Password"
								onClick={this.onPasswordChange}
							>
								<PasswordIcon />
							</ButtonV5>
						</div>
						<div className="buttons hbox">
							<ButtonV5
								label="Reset"
								className="secondary"
								disabled={this.state.isUserDataSaving || !this.isUserDataChangedLocally}
								onClick={this.revertUserDataToSavedValues}
							/>
							<ButtonV5
								label={this.state.isUserDataSaving ? "Saving..." : "Save"}
								disabled={this.state.isUserDataSaving || !this.isUserDataChangedLocally || !this.isLocalUserDataValid}
								className="primary"
								onClick={this.onSave}
							/>
						</div>
					</div>
				</div>
				{this.renderPasswordPanel()}
			</MyProfileStyled>
		);
	}
}

const MyProfileStyled = styled.div`
	width: 480px;
	margin-left: 40px;

	.ChangePassword {
		justify-content: flex-end;
		padding: 40px 0;
		display: flex;
	}

	.ChangePasswordButton {
		width: 185px;
		background-color: ${colorPalette.white};
		color: ${colorPalette.primary.c500Primary};
		border: 1px solid ${colorPalette.primary.c500Primary};
		border-radius: ${radius.sm};
	}

	.buttons {
		justify-content: flex-end;

		.Button {
			margin-left: 8px;
		}
	}

	.avatarBox {
		> .Initials {
			${FlexCenterStyle}
			margin-top: 50px;
			margin-bottom: 50px;
			position: initial;
			top: initial;
			left: initial;
			justify-content: center;
			width: 96px;
			height: 96px;
			border-radius: 50%;
			color: white;
		}
	}

	.Field {
		input {
			width: 300px;
		}

		label {
			min-width: 180px;
			display: inline-block;
		}

		&.firstname {
			padding-bottom: 16px;
		}

		&.usernameField {
			margin-top: 40px;
			padding-bottom: 16px;
		}

		.element {
			position: relative;
		}

		.infoIcon {
			top: 7px;
		}
	}
`;

const ChangePasswordPanelStyled = styled.div`
	${VerticalFlexStyle};
	background-color: ${colorPalette.white};
	border-radius: ${radius.md};
	width: 440px;
	box-shadow: 0px 4px 20px 0px #00000033;
	margin: 16px;
	z-index: ${zIndex.createPanel + 1};
	position: absolute;

	.IconButton {
		height: 24px;
		min-width: 24px;

		svg {
			height: 16px;
			width: 16px;
		}
	}

	.buttons {
		justify-content: flex-end;
	}

	.Field {
		${FlexCenterStyle}
		padding-bottom: 8px;

		label {
			min-width: 167px;
			color: black;
			font-size: ${fontSize.md};
		}

		.check-icon {
			color: ${colorPalette.positive.c500Primary};
			position: absolute;
			right: 50px;
			top: 97px;
		}
	}
`;

const HeaderStyled = styled.div`
	${FlexCenterStyle}
	justify-content: space-between;
	height: 24px;
	margin: 8px;
`;

const BodyStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 8px;
	flex: 1;

	.fieldsContainer {
		padding: 0 8px;
	}
`;

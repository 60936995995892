import * as React from "react";
import {inject} from "mobx-react";
import {SVGIcon} from "../button/SVGIcon";
import {IconButton} from "../button/IconButton";
import type {IButtonProps} from "../button/Button";
import {ToggleButton} from "../button/ToggleButton";
import {XyiconFeature} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {ITabChildProps} from "./TabChild";

type IChild = React.ReactElement<ITabChildProps>;

interface ITabViewProps<T = any> {
	className?: string;
	selectedTabId?: string;
	onChangeSelectedTabId?: (id: string) => void;
	children?: IChild[] | IChild;
	icon?: string;
	onClose?: () => void;
	feature?: XyiconFeature;
	appState?: AppState;
}

interface ITabViewState {
	selectedTabId: string;
}

/**
 * Can be used in 2 different way:
 * - if onChangeSelectedTabId is given its an externally managed component (uses prop.selectedTabId)
 * - if not given, it uses its own state (selectedTabId is only used as the default state)
 */
@inject("appState")
export class TabView<T = any> extends React.Component<ITabViewProps<T>, ITabViewState> {
	public static defaultProps: Partial<ITabViewProps> = {
		className: "",
	};

	constructor(props: ITabViewProps) {
		super(props);
		this.state = {
			selectedTabId: props.selectedTabId || (React.Children.toArray(props.children)?.[0] as IChild)?.props.id || "",
		};
	}

	private onTabChange = (id: string) => {
		this.setState({selectedTabId: id});
		this.props.onChangeSelectedTabId?.(id);
		if (this.props.feature === XyiconFeature.SpaceEditor) {
			this.props.appState.tableComponent.current?._table.current?.scroll({left: 0});
		}
	};

	public selectTab(id: string) {
		this.setState({selectedTabId: id});
	}

	public override render() {
		const children = React.Children.toArray(this.props.children).filter((child) => !!child);
		const selectedTabId = this.props.onChangeSelectedTabId ? this.props.selectedTabId : this.state.selectedTabId;
		const {onClose} = this.props;
		const buttons: React.ReactElement<IButtonProps>[] = [];
		const views = children.map((child: IChild, index: number) => {
			const id = child.props.id || index.toString();
			const active = id === selectedTabId || (!selectedTabId && index === 0);
			const button = (
				<ToggleButton
					key={index}
					label={child.props.label}
					title={child.props.title}
					disabled={child.props.disabled}
					value={active}
					onChange={() => {
						this.onTabChange(id);
					}}
					icon={child.props.icon}
				/>
			);

			buttons.push(button);

			if (child.props.disabled || !active) {
				return null;
			} else {
				return React.cloneElement(child, {active: active} as ITabChildProps);
			}
		});

		return (
			<div className={`TabView ${this.props.className} tab${children.length}`}>
				<div className="buttons">
					{this.props.icon && (
						<div className="featureIcon">
							<SVGIcon icon={this.props.icon} />
						</div>
					)}
					{buttons}
					{onClose && (
						<IconButton
							className="closePanel"
							icon="close"
							title="Close"
							onClick={onClose}
						/>
					)}
				</div>
				<div className="views">{views}</div>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {BoundarySpaceMap3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {Xyicon3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {DistanceUnitName} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import type {App} from "../../../../../App";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import type {IModel} from "../../../../../data/models/Model";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {FocusLoss} from "../../../../../utils/ui/focus/FocusLoss";
import {AppUtils} from "../../../../../utils/AppUtils";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import ApplyIcon from "../../../icons/check.svg?react";
import CloseIcon from "../../../icons/xmark-large.svg?react";
import {Permission} from "../../../../../generated/api/base";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {FieldV5} from "../../FieldV5";
import {SingleLineInputV5} from "../SingleLineInputV5";
import type {PropertyName} from "../../PropertiesSectionV5";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";

interface IMassPropertyInputProps {
	key: string;
	propertyName: string;
	propertyPartName: PropertyName;
	propertyRef: string;
	propertyLabel: string;
	items: (Xyicon3D | BoundarySpaceMap3D)[];
	open: boolean;
	distanceUnitName: DistanceUnitName;
	disabled?: boolean;
	noFixedPosition?: boolean;
	app?: App;
	appState?: AppState;
	transport?: TransportLayer;
	onOpen: (type: string) => void;
	getSpaceItemValue: (partName: string, partRef: string, spaceItem3D: Xyicon3D | BoundarySpaceMap3D) => number;
}

interface IMassFieldInputState {
	selectedOptionId: string;
	selectedOptionValue: any;
	inputValue: string;
	updating: boolean;
	transform: TransformObj;
	open: boolean;
}

interface IOption {
	id: "all" | "blank" | string;
	label: string | number;
	value?: number;
}

@inject("app")
@inject("appState")
@inject("transport")
@observer
export class MassPropertyInputV5 extends React.Component<IMassPropertyInputProps, IMassFieldInputState> {
	private _element = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: IMassPropertyInputProps) {
		super(props);
		this.state = {
			selectedOptionId: "all",
			selectedOptionValue: undefined,
			inputValue: "",
			updating: false,
			transform: null,
			open: this.props.open,
		};
	}

	private onOpenClick = async () => {
		const {onOpen, propertyPartName} = this.props;

		onOpen(propertyPartName);
		await TimeUtils.wait(100);
		this.removeListeners();
		this.addListeners();
	};

	private onApply = async () => {
		const {items, propertyPartName, propertyRef, appState} = this.props;
		const {selectedOptionId, inputValue} = this.state;
		const newValue = parseFloat(inputValue) * this.correctionMultiplier;
		const itemsWithPermission = items.filter((item) => appState.actions.someSpaceItemsHaveGivenPermission([item], Permission.Update));

		KeyboardListener.getInstance().signals.up.remove(this.pressButton);

		const itemsToUpdate = itemsWithPermission.filter((item: Xyicon3D) => {
			const value = item.modelData[propertyRef as keyof IModel];

			if (selectedOptionId === "all") {
				return value !== newValue;
			} else if (selectedOptionId === "blank") {
				return StringUtils.isBlank(value);
			} else {
				return item.modelData.refId === selectedOptionId && value !== newValue;
			}
		});

		const confirmed = await ConfirmWindowV5.open(`${itemsToUpdate.length} of ${items.length} items will be updated. Continue?`, "Confirm Update", {
			ok: "Yes",
			cancel: "Cancel",
		});

		if (confirmed) {
			this.props.onOpen("");
			this.removeListeners();
			if (itemsToUpdate.length > 0) {
				this.setState({updating: true});

				await this.props.appState.actions.updateProperties(
					parseFloat(inputValue),
					propertyPartName as PropertyName,
					itemsToUpdate,
					this.props.distanceUnitName,
				);
			}
			this.setState({
				updating: false,
				inputValue: "",
			});
		} else {
			KeyboardListener.getInstance().signals.up.add(this.pressButton);
		}
	};

	private onBlur = (event?: MouseEvent) => {
		if (!(event?.target instanceof Element) || !this.props.app.modalContainer.contains(event?.target)) {
			this.onCancel();
		} else {
			FocusLoss.stopListen(this._floating.current, this.onBlur);
			FocusLoss.listen(this._floating.current, this.onBlur);
		}
	};

	private onCancel = () => {
		this.removeListeners();
		this.props.onOpen("");
	};

	private onOptionChange = (option: IOption) => {
		this.setState({
			selectedOptionId: option.id,
			selectedOptionValue: option.value,
		});
	};

	private onChange = (value: string) => {
		this.setState({
			inputValue: value,
		});
	};

	private removeListeners = () => {
		AppUtils.disableScrolling(false);
		FocusLoss.stopListen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.remove(this.pressButton);
	};

	private addListeners = () => {
		FocusLoss.listen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.add(this.pressButton);
		AppUtils.disableScrolling(true);
	};

	private pressButton = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ENTER:
				this.onApply();
				break;
			case KeyboardListener.KEY_ESCAPE:
				this.onCancel();
				break;
		}
	};

	private getEditInput() {
		const {inputValue, updating} = this.state;

		return (
			<SingleLineInputV5
				value={inputValue}
				onChange={this.onChange}
				onInput={this.onChange}
				updating={updating}
				onFocusLossForceBlur={true}
				disabled={this.props.disabled}
				inputType="number"
			/>
		);
	}

	private get correctionMultiplier() {
		return this.props.app.spaceViewRenderer.correctionMultiplier.current;
	}

	static getDerivedStateFromProps(props: IMassPropertyInputProps, state: IMassFieldInputState) {
		if (props.open !== state.open) {
			return {open: props.open};
		}

		return null;
	}

	public override componentDidUpdate(prevProps: IMassPropertyInputProps, prevState: IMassFieldInputState) {
		if (!this.props.noFixedPosition && !prevState.open && this.state.open && this._element.current && this._floating.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._element.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.left,
					10,
				),
			});
		}
	}

	public override componentWillUnmount() {
		this.removeListeners();
	}

	public override render() {
		const {open, items, noFixedPosition, propertyName, propertyLabel, propertyRef, appState, getSpaceItemValue, propertyPartName, disabled} =
			this.props;
		const {selectedOptionId, updating, transform} = this.state;
		const element = this._element;
		const {actions} = appState;

		const options: IOption[] = [
			{
				id: "all",
				label: "(All Values)",
			},
		];

		let allValuesMatch = true;
		let blankAdded = false;

		let first = true;
		let firstValue: number;

		for (const item of items as Xyicon3D[]) {
			const spaceItemValue = getSpaceItemValue(propertyName, propertyRef, item);
			const value = actions.formatPropValue(spaceItemValue, propertyPartName, item, this.props.distanceUnitName);

			if (!MathUtils.isValidNumber(value)) {
				continue;
			}

			// Note: if this is changed to getValue, then firstValue and option.label needs to be formatted to string
			// to avoid crashes from React.
			if (first) {
				firstValue = value;
				first = false;
			}
			if (value !== firstValue) {
				allValuesMatch = false;
			}

			if (StringUtils.isBlank(value)) {
				if (!blankAdded) {
					options.push({
						id: "blank",
						label: "(Blank Values)",
					});
					blankAdded = true;
				}
			} else {
				const id = item.modelData.refId;

				if (!options.some((option) => option.value === value)) {
					options.push({
						id: id,
						label: value,
						value: value,
					});
				}
			}
		}

		let inlineStyle: React.CSSProperties = element && {
			transform: transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
		};

		if (inlineStyle) {
			inlineStyle.transform;
		}

		if (noFixedPosition && inlineStyle) {
			inlineStyle.transform = "";
		}

		return (
			<div className={ReactUtils.cls("MassFieldInput", {open})}>
				<FieldV5
					key={this.props.key}
					label={propertyLabel || propertyPartName}
					noWrap={true}
				>
					<div
						className={ReactUtils.cls("unfocused", {disabled})}
						onClick={this.onOpenClick}
						ref={this._element}
					>
						{allValuesMatch ? firstValue : <i>Multiple values</i>}
						{updating && <span className="spinner" />}
					</div>
					{open && (
						<DomPortal
							destination={this.props.app.modalContainer}
							noPortal={noFixedPosition}
						>
							<div
								className={ReactUtils.cls("MassFieldInput__editArea properties", {[VerticalAlignment[transform?.vertical]]: transform})}
								style={inlineStyle}
								ref={this._floating}
							>
								<div className="header hbox alignCenter">
									<h4 className="flex_1">
										Editing multiple Values for <span>{`${propertyLabel || propertyPartName}`}</span>
									</h4>
									<IconButtonV5
										IconComponent={CloseIcon}
										onClick={this.onCancel}
									/>
								</div>
								<div className="container">
									<FieldV5 label="Value(s) to Update">
										<SelectInputV5
											options={options}
											render={(option) => option.label}
											selected={options.find((option) => option.id === selectedOptionId)}
											onChange={this.onOptionChange}
											onFocusLossForceBlur={true}
										/>
									</FieldV5>
									<FieldV5 label="New value">{this.getEditInput()}</FieldV5>
									<div className="editButtons">
										<IconButtonV5
											IconComponent={ApplyIcon}
											title="Apply"
											onClick={this.onApply}
										/>
										<IconButtonV5
											IconComponent={CloseIcon}
											title="Cancel"
											onClick={this.onCancel}
										/>
									</div>
								</div>
							</div>
						</DomPortal>
					)}
				</FieldV5>
			</div>
		);
	}
}

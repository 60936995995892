import * as React from "react";

interface IStackChildProps {
	id?: string;
	children: React.ReactNode;
}

export class StackChild extends React.Component<IStackChildProps> {
	public static defaultProps: IStackChildProps = {
		id: "",
		children: <></>,
	};

	public override render() {
		return this.props.children;
	}
}

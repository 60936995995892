import * as React from "react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";

interface ISpaceEditorSettingsProps {
	spaceViewRenderer: SpaceViewRenderer;
	onRealignSpaceClick: () => void;
	onClose: () => void;
	closeOnBlur: boolean;
}

export class SpaceEditorSettings extends React.Component<ISpaceEditorSettingsProps> {
	private _element = React.createRef<HTMLDivElement>();

	private onSetScaleClick = () => {
		this.props.spaceViewRenderer.inheritedMethods.setActiveTool("setScale");
		this.props.onClose();
	};

	private onRealignClick = () => {
		this.props.onRealignSpaceClick();
		this.props.onClose();
	};

	private onSnapToGridClick = () => {
		const {snapToGridManager} = this.props.spaceViewRenderer.spaceItemController;

		if (snapToGridManager.isActive) {
			snapToGridManager.deactivate();
		} else {
			snapToGridManager.activate();
		}

		this.props.onClose();
	};

	private onSnapToAngleClick = () => {
		const {boundaryManager} = this.props.spaceViewRenderer;

		boundaryManager.setSnapToAngle(!boundaryManager.isSnapToAngleActive);

		this.props.onClose();
	};

	private onScrollbarClick = () => {
		const {scrollbars} = this.props.spaceViewRenderer.toolManager.cameraControls;

		if (scrollbars.enabled) {
			scrollbars.disable();
		} else {
			scrollbars.enable();
		}

		this.props.onClose();
	};

	private onBlur = (event: MouseEvent) => {
		let shouldClose: boolean = true;

		if (this._element.current) {
			if (HTMLUtils.isDescendant(this._element.current.parentElement, event.target as Element, true)) {
				shouldClose = false;
			}
		}

		if (shouldClose) {
			this.props.onClose();
		}

		return false;
	};

	public override componentDidMount() {
		if (this.props.closeOnBlur) {
			FocusLoss.listen(this._element.current, this.onBlur);
		}
	}

	public override componentWillUnmount() {
		if (this.props.closeOnBlur) {
			FocusLoss.stopListen(this._element.current, this.onBlur);
		}
	}

	public override render() {
		return (
			<div
				className="SpaceEditorSettings vbox"
				ref={this._element}
			>
				<div
					className="selectable hbox alignCenter"
					onClick={this.onSetScaleClick}
				>
					<SVGIcon icon="setScale" />
					<span>Set Scale</span>
				</div>
				<div
					className="selectable hbox alignCenter"
					onClick={this.onRealignClick}
				>
					<SVGIcon icon="realignSpace" />
					<span>Realign Background</span>
				</div>
				<div
					className="selectable hbox alignCenter"
					onClick={this.onSnapToGridClick}
				>
					<SVGIcon icon="snapToGrid" />
					<span>Snap to Grid</span>
					{this.props.spaceViewRenderer.spaceItemController.snapToGridManager.isActive && <span className="marginLeftAuto">✓</span>}
				</div>
				<div
					className="selectable hbox alignCenter"
					onClick={this.onSnapToAngleClick}
				>
					<SVGIcon icon="snapToAngle" />
					<span>Snap to Angle</span>
					{this.props.spaceViewRenderer.boundaryManager.isSnapToAngleActive && <span className="marginLeftAuto">✓</span>}
				</div>
				<div
					className="selectable hbox alignCenter"
					onClick={this.onScrollbarClick}
				>
					<SVGIcon icon="scrollbar" />
					<span>Enable Scrollbars</span>
					{this.props.spaceViewRenderer.toolManager.cameraControls.scrollbars.enabled && <span className="marginLeftAuto">✓</span>}
				</div>
			</div>
		);
	}
}

import type {
	Texture,
	Object3D,
	BufferGeometry,
	Usage,
	InterleavedBufferAttribute,
	MeshBasicMaterial,
	Material,
	BufferAttribute,
	TypedArray,
} from "three";
import {
	NearestFilter,
	ClampToEdgeWrapping,
	Mesh,
	Vector3,
	Scene,
	OrthographicCamera,
	Vector2,
	Raycaster,
	PerspectiveCamera,
	InstancedMesh,
	InstancedBufferAttribute,
	InstancedBufferGeometry,
	DynamicDrawUsage,
	Line,
	MeshPhongMaterial,
	Box3,
} from "three";
import type {GLTF} from "three/examples/jsm/loaders/GLTFLoader.js";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
import type {GLTFExporterOptions} from "three/examples/jsm/exporters/GLTFExporter.js";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter.js";
import {estimateBytesUsed, mergeGeometries} from "three/examples/jsm/utils/BufferGeometryUtils.js";
import type {CSSProperties} from "react";
import type {Color, Dimensions, PointDouble} from "../generated/api/base";
import {Constants} from "../ui/modules/space/spaceeditor/logic3d/Constants";
import type {SpaceViewRenderer} from "../ui/modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {ICornerLetter} from "../ui/modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupAB";
import type {IZoomInfo} from "../offline_utils/utils/PDFRendererUtils";
import {ColorUtils} from "./ColorUtils";
import {HTMLUtils} from "./HTML/HTMLUtils";
import {MathUtils} from "./math/MathUtils";
import {Base64Utils} from "./Base64Utils";
import {IndexBufferGeometryUtils} from "./IndexBufferGeometryUtils";
import {Functions} from "./function/Functions";

interface IElement {
	offsetWidth: number;
	offsetHeight: number;
}

export interface ILineSegment {
	A: PointDouble;
	B: PointDouble;
}

export interface IBox2 {
	min: PointDouble;
	max: PointDouble;
}

export interface IBox3 {
	min: Dimensions;
	max: Dimensions;
}

export interface ISize {
	width: number;
	height: number;
}

export interface IRect {
	position: PointDouble;
	scale: PointDouble;
}

export class THREEUtils {
	private static readonly tempV2 = new Vector2();
	private static readonly _raycaster = new Raycaster();
	private static readonly _plane = {
		q: new Vector3(0, 0, 0), // A point on the surface of the plane
		n: new Vector3(0, 0, 1), // The normal vector of the plane
	};

	private static _gltfLoader: GLTFLoader;
	private static get gltfLoader() {
		if (!this._gltfLoader) {
			this._gltfLoader = new GLTFLoader();
		}

		return this._gltfLoader;
	}

	private static _gltfExporter: GLTFExporter;
	private static get gltfExporter() {
		if (!this._gltfExporter) {
			this._gltfExporter = new GLTFExporter();
		}

		return this._gltfExporter;
	}

	private static _defaultMaterial: MeshPhongMaterial;
	private static get defaultMaterial() {
		if (!this._defaultMaterial) {
			this._defaultMaterial = new MeshPhongMaterial({color: Constants.DEFAULT_3D_COLOR});
		}

		return this._defaultMaterial.clone();
	}
	/**
	 * With these options, it's not required for the image to be power of 2 in dimensions (webgl 1 limitation)
	 */
	public static applyNearestFilterToTexture(texture: Texture) {
		texture.generateMipmaps = false;
		texture.anisotropy = 0;
		texture.wrapS = texture.wrapT = ClampToEdgeWrapping;

		texture.minFilter = NearestFilter;
		texture.magFilter = NearestFilter;

		texture.needsUpdate = true;
	}

	public static clearContainer(container: Object3D) {
		for (let i = container.children.length - 1; i >= 0; --i) {
			if (container.children[i].children.length > 0) {
				THREEUtils.clearContainer(container.children[i]);
			}
			container.remove(container.children[i]);
		}
	}

	public static disposeContainer(container: Object3D) {
		container.traverse((node: Object3D) => {
			if (node instanceof Mesh || node instanceof Line) {
				const material = node.material as MeshBasicMaterial;

				material.map?.dispose();
				material.envMap?.dispose();
				material.aoMap?.dispose();
				material.alphaMap?.dispose();
				material.dispose();
				(node.geometry as BufferGeometry)?.dispose();
			}
		});
	}

	public static disposeAndClearContainer(container: Object3D) {
		THREEUtils.disposeContainer(container);
		THREEUtils.clearContainer(container);
	}

	public static getDimensionsOfObject(obj: Object3D) {
		obj.updateMatrixWorld(true);
		const bbox = new Box3().expandByObject(obj);
		const size = bbox.getSize(new Vector3());

		return {
			x: size.x,
			y: size.y,
			z: size.z,
		};
	}

	public static scaleObject3DForSpaceEditor(
		object3D: Object3D,
		spaceUnitsPerMeter: number,
		originalSizeInMeters: Dimensions,
		newSizeInMeters: Dimensions,
	) {
		const defaultUnit = Constants.DEFAULT_UNIT;

		object3D.scale.x *= newSizeInMeters.x / originalSizeInMeters.x;
		object3D.scale.y *= newSizeInMeters.y / originalSizeInMeters.y;
		object3D.scale.z *= newSizeInMeters.z / originalSizeInMeters.z;

		// convert to spaceunits
		object3D.scale.x = MathUtils.convertDistanceToSpaceUnit(object3D.scale.x, defaultUnit, spaceUnitsPerMeter);
		object3D.scale.y = MathUtils.convertDistanceToSpaceUnit(object3D.scale.y, defaultUnit, spaceUnitsPerMeter);
		object3D.scale.z = MathUtils.convertDistanceToSpaceUnit(object3D.scale.z, defaultUnit, spaceUnitsPerMeter);
	}

	public static detectCollision(rect1: IRect, rect2: IRect) {
		// https://gamedev.stackexchange.com/questions/586/what-is-the-fastest-way-to-work-out-2d-bounding-box-intersection
		return (
			Math.abs(rect1.position.x - rect2.position.x) * 2 < rect1.scale.x + rect2.scale.x &&
			Math.abs(rect1.position.y - rect2.position.y) * 2 < rect1.scale.y + rect2.scale.y
		);
	}

	public static createScene(
		sceneName: string,
		initialPosition: Vector3 = new Vector3(0, 0, 0),
		initialScale: Vector3 = new Vector3(1, 1, 1),
		autoUpdate: boolean = false,
	) {
		const scene = new Scene();

		scene.name = sceneName;
		scene.position.copy(initialPosition);
		scene.scale.copy(initialScale);
		scene.matrixWorldAutoUpdate = autoUpdate;
		scene.matrixAutoUpdate = autoUpdate;
		THREEUtils.updateMatrices(scene);

		return scene;
	}

	/**
	 * Adds element to a container sets matrixAutoUpdate and matrixWorldAutoUpdate to false and updates matrices after that immediately
	 * This method for rendering is much faster, but we'll have to manually update the matrices when needed
	 * @param container
	 * @param element
	 */
	public static add(container: Object3D, element: Object3D) {
		container.add(element);
		element.matrixAutoUpdate = false;
		element.matrixWorldAutoUpdate = false;
		THREEUtils.updateMatrices(element);
	}

	/**
	 * Same as `add`, except it adds the child to the beginning of the `children`
	 * @param container
	 * @param element
	 */
	public static addFront(container: Object3D, element: Object3D) {
		if (element.isObject3D) {
			if (element.parent) {
				element.parent.remove(element);
			}
			element.parent = container;
			container.children.unshift(element);

			element.matrixAutoUpdate = false;
			THREEUtils.updateMatrices(element);
		}
	}

	public static renderToTop(element: Object3D) {
		// With this trick we can render it on top of the others (the objects are being rendered in order, if depthwrite is disabled!)
		const parent = element.parent;

		if (parent) {
			parent.remove(element);
			parent.add(element);
		}
	}

	public static renderToBottom(element: Object3D) {
		// similar to renderToTop, only the other way around
		const parent = element.parent;

		if (parent) {
			parent.remove(element);
			parent.children.unshift(element);
		}
	}

	public static setPosition(element: Object3D, x: number, y: number, z: number = element.position.z) {
		element.position.set(x, y, z);
		THREEUtils.updateMatrices(element);
	}

	public static setScale(element: Object3D, x: number, y: number, z: number = 1) {
		element.scale.set(x, y, z);
		THREEUtils.updateMatrices(element);
	}

	public static updateMatrices(element: Object3D) {
		element.updateMatrix();
		element.updateMatrixWorld();
	}

	private static dot(a: PointDouble, b: PointDouble) {
		return a.x * b.x + a.y * b.y;
	}

	private static sub(a: PointDouble, b: PointDouble) {
		return {
			x: a.x - b.x,
			y: a.y - b.y,
		};
	}

	/**
	 * return values are between -1 and 1
	 */
	public static domCoordinatesToNDC(x: number, y: number, domElement: HTMLElement) {
		return {
			x: (x / domElement.offsetWidth - 0.5) * 2,
			y: -(y / domElement.offsetHeight - 0.5) * 2 /** y values are flipped when we compare html coordinates with 3d coordinates */,
		};
	}

	public static domCoordinatesToWorldCoordinates(
		x: number,
		y: number,
		worldZ: number,
		domElement: HTMLElement,
		camera: OrthographicCamera | PerspectiveCamera,
	): Dimensions {
		const NDC = THREEUtils.domCoordinatesToNDC(x, y, domElement);

		return THREEUtils.NDCtoWorldCoordinates(NDC.x, NDC.y, worldZ, camera);
	}

	public static NDCtoWorldCoordinates(x: number, y: number, worldZ: number, spaceSize: ISize): Dimensions;
	public static NDCtoWorldCoordinates(x: number, y: number, worldZ: number, camera: OrthographicCamera | PerspectiveCamera): Dimensions;
	public static NDCtoWorldCoordinates(
		x: number,
		y: number,
		worldZ: number,
		cameraOrSpaceSize: (OrthographicCamera | PerspectiveCamera) | ISize,
	): Dimensions {
		if (cameraOrSpaceSize instanceof OrthographicCamera || cameraOrSpaceSize instanceof PerspectiveCamera) {
			const camera = cameraOrSpaceSize;

			/**
			 * See the intersectPlane function here: http://vargapeter.info/thesis.pdf
			 * */
			this.tempV2.set(x, y);
			THREEUtils._raycaster.setFromCamera(this.tempV2, camera);
			const ray = THREEUtils._raycaster.ray;

			const plane = THREEUtils._plane;

			plane.q.setZ(worldZ);
			const t = plane.n.dot(plane.q.clone().sub(ray.origin)) / plane.n.dot(ray.direction);

			if (t < 0) {
				return null;
			}

			const hitPoint = ray.origin.add(ray.direction.multiplyScalar(t));

			return {
				x: hitPoint.x,
				y: hitPoint.y,
				z: worldZ,
			};
		} else {
			const spaceSize = cameraOrSpaceSize;

			return {
				x: x * spaceSize.width,
				y: y * spaceSize.height,
				z: worldZ,
			};
		}
	}

	public static worldCoordinatesToDomCoordinates(
		worldX: number,
		worldY: number,
		worldZ: number,
		domElement: IElement,
		camera: OrthographicCamera | PerspectiveCamera,
	): PointDouble;
	public static worldCoordinatesToDomCoordinates(worldX: number, worldY: number, worldZ: number, domElement: IElement, spaceSize: ISize): PointDouble;
	public static worldCoordinatesToDomCoordinates(
		worldX: number,
		worldY: number,
		worldZ: number,
		domElement: IElement,
		cameraOrSpaceSize: OrthographicCamera | PerspectiveCamera | ISize,
	) {
		// Yes, they are the same below.. TypeScript bug..?
		const ndc =
			cameraOrSpaceSize instanceof OrthographicCamera || cameraOrSpaceSize instanceof PerspectiveCamera
				? THREEUtils.worldCoordinatesToNDC(worldX, worldY, worldZ, cameraOrSpaceSize)
				: THREEUtils.worldCoordinatesToNDC(worldX, worldY, worldZ, cameraOrSpaceSize);

		return {
			x: ndc.x * domElement.offsetWidth,
			y: domElement.offsetHeight - ndc.y * domElement.offsetHeight,
		};
	}

	public static worldCoordinatesToNDC(worldX: number, worldY: number, worldZ: number, camera: OrthographicCamera | PerspectiveCamera): PointDouble;
	public static worldCoordinatesToNDC(worldX: number, worldY: number, worldZ: number, spaceSize: ISize): PointDouble;
	public static worldCoordinatesToNDC(
		worldX: number,
		worldY: number,
		worldZ: number,
		cameraOrSpaceSize: OrthographicCamera | PerspectiveCamera | ISize,
	) {
		if (cameraOrSpaceSize instanceof OrthographicCamera || cameraOrSpaceSize instanceof PerspectiveCamera) {
			const camera = cameraOrSpaceSize;

			camera.updateMatrixWorld();

			const position = new Vector3(worldX, worldY, worldZ);

			position.project(camera);

			return {
				x: (position.x + 1) / 2,
				y: (position.y + 1) / 2,
			};
		} // we simply assume a top-down view and we interpolate the coords between space
		else {
			const spaceSize = cameraOrSpaceSize;

			return {
				x: worldX / spaceSize.width,
				y: worldY / spaceSize.height,
			};
		}
	}

	public static getLength(vec: PointDouble) {
		return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
	}

	public static normalize(vec: PointDouble) {
		const len = THREEUtils.getLength(vec);

		return {
			x: vec.x / len,
			y: vec.y / len,
		};
	}

	/** Returns the normalized normal vector of vec */
	public static getNormal(vec: PointDouble) {
		return THREEUtils.normalize({
			x: vec.y,
			y: -vec.x,
		});
	}

	public static multiplyByScalar(vec: PointDouble, scalar: number) {
		return {
			x: vec.x * scalar,
			y: vec.y * scalar,
		};
	}

	public static calculateDistance(pathCoords: PointDouble[]) {
		let sumOfParts = 0;

		for (let i = 0; i < pathCoords.length - 1; ++i) {
			const vertexA = pathCoords[i];
			const vertexB = pathCoords[i + 1];

			sumOfParts += Math.sqrt((vertexB.x - vertexA.x) ** 2 + (vertexB.y - vertexA.y) ** 2);
		}

		return sumOfParts;
	}

	public static calculateArea(pathCoords: PointDouble[]) {
		/**
		 * Based on this: https://www.mathopenref.com/coordpolygonarea.html
		 */

		// we need to combine the last vertex with the first one as well
		const xyPairs = [...pathCoords, pathCoords[0]];

		let sumOfParts = 0;

		for (let i = 0; i < xyPairs.length - 1; ++i) {
			const vertexA = xyPairs[i];
			const vertexB = xyPairs[i + 1];

			sumOfParts += vertexA.x * vertexB.y - vertexA.y * vertexB.x;
		}

		return Math.abs(sumOfParts / 2);
	}

	public static cloneGeometryData(vertices: PointDouble[]) {
		const geometryData: PointDouble[] = [];

		for (const vertex of vertices) {
			geometryData.push({
				x: vertex.x,
				y: vertex.y,
			});
		}

		return geometryData;
	}

	public static getRotatedBox2(unrotatedBbox: IBox2, orientation: number): IBox2 {
		const bbox = unrotatedBbox;
		const position = this.getCenterOfBoundingBox(bbox);
		const rotatedVertices = THREEUtils.getRotatedVertices(
			[
				{
					x: bbox.min.x,
					y: bbox.min.y,
				},
				{
					x: bbox.max.x,
					y: bbox.min.y,
				},
				{
					x: bbox.max.x,
					y: bbox.max.y,
				},
				{
					x: bbox.min.x,
					y: bbox.max.y,
				},
			],
			orientation,
			position,
		);

		return THREEUtils.calculateBox(rotatedVertices);
	}

	public static getRotatedBox3(unrotatedBbox: IBox3, orientation: number): IBox3 {
		const rotatedBBox2 = this.getRotatedBox2(unrotatedBbox, orientation);
		const rotatedBBox3: IBox3 = {
			min: {
				...rotatedBBox2.min,
				z: unrotatedBbox.min.z,
			},
			max: {
				...rotatedBBox2.max,
				z: unrotatedBbox.max.z,
			},
		};

		return rotatedBBox3;
	}

	public static calculateBox(vertices: PointDouble[]) {
		const min = {
			x: vertices[0]?.x ?? 0,
			y: vertices[0]?.y ?? 0,
		};

		const max = {
			x: vertices[0]?.x ?? 0,
			y: vertices[0]?.y ?? 0,
		};

		for (let i = 1; i < vertices.length; ++i) {
			const vertex = vertices[i];

			if (vertex.x < min.x) {
				min.x = vertex.x;
			}
			if (vertex.y < min.y) {
				min.y = vertex.y;
			}
			if (vertex.x > max.x) {
				max.x = vertex.x;
			}
			if (vertex.y > max.y) {
				max.y = vertex.y;
			}
		}

		return {
			max: max,
			min: min,
		};
	}

	public static getCenterOfBoundingBox(bbox: IBox2) {
		return {
			x: (bbox.min.x + bbox.max.x) / 2,
			y: (bbox.min.y + bbox.max.y) / 2,
		};
	}

	public static getSizeOfBoundingBox2(bbox: IBox2) {
		return {
			x: bbox.max.x - bbox.min.x,
			y: bbox.max.y - bbox.min.y,
		};
	}

	public static getSizeOfBoundingBox3(bbox: IBox3) {
		return {
			x: bbox.max.x - bbox.min.x,
			y: bbox.max.y - bbox.min.y,
			z: bbox.max.z - bbox.min.z,
		};
	}

	public static spaceCoordsToThumbnailCoords(geometryData: PointDouble[], canvasSize: number, strokeWidthToBBoxRatio: number) {
		const box = THREEUtils.calculateBox(geometryData);
		const {x: w, y: h} = THREEUtils.getSizeOfBoundingBox2(box);

		const maxBoxSize = Math.max(w, h);

		const strokeRadiusToBBoxRatio = strokeWidthToBBoxRatio / 2;

		let toCenterXOffset = 0;
		let toCenterYOffset = 0;

		const wWithPadding = (w + strokeWidthToBBoxRatio) / (maxBoxSize + strokeWidthToBBoxRatio);
		const hWithPadding = (h + strokeWidthToBBoxRatio) / (maxBoxSize + strokeWidthToBBoxRatio);

		if (w >= h) {
			toCenterYOffset = -((wWithPadding - hWithPadding) / wWithPadding / 2);
		} else {
			toCenterXOffset = (hWithPadding - wWithPadding) / hWithPadding / 2;
		}

		return geometryData.map((point) => ({
			x: (toCenterXOffset + strokeRadiusToBBoxRatio + ((point.x - box.min.x) / maxBoxSize) * (1 - strokeWidthToBBoxRatio)) * canvasSize,
			y: (1 + toCenterYOffset - (strokeRadiusToBBoxRatio + ((point.y - box.min.y) / maxBoxSize) * (1 - strokeWidthToBBoxRatio))) * canvasSize,
		}));
	}

	/**
	 *
	 * @param side, can be calculated as follows: side = {x: max.x - min.x,	y: max.y - min.y};
	 * @param a pointer-start
	 * @param b pointer-current
	 */
	public static constrainScale(side: PointDouble, a: PointDouble, b: PointDouble, fixedPoint: ICornerLetter = "a") {
		const newA = {
			x: a.x,
			y: a.y,
		};

		const newB = {
			x: b.x,
			y: b.y,
		};

		const sideSize = Math.max(side.x, side.y);

		if (fixedPoint === "a") {
			newB.x = a.x < b.x ? a.x + sideSize : a.x - sideSize;
			newB.y = a.y < b.y ? a.y + sideSize : a.y - sideSize;
		} else {
			newA.x = b.x < a.x ? b.x + sideSize : b.x - sideSize;
			newA.y = b.y < a.y ? b.y + sideSize : b.y - sideSize;
		}

		return {
			a: newA,
			b: newB,
		};
	}

	// get squared distance from a point to a segment
	public static pointToSegmentDistSq(p: PointDouble, s: ILineSegment) {
		const a = s.A;
		const b = s.B;

		let x = a.x;
		let y = a.y;
		let dx = b.x - x;
		let dy = b.y - y;

		if (dx !== 0 || dy !== 0) {
			const t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy);

			if (t > 1) {
				x = b.x;
				y = b.y;
			} else if (t > 0) {
				x += dx * t;
				y += dy * t;
			}
		}

		dx = p.x - x;
		dy = p.y - y;

		return dx * dx + dy * dy;
	}

	// signed distance from point to polygon outline (negative if point is outside)
	public static pointToPolygonDist(p: PointDouble, polygon: PointDouble[]) {
		let inside = false;
		let minDistSq = Infinity;

		for (let i = 0, len = polygon.length, j = len - 1; i < len; j = i++) {
			const a = polygon[i];
			const b = polygon[j];

			if (a.y > p.y !== b.y > p.y && p.x < ((b.x - a.x) * (p.y - a.y)) / (b.y - a.y) + a.x) {
				inside = !inside;
			}

			minDistSq = Math.min(minDistSq, this.pointToSegmentDistSq(p, {A: a, B: b}));
		}

		return minDistSq === 0 ? 0 : (inside ? 1 : -1) * Math.sqrt(minDistSq);
	}

	public static getSnappedPointIfCloseEnough(referencePoint: PointDouble, newPoint: PointDouble, distanceThreshold: number) {
		let retPoint = {...newPoint};

		const bbox = THREEUtils.calculateBox([referencePoint, newPoint]);
		const side = THREEUtils.getSizeOfBoundingBox2(bbox);

		const snappedPoint = THREEUtils.snapTo45Deg(side, referencePoint, retPoint).b;

		const distance = THREEUtils.calculateDistance([snappedPoint, retPoint]);

		if (distance < distanceThreshold) {
			retPoint = snappedPoint;
		}

		return retPoint;
	}

	public static snapTo45Deg(side: PointDouble, a: PointDouble, b: PointDouble, fixedPoint: ICornerLetter = "a") {
		let newA = {...a};

		let newB = {...b};

		if (side.x < side.y / 2) {
			if (fixedPoint === "a") {
				newB.x = a.x;
			} else {
				newA.x = b.x;
			}
		} else if (side.y < side.x / 2) {
			if (fixedPoint === "a") {
				newB.y = a.y;
			} else {
				newA.y = b.y;
			}
		} else {
			const constrainCoords = THREEUtils.constrainScale(side, a, b, fixedPoint);

			newA = constrainCoords.a;
			newB = constrainCoords.b;
		}

		return {
			a: newA,
			b: newB,
		};
	}

	public static getStyleForFloatingUIElement(
		worldX: number,
		worldY: number,
		worldZ: number,
		spaceViewRenderer: SpaceViewRenderer,
		clampToCanvas: boolean = false,
		position: "top" | "bottom" | "center" = "center",
		element?: Element,
	): CSSProperties {
		const unclampedXY = THREEUtils.worldCoordinatesToDomCoordinates(
			worldX,
			worldY,
			worldZ,
			spaceViewRenderer.domElement,
			spaceViewRenderer.activeCamera,
		);

		let x: number = unclampedXY.x;
		let y: number = unclampedXY.y;

		if (clampToCanvas) {
			const elementSize = element ? HTMLUtils.getSize(element) : {width: 100, height: 100};

			const w = elementSize.width / 2;
			const h = elementSize.height / 2;

			const marginTopOffset = 60; // because of viewbar
			const marginLeftOffset = 80; // because of toolbar
			const generalOffsetFromEdges = 10;

			const marginLeft = marginLeftOffset + w;
			const marginRight = generalOffsetFromEdges + w;
			let marginTop = marginTopOffset + h;
			let marginBottom = generalOffsetFromEdges + h;

			if (position === "top") {
				marginTop += h;
				marginBottom -= h;
			} else if (position === "bottom") {
				marginTop -= h;
				marginBottom += h + generalOffsetFromEdges;
			}

			x = MathUtils.clamp(unclampedXY.x, marginLeft, spaceViewRenderer.domElement.offsetWidth - marginRight);
			y = MathUtils.clamp(unclampedXY.y, marginTop, spaceViewRenderer.domElement.offsetHeight - marginBottom);
		}

		let yAlignAdjustment = "-50%";

		if (position === "top") {
			yAlignAdjustment = "-100%";
		} else if (position === "bottom") {
			yAlignAdjustment = "0";
		}

		return {
			transform: `translate(${x}px, ${y}px) translate(-50%, ${yAlignAdjustment})`,
		};
	}

	public static applyOffsetToGeometryData(geometryData: PointDouble[], offset: PointDouble) {
		for (const vertex of geometryData) {
			vertex.x += offset.x;
			vertex.y += offset.y;
		}

		return geometryData;
	}

	public static applyRotationToBufferGeometry(bufferGeometry: BufferGeometry, angle: number, pivotVector: Vector2 = new Vector2(0, 0)) {
		const tempVertex = new Vector2();

		const positionAttribute = bufferGeometry.attributes.position as BufferAttribute;

		for (let i = 0; i < positionAttribute.count; ++i) {
			tempVertex.set(positionAttribute.getX(i), positionAttribute.getY(i));
			tempVertex.rotateAround(pivotVector, angle);

			positionAttribute.setXY(i, tempVertex.x, tempVertex.y);
		}

		positionAttribute.needsUpdate = true;
	}

	public static applyOffsetToBufferGeometry(bufferGeometry: BufferGeometry, offset: Dimensions) {
		const positionAttribute = bufferGeometry.attributes.position as BufferAttribute;

		for (let i = 0; i < positionAttribute.count; ++i) {
			const newX = positionAttribute.getX(i) + offset.x;
			const newY = positionAttribute.getY(i) + offset.y;
			const newZ = positionAttribute.getZ(i) + offset.z;

			positionAttribute.setXYZ(i, newX, newY, newZ);
		}

		positionAttribute.needsUpdate = true;
	}

	public static subVec2fromVec2(a: PointDouble, b: PointDouble) {
		return {
			x: a.x - b.x,
			y: a.y - b.y,
		};
	}

	public static addVec2ToVec2(a: PointDouble, b: PointDouble) {
		return {
			x: a.x + b.x,
			y: a.y + b.y,
		};
	}

	// https://stackoverflow.com/questions/3706219/algorithm-for-iterating-over-an-outward-spiral-on-a-discrete-2d-grid-from-the-or
	public static getSpiralCoordinate(n: number) {
		// direction in which we move right now
		const direction = {
			x: 1,
			y: 0,
		};

		// length of current segment and how much of current segment we passed
		let segmentLength = 1;
		let segmentPassed = 0;

		// current position
		const position = {
			x: 0,
			y: 0,
		};

		for (let i = 0; i < n; ++i) {
			// make a step, add direction vector to current position
			position.x += direction.x;
			position.y -= direction.y;
			++segmentPassed;

			if (segmentPassed == segmentLength) {
				// done with current segment
				segmentPassed = 0;

				// 'rotate' directions
				const buffer = direction.x;

				direction.x = -direction.y;
				direction.y = buffer;

				// increase segment length if necessary
				if (direction.y == 0) {
					++segmentLength;
				}
			}
		}

		return position;
	}

	public static getRotatedVertex(vertex: PointDouble, angle: number, pivot: PointDouble) {
		const c = Math.cos(angle);
		const s = Math.sin(angle);

		const x = vertex.x - pivot.x;
		const y = vertex.y - pivot.y;

		return {
			x: x * c - y * s + pivot.x,
			y: x * s + y * c + pivot.y,
		};
	}

	public static getCenterFromGeometryData(geometryData: PointDouble[], angle: number = 0) {
		const pivot = {x: 0, y: 0};
		const unrotatedGeometryData = THREEUtils.getRotatedVertices(geometryData, -angle, pivot);

		const bbox = THREEUtils.calculateBox(unrotatedGeometryData);

		const unrotatedCenter = THREEUtils.getCenterOfBoundingBox(bbox);

		const finalCenter = THREEUtils.getRotatedVertices([unrotatedCenter], angle, pivot)[0];

		return finalCenter;
	}

	/**
	 * @param vertices
	 * @param pivot
	 * @param angle
	 */
	public static getRotatedVertices(vertices: PointDouble[], angle: number, pivot?: PointDouble) {
		if (!pivot) {
			const {max, min} = THREEUtils.calculateBox(vertices);

			pivot = {
				x: (max.x + min.x) / 2,
				y: (max.y + min.y) / 2,
			};
		}

		return vertices.map((vertex: PointDouble) => THREEUtils.getRotatedVertex(vertex, angle, pivot));
	}

	public static getGeometryDataFromBox(min: PointDouble, max: PointDouble) {
		return [
			{
				x: min.x,
				y: min.y,
			},
			{
				x: max.x,
				y: min.y,
			},
			{
				x: max.x,
				y: max.y,
			},
			{
				x: min.x,
				y: max.y,
			},
		];
	}

	// Modified version of Justin C. Round's algorithm found here:
	// https://stackoverflow.com/questions/13937782/calculating-the-point-of-intersection-of-two-lines
	private static checkLineIntersection(line1Start: PointDouble, line1End: PointDouble, line2Start: PointDouble, line2End: PointDouble) {
		// if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point

		const result: {x: number; y: number} = {
			x: null,
			y: null,
			// onLine1: false,
			// onLine2: false
		};

		const denominator = (line2End.y - line2Start.y) * (line1End.x - line1Start.x) - (line2End.x - line2Start.x) * (line1End.y - line1Start.y);

		if (denominator == 0) {
			return result;
		}
		let a = line1Start.y - line2Start.y;
		let b = line1Start.x - line2Start.x;
		let numerator1 = (line2End.x - line2Start.x) * a - (line2End.y - line2Start.y) * b;
		let numerator2 = (line1End.x - line1Start.x) * a - (line1End.y - line1Start.y) * b;

		a = numerator1 / denominator;
		b = numerator2 / denominator;

		// if we cast these lines infinitely in both directions, they intersect here:
		result.x = line1Start.x + a * (line1End.x - line1Start.x);
		result.y = line1Start.y + a * (line1End.y - line1Start.y);

		// // if line1 is a segment and line2 is infinite, they intersect if:
		// if (a > 0 && a < 1)
		// {
		// 	result.onLine1 = true;
		// }
		// // if line2 is a segment and line1 is infinite, they intersect if:
		// if (b > 0 && b < 1)
		// {
		// 	result.onLine2 = true;
		// }
		// if line1 and line2 are segments, they intersect if both of the above are true
		return result;
	}

	public static projectPointToLineSegment(point: PointDouble, lineStart: PointDouble, lineEnd: PointDouble) {
		const ab = this.sub(lineEnd, lineStart);
		const ap = this.sub(point, lineStart);

		const normalToAB = {
			a: {
				x: point.x + ab.y,
				y: point.y - ab.x,
			},
			b: {
				x: point.x - ab.y,
				y: point.y + ab.x,
			},
		};

		const intersectionPoint = this.checkLineIntersection(lineStart, lineEnd, normalToAB.a, normalToAB.b);

		// if it's not between A and B, but over A on the same line
		if (this.dot(ab, ap) < 0) {
			return lineStart;
		}
		// if it's not between A and B, but over B on the same line
		else if (this.calculateDistance([lineStart, intersectionPoint]) > this.calculateDistance([ab, {x: 0, y: 0}])) {
			return lineEnd;
		}
		// if it's between A and B
		else {
			return intersectionPoint;
		}
	}

	// https://stackoverflow.com/questions/22521982/check-if-point-is-inside-a-polygon
	public static isPointInsidePolygon(point: PointDouble, polygon: PointDouble[]) {
		const x = point.x;
		const y = point.y;

		let inside = false;

		for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
			const xi = polygon[i].x;
			const yi = polygon[i].y;
			const xj = polygon[j].x;
			const yj = polygon[j].y;

			const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

			if (intersect) {
				inside = !inside;
			}
		}

		return inside;
	}

	// https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
	public static doesLineSegmentIntersectLineSegment(l1: ILineSegment, l2: ILineSegment) {
		const det = (l1.B.x - l1.A.x) * (l2.B.y - l2.A.y) - (l2.B.x - l2.A.x) * (l1.B.y - l1.A.y);

		if (det === 0) {
			return false;
		} else {
			const lambda = ((l2.B.y - l2.A.y) * (l2.B.x - l1.A.x) + (l2.A.x - l2.B.x) * (l2.B.y - l1.A.y)) / det;
			const gamma = ((l1.A.y - l1.B.y) * (l2.B.x - l1.A.x) + (l1.B.x - l1.A.x) * (l2.B.y - l1.A.y)) / det;

			return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
		}
	}

	public static isPolygonInsidePolygon(childPolygon: PointDouble[], parentPolygon: PointDouble[]) {
		for (const vertex of childPolygon) {
			if (!THREEUtils.isPointInsidePolygon(vertex, parentPolygon)) {
				return false;
			}
		}

		let lineA: ILineSegment;
		let lineB: ILineSegment;

		for (let i = 0; i < parentPolygon.length; ++i) {
			lineA = {
				A: {
					x: parentPolygon[i].x,
					y: parentPolygon[i].y,
				},
				B: {
					x: parentPolygon[(i + 1) % parentPolygon.length].x, // we include the last with the first point like this
					y: parentPolygon[(i + 1) % parentPolygon.length].y,
				},
			};

			for (let j = 0; j < childPolygon.length; ++j) {
				lineB = {
					A: {
						x: childPolygon[j].x,
						y: childPolygon[j].y,
					},
					B: {
						x: childPolygon[(j + 1) % childPolygon.length].x,
						y: childPolygon[(j + 1) % childPolygon.length].y,
					},
				};

				if (THREEUtils.doesLineSegmentIntersectLineSegment(lineA, lineB)) {
					return false;
				}
			}
		}

		return true;
	}

	/**
	 * Returns an array with the instanceMatrix, and all the instancedbufferattributes, but not from the rest (position, uv)
	 */
	private static getInstancedBufferAttributes(instancedMesh: InstancedMesh) {
		const instancedBufferAttributes: (BufferAttribute | InstancedBufferAttribute)[] = [instancedMesh.instanceMatrix];
		const attributesObject = (instancedMesh.geometry as InstancedBufferGeometry).attributes;

		for (const key in attributesObject) {
			const attribute = attributesObject[key];

			if (attribute instanceof InstancedBufferAttribute) {
				attribute.name = key;
				instancedBufferAttributes.push(attribute);
			}
		}

		return instancedBufferAttributes;
	}

	public static removeItemFromInstancedMesh(instancedMesh: InstancedMesh, instanceId: number) {
		try {
			const attributesToRemoveElementsFrom = this.getInstancedBufferAttributes(instancedMesh);

			for (const attribute of attributesToRemoveElementsFrom) {
				const attributeArray = attribute.array as Float32Array;
				const itemSize = attribute.itemSize;
				const startIndex = instanceId * itemSize;

				// splice
				attributeArray.set(attributeArray.subarray(0, startIndex));
				attributeArray.set(attributeArray.subarray(startIndex + itemSize), startIndex);
				attribute.needsUpdate = true;
			}

			--instancedMesh.count;
		} catch (error: unknown) {
			console.warn(
				"Something went wrong with removing the item from the instancedMesh. Probably the whole instancedMesh has been removed already. (space loading has been interrupted?)",
			);
			console.warn(error);
		}
	}

	/**
	 * Maxcount can't be increased, so we have to create a new one, and copy everything
	 */
	public static cloneInstancedMeshWithLargerMaxCount(instancedMesh: InstancedMesh) {
		const instancedBufferAttributes = this.getInstancedBufferAttributes(instancedMesh);
		const geometry = instancedMesh.geometry as InstancedBufferGeometry;
		const parentObject = instancedMesh.parent;

		parentObject?.remove(instancedMesh);
		instancedMesh.dispose();

		const previousMaxCount = geometry.instanceCount;
		const newMaxCount = previousMaxCount * 2;
		const newInstancedMesh = new InstancedMesh(new InstancedBufferGeometry().copy(geometry), instancedMesh.material as Material, newMaxCount);

		geometry.dispose();
		(newInstancedMesh.geometry as InstancedBufferGeometry).instanceCount = newMaxCount;
		newInstancedMesh.instanceMatrix.set(instancedMesh.instanceMatrix.array);
		newInstancedMesh.count = instancedMesh.count;
		newInstancedMesh.name = instancedMesh.name;
		newInstancedMesh.userData = instancedMesh.userData;

		for (const attribute of instancedBufferAttributes) {
			if (attribute !== instancedMesh.instanceMatrix) {
				const typedArray = new Float32Array(newMaxCount * attribute.itemSize); // we assume that all the previous instancedbufferattributes were float32array

				typedArray.set(attribute.array);
				(newInstancedMesh.geometry as InstancedBufferGeometry).setAttribute(
					attribute.name,
					new InstancedBufferAttribute(typedArray, attribute.itemSize),
				);
			}
		}

		if (parentObject) {
			parentObject.add(newInstancedMesh);
		}

		return newInstancedMesh;
	}

	public static createInstancedBufferAttribute(array: TypedArray, itemSize: number, usage: Usage = DynamicDrawUsage) {
		const attribute = new InstancedBufferAttribute(array, itemSize);

		attribute.setUsage(usage);

		return attribute;
	}

	public static setColorForInstancedMesh(instancedMesh: InstancedMesh, color: Color) {
		if (instancedMesh) {
			const fontColor = ColorUtils.hex2Array(color.hex).slice(0, 3);
			const fontOpacity = 1 - color.transparency;

			const geometry = instancedMesh.geometry as BufferGeometry;

			const colorAttrib = geometry.attributes.color as BufferAttribute;
			const opacityAttrib = geometry.attributes.opacity as BufferAttribute;

			for (let i = 0; i < colorAttrib.count; ++i) {
				THREEUtils.setAttributeXYZ(colorAttrib, i, fontColor[0], fontColor[1], fontColor[2]);
				THREEUtils.setAttributeX(opacityAttrib, i, fontOpacity);
			}
		}
	}

	public static toIndexedBufferGeometry(bufferGeometry: BufferGeometry) {
		return IndexBufferGeometryUtils.toIndexBufferGeometry(bufferGeometry);
	}

	public static mergeGeometriesOfObject(obj: Object3D, keepUV: boolean = false) {
		const geometries: BufferGeometry[] = [];

		// Delete all the unnecessary attributes, that we don't use anyway: uv, vertex colors, etc
		// This helps us reducing the filesize,
		// and also we can merge the geometries if they have the same type of attributes
		const necessaryAttribs = new Set<string>(["position", "normal"]);

		if (keepUV) {
			necessaryAttribs.add("uv");
		}

		// After loading, the world matrices are not automatically updated, so we need to update them manually
		obj.updateMatrixWorld(true);
		obj.traverse((object: Object3D) => {
			if (object instanceof Mesh) {
				const geometry = object.geometry as BufferGeometry;

				geometry.applyMatrix4(object.matrixWorld);

				for (const key in geometry.attributes) {
					if (!necessaryAttribs.has(key)) {
						geometry.deleteAttribute(key);
					}
				}

				geometries.push(geometry.index ? geometry.toNonIndexed() : geometry);
			}
		});

		const mergedNonIndexedGeometry = mergeGeometries(geometries);
		const mergedIndexedGeometry = THREEUtils.toIndexedBufferGeometry(mergedNonIndexedGeometry);

		// Indexed should be smaller in the vast majority of the cases, but check anyway
		const isIndexedLargerThanNonIndexed = estimateBytesUsed(mergedNonIndexedGeometry) < estimateBytesUsed(mergedIndexedGeometry);
		const mergedGeometry = isIndexedLargerThanNonIndexed ? mergedNonIndexedGeometry : mergedIndexedGeometry;

		mergedGeometry.center();

		return mergedGeometry;
	}

	// Merge the geometries of the gltf, sets the origin to the center
	// and replaces the materials with one single, simple material (also removes the textures)
	public static getMeshFromGltf(glTF: GLTF, keepUV: boolean = false) {
		const mergedGeometry = THREEUtils.mergeGeometriesOfObject(glTF.scene, keepUV);
		const mesh = new Mesh(mergedGeometry, this.defaultMaterial);

		return mesh;
	}

	public static loadGLTF(url: string) {
		return this.gltfLoader.loadAsync(url);
	}

	public static convertMeshToBase64Gltf(mesh: Mesh) {
		return new Promise<string>((resolve, reject) => {
			const options: GLTFExporterOptions = {
				binary: true,
			};

			if (this.gltfExporter.parse) {
				this.gltfExporter.parse(
					mesh,
					(result: ArrayBuffer) => {
						const array = new Uint8Array(result);
						const base64 = `data:application/octet-stream;base64,${Base64Utils.bytesToBase64(array)}`;

						resolve(base64);
					},
					Functions.emptyFunction,
					options,
				);
			} else {
				resolve("error");
			}
		});
	}

	public static simplifyGltf(originalGltf: GLTF): Promise<string> {
		const mesh = this.getMeshFromGltf(originalGltf);

		return this.convertMeshToBase64Gltf(mesh);
	}

	// In align mode, we only create the deepest level tiles
	public static generateZoomInfo(
		spaceSize: PointDouble,
		spaceResolution: PointDouble,
		tileResolution: number,
		isAlignMode: boolean = false,
	): IZoomInfo[] {
		const zoomInfo = [];

		const correctionMultiplier = spaceSize.x / spaceResolution.x;

		let worldSizeOfTile = tileResolution * correctionMultiplier; // deepest level

		const thresholdCoeff = 1536; // got it by trial and error. For an average full hd screen, this seems to be optimal
		const threshold = thresholdCoeff + (thresholdCoeff - (Math.max(spaceResolution.x, spaceResolution.y) % worldSizeOfTile)); //*window.devicePixelRatio;

		do {
			zoomInfo.unshift({
				columns: Math.ceil(spaceSize.x / worldSizeOfTile),
				rows: Math.ceil(spaceSize.y / worldSizeOfTile),
				tileSize: worldSizeOfTile,
			});
			worldSizeOfTile *= 2;
		} while (
			zoomInfo[0].columns * tileResolution > threshold &&
			zoomInfo[0].rows * tileResolution > threshold &&
			zoomInfo[0].columns > 1 &&
			zoomInfo[0].rows > 1 &&
			!isAlignMode
		);

		return zoomInfo;
	}

	public static setAttributeX(attribute: BufferAttribute | InstancedBufferAttribute | InterleavedBufferAttribute, index: number, x: number) {
		(attribute as BufferAttribute).setX(index, x).needsUpdate = true;
	}

	public static setAttributeXY(
		attribute: BufferAttribute | InstancedBufferAttribute | InterleavedBufferAttribute,
		index: number,
		x: number,
		y: number,
	) {
		(attribute as BufferAttribute).setXY(index, x, y).needsUpdate = true;
	}

	public static setAttributeXYZ(
		attribute: BufferAttribute | InstancedBufferAttribute | InterleavedBufferAttribute,
		index: number,
		x: number,
		y: number,
		z: number,
	) {
		(attribute as BufferAttribute).setXYZ(index, x, y, z).needsUpdate = true;
	}
}

import {useEffect, useRef} from "react";
import {MathUtils} from "three";

interface IBorderRectAroundSvgPartProps {
	readonly borderColor: string;
	readonly orientation: number;
	readonly pivotX: number;
	readonly pivotY: number;
	readonly scale: number;
	readonly viewBoxSize: number;
}

const selectedSvgPartAttributeKey = "selectedsvgpart";

export const BorderRectAroundSvgPartV5 = (props: IBorderRectAroundSvgPartProps) => {
	const rectRef = useRef<SVGRectElement>();

	useEffect(() => {
		const component = document.body.querySelector(`[${selectedSvgPartAttributeKey}="true"]`) as SVGPathElement;

		if (!component || !rectRef.current) {
			return;
		}

		const pivot = {
			x: props.pivotX * props.viewBoxSize,
			y: props.pivotY * props.viewBoxSize,
		};

		const rotate = `rotate(${MathUtils.radToDeg(props.orientation)} ${pivot.x} ${pivot.y})`;

		const rectBBox = component.getBBox();

		rectBBox.width *= props.scale;
		rectBBox.height *= props.scale;

		const rectComponent = rectRef.current;

		rectComponent.setAttribute("x", `${pivot.x + (rectBBox.x - props.viewBoxSize / 2) * props.scale}`);
		rectComponent.setAttribute("y", `${pivot.y + (rectBBox.y - props.viewBoxSize / 2) * props.scale}`);
		rectComponent.setAttribute("width", `${rectBBox.width}`);
		rectComponent.setAttribute("height", `${rectBBox.height}`);
		rectComponent.setAttribute("transform", rotate);
	});

	return (
		<rect
			ref={rectRef}
			data-transformhelper={true}
			fill="none"
			stroke={props.borderColor}
			strokeWidth={0.5}
		/>
	);
};

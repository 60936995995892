import {DoubleSide, RawShaderMaterial} from "three";
import type {WebGLProgramParametersWithUniforms} from "three";

export class ModelBasedXyiconMaterial extends RawShaderMaterial {
	private _vertexShader: string = `precision highp float;

uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;

attribute vec3 position;
attribute vec3 normal;
attribute mat4 instanceMatrix;
attribute vec3 color;
attribute float opacity;
attribute float isGrayScaled;

uniform vec2 atlasSize;

varying vec3 vNormal;
varying vec3 vColor;
varying float vOpacity;
varying float vIsGrayScaled;

void main()
{
	vNormal = normal;
	vColor = color;
	vOpacity = opacity;
	vIsGrayScaled = isGrayScaled;
	gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(position, 1.0);
}
`;

	private _fragmentShader: string = `precision highp float;

//https://www.shadertoy.com/view/XdXSzX
#define SRGB_COEFFICIENTS vec3(0.2126, 0.7152, 0.0722)

varying vec3 vNormal;
varying vec3 vColor;
varying float vOpacity;
varying float vIsGrayScaled;

float lambertian()
{
	vec3 light1 = normalize(vec3(-0.5, -1, 0.3));
	vec3 light2 = normalize(vec3(2, 1, 0.5));
	vec3 light3 = normalize(vec3(-0.2, 1, 0.75));

	float result = clamp(dot(vNormal, light1), 0.0, 1.0);
	result += clamp(dot(vNormal, light2), 0.0, 1.0);
	result += clamp(dot(vNormal, light3), 0.0, 1.0);

	return clamp(result, 0.1, 2.0);
}

void main()
{
	vec4 outputColor = vec4(vColor.rgb * lambertian(), vOpacity);
	gl_FragColor = vIsGrayScaled > 0.5 ? vec4(vec3(dot(outputColor.rgb, SRGB_COEFFICIENTS)), outputColor.a) : outputColor;
}
`;

	constructor() {
		super({transparent: true, side: DoubleSide});

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;

			program.uniforms = {
				// Add uniforms
			};
		};
	}
}

import {Formatter} from "./format/Formatter";

export class DateUtils {
	public static sortByDate(a: Date, b: Date) {
		return a < b ? 1 : -1;
	}

	public static sortByTime(a: Date, b: Date) {
		const hour1 = Formatter.leftPad(a.getHours(), 2, "0");
		const hour2 = Formatter.leftPad(b.getHours(), 2, "0");
		const minute1 = Formatter.leftPad(a.getMinutes(), 2, "0");
		const minute2 = Formatter.leftPad(b.getMinutes(), 2, "0");

		return `${hour1}${minute1}` < `${hour2}${minute2}` ? 1 : -1;
	}

	public static sameDay(date1: Date, date2: Date) {
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
	}

	public static sameMonth(date1: Date, date2: Date) {
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
	}

	public static sameTime(date1: Date, date2: Date) {
		return date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes();
	}

	public static parse(dateString: string): Date {
		let result = new Date(dateString);

		if (isNaN(result.getTime())) {
			result = new Date();
			// check if the dateString is in HH:MM SS format eg. 04:14 AM, 12:35PM...
			if (/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(dateString)) {
				//split by " " and ":" characters
				const split = dateString.split(/[\s:]+/);
				const hours = (parseInt(split[0]) === 12 ? 0 : parseInt(split[0])) + (split[2].toLowerCase() === "pm" ? 12 : 0);
				const minutes = parseInt(split[1]);

				result.setHours(hours);
				result.setMinutes(minutes);
			}
		}
		return result;
	}

	public static dateValidation(dateString: string) {
		const result = new Date(dateString);
		const year = result.getFullYear();

		if (year < 1000 || year > 9999) {
			return false;
		}

		return !isNaN(result.getTime());
	}

	public static stringify(date: Date) {
		return date?.toISOString() || "";
	}

	public static today() {
		return new Date().toLocaleDateString();
	}

	public static todayStringified() {
		return new Date().toISOString();
	}

	private static formatDateUS(date: Date): string {
		const months: string[] = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const day: number = date.getDate();
		const month: string = months[date.getMonth()];
		const year: number = date.getFullYear();

		const suffix: string = this.getDaySuffix(day);

		return `${day}${suffix} ${month}, ${year}`;
	}

	private static getDaySuffix(day: number): string {
		if (day >= 11 && day <= 13) {
			return "th";
		}
		switch (day % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	}

	public static todayUSFormat() {
		return this.formatDateUS(new Date());
	}

	public static getWeekNumber(d: Date): [number, number] {
		// https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php

		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
		var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		var weekNo = Math.ceil(((d.getTime() - (yearStart as any)) / 86400000 + 1) / 7);

		return [d.getUTCFullYear(), weekNo];
	}

	public static parseDateRange(dateRange: string) {
		if (!dateRange) {
			return {from: "", to: ""};
		}

		const dates = dateRange.split(" - ");
		const from = dates[0];
		const to = dates[1];

		return {from, to};
	}

	public static stringifyDateRange(dateRange: {from: Date; to: Date}) {
		const from = DateUtils.stringify(dateRange.from);
		const to = DateUtils.stringify(dateRange.to);

		return `${from} - ${to}`;
	}
}

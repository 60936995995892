import * as React from "react";
import styled from "styled-components";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import HideIcon from "../../icons/hide.svg?react";
import UnhideIcon from "../../icons/unhide.svg?react";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {FlexCenterStyle} from "../../styles/styles";

interface IPasswordInputProps {
	value?: string;
	onInput: (val: string) => void;
	infoText?: string;
	placeholder?: string;
	isTooltip?: boolean;
	isTextInfo?: boolean;
	divRef?: React.RefObject<HTMLDivElement>;
}

interface IPasswordInputState {
	hidePassword: boolean;
}

export class PasswordInputV5 extends React.Component<IPasswordInputProps, IPasswordInputState> {
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	constructor(props: IPasswordInputProps) {
		super(props);

		this.state = {
			hidePassword: true,
		};
	}

	private toggleHiding = () => {
		this.setState((prevState) => ({hidePassword: !prevState.hidePassword}));
	};

	public override render() {
		const {value, placeholder, infoText, isTooltip, isTextInfo, divRef} = this.props;
		const {hidePassword} = this.state;

		return (
			<PasswordInputStyled className="PasswordInput hbox alignCenter">
				{isTooltip ? (
					<TextInputV5
						value={value}
						onInput={(val) => this.props.onInput(val)}
						inputType={hidePassword ? "password" : "text"}
						placeholder={placeholder}
						errorMessage={infoText}
						isTooltip={isTooltip}
						isTextInfo={isTextInfo}
					/>
				) : (
					<TextInputV5
						value={value}
						onInput={(val) => this.props.onInput(val)}
						inputType={hidePassword ? "password" : "text"}
						placeholder={placeholder}
						errorMessage={infoText}
						isTooltip={isTooltip}
						isTextInfo={isTextInfo}
						divRef={(divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
					/>
				)}
				{hidePassword ? (
					<IconButtonV5
						IconComponent={HideIcon}
						onClick={this.toggleHiding}
						className="hideUnhideIcon"
					/>
				) : (
					<IconButtonV5
						IconComponent={UnhideIcon}
						onClick={this.toggleHiding}
						className="hideUnhideIcon"
					/>
				)}
			</PasswordInputStyled>
		);
	}
}

const PasswordInputStyled = styled.div`
	${FlexCenterStyle}
	position: relative;

	input {
		width: 240px;
	}

	.hideUnhideIcon {
		right: 0;
		position: absolute;
	}

	.infoIcon {
		right: 35px;
		top: 8px;
	}
`;

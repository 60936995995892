interface IFileDropperConfig {
	accept: string;
	targetElement: Element;
	onDragOver: (event: DragEvent) => boolean;
	onDrop: (event: DragEvent) => void;
	autoEnable?: boolean;
}

/**
 *
 * Allows an element to accept files to be dropped onto.
 *
 * See http://www.html5rocks.com/en/tutorials/dnd/basics/
 *
 */
export class FileDropper {
	public static droppingCls = "dropping_files";

	public static hasFiles(event: DragEvent): boolean {
		let hasFiles = false;

		const types = event.dataTransfer.types;

		if (types.indexOf("Files") > -1) {
			hasFiles = true;
		}
		// if ((<String[]><any>types).indexOf)
		// {
		// 	// types is String[]
		// 	if ((<String[]><any>types).indexOf("Files") > -1)
		// 	{
		// 		hasFiles = true;
		// 	}
		// }
		// else if ((<DOMStringList><any>types).contains && (<DOMStringList><any>types).contains("Files"))
		// {
		// 	// types is a DOMStringList
		// 	hasFiles = true;
		// }

		return hasFiles;
	}

	protected _config: IFileDropperConfig;

	// TODO this is a hack..
	//http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
	protected _counter = 0;

	constructor(config: IFileDropperConfig) {
		this._config = config;

		if (config.autoEnable !== false) {
			this.enable();
		}
	}

	public checkType(files: FileList) {
		// Careful, eg.: "images/png" should be accepted, when props.accept === "image/*"
		// Also, ".glb" should be accepted when props.accept === ".glb,.glTF", which is not a mime type, but an extension
		const acceptThese = this._config.accept.split(",").map((v) => v.trim());
		const acceptRegExp = new RegExp(acceptThese.join("|").replace("*", ".+").replace(".", ""), "i");

		for (let i = 0; i < files.length; ++i) {
			const file = files[i];

			for (const acceptType of acceptThese) {
				if (!acceptType.includes("/")) {
					if (file.name.includes(acceptType)) {
						return true;
					}
				} else if (!file.type.match(acceptRegExp)) {
					return false;
				}
			}
		}

		return true;
	}

	public enable() {
		const targetElement = this._config.targetElement;

		targetElement.addEventListener("dragenter", this.onDragEnter);
		targetElement.addEventListener("dragleave", this.onDragLeave);
		targetElement.addEventListener("dragover", this.onDragOverHandler);
		targetElement.addEventListener("drop", this.onDropHandler);
		targetElement.addEventListener("dragend", this.onDragEnd);

		document.addEventListener("dragover", this.preventDefault);
		document.addEventListener("drop", this.preventDefault);
	}

	public disable() {
		const targetElement = this._config.targetElement;

		targetElement.removeEventListener("dragenter", this.onDragEnter);
		targetElement.removeEventListener("dragleave", this.onDragLeave);
		targetElement.removeEventListener("dragover", this.onDragOverHandler);
		targetElement.removeEventListener("drop", this.onDropHandler);
		targetElement.removeEventListener("dragend", this.onDragEnd);

		document.removeEventListener("dragover", this.preventDefault);
		document.removeEventListener("drop", this.preventDefault);
	}

	private preventDefault = (event: Event) => {
		event.preventDefault();
	};

	private onDragEnter = (event: DragEvent) => {
		// Called when the mouse starts to hover over the targetElement.
		if (!FileDropper.hasFiles(event)) {
			// In case we're dragging just a text from the dom, we should ignore that
			return;
		}

		this._counter++;

		this.showHighlight();
	};

	private onDragLeave = (event: DragEvent) => {
		// Called when the mouse stops to hover over the targetElement and when the escape is pressed.
		// Not on drop.
		if (!FileDropper.hasFiles(event)) {
			// In case we're dragging just a text from the dom, we should ignore that
			return;
		}

		this._counter--;

		if (this._counter === 0) {
			this.hideHighlight();
		}
	};

	private onDragOverHandler = (event: DragEvent) => {
		// This runs multiple times when hovering over the element, even when the mouse is not moving.

		this.onDragOver(event);
	};

	protected onDragOver(event: DragEvent) {
		// Without this, the browser would navigate away.
		event.preventDefault();

		if (this.checkFiles(event)) {
			// "none", "copy", "link", "move"
			event.dataTransfer.dropEffect = "move";
			this.showHighlight();
		} else {
			event.dataTransfer.dropEffect = "none";
			this.hideHighlight();
		}
	}

	protected checkFiles(event: DragEvent) {
		if (this._config.onDragOver(event)) {
			return true;
		}

		return false;
	}

	private onDragEnd = (event: DragEvent) => {
		// Called when a drag is ended: releasing the mouse or hitting the escape key.
		// Note: in practise this doesn't seem to run at all.
	};

	private onDropHandler = (event: DragEvent) => {
		this.onDrop(event);
	};

	protected onDrop(event: DragEvent) {
		event.preventDefault();
		this._counter = 0;

		this.hideHighlight();

		if (this._config.onDrop) {
			this._config.onDrop(event);
		}
	}

	protected showHighlight() {
		this._config.targetElement.classList.add(FileDropper.droppingCls);
	}

	protected hideHighlight() {
		this._config.targetElement.classList.remove(FileDropper.droppingCls);
	}
}

import * as React from "react";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import type {Markup} from "../../../../../data/models/Markup";
import {MarkupType} from "../../../../../generated/api/base";
import {MarkupTextActionBarV5} from "./MarkupTextActionBarV5";
import {MarkupTextInputElementV5} from "./MarkupTextInputElementV5";
import {SpaceActionBarStyled} from "./SpaceActionBar.styled";

interface IMarkupTextModifierProps {
	style: React.CSSProperties;
	barRef: React.Ref<HTMLDivElement>;
	spaceViewRenderer: SpaceViewRenderer;
	markup3D: Markup3D;
	onClose: () => void;
}

export class MarkupTextModifierV5 extends React.Component<IMarkupTextModifierProps> {
	private _inputRef = React.createRef<MarkupTextInputElementV5>();
	private _stringifiedTextObjectOnMount: string;

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.props.onClose();
				break;
		}
	};

	private exitTextMode(markup3D: Markup3D) {
		const {spaceViewRenderer} = this.props;

		(markup3D.modelData as Markup).text.content = (this._inputRef.current?.innerText ?? "").replace(/\n+$/g, ""); // remove newlines from the end of the string;
		markup3D.isTextInHtmlEditMode = false;

		const doesContainOnlyWhiteSpace = markup3D.textContent.trim().length === 0;

		if (markup3D.type === MarkupType.Text_Box && doesContainOnlyWhiteSpace) {
			markup3D.destroy(true);
			spaceViewRenderer.spaceItemController.updateActionBar();
		} else {
			const newStringifiedTextObjectOnMount = JSON.stringify(markup3D.text);

			if (this._stringifiedTextObjectOnMount !== newStringifiedTextObjectOnMount) {
				spaceViewRenderer.markupManager.updateItems([markup3D], true);
				this._stringifiedTextObjectOnMount = newStringifiedTextObjectOnMount;
			}
		}
		spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		spaceViewRenderer.needsRender = true;
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	public override componentDidMount() {
		this.props.markup3D.isTextInHtmlEditMode = true;
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);

		this._stringifiedTextObjectOnMount = JSON.stringify(this.props.markup3D.text);
	}

	public override UNSAFE_componentWillUpdate(nextProps: Readonly<IMarkupTextModifierProps>, nextState: Readonly<{}>, nextContext: any): void {
		if (nextProps.markup3D !== this.props.markup3D) {
			this.exitTextMode(this.props.markup3D);
			this.props.onClose();
		}
	}

	public override componentWillUnmount() {
		this.props.markup3D.isTextInHtmlEditMode = false;
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);

		const {markup3D} = this.props;

		this.exitTextMode(markup3D);
	}

	public override render() {
		return (
			<>
				<MarkupTextInputElementV5
					ref={this._inputRef}
					spaceViewRenderer={this.props.spaceViewRenderer}
					markup3D={this.props.markup3D}
				/>
				<SpaceActionBarStyled
					ref={this.props.barRef}
					className="textStyleModifierContainer"
					style={this.props.style}
				>
					<MarkupTextActionBarV5
						forceUpdateArrow={this.forceUpdateArrow}
						markup3D={this.props.markup3D}
						onClose={this.props.onClose}
					/>
				</SpaceActionBarStyled>
			</>
		);
	}
}

import * as React from "react";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";

interface IPopupManagerProps {
	keyboardListener: KeyboardListener;
}

interface IPopupManagerState {
	window: PopupWindow;
	props: any;
}

type PopupWindow = React.ComponentType<{}>;

export class PopupManager extends React.Component<IPopupManagerProps, IPopupManagerState> {
	private static instance: PopupManager;

	constructor(props: IPopupManagerProps) {
		super(props);

		this.state = {
			window: null,
			props: {},
		};

		if (PopupManager.instance) {
			console.warn("You shouldn't use two PopupManagers, this is a singleton class (for now).");
		}
		PopupManager.instance = this;
	}

	public open(window: PopupWindow, props: any) {
		this.setState({window: window, props: props});

		this.props.keyboardListener.signals.down.add(this.onKeyDown, this);
	}

	public override render() {
		if (this.state.window) {
			const Child = this.state.window;

			return (
				<Child
					{...this.state.props}
					onClose={this.onPopupClose}
					keyboardListener={this.props.keyboardListener}
				/>
			);
		}
		return null;
	}

	private onPopupClose = () => {
		this.setState({window: null, props: null});
		this.props.keyboardListener.signals.down.remove(this.onKeyDown, this);
	};

	private onKeyDown(event: KeyboardEvent) {
		if (event.code === KeyboardListener.KEY_ESCAPE) {
			this.onEscapeKeyPressed();

			event.preventDefault();
			this.props.keyboardListener.signals.down.halt();
		}
	}

	private onEscapeKeyPressed() {
		this.onPopupClose();
	}
}

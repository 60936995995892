import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import {Field} from "../../../../widgets/form/field/Field";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {XyiconFeature, FieldDataType} from "../../../../../generated/api/base";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import {UserGroupUsers} from "./UserGroupUsers";

interface IUserGroupDetailsTabProps {
	userGroups: UserGroup[];
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class UserGroupDetailsTab extends React.Component<IUserGroupDetailsTabProps> {
	private isNameValid = (value: string) => {
		const {appState, userGroups} = this.props;

		return appState.actions.isUserGroupNameValid(value, userGroups[0]);
	};

	private onNameChange = (value: string) => {
		if (this.isNameValid(value)) {
			this.props.userGroups[0].name = value;
			this.updateApi();
		}
	};

	private onBlur = () => {
		const userGroup = this.props.userGroups[0];

		if (userGroup) {
			this.updateApi();
		}
	};

	private onUsersChanged = () => {
		this.updateApi();
	};

	private updateApi() {
		const userGroup = this.props.userGroups[0];

		if (userGroup.id) {
			// we're in edit mode
			this.props.transport.services.feature.update(userGroup.id, XyiconFeature.UserGroup, {
				name: userGroup.name,
				userIDList: userGroup.userIds,
			});
		}
	}

	private get userGroupMemberCount() {
		const {appState, userGroups} = this.props;

		return userGroups[0].userIds.filter((id) => appState.actions.getFeatureItemById(id, XyiconFeature.User)).length;
	}

	public override render() {
		const {userGroups, appState} = this.props;
		const userGroup = userGroups[0];

		return (
			<div className="DetailsTab">
				{userGroups.length === 0 && <div className="noData">{"Please select a User Group."}</div>}
				{userGroups.length === 1 && (
					<>
						<div className="header">
							<Field label="Name">
								<ClickToEditInput
									disabled={userGroup.isSystem}
									value={userGroup.name}
									onChange={this.onNameChange}
									onBlur={this.onBlur}
									dataType={FieldDataType.SingleLineText}
									valueValidator={this.isNameValid}
									noButtons={true}
								/>
							</Field>
							<Field label="Users">{this.userGroupMemberCount}</Field>
							<Field label="Last Updated">{DateFormatter.format(userGroup.lastModifiedAt)}</Field>
							<Field label="Last Updated By">{appState.actions.renderName(userGroup.lastModifiedBy)}</Field>
						</div>
						<UserGroupUsers
							userGroup={userGroup}
							onChange={this.onUsersChanged}
						/>
					</>
				)}
			</div>
		);
	}
}

import * as React from "react";

import {ChartWidget} from "../ChartWidget";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../utils/ReactUtils";

export interface IPieChartProps<T> {
	data: T[];
	labels: {
		title: string;
		selector: (item: T) => boolean;
	}[];
	onExpand: () => void;
	unknownColumn?: boolean;
}

interface IPieChartState {
	selectedIndex: number;
}

interface ILabelData {
	title: string;
	count: number;
}

export class PieChart<T = {}> extends React.PureComponent<IPieChartProps<T>, IPieChartState> {
	private _size = [300, 300];
	private _colors = ["#4CAF50", "#00BCD4", "#E91E63", "#FFC107", "#9E9E9E", "#CDDC39"];

	constructor(props: IPieChartProps<T>) {
		super(props);

		this.state = {
			selectedIndex: -1,
		};
	}

	private getLabelData() {
		const {data, labels} = this.props;
		const result: ILabelData[] = [];

		for (let i = 0; i < labels.length; ++i) {
			const label = labels[i];

			result.push({
				title: label.title,
				count: 0,
			});
		}

		result.push({
			title: "Unknown",
			count: 0,
		});

		for (const row of data) {
			let labelFound = false;

			for (let i = 0; i < labels.length; ++i) {
				const label = labels[i];

				if (label.selector(row)) {
					result[i].count++;
					labelFound = true;
					break;
				}
			}

			if (!labelFound) {
				// nothing matched -> put to Undefined
				result[result.length - 1].count++;
			}
		}

		return result;
	}

	private getLabelColor(index: number) {
		return this._colors[index % this._colors.length];
	}

	private onHover = (index: number) => {
		this.setState({selectedIndex: index});
	};

	private onHoverOut = () => {
		this.setState({selectedIndex: -1});
	};

	public override render() {
		const {data} = this.props;
		const labelData = this.getLabelData();

		let l = this._size[0] / 2;
		let R = 0;

		const interacting = this.state.selectedIndex !== -1;

		return (
			<ChartWidget
				className={ReactUtils.cls("PieChart", {interacting: interacting})}
				onExpand={this.props.onExpand}
			>
				<>
					<svg
						viewBox={`0, 0, ${this._size[0]}, ${this._size[1]}`}
						className={ReactUtils.cls({interacting: interacting})}
						transform={"rotate(-90)"}
						onMouseLeave={this.onHoverOut}
						onMouseOut={this.onHoverOut}
					>
						{labelData.map((label, index) => {
							const percent = label.count / data.length;
							const ratio = Math.min(0.99999, percent);
							const a = ratio * Math.PI * 2;
							const arcSweep = a > Math.PI ? 1 : 0;

							const L = l;

							const d = `M${0},${0} L${L},${0} A${L},${L} 1 ${arcSweep},1 ${L * Math.cos(a)}, ${L * Math.sin(a)} z`;

							const color = this.getLabelColor(index);

							const title = `${label.title} - ${label.count} (${MathUtils.setPrecision(percent * 100, 1)}%)`;
							const res = (
								<path
									key={index}
									fill={color}
									d={d}
									onMouseOver={(event) => this.onHover(index)}
									className={ReactUtils.cls("slice", {selected: index === this.state.selectedIndex})}
									transform={`rotate(${R * MathUtils.RAD2DEG}, ${L}, ${L}) translate(${L}, ${L})`}
								>
									<title>{title}</title>
								</path>
							);

							R = R + a;

							return res;
						})}
					</svg>
					<div className="legend">
						{labelData.map((label, index) => (
							<div
								key={index}
								className={ReactUtils.cls("entry", {selected: index === this.state.selectedIndex})}
								onMouseOver={(event) => this.onHover(index)}
								onMouseOut={this.onHoverOut}
								onMouseLeave={this.onHoverOut}
							>
								<div
									className="rect"
									style={{backgroundColor: this.getLabelColor(index)}}
								/>
								{label.title}
							</div>
						))}
					</div>
				</>
			</ChartWidget>
		);
	}
}

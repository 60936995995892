import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import {PopupWindowV5} from "./PopupWindowV5";
import type {IPopupWindowConfigV5} from "./PopupWindowV5";

/**
 * Can be used as an alternative for `confirm("confirmMessage");`
 */
export class ConfirmWindowV5 extends PopupWindowV5<boolean | null> {
	protected _okValue = true;
	protected _cancelValue = false;

	protected static override readonly _defaultConfig: IPopupWindowConfigV5 = {
		ok: "Delete",
		cancel: "Cancel",
		backdrop: false,
	};

	constructor(message: string, title: string, config: IPopupWindowConfigV5) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(ConfirmWindowV5._defaultConfig, config),
		});
	}

	public static open(message: string, title: string = "Confirm Delete", config: IPopupWindowConfigV5 = {}) {
		return new ConfirmWindowV5(message, title, config).open() as Promise<boolean>;
	}
}

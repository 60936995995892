import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Type} from "../../../../../data/models/Type";
import type {AppState} from "../../../../../data/state/AppState";
import type {XyiconFeature} from "../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {TypeForm} from "./form/TypeForm";

interface ITypeDetailsTabProps {
	selected: Type[];
	fieldsFeature: XyiconFeature;
	appState?: AppState;
	transport?: TransportLayer;
}

interface ITypeDetailsTabState {}

@inject("appState")
@inject("transport")
@observer
export class TypeDetailsTab extends React.Component<ITypeDetailsTabProps, ITypeDetailsTabState> {
	constructor(props: ITypeDetailsTabProps) {
		super(props);
		this.state = {};
	}

	public override render() {
		const {selected, fieldsFeature} = this.props;
		const type = selected[0];

		return (
			<>
				{selected.length === 0 && <div className="noData">Please select a type.</div>}
				{selected.length === 1 && (
					<TypeForm
						type={type}
						fieldsFeature={fieldsFeature}
						avatar={true}
					/>
				)}
			</>
		);
	}
}

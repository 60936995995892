import * as React from "react";
import {inject, observer} from "mobx-react";
import {EmptyListView} from "../grid/EmptyListView";
import type {IModel} from "../../../../data/models/Model";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {XyiconFeature} from "../../../../generated/api/base";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {NoResultFilterView} from "../grid/NoResultFilterView";
import {NoResultSearchView} from "../grid/NoResultSearchView";
import {Card} from "./Card";

interface ICardViewProps<T extends IModel> {
	feature: XyiconFeature;
	items: T[];
	filterSuppression?: boolean;
	selected: T[];
	onSelect: (item: T[]) => void;
	onDoubleClick: (item: T) => void;
	onAddClick: () => void;
	appState?: AppState;
	transport?: TransportLayer;
	search: string;
}

@inject("appState")
@inject("transport")
@observer
export class CardView<T extends IModel> extends React.Component<ICardViewProps<T>> {
	private onMouseUp = (event: MouseEvent) => {
		const target = event.target;

		if (target instanceof HTMLElement && (target.className.includes("mainpanel") || target.parentElement.className.includes("mainpanel"))) {
			this.props.onSelect([]);
		}
	};

	public override componentDidMount(): void {
		requestAnimationFrame(() => {
			document.addEventListener("mouseup", this.onMouseUp);
		});
	}

	public override componentWillUnmount(): void {
		document.removeEventListener("mouseup", this.onMouseUp);
	}

	public override render() {
		const {items: items_, search, filterSuppression, feature, appState, selected, onSelect, onDoubleClick, onAddClick} = this.props;
		const searchedItems = appState.actions.searchModels(items_, search, feature);

		const items = searchedItems.slice().sort((a: IModel, b: IModel) => StringUtils.sortIgnoreCase(a.name, b.name));

		const hasSearchSuppression = items_.length !== items.length;

		return (
			<div className="CardView hbox">
				{items.length > 0 ? (
					items.map((item: T) => (
						<Card
							key={item.id}
							selected={selected.includes(item)}
							item={item}
							onSelect={onSelect}
							onDoubleClick={onDoubleClick}
						/>
					))
				) : filterSuppression ? (
					<NoResultFilterView />
				) : hasSearchSuppression ? (
					<NoResultSearchView search={search} />
				) : (
					<EmptyListView
						onAddClick={onAddClick}
						feature={feature}
					/>
				)}
			</div>
		);
	}
}

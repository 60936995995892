import type {AppState} from "../state/AppState";
import {CSVExporter} from "./CSVExporter";
import {ExcelExporter} from "./ExcelExporter";
import {PDFExporter} from "./PDFExporter";
import type {BaseExporter, ExportFormat} from "./BaseExporter";

export abstract class ExporterFactory {
	public static createExporter(format: ExportFormat, appState: AppState) {
		let exporter: BaseExporter;

		if (format === "csv") {
			exporter = new CSVExporter(appState);
		} else if (format === "excel") {
			exporter = new ExcelExporter(appState);
		} else if (format === "pdf") {
			exporter = new PDFExporter(appState);
		}

		return exporter;
	}
}

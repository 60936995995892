import * as React from "react";
import {inject} from "mobx-react";
import {findDOMNode} from "react-dom";
import {DomPortal} from "../../../portal/DomPortal";
import type {AppState} from "../../../../../../data/state/AppState";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import {ArrayUtils} from "../../../../../../utils/data/array/ArrayUtils";
import {TextInput} from "../../../../../widgets/input/text/TextInput";
import type {TransformObj} from "../../../../../../utils/dom/DomUtils";
import {DomUtils} from "../../../../../../utils/dom/DomUtils";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import {TimeUtils} from "../../../../../../utils/TimeUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";

interface IAnyParamProps {
	param: any[];
	onChange: (value: any[]) => void;
	appState?: AppState;
	field?: IFieldAdapter;
}

interface IAnyParamState {
	value: string;
	transform: TransformObj;
	isInputFocused: boolean;
	tags: string[];
}

@inject("appState")
export class AnyParam extends React.Component<IAnyParamProps, IAnyParamState> {
	private _element = React.createRef<HTMLDivElement>();
	private _list = React.createRef<HTMLDivElement>();

	constructor(props: IAnyParamProps) {
		super(props);
		this.state = {
			value: "",
			transform: null,
			isInputFocused: false,
			tags: [],
		};
	}

	public static getDerivedStateFromProps(props: IAnyParamProps, state: IAnyParamState) {
		if (props.param) {
			return {
				tags: props.param,
			} as IAnyParamState;
		}
		return null;
	}

	private getSuggestionList(value: string) {
		const {tags} = this.state;
		const {appState, field} = this.props;
		const feature = field.feature;
		const models = appState.actions.getList(feature);

		const suggestions = models
			.map((model) => appState.actions.renderValue(model, field.refId))
			.filter((val) => val && !tags.includes(val) && val.toLowerCase?.().startsWith(value.toLowerCase()))
			.sort((a, b) => StringUtils.sortIgnoreCase(a, b));

		return ArrayUtils.removeDuplicates(suggestions);
	}

	private onInputValue = (value: string) => {
		this.setState({value});
	};

	private onFocus = async () => {
		this.setState({isInputFocused: true});

		await TimeUtils.wait(500);
		FocusLoss.listen(this._list.current, this.onFocusLoss);
	};

	private onFocusLoss = (event?: MouseEvent) => {
		const {value} = this.state;
		let eventTargetInModalContainer = null;

		if (event.target instanceof Element) {
			eventTargetInModalContainer = this.props.appState.app.modalContainer.contains(findDOMNode(event.target));
		}

		if (event && !eventTargetInModalContainer) {
			this.addTag(value);
			this.setState({isInputFocused: false});
		}
	};

	private removeTag = (index: number) => {
		const tags = ArrayUtils.removeAtIndex(this.state.tags, index);

		this.setState({tags});
		this.props.onChange(tags);
	};

	private addTag = (value: string) => {
		const tags = [...this.state.tags];

		if (!tags.includes(value) && value) {
			tags.push(value);
			this.props.onChange(tags);
		}
		this.setState({tags, value: ""});
	};

	private onKeyDown = (event: React.KeyboardEvent, value: string) => {
		switch (event.key) {
			case KeyboardListener.KEY_TAB:
			case KeyboardListener.KEY_ENTER:
				this.addTag(value);
				break;
		}
	};

	public override componentDidMount() {
		if (!this.props.param) {
			this.props.onChange(null);
		}
	}

	public override componentDidUpdate(prevProps: IAnyParamProps, prevState: IAnyParamState) {
		if (!this.props.param) {
			this.props.onChange(null);
		}

		if (
			this._element.current &&
			this._list.current &&
			(prevState.isInputFocused !== this.state.isInputFocused || prevState.tags.length !== this.state.tags.length)
		) {
			this.setState({transform: DomUtils.getFixedFloatingElementPosition(this._element.current, this._list.current)});
		}
	}

	public override render() {
		const {appState} = this.props;
		const {transform, value, isInputFocused, tags} = this.state;
		const suggestions = this.getSuggestionList(value);

		let inlineStyle: React.CSSProperties = {
			width: this._element.current?.getBoundingClientRect().width,
			transform: transform?.translate,
			position: "absolute",
		};

		return (
			<div
				className="AnyParam"
				ref={this._element}
			>
				{tags.slice().map((option, index) => {
					return (
						<div
							className="item hbox"
							key={index}
						>
							<div className="label">{option}</div>
							<IconButton
								icon="close"
								className="delete"
								onClick={() => this.removeTag(index)}
							/>
						</div>
					);
				})}
				<div className="list-search">
					<TextInput
						value={this.state.value || ""}
						onInput={this.onInputValue}
						inputType="text"
						autoFocus={true}
						onKeyDown={this.onKeyDown}
						onClick={this.onFocus}
					/>
				</div>
				{isInputFocused && (
					<DomPortal destination={appState.app.modalContainer}>
						<div
							className="AnyParamInput__open-list-container"
							style={inlineStyle}
							ref={this._list}
						>
							<div className="open-list">
								{suggestions.map((suggestion, index) => (
									<div
										key={index}
										className="item"
										onClick={() => this.addTag(suggestion)}
									>
										<div className="label">{suggestion}</div>
									</div>
								))}
							</div>
						</div>
					</DomPortal>
				)}
			</div>
		);
	}
}

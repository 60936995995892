import styled from "styled-components";
import {Functions} from "../../../utils/function/Functions";
import {InfoButtonV5} from "../button/InfoButtonV5";
import {ToggleSwitchV5} from "../widgets/switch/ToggleSwitchV5";
import {fontSize} from "../styles/styles";

interface IToggleSwitchFieldProps {
	value: boolean;
	onChange: (value: boolean) => void;
	label: string;
	noBooleanLabel?: boolean;
	disabled?: boolean;
	labelFirst?: boolean; // changes the order of toggler and label
	classNames?: string;
	divRef?: React.RefObject<HTMLDivElement>;
	bubbleText?: string;
	onMouseOver?: () => void;
	onMouseLeave?: () => void;
}

export const ToggleSwitchFieldV5 = (props: IToggleSwitchFieldProps) => {
	const {value, label, classNames, labelFirst, disabled, divRef, bubbleText, noBooleanLabel, onMouseLeave, onMouseOver, onChange} = props;

	const onLabelClick = (event: React.MouseEvent) => {
		onChange(!value);
	};

	let style: React.CSSProperties = {};

	if (labelFirst) {
		style.flexDirection = "row-reverse";
		style.justifyContent = "flex-end";
	}

	return (
		<ToggleSwitchFieldStyled
			ref={divRef}
			className={`ToggleSwitchField ${classNames || ""}`}
			style={style}
			onMouseOver={onMouseOver ? onMouseOver : Functions.emptyFunction}
			onMouseLeave={onMouseLeave ? onMouseLeave : Functions.emptyFunction}
		>
			<ToggleSwitchV5
				value={value}
				onChange={onChange}
				disabled={disabled ?? false}
				noLabel={noBooleanLabel}
			/>
			<label
				className="label"
				onClick={onLabelClick}
			>
				{label}
			</label>
			{bubbleText && <InfoButtonV5 bubbleText={bubbleText} />}
		</ToggleSwitchFieldStyled>
	);
};

export const ToggleSwitchFieldStyled = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
	gap: 8px;

	label {
		font-size: ${fontSize.lg};
	}
`;

import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {PointDouble} from "../../../../../../../../generated/api/base";
import type {MarkupABDimensions, ICornerLetter} from "./MarkupAB";
import {MarkupAB} from "./MarkupAB";
import type {IMarkupConfig} from "./MarkupUtils";

export abstract class MarkupAB1D extends MarkupAB {
	protected _dimensions: MarkupABDimensions = 1;
	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, config);
	}

	protected processAB(A: PointDouble, B: PointDouble, isLocal: boolean, keepAspectRatio: boolean, fixedPoint: ICornerLetter = "a") {
		return this.process1DAB(A, B, isLocal, keepAspectRatio, fixedPoint);
	}
}

import type {Scene} from "three";
import {BufferGeometry, LineLoop, BufferAttribute, LineBasicMaterial, Group, Mesh, DynamicDrawUsage} from "three";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {BasicMaterial} from "../materials/BasicMaterial";
import type {SpaceItemController} from "../managers/spaceitems/SpaceItemController";
import {Constants} from "../Constants";
import type {IBox2} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";

/**
 * This is the blue rectangle that the user can create with their mouse to select multiple items at once
 */

export class SelectionBox {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _pointer: {
		startX: number;
		startY: number;
		currentX: number;
		currentY: number;
	};
	private _container: Scene;
	private _group: Group;
	private _spaceItemController: SpaceItemController;
	private _borderGeometry: BufferGeometry;
	private _fill: Mesh;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._container = spaceViewRenderer.topLayerScene;
		this._spaceItemController = spaceViewRenderer.spaceItemController;
		this._pointer = {
			startX: null,
			startY: null,
			currentX: null,
			currentY: null,
		};

		const borderVertices = new Float32Array(12); // 4 vertices, 3 coords (xyz) each

		this._borderGeometry = new BufferGeometry();
		this._borderGeometry.setAttribute("position", new BufferAttribute(borderVertices, 3));
		(this._borderGeometry.attributes.position as BufferAttribute).setUsage(DynamicDrawUsage);

		const color = Constants.COLORS.SELECTION_BOX;

		const borderMaterial = new LineBasicMaterial({color: color, depthTest: false});
		const line = new LineLoop(this._borderGeometry, borderMaterial);

		line.frustumCulled = false;

		this._fill = new Mesh(spaceViewRenderer.planeGeometry, new BasicMaterial(color, 0.2, true));
		this._fill.frustumCulled = false;

		this._group = new Group();
		this._group.name = "selectionBox";
		this._group.add(line);
		this._group.add(this._fill);
		this._group.visible = false;
		THREEUtils.add(this._container, this._group);
	}

	/**
	 *
	 * @param x X world coordinate of the cursor
	 * @param y Y world coordinate of the cursor
	 */
	public startSelecting(x: number, y: number) {
		this._pointer.startX = x;
		this._pointer.startY = y;
	}

	public cursorMovedTo(x: number, y: number) {
		if (this._pointer.startX === null || this._pointer.startY === null) {
			/**
			 * Can happen, eg.: when the user moves the camera, and meanwhile presses ctrl
			 */
			this.startSelecting(x, y);
		}
		this._pointer.currentX = x;
		this._pointer.currentY = y;

		this.updateBox();

		this._group.visible = true;
		this._spaceViewRenderer.needsRender = true;
	}

	private updateBox() {
		const box = this.getBoxData();

		const positionAttribute = this._borderGeometry.attributes.position as BufferAttribute;

		positionAttribute.setXYZ(0, box.min.x, box.min.y, 0);
		positionAttribute.setXYZ(1, box.max.x, box.min.y, 0);
		positionAttribute.setXYZ(2, box.max.x, box.max.y, 0);
		positionAttribute.setXYZ(3, box.min.x, box.max.y, 0);

		positionAttribute.needsUpdate = true;

		const size = THREEUtils.getSizeOfBoundingBox2(box);
		const center = THREEUtils.getCenterOfBoundingBox(box);

		this._fill.position.setX(center.x);
		this._fill.position.setY(center.y);
		this._fill.scale.setX(size.x);
		this._fill.scale.setY(size.y);
	}

	private getBoxData(): IBox2 {
		const smallerX = Math.min(this._pointer.startX, this._pointer.currentX);
		const smallerY = Math.min(this._pointer.startY, this._pointer.currentY);

		const largerX = Math.max(this._pointer.startX, this._pointer.currentX);
		const largerY = Math.max(this._pointer.startY, this._pointer.currentY);

		return {
			min: {
				x: smallerX,
				y: smallerY,
			},
			max: {
				x: largerX,
				y: largerY,
			},
		};
	}

	private selectItemsInsideBox(box: IBox2) {
		this._spaceItemController.selectItemsInsideRectangle(box.min.x, box.min.y, box.max.x, box.max.y);
	}

	private isAnyPropNull() {
		return this._pointer.startX == null || this._pointer.startY == null || this._pointer.currentX == null || this._pointer.currentY == null;
	}

	public endSelecting(selectItemsInside: boolean) {
		if (selectItemsInside && !this.isAnyPropNull()) {
			const box = this.getBoxData();

			this.selectItemsInsideBox(box);
		}
		this._pointer.startX = this._pointer.startY = this._pointer.currentX = this._pointer.currentY = null;
		this._group.visible = false;
	}
}

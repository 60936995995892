import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes} from "../FieldDataTypes";
import {FilterOperator} from "../../filter/operator/FilterOperator";
import {MassFieldBooleanInput} from "../../../../ui/modules/abstract/sidepanel/tabs/details/field/mass/datatypes/MassFieldBooleanInput";
import type {IBooleanFieldSettingsDefinition} from "../../../../ui/modules/settings/modules/field/datatypes/BooleanFieldSettings";
import {BooleanFieldSettings} from "../../../../ui/modules/settings/modules/field/datatypes/BooleanFieldSettings";
import {ToggleSwitch} from "../../../../ui/widgets/button/switch/ToggleSwitch";
import {ToggleSwitchV5} from "../../../../ui/5.0/widgets/switch/ToggleSwitchV5";
import {BooleanFieldSettingsV5} from "../../../../ui/5.0/details/BooleanFieldSettingsV5";
import {MassFieldBooleanInputV5} from "../../../../ui/5.0/details/datatypes/mass/MassFieldBooleanInputV5";

/**
 * Notes:
 * If value is undefined / null, it will be converted to false, this is so we can
 * treat explicit false values the same as the default undefined value, because we don't
 * want to have 3 states for a boolean field.
 */
const parseBoolean = (value: string) => {
	if (value === "false") {
		return false;
	}
	return !!value;
};

const renderBoolean = (value: boolean, settings: IBooleanFieldSettingsDefinition) => {
	if (value === undefined || value === null) {
		return "";
	}
	return value ? settings.displayLabelForTrue : settings.displayLabelForFalse;
};

export function configureBoolean() {
	FieldDataTypes.configure({
		id: FieldDataType.Boolean,
		title: "Boolean",
		converter: parseBoolean,
		formatter: renderBoolean,
		input: ToggleSwitch,
		inputV5: ToggleSwitchV5,
		operatorsArray: [FilterOperator.IS_TRUE, FilterOperator.IS_FALSE],
		settings: BooleanFieldSettings,
		settingsV5: BooleanFieldSettingsV5,
		massInputV5: MassFieldBooleanInputV5,
		massEditInput: MassFieldBooleanInput,
		defaultSettings: {
			displayLabelForTrue: "Yes",
			displayLabelForFalse: "No",
		} as IBooleanFieldSettingsDefinition,
	});
}

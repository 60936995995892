import {useRef, useState} from "react";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {Report} from "../../../data/models/Report";
import {ReportScope, ReportType} from "../../../generated/api/reports";
import {notify} from "../../../utils/Notify";
import {NotificationType} from "../../notification/Notification";
import type {Portfolio} from "../../../data/models/Portfolio";
import type {IActionBarItem} from "../abstract/ModuleViewV5";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {WarningWindow} from "../../modules/abstract/popups/WarningWindow";
import {useAppStore} from "../../../StateManager";
import PlayIcon from "../icons/play.svg?react";
import CloneIcon from "../icons/clone.svg?react";
import DeleteIcon from "../icons/delete.svg?react";
import PenWithLineIcon from "../icons/pen-with-line.svg?react";
import ShareIcon from "../icons/share.svg?react";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {ReportSharingPopup} from "../sharing/ReportSharingPopup";
import {RunReportPageV5} from "./report/create/wizard/run/RunReportPageV5";
import {ReportPanelV5} from "./report/create/ReportPanelV5";

interface IReportViewV5Props {
	param1?: any;
}

export const ReportViewV5 = (props: IReportViewV5Props) => {
	const _moduleRef = useRef<ModuleViewV5<Report>>();
	const createButtonRef = useRef<HTMLDivElement>();
	const appState = useAppStore((state) => state.appState);
	const navBarState = useAppStore((state) => state.navBarState);
	const {transport} = appState.app;

	const [newReport, setNewReport] = useState<Report>(Report.createNew(appState, XyiconFeature.Report));
	const [editReport, setEditReport] = useState<Report>(Report.createNew(appState));
	const [runningReport, setRunningReport] = useState<Report>(null);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [reportForSharePanel, setReportForSharePanel] = useState<Report | null>(null);

	const onRunReport = (report: Report) => {
		runReport([report]);
	};

	// Called when clicking on action bar and from TableRow
	const runReport = async (reports: Report[]) => {
		const report = reports[0];

		if (report.scope === ReportScope.PortfolioIDList && report.portfolioIDList.length === 0) {
			await WarningWindow.open("You have to select a portfolio for the report to be able to run it!");
			return;
		}
		if (!hasAccessToRunReport(report)) {
			await WarningWindow.open("You do not have access to run this report.");
			return;
		}

		if (report.type === ReportType.UserDefinedReport || report.type === ReportType.UserDefinedLinkedXyiconReport) {
			// user defined report -> run it and open RunReport window
			setRunningReport(report);

			await transport.services.feature.runSavedReport(report);
		} else {
			// custom report -> download / email it

			const notification = notify(appState.app.notificationContainer, {
				type: NotificationType.Message,
				title: "One moment please, we are running your report",
				description: "You will receive a notification when your report is ready for download.",
				lifeTime: 10000,
			});

			await transport.services.feature.runSavedReport(report);

			if (report.deliveryMethod !== "Email") {
				await report.onLoaded();
				if (report.errorMessage) {
					notify(appState.app.notificationContainer, {
						type: NotificationType.Error,
						title: report.errorMessage,
						lifeTime: Infinity,
					});
				} else {
					notification.onClose();
					notify(appState.app.notificationContainer, {
						lifeTime: 10000,
						type: NotificationType.Message,
						title: `${report.name} is ready for download`,
						buttonLabel: "Download",
						onActionButtonClick: () => {
							report.downloadReport();
						},
					});
				}
			}
		}
	};

	const hasAccessToRunReport = (report: Report) => {
		if (report.ownerUserID === appState.user?.id) {
			return true;
		}
		if (appState.user?.isAdmin) {
			return true;
		}

		const portfolioId = report.getPortfolio(transport.appState);
		const portfolio = appState.actions.getFeatureItemById<Portfolio>(portfolioId, XyiconFeature.Portfolio);

		// If portfolio is in the list of loaded portfolios -> user has access, otherwise not.
		return !!portfolio;
	};

	const onAddClick = (feature: XyiconFeature) => {
		setNewReport(Report.createNew(appState, feature));
		_moduleRef.current?.switchToCreatingMode();
	};

	const onDuplicateClick = (report: Report[]) => {
		setEditReport(report[0].duplicate());
		setIsEditing(true);
	};

	const onEditClick = (report: Report) => {
		report.startEditing();
		setEditReport(report);
		setIsEditing(true);
	};

	const onCloseReport = () => {
		setRunningReport(null);
	};

	const canEditReports = (reports: Report[]) => {
		for (const report of reports) {
			// cannot edit custom designed reports
			if (!report.isUserDefined) {
				return false;
			}

			// check permission
			const permission = Report.getPermission(report, appState);

			if (permission < Permission.Update) {
				return false;
			}
		}

		return true;
	};

	const canDeleteReports = (reports: Report[]) => {
		if (reports.length < 1) {
			return false;
		}

		for (const report of reports) {
			// cannot delete custom designed reports
			if (!report.isUserDefined) {
				return false;
			}

			// check permission
			const permission = Report.getPermission(report, appState);

			if (permission < Permission.Delete) {
				return false;
			}
		}

		return true;
	};

	const canCreateReports = () => {
		return Report.canCreateReports(appState);
	};

	const filterReports = (report: Report) => {
		// this hides reports that shouldn't be shown for the logged in user
		const permission = Report.getPermission(report, appState);

		return permission >= Permission.View;
	};

	const getActionBar = () => {
		const result: IActionBarItem<Report>[] = [
			{
				id: "play",
				title: "Run Report",
				label: "Run",
				onClick: runReport,
				IconComponent: PlayIcon,
				visible: (selectedItem) => selectedItem.length === 1,
			},
		];

		if (appState.user?.isAdmin) {
			result.push(
				...([
					{
						id: "duplicate",
						title: "Duplicate Report",
						label: "Duplicate",
						IconComponent: CloneIcon,
						visible: (reports: Report[]) => reports.length === 1 && canCreateReports() && reports[0].isUserDefined,
						onClick: onDuplicateClick,
					},
					{
						id: "edit",
						title: "Edit Report",
						label: "Edit",
						IconComponent: PenWithLineIcon,
						visible: (selectedItems) => selectedItems.length === 1,
						onClick: (selectedItems) => onEditClick(selectedItems[0] as Report),
						enabled: canEditReports,
					},
					{
						id: "share",
						title: "Share Report",
						label: "Share",
						IconComponent: ShareIcon,
						visible: (selectedItems) => selectedItems.length === 1,
						onClick: (selectedItems) => setReportForSharePanel(selectedItems[0] as Report),
						enabled: canCreateReports,
					},
					{
						id: "delete",
						title: "Delete Report",
						label: "Delete",
						IconComponent: DeleteIcon,
						visible: () => true,
						enabled: canDeleteReports,
					},
				] as IActionBarItem<Report>[]),
			);
		}

		return result;
	};

	const onCloseEditingReports = () => {
		setIsEditing(false);
	};

	// TODO
	// constructor(props: IReportViewProps)
	// {
	// 	props.app.reportView = this;
	// }

	{
		return (
			<>
				<ModuleViewV5
					feature={XyiconFeature.Report}
					className="ReportView"
					viewBar={false}
					manageColumns={false}
					filterPermissions={filterReports}
					actionBar={getActionBar()}
					canDeleteReports={canDeleteReports}
					create={(onClose) => (
						<DomPortal destination={appState.app.modalContainer}>
							<ReportPanelV5
								report={newReport}
								onRunReport={onRunReport}
								parentRef={createButtonRef}
								onClose={(report?: Report) => {
									onClose();
									setNewReport(Report.createNew(appState, XyiconFeature.Report));
									if (!report) {
										// reportId is falsy when closing, truthy when saving / creating
										report?.cancelEditing();
									}
								}}
							/>
						</DomPortal>
					)}
					createButtonRef={createButtonRef}
					emptyListText={
						appState.user?.isAdmin ? null : (
							<>
								No reports are shared.
								<br />
								Contact your Administrator for assistance.
							</>
						)
					}
				/>
				{runningReport && (
					<RunReportPageV5
						report={runningReport}
						onClose={onCloseReport}
						parentRef={createButtonRef}
						onEditClick={onEditClick}
						isNavbarDocked={navBarState == "docked"}
					/>
				)}
				{isEditing && editReport && (
					<ReportPanelV5
						report={editReport}
						onRunReport={onRunReport}
						parentRef={createButtonRef}
						onClose={(reportId?: string) => {
							onCloseEditingReports();
							if (!reportId) {
								// reportId is falsy when closing, truthy when saving / creating
								editReport?.cancelEditing();
							}
						}}
					/>
				)}
				{reportForSharePanel && (
					<ReportSharingPopup
						report={reportForSharePanel}
						onClose={() => setReportForSharePanel(null)}
					/>
				)}
			</>
		);
	}
};

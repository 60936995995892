import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import type {IFieldDataTypeSettingsProps} from "./IFieldDataTypeSettingsProps";

export enum IDateFieldFormat {
	DATE = "date",
	TIME = "time",
	DATETIME = "datetime",
	DATERANGE = "daterange",
	TIMERANGE = "timerange",
}

export interface IDateFieldSettingsDefinition {
	format: IDateFieldFormat;
}

@observer
export class DateFieldSettings extends React.Component<IFieldDataTypeSettingsProps> {
	private static readonly _options: {id: IDateFieldFormat; label: string}[] = [
		{
			id: IDateFieldFormat.DATE,
			label: "Date Only",
		},
		{
			id: IDateFieldFormat.TIME,
			label: "Time Only",
		},
		{
			id: IDateFieldFormat.DATETIME,
			label: "Date + Time",
		},
	];

	public override render() {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as IDateFieldSettingsDefinition;

		return (
			<>
				<Field label="Format">
					<SelectInput
						options={DateFieldSettings._options}
						selected={DateFieldSettings._options.find((option) => option.id === settings.format)}
						onChange={(option) => {
							settings.format = option.id;
							onChange();
						}}
						render={(option) => option.label}
					/>
				</Field>
			</>
		);
	}
}

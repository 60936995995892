import {FileType} from "../../generated/api/base";

export const getFileTypeLabel = (fileType: FileType = FileType.DOC) => {
	return FileType[fileType] || "DOC";
};

const imageExtensions = ["svg", "jpg", "jpeg", "jpe", "png", "tiff", "gif", "bmp", "webm"];

const openableInNewTabExtensions = ["pdf", "html", "htm"];

export const isImage = (fileExtension: string) => {
	return imageExtensions.includes(fileExtension);
};

export const isOpenableInNewTab = (fileExtension: string) => {
	return openableInNewTabExtensions.includes(fileExtension);
};

// const imageTypes = [
// 	FileType.SVG,
// 	FileType.JPG,
// 	FileType.PNG,
// 	FileType.TIFF,
// 	FileType.GIF,
// 	FileType.BMP
// ];

// export const isImage = (fileType: FileType) =>
// {
// 	return imageTypes.includes(fileType);
// }

// export const isImageType = (fileName: string) =>
// {
// 	const ext = FileUtils.getExtension(fileName) || "";
// 	const extCaps = ext.toUpperCase();
// 	for (const type of imageTypes)
// 	{
// 		if (getFileTypeLabel(type) === extCaps)
// 		{
// 			return true;
// 		}
// 	}
// 	return false;
// }

import styled from "styled-components";
import type {MouseEvent} from "react";
import CloseIcon from "../../../icons/xmark.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

interface ISelectedObjectsBubbleProps {
	readonly numberOfSelectedObjects: number;
	readonly onClick: () => void;
	readonly onCloseClick: () => void;
}

export const SelectedObjectsBubble = (props: ISelectedObjectsBubbleProps) => {
	return (
		<SelectedObjectsBubbleStyled onClick={props.onClick}>
			{props.numberOfSelectedObjects} selected
			<IconButtonV5
				IconComponent={CloseIcon}
				title="Close"
				onClick={(e: MouseEvent<HTMLDivElement>) => {
					e.stopPropagation();
					props.onCloseClick();
				}}
			/>
		</SelectedObjectsBubbleStyled>
	);
};

const SelectedObjectsBubbleStyled = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: ${radius.sm};
	background-color: ${colorPalette.primary.c500Primary};
	gap: 8px;
	padding-left: 8px;
`;

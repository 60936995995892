import type {InstancedMesh, LineSegments, Mesh, Vector3} from "three";
import {PerspectiveCamera} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {SelectionBox} from "../../elements3d/SelectionBox";
import {RotationChanger} from "../RotationChanger";
import type {Xyicon3D} from "../../elements3d/Xyicon3D";
import type {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import {Constants} from "../../Constants";
import type {Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import {ZLineManager} from "../../managers/ZLineManager";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import {Permission, MarkupType} from "../../../../../../../generated/api/base";
import type {Boundary} from "../../../../../../../data/models/Boundary";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {isUserTryingToPutItOnTopOfHoveredItems} from "../../../ui/toolbar/EmbeddedUtils";
import {Tool} from "./Tool";
import type {ToolType} from "./Tools";

export enum SelectionToolAction {
	TRANSLATE,
	ROTATE,
	SELECT,
	SHOW_LINKS,
	SHOW_EMBEDDED,
	GHOST_MODE,
}

const cursorLockStyle = `url(src/assets/images/spaceviewer/lockActive.svg) 11 11, pointer`;
const cursorRotationHoverStyle = `url(src/assets/images/spaceviewer/rotation_hover.svg) 8 9, pointer`;
const cursorRotationGrabStyle = cursorRotationHoverStyle; //`url(src/assets/images/spaceviewer/rotation_grab.svg) 9 9, pointer`;

export class SelectionTool extends Tool {
	private _rotationChanger: RotationChanger;
	private _selectionBox: SelectionBox;
	protected override _toolType: ToolType = "selection";

	public currentAction: SelectionToolAction;
	private _spaceItemAtCoords: SpaceItem = null;
	private _isSomethingBeingTranslated: boolean = false;
	private _grabbedObjectType: "vertex" | "line" = null;
	private _isPointerDown: boolean = false;
	private _hoveredElement: SpaceItem = null;
	private _hoveredLinkIconInstanceId: number = -1;
	private _areOnlyXyiconsDragged: boolean = false;
	private _toolTipForLink = document.createElement("div");

	private _isZDown: boolean = false;
	private _savedPointerY: number = 0;
	private _zLineManager: ZLineManager;
	private _worldPointOnHoveredXyicon: Vector3 | null = null;

	private _previouslySelectedItems: SpaceItem[] = [];

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, true, "default");
		this.currentAction = null;
		this._rotationChanger = new RotationChanger(this._spaceItemController);
		this._selectionBox = spaceViewRenderer.selectionBox;
		this._zLineManager = new ZLineManager(spaceViewRenderer);

		this._toolTipForLink.classList.add("InfoBubble", "flexCenter");
		this._toolTipForLink.style.position = "absolute";
		this._toolTipForLink.textContent = "Ctrl + Click to follow link";
	}

	public override activate() {
		const hasChanged = super.activate();

		if (hasChanged) {
			KeyboardListener.getInstance().signals.windowBlur.add(this.reset);
			KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
			KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		}

		return hasChanged;
	}

	public override deactivate() {
		const hasChanged = super.deactivate();

		if (hasChanged) {
			this._hoveredElement?.mouseOut();
			this.resetKeyboardValues();
			KeyboardListener.getInstance().signals.windowBlur.remove(this.reset);
			KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
			KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);

			this._isZDown = false;
		}

		return hasChanged;
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case "z":
			case "Z":
				this._isZDown = true;
				this._savedPointerY = this._currentPointerXY.y;
				this._spaceItemController.startTranslatingSelectedItems(this._currentPointerWorld.x ?? 0, this._currentPointerWorld.y ?? 0);
				this._zLineManager.update();
				break;
		}
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case "z":
			case "Z":
				this._isZDown = false;
				this._zLineManager.destroyAll();
				this._spaceItemController.stopTranslatingSelectedItems();
				this._spaceItemController.startTranslatingSelectedItems(this._currentPointerWorld.x ?? 0, this._currentPointerWorld.y ?? 0);
				this._pointerWorldStart.x = this._currentPointerWorld.x;
				this._pointerWorldStart.y = this._currentPointerWorld.y;
				break;
		}
	};

	private isItemLinkableToSelectedItems(spaceItem: SpaceItem) {
		return (
			spaceItem &&
			this._spaceViewRenderer.toolManager.isInLinkMode &&
			this._spaceViewRenderer.toolManager.linkManager.isXyiconLinkableToSelectedXyicons(spaceItem)
		);
	}

	private isMeshAtCoordsMsdfText(meshAtCoords: Mesh | InstancedMesh) {
		return meshAtCoords.name === "markupTexts" || meshAtCoords.name.includes("captionTexts");
	}

	private getLinkAtCursorFromMesh(meshAtCoords: Mesh | InstancedMesh): string | null {
		if (this.isMeshAtCoordsMsdfText(meshAtCoords)) {
			const spaceItem = this._spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords) as Markup3D | Xyicon3D | BoundarySpaceMap3D;

			if (spaceItem) {
				const objectWithText = spaceItem.spaceItemType === "markup" ? (spaceItem as Markup3D) : (spaceItem as Xyicon3D | BoundarySpaceMap3D).caption;
				const instanceId = meshAtCoords.userData.instanceIds[0];

				return this._spaceViewRenderer.actions.getLinkFromMsdfTextInstanceId(objectWithText, instanceId);
			}
		}

		return null;
	}

	private updateTooltipForLink(pointer: Pointer) {
		if (!this._toolTipForLink.parentElement) {
			document.body.appendChild(this._toolTipForLink);
		}

		this._toolTipForLink.style.left = `${pointer.pageX}px`;
		this._toolTipForLink.style.top = `${pointer.pageY}px`;
	}

	private hideTooltipForLink() {
		this._toolTipForLink.remove();
	}

	private updateLinkLines(changeSelection: boolean = true) {
		const selectedItems = this._spaceItemController.selectedItems;
		const finalChangeSelection = changeSelection && !selectedItems.some((s) => s.id === this._spaceItemAtCoords.modelData.id);
		const fromXyiconIds = finalChangeSelection
			? [this._spaceItemAtCoords.modelData.id]
			: selectedItems.filter((selectedItem) => selectedItem.spaceItemType === "xyicon").map((selectedItem) => selectedItem.id);

		this._spaceItemController.openLinks(fromXyiconIds, finalChangeSelection);
	}

	public updateCursorStyle(pointer: Pointer, checkForMsdfTexts: boolean = true) {
		if (this._isZTranslateActive) {
			this._domElement.style.cursor = "move";
		} else if (this._spaceViewRenderer.toolManager.cameraControls.isCameraGrabbed || this._spaceViewRenderer.ghostModeManager.isActive) {
			return;
		} else {
			this._hoveredElement?.mouseOut();
			this._hoveredElement = null;
			this._worldPointOnHoveredXyicon = null;
			const requestAnimationFrameId = requestAnimationFrame(() => this.hideTooltipForLink());

			this._linkIconManager.mouseOut(this._hoveredLinkIconInstanceId);

			const isInLinkMode = this._spaceViewRenderer.toolManager.isInLinkMode;

			this._domElement.style.cursor = isInLinkMode ? "copy" : "default";

			const x = pointer.localX;
			const y = pointer.localY;

			const {meshAtCoords} = this._spaceItemController.getMeshAtCoords(x, y);

			if (meshAtCoords) {
				this._domElement.style.cursor = isInLinkMode ? "copy" : "pointer";

				if (meshAtCoords.name === "linkIcon") {
					const associatedXyicon = this._spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords) as Xyicon3D;

					this._hoveredLinkIconInstanceId = associatedXyicon.linkInstanceId;
					this._linkIconManager.mouseOver(this._hoveredLinkIconInstanceId);
				} else if (checkForMsdfTexts && this.isMeshAtCoordsMsdfText(meshAtCoords)) {
					cancelAnimationFrame(requestAnimationFrameId);
					this._domElement.style.cursor = "default";

					const link = this.getLinkAtCursorFromMesh(meshAtCoords);

					if (link) {
						this.updateTooltipForLink(pointer);
						if (KeyboardListener.isCtrlDown) {
							this._domElement.style.cursor = "pointer";
						}
					} else {
						const spaceItemAtCoords = this._spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords);

						if (spaceItemAtCoords?.type === MarkupType.Text_Box) {
							this.updateCursorStyle(pointer, false);
						}
					}
				} else if (meshAtCoords.name === "rotationIcon") {
					this._domElement.style.cursor = cursorRotationHoverStyle;
				} else if (meshAtCoords.name !== "embeddedIcon") {
					this._hoveredElement = this._spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords);

					if (["xyicon", "boundary"].includes(this._hoveredElement?.spaceItemType)) {
						const spaceItem = this._hoveredElement.modelData as Xyicon | Boundary;

						const permission = this._spaceViewRenderer.actions.getModuleTypePermission(spaceItem.typeId, spaceItem.ownFeature);

						if (permission < Permission.Update) {
							this._domElement.style.cursor = "not-allowed";
						}
					}
				}

				if (this._spaceItemController.isInEditMode && meshAtCoords.name !== "rotationIcon") {
					if (meshAtCoords.name.includes("grabbableCorner")) {
					} else if (
						this._spaceItemController.currentlyEditedItem.spaceItemType === "boundary" &&
						this._spaceItemController.currentlyEditedItem.isObjectPartOfThis(meshAtCoords)
					) {
						this._domElement.style.cursor = KeyboardListener.isCtrlDown ? "move" : "copy";
					} else {
						this._domElement.style.cursor = "default";
					}
				} else {
					if (this._hoveredElement?.mouseOver) {
						const isInLinkMode = this._spaceViewRenderer.toolManager.isInLinkMode;

						if (!isInLinkMode || (isInLinkMode && this.isItemLinkableToSelectedItems(this._hoveredElement))) {
							this._hoveredElement.mouseOver();
						}
					}
				}
			}
		}
	}

	public onPointerDownCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		this._isPointerDown = true;

		const x = pointer.localX;
		const y = pointer.localY;

		this._spaceItemAtCoords = null;

		this.hideTooltipForLink();

		const {meshAtCoords} = this._spaceItemController.getMeshAtCoords(x, y);

		const isAppVersionV5 = this._spaceViewRenderer.transport.appState.currentUIVersion === "5.0";

		if (this.currentAction === SelectionToolAction.GHOST_MODE) {
		} else {
			if (meshAtCoords) {
				const pointerDownOnElement = () => {
					if (!this._spaceViewRenderer.toolManager.isInLinkMode) {
						if (!KeyboardListener.isCtrlDown) {
							if (!this._spaceItemAtCoords.isSelected || isAppVersionV5) {
								this._spaceItemController.deselectAll(false);
								this._spaceItemAtCoords.select();

								if (isAppVersionV5) {
									if (pointer.originalEvent.detail === 2) {
										this._spaceItemController.updateDetailsPanel(true, true);
									}
								} else {
									this._spaceItemController.updateDetailsPanel(true);
								}
							}
							this._spaceItemController.startTranslatingSelectedItems(worldX, worldY);
							this.currentAction = SelectionToolAction.TRANSLATE;

							this._areOnlyXyiconsDragged =
								this._spaceItemController.selectedItems.length === this._spaceItemController.xyiconManager.selectedItems.length;
						}
					}
				};

				this._spaceItemAtCoords = this._spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords);
				if (meshAtCoords.name === "rotationIcon") {
					const pivot = this._spaceItemAtCoords.position;

					this._rotationChanger.startRotating(worldX, worldY, pivot.x, pivot.y, this._spaceItemAtCoords.typeName);
					this.currentAction = SelectionToolAction.ROTATE;
					this._domElement.style.cursor = cursorRotationGrabStyle;
				} else if (meshAtCoords.name === "linkIcon") {
					this.currentAction = SelectionToolAction.SHOW_LINKS;
				} else if (meshAtCoords.name === "embeddedIcon") {
					this.currentAction = SelectionToolAction.SHOW_EMBEDDED;
				} else if (this.isMeshAtCoordsMsdfText(meshAtCoords)) {
					if (KeyboardListener.isCtrlDown) {
						const link = this.getLinkAtCursorFromMesh(meshAtCoords);

						if (link) {
							this._domElement.style.cursor = "";
							this.reset();
							KeyboardListener.getInstance().resetFlags();
							const linkWithProtocol = link.startsWith("http") ? link : `https://${link}`;

							this._spaceViewRenderer.transport.appState.app.navigation.openInNewTab(linkWithProtocol);
						}
					}

					if (this._spaceItemAtCoords?.type === MarkupType.Text_Box) {
						pointerDownOnElement();
					} else {
						this._spaceItemAtCoords = null;
					}
				} else {
					if (this._spaceItemController.isInEditMode) {
						if (meshAtCoords.name.includes("grabbableCorner")) {
							this._grabbedObjectType = "vertex";
							this._spaceItemController.onGrabbableCornerPointerDown(meshAtCoords.userData.grabbableCorner);
							this.currentAction = SelectionToolAction.TRANSLATE;
						} else if (
							this._spaceItemController.currentlyEditedItem.spaceItemType === "boundary" &&
							this._spaceItemController.currentlyEditedItem.isObjectPartOfThis(meshAtCoords as any as LineSegments)
						) {
							if (KeyboardListener.isCtrlDown) {
								this._grabbedObjectType = "line";
								this._boundaryManager.onLinePointerDown(parseInt(meshAtCoords.name), worldX, worldY, false);
								this.currentAction = SelectionToolAction.TRANSLATE;
							} else {
								this._grabbedObjectType = "vertex";
								const newCorner = this._boundaryManager.onLinePointerDown(parseInt(meshAtCoords.name), worldX, worldY, true);

								this._boundaryManager.onGrabbableCornerPointerDown(newCorner);
								this.currentAction = SelectionToolAction.TRANSLATE;
							}
						}
					} else {
						pointerDownOnElement();
					}
				}
			} else {
				if (!KeyboardListener.isCtrlDown) {
					this._spaceItemController.deselectAll();
					this._spaceViewRenderer.toolManager.linkManager.disableLinkMode();
					this._domElement.style.cursor = "default";
				}

				this._selectionBox.startSelecting(worldX, worldY);
				this.currentAction = SelectionToolAction.SELECT;
			}
		}

		if (this._isZDown) {
			this._savedPointerY = this._currentPointerXY.y;
			this._zLineManager.update();
		}
	};

	protected onPointerMoveCallback = (pointer: Pointer, worldX: number, worldY: number) => {
		const isGhostModeActive = this._spaceViewRenderer.ghostModeManager.isActive;

		if (isGhostModeActive) {
			this.currentAction = SelectionToolAction.GHOST_MODE;
			this._domElement.style.cursor = "grab";
			this._spaceViewRenderer.ghostModeManager.pointerMoveCallback(pointer, worldX, worldY);
		} else {
			if (this._isPointerDown && MathUtils.isValidNumber(worldX) && MathUtils.isValidNumber(worldY)) {
				if (!this._spaceViewRenderer.toolManager.cameraControls.isPanning && !KeyboardListener.isSpaceDown) {
					if (this._isZTranslateActive) {
						const {cameraControls} = this._spaceViewRenderer.toolManager;
						const deltaZ = this._savedPointerY - this._currentPointerXY.y;
						const fineTunedDeltaZ =
							(deltaZ * Math.sin(cameraControls.polarAngle) * this._spaceViewRenderer.correctionMultiplier.current) / cameraControls.cameraZoomValue;

						this._spaceItemController.translateSelectedItems(0, 0, fineTunedDeltaZ);
					} else {
						this._hoveredElement?.mouseOut();
						this._hoveredElement = null;
						this._worldPointOnHoveredXyicon = null;

						const delta = {
							x: worldX - this._pointerWorldStart.x,
							y: worldY - this._pointerWorldStart.y,
						};

						if (this.currentAction === SelectionToolAction.SHOW_LINKS || this.currentAction === SelectionToolAction.SHOW_EMBEDDED) {
							if (
								Math.abs(pointer.localX - pointer.startX) > Constants.CLICK_MOVEMENT_THRESHOLD ||
								Math.abs(pointer.localY - pointer.startY) > Constants.CLICK_MOVEMENT_THRESHOLD
							) {
								this.currentAction = null;
							}
						}

						const selectedItems = this._spaceItemController.selectedItems;

						if (this.currentAction === SelectionToolAction.TRANSLATE) {
							if (selectedItems.length > 0) {
								if (this._grabbedObjectType === "vertex") {
									this._spaceItemController.onGrabbableCornerPointerMove(delta.x, delta.y);
									this._domElement.style.cursor = "grabbing";
								} else if (this._grabbedObjectType === "line") {
									this._boundaryManager.onLinePointerMove(delta.x, delta.y);
									this._domElement.style.cursor = "grabbing";
								} else {
									const selectedItems = this._spaceItemController.selectedItems;
									const selectedItemsThatUserCanUpdate = this._spaceViewRenderer.actions.filterSpaceItemsForPermission(
										selectedItems,
										Permission.Update,
									);
									const selectedItemsThatUserCanMove = selectedItemsThatUserCanUpdate.filter((spaceItem: SpaceItem) => !spaceItem.isPositionLocked);

									this._domElement.style.cursor =
										selectedItemsThatUserCanMove.length === 0
											? selectedItemsThatUserCanUpdate.length === 0
												? "not-allowed"
												: cursorLockStyle
											: "grabbing";
									this._isSomethingBeingTranslated = true;
									let finalZ: number = 0;

									if (this.areSelectedXyiconsEmbeddable && selectedItemsThatUserCanMove.length > 0) {
										// Embedding xyicon:
										// increase size of the hovered xyicon if the selected ones are embeddable into it
										const excludedSpaceItemIDList: string[] = [];

										for (const selectedXyicon of this._spaceViewRenderer.xyiconManager.selectedItems) {
											if (selectedXyicon.modelData?.id) {
												excludedSpaceItemIDList.push(selectedXyicon.modelData.id);
											}
										}
										const intersection = this._spaceItemController.getMeshAtCoords(
											pointer.localX,
											pointer.localY,
											this._spaceViewRenderer.xyiconManager.getIntersectables(),
											excludedSpaceItemIDList,
										);
										const {meshAtCoords} = intersection;

										this._worldPointOnHoveredXyicon = intersection.point;

										if (this.isUserTryingToPutItOnTopOfHoveredItems && this._worldPointOnHoveredXyicon) {
											delta.x = this._worldPointOnHoveredXyicon.x - this._pointerWorldStart.x;
											delta.y = this._worldPointOnHoveredXyicon.y - this._pointerWorldStart.y;
											finalZ = this._worldPointOnHoveredXyicon.z + Constants.EPSILON * this._spaceViewRenderer.correctionMultiplier.current;
										} else {
										}

										if (meshAtCoords) {
											if (this.isUserTryingToPutItOnTopOfHoveredItems) {
												// console.log(`x: ${this._worldPointOnHoveredXyicon.x}, y: ${this._worldPointOnHoveredXyicon.y}, z: ${this._worldPointOnHoveredXyicon.z}`);
											} else {
												this._hoveredElement = this._spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords);
												(this._hoveredElement as Xyicon3D)?.mouseOver(true);
											}
										}
									}

									this._spaceItemController.translateSelectedItems(delta.x, delta.y, finalZ);
								}
							}
						} else if (this.currentAction === SelectionToolAction.ROTATE) {
							if (selectedItems.length > 0) {
								if (this._rotationChanger.isRotating) {
									this._rotationChanger.updateXY(worldX, worldY);
								}
							}
						} else if (this.currentAction === SelectionToolAction.SELECT) {
							this._selectionBox.cursorMovedTo(worldX, worldY);
						}
					}
				}
			} else {
				this.updateCursorStyle(pointer);
			}
		}
	};

	public onPointerUpCallback = (pointer: Pointer) => {
		// Longtap opens up the context menu. We don't want to close it on pointerup,
		// because that way it closes before the user can select anything from it
		if (this._spaceViewRenderer.inheritedMethods.isContextMenuOpen()) {
			return;
		}

		let disableLinkMode = true;

		if (
			(this.currentAction === SelectionToolAction.SHOW_LINKS || this.currentAction === SelectionToolAction.SHOW_EMBEDDED) &&
			this._spaceItemAtCoords
		) {
			if (this.currentAction === SelectionToolAction.SHOW_LINKS) {
				if (this._spaceItemController.linkLineManager.currentXyiconIds.has(this._spaceItemAtCoords.modelData.id)) {
					this._spaceItemController.closeLinks();
				} else {
					this.updateLinkLines();
				}
			} else {
				this._spaceItemController.updateEmbeddedWindow(this._spaceItemAtCoords.modelData.id);
			}
		} else {
			if (this._spaceViewRenderer.toolManager.isInLinkMode) {
				if (this.isItemLinkableToSelectedItems(this._spaceItemAtCoords)) {
					this._spaceViewRenderer.toolManager.linkManager.onEndXyiconClickInLinkMode((this._spaceItemAtCoords as Xyicon3D).modelData as Xyicon);
					disableLinkMode = false;
				}
			} else if (this.areSelectedXyiconsEmbeddableIntoHovered) {
				const {actions, spaceItemController} = this._spaceViewRenderer;
				const {selectedItems} = spaceItemController;

				const noPermissionCount = actions.getNumberOfSpaceItemsWithoutPermission(selectedItems, Permission.Update);
				const hoveredHasPermission = actions.someSpaceItemsHaveGivenPermission([this._hoveredElement], Permission.Update);

				if (noPermissionCount === 0 && hoveredHasPermission) {
					const {linkManager} = this._spaceViewRenderer.toolManager;

					linkManager.embedXyiconsIntoParentXyicon(
						this._spaceViewRenderer.xyiconManager.selectedItems as Xyicon3D[],
						this._hoveredElement as Xyicon3D,
					);
				} else {
					// put back into original position
					this._spaceViewRenderer.spaceItemController.translateSelectedItems(0, 0, 0);
				}
			} else if (this.currentAction === SelectionToolAction.GHOST_MODE && this._spaceViewRenderer.ghostModeManager.isActive) {
				this._spaceViewRenderer.inheritedMethods.onPasteClick();
			}
			// double-click on a single markup that supports text -> add/edit text
			else if (this.shouldEnterMarkupTextEditMode) {
				this._spaceViewRenderer.inheritedMethods.onSwitchToTextEditMode();
			} else if (
				!this._isSomethingBeingTranslated &&
				!this._spaceItemController.isInEditMode &&
				this._spaceItemAtCoords &&
				this.currentAction !== SelectionToolAction.ROTATE &&
				!this._isZDown
			) {
				if (!KeyboardListener.isCtrlDown) {
					this._spaceItemController.deselectAll(false);
				}
				this._spaceItemAtCoords.toggleSelection();

				if (!KeyboardListener.isCtrlDown) {
					this._spaceItemController.closeLinks();
				} else if (this._spaceItemController.linkLineManager.lines.length > 0) {
					this.updateLinkLines(false);
				}

				if (this._spaceItemAtCoords.spaceItemType === "markup" && (this._spaceItemAtCoords as Markup3D).textContent.length > 0) {
					this._spaceItemController.markupTextManager.updateTextTransformations();
				}
				this._spaceItemController.updateDetailsPanel(true);
			}
		}

		this._previouslySelectedItems = this._spaceItemController.selectedItems;

		if (disableLinkMode) {
			this._spaceViewRenderer.toolManager.linkManager.disableLinkMode();
		}

		this.reset();
		this.updateCursorStyle(pointer);
	};

	private get isUserTryingToPutItOnTopOfHoveredItems(): boolean {
		return isUserTryingToPutItOnTopOfHoveredItems();
	}

	// Returns whether they're embeddable at all
	private get areSelectedXyiconsEmbeddable() {
		return (
			this._areOnlyXyiconsDragged &&
			this._spaceViewRenderer.xyiconManager.selectedItems.every(
				(xyicon: Xyicon3D) => !xyicon.isPositionLocked && (xyicon.modelData as Xyicon).embeddedXyicons.length === 0,
			)
		);
	}

	private get areSelectedXyiconsEmbeddableIntoHovered() {
		return (
			this._isSomethingBeingTranslated &&
			this._hoveredElement &&
			this.areSelectedXyiconsEmbeddable &&
			this._spaceViewRenderer.toolManager.linkManager.isXyiconLinkableToSelectedXyicons(this._hoveredElement)
		);
	}

	private get shouldEnterMarkupTextEditMode() {
		const selectedItems = this._spaceItemController.selectedItems;

		return (
			!this._isSomethingBeingTranslated &&
			!this._spaceItemController.isInEditMode &&
			this.currentAction === SelectionToolAction.TRANSLATE &&
			selectedItems.length === 1 &&
			selectedItems[0].spaceItemType === "markup" &&
			(selectedItems[0] as Markup3D).doesSupportText &&
			ObjectUtils.compare(this._previouslySelectedItems, selectedItems)
		);
	}

	private reset = () => {
		this._isPointerDown = false;
		this._areOnlyXyiconsDragged = false;
		this._zLineManager.destroyAll();
		this._selectionBox.endSelecting(true);
		this._spaceItemController.stopTranslatingSelectedItems();
		this._rotationChanger.stopRotating();

		if (this.currentAction === SelectionToolAction.TRANSLATE) {
			this._isSomethingBeingTranslated = false;
		}

		if (this._grabbedObjectType) {
			this._spaceItemController.onGrabbableCornerPointerUp();
		}
		this._spaceItemController.updateActionBar();
		this._grabbedObjectType = null;
		this._spaceItemAtCoords = null;

		this.currentAction = null;
	};

	private resetKeyboardValues = () => {
		this._selectionBox.endSelecting(KeyboardListener.isCtrlDown);
	};

	private get _isZTranslateActive() {
		return this._isZDown && this._isPointerDown && this._spaceViewRenderer.activeCamera instanceof PerspectiveCamera;
	}

	private get _spaceItemController() {
		return this._spaceViewRenderer.spaceItemController;
	}

	private get _boundaryManager() {
		return this._spaceItemController.boundaryManager;
	}

	private get _linkIconManager() {
		return this._spaceItemController.linkIconManager;
	}

	public get isSomethingBeingTranslated() {
		return this._isSomethingBeingTranslated;
	}
}

import * as React from "react";
import {TextInput} from "../../../text/TextInput";
import type {IInputProps} from "../../FieldInput";
import type {IDateFieldSettingsDefinition} from "../../../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import {IconButton} from "../../../../button/IconButton";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";

export class EmailInput extends React.PureComponent<IInputProps<IDateFieldSettingsDefinition>> {
	private getErrorMessage = (value: string) => {
		return !value || StringUtils.emailValidator(value) ? "" : "Enter a valid Email!";
	};

	public override render() {
		const {value, onInput, onApply, onCancel, onBlur, inline, disabled, noButtons, onChange, caretPosition} = this.props;

		return (
			<div className="hbox field-input-container">
				<TextInput
					value={value}
					onInput={onInput}
					onChange={onChange}
					onBlur={onBlur}
					caretPosition={caretPosition}
					errorMessageTop={-28}
					autoFocus={true}
					disabled={disabled}
					noButtons={noButtons}
					inline={inline}
					getErrorMessage={this.getErrorMessage}
				/>
				{!disabled && !noButtons && (
					<>
						<IconButton
							className="apply"
							icon="apply"
							title="Apply"
							onClick={onApply}
						/>
						<IconButton
							className="cancel"
							icon="cancel"
							title="Cancel"
							onClick={onCancel}
						/>
					</>
				)}
			</div>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {PointDouble} from "../../../../generated/api/base";
import {ImageType} from "../../../../generated/api/base";
import {ImageUtils} from "../../../../utils/image/ImageUtils";
import {ButtonV5} from "../../button/ButtonV5";
import {FieldV5} from "../../details/FieldV5";
import {IconEditorV5} from "../../abstract/view/catalog/IconEditorV5";
import {FileDropperReactV5} from "../../interaction/FileDropperReactV5";
import {ImageUploadPreprocessor} from "../../../../utils/image/ImageUploadPreprocessor";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {Constants} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {PopupV5} from "../../popup/PopupV5";
import {colorPalette} from "../../styles/colorPalette";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {radius} from "../../styles/styles";
import {CreatePopupFieldStyled} from "../../popup/CreatePopupField.styled";
import {FieldStyled} from "../../details/Field.styled";

const OrganizationSettingsStyled = styled.div`
	.OrganizationSettings {
		position: relative;
		height: 100%;
		padding: 40px;

		.settingsContainer {
			padding: 40px;
			z-index: 0;
			width: 400px;

			.organizationname {
				padding-top: auto;
				padding-bottom: auto;
			}

			${FieldStyled} {
				margin-top: 32px;

				.label {
					min-width: 130px;
				}

				.element {
					padding-left: 12px;

					.disabledInputFeild {
						width: 300px;
						height: 32px;
						border: 1px solid ${colorPalette.gray.c300};
						border-radius: ${radius.sm};
					}
				}
			}

			.logoContainer {
				align-items: center;
				display: flex;

				.logo {
					width: 100px;
					height: 100px;
					margin-right: 64px;
					flex-shrink: 0;
					border-radius: ${radius.sm};
					border: solid 1px ${colorPalette.gray.c300};

					img {
						width: 100%;
						height: 100%;
					}

					> .noLogo {
						width: 100%;
						height: 100%;
						border: 1px dashed ${colorPalette.primary.c500Primary};
					}
				}

				.btnContainer {
					position: relative;

					> span {
						color: ${colorPalette.primary.c500Primary};
						font-size: 14px;
						position: absolute;
						left: 0;
						top: 14px;
					}

					input[type="file"] {
						opacity: 0;
						width: 200px;
					}
				}
			}
		}
	}
`;

const OrganizationLogoInputStyled = styled.div`
	.imageUpload {
		display: flex;
		flex-direction: column;
		align-items: center;

		.logoContainer {
			margin: 0;
			width: 200px;
			height: 200px;

			.IconEditor {
				width: 200px;
				height: 200px;
				position: relative;
				border-radius: 16px;
				border: solid 1px ${colorPalette.gray.c300};
				overflow: hidden;
			}

			.logo {
				margin: 0;
				width: 100%;
				height: 100%;
			}
		}

		.FileDropper {
			margin-top: 50px;
			width: 365px;
			height: 100px;
			border-width: 2px;
			margin-bottom: 10px;
			padding: 5px;
			margin-left: 10px;

			.label {
				font-size: 14px;
				transform: translate(0px, 35px);
				text-align: left;
				margin-left: 20px;
			}

			svg {
				width: 32px;
				height: 32px;
				transform: translate(0px, -35px);
			}
		}

		.buttons {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			margin-right: 15px;
		}
	}
`;

interface IOrganizationSettingsProps {
	appState?: AppState;
	transport?: TransportLayer;
}

interface IOrganizationSettingsStates {
	newImage: string;
	existingImageURL: string;
	existingImage: string;
	imageAspectRatio: number;
	iconTranslate: PointDouble;
	iconOrientation: number;
	isIconFlippedX: boolean;
	isIconFlippedY: boolean;
	scale: number;
	isSidePanelOpen: boolean;
	isPopupOpen: boolean;
	isScaleChanged: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class OrganizationSettingsV5 extends React.Component<IOrganizationSettingsProps, IOrganizationSettingsStates> {
	private _svgRef = React.createRef<SVGSVGElement>();
	private _newImageType: ImageType;
	private _prevScale: number = 1;
	private parentRef = React.createRef<HTMLDivElement>();

	constructor(props: IOrganizationSettingsProps) {
		super(props);
		this.state = {
			newImage: null,
			existingImageURL: null,
			existingImage: null,
			imageAspectRatio: 1,
			iconTranslate: {x: 0.5, y: 0.5},
			iconOrientation: 0,
			isIconFlippedX: false,
			isIconFlippedY: false,
			scale: 1,
			isSidePanelOpen: false,
			isPopupOpen: false,
			isScaleChanged: false,
		};
	}

	private onFileInputChange = async (files: FileList) => {
		if (files.length > 0) {
			const file = files[0];

			const data = await ImageUploadPreprocessor.getImageDataForUpload(file, false);

			this._newImageType = data.imageType;

			if (this._newImageType === ImageType.SVG) {
				data.fullImageData = await FileUtils.readAsText(file);
			}

			this.setState({
				newImage: data.fullImageData,
				imageAspectRatio: data.aspectRatio,
			});
		}
	};

	private onPopupOpen = () => {
		this.setState({
			isPopupOpen: true,
		});
	};

	private onPopupClose = () => {
		this.setState({
			isPopupOpen: false,
		});
	};

	private onSaveClick = async () => {
		const {newImage, scale} = this.state;
		const isScaleChanged = this._prevScale !== scale;

		if (newImage || isScaleChanged) {
			const isSVG = this._newImageType === ImageType.SVG;
			const generatedImage = await ImageUploadPreprocessor.createCompressedImageFromSVG(this._svgRef.current, isSVG, true, Constants.RESOLUTION.LOGO);
			const result = await this.props.transport
				.requestForOrganization({
					url: "organizations/uploadlogo",
					method: XHRLoader.METHOD_POST,
					params: {
						image: generatedImage,
						imageType: isSVG ? ImageType.SVG : ImageType.PNG,
					},
				})
				.catch((error) => console.log(error));

			this.props.appState.actions.getCurrentOrganization().logoVersion++;

			this.loadLogo(generatedImage);

			this._prevScale = scale;

			this.onPopupClose();
		} else {
			console.log(newImage, "No file selected, or something went wrong");
		}
	};

	private async loadLogo(orgLogoURL?: string) {
		const {transport} = this.props;
		let innerPart = this.state.existingImage;

		if (!orgLogoURL) {
			orgLogoURL = transport.getCurrentOrganizationLogo();
			const img = orgLogoURL && (await ImageUtils.loadImage(orgLogoURL));

			innerPart = img && ImageUtils.image2Base64String(img);
		}

		this.setState({
			existingImageURL: orgLogoURL,
			newImage: null,
			existingImage: innerPart,
		});
	}

	public override componentDidMount() {
		this.loadLogo();
	}

	public override render() {
		const {appState} = this.props;
		const {existingImageURL, newImage, scale, existingImage, isSidePanelOpen, isPopupOpen, imageAspectRatio, iconOrientation, iconTranslate} =
			this.state;
		const orgName = appState.actions.getCurrentOrganizationName();
		const isUserAdmin = !!appState.user?.isAdmin;

		return (
			<OrganizationSettingsStyled>
				<div className="OrganizationSettings">
					<div className="settingsContainer vbox">
						<div className="logoContainer">
							<div className="logo">
								{existingImageURL ? (
									<img
										src={existingImageURL}
										alt="Organization logo"
									/>
								) : (
									<div className="noLogo" />
								)}
							</div>
							{isUserAdmin && (
								<ButtonV5
									label={`${existingImageURL ? "Edit Logo" : "Upload Logo"}`}
									onClick={this.onPopupOpen}
									ref={this.parentRef}
								/>
							)}
						</div>
						<div className="organizationname">
							<FieldV5 label="Organization Name">
								<ClickToEditInputV5
									value={orgName}
									disabled={true}
									className="disabledInputFeild"
								/>
							</FieldV5>
						</div>
					</div>
				</div>
				{isUserAdmin && isPopupOpen && (
					<PopupV5
						onClose={this.onPopupClose}
						label="Upload Logo"
						parentRef={this.parentRef.current}
						className="OrganizationCreation"
						width="400px"
						height="480px"
						freezeRoot={true}
					>
						<CreatePopupFieldStyled>
							<OrganizationLogoInputStyled>
								<div className="imageUpload">
									<div className="logoContainer">
										{(existingImageURL || newImage) && (
											<IconEditorV5
												selectedLayer="Image"
												replaceColor={false}
												isSVG={this._newImageType === ImageType.SVG}
												innerPart={newImage || existingImage}
												imageAspectRatio={imageAspectRatio}
												iconTranslate={iconTranslate}
												iconOrientation={iconOrientation}
												scale={scale}
												onIconTranslateChange={(newTranslate: PointDouble) => this.setState({iconTranslate: newTranslate})}
												onScaleChange={(newScale: number) => this.setState({scale: newScale})}
												svgRef={this._svgRef}
												sliderWidth={150}
											/>
										)}
									</div>
									<FileDropperReactV5
										onFileInputChange={this.onFileInputChange}
										purpose="Drag and drop or click to add a new file (.csv or .xlsx)"
										accept="image/*"
										multiple={false}
									/>
									<div className="buttons">
										<ButtonV5
											label="Save"
											onClick={this.onSaveClick}
											disabled={!newImage && this._prevScale === scale}
										/>
									</div>
								</div>
							</OrganizationLogoInputStyled>
						</CreatePopupFieldStyled>
					</PopupV5>
				)}
			</OrganizationSettingsStyled>
		);
	}
}

import styled from "styled-components";

export const ReportColumnsStyled = styled.div`
	min-width: 750px;
	height: 100%;
	.InfoButtonV5 {
		top: 10px;
		color: #0b5199;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.manage-cols-box {
		grid-template-columns: 341px 28px 341px 28px;
		display: grid;
		grid-gap: 10px;
	}

	.ColumnEditor {
		max-height: calc(100% - 130px);

		.manage-cols-box {
			height: inherit;

			.col {
				max-height: 450px;

				.border {
					.views {
						height: calc(100% - 50px);
						.DetailsTab {
							padding: 0;
						}
					}

					.buttons {
						padding: 0;
						.tabLabel {
							font-size: 16px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}

	.toggleSwitchLabel {
		display: none;
	}

	.ToggleSwitchField {
		.label {
			padding: 8px 0 8px 16px;
			color: black;
		}
	}
`;

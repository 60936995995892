import type {Pointer} from "../Pointer";
import {Signal} from "../../signal/Signal";
import type {PointerDetector} from "../PointerDetector";
import {ObjectUtils} from "../../data/ObjectUtils";
import type {IGesture} from "./IGesture";

export interface IPinchZoomGestureData {
	pointer1: Pointer;
	pointer2: Pointer;
	startDistance: number;
	distance: number;
	middlePointer: Pointer; // a pointer representing the middlepoint of the 2 pointers that are currently pressed
}

/**
 * Main logic here:
 * - check if number of pointers changes (pointer is pressed / released)
 * - if 2 pointers are pressed: activate pinch zoom, otherwise: deactivate
 * - dispatch start, update, end events.
 */
export class PinchZoomGesture implements IGesture {
	public signals = {
		start: Signal.create<IPinchZoomGestureData>(),
		update: Signal.create<IPinchZoomGestureData>(),
		end: Signal.create<IPinchZoomGestureData>(),
	};

	private _pointerDetector: PointerDetector;
	private _active: boolean = false;

	private _moveData: IPinchZoomGestureData; // reuse

	constructor(pointerDetector: PointerDetector) {
		this._pointerDetector = pointerDetector;

		this._moveData = {
			pointer1: null,
			pointer2: null,
			startDistance: 0,
			distance: 0,
			middlePointer: null,
		};
	}

	public listen() {
		this._pointerDetector.signals.down.add(this.onPointerUpOrDown, this);
		this._pointerDetector.signals.up.add(this.onPointerUpOrDown, this);
	}

	private onPointerUpOrDown() {
		const pointersLength = this._pointerDetector.pointersLength;

		if (!this._active && pointersLength === 2) {
			this.activate();
		} else if (this._active && pointersLength !== 2) {
			this.deactivate();
		}
	}

	private activate() {
		this._active = true;

		this._moveData.pointer1 = this._pointerDetector.pointerArray[0];
		this._moveData.pointer2 = this._pointerDetector.pointerArray[1];

		const distance = this.calculateDistance();

		this._moveData.startDistance = distance;
		this._moveData.distance = distance;
		this._moveData.middlePointer = ObjectUtils.clone(this._moveData.pointer1);
		this.refreshMiddlePointer();

		this.signals.start.dispatch(this._moveData);

		this._pointerDetector.signals.move.add(this.onPointerMove, this);
	}

	private deactivate() {
		this._active = false;

		this.refreshDistance();
		this.refreshMiddlePointer();
		this.signals.end.dispatch(this._moveData);
	}

	protected onPointerMove() {
		// this._active should always be true because we only attach the listener in activate
		if (this._active) {
			this.refreshDistance();
			this.refreshMiddlePointer();
			this.signals.update.dispatch(this._moveData);
		}
	}

	private refreshDistance() {
		const distance = this.calculateDistance();

		this._moveData.distance = distance;
	}

	private calculateDistance() {
		const pointer1 = this._moveData.pointer1;
		const pointer2 = this._moveData.pointer2;

		const dx = pointer1.localX - pointer2.localX;
		const dy = pointer1.localY - pointer2.localY;

		return Math.sqrt(dx * dx + dy * dy);
	}

	private refreshMiddlePointer() {
		const pointer1 = this._moveData.pointer1;
		const pointer2 = this._moveData.pointer2;

		const pointer = this._moveData.middlePointer;

		pointer.dx = (pointer1.dx + pointer2.dx) / 2;
		pointer.dy = (pointer1.dy + pointer2.dy) / 2;
		pointer.offsetX = (pointer1.offsetX + pointer2.offsetX) / 2;
		pointer.offsetY = (pointer1.offsetY + pointer2.offsetY) / 2;
		pointer.localX = (pointer1.localX + pointer2.localX) / 2;
		pointer.localY = (pointer1.localY + pointer2.localY) / 2;
		pointer.pageX = (pointer1.pageX + pointer2.pageX) / 2;
		pointer.pageY = (pointer1.pageY + pointer2.pageY) / 2;

		pointer.originalEvent = pointer1.originalEvent;
		pointer.pointerData = {
			clientX: (pointer1.pointerData.clientX + pointer2.pointerData.clientX) / 2,
			clientY: (pointer1.pointerData.clientY + pointer2.pointerData.clientY) / 2,
			pageX: (pointer1.pointerData.pageX + pointer2.pointerData.pageX) / 2,
			pageY: (pointer1.pointerData.pageY + pointer2.pointerData.pageY) / 2,
			screenX: (pointer1.pointerData.screenX + pointer2.pointerData.screenX) / 2,
			screenY: (pointer1.pointerData.screenY + pointer2.pointerData.screenY) / 2,
			target: pointer1.pointerData.target,
		};
	}

	public get lastData() {
		return this._moveData;
	}
}

import type {PDFPageDrawTextOptions, PDFImage} from "pdf-lib";
import {PDFDocument, rgb} from "pdf-lib";
import type {View} from "../models/View";
import type {IModel} from "../models/Model";
import {FileUtils} from "../../utils/file/FileUtils";
import type {ISize} from "../../utils/THREEUtils";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {BaseExporter} from "./BaseExporter";

export class PDFExporter extends BaseExporter {
	protected readonly _extension = "pdf";

	public static setPDFProperties(pdfDoc: PDFDocument) {
		// pdfDoc.setTitle("");
		pdfDoc.setAuthor("Xyicon");
		//pdfDoc.setSubject('📘 An Epic Tale of Woe 📖');
		//pdfDoc.setKeywords(['eggs', 'wall', 'fall', 'king', 'horses', 'men']);
		pdfDoc.setProducer("Xyicon");
		pdfDoc.setCreator("Xyicon");
		pdfDoc.setCreationDate(new Date());
		pdfDoc.setModificationDate(new Date());
	}

	private getNewPage(pdfDoc: PDFDocument, footer?: {image: PDFImage; size: ISize}) {
		const page = pdfDoc.addPage();

		if (footer) {
			page.drawImage(footer.image, {
				x: page.getWidth() / 2 - footer.size.width / 2,
				y: 45,
				width: footer.size.width,
				height: footer.size.height,
			});
		}

		return page;
	}

	protected async _exportView<T extends IModel>(view: View, items: T[]) {
		const fieldRefIds = this.getFieldRefIds(view);
		const headers = this.getHeaders(fieldRefIds);
		const rows = items.map((item) =>
			fieldRefIds.map((fieldRefId) => {
				const values = this.getFieldData(item, fieldRefId);

				return values.filter(StringUtils.isTruthy).flat().join(",");
			}),
		);

		const pdfDoc = await PDFDocument.create();

		PDFExporter.setPDFProperties(pdfDoc);

		const pngUrl = "./assets/images/sr_logo.png";
		const pngImage = await pdfDoc.embedPng(await fetch(pngUrl).then((res) => res.arrayBuffer()));
		const pngSize = pngImage.scale(0.5);
		const footer = {image: pngImage, size: pngSize};

		let page = this.getNewPage(pdfDoc, footer);
		const {width, height} = page.getSize();
		const fontSize = 10;
		const fontSizeHeaderMultiplicator = 1.0;
		const marginTop = 45;
		const marginLeft = marginTop;
		const marginRight = marginLeft;
		const marginBottom = footer ? 110 : marginTop;
		const padding = 5;
		const rectHeightMultiplicator = 2;
		const rectWidth = width - (marginLeft + marginRight);
		const rectHeight = fontSize * rectHeightMultiplicator;
		const fieldWidth = rectWidth / headers.length;

		page.setFontSize(fontSize);

		const maxRowNumberPerPage = Math.floor((height - (marginTop + marginBottom)) / rectHeight);
		const pageCount = Math.ceil(rows.length / maxRowNumberPerPage);

		// we add one initially
		for (let i = 0; i < pageCount - 1; ++i) {
			this.getNewPage(pdfDoc, footer);
		}

		const pages = pdfDoc.getPages();

		const startX = marginLeft + padding;
		const startY = height - marginTop - 1.25 * fontSize * fontSizeHeaderMultiplicator;

		rows.unshift(headers); // add headers to the rows

		for (let i = 0, rowNumber = 0, pageNumber = 0; i < rows.length; ++i, ++rowNumber) {
			if (rowNumber === maxRowNumberPerPage) {
				rowNumber = 0;
				page = pages[++pageNumber];
				page.setFontSize(fontSize);
			}

			if (i % 2 === 0) {
				page.drawRectangle({
					x: marginLeft,
					y: startY - rectHeight * rowNumber - (rectHeight - fontSize) / 2 - fontSize / 6,
					color: rgb(0.97, 0.97, 0.98),
					width: rectWidth,
					height: rectHeight,
				});
			}

			for (let j = 0; j < rows[i].length; ++j) {
				const textConfig: PDFPageDrawTextOptions = {
					x: startX + j * fieldWidth,
					y: startY - rowNumber * rectHeight,
				};

				if (i === 0) {
					textConfig.color = rgb(0.2, 0.58, 0.94);
					textConfig.size = fontSize * fontSizeHeaderMultiplicator;
				}

				page.drawText(rows[i][j], textConfig);
			}
		}

		FileUtils.downloadFileGivenByData(await pdfDoc.save(), this.getFileName(view), "application/pdf");
	}

	public async exportTable(data: (string | number)[][], fileName: string) {
		const headers = data[0];

		data = data.slice(1);

		const rows = data;

		const pdfDoc = await PDFDocument.create();

		PDFExporter.setPDFProperties(pdfDoc);

		const pngUrl = "./assets/images/sr_logo.png";
		const pngImage = await pdfDoc.embedPng(await fetch(pngUrl).then((res) => res.arrayBuffer()));
		const pngSize = pngImage.scale(0.5);
		const footer = {image: pngImage, size: pngSize};

		let page = this.getNewPage(pdfDoc, footer);
		const {width, height} = page.getSize();
		const fontSize = 10;
		const fontSizeHeaderMultiplicator = 1.0;
		const marginTop = 45;
		const marginLeft = marginTop;
		const marginRight = marginLeft;
		const marginBottom = footer ? 110 : marginTop;
		const padding = 5;
		const rectHeightMultiplicator = 2;
		const rectWidth = width - (marginLeft + marginRight);
		const rectHeight = fontSize * rectHeightMultiplicator;
		const fieldWidth = rectWidth / headers.length;

		page.setFontSize(fontSize);

		const maxRowNumberPerPage = Math.floor((height - (marginTop + marginBottom)) / rectHeight);
		const pageCount = Math.ceil(rows.length / maxRowNumberPerPage);

		// we add one initially
		for (let i = 0; i < pageCount - 1; ++i) {
			this.getNewPage(pdfDoc, footer);
		}

		const pages = pdfDoc.getPages();

		const startX = marginLeft + padding;
		const startY = height - marginTop - 1.25 * fontSize * fontSizeHeaderMultiplicator;

		rows.unshift(headers); // add headers to the rows

		for (let i = 0, rowNumber = 0, pageNumber = 0; i < rows.length; ++i, ++rowNumber) {
			if (rowNumber === maxRowNumberPerPage) {
				rowNumber = 0;
				page = pages[++pageNumber];
				page.setFontSize(fontSize);
			}

			if (i % 2 === 0) {
				page.drawRectangle({
					x: marginLeft,
					y: startY - rectHeight * rowNumber - (rectHeight - fontSize) / 2 - fontSize / 6,
					color: rgb(0.97, 0.97, 0.98),
					width: rectWidth,
					height: rectHeight,
				});
			}

			for (let j = 0; j < rows[i].length; ++j) {
				const textConfig: PDFPageDrawTextOptions = {
					x: startX + j * fieldWidth,
					y: startY - rowNumber * rectHeight,
				};

				if (i === 0) {
					textConfig.color = rgb(0.2, 0.58, 0.94);
					textConfig.size = fontSize * fontSizeHeaderMultiplicator;
				}

				page.drawText(`${rows[i][j]}`, textConfig);
			}
		}

		FileUtils.downloadFileGivenByData(await pdfDoc.save(), `${fileName}.pdf`, "application/pdf");
	}
}

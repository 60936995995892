import * as React from "react";
import {inject} from "mobx-react";
import styled, {css} from "styled-components";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {FieldDataType} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import type {AppState} from "../../../../data/state/AppState";
import {type IInputType} from "../../details/datatypes/TextInputV5";
import {FieldInputLabelsV5, FieldInputsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {SingleLineLabelV5} from "../../details/datatypes/SingleLineLabelV5";
import {SingleLineInputV5} from "../../details/datatypes/SingleLineInputV5";
import {InfoBubbleV5} from "../../button/InfoBubbleV5";
import ErrorInfoIcon from "../../icons/errorInfo.svg?react";
import {colorPalette} from "../../styles/colorPalette";
import {FlexCenterStyle, InfoIconStyled, radius} from "../../styles/styles";

interface IClickToEditInputProps {
	className?: string;
	errorMessage?: string; // if the current value can't be applied
	dataTypeSettings?: any; // {decimals: number, formatting: "currency", maximum: number, minimum: number}
	dataType?: FieldDataType;
	value: any;
	placeholder: any;
	trimText?: boolean;
	disabled?: boolean;
	updating?: boolean;
	hasValidation?: boolean;
	focused?: boolean;
	dateFormat?: string;
	onFocusLossForceBlur?: boolean;
	inputType?: IInputType;
	caretPosition?: number;
	appState?: AppState;
	inline?: boolean;
	onLiveChange?: (value: any) => void;
	onCancelEdit?: () => void;
	onChange: (value: any) => void;
	valueValidator?: (value: string) => boolean;
	onClick?: (event?: React.MouseEvent) => void;
	onHover?: (over?: boolean) => void;
	onBlur?: () => void;
}

interface IClickToEditInputState {
	editing: boolean;
	editingValue: any;
	inputHeight: number;
	inputScrollHeight: number;
	errorMessage: string;
	isLabelInSecondaryState: boolean;
	isFocus: boolean;
}

@inject("appState")
export class ClickToEditInputV5 extends React.Component<IClickToEditInputProps, IClickToEditInputState> {
	public static defaultProps: Partial<IClickToEditInputProps> = {
		dataType: FieldDataType.SingleLineText,
		value: null, // without this, this.state.editingValue !== this.props.value is false, and we're updating the backend with the same nullish value unnecessarily
		trimText: true,
	};

	public _element = React.createRef<HTMLDivElement>();
	private _input = React.createRef<HTMLDivElement>();
	private _labelContent = React.createRef<HTMLDivElement>();

	constructor(props: IClickToEditInputProps) {
		super(props);
		this.state = {
			editing: false,
			editingValue: this.props.value,
			inputHeight: null,
			errorMessage: "",
			isLabelInSecondaryState: false,
			inputScrollHeight: null,
			isFocus: false,
		};
	}

	private onClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.refreshEditState();

		this.setState({
			editing: true,
			editingValue: this.props.value,
		});

		this.props.onClick?.(event);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.onCancelEdit();
				break;
			case KeyboardListener.KEY_ENTER:
				if (!(this.props.dataType === FieldDataType.MultiLineText && KeyboardListener.isShiftDown)) {
					this.onApplyEdit();
				}
				break;
		}
	};

	private onFocusLoss = (e?: MouseEvent) => {
		let eventTargetInModalContainer = false;

		if (e?.target instanceof Element) {
			eventTargetInModalContainer = this.props.appState.app.modalContainer?.contains(e?.target);

			if (!eventTargetInModalContainer) {
				// the eventTarget is not in modalContainer
				if (!this.onApplyEdit()) {
					this.onCancelEdit();
				}
			} else {
				if (this.props.appState.app.modalContainer?.contains(this._element.current)) {
					// the eventTarget and the field is in modalContainer (Popups)
					if (!this.onApplyEdit()) {
						this.onCancelEdit();
					}
				}
			}
		} else {
			if (!this.onApplyEdit()) {
				this.onCancelEdit();
			}
		}
	};

	private onCancelEdit = () => {
		this.cancelLocalEdit();
		this.props.onCancelEdit?.();
	};

	public cancelLocalEdit() {
		if (this.state.editing) {
			this.setState({
				editing: false,
				editingValue: this.props.value,
			});

			this.props.onLiveChange?.(this.props.value);
		}
	}

	private onApplyEdit = () => {
		const editing = this.state.editing;

		this.setState({
			editing: false,
		});

		const editingValue = this.editingValue;

		if (editing && !this.state.errorMessage && editingValue !== this.props.value) {
			this.props.onChange?.(editingValue);
			return true;
		}

		return false;
	};

	public get editingValue() {
		return this.trimValueIfNeeded(this.state.editingValue);
	}

	private onEditingInput = (value: any) => {
		const {valueValidator, onLiveChange} = this.props;

		this.setState({editingValue: value});

		if (valueValidator) {
			let errorMessage = "";

			if (!valueValidator(value)) {
				errorMessage = "Name needs to be unique!";
			}

			if (!value) {
				errorMessage = "Name cannot be empty!";
			}

			this.setState({errorMessage});
		}
		onLiveChange?.(value);
	};

	private trimValueIfNeeded(editingValue: string | number) {
		if (this.props.trimText && typeof editingValue === "string") {
			editingValue = editingValue.trim();
		}

		return editingValue;
	}

	private onChange = (value: any) => {
		const newValue = this.trimValueIfNeeded(value);

		this.setState({editingValue: newValue});
		this.props.onChange(newValue);
	};

	private onBlur = () => {
		const {onBlur, value} = this.props;

		if (onBlur) {
			if (this.state.errorMessage) {
				this.setState({editingValue: value, errorMessage: ""});
			} else {
				onBlur?.();
			}
		}

		this.setState({
			isFocus: false,
		});
	};

	private handleFocus = () => {
		this.setState({isFocus: true});
	};

	private onLabelStateChange = (value: boolean) => {
		this.setState({isLabelInSecondaryState: value});
	};

	private getLabel() {
		const {placeholder, dataType, dataTypeSettings, appState} = this.props;
		const {editingValue, errorMessage} = this.state;
		let {value} = this.props;

		let LabelComponent = FieldInputLabelsV5[dataType];

		LabelComponent = LabelComponent || SingleLineLabelV5;

		if (!value && placeholder) {
			value = placeholder;
		}

		const final = appState.actions.getURLsInTextInputs(value);

		return (
			<LabelComponent
				value={errorMessage ? editingValue : final}
				dataTypeSettings={dataTypeSettings}
				onLabelStateChange={this.onLabelStateChange}
				divRef={this._labelContent}
				height={this._labelContent.current?.scrollHeight || 0}
			/>
		);
	}

	private getEditInput() {
		const {dataType, dataTypeSettings, onFocusLossForceBlur, inputType, caretPosition, focused} = this.props;
		const {editingValue, inputHeight, isLabelInSecondaryState, inputScrollHeight} = this.state;

		let InputComponent = FieldInputsV5[dataType];

		InputComponent = InputComponent || SingleLineInputV5;
		return (
			<InputComponent
				value={editingValue ?? this.props.dateFormat}
				dataTypeSettings={dataTypeSettings}
				onInput={this.onEditingInput}
				onCancel={this.onCancelEdit}
				onApply={this.onApplyEdit}
				onChange={this.onChange}
				onBlur={this.onBlur}
				height={inputHeight}
				scrollHeight={inputScrollHeight}
				onFocusLossForceBlur={onFocusLossForceBlur}
				noButtons={true}
				inputType={inputType}
				secondaryState={isLabelInSecondaryState}
				caretPosition={caretPosition}
				expand={isLabelInSecondaryState}
				focused={focused}
			/>
		);
	}

	private refreshEditState() {
		this.setState({
			editing: true,
			editingValue: this.props.value,
		});

		this.addListeners();
	}

	private refreshInputHeight() {
		const inputRef = this._input.current;
		const labelRef = this._labelContent.current;

		if (labelRef && inputRef && inputRef.offsetHeight !== this.state.inputHeight && labelRef.offsetHeight !== this.state.inputScrollHeight) {
			this.setState({
				inputHeight: inputRef.offsetHeight,
				inputScrollHeight: labelRef.scrollHeight,
			});
		}
	}

	private onMouseOver = () => {
		this.props.onHover?.(true);
	};

	private onMouseLeave = () => {
		this.props.onHover?.(false);
	};

	private removeListeners() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		FocusLoss.stopListen(this._element.current, this.onFocusLoss);
	}

	private addListeners() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		FocusLoss.listen(this._element.current, this.onFocusLoss, null, "up");
	}

	public override componentDidMount() {
		this.refreshInputHeight();
		this.addListeners();
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IClickToEditInputProps>, nextContext: any): void {
		if (this.props.value !== nextProps.value) {
			this.setState({
				editingValue: nextProps.value,
			});
		}
	}

	public override componentDidUpdate(prevProps: IClickToEditInputProps, prevState: IClickToEditInputState) {
		this.refreshInputHeight();

		if (prevProps.focused !== this.props.focused) {
			this.refreshEditState();
		}

		if (prevProps.focused && !this.props.focused) {
			this.onApplyEdit();
		}
	}

	public override componentWillUnmount() {
		this.onFocusLoss();
		this.removeListeners();
	}

	public override render() {
		const {editing, errorMessage} = this.state;
		const {className, disabled, updating, hasValidation, inline} = this.props;
		const defaultMessage = "Field name already exists for the selected organization.";

		return (
			<ClickToEditInputStyled
				className={className || ""}
				ref={this._element}
				onMouseOver={this.onMouseOver}
				onMouseLeave={this.onMouseLeave}
				$error={!!errorMessage}
				$editing={editing}
				$empty={!this.state.editingValue}
				$inline={inline}
				onFocus={this.handleFocus}
				onBlur={this.onBlur}
			>
				{errorMessage && !disabled && (
					<InfoIconStyled
						className={ReactUtils.cls("infoIcon", {editing})}
						onMouseOver={() => this.setState({isFocus: true})}
						onMouseOut={() => this.setState({isFocus: false})}
					>
						<ErrorInfoIcon />
						<InfoBubbleV5
							content={errorMessage || defaultMessage}
							isErrorMessage={true}
							isFocus={this.state.isFocus}
						/>
					</InfoIconStyled>
				)}
				{editing && !disabled ? (
					this.getEditInput()
				) : (
					<div
						className={ReactUtils.cls("unfocused", {disabled, updating})}
						onClick={this.onClick}
						ref={this._input}
					>
						{this.getLabel()}
					</div>
				)}
				{updating && <span className={ReactUtils.cls("spinner", {hasValidation})} />}
			</ClickToEditInputStyled>
		);
	}
}

export const ClickToEditInputStyled = styled.div<{$error: boolean; $editing: boolean; $empty: boolean; $inline: boolean}>`
	width: 100%;
	position: relative;

	.infoIcon {
		position: absolute;
		top: 5px;
		right: 5px;

		svg {
			height: 16px;
			width: 16px;
			color: #ff4136;
		}
	}
	${(props) => {
		if (props.$editing) {
			return css`
				.field-input-container {
					input {
						padding-right: 30px;
						border: solid 1px ${colorPalette.blueGray.c500Primary};
					}
				}

				.multiline {
					textarea {
						border: solid 1px var(--blue);
					}

					&:has(.field-input-container) {
						textarea {
							border-bottom: none;
						}
					}
				}
			`;
		}
	}}

	${(props) => {
		if (props.$error) {
			return css`
				.field-input-container {
					input {
						border: 1px solid red;
					}
				}

				.unfocused {
					border: 1px solid red;
				}

				border-radius: ${radius.sm};
			`;
		}
	}}

	.SingleLineLabel {
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	.spinner {
		position: absolute;
		top: 8px;
		right: 7px;
		visibility: visible;
		border-radius: 50%;
		width: 16px;
		height: 16px;

		border: 2px solid transparent;
		border-top: 2px solid #ef9647;
		border-left: 2px solid #ef9647;
		border-bottom: 2px solid #ef9647;

		animation: spin 2s linear infinite;
	}

	.field-input-container {
		align-items: stretch;
		width: 100%;

		input {
			background: transparent;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.multiline {
		textarea {
			max-height: 94px;
			overflow-y: auto;
			line-height: 16px;
			resize: none;
			margin-bottom: 0;
			padding: 10px;
			height: inherit;

			&:focus {
				background: ${colorPalette.white};
			}
		}

		&.extended,
		&.expand {
			textarea {
				max-height: 400px;
				overflow-y: auto;
			}
		}
	}

	.unfocused,
	input {
		border: ${(props) => (props.$inline ? "none" : `1px solid ${colorPalette.gray.c300}`)};
		border-radius: ${radius.sm};
		padding: 7px;
		width: 100%;
		font-size: 14px;
		/* color: ${colorPalette.gray.c700Dark}; */
		outline: none;
	}

	.unfocused {
		cursor: text;
		align-items: center;
		word-break: break-all;
		min-height: 32px;

		&:hover:not(.disabled) {
			background-color: ${colorPalette.gray.c200Light};
		}

		&.disabled {
			border-color: transparent;
			cursor: auto;

			.MultiLineLabel {
				pointer-events: all;
			}
		}

		p {
			margin: 0;
			line-height: 18px;
			white-space: break-spaces;
		}

		&.updating {
			.SingleLineLabel {
				width: calc(100% - 10px);
			}
		}

		&:empty {
			${FlexCenterStyle};
			height: 32px;
		}
	}

	&.noButtons {
		.infoIcon {
			right: 10px;
			width: 10px;
			height: 10px;
			position: absolute;
		}
	}

	&.infoBubbleAlignLeft {
		.InfoBubble {
			transform: translate(calc(-100% + 15px), -44px);

			&::after {
				right: 8px;
			}
		}
	}

	&.portfolionameInput {
		.error {
			left: -60px;
			&::after {
				left: auto;
				right: 5px;
			}
		}
	}
`;

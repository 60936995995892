import type {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import {getDefaultPositionOfBoundaryCaption} from "../../renderers/SpaceViewRendererUtils";
import {Caption} from "./Caption";

export class BoundaryCaption extends Caption {
	public setToDefaultPosition() {
		const pos = getDefaultPositionOfBoundaryCaption((this._parent as BoundarySpaceMap3D).data.geometryData);

		this._position.x = pos.x;
		this._position.y = pos.y;
	}
}

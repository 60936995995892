import {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import {Vector2, Mesh} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {HighlightMaterial} from "../../materials/HighlightMaterial";
import {Constants} from "../../Constants";
import {getCorrectionMultiplierForSpaceItem, MeasureType} from "../../renderers/SpaceViewRendererUtils";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {MarkupType} from "../../../../../../../generated/api/base";
import type {IMarkupConfig} from "./abstract/MarkupUtils";
import {Markup3D} from "./abstract/Markup3D";
import {highlightOpacity, highlightRadius} from "./MarkupStaticElements";

export class MarkupDrawing extends Markup3D {
	private _pathCoords3D: number[] = [];
	private _2dVectors: Vector2[] = [];
	private _pathCoords2D: number[] = [];
	private _textContent: string = "";

	constructor(spaceViewRenderer: SpaceViewRenderer, config: IMarkupConfig) {
		super(spaceViewRenderer, {measureType: MeasureType.NONE, ...config});

		if (config.isHighLight) {
			this._meshMaterial = new HighlightMaterial(this._color, highlightOpacity, highlightRadius * this._correctionMultiplier);
			this._mesh = new Mesh(this._spaceViewRenderer.planeGeometry, this._meshMaterial);
			this.updateHighlightMesh(true);
			THREEUtils.add(this._group, this._mesh);
		}
	}

	/**
	 *
	 * @param pathCoords vertices in world position
	 */
	public updateGeometry(pathCoords: PointDouble[], isLocal: boolean) {
		this._group.visible = true;
		this._spaceViewRenderer.needsRender = true;
		if (isLocal) {
			pathCoords = THREEUtils.getRotatedVertices(pathCoords, -this._lastSavedOrientation, {x: 0, y: 0});
			this._group.rotation.z = this._lastSavedOrientation;
			THREEUtils.updateMatrices(this._group);
		} else {
			this._worldGeometryData = pathCoords;
		}

		this._pathCoords3D.length = 0;
		this._2dVectors.length = 0;
		this._pathCoords2D.length = 0;

		for (let i = 0; i < pathCoords.length; ++i) {
			const pathCoord = pathCoords[i];

			if (this._config.isHighLight) {
				this._pathCoords2D.push(pathCoord.x);
				this._pathCoords2D.push(pathCoord.y);
			}

			this._pathCoords3D.push(pathCoord.x);
			this._pathCoords3D.push(pathCoord.y);
			this._pathCoords3D.push(0);
			this._2dVectors.push(new Vector2(pathCoord.x, pathCoord.y));
		}

		if (this.measureType === MeasureType.AREA) {
			this._pathCoords3D.push(pathCoords[0].x);
			this._pathCoords3D.push(pathCoords[0].y);
			this._pathCoords3D.push(0);
			this.updateMesh(this._2dVectors);
		}

		this._lineGeometry.dispose();

		if (this._config.isHighLight) {
			(this._meshMaterial as HighlightMaterial).setGeometryData(this._pathCoords2D);
		} else {
			this._lineGeometry = new LineGeometry();
			(this._lineGeometry as LineGeometry).setPositions(this._pathCoords3D);
			this._line.geometry = this._lineGeometry as LineGeometry;
		}

		if (this._isDashed) {
			this._line.computeLineDistances();
		}

		if (isLocal) {
			this._selectionPart.update(this._pathCoords3D);
		}
		this.updateBoundingBox();

		if (isLocal && this._config.isHighLight) {
			this.updateHighlightMesh(false);
		}

		if (this.measureType !== MeasureType.NONE) {
			let valueInSpaceUnit = 0;
			let value = 0;
			const space = this._modelData?.space ? this._modelData.space : this._spaceViewRenderer.space;

			const unitName = this.unitName;
			const unit = Constants.DISTANCE_UNITS[unitName];

			if (this.measureType === MeasureType.AREA) {
				valueInSpaceUnit = THREEUtils.calculateArea(this._worldGeometryData);
				value = MathUtils.convertAreaFromSpaceUnit(valueInSpaceUnit, unit.name, space.spaceUnitsPerMeter);

				if (unitName === "foot&inch") {
					// value is in ft²
					const ftSqInSq = MathUtils.convertFeetSqInDecimalToFeetSqAndInchesSq(value);

					this._textContent = MathUtils.formatFeetSqAndInchesSqArrayToText(ftSqInSq);
				} else {
					this._textContent = `${value.toFixed(Constants.TO_FIXED)} ${unit.abbreviation}²`;
				}
			} // if measureType === MeasureType.DISTANCE
			else {
				valueInSpaceUnit = THREEUtils.calculateDistance(this._worldGeometryData);
				value = MathUtils.convertDistanceFromSpaceUnit(valueInSpaceUnit, unit.name, space.spaceUnitsPerMeter);
				if (unitName === "foot&inch") {
					const feetAndInches = MathUtils.convertFeetInDecimalToFeetAndInches(value);

					this._textContent = MathUtils.formatFeetAndInchesArrayToText(feetAndInches);
				} else {
					this._textContent = `${value.toFixed(Constants.TO_FIXED)} ${unit.abbreviation}`;
				}
			}

			this.recreateTextGroups();
		}

		this.onGeometryUpdated();
	}

	private updateHighlightMesh(editMode: boolean) {
		if (this._config.isHighLight) {
			if (editMode) {
				const spaceSize = this._spaceViewRenderer.spaceSize;
				const spaceOffset = this._spaceViewRenderer.spaceOffset;

				this._mesh.scale.setX(spaceSize.width);
				this._mesh.scale.setY(spaceSize.height);
				this._mesh.position.setX(spaceOffset.x + spaceSize.width / 2);
				this._mesh.position.setY(spaceOffset.y + spaceSize.height / 2);
			} else {
				const unrotatedVertices = this.unrotatedGeometryData;
				const {min, max} = THREEUtils.calculateBox(unrotatedVertices);
				const padding = 2 * highlightRadius * getCorrectionMultiplierForSpaceItem(this._spaceViewRenderer, this._modelData);

				this._mesh.scale.setX(max.x - min.x + padding);
				this._mesh.scale.setY(max.y - min.y + padding);
				this._mesh.position.setX(0);
				this._mesh.position.setY(0);
				this._meshHeight = this._mesh.scale.y;

				const pathCoordsNumArray = [];

				for (const pathCoord of this._worldGeometryData) {
					pathCoordsNumArray.push(pathCoord.x);
					pathCoordsNumArray.push(pathCoord.y);
				}

				(this._meshMaterial as HighlightMaterial).setGeometryData(pathCoordsNumArray);
			}

			THREEUtils.updateMatrices(this._mesh);
		}
	}

	public override switchEditMode(enabled: boolean, updateBackendOnFinish: boolean) {
		const hasChanged = super.switchEditMode(enabled, updateBackendOnFinish);

		if (enabled) {
			this.updateHighlightMesh(enabled);
		}

		return hasChanged;
	}

	public override translate(x: number, y: number, z: number, force: boolean = false) {
		const hasChanged = super.translate(x, y, z, force);

		if (hasChanged && this._config.isHighLight) {
			const worldGeometryData = THREEUtils.cloneGeometryData(this._worldGeometryDataOnStartTranslating);

			THREEUtils.applyOffsetToGeometryData(worldGeometryData, {x: x, y: y});

			const pathCoordsNumArray = [];

			for (const pathCoord of worldGeometryData) {
				pathCoordsNumArray.push(pathCoord.x);
				pathCoordsNumArray.push(pathCoord.y);
			}
			(this._meshMaterial as HighlightMaterial).setGeometryData(pathCoordsNumArray);
		}

		return hasChanged;
	}

	public override rotateWithHandlerByDelta(deltaAngle: number, pivot?: PointDouble) {
		if (this.hasPermissionToMoveOrRotate) {
			super.rotateWithHandlerByDelta(deltaAngle, pivot);

			if (this._config.isHighLight) {
				const pivotPoint = pivot ?? this.position;
				const pathCoords = THREEUtils.getRotatedVertices(this._worldGeometryData, this._group.rotation.z - this._lastSavedOrientation, pivotPoint);

				const pathCoordsNumArray = [];

				for (const pathCoord of pathCoords) {
					pathCoordsNumArray.push(pathCoord.x);
					pathCoordsNumArray.push(pathCoord.y);
				}

				(this._meshMaterial as HighlightMaterial).setGeometryData(pathCoordsNumArray);
			}
		}
	}

	public override get fontColor() {
		return this.isTemp ? super.fontColor : this.shiftedColor;
	}

	public override get textContent() {
		return this._textContent;
	}

	public get type(): MarkupType {
		if (this._config.isHighLight) {
			return MarkupType.Highlight_Drawing;
		} else {
			switch (this.measureType) {
				case MeasureType.DISTANCE:
					return MarkupType.Nonlinear_Distance;
				case MeasureType.AREA:
					return MarkupType.Irregular_Area;
				case MeasureType.NONE:
				default:
					return MarkupType.Pencil_Drawing;
			}
		}
	}

	public get measureType() {
		return this._config.measureType;
	}
}

import styled from "styled-components";
import {useAppStore} from "../../../StateManager";
import {featureTitles} from "../../../data/state/AppStateConstants";
import type {XyiconFeature} from "../../../generated/api/base";
import {Permission} from "../../../generated/api/base";
import {ButtonV5} from "../button/ButtonV5";
import AddIcon from "../icons/plus.svg?react";
import {colorPalette} from "../styles/colorPalette";
import type {View} from "../../../data/models/View";
import {StringUtils} from "../../../utils/data/string/StringUtils";

interface IEmptyListViewProps {
	readonly onAddClick?: () => void;
	readonly feature?: XyiconFeature;
	readonly text?: React.ReactNode;
}

export const EmptyListViewV5 = (props: IEmptyListViewProps) => {
	const appState = useAppStore((state) => state.appState);
	const {feature, onAddClick, text} = props;
	const moduleName = featureTitles[feature] || "";
	const views = appState.actions.getViews(feature).toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name));
	const hasPermission = appState.actions.hasAnyTypeTheGivenPermissionInModule(feature, Permission.Update);

	return (
		<EmptyListViewStyled className="EmptyListView">
			<div className="content">
				{views.length != 0 ? (
					<>
						<p className="description">
							{text || `There are no ${moduleName || ""} items to list.`}
							{onAddClick && (
								<>
									<br />
									Start by adding your first ...
								</>
							)}
						</p>
						{onAddClick && (
							<ButtonV5
								label={`Add New ${moduleName || "item"}`}
								disabled={!hasPermission}
								onClick={onAddClick}
							>
								<AddIcon />
							</ButtonV5>
						)}
					</>
				) : (
					<p className="description">{text || `No views to display..`}</p>
				)}
			</div>
		</EmptyListViewStyled>
	);
};

const EmptyListViewStyled = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.content {
		p.description {
			text-align: center;
			color: var(--navText);
			line-height: 24px;
		}

		.Button {
			border: dashed 2px var(--blue);
			height: 150px;
			display: flex;
			color: ${colorPalette.primary.c500Primary};

			&.disabled {
				color: ${colorPalette.gray.c200Light};
				border-color: ${colorPalette.gray.c200Light};
			}

			svg {
				margin-bottom: 16px;
				color: ${colorPalette.primary.c500Primary};
			}
		}
	}
`;

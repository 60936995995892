import * as React from "react";
import {ReactUtils} from "../../../utils/ReactUtils";
import {Functions} from "../../../../utils/function/Functions";

interface IStepIndicatorProps {
	steps: string[];
	currentStepIndex: number;
	onStepClick: (stepIndex: number) => void;
	editing?: boolean;
}

export class StepIndicator extends React.Component<IStepIndicatorProps> {
	public static defaultProps: Partial<IStepIndicatorProps> = {
		onStepClick: Functions.emptyFunction,
	};

	public override render() {
		const {steps, editing} = this.props;

		return (
			<div
				className="StepIndicator hbox"
				style={{maxWidth: `${167 * this.props.steps.length}px`}}
			>
				{steps.map((value: string, index: number) => {
					const isDone = index < this.props.currentStepIndex;

					return (
						<React.Fragment key={index}>
							<div
								style={{cursor: this.props.onStepClick !== Functions.emptyFunction ? "pointer" : ""}}
								data-content={value}
								className={ReactUtils.cls("stepIndex flexCenter", {
									active: index === this.props.currentStepIndex,
									done: isDone,
								})}
								onClick={() => this.props.onStepClick(index)}
							>
								{editing || isDone ? "✓" : `${index + 1}`}
							</div>
							{index < this.props.steps.length - 1 && (
								<div className="lineWrapper vbox justifyCenter">
									<div className="line"></div>
								</div>
							)}
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}

import {Object3D} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {SpaceItem, ISpaceItemMinimumData} from "../../elements3d/SpaceItem";
import {ItemSelectionBox} from "../../elements3d/ItemSelectionBox";
import type {ILayerSettings} from "../../../ui/viewbar/LayerView";
import type {CaptionedItem} from "../MSDF/CaptionManager";
import {CaptionManager} from "../MSDF/CaptionManager";
import type {Xyicon3D} from "../../elements3d/Xyicon3D";
import {getFeatureBySpaceItemType} from "../../renderers/SpaceViewRendererUtils";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import type {PointDouble} from "../../../../../../../generated/api/base";
import type {IModel, ISpaceItemModel} from "../../../../../../../data/models/Model";
import {Signal} from "../../../../../../../utils/signal/Signal";
import type {IFormattingRuleSet} from "../../../../../../../data/models/ViewUtils";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import {notify} from "../../../../../../../utils/Notify";
import {NotificationType} from "../../../../../../notification/Notification";
import type {BoundarySpaceMap} from "../../../../../../../data/models/BoundarySpaceMap";
import type {INotificationElementParams} from "../../../../../../notification/AppNotifications";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";
import {StringUtils} from "../../../../../../../utils/data/string/StringUtils";
import type {Collection} from "../../../../../../../data/models/abstract/Collection";
import type {Markup} from "../../../../../../../data/models/Markup";
import type {XyiconManager} from "./XyiconManager";

export type SpaceItemType = "boundary" | "markup" | "xyicon";

export abstract class ItemManager {
	protected _spaceViewRenderer: SpaceViewRenderer;
	protected _selectionBox: ItemSelectionBox;
	protected _container: Object3D;
	protected abstract _items: Collection<SpaceItem>;
	private _type: SpaceItemType;
	public signals = {
		itemsAdd: Signal.create<IModel[]>(),
		itemsRemove: Signal.create<IModel[]>(),
		itemsUpdate: Signal.create<IModel[]>(),
		onClear: Signal.create<void>(),
	};
	private _isAddingItemsToServer: boolean = false; // true if we've already sent the request to the server, but the response hasn't arrived yet
	private _itemsHiddenNotification: INotificationElementParams | null = null;

	constructor(spaceViewRenderer: SpaceViewRenderer, type: SpaceItemType) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._type = type;
		if (type === "markup") {
			this._container = this._spaceViewRenderer.markupScene;
		} else {
			this._container = new Object3D();
			this._container.name = `${this._type}Container`;
		}
		this._selectionBox = new ItemSelectionBox();
	}

	protected get _selectedItems() {
		return this._items.array.filter((i) => i.isSelected);
	}

	public get feature() {
		return getFeatureBySpaceItemType(this._type);
	}

	public init() {
		THREEUtils.add(this._spaceViewRenderer.xyiconScene, this._container);
	}

	public updateSelectionBox() {
		this._selectionBox.reset();
		for (const item of this._selectedItems) {
			this._selectionBox.expandByBoundingBox(item.boundingBox);
		}
	}

	private isItemInsideRectangle(item: SpaceItem, smallerX: number, largerX: number, smallerY: number, largerY: number) {
		const bbox = item.boundingBox;

		return smallerX <= bbox.min.x && bbox.max.x <= largerX && smallerY <= bbox.min.y && bbox.max.y <= largerY;
	}

	public selectItemsInsideRectangle(smallerX: number, smallerY: number, largerX: number, largerY: number) {
		for (const item of this._items.array) {
			if (this.isItemInsideRectangle(item, smallerX, largerX, smallerY, largerY)) {
				item.select(false);
			}
		}
		const itemBoundingBoxes = this._selectedItems.map((item) => item.boundingBox);

		this._selectionBox.setFromBoundingBoxes(itemBoundingBoxes);
	}

	public startTranslatingSelectedItems() {
		for (const item of this._selectedItems) {
			item.startTranslating();
		}
		this._selectionBox.startTranslating();
	}

	/**
	 * // world coordinates, measured from pointerStart (NOT from the last pointerposition) -> this way it's more reliable
	 * @param x
	 * @param y
	 */
	public translateSelectedItems(x: number, y: number, z: number) {
		for (const item of this._selectedItems) {
			item.translate(x, y, z);
		}
		this.updateSelectionBox();
	}

	public stopTranslatingSelectedItems() {
		const selectedItems = this._selectedItems;

		for (const item of selectedItems) {
			item.stopTranslating();
		}
		this.updateSelectionBox();
		this.updateItems(selectedItems, false, "position");
	}

	public deselectAll() {
		const selectedItems = [...this._selectedItems];

		for (const item of selectedItems) {
			item.deselect(false);
		}
		this._selectionBox.reset();
	}

	public startRotatingSelectedItems(pivot?: PointDouble) {
		for (const item of this._selectedItems) {
			item.startRotating(pivot);
		}
		// overridden for markupcallouts, for example
	}

	public rotateSelectedItems(deltaAngle: number, pivot?: PointDouble) {
		for (const item of this._selectedItems) {
			item.rotateWithHandlerByDelta(deltaAngle, pivot);
		}
	}

	public stopRotatingSelectedItems() {
		const selectedItems = this._selectedItems;

		for (const item of selectedItems) {
			item.stopRotating();
		}
		this.updateSelectionBox();

		return this.updateItems(selectedItems, false, "orientation");
	}

	private async deleteItemsFromServer(items: IModel[]) {
		try {
			if (items.length > 0) {
				await this._spaceViewRenderer.actions.deleteItems(items, this.feature, this.feature === XyiconFeature.Boundary ? "boundaryspacemaps" : "");
			}
		} catch (error) {
			console.warn("One or more items couldn't be deleted!");
		}
	}

	public async deleteSelectedItems(notifyServer: boolean = true) {
		const selectedItems = this._selectedItems;

		if (selectedItems.length > 0) {
			const clonedItems = [...selectedItems];
			const items = clonedItems.map((spaceItem: SpaceItem) => spaceItem.modelData);

			const spaceItemType = clonedItems[0].spaceItemType;

			if (spaceItemType === "xyicon" || spaceItemType === "boundary") {
				(this as any as XyiconManager).captionManager?.hideTextGroup(
					clonedItems.filter(CaptionManager.filterVisibleCaptionedItems).map((item: CaptionedItem) => item.caption),
					true,
				);
			}

			// Delete from the frontend
			for (let i = clonedItems.length - 1; i >= 0; --i) {
				const item = clonedItems[i];

				if (spaceItemType === "xyicon") {
					const embeddedXyicons = (item.modelData as Xyicon)?.embeddedXyicons || [];

					for (let j = embeddedXyicons.length - 1; j >= 0; --j) {
						const embeddedXyicon3D = this.getItemById(embeddedXyicons[j].id);

						if (embeddedXyicon3D) {
							embeddedXyicon3D.destroy();
						}
					}
				}

				item.destroy();
			}
			this.updateSelectionBox();

			this.signals.itemsRemove.dispatch(items);

			this._spaceViewRenderer.needsRender = true;

			if (notifyServer) {
				const filteredItems = items.filter((item) => !(item as Markup).isTemp);

				// Delete from the server
				await this.deleteItemsFromServer(filteredItems);
			}
		}
		this._spaceViewRenderer.spaceItemController.updateFilterState();
	}

	public clear() {
		const itemsToDestroy = [...this._items.array];

		this._items.replaceByArray([]);
		for (let i = itemsToDestroy.length - 1; i >= 0; --i) {
			const itemToDestroy = itemsToDestroy[i];

			itemToDestroy.destroy(false, false);
		}

		this._itemsHiddenNotification?.onClose();

		this.signals.onClear.dispatch();
	}

	// should be called by spaceItem.destroy() only
	public async deleteItem(item: SpaceItem, notifyServer: boolean = false) {
		this._items.deleteById(item.id);

		item.deselect();

		if (notifyServer) {
			await this.deleteItemsFromServer([item.modelData]);
		}
	}

	public updateByModel(modelData: IModel) {
		const spaceItem = this.getItemById(modelData.id) as SpaceItem;

		if (spaceItem) {
			spaceItem.updateByModel(modelData);
		}

		return spaceItem;
	}

	public onLayerSettingsModified() {
		const wasThereSelectedItems = this._selectedItems.length > 0;

		for (const item of this._items.array) {
			item.onLayerSettingsModified();
		}

		if (wasThereSelectedItems) {
			const {spaceItemController} = this._spaceViewRenderer;

			spaceItemController.updateActionBar(false);
			if (spaceItemController.rotationIconManager.isRotationModeOn) {
				spaceItemController.rotationIconManager.update();
			}
		}

		if (this._type === "markup") {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}

		this._spaceViewRenderer.needsRender = true;
	}

	public onFormattingRulesModified() {
		if (this._type === "boundary" || this._type === "xyicon") {
			for (const item of this._items.array) {
				item.onFormattingRulesModified();
			}

			this._spaceViewRenderer.needsRender = true;
		}
	}

	public getItemsByType(itemType: string | number) {
		return this._items.array.filter((item: SpaceItem) => item.type === itemType);
	}

	public abstract createSpaceItem3DFromModel(data: ISpaceItemMinimumData): Promise<SpaceItem> | SpaceItem;
	public abstract getIntersectables(): Object3D[];
	public abstract addItemsByModel(model: IModel[]): Promise<SpaceItem[]> | SpaceItem[];
	protected abstract addItems(items: SpaceItem[], addToServer?: boolean): Promise<void>;
	public abstract updateItems(items: SpaceItem[], force?: boolean, type?: "position" | "orientation"): Promise<void>;

	public async add(items: SpaceItem[], addToServer: boolean = true, selectItems: boolean = addToServer) {
		if (items.length > 0) {
			// .isEmbedded can be false (if there's a term for false negative, we should call this false false, lol). When the user copy-pastes a xyicon with embedded xyicons,
			// and the response from the server for the creation of the embedded ones arrive (so the modelData is overwritten),
			// but the new links haven't arrived yet through signalR. So we must check this condition before the request is sent to the server

			let showNotification = selectItems;
			const newUnembeddedItems = showNotification ? items.filter((item) => !(item as Xyicon3D).parentXyicon) : [];

			this._isAddingItemsToServer = true;
			await this.addItems(items, addToServer);
			this._isAddingItemsToServer = false;
			if (selectItems) {
				this._spaceViewRenderer.spaceItemController.deselectAll(false);
				for (const item of items) {
					if (!item.isDestroyed) {
						item.select();
					}
				}
				this._spaceViewRenderer.spaceItemController.updateActionBar();
			}
			this._spaceViewRenderer.actions.updateSpaceEditorFilterState();
			this.signals.itemsAdd.dispatch(items.map((spaceItem) => spaceItem.modelData));

			// #2731: A delay to the notification to prevent false alarm (when signalr with the link updates arrive a little bit later than the backend response for the API call)
			await TimeUtils.wait(500);
			showNotification = showNotification && newUnembeddedItems.some((item: SpaceItem) => !item.isVisible);
			if (showNotification) {
				this._itemsHiddenNotification?.onClose();

				this._itemsHiddenNotification = notify(this._spaceViewRenderer.transport.appState.app.notificationContainer, {
					lifeTime: Infinity,
					type: NotificationType.Warning,
					title: `${StringUtils.capitalize(this._type)} is not visible in the active view.`,
					description: `The view's filter prevents the ${this._type} from displaying on the space. To make the ${this._type} visible, switch to another view or edit the active filter. Click the Edit Details button to update the ${this._type}'s fields.`,
					buttonLabel: "Edit Details",
					onActionButtonClick: () =>
						this._spaceViewRenderer.inheritedMethods.selectItems(
							items
								.filter((item) => !item.isDestroyed)
								.map(
									(item) =>
										((item.modelData as BoundarySpaceMap).isBoundarySpaceMap
											? (item.modelData as BoundarySpaceMap).parent
											: item.modelData) as ISpaceItemModel,
								),
							true,
						),
				});
			}
		}
	}

	protected triggerUpdateStateForFieldsWithValidation(
		createDetails: {fieldValues?: Record<string, any> | null},
		itemModel: {id: string; ownFeature: XyiconFeature},
	) {
		if (createDetails?.fieldValues) {
			for (const fieldRefId in createDetails.fieldValues) {
				const field = this._spaceViewRenderer.transport.appState.actions.getFieldByRefId(fieldRefId);

				if (field.hasValidation) {
					this._spaceViewRenderer.transport.appState.itemFieldUpdateManager.addItemFieldUpdateToUpdateList(
						itemModel.id,
						fieldRefId,
						itemModel.ownFeature,
					);
				}
			}
		}
	}

	public get items() {
		return this._items;
	}

	public get selectedItems() {
		return this._selectedItems;
	}

	public get selectionBox() {
		return this._selectionBox;
	}

	public getItemById(id: string) {
		return this._items.getById(id);
	}

	public get layerSettings(): ILayerSettings {
		const selectedView = this._spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
		const {layers} = selectedView.spaceEditorViewSettings;

		return layers[this._type];
	}

	public get formattingRules(): IFormattingRuleSet | undefined {
		const selectedView = this._spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
		const {formattingRules} = selectedView.spaceEditorViewSettings;

		/* if (this._type !== "markup")
		{ */
		return formattingRules[this._type as "boundary" | "xyicon"];
		/* }
		return undefined; */
	}

	public get areFormattingRulesEnabled() {
		return this.formattingRules.enabled;
	}

	public get container() {
		return this._container;
	}

	public get isAddingItemsToServer() {
		return this._isAddingItemsToServer;
	}

	public get correctionMultiplier() {
		return this._spaceViewRenderer.correctionMultiplier.current;
	}
}

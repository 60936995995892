import type {TransportLayer} from "../TransportLayer";
import type {ITypeData} from "../models/Type";
import {Type} from "../models/Type";
import type {IFieldData} from "../models/field/Field";
import {Field} from "../models/field/Field";
import type {DataSchemaDto} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import {typesFeatures} from "../state/AppStateConstants";
import type {IFieldMap} from "../models/TypeFieldMapping";

type TypeFieldKind = "types" | "fields";

export class TypeAndFieldService {
	private _transport: TransportLayer;

	constructor(transportLayer: TransportLayer) {
		this._transport = transportLayer;
	}

	public async populateFullList(kind: TypeFieldKind) {
		const dataArray = await this.fetchAllFeaturesList(kind);

		if (dataArray) {
			const items: (Type | Field)[] = [];

			for (const data of dataArray) {
				const item = this.createModel(data, kind);

				if (item) {
					items.push(item);
				}
			}

			const groupList: {[key: number]: (Type | Field)[]} = {};

			items.forEach((item: Type | Field) => {
				if (!groupList[item.feature]) {
					groupList[item.feature] = [item];
				} else {
					groupList[item.feature].push(item);
				}
			});

			Object.keys(groupList).forEach((key) => {
				const feature = key as unknown as XyiconFeature;

				this._transport.appState[kind][feature] = groupList[feature] as Type[] | Field[];
			});
		}
	}

	public async populateList(feature: XyiconFeature, kind: TypeFieldKind) {
		const dataArray = await this.fetchList(feature, kind);

		if (dataArray) {
			const items: (Type | Field)[] = [];

			for (const data of dataArray) {
				const item = this.createModel(data, kind);

				if (item) {
					items.push(item);
				}
			}

			if (kind === "types") {
				this._transport.appState.types[feature] = items as Type[];
			} else {
				this._transport.appState.fields[feature] = items as Field[];
			}
		}
	}

	//public static createModel(data: ITypeData, kind: "types"): Type;
	//public static createModel(data: IFieldData, kind: "fields"): Type;
	public createModel(data: ITypeData | IFieldData, kind: TypeFieldKind): Field | Type {
		if (!data) {
			return null;
		}

		const appState = this._transport.appState;

		switch (kind) {
			case "types": {
				const type = new Type(data as ITypeData, appState);

				appState.typesById[type.id] = type;

				const list = appState.types[data.feature];

				if (list) {
					list.push(type);
				}

				return type;
			}
			case "fields": {
				const field = new Field(data as IFieldData);

				appState.fieldsById[field.id] = field;
				appState.fieldsByRef[field.refId] = field;

				const list = appState.fields[data.feature];

				if (list) {
					list.push(field);
				}
				return field;
			}

			default:
				console.warn("Kind not found!", kind);
				return null;
		}
	}

	private async fetchAllFeaturesList<T = ITypeData | IFieldData>(kind: TypeFieldKind) {
		const {result} = await this._transport.requestForOrganization<T[]>({
			url: `${kind}/all`,
			method: XHRLoader.METHOD_GET,
		});

		return result;
	}

	private async fetchList<T = ITypeData | IFieldData>(feature: XyiconFeature, kind: TypeFieldKind) {
		if (kind === "types") {
			// Catalog -> Xyicon
			feature = typesFeatures[feature as XyiconFeature.XyiconCatalog] || feature;
		}

		const {result} = await this._transport.requestForOrganization<T[]>({
			url: `${kind}/all`,
			method: XHRLoader.METHOD_GET,
			params: {
				feature: feature,
			},
		});

		return result;
	}

	public async refreshMapping() {
		const {result: allMapping, error} = await this._transport.requestForOrganization<DataSchemaDto>({
			url: "dataschema",
			method: XHRLoader.METHOD_GET,
		});

		if (!allMapping) {
			return;
		}

		const featureName: {[key in keyof DataSchemaDto]: XyiconFeature} = {
			portfolioFieldsByType: XyiconFeature.Portfolio,
			xyiconFieldsByType: XyiconFeature.Xyicon,
			xyiconCatalogFieldsByType: XyiconFeature.XyiconCatalog,
			boundaryFieldsByType: XyiconFeature.Boundary,
			spaceFieldsByType: XyiconFeature.Space,
		};

		for (const key in allMapping) {
			const mapping = allMapping[key as keyof DataSchemaDto];

			if (mapping) {
				const mappingSets: Record<string, Set<string>> = {};

				// TODO note we're currently ignoring mapping.value, which is the permission
				for (const key in mapping) {
					const array = mapping[key].map((o: IFieldMap) => o.key);

					mappingSets[key as keyof typeof mapping] = new Set<string>(array);
				}
				this._transport.appState.typeFieldMapping[featureName[key as keyof DataSchemaDto]] = mappingSets;
			}
		}

		return this._transport.appState.typeFieldMapping;
	}

	public async applyMapping(feature: XyiconFeature, typeId: string) {
		const mapping = this._transport.appState.typeFieldMapping[feature];

		const type = this._actions.getTypeById(typeId);

		if (!type) {
			// Note: backend shouldn't list these in dataschema api
			console.warn(`Type not found locally, skipping update: ${typeId}`);
			return;
		}
		const fields = Array.from(mapping[typeId]);

		// We have to send the type feature because that is mapped to fields
		//const typeFeature = typesFeatures[feature] || feature;

		await this._transport.requestForOrganization({
			url: "types/maptofields",
			method: XHRLoader.METHOD_POST,
			params: {
				typeID: typeId,
				mappedFieldList: fields,
				feature: feature,
			},
		});

		type.applyUpdate?.({
			lastModifiedAt: new Date().toString(),
			lastModifiedBy: this._transport.appState.user?.id || "",
		});
	}

	// this calls the fields/maptotypes as types/maptofields doesnt support mapping xyicon types to catalog fields
	// public async applyFieldMapping(feature: XyiconFeature)
	// {
	// 	const promises = [];
	//
	// 	const fields = this._transport.appState.fields[feature];
	// 	for (const field of fields)
	// 	{
	// 		const typeIds = this._actions.getTypesForField(field);
	// 		promises.push(
	// 			this._transport.requestForOrganization({
	// 				url: `fields/maptotypes`,
	// 				method: XHRLoader.METHOD_POST,
	// 				params: {
	// 					"fieldID": field.id,
	// 					"mappedTypeList": typeIds,
	// 					feature: feature
	// 				}
	// 			})
	// 		);
	// 	}
	//
	// 	await Promise.all(promises);
	// }

	public async createType(type: Type) {
		const {result, error} = await this._transport.requestForOrganization({
			url: "types/create",
			method: XHRLoader.METHOD_PUT,
			params: this.getTypeData(type),
		});

		return {
			error,
			type: this.createModel(result, "types"),
		};
	}

	public async createField(field: Field) {
		const {error, result} = await this._transport.requestForOrganization({
			url: "fields/create",
			method: XHRLoader.METHOD_PUT,
			params: field.serialize(true),
		});

		return {
			error,
			field: this.createModel(result, "fields"),
		};
	}

	public async updateType(type: Type) {
		if (!type.id) {
			console.warn("Type not created yet!", type);
			return;
		}

		if (JSON.stringify(type.data) !== JSON.stringify(type.savedData)) {
			const params: Partial<ITypeData> = {
				featureTypeID: type.id,
				...this.getTypeData(type),
			};

			const {result} = await this._transport.requestForOrganization<ITypeData>({
				url: "types/update",
				method: XHRLoader.METHOD_POST,
				params: params,
			});

			type.applyData(result);

			type.applyUpdate?.(result);
		}
	}

	public updateField(field: Field) {
		if (!field.refId) {
			console.log("Field not created yet!", field);
			return;
		}
		const params: Partial<IFieldData> = {
			fieldID: field.id,
			fieldRefID: field.refId,
			...field.serialize(),
		};

		return this._transport.requestForOrganization({
			url: "fields/update",
			method: XHRLoader.METHOD_POST,
			params: params,
		});
	}

	public updateFieldFormula(field: Field) {
		if (!field.refId) {
			console.log("Field not created yet!", field);
			return;
		}

		const params: Partial<IFieldData> = {
			fieldID: field.id,
			hasFormula: field.hasFormula,
			formula: field.formula,
			feature: field.feature,
		};

		return this._transport.requestForOrganization({
			url: "fields/updateformula",
			method: XHRLoader.METHOD_POST,
			params: params,
		});
	}

	private getTypeData(type: Type): Partial<ITypeData> {
		return {
			name: type.name,
			feature: type.feature,
			settings: type.settings || {color: {...Type.defaultColor}},
		};
	}

	public async remove(kind: TypeFieldKind, ids: string[], feature: XyiconFeature) {
		const key = kind === "types" ? "featureTypeIDList" : "fieldIDList";
		const data = {
			[key]: ids,
		};

		const {result, error} = await this._transport.requestForOrganization({
			url: `${kind}/delete`,
			method: XHRLoader.METHOD_DELETE,
			params: {
				...data,
				feature: feature,
			},
		});

		if (result?.deletedList) {
			await this.applyDelete(result.deletedList, kind, feature);
		}

		return result;
	}

	public async applyDelete(ids: string[], kind: TypeFieldKind, feature: XyiconFeature) {
		const appState = this._transport.appState;

		if (kind === "types") {
			appState.types[feature] = appState.types[feature].filter((type) => !ids.includes(type.id));

			for (const id of ids) {
				delete appState.typesById[id];
			}
		} else {
			// kind === "fields"
			appState.fields[feature] = appState.fields[feature].filter((field) => !ids.includes(field.id));

			for (const id of ids) {
				const fieldToBeDeleted = appState.actions.getFieldById(id);

				if (fieldToBeDeleted) {
					appState.app.transport.signalR.listener.closeValidationRulesAssociatedWithField(fieldToBeDeleted.refId);
					delete appState.fieldsByRef[fieldToBeDeleted.refId];
				}

				delete appState.fieldsById[id];
			}
		}

		if (kind === "fields") {
			// If a field gets removed, we need to refresh the layouts
			await this._transport.services.fieldLayout.refreshLayouts();
		}
	}

	private get _actions() {
		return this._transport.appState.actions;
	}
}

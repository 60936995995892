import {convertColumnNameToPropertyName} from "../../logic3d/managers/ClipboardUtils";
import type {AppActions} from "../../../../../../data/state/AppActions";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {Markup} from "../../../../../../data/models/Markup";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import {featureTitles} from "../../../../../../data/state/AppStateConstants";

export class HTMLTableGenerator {
	private static clearStyle(style: CSSStyleDeclaration) {
		style.fontFamily = "initial";
		style.color = "initial";
		style.fontSize = "initial";
	}

	private static createTableElement() {
		const table = document.createElement("table");

		this.clearStyle(table.style);
		table.setAttribute("cellspacing", "0");
		table.setAttribute("cellpadding", "0");
		table.setAttribute("border", "1");
		table.style.borderCollapse = "collapse";

		return table;
	}

	private static createTrElement() {
		const tr = document.createElement("tr");

		tr.style.border = "1px solid rgb(204, 204, 204)";

		return tr;
	}

	private static createThElement(innerHTML: string) {
		const th = document.createElement("th");

		th.innerHTML = innerHTML;

		return th;
	}

	private static createTdElement(innerHTML: string) {
		const td = document.createElement("td");

		this.clearStyle(td.style);
		td.style.border = "1px solid rgb(204, 204, 204)";
		td.innerHTML = innerHTML;

		return td;
	}

	private static putColumnValueIntoTable(spaceItem: Xyicon | BoundarySpaceMap | Markup, columnName: string, tr: HTMLTableRowElement) {
		const value = spaceItem[convertColumnNameToPropertyName(columnName) as keyof (Xyicon | BoundarySpaceMap | Markup)] ?? "";
		let strValue: string = typeof value === "object" ? JSON.stringify(value) : `${value}`;

		if (strValue === "[]") {
			strValue = "";
		}
		const td = this.createTdElement(strValue);

		tr.appendChild(td);
	}

	public static createTableForSpaceItems(spaceItems: Xyicon[] | BoundarySpaceMap[] | Markup[], actions: AppActions) {
		if (spaceItems.length > 0) {
			const feature = spaceItems[0].ownFeature;
			const isXyiconOrBoundary = [XyiconFeature.Xyicon, XyiconFeature.Boundary].includes(feature);

			const fields: IFieldAdapter[] = isXyiconOrBoundary ? actions.getAssignedFieldsFromLayoutForItems(spaceItems) : [];

			if (feature === XyiconFeature.Xyicon) {
				const embeddedXyicons = [];

				for (const spaceItem of spaceItems) {
					embeddedXyicons.push(...(spaceItem as Xyicon).embeddedXyicons);
				}
				//Xyicon[] is not working
				spaceItems.push(...(embeddedXyicons as never[]));
			}

			const table = this.createTableElement();

			const tableHeader = this.createTrElement();

			tableHeader.style.backgroundColor = "#E3E3E3";

			const spaceItemTitle = featureTitles[feature];

			// before the "fields"
			const preColumns: string[] = [];
			// after the "fields"
			const postColumns: string[] = [];

			if (feature === XyiconFeature.Xyicon) {
				preColumns.push(`${spaceItemTitle} Model`);
			}
			preColumns.push(`${spaceItemTitle} Type`);

			switch (feature) {
				case XyiconFeature.Xyicon:
					postColumns.push(
						`${spaceItemTitle} ID`,
						`Parent ${spaceItemTitle} ID`,
						`${spaceItemTitle} X`,
						`${spaceItemTitle} Y`,
						`${spaceItemTitle} Z`,
						`${spaceItemTitle} Orientation`,
						`${spaceItemTitle} Port Data`,
						`${spaceItemTitle} Settings`,
					);
					break;
				case XyiconFeature.Boundary:
					postColumns.push(`${spaceItemTitle} ID`, `${spaceItemTitle} Geometry`, `${spaceItemTitle} Orientation`);
					break;
				case XyiconFeature.Markup:
					postColumns.push(
						`${spaceItemTitle} Geometry`,
						`${spaceItemTitle} Fill Transparency`,
						`${spaceItemTitle} Line Thickness`,
						`${spaceItemTitle} Settings`,
						`${spaceItemTitle} Text`,
						`${spaceItemTitle} Orientation`,
						`${spaceItemTitle} Color`,
					);
					break;
			}

			for (const additionalColumn of preColumns) {
				tableHeader.appendChild(this.createThElement(additionalColumn));
			}

			for (const field of fields) {
				tableHeader.appendChild(this.createThElement(field.name));
			}

			for (const additionalColumn of postColumns) {
				tableHeader.appendChild(this.createThElement(additionalColumn));
			}

			table.appendChild(tableHeader);

			for (const spaceItem of spaceItems) {
				const tr = this.createTrElement();

				for (let i = 0; i < preColumns.length; ++i) {
					const additionalColumn = preColumns[i];

					if (i === 0) {
						tr.setAttribute("item-id", spaceItem.id);
					}
					this.putColumnValueIntoTable(spaceItem, additionalColumn, tr);
				}

				if (isXyiconOrBoundary) {
					for (const field of fields) {
						const fieldValue: string | boolean = actions.getHumanFriendlyFieldValueForItem(spaceItem, field);

						const td = this.createTdElement(fieldValue);

						tr.appendChild(td);
					}
				}

				for (const additionalColumn of postColumns) {
					this.putColumnValueIntoTable(spaceItem, additionalColumn, tr);
				}

				table.appendChild(tr);
			}

			return table;
		} else {
			return null;
		}
	}
}

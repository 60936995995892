import styled from "styled-components";
import {radius} from "../../../../../../styles/styles";
import {colorPalette} from "../../../../../../styles/colorPalette";

export const ReportFilterStyled = styled.div`
	width: 750px;

	.ReportFilterContainer {
		min-height: 250px;
		overflow-y: auto;
		margin: 10px 0;
		height: 30vh;
	}

	.search {
		@extend .hbox;
	}

	.AdvancedFilterField {
		background: white;
		margin: 0px;
		padding: 0px;

		.criterias {
			grid-template-columns: 248px 160px 200px;
			display: grid;
			margin: 10px;
			grid-gap: 10px;
			align-items: center;

			.header {
				display: none;
			}

			input {
				height: 32px;
				padding: 11px;
				border: 1px solid #c8c8c8;
				border-radius: ${radius.sm};
				width: 200px;
			}

			.SelectInput {
				.filterField {
					svg {
						width: 12px;
						height: 12px;
						right: 20px;
					}
				}
			}
		}

		&:hover {
			background: #f5f5f5;
			.button {
				visibility: initial;
			}
		}
	}

	.TabView {
		.views {
			display: block;
			margin: 10px 0;

			.DetailsTab {
				padding: 0 32px;
			}
		}
		.Button {
			margin-left: auto;
		}

		.buttons {
			padding: 0 32px;

			.button {
				background: none;
				margin-right: 50px;
				margin-bottom: 10px;
				color: ${colorPalette.gray.c700Dark};

				&.selected {
					background: white;
					color: var(--blue);
					border-bottom: 3px solid #1e88e5;
				}

				.tabLabel {
					font-size: 18px;
					font-weight: 700;
				}
			}
		}
	}

	&.linkedFields {
		.TabView {
			& > .buttons {
				display: flex;
			}
		}
	}

	.buttons {
		justify-content: left;
	}
`;

// Inspired by this: https://github.com/mapbox/polylabel

import type {IBox2} from "../THREEUtils";
import {THREEUtils} from "../THREEUtils";
import {TinyQueue} from "../TinyQueue";
import type {PointDouble} from "../../generated/api/base";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";

interface IPoleOfInaccessibility extends PointDouble {
	distance: number;
}

export class PolyLabel {
	public static getVisualCenter(polygon: PointDouble[], precision: number = 1, debug: boolean = false): IPoleOfInaccessibility {
		// find the bounding box of the outer ring
		const bbox = THREEUtils.calculateBox(polygon);
		const {min, max} = bbox;
		const size = THREEUtils.getSizeOfBoundingBox2(bbox);

		const width = size.x;
		const height = size.y;
		let cellSize = Math.min(width, height);

		// It can be very tiny for a degenerative boundary, causing a freezing, because the for loop below uses the "cellSize" as the stepSize
		if (cellSize < Constants.EPSILON) {
			cellSize = 0;
		}

		let h = cellSize / 2;

		if (cellSize === 0) {
			const pos = THREEUtils.getCenterOfBoundingBox(bbox);

			return {
				x: pos.x,
				y: pos.y,
				distance: 0,
			};
		}

		// a priority queue of cells in order of their "potential" (max distance to polygon)
		const cellQueue = new TinyQueue([], PolyLabel.compareMax);

		// cover polygon with initial cells
		for (let x = min.x; x < max.x; x += cellSize) {
			for (let y = min.y; y < max.y; y += cellSize) {
				cellQueue.push(new Cell(x + h, y + h, h, polygon));
			}
		}

		// take centroid as the first best guess
		let bestCell = this.getCentroidOfCell(polygon, bbox);

		// second guess: bounding box centroid
		const bboxCell = new Cell(min.x + width / 2, min.y + height / 2, 0, polygon);

		if (bboxCell.d > bestCell.d) {
			bestCell = bboxCell;
		}

		let numProbes = cellQueue.length;

		while (cellQueue.length) {
			// pick the most promising cell from the queue
			const cell = cellQueue.pop();

			// update the best cell if we found a better one
			if (cell.d > bestCell.d) {
				bestCell = cell;
				if (debug) {
					console.log("found best %f after %d probes", Math.round(1e4 * cell.d) / 1e4, numProbes);
				}
			}

			// do not drill down further if there's no chance of a better solution
			if (cell.max - bestCell.d <= precision) {
				continue;
			}

			// split the cell into four cells
			h = cell.h / 2;
			cellQueue.push(new Cell(cell.x - h, cell.y - h, h, polygon));
			cellQueue.push(new Cell(cell.x + h, cell.y - h, h, polygon));
			cellQueue.push(new Cell(cell.x - h, cell.y + h, h, polygon));
			cellQueue.push(new Cell(cell.x + h, cell.y + h, h, polygon));
			numProbes += 4;
		}

		if (debug) {
			console.log(`num probes: ${numProbes}`);
			console.log(`best distance: ${bestCell.d}`);
		}

		return {
			x: bestCell.x,
			y: bestCell.y,
			distance: bestCell.d,
		};
	}

	private static getCentroidOfCell(polygon: PointDouble[], bbox: IBox2) {
		let area = 0;
		let x = 0;
		let y = 0;

		for (let i = 0, len = polygon.length, j = len - 1; i < len; j = i++) {
			const a = polygon[i];
			const b = polygon[j];
			const f = a.x * b.y - b.x * a.y;

			x += (a.x + b.x) * f;
			y += (a.y + b.y) * f;
			area += f * 3;
		}
		if (area === 0) {
			const pos = THREEUtils.getCenterOfBoundingBox(bbox);

			return new Cell(pos.x, pos.y, 0, polygon);
		} else {
			return new Cell(x / area, y / area, 0, polygon);
		}
	}

	private static compareMax(a: Cell, b: Cell) {
		return b.max - a.max;
	}
}

class Cell {
	private _x: number;
	private _y: number;
	private _h: number;

	private _d: number;
	private _max: number;

	constructor(x: number, y: number, h: number, polygon: PointDouble[]) {
		this._x = x;
		this._y = y;
		this._h = h;

		this._d = THREEUtils.pointToPolygonDist({x, y}, polygon); // distance from cell center to polygon
		this._max = this._d + this._h * Math.SQRT2; // max distance to polygon within a cell
	}

	public get d() {
		return this._d;
	}

	public get x() {
		return this._x;
	}

	public get y() {
		return this._y;
	}

	public get h() {
		return this._h;
	}

	public get max() {
		return this._max;
	}
}

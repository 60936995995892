import {EdgesGeometry, Mesh, LineSegments, Color} from "three";
import {CircleGeometry} from "three/src/geometries/CircleGeometry.js";
import {BasicMaterial} from "../materials/BasicMaterial";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {EditableSpaceItem} from "./EditableSpaceItem";

export class GrabbableCorner {
	private static readonly _RADIUS: number = 0.4;
	private _geometry: CircleGeometry = new CircleGeometry(GrabbableCorner._RADIUS, 16);
	private _material: BasicMaterial = new BasicMaterial(0xffffff, 0.5, true);
	private _mesh: Mesh = new Mesh(this._geometry, this._material);
	private _edges: EdgesGeometry = new EdgesGeometry(this._geometry);
	private _lineSegments: LineSegments;

	constructor(spaceItem: EditableSpaceItem, worldX: number, worldY: number, strokeColor: number, meshName: string) {
		this._lineSegments = new LineSegments(this._edges, new BasicMaterial(strokeColor, 1, false));
		this._lineSegments.name = "excluded"; // excluded from raycast intersections
		this._mesh.name = meshName;
		this._mesh.position.set(worldX, worldY, 0);
		this._mesh.add(this._lineSegments);
		this._mesh.userData.grabbableCorner = this;
		this._mesh.userData.itemManager = spaceItem.itemManager;
		THREEUtils.add(spaceItem.intersectables, this._mesh);
	}

	public setSize(size: number) {
		this._mesh.scale.setX(size);
		this._mesh.scale.setY(size);
		THREEUtils.updateMatrices(this._mesh);
	}

	public setFillColor(color: number, intensity: number) {
		const colorObj = new Color(color);

		colorObj.multiplyScalar(intensity);
		(this._mesh.material as BasicMaterial).setColor(colorObj.getHex());
	}

	public dispose() {
		this._mesh.geometry.dispose();
		this._lineSegments.geometry.dispose();
		THREEUtils.disposeContainer(this._mesh);
	}

	public get mesh() {
		return this._mesh;
	}

	public static get RADIUS() {
		return GrabbableCorner._RADIUS;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {type Sortable, ReactSortable} from "react-sortablejs";
import {computed} from "mobx";
import {ConfirmValueWindow} from "../../abstract/popups/ConfirmValueWindow";
import {ConfirmWindow} from "../../abstract/popups/ConfirmWindow";
import type {IPopupWindowConfig} from "../../abstract/popups/PopupWindow";
import {InfoBubble} from "../../abstract/common/infobutton/InfoBubble";
import {DomPortal} from "../../abstract/portal/DomPortal";
import {IconButton} from "../../../widgets/button/IconButton";
import type {UpdateSpaceFileRequest, SpaceFileUpdateData, DeleteSpaceFileRequest, SpaceFileDeleteDto} from "../../../../generated/api/base";
import {Permission, XyiconFeature, FieldDataType} from "../../../../generated/api/base";
import type {Space} from "../../../../data/models/Space";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {Button} from "../../../widgets/button/Button";
import {OptionsButton} from "../../../widgets/button/options/OptionsButton";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {ReactUtils} from "../../../utils/ReactUtils";
import {Functions} from "../../../../utils/function/Functions";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {SVGIcon} from "../../../widgets/button/SVGIcon";
import {CreateVersionSet} from "./CreateVersionSet";

interface IManageVersionSetsState {
	createPanelOpen: boolean;
	editingVersionSet: SpaceVersion;
	activeSpaceId: string;
	hoveredSpaceBgDeleteButton: {i: number; j: number};
	hoveredSpaceBg: {i: number; j: number};
	hoveredSpaceName: number;
	hoveredVersionSetName: number;
	toolTipContent: string | React.JSX.Element;
	toolTipTransform: TransformObj;
}

interface IManageVersionSetsProps {
	onClose: () => void;
	appState?: AppState;
	transport?: TransportLayer;
}

export interface ISpaceVersionSortables {
	id: string; //spaceID
	sortables: ISortableItem[];
}

interface ISortableItem {
	id: string;
	spaceVersion?: SpaceVersion;
}

const dragTolerance = 4;

@inject("appState")
@inject("transport")
@observer
export class ManageVersionSets extends React.PureComponent<IManageVersionSetsProps, IManageVersionSetsState> {
	private _table = React.createRef<HTMLDivElement>();
	private _body = React.createRef<HTMLDivElement>();
	private _header = React.createRef<HTMLDivElement>();

	private _floating = React.createRef<HTMLDivElement>();
	private _deleteButtonRefArray: {[key: string]: HTMLDivElement} = {};
	private _versionSetNameRefArray: HTMLSpanElement[] = [];
	private _spaceNameRefArray: HTMLDivElement[] = [];

	constructor(props: IManageVersionSetsProps) {
		super(props);
		this.state = {
			createPanelOpen: false,
			editingVersionSet: null,
			activeSpaceId: null,
			hoveredSpaceBgDeleteButton: {i: -1, j: -1},
			hoveredSpaceBg: {i: -1, j: -1},
			hoveredSpaceName: -1,
			hoveredVersionSetName: -1,
			toolTipContent: "",
			toolTipTransform: null,
		};
	}

	@computed
	private get sortables(): ISpaceVersionSortables[] {
		const spaces = this.getSpaces();
		const spaceVersions = this.getVersions();

		return spaces
			.filter((space) => this.props.appState.actions.getModuleTypePermission(space.typeId, XyiconFeature.Space) > Permission.View)
			.map((s) => ({
				id: s.id,
				sortables: spaceVersions.map((sv) => ({id: sv.id, spaceVersion: sv})),
			}));
	}

	private onCloseManageVersionSetClick = () => {
		this.props.onClose();
	};

	private onCloseCreatePanel = () => {
		this.setState({createPanelOpen: false, editingVersionSet: null});
	};

	private onEditVersion = (version: SpaceVersion) => {
		this.setState({createPanelOpen: true, editingVersionSet: version});
	};

	private onOpenCreatePanel = () => {
		this.setState({createPanelOpen: true});
	};

	private onDeleteVersion = async (version: SpaceVersion) => {
		const message = `You are about to delete ${version.name}, which will delete all PDFs assigned to it.<br>Are you sure you want to delete the selected version set?`;
		const title = "Confirm Version Set Deletion";
		const confirmed = await ConfirmValueWindow.open(message, version.name, title);

		if (confirmed) {
			await this.props.appState.actions.deleteItems([version], XyiconFeature.SpaceVersion);
		}
	};

	private onDeleteSpaceFile = async (space: Space, versionSet: SpaceVersion) => {
		const message = `You are about to delete the PDF from the ${space.name} space and ${versionSet.name} version set. Do you wish to continue?`;
		const title = "Confirm PDF Deletion";
		const config: IPopupWindowConfig = {ok: "Delete", cancel: "Cancel"};
		const spaceFileIndexToDelete = space.spaceFiles.findIndex((sF) => sF.spaceVersionId === versionSet.id);
		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed && spaceFileIndexToDelete > -1) {
			const spaceFileIdToDelete = space.spaceFiles[spaceFileIndexToDelete].id;

			const params: DeleteSpaceFileRequest = {
				portfolioID: this.props.appState.portfolioId,
				spaceID: space.id,
				spaceFileIDList: [spaceFileIdToDelete],
			};

			space.spaceFiles.splice(spaceFileIndexToDelete, 1);
			const result = await this.props.transport.requestForOrganization<SpaceFileDeleteDto>({
				url: "spacefiles/delete",
				method: XHRLoader.METHOD_DELETE,
				params: params,
			});
		}
	};

	private onMouseOverSpaceBgDelete = (i: number, j: number, name: string, noPermission: boolean) => {
		const toolTipContent = noPermission ? (
			<>
				<SVGIcon icon="locked" />
				<div>User does not have the correct permission to delete this Space version.</div>
			</>
		) : (
			`You are not allowed to delete this PDF from this location because it is the only one available for ${name} space. Navigate to the SPACES module and delete the entire space to remove this PDF.`
		);

		this.setState({
			hoveredSpaceBgDeleteButton: {i, j},
			toolTipContent,
		});
	};

	private onMouseLeaveSpaceBgDelete = () => {
		this.setState({hoveredSpaceBgDeleteButton: {i: -1, j: -1}, toolTipContent: ""});
	};

	private onMouseOverSpaceName = (event: React.MouseEvent, index: number, name: string) => {
		const div = event.currentTarget;

		if (div.scrollWidth > div.clientWidth) {
			this.setState({hoveredSpaceName: index, toolTipContent: name});
		}
	};

	private onMouseLeaveSpaceName = () => {
		this.setState({hoveredSpaceName: -1, toolTipContent: ""});
	};

	private onMouseOverVersionSetName = (event: React.MouseEvent, index: number, name: string) => {
		const div = event.currentTarget;

		if (div.scrollWidth > div.clientWidth) {
			this.setState({hoveredVersionSetName: index, toolTipContent: name});
		}
	};

	private onMouseLeaveVersionSetName = () => {
		this.setState({hoveredVersionSetName: -1, toolTipContent: ""});
	};

	private isDeleteDisabled = (version: SpaceVersion) => {
		const spaces = this.getSpaces();

		return spaces.some((space) => space.versions.length === 1 && space.versions[0].id === version.id);
	};

	private getVersions = () => {
		return this.props.appState.actions
			.getList<SpaceVersion>(XyiconFeature.SpaceVersion)
			.slice()
			.sort((a, b) => (a.date < b.date ? 1 : -1));
	};

	private getSpaces = () => {
		return this.props.appState.actions.getList<Space>(XyiconFeature.Space);
	};

	private onTableScroll = (event?: React.UIEvent) => {
		// This runs when the table is scrolled horizontally.
		// We need to set the width manually for header + body, to make sure the vertical scrollbar is position properly.
		// See:
		// https://stackoverflow.com/questions/48217171/horizontal-and-vertical-scroll-able-table-in-bootstrap

		const table = this._table.current;
		const header = this._header.current;
		const body = this._body.current;

		const w = table.offsetWidth + table.scrollLeft;

		// header is wider than body, so -20 px is needed to prevent forever growing on scroll to right
		header.style.width = `${w - 20}px`;
		body.style.width = `${w}px`;
	};

	private onDragStart = (activeSpaceId: string) => {
		this.setState({activeSpaceId});
	};

	private onDragEnd = async (evt: Sortable.SortableEvent, space: Space) => {
		const indexFrom = evt.oldDraggableIndex;
		const indexTo = evt.newDraggableIndex;
		const spaceVersions = this.getVersions();
		const destinationThumbnail = space.spaceFiles.find((sF) => sF.spaceVersionId === spaceVersions[indexTo].id)?.thumbnailFileURL;

		if (!destinationThumbnail) {
			// Local changes
			const spaceFileToUpdate1 = space.spaceFiles.find((sf) => sf.spaceVersionId === spaceVersions[indexFrom].id);
			const spaceFileToUpdate2 = space.spaceFiles.find((sf) => sf.spaceVersionId === spaceVersions[indexTo].id);

			if (spaceFileToUpdate1) {
				spaceFileToUpdate1.spaceVersionId = spaceVersions[indexTo].id;
			}

			if (spaceFileToUpdate2) {
				spaceFileToUpdate2.spaceVersionId = spaceVersions[indexFrom].id;
			}

			this.setState({
				activeSpaceId: null,
			});

			// Send data to the backend
			const spaceFileUpdateData: SpaceFileUpdateData[] = space.spaceFiles.map((sf) => ({
				spaceFileID: sf.id,
				spaceVersionID: sf.spaceVersionId,
				spaceID: space.id,
				settings: {
					insertionInfo: sf.insertionInfo,
				},
			}));

			const updateParams: UpdateSpaceFileRequest = {
				spaceFileList: spaceFileUpdateData,
				portfolioID: this.props.appState.portfolioId,
			};

			await this.props.transport.updateSpaceFiles(updateParams);
		}
	};

	private getUniqueIndex = (i: number, j: number) => {
		return `${i}_${j}`;
	};

	private onMouseOverSpaceBg = (i: number, j: number) => {
		this.setState({hoveredSpaceBg: {i, j}});
	};

	private onMouseLeaveSpaceBg = () => {
		this.setState({hoveredSpaceBg: {i: -1, j: -1}});
	};

	public override componentDidUpdate(prevProps: IManageVersionSetsProps, prevState: IManageVersionSetsState) {
		const {hoveredSpaceBgDeleteButton, hoveredSpaceName, hoveredVersionSetName} = this.state;
		const {i, j} = hoveredSpaceBgDeleteButton;

		let ref: HTMLElement;

		if (i > -1 && j > -1) {
			ref = this._deleteButtonRefArray[this.getUniqueIndex(i, j)];
		}
		if (hoveredSpaceName > -1) {
			ref = this._spaceNameRefArray[hoveredSpaceName];
		}
		if (hoveredVersionSetName > -1) {
			ref = this._versionSetNameRefArray[hoveredVersionSetName];
		}

		if (
			ref &&
			this._floating.current &&
			prevState.hoveredSpaceName < 0 &&
			prevState.hoveredVersionSetName < 0 &&
			prevState.hoveredSpaceBgDeleteButton.i < 0 &&
			prevState.hoveredSpaceBgDeleteButton.j < 0
		) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					ref,
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}
	}

	public override render() {
		const {appState} = this.props;
		const {createPanelOpen, editingVersionSet, activeSpaceId, toolTipTransform, hoveredSpaceBg, toolTipContent} = this.state;
		const spaceVersions = this.getVersions();
		const {i: bgI, j: bgJ} = hoveredSpaceBg;
		const inlineStyle = this._floating && {
			transform: toolTipTransform?.translate,
		};

		const {sortables} = this;

		return (
			<div className="SidePanel">
				{this.state.createPanelOpen && (
					<div
						className="shadowDiv"
						onClick={this.onCloseCreatePanel}
					/>
				)}
				<div
					className={ReactUtils.cls("createPanel", {
						open: createPanelOpen,
					})}
				>
					{createPanelOpen && (
						<CreateVersionSet
							onClose={this.onCloseCreatePanel}
							editingVersionSet={editingVersionSet}
						/>
					)}
				</div>
				<div className="heading createBox hbox">
					<h4 className="detailsTitle">Version Sets - Create, Edit and Delete Version Sets</h4>
					<IconButton
						icon="close"
						title="Close Panel"
						className="close"
						onClick={this.onCloseManageVersionSetClick}
					/>
				</div>
				<div className="secondaryHeader">
					<Button
						className="primary"
						label="Create Version Set"
						title="Create Version"
						icon="add"
						onClick={this.onOpenCreatePanel}
					/>
				</div>
				<div
					className="Table hbox"
					ref={this._table}
					onScroll={this.onTableScroll}
				>
					<div
						className="TableHeaders hbox alignCenter"
						ref={this._header}
					>
						<div className="th hbox alignCenter">Space</div>
						{spaceVersions.map((version, index) => {
							return (
								<div
									key={index}
									className="th hbox"
								>
									<div className="thText vbox">
										<span
											ref={(ref) => (this._versionSetNameRefArray[index] = ref)}
											className="bold"
											onMouseOver={(e) => this.onMouseOverVersionSetName(e, index, version.name)}
											onMouseLeave={this.onMouseLeaveVersionSetName}
											title={version.name}
										>
											{version.name}
										</span>
										<span className="date">
											{this.props.appState.actions.formatValueByDataType(version.date, FieldDataType.DateTime, {format: "date"})}
										</span>
									</div>
									<div className="dropdown">
										<OptionsButton
											options={[
												{
													label: "Edit Version",
													onSelect: () => this.onEditVersion(version),
												},
												{
													label: "Delete Version",
													onSelect: () => this.onDeleteVersion(version),
													disabled: this.isDeleteDisabled(version),
													infoTextWhenDisabled: `${version.name} cannot be deleted since it contains the only available PDF version of one or more Spaces. Reassign the PDF to a different version set and try again.`,
													delay: 0,
												},
											]}
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div
						className="TableRows"
						ref={this._body}
					>
						{sortables.map((spaceItem, i) => {
							const space = appState.actions.getSpaceById(spaceItem.id);

							return (
								<div
									className={ReactUtils.cls("row hbox", {dragActive: activeSpaceId === space.id})}
									key={spaceItem.id}
								>
									<div
										className="tr alignCenter hbox"
										title={space.name}
									>
										<div
											className="spaceName"
											ref={(ref) => (this._spaceNameRefArray[i] = ref)}
											onMouseOver={(e) => this.onMouseOverSpaceName(e, i, space.name)}
											onMouseLeave={this.onMouseLeaveSpaceName}
										>
											{space.name}
										</div>
									</div>
									<ReactSortable
										className="sortables hbox"
										swap={true}
										forceFallback={true}
										swapThreshold={0.45}
										fallbackTolerance={dragTolerance}
										touchStartThreshold={dragTolerance}
										direction="horizontal"
										list={spaceItem.sortables}
										onStart={() => this.onDragStart(space.id)}
										setList={Functions.emptyFunction}
										onEnd={(evt: Sortable.SortableEvent) => this.onDragEnd(evt, space)}
										filter=".ignore-elements"
									>
										{spaceItem.sortables.map((sortable, j) => {
											const noPermission = appState.actions.getModuleTypePermission(space.typeId, space.ownFeature) < Permission.Delete;
											const isSpaceDeleteDisabled = space.spaceFiles.length === 1 || noPermission;
											const thumbnailURL = space.spaceFiles.find((sF) => sF.spaceVersionId === sortable.spaceVersion.id)?.thumbnailFileURL;

											return (
												<div
													className={ReactUtils.cls("tr", {"ignore-elements": !thumbnailURL})}
													key={sortable.id}
												>
													{thumbnailURL ? (
														<div
															className="cell tiledCell"
															onMouseOver={() => this.onMouseOverSpaceBg(i, j)}
															onMouseLeave={this.onMouseLeaveSpaceBg}
														>
															<div
																className="thumbnail"
																style={{backgroundImage: `url("${thumbnailURL}")`}}
															/>
															{bgI === i && bgJ === j && (
																<div
																	ref={(parentRef) => (this._deleteButtonRefArray[this.getUniqueIndex(i, j)] = parentRef)}
																	className="deleteBtn"
																	onMouseOver={() => isSpaceDeleteDisabled && this.onMouseOverSpaceBgDelete(i, j, space.name, noPermission)}
																	onMouseLeave={this.onMouseLeaveSpaceBgDelete}
																>
																	<IconButton
																		icon="delete"
																		title="Delete"
																		disabled={isSpaceDeleteDisabled}
																		onClick={() => this.onDeleteSpaceFile(space, sortable.spaceVersion)}
																	/>
																</div>
															)}
														</div>
													) : (
														<div className="cell emptyCell" />
													)}
												</div>
											);
										})}
									</ReactSortable>
								</div>
							);
						})}
					</div>
				</div>
				{toolTipContent && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<InfoBubble
							divRef={this._floating}
							content={toolTipContent}
							style={inlineStyle}
							className="DeleteButtonToolTip"
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {Boundary} from "../../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../../data/models/BoundarySpaceMap";
import type {AppState} from "../../../../data/state/AppState";
import {Permission} from "../../../../generated/api/base";
import {ConfirmWindow} from "../../../modules/abstract/popups/ConfirmWindow";
import type {BoundarySpaceMap3D} from "../../../modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import {FieldV5} from "../../details/FieldV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";

interface IBoundaryDefaultFieldsProps {
	item: Boundary;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class BoundaryDefaultFieldsV5 extends React.Component<IBoundaryDefaultFieldsProps> {
	private onTypeChange = async (type: Type) => {
		const {item, appState} = this.props;
		const {spaceViewRenderer} = appState.app;

		const title = "Confirm boundary type change";
		const message =
			"Once you change the boundary type, fields (and data) not assigned to the new boundary type will be removed. Do you wish to continue?";
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed) {
			item.typeId = type.id;

			await this.props.transport.updateBoundaryType(item);

			if (spaceViewRenderer.isMounted) {
				const selectedItems = spaceViewRenderer.boundaryManager.selectedItems;
				const selectedItemIds = selectedItems.map((sI) => sI.id);
				const boundaries = selectedItems.map((item: BoundarySpaceMap3D) => (item.modelData as BoundarySpaceMap).parent.boundarySpaceMaps).flat();

				boundaries.forEach((boundary) => {
					const boundary3D = spaceViewRenderer.boundaryManager.getItemById(boundary.id);

					boundary3D?.destroy(false);
				});
				const b3Ds = spaceViewRenderer.boundaryManager.addItemsByModel(boundaries.filter((b) => b.spaceId === spaceViewRenderer.space.id));

				b3Ds.forEach((b3D) => selectedItemIds.includes(b3D.modelData.id) && b3D.select());
				spaceViewRenderer.spaceItemController.updateActionBar();
				await spaceViewRenderer.boundaryManager.captionManager.updateCaptions();
				await spaceViewRenderer.xyiconManager.captionManager.updateCaptions();
			}
		}
	};

	private get hasPermission() {
		const {item, appState} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, item.ownFeature) >= Permission.Update;
	}

	public override render() {
		const {item, appState} = this.props;

		return (
			<>
				<FieldV5 label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</FieldV5>
				<FieldV5
					className="TypeSelector"
					label="Type"
				>
					<SelectInputV5
						options={this.props.appState.types[item.ownFeature]}
						render={(item) => item.name}
						selected={appState.actions.getTypeById(item.typeId)}
						onChange={this.onTypeChange}
						disabled={!this.hasPermission}
					/>
				</FieldV5>
			</>
		);
	}
}

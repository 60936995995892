import {useRef, useState} from "react";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {UIVersion} from "../../../data/state/AppState";
import {TimeUtils} from "../../../utils/TimeUtils";
import {useAppStore} from "../../../StateManager";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import KeyboardIcon from "../icons/keyboard.svg?react";
import NotebookIcon from "../icons/notebook.svg?react";
import LightbulbIcon from "../icons/lightbulb-alt.svg?react";
import BugIcon from "../icons/bug.svg?react";
import GearIcon from "../icons/gear.svg?react";
import MessageSquareExclamationIcon from "../icons/message-square-exclamation.svg?react";
import ArrowRightFromBracketIcon from "../icons/arrow-right-from-bracket.svg?react";
import type {IDropdownElement} from "../dropdown/TopBarDropdownV5";
import {TopBarDropdownV5} from "../dropdown/TopBarDropdownV5";
import {ShortcutWindowV5} from "../popup/ShortcutWindowV5";
import {useClickOutside} from "../utils";
import {CheckboxInputV5} from "../details/datatypes/CheckboxInputV5";
import {NavigationEnum} from "../../../Enums";
import {AvatarStyled} from "./Avatar.styled";

export const UserProfileV5 = () => {
	const _avatar = useRef<HTMLDivElement>();

	const appState = useAppStore((state) => state.appState);
	const {setNavBarState, navBarState} = useAppStore();
	const [isShortcutOpen, setIsShortcutOpen] = useState<boolean>(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const onSettingsClick = () => {
		appState.app.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal");
		setNavBarState("hidden");
	};

	const onMyProfileClick = () => {
		appState.app.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal");
		if (navBarState === "docked") {
			setNavBarState("closed");
		}
	};

	const onToggleLiveChatBox = () => {
		const liveChat: HTMLDivElement = document.querySelector("#hubspot-messages-iframe-container.widget-align-left");

		liveChat?.classList.toggle("open");
	};

	const onShortCutClicked = async () => {
		if (!isShortcutOpen) {
			setIsShortcutOpen(true);
		} else {
			setIsShortcutOpen(false);
			await TimeUtils.waitForNextFrame();
			setIsShortcutOpen(true);
		}
	};

	const onCloseShortcut = () => {
		setIsShortcutOpen(false);
	};

	const onDocsClicked = () => {
		appState.app.navigation.openInNewTab("https://support.xyicon.com");
	};

	const onFeatureClicked = () => {
		appState.app.navigation.openInNewTab("https://support.xyicon.com/docs/submit-a-feature-request");
	};

	const onBugClicked = () => {
		appState.app.navigation.openInNewTab("https://support.xyicon.com/docs/report-an-issue");
	};

	const onLogoutClick = () => {
		return appState.app.transport.services.auth.logout();
	};

	const onUITogglerClick = () => {
		const newVersion: UIVersion = "4.0";

		appState.setUIVersion(newVersion);
	};

	useClickOutside([_avatar], () => setIsDropdownOpen(false), "mouseup");

	const user = appState.user;
	const initials = user ? appState.actions.renderInitials(user.id) : "";

	const dropdownElements: IDropdownElement[] = [
		{
			icon: <AvatarStyled>{initials}</AvatarStyled>,
			onMouseDown: onMyProfileClick,
			label: "My profile",
		},
		{
			icon: <CircleQuestionIcon />,
			label: "Help",
			subElements: [
				{
					icon: <MessageSquareExclamationIcon />,
					onMouseDown: onToggleLiveChatBox,
					label: "Live Chat",
				},
				{
					icon: <KeyboardIcon />,
					onMouseDown: onShortCutClicked,
					label: "Keyboard Shortcuts",
				},
				{
					icon: <NotebookIcon />,
					onMouseDown: onDocsClicked,
					label: "Help Documents",
				},
				{
					icon: <LightbulbIcon />,
					onMouseDown: onFeatureClicked,
					label: "Suggest a Feature",
				},
				{
					icon: <BugIcon />,
					onMouseDown: onBugClicked,
					label: "Report a Problem",
				},
			],
		},
		{
			icon: <GearIcon />,
			onMouseDown: onSettingsClick,
			label: "Settings",
		},
	];

	if (appState.user?.email.endsWith("@xyicon.com")) {
		dropdownElements.push({
			icon: <CheckboxInputV5 value={true} />,
			onMouseDown: onUITogglerClick,
			label: "Switch to UI 4.0",
			hasLineOnTop: true,
		});
	}

	dropdownElements.push({
		icon: <ArrowRightFromBracketIcon />,
		onMouseDown: onLogoutClick,
		label: "Logout",
	});

	return (
		<>
			<AvatarStyled
				className="avatar avatarColor dropdown"
				ref={_avatar}
				onClick={() => setIsDropdownOpen(!isDropdownOpen)}
			>
				<span>{initials}</span>
				{isDropdownOpen && (
					<DomPortal destination={appState.app.modalContainer}>
						<TopBarDropdownV5
							elements={dropdownElements}
							parent={_avatar}
						/>
					</DomPortal>
				)}
			</AvatarStyled>
			{isShortcutOpen && (
				<DomPortal destination={document.body}>
					<ShortcutWindowV5 onClose={onCloseShortcut} />
				</DomPortal>
			)}
		</>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {Space} from "../../../data/models/Space";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {IModel} from "../../../data/models/Model";
import type {XyiconFeature} from "../../../generated/api/base";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {NoResultFilterView} from "../../modules/abstract/grid/NoResultFilterView";
import {EmptyListViewV5} from "../details/EmptyListViewV5";
import {NoResultSearchView} from "../../modules/abstract/grid/NoResultSearchView";
import type {IActionBarItem} from "../abstract/ModuleViewV5";
import {GridActionBarV5} from "../abstract/GridActionBarV5";
import {SpaceCard} from "./spaceeditor/spaceselector/SpaceCard";

interface ICardViewProps<T extends IModel> {
	feature: XyiconFeature;
	items: T[];
	filterSuppression?: boolean;
	selected: T[];
	onSelect: (item: T[]) => void;
	onDoubleClick: (item: T) => void;
	onAddClick: () => void;
	appState?: AppState;
	transport?: TransportLayer;
	search: string;
	actionBarButtons?: IActionBarItem<T>[];
	actionBarDefaultClick: (item: IActionBarItem<T>) => void;
	onCloseGridActionBar?: () => void;
}

@inject("appState")
@inject("transport")
@observer
export class CardViewV5<T extends IModel> extends React.Component<ICardViewProps<T>> {
	public override render() {
		const {
			items: items_,
			search,
			filterSuppression,
			feature,
			appState,
			selected,
			onSelect,
			onDoubleClick,
			onAddClick,
			actionBarButtons,
			actionBarDefaultClick,
			onCloseGridActionBar,
		} = this.props;
		const searchedItems = appState.actions.searchModels(items_, search, feature);

		const items = searchedItems.toSorted((a: IModel, b: IModel) => StringUtils.sortIgnoreCase(a.name, b.name));

		const hasSearchSuppression = items_.length !== items.length;

		return (
			<>
				<CardViewStyled>
					{items.length > 0 ? (
						items.map((item: T) => (
							<SpaceCard
								key={item.id}
								isSelected={selected.includes(item)}
								space={item as IModel as Space}
								onClick={(s) => {
									if (!selected.includes(s as IModel as T)) {
										onSelect([s as IModel as T]);
									}
								}}
								onDoubleClick={onDoubleClick as (item: IModel) => void}
								topRight="VersionSelector"
							/>
						))
					) : filterSuppression ? (
						<NoResultFilterView />
					) : hasSearchSuppression ? (
						<NoResultSearchView search={search} />
					) : (
						<EmptyListViewV5
							onAddClick={onAddClick}
							feature={feature}
						/>
					)}
				</CardViewStyled>
				{selected.length > 0 && actionBarButtons?.length > 0 && (
					<GridActionBarV5
						items={selected}
						feature={this.props.feature}
						buttons={actionBarButtons}
						defaultClick={actionBarDefaultClick}
						onClose={onCloseGridActionBar}
					/>
				)}
			</>
		);
	}
}

const CardViewStyled = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	grid-auto-rows: 208px;
	gap: 32px;
	overflow-y: auto;
	height: 100%;
	padding: 16px;
`;

import type {InstancedMesh} from "three";
import {makeObservable, observable} from "mobx";
import {Xyicon3D} from "../../elements3d/Xyicon3D";
import type {TextureManager} from "../TextureManager";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {XyiconTextureAtlas} from "../XyiconTextureAtlas";
import {CaptionManager} from "../MSDF/CaptionManager";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import type {ImageBasedXyiconMaterial} from "../../materials/ImageBasedXyiconMaterial";
import type {Xyicon, IXyiconMinimumData} from "../../../../../../../data/models/Xyicon";
import {XyiconFeature, CatalogIconType} from "../../../../../../../generated/api/base";
import type {
	CreateXyiconRequest,
	UpdateXyiconOrientionRequest,
	UpdateXyiconPositionRequest,
	UpdateXyiconSettingsRequest,
	XyiconCreateDetail,
	XyiconDto,
	XyiconFieldUpdateDto,
	XyiconOrientationDto,
	XyiconPositionDto,
} from "../../../../../../../generated/api/base";
import {FeatureService} from "../../../../../../../data/services/FeatureService";
import {XHRLoader} from "../../../../../../../utils/loader/XHRLoader";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import type {Catalog} from "../../../../../../../data/models/Catalog";
import {Formatter} from "../../../../../../../utils/format/Formatter";
import type {Link} from "../../../../../../../data/models/Link";
import {Collection} from "../../../../../../../data/models/abstract/Collection";
import type {IModel} from "../../../../../../../data/models/Model";
import {ModelBasedXyiconManager} from "./ModelBasedXyiconManager";
import {ImageBasedXyiconManager} from "./ImageBasedXyiconManager";
import {ItemManager} from "./ItemManager";

export class XyiconManager extends ItemManager {
	@observable
	protected _items: Collection<Xyicon3D> = new Collection();
	private _xyiconSize: number;
	private _textureManager: TextureManager;
	private _instancedMeshes: InstancedMesh[] = [];
	private _imageBasedXyiconManager: ImageBasedXyiconManager;
	private _modelBasedXyiconManager: ModelBasedXyiconManager;

	private _captionManager: CaptionManager;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "xyicon");
		makeObservable(this);

		this._textureManager = spaceViewRenderer.textureManager;
		this._captionManager = new CaptionManager(spaceViewRenderer, "xyicon");

		this._imageBasedXyiconManager = new ImageBasedXyiconManager(spaceViewRenderer, this, this._container);
		this._modelBasedXyiconManager = new ModelBasedXyiconManager(spaceViewRenderer, this, this._container);
	}

	private saveSettingsChangeToDb(xyicons: Xyicon3D[]) {
		const params: UpdateXyiconSettingsRequest = {
			portfolioID: this._spaceViewRenderer.transport.appState.portfolioId,
			updatedXyicons: xyicons.map((item) => {
				const settings = (item.modelData as Xyicon).settings;
				const xyiconId = item.modelData.id;

				return {
					settings,
					xyiconID: xyiconId,
				};
			}),
		};

		return this._spaceViewRenderer.transport.requestForOrganization<XyiconFieldUpdateDto>({
			method: "POST",
			url: "xyicons/updatesettings",
			params,
		});
	}

	private flipSelected(direction: "X" | "Y") {
		const selectedXyicons = this._selectedItems as Xyicon3D[];

		for (const item of selectedXyicons) {
			const xyiconData = item.modelData as Xyicon;

			const prop: keyof Xyicon = `isFlipped${direction}`;

			xyiconData.settings[prop] = !xyiconData[prop];
			item.updateFlip();
		}
		this._spaceViewRenderer.needsRender = true;

		return this.saveSettingsChangeToDb(selectedXyicons);
	}

	public flipXSelected() {
		return this.flipSelected("X");
	}

	public flipYSelected() {
		return this.flipSelected("Y");
	}

	public initXyiconSize(xyiconSize: number) {
		this._xyiconSize = xyiconSize;
	}

	public getIntersectables() {
		return [
			...this._instancedMeshes,
			...this._items.array.filter((xyicon) => xyicon.counterMesh && xyicon.counterMesh.parent).map((xyicon) => xyicon.counterMesh),
		];
	}

	protected async addItems(items: Xyicon3D[], addToServer: boolean = true) {
		if (addToServer) {
			const appState = this._spaceViewRenderer.transport.appState;
			const spaceID = appState.space.id;
			const portfolioID = appState.portfolioId;

			const xyiconCreateDetailList: XyiconCreateDetail[] = [];

			for (const item of items) {
				const position = item.position;
				const linkedBoundarySpaceMapList = item.getBoundarySpaceMapsWithOverlap();
				const modelData = item.modelData as Xyicon;
				const xyiconDetailObject: XyiconCreateDetail = {
					spaceID: spaceID,
					xyiconCatalogID: modelData.catalogId,
					iconX: position.x,
					iconY: position.y,
					iconZ: position.z,
					parentXyiconID: modelData.parentXyicon?.id || null,
					linkedBoundarySpaceMapList: linkedBoundarySpaceMapList,
					orientation: modelData.orientation,
					fieldValues: modelData.fieldData,
					portData: modelData.portData,
					settings: modelData.settings || {},
				};

				const tempId = (modelData as IXyiconMinimumData).tempId;
				const guid = (modelData as IXyiconMinimumData).guid;

				if (tempId || guid) {
					xyiconDetailObject.settings = {
						...xyiconDetailObject.settings,
						metadata: {
							tempId: tempId,
							guid: guid,
						},
					};
				}
				xyiconCreateDetailList.push(xyiconDetailObject);
			}

			if (xyiconCreateDetailList.length > 0) {
				try {
					const createData: CreateXyiconRequest = {
						portfolioID: portfolioID,
						xyiconCreateDetailList,
					};

					const xyiconModels = await this._spaceViewRenderer.transport.services.feature.create<Xyicon>(createData, XyiconFeature.Xyicon);

					if (xyiconModels?.length === items.length) {
						for (let i = 0; i < items.length; ++i) {
							const item = items[i];
							const xyiconModel = xyiconModels[i];

							item.addModelData(xyiconModel);
							this.triggerUpdateStateForFieldsWithValidation(xyiconCreateDetailList[i], xyiconModel);
						}
					} else {
						for (const item of items) {
							item.destroy(false);
						}
					}

					this._spaceViewRenderer.needsRender = true;
				} catch (error) {
					console.warn(`Xyicon not saved: ${error}`);
				}
			}
		}

		this._items.addMultiple(items);
	}

	public async createSpaceItem3DFromModel(model: IXyiconMinimumData) {
		const {actions} = this._spaceViewRenderer.transport.appState;
		const catalog = actions.getFeatureItemById(model.catalogId, XyiconFeature.XyiconCatalog) as Catalog;

		const instancedXyiconManager =
			catalog.iconCategory === CatalogIconType.ModelParameter ? this._modelBasedXyiconManager : this._imageBasedXyiconManager;
		const {instanceId, instancedMeshId, color, opacity} = await instancedXyiconManager.createNecessaryDataFromXyiconModel(model);

		if ((model as any).iconCategory == null) {
			(model as any).iconCategory = catalog.iconCategory;
		}

		return new Xyicon3D(this._spaceViewRenderer, instancedMeshId, instanceId, model, color, opacity);
	}

	public async addItemsByModel(models: Xyicon[], forceGeometryReload: boolean = false) {
		const catalogGeometryAlreadyReloaded: {[key: string]: true} = {};

		const xyicons: Xyicon3D[] = [];

		for (const model of models) {
			if (forceGeometryReload && !catalogGeometryAlreadyReloaded[model.catalogId] && model.catalog.iconCategory === CatalogIconType.ModelParameter) {
				const indexOfCatalog = this._instancedMeshes.findIndex((instancedMesh: InstancedMesh) => instancedMesh.name === `${model.catalogId}`);

				if (indexOfCatalog > -1) {
					this._instancedMeshes[indexOfCatalog].name += "_old"; // to disable cache-loading from it
					this._modelBasedXyiconManager.deleteGeometryFromCache(model.catalogId);
				}
				catalogGeometryAlreadyReloaded[model.catalogId] = true;
			}

			xyicons.push(await this.createSpaceItem3DFromModel(model));
		}
		this.add(xyicons, false);

		for (const model of models) {
			const linkObjects = this._spaceViewRenderer.actions.getLinksXyiconXyicon(model.id);

			for (const linkObject of linkObjects) {
				this.updateEmbeddedDetailsOfXyicon(linkObject.link, "created");
			}
		}

		return xyicons;
	}

	public async initXyicons(xyicons: Xyicon[]) {
		const imageBasedXyicons: Xyicon[] = []; // Default, and Custom (Standard, and Skinned)
		const modelBasedXyicons: Xyicon[] = []; // ThreeDimensional (Revit)

		for (const xyicon of xyicons) {
			const iconCategory = xyicon.catalog?.iconCategory;

			if (iconCategory != null) {
				if (iconCategory === CatalogIconType.ModelParameter) {
					modelBasedXyicons.push(xyicon);
				} else {
					imageBasedXyicons.push(xyicon);
				}
			} else {
				console.warn(`Xyicon (${xyicon?.refId} doesn't have a valid catalog. Not loaded yet?)`);
			}
		}

		await this._captionManager.init();

		await this._imageBasedXyiconManager.init(imageBasedXyicons);
		await this._modelBasedXyiconManager.init(modelBasedXyicons);

		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.updateCameraPosUniform);
	}

	private updateCameraPosUniform = () => {
		const cameraPos = this._spaceViewRenderer.activeCamera.position;

		for (const instancedMesh of this.instancedMeshes) {
			(instancedMesh.material as ImageBasedXyiconMaterial).setCameraPosition?.([cameraPos.x, cameraPos.y, cameraPos.z]);
		}
	};

	public getSpaceItemByInstancedMeshIDAndInstanceID(instancedMeshId: number, instanceId: number) {
		for (const xyicon of this._items.array) {
			if (xyicon.instanceId === instanceId && xyicon.instancedMeshId === instancedMeshId) {
				return xyicon;
			}
		}
	}

	public setSize(newSize: number) {
		if (this._xyiconSize !== newSize) {
			this._xyiconSize = newSize;

			for (const xyicon of this._items.array) {
				xyicon.setSize(newSize);
			}
		}

		this.updateSelectionBox();
		this._spaceViewRenderer.needsRender = true;
	}

	public updateEmbeddedDetailsOfXyicon(link: Link, action: "deleted" | "created" | "updated") {
		if (link.isEmbedded || action === "updated") {
			const parentXyicon = this.getItemById(link.fromObjectId) as Xyicon3D;

			parentXyicon?.updateCounter();
		}

		const toXyicon = this.getItemById(link.toObjectId) as Xyicon3D;

		if (toXyicon) {
			const isHidden = (link.isEmbedded && (action === "created" || action === "updated")) || toXyicon.layerSettings.isHidden;

			toXyicon.setVisibility(!isHidden);
		}
	}

	/**
	 * Hides captions of selected xyicons
	 */
	public hideCaptionsForSelected() {
		const selectedItems = this._selectedItems as Xyicon3D[];

		for (const xyicon of selectedItems) {
			xyicon.hideCaption();
		}
		this._captionManager.hideTextGroup(selectedItems.filter(CaptionManager.filterVisibleCaptionedItems).map((x) => x.caption));
	}

	/**
	 * Shows captions of selected xyicons
	 */
	public showCaptionsForSelected() {
		const selectedItems = this._selectedItems as Xyicon3D[];

		for (const xyicon of selectedItems) {
			xyicon.showCaption();
		}
		if (selectedItems.length > 0) {
			this._captionManager.updateTextTransformations();
		}
	}

	public hideCaptions() {
		this._captionManager.hide();

		for (const xyicon of this._items.array) {
			if (xyicon.captionLeaderLine) {
				xyicon.captionLeaderLine.hide();
			}
		}
	}

	public showCaptions() {
		this._captionManager.show();

		for (const xyicon of this._items.array) {
			if (xyicon.captionLeaderLine && xyicon.caption.isVisible) {
				xyicon.captionLeaderLine.show();
			}
		}
	}

	/**
	 * // world coordinates, measured from pointerStart (NOT from the last pointerposition) -> this way it's more reliable
	 * @param x
	 * @param y
	 */
	public override translateSelectedItems(x: number, y: number, z: number) {
		super.translateSelectedItems(x, y, z);
		this._spaceViewRenderer.spaceItemController.linkIconManager.icons?.computeBoundingSphere();
		this.hideCaptionsForSelected();
	}

	public override stopTranslatingSelectedItems() {
		super.stopTranslatingSelectedItems();
		this.showCaptionsForSelected();
	}

	public async updateItems(items: SpaceItem[], force: boolean = false) {
		const xyicons = [...items]; // the original array can be modified (eg.: length = 0), and it can cause bugs if we don't clone the array like this
		const xyiconListForOrientationChange: XyiconOrientationDto[] = [];
		const xyiconListForPositionChange: XyiconPositionDto[] = [];

		for (const xyicon3D of xyicons as Xyicon3D[]) {
			const modelData = xyicon3D.modelData as Xyicon;
			const previousData = {
				iconX: modelData.iconX,
				iconY: modelData.iconY,
				iconZ: modelData.iconZ,
			};

			const currentPosition = xyicon3D.position;

			const hasChanged =
				force || previousData.iconX !== currentPosition.x || previousData.iconY !== currentPosition.y || previousData.iconZ !== currentPosition.z;

			if (hasChanged) {
				xyiconListForPositionChange.push({
					xyiconID: modelData.id,
					iconX: currentPosition.x,
					iconY: currentPosition.y,
					iconZ: currentPosition.z,
					linkedBoundarySpaceMapList: xyicon3D.getBoundarySpaceMapsWithOverlap(),
				});
			}
		}

		for (const xyicon3D of xyicons) {
			const modelData = xyicon3D.modelData as Xyicon;
			const previousOrientation = modelData.orientation;
			const currentOrientation = xyicon3D.orientation;

			const hasChanged = currentOrientation !== previousOrientation;

			if (hasChanged) {
				xyiconListForOrientationChange.push({
					xyiconID: modelData.id,
					orientation: currentOrientation,
				});
			}
		}

		const updateTypes: ("position" | "orientation")[] = ["position", "orientation"];

		for (const type of updateTypes) {
			const xyiconList = type === "orientation" ? xyiconListForOrientationChange : xyiconListForPositionChange;

			if (xyiconList.length > 0) {
				try {
					const name = FeatureService.getApiNameForFeature(this.feature);

					const params: UpdateXyiconPositionRequest & UpdateXyiconOrientionRequest = {
						spaceID: this._spaceViewRenderer.transport.appState.space.id,
						portfolioID: this._spaceViewRenderer.transport.appState.portfolioId,
					};

					const capitalizedType = Formatter.capitalize(type) as "Position" | "Orientation";

					params[`xyicon${capitalizedType}List`] = xyiconList;

					const {result} = await this._spaceViewRenderer.transport.requestForOrganization<XyiconDto[]>({
						url: `${name}/update${type}`,
						method: XHRLoader.METHOD_POST,
						params: params,
					});

					const updatedXyiconArray: IModel[] = [];

					for (const newXyiconModelData of result) {
						const xyicon3D = xyicons.find((xyicon: Xyicon3D) => xyicon.modelData.id === newXyiconModelData.xyiconID);

						xyicon3D.applyModelData(ObjectUtils.apply(JSON.parse(JSON.stringify((xyicon3D.modelData as Xyicon).data)), newXyiconModelData));
						updatedXyiconArray.push(xyicon3D.modelData);
					}
					this.signals.itemsUpdate.dispatch(updatedXyiconArray);
				} catch (error) {
					console.warn("Items couldn't be updated on the backend!");
					console.warn(error);
				}
			}
		}
	}

	public getInstancedMeshById(instancedMeshId: number) {
		return this._instancedMeshes[instancedMeshId];
	}

	public async updateXyicons(xyicons: Xyicon[]) {
		if (this._spaceViewRenderer.isMounted) {
			// save ids for reselecting
			const selectedXyiconIds = this.selectedItems.map((xyicon) => xyicon.modelData.id);

			this.deselectAll();

			// since they're instanced meshes, and they can be different 3d models as well, it's much easier and safer to just recreate them...
			for (const xyicon of xyicons) {
				const xyicon3D = this.getItemById(xyicon.id);

				xyicon3D?.destroy(false);
			}
			await this.addItemsByModel(xyicons, true);

			// reselect them after they're recreated
			for (const xyiconId of selectedXyiconIds) {
				const xyicon3D = this.getItemById(xyiconId);

				xyicon3D.select();
			}
			this._spaceViewRenderer.spaceItemController.updateActionBar();
			this._spaceViewRenderer.spaceItemController.updateDetailsPanel(true);
			this._spaceViewRenderer.spaceItemController.linkIconManager.update();
		}
	}

	public override clear() {
		super.clear();
		for (const instancedMesh of this._instancedMeshes) {
			instancedMesh.geometry.dispose();
			(instancedMesh.material as unknown as XyiconTextureAtlas).dispose();
		}
		this._modelBasedXyiconManager.clearCache();
		this._instancedMeshes.length = 0;
		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.updateCameraPosUniform);
		this._captionManager.clear();
	}

	public async updateCatalog(catalog: Catalog) {
		if (this._spaceViewRenderer.isMounted) {
			if (catalog.iconCategory !== CatalogIconType.ModelParameter) {
				await this._imageBasedXyiconManager.updateCatalogTexture(catalog);
			}
			// Recreate the affected xyicons

			const affectedXyicons = this._items.array
				.map((xyicon3D: Xyicon3D) => xyicon3D.modelData as Xyicon)
				.filter((xyicon: Xyicon) => xyicon?.catalogId === catalog.id);

			await this.updateXyicons(affectedXyicons);
		}
	}

	public get instancedMeshes() {
		return this._instancedMeshes;
	}

	public get xyiconSize() {
		return this._xyiconSize;
	}

	public get textureManager() {
		return this._textureManager;
	}

	public get captionManager() {
		return this._captionManager;
	}
}

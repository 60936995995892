import {computed, observable, makeObservable} from "mobx";
import type {AppState} from "../state/AppState";
import {XyiconFeature} from "../../generated/api/base";
import type {SpaceDto, SpaceFileDto, SpaceUpdateFieldDto} from "../../generated/api/base";
import {DateUtils} from "../../utils/DateUtils";
import type {IModel} from "./Model";
import type {SpaceVersion} from "./SpaceVersion";
import {SpaceFile} from "./SpaceFile";

export class Space implements IModel, Omit<SpaceDto, "spaceFiles"> {
	public static search(space: Space, search = "") {
		if (!search) {
			return true;
		}

		search = search.toLowerCase();
		const refId = space.refId.toLowerCase();

		if (refId.indexOf(search) > -1) {
			return true;
		}

		return false;
	}

	public readonly ownFeature = XyiconFeature.Space;

	@observable
	private _data: Omit<SpaceDto, "spaceFiles">;

	@observable
	private _spaceFiles: SpaceFile[];

	@observable
	private _selectedSpaceFileId: string;

	private _appState: AppState;

	constructor(data: SpaceDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data);
	}

	public applyUpdate(data: SpaceUpdateFieldDto) {
		if (data.spacesIDList.includes(this.id)) {
			this.applyFieldValues(data.fieldValues);
			this._data.lastModifiedAt = data.lastModifiedAt || this._data.lastModifiedAt;
			this._data.lastModifiedBy = data.lastModifiedBy || this._data.lastModifiedBy;
		}
	}

	public applyFieldValues(fieldValues: Record<string, any> | null) {
		if (fieldValues.name !== undefined) {
			this.name = fieldValues.name;
		}
	}

	public applyData(data_: SpaceDto) {
		const {spaceFiles, ...data} = data_;

		this._data = data;
		this._spaceFiles = spaceFiles
			.map((spaceFileData: SpaceFileDto) => new SpaceFile(spaceFileData, this, this._appState))
			.sort(this.sortSpaceFilesByIssueanceDate);

		this._selectedSpaceFileId = this.newestValidSpaceFile?.id;
		this._data.fieldData = this._data.fieldData || {};
	}

	private sortSpaceFilesByIssueanceDate = (a: SpaceFile, b: SpaceFile) => DateUtils.sortByDate(a.spaceVersion?.date, b.spaceVersion?.date);

	public removeTemporarySpaceFiles() {
		this._spaceFiles = this._spaceFiles.filter((spaceFile: SpaceFile) => spaceFile.id != null);
	}

	// Newest is the [0] element, oldest is the [n-1]
	// Temporary space files are generated while the user is creating / aligning a new spacefile
	public addTemporarySpaceFile(spaceFileData: SpaceFileDto) {
		const newSpaceFile = new SpaceFile(spaceFileData, this, this._appState);

		// Override, if a temporary spacefile already exists
		if (this.newestSpaceFile.id == null) {
			this._spaceFiles[0] = newSpaceFile;
		} else {
			this._spaceFiles.unshift(newSpaceFile);
		}
	}

	public addSpaceFile(spaceFileData: SpaceFileDto) {
		const newSpaceFile = new SpaceFile(spaceFileData, this, this._appState);

		this._spaceFiles.unshift(newSpaceFile);
		// The newly added spacefile might not be the newest, when it comes to issueance date of spaceversions
		// So we need to sort it again
		this._spaceFiles = this._spaceFiles.slice().sort(this.sortSpaceFilesByIssueanceDate);
		this.setSelectedSpaceFile(this.newestSpaceFile);
	}

	public setSelectedSpaceFile(spaceFile: SpaceFile) {
		this._selectedSpaceFileId = spaceFile?.id || "";
	}

	public setSpaceUnitsPerMeter(spaceUnitsPerMeter: number) {
		this._data.unitsPerMeter = spaceUnitsPerMeter;
	}

	public setSpaceType(typeID: string) {
		this._data.spaceTypeID = typeID;
	}

	@computed
	public get selectedSpaceFile() {
		return this._spaceFiles.find((spaceFile) => spaceFile.id === this._selectedSpaceFileId) || this.newestSpaceFile;
	}

	@computed
	public get id() {
		return this._data.spaceID;
	}

	@computed
	public get refId() {
		return this._data.spaceRefID;
	}

	@computed
	public get typeId() {
		return this._data.spaceTypeID;
	}

	@computed
	public get type() {
		return this._appState.actions.getTypeById(this.typeId);
	}

	@computed
	public get typeName() {
		return this.type?.name || "";
	}

	@computed
	public get portfolioId() {
		return this._data.portfolioID;
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name;
	}

	@computed
	public get spaceUnitsPerMeter() {
		return this._data.unitsPerMeter;
	}

	@computed
	public get newestSpaceFile() {
		return this._spaceFiles[0];
	}

	@computed
	public get newestValidSpaceFile() {
		return this._spaceFiles.find((spaceFile: SpaceFile) => spaceFile.id != null);
	}

	@computed
	public get thumbnailFileURL() {
		return this.selectedSpaceFile.thumbnailFileURL;
	}

	@computed
	public get newestSourceFileURL() {
		return this.newestSpaceFile.sourceFileURL;
	}

	@computed
	public get spaceFiles() {
		return this._spaceFiles;
	}

	@computed
	public get versionId() {
		return (this.selectedSpaceFile || this.newestSpaceFile).spaceVersionId;
	}

	@computed get versions() {
		return this.spaceFiles.map((spaceFile: SpaceFile) =>
			this._appState.actions.getFeatureItemById(spaceFile.spaceVersionId, XyiconFeature.SpaceVersion),
		) as SpaceVersion[];
	}

	@computed
	public get issuanceDate() {
		return this._appState.actions.getFeatureItemById<SpaceVersion>(this.versionId, XyiconFeature.SpaceVersion)?.date || "";
	}

	@computed
	public get versionName() {
		// this is used by DetailsTab
		return this._appState.actions.getFeatureItemById<SpaceVersion>(this.versionId, XyiconFeature.SpaceVersion)?.name || "";
	}

	@computed
	public get fieldData() {
		return this._data.fieldData;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import {styled} from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {radius, FlexCenterStyle} from "../styles/styles";

export const LineStyled = styled.div`
	height: 32px;
	width: 2px;
	background-color: ${colorPalette.primary.c500Primary};
`;

export const ProjectNameStyled = styled.div<{$isOrgWide?: boolean; $isInSettingsView: boolean}>`
	${FlexCenterStyle};
	gap: 8px;
	position: relative;
	cursor: ${(props) => (props.$isOrgWide ? "auto" : "pointer")};
	margin-left: ${(props) => (props.$isInSettingsView ? "200px" : "0")};
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
`;

export const ProjectNameDropDownStyled = styled.div`
	display: flex;
	padding: 0 4px;
	height: 32px;
	border-radius: 4px;
	justify-content: flex-start;
	gap: 8px;
	align-items: center;
	width: 100%;

	&:hover,
	&.isOpen {
		background-color: ${colorPalette.blueGray.c200Light};
	}

	.projectTopBar {
		width: 140px;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	.projectNavBar {
		display: flex;
		align-items: center;
		overflow: hidden;
		width: 100%;
	}

	.projectNameTopBar {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
		padding-right: 8px;
	}

	.projectNameNavBar {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 16px;
	}

	.DropdownV5 {
		border-radius: 10px;
		max-height: 288px;

		li {
			height: 40px;
		}
	}
`;

export const ProjectNameIconsStyled = styled.div`
	${FlexCenterStyle};
	pointer-events: auto;
	position: relative;
	width: 12px;
	height: 12px;
	margin-left: auto;
	cursor: pointer;

	&.dockIcon {
		width: 28px;
		height: 28px;
		fill: transparent;
		border-radius: ${radius.xs};

		.icon {
			width: 24px;
			height: 24px;
		}
	}
`;

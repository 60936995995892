import * as React from "react";
import {inject, observer} from "mobx-react";
import {OptionsButton} from "../button/options/OptionsButton";
import {SVGIcon} from "../button/SVGIcon";
import {Initials} from "../Initials";
import type {IViewItem} from "../../../data/models/ViewUtils";
import {TextInput} from "../input/text/TextInput";
import type {Collection} from "../../../data/models/abstract/Collection";
import {XyiconFeature} from "../../../generated/api/base";
import type {User} from "../../../data/models/User";
import type {UserGroup} from "../../../data/models/UserGroup";
import type {AppState} from "../../../data/state/AppState";
import {InfoBubble} from "../../modules/abstract/common/infobutton/InfoBubble";
import {ConfirmWindow} from "../../modules/abstract/popups/ConfirmWindow";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../utils/TimeUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {StringUtils} from "../../../utils/data/string/StringUtils";

interface IViewItemProps {
	appState?: AppState;
	data: IViewItem;
	selectedViewId: string;
	searchString: string;
	onShareClick: (viewId: string) => void;
	startFocusListen: () => void;
	stopFocusListen: () => void;
	onElementAddedToList: () => void;
	closeViewSelect: () => void;
	onSelect: (view: string) => Promise<void>;
}

interface IViewItemState {
	isOwnerToolTipOpen: boolean;
	isSharedToolTipOpen: boolean;
	sharedToolTipTransform: TransformObj | null;
	ownerToolTipTransform: TransformObj | null;
	isInEditMode: boolean;
	renameErrorMessage: string;
	isViewNameToolTipOpen: boolean;
	viewNameToolTipTransform: TransformObj;
}

@inject("appState")
@observer
export class ViewItem extends React.Component<IViewItemProps, IViewItemState> {
	private _sharedNum = React.createRef<HTMLDivElement>();
	private _sharedToolTip = React.createRef<HTMLDivElement>();
	private _avatar = React.createRef<HTMLDivElement>();
	private _ownerToolTip = React.createRef<HTMLDivElement>();
	private _viewName = React.createRef<HTMLDivElement>();
	private _viewNameParent = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;

	constructor(props: IViewItemProps) {
		super(props);

		this.state = {
			isOwnerToolTipOpen: false,
			isSharedToolTipOpen: false,
			sharedToolTipTransform: null,
			ownerToolTipTransform: null,
			isInEditMode: false,
			renameErrorMessage: "",
			isViewNameToolTipOpen: false,
			viewNameToolTipTransform: null,
		};
	}

	private openOwnerToolTip = () => {
		this.setState({
			isOwnerToolTipOpen: true,
		});
	};

	private closeOwnerToolTip = () => {
		this.setState({
			isOwnerToolTipOpen: false,
		});
	};

	private openSharedToolTip = () => {
		this.setState({
			isSharedToolTipOpen: true,
		});
	};

	private closeSharedToolTip = () => {
		this.setState({
			isSharedToolTipOpen: false,
		});
	};

	private onClick = (e: React.MouseEvent) => {
		const {closeViewSelect, onSelect, data} = this.props;

		if ((e.target as HTMLElement).tagName.toUpperCase() !== "INPUT") {
			closeViewSelect();
			onSelect(data.id);
		}
	};

	private onDuplicateClick = () => {
		return this.view.duplicate(this.workaroundForFocusLoss, this.props.onElementAddedToList);
	};

	private onDeleteClick = async () => {
		this.props.stopFocusListen();
		const confirmed = await ConfirmWindow.open("Are you sure you want to delete the selected 1 item?");

		this.props.startFocusListen();

		if (confirmed) {
			await this.props.appState.app.transport.services.view.delete(this.props.data.id);
		}
	};

	private onShareClick = () => {
		this.props.onShareClick(this.props.data.id);
	};

	private onRenameClick = async () => {
		await this.workaroundForFocusLoss();
		if (!this.state.isInEditMode) {
			this.setState({
				isInEditMode: true,
			});
		}
	};

	private getErrorMessage = (value: string) => {
		const view = this.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			return "";
		}
		return value === "" ? "Name cannot be empty!" : "Name needs to be unique!";
	};

	private onRenameInput = (value: string) => {
		const renameErrorMessage = this.getErrorMessage(value);

		if (renameErrorMessage && !this.state.renameErrorMessage) {
			this.setState({
				renameErrorMessage,
			});
		} else if (!renameErrorMessage && this.state.renameErrorMessage) {
			this.setState({
				renameErrorMessage: "",
			});
		}
	};

	private onRenameApply = async (value: string) => {
		const view = this.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			this.onRenameBlur();

			if (value !== view.name) {
				view.name = value;
				await this.props.appState.app.transport.services.view.update(view.getData());
			}
		}
	};

	private onRenameBlur = () => {
		if (this.state.isInEditMode) {
			this.setState({
				isInEditMode: false,
				renameErrorMessage: "",
			});
		}

		this.props.closeViewSelect();
	};

	private workaroundForFocusLoss = async () => {
		this.props.stopFocusListen();
		await TimeUtils.waitForNextFrame();
		this.props.startFocusListen();
	};

	private getOptions = () => {
		const {appState} = this.props;
		const view = this.view;
		const user = appState.user;
		const userCanEdit = view?.getPermission(user.id) > 1;
		const userIsOwner = view?.ownedBy === user.id;
		const options = [];

		if (userIsOwner || userCanEdit) {
			options.push({
				label: "Share",
				onSelect: this.onShareClick,
			});
		}

		if (userIsOwner) {
			options.push({
				label: "Change Owner",
				onSelect: this.onShareClick,
			});
		}

		if (userIsOwner || userCanEdit) {
			options.push({
				label: "Rename",
				onSelect: this.onRenameClick,
			});
		}

		options.push({
			label: "Duplicate",
			onSelect: this.onDuplicateClick,
		});

		if (userIsOwner) {
			options.push({
				label: "Delete",
				onSelect: this.onDeleteClick,
			});
		}

		return options;
	};

	private getViewSharingListLength = () => {
		const {view} = this;
		const settings = view?.viewSharingSettings;
		let length = settings?.filter((sharing) => sharing.userID !== view?.ownedBy).length ?? 0;

		if (length > 99) {
			return "99+";
		}

		return length;
	};

	private getToolTipContent = () => {
		const view = this.view;
		const settings = view.viewSharingSettings;
		const userGroups = this.props.appState.lists[XyiconFeature.UserGroup] as Collection<UserGroup>;
		const users = settings.filter((sharing) => sharing.userID && sharing.userID !== view.ownedBy);
		const groups = settings.filter((sharing) => sharing.userGroupID);

		// users who are not in any user group
		const individuals = users.filter(
			(user) => !groups.find((sharing) => (userGroups.getById(sharing.userGroupID) as UserGroup)?.userIds.includes(user.userID)),
		);

		let content = "";

		if (users.length > 0 && groups.length === 0) {
			content = `Shared with ${users.length} user${users.length > 1 ? "s" : ""}`;
		}
		if (users.length === 0 && groups.length > 0) {
			content = `Shared with ${groups.length} group${groups.length > 1 ? "s" : ""}`;
		}
		if (groups.length > 0 && individuals.length > 0) {
			content = `Shared with ${groups.length} group${groups.length > 1 ? "s" : ""} and ${individuals.length} user${individuals.length > 1 ? "s" : ""}`;
		}

		return content;
	};

	private onMouseOverViewName = (event: React.MouseEvent) => {
		if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
			if (this._timeOutId) {
				clearTimeout(this._timeOutId);
			}

			this._timeOutId = window.setTimeout(() => {
				this.setState({isViewNameToolTipOpen: true});
			}, 1000);
		}
	};

	private onMouseLeaveViewName = () => {
		clearTimeout(this._timeOutId);

		this.setState({isViewNameToolTipOpen: false});
	};

	private get view() {
		return this.props.appState.actions.getViewById(this.props.data.id);
	}

	public override componentDidUpdate(prevProps: IViewItemProps, prevState: IViewItemState) {
		if (!prevState.isSharedToolTipOpen && this.state.isSharedToolTipOpen && this._sharedNum.current && this._sharedToolTip.current) {
			this.setState({
				sharedToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._sharedNum.current,
					this._sharedToolTip.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					8,
					0,
				),
			});
		}

		if (!prevState.isOwnerToolTipOpen && this.state.isOwnerToolTipOpen && this._avatar.current && this._ownerToolTip.current) {
			this.setState({
				ownerToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._avatar.current,
					this._ownerToolTip.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					10,
					13,
				),
			});
		}

		if (!prevState.isViewNameToolTipOpen && this.state.isViewNameToolTipOpen && this._viewNameParent.current && this._viewName.current) {
			this.setState({
				viewNameToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._viewNameParent.current,
					this._viewName.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					0,
					0,
				),
			});
		}
	}

	public override render() {
		const {
			isSharedToolTipOpen,
			sharedToolTipTransform,
			isOwnerToolTipOpen,
			ownerToolTipTransform,
			viewNameToolTipTransform,
			isInEditMode,
			renameErrorMessage,
			isViewNameToolTipOpen,
		} = this.state;
		const {searchString, appState} = this.props;
		const app = appState.app;
		const view = this.view;

		const users = appState.lists[XyiconFeature.User] as Collection<User>;
		const viewOwner: User = users?.getById(view?.ownedBy);

		const isFilteredOut = searchString ? !StringUtils.containsIgnoreCase(this.view.name, searchString) : false;

		const inlineStyleSharedToolTip: React.CSSProperties = isSharedToolTipOpen
			? {
					transform: sharedToolTipTransform?.translate,
					zIndex: 8000,
				}
			: {};

		const inlineStyleOwnerToolTip: React.CSSProperties = isOwnerToolTipOpen
			? {
					transform: ownerToolTipTransform?.translate,
					zIndex: 8000,
				}
			: {};

		const inlineStyleViewName: React.CSSProperties = this._viewName.current && {
			transform: viewNameToolTipTransform?.translate,
		};

		return (
			<div
				className={ReactUtils.cls("ViewItem hbox alignCenter", {
					active: this.props.selectedViewId === this.props.data.id,
					isInEditMode: this.state.isInEditMode,
					hidden: isFilteredOut,
				})}
				onClick={this.onClick}
			>
				<SVGIcon
					icon="drag"
					classNames="drag"
				/>
				{isInEditMode ? (
					<TextInput
						className="viewName flex_1"
						value={view.name ?? ""}
						errorMessage={renameErrorMessage}
						onInput={this.onRenameInput}
						onChange={this.onRenameApply}
						onBlur={this.onRenameBlur}
						autoFocus={true}
						getErrorMessage={this.getErrorMessage}
						errorMessageTop={-22}
					/>
				) : (
					<div
						ref={this._viewNameParent}
						className="viewName flex_1"
						onMouseOver={this.onMouseOverViewName}
						onMouseLeave={this.onMouseLeaveViewName}
					>
						{view.name}
					</div>
				)}
				<div
					className="avatar"
					ref={this._avatar}
					onMouseEnter={this.openOwnerToolTip}
					onMouseLeave={this.closeOwnerToolTip}
				>
					{isOwnerToolTipOpen && (
						<DomPortal destination={app.modalContainer}>
							<div
								className="ViewItem__ownerToolTip"
								style={inlineStyleOwnerToolTip}
								ref={this._ownerToolTip}
							>
								<InfoBubble content="Owner" />
							</div>
						</DomPortal>
					)}
					{viewOwner?.profileFileName ? (
						<img
							src={viewOwner.profileFileName}
							alt={`${viewOwner?.fullName} profile image`}
						/>
					) : (
						<Initials name={viewOwner?.fullName || viewOwner?.email} />
					)}
				</div>
				<div
					className="sharedNum"
					onMouseEnter={this.openSharedToolTip}
					onMouseLeave={this.closeSharedToolTip}
					ref={this._sharedNum}
				>
					{isSharedToolTipOpen && (
						<DomPortal destination={app.modalContainer}>
							<div
								className="ViewItem__sharedToolTip"
								style={inlineStyleSharedToolTip}
								ref={this._sharedToolTip}
							>
								<InfoBubble content={this.getToolTipContent()} />
							</div>
						</DomPortal>
					)}
					{this.getViewSharingListLength() ? `/${this.getViewSharingListLength()}` : ""}
				</div>
				{isViewNameToolTipOpen && (
					<DomPortal destination={app.modalContainer}>
						<div
							className="ViewItem__viewNameToolTip"
							style={inlineStyleViewName}
							ref={this._viewName}
						>
							<InfoBubble content={view.name ?? ""} />
						</div>
					</DomPortal>
				)}
				<OptionsButton options={this.getOptions()} />
			</div>
		);
	}
}

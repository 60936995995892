export enum PassSecurityLevel {
	RequirementsNotMet = 0,
	Weak = 1,
	Good = 2,
	Strong = 3,
}

export class PasswordUtils {
	// at least 8 characters
	public static isLevel1(pass: string) {
		return pass.length > 7;
	}

	// //contains one or more special characters (space is an acceptable special character)
	// public static isLevel2(pass: string)
	// {
	// 	return /[!@#$%^&*()_+\s\-=\[\]{};':"\\|,.<>\/?]/.test(pass);
	// }

	// at least one numerical character (0-9)
	public static isLevel2(pass: string) {
		return /[0-9]/.test(pass);
		//return pass !== pass.toUpperCase();
	}

	//contains one or more lower-case alphabetical character (A-Z)
	public static isLevel3(pass: string) {
		return /[a-z]/.test(pass);
	}

	//contains one or more upper-case alphabetical character (A-Z)
	public static isLevel4(pass: string) {
		return /[A-Z]/.test(pass);
	}

	// password length is 10 or more than 10 characters
	public static isLevel5(pass: string) {
		return pass.length > 9;
	}

	// password length is 14 or more than 14 characters
	public static isLevel6(pass: string) {
		return pass.length > 13;
	}

	public static getPasswordSecurityLevel = (value: string) => {
		if (this.isLevel1(value) && this.isLevel2(value) && this.isLevel3(value) && this.isLevel4(value)) {
			if (this.isLevel6(value)) {
				return PassSecurityLevel.Strong;
			} else if (this.isLevel5(value)) {
				return PassSecurityLevel.Good;
			}
			return PassSecurityLevel.Weak;
		} else {
			return PassSecurityLevel.RequirementsNotMet;
		}
	};
}

import * as React from "react";
import {inject} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../../data/state/AppState";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import type {ManageColumnCallback} from "../../../widgets/table/TableHeaderDropDown";
import type {IViewColumn, IViewSort} from "../../../../data/models/ViewUtils";
import {getSortForField, getSortIndex} from "../../../../data/models/ViewUtils";
import type {XyiconFeature} from "../../../../generated/api/base";
import type {View} from "../../../../data/models/View";
import type {Pointer} from "../../../../utils/interaction/Pointer";
import {minHeaderWidth} from "../../../widgets/table/TableConstants";
import {ReactUtils} from "../../../utils/ReactUtils";
import {CheckboxInputV5} from "../../details/datatypes/CheckboxInputV5";
import {colorPalette} from "../../styles/colorPalette";
import {TableHeaderV5} from "./TableHeaderV5";

interface ITableHeadersProps {
	headers: Partial<IViewColumn>[];
	checkboxColumn: boolean;
	allSelected: boolean;
	onAllClicked: () => void;
	onToggleSort: (column: IFieldPointer) => void;
	onAutoResizeColumn: (index: number, header: Partial<IViewColumn>) => void;
	onReorderColumn?: (index: number, newIndex: number) => void;
	onHeaderWidthChange: (index: number, width: number, header: Partial<IViewColumn>) => void;
	headerRef: React.RefObject<HTMLDivElement>;
	tableRowIcon?: boolean;
	feature: XyiconFeature;
	view: View;
	onManageColumns?: ManageColumnCallback;
	onSortAsc?: (id: IFieldPointer) => void;
	onSortDesc?: (id: IFieldPointer) => void;
	onClearSort?: (id: IFieldPointer) => void;
	sorts: IViewSort[];
	appState?: AppState;
}

interface ITableHeadersState {
	dragSourceIndex: number;
	dragTarget: number;
}

@inject("appState")
export class TableHeadersV5 extends React.Component<ITableHeadersProps, ITableHeadersState> {
	private _columnDrags: number[] = [];

	constructor(props: ITableHeadersProps) {
		super(props);

		this.state = {
			dragSourceIndex: -1,
			dragTarget: -1,
		};
	}

	private onDragStart = (index: number, event: React.DragEvent) => {
		if (this.props.onReorderColumn) {
			this.setState({dragSourceIndex: index});
		}
	};

	private onDragEnd = (index: number, event: React.DragEvent) => {
		if (this.props.onReorderColumn) {
			const {dragSourceIndex, dragTarget} = this.state;

			this.setState({
				dragSourceIndex: -1,
				dragTarget: -1,
			});

			let newIndex = dragTarget;

			if (dragTarget < dragSourceIndex) {
				newIndex = dragTarget + 1;
			}

			this.props.onReorderColumn(dragSourceIndex, newIndex);
		}
	};

	private onDragEnter = (index: number, event: React.DragEvent) => {
		if (this.props.onReorderColumn) {
			this.setState({dragTarget: index});
		}
	};

	private onDragOver = (index: number, event: React.DragEvent) => {
		if (this.props.onReorderColumn) {
			event.preventDefault();
			this.setState({dragTarget: index});
		}
	};

	private onDragLeave = (index: number, event: React.DragEvent) => {
		if (this.props.onReorderColumn) {
			this.setState({dragTarget: -1});
		}
	};

	private onColResizeStart = (index: number, pointer: Pointer) => {
		this._columnDrags[index] = this.props.headers[index].width || minHeaderWidth;
	};

	private onColResizeMove = (index: number, pointer: Pointer, header: Partial<IViewColumn>) => {
		const width = Math.max(minHeaderWidth, this._columnDrags[index] + pointer.offsetX);

		this.props.onHeaderWidthChange(index, width, header);
	};

	public override render() {
		const {
			headers,
			checkboxColumn,
			allSelected,
			onAllClicked,
			tableRowIcon,
			feature,
			appState,
			view,
			onSortAsc,
			onSortDesc,
			onClearSort,
			sorts,
			headerRef,
			onToggleSort,
			onAutoResizeColumn,
			onManageColumns,
		} = this.props;
		const {dragSourceIndex, dragTarget} = this.state;
		const multipleSorts = appState.actions.getSortsForFeature(view, feature).length > 1;

		return (
			<TableHeadersStyled
				ref={headerRef}
				className={ReactUtils.cls("head", {tableRowIcon})}
			>
				{checkboxColumn && (
					<TableHeadersCheckBoxColumnStyled
						className="th"
						onClick={onAllClicked}
					>
						<CheckboxInputV5
							value={allSelected}
							disabled={true}
						/>
						<div className="resizer checkbox" />
					</TableHeadersCheckBoxColumnStyled>
				)}
				{headers.map((header, index) => {
					const sortIndex = getSortIndex(view, header.field, appState, feature);
					const sort = getSortForField(sorts, header.field) || sorts[0];

					return (
						<TableHeaderV5
							key={header.field}
							header={header}
							index={index}
							sortIndex={sortIndex}
							sort={sort}
							multipleSorts={multipleSorts}
							dragSource={index === dragSourceIndex}
							dragTarget={index === dragTarget && index !== dragSourceIndex}
							onDragStart={this.onDragStart}
							onDragEnd={this.onDragEnd}
							onDragEnter={this.onDragEnter}
							onDragOver={this.onDragOver}
							onDragLeave={this.onDragLeave}
							onColResizeStart={this.onColResizeStart}
							onColResizeMove={this.onColResizeMove}
							onToggleSort={onToggleSort}
							onAutoResizeColumn={onAutoResizeColumn}
							onManageColumns={onManageColumns}
							allowHide={headers.length > 1}
							feature={feature}
							onSortAsc={onSortAsc}
							onSortDesc={onSortDesc}
							onClearSort={onClearSort}
						/>
					);
				})}
			</TableHeadersStyled>
		);
	}
}

const TableHeadersStyled = styled.div`
	padding-left: 8px;
	display: flex;
	user-select: none;
	text-align: justify;
	background: ${colorPalette.gray.c100};
	height: 40px;
	align-items: center;
	color: ${colorPalette.gray.c700Dark};
	font-size: 14px;
	line-height: 16px;
	border-bottom: 1px solid ${colorPalette.gray.c500Primary};

	.CheckboxInput {
		position: relative;
		right: 2px;
	}
`;

const TableHeadersCheckBoxColumnStyled = styled.div`
	/* min-width: 40px; */
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import type {AppState} from "../../../../../../data/state/AppState";

interface ColorRuleRowProps {
	label: string;
	options: string[];
	onChangeIndicator: (fieldName: string) => void;
	selectedIndicator: string;
	onChangeHighlight: (fieldName: string) => void;
	selectedHighlight: string;
	render?: (item: any) => React.ReactNode;
	appState?: AppState;
}

@inject("appState")
@observer
export class ColorRuleRow extends React.Component<ColorRuleRowProps> {
	public override render() {
		const {render, label, options, onChangeIndicator, onChangeHighlight, selectedIndicator, selectedHighlight, appState} = this.props;

		const indicatorProps = {sort: false, options, render, onChange: onChangeIndicator, selected: selectedIndicator};
		const highlightProps = {sort: false, options, render, onChange: onChangeHighlight, selected: selectedHighlight};

		return (
			<div className="ColorRuleRow hbox">
				<div className="label">{label}</div>
				<SelectInput {...indicatorProps} />
				<SelectInput {...highlightProps} />
			</div>
		);
	}
}

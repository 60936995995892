import * as React from "react";
import {inject, observer} from "mobx-react";
import {OptionsButton} from "../button/options/OptionsButton";
import {SVGIcon} from "../button/SVGIcon";
import type {IContextOption} from "../context/ContextOptions";
import {TextInput} from "../input/text/TextInput";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import {doesStructureContainElement} from "../../../data/models/ViewUtils";
import {TimeUtils} from "../../../utils/TimeUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {ConfirmWindow} from "../../modules/abstract/popups/ConfirmWindow";
import type {AppState} from "../../../data/state/AppState";
import {StringUtils} from "../../../utils/data/string/StringUtils";

interface IViewFolderProps {
	appState?: AppState;
	data: IViewFolder;
	searchString: string;
	startFocusListen: () => void;
	stopFocusListen: () => void;
	saveViewFolderStructureToDatabase: () => void;
}

interface IViewFolderState {
	isInEditMode: boolean;
}

@inject("appState")
@observer
export class ViewFolder extends React.Component<IViewFolderProps, IViewFolderState> {
	constructor(props: IViewFolderProps) {
		super(props);

		this.state = {
			isInEditMode: false,
		};
	}

	private onToggleOpen = () => {
		if (!this.state.isInEditMode) {
			this.props.data.isOpen = !this.props.data.isOpen;

			return this.props.saveViewFolderStructureToDatabase();
		}
	};

	private onDeleteClick = async () => {
		this.props.stopFocusListen();
		const confirmed = await ConfirmWindow.open("Are you sure you want to delete the selected 1 item?");

		this.props.startFocusListen();

		if (confirmed) {
			const isDeletionSuccessful = this.props.appState.user?.removeElementFromViewFolderStructure(this.props.data.id);

			if (isDeletionSuccessful) {
				return this.props.saveViewFolderStructureToDatabase();
			}
		}
	};

	private onRenameClick = async () => {
		await this.workaroundForFocusLoss();
		if (!this.state.isInEditMode) {
			this.setState({
				isInEditMode: true,
			});
		}
	};

	private onRenameApply = (value: string) => {
		if (value !== this.props.data.name) {
			this.props.data.name = value;
			this.props.saveViewFolderStructureToDatabase();
		}

		this.onRenameBlur();
	};

	private onRenameBlur = () => {
		if (this.state.isInEditMode) {
			this.setState({
				isInEditMode: false,
			});
		}
	};

	private async workaroundForFocusLoss() {
		this.props.stopFocusListen();
		await TimeUtils.waitForNextFrame();
		this.props.startFocusListen();
	}

	private getOptions = () => {
		const options: IContextOption[] = [];

		options.push({
			label: "Rename",
			onSelect: this.onRenameClick,
		});

		if (this.props.data.children.length === 0) {
			options.push({
				label: "Delete",
				onSelect: this.onDeleteClick,
			});
		}

		return options;
	};

	public override render() {
		const isOpen =
			this.props.data.isOpen ||
			(this.props.searchString && doesStructureContainElement(this.props.data.children, this.props.searchString, this.props.appState.actions));
		const isFilteredOut = this.props.searchString ? !StringUtils.containsIgnoreCase(this.props.data.name, this.props.searchString) : false;

		return (
			<div
				className={ReactUtils.cls("ViewFolder header hbox alignCenter flex_1", {hidden: isFilteredOut})}
				onClick={this.onToggleOpen}
			>
				<SVGIcon
					icon="drag"
					classNames="drag"
				/>
				<div className={ReactUtils.cls("arrow", {isOpen})} />
				<SVGIcon icon={`folder${isOpen ? "-open" : ""}`} />
				{this.state.isInEditMode ? (
					<TextInput
						className="name flex_1"
						value={this.props.data.name}
						onChange={this.onRenameApply}
						onBlur={this.onRenameBlur}
						autoFocus={true}
					/>
				) : (
					<div className="name flex_1">{this.props.data.name}</div>
				)}
				<OptionsButton options={this.getOptions()} />
			</div>
		);
	}
}

import styled from "styled-components";
import {colorPalette} from "../styles/colorPalette";
import {fontSize, fontWeight} from "../styles/styles";
import {SelectInputStyled} from "../input/select/SelectInputV5";

export const ViewSelectorStyled = styled.div`
	/* ${SelectInputStyled} {
		border: none;
		border-right: 1px solid #37474F;
		border-radius: 0;
		margin-right: 8px;
		color: ${colorPalette.gray.c950};
	
		.input {
			font-size: ${fontSize.md};
			font-weight: ${fontWeight.bold};
			line-height: 16px;
			letter-spacing: 0.015em;
			text-align: left;
		}
	} */
`;

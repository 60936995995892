import * as React from "react";
import {ReactUtils} from "../../../../../../utils/ReactUtils";
import {ColorUtils} from "../../../../../../../utils/ColorUtils";
import type {Color} from "../../../../../../../generated/api/base";

interface ColorPaletteProps {
	label: string;
	activeColor: Color;
	colors: Color[];
	onColorClick: (newColor: Color) => void;
	isTransparencyEnabled: boolean;
}

export class ColorPalette extends React.Component<ColorPaletteProps> {
	private onColorClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const div = event.currentTarget;
		const hex = div.getAttribute("data-hex");
		const transparency = parseFloat(div.getAttribute("data-transparency")) || 0;

		this.props.onColorClick({
			hex: hex,
			transparency: transparency,
		});
	};

	private getHTMLElements() {
		return this.props.colors.map((color: Color, index: number) => {
			const rgba = ColorUtils.hex2rgb(color.hex, 1 - color.transparency) as string;
			const style = {
				backgroundColor: rgba,
			};

			return (
				<div
					style={style}
					onClick={this.onColorClick}
					data-hex={color.hex} /** Needed to retrieve it with getAttribute in `onColorClick` */
					data-transparency={color.transparency}
					key={index}
					className={ReactUtils.cls({
						active: color.hex === this.props.activeColor.hex && color.transparency === this.props.activeColor.transparency,
						transparent: this.props.isTransparencyEnabled && color.transparency > 0,
					})}
				></div>
			);
		});
	}

	public override render() {
		return (
			<div
				className="ColorPalette"
				data-label={this.props.label}
			>
				{this.getHTMLElements()}
			</div>
		);
	}
}

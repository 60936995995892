import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {IPointerEventData} from "./IPointerEventData";

interface IPointerConfig {
	id?: number;
	localX?: number;
	localY?: number;
	pageX?: number;
	pageY?: number;
	time?: number;
	originalEvent?: MouseEvent | TouchEvent;
	pointerData?: IPointerEventData;
	currentTarget?: Element;
}

// TODO static method to create Pointer from Event
export class Pointer {
	public id: number;
	public startX: number;
	public startY: number;
	// TODO rename x, y, make it a class?
	public localX: number;
	public localY: number;
	public time: number;

	public pageX: number;
	public pageY: number;

	public originalEvent: MouseEvent | TouchEvent;
	public pointerData: IPointerEventData;
	public currentTarget: Element;

	public canceled: boolean = false;

	public offsetX: number = 0;
	public offsetY: number = 0;
	public dx: number = 0;
	public dy: number = 0;
	public userData: any;

	constructor(config?: IPointerConfig) {
		if (config) {
			this.id = config.id;
			this.startX = config.localX;
			this.startY = config.localY;
			this.localX = config.localX;
			this.localY = config.localY;
			this.pageX = config.pageX;
			this.pageY = config.pageY;
			this.time = config.time;
			this.originalEvent = config.originalEvent;
			this.pointerData = config.pointerData;
			this.currentTarget = config.currentTarget;
		}
	}

	// TODO rename to isNormalButton
	public get isNormalClick() {
		return this.button <= Constants.MOUSE_BUTTON.LEFT;
	}

	public get isRightClick() {
		return this.button === Constants.MOUSE_BUTTON.RIGHT;
	}

	public get isMiddleClick() {
		return this.button === Constants.MOUSE_BUTTON.MIDDLE;
	}

	public get button() {
		if (this.isMouse) {
			const mouseEvent = this.originalEvent as MouseEvent;

			if (mouseEvent.button === undefined) {
				// unsupported by browser
				return -1;
			} else {
				return mouseEvent.button;
			}
		} else {
			return -1;
		}
	}

	public get isMouse() {
		return this.id === 1;
	}

	public get isTouch() {
		return !this.isMouse;
	}
}

type IDistanceUnits = {
	[key in ExtendedDistanceUnitName]: IDistanceUnit;
};

export interface IDistanceUnit {
	name: ExtendedDistanceUnitName;
	plural: string;
	abbreviation: string;
	multiplicator: number; // value * multiplicator = meter
}

export interface ISizeRange {
	values: number[];
	default: number;
	min: number;
	max: number;
}

export type DistanceUnitName = "inch" | "foot" | "millimeter" | "centimeter" | "meter";
export type ExtendedDistanceUnitName = DistanceUnitName | "foot&inch";

export class Constants {
	public static readonly EPSILON = 0.00001;
	// radian / millisec. Multiply by deltaframe to get the actual radian you need to change per frame
	public static readonly AUTOROTATION_SPEED = 0.0002;

	public static get DURATIONS() {
		// ms
		return {
			DEFAULT_ANIMATION: 400,
			CAMERA_MOVEMENT: 1500,
		};
	}

	public static readonly DEFAULT_3D_COLOR = 0x00a3b5;

	public static readonly DEFAULT_SPACE_WIDTH = 100; // "model space units"
	public static readonly DEFAULT_SPACE_RESOLUTION = 2048 * 4; // width, in px

	public static readonly DEFAULT_VALUE_FOR_INCLUDED_POSITION_LOCK = false;
	public static readonly DEFAULT_VALUE_FOR_INCLUDED_HIDE = false;

	public static get COLORS() {
		return {
			PICTON_BLUE: "3495F0",
			PICTON_BLUE_HOVER: "2A80D1",
			SELECTION_BOX: 0x00b9d2,
		};
	}

	public static get MOUSE_BUTTON() {
		return {
			LEFT: 0,
			MIDDLE: 1,
			RIGHT: 2,
		};
	}

	public static get iOS() {
		if (typeof navigator === "undefined") {
			return false;
		}

		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
	}

	public static get URLForStandardXyiconGLB() {
		return "src/assets/models/standardXyicon.glb";
	}

	public static get isFirefox() {
		if (typeof navigator === "undefined") {
			return false;
		}

		return /firefox/.test(navigator.userAgent.toLowerCase());
	}

	public static get SIZE() {
		return {
			FONT: {
				values: [8, 9, 10, 11, 12, 14, 16, 20, 24, 30, 36, 48, 60, 72, 96, 150, 200, 250, 300, 400],
				default: 20,
				min: 8,
				max: 2500,
			},
			COUNTER_HEIGHT: 32, // px on the canvas when you're fully zoomed in
			XYICON: 81, // px on the canvas when you're fully zoomed in
			NAVIGATION_BG: {
				WIDTH: 300,
				HEIGHT: 225,
			},
			GRABBABLE_ITEM_PX: 32,
			ROTATION_HANDLER_PX: 32,
		};
	}

	public static readonly COUNTER_BG_COLOR = "37474F";
	public static readonly COUNTER_BG_COLORV5 = "363636";
	public static readonly COUNTER_TEXT_COLOR = "FFFFFF";
	public static readonly COUNTER_BORDER_COLOR = "FFFFFF";

	public static get RESOLUTION() {
		return {
			XYICON: 128, // we save them at this resolution, although we can load them at lower resolution within the space editor. See "XyiconTextureAtlas" for the loading resolution
			LOGO: 512, // maximum resolution for organization logo
			TILE: 2048,
		};
	}

	public static readonly TO_FIXED: number = 2;

	public static readonly DEFAULT_UNIT: DistanceUnitName = "meter";

	public static get DISTANCE_UNITS(): IDistanceUnits {
		return {
			millimeter: {
				name: "millimeter",
				plural: "millimeters",
				abbreviation: "mm",
				multiplicator: 0.001,
			},
			centimeter: {
				name: "centimeter",
				plural: "centimeters",
				abbreviation: "cm",
				multiplicator: 0.01,
			},
			meter: {
				name: "meter",
				plural: "meters",
				abbreviation: "m",
				multiplicator: 1,
			},
			inch: {
				name: "inch",
				plural: "inches",
				abbreviation: "in",
				multiplicator: 0.0254,
			},
			foot: {
				name: "foot",
				plural: "feet",
				abbreviation: "ft",
				multiplicator: 0.3048,
			},
			"foot&inch": {
				name: "foot&inch",
				plural: "Feet and Inches",
				abbreviation: "ft",
				multiplicator: 0.3048, // need to manually add the inch multiplicator, where needed...
			},
		};
	}

	public static readonly SVG_UTF8_PREFIX = "data:image/svg+xml;utf8,";
	public static readonly SVG_BASE64_PREFIX = "data:image/svg+xml;base64,";

	public static readonly SVG_NAMESPACE = "http://www.w3.org/2000/svg";
	public static readonly CATALOG_SVG_VIEWBOXSIZE = 74;
	public static readonly ANGLE_SNAP_THRESHOLD = 0.130899694; // 7.5 deg as rad
	public static readonly FOV = 60; // deg
	public static readonly CLICK_MOVEMENT_THRESHOLD = 3;

	constructor() {
		console.warn("This is supposed to be a singleton class. Please use it that way.");
	}
}

import styled from "styled-components";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";
import {IconButtonStyled} from "../../../interaction/IconButtonV5";
import {ColorSelectorStyled} from "../../../colors/ColorSelectorV5";
import {DropdownButtonStyled} from "../../../interaction/DropdownButtonV5";
import {SelectInputStyled} from "../../../input/select/SelectInputV5";

export const SpaceActionBarStyled = styled.div`
	position: absolute;
	z-index: 1;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
	background-color: ${colorPalette.gray.c950};
	border-radius: ${radius.md};
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	gap: 4px;

	${IconButtonStyled} {
		&.isActive {
			background-color: ${colorPalette.primary.c500Primary};
		}
	}

	${IconButtonStyled}, ${ColorSelectorStyled}, ${DropdownButtonStyled} {
		&:hover,
		&.isOpen {
			background-color: ${colorPalette.gray.c700Dark};
		}
	}

	${SelectInputStyled} {
		color: inherit;
	}
`;

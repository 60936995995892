import React, {useRef, useState} from "react";
import {Observer} from "mobx-react";
import styled from "styled-components";
import {XyiconFeature} from "../../../../../generated/api/base";
import {Type} from "../../../../../data/models/Type";
import type {Type as PanelType} from "../../../../../data/models/Type";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {notify} from "../../../../../utils/Notify";
import {NotificationType} from "../../../../notification/Notification";
import {useAppStore} from "../../../../../StateManager";
import {ButtonV5} from "../../../button/ButtonV5";
import {TypeFormV5} from "./form/TypeFormV5";

interface ICreateTypePanelProps {
	moduleFeature: XyiconFeature;
	feature: XyiconFeature;
	onCancel: () => void;
	onCreated: (id: string) => void;
}

export const CreateTypePanelV5 = (props: ICreateTypePanelProps) => {
	const {feature, moduleFeature, onCreated, onCancel} = props;
	const appState = useAppStore((state) => state.appState);
	const {
		app: {transport, navigation},
	} = appState;

	const panelTypeRef = useRef<PanelType>(new Type({feature}, appState));
	const [createClicked, setCreateClicked] = useState(false);
	const [isValidInput, setIsValidInput] = useState(false);

	const onCreateClicked = async () => {
		setCreateClicked(true);
		const {type, error} = await transport.services.typefield.createType(panelTypeRef.current);

		if (type) {
			resetCreateType();
			onCreated(type.id);
		}

		if ([XyiconFeature.Space, XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(moduleFeature)) {
			if (appState.lists[XyiconFeature.PermissionSet].array.length > 0) {
				const moduleName = featureTitles[moduleFeature];

				notify(appState.app.notificationContainer, {
					title: `Enable new ${moduleName} type in permission sets!`,
					type: NotificationType.Message,
					lifeTime: Infinity,
					description: `By default, new types are disabled (hidden) in all permission sets. Click Change Permissions to enable the new ${moduleName} type for your existing permission sets.`,
					buttonLabel: "Change Permissions",
					onActionButtonClick: () => {
						navigation.go("app/settings/permissionsets/portfolios");
					},
				});
			}
		}
	};
	const onNameInput = (value: string) => {
		panelTypeRef.current.name = value;
		panelTypeRef.current.name = value;
		setIsValidInput(!!panelTypeRef.current.name && appState.actions.isTypeNameValid(panelTypeRef.current.name, panelTypeRef.current));
	};

	const resetCreateType = () => {
		panelTypeRef.current.name = "";
		setCreateClicked(false);
	};

	return (
		<Observer>
			{() => {
				return (
					<CreateTypePanelContainerStyled>
						<TypeFormV5
							type={panelTypeRef.current}
							createPanelNameInput={onNameInput}
							isCreatingType={true}
						/>
						<ButtonV5
							label="Confirm"
							onClick={onCreateClicked}
							disabled={!isValidInput || createClicked}
							className="button"
						/>
					</CreateTypePanelContainerStyled>
				);
			}}
		</Observer>
	);
};

const CreateTypePanelContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;

	.button {
		width: 70px;
		margin-left: auto;
	}
`;

import * as React from "react";
import {TextInput} from "../text/TextInput";
import {IconButton} from "../../button/IconButton";

interface IPasswordInputProps {
	value?: string;
	onInput: (val: string) => void;
	infoText?: string;
	placeholder?: string;
	isTooltip?: boolean;
	isTextInfo?: boolean;
	divRef?: React.RefObject<HTMLDivElement>;
}

interface IPasswordInputState {
	hidePassword: boolean;
}

export class PasswordInput extends React.Component<IPasswordInputProps, IPasswordInputState> {
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	constructor(props: IPasswordInputProps) {
		super(props);

		this.state = {
			hidePassword: true,
		};
	}

	private toggleHiding = () => {
		this.setState((prevState) => ({hidePassword: !prevState.hidePassword}));
	};

	public override render() {
		const {value, placeholder, infoText, isTooltip, isTextInfo, divRef} = this.props;
		const {hidePassword} = this.state;

		return (
			<div className="PasswordInput hbox alignCenter">
				{isTooltip ? (
					<TextInput
						value={value}
						onInput={(val) => this.props.onInput(val)}
						inputType={hidePassword ? "password" : "text"}
						placeholder={placeholder}
						errorMessage={infoText}
						isTooltip={isTooltip}
						isTextInfo={isTextInfo}
					/>
				) : (
					<TextInput
						value={value}
						onInput={(val) => this.props.onInput(val)}
						inputType={hidePassword ? "password" : "text"}
						placeholder={placeholder}
						errorMessage={infoText}
						isTooltip={isTooltip}
						isTextInfo={isTextInfo}
						divRef={(divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
					/>
				)}
				<IconButton
					icon={hidePassword ? "hide-active" : "hide"}
					onClick={this.toggleHiding}
				/>
			</div>
		);
	}
}

import {Observer} from "mobx-react";
import {useCallback, useEffect, useRef, useState} from "react";
import type {AppState} from "../../../data/state/AppState";
import {User} from "../../../data/models/User";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Permission} from "../../../generated/api/base";
import {XyiconFeature} from "../../../generated/api/base";
import {UserGroup} from "../../../data/models/UserGroup";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {InitialsV5} from "../widgets/InitialsV5";
import {
	AddUserOrUserGroupSelectorStyled,
	AddUserOrUserGroupStyled,
	NameContainerStyled,
	RowStyled,
	SectionTitle,
	SharingPanelStyled,
	UserAndUserGroupContainer,
	UserContainer,
	UserGroupContainer,
} from "../sharing/SharingPanel.styled";
import {useAppStore} from "../../../StateManager";
import {useClickOutside} from "../utils";
import {ButtonV5} from "../button/ButtonV5";
import {SelectSearchFieldV5} from "../input/search/SelectSearchFieldV5";
interface IAddUserOrUserGroupProps {
	appState?: AppState;
	exceptions?: string[];
	itemLabel: "view" | "report" | "view folder";
	itemName: string;
	usersOnly?: boolean;
	listMaxHeight?: number;
	onAdd: (userId: string, permission: Permission.View | Permission.Update, feature: XyiconFeature) => void;
	getSelectedItems: (items: any[]) => void;
	onDeleteUser?: (user: User) => void;
	onDeleteUserGroup?: (userGroup: UserGroup) => void;
	getPermissionType?: (permission: Permission.View | Permission.Update) => void;
	ownedByUserId: string;
	selectedItemList?: any[];
}

export const AddUserOrUserGroupV5 = (props: IAddUserOrUserGroupProps) => {
	const appState = useAppStore((state) => state.appState);
	const {onAdd, itemName, itemLabel, exceptions, usersOnly, ownedByUserId, selectedItemList, getSelectedItems} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const [selectedItems, setSelectedItems] = useState(selectedItemList);
	const ref = useRef<HTMLDivElement>();

	const onToggleIsOpen = () => {
		setIsOpen((o) => !o);
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		setSearchString("");
	}, []);

	useClickOutside([ref], onClose);

	const onItemSelect = (id: string, itemName: string, feature: XyiconFeature) => {
		const isItemSelected = selectedItems.some((item) => item.id === id);

		if (!isItemSelected) {
			setSelectedItems((prevItems) => [...prevItems, {id, itemName, feature}]);
		}
	};

	const getPermission = (value: Permission.View | Permission.Update) => {
		props.getPermissionType(value);
	};

	const getSelectedItemsList = (items: []) => {
		setSelectedItems(items);
	};

	useEffect(() => {
		setSelectedItems(selectedItemList);
	}, [selectedItemList]);

	useEffect(() => {
		getSelectedItems(selectedItems);
	}, [selectedItems, getSelectedItems]);

	return (
		<Observer>
			{() => {
				const exceptionsWithCurrentUser = [...exceptions, appState.user?.id];

				let users = appState.actions.getList<User>(XyiconFeature.User);

				if (searchString) {
					users = users.filter((user) => User.search(user, searchString));
				}

				let sharedUsers: User[] = [];

				if (exceptionsWithCurrentUser?.length) {
					sharedUsers = users.filter((user) => exceptionsWithCurrentUser.includes(user.id));
				}

				let userGroups = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);

				if (searchString) {
					userGroups = userGroups.filter((userGroup) => UserGroup.search(userGroup, searchString));
				}

				let sharedUserGroups: UserGroup[] = [];

				if (exceptions?.length) {
					sharedUserGroups = userGroups.filter((userGroup) => exceptions.includes(userGroup.id));
				}

				return (
					<AddUserOrUserGroupStyled
						ref={ref}
						className={ReactUtils.cls("AddUserOrUserGroup", {isOpen})}
					>
						<SelectSearchFieldV5
							className="hbox flex_1"
							value={searchString}
							onInput={setSearchString}
							onClick={() => onToggleIsOpen()}
							selectedItems={selectedItems}
							getItems={getSelectedItemsList}
							placeholder={!isOpen && selectedItems.length == 0 ? "Enter user/group, comma separated" : " "}
							getPermissionType={getPermission}
						/>
						{isOpen && (
							<AddUserOrUserGroupSelectorStyled
								className="AddUserOrUserGroup__selector vbox"
								ref={ref}
								$isDropdown={true}
							>
								<SharingPanelStyled $isDropdown={true}>
									<UserAndUserGroupContainer $isDropdown={true}>
										{!usersOnly && userGroups.length !== 0 && (
											<UserGroupContainer $isDropdown={true}>
												<SectionTitle>User Groups</SectionTitle>
												{userGroups
													.toSorted((a: UserGroup, b: UserGroup) => StringUtils.sortIgnoreCase(a.name, b.name))
													.map((userGroup) => (
														<RowStyled
															key={userGroup.id}
															onClick={() => {
																if (!sharedUserGroups.some((sharedUserGroup) => sharedUserGroup.id === userGroup.id)) {
																	onItemSelect(userGroup.id, userGroup.name, XyiconFeature.UserGroup);
																}
															}}
															className={ReactUtils.cls({selected: selectedItems.some((item) => item.id === userGroup.id)})}
														>
															<div className="avatar">
																<InitialsV5
																	name={userGroup.name}
																	className="usergroup"
																/>
															</div>
															<NameContainerStyled>
																<div className="name">{userGroup.name}</div>
																<div className="counter">{userGroup.renderMemberCount()}</div>
															</NameContainerStyled>
															{sharedUserGroups.some((sharedUserGroup) => sharedUserGroup.id === userGroup.id) && (
																<ButtonV5
																	label="Unshare"
																	title="Unshare"
																	className="unshare"
																	onClick={(e) => props.onDeleteUserGroup(userGroup)}
																/>
															)}
														</RowStyled>
													))}
											</UserGroupContainer>
										)}
										{users.length !== 0 && (
											<UserContainer
												className="section"
												$isDropdown={true}
											>
												<SectionTitle>Users</SectionTitle>
												{users
													.toSorted((a: User, b: User) => StringUtils.sortIgnoreCase(a.fullName, b.fullName))
													.map((user: User) => (
														<RowStyled
															key={user.id}
															className={ReactUtils.cls({
																unregistered: user.status === "invited",
																selected: selectedItems.some((item) => item.id === user.id),
															})}
															onClick={() => {
																if (!sharedUsers.some((sharedUsers) => sharedUsers.id === user.id)) {
																	onItemSelect(user.id, user.firstName, XyiconFeature.User);
																}
															}}
														>
															<div className="avatar">
																{user.profileFileName ? (
																	<img
																		src={user.profileFileName}
																		alt={`${user.fullName} profile image`}
																	/>
																) : (
																	<InitialsV5
																		name={user.fullName || user.email}
																		className="users"
																	/>
																)}
															</div>
															<NameContainerStyled>
																<div className="name">{user.status === "invited" ? "Unregistered User" : user.fullName}</div>
																<div className="email">{user.email}</div>
															</NameContainerStyled>
															{user.id !== ownedByUserId && sharedUsers.some((sharedUserGroup) => sharedUserGroup.id === user.id) && (
																<ButtonV5
																	label="Unshare"
																	title="Unshare"
																	className="unshare"
																	onClick={(e) => props.onDeleteUser(user)}
																/>
															)}
														</RowStyled>
													))}
											</UserContainer>
										)}
										{userGroups.length === 0 && users.length === 0 && (
											<div className="empty">We couldn't find a match. To invite this user to Xyicon, please contact your Administrator.</div>
										)}
									</UserAndUserGroupContainer>
								</SharingPanelStyled>
							</AddUserOrUserGroupSelectorStyled>
						)}
					</AddUserOrUserGroupStyled>
				);
			}}
		</Observer>
	);
};

import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {TextInputV5} from "./TextInputV5";

export const IPInputV5 = (props: IInputPropsV5) => {
	const {value, onBlur, onInput, caretPosition} = props;

	return (
		<div className="hbox field-input-container">
			<TextInputV5
				value={value}
				onInput={onInput}
				caretPosition={caretPosition}
				autoFocus={true}
				onBlur={onBlur}
			/>
		</div>
	);
};

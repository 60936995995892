import * as React from "react";
import {observer, inject} from "mobx-react";
import type {IModel} from "../../../data/models/Model";
import {XyiconFeature} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {Boundary} from "../../../data/models/Boundary";
import type {IXyiconLinkObject} from "../../../data/state/AppActions";
import type {ICrossPortfolioLinkData} from "../../modules/space/spaceeditor/ui/actionbar/CrossPortfolioXyicon";
import {CrossPortfolioXyicon} from "../../modules/space/spaceeditor/ui/actionbar/CrossPortfolioXyicon";
import type {Xyicon} from "../../../data/models/Xyicon";
import {SpaceItemContainerV5} from "../spaceeditor/SpaceItemContainerV5";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";

interface IDetailsSectionProps {
	item: IModel;
	feature: XyiconFeature;
	appState?: AppState;
	saveStateToLocalStorage?: boolean;
}

@inject("appState")
@observer
export class LinksSectionV5 extends React.Component<IDetailsSectionProps> {
	private renderLinks(feature: XyiconFeature) {
		const {appState, item} = this.props;
		const {actions} = appState;

		const itemId = item.id;

		const boundaries: Boundary[] = [...actions.getLinksXyiconBoundary(itemId), ...actions.getLinksBoundaryBoundary(itemId, true)].map(
			(value) => value.object,
		);
		const xyiconsForBoundary: IXyiconLinkObject[] = actions.getLinksXyiconsForBoundary(itemId);
		const xyiconsForXyicon: IXyiconLinkObject[] = actions.getLinksXyiconXyicon(itemId);
		const crossPortfolioXyiconLinks = actions.getCrossPortfolioLinksXyiconXyicon(itemId);
		const xyicons: (Xyicon | ICrossPortfolioLinkData)[] = [...xyiconsForBoundary.map((obj) => obj.object)];
		const embeddedXyicons: Xyicon[] = [];
		const xyiconsLinkedViaPort: (Xyicon | ICrossPortfolioLinkData)[] = []; // xyicons that are connected to the selected xyicon via their ports, BUT not connected to the selected xyicon's port, but to the selected xyicon itself

		for (const obj of xyiconsForXyicon) {
			const {link, object} = obj;
			const isSelectedTypeFrom = link.fromObjectId === itemId;

			if (isSelectedTypeFrom) {
				if (link.isEmbedded) {
					// boundary/xyicon is connected to a xyicon
					embeddedXyicons.push(object);
				} else if (link.fromPortId) {
					// xyicon's port is connected to another xyicon, or another xyicon's port (we handle this case in the "ports" section)
				} else if (link.toPortId) {
					// xyicon is connected to a xyicon's port
					xyiconsLinkedViaPort.push(object);
				} // boundary/xyicon is connected to a xyicon
				else {
					xyicons.push(object);
				}
			} else {
				if (link.toPortId) {
				} else if (link.fromPortId) {
					// xyicon is connected to another xyicon's port
					xyiconsLinkedViaPort.push(object);
				} // xyicon is connected to a xyicon
				else {
					xyicons.push(object);
				}
			}
		}

		for (const link of crossPortfolioXyiconLinks) {
			if (!link.onePortId) {
				if (link.otherPortId) {
					xyiconsLinkedViaPort.push(link);
				} // if (!link.otherPortId)
				else {
					xyicons.push(link);
				}
			}
		}

		return this.renderLinkContainers(feature, boundaries, xyicons, embeddedXyicons, xyiconsLinkedViaPort);
	}

	private getLinkElements(items: (Boundary | Xyicon | ICrossPortfolioLinkData)[]) {
		return (
			<div className="linkElements">
				{items.map((item: Xyicon | Boundary | ICrossPortfolioLinkData) => {
					const spaceItem = item as Xyicon | Boundary;
					const crossPortfolioLinkData = item as ICrossPortfolioLinkData;

					if (!(item as Xyicon).ownFeature) {
						return (
							<CrossPortfolioXyicon
								key={crossPortfolioLinkData.link.id}
								transport={this.props.appState.app.transport}
								linkData={crossPortfolioLinkData}
								showBreakLinkAndDeleteButton={false}
							/>
						);
					} else {
						return (
							<SpaceItemContainerV5
								queryString=""
								key={spaceItem.id}
								item={spaceItem}
								showInfoButton={true}
								showDeleteButton={false}
							/>
						);
					}
				})}
			</div>
		);
	}

	private renderLinkContainers(
		feature: XyiconFeature,
		boundaries: Boundary[],
		xyicons: (Xyicon | ICrossPortfolioLinkData)[],
		embeddedXyicons: Xyicon[],
		xyiconsLinkedViaPort: (Xyicon | ICrossPortfolioLinkData)[],
	) {
		const arrayOfContainers = [
			<ToggleContainerV5
				key={0}
				title="Boundaries"
				saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				className="FieldSection"
			>
				{this.getLinkElements(boundaries)}
			</ToggleContainerV5>,
			<ToggleContainerV5
				key={1}
				title="Xyicons"
				saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				className="FieldSection"
			>
				{this.getLinkElements(xyicons)}
			</ToggleContainerV5>,
		];

		if (feature === XyiconFeature.Xyicon) {
			arrayOfContainers.push(
				<ToggleContainerV5
					key={2}
					title="Embedded Xyicons"
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
					className="FieldSection"
				>
					{this.getLinkElements(embeddedXyicons)}
				</ToggleContainerV5>,
				<ToggleContainerV5
					key={3}
					title="Ports"
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
					className="FieldSection"
				>
					{this.getLinkElements(xyiconsLinkedViaPort)}
				</ToggleContainerV5>,
			);
		}
		return arrayOfContainers;
	}

	public override render() {
		const {feature} = this.props;

		return (
			(feature === XyiconFeature.Xyicon || feature === XyiconFeature.Boundary) && (
				<ToggleContainerV5
					title="Links"
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				>
					{this.renderLinks(this.props.feature)}
				</ToggleContainerV5>
			)
		);
	}
}

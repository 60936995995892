import {Constants} from "../Constants";
import type {SpaceItemType} from "../managers/spaceitems/ItemManager";
import type {ITextPart} from "../managers/MSDF/TextUtils";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {Dimensions, PointDouble, SpaceFileInsertionInfo} from "../../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import type {IModel} from "../../../../../../data/models/Model";
import type {AppActions} from "../../../../../../data/state/AppActions";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {PolyLabel} from "../../../../../../utils/math/PolyLabel";
import type {IRect} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {VectorUtils} from "../../../../../../utils/VectorUtils";
import type {IMarkupMinimumData} from "../../../../../../data/models/Markup";
import type {Space} from "../../../../../../data/models/Space";
import type {IRealSize} from "../../../../settings/modules/type/form/RealSizeInput";
import type {SpaceViewRenderer} from "./SpaceViewRenderer";

export type ThemeType = "light" | "dark";

export interface ICaptionParent extends IRect {
	id: string;
	spaceItemType: "xyicon" | "boundary";
	geometryData?: PointDouble[];
}
export interface ICaption extends IRect {
	text: ITextPart[];
	parent: ICaptionParent;
}

export enum SpaceEditorMode {
	NORMAL,
	SET_SCALE,
	ALIGN,
}

export const isSpaceNamePresentInAnotherSpaceVersion = (actions: AppActions, spaceName: string) => {
	const spaces = actions.getList<Space>(XyiconFeature.Space);

	return spaces.some((space: Space) => space.name === spaceName);
};

export const calculateSpaceResolution = (
	spaceUnitsPerMeter: number,
	insertionInfo: SpaceFileInsertionInfo,
	xyiconSize: IRealSize,
	mode: SpaceEditorMode,
) => {
	const spaceWidth = insertionInfo.width;
	const spaceHeight = insertionInfo.height;
	const correctionMultiplier = calculateCorrectionMultiplier(spaceUnitsPerMeter, insertionInfo, xyiconSize, mode);
	const spaceResolutionWidth = spaceWidth / correctionMultiplier;

	return {
		spaceResolution: {
			x: spaceResolutionWidth,
			y: spaceResolutionWidth / (spaceWidth / spaceHeight),
		},
		correctionMultiplier,
	};
};

export const calculateCorrectionMultiplier = (
	spaceUnitsPerMeter: number,
	insertionInfo: SpaceFileInsertionInfo,
	xyiconSize: IRealSize,
	mode: SpaceEditorMode,
) => {
	let spaceResolutionWidth: number = 0;

	if (mode === SpaceEditorMode.NORMAL) {
		const xyiconSizeInMeters = xyiconSize.value * Constants.DISTANCE_UNITS[xyiconSize.unit].multiplicator;
		const spaceWidthInMeters = (insertionInfo.width || Constants.DEFAULT_SPACE_RESOLUTION) / spaceUnitsPerMeter;

		spaceResolutionWidth = Constants.SIZE.XYICON * (spaceWidthInMeters / xyiconSizeInMeters);
	} else {
		spaceResolutionWidth = Constants.DEFAULT_SPACE_RESOLUTION;
	}

	return insertionInfo.width / spaceResolutionWidth;
};

export const getCorrectionMultiplierForSpaceItem = (spaceViewRenderer: SpaceViewRenderer, markup?: IMarkupMinimumData): number => {
	const modelDataSpaceId = markup?.spaceId;
	const modelDataSpace = spaceViewRenderer.actions.getSpaceById(modelDataSpaceId);
	const correctionMultiplier =
		modelDataSpace && modelDataSpaceId !== spaceViewRenderer.space?.id
			? calculateCorrectionMultiplier(
					modelDataSpace.spaceUnitsPerMeter,
					modelDataSpace.selectedSpaceFile.insertionInfo,
					modelDataSpace.type.settings.xyiconSize,
					SpaceEditorMode.NORMAL,
				)
			: spaceViewRenderer.correctionMultiplier.current;

	return correctionMultiplier;
};

export const getActiveCaptionFields = (feature: XyiconFeature, actions: AppActions) => {
	const captionFields: IFieldAdapter[] = [];

	const captionSettings = actions.getSelectedView(XyiconFeature.SpaceEditor).spaceEditorViewSettings.captions[
		feature === XyiconFeature.Xyicon ? "xyicon" : "boundary"
	];

	for (const checkedFieldRefId of captionSettings.checkList) {
		const field = actions.getFieldByRefId(checkedFieldRefId);

		if (field) {
			captionFields.push(field);
		}
	}

	return captionFields;
};

export const getFormattingColor = (item: IModel, fieldName: string, actions: AppActions) => {
	const modelData = item.ownFeature === XyiconFeature.Boundary ? (item as BoundarySpaceMap).parent : item;
	const field = actions.getFieldByName(item.ownFeature, fieldName);

	return actions.getFormattingColor(modelData, field?.refId);
};

export const getEmbeddedCounterPosition = (scale: Dimensions, correctionMultiplier: number, zOffset: number): Dimensions => {
	const offset = zOffset * correctionMultiplier;

	return {
		x: (scale.x - 32 * correctionMultiplier) / 2,
		y: scale.y / 2,
		z: scale.z + offset,
	};
};

export const getEmbeddedCounterScale = (xyiconMeshScale: number, correctionMultiplier: number) => {
	return (Constants.SIZE.COUNTER_HEIGHT * correctionMultiplier) / Math.abs(xyiconMeshScale);
};

export const getLinkIconPosition = (scale: Dimensions, correctionMultiplier: number, zOffset: number): Dimensions => {
	const offset = zOffset * correctionMultiplier;

	return {
		x: (scale.x - 32 * correctionMultiplier) / 2,
		y: (-scale.y + 29 * correctionMultiplier) / 2,
		z: scale.z + offset,
	};
};

export const getXyiconIndicatorPosition = (scale: Dimensions, correctionMultiplier: number): Dimensions => {
	const offset = 0.1 * correctionMultiplier;

	return {
		x: (-scale.x + 32 * correctionMultiplier) / 2,
		y: scale.y / 2,
		z: scale.z + offset,
	};
};

/**
 *
 * @param xyiconMeshScale usually 1, or -1 (if xyicon.isFlippedX is true). If the xyicon is temporary increased in size (eg.: another xyicon is hovered over), it can be larger as well
 */
export const getXyiconIndicatorScale = (xyiconMeshScale: number, correctionMultiplier: number) => {
	return (24 * correctionMultiplier) / Math.abs(xyiconMeshScale);
};

export const getXyiconHighlightScale = (scale: PointDouble) => {
	return Math.sqrt(scale.x * scale.x + scale.y * scale.y);
};

export const getBoundaryIndicatorScale = (correctionMultiplier: number) => {
	return 250 * correctionMultiplier;
};

// If the distance between the xyicon and its caption is larger than this, we show the leaderline
export const getCaptionLeaderLineVisibilityThreshold = (correctionMultiplier: number) => {
	return 70 * correctionMultiplier;
};

export const getDefaultPositionOfXyiconCaption = (
	xyiconPos: PointDouble,
	xyiconHeight: number,
	captionScale: PointDouble,
	correctionMultiplier: number,
): PointDouble => {
	return {
		x: xyiconPos.x,
		y: xyiconPos.y - (xyiconHeight + captionScale.y + 5 * correctionMultiplier) / 2,
	};
};

export const getDefaultPositionOfBoundaryCaption = (geometryData: PointDouble[]) => {
	return PolyLabel.getVisualCenter(geometryData);
};

export const setCaptionToDefaultPosition = (caption: ICaption, correctionMultiplier: number) => {
	const newDefaultPos =
		caption.parent.spaceItemType === "xyicon"
			? getDefaultPositionOfXyiconCaption(caption.parent.position, caption.parent.scale.y, caption.scale, correctionMultiplier)
			: getDefaultPositionOfBoundaryCaption(caption.parent.geometryData);

	caption.position.x = newDefaultPos.x;
	caption.position.y = newDefaultPos.y;
};

const _stepSize: number = 5; // px
const _directionsToCheck = [
	[0, 1],
	[1, 0],
	[-1, 0],
	[1, -1],
	[-1, -1],
	[1, 1],
	[-1, 1],
	[0, -1],
].map(VectorUtils.normalize);

/**
 * Returns the first object that rect1 has intersection with, or null if there's no intersection
 */
const detectCollisions = (rect1: IRect /* Caption */, arrayOfRects: IRect[]) => {
	for (const rect2 of arrayOfRects) {
		if (rect1 !== rect2) {
			if (THREEUtils.detectCollision(rect1, rect2)) {
				return rect2;
			}
		}
	}

	return null;
};

const solveCollisions = (caption: ICaption, arrayOfRects: IRect[], correctionMultiplier: number) => {
	let collisionRect = detectCollisions(caption, arrayOfRects);

	if (collisionRect) {
		let stepCount = 1;

		let isEmptySpotFound = false;
		let deltaX = 0;
		let deltaY = 0;

		const captionParentPosition = caption.parent.position;
		const parentScale = caption.parent.scale;

		// const parentBBox = caption.parent.spaceItemType === "xyicon" ? caption.parent.boundingBox : THREEUtils.calculateBox([captionParentPosition]);
		// const parentScale = THREEUtils.getSizeOfBoundingBox(parentBBox);

		const isXyicon = caption.parent.spaceItemType === "xyicon";

		while (!isEmptySpotFound) {
			for (const dir of _directionsToCheck) {
				if (isXyicon) {
					caption.position.x = captionParentPosition.x;
					caption.position.y = captionParentPosition.y;
				} else {
					setCaptionToDefaultPosition(caption, correctionMultiplier);
				}

				if (isXyicon && stepCount === 1 && (dir[0] === 0 || dir[1] === 0)) {
					deltaX = (dir[0] * (parentScale.x + caption.scale.x + 1 * correctionMultiplier)) / 2;
					deltaY = (dir[1] * (parentScale.y + caption.scale.y + 1 * correctionMultiplier)) / 2;
				} else {
					const stepSize =
						stepCount === 1
							? Math.sqrt((caption.scale.x + parentScale.x) ** 2 + (caption.scale.y + parentScale.y) ** 2) / 2 + 1 * correctionMultiplier
							: _stepSize;

					deltaX = dir[0] * stepCount * stepSize * correctionMultiplier;
					deltaY = dir[1] * stepCount * stepSize * correctionMultiplier;
				}

				caption.position.x += deltaX;
				caption.position.y += deltaY;

				collisionRect = detectCollisions(caption, arrayOfRects);

				if (collisionRect) {
					continue;
				} else {
					isEmptySpotFound = true;
					break;
				}
			}
			stepCount++;
		}
	}
};

export const getRadiusForCaptionCollision = (correctionMultiplier: number, captionFontSize: number) =>
	(5 * Constants.SIZE.XYICON * correctionMultiplier * captionFontSize) / Constants.SIZE.FONT.default;

export const updateCaption = (caption: ICaption, visibleXyicons: IRect[], visibleCaptions: ICaption[], correctionMultiplier: number) => {
	const rectangles = [...visibleXyicons, ...visibleCaptions];

	solveCollisions(caption, rectangles, correctionMultiplier);
};

export const getCaptionFontConfig = () => {
	return {
		opacity: 1,
		textHAlign: HorizontalAlignment.center,
		textVAlign: VerticalAlignment.center,
		isBold: true,
		isItalic: false,
		isUnderlined: false,
	};
};

const paddingTopBottomInPx: number = 10;

export const paddingLeftRightInPx: number = 15;

export const calculateCaptionSizeFromTextObject = (textObject: {size: PointDouble}, fontSize: number, correctionMultiplier: number) => {
	const ratio = fontSize / Constants.SIZE.FONT.default;
	const paddingLeftRight = paddingLeftRightInPx * correctionMultiplier * ratio;
	const paddingTopBottom = paddingTopBottomInPx * correctionMultiplier * ratio;

	return {
		x: Math.max(textObject.size.x + paddingLeftRight, 60 * correctionMultiplier * ratio),
		y: textObject.size.y + paddingTopBottom,
	};
};

export const getFeatureBySpaceItemType = (type: SpaceItemType) => {
	switch (type) {
		case "xyicon":
			return XyiconFeature.Xyicon;
		case "boundary":
			return XyiconFeature.Boundary;
		case "markup":
			return XyiconFeature.Markup;
	}
};

export enum MeasureType {
	NONE,
	DISTANCE,
	AREA,
}

export const MarkupSidePadding: number = 10; // px

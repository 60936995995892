import * as React from "react";
import {inject, observer} from "mobx-react";
import {action} from "mobx";
import type {AppState} from "../../../../../data/state/AppState";
import type {XyiconFeature} from "../../../../../generated/api/base";
import {Button} from "../../../../widgets/button/Button";
import {SelectInput} from "../../../../widgets/input/select/SelectInput";
import {LogicalSeparator} from "../../../../../data/models/filter/LogicalSeparator";
import {FieldDataTypes} from "../../../../../data/models/field/FieldDataTypes";
import {LogicalSeparators} from "../../../../../data/models/filter/LogicalSeparators";
import type {FilterOperator} from "../../../../../data/models/filter/operator/FilterOperator";
import type {FilterType, IFilterRow, IFilterRowFilter} from "../../../../../data/models/filter/Filter";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import type {IFieldAdapter, IFieldPointer} from "../../../../../data/models/field/Field";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import type {IModel} from "../../../../../data/models/Model";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {AdvancedFilterField} from "./AdvancedFilterField";

interface IAdvancedFilterEditorProps {
	autoUpdate?: boolean;
	features: XyiconFeature[];
	filters: IFilterRow[];
	localFilters?: IFilterRow[];
	items?: IModel[];
	operatorFilter?: (operator: FilterOperator) => boolean;
	fieldFilter?: (field: IFieldAdapter) => boolean;
	syncFilters?: (isLoaderNeeded?: boolean) => void;
	syncLocalFilters?: (filters: IFilterRow[]) => void;
	multiplePortfolios?: boolean;
	appState?: AppState;
	linkedFields?: boolean;
}

@inject("appState")
@observer
export class AdvancedFilterEditor extends React.Component<IAdvancedFilterEditorProps> {
	public readonly type: FilterType = "advanced";

	@action
	public onAddRow = () => {
		const rows = this.filters;
		const field = this.getFields()[0];

		if (rows.length > 0) {
			rows.push({
				type: "separator",
				value: LogicalSeparator.AND,
			});
		}

		const operatorFilter = this.props.operatorFilter;

		rows.push({
			type: "filter",
			value: {
				field: field.refId,
				operator: FieldDataTypes.map[field.dataType]
					.operators(field.dataTypeSettings)
					.sort(StringUtils.sortIgnoreCase) // SelectInput orders by sort
					.find((op) => (operatorFilter ? operatorFilter(op) : true)),
				param: undefined,
			},
		});
	};

	private getFields() {
		const {fieldFilter, features, multiplePortfolios, appState} = this.props;
		let fields = appState.actions.filterActions.getFieldsForFilters(features, undefined, multiplePortfolios) || [];

		if (fieldFilter) {
			fields = fields.filter(fieldFilter);
		}
		return fields;
	}

	private getFieldRefIds(): IFieldPointer[] {
		return this.getFields().map((f) => f.refId);
	}

	private onChangeField = (row: IFilterRowFilter, fieldPointer: IFieldPointer) => {
		const field = this.props.appState.actions.getFieldByRefId(fieldPointer);

		if (field) {
			row.value.field = fieldPointer;
			// Reset operator to the first available value for this dataType
			let operators = FieldDataTypes.map[field.dataType].operators(field.dataTypeSettings);

			if (this.props.operatorFilter) {
				operators = operators.filter(this.props.operatorFilter);
			}
			this.onChangeOperator(row, operators[0]);
		}
	};

	private onChangeOperator = (row: IFilterRowFilter, operator: FilterOperator) => {
		row.value.operator = operator;
		row.value.param = undefined;
	};

	private onChangeParams = (row: IFilterRowFilter, param: any) => {
		row.value.param = param;
	};

	private onRemoveField = (index: number) => {
		if (index >= 0) {
			this.filters.splice(index, 1);
		}

		// remove next separator if any
		const nextSeparator = this.filters[index];

		if (nextSeparator?.type === "separator") {
			this.filters.splice(index, 1);
		}

		// remove any last separators (normally this shouldn't run)
		while (this.filters[this.filters.length - 1]?.type === "separator") {
			this.filters.pop();
		}
	};

	public clearAll() {
		this.filters.length = 0;
		this.props.syncFilters?.();
	}

	public resetTo(filters: IFilterRow[]) {
		if (!ObjectUtils.compare(this.filters, filters)) {
			this.props.syncLocalFilters?.(filters);
		}

		if (!ObjectUtils.compare(this.props.filters, filters)) {
			ArrayUtils.copy(this.props.filters, filters);
		}
	}

	public applyAll() {
		ArrayUtils.replaceObservable(this.props.filters, ObjectUtils.deepClone(this.filters));
	}

	private get filters() {
		const {autoUpdate, localFilters, filters} = this.props;

		return autoUpdate ? filters : localFilters;
	}

	public override render() {
		const {features} = this.props;
		const fieldRefIds = this.getFieldRefIds();

		return (
			<div className="AdvancedFilterEditor">
				{this.filters.map((row, index) => {
					if (row.type === "filter") {
						return (
							<AdvancedFilterField
								key={index}
								fieldRefIds={fieldRefIds}
								filter={row.value}
								features={features}
								onChangeField={(field) => this.onChangeField(row, field)}
								onChangeOperator={(operator) => this.onChangeOperator(row, operator)}
								onChangeParams={(params) => this.onChangeParams(row, params)}
								onRemove={() => this.onRemoveField(index)}
								operatorFilter={this.props.operatorFilter}
								linkedFields={this.props.linkedFields}
							>
								<div className="separator">
									{this.filters[index - 1]?.type === "separator" && (
										<SelectInput
											key={index - 1}
											options={LogicalSeparators.array}
											render={(separator) => separator.label}
											selected={LogicalSeparators.array.find((separator) => separator.id === this.filters[index - 1].value)}
											onChange={(value) => {
												this.filters[index - 1].value = value.id;
												this.forceUpdate();
											}}
											fullWidth={true}
										/>
									)}
								</div>
							</AdvancedFilterField>
						);
					}
				})}
				<Button
					label="+"
					className="dashed"
					onClick={this.onAddRow}
				/>
			</div>
		);
	}
}

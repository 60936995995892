import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes} from "../FieldDataTypes";
import {FilterOperator} from "../../filter/operator/FilterOperator";
import {NumericFieldSettings} from "../../../../ui/modules/settings/modules/field/datatypes/NumericFieldSettings";
import {NumericLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/numeric/NumericLabel";
import {NumericInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/numeric/NumericInput";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {NumericInputV5} from "../../../../ui/5.0/details/datatypes/numeric/NumericInputV5";
import {NumericLabelV5} from "../../../../ui/5.0/details/datatypes/numeric/NumericLabelV5";
import {NumericFieldSettingsV5} from "../../../../ui/5.0/details/datatypes/numeric/NumericFieldSettingsV5";

const parseNumeric = (value: any) => {
	if (value || value === 0) {
		return Number.parseFloat(value);
	}
	return undefined;
};

const renderNumeric = (value: number, dataTypeSettings?: INumericFieldSettingsDefinition) => {
	let val: string | number = value ?? "";

	if (val !== "" && val?.toString) {
		const decimals = MathUtils.isValidNumber(dataTypeSettings?.decimals) ? dataTypeSettings.decimals : 0;

		if (typeof value !== "number") {
			val = Number(val);
		}

		if (dataTypeSettings?.formatting === "csv" || dataTypeSettings?.formatting === "currency") {
			const options: Intl.NumberFormatOptions = {
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			};

			if (dataTypeSettings.formatting === "currency") {
				options.style = "currency";
				options.currency = "USD";
			}

			val = (val as number).toLocaleString("en-US", options);
		} else if (dataTypeSettings?.formatting === "percentage") {
			val = `${((val as number) * 100).toFixed(decimals - 2)}%`;
		} else {
			val = (val as number).toFixed(decimals);
		}
	}

	return val as string;
};

const changeNumeric = (value: string, inputValue: string, selectedOptionValue?: string) => {
	if (selectedOptionValue === undefined) {
		return value !== inputValue;
	}
	return selectedOptionValue === value && value !== inputValue;
};

export function configureNumeric() {
	FieldDataTypes.configure({
		id: FieldDataType.Numeric,
		title: "Numeric",
		converter: parseNumeric,
		formatter: renderNumeric,
		changeChecker: changeNumeric,
		operatorsArray: [
			FilterOperator.IS_EQUAL_TO_NUM,
			FilterOperator.IS_NOT_EQUAL_TO_NUM,
			FilterOperator.IS_LESS_THAN_NUM,
			FilterOperator.IS_LESS_THAN_OR_EQUAL_TO_NUM,
			FilterOperator.IS_GREATER_THAN_NUM,
			FilterOperator.IS_GREATER_THAN_OR_EQUAL_TO_NUM,
			FilterOperator.IS_IN_BETWEEN_NUM,
			FilterOperator.IS_NOT_BETWEEN_NUM,
			FilterOperator.IS_BLANK,
			FilterOperator.IS_NOT_BLANK,
			FilterOperator.IS_ANY_OF,
			FilterOperator.IS_NOT_ANY_OF,
		],
		settings: NumericFieldSettings,
		settingsV5: NumericFieldSettingsV5,
		label: NumericLabel,
		labelV5: NumericLabelV5,
		input: NumericInput,
		inputV5: NumericInputV5,
		defaultSettings: {
			decimals: 2,
			formatting: "general",
		} as INumericFieldSettingsDefinition,
	});
}

export interface INumericFieldSettingsDefinition {
	decimals: number;
	formatting: "general" | "csv" | "currency" | "percentage";
}

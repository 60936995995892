import styled from "styled-components";

export const MassFieldInputStyled = styled.div`
	> .Field {
		.element {
			position: relative;

			.unfocused {
				.spinner {
					position: absolute;
					top: 11px;
					right: base.$xs;
					visibility: visible;
				}
			}
		}
	}

	&.open {
		> .Field {
			.element {
				.unfocused {
					border-color: var(--blue);
				}
			}
		}
	}

	.unfocused {
		cursor: pointer;
		align-items: center;
		word-break: break-all;
		border: solid 1px var(--input-border);
		min-height: base.$input-height;
		padding: base.$sm;
		width: 100%;
		@include mixins.ellipsis;
		position: relative;

		&.multiInput {
			padding: 0;
		}

		&:hover:not(.disabled) {
			border-color: var(--input-border);
		}

		p {
			margin: 0;
			line-height: 18px;
			white-space: break-spaces;
		}
	}
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import type {AppState} from "../../../../data/state/AppState";
import {Permission, XyiconFeature} from "../../../../generated/api/base";
import type {Collection} from "../../../../data/models/abstract/Collection";
import type {UserGroup} from "../../../../data/models/UserGroup";
import {ReactUtils} from "../../../utils/ReactUtils";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import DotsHorizontalIcon from "../../icons/dots-horizontal.svg?react";
import ListIcon from "../../icons/list.svg?react";
import FrameIcon from "../../icons/frame.svg?react";
import {Functions} from "../../../../utils/function/Functions";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import type {IDropdownOption} from "../../interaction/DropdownOptionsV5";
import {zIndex} from "../../styles/styles";
import type {View} from "../../../../data/models/View";
import type {IViewFolder} from "../../../../data/models/ViewUtils";
import {ViewElementStyled, getKeyForLocalStorageWorkspaceViewSections} from "./WorkspaceViewCommon";
import type {WorkspaceViewType} from "./WorkspaceViewCommon";

interface IViewItemProps {
	appState?: AppState;
	view: View;
	type: WorkspaceViewType;
	level?: number;
	onViewClick: (view: View) => void;
	onShareClick: (viewItem: View | IViewFolder) => void;
	forceUpdateParent: () => void;
}

interface IViewItemState {
	isOwnerToolTipOpen: boolean;
	isSharedToolTipOpen: boolean;
	sharedToolTipTransform: TransformObj | null;
	ownerToolTipTransform: TransformObj | null;
	isInEditMode: boolean;
	renameErrorMessage: string;
	isViewNameToolTipOpen: boolean;
	viewNameToolTipTransform: TransformObj;
}

@inject("appState")
@observer
export class ViewItemV5 extends React.Component<IViewItemProps, IViewItemState> {
	private _sharedNum = React.createRef<HTMLDivElement>();
	private _sharedToolTip = React.createRef<HTMLDivElement>();
	private _avatar = React.createRef<HTMLDivElement>();
	private _ownerToolTip = React.createRef<HTMLDivElement>();
	private _viewName = React.createRef<HTMLDivElement>();
	private _viewNameParent = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;

	constructor(props: IViewItemProps) {
		super(props);

		this.state = {
			isOwnerToolTipOpen: false,
			isSharedToolTipOpen: false,
			sharedToolTipTransform: null,
			ownerToolTipTransform: null,
			isInEditMode: false,
			renameErrorMessage: "",
			isViewNameToolTipOpen: false,
			viewNameToolTipTransform: null,
		};
	}

	private openOwnerToolTip = () => {
		this.setState({
			isOwnerToolTipOpen: true,
		});
	};

	private closeOwnerToolTip = () => {
		this.setState({
			isOwnerToolTipOpen: false,
		});
	};

	private openSharedToolTip = () => {
		this.setState({
			isSharedToolTipOpen: true,
		});
	};

	private closeSharedToolTip = () => {
		this.setState({
			isSharedToolTipOpen: false,
		});
	};

	private onClick = (e: React.MouseEvent) => {
		const {view} = this.props;

		// Without this, the navigation panel can open up when you click on a view/folder in a popup
		e.stopPropagation();

		this.props.onViewClick(view);
	};

	private onRenameClick = () => {
		// Workaround for focusloss/blur problem
		requestAnimationFrame(() => {
			if (!this.state.isInEditMode) {
				this.setState({
					isInEditMode: true,
				});
			}
		});
	};

	private getErrorMessage = (value: string) => {
		const view = this.props.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			return "";
		}
		return value === "" ? "Name cannot be empty!" : "Name needs to be unique!";
	};

	private onRenameInput = (value: string) => {
		const renameErrorMessage = this.getErrorMessage(value);

		if (renameErrorMessage && !this.state.renameErrorMessage) {
			this.setState({
				renameErrorMessage,
			});
		} else if (!renameErrorMessage && this.state.renameErrorMessage) {
			this.setState({
				renameErrorMessage: "",
			});
		}
	};

	private onRenameApply = async (value: string) => {
		const view = this.props.view;
		const isNameValid = this.props.appState.actions.isNameValidForView(value, view.itemFeature, view.id);

		if (isNameValid) {
			this.onRenameBlur();

			if (value !== view.name) {
				view.name = value;
				await this.props.appState.app.transport.services.view.update(view.getData());
			}
		}
	};

	private onRenameBlur = () => {
		if (this.state.isInEditMode) {
			this.setState({
				isInEditMode: false,
				renameErrorMessage: "",
			});
		}
	};

	private onDeleteClick = async () => {
		const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

		if (confirmed) {
			const isFavorite = this.props.view?.isFavorite;

			await this.props.appState.app.transport.services.view.delete(this.props.view.id);

			if (this.props.type === "Global") {
				const {organization} = this.props.appState;

				if (organization) {
					await organization.setGlobalViews(organization.globalViews);
				}
			}
			if (isFavorite) {
				const {user} = this.props.appState;

				if (user) {
					await user.setFavoriteViews(user.favoriteViews);
				}
			}
		}
	};

	private onDuplicateClick = async () => {
		const duplicate = await this.props.view.duplicate();

		const keyForLocalStorage = getKeyForLocalStorageWorkspaceViewSections(this.props.appState.user.id);

		let openViewSpaceTypes: WorkspaceViewType[] = JSON.parse(localStorage.getItem(keyForLocalStorage)) || null;

		if (!openViewSpaceTypes) {
			openViewSpaceTypes = ["Favorites", "Global", "My Views", "Shared With Me"];
			localStorage.setItem(keyForLocalStorage, JSON.stringify(openViewSpaceTypes));
		}

		const newOpenViewSpaceTypes = new Set([...openViewSpaceTypes]);

		if (this.props.type === "Global") {
			if (!newOpenViewSpaceTypes.has("My Views")) {
				newOpenViewSpaceTypes.add("My Views");
			}
			localStorage.setItem(keyForLocalStorage, JSON.stringify([...newOpenViewSpaceTypes]));
			this.props.forceUpdateParent();
		}

		return duplicate;
	};

	private onShareClick = () => {
		this.props.onShareClick(this.props.view);
	};

	private onMarkAsGlobalClick = () => {
		return this.props.appState.app.transport.services.view.markViewAsGlobal(this.props.view);
	};

	private getOptions = (): IDropdownOption[] => {
		const user = this.props.appState.user;
		const view = this.props.view;
		const type = this.props.type;

		const dropdownOptions: IDropdownOption[] = [
			{
				label: view?.isFavorite ? "Remove Favorite" : "Mark as Favorite",
				onClick: view?.isFavorite ? () => view.setFavorite(false) : () => view.setFavorite(true),
			},
		];

		switch (type) {
			case "My Views":
				dropdownOptions.push(
					{
						label: "Share",
						onClick: this.onShareClick,
					},
					{
						label: "Change Owner",
						onClick: () => console.log("onChangeOwnerClick"),
					},
					{
						label: "Rename",
						onClick: this.onRenameClick,
					},
					{
						label: "Duplicate",
						onClick: this.onDuplicateClick,
					},
				);

				if (user.isAdmin) {
					dropdownOptions.push({
						label: "Mark as Global View",
						onClick: this.onMarkAsGlobalClick,
					});
				}

				dropdownOptions.push({
					label: "Delete",
					onClick: this.onDeleteClick,
				});
				break;
			case "Shared With Me":
				if (view.getPermission(user.id) >= Permission.Update) {
					// edit access
					dropdownOptions.push(
						{
							label: "Share",
							onClick: this.onShareClick,
						},
						{
							label: "Rename",
							onClick: this.onRenameClick,
						},
						{
							label: "Duplicate",
							onClick: this.onDuplicateClick,
						},
					);

					if (user.isAdmin) {
						dropdownOptions.push({
							label: "Mark as Global View",
							onClick: this.onMarkAsGlobalClick,
						});
					}
				} else {
					// view access only
					dropdownOptions.push({
						label: "Duplicate",
						onClick: this.onDuplicateClick,
					});
				}

				break;
			case "Global":
				if (user.isAdmin) {
					dropdownOptions.push(
						{
							label: "Share",
							onClick: this.onShareClick,
						},
						{
							label: "Rename",
							onClick: this.onRenameClick,
						},
						{
							label: "Duplicate",
							onClick: this.onDuplicateClick,
						},
						{
							label: "Delete",
							onClick: this.onDeleteClick,
						},
					);
				} else {
					dropdownOptions.push({
						label: "Duplicate",
						onClick: this.onDuplicateClick,
					});
				}

				break;
		}

		return dropdownOptions;
	};

	private getViewSharingListLength = () => {
		const {view} = this.props;
		const settings = view?.viewSharingSettings;
		let length = settings?.filter((sharing) => sharing.userID !== view?.ownedBy).length ?? 0;

		if (length > 99) {
			return "99+";
		}

		return length;
	};

	private getToolTipContent = () => {
		const view = this.props.view;
		const settings = view.viewSharingSettings;
		const userGroups = this.props.appState.lists[XyiconFeature.UserGroup] as Collection<UserGroup>;
		const users = settings.filter((sharing) => sharing.userID && sharing.userID !== view.ownedBy);
		const groups = settings.filter((sharing) => sharing.userGroupID);

		// users who are not in any user group
		const individuals = users.filter(
			(user) => !groups.find((sharing) => (userGroups.getById(sharing.userGroupID) as UserGroup)?.userIds.includes(user.userID)),
		);

		let content = "";

		if (users.length > 0 && groups.length === 0) {
			content = `Shared with ${users.length} user${users.length > 1 ? "s" : ""}`;
		}
		if (users.length === 0 && groups.length > 0) {
			content = `Shared with ${groups.length} group${groups.length > 1 ? "s" : ""}`;
		}
		if (groups.length > 0 && individuals.length > 0) {
			content = `Shared with ${groups.length} group${groups.length > 1 ? "s" : ""} and ${individuals.length} user${individuals.length > 1 ? "s" : ""}`;
		}

		return content;
	};

	private onMouseOverViewName = (event: React.MouseEvent) => {
		if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
			if (this._timeOutId) {
				clearTimeout(this._timeOutId);
			}

			this._timeOutId = window.setTimeout(() => {
				this.setState({isViewNameToolTipOpen: true});
			}, 1000);
		}
	};

	private onMouseLeaveViewName = () => {
		clearTimeout(this._timeOutId);

		this.setState({isViewNameToolTipOpen: false});
	};

	public override componentDidUpdate(prevProps: IViewItemProps, prevState: IViewItemState) {
		if (!prevState.isSharedToolTipOpen && this.state.isSharedToolTipOpen && this._sharedNum.current && this._sharedToolTip.current) {
			this.setState({
				sharedToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._sharedNum.current,
					this._sharedToolTip.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					8,
					0,
				),
			});
		}

		if (!prevState.isOwnerToolTipOpen && this.state.isOwnerToolTipOpen && this._avatar.current && this._ownerToolTip.current) {
			this.setState({
				ownerToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._avatar.current,
					this._ownerToolTip.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					10,
					13,
				),
			});
		}

		if (!prevState.isViewNameToolTipOpen && this.state.isViewNameToolTipOpen && this._viewNameParent.current && this._viewName.current) {
			this.setState({
				viewNameToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._viewNameParent.current,
					this._viewName.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					0,
					0,
				),
			});
		}
	}

	public override render() {
		const {
			isSharedToolTipOpen,
			sharedToolTipTransform,
			isOwnerToolTipOpen,
			ownerToolTipTransform,
			viewNameToolTipTransform,
			isInEditMode,
			renameErrorMessage,
			isViewNameToolTipOpen,
		} = this.state;
		const {appState, level} = this.props;
		const app = appState.app;
		const view = this.props.view;

		const inlineStyleViewName: React.CSSProperties = this._viewName.current && {
			transform: viewNameToolTipTransform?.translate,
		};

		const isActive = view?.itemFeature === appState.selectedFeature && view.id === appState.actions.getSelectedView(view?.itemFeature)?.id;
		const Icon = view?.itemFeature === XyiconFeature.SpaceEditor ? FrameIcon : ListIcon;

		return (
			<ViewElementStyled
				className={ReactUtils.cls("ViewItem hbox alignCenter", {
					isActive,
					isInEditMode: this.state.isInEditMode,
					level1: level === 1,
				})}
				onClick={this.onClick}
				$isActive={isActive}
			>
				{/* <DragIcon /> */}
				<Icon />
				{isInEditMode ? (
					<TextInputV5
						className="viewName flex_1"
						value={view.name ?? ""}
						errorMessage={renameErrorMessage}
						onInput={this.onRenameInput}
						onChange={this.onRenameApply}
						onBlur={this.onRenameBlur}
						autoFocus={true}
						getErrorMessage={this.getErrorMessage}
						errorMessageTop={-22}
					/>
				) : (
					<div
						ref={this._viewNameParent}
						className="viewName flex_1"
						onMouseOver={this.onMouseOverViewName}
						onMouseLeave={this.onMouseLeaveViewName}
					>
						{view?.name}
					</div>
				)}
				{/* <div className="avatar" ref={this._avatar} onMouseEnter={this.openOwnerToolTip} onMouseLeave={this.closeOwnerToolTip}>
					{
						isOwnerToolTipOpen &&
						<DomPortal destination={app.modalContainer}>
							<div className="ViewItem__ownerToolTip" style={inlineStyleOwnerToolTip} ref={this._ownerToolTip}>
								<InfoBubbleV5 content="Owner" />
							</div>
						</DomPortal>
					}
					{
						viewOwner?.profileFileName
							? <img src={viewOwner.profileFileName} alt={`${viewOwner?.fullName} profile image`} />
							: <InitialsV5 name={viewOwner?.fullName || viewOwner?.email} />
					}
				</div> */}
				{/* <div className="sharedNum" onMouseEnter={this.openSharedToolTip} onMouseLeave={this.closeSharedToolTip} ref={this._sharedNum}>
					{
						isSharedToolTipOpen &&
						<DomPortal destination={app.modalContainer}>
							<div className="ViewItem__sharedToolTip" style={inlineStyleSharedToolTip} ref={this._sharedToolTip}>
								<InfoBubbleV5 content={this.getToolTipContent()} />
							</div>
						</DomPortal>
					}
					{
						this.getViewSharingListLength() ? `/${this.getViewSharingListLength()}` : ""
					}
				</div> */}
				{isViewNameToolTipOpen && (
					<DomPortal destination={app.modalContainer}>
						<div
							className="ViewItem__viewNameToolTip"
							style={inlineStyleViewName}
							ref={this._viewName}
							title={view?.name}
						>
							{/* <InfoBubbleV5 content={view.name ?? ""} /> */}
						</div>
					</DomPortal>
				)}
				<DropdownButtonV5
					options={this.getOptions()}
					button={
						<IconButtonV5
							IconComponent={DotsHorizontalIcon}
							onClick={Functions.emptyFunction}
						/>
					}
					optionsZIndex={zIndex.primaryNavigation + 1}
				/>
			</ViewElementStyled>
		);
	}
}

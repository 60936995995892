import styled from "styled-components";
import {colorPalette} from "../../../styles/colorPalette";
import {baseDistance, fontSize, fontWeight} from "../../../styles/styles";

export const FloatingCaptionConfigurator = styled.div`
	&.FloatingCaptionConfigurator {
		position: absolute;
		background-color: ${colorPalette.white};
		border: 1px solid ${colorPalette.gray.c200Light};
		z-index: 10;

		.ColorSelector {
			border: 1px solid ${colorPalette.gray.c200Light};

			.innerSpan {
				width: 38px;
			}

			&[title="Fill Color"] {
				.innerSpan svg {
					width: 30px;
					height: 30px;
				}
			}
		}

		.title {
			padding: ${baseDistance.sm} ${baseDistance.md};
			background: var(--bg3);
			display: flex;
			font-size: ${fontSize.lg};
			font-weight: ${fontWeight.normal};
			justify-content: space-between;
		}

		.fontSettings {
			padding: 20px;
			display: flex;
			align-items: center;

			> div {
				margin-left: ${baseDistance.sm};
				margin-right: ${baseDistance.sm};
				display: flex;
			}

			.SelectInput {
				height: 32px;
				margin-left: ${baseDistance.sm};
				margin-right: ${baseDistance.sm};
			}
		}
	}
`;

import {ArrayUtils} from "../../data/array/ArrayUtils";

interface IFocusLossListener {
	(event: Event): any;
}

type StarterEvent = "down" | "up";

export class FocusLoss {
	// Note: static state is usually not great...
	private static _detectors: FocusLoss[] = [];

	private _focusLossStarterEvent: StarterEvent = null;

	public static listen(element: HTMLElement, listener: IFocusLossListener, windowTarget?: EventTarget, focusLossStarterEvent: StarterEvent = "down") {
		FocusLoss._detectors.push(new FocusLoss(element, listener, windowTarget, focusLossStarterEvent));
	}

	public static stopListen(element: HTMLElement, listener: IFocusLossListener) {
		for (let i = FocusLoss._detectors.length - 1; i >= 0; --i) {
			const detector = FocusLoss._detectors[i];

			if (detector._element === element && detector._onFocusLoss === listener) {
				detector.dispose();
				ArrayUtils.removeMutable(FocusLoss._detectors, detector);
			}
		}
	}

	private _element: HTMLElement;
	private _onFocusLoss: IFocusLossListener;
	private _windowTarget: EventTarget;
	private _touchMove: boolean = false;
	private _isPointerDown: boolean = false;

	private constructor(
		element: HTMLElement,
		onFocusLoss: IFocusLossListener,
		windowTarget?: EventTarget,
		focusLossStarterEvent: StarterEvent = "down",
	) {
		this._element = element;
		if (!this._element) {
			return;
		}
		this._onFocusLoss = onFocusLoss;
		this._windowTarget = windowTarget || window;

		// Add with setTimeout(f, 0) ? (in case it's opened in a click handler)
		this._focusLossStarterEvent = focusLossStarterEvent;
		this.addListeners();
	}

	private addListeners() {
		this._windowTarget.addEventListener("touchmove", this.onTouchMove);

		if (this._focusLossStarterEvent === "down") {
			// useCapture: true to avoid other listeners to call stopPropagation
			this._windowTarget.addEventListener("mousedown", this.onEventStarted, true);
			this._windowTarget.addEventListener("touchstart", this.onEventStarted, true);
		} else {
			// We don't want to lose focus when "mousedown" happens inside the element, but "mouseup" happens outside
			// See https://dev.azure.com/xyicon/SpaceRunner%20V4/_workitems/edit/3446 for more details
			this._element.addEventListener("mousedown", this.onPointerDownOnElement);
			this._windowTarget.addEventListener("mouseup", this.onPointerUp, true);
			this._windowTarget.addEventListener("touchend", this.onPointerUp, true);
		}

		this._windowTarget.addEventListener("blur", this.onWindowBlur);
	}

	private removeListeners() {
		this._windowTarget.removeEventListener("touchmove", this.onTouchMove);

		if (this._focusLossStarterEvent === "down") {
			this._windowTarget.removeEventListener("mousedown", this.onEventStarted, true);
			this._windowTarget.removeEventListener("touchstart", this.onEventStarted, true);
		} else {
			this._element.removeEventListener("mousedown", this.onPointerDownOnElement);
			this._windowTarget.removeEventListener("mouseup", this.onPointerUp, true);
			this._windowTarget.removeEventListener("touchend", this.onPointerUp, true);
		}

		this._windowTarget.removeEventListener("blur", this.onWindowBlur);
	}

	private onPointerDownOnElement = (event: MouseEvent | TouchEvent) => {
		this._isPointerDown = true;
	};

	private onPointerUp = (event: MouseEvent | TouchEvent) => {
		this.onEventStarted(event);
		this._isPointerDown = false;
	};

	private onTouchMove = () => {
		this._touchMove = true;
	};

	private onEventStarted = (event: MouseEvent | TouchEvent) => {
		if (!this._element) {
			return;
		}
		// If clicking on something not inside this element -> lose focus
		if (!this._touchMove && !this._isPointerDown && !(event.target instanceof Window) && !this._element.contains(event.target as HTMLElement)) {
			this.loseFocus(event);
		} else if (this._touchMove) {
			this._touchMove = false;
		}
	};

	private onWindowBlur = (event: MouseEvent | TouchEvent) => {
		this.loseFocus(event);
	};

	private loseFocus(event: Event) {
		const result = this._onFocusLoss(event);

		if (result !== false) {
			this.removeListeners();
			this._onFocusLoss = null;
		}
	}

	public dispose() {
		this.removeListeners();
		this._touchMove = false;
	}
}

import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {IPopupWindowConfig} from "./PopupWindow";
import {PopupWindow} from "./PopupWindow";

/**
 * Can be used as an alternative for `confirm("confirmMessage");`
 */
export class ConfirmWindow extends PopupWindow<boolean | null> {
	protected _okValue = true;
	protected _cancelValue = false;

	protected static override readonly _defaultConfig: IPopupWindowConfig = {
		ok: "Yes",
		cancel: "No",
		backdrop: false,
	};

	constructor(message: string, title: string, config: IPopupWindowConfig) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(ConfirmWindow._defaultConfig, config),
		});
	}

	public static open(message: string, title: string = "Please confirm", config: IPopupWindowConfig = {}) {
		return new ConfirmWindow(message, title, config).open() as Promise<boolean>;
	}
}

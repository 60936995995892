import {inject, observer} from "mobx-react";
import * as React from "react";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {TableViewV5} from "../../../abstract/TableViewV5";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import type {IActionBarItem} from "../../../abstract/ModuleViewV5";
import DeleteIcon from "../../../icons/delete.svg?react";
import {PortfolioGroupDetailsTabV5} from "./PortfolioGroupDetailsTabV5";
import {CreatePortfolioGroupPanelV5} from "./CreatePortfolioGroupPanelV5";

interface IPortfolioGroupsViewProps {
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class PortfolioGroupsViewV5 extends React.Component<IPortfolioGroupsViewProps> {
	private _headers = [
		{
			id: "name",
			title: "Group Name",
		},
		{
			id: "portfolioIds",
			title: "Number of portfolios",
		},
		{
			id: "lastModifiedAt",
			title: "Last Updated",
		},
		{
			id: "lastModifiedBy",
			title: "Updated By",
		},
	];

	private canDeleteUser = (users: PortfolioGroup[]) => {
		if (users.length < 1) {
			return false;
		}

		return true;
	};

	private getActionBar = () => {
		const result: IActionBarItem<PortfolioGroup>[] = [
			{
				id: "delete",
				title: "Delete Report",
				label: "Delete",
				IconComponent: DeleteIcon,
				visible: () => true,
				enabled: this.canDeleteUser,
			},
		];

		return result;
	};

	public override render() {
		return (
			<TableViewV5
				ownFeature={XyiconFeature.PortfolioGroup}
				headers={this._headers}
				create={(onCreated, onCancel, creating) => (
					<CreatePortfolioGroupPanelV5
						onCancel={onCancel}
						onCreated={onCreated}
						creating={creating}
					/>
				)}
				details={(selected: PortfolioGroup[]) => <PortfolioGroupDetailsTabV5 portfolioGroups={selected} />}
				actionBar={this.getActionBar()}
				canDeleteUser={this.canDeleteUser}
			/>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {ConfirmWindow} from "../../popups/ConfirmWindow";
import type {View} from "../../../../../data/models/View";
import type {ViewSharingSettingsData} from "../../../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../../../generated/api/base";
import type {App} from "../../../../../App";
import type {AppState} from "../../../../../data/state/AppState";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import type {User} from "../../../../../data/models/User";
import {AddUserOrUserGroup} from "../../../../widgets/user/AddUserOrUserGroup";
import {IconButton} from "../../../../widgets/button/IconButton";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {Initials} from "../../../../widgets/Initials";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {ShareOption} from "./ShareOption";

interface IEditViewSharingProps {
	feature: XyiconFeature;
	view: View;
	transport?: TransportLayer;
	close?: () => void;
	onDeleteCurrentUser?: () => void;

	app?: App;
	appState?: AppState;
}

interface IEditViewSharingState {
	search: string;
	shareWith: User | UserGroup;
	permission: number;
	error: string;
}

/**
 * Note: this component is very similar to ReportSharingSection
 */
@inject("app")
@inject("appState")
@inject("transport")
@observer
export class EditViewSharing extends React.Component<IEditViewSharingProps, IEditViewSharingState> {
	private readonly _editingView: View;
	private _isDeletePopupWindowOpen: boolean = false;

	constructor(props: IEditViewSharingProps) {
		super(props);

		this._editingView = props.view;

		this.state = {
			search: "",
			shareWith: null,
			permission: Permission.View,
			error: null,
		};
	}

	private onSearch = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private onAddClick = (id: string, feature: XyiconFeature.User | XyiconFeature.UserGroup) => {
		const {appState} = this.props;
		const item = appState.actions.getFeatureItemById<User | UserGroup>(id, feature);

		const sharingSettings = this.props.view.viewSharingSettings || [];

		if (feature === XyiconFeature.User) {
			sharingSettings.push({
				userID: item.id,
				userGroupID: null,
				canEditSharedView: this.state.permission === Permission.Update,
			});
		} else if (feature === XyiconFeature.UserGroup) {
			sharingSettings.push({
				userID: null,
				userGroupID: item.id,
				canEditSharedView: this.state.permission === Permission.Update,
			});
		}

		this.setState({search: ""});
		this.saveChanges();
	};

	private saveChanges = async () => {
		const {transport, appState, feature, view} = this.props;

		this._editingView?.applyData(view.getData());
		await transport.services.view.update(view.getData());
		const selectedView = this.props.app.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);

		if (selectedView?.id === view.id) {
			appState.actions.onViewSelected();
		}
	};

	private onDeleteUserGroup = async (userGroup: UserGroup) => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await ConfirmWindow.open("Are you sure you want to delete the selected 1 item?");

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				const sharingSettings = this.props.view.viewSharingSettings;
				const index = sharingSettings?.findIndex((sh) => sh.userGroupID === userGroup.id);

				if (index >= 0) {
					sharingSettings.splice(index, 1);
				}

				this.saveChanges();
			}
		}
	};

	private onDeleteUser = async (user: User) => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await ConfirmWindow.open("Are you sure you want to delete the selected 1 item?");

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				const {close, appState, feature, onDeleteCurrentUser} = this.props;
				const sharingSettings = this.props.view.viewSharingSettings;
				const index = sharingSettings?.findIndex((sh) => sh.userID === user.id);

				if (index >= 0) {
					sharingSettings.splice(index, 1);
				}

				this.saveChanges();

				if (user.id === appState.user.id) {
					appState.actions.selectViewById(appState.actions.getViews(feature)[0].id);

					onDeleteCurrentUser?.();
					close?.();
				}
			}
		}
	};

	private getShareOptionValue = (sharing: ViewSharingSettingsData) => {
		let permission = Permission.View;

		if (this.props.view.ownedBy === sharing.userID) {
			permission = ShareOption.OWNER;
		} else if (sharing.canEditSharedView) {
			permission = Permission.Update;
		}

		return permission;
	};

	private setSetUserPermission = async (permission: number, sharing: ViewSharingSettingsData) => {
		const {view} = this.props;

		if (permission === ShareOption.OWNER) {
			if (!this._isDeletePopupWindowOpen) {
				this._isDeletePopupWindowOpen = true;
				const confirmed = await ConfirmWindow.open(
					`You will be removed as the owner of the "${view.name}" view but will retain edit privileges. Do you want to continue?`,
					"Confirm Ownership Change",
					{ok: "Change Owner", cancel: "Cancel"},
				);

				this._isDeletePopupWindowOpen = false;

				if (confirmed) {
					const tempOwnerId = view.ownedBy;

					// set new view owner
					view.ownedBy = sharing.userID;

					// set editing permission to old owner
					const oldOwner = view.viewSharingSettings.find((sharing) => sharing.userID === tempOwnerId);

					if (oldOwner) {
						oldOwner.canEditSharedView = true;
					} else {
						const tempOwner = this.props.appState.actions.findUser(tempOwnerId);

						view.viewSharingSettings.push({
							userID: tempOwner.id,
							userGroupID: null,
							canEditSharedView: true,
						});
					}
				}
			}
		} else if (permission === Permission.Update) {
			sharing.canEditSharedView = true;
		} else if (permission === Permission.View) {
			sharing.canEditSharedView = false;
		}

		this.saveChanges();
	};

	private setSetUserGroupPermission = (permission: number, sharing: ViewSharingSettingsData) => {
		sharing.canEditSharedView = permission === Permission.Update;
		this.saveChanges();
	};

	public override render() {
		const {view, appState} = this.props;
		const {search} = this.state;

		const users = appState.lists[XyiconFeature.User];
		const userGroups = appState.lists[XyiconFeature.UserGroup];
		const viewOwner: User = users?.getById(view?.ownedBy);

		const viewSharing = view?.viewSharingSettings || [];
		const userGroupSharingList = viewSharing.filter((sh) => sh.userGroupID);
		const userSharingList = viewSharing.filter((sh) => sh.userID);

		const exceptionIds: string[] = [...userGroupSharingList.map((sh) => sh.userGroupID), ...userSharingList.map((sh) => sh.userID)];

		return (
			<>
				<div className="heading createBox hbox">
					<h4>{`Share "${view?.name}" view`}</h4>
					<IconButton
						className="closePanel"
						icon="close"
						onClick={this.props.close}
					/>
				</div>
				<div className="searchBox hbox flex_1">
					<AddUserOrUserGroup
						search={search}
						onSearch={this.onSearch}
						onAdd={this.onAddClick}
						exceptions={exceptionIds}
						view={view}
					/>
				</div>
				<div className="EditViewSharing vbox">
					<div className="vbox flex_1 overflowYAuto">
						<div className="section">
							<h3>Owner</h3>
							<div className="list">
								<div className="item hbox owner">
									<div className="avatar">
										{viewOwner?.profileFileName ? (
											<img
												src={viewOwner.profileFileName}
												alt={`${viewOwner?.fullName} profile image`}
											/>
										) : (
											<Initials name={viewOwner?.fullName || viewOwner?.email} />
										)}
									</div>
									<div className="vbox flex_1">
										<div className="name">{`${viewOwner?.fullName}${viewOwner === appState.user ? " (Me)" : ""}`}</div>
										<div className="email">{viewOwner?.email}</div>
									</div>
									<SVGIcon
										icon="crown"
										classNames="owner-icon"
									/>
								</div>
							</div>
						</div>
						<div className="section">
							<h3>User Groups</h3>
							{userGroupSharingList.find((sharing) => userGroups.getById(sharing.userGroupID)) ? (
								userGroupSharingList
									.toSorted((a: ViewSharingSettingsData, b: ViewSharingSettingsData) =>
										StringUtils.sortIgnoreCase(userGroups.getById(a.userGroupID).name, userGroups.getById(b.userGroupID).name),
									)
									.map((sharing, index) => {
										const userGroup = userGroups.getById(sharing.userGroupID);

										if (!userGroups) {
											return null;
										}
										return (
											<div
												key={index}
												className="item hbox"
											>
												<div className="avatar">
													<Initials name={userGroup.name} />
												</div>
												<div className="vbox flex_1">
													<div className="name">{userGroup.name}</div>
													<div className="counter">{userGroup.renderMemberCount()}</div>
												</div>
												{!view.isSystem && (
													<ShareOption
														value={sharing.canEditSharedView ? Permission.Update : Permission.View}
														onChange={(permission) => this.setSetUserGroupPermission(permission, sharing)}
														hasOwner={false}
														disabled={appState.user && !appState.user?.isAdmin}
													/>
												)}
												{appState.user?.isAdmin && (
													<IconButton
														icon="delete"
														onClick={() => this.onDeleteUserGroup(userGroup)}
													/>
												)}
											</div>
										);
									})
							) : (
								<div className="empty">
									<div>View not shared with a user group</div>
								</div>
							)}
						</div>
						<div className="section">
							<h3>Users</h3>
							{userSharingList.find((sharing) => users.getById(sharing.userID)) ? (
								userSharingList
									.toSorted((a: ViewSharingSettingsData, b: ViewSharingSettingsData) =>
										StringUtils.sortIgnoreCase(users.getById(a.userID)?.fullName, users.getById(b.userID)?.fullName),
									)
									.map((sharing, index) => {
										const user: User = users.getById(sharing.userID);

										if (!user || user.id === view.ownedBy) {
											return null;
										}
										return (
											<div
												key={index}
												className={ReactUtils.cls("item hbox", {unregistered: (users.getById(sharing.userID) as User).status === "invited"})}
											>
												<div className="avatar">
													{user.profileFileName ? (
														<img
															src={user.profileFileName}
															alt={`${user.fullName} profile image`}
														/>
													) : (
														<Initials name={user.fullName || user.email} />
													)}
												</div>
												<div className="vbox flex_1">
													<div className="name">{user.fullName}</div>
													<div className="email">{user.email}</div>
												</div>
												{!view.isSystem && (
													<ShareOption
														value={this.getShareOptionValue(sharing)}
														onChange={(permission) => this.setSetUserPermission(permission, sharing)}
														hasOwner={appState.user?.id === view.ownedBy}
													/>
												)}
												<IconButton
													icon="delete"
													onClick={() => this.onDeleteUser(user)}
												/>
											</div>
										);
									})
							) : (
								<div className="empty">
									<div>View not shared with a user</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

import {useRef} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {zIndex} from "../styles/styles";

export const InfoBubbleStyled = styled.div<{$isFocus?: boolean}>`
	z-index: ${zIndex.contextOptions + zIndex.popup};
	background: #37474f;
	box-shadow: 0px 4px 20px 0px #00000033;
	border-radius: 4px;
	font-size: 12px;
	line-height: 140%;
	position: absolute;
	width: max-content;
	max-width: 300px;
	left: 50%;
	border-color: none;
	color: white;
	border-left: none;
	top: -5px;
	transform: translateX(-50%) translateY(-100%) translateY(-10px);
	white-space: initial;
	display: ${({$isFocus}) => ($isFocus ? "block" : "none")};

	&.error {
		left: calc(50% - 6px);
		border-color: #ff4136;
		color: #ff4136;
		background: white;
		border-left: #ff4136 4px solid;

		&::after {
			background: #ffffff;
		}

		&.left {
			transform: translate(calc(-100% + 15px), -44px);
			&::after {
				right: 8px;
			}
		}
	}

	&.onPopup {
		z-index: ${zIndex.popup + zIndex.contextOptions};
	}

	&::after {
		content: "";
		background: #37474f;
		transform: rotate(45deg);
		position: absolute;
		bottom: -5px;
		width: 10px;
		height: 10px;
		left: 50%;
	}

	&.bottomOuter::after {
		top: -5px;
		bottom: auto;
	}

	&.left {
		transform: none;
		&::after {
			right: 10px;
		}
	}

	&.right::after {
		left: 10px;
	}

	div {
		word-wrap: break-word;
		padding: 10px;

		h4 {
			font-size: 12px;
			font-weight: 700;
			margin-bottom: 10px;
		}
	}
`;

interface IInfoBubbleV5Props {
	readonly content: string | React.ReactNode;
	readonly isErrorMessage?: boolean;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly style?: React.CSSProperties;
	readonly className?: string;
	readonly title?: string;
	readonly isFocus?: boolean;
}

export const InfoBubbleV5 = (props: IInfoBubbleV5Props) => {
	const floatingElementRef = useRef<HTMLDivElement>(null);
	const {isErrorMessage, content, divRef, style = {}, title, className, isFocus} = props;

	let left = false;

	if (floatingElementRef.current) {
		left = floatingElementRef.current.getBoundingClientRect().right > window.innerWidth;
	}

	return (
		<InfoBubbleStyled
			ref={divRef || floatingElementRef}
			style={style}
			className={ReactUtils.cls(`info-bubble, ${className || ""}`, {error: isErrorMessage, left})}
			$isFocus={isFocus}
		>
			<div className="content vbox">
				{!!title?.length && <h4>{title}</h4>}
				{content}
			</div>
		</InfoBubbleStyled>
	);
};

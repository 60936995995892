import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {App} from "../../../../App";
import type {AppState} from "../../../../data/state/AppState";
import type {Markup} from "../../../../data/models/Markup";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {FieldV5} from "../../details/FieldV5";
import {IDateFieldFormat} from "../../details/datatypes/DateFieldSettingsV5";

interface IMarkupDefaultFieldsProps {
	item: Markup;
	app?: App;
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("app")
@inject("appState")
@inject("transport")
@observer
export class MarkupDefaultFieldsV5 extends React.Component<IMarkupDefaultFieldsProps> {
	public override render() {
		const {item, appState} = this.props;

		return (
			<>
				<FieldV5 label="Last Modified At">{DateFormatter.format(item.data.lastModifiedAt, IDateFieldFormat.DATETIME)}</FieldV5>
				<FieldV5 label="Last Modified By">{appState.actions.findUser(item.data.lastModifiedBy)?.fullName || ""}</FieldV5>
				{item.textContent && <FieldV5 label="Markup Text">{item.textContent}</FieldV5>}
			</>
		);
	}
}

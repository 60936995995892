import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes, stringOperators} from "../FieldDataTypes";
import {MultiLineLabel} from "../../../../ui/widgets/input/clicktoedit/datatypes/multiline/MultiLineLabel";
import {MultiLineInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/multiline/MultiLineInput";
import {MassFieldMultiLineInput} from "../../../../ui/modules/abstract/sidepanel/tabs/details/field/mass/datatypes/MassFieldMultiLineInput";
import {MultiLineInputV5} from "../../../../ui/5.0/details/datatypes/multi/MultiLineInputV5";
import {MultiLineLabelV5} from "../../../../ui/5.0/details/datatypes/multi/MultiLineLabelV5";
import {MassFieldMultiLineInputV5} from "../../../../ui/5.0/details/datatypes/mass/MassFieldMultiLineInputV5";

export function configureMultiLine() {
	FieldDataTypes.configure({
		id: FieldDataType.MultiLineText,
		title: "Text (Multi Line)",
		operatorsArray: stringOperators,
		label: MultiLineLabel,
		labelV5: MultiLineLabelV5,
		input: MultiLineInput,
		inputV5: MultiLineInputV5,
		massInput: MassFieldMultiLineInput,
		massInputV5: MassFieldMultiLineInputV5,
	});
}

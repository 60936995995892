import * as React from "react";
import {inject} from "mobx-react";
import {AppFieldActions} from "../../../../data/state/AppFields";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature, FieldDataType} from "../../../../generated/api/base";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {simpleFilterPositions} from "../../../modules/abstract/filter/simple/SimpleFilterPositions";
import {ReactUtils} from "../../../utils/ReactUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import {CheckboxInput} from "../../../widgets/input/checkbox/CheckboxInput";
import {ToggleContainerV5} from "../../widgets/ToggleContainerV5/ToggleContainerV5";
import {SearchFieldV5} from "../../input/search/SearchFieldV5";
import {FilterFieldItem, SimpleFilterFieldStyled} from "./SimpleFilterField.styled";

interface IFilterFieldProps {
	fieldRefId: string;
	title: string;
	values: string[];
	checkedValues: string[];
	open: boolean;
	onToggle: (fieldRefId: string, open: boolean) => void;
	onChange: (fieldRefId: string, value: string) => void;
	onChangeAll: (fieldRefId: string, checked: boolean, values: any) => void;
	onClear: (fieldRefId: string) => void;
	hasFiltersApplied: boolean;
	appState?: AppState;
}

interface IFilterFieldState {
	search: string;
	loadMoreCount: number;
}

@inject("appState")
export class SimpleFilterFieldV5 extends React.Component<IFilterFieldProps, IFilterFieldState> {
	// As per https://dev.azure.com/xyicon/SpaceRunner%20V4/_sprints/taskboard/Product/SpaceRunner%20V4/2021%20November/S-79?workitem=2369
	// Hide "Blank" checkboxes for these fields
	private _hideBlanksForTheseFieldRefIds: string[] = simpleFilterPositions[XyiconFeature.SpaceEditor];
	private readonly rowPerPage: number = 500;

	constructor(props: IFilterFieldProps) {
		super(props);
		this.state = {
			search: "",
			loadMoreCount: 1,
		};
	}

	public override shouldComponentUpdate(nextProps: Readonly<IFilterFieldProps>, nextState: Readonly<IFilterFieldState>, nextContext: any): boolean {
		// This makes sure the component is only updated if it really changes
		return !ObjectUtils.compare(this.state, nextState) || !ObjectUtils.compare(this.props, nextProps);
	}

	private applySearch = (value: string) => {
		const {search} = this.state;

		return StringUtils.containsIgnoreCase(value, search);
	};

	private onClearClick = (event: React.MouseEvent) => {
		// Don't allow the toggle to happen at the same time
		event.stopPropagation();
		this.props.onClear(this.props.fieldRefId);
	};

	private onValueChange = (value: string) => {
		this.props.onChange(this.props.fieldRefId, value);
	};

	private onToggle = (open: boolean) => {
		this.disableScroll();
		this.props.onToggle(this.props.fieldRefId, open);
		this.setState({loadMoreCount: 1});
	};

	private disableScroll = () => {
		// Get the current div scroll position
		const topContainer = document.getElementsByClassName("top-section")[0];
		const scrollTop = topContainer.scrollTop;
		const scrollLeft = topContainer.scrollLeft;

		// if any scroll is attempted,
		// set this to the previous value
		setTimeout(() => {
			topContainer.scrollTo(scrollLeft, scrollTop);
		});
	};

	private onToggleAll = () => {
		const searchedValues = this.getSearchedValues();
		const value = this.allSearchedOptionsAreChecked(searchedValues);

		this.props.onChangeAll(this.props.fieldRefId, !value, searchedValues);
	};

	private getSearchedValues() {
		const {search} = this.state;
		const {values} = this.props;

		return search ? values.filter(this.applySearch) : values;
	}

	private onSearchChange = (value: string) => {
		this.setState({
			search: value,
			loadMoreCount: 1,
		});
	};

	private allSearchedOptionsAreChecked(searchedValues: string[]) {
		const {checkedValues} = this.props;
		const hasUncheckedSearchValue = searchedValues.some((v) => !checkedValues.includes(v));

		return !hasUncheckedSearchValue;
	}

	private columnContainerOnScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
		const {scrollTop, offsetHeight, scrollHeight} = event.target as HTMLDivElement;
		const leewayBeforeBottom = 1000;

		if (scrollTop + offsetHeight >= scrollHeight - leewayBeforeBottom) {
			this.setState({
				loadMoreCount: this.state.loadMoreCount + 1,
			});
		}
	}

	private getChildren() {
		const {values, checkedValues, fieldRefId, appState} = this.props;
		const {search, loadMoreCount} = this.state;
		const searchedValues = this.getSearchedValues();
		const field = appState.actions.getFieldByRefId(fieldRefId);
		const isMultipleChoice = field.dataType === FieldDataType.MultipleChoiceList;
		const allSearchedValuesAreChecked = this.allSearchedOptionsAreChecked(searchedValues);

		return (
			<SimpleFilterFieldStyled>
				<SearchFieldV5
					placeholder="Find..."
					className="findInput"
					value={search}
					onInput={this.onSearchChange}
				/>
				<FilterFieldItem>
					<CheckboxInput
						disabled={values.length === 0}
						value={allSearchedValuesAreChecked}
						onChange={this.onToggleAll}
					/>
					All
				</FilterFieldItem>
				{searchedValues.includes("") && !this._hideBlanksForTheseFieldRefIds.includes(field.refId) && (
					<FilterFieldItem>
						<CheckboxInput
							value={checkedValues.includes("")} // TODO
							onChange={(e) => this.onValueChange("")}
						/>
						Blanks
					</FilterFieldItem>
				)}
				<div
					className={ReactUtils.cls("values", {multi: isMultipleChoice})}
					onScroll={(ev) => this.columnContainerOnScroll(ev)}
				>
					{searchedValues.length > 0 ? (
						searchedValues
							.sort((a, b) => {
								if (AppFieldActions.isRefId(field.refId)) {
									const a_num = StringUtils.refId2Number(a);
									const b_num = StringUtils.refId2Number(b);

									return a_num > b_num ? 1 : -1;
								}

								return StringUtils.sortIgnoreCase(a, b);
							})
							.slice(0, loadMoreCount * this.rowPerPage) // scroll pagination
							.map(
								(value) =>
									// It's possible that 2011 and "2011" are both present in the array, but they're not interpreted as 2 different keys
									value && (
										<FilterFieldItem>
											<CheckboxInput
												value={checkedValues.includes(value)}
												onChange={(e) => this.onValueChange(value)}
												className="checkbox"
											/>
											{value}
										</FilterFieldItem>
									),
							)
					) : (
						<div className="noResult field">No results found for the term "{search}".</div>
					)}
				</div>
			</SimpleFilterFieldStyled>
		);
	}

	public override render() {
		const {title, open, appState} = this.props;

		const toggleTitle = (
			<span className={ReactUtils.cls("FilterTitle", {applied: this.props.hasFiltersApplied})}>
				{this.props.hasFiltersApplied && (
					<IconButton
						icon="close"
						className="close"
						onClick={this.onClearClick}
					/>
				)}
				{title}
			</span>
		);

		return (
			<ToggleContainerV5
				title={toggleTitle}
				open={open}
				onToggleOpen={this.onToggle}
				className="SimpleFilterField"
				scrollIntoViewOnOpen={true}
				hasFiltersApplied={this.props.hasFiltersApplied}
			>
				{open && this.getChildren()}
			</ToggleContainerV5>
		);
	}
}

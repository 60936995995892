import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {FieldV5} from "../../../details/FieldV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {FieldDataType, XyiconFeature} from "../../../../../generated/api/base";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {DetailsTabV5HeaderStyles} from "../../../details/DetailsTabV5HeaderStyles";
import {FlexCenter, radius} from "../../../styles/styles";
import InfoIcon from "../../../icons/circle-information.svg?react";
import {NodataUserDetailsTabStyled, NoDataStyled} from "../users/UserDetailsTabV5";
import {FieldStyled} from "../../../details/Field.styled";
import {UserGroupUsersV5} from "./UserGroupUsersV5";

export const UserGroupDetailsTabStyled = styled.div`
	${DetailsTabV5HeaderStyles} {
		padding-top: 16px;
		padding-left: 16px;
		border-bottom: none;
		padding-bottom: 2px;
		margin-bottom: 10px;
	}

	.disabledInputFeild {
		border: 1px solid #c8c8c8;
		border-radius: ${radius.sm};
	}

	${FieldStyled} {
		margin-bottom: 8px;
	}
`;

interface IUserGroupDetailsTabProps {
	userGroups: UserGroup[];
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class UserGroupDetailsTabV5 extends React.Component<IUserGroupDetailsTabProps> {
	private isNameValid = (value: string) => {
		const {appState, userGroups} = this.props;

		return appState.actions.isUserGroupNameValid(value, userGroups[0]);
	};

	private onNameChange = (value: string) => {
		if (this.isNameValid(value)) {
			this.props.userGroups[0].name = value;
			this.updateApi();
		}
	};

	private onBlur = () => {
		const userGroup = this.props.userGroups[0];

		if (userGroup) {
			this.updateApi();
		}
	};

	private onUsersChanged = () => {
		this.updateApi();
	};

	private updateApi() {
		const userGroup = this.props.userGroups[0];

		if (userGroup.id) {
			// we're in edit mode
			this.props.transport.services.feature.update(userGroup.id, XyiconFeature.UserGroup, {
				name: userGroup.name,
				userIDList: userGroup.userIds,
			});
		}
	}

	private get userGroupMemberCount() {
		const {appState, userGroups} = this.props;

		return userGroups[0].userIds.filter((id) => appState.actions.getFeatureItemById(id, XyiconFeature.User)).length;
	}

	public override render() {
		const {userGroups, appState} = this.props;
		const userGroup = userGroups[0];

		return (
			<NodataUserDetailsTabStyled>
				{userGroups.length === 0 && <NoDataStyled>Please select a User Group</NoDataStyled>}
				{userGroups.length === 1 && (
					<>
						<UserGroupDetailsTabStyled>
							<DetailsTabV5HeaderStyles>
								<FlexCenter $gap="10px">
									<InfoIcon />
									<h2>Details</h2>
								</FlexCenter>
							</DetailsTabV5HeaderStyles>
							<div className="header">
								<FieldV5 label="Name">
									<ClickToEditInputV5
										disabled={userGroup.isSystem}
										value={userGroup.name}
										onChange={this.onNameChange}
										onBlur={this.onBlur}
										dataType={FieldDataType.SingleLineText}
										valueValidator={this.isNameValid}
									/>
								</FieldV5>
								<FieldV5 label="Users">
									<ClickToEditInputV5
										value={this.userGroupMemberCount}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<FieldV5 label="Last Updated">
									<ClickToEditInputV5
										value={DateFormatter.format(userGroup.lastModifiedAt)}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<FieldV5 label="Last Updated By">
									<ClickToEditInputV5
										value={appState.actions.renderName(userGroup.lastModifiedBy)}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>
							</div>
						</UserGroupDetailsTabStyled>

						<UserGroupUsersV5
							userGroup={userGroup}
							onChange={this.onUsersChanged}
						/>
					</>
				)}
			</NodataUserDetailsTabStyled>
		);
	}
}

import type {Report} from "../../../data/models/Report";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import {ReportScopeWidgetV5} from "./ReportScopeWidgetV5";

interface IReportScopeSectionProps {
	readonly report: Report;
}

export const ReportScopeSectionV5 = (props: IReportScopeSectionProps) => {
	const {report} = props;

	const onUpdate = () => {
		// const {report, appState} = this.props;
		// appState.actions.updateReport(report);
	};

	return (
		<ToggleContainerV5
			title="Scope"
			open={true}
		>
			<ReportScopeWidgetV5
				report={report}
				onChange={onUpdate}
				disabled={true}
			/>
		</ToggleContainerV5>
	);
};

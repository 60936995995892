import * as React from "react";
import type {ISizeRange} from "../../logic3d/Constants";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import {MarkupUtils} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {SizeChanger} from "../../../../abstract/common/sizechanger/SizeChanger";
import type {Markup} from "../../../../../../data/models/Markup";
import {MarkupsWithArrowHeads} from "../../logic3d/elements3d/markups/MarkupStaticElements";

interface IArrowHeadSizeChanger {
	spaceViewRenderer: SpaceViewRenderer;
}

export class ArrowHeadSizeChanger extends React.Component<IArrowHeadSizeChanger> {
	private _range: ISizeRange = {
		values: [10, 20, 30, 40, 50, 75, 100, 150, 200, 300, 500],
		default: MarkupUtils.defaultArrowHeadSize,
		min: 10,
		max: 500,
	};

	private get _selectedItems() {
		return this.props.spaceViewRenderer.spaceItemController.selectedItems as Markup3D[];
	}

	private onChange = (newValue: number) => {
		const markupsToUpdate: Markup3D[] = [];

		const markup3Ds = this._selectedItems as Markup3D[];

		for (const markup3D of markup3Ds) {
			const markup = markup3D.modelData as Markup;
			const hasChanged = markup.arrowHeadSize !== newValue;

			if (hasChanged) {
				markup.setArrowHeadSize(newValue);
				markupsToUpdate.push(markup3D);
			}
		}

		for (const markup3D of markupsToUpdate) {
			markup3D.updateByModel(markup3D.modelData as Markup);
		}

		this.props.spaceViewRenderer.markupManager.updateItems(markupsToUpdate, true);

		this.forceUpdate();
	};

	public override render() {
		const selectedItems = this._selectedItems;
		const areOnlyArrowsSelected =
			selectedItems.length > 0 && selectedItems.every((item) => item.spaceItemType === "markup" && MarkupsWithArrowHeads.includes(item.type));

		return (
			areOnlyArrowsSelected && (
				<div
					className="hbox"
					title="Change arrowhead size"
				>
					<SVGIcon
						icon="arrowHeadSize"
						style={{width: "40px"}}
					/>
					<SizeChanger
						value={(selectedItems[0].modelData as Markup).arrowHeadSize}
						onChange={this.onChange}
						range={this._range}
					/>
				</div>
			)
		);
	}
}

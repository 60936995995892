import {RawShaderMaterial} from "three";
import type {WebGLProgramParametersWithUniforms} from "three";

export class BasicInstanceMaterial extends RawShaderMaterial {
	private _vertexShader: string = `precision highp float;

attribute vec3 position;
attribute mat4 instanceMatrix;
attribute vec3 color;
attribute float opacity;

uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;

varying vec3 vColor;
varying float vOpacity;

void main()
{
	vColor = color;
	vOpacity = opacity;
	
	gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(position, 1.0);
}
`;

	private _fragmentShader: string = `precision highp float;

varying vec3 vColor;
varying float vOpacity;

void main()
{
	gl_FragColor = vec4(vColor, vOpacity);
}
`;
	constructor() {
		super({transparent: true, depthTest: false});

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;
		};
	}
}

import type {IError} from "../data/TransportLayer";
import {NotificationType} from "../ui/notification/Notification";
import type {IModel} from "../data/models/Model";
import type {AppNotifications} from "../ui/notification/AppNotifications";
import {notify} from "./Notify";

export class TimeUtils {
	public static readonly updateThreshold = 1000;

	public static async waitUpdates<T = any>(
		promises: Promise<{result: T; error: IError}>[],
		items: IModel[],
		notificationContainer: React.RefObject<AppNotifications>,
	) {
		const startTime = Date.now();

		const results = await Promise.all(promises);
		const errors = results.filter((r) => r.error);

		if (errors.length > 0) {
			if (errors.length === results.length) {
				notify(notificationContainer, {
					type: NotificationType.Error,
					title: "Failed to update selected objects",
					description: "Oops, something went wrong. We were unable to update the selected objects.",
				});
			} else {
				const objectIds: string[] = [];

				results.forEach((result, index) => {
					if (result.error) {
						const item = items[index];

						objectIds.push(item?.refId || item?.id);
					}
				});
				notify(notificationContainer, {
					type: NotificationType.Error,
					title: `Failed to update ${errors.length} out of ${results} objects`,
					description: `Oops, something went wrong. We were unable to update the following objects: ${objectIds.join(", ")}`,
				});
			}
		} else {
			const dt = Date.now() - startTime;
			const timeout = Math.max(0, TimeUtils.updateThreshold - dt);

			await TimeUtils.wait(timeout);
		}
	}

	public static async waitUpdate<T = any>(promise: Promise<{result: T; error: IError}>, notificationContainer: React.RefObject<AppNotifications>) {
		const startTime = Date.now();

		const response = await promise;

		if (response.error) {
			notify(notificationContainer, {
				type: NotificationType.Error,
				title: "Failed to update selected object",
				description: "Oops, something went wrong. We were unable to update the selected object.",
			});
		} else {
			const dt = Date.now() - startTime;
			const timeout = Math.max(0, TimeUtils.updateThreshold - dt);

			await TimeUtils.wait(timeout);
		}
	}

	public static wait(delay: number): Promise<void> {
		return new Promise((resolve) => {
			setTimeout(resolve, delay);
		});
	}

	public static waitForNextFrame(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			requestAnimationFrame(() => {
				resolve();
			});
		});
	}
}

import {observer} from "mobx-react";
import * as React from "react";
import {ReactUtils} from "../utils/ReactUtils";
import type {IIconButtonProps} from "../widgets/button/IconButton";
import {IconButton} from "../widgets/button/IconButton";
import type {TransformObj} from "../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../utils/dom/DomUtils";
import {FocusLoss} from "../../utils/ui/focus/FocusLoss";
import {TimeUtils} from "../../utils/TimeUtils";
import {Functions} from "../../utils/function/Functions";

interface ITopBarDropdownProps {
	elements: IDropdownElementProps[];
	parent: React.RefObject<HTMLDivElement>;
	parentOnClick?: () => void;
	parentFocusLoss?: () => void;
	horizontalAlignment?: HorizontalAlignment;
	verticalAlignment?: VerticalAlignment;
}

export interface IDropdownElementProps extends IIconButtonProps {
	hasLineOnTop?: boolean;
}

interface ITopBarDropdownState {
	transform: TransformObj;
	active: boolean;
}

@observer
export class TopBarDropdown extends React.Component<ITopBarDropdownProps, ITopBarDropdownState> {
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: ITopBarDropdownProps) {
		super(props);
		this.state = {
			transform: null,
			active: false,
		};
	}

	public static defaultProps: Partial<ITopBarDropdownProps> = {
		horizontalAlignment: HorizontalAlignment.right,
		verticalAlignment: VerticalAlignment.bottomOuter,
	};

	public toggleOpen = () => {
		this.setState((prevState) => {
			if (!prevState.active) {
				FocusLoss.stopListen(this.props.parent.current, this.onFocusLoss);
				FocusLoss.listen(this.props.parent.current, this.onFocusLoss);
			}

			return {active: !prevState.active};
		});

		this.props.parentOnClick?.();
	};

	private onFocusLoss = () => {
		this.setState({active: false});
		this.props.parentFocusLoss?.();
	};

	private async updateParent() {
		await TimeUtils.waitForNextFrame();
		const div = this.props.parent.current;

		if (div) {
			div.removeEventListener("click", this.toggleOpen);
			div.addEventListener("click", this.toggleOpen);
		}
	}

	public override componentDidMount() {
		this.updateParent();
	}

	public override componentDidUpdate(prevProps: ITopBarDropdownProps, prevState: ITopBarDropdownState) {
		const {parent, verticalAlignment, horizontalAlignment} = this.props;

		this.updateParent();
		if (!prevState.active && this.state.active && parent.current && this._floating.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					parent.current as Element,
					this._floating.current,
					verticalAlignment,
					horizontalAlignment,
					10,
					0,
				),
			});
		}
	}

	public override componentWillUnmount(): void {
		FocusLoss.stopListen(this.props.parent.current as HTMLElement, this.onFocusLoss);
	}

	public override render() {
		const {elements, horizontalAlignment} = this.props;

		const inlineStyle = this._floating.current && {
			transform: this.state.transform?.translate,
		};

		return (
			<>
				{this.state.active ? (
					<div
						ref={this._floating}
						style={inlineStyle}
						className={ReactUtils.cls("dropdownContent", {[HorizontalAlignment[horizontalAlignment]]: true})}
					>
						{elements.map((element, index) => {
							return (
								<div
									key={index}
									className={ReactUtils.cls("profileItem", {lined: element.hasLineOnTop})}
									onMouseUp={element.onClick}
									onMouseDown={Functions.preventDefault}
									onTouchStart={Functions.preventDefault}
								>
									<IconButton
										className="profileButton"
										icon={element.icon}
										label={element.label}
										strokeOnly={element.strokeOnly}
									/>
								</div>
							);
						})}
					</div>
				) : null}
			</>
		);
	}
}

import {Observer} from "mobx-react";
import {useAppStore} from "../../../StateManager";
import type {Catalog} from "../../../data/models/Catalog";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import {Functions} from "../../../utils/function/Functions";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import type {ISpaceItemProps} from "../../modules/space/spaceeditor/ui/toolbar/SpaceItem";
import {SpaceItemActionButtonsV5} from "../toolbar/SpaceItemActionButtonsV5";
import TrashIcon from "../icons/trash.svg?react";
import InfoIcon from "../icons/circle-information.svg?react";
import UnlinkIcon from "../icons/unlink.svg?react";
import {PopupUtilsV5} from "../popup/PopupUtilsV5";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {WarningWindowV5} from "../popup/WarningWindowV5";
import {SpaceItemV5} from "./SpaceItemV5";
import {SpaceItemContainerStyled} from "./SpaceItemContainer.Style";

interface ISpaceItemContainerProps extends ISpaceItemProps {
	userCatalogPermission?: Permission;
	showInfoButton: boolean;
	showDeleteButton: boolean;
	queryString: string;
	className?: string;
	options?: IDropdownOption[];
	stlye?: React.CSSProperties;
	onBreakLinkClick?: () => void;
	onEditCatalogClick: (catalog: Catalog) => void;
	onDuplicateCatalogClick: (catalog: Catalog) => void;
	onCreateUnplottedXyiconClick: (catalog: Catalog) => void;
}

export const SpaceItemContainerV5 = (props: ISpaceItemContainerProps) => {
	const appState = useAppStore((state) => state.appState);
	const {
		item,
		options,
		queryString,
		onPointerDown,
		onPointerMove,
		onPointerUp,
		userCatalogPermission,
		onBreakLinkClick,
		showInfoButton,
		showDeleteButton,
	} = props;

	const userXyiconPermission = appState.actions.getModuleTypePermission(props.item.typeId, XyiconFeature.Xyicon);

	const onDuplicateCatalogClick = () => {
		const {onDuplicateCatalogClick, item} = props;

		onDuplicateCatalogClick?.(item as Catalog);
	};

	const onEditCatalogClick = () => {
		const {onEditCatalogClick, item} = props;

		onEditCatalogClick?.(item as Catalog);
	};

	const deleteItem = async () => {
		const count = appState.actions.getNumberOfModels([item]);
		const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(item.ownFeature, count);

		if (confirmed) {
			await appState.actions.deleteSpaceItem(item);
		}
	};

	const onDeleteCatalogClick = async () => {
		const {item} = props;

		const isSafeToDeleteResponse = await appState.app.transport.requestForOrganization({
			url: "xyiconcatalogs/issafetodelete",
			method: XHRLoader.METHOD_POST,
			params: {
				xyiconCatalogIDList: [item.id],
			},
		});

		if (isSafeToDeleteResponse.result?.inUseList.length === 1) {
			await WarningWindowV5.open("You can't delete this item, because it's already used as xyicons.");
		} else {
			await deleteItem();
		}
	};

	const onDetailsClick = () => {
		const {item} = props;

		if (item.ownFeature !== XyiconFeature.XyiconCatalog) {
			appState.app.onDetailsClick(item);
		}
	};

	const onCreateUnplottedXyiconClick = () => {
		props.onCreateUnplottedXyiconClick(props.item as Catalog);
	};

	function setFavorite(value: boolean) {
		return (props.item as Catalog).setFavorite(value);
	}

	const onFavoriteClick = () => setFavorite(true);
	const onUnFavoriteClick = () => setFavorite(false);

	const hasSpaceItemPermission = props.item.ownFeature === XyiconFeature.Boundary || userXyiconPermission > Permission.View;

	const isExternalXyicon = item.ownFeature === XyiconFeature.Xyicon && item.isExternal;
	const isUnplottedXyicon = item.ownFeature === XyiconFeature.Xyicon && item.isUnplotted;

	const getXyiconRelatedOptionList = () => {
		const options: IDropdownOption[] = [];

		if ((isExternalXyicon || isUnplottedXyicon) && onBreakLinkClick && onBreakLinkClick !== Functions.emptyFunction && hasSpaceItemPermission) {
			options.push({
				label: "Break Link",
				IconComponent: UnlinkIcon,
				onClick: onBreakLinkClick,
			});
		}

		if (showInfoButton && hasSpaceItemPermission) {
			options.push({
				label: "Details",
				IconComponent: InfoIcon,
				onClick: onDetailsClick,
			});
		}

		if (showDeleteButton && userXyiconPermission === Permission.Delete && hasSpaceItemPermission) {
			options.push({
				label: "Delete",
				IconComponent: TrashIcon,
				onClick: deleteItem,
			});
		}

		return options;
	};

	return (
		<Observer>
			{() => {
				const isFavorite = (props.item as Catalog).isFavorite;

				return (
					<SpaceItemContainerStyled
						$grababble={!isExternalXyicon && props.onPointerDown && props.onPointerDown !== Functions.emptyFunction && hasSpaceItemPermission}
						$noPermission={!hasSpaceItemPermission}
					>
						<SpaceItemV5
							item={item}
							queryString={queryString}
							onPointerDown={onPointerDown}
							onPointerMove={onPointerMove}
							onPointerUp={onPointerUp}
						/>
						<SpaceItemActionButtonsV5
							deleteItem={deleteItem}
							catalog={props.item ? (props.item as Catalog) : undefined}
							feature={props.item.ownFeature}
							isExternalXyicon={isExternalXyicon}
							isUnplottedXyicon={isUnplottedXyicon}
							isCrossPortfolioXyicon={false}
							isFavorite={isFavorite}
							itemTypeId={props.item.typeId}
							onBreakLinkClick={props.onBreakLinkClick}
							onCreateUnplottedXyiconClick={onCreateUnplottedXyiconClick}
							onDeleteCatalogClick={onDeleteCatalogClick}
							onDetailsClick={onDetailsClick}
							onDuplicateCatalogClick={onDuplicateCatalogClick}
							onFavoriteClick={onFavoriteClick}
							onUnFavoriteClick={onUnFavoriteClick}
							showDeleteButton={props.showDeleteButton}
							showInfoButton={props.showInfoButton}
							options={options}
						/>
					</SpaceItemContainerStyled>
				);
			}}
		</Observer>
	);
};

SpaceItemContainerV5.defaultProps = {
	showInfoButton: false,
	showDeleteButton: false,
	onEditCatalogClick: Functions.emptyFunction,
	onDuplicateCatalogClick: Functions.emptyFunction,
	onBreakLinkClick: Functions.emptyFunction,
	onCreateUnplottedXyiconClick: Functions.emptyFunction,
	onPointerDown: Functions.emptyFunction,
	onPointerMove: Functions.emptyFunction,
	onPointerUp: Functions.emptyFunction,
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IReactionDisposer} from "mobx";
import {observable, makeObservable, reaction} from "mobx";
import type {IModel} from "../../../../data/models/Model";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature} from "../../../../generated/api/base";
import {ToggleSwitchField} from "../../../widgets/button/switch/ToggleSwitchField";
import type {IFilterRow, IFilterState} from "../../../../data/models/filter/Filter";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {Button} from "../../../widgets/button/Button";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {SearchField} from "../../../widgets/input/search/SearchField";
import {SaveToViewButton} from "../common/savetoview/SaveToViewButton";
import {MultiActionButton} from "../../../widgets/button/multiaction/MultiActionButton";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import {AdvancedFilterEditor} from "./advanced/AdvancedFilterEditor";
import {SimpleFilterEditor} from "./simple/SimpleFilterEditor";

interface IFilterEditorProps {
	features: XyiconFeature[];
	feature: XyiconFeature;
	items: IModel[];
	autoUpdate?: boolean;
	loader?: boolean;
	onSelect: (item: IModel[], disableTabChange?: boolean) => void;

	appState?: AppState;
	transport?: TransportLayer;
}

interface IFilterEditorState {
	applyingFilters: boolean;
	search: string;
}

@inject("appState")
@inject("transport")
@observer
export class FilterEditor extends React.Component<IFilterEditorProps, IFilterEditorState> {
	private readonly _ref = React.createRef<AdvancedFilterEditor & SimpleFilterEditor>();
	private _isMounted: boolean = false;
	private _reactionDisposer: IReactionDisposer | null = null;

	// These contain props.filters + potentially local filters that are not saved to view yet
	@observable
	private _localFilters: {
		simple: IFilterRow[];
		advanced: IFilterRow[];
	};

	constructor(props: IFilterEditorProps) {
		super(props);
		makeObservable(this);
		this.state = {
			applyingFilters: false,
			search: "",
		};
		const selectedView = this.selectedView;

		this._localFilters = {
			simple: ObjectUtils.deepClone(selectedView.filters.filters.simple),
			advanced: ObjectUtils.deepClone(selectedView.filters.filters.advanced),
		};
	}

	public reset = (viewFilters?: IFilterState) => {
		const selectedView = this.selectedView;
		const savedFilters = selectedView.getSavedFilters();

		viewFilters = viewFilters || savedFilters; // Note: could unify / simplify this

		this._localFilters.simple = ObjectUtils.deepClone(viewFilters.filters.simple);
		this._localFilters.advanced = ObjectUtils.deepClone(viewFilters.filters.advanced);

		const filters = selectedView.filters.type === "simple" ? this._localFilters.simple : this._localFilters.advanced;

		// If you click the toggle for advanced filtering, it's possible
		// that getFilterEditor will give you the "SimpleFilterEditor" (and vica versa),
		// while the data above already suggests it should be the advanced one

		const activeFilterEditor = this.getFilterEditor();

		if (activeFilterEditor?.type === selectedView.filters.type) {
			activeFilterEditor.resetTo(filters);
		}
	};

	public isResetButtonEnabled() {
		const selectedView = this.selectedView;

		const savedFilters = selectedView.getSavedFilters();

		const isSimple = selectedView.filters.type === "simple";
		const savedViewFilters = isSimple ? savedFilters.filters.simple : savedFilters.filters.advanced;
		const localFilters = isSimple ? this._localFilters.simple : this._localFilters.advanced;

		return JSON.stringify(localFilters) !== JSON.stringify(savedViewFilters);
	}

	private syncFilters = () => {
		if (this._isMounted) {
			this.turnOffViewWatcher();
			this.getFilterEditor()?.applyAll();
			this.turnOnViewWatcher();
			if (this.state.applyingFilters) {
				this.setState({
					applyingFilters: false,
				});
			}
		}
	};

	private syncLocalFilters = (filters: IFilterRow[]) => {
		this._localFilters.advanced = filters;
	};

	public applyAll = () => {
		const {onSelect, feature, appState} = this.props;

		if (this._isMounted) {
			this.setState({
				applyingFilters: true,
			});
		}

		// timeout needed otherwise the loader will not be shown as the UI freezes
		setTimeout(() => {
			this.syncFilters();
		}, 100);

		appState.tableComponent.current?.onPageChange(0);
		if (feature !== XyiconFeature.Portfolio) {
			onSelect([]);
		}
	};

	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	private getFilterEditor() {
		return this._ref.current as AdvancedFilterEditor | SimpleFilterEditor;
	}

	private onChangeFilterType = (value: boolean) => {
		const newType = value ? "advanced" : "simple";
		const selectedView = this.selectedView;

		// Save props.filters to localFilters when switching, so the up to date values will be
		// passed to localFilters
		if (newType === "simple") {
			this._localFilters.simple = ObjectUtils.deepClone(selectedView.filters.filters.simple);
		} else {
			this._localFilters.advanced = ObjectUtils.deepClone(selectedView.filters.filters.advanced);
		}

		selectedView.filters.type = newType;
	};

	private onResetClick = () => {
		this.reset();
	};

	private onClearAllClick = () => {
		this.getFilterEditor()?.clearAll();
	};

	private onResetSearch = () => {
		this.onSearchInput("");
	};

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private renderResetButton(clearDisabled: boolean) {
		const resetEnabled = this.isResetButtonEnabled();
		const clearEnabled = !clearDisabled;

		if (resetEnabled && clearEnabled) {
			return (
				<MultiActionButton
					className="secondary"
					icon="reset"
					disabled={!this.isResetButtonEnabled()}
					options={[
						{
							id: "reset",
							label: "Reset",
							onClick: this.onResetClick,
						},
						{
							id: "clearAll",
							label: "Clear All",
							onClick: this.onClearAllClick,
							disabled: clearDisabled,
						},
						// ...(
						// 	canCreateNew ?
						// 		[
						// 			{
						// 				id: "saveAsNew",
						// 				label: "Save as New View",
						// 				onClick: this.onSaveAsNewViewClick
						// 			}
						// 		]
						// 		:
						// 		[]
						// ),
					]}
				/>
			);
		}

		if (resetEnabled && !clearEnabled) {
			return (
				<Button
					icon="reset"
					className="secondary"
					label="Reset"
					onClick={this.onResetClick}
				/>
			);
		}

		if (!resetEnabled && clearEnabled) {
			return (
				<Button
					icon="close"
					className="secondary"
					label="Clear All"
					onClick={this.onClearAllClick}
				/>
			);
		}

		return (
			<Button
				icon="reset"
				className="secondary"
				disabled={true}
				label="Reset"
			/>
		);
	}

	private getStringifiedFiltersForSelectedView = () => {
		return JSON.stringify(this.selectedView.filters);
	};

	private onViewFiltersChanged = () => {
		this.reset(this.selectedView.filters);
	};

	private turnOnViewWatcher() {
		this._reactionDisposer = reaction(this.getStringifiedFiltersForSelectedView, this.onViewFiltersChanged);
	}

	private turnOffViewWatcher() {
		this._reactionDisposer?.();
	}

	private fieldFilter = (field: IFieldAdapter) => {
		const refId = field.refId;

		if (
			refId.includes("versionName") ||
			refId.includes("issuanceDate") ||
			refId === `${XyiconFeature.Xyicon}/icon` ||
			refId === `${XyiconFeature.XyiconCatalog}/icon`
		) {
			return false;
		}
		return true;
	};

	public override componentDidMount() {
		this._isMounted = true;

		this.turnOnViewWatcher();
	}

	public override componentWillUnmount() {
		this.turnOffViewWatcher();

		this._isMounted = false;
	}

	public override render() {
		const {items, features, feature, autoUpdate} = this.props;
		const selectedView = this.selectedView;
		const filters = selectedView.filters;

		const appliedFilters = filters.type === "simple" ? filters.filters.simple : filters.filters.advanced;
		const localFilters = filters.type === "simple" ? this._localFilters.simple : this._localFilters.advanced;
		const clearDisabled = appliedFilters.length + localFilters.length === 0;

		return (
			<>
				{!autoUpdate && (
					<div className="topButtons hbox">
						<SaveToViewButton
							feature={feature}
							viewChangeType="filters"
						/>
						<div className="flex_1" />
						{this.renderResetButton(clearDisabled)}
						{/*<Button*/}
						{/*	label="Reset"*/}
						{/*	disabled={!this.isResetButtonEnabled()}*/}
						{/*	onClick={this.onResetClick}*/}
						{/*	icon="reset"*/}
						{/*/>*/}
						<Button
							label="Apply"
							className="primary"
							disabled={JSON.stringify(appliedFilters) === JSON.stringify(localFilters)}
							onClick={this.applyAll}
							loading={this.state.applyingFilters}
							icon="apply"
						/>
					</div>
				)}
				<div className="FilterEditor filter-editor">
					<div className="heading hbox">
						<SearchField
							value={this.state.search}
							onInput={this.onSearchInput}
						/>
						<ToggleSwitchField
							label="Advanced Filtering"
							value={filters.type === "advanced"}
							onChange={this.onChangeFilterType}
						/>
					</div>
					{filters.type === "advanced" ? (
						<AdvancedFilterEditor
							ref={this._ref}
							features={features}
							filters={appliedFilters}
							localFilters={localFilters}
							items={items}
							fieldFilter={this.fieldFilter}
							syncFilters={this.syncFilters}
							syncLocalFilters={this.syncLocalFilters}
						/>
					) : (
						<SimpleFilterEditor
							ref={this._ref}
							features={features}
							feature={feature}
							filters={appliedFilters}
							localFilters={localFilters}
							items={items}
							loader={this.props.loader}
							search={this.state.search}
							resetSearch={this.onResetSearch}
						/>
					)}
				</div>
			</>
		);
	}
}

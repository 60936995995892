import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useAppStore} from "../../../../StateManager";
import {Functions} from "../../../../utils/function/Functions";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {XyiconFeature} from "../../../../generated/api/base";
import type {Space} from "../../../../data/models/Space";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {ReactUtils} from "../../../utils/ReactUtils";
import type {IDropdownOption} from "../../interaction/DropdownOptionsV5";
import {DropdownButtonV5} from "../../interaction/DropdownButtonV5";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import DotsHorizontalIcon from "../../icons/dots-horizontal.svg?react";
import type {IOnePagePDFDoc} from "./CreateSpacePanelV5";

type PDFListItemMode = "EditSpaceNames" | "SetScaleOrAlign" | "Confirm";

interface IPDFListItemProps {
	mode: PDFListItemMode;
}

const PdfListContainerStyled = styled.div<IPDFListItemProps>`
	min-height: 65px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	white-space: nowrap;
	border-bottom: 2px solid #c8c8c8;

	${(props) => props.mode === "SetScaleOrAlign" && "border-bottom: none;"}

	&:last-child {
		border-bottom: none;
	}

	&.confirmed {
		background-color: rgba(98, 183, 107, 0.2);
		border-bottom: none;
	}

	&.active {
		border: 2px solid #1e88e5;
	}
	> .hbox:first-of-type {
		.vbox {
			overflow: hidden;
			margin-right: 10px;
			justify-content: center;
		}

		&:not(:last-child) {
			margin-bottom: 5px 0;
		}

		> .vbox {
			overflow-x: hidden;
		}

		.delete,
		.OptionsButton {
			margin-left: auto;
			margin-right: 10px;
		}

		.OptionsButton {
			display: flex;
			position: relative;
			justify-content: flex-end;
		}
	}

	svg {
		cursor: pointer;
		width: 24px;
		height: 24px;
		margin: 4px;
	}
`;

const SpaceItemBackground = styled.div`
	width: 80px;
	height: 60px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
`;

interface IPDFListItemV5Props {
	selectedVersion: SpaceVersion;
	onePagePDFDoc: IOnePagePDFDoc;
	mode: PDFListItemMode;
	active: boolean;
	originalPDFIndex: number;
	originalPageNumber: number;
	originalPDFFileName: string;
	forceUpdate: () => void;
	onSpaceNameChange: (originalPDFIndex: number, originalPageNumber: number, newName: string) => void;
	isSpaceNameAvailable: (spaceName: string) => boolean;
	onRotate: (originalPDFIndex: number, originalPageNumber: number, deltaAngle: number) => void;
	onDeleteClick: (originalPDFIndex: number, originalPageNumber: number) => void;
	onSelect: (originalPDFIndex: number, originalPageNumber: number) => void;
}

export const PDFListItemV5 = (props: IPDFListItemV5Props) => {
	const {isSpaceNameAvailable} = props;
	const appState = useAppStore((state) => state.appState);
	const [thumbnailURL, setThumbnailURL] = useState<string>("");
	const [isWaitingOnThumbnail, setIsWaitingOnThumbnail] = useState<boolean>(true);
	const [newName, setNewName] = useState<string>("");
	const isMountedRef = useRef(true);

	let _originalRotation: number;
	let _rotation: number;

	const updateThumbnail = async () => {
		setIsWaitingOnThumbnail(true);
		props.forceUpdate();

		_originalRotation = props.onePagePDFDoc.originalRotation;
		const pdf = props.onePagePDFDoc.doc;
		const firstPage = pdf.getPages()[0];

		_rotation = MathUtils.clampDegreeBetween0And360(firstPage.getRotation().angle);

		const deltaAngle = MathUtils.clampDegreeBetween0And360(_rotation - _originalRotation);

		const thumbnailObject = await appState.app.graphicalTools.pdfRenderer.getThumbnailAndViewBox(
			400,
			props.onePagePDFDoc.doc,
			`${props.originalPDFFileName}_${props.originalPageNumber}`,
			deltaAngle,
		);

		if (!props.onePagePDFDoc.thumbnail || !props.onePagePDFDoc.insertionInfo) {
			if (!props.onePagePDFDoc.thumbnail) {
				props.onePagePDFDoc.thumbnail = thumbnailObject.url;
			}
			if (!props.onePagePDFDoc.insertionInfo) {
				props.onePagePDFDoc.insertionInfo = thumbnailObject.insertionInfo;
			}
			props.forceUpdate();
		}

		setThumbnailURL(thumbnailObject.url);
		setIsWaitingOnThumbnail(false);
	};

	if (!thumbnailURL && !isWaitingOnThumbnail) {
		updateThumbnail();
	}

	const onSpaceNameChange = (newName: string) => {
		if (newName !== props.onePagePDFDoc.spaceName) {
			if (newName && isSpaceNameAvailable(newName)) {
				props.onSpaceNameChange(props.originalPDFIndex, props.originalPageNumber, newName);
			}
		}
	};

	const onClick = () => {
		if (props.onSelect) {
			props.onSelect(props.originalPDFIndex, props.originalPageNumber);
		}
	};

	const onRotateCWClick = () => {
		props.onRotate(props.originalPDFIndex, props.originalPageNumber, 90);
		updateThumbnail();
	};

	const onRotateCCWClick = () => {
		props.onRotate(props.originalPDFIndex, props.originalPageNumber, -90);
		updateThumbnail();
	};

	const getVersionHistory = () => {
		const spaces = appState.actions.getList(XyiconFeature.Space) as Space[];
		const spaceWithTheSameName = spaces.find((space: Space) => space.name === props.onePagePDFDoc.spaceName);

		const spaceVersions = [props.selectedVersion];

		if (spaceWithTheSameName) {
			const relatedSpaceVersions = spaceWithTheSameName.versions;

			props.onePagePDFDoc.spaceId = spaceWithTheSameName.id;

			for (const spaceVersion of relatedSpaceVersions) {
				if (!spaceVersions.includes(spaceVersion)) {
					spaceVersions.push(spaceVersion);
				}
			}
		} else {
			props.onePagePDFDoc.spaceId = null;
		}

		return spaceVersions.map((spaceVersion: SpaceVersion, index: number) => {
			return (
				<div
					className="vbox"
					key={index}
				>
					<div style={{fontWeight: 400, fontSize: "16px", color: "#363636"}}>{spaceVersion.name}</div>
					<div style={{fontSize: "16px", color: "#7B7B7B"}}>{DateFormatter.format(spaceVersion.date.toString())}</div>
				</div>
			);
		});
	};

	const options: IDropdownOption[] = [
		{
			label: "Rotate 90° CW",
			onClick: onRotateCWClick,
		},
		{
			label: "Rotate 90° CCW",
			onClick: onRotateCCWClick,
		},
		{
			label: "Delete",
			onClick: () => {
				props.onDeleteClick(props.originalPDFIndex, props.originalPageNumber);
			},
		},
	];

	useEffect(() => {
		isMountedRef.current = true;
		updateThumbnail();

		return () => {
			isMountedRef.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isScaleSet = props.mode === "SetScaleOrAlign" && MathUtils.isValidNumber(props.onePagePDFDoc.spaceUnitsPerMeter);
	const isAlignConfirmed = props.mode === "SetScaleOrAlign" && props.onePagePDFDoc.isAlignmentConfirmed;

	return (
		<PdfListContainerStyled
			className={ReactUtils.cls("pdfContainer hbox bordered", {
				disabled: isWaitingOnThumbnail,
				active: props.active,
				confirmed: isScaleSet || isAlignConfirmed,
			})}
			onClick={onClick}
			mode={props.mode}
		>
			<div className="hbox flex1">
				<SpaceItemBackground
					style={{backgroundImage: `url("${thumbnailURL}")`}}
					className="thumbnail"
				/>
				<div className="vbox justifyCenter">
					{props.mode === "EditSpaceNames" ? (
						<>
							<div style={{fontWeight: 400, fontSize: "16px", color: "#363636"}}>{`Page ${props.onePagePDFDoc.originalPageNumber + 1}`}</div>
							<div style={{fontSize: "12px", color: "#7B7B7B"}}>{props.originalPDFFileName}</div>
						</>
					) : (
						<>
							<div style={{fontWeight: 400, fontSize: "16px", color: "#363636"}}>{props.onePagePDFDoc.spaceName}</div>
							{(isScaleSet || isAlignConfirmed) && <div style={{fontSize: "12px"}}>{isScaleSet ? "Scale Set ✓" : "Aligned ✓"}</div>}
						</>
					)}
				</div>
			</div>
			{props.mode === "EditSpaceNames" && (
				<>
					<ClickToEditInputV5
						value={props.onePagePDFDoc.spaceName}
						className="flexCenter flex1 spaceName center"
						onChange={(newName) => setNewName(newName)}
						onLiveChange={(newName) => setNewName(newName)}
						onBlur={() => onSpaceNameChange(newName)}
						valueValidator={isSpaceNameAvailable}
					/>
					<div style={{fontWeight: 400, margin: "0 10px 0 30px", fontSize: "16px", width: "160px"}}>{getVersionHistory()}</div>
					<DropdownButtonV5
						button={
							<IconButtonV5
								IconComponent={DotsHorizontalIcon}
								onClick={Functions.emptyFunction}
							/>
						}
						options={[
							{
								label: "Rotate 90° CW",
								onClick: () => {
									Functions.emptyFunction;
								},
							},
							{
								label: "Rotate 90° CCW",
								onClick: () => {
									Functions.emptyFunction;
								},
							},
							{
								label: "Delete",
								onClick: () => {
									{
										Functions.emptyFunction;
									}
								},
							},
						]}
					/>
				</>
			)}
		</PdfListContainerStyled>
	);
};

import type {Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {TextGroupManager} from "./TextGroupManager";

export class MarkupTextManager extends TextGroupManager {
	protected _instancedMeshName: string = "markupTexts";

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, true);
		this._spaceViewRenderer.signals.onBeforeRender.add(this.updateIfNeeded);
	}

	protected get _objectsWithTexts() {
		return [...(this._spaceViewRenderer.markupManager.items.array as Markup3D[]), ...this._spaceViewRenderer.ghostModeManager.ghostMarkups].filter(
			(markup: Markup3D) => !!markup.textContent && !markup.isTextInHtmlEditMode,
		);
	}

	private updateIfNeeded = () => {
		if (this.updateOnNextFrame) {
			this.updateTextTransformations();
		}
	};

	public dispose() {
		this._spaceViewRenderer.signals.onBeforeRender.remove(this.updateIfNeeded);
	}
}

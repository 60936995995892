import type {FunctionComponent, Key, MouseEvent, ReactNode, SVGProps} from "react";
import styled from "styled-components";
import type {IDropdownOption, ISubOptionWrapper} from "../interaction/DropdownOptionsV5";
import {VerticalFlexStyle} from "../styles/styles";
import type {NavigationEnum} from "../../../Enums";

export type TMenuV5 = {
	readonly label: string;
	readonly nav?: NavigationEnum;
	readonly icon: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly onlyIcon?: boolean;
	readonly small?: boolean;
	readonly isActive?: boolean;
	readonly key?: Key;
	readonly onClick?: () => void;
	readonly onPlusClick?: (event: MouseEvent) => void;
	readonly dropdownOptions?: (IDropdownOption | ISubOptionWrapper)[];
	readonly floatingMenu?: ReactNode;
	readonly closeFloatingMenu?: () => void; // should be provided if floatingMenu is provided
};

export const NavMenuStyled = styled.div`
	${VerticalFlexStyle}
	gap: 8px;
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import {SelectInput} from "../select/SelectInput";
import type {Type} from "../../../../data/models/Type";
import {ConfirmWindow} from "../../../modules/abstract/popups/ConfirmWindow";
import type {AppState} from "../../../../data/state/AppState";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {IModel} from "../../../../data/models/Model";
import type {Portfolio} from "../../../../data/models/Portfolio";
import type {Space} from "../../../../data/models/Space";
import type {Boundary} from "../../../../data/models/Boundary";
import {XyiconModelField} from "../../../modules/abstract/sidepanel/tabs/details/default/XyiconModelField";
import type {Xyicon} from "../../../../data/models/Xyicon";
import {XyiconFeature} from "../../../../generated/api/base";
import type {Catalog} from "../../../../data/models/Catalog";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";

interface ITypeInputProps {
	item?: IModel;
	selectedItems?: IModel[];
	appState?: AppState;
	transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class TypeInput extends React.Component<ITypeInputProps> {
	private onTypeChange = async (type: Type) => {
		const {item, selectedItems, transport} = this.props;
		const title = `Confirm ${featureTitles[item.ownFeature]} Type Change`;
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};
		let xyicons: Xyicon[] = [];
		let message = `Once you change the ${featureTitles[item.ownFeature].toLowerCase()} type, fields (and data) not assigned to the new ${featureTitles[item.ownFeature].toLowerCase()} type will be removed. Do you wish to continue?`;

		if (item.ownFeature === XyiconFeature.XyiconCatalog) {
			const affectedModels = selectedItems.filter((item) => item.typeId !== type.id).map((item) => (item as Catalog).model);

			xyicons = this.props.appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((xyicon) => affectedModels.includes(xyicon.model));
			message = `Once you change the catalog type, fields (and data) not assigned to the new catalog type will be removed. There are ${xyicons.length} xyicons that will be updated with the new catalog type and assigned fields.  Do you wish to continue?`;
		}

		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed && selectedItems) {
			// in case of SpaceEditor table boundary type change (selectedItems is empty) or if some items are selected and other element's type is changed
			const itemsToCheck = selectedItems.length > 0 && selectedItems.includes(item) ? selectedItems : [item];

			itemsToCheck.forEach((element) => {
				if (element.ownFeature === XyiconFeature.Space) {
					(element as Space).setSpaceType(type.id);
				} else if ([XyiconFeature.Portfolio, XyiconFeature.Boundary, XyiconFeature.XyiconCatalog].includes(element.ownFeature)) {
					element.typeId = type.id;
				}
			});

			if (item.ownFeature === XyiconFeature.Space) {
				await transport.updateSpaceType(itemsToCheck as Space[]);
			} else if (item.ownFeature === XyiconFeature.Portfolio) {
				await transport.updatePortfolioType(itemsToCheck as Portfolio[]);
			} else if (item.ownFeature === XyiconFeature.Boundary) {
				await transport.updateBoundaryType(itemsToCheck as Boundary[]);
			} else if (item.ownFeature === XyiconFeature.XyiconCatalog) {
				const result = await transport.updateCatalogType(itemsToCheck as Catalog[]);

				if (result) {
					xyicons.forEach((xyicon) => (xyicon.typeId = type.id));
				}
			}
		}
	};

	public override render() {
		const {item, selectedItems, appState} = this.props;

		const feature = item.ownFeature === XyiconFeature.XyiconCatalog ? XyiconFeature.Xyicon : item.ownFeature;

		return item.ownFeature !== XyiconFeature.Xyicon ? (
			<SelectInput
				options={this.props.appState.types[feature]}
				render={(item: IModel) => item.name}
				selected={appState.actions.getTypeById(item.typeId)}
				searchBar={true}
				onChange={this.onTypeChange}
				inline={true}
				horizontalAlignment={HorizontalAlignment.center}
			/>
		) : (
			<XyiconModelField
				disabled={false}
				xyicons={(selectedItems as Xyicon[]) ?? []}
				item={item as Xyicon}
				inline={true}
			/>
		);
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {GridView} from "../../../grid/GridView";
import type {IModel} from "../../../../../../data/models/Model";
import type {View} from "../../../../../../data/models/View";
import type {XyiconFeature} from "../../../../../../generated/api/base";
import type {AppState} from "../../../../../../data/state/AppState";
import {Debouncer} from "../../../../../../utils/function/Debouncer";
import {UnsavedLayout} from "../../../view/UnsavedLayout";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import {Button} from "../../../../../widgets/button/Button";

interface IGridTabProps<T> {
	feature: XyiconFeature;
	items: T[];
	view: View;
	onClick: (item: T) => void;
	selected: T[];
	onManageColumns: () => void;
	appState?: AppState;
	onSelect: (item: T[]) => void;
}

interface IGridTabSettings {
	findString: string;
	searching: boolean;
}

@inject("appState")
export class GridTab<T extends IModel> extends React.Component<IGridTabProps<T>, IGridTabSettings> {
	private readonly _listDebouncer = new Debouncer(1000);
	private readonly _typingDebouncer = new Debouncer(1000);

	private _lastResult = {
		findString: "",
		result: [] as IModel[],
	};

	constructor(props: IGridTabProps<T>) {
		super(props);

		this.state = {
			findString: "",
			searching: false,
		};
	}

	public override componentDidMount() {
		this._lastResult = {
			findString: "",
			result: [] as any[],
		};
	}

	private onFindChange = (findString: string) => {
		const list = this.props.items;

		if (list.length > Debouncer.limit) {
			// search would be too slow to trigger for each keystroke if the list is long -> add debouncer
			// First wait until user stopped typing
			this._typingDebouncer.debounce(() => {
				// User stopped typing:
				if (this.props.appState.actions.areModelsCached()) {
					// models cached, search is fast -> no need to show loader
					this.setState({
						findString: findString,
						searching: false,
					});
				} else {
					// show searching is in progress
					this.setState({searching: true});

					// Wait one more second before search starts to allow the search spinner to be rendered
					// (UI gets frozen during search so it helps the user to see a spinner)
					this._listDebouncer.debounceList(() => {
						// spinner is now visible, now start the search by setting the state
						this.setState({
							findString: findString,
							searching: false,
						});
					}, list);
				}
			});
		} else {
			this.setState({
				findString: findString,
				searching: false,
			});
		}
	};

	private getSearchedItems() {
		if (this.state.searching) {
			return [];
		}

		const {items, feature, appState} = this.props;
		const {findString} = this.state;

		if (findString && this._lastResult.findString === findString) {
			// Don't search again if findString didn't change
			return this._lastResult.result;
		}

		return appState.actions.searchModelsCached(items, findString, feature);
	}

	public override render() {
		const {selected, feature, view, onManageColumns, onClick, onSelect} = this.props;
		const {findString, searching} = this.state;

		const items = this.getSearchedItems();

		return (
			<div className="GridTab vbox flex_1">
				<div className="header hbox alignCenter topButtons">
					<div>
						<UnsavedLayout
							feature={feature}
							selectedView={view}
							label={false}
						/>
					</div>
					<SearchField
						value={this.state.findString}
						onInput={this.onFindChange}
					/>
					<Button
						icon="manage-columns"
						label="Manage Columns"
						className="large gray"
						onClick={onManageColumns}
					/>
				</div>
				<GridView
					feature={feature}
					items={items}
					selected={selected}
					view={view}
					onSelect={onSelect}
					onClick={onClick}
					checkboxColumn={false}
					search={findString}
					searching={searching}
					onManageColumns={onManageColumns}
					isCellContentsWrappingOn={true}
				/>
			</div>
		);
	}
}

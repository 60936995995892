import {ListItemStyled, ListStyled, NewFeaturesStyled, TitleStyled} from "./NewFeatures";

export const Help = () => {
	const featuresList = [
		{text: "FAQ", destination: ""},
		{text: "Helpful Articles", destination: ""},
		{text: "Chat with Support", destination: ""},
		{text: "Keyboard Shortcuts", destination: ""},
		{text: "Report a Problem", destination: ""},
		{text: "Request a Feature", destination: ""},
	];

	return (
		<NewFeaturesStyled>
			<TitleStyled>Need Help?</TitleStyled>

			<ListStyled>
				{featuresList.map((item, index) => (
					<div key={index}>
						<ListItemStyled>
							<a href={item.destination}>{item.text}</a>
						</ListItemStyled>
					</div>
				))}
			</ListStyled>
		</NewFeaturesStyled>
	);
};

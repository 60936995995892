import {useState} from "react";
import type {IToolConfig, SelectionToolType, SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Color} from "../../../../../generated/api/base";
import {MarkupManager} from "../../../../modules/space/spaceeditor/logic3d/managers/spaceitems/MarkupManager";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {colorPalette} from "../../../styles/colorPalette";
import type {IToolButtonProps, TTitleAlignment} from "./ToolButtonV5";
import {ToolButtonV5} from "./ToolButtonV5";
import {ToolGroupV5} from "./ToolGroupV5";
import {SpaceToolBarStyled} from "./SpaceToolBarStyled";

const colorExceptions: SpaceTool[] = ["pan", "selection"];

interface IMarkupToolBarProps {
	active: boolean;
	selectionToolType: SelectionToolType;
	openDefaultToolBar: () => void;
	tools: {
		[id in SpaceTool]: IToolConfig;
	};
	activeToolId: SpaceTool;
	createToolButton: (
		id: SpaceTool,
		tooltipAlignment: TTitleAlignment,
		color?: Color,
	) => React.ReactElement<IToolButtonProps, string | React.JSXElementConstructor<any>>;
	onToolClick: (id: SpaceTool) => void;
	spaceViewRenderer: SpaceViewRenderer;
}

export const MarkupToolBarV5 = (props: IMarkupToolBarProps) => {
	const [activeColor, setActiveColor] = useState<Color>({
		hex: props.spaceViewRenderer.markupManager?.markupColor || MarkupManager.defaultMarkupColor,
	});

	const onColorChange = (newColor: Color) => {
		props.spaceViewRenderer.markupManager.setColor(newColor.hex);
		setActiveColor(newColor);
	};

	const {eyeDropperProps, markupManager} = props.spaceViewRenderer;
	const {createToolButton, openDefaultToolBar, onToolClick, activeToolId, tools, active} = props;

	return (
		<SpaceToolBarStyled
			className={ReactUtils.cls({SpaceToolBar: true, Markup: true, collapsed: !active})}
			$activeBackgroundColor={colorExceptions.includes(activeToolId) ? colorPalette.primary.c500Primary : `#${activeColor.hex}`}
		>
			<ToolButtonV5
				icon="back"
				isActive={false}
				title="Back"
				onClick={openDefaultToolBar}
			/>
			{createToolButton("selection", "right", null)}
			{createToolButton("pan", "right", null)}
			{createToolButton("markupCloud", "right", activeColor)}
			{createToolButton("markupCallout", "right", activeColor)}

			<ToolGroupV5
				activeTool={activeToolId}
				onClick={(id: SpaceTool) => onToolClick(id)}
				tools={tools}
				color={activeColor}
			>
				{createToolButton("markupArrow", "top", activeColor)}
				{createToolButton("markupBidirectionalArrow", "top", activeColor)}
				{createToolButton("markupLine", "top", activeColor)}
				{createToolButton("markupDashedLine", "top", activeColor)}
			</ToolGroupV5>

			<ToolGroupV5
				activeTool={activeToolId}
				onClick={(id: SpaceTool) => onToolClick(id)}
				tools={tools}
				color={activeColor}
			>
				{createToolButton("markupPencil", "top", activeColor)}
				{createToolButton("markupHighlight", "top", activeColor)}
			</ToolGroupV5>

			{createToolButton("markupText", "right", activeColor)}

			<ToolGroupV5
				activeTool={activeToolId}
				onClick={(id: SpaceTool) => onToolClick(id)}
				tools={tools}
				color={activeColor}
			>
				{createToolButton("markupRectangle", "top", activeColor)}
				{createToolButton("markupEllipse", "top", activeColor)}
				{createToolButton("markupTriangle", "top", activeColor)}
				{createToolButton("markupCross", "top", activeColor)}
			</ToolGroupV5>

			<ToolGroupV5
				activeTool={activeToolId}
				onClick={(id: SpaceTool) => onToolClick(id)}
				tools={tools}
				color={activeColor}
			>
				{createToolButton("measureLinearDistance", "top", activeColor)}
				{createToolButton("measureRectArea", "top", activeColor)}
				{createToolButton("measureNonLinearDistance", "top", activeColor)}
				{createToolButton("measureIrregularArea", "top", activeColor)}
			</ToolGroupV5>

			{/* {createToolButton("markupStamp", true)} */}
			{/* {createToolButton("markupPhoto", true)} */}

			<ColorSelectorV5
				title="Markup Color"
				color={{hex: markupManager?.markupColor || MarkupManager.defaultMarkupColor, transparency: 0}}
				onColorChange={onColorChange}
				isTransparencyEnabled={false}
				offsetX={10}
				eyeDropperProps={eyeDropperProps}
				horizontalAlignment={HorizontalAlignment.outerRight}
				verticalAlignment={VerticalAlignment.top}
				closeWindowOnSelect={true}
				tooltipAlignment="right"
			/>
		</SpaceToolBarStyled>
	);
};

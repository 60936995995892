import type {Color} from "../../../../generated/api/base";
import {ColorUtils} from "../../../../utils/ColorUtils";

export const catalogIconBGLightThreshold = 0.95;

const defaultIconColor: Color = {
	hex: "FFFFFF",
	transparency: 0,
};

export const getCatalogIconColor = (bgColor: Color): Color => {
	const hsl = ColorUtils.hex2hsl(bgColor.hex);

	const ret = {...defaultIconColor};

	ret.hex = hsl.l > catalogIconBGLightThreshold ? "000000" : "ffffff"; // ffffff is in case of the user goes to catalog creation step 3 with a type, then goes back and changes type

	return ret;
};

import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {PopupWindow} from "./PopupWindow";

/**
 * Can be used as an alternative for `alert("alertMessage");`
 */
export class WarningWindow extends PopupWindow<boolean | null> {
	protected static override readonly _defaultConfig = {
		ok: "OK",
		cancel: null as string,
		backdrop: true,
	};
	protected _okValue = true;
	protected _cancelValue = false;

	constructor(message: string, title: string, config: {ok?: string; backdrop?: boolean} = {}) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(WarningWindow._defaultConfig, config),
		});
	}

	public static open(message: string, title: string = "Warning", config?: {ok: string; backdrop: boolean}) {
		return new WarningWindow(message, title, config).open();
	}
}

import type {BufferGeometry} from "three";
import {InstancedBufferGeometry, InstancedMesh, Object3D} from "three";
import type {DoubleColorIconMaterial} from "../../../materials/DoubleColorIconMaterial";
import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import type {IObjectWithRotationHandler} from "./RotationIconTypes";

export abstract class InstancedIconManager {
	protected _spaceViewRenderer: SpaceViewRenderer;
	protected _geometry: BufferGeometry;
	protected readonly _name: string;
	protected _defaultBackgroundColor: number[] = [1, 1, 1];
	protected readonly _iconColor: number[];
	protected _iconContainer: Object3D = new Object3D();
	protected _icons: InstancedMesh;
	protected _dummyObject: Object3D = new Object3D();

	constructor(spaceViewRenderer: SpaceViewRenderer, name: string, iconColor: number[] = [154 / 255, 154 / 255, 154 / 255]) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._geometry = this._spaceViewRenderer.planeGeometry;
		this._name = name;
		this._iconColor = iconColor;
		this._iconContainer.name = `${name}IconContainer`;
	}

	public init() {
		THREEUtils.add(this._spaceViewRenderer.xyiconScene, this._iconContainer);
	}

	protected updateTransformationsFromDummyObjects(getDummyObjectForMatrix: ((target: Object3D) => Object3D)[]) {
		for (let i = 0; i < getDummyObjectForMatrix.length; ++i) {
			getDummyObjectForMatrix[i](this._dummyObject);
			this._icons.setMatrixAt(i, this._dummyObject.matrix);
		}

		// https://discourse.threejs.org/t/raycast-fails-after-modifying-the-instance-matrices/53791
		this._icons.computeBoundingSphere();

		this._icons.instanceMatrix.needsUpdate = true;
		this._spaceViewRenderer.needsRender = true;
	}

	public removeIcons() {
		if (this._icons?.parent) {
			this._icons.parent.remove(this._icons);
			this._icons.geometry.dispose();
			this._icons.dispose();
			this._icons = null;
			this._spaceViewRenderer.needsRender = true;
		}
	}

	public abstract updateTransformations(): void;
	protected abstract prepareInstancedMesh(): {material: DoubleColorIconMaterial; objects: IObjectWithRotationHandler[]};
	protected abstract onInstancedMeshCreated(objects: IObjectWithRotationHandler[]): void;

	public update(params?: {material: DoubleColorIconMaterial; objects: IObjectWithRotationHandler[]}) {
		this.removeIcons();

		if (!params) {
			params = this.prepareInstancedMesh();
		}

		this.recreateInstancedMesh(params.material, params.objects);
	}

	protected initBufferAttributes(count: number) {
		const geometry = this._icons.geometry as InstancedBufferGeometry;
		const opacityTypedArray = new Float32Array(count);

		opacityTypedArray.fill(1);
		geometry.setAttribute("opacity", THREEUtils.createInstancedBufferAttribute(opacityTypedArray, 1));

		const arraySize = count * 3; // r, g, b
		const backgroundColorTypedArray = new Float32Array(arraySize);

		for (let i = 0; i < arraySize; i += 3) {
			backgroundColorTypedArray[i] = this._defaultBackgroundColor[0];
			backgroundColorTypedArray[i + 1] = this._defaultBackgroundColor[1];
			backgroundColorTypedArray[i + 2] = this._defaultBackgroundColor[2];
		}
		geometry.setAttribute("backgroundColor", THREEUtils.createInstancedBufferAttribute(backgroundColorTypedArray, 3));

		const iconColorTypedArray = new Float32Array(arraySize);

		for (let i = 0; i < arraySize; i += 3) {
			iconColorTypedArray[i] = this._iconColor[0];
			iconColorTypedArray[i + 1] = this._iconColor[1];
			iconColorTypedArray[i + 2] = this._iconColor[2];
		}
		geometry.setAttribute("iconColor", THREEUtils.createInstancedBufferAttribute(iconColorTypedArray, 3));
	}

	public setIconColor(index: number, rgb: [number, number, number]) {
		const geometry = this._icons.geometry as InstancedBufferGeometry;

		THREEUtils.setAttributeXYZ(geometry.attributes.iconColor, index, rgb[0], rgb[1], rgb[2]);
	}

	protected recreateInstancedMesh(material: DoubleColorIconMaterial, objects: IObjectWithRotationHandler[]) {
		if (objects.length > 0) {
			this._icons = new InstancedMesh(new InstancedBufferGeometry().copy(this._geometry as InstancedBufferGeometry), material, objects.length);
			this._icons.name = `${this._name}Icon`;
			this.initBufferAttributes(objects.length);

			this.onInstancedMeshCreated(objects);
			this.updateTransformations();

			THREEUtils.add(this._iconContainer, this._icons);
		}
	}

	public clear() {
		this.removeIcons();
		THREEUtils.disposeAndClearContainer(this._iconContainer);
	}

	public get icons() {
		return this._icons;
	}

	public get dummyObject() {
		return this._dummyObject;
	}
}

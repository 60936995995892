import type {IObservableArray} from "mobx";
import {ObjectUtils} from "../ObjectUtils";

export class ArrayUtils {
	public static move<T extends any>(array: T[], fromIndex: number, toIndex: number) {
		const element = array[fromIndex];

		array.splice(fromIndex, 1);
		array.splice(toIndex, 0, element);

		return array;
	}

	// Creates a new array which doesn't contain the same element twice
	// Note that it doesn't modify the original array
	// https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
	public static removeDuplicates<T>(array: T[]) {
		return [...new Set(array)];
	}

	public static addMutable<T = any>(array: T[], element: T) {
		if (!array.includes(element)) {
			array.push(element);
		}
	}

	public static removeMutable<T = any>(array: T[], element: T) {
		const index = array.indexOf(element);

		if (index !== -1) {
			array.splice(index, 1);
		}
	}

	public static add<T = any>(sourceArray: T[], element: T) {
		const clone = sourceArray.slice(0);

		clone.push(element);
		return clone;
	}

	public static remove<T = any>(sourceArray: T[], element: T) {
		const index = sourceArray.indexOf(element);

		return ArrayUtils.removeAtIndex(sourceArray, index);
	}

	public static removeAtIndex<T = any>(sourceArray: T[], index: number) {
		if (index === -1) {
			return sourceArray.slice(0);
		} else {
			return [...sourceArray.slice(0, index), ...sourceArray.slice(index + 1)];
		}
	}

	public static partition<T = any>(array: T[], filter: (a: T) => boolean) {
		const a: T[] = [];
		const b: T[] = [];

		for (const item of array) {
			if (filter(item)) {
				a.push(item);
			} else {
				b.push(item);
			}
		}
		return [a, b];
	}

	// Returns an array without duplicate elements
	public static uniq<T = any>(array: T[]) {
		const result: T[] = [];

		const added = new Set<T>();

		for (const item of array) {
			if (!added.has(item)) {
				added.add(item);
				result.push(item);
			}
		}
		return result;
	}

	public static uniqByKey<T = any>(array: T[], keyAccessor: (t: T) => string) {
		const keys = new Set<string>();

		return array.filter((item) => {
			const key = keyAccessor(item);

			if (!keys.has(key)) {
				keys.add(key);
				return true;
			}
			return false;
		});
	}

	public static keys2array(object: Object): string[] {
		return Object.keys(object);
	}

	public static values2array(object: any): string[] {
		const result = [];

		for (const key in object) {
			result.push(object[key]);
		}

		return result;
	}

	public static equals<T>(a: T[], b: T[]) {
		if (a === b) {
			return true;
		}

		if (a.length !== b.length) {
			return false;
		}

		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) {
				return false;
			}
		}

		return true;
	}

	public static copy<T>(destination: T[], source: T[]) {
		destination.length = source.length;
		for (let i = 0; i < source.length; ++i) {
			destination[i] = ObjectUtils.deepClone(source[i]);
		}
	}

	public static replaceObservable<T>(destination: T[], source: T[]) {
		const dest = destination as IObservableArray<T>;

		dest.replace(source);
	}
}

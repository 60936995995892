import {styled} from "styled-components";
import {FLEXCENTER, baseDistance, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {AvatarStyled} from "../user/Avatar.styled";

export const DropdownContentStyled = styled.div`
	top: 0;
	left: 0;
	visibility: hidden;
	display: block;
	position: absolute;
	color: #333;
	background-color: #ffffff;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: ${zIndex.popup};
	padding: 5px;
	border-radius: ${radius.md};
	cursor: pointer;
`;

export const ProfileItemStyled = styled.div`
	width: 256px;
	height: 40px;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 8px;
	gap: 8px;

	.arrow {
		box-sizing: border-box;
		width: ${baseDistance.sm};
		height: ${baseDistance.sm};
		margin-top: ${baseDistance.xs};
		background: transparent;
		border: solid 5px transparent;
		border-top-color: ${colorPalette.gray.c950};
		transition: ease-in-out transform 0.2s;
		transform-origin: 43% 20%;
		transform: rotate(-90deg);
		position: absolute;
		top: 13px;
		right: 18px;

		&.isOpen {
			transform: rotate(0deg);
		}
	}

	${AvatarStyled} {
		width: 24px;
		height: 24px;
		font-size: 12px;
	}

	.profileButton {
		margin: 0px 15px 5px 15px;
		left: 0;
		${FLEXCENTER}
		justify-content: left;
		font-size: 16px;
		font-weight: 400;
		min-height: 40px;

		.icon {
			margin-right: 15px;
			fill: black;
			width: 18px;
			height: 18px;
			stroke-width: 1px;
		}

		&:hover {
			color: ${colorPalette.primary.c500Primary};
		}
	}

	&.lined {
		.profileButton {
			margin-bottom: 0;
		}

		border-top: 1px solid ${colorPalette.gray.c300};
	}

	&:hover {
		background-color: ${colorPalette.primary.c200Light};
		border-radius: ${radius.sm};
	}
`;

import * as React from "react";
import {observer} from "mobx-react";
import type {DocumentModel} from "../../../../../../../../data/models/DocumentModel";
import type {IContextOption} from "../../../../../../../widgets/context/ContextOptions";
import type {DocumentSectionItem} from "../DocumentSection";
import {ReactUtils} from "../../../../../../../utils/ReactUtils";
import {LoaderIcon} from "../../../../../../../widgets/button/LoaderIcon";
import {DocumentCardItem} from "./DocumentCardItem";

interface IDocumentCardViewProps {
	documentItems: DocumentSectionItem[];
	uploadingArraySize: number;
	getOptions: (document: DocumentSectionItem) => IContextOption[];
	onPreviewClick: (document: DocumentModel) => void;
}

@observer
export class DocumentCardView extends React.Component<IDocumentCardViewProps> {
	public override render() {
		const {documentItems, getOptions, onPreviewClick, uploadingArraySize} = this.props;
		const isDocumentListEmpty = documentItems.length === 0;
		const loaderArray: React.JSX.Element[] = [];

		for (let i = 0; i < uploadingArraySize; i++) {
			loaderArray.push(
				<div
					key={i}
					className="loaderText vbox alignCenter justifyCenter"
				>
					<LoaderIcon />
					Uploading Document...
				</div>,
			);
		}

		return (
			<div className={ReactUtils.cls("DocumentCardView", {empty: isDocumentListEmpty})}>
				{isDocumentListEmpty && loaderArray.length === 0 ? (
					<div className="noDataSection">No Documents...</div>
				) : (
					documentItems.map((documentItem) => {
						const document = documentItem.document;

						return (
							<DocumentCardItem
								key={document.id}
								documentItem={documentItem}
								getOptions={getOptions}
								onPreviewClick={onPreviewClick}
							/>
						);
					})
				)}
				{loaderArray}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {computed, observable, runInAction} from "mobx";
import {Button} from "../../../widgets/button/Button";
import {Field} from "../../../widgets/form/field/Field";
import type {AppState} from "../../../../data/state/AppState";
import {TextInput} from "../../../widgets/input/text/TextInput";
import {Initials} from "../../../widgets/Initials";
import {ReactUtils} from "../../../utils/ReactUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {PasswordInput} from "../../../widgets/input/pssword/PasswordInput";
import {PasswordValidator} from "../../../widgets/password/PasswordValidator";
import {PassSecurityLevel, PasswordUtils} from "../../../widgets/password/PasswordUtils";
import {notify} from "../../../../utils/Notify";
import type {INotificationParams} from "../../../notification/Notification";
import {NotificationType} from "../../../notification/Notification";
import type {ChangePasswordRequest, UpdateUserProfileRequest, UserDto} from "../../../../generated/api/base";
import {DomPortal} from "../../abstract/portal/DomPortal";
import {SVGIcon} from "../../../widgets/button/SVGIcon";
import {InfoBubble} from "../../abstract/common/infobutton/InfoBubble";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";

interface IPersonalSettingsProps {
	appState?: AppState;
	transport?: TransportLayer;
}

interface IMyProfileState {
	isSidePanelOpen: boolean;
	currentPassword: string;
	newPasswordConfirm: string;
	newPassword: string;
	isUserDataSaving: boolean;
	isPasswordSaving: boolean;
	passwordToolTipTransform: TransformObj;
}

interface IChangeableUserData {
	userName: string;
	firstName: string;
	lastName: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class MyProfile extends React.Component<IPersonalSettingsProps, IMyProfileState> {
	private _isMounted: boolean = false;
	private _passwordParent = React.createRef<HTMLInputElement>();
	private _password = React.createRef<HTMLDivElement>();

	@observable
	private _savedUserData: string = "";

	private get _user() {
		return this.props.appState.user;
	}

	private get _transportLayer() {
		return this.props.transport;
	}

	constructor(props: IPersonalSettingsProps) {
		super(props);
		this.state = {
			isSidePanelOpen: false,
			currentPassword: "",
			newPassword: "",
			newPasswordConfirm: "",
			isUserDataSaving: false,
			isPasswordSaving: false,
			passwordToolTipTransform: null,
		};

		this.saveUserDataLocally();
	}

	private saveUserDataLocally() {
		const user = this._user;

		const currentUserData: IChangeableUserData = {
			userName: user.username,
			firstName: user.firstName,
			lastName: user.lastName,
		};

		this._savedUserData = JSON.stringify(currentUserData);
	}

	private revertUserDataToSavedValues = () => {
		const user = this._user;
		const savedData: IChangeableUserData = JSON.parse(this._savedUserData);

		runInAction(() => {
			user.username = savedData.userName;
			user.firstName = savedData.firstName;
			user.lastName = savedData.lastName;
		});
	};

	private onSave = async () => {
		const user = this._user;

		const params: UpdateUserProfileRequest = {
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			phoneNumber: user.phone,
			countryCode: user.countryCode,
			userGeneralSetting: {
				timeZone: "GMT+2",
				theme: this.props.appState.theme,
				language: "en",
			},
		};

		this.setState({
			isUserDataSaving: true,
		});
		const {result, error} = await this._transportLayer.request<UserDto>({
			url: "users/updateuserprofile",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		this.setState({
			isUserDataSaving: false,
		});

		const userNotificationParams: INotificationParams = {
			type: NotificationType.Success,
			title: "Change user data successful!",
			lifeTime: 10000,
			description: "Your user data is successfully changed",
		};

		if (error) {
			userNotificationParams.type = NotificationType.Error;
			userNotificationParams.title = "Change user data unsuccessful!";
			userNotificationParams.description = `Your user data has not been changed due to the following error: ${error.ErrorMessage}`;

			this.revertUserDataToSavedValues();
		} else {
			this.saveUserDataLocally();
		}

		notify(this.props.appState.app.notificationContainer, userNotificationParams);
	};

	private resetPasswordInputs() {
		this.setState({
			currentPassword: "",
			newPassword: "",
			newPasswordConfirm: "",
			isPasswordSaving: false,
		});
	}

	private onSavePasswordChange = async () => {
		if (this.state.newPassword === this.state.newPasswordConfirm) {
			const params: ChangePasswordRequest = {
				oldPassword: this.state.currentPassword,
				newPassword: this.state.newPassword,
			};

			this.setState({
				isPasswordSaving: true,
			});
			const {result, error} = await this._transportLayer.requestForOrganization<boolean>({
				url: "users/changepassword",
				method: XHRLoader.METHOD_POST,
				params: params,
			});

			const notificationParams: INotificationParams = {
				type: NotificationType.Error,
				title: "Change password unsuccessful!",
				lifeTime: 10000,
			};

			if (error) {
				if (this._isMounted) {
					this.resetPasswordInputs();
				}
				notificationParams.description = error.ErrorMessage;
			} else {
				window.setTimeout(() => {
					if (this._isMounted) {
						this.onCancelPasswordChange();
					}
				}, 2000);

				notificationParams.title = "Change password successful!";
				notificationParams.type = NotificationType.Success;
				notificationParams.description = "Your password is successfully changed.";
			}

			notify(this.props.appState.app.notificationContainer, notificationParams);
		}
	};

	private onCancelPasswordChange = () => {
		this.setState({isSidePanelOpen: false});
		this.resetPasswordInputs();
	};

	private onPasswordChange = () => {
		this.setState({isSidePanelOpen: true});
	};

	private handleCurrentPasswordInput = (value: string) => {
		this.setState({
			currentPassword: value,
		});
	};

	// Compared to what we have on the backend
	@computed
	private get isUserDataChangedLocally() {
		const user = this._user;
		const stringifiedUserData: IChangeableUserData = {
			userName: user.username,
			firstName: user.firstName,
			lastName: user.lastName,
		};

		const currentData = JSON.stringify(stringifiedUserData);

		return currentData !== this._savedUserData;
	}

	@computed
	private get isLocalUserDataValid() {
		const user = this._user;

		return !!user.username && !!user.firstName && !!user.lastName;
	}

	private get isPasswordSaveButtonEnabled() {
		const {currentPassword, newPassword, newPasswordConfirm} = this.state;

		return (
			!this.state.isPasswordSaving &&
			PasswordUtils.getPasswordSecurityLevel(newPassword) > PassSecurityLevel.RequirementsNotMet &&
			newPassword === newPasswordConfirm &&
			currentPassword &&
			newPassword &&
			newPasswordConfirm &&
			newPassword.length <= 64
		);
	}

	private get passwordConfirmError() {
		return this.state.newPasswordConfirm && this.state.newPasswordConfirm !== this.state.newPassword ? "Password do not match." : "";
	}

	private handleNewPasswordInput = (value: string) => {
		this.setState({
			newPassword: value,
		});
	};

	private handleNewPasswordConfirmInput = (value: string) => {
		this.setState({
			newPasswordConfirm: value,
		});
	};

	private onFirstNameChange = (newFirstName: string) => {
		this._user.firstName = newFirstName;
	};

	private onLastNameChange = (newLastName: string) => {
		this._user.lastName = newLastName;
	};

	private onUserNameChange = (newUserName: string) => {
		this._user.username = newUserName;
	};

	private renderPasswordPanel() {
		const {currentPassword, newPassword, newPasswordConfirm, isSidePanelOpen, passwordToolTipTransform} = this.state;
		const isPasswordSaveButtonEnabled = this.isPasswordSaveButtonEnabled;

		const passwordInlineStyle: React.CSSProperties = {
			top: "5px",
			left: "-5px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
			transform: passwordToolTipTransform?.translate,
		};

		return (
			<div className={ReactUtils.cls("simpleSidePanel", {open: isSidePanelOpen})}>
				<div className="heading hbox createBox">
					<h4>Change Your password</h4>
					<IconButton
						icon="close"
						onClick={this.onCancelPasswordChange}
					/>
				</div>
				<div className="buttons hbox">
					<Button
						label="Cancel"
						className="secondary"
						onClick={this.onCancelPasswordChange}
					/>
					<Button
						label={this.state.isPasswordSaving ? "Saving..." : "Save"}
						className="primary"
						disabled={!isPasswordSaveButtonEnabled}
						onClick={this.onSavePasswordChange}
					/>
				</div>
				<div className="password-container">
					<Field label="Current Password">
						<PasswordInput
							value={currentPassword}
							onInput={this.handleCurrentPasswordInput}
						/>
					</Field>
					<Field label="New Password">
						<PasswordInput
							value={newPassword}
							onInput={this.handleNewPasswordInput}
							infoText={
								newPassword && newPassword === currentPassword
									? "Your new password matches your old password. Saving it like this won't make any difference."
									: ""
							}
							isTooltip={true}
							isTextInfo={true}
						/>
					</Field>
					<Field label="Confirm Password">
						<PasswordInput
							value={newPasswordConfirm}
							onInput={this.handleNewPasswordConfirmInput}
							divRef={this._passwordParent}
						/>
						{this.passwordConfirmError && (
							<div className="passworderror">
								<DomPortal destination={this.props.appState.app.modalContainer}>
									<div
										ref={this._password}
										className={ReactUtils.cls("infoIcon editing errorInfo", {left: false})}
										style={passwordInlineStyle}
									>
										{<SVGIcon icon="info" />}
										<InfoBubble
											content={"Password do not match."}
											isErrorMessage={true}
											style={{width: "200px"}}
										/>
									</div>
								</DomPortal>
							</div>
						)}
					</Field>
					<PasswordValidator password={newPassword} />
				</div>
			</div>
		);
	}

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override componentDidUpdate = (prevProps: IPersonalSettingsProps, prevState: IMyProfileState) => {
		if (this.passwordConfirmError && !prevState.newPasswordConfirm) {
			this.setState({
				passwordToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._passwordParent.current,
					this._password.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.right,
				),
			});
		}
	};

	public override componentWillUnmount() {
		if (!this.state.isUserDataSaving) {
			this.revertUserDataToSavedValues();
		}

		this._isMounted = false;
	}

	public override render() {
		const user = this._user;

		if (!user) {
			return null;
		}

		return (
			<div className="MyProfile">
				<div className="vbox userdataContainer">
					<div className="buttons hbox">
						<Button
							label="Reset"
							className="secondary"
							disabled={this.state.isUserDataSaving || !this.isUserDataChangedLocally}
							onClick={this.revertUserDataToSavedValues}
						/>
						<Button
							label={this.state.isUserDataSaving ? "Saving..." : "Save"}
							disabled={this.state.isUserDataSaving || !this.isUserDataChangedLocally || !this.isLocalUserDataValid}
							className="primary"
							onClick={this.onSave}
						/>
					</div>
					<div className="avatarBox hbox justifyCenter alignCenter">
						<Initials
							name={`${user.firstName} ${user.lastName}`}
							color="1874CD"
						/>
					</div>
					<div className="fieldsContainer">
						<Field label="First name">
							<TextInput
								value={user.firstName}
								onInput={this.onFirstNameChange}
								errorMessage={!user.firstName ? "First name cannot be empty" : ""}
								isTextInfo={true}
							/>
						</Field>
						<Field label="Last name">
							<TextInput
								value={user.lastName}
								onInput={this.onLastNameChange}
								errorMessage={!user.lastName ? "Last name cannot be empty" : ""}
								isTextInfo={true}
							/>
						</Field>
						<Field label="Change Password">
							<Button
								className="secondary"
								label="Change Password"
								onClick={this.onPasswordChange}
							/>
						</Field>
						<Field label="Username">
							<TextInput
								value={user.username}
								onInput={this.onUserNameChange}
							/>
						</Field>
						<Field label="Email">
							<TextInput
								placeholder="Email"
								value={user.email}
								disabled={true}
							/>
						</Field>
					</div>
				</div>
				{this.renderPasswordPanel()}
			</div>
		);
	}
}

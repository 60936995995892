import * as React from "react";
import {inject, observer} from "mobx-react";
import {SettingTableTab} from "../abstract/SettingTableTab";
import {TabChild} from "../../../../widgets/tab/TabChild";
import {TabView} from "../../../../widgets/tab/TabView";
import type {Type} from "../../../../../data/models/Type";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import type {IHeader} from "../../../../widgets/table/SimpleTable";
import {CreateTypePanel} from "./CreateTypePanel";
import {TypeDetailsTab} from "./TypeDetailsTab";

interface ITypeSettingsProps {
	feature: XyiconFeature;
	typesFeature: XyiconFeature;
	transport?: TransportLayer;
	appState?: AppState;
}

interface ITypeSettingsState {
	columns: IHeader[];
}

@inject("appState")
@inject("transport")
@observer
export class TypeSettings extends React.Component<ITypeSettingsProps, ITypeSettingsState> {
	constructor(props: ITypeSettingsProps) {
		super(props);
		this.state = {
			columns: [
				{
					id: "name",
					title: "Name",
				},
				{
					id: "system",
					title: "System",
				},
				{
					id: "lastModifiedAt",
					title: "Last updated",
				},
			],
		};
	}

	private getFields = (type: Type) => {
		return this.state.columns.map((field) => {
			// TODO
			const id = field.id;

			switch (id) {
				case "system":
					return this.props.appState.actions.getBooleanFieldTitle(type.isSystem);
				case "lastModifiedAt":
					return DateFormatter.format(type.lastModifiedAt);
				default:
					return type[id as "name"];
			}
		});
	};

	public override render() {
		const {feature, typesFeature} = this.props;

		return (
			<SettingTableTab
				headers={this.state.columns}
				getFields={this.getFields}
				feature={feature}
				typesFeature={typesFeature}
				kind="types"
				right={(selected) => (
					<TabView
						className="SidePanel"
						selectedTabId="details"
					>
						<TabChild
							id="details"
							icon="details"
							title="Details"
							label="Details"
						>
							<TypeDetailsTab
								selected={selected}
								fieldsFeature={feature}
							/>
						</TabChild>
					</TabView>
				)}
				create={(onCancel, onCreated) => (
					<CreateTypePanel
						feature={typesFeature}
						moduleFeature={feature}
						onCancel={onCancel}
						onCreated={onCreated}
					/>
				)}
				emptyListText={`There are no ${featureTitles[feature]} Types to list.`}
			/>
		);
	}
}

import type * as React from "react";
import type {FieldDataType} from "../../../generated/api/base";
import {FilterOperator} from "../filter/operator/FilterOperator";
import {
	FieldDetailsTabFormattingSettings,
	FieldDetailsTabSettings,
	FieldDetailsTabSettingsV5,
} from "../../../ui/modules/settings/modules/field/FieldDetailsTabConstants";
import type {IInputProps} from "../../../ui/widgets/input/clicktoedit/FieldInput";
import {
	FieldInlineInputs,
	FieldInlineInputsV5,
	FieldInputLabels,
	FieldInputLabelsV5,
	FieldInputs,
	FieldInputsV5,
	FieldMassEditInputs,
	FieldMassEditInputsV5,
	MassInputs,
	MassInputsV5,
} from "../../../ui/widgets/input/clicktoedit/InputUtils";
import type {IMassInputProps, IMassInputV5Props} from "../../../ui/modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import type {AppState} from "../../state/AppState";
import {Field} from "./Field";

interface IFieldDataTypeConfigBase {
	id: FieldDataType;
	title: string;
	defaultSettings?: any;
	converter?: IFieldValueConverter;
	formatter?: IFieldValueFormatter;
	changeChecker?: IFieldValueApplier;
	formattingRules?: React.ComponentType<{field: Field}>;
	settings?: React.ComponentType<{field: Field; onChange: () => void}>;
	settingsV5?: React.ComponentType<{field: Field; onChange: () => void}>;
	label?: React.ComponentType<{value: any; dataTypeSettings?: any}>;
	labelV5?: React.FunctionComponent<{value: any; dataTypeSettings?: any}>;
	input?: React.ComponentType<IInputProps>;
	inputV5?: React.FunctionComponent<IInputProps>;
	massInput?: React.ComponentType<IMassInputProps>;
	massInputV5?: React.FunctionComponent<IMassInputV5Props>;
	massEditInput?: React.ComponentType<IInputProps>;
	massEditInputV5?: React.FunctionComponent<IInputProps>;
	inlineInput?: React.ComponentType<IInputProps>;
	inlineInputV5?: React.FunctionComponent<IInputProps>;
	system?: boolean;
}

interface IFieldDataTypeConfig extends IFieldDataTypeConfigBase {
	operators: (dataTypeSettings?: any) => FilterOperator[];
}
interface IFieldDataTypeConfig2 extends IFieldDataTypeConfigBase {
	operatorsArray: FilterOperator[];
}

interface IFieldValueConverter {
	(value: string): any;
}

interface IFieldValueFormatter {
	(value: any, settings?: any): string;
}

interface IFieldValueApplier {
	(value: any, inputValue: any, selectedOptionValue?: any, refId?: string, appState?: AppState): boolean;
}

export class FieldDataTypes {
	private static _map: {[type: number]: IFieldDataTypeConfig} = {};
	private static _array: IFieldDataTypeConfig[] = [];

	// private static defaultConfig: IFieldDataTypeConfig = {
	// 	operators: []
	// };

	public static configure(config_: IFieldDataTypeConfig | IFieldDataTypeConfig2) {
		const config = config_ as IFieldDataTypeConfig;

		if (!config.operators) {
			config.operators = () => (config_ as IFieldDataTypeConfig2).operatorsArray;
		}

		this._map[config.id] = config;
		this._array.push(config);

		if (config.settings) {
			FieldDetailsTabSettings[config.id] = config.settings;
		}
		if (config.settingsV5) {
			FieldDetailsTabSettingsV5[config.id] = config.settingsV5;
		}
		if (config.formattingRules) {
			FieldDetailsTabFormattingSettings[config.id] = config.formattingRules;
		}
		if (config.label) {
			FieldInputLabels[config.id] = config.label;
		}
		if (config.labelV5) {
			FieldInputLabelsV5[config.id] = config.labelV5;
		}
		if (config.input) {
			FieldInputs[config.id] = config.input;
		}
		if (config.inputV5) {
			FieldInputsV5[config.id] = config.inputV5;
		}
		if (config.massInput) {
			MassInputs[config.id] = config.massInput;
		}
		if (config.massInputV5) {
			MassInputsV5[config.id] = config.massInputV5;
		}
		if (config.massEditInput) {
			FieldMassEditInputs[config.id] = config.massEditInput;
		}
		if (config.massEditInputV5) {
			FieldMassEditInputsV5[config.id] = config.massEditInputV5;
		}
		if (config.inlineInput) {
			FieldInlineInputs[config.id] = config.inlineInput;
		}
		if (config.inlineInputV5) {
			FieldInlineInputsV5[config.id] = config.inlineInputV5;
		}
		if (config.defaultSettings) {
			Field.defaultSettings[config.id] = config.defaultSettings;
		}
	}

	public static clear() {
		this._map = {};
		this._array.length = 0;
	}

	public static get map() {
		return this._map;
	}

	public static get array() {
		return this._array;
	}
}

export const stringOperators = [
	FilterOperator.IS_BLANK,
	FilterOperator.IS_NOT_BLANK,
	FilterOperator.IS_EQUAL_TO_STR,
	FilterOperator.IS_NOT_EQUAL_TO_STR,
	FilterOperator.CONTAINS,
	FilterOperator.DOES_NOT_CONTAIN,
	FilterOperator.IS_STARTING_WITH,
	FilterOperator.IS_ENDING_WITH,
	FilterOperator.IS_ANY_OF,
	FilterOperator.IS_NOT_ANY_OF,
];

// User, Type is configured in AppState

import styled from "styled-components";
import {useRef} from "react";
import {useAppStore} from "../../../../../StateManager";
import type {IModel} from "../../../../../data/models/Model";
import {MassInputsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import type {IMassInputV5Props} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {getItemsToCheck} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {CreatePopupStyled} from "../../../modules/portfolio/PortfolioCreatePopupV5";
import {MassFieldInputV5} from "./MassFieldInputV5";

interface IMassUpdatePopupV5Props {
	fieldRefId: string;
	items: IModel[];
	onClose: () => void;
}

const MassUpdatePopupStyled = styled(CreatePopupStyled)`
	.header {
		padding: 8px 16px;

		.name {
			margin: 0;
		}

		.cancel {
			margin: 0;
		}
	}

	.body {
		padding: 0 16px;
		gap: 8px;

		.button {
			margin-bottom: 8px;
		}
	}
`;

export const MassUpdatePopupV5 = (props: IMassUpdatePopupV5Props) => {
	const childRef = useRef<IMassInputV5Props>();

	const appState = useAppStore((store) => store.appState);

	const field = appState.actions.getFieldByRefId(props.fieldRefId);

	{
		const MassInputComponent = MassInputsV5[field.dataType] || MassFieldInputV5;
		const itemsToCheck = getItemsToCheck(props.items, field, appState);

		return (
			<MassUpdatePopupStyled
				onClose={props.onClose}
				label={field.name}
				buttonProps={{
					onClick: () => childRef.current?.onApply(itemsToCheck),
					disabled: false,
					label: "Confirm",
					hideIcon: true,
				}}
				centerOnScreen={true}
				smallCancelIcon={true}
				freezeRoot={true}
			>
				<MassInputComponent
					ref={childRef}
					field={field}
					items={itemsToCheck}
					onClose={props.onClose}
				/>
			</MassUpdatePopupStyled>
		);
	}
};

import * as React from "react";

interface IActionBarProps {
	className?: string;
	children: React.ReactNode;
}

export class ActionBar extends React.Component<IActionBarProps> {
	public override render() {
		return <div className={`ActionBar hbox ${this.props.className || ""}`}>{this.props.children}</div>;
	}
}

import {useEffect} from "react";
import {HTMLUtils} from "../../utils/HTML/HTMLUtils";

// https://stackoverflow.com/questions/63359138/react-closing-a-dropdown-when-click-outside
export function useClickOutside(
	refs: React.RefObject<any>[],
	onClickOutside: (event: MouseEvent) => void,
	starterEvent: "mousedown" | "mouseup" = "mousedown",
) {
	useEffect(() => {
		/**
		 * Invoke Function onClick outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			const isClickOutSideOfAllRefs = refs.filter((ref) => !!ref.current).every((ref) => !ref.current.contains(event.target));

			if (isClickOutSideOfAllRefs) {
				onClickOutside(event);
			}
		}
		// Bind
		document.addEventListener(starterEvent, handleClickOutside);
		return () => {
			// dispose
			document.removeEventListener(starterEvent, handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...refs, onClickOutside, starterEvent]);
}

export function useMouseLeave(element: HTMLElement | null, onMouseLeave: (event: MouseEvent) => void, exceptions: HTMLElement[] = []) {
	useEffect(() => {
		function handleMouseMove(event: MouseEvent) {
			const target = event.target as HTMLElement;
			const isTargetPartOfExceptions = target && [element, ...exceptions].some((exception) => HTMLUtils.isDescendant(exception, target, true));

			if (!isTargetPartOfExceptions) {
				onMouseLeave(event);
			}
		}

		document.addEventListener("mousemove", handleMouseMove);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [element, onMouseLeave, ...exceptions]);
}

import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import {NumberInput} from "../../../../../widgets/input/number/NumberInput";
import type {INumericFieldSettingsDefinition} from "../../../../../../data/models/field/datatypes/Numeric";
import type {IFieldDataTypeSettingsProps} from "./IFieldDataTypeSettingsProps";

@observer
export class NumericFieldSettings extends React.Component<IFieldDataTypeSettingsProps> {
	private static readonly _options: {
		id: "general" | "csv" | "currency" | "percentage";
		label: string;
	}[] = [
		{
			id: "general",
			label: "General",
		},
		{
			id: "csv",
			label: "Comma separated values",
		},
		{
			id: "currency",
			label: "Currency",
		},
		// Temporary disable until BA team decides how it should work
		// {
		// 	id: "percentage",
		// 	label: "Percentage"
		// }
	];

	private onInput = (value: number) => {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as INumericFieldSettingsDefinition;

		settings.decimals = settings?.formatting === "percentage" && settings.decimals !== value ? value + 2 : value;
		onChange();
	};

	public override render() {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as INumericFieldSettingsDefinition;

		return (
			<>
				<Field label="Formatting">
					<SelectInput
						options={NumericFieldSettings._options}
						selected={NumericFieldSettings._options.find((option) => option.id === settings.formatting)}
						onChange={(option) => {
							settings.formatting = option.id;
							onChange();
						}}
						render={(option) => option.label}
					/>
				</Field>
				<Field
					label="Decimal Places"
					className="decimals"
				>
					<NumberInput
						value={settings?.formatting === "percentage" ? settings.decimals - 2 : settings.decimals}
						onInput={this.onInput}
						decimals={0}
						min={0}
						max={10}
					/>
				</Field>
			</>
		);
	}
}

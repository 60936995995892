import * as React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "../../../../widgets/button/Button";
import {IconButton} from "../../../../widgets/button/IconButton";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../../generated/api/base";
import {Type} from "../../../../../data/models/Type";
import type {AppState} from "../../../../../data/state/AppState";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {notify} from "../../../../../utils/Notify";
import {NotificationType} from "../../../../notification/Notification";
import type {Navigation} from "../../../../../Navigation";
import {TypeForm} from "./form/TypeForm";

interface ICreateTypePanelProps {
	moduleFeature: XyiconFeature;
	feature: XyiconFeature;
	onCancel: () => void;
	onCreated: (id: string) => void;
	transport?: TransportLayer;
	appState?: AppState;
	navigation?: Navigation;
}

interface ICreateTypePanelState {
	createClicked: boolean;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class CreateTypePanel extends React.PureComponent<ICreateTypePanelProps, ICreateTypePanelState> {
	private readonly _type: Type;

	constructor(props: ICreateTypePanelProps) {
		super(props);
		this.state = {
			createClicked: false,
		};
		this._type = new Type(
			{
				feature: props.feature,
			} as any,
			this.props.appState,
		);
	}

	private onCreateClicked = async () => {
		const {moduleFeature} = this.props;

		this.setState({createClicked: true});

		const {type, error} = await this.props.transport.services.typefield.createType(this._type);

		if (type) {
			this.resetCreateType();
			this.props.onCreated(type.id);
		}

		if ([XyiconFeature.Space, XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(moduleFeature)) {
			if (this.props.appState.lists[XyiconFeature.PermissionSet].array.length > 0) {
				const moduleName = featureTitles[moduleFeature];

				notify(this.props.appState.app.notificationContainer, {
					title: `Enable new ${moduleName} type in permission sets!`,
					type: NotificationType.Message,
					lifeTime: Infinity,
					description: `By default, new types are disabled (hidden) in all permission sets. Click Change Permissions to enable the new ${moduleName} type for your existing permission sets.`,
					buttonLabel: "Change Permissions",
					onActionButtonClick: () => {
						this.props.navigation.go("app/settings/permissionsets/portfolios");
					},
				});
			}
		}
	};

	private onNameInput = (value: string) => {
		this._type.name = value;
	};

	private onCancel = () => {
		this.resetCreateType();
		this.props.onCancel();
	};

	private resetCreateType = () => {
		this._type.name = "";
		this.setState({createClicked: false});
	};

	public override render() {
		const {createClicked} = this.state;
		const type = this._type;
		const isValid = !!type.name && this.props.appState.actions.isTypeNameValid(type.name, type);

		return (
			<div className="CreateTypePanel SidePanel">
				<div className="heading hbox createBox">
					<h4>Create New Type</h4>
					<IconButton
						className="close"
						icon="close"
						onClick={this.onCancel}
					/>
				</div>
				<div
					className="btns hbox"
					data-cy="CreateNewTypesButtonWrapper"
				>
					<Button
						label="Cancel"
						onClick={this.onCancel}
					/>
					<Button
						label="Create"
						onClick={this.onCreateClicked}
						disabled={!isValid || createClicked}
						className="primary"
					/>
				</div>
				<TypeForm
					type={type}
					createPanelNameInput={this.onNameInput}
				/>
			</div>
		);
	}
}

import type {WebGLProgramParametersWithUniforms} from "three";
import {RawShaderMaterial} from "three";
import {Constants} from "../Constants";
import type {Color} from "../../../../../../generated/api/base";
import {ColorUtils} from "../../../../../../utils/ColorUtils";
import {BasicMaterial} from "./BasicMaterial";

export class IndicatorMaterial extends RawShaderMaterial {
	public static readonly TRANSPARENCY = {
		indicator: 0,
		highlight: 0.75,
	};

	private _color: {
		type: "4fv";
		value: number[];
	};

	private _vertexShader: string = BasicMaterial.vertexShader;
	private _fragmentShader: string = `precision highp float;

varying vec2 vUv;

uniform vec4 color;

void main()
{
	float distance = length(vUv - vec2(0.5, 0.5));
	float alpha = smoothstep(0.5, 0.51, 1.0 - distance) * color.a; // distance < 0.5 ? color.a : 0.0;
	if (alpha < ${Constants.EPSILON})
	{
		discard;
	}
	gl_FragColor = vec4(color.rgb, alpha);
}
`;

	constructor(color: Color) {
		super({transparent: true});

		this._color = {
			type: "4fv",
			value: ColorUtils.hex2Array(color.hex, 1 - color.transparency),
		};

		this.onBeforeCompile = (program: WebGLProgramParametersWithUniforms) => {
			program.vertexShader = this._vertexShader;
			program.fragmentShader = this._fragmentShader;

			program.uniforms = {
				color: this._color,
			};
		};
	}

	public setColor(color: Color) {
		this._color.value = ColorUtils.hex2Array(color.hex, 1 - color.transparency);
	}

	public setOpacity(value: number) {
		this._color.value[3] = value;
	}

	public get color() {
		return this._color.value;
	}
}

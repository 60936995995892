import type {FunctionComponent, SVGProps} from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {baseDistance, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import CaretRightIcon from "../icons/caret-right.svg?react";
import {InfoBubbleStyled, InfoBubbleV5} from "../button/InfoBubbleV5";
import {Functions} from "../../../utils/function/Functions";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {SearchFieldStyled} from "../input/search/SearchField.styled";
import {TimeUtils} from "../../../utils/TimeUtils";

export interface IDropdownOption {
	readonly label: string;
	readonly isActive?: boolean;
	readonly disabled?: boolean;
	readonly infoText?: string;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly iconCustomStyle?: React.CSSProperties;
	readonly onClick: (...args: any[]) => any;
	readonly customElement?: JSX.Element;
}

export interface ISubOptionWrapper {
	readonly label: string;
	readonly disabled?: boolean;
	readonly infoText?: string;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly iconCustomStyle?: React.CSSProperties;
	readonly options: (IDropdownOption | ISubOptionWrapper)[];
	readonly customElement?: JSX.Element;
}

interface IDropdownOptionsProps {
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly style?: React.CSSProperties;
	readonly options: (IDropdownOption | ISubOptionWrapper)[];
	readonly className?: string;
	readonly optionsZIndex?: number;
	readonly onClose: () => void;
	readonly showSearch?: boolean;
	readonly closeOnMouseLeave?: boolean;
}

export const DropdownOptionsV5 = (props: IDropdownOptionsProps) => {
	const {
		style = {},
		divRef,
		options,
		className = "",
		onClose,
		optionsZIndex = zIndex.dropdowns,
		showSearch = true,
		closeOnMouseLeave = false,
	} = props;
	const [openOption, setOpenOption] = useState<IDropdownOption | ISubOptionWrapper | null>(null);
	const [searchText, setSearchText] = useState<string>("");

	const filteredOptions = searchText.length === 0 ? options : options.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));

	const finalStyle: React.CSSProperties = {
		...style,
		overflowY: options.some((o) => (o as ISubOptionWrapper).options?.length > 0) || options.some((o) => o.infoText) ? "visible" : "auto",
	};

	useEffect(() => {
		const currentDivRef = divRef?.current;

		if (currentDivRef && closeOnMouseLeave) {
			currentDivRef.addEventListener("mouseleave", onClose);
		}
		return () => {
			currentDivRef?.removeEventListener("mouseleave", onClose);
		};
	}, [divRef, closeOnMouseLeave, onClose]);

	return (
		<DropdownOptionsStyled
			id="DropdownOptionsV5"
			className={`DropdownOptions ${className}`}
			style={finalStyle}
			ref={divRef}
			onClick={Functions.stopPropagation}
			$zIndex={optionsZIndex}
		>
			{showSearch && options.length > 4 && (
				<SearchFieldV5
					className="SearchField"
					value={searchText}
					onInput={(value, ev) => setSearchText(value)}
					onClick={(ev) => ev.stopPropagation()}
				/>
			)}
			{filteredOptions.length === 0 && options.length > 0 && <div>No results found...</div>}
			{filteredOptions.map((option, index) => {
				const hasSubOptions = Array.isArray((option as ISubOptionWrapper).options);
				const isSubMenuOpen = hasSubOptions && option === openOption;

				return (
					<div
						key={JSON.stringify({...option, customElement: null})}
						className={ReactUtils.cls(`option`, {
							hasSubOptions,
							disabled: option.disabled,
							hasIcon: option.IconComponent,
							isActive: (option as IDropdownOption).isActive,
						})}
						onMouseOver={() => setOpenOption(option)}
						onMouseDown={async (ev) => {
							ev.stopPropagation();
							if ((option as IDropdownOption).onClick && !option.disabled) {
								(option as IDropdownOption).onClick();
								await TimeUtils.waitForNextFrame(); // workaround: without this, "useClickOutside" can't find the "option" element within the modalContainer, so the "FloatingMenu" closes
								onClose();
							}
						}}
					>
						<div className="optionLabel">
							{option.IconComponent && (
								<div className="icon">
									<option.IconComponent style={option.iconCustomStyle} />
								</div>
							)}
							<div className="title">{option.customElement ? option.customElement : option.label}</div>
						</div>
						{/* {
								(option as IDropdownOption).isActive &&
								<MarginLeftAuto>✓</MarginLeftAuto>
							} */}
						{hasSubOptions && (
							<>
								<div style={{width: "16px"}}></div>
								<CaretRightIcon />
							</>
						)}
						{option.infoText && (
							<InfoBubbleV5
								content={option.infoText}
								className="InfoBubble"
							/>
						)}
						{isSubMenuOpen && (
							<DropdownOptionsV5
								options={(option as ISubOptionWrapper).options}
								onClose={onClose}
								className="sub"
							/>
						)}
					</div>
				);
			})}
		</DropdownOptionsStyled>
	);
};

export const DropdownOptionsStyled = styled.div<{$zIndex: number}>`
	display: flex;
	flex-direction: column;
	z-index: ${(props) => props.$zIndex};
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	background-color: ${colorPalette.white};
	border-radius: ${radius.md};
	position: absolute;
	text-align: left;
	font-size: 16px;
	line-height: 24px;
	gap: 8px;
	margin: 0;
	padding: 8px;
	min-width: 80px;
	max-height: 288px;

	${SearchFieldStyled} {
		width: 100%;
	}

	&.sub {
		position: absolute;
		top: -8px;
		left: calc(100% + 8px);
		transform: initial;
		overflow-y: hidden;

		&::before {
			display: none;
		}

		.option {
			.optionLabel {
				width: 100%;
			}
		}
	}

	.option {
		color: ${colorPalette.gray.c950};
		position: relative;
		white-space: nowrap;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: ${baseDistance.sm};
		height: 32px;
		border-radius: ${radius.sm};
		width: 100%;

		.optionLabel {
			display: flex;
			gap: ${baseDistance.sm};
			align-items: center;
			max-width: 400px;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.icon {
				display: flex;
			}

			.title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
		}

		.rightIcon {
			display: flex;
		}

		> svg {
			&:first-of-type {
				margin-right: 8px;
			}
		}

		> ${InfoBubbleStyled} {
			display: none;
		}

		&.isActive {
			color: ${colorPalette.primary.c500Primary};
			background: ${colorPalette.primary.c200Light};
		}

		&:not(.disabled, .isActive):hover {
			color: ${colorPalette.gray.c950};
			background: ${colorPalette.gray.c200Light};
			> .right {
				color: white;
				fill: white;
			}
		}

		&:hover {
			> ${InfoBubbleStyled} {
				display: flex;
			}
		}

		&.disabled {
			pointer-events: initial;
			color: ${colorPalette.gray.c200Light};
		}

		&.hasSubOptions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.right {
				width: 10px;
				height: 10px;
			}
		}
	}
`;

import {computed, makeObservable, observable} from "mobx";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {Xyicon3D} from "../elements3d/Xyicon3D";
import type {SpaceItem} from "../elements3d/SpaceItem";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import type {LinkBasicDto, LinkXyiconRequest, PointDouble, XyiconLinkDetail} from "../../../../../../generated/api/base";

export class LinkManager {
	private _spaceViewRenderer: SpaceViewRenderer;

	@observable
	private _isInLinkMode: boolean = false;
	private _fromPortId: string = null;
	private _endXyicon: Xyicon | null = null;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		makeObservable(this);
		this._spaceViewRenderer = spaceViewRenderer;
	}

	public enableLinkMode(fromPortID: string) {
		this._fromPortId = fromPortID;
		this._isInLinkMode = true;
		this._spaceViewRenderer.spaceItemController.closePortSelector();
	}

	public disableLinkMode = () => {
		this._isInLinkMode = false;
		this._spaceViewRenderer.spaceItemController.closePortSelector();
		this._spaceViewRenderer.spaceItemController.updateActionBar();
	};

	/** When a user selects the endxyicon of a link */
	public onEndXyiconClickInLinkMode(xyicon: Xyicon, portsPosition: PointDouble = xyicon.position) {
		this._endXyicon = xyicon;
		const spaceItemController = this._spaceViewRenderer.spaceItemController;
		const ports = this._endXyicon.ports;

		if (ports.length > 0) {
			spaceItemController.openPortSelector(portsPosition.x, portsPosition.y, this._endXyicon, ports, "to");
		} else {
			this.createLinks(null);
			this.disableLinkMode();
		}
	}

	/** Create links between the previously selected xyicons (from) and the lastly clicked xyicon
	 * toPortID: if null, the link is created to the xyicon itself
	 */
	public createLinks(toPortId: string | null) {
		const selectedItems = this._spaceViewRenderer.xyiconManager.selectedItems;

		const linkDetails: XyiconLinkDetail[] = selectedItems.map((xyicon: Xyicon3D) => {
			return {
				fromXyiconID: xyicon.modelData.id,
				fromPortID: this._fromPortId,
				toXyiconID: this._endXyicon.id,
				toPortID: toPortId,
				isEmbedded: false,
			};
		});

		const linkParams: LinkXyiconRequest = {
			fromPortfolioID: selectedItems[0]?.modelData.portfolioId,
			toPortfolioID: this._endXyicon.portfolioId,
			xyiconLinkDetails: linkDetails,
		};

		return this.sendCreateRequest(linkParams);
	}

	public embedXyiconsIntoParentXyicon(xyiconsToEmbed: Xyicon3D[], parentXyicon: Xyicon3D) {
		// xyiconsToEmbed is usually the selecteditems, but the items get deselected when the visibility is set to false, so we better clone this array for safety
		xyiconsToEmbed = [...xyiconsToEmbed];

		this._spaceViewRenderer.spaceItemController.deselectAll();

		const linkDetails: XyiconLinkDetail[] = [];

		for (const xyiconToEmbed of xyiconsToEmbed) {
			xyiconToEmbed.setVisibility(false);

			linkDetails.push({
				fromXyiconID: parentXyicon.modelData.id,
				fromPortID: null,
				toXyiconID: xyiconToEmbed.modelData.id,
				toPortID: null,
				isEmbedded: true,
			});
		}

		const params: LinkXyiconRequest = {
			fromPortfolioID: this._spaceViewRenderer.transport.appState.portfolioId,
			toPortfolioID: this._spaceViewRenderer.transport.appState.portfolioId,
			xyiconLinkDetails: linkDetails,
		};

		return this.sendCreateRequest(params);
	}

	public sendCreateRequest(linkParams: LinkXyiconRequest) {
		if (linkParams.xyiconLinkDetails.length > 0) {
			return this._spaceViewRenderer.transport.requestForOrganization<LinkBasicDto>({
				url: "xyicons/createlink",
				method: XHRLoader.METHOD_PUT,
				params: linkParams,
			});
		}
	}

	public isXyiconLinkableToSelectedXyicons(xyicon: SpaceItem) {
		return (
			xyicon &&
			xyicon.spaceItemType === "xyicon" &&
			this._spaceViewRenderer.xyiconManager.selectedItems.length > 0 &&
			!this._spaceViewRenderer.xyiconManager.selectedItems.includes(xyicon)
		);
	}

	@computed
	public get isInLinkMode() {
		return this._isInLinkMode;
	}
}

import styled from "styled-components";

const AVATAR_SIZE = "32px";

export const AvatarStyled = styled.div`
	background: #3d9970;
	border-radius: 50%;
	height: ${AVATAR_SIZE};
	text-align: center;
	width: ${AVATAR_SIZE};
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;

import styled from "styled-components";
import {baseDistance, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

export const AdvancedFilterField = styled.div`
	&.AdvancedFilterField {
		margin: 0px ${baseDistance.sm} ${baseDistance.sm};
		padding: ${baseDistance.sm};
		background: ${colorPalette.gray.c100};
		border-radius: ${radius.sm};

		.criterias {
			display: flex;
			flex-direction: column;
			width: 100%;
			grid-gap: ${baseDistance.sm};

			.header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding-right: ${baseDistance.xs};

				span.delete {
					svg.icon {
						width: 10px;
						height: 10px;
						position: relative;
						top: ${baseDistance.xs};
					}
				}
			}

			.SelectInput {
				background: ${colorPalette.white};
				max-width: 100%;
				border-radius: ${radius.md};
				display: flex;
				justify-content: space-between;
			}

			input {
				width: 100%;
				padding: 8px;
				border: 1px solid ${colorPalette.gray.c300};
				border-radius: ${radius.md};
				background: ${colorPalette.white};
			}
		}

		.deleteColumn {
			align-items: center;
			display: flex;
			padding: 10px;

			span.delete {
				svg.icon {
					top: 0px;
					width: 12px;
					height: 12px;
				}
			}
		}
	}
`;

import {styled} from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import type {Sortable} from "react-sortablejs";
import {ReactSortable} from "react-sortablejs";
import {useAppStore} from "../../../../../StateManager";
import type {DeleteSpaceFileRequest, SpaceFileDeleteDto, SpaceFileUpdateData, UpdateSpaceFileRequest} from "../../../../../generated/api/base";
import {FieldDataType, Permission, XyiconFeature} from "../../../../../generated/api/base";
import type {ISpaceVersionSortables} from "../../../../modules/space/versionset/ManageVersionSets";
import type {SpaceVersion} from "../../../../../data/models/SpaceVersion";
import type {Space} from "../../../../../data/models/Space";
import type {IPopupWindowConfig} from "../../../../modules/abstract/popups/PopupWindow";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {ButtonV5} from "../../../button/ButtonV5";
import XmarkLargeIcon from "../../../icons/xmark-large.svg?react";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {SVGIcon} from "../../../../widgets/button/SVGIcon";
import {DomUtils, VerticalAlignment, type TransformObj, HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {IconButton} from "../../../../widgets/button/IconButton";
import {Functions} from "../../../../../utils/function/Functions";
import {ReactUtils} from "../../../../utils/ReactUtils";
import CirclePlusIcon from "../../../icons/circle-plus.svg?react";
import DotsHorizontalIcon from "../../../icons/dots-horizontal.svg?react";
import GripDotsVerticalIcon from "../../../icons/grip-dots-vertical.svg?react";
import DeleteIcon from "../../../icons/delete.svg?react";
import PenWithLineIcon from "../../../icons/pen-with-line.svg?react";
import {BACKGROUND, ELLIPSIS, FlexCenterStyle, fontSize, fontWeight, radius, zIndex} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {ResizeDetector} from "../../../../../utils/resize/ResizeDetector";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import {InfoBubbleV5} from "../../../button/InfoBubbleV5";
import {ConfirmValueWindowV5} from "../../../popup/ConfirmValueWindowV5";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {VersionSetCreatePopupV5} from "./VersionSetCreatePopup";
import {VersionSetEditPopupV5} from "./VersionSetEditPopup";

interface IManageVersionSetsV5Props {
	onClose: () => void;
}

interface IIndex {
	i: number;
	j: number;
}

export const ManageVersionSetsV5 = (props: IManageVersionSetsV5Props) => {
	const _createButtonRef = useRef<HTMLDivElement>();
	const _tableRef = useRef<HTMLDivElement>();
	const _headerRef = useRef<HTMLDivElement>();
	const _bodyRef = useRef<HTMLDivElement>();
	const _infoBubbleRef = useRef<HTMLDivElement>();
	const _versionSetNameRefArray = useRef([]);
	const _spaceNameRefArray = useRef([]);
	const _deleteButtonRefArray = useRef<{[key: string]: HTMLDivElement}>({});
	const _resizeDetector = useRef<ResizeDetector>(null);

	const dragTolerance = 4;

	const appState = useAppStore((state) => state.appState);
	const transport = appState.app.transport;

	const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
	const [editPopupOpen, setEditPopupOpen] = useState<boolean>(false);
	const [editedVersionIndex, setEditedVersionIndex] = useState<number>(-1);
	const [hoveredVersionIndex, setHoveredVersionIndex] = useState<number>(-1);
	const [hoveredSpaceNameIndex, setHoveredSpaceNameIndex] = useState<number>(-1);
	const [hoveredSpaceBGIndexes, setHoveredSpaceBGIndexes] = useState<IIndex>({i: -1, j: -1});
	const [hoveredSpaceBGDeleteIndexes, setHoveredSpaceBGDeleteIndexes] = useState<IIndex>({i: -1, j: -1});
	const [tooltipContent, setTooltipContent] = useState<string>("");
	const [tooltipTransform, setTooltipTransform] = useState<TransformObj>(null);
	const [activeSpaceId, setActiveSpaceId] = useState<string>(null);
	const [count, setCount] = useState<number>(0);

	const getVersions = () => {
		return appState.actions
			.getList<SpaceVersion>(XyiconFeature.SpaceVersion)
			.slice()
			.sort((a, b) => (a.date < b.date ? 1 : -1));
	};

	const getSpaces = () => {
		return appState.actions.getList<Space>(XyiconFeature.Space);
	};

	const getSortables = (): ISpaceVersionSortables[] => {
		const spaces = getSpaces();
		const spaceVersions = getVersions();

		return spaces
			.filter((space) => appState.actions.getModuleTypePermission(space.typeId, XyiconFeature.Space) > Permission.View)
			.map((s) => ({
				id: s.id,
				sortables: spaceVersions.map((sv) => ({id: sv.id, spaceVersion: sv})),
			}));
	};

	const getUniqueIndex = (i: number, j: number): string => {
		return `${i}_${j}`;
	};

	const onTableScroll = () => {
		// This runs when the table is scrolled horizontally.
		// We need to set the header's scrollLeft manually to make sure the vertical scrollbar is position properly.

		const header = _headerRef.current;
		const body = _bodyRef.current;

		header.scroll(body.scrollLeft, 0);
	};

	const onResizeWindow = () => {
		const table = _tableRef.current;
		const header = _headerRef.current;
		const body = _bodyRef.current;

		if (!(table.scrollWidth > table.clientWidth)) {
			header.style.width = "auto";
			body.style.width = "auto";
		}
	};

	const getVersionSetEditPopup = (index: number) => {
		setEditPopupOpen(true);
		setEditedVersionIndex(index);
	};

	const onMouseOverVersionSetName = (event: React.MouseEvent, index: number, name: string) => {
		const div = event.currentTarget;

		if (div.scrollWidth > div.clientWidth) {
			setHoveredVersionIndex(index);
			setTooltipContent(name);
		}
	};

	const onMouseLeaveVersionSetName = () => {
		setHoveredVersionIndex(-1);
		setTooltipContent("");
	};

	const onMouseOverSpaceName = (event: React.MouseEvent, index: number, name: string) => {
		const div = event.currentTarget;

		if (div.scrollWidth > div.clientWidth) {
			setHoveredSpaceNameIndex(index);
			setTooltipContent(name);
		}
	};

	const onMouseLeaveSpaceName = () => {
		setHoveredSpaceNameIndex(-1);
		setTooltipContent("");
	};

	const onMouseOverSpaceBgDelete = (i: number, j: number, name: string, noPermission: boolean) => {
		const tooltipContent = noPermission
			? ((
					<>
						<SVGIcon icon="locked" />
						<div>User does not have the correct permission to delete this Space version.</div>
					</>
				) as unknown as string)
			: `You are not allowed to delete this PDF from this location because it is the only one available for ${name} space. Navigate to the SPACES module and delete the entire space to remove this PDF.`;

		setHoveredSpaceBGDeleteIndexes({i, j});
		setTooltipContent(tooltipContent);
	};

	const onMouseLeaveSpaceBgDelete = () => {
		setHoveredSpaceBGDeleteIndexes({i: -1, j: -1});
		setTooltipContent("");
	};

	const onDragEnd = async (evt: Sortable.SortableEvent, space: Space) => {
		const indexFrom = evt.oldDraggableIndex;
		const indexTo = evt.newDraggableIndex;
		const spaceVersions = getVersions();
		const destinationThumbnail = space.spaceFiles.find((sF) => sF.spaceVersionId === spaceVersions[indexTo].id)?.thumbnailFileURL;

		if (!destinationThumbnail) {
			// Local changes
			const spaceFileToUpdate1 = space.spaceFiles.find((sf) => sf.spaceVersionId === spaceVersions[indexFrom].id);
			const spaceFileToUpdate2 = space.spaceFiles.find((sf) => sf.spaceVersionId === spaceVersions[indexTo].id);

			if (spaceFileToUpdate1) {
				spaceFileToUpdate1.spaceVersionId = spaceVersions[indexTo].id;
			}

			if (spaceFileToUpdate2) {
				spaceFileToUpdate2.spaceVersionId = spaceVersions[indexFrom].id;
			}

			setActiveSpaceId(null);

			// Send data to the backend
			const spaceFileUpdateData: SpaceFileUpdateData[] = space.spaceFiles.map((sf) => ({
				spaceFileID: sf.id,
				spaceVersionID: sf.spaceVersionId,
				spaceID: space.id,
				settings: {
					insertionInfo: sf.insertionInfo,
				},
			}));

			const updateParams: UpdateSpaceFileRequest = {
				spaceFileList: spaceFileUpdateData,
				portfolioID: appState.portfolioId,
			};

			await transport.updateSpaceFiles(updateParams);
		}
	};

	const onDeleteVersion = async (version: SpaceVersion) => {
		const message = `You are about to delete ${version.name}, which will delete all PDFs assigned to it.<br>Are you sure you want to delete the selected version set?`;
		const title = "Confirm Version Set Deletion";
		const confirmed = await ConfirmValueWindowV5.open(message, version.name, title);

		if (confirmed) {
			await appState.actions.deleteItems([version], XyiconFeature.SpaceVersion);
			setCount(count + 1);
		}
	};

	const onDeleteSpaceFile = async (space: Space, versionSet: SpaceVersion) => {
		const message = `You are about to delete the PDF from the ${space.name} space and ${versionSet.name} version set. Do you wish to continue?`;
		const title = "Confirm PDF Deletion";
		const config: IPopupWindowConfig = {ok: "Delete", cancel: "Cancel"};
		const spaceFileIndexToDelete = space.spaceFiles.findIndex((sF) => sF.spaceVersionId === versionSet.id);
		const confirmed = await ConfirmWindowV5.open(message, title, config);

		if (confirmed && spaceFileIndexToDelete > -1) {
			const spaceFileIdToDelete = space.spaceFiles[spaceFileIndexToDelete].id;

			const params: DeleteSpaceFileRequest = {
				portfolioID: appState.portfolioId,
				spaceID: space.id,
				spaceFileIDList: [spaceFileIdToDelete],
			};

			space.spaceFiles.splice(spaceFileIndexToDelete, 1);
			const result = await transport.requestForOrganization<SpaceFileDeleteDto>({
				url: "spacefiles/delete",
				method: XHRLoader.METHOD_DELETE,
				params: params,
			});
		}
	};

	const onCreateClicked = () => {
		setEditPopupOpen(false);
		setCreatePopupOpen(true);
	};

	const onOptionsClicked = () => {
		setCreatePopupOpen(false);
		setEditPopupOpen(false);
	};

	const isDeleteDisabled = (version: SpaceVersion) => {
		const spaces = getSpaces();

		return spaces.some((space) => space.versions.length === 1 && space.versions[0].id === version.id);
	};

	useEffect(() => {
		_resizeDetector.current = new ResizeDetector(document.body);
		_resizeDetector.current.resize.add(onResizeWindow);

		return () => {
			_resizeDetector.current.resize.remove(onResizeWindow);
			_resizeDetector.current.dispose();
		};
	}, []);

	useEffect(() => {
		const {i, j} = hoveredSpaceBGDeleteIndexes;

		let ref: HTMLElement;

		if (i > -1 && j > -1) {
			ref = _deleteButtonRefArray.current[getUniqueIndex(i, j)];
		}
		if (hoveredSpaceNameIndex > -1) {
			ref = _spaceNameRefArray.current[hoveredSpaceNameIndex];
		}
		if (hoveredVersionIndex > -1) {
			ref = _versionSetNameRefArray.current[hoveredVersionIndex];
		}

		if (ref && _infoBubbleRef.current) {
			setTooltipTransform(
				DomUtils.getFixedFloatingElementPosition(ref, _infoBubbleRef.current, VerticalAlignment.topOuter, HorizontalAlignment.center),
			);
		}
	}, [hoveredSpaceBGDeleteIndexes, hoveredSpaceNameIndex, hoveredVersionIndex]);

	{
		const sortables = getSortables();
		const spaceVersions = getVersions();
		const {i: bgI, j: bgJ} = hoveredSpaceBGIndexes;
		const inlineStyle: React.CSSProperties = _infoBubbleRef && {
			transform: tooltipTransform?.translate,
			zIndex: 9000,
			position: "absolute",
			top: 0,
			left: 0,
		};

		return (
			<ManageVersionSetsStyled>
				{createPopupOpen && (
					<VersionSetCreatePopupV5
						onClose={() => setCreatePopupOpen(false)}
						parentRef={_createButtonRef}
					/>
				)}
				{editPopupOpen && (
					<VersionSetEditPopupV5
						onClose={() => setEditPopupOpen(false)}
						parentRef={_versionSetNameRefArray.current[editedVersionIndex]}
						versionSet={spaceVersions[editedVersionIndex]}
					/>
				)}
				<HeaderStyled>
					<HeaderLabelStyled>Manage Version Sets</HeaderLabelStyled>
					<HeaderRightStyled>
						<ButtonV5
							label="Create Version Set"
							title="Create Version"
							onClick={onCreateClicked}
							ref={_createButtonRef}
						>
							<CirclePlusIcon />
						</ButtonV5>
						<IconButtonV5
							onClick={props.onClose}
							IconComponent={XmarkLargeIcon}
						/>
					</HeaderRightStyled>
				</HeaderStyled>
				<SecondaryHeaderStyled></SecondaryHeaderStyled>
				<TableStyled ref={_tableRef}>
					<TableHeaderStyled ref={_headerRef}>
						<HeaderCellStyled>Space</HeaderCellStyled>
						{spaceVersions.map((version, index) => {
							return (
								<HeaderCellStyled key={index}>
									<VersionNameStyled
										ref={(ref) => (_versionSetNameRefArray.current[index] = ref)}
										onMouseOver={(e) => onMouseOverVersionSetName(e, index, version.name)}
										onMouseLeave={onMouseLeaveVersionSetName}
										title={version.name}
									>
										{version.name}
									</VersionNameStyled>
									<VersionDateStyled>
										{appState.actions.formatValueByDataType(version.date, FieldDataType.DateTime, {format: "date"})}
									</VersionDateStyled>
									<OptionsButtonStyled
										options={[
											{
												label: "Edit Version",
												onClick: () => getVersionSetEditPopup(index),
												IconComponent: PenWithLineIcon,
											},
											{
												label: "Delete Version",
												onClick: () => onDeleteVersion(version),
												disabled: isDeleteDisabled(version),
												infoText:
													isDeleteDisabled(version) &&
													`${version.name} cannot be deleted since it contains the only available PDF version of one or more Spaces. Reassign the PDF to a different version set and try again.`,
												IconComponent: DeleteIcon,
											},
										]}
										verticalAlignment={VerticalAlignment.bottomOuter}
										button={<DotsHorizontalIcon />}
										onClick={onOptionsClicked}
									/>
								</HeaderCellStyled>
							);
						})}
					</TableHeaderStyled>
					<TableBodyStyled
						ref={_bodyRef}
						onScroll={onTableScroll}
					>
						{sortables.map((spaceItem, i) => {
							const space = appState.actions.getSpaceById(spaceItem.id);

							return (
								<div
									className={ReactUtils.cls("row hbox", {dragActive: activeSpaceId === space.id})}
									key={spaceItem.id}
								>
									<SpaceNameStyled
										ref={(ref) => (_spaceNameRefArray.current[i] = ref)}
										onMouseOver={(e) => onMouseOverSpaceName(e, i, space.name)}
										onMouseLeave={onMouseLeaveSpaceName}
									>
										{space.name}
									</SpaceNameStyled>
									<ReactSortable
										className="sortables hbox"
										swap={true}
										forceFallback={true}
										swapThreshold={0.45}
										fallbackTolerance={dragTolerance}
										touchStartThreshold={dragTolerance}
										direction="horizontal"
										list={spaceItem.sortables}
										onStart={() => setActiveSpaceId(space.id)}
										setList={Functions.emptyFunction}
										onEnd={(evt: Sortable.SortableEvent) => onDragEnd(evt, space)}
										filter=".ignore-elements"
									>
										{spaceItem.sortables.map((sortable, j) => {
											const noPermission = appState.actions.getModuleTypePermission(space.typeId, space.ownFeature) < Permission.Delete;
											const isSpaceDeleteDisabled = space.spaceFiles.length === 1 || noPermission;
											const thumbnailURL = space.spaceFiles.find((sF) => sF.spaceVersionId === sortable.spaceVersion.id)?.thumbnailFileURL;

											return (
												<TableCellStyled
													className={ReactUtils.cls("tr", {"ignore-elements": !thumbnailURL})}
													key={sortable.id}
												>
													{thumbnailURL ? (
														<CellContentStyled
															onMouseOver={() => setHoveredSpaceBGIndexes({i, j})}
															onMouseLeave={() => setHoveredSpaceBGIndexes({i: -1, j: -1})}
														>
															<DragHandleStyled>
																<GripDotsVerticalIcon />
															</DragHandleStyled>
															<ThumbnailStyled style={{backgroundImage: `url("${thumbnailURL}")`}} />
															{bgI === i && bgJ === j && (
																<DeleteButtonStyled
																	ref={(parentRef) => (_deleteButtonRefArray.current[getUniqueIndex(i, j)] = parentRef)}
																	onMouseOver={() => isSpaceDeleteDisabled && onMouseOverSpaceBgDelete(i, j, space.name, noPermission)}
																	onMouseLeave={onMouseLeaveSpaceBgDelete}
																>
																	<IconButton
																		icon="delete"
																		title="Delete"
																		disabled={isSpaceDeleteDisabled}
																		onClick={() => onDeleteSpaceFile(space, sortable.spaceVersion)}
																	/>
																</DeleteButtonStyled>
															)}
														</CellContentStyled>
													) : (
														<CellContentStyled className="cell emptyCell" />
													)}
												</TableCellStyled>
											);
										})}
									</ReactSortable>
								</div>
							);
						})}
					</TableBodyStyled>
				</TableStyled>
				{tooltipContent && (
					<DomPortal destination={appState.app.modalContainer}>
						<InfoBubbleV5
							divRef={_infoBubbleRef}
							content={tooltipContent}
							style={inlineStyle}
							className="DeleteButtonToolTip"
						/>
					</DomPortal>
				)}
			</ManageVersionSetsStyled>
		);
	}
};

const cellWidth = "232px";
const cellHeight = "152px";

const ManageVersionSetsStyled = styled.div`
	z-index: ${zIndex.createPanel};
	top: 0;
	bottom: 0;
	position: absolute;
	background: white;
	left: 0;
	right: 0;
	border-radius: 8px;
	padding: 16px;
	width: calc(100vw - 65px);
`;

const HeaderStyled = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 4px 0;

	svg {
		cursor: pointer;
		width: 16px;
		height: 16px;
	}
`;

const HeaderLabelStyled = styled.h4`
	font-size: ${fontSize.xl};
	font-weight: ${fontWeight.bold};
	line-height: 24px;
`;

const HeaderRightStyled = styled.div`
	${FlexCenterStyle};
	gap: 16px;
`;

const SecondaryHeaderStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;
	margin-bottom: 16px;
`;

const TableStyled = styled.div`
	position: relative;
	border-radius: ${radius.md};
	border: 1px solid ${colorPalette.gray.c300};
	max-height: calc(100vh - 200px);
`;

const TableHeaderStyled = styled.div`
	background-color: ${colorPalette.gray.c100};
	border-radius: ${radius.md} ${radius.md} 0 0;
	border-bottom: none;
	display: flex;
	height: 40px;
	width: 100%;
	font-size: ${fontSize.lg};
	font-weight: ${fontWeight.normal};
	line-height: 24px;
	overflow: hidden;
`;

const TableBodyStyled = styled.div`
	overflow: scroll;
	width: 100%;
	max-height: calc(100vh - 241px);
`;

const HeaderCellStyled = styled.div`
	display: flex;
	gap: 8px;
	min-width: ${cellWidth};
	max-width: ${cellWidth};
	border-right: 1px solid ${colorPalette.gray.c300};
	align-items: center;
	color: ${colorPalette.gray.c950};

	&:first-child {
		width: ${cellWidth};
		max-width: ${cellWidth};
		border-radius: ${radius.md} 0 0 0;
		margin-left: 8px;
	}
`;

const VersionNameStyled = styled.span`
	width: 110px;
	${ELLIPSIS}
	color: ${colorPalette.gray.c950};
	margin-left: 8px;
`;

const VersionDateStyled = styled.span`
	width: 66px;
	font-size: ${fontSize.sm};
	color: ${colorPalette.gray.c700Dark};
`;

const OptionsButtonStyled = styled(DropdownButtonV5)`
	height: 24px;
	width: 24px;

	svg {
		height: 16px;
		width: 16px;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

const CellBase = styled.div`
	width: ${cellWidth};
	min-width: ${cellWidth};
	height: ${cellHeight};
	align-items: center;
	display: flex;
`;

const TableCellStyled = styled(CellBase)`
	justify-content: center;
`;

const SpaceNameStyled = styled(CellBase)`
	padding-left: 8px;
	${ELLIPSIS}
`;

const CellContentStyled = styled.div`
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: ${radius.md};
	width: 200px;
	height: 120px;
	position: relative;
	display: flex;
	align-items: center;

	&.emptyCell {
		border: 2px dashed ${colorPalette.gray.c300};
	}

	&:hover {
		border-color: var(--blue);
	}
`;

const ThumbnailStyled = styled.div`
	width: 100%;
	height: 100%;
	${BACKGROUND}
`;

const DragHandleStyled = styled.div`
	height: 100%;
	min-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${radius.md} 0 0 ${radius.md};
	background-color: ${colorPalette.gray.c100};
	cursor: pointer;
`;

const DeleteButtonStyled = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	background-color: var(--bg3);
	border-radius: 0 ${radius.md} 0 0;

	.button {
		padding: 8px 9px;
		border-radius: 0 ${radius.md} 0 0;

		&.disabled {
			fill: ${colorPalette.gray.c500Primary};
		}

		.icon {
			width: 12px;
			height: 14px;
		}
	}
`;

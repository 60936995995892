import type {LibraryImageDto} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import type {IModel} from "./Model";

export class LibraryImage implements IModel, LibraryImageDto {
	private _data: LibraryImageDto;
	private _appState: AppState;

	constructor(data: LibraryImageDto, appState: AppState) {
		this._data = data;
		this._appState = appState;
	}

	private get _transport() {
		return this._appState.app.transport;
	}

	public get id() {
		return this._data.libraryImageID;
	}

	public get fileName() {
		return this._data.fileName;
	}

	public get url(): string {
		return this._transport.getFullPathFromServer(`library/${this.fileName}`);
	}

	public get thumbnail() {
		return this._data.thumbnail;
	}

	public get keywords() {
		return this._data.keywords;
	}

	public get imageType() {
		return this._data.type;
	}

	public get data() {
		return this._data;
	}
}

import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {Constants} from "../../Constants";
import type {PointDouble} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {PointerDetector} from "../../../../../../../utils/interaction/PointerDetector";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";
import type {IToolConfig, SpaceTool, ToolType} from "./Tools";
import type {SelectionTool} from "./SelectionTool";

interface ITool {
	activate: () => void;
	deactivate: () => void;
}

/**
 * worldDX and worldDY are the difference between the current and the previous values (delta)
 */
type PointerCallback = (pointer: Pointer, worldX?: number, worldY?: number) => void;
export abstract class Tool implements ITool {
	private static _pointersDown: number = 0;
	private _pointerDetector: PointerDetector;
	private _isActive: boolean = false;
	private _isEnabledMouseButtonDown: boolean = false;
	protected _mouseButtonsToActivate: number[] = [Constants.MOUSE_BUTTON.LEFT];
	protected abstract readonly _toolType: ToolType;

	protected abstract onPointerDownCallback: PointerCallback;
	protected abstract onPointerMoveCallback: PointerCallback;
	protected abstract onPointerUpCallback: PointerCallback;

	protected _spaceViewRenderer: SpaceViewRenderer;

	protected _pointerWorldStart: PointDouble;
	private _savedPointerObject: Pointer;
	protected _currentPointerWorld: PointDouble;
	protected _currentPointerXY: PointDouble = {
		x: null,
		y: null,
	};

	private _movementUpdateShouldBeCalled: boolean = false;

	private readonly _dispatchPointerMoveWithoutPointerDown: boolean;

	protected _defaultCursorStyle: string;
	protected _defaultCursorStyleForTool: string;

	constructor(spaceViewRenderer: SpaceViewRenderer, dispatchPointerMoveWithoutPointerDown: boolean = false, defaultCursorStyleForTool: string = "") {
		this._dispatchPointerMoveWithoutPointerDown = dispatchPointerMoveWithoutPointerDown;
		this._spaceViewRenderer = spaceViewRenderer;

		this._defaultCursorStyleForTool = defaultCursorStyleForTool;

		this._pointerDetector = spaceViewRenderer.toolManager.pointerDetector;
	}

	protected get _domElement() {
		return this._spaceViewRenderer.domElement;
	}

	/**
	 * Returns if it's changed
	 */
	public activate(): boolean {
		if (!this._isActive) {
			this._pointerDetector.signals.down.add(this.onPointerDown);
			this._pointerDetector.signals.hoverMove.add(this.onPointerMove);
			this._pointerDetector.signals.move.add(this.onPointerMove);
			this._pointerDetector.signals.up.add(this.onPointerUp);
			this._isActive = true;
			this._defaultCursorStyle = this._domElement.style.cursor;
			this._domElement.style.cursor =
				this._spaceViewRenderer.toolManager.cameraControls.isPanning || KeyboardListener.isSpaceDown || KeyboardListener.isShiftDown
					? "grabbing"
					: this._defaultCursorStyleForTool;

			this._spaceViewRenderer.signals.onBeforeRender.add(this.updatePointerMovement);

			return true;
		}

		return false;
	}

	/**
	 * Returns if it's changed
	 */
	public deactivate(): boolean {
		if (this._isActive) {
			this._pointerDetector.signals.down.remove(this.onPointerDown);
			this._pointerDetector.signals.hoverMove.remove(this.onPointerMove);
			this._pointerDetector.signals.move.remove(this.onPointerMove);
			this._pointerDetector.signals.up.remove(this.onPointerUp);
			this._isActive = false;
			this._domElement.style.cursor = this._defaultCursorStyle;

			this._spaceViewRenderer.signals.onBeforeRender.remove(this.updatePointerMovement);

			return true;
		}

		return false;
	}

	/**
	 * MouseMove event can run a lot more frequently than the refresh rate of the user's display.
	 * We optimize this way: on mousemove, only the new mouse coordinates are saved, and the rest of the calculations are called only once / frame refresh
	 */
	private updatePointerMovement = () => {
		if (this._savedPointerObject) {
			this._currentPointerWorld = THREEUtils.domCoordinatesToWorldCoordinates(
				this._savedPointerObject.localX,
				this._savedPointerObject.localY,
				this._spaceViewRenderer.spaceOffset.z,
				this._spaceViewRenderer.domElement,
				this._spaceViewRenderer.activeCamera,
			);
		}

		if (this._movementUpdateShouldBeCalled) {
			this._movementUpdateShouldBeCalled = false;
			if (this._currentPointerWorld || this._toolType === "selection") {
				this.onPointerMoveCallback(this._savedPointerObject, this._currentPointerWorld?.x, this._currentPointerWorld?.y);
			}
		}
	};

	protected onPointerDown = (pointer: Pointer) => {
		this._currentPointerXY = {
			x: pointer.localX,
			y: pointer.localY,
		};

		this._currentPointerWorld = THREEUtils.domCoordinatesToWorldCoordinates(
			pointer.localX,
			pointer.localY,
			this._spaceViewRenderer.spaceOffset.z,
			this._domElement,
			this._spaceViewRenderer.activeCamera,
		);

		if (!KeyboardListener.isSpaceDown && !KeyboardListener.isShiftDown) {
			Tool._pointersDown++;

			if (Tool._pointersDown > 1) {
				this.onPointerUpCallback(pointer, this._currentPointerWorld?.x, this._currentPointerWorld?.y);
			}

			this._isEnabledMouseButtonDown = false;

			if (this._mouseButtonsToActivate.includes(Constants.MOUSE_BUTTON.LEFT) && pointer.isNormalClick) {
				this._isEnabledMouseButtonDown = true;
			}
			if (this._mouseButtonsToActivate.includes(Constants.MOUSE_BUTTON.MIDDLE) && pointer.isMiddleClick) {
				this._isEnabledMouseButtonDown = true;
			}
			if (this._mouseButtonsToActivate.includes(Constants.MOUSE_BUTTON.RIGHT) && pointer.isRightClick) {
				this._isEnabledMouseButtonDown = true;
			}

			if (Tool._pointersDown === 1 && this._isEnabledMouseButtonDown && !KeyboardListener.isSpaceDown && !KeyboardListener.isShiftDown) {
				this._pointerWorldStart = this._currentPointerWorld;
				if (this._pointerWorldStart) {
					this.onPointerDownCallback(pointer, this._pointerWorldStart.x, this._pointerWorldStart.y);
				}
			}
		}
	};

	protected onPointerMove = (pointer: Pointer) => {
		// console.log(`${pointer.localX}, ${pointer.localY + 50}`);
		if (Tool._pointersDown > 1) {
			return;
		}

		this._savedPointerObject = pointer;

		const isCursorMoved = pointer.localX !== this._currentPointerXY.x || pointer.localY !== this._currentPointerXY.y;

		this._currentPointerXY = {
			x: pointer.localX,
			y: pointer.localY,
		};

		if (isCursorMoved) {
			const isGrabbed = this._spaceViewRenderer.toolManager.cameraControls.isCameraGrabbed;
			const isReadyToGrab = (KeyboardListener.isSpaceDown || KeyboardListener.isShiftDown) && Tool._pointersDown === 0;

			if (isGrabbed) {
				this._domElement.style.cursor = "grabbing";
			} else if (isReadyToGrab) {
				this._domElement.style.cursor = "grab";
			} else {
				if (!(this as any as SelectionTool).updateCursorStyle && !this._spaceViewRenderer.toolManager.cameraControls.isCameraGrabbed) {
					this._domElement.style.cursor = this._defaultCursorStyleForTool;
				}
				if ((Tool._pointersDown === 1 && this._isEnabledMouseButtonDown) || this._dispatchPointerMoveWithoutPointerDown) {
					if (this._dispatchPointerMoveWithoutPointerDown || pointer.startX !== pointer.localX || pointer.startY !== pointer.localY) {
						this._movementUpdateShouldBeCalled = true;
					}
				}
			}
		}
	};

	protected onPointerUp = (pointer: Pointer) => {
		if (Tool._pointersDown > 0) {
			Tool._pointersDown = Math.max(Tool._pointersDown - 1, 0);
			this._movementUpdateShouldBeCalled = false;
			if (Tool._pointersDown === 0 && this._isEnabledMouseButtonDown) {
				this._isEnabledMouseButtonDown = false;
				const pointerNow = THREEUtils.domCoordinatesToWorldCoordinates(
					pointer.localX,
					pointer.localY,
					this._spaceViewRenderer.spaceOffset.z,
					this._spaceViewRenderer.domElement,
					this._spaceViewRenderer.activeCamera,
				);

				this.onPointerUpCallback(pointer, pointerNow?.x, pointerNow?.y);
				this._spaceViewRenderer.needsRender = true;
			}
		}
	};

	public static reset() {
		Tool._pointersDown = 0;
	}

	protected get isActive() {
		return this._isActive;
	}

	public get currentPointerWorld() {
		return this._currentPointerWorld;
	}

	public get toolId(): SpaceTool {
		for (const key in this._spaceViewRenderer.tools) {
			if ((this._spaceViewRenderer.tools[key as SpaceTool] as IToolConfig).tool === this) {
				return key as SpaceTool;
			}
		}

		console.warn("Invalid tool. This shouldn't have happened.");

		return null;
	}
}
